import template from './news-feed.html';
import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import { Country, Customer } from '@proftit/crm.api.models.entities';
class NewsFeedController extends BaseController {
  newsCity: string;
  newsCountry: Country;
  feeds: any[];

  customer: Customer;

  /*@ngInject*/
  constructor(
    readonly customersService: () => CustomersService,
    readonly $scope: IScope,
  ) {
    super();
  }

  $onInit() {
    this.newsCity = this.newsCountry = null;

    // Init the news feed with current country and city
    this.initNews();

    /**
     * Listen to contact updates. when the city/country changes, re-init news.
     *
     * We can't just $watch the city or country - because we don't want to change the newsfeed
     * before the user submits the form.
     */
    this.$scope.$on('contact:update', (e, data) => {
      if (
        this.newsCity !== this.customer.city ||
        this.newsCountry !== this.customer.country
      ) {
        // location has changed, update weather
        this.initNews();
      }
    });
  }

  /**
   * Init the news feed for current city and country
   */
  initNews() {
    // first, reset current feed
    this.feeds = [];

    this.customersService()
      .setConfig({ suppressGrowl: true, blockUiRef: 'newsFeed' })
      .getNews(this.customer.id)
      .then((news) => {
        this.feeds = news;
        // Save for comparison when customer updates
        this.newsCity = this.customer.city;
        this.newsCountry = this.customer.country;
      })
      .catch(() => {
        this.feeds = null;
      });
  }
}

export default {
  template,
  controller: NewsFeedController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
  },
};
