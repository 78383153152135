import { PermissionNormalized } from '~/source/common/models/permission-structure';

export const menuEntries = [
  {
    permissionOnly: PermissionNormalized.AutomationInactivity,
    svgIcon: 'fee',
    title: 'automation.INACTIVITY_FEE',
    uiState: '.inactivityFee',
  },
  {
    permissionOnly: PermissionNormalized.AutomationAccountStatus,
    svgIcon: 'account',
    title: 'automation.ACCOUNT_STATUS',
    uiState: '.accountStatus',
  },
  {
    svgIcon: 'depositDemoAccount',
    title: 'automation.BRAND_DEMO_DEPOSIT',
    permissionOnly: PermissionNormalized.AutomationDemoDeposit,
    uiState: '.depositDemoAccount',
  },
  {
    svgIcon: 'autoAssignment',
    title: 'automation.AUTO_ASSIGNMENTS',
    permissionOnly: PermissionNormalized.AutoAssignments,
    uiState: '.autoAssignments',
  },
  {
    svgIcon: 'accountStatusPermissions',
    title: 'automation.ACCOUNT_STATUS_PERMISSIONS',
    permissionOnly: PermissionNormalized.AutomationAccountStatusPermissions,
    uiState: '.accountStatusPermissions',
  },
  {
    svgIcon: 'referAFriend',
    title: 'automation.REFER_A_FRIEND',
    permissionOnly: PermissionNormalized.AutomationReferFriend,
    uiState: '.referAFriend',
  },
  {
    svgIcon: 'promoCode',
    title: 'automation.PROMO_CODE',
    permissionOnly: PermissionNormalized.AutomationBrandPlatformPromoCode,
    uiState: '.promoCode',
  },
];
