import Component from './base/component';
import QuestionnaireService from '~/source/management/questionnaire/questionnaire.service';

class CountryController extends Component.controller {
  static $inject = ['questionnaireService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.SELECT_QUESTIONNAIRE_FORM';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return (this.dataServiceInst as QuestionnaireService)
      .filter({
        isHead: true,
      })
      .getListWithQuery();
  }
}

export const QuestionnaireFormSelectComponent = Component.config({
  controller: CountryController,
});
