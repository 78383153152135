import template from './email-template-designs-table.component.html';
const styles = require('./email-template-designs-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { DesignTemplate, Brand } from '@proftit/crm.api.models.entities';
import { TableController } from '~/source/common/components/table/table.controller';
import { useStreams } from '@proftit/rxjs.adjunct';
import { DesignTemplatesService } from '~/source/common/api-crm-server/services/design-templates.service';
import { emailTemplatesDesignsTableSettings } from './email-templates-designs-table.settings';
import { emailTemplatesDesignsTableCols } from './email-templates-designs-table.cols';
import * as rx from '@proftit/rxjs';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { ActionMenuDropdownItem } from '~/source/common/components/actions-menu-dropdown/action-menu-dropdown-item';
import * as _ from '@proftit/lodash';
import { switchOn } from '~/source/common/utilities/switch-on';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  DESIGN_TEMPLATE_DELETED,
  DESIGN_TEMPLATE_UPDATED,
  DESIGN_TEMPLATE_CREATED,
} from '~/source/common/constants/general-pubsub-keys';
import log from 'loglevel';
import { EmailTemplatesDesignsTableAction } from './email-templates-designs-table-actions';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { checkEmailTemplatePermission } from '~/source/common/utilities/rxjs/observables/check-email-template-permission';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import { FormControl } from '@proftit/ng1.reactive-forms';

interface NumberOfRowsElement {
  count: number;
}

export class EmailTemplateDesignsTableController extends TableController {
  static $inject = [
    'designTemplatesService',
    'prfClientGeneralPubsub',
    'modalService',
    'PermPermissionStore',
    ...TableController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = 'emailTemplateDesignsTable';

  designTemplatesService: () => DesignTemplatesService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  PermPermissionStore: ng.permission.PermissionStore;
  modalService: ModalService;
  dataServiceInstance: DesignTemplatesService;

  settings = { ...emailTemplatesDesignsTableSettings };
  cols = [...emailTemplatesDesignsTableCols];

  brand$ = new rx.BehaviorSubject<Brand>(null);
  collectionMenuItems$ = new rx.BehaviorSubject<{
    [key: string]: ActionMenuDropdownItem;
  }>({});
  opMenuAction$ = new rx.Subject<{
    actionCode: EmailTemplatesDesignsTableAction;
    itemId: number;
  }>();

  triggerShowSelectedNumberOfRows$ = new rx.BehaviorSubject<object | boolean>(
    false,
  );

  selectedNumberOfRows = new FormControl<NumberOfRowsElement>(null);

  constructor(...args) {
    super(...args);

    useStreams(
      [
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
        this.streamInitTable(),
        this.streamCalcMenuItems(),
        this.streamMenuActionPerform(),
        this.streamRefreshTableOnExternalNotice(),
        this.streamInitSelectedNumberOfRows(),
        this.selectedNumberOfRows.value$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.designTemplatesService();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  fetchFn() {
    // todoOld: brand filter

    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiRef })
      .expand(['createdBy'])
      .sort({ createdAt: 'desc' });
  }

  streamInitTable() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamCalcMenuItems() {
    return rx.pipe(
      () => this.collection$,
      rx.switchMap(() =>
        checkEmailTemplatePermission(this.PermPermissionStore),
      ),
      rx.withLatestFrom(this.collection$),
      rx.map(([[updatePerm, deletePerm, createPerm], items]) =>
        items.map((item) => ({
          id: item.id,
          menu: calcMenuItemForDesignTemplate(
            item as DesignTemplate,
            updatePerm,
            deletePerm,
            createPerm,
          ),
        })),
      ),
      rx.map((itemsMenus) =>
        itemsMenus.reduce((acc, itemMenu) => {
          acc[itemMenu.id] = itemMenu.menu;
          return acc;
        }, {}),
      ),
      rx.tap((menus) => this.collectionMenuItems$.next(menus)),
    )(null);
  }

  streamMenuActionPerform() {
    return rx.pipe(
      () => this.opMenuAction$,
      rx.tap((action) => {
        const actions = {
          [EmailTemplatesDesignsTableAction.Delete]: () =>
            this.dataServiceInstance.deleteItem(action.itemId),
          [EmailTemplatesDesignsTableAction.Activate]: () =>
            this.dataServiceInstance.activateItem(action.itemId),
          [EmailTemplatesDesignsTableAction.Deactivate]: () =>
            this.dataServiceInstance.deactivateItem(action.itemId),
          [EmailTemplatesDesignsTableAction.Edit]: () =>
            this.openDesignTemplateDialogForEdit(action.itemId),
          [EmailTemplatesDesignsTableAction.Copy]: () =>
            this.openDesignTemplateDialogForCopy(action.itemId),
        };
        switchOn(actions, action.actionCode, () => {
          log.error('unhandled action', action);
          throw new Error('unhandled action');
        });
      }),
      rx.catchError((err, obs) => {
        log.error('error on action', err);
        return obs;
      }),
    )(null);
  }

  streamRefreshTableOnExternalNotice() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) =>
        [
          DESIGN_TEMPLATE_DELETED,
          DESIGN_TEMPLATE_UPDATED,
          DESIGN_TEMPLATE_CREATED,
        ].includes(key),
      ),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamInitSelectedNumberOfRows() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.lifecycles.onInit$,
          rx.obs.from([this.settings.ngTable.parameters.count]),
        ),
      rx.map(([a, num]) => {
        const id = numRowsOptions.indexOf(num);

        return {
          id,
          count: num,
          name: num,
        };
      }),
      rx.tap((numOfRowRecord) => {
        this.selectedNumberOfRows.setValueAsFirst(numOfRowRecord);
      }),
      rx.tap(() => {
        this.triggerShowSelectedNumberOfRows$.next({});
      }),
    )(null);
  }

  openDesignTemplateDialogForEdit(id: number) {
    this.modalService.open({
      component: 'prfDesignTemplateDialog',
      resolve: {
        id: () => id,
        action: () => CrudOpeartion.Update,
      },
    });
  }

  openDesignTemplateDialogForCopy(id: number) {
    this.modalService.open({
      component: 'prfDesignTemplateDialog',
      resolve: {
        id: () => id,
        action: () => CrudOpeartion.Create,
        subAction: () => 'copy',
      },
    });
  }

  onRowClick(item: DesignTemplate) {
    this.opMenuAction$.next({
      actionCode: EmailTemplatesDesignsTableAction.Edit,
      itemId: item.id,
    });
  }
}

function calcMenuItemForDesignTemplate(
  item: DesignTemplate,
  updatePerm,
  deletePerm,
  createPerm,
) {
  return _.flow([
    () => [],
    (menu) =>
      updatePerm
        ? [
            ...menu,
            {
              actionCode: EmailTemplatesDesignsTableAction.Edit,
              labelCode: 'common.EDIT',
            },
          ]
        : menu,
    (menu) => (updatePerm ? [...menu, calcActivateMenuItem(item)] : menu),
    (menu) =>
      createPerm
        ? [
            ...menu,
            {
              actionCode: EmailTemplatesDesignsTableAction.Copy,
              labelCode: 'common.COPY',
            },
          ]
        : menu,
    (menu) =>
      deletePerm
        ? [
            ...menu,
            {
              actionCode: EmailTemplatesDesignsTableAction.Delete,
              labelCode: 'common.DELETE',
            },
          ]
        : menu,
  ])();
}

function calcActivateMenuItem(item: DesignTemplate) {
  if (item.isActive) {
    return {
      actionCode: EmailTemplatesDesignsTableAction.Deactivate,
      labelCode: 'common.DEACTIVATE',
    };
  }

  return {
    actionCode: EmailTemplatesDesignsTableAction.Activate,
    labelCode: 'common.ACTIVATE',
  };
}

export const EmailTemplateDesignsTableComponent = {
  template,
  controller: EmailTemplateDesignsTableController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};
