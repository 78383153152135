import template from './contacts-stats.html';
import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import CustomerStats from '~/source/contact/common/services/stats.service';

class ContactsStatsController extends BaseController {
  summary;

  /*@ngInject*/
  constructor(readonly $scope: IScope, readonly stats: () => CustomerStats) {
    super();
  }

  $onInit() {
    this.summary = (this.stats()
      .setConfig({ blockUiRef: 'customersSummary' })
      .getOneWithQuery('customers') as any).$object;
  }
}

export default {
  template,
  controller: ContactsStatsController,
  controllerAs: 'vm',
};
