import template from './brand-inactivity-fee-list.component.html';
const styles = require('./brand-inactivity-fee-list.component.scss');

import ng from 'angular';

import { InactivityFeeSetting, Brand } from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { BrandsService } from '~/source/management/brand/services/brands';
import { useStreams } from '@proftit/rxjs.adjunct';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { INACTIVITY_FEE_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { CrudOperation } from '@proftit/request-client';

export class BrandInactivityFeeListController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  fees$ = new rx.BehaviorSubject<InactivityFeeSetting[]>([]);
  brand$ = new rx.BehaviorSubject<Brand>(null);
  opChangeFeeCrudAction$ = new rx.Subject<CrudOperation>();
  inProcess$ = new rx.BehaviorSubject<boolean>(false);
  opGetFeeList$ = new rx.Subject<void>();

  brand: Brand;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams(
      [
        this.streamCalcFeesList(),
        this.streamRefreshListOnAdded(),
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
        rx.pipe(
          () => this.lifecycles.onInit$,
          rx.tap(() => this.opGetFeeList$.next()),
        )(null),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCalcFeesList() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.opGetFeeList$, this.brand$),
      rx.tap(() => this.inProcess$.next(true)),
      rx.switchMap(([a, brand]) => {
        if (_.isNil(brand)) {
          return rx.obs.from([[]]);
        }
        return rx.obs.from(this.getBrandFees(brand));
      }),
      rx.tap(() => this.inProcess$.next(false)),
      rx.tap((fees) => this.fees$.next(fees)),
    )(null);
  }

  streamRefreshListOnAdded() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key, payload }) => key === INACTIVITY_FEE_ADDED),
      rx.tap(() => this.opGetFeeList$.next()),
    )(null);
  }

  getBrandFees(brand: Brand) {
    return this.brandsService()
      .getInactivityFeeSettingsResource(brand.id)
      .getListWithQuery<IElementRestNg<InactivityFeeSetting>>()
      .then((data) => data.plain());
  }
}

export const BrandInactivityFeeListComponent = {
  template,
  controller: BrandInactivityFeeListController,
  bindings: {
    brand: '<',
  },
};
