/**
 * Wrap angular permissions validate promise and return its
 * results as resolve true/false instead of resolve/reject.
 *
 * @param {object} permDef - angular-permission permission definition.
 * @returns {Promise} promise that resolve true on valid permission or
 *   false on invalid permission.
 */
export function wrapNgPermissionValidatePromise(permDef): Promise<boolean> {
  return new Promise((resolve) => {
    permDef
      .validatePermission()
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
}

export default wrapNgPermissionValidatePromise;
