import * as _ from '@proftit/lodash';
import ApiUserAbstractManagerController from '../abstractManager';
import ManagementBrandCampaignFormFormatter from '../../services/brand-campaign-form-formatter';
import ManagementIpRangesFormFormatter from '../../services/ip-ranges-form-formatter';
import ModelNormalizerService from '~/source/common/services/model-normalizer';

import template from './api-user-update.component.html';

class ApiUserUpdateController extends ApiUserAbstractManagerController {
  static $inject = [
    'usersService',
    '$state',
    'modelNormalizer',
    'managementBrandCampaignFormFormatter',
    'managementIpRangesFormFormatter',
    'brandsService',
  ];

  managementBrandCampaignFormFormatter: () => ManagementBrandCampaignFormFormatter;
  managementIpRangesFormFormatter: () => ManagementIpRangesFormFormatter;
  modelNormalizer: ModelNormalizerService;

  ipRangesFormFormatterInst: ManagementIpRangesFormFormatter;

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      newBrands: [],
      brandCampaignFormFormatterInst: this.managementBrandCampaignFormFormatter(),
      ipRangesFormFormatterInst: this.managementIpRangesFormFormatter(),
    });

    this.fetchUserById(); // get user data by id
  }

  /**
   * Toggle user activity flag
   *
   * @param {boolean} status
   */
  toggleUserActivity(status) {
    this.apiUserModel.patch({
      isActive: status,
    });
  }

  /**
   * get user data by id
   */
  fetchUserById() {
    this.usersServiceInst
      .expand(['userGroup'])
      .embed(['brands', 'whitelistIps', 'brands.campaigns'])
      .getOneWithQuery(this.$state.params.userId)
      .then((data: any) => {
        this.apiUserModel = data;
      });
  }

  /**
   * patch user
   *
   * this query will be called on onUserFormValid function
   * which is inherited from parent class
   *
   * @returns {*}
   */
  queryUser() {
    const formattedIpRangesUser = this.ipRangesFormFormatterInst.format(
      this.apiUserModel,
    );

    // id is omitted because of normalizer
    const normalizedUser = this.modelNormalizer.normalize(
      _.omit(['id', 'brands', 'roleId'], formattedIpRangesUser),
    );
    /*
     * this method is used only for updating user properties therefore
     * update user
     */
    return this.apiUserModel.patch(normalizedUser);
  }

  /**
   * Remove brand-campaign connection from resource and view
   *
   * @param {int} brandId - brand to remove
   */
  removeBrandCampaign(brandId: number) {
    // Remove from server, then remove the element from view
    this.usersServiceInst
      .removeBrandConnection(this.apiUserModel.id, brandId)
      .then(() => {
        const { brands } = this.apiUserModel;
        const index = brands.findIndex((b) => b.id === brandId);
        brands.splice(index, 1);
      });
  }
}

const ApiUserUpdateComponent = {
  template,
  controller: ApiUserUpdateController,
  controllerAs: 'vm',
};

export default ApiUserUpdateComponent;
