import Component from '~/source/common/components/dropdowns/base/component';

class TradeReputationTypesController extends Component.controller {
  static $inject = [
    'tradeReputationTypesService',
    ...Component.controller.$inject,
  ];

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'contact.reputations';
  }

  /**
   * Used if select is nullable, returns nullable entity
   *
   * @returns {Array}
   */
  get nullableElement() {
    return {
      id: null,
      code: 'null',
    };
  }
}

export default Component.config({ controller: TradeReputationTypesController });
