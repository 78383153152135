import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import Component from '~/source/common/components/dropdowns/base/component';
import { useStreams } from '@proftit/rxjs.adjunct';
import type { IHttpService, IPromise } from 'angular';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { PlatfromGroupsService } from '../../services/platform-groups.service';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CurrentTrcGroupsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-groups-of-platform-service.directive';

class Controller extends Component.controller {
  static $inject = [
    'prfPlatformGroupsService',
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    ...Component.controller.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentTrcGroupsOfPlatform: CurrentTrcGroupsOfPlatformServiceDirectiveController;

  /* injected */

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfPlatformGroupsService: PlatfromGroupsService;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];

    useStreams([this.streamData()], this.lifecycles.onDestroy$);
  }

  streamData() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.tap((session) => {
        this._data = [];

        if (!session.isLoggedIn) {
          return;
        }

        this.fetchData();
      }),
    )(null);
  }

  query() {
    return rx
      .pipe(
        () => rx.obs.of(true),
        rx.tap(() => this.startBlockUi()),
        rx.switchMap(
          () => this.prfCurrentTrcGroupsOfPlatform.generalGroupsS.stream$,
        ),
        rx.finalize(() => {
          this.stopBlockUi();
        }),
        rx.take(1),
      )(null)
      .toPromise() as any;
  }

  startBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.getBlockUiRef());

    if (_.isNil(blockUiInstance)) {
      return null;
    }

    blockUiInstance.start();
    return null;
  }

  stopBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.getBlockUiRef());
    if (_.isNil(blockUiInstance)) {
      return;
    }

    blockUiInstance.stop();
    return null;
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Groups',
    };
  }

  get defaultPlaceholder() {
    return 'SELECT_GROUP';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const CfdPlatformGroupNameSelectComponent = Component.config({
  controller: Controller,
  require: {
    prfCurrentPlatformSession: '^',
    prfCurrentTrcGroupsOfPlatform: '^',
  },
});
