import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { UserDepartmentCodeCode } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import moment from 'moment';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import template from './auto-assignment-panel-dashboard.component.html';
import UserSettings from '~/source/common/services/user-settings';
import { CurrentBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-brand-store-service.directive';
import { generateAutoAssignmentPanelForm } from './generate-auto-assignment-panel-form';
import { BrandsStoreService } from '~/source/common/store-services/brands-store.service';
import {
  Desk,
  Brand,
  UserDepartmentCode,
  AssignmentCounts,
  UserPerformanceDepartment,
} from '@proftit/crm.api.models.entities';
import {
  useStreams,
  shareReplayRefOne,
  distinctUntilChangedWithState,
  tapLog,
} from '@proftit/rxjs.adjunct';
import { observeObjectProxyFieldValue } from '@proftit/proxy-bean-rxjs';
import {
  SimpleAllabeMenuItem,
  SimpleAllabeMenuItemType,
} from './simple-allable-menu-item';
import { AssignmentFilter } from './assignment-filter';
import { DateRange } from './date-range';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { CurrentDepartmentsStoreServiceDirectiveController } from '~/source/common/service-directives/current-departments-store-service.directive';
import {
  addHours,
  endOfDay,
  startOfDay,
  subMonths,
  subSeconds,
} from 'date-fns';
const styles = require('./auto-assignment-panel-dashboard.component.scss');

const allBrandOption = generateAllBrandOption();
const allDesksOption = generateAllDeskOption();

const assignmentFilterNew: SimpleAllabeMenuItem<AssignmentFilter> = {
  label: 'New',
  type: SimpleAllabeMenuItemType.Specific,
  value: AssignmentFilter.New,
};
const assignmentFilterTotal: SimpleAllabeMenuItem<AssignmentFilter> = {
  label: 'Total',
  type: SimpleAllabeMenuItemType.Specific,
  value: AssignmentFilter.Total,
};

const assignmentFilters: SimpleAllabeMenuItem<AssignmentFilter>[] = [
  assignmentFilterNew,
  assignmentFilterTotal,
];

const pageSize = 10;
const searchTermDebounceTime = 1000;

const USER_SETTING_ASSIGNMENT_PANNEL_KEY = 'user_setting_assignment_pannel_key';

export class AutoAssignmentPanelDashboardController {
  /* require */

  prfCurrentBrand: CurrentBrandStoreServiceDirectiveController;

  prfCurrentDepartments: CurrentDepartmentsStoreServiceDirectiveController;

  /* state */

  styles = styles;

  selectedDateRangeSettings = {
    minDate: subMonths(new Date(), 6),
    maxDate: new Date(),
  };

  assignmentFilters = assignmentFilters;

  AssignmentFilter = AssignmentFilter;

  lifecycles = observeComponentLifecycles(this);

  openLogsPopupWithPreFiltersOp$ = new rx.Subject<{
    assignment: AssignmentCounts;
    assignmentFilter: AssignmentFilter;
  }>();

  mainForm = generateAutoAssignmentPanelForm(
    allBrandOption,
    null,
    allDesksOption,
    assignmentFilterNew,
    {
      startDate: moment(addHours(startOfDay(new Date()), 3)),
      endDate: moment(endOfDay(new Date())),
    },
    '',
  );

  brandsOptions$ = this.streamBrandsOptions();

  departmentsOptions$ = this.streamDepartmentsOptions();

  desksOptions$ = this.streamDesksOptions();

  dateRange$ = observeObjectProxyFieldValue<DateRange>(
    this.mainForm,
    'dateRange',
  ).pipe(
    rx.map((dateRangeProxy) => {
      const startDate = convertToTimeZoneTime(
        dateRangeProxy.startDate.toDate(),
      );

      const endDate = convertToTimeZoneTime(dateRangeProxy.endDate.toDate());

      return {
        startDate,
        endDate,
      };
    }),
    rx.startWith({
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
    }),
    shareReplayRefOne(),
  );

  whenAssignmentClick = (
    assignment: UserPerformanceDepartment,
    column: string,
  ) => {
    this.openLogsPopupWithPreFiltersOp$.next({
      assignment,
      assignmentFilter:
        column === Columns.FreshAssignmentCount
          ? AssignmentFilter.New
          : AssignmentFilter.Total,
    });
  };

  /* @ngInject */
  constructor(
    readonly prfBrandsStore: BrandsStoreService,
    readonly popupService: PopupService,
    readonly userSettingsService: UserSettings,
  ) {}

  $onInit() {
    useStreams(
      [
        this.streamMainFormMutable(),
        this.streamSyncSelectedBrandToCurrent(),
        this.streamSaveSelectedFiltersToLocalStorage(),
        this.streamOpenLogsWithFilters(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamBrandsOptions(): rx.Observable<SimpleAllabeMenuItem<Brand>[]> {
    return rx.pipe(
      () => this.prfBrandsStore.brandsS.stream$,
      rx.map((brands) => brands.map((brand) => generateBrandOption(brand))),
      rx.map((brandOptions) => [allBrandOption, ...brandOptions]),
      shareReplayRefOne(),
    )(null);
  }

  streamDepartmentsOptions(): rx.Observable<
    SimpleAllabeMenuItem<UserDepartmentCode>[]
  > {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((isInit) => isInit)),
      rx.switchMap(() => this.prfCurrentDepartments.departments$),
      rx.map((depts) => depts.map((dep) => generateDepartmentOption(dep))),
      rx.map((depsOptions) => [...depsOptions]),
      shareReplayRefOne(),
    )(null);
  }

  streamMainFormMutableFromBrandChange() {
    const streamFn = rx.pipe(
      () => observeObjectProxyFieldValue<Brand>(this.mainForm, 'brandOption'),
      rx.filter((brandOption) => brandOption === allBrandOption),
      rx.tap(() => {
        this.mainForm.deskOption = allDesksOption;
      }),
    );

    return streamFn(null);
  }

  streamMainFormMutableFromInitData() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest({
          brands: this.brandsOptions$,
          departments: this.departmentsOptions$,
          savedSettings: this.userSettingsService.getSettingWithoutDefault(
            USER_SETTING_ASSIGNMENT_PANNEL_KEY,
          ),
        }),
      rx.tap(({ brands, departments, savedSettings }) => {
        if (_.isNil(savedSettings)) {
          const defaultDepartment = departments.find(
            (dep) => dep.value.code === UserDepartmentCodeCode.SalesConversion,
          );
          this.mainForm.departmentOption = defaultDepartment;
          return;
        }

        const selectedDepartment = departments.find(
          (dep) => dep.value.id === savedSettings.value.data.departmentId,
        );
        const selectedBrand = savedSettings.value.data.brandId
          ? brands.find(
              (brand) => brand.value?.id === savedSettings.value.data.brandId,
            )
          : allBrandOption;

        this.mainForm.departmentOption = selectedDepartment;
        this.mainForm.brandOption = selectedBrand;
      }),
    )(null);
  }

  streamMainFormMutable() {
    const streamFn = rx.pipe(() =>
      rx.obs.merge(
        this.streamMainFormMutableFromBrandChange(),
        this.streamMainFormMutableFromInitData(),
      ),
    );

    return streamFn(null);
  }

  streamSyncSelectedBrandToCurrent() {
    return rx.pipe(
      () =>
        observeObjectProxyFieldValue<SimpleAllabeMenuItem<Brand>>(
          this.mainForm,
          'brandOption',
        ),
      rx.tap((brandOption) => {
        if (_.isNil(brandOption)) {
          return;
        }

        if (brandOption.type === SimpleAllabeMenuItemType.All) {
          return;
        }

        this.prfCurrentBrand.loadBrand(brandOption.value.id);
      }),
    )(null);
  }

  streamSaveSelectedFiltersToLocalStorage() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest({
          brand: observeObjectProxyFieldValue<SimpleAllabeMenuItem<Brand>>(
            this.mainForm,
            'brandOption',
          ).pipe(
            rx.startWith(this.mainForm.brandOption),
            rx.distinctUntilChanged(),
          ),
          department: observeObjectProxyFieldValue<
            SimpleAllabeMenuItem<UserDepartmentCode>
          >(this.mainForm, 'departmentOption').pipe(
            rx.startWith(this.mainForm.departmentOption),
            rx.distinctUntilChanged(),
          ),
        }),
      rx.filter(({ brand, department }) => {
        return ![brand, department].some(_.isNil);
      }),
      rx.switchMap(({ brand, department }) => {
        const payload = {
          brandId: brand.value?.id,
          departmentId: department.value.id,
        };

        return this.userSettingsService
          .getSetting(USER_SETTING_ASSIGNMENT_PANNEL_KEY, payload)
          .then((data) =>
            this.userSettingsService.setSettingValue(data.id, {
              version: 2,
              data: payload,
            }),
          );
      }),
    )(null);
  }

  streamOpenLogsWithFilters() {
    return rx.pipe(
      () => this.openLogsPopupWithPreFiltersOp$,
      rx.withLatestFrom(
        observeObjectProxyFieldValue<SimpleAllabeMenuItem<Brand>>(
          this.mainForm,
          'brandOption',
        ).pipe(rx.startWith(this.mainForm.brandOption)),
        observeObjectProxyFieldValue<SimpleAllabeMenuItem<UserDepartmentCode>>(
          this.mainForm,
          'departmentOption',
        ).pipe(rx.startWith(this.mainForm.departmentOption)),
        observeObjectProxyFieldValue<SimpleAllabeMenuItem<Desk>>(
          this.mainForm,
          'deskOption',
        ).pipe(rx.startWith(this.mainForm.deskOption)),
        observeObjectProxyFieldValue<DateRange>(
          this.mainForm,
          'dateRange',
        ).pipe(rx.startWith(this.mainForm.dateRange)),
      ),
      rx.tap(
        ([
          { assignment, assignmentFilter },
          brandOp,
          departmentOp,
          deskOp,
          dateRange,
        ]) => {
          this.popupService.open({
            component: 'prfCustomerAssignmentLogsTablePopup',
            resolve: {
              brand: () => brandOp.value,
              department: () => departmentOp.value,
              desk: () => deskOp.value,
              dateRange: () => ({ ...dateRange }),
              assignmentCounts: () => assignment,
              assigmentFilter: () => assignmentFilter,
            },
          });
        },
      ),
    )(null);
  }

  streamDesksOptions() {
    return rx.pipe(
      () =>
        observeObjectProxyFieldValue<SimpleAllabeMenuItem<Brand>>(
          this.mainForm,
          'brandOption',
        ).pipe(rx.startWith(this.mainForm.brandOption)),
      rx.switchMap((brandOption) => {
        if (_.isNil(brandOption)) {
          return rx.obs.of([allDesksOption]);
        }

        if (brandOption.type === SimpleAllabeMenuItemType.All) {
          return rx.obs.of([allDesksOption]);
        }

        return this.prfCurrentBrand.desks$.pipe(
          rx.map((desks) => desks.map((desk) => generateDeskOption(desk))),
          rx.map((options) => [allDesksOption, ...options]),
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  hasUserPic(assigment) {
    if (!_.isNil(assigment.resizeUrl)) {
      return true;
    }

    if (!_.isNil(assigment.url)) {
      return true;
    }

    return false;
  }

  calcUserPic(assigment) {
    if (assigment.resizeUrl) {
      return assigment.resizeUrl;
    }

    if (assigment.url) {
      return assigment.url;
    }

    return '/assets/img/img-src/brand-logo-ph.png';
  }

  openLogsPopup() {
    this.popupService.open({
      component: 'prfCustomerAssignmentLogsTablePopup',
      resolve: {},
    });
  }
}

function generateBrandOption(brand: Brand): SimpleAllabeMenuItem<Brand> {
  return {
    value: brand,
    label: brand.name,
    type: SimpleAllabeMenuItemType.Specific,
  };
}

function generateDepartmentOption(
  department: UserDepartmentCode,
): SimpleAllabeMenuItem<UserDepartmentCode> {
  return {
    value: department,
    label: department.name,
    type: SimpleAllabeMenuItemType.Specific,
  };
}

function generateAllBrandOption(): SimpleAllabeMenuItem<Brand> {
  return {
    value: null,
    label: 'All brands',
    type: SimpleAllabeMenuItemType.All,
  };
}

function generateAllDepartmentOption(): SimpleAllabeMenuItem<
  UserDepartmentCode
> {
  return {
    value: null,
    label: 'All departments',
    type: SimpleAllabeMenuItemType.All,
  };
}

function generateDeskOption(desk: Desk): SimpleAllabeMenuItem<Desk> {
  return {
    value: desk,
    label: desk.name,
    type: SimpleAllabeMenuItemType.Specific,
  };
}

function generateAllDeskOption(): SimpleAllabeMenuItem<Desk> {
  return {
    value: null,
    label: 'All Desks',
    type: SimpleAllabeMenuItemType.All,
  };
}

export const AutoAssignmentPanelDashboardComponent = {
  template,
  controller: AutoAssignmentPanelDashboardController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
    prfCurrentBrand: '^',
    prfCurrentDepartments: '^',
  },
};

enum Columns {
  FreshAssignmentCount = 'freshAssignmentCount',
}

function convertToTimeZoneTime(date: Date) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneOffet = getTimezoneOffset(timeZone, date);
  const resultDate = subSeconds(date, timeZoneOffet / 1000);

  return resultDate;
}
