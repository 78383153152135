// This file was generated automatically. Tread lightly.

export enum MigratedStatusCode {
  CallAgain = 'callAgain',
  CheckNumber = 'checkNumber',
  InTheMoney = 'inTheMoney',
  New = 'new',
  NoAnswer = 'noAnswer',
  NoCall = 'noCall',
  NotInterested = 'notInterested',
  Reassign = 'reassign',
}
