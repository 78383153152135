import * as _ from '@proftit/lodash';
/**
 * Table Controller.
 * we use ng-table in the project, this controller initialize ng-table params
 */

import log from 'loglevel';
import Promise from 'bluebird';

import TableController from './table.controller';
import SocketService from '~/source/common/services/socket';
import TokensService from '~/source/auth/services/tokens';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { UserTokenModel } from '../../models/user-token-model';
import RestService, {
  IRestServiceInstance,
} from '~/source/common/services/rest';
import * as rx from '@proftit/rxjs';

export interface SocketOperation {
  listenTo: (data) => void;
  stopListening: (data) => void;
}

export abstract class TableLiveController<
  T extends IRestServiceInstance = IRestServiceInstance
> extends TableController<T> {
  static $inject = [
    'tokensService',
    'highlightEntityService',
    ...TableController.$inject,
  ];

  tokensService: TokensService;
  cachedUser: UserTokenModel;
  highlightEntityService: any; // add type once this model is converted to TS
  propertiesToUpdate$ = new rx.Subject();

  // These are implemented as getters by the subclasses
  /**
   * A variable name that should exist in the scope, so we could listen for updates
   */
  get liveEntitiesVarName(): string {
    return null;
  }

  /**
   * A collection that hold all the entities in the table.
   * Set by the subclass.
   */
  abstract get entitiesContainer(): Restangular.ICollection;

  /**
   * Socket service instance. Set by the subclass
   */
  abstract get socketService(): SocketService;

  /**
   * Array of notification ids to exclude from updates
   */
  excludeNotifications: number[];

  onEntityUpdateBinded: Function;

  socketsOperations: SocketOperation[] = [];

  constructor(...args) {
    super(...args);

    this.cachedUser = this.tokensService.getCachedUser();
  }

  $onInit() {
    super.$onInit();

    // listen to page change
    this.$scope.$watch(
      this.liveEntitiesVarName,
      (
        subscribeToEntities: Restangular.ICollection,
        unsubscribeFromEntities: Restangular.ICollection,
      ) => this.onPageChanged(subscribeToEntities, unsubscribeFromEntities),
    );
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId: number): string {
    return `user.${this.cachedUser.id}.${this.socketService.channelRoot}.${elementId}`;
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   *
   * @returns {boolean}
   */
  isUpdateNotification(): boolean {
    return true;
  }

  /**
   * Subscribe for updates
   *
   * @param {Restangular.ICollection} data
   */
  listenTo(data: Restangular.ICollection): void {
    this.operateStreamer(data, 'subscribe');

    this.socketsOperations.forEach((socketOperation) => {
      socketOperation.listenTo(data);
    });
  }

  /**
   * Unsubscribe from updates
   *
   * @param {Restangular.ICollection} data
   */
  stopListening(data: Restangular.ICollection) {
    this.operateStreamer(data, 'unsubscribe');

    this.socketsOperations.forEach((socketOperation) => {
      socketOperation.stopListening(data);
    });
  }

  /**
   * When scope is destroyed we need to unsubscribe from updates
   */
  $onDestroy(): void {
    if (this.entitiesContainer && this.isStreamingOn()) {
      // unsubscribe entities if available
      this.stopListening(this.entitiesContainer);
    }
  }

  /**
   * Used by listenTo and stopListening methods
   * in order to listen to visible elements
   *
   * @param {Restangular.ICollection} data
   * @param {String} operation - subscribe/unsubscribe
   * @private
   */
  operateStreamer(data: Restangular.ICollection, operation: string) {
    if (_.isNil(this.onEntityUpdateBinded)) {
      this.onEntityUpdateBinded = this.socketService.wrapListener(
        this.onEntityUpdate.bind(this),
      );
    }

    // subscribe/unsubscribes all the elements in data
    data.forEach(({ id }) => {
      this.socketService[operation](
        this.buildChannel(id),
        this.onEntityUpdateBinded,
      );
    });
  }

  /*
   * Allow disable/enable streaming functionality without changing controller classes.
   * Can be override by child.
   */
  isStreamingOn() {
    return true;
  }

  /**
   * Called on page change
   *
   * @param {Restangular.ICollection} subscribeToEntities
   * @param {Restangular.ICollection} unsubscribeFromEntities
   */
  onPageChanged(
    subscribeToEntities: Restangular.ICollection,
    unsubscribeFromEntities: Restangular.ICollection,
  ): void {
    if (subscribeToEntities === undefined) {
      return;
    }

    if (this.isUpdateNotification()) {
      // notification directive want listen to updates of those elements
      this.excludeNotifications = subscribeToEntities.map(({ id }) => id);
    }

    if (this.isStreamingOn()) {
      // listen for updates on position that currently visible
      this.listenTo(subscribeToEntities);
    }

    if (unsubscribeFromEntities !== undefined) {
      // stop listening for positions that no longer visible
      this.stopListening(unsubscribeFromEntities);
    }
  }

  /**
   * Callback for updates on visible positions
   *
   * @param {object} propertiesToUpdate
   */
  onEntityUpdate(propertiesToUpdate: any) {
    const entityToUpdate = this.findEntityToUpdateFromStreamer(
      this.entitiesContainer,
      propertiesToUpdate,
    );

    if (!entityToUpdate) {
      log.warn('Got updated for non existing entity', propertiesToUpdate);
      return;
    }

    this.updateEntityFromStreamerNotification(
      entityToUpdate,
      propertiesToUpdate,
    );

    // highlight the update
    if (this.getDoEntityHighlightOnUpdate()) {
      this.highlightEntityService.highlight(entityToUpdate);
    }

    // notify subclasses entity was update
    this.onAfterEntityUpdate(entityToUpdate);
    this.propertiesToUpdate$.next(propertiesToUpdate);
  }

  /***
   * Is do entity highlight after socket update.
   *
   * Allow setting for inhariting components.
   *
   * @return setting.
   */
  getDoEntityHighlightOnUpdate() {
    return true;
  }

  findEntityToUpdateFromStreamer(entities, newData) {
    return this.entitiesContainer.find(({ id }) => id === newData.id);
  }

  updateEntityFromStreamerNotification(entity, newData) {
    Object.assign(entity, newData);
  }

  /**
   * Method to allow doing work after entity was updated from socket.
   *
   * @param {object} entity - updated entity
   * @return {void}
   */
  onAfterEntityUpdate(entity) {}

  /**
   * Add socket operation object to the list.
   *
   * Socket operations allowsmore generalize form of the table-live socket doing. Especially mutliple.
   * It was needed for mutliple socket operation per item for crm + mt4 open pnl extraction.
   *
   * @param op - socket operation to add
   */
  addSocketOperation(op: SocketOperation) {
    this.socketsOperations = [...this.socketsOperations, op];
  }
}

export default TableLiveController;
