import * as _ from '@proftit/lodash';

import switchOn from '~/source/common/utilities/switch-on';

/**
 *
 */
export function convertCustomerPropertyValueToString(valueParam, typeCode) {
  if (_.isNil(valueParam)) {
    return valueParam;
  }

  return switchOn(
    {
      bool: () => valueParam.toString(),
      text: () => valueParam,
      select: () => valueParam,
      multi_select: () => [...valueParam].join('<<delimiter>>'),
      date: () => valueParam,
    },
    typeCode,
    () => {
      throw new Error('unimplemented');
    },
  );
}

export default convertCustomerPropertyValueToString;
