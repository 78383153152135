config.$inject = ['screenSize'];
/**
 * angular-match-media configuration.
 * @see {@link https://github.com/jacopotarantino/angular-match-media}
 * @param {object} screenSize
 */
function config(screenSize) {
  // Add screen size rules
  screenSize.rules = {
    prfSM: '(max-width: 1699px)',
  };
}
export default config;
