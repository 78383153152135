import Fingerprint2 from 'fingerprintjs2';

const fingerprintOptions = {
  fonts: {
    extendJsFonts: false,
  },
  excludes: {
    fonts: true,
    fontsFlash: true,
    deviceMemory: true,
    enumerateDevices: true,
    availableScreenResolution: true,
  },
};

export interface AppTag {
  fingerprints: any;
  murmur: string;
}

/**
 * Get browser fingerprints info.
 */
export function getBrowserInfo(): Promise<AppTag> {
  return new Promise<AppTag>((resolve, reject) => {
    setTimeout(() => {
      Fingerprint2.getPromise(fingerprintOptions).then((fingerprints) => {
        const murmur = Fingerprint2.x64hash128(
          fingerprints.map((x) => x.value).join(''),
          31,
        );
        resolve({
          fingerprints,
          murmur,
        });
      });
    }, 500);
  });
}
