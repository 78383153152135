import template from './set-up-import-files-info.component.html';
const styles = require('./set-up-import-files-info.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class SetUpImportFilesInfoController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SetUpImportFilesInfoComponent = {
  template,
  controller: SetUpImportFilesInfoController,
};
