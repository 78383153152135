import BaseController from '~/source/common/controllers/base';
import { IScope, ITimeoutService } from 'angular';
import CustomerAllocationsService from '~/source/contact/common/services/customer-allocations.service';
import { Customer, Desk, User } from '@proftit/crm.api.models.entities';
import { CustomerStatus } from '~/source/common/models/customer-status';
import template from './contacts-assign.html';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import { BrandsService } from '~/source/management/brand/services/brands';
import { TokensService } from '~/source/auth/services/tokens';
import { UsersService } from '~/source/management/user/services/users';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { DeskedGroupedUsers } from '../models/desk-grouped-users';
import { SplitInfoUser } from '../models/split-info-user';
import * as rx from '@proftit/rxjs';
import { FormControl } from '@proftit/ng1.reactive-forms';

export interface ContactAssignFormError {
  errorMessage: string;
  errorType: string;
}

interface DeskToUnassignedModel {
  deskId: number;
  isUnassignSelectedFormControl: FormControl<boolean>;
}

function calcFlatlistUsers(deskedGroupedUsers: DeskedGroupedUsers) {
  return _.flow([
    (groupedUsers) => _.values(groupedUsers),
    (groupedUsersAsList) => _.flatten(groupedUsersAsList),
  ])(deskedGroupedUsers);
}

class ContactsAssignController extends BaseController {
  lifecycles = observeComponentLifecycles(this);

  // bindings
  brandId: number;
  customers: Customer[];
  isOpen: { status: boolean };
  assignLimit: number;
  totalRecords: number;
  tableParams: any;
  selectAllItems: boolean;

  selectedCustomersCount: number;
  selectedDesk: Desk = {} as any;
  selectedStatus: CustomerStatus = {} as any;
  invalidFormErrors: ContactAssignFormError[];
  customerAllocationsInstance: CustomerAllocationsService;

  existingDesks$ = new rx.BehaviorSubject<Desk[]>([]);
  selectedDesk$ = new rx.BehaviorSubject<Desk>(null);
  deskSelectedUsers$ = new rx.BehaviorSubject<SplitInfoUser[]>([]);
  allUsersOfSelectedDesk$ = new rx.BehaviorSubject<SplitInfoUser[]>([]);
  deskGroupedSelectedUsers$ = new rx.BehaviorSubject<DeskedGroupedUsers>({});
  opSelectUserInSelectedDesk$ = new rx.Subject<{
    user: SplitInfoUser;
    isSelected: boolean;
  }>();
  opUsersSplitsChanged$ = new rx.Subject<DeskedGroupedUsers>();
  opSelectAllDeskUsers$ = new rx.Subject<void>();
  onLockChangeAction = new rx.Subject<{ user: SplitInfoUser }>();
  desksUnassignSelectedModel$ = this.streamDesksUnassignSelectedModel();
  unassignSelectedForSelectedDesk$ = this.streamUnassignSelectedForSelectedDesk();

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customerAllocationsService: CustomerAllocationsService,
    readonly $timeout: ITimeoutService,
    readonly brandsService: () => BrandsService,
    readonly usersService: () => UsersService,
  ) {
    super();
    this.customerAllocationsInstance = customerAllocationsService;

    useStreams(
      [
        this.streamGetExistingDesks(),
        this.streamSelectDefaultDeskOnStart(),
        this.streamCalcDeskSelectedUsers(),
        this.streamFetchAllUsersOfSelectedDesk(),
        this.streamSelectUsersInDesks(),
        this.streamSelectAllUsersInDesk(),
        this.streamChangeUsersSplits(),
        this.streamDeskGroupedSelectedUsersFromLockChange(),
        this.streamSelectedDeskUsersToUnassign(),
        this.desksUnassignSelectedModel$,
        this.unassignSelectedForSelectedDesk$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onChanges() {
    // the real number of selected customer
    this.selectedCustomersCount = this.selectAllItems
      ? this.assignLimit
      : this.customers.length;
  }

  $onDestroy() {}

  streamDesksUnassignSelectedModel(): rx.Observable<DeskToUnassignedModel[]> {
    return rx.pipe(
      () => this.existingDesks$,
      rx.filter(
        (existingDesks) => !_.isNil(existingDesks) && existingDesks.length > 0,
      ),
      rx.map((desks) =>
        desks.map((desk) => {
          return {
            deskId: desk.id,
            isUnassignSelectedFormControl: new FormControl<boolean>(false),
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamUnassignSelectedForSelectedDesk(): rx.Observable<FormControl<boolean>> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.selectedDesk$,
          this.desksUnassignSelectedModel$,
        ),
      rx.filter(
        ([selectedDesk, desksUnassignSelectedModel]) =>
          !_.isNil(selectedDesk) &&
          !_.isNil(desksUnassignSelectedModel) &&
          desksUnassignSelectedModel.length > 0,
      ),
      rx.map(([selectedDesk, desksUnassignSelectedModel]) => {
        return desksUnassignSelectedModel.find(
          (item) => item.deskId === selectedDesk.id,
        ).isUnassignSelectedFormControl;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamGetExistingDesks() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.switchMap(() => rx.obs.from(this.fetchExistingDesks())),
      rx.map((desks) => desks.sort((a, b) => (a.name < b.name ? -1 : 1))),
      rx.tap((desks) => this.existingDesks$.next(desks)),
    )(null);
  }

  streamSelectDefaultDeskOnStart() {
    return rx.pipe(
      () => this.existingDesks$,
      rx.map((desks) => desks[0]),
      rx.filter((desk) => !_.isNil(desk)),
      rx.tap((desk) => this.selectedDesk$.next(desk)),
    )(null);
  }

  streamCalcDeskSelectedUsers() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.selectedDesk$,
          this.deskGroupedSelectedUsers$,
        ),
      rx.map(([selectedDesk, desksUsers]) => {
        if (_.isNil(selectedDesk)) {
          return [];
        }

        return _.defaultTo([], desksUsers[selectedDesk.id]);
      }),
      rx.tap((users) => this.deskSelectedUsers$.next(users)),
    )(null);
  }

  streamDeskGroupedSelectedUsersFromLockChange() {
    return rx.pipe(
      () => this.onLockChangeAction,
      rx.withLatestFrom(this.deskGroupedSelectedUsers$),
      rx.map(([{ user }, oldUsers]) => {
        return _.mapValues((deskUsers) => {
          return deskUsers.map((deskUser) =>
            deskUser.id === user.id ? user : deskUser,
          );
        }, oldUsers);
      }),
      rx.tap((newUsers) => {
        this.deskGroupedSelectedUsers$.next(newUsers);
      }),
    )(null);
  }

  streamSelectedDeskUsersToUnassign() {
    return rx.pipe(
      () =>
        this.unassignSelectedForSelectedDesk$.pipe(
          rx.switchMap((formControl) => {
            return formControl.value$.pipe(
              rx.distinctUntilChanged(),
              /* 
                form control is behavior. always has first value. 
                distinctUntilChanged on first value always fire. 
                we need to skip the first emission that alway happens.
              */
              rx.skip(1),
            );
          }),
        ),
      rx.withLatestFrom(this.selectedDesk$, this.deskGroupedSelectedUsers$),
      rx.filter(
        ([isUnassignSelected, selectedDesk, deskGroupedSelectedUsers]) =>
          !_.isNil(selectedDesk),
      ),
      rx.tap(([isUnassignSelected, selectedDesk, deskGroupedSelectedUsers]) => {
        const copyDeskGroupedSelectedUsers = { ...deskGroupedSelectedUsers };
        delete copyDeskGroupedSelectedUsers[selectedDesk.id];

        const newDeskGroupedSelectedUsers = {
          ...copyDeskGroupedSelectedUsers,
        };

        if (isUnassignSelected) {
          const currentlyAllocatedCustomersAmount = Object.values(
            copyDeskGroupedSelectedUsers,
          ).reduce((acc, userArray) => {
            const allocatedCustomersInDesk = userArray.reduce(
              (currentDeskAcc, user) => {
                return currentDeskAcc + user.allocatedCustomers;
              },
              0,
            );
            return acc + allocatedCustomersInDesk;
          }, 0);
          const amountToAllocateToUnassign =
            this.customers.length - currentlyAllocatedCustomersAmount;

          newDeskGroupedSelectedUsers[selectedDesk.id] = [
            {
              id: 0,
              firstName: 'Unassign',
              allocatedCustomers: amountToAllocateToUnassign,
              isLocked: false,
            },
          ];
        }

        this.deskGroupedSelectedUsers$.next(newDeskGroupedSelectedUsers);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFetchAllUsersOfSelectedDesk() {
    return rx.pipe(
      () => this.selectedDesk$,
      rx.switchMap((desk) => {
        if (_.isNil(desk)) {
          return rx.obs.from([[]]);
        }

        return rx.obs.from(this.fetchUsersForDesk(this.brandId, desk.id));
      }),
      rx.tap((users) => this.allUsersOfSelectedDesk$.next(users)),
    )(null);
  }

  streamSelectUsersInDesks() {
    return rx.pipe(
      () => this.opSelectUserInSelectedDesk$,
      rx.withLatestFrom(this.selectedDesk$, this.deskGroupedSelectedUsers$),
      rx.map(
        ([{ user, isSelected }, selectedDesk, deskGroupedSelectedUsers]) => {
          const selectedDeskUsers = _.defaultTo(
            [],
            deskGroupedSelectedUsers[selectedDesk.id],
          );

          if (isSelected) {
            return {
              ...deskGroupedSelectedUsers,
              [selectedDesk.id]: _.unionBy(
                (a) => a.id,
                [user],
                selectedDeskUsers,
              ),
            };
          }

          return {
            ...deskGroupedSelectedUsers,
            [selectedDesk.id]: _.reject(
              (a) => a.id === user.id,
              selectedDeskUsers,
            ),
          };
        },
      ),
      rx.map((desksUsers) =>
        _.mapValues((users) => {
          return users.map((user) => ({
            ...user,
            isLocked: _.isNil(user.isLocked) ? false : user.isLocked,
            allocatedCustomers: user.isLocked ? user.allocatedCustomers : 0,
          }));
        }, desksUsers),
      ),
      rx.tap((desksUsers) => this.deskGroupedSelectedUsers$.next(desksUsers)),
    )(null);
  }

  streamSelectAllUsersInDesk() {
    return rx.pipe(
      () => this.opSelectAllDeskUsers$,
      rx.withLatestFrom(this.allUsersOfSelectedDesk$, this.deskSelectedUsers$),
      rx.map(
        ([a, deskUsers, deskSelectedUsers]) =>
          deskUsers.length === deskSelectedUsers.length,
      ),
      rx.withLatestFrom(this.allUsersOfSelectedDesk$, this.deskSelectedUsers$),
      rx.tap(([isAllSelected, allUsersOfSelectedDesk, deskSelectedUsers]) => {
        if (isAllSelected) {
          allUsersOfSelectedDesk.map((user) =>
            this.opSelectUserInSelectedDesk$.next({ user, isSelected: false }),
          );
          return;
        }

        const unselectedUsers = allUsersOfSelectedDesk.reduce((acc, user) => {
          if (
            deskSelectedUsers.find(
              (selectedUser) => selectedUser.id === user.id,
            )
          ) {
            return acc;
          }

          return [...acc, user];
        }, []);

        unselectedUsers.map((user) =>
          this.opSelectUserInSelectedDesk$.next({ user, isSelected: true }),
        );
      }),
    )(null);
  }

  streamChangeUsersSplits() {
    return rx.pipe(
      () => this.opUsersSplitsChanged$,
      rx.tap((deskedGroupedUsers) =>
        this.deskGroupedSelectedUsers$.next(deskedGroupedUsers),
      ),
    )(null);
  }

  fetchExistingDesks() {
    return this.brandsService()
      .setConfig({ blockUiRef: 'fetchDesks' }) // todoOld
      .getDesksResource(this.brandId)
      .getListWithQuery()
      .then((data) => data.plain());
  }

  fetchUsersForDesk(brandId: number, deskId: number) {
    const filters = {
      deskId: [deskId],
      brandId: [brandId],
      isActive: true,
      'role.code': {
        exclude: 'extapi',
      },
    };

    return this.usersService()
      .setConfig({ blockUiRef: 'fetchUsers' }) // todoOld
      .expand('role')
      .sort({ firstName: 'asc' })
      .filter(filters)
      .getListWithQuery<IElementRestNg<User>>()
      .then((data) => data.plain())
      .then((users) =>
        users.map((user) => ({
          ...user,
          fullname: `${user.firstName} ${user.lastName}`,
        })),
      );
  }

  /**
   * Assign customers to user
   * @returns {Promise}
   */
  assign() {
    if (!this.isFormValid()) {
      return;
    }

    // assign all customers
    if (this.selectAllItems) {
      return this.assignFilter();
    }

    const normalized = this.normalize();

    return this.customerAllocationsInstance
      .setConfig({ blockUiRef: 'assignToComponent' })
      .filter('brandId', this.brandId)
      .postWithQuery(normalized)
      .then(() => this.onPatchSuccess());
  }

  /**
   * normalize for assign action
   */
  normalize() {
    const userAllocations = {};
    let desksUsers;
    desksUsers = _.flow([
      (x) => _.toPairs(x),
      (x) => x.map(([id, users]) => ({ id, users })),
      (x) =>
        x.map((group) => ({
          ...group,
          users: _.flow([
            (users) => users.filter((user) => user.allocatedCustomers > 0),
            (users) =>
              users.reduce(
                (acc, user) => ({
                  ...acc,
                  [user.id]: user.allocatedCustomers,
                }),
                {},
              ),
          ])(group.users),
        })),
    ])(this.deskGroupedSelectedUsers$.getValue());

    const normalized = <any>{
      customers: this.customers,
      desks: desksUsers,
    };

    // add optional field
    if (this.selectedStatus && this.selectedStatus.id) {
      normalized.customerStatusId = this.selectedStatus.id;
    }

    return normalized;
  }

  /**
   * Assign all customers to user, there could be thousands of customers, so in this case we assign
   * customers to users using filter route
   * @returns {Promise}
   */
  assignFilter() {
    const normalized = this.normalizeFilter();

    return (
      this.customerAllocationsInstance
        .setConfig({ blockUiRef: 'assignToComponent' })
        /*
         * only assign customers matching the current filter & brandId
         * brandId don't exists in tableParams filters. because its added to current filter
         * (in parent controller) as a requiredApiFilters.
         */
        .filter({ ...this.tableParams.filter(), brandId: this.brandId })
        .sort(this.tableParams.sorting())
        /*
         * A limit must be sent to the server. Otherwise the default 1k server limit will be applied
         * We are using the "pagination" as "action limit" here. (which is why page is always 1)
         */
        .setPage(1, this.assignLimit)
        // patch with filter resource
        .postWithQuery(normalized)
        .then(() => this.onPatchSuccess())
    );
  }

  /**
   * normalize for assign filter action
   */
  normalizeFilter() {
    const normalized = this.normalize();

    // customers should be null in filter mode, and taken by filter on server side
    return { ...normalized, customers: null };
  }

  /**
   * called after user assign customers to user
   * close modal, emit event & assign selected values to bindings
   */
  onPatchSuccess() {
    this.isOpen.status = false;

    // cancel batch mode
    this.selectAllItems = false;

    this.$scope.$emit('contact:assignToUser:updated'); // let the world know what happened
  }

  /**
   * is the form valid or not
   * @returns {boolean} is this form valid or not
   */
  isFormValid() {
    this.invalidFormErrors = this.getInvalidFormErrors();
    return (
      this.invalidFormErrors.filter((error) => error.errorType === 'ERROR')
        .length === 0
    );
  }

  /**
   * return error message if exists
   * @returns {Object} error message object if exist
   */
  getInvalidFormErrors(): ContactAssignFormError[] {
    const errors = [];
    return errors;

    const selectedUsers = calcFlatlistUsers(
      this.deskGroupedSelectedUsers$.getValue(),
    );

    // number of selected customers should be greater than number of selected users
    if (this.selectedCustomersCount < selectedUsers.length) {
      errors.push({
        errorMessage:
          'contact.assignPopup.errors.NUMBER_OF_CONTACTS_MUST_EQUAL_OR_GREATER_THAN_NUMBER_OF_USERS',
        errorType: 'ERROR',
      });
    }

    // number of user allocations should be equal to number of selected customers
    const userAllocationsCount = selectedUsers.reduce(
      (sum, user) => sum + user.allocatedCustomers,
      0,
    );
    if (this.selectedCustomersCount !== userAllocationsCount) {
      errors.push({
        errorMessage:
          'contact.assignPopup.errors.ALL_SELECTED_CUSTOMERS_MUST_BE_ALLOCATED_TO_USERS',
        errorType: 'ERROR',
      });
    }

    return errors;
  }

  /**
   * cancel assign process
   */
  cancel() {
    this.isOpen.status = !this.isOpen.status;

    // cancel batch mode
    this.selectAllItems = false;
  }
}

export default {
  template,
  controller: ContactsAssignController,
  controllerAs: 'vm',
  bindings: {
    brandId: '<',
    customers: '<',
    isOpen: '<',
    assignLimit: '<',
    totalRecords: '<',
    tableParams: '<',
    selectAllItems: '<',
  },
};
