import angular from 'angular';
import moment from 'moment';
import MarketingCampaignController from '../../campaign.base.controller';
import template from './campaign-create.html';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';
import {
  Campaign,
  AutomaticFtdDepositReward,
  AutomaticNonFtdDepositReward,
  AutomaticRegistrationReward,
} from '@proftit/crm.api.models.entities';

class MarketingCreateCampaignController extends MarketingCampaignController {
  static $inject = ['$translate', ...MarketingCampaignController.$inject];

  $translate: angular.translate.ITranslateService;

  campaign: Partial<Campaign>;
  rewards = {
    automaticRegistrationRewards: [],
    automaticDepositRewardsFtd: [],
    automaticDepositRewardsNonFtd: [],
  };

  $onInit() {
    Promise.resolve(this.initializeEmptyCampaign())
      .then((emptyCampaign) => {
        if (this.$state.params.campaignId) {
          return this.makeCopyFromSourceCampaign(
            this.$state.params.campaignId,
          ).then((campaignCopy) => ({ ...emptyCampaign, ...campaignCopy }));
        }

        return emptyCampaign;
      })
      .then((campaign) => {
        this.campaign = campaign;
      });
  }

  initializeEmptyCampaign(): Partial<Campaign> {
    return {
      brandTrackerParams: [],
      trackers: {},
      trackerParams: [],
      currencyConnections: [],
      blacklistedCountries: [],
      isEndless: false,
      fromDate: null,
      tillDate: null,
      automaticRegistrationRewards: [],
      automaticDepositRewardsFtd: [],
      automaticDepositRewardsNonFtd: [],
      shouldAssignToRegistrationDesk: false,
    };
  }

  updateAutomaticRegistrationBonuses({ bonusesData }) {
    this.rewards.automaticRegistrationRewards = [...bonusesData];
  }

  updateAutomaticDepositBonusesFtd({ bonusesData }) {
    this.rewards.automaticDepositRewardsFtd = [...bonusesData];
  }

  updateAutomaticDepositBonusesNonFtd({ bonusesData }) {
    this.rewards.automaticDepositRewardsNonFtd = [...bonusesData];
  }

  makeCopyFromSourceCampaign(campaignId: number): Promise<Partial<Campaign>> {
    return Promise.all([
      this.fetchOrigCampaign(campaignId),
      this.fetchCopyTranslations(),
    ]).then(([origCampaign, translations]) => {
      const name = `${translations['marketing.campaign.COPIED_CAMPAIGN_NAME_ATTACH']} ${origCampaign.name}`;
      const isEndless = !moment(origCampaign.tillDate).isValid();
      const brandTrackerParams = origCampaign.brandTrackerParams.map((param) =>
        _.omit(['id'], param),
      );
      const trackerParams = origCampaign.trackerParams.map((param) =>
        _.omit(['id'], param),
      );
      const currencyConnections = origCampaign.currencyConnections.map((conn) =>
        _.omit(['id'], conn),
      );
      const trackers = origCampaign.trackers.map((conn) =>
        _.omit(['id'], conn),
      );

      const copiedParams = _.omit(
        [
          'brandTrackerParams',
          'currencyConnections',
          'id',
          'name',
          'trackerParams',
          'trackers',
        ],
        origCampaign,
      );

      return {
        ...copiedParams,
        name,
        isEndless,
        brandTrackerParams,
        trackerParams,
        currencyConnections,
        trackers,
      };
    });
  }

  fetchOrigCampaign(campaignId) {
    return this.marketingCampaignsServiceInst
      .expand([
        'brand',
        'brand.platformConnections.platform',
        'currencyConnections.currency',
        'desk',
        'pricingModel',
        'platform',
        'language',
        'campaignStatus',
        'user',
      ])
      .embed([
        'brand.platformConnections',
        'trackerParams',
        'brandTrackerParams',
        'trackers',
        'blacklistedCountries',
        'currencyConnections',
        'automaticDepositRewardsNonFtd',
        'automaticDepositRewardsFtd',
        'automaticRegistrationRewards',
      ])
      .getOneWithQuery<IElementRestNg<Campaign>>(campaignId)
      .then((data) => data.plain());
  }

  fetchCopyTranslations() {
    return this.$translate(['marketing.campaign.COPIED_CAMPAIGN_NAME_ATTACH']);
  }

  get brandTrackersQty() {
    return this.settings.form.elasticFields.brandTrackerParams.initialQty;
  }

  fetchFn(): any {
    throw new Error('Should not fetch data on create controller');
  }

  /**
   * Called when form is valid
   * Here we are sending the request to server
   */
  onValidated() {
    const normalizedData = this.modelNormalizer.normalize(this.campaign);
    const filteredRewards = this.filterRewards(this.rewards);
    const dataToSend = {
      ...normalizedData,
      ...filteredRewards,
    };
    // normalize the form data and make ready for submission
    this.marketingCampaignsServiceInst
      .setConfig({ blockUiRef: 'campaignSettingsForm' })
      .postWithQuery(dataToSend)
      .then((resp) => this.generateCode(resp))
      .then((popup) => {
        // popup closed pass user to marketing dashboard
        popup.closed.then(() => {
          // once the modal is closed, go to dashboard page
          this.$state.go(this.settings.form.states.onSuccess);
        });
      });
  }

  /**
   * Returns a subset of rewards. It will return data only for reward types that have at least one valid reward for that type (only non empty rewards).
   * @param data - All the reward types with their data to check.
   */
  filterRewards(data: {
    automaticRegistrationRewards: AutomaticRegistrationReward[];
    automaticDepositRewardsFtd: AutomaticFtdDepositReward[];
    automaticDepositRewardsNonFtd: AutomaticNonFtdDepositReward[];
  }) {
    const cloneData = { ...data };

    const objToReturn = Object.keys(cloneData).reduce((acc, key) => {
      const rewardsForKey = cloneData[key].filter(
        (reward) => !this.areRewardValuesEmpty(reward),
      );

      if (!_.isEmpty(rewardsForKey)) {
        return {
          ...acc,
          [key]: rewardsForKey,
        };
      }
      return acc;
    }, {});
    return objToReturn;
  }

  /**
   * Returns true if all the reward values are filled, false otherwise.
   * @param reward The reward object to check.
   */
  areRewardValuesEmpty(
    reward:
      | AutomaticRegistrationReward
      | AutomaticFtdDepositReward
      | AutomaticNonFtdDepositReward,
  ): boolean {
    const { currencyId, ...rest } = reward;
    const keyValuePairs = _.toPairs(rest);
    return !keyValuePairs.every(([key, value]) => !_.isNil(value));
  }
}

export default {
  template,
  controller: MarketingCreateCampaignController,
  controllerAs: 'vm',
};
