import template from './brand-demo-deposit-automation-item.component.html';
const styles = require('./brand-demo-deposit-automation-item.component.scss');

import ng from 'angular';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandsService } from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { Changeset } from '~/source/common/utilities/changeset/changeset';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { IPromise } from 'restangular';
import { requestWithComponent } from '~/source/common/utilities/request-with-component';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';

export class BrandDemoDepositAutomationItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  growlId = generateGrowlId();
  blockUiId = generateBlockuiId();

  currencyConnectionsChangeset$ = new rx.BehaviorSubject<any>([]);
  brandIsDemoDepositActiveChanges$ = new rx.BehaviorSubject<Brand>(null);

  opSave$ = new rx.Subject<void>();
  opSaveDemoDepositAmounts$ = new rx.Subject<void>();
  opActivateEdit$ = new rx.Subject<void>();
  opCancelEdit$ = new rx.Subject<void>();
  opCloseEdit$ = new rx.Subject<void>();

  isEnabledDemoDepositActiveValid$ = new rx.BehaviorSubject<boolean>(false);
  isValidCurrencyConnections$ = new rx.BehaviorSubject<boolean>(false);
  opBrandIdFromSave$ = new rx.Subject<number>();
  brandIdInput$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'brandId',
  );
  brandId$ = this.streamBrandId();
  brand$ = this.streamBrand();
  brandIsDemoDepositActive$ = this.streamBrandIsDemoDepositActive();
  currencyConnections$ = this.streamCurrencyConnections();
  isActiveEdit$ = this.streamIsActiveEdit();
  isDemoDepositSaveEnabled$ = this.streamIsDemoDepositSaveEnabled();
  showEditActionLink$ = this.streamShowEditActionLink();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [this.brandIdInput$, this.showEditActionLink$, this.streamActionSave()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBrandId() {
    return rx.pipe(
      () => rx.obs.merge(this.brandIdInput$, this.opBrandIdFromSave$),
      shareReplayRefOne(),
    )(null);
  }

  streamBrand() {
    return rx.pipe(
      () => this.brandId$,
      rx.switchMap(
        (brandId) =>
          requestWithComponent(
            (s) => s.getBrandDemoDepositInfo(brandId),
            () => null,
            this.brandsService(),
            this,
          ) as rx.Observable<Brand>,
      ),
    )(null);
  }

  streamBrandIsDemoDepositActive() {
    const brandIsDemoDepositActive$ = new rx.BehaviorSubject<Brand>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.brand$,
          this.opCancelEdit$.pipe(
            rx.withLatestFrom(brandIsDemoDepositActive$),
            rx.map(([a, brand]) => brand),
          ),
        ),
      rx.tap((val) => brandIsDemoDepositActive$.next(val)),
      shareReplayRefOne(),
    )(null);
  }

  streamCurrencyConnectionsFromInput() {
    return rx.pipe(
      () => this.brand$,
      rx.map((brand) => brand.currencyConnections),
      shareReplayRefOne(),
    )(null);
  }

  streamCurrencyConnections() {
    const currencyConnections$ = new rx.BehaviorSubject<any[]>([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamCurrencyConnectionsFromInput(),
          this.opCancelEdit$.pipe(
            rx.withLatestFrom(currencyConnections$),
            rx.map(([op, conns]) => [...conns]),
          ),
        ),
      rx.tap((conns) => currencyConnections$.next(conns)),
      shareReplayRefOne(),
    )(null);
  }

  streamActionSave() {
    return rx.pipe(
      () => this.opSave$,
      rx.withLatestFrom(
        this.brandId$,
        this.brandIsDemoDepositActiveChanges$,
        this.currencyConnectionsChangeset$,
      ),
      rx.filter(([o, brandId, isActiveChangeset, currencyConnChangeset]) => {
        return true;
      }),
      rx.switchMap(([o, brandId, isActiveChanges, currencyConnChangeset]) => {
        const normCurrencyConn = currencyConnChangeset.data.reduce(
          (acc, origCurr) => {
            const change = currencyConnChangeset.changes.find(
              (c) => c.item.id === origCurr.id,
            );
            if (_.isNil(change)) {
              return [...acc, origCurr];
            }

            const newCurr = {
              ...origCurr,
              demoDepositAmount: change.item.demoDepositAmount,
            };

            return [...acc, newCurr];
          },
          [],
        );

        return requestWithComponent(
          (s) =>
            s.updateDemoDeposit(
              brandId,
              isActiveChanges.isDemoDepositActive,
              normCurrencyConn,
            ),
          () => rx.obs.EMPTY,
          this.brandsService(),
          this,
        ) as rx.Observable<Brand>;
      }),
      rx.tap(() => this.opCloseEdit$.next()),
      rx.tap((updatedBrand) =>
        updatedBrand ? this.opBrandIdFromSave$.next(updatedBrand.id) : null,
      ),
    )(null);
  }

  streamIsActiveEdit() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.opActivateEdit$.pipe(rx.map(() => true)),
          this.opCancelEdit$.pipe(rx.map(() => false)),
          this.opCloseEdit$.pipe(rx.map(() => false)),
        ),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamIsDemoDepositSaveEnabled() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.isEnabledDemoDepositActiveValid$,
          this.isValidCurrencyConnections$,
          this.brandIsDemoDepositActive$,
          this.currencyConnectionsChangeset$,
        ]),
      rx.map(
        ([
          isIsActiveValid,
          isValidCurrencyConnections,
          changesIsActive,
          changesCurrencies,
        ]) => {
          if (!isIsActiveValid) {
            return false;
          }

          if (!isValidCurrencyConnections) {
            return false;
          }

          if (_.isNil(changesIsActive) && _.isNil(changesCurrencies.action)) {
            return false;
          }

          return true;
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamShowEditActionLink() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isActiveEdit$,
          checkCrudPermission(
            PermissionNormalized.AutomationDemoDeposit,
            this.PermPermissionStore,
          ),
        ),
      rx.map(([isActiveEdit, automationDemoDepositPerm]) => {
        if (isActiveEdit) {
          return false;
        }

        if (!automationDemoDepositPerm.isUpdate) {
          return false;
        }

        return true;
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const BrandDemoDepositAutomationItemComponent = {
  template,
  controller: BrandDemoDepositAutomationItemController,
  bindings: {
    brandId: '<',
  },
};
