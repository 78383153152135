import BaseController from '~/source/common/controllers/base';
import wireInfoDataTemplate from './brand-deposit-wire-manager.component.html';
import BrandService from '~/source/management/brand/services/brands';
import ModelNormalizer from '~/source/common/services/model-normalizer.js';
import * as _ from '@proftit/lodash';
import { BrandDepositWireAccount } from '@proftit/crm.api.models.entities/src/brand-deposit-wire-account';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { BrandCurrency } from '~/source/common/models/brand-currency';
const styles = require('./brand-deposit-wire-manager.component.scss');

class ComponentController extends BaseController {
  static $inject = ['$scope', 'brandsService', 'modelNormalizer'];

  styles = styles;

  brand: any;
  regularFields: BrandDepositWireAccount;
  customFields: { key: string; value: string | number }[];
  // allFields: BrandDepositWireAccounts;
  bankAccountInfo: any;
  brandCurrency: BrandCurrency;
  currencies: any;
  isEdit: boolean;
  onSetEdit: (a: { isEdit: boolean }) => void;
  brandsService: () => BrandService;
  brandsServiceInstance: BrandService;
  modelNormalizer: ModelNormalizer;
  prevRegularAttributes: any = {};
  prevCustomAttributes: any = {};
  customFieldInputVal: string;
  onBankAccountUpdate: (a: {
    oldValue: BrandDepositWireAccount;
    newValue: BrandDepositWireAccount;
  }) => void;

  $onInit() {
    this.brandsServiceInstance = this.brandsService();
    this.regularFields = _.pick(
      [
        'holderName',
        'holderAddress',
        'bankName',
        'bankAddress',
        'country',
        'iban',
        'swiftCode',
        'noteOnTransfer',
      ],
      this.bankAccountInfo,
    );

    this.customFields = this.bankAccountInfo.customFields;
  }

  /**
   * Enter edit mode:
   * Save current notification settings model state so we could restore them if the user chooses to cancel
   *
   * @returns {void}
   */
  enterEdit(): void {
    // Save pre-edit state
    this.prevRegularAttributes = { ...this.regularFields };
    this.prevCustomAttributes = _.cloneDeep(this.customFields);
    // Enter edit mode
    this.onSetEdit({ isEdit: true });
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   *
   * @returns {void}
   */
  cancelEdit(): void {
    // Restore pre-edit state
    Object.assign(this.regularFields, this.prevRegularAttributes);
    this.customFields = this.prevCustomAttributes;
    this.onSetEdit({ isEdit: false });
  }

  updateAccountData(
    bankData: BrandDepositWireAccount,
    bankDataId: number,
  ): Promise<any> {
    // Save the wire account info
    // return this.bankAccountInfo.patch(bankData)
    return this.brandsServiceInstance
      .updateBrandDepositWireAccount(this.brand.id, bankDataId, bankData)
      .then((account) => {
        // change to display mode
        this.onSetEdit({ isEdit: false });
        Object.assign(this.bankAccountInfo, this.regularFields);
        this.bankAccountInfo = {
          ...this.bankAccountInfo,
          customFields: this.customFields,
        };
        return account;
      })
      .then((account) => {
        this.onBankAccountUpdate({
          oldValue: { ...bankData, id: bankDataId },
          newValue: account,
        });
      });
  }

  /**
   * Save account-wire-info data to the server.
   *
   */
  save(): Promise<any> {
    const data = {
      ...this.regularFields,
      customFields: normalizeCustomFields(this.customFields),
      currency: this.brandCurrency.currency,
    };

    // normalize the form data and make ready for submission
    const normalizedData = this.modelNormalizer.normalize(_.omitEs(data, 'id'));

    const id = _.getEs(this.bankAccountInfo, 'id');

    if (!this.bankAccountInfo._isNew) {
      return this.updateAccountData(normalizedData, id);
    }

    const oldValue = { ...this.regularFields, id };
    // create new wire info data on New Added Currency (when updating an existing brand)
    return this.brandsServiceInstance
      .getBankAccountResource(this.brand.id)
      .expand(['currency'])
      .postWithQuery<IElementRestNg<BrandDepositWireAccount>>(normalizedData)
      .then((updatedBankData) => {
        this.onBankAccountUpdate({ oldValue, newValue: updatedBankData });
      });
  }
}

export const BrandDepositWireManagerComponent = {
  controller: ComponentController,
  template: wireInfoDataTemplate,
  controllerAs: 'vm',
  bindings: {
    bankAccountInfo: '<',
    brand: '<',
    brandCurrency: '<',
    isEdit: '<',
    onSetEdit: '&',
    onBankAccountUpdate: '&',
  },
};

function normalizeCustomFields(
  customFields: { key: string; value: string | number }[],
) {
  return customFields.filter((item) => !_.isEmpty(item.key));
}
