import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './refer-a-friend.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import BrandsService from '~/source/management/brand/services/brands';

const styles = require('./refer-a-friend.component.scss');

export class ReferAFriendController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  selectedBrandFormControl = new FormControl(null);
  selectedBrandPlatformFormControl = new FormControl(null);

  brand$ = this.streamBrand();

  /* @ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    useStreams(
      [
        this.brand$,
        this.selectedBrandFormControl.value$,
        this.selectedBrandPlatformFormControl.value$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamBrand() {
    return rx.pipe(
      () =>
        this.selectedBrandFormControl.value$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.distinctUntilKeyChanged('id'),
        ),
      rx.switchMap((brand) => {
        return rx.obs
          .from(
            this.brandsService()
              .embed(['currencyConnections'])
              .expand('currencyConnections.currency')
              .getOneWithQuery(brand.id),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ReferAFriendComponent = {
  template,
  controller: ReferAFriendController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
