import template from './email-provider-manager.component.html';
const styles = require('./email-provider-manager.component.scss');
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { Editable } from '~/source/common/utilities/editable';
import { FormArray, FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  Brand,
  CommunicationProviderCredentials,
  CommunicationProvider,
} from '@proftit/crm.api.models.entities';
import emailProviderSettings from '../email-provider-settings.unsorted.json';
import {
  isEmailFormValidator,
  isStringNotEmptyOrOtherNotNilFormValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { arrayToHashMap } from '@proftit/general-utilities';
import { BrandsService } from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { FilesUploadService } from '~/source/common/services/files-upload';
import { EmailCredential } from './../email-credential';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { TestEmailConnectionService } from '~/source/common/services/test-email-connection.service';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { getFormArray } from './form-array-helper-functions';
import { CommunicationProviderCode } from '@proftit/crm.api.models.enums';

export class EmailProviderManagerController {
  brand: Brand;

  blockUiId = generateBlockuiId();
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  editManager = new Editable<any>();
  brandsServiceInst: BrandsService;
  blockUiInstance;
  growlId = generateGrowlId();

  reloadBrand: () => void;
  testConnectionAction = new rx.Subject<void>();
  uploadFileAction = new rx.Subject<{ file: File; fieldCode: string }>();
  afterUploadFileAction = new rx.Subject<{
    url: string;
    fieldCode: string;
  }>();
  onFailedFileSelectionAction = new rx.Subject<void>();

  toggleSwitchFormControl = new FormControl<boolean>(false);
  providerFormControl = new FormControl<any>(null);
  testConnectionEmailFormControl = new FormControl<string>('', {
    validators: [isEmailFormValidator],
  });

  brandInput$ = observeShareCompChange<Brand>(
    this.lifecycles.onChanges$,
    'brand',
  );
  brand$ = this.streamBrand();
  formFieldCredentials$ = this.streamFormFieldCredentials();
  isCreateMode$ = this.streamIsCreateMode();
  gmailConfigurationFileUpdatedAt$ = this.streamGmailConfigurationFileUpdatedAt();

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly filesUploadService: FilesUploadService,
    readonly blockUI: any,
    readonly testEmailConnectionService: TestEmailConnectionService,
    readonly growl: ng.growl.IGrowlService,
  ) {
    useStreams(
      [
        this.editManager.initiator$,
        this.formFieldCredentials$.pipe(
          rx.switchMap((arrayControl) => arrayControl.value$),
        ),
        this.streamFileValues(),
        this.streamTestConnection(),
        this.streamEmailProvider(),
        this.streamTestConnectionEmail(),
        this.streamProviderEnabledToggleSwitch(),
        this.streamFileUpload(),
        this.toggleSwitchFormControl.value$,
        this.providerFormControl.value$,
        this.testConnectionEmailFormControl.value$,
        this.streamSave(),
        this.streamOnFailedFileSelection(),
        this.gmailConfigurationFileUpdatedAt$,
      ],
      this.lifecycles.onDestroy$,
    );
    this.brandsServiceInst = this.brandsService();
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamGmailConfigurationFileUpdatedAt() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((b) => !_.isNil(b)),
      rx.filter((b) => b.emailCredentials.length > 0),
      rx.filter(
        (b) =>
          b.emailCredentials[0].emailProvider.code ===
          CommunicationProviderCode.Gmail,
      ),
      rx.map((brand) => brand.emailCredentials[0].updatedAt),
      shareReplayRefOne(),
    )(null);
  }

  streamOnFailedFileSelection() {
    return rx.pipe(
      () => this.onFailedFileSelectionAction,
      rx.tap(() => {
        this.growl.error('emailProvider.gmail.JSON_FILES_ONLY', {
          referenceId: <any>this.growlId,
        });
      }),
    )(null);
  }

  streamBrand() {
    return rx.pipe(
      () => rx.obs.merge(this.brandInput$),
      rx.map((brand) => brand),
      shareReplayRefOne(),
    )(null);
  }

  streamIsCreateMode() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((brand) => brand.emailCredentials.length === 0),
      shareReplayRefOne(),
    )(null);
  }

  streamSave() {
    return rx.pipe(
      () => this.editManager.saveAction,
      rx.withLatestFrom(
        this.brand$,
        this.toggleSwitchFormControl.value$,
        this.providerFormControl.value$ as rx.Observable<any>,
        this.formFieldCredentials$.pipe(
          rx.switchMap(
            (arrayControl) =>
              arrayControl.value$ as rx.Observable<EmailCredential[]>,
          ),
        ),
        this.isCreateMode$,
      ),
      rx.switchMap(
        ([
          a,
          brand,
          toggleSwitchValue,
          emailProvider,
          emailCredentials,
          isCreateMode,
        ]) => {
          const emailCredentialsValues = emailCredentials.map((credential) => {
            const { code, value } = credential;
            return {
              value,
              key: code,
            };
          });
          if (isCreateMode) {
            return rx.obs
              .from(
                this.brandsServiceInst
                  .setConfig({ blockUiRef: this.blockUiId })
                  .getEmailCredentialsCollectionResource(this.brand.id)
                  .postWithQuery<IElementRestNg<Brand>>({
                    id: brand.id,
                    isEmailProviderActive: toggleSwitchValue,
                    emailProviderId: emailProvider.id,
                    credentials: emailCredentialsValues,
                  }),
              )
              .pipe(rx.catchError((e) => rx.obs.NEVER));
          }
          return rx.obs
            .from(
              this.brandsServiceInst
                .setConfig({ blockUiRef: this.blockUiId })
                .getEmailCredentialsSingleResource(
                  this.brand.id,
                  this.brand.emailCredentials[0].id,
                )
                .patchWithQuery<IElementRestNg<Brand>>({
                  id: brand.id,
                  isEmailProviderActive: toggleSwitchValue,
                  emailProviderId: emailProvider.id,
                  credentials: emailCredentialsValues,
                }),
            )
            .pipe(rx.catchError((e) => rx.obs.NEVER));
        },
      ),
      rx.tap(() => {
        this.reloadBrand();
        this.editManager.saveIsDoneAction.next();
      }),
    )(null);
  }

  streamFileUpload() {
    return rx.pipe(
      () => this.uploadFileAction,
      rx.switchMap(({ file, fieldCode }) => {
        this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
        this.blockUiInstance.start();

        return this.filesUploadService
          .uploadPrivateFile(file)
          .then((res) => {
            return {
              fieldCode,
              url: res.data.filePath,
            };
          })
          .catch((e) => {
            this.blockUiInstance.stop();
            return null;
          });
      }),
      rx.filter((value) => !_.isNil(value)),
      rx.tap(({ url, fieldCode }) => {
        this.blockUiInstance.stop();
        this.afterUploadFileAction.next({ url, fieldCode });
      }),
    )(null);
  }

  streamFileValues() {
    return rx.pipe(
      () => this.afterUploadFileAction,
      rx.withLatestFrom(
        this.formFieldCredentials$.pipe(
          rx.switchMap((array) => {
            return array.controls$ as rx.Observable<FormGroup[]>;
          }),
        ),
      ),
      rx.tap(([{ url, fieldCode }, formGroups]) => {
        const wasUpdateSuccessful = this.setFormControlValue(
          formGroups,
          fieldCode,
          url,
        );
      }),
    )(null);
  }

  /**
   * Sets a value of a FormControl that is a descendant of the FormArray.
   * @param formGroups - the FormGroups of the FormArray - each FormGroup represents 1 credential object.
   * @param fieldCode - the code of the FormGroup we want to set the value for.
   * @param value - Value to set.
   * @returns - true if found a FormGroup that has a code equal to the fieldCode parameter, false otherwise.
   */
  setFormControlValue(
    formGroups: FormGroup[],
    fieldCode: string,
    value: any,
  ): boolean {
    const relevantFormGroup = formGroups.find(
      (formGroup) => fieldCode === formGroup.controls.code.value,
    );
    if (!_.isNil(relevantFormGroup)) {
      const valueFormControl = relevantFormGroup.controls.value as FormControl<
        string
      >;
      valueFormControl.setValue(value);
      return true;
    }
    return false;
  }

  streamProviderEnabledToggleSwitch() {
    const toggleSwitch$ = new rx.BehaviorSubject<boolean>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamProviderEnabledToggleSwitchFromBrand(),
          this.streamProviderEnabledToggleSwitchFromCancel(toggleSwitch$),
        ),
      rx.withLatestFrom(this.toggleSwitchFormControl.value$),
      rx.tap(([toggleSwitchValue, currentValue]) => {
        toggleSwitch$.next(toggleSwitchValue);
        if (toggleSwitchValue !== currentValue) {
          this.toggleSwitchFormControl.setValue(toggleSwitchValue);
        }
      }),
    )(null);
  }

  getIsEmailProviderActiveFromBrand() {
    return (obs: rx.Observable<Brand>) =>
      obs.pipe(
        rx.filter((brand) => !_.isNil(brand)),
        rx.map((brand) => brand.emailCredentials[0].isEmailProviderActive),
      );
  }

  streamProviderEnabledToggleSwitchFromBrand(): rx.Observable<boolean> {
    return rx.pipe(
      () => this.brand$,
      rx.filter(
        (x) =>
          !_.isNil(x) &&
          !_.isNil(x.emailCredentials) &&
          x.emailCredentials.length > 0,
      ),
      this.getIsEmailProviderActiveFromBrand(),
    )(null);
  }

  streamProviderEnabledToggleSwitchFromCancel(
    toggleSwitch$: rx.Observable<boolean>,
  ): rx.Observable<boolean> {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.withLatestFrom(toggleSwitch$),
      rx.map(([a, toggleSwitchValue]) => toggleSwitchValue),
    )(null);
  }

  streamTestConnectionEmail() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.editManager.cancelEditAction,
          this.editManager.afterSaveAction,
        ),
      rx.tap(() => {
        this.testConnectionEmailFormControl.setValueAsFirst('');
      }),
    )(null);
  }

  streamEmailProvider() {
    const emailProvider$ = new rx.BehaviorSubject<any>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamEmailProviderFromBrand(),
          this.streamEmailProviderFromCancel(emailProvider$),
        ),
      rx.withLatestFrom(
        this.providerFormControl.value$ as rx.Observable<CommunicationProvider>,
      ),
      rx.tap(([emailProvider, currentEmailProvider]) => {
        emailProvider$.next(emailProvider);
        if (
          _.isNil(emailProvider) ||
          _.isNil(currentEmailProvider) ||
          emailProvider.id !== currentEmailProvider.id
        ) {
          this.providerFormControl.setValue(emailProvider);
        }
      }),
    )(null);
  }

  getEmailProviderFromBrand() {
    return (obs: rx.Observable<Brand>) =>
      obs.pipe(
        rx.filter((brand) => !_.isNil(brand)),
        rx.map((brand) => brand.emailCredentials[0].emailProvider),
      );
  }

  streamEmailProviderFromBrand() {
    return rx.pipe(
      () => this.brand$,
      rx.filter(
        (x) =>
          !_.isNil(x) &&
          !_.isNil(x.emailCredentials) &&
          x.emailCredentials.length > 0,
      ),
      this.getEmailProviderFromBrand(),
    )(null);
  }

  streamEmailProviderFromCancel(emailProvider$: rx.Observable<any>) {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.withLatestFrom(emailProvider$),
      rx.map(([a, emailProvider]) => _.cloneDeep(emailProvider)),
    )(null);
  }

  streamTestConnection() {
    return rx.pipe(
      () => this.testConnectionAction,
      rx.withLatestFrom(
        this.testConnectionEmailFormControl.isValid$,
        this.testConnectionEmailFormControl.value$,
        this.formFieldCredentials$.pipe(
          rx.switchMap((arrayControl) => arrayControl.value$),
        ),
        this.providerFormControl.value$ as rx.Observable<CommunicationProvider>,
      ),
      rx.filter(([a, isValid]) => isValid),
      rx.switchMap(([a, isValid, emailToTest, formFields, provider]) => {
        const formFieldsHashMap = arrayToHashMap<string, EmailCredential>(
          formFields as any,
          'code',
        );
        const topic = _.defaultTo('', formFieldsHashMap.get('topic').value);
        const credentialsFileUrl = _.defaultTo(
          '',
          formFieldsHashMap.get('credentials_file').value,
        );
        return this.testEmailConnectionService
          .setConfig({
            blockUiRef: this.blockUiId,
            growlRef: this.growlId,
          })
          .getEmailProviderResource(provider.code)
          .customPostWithQuery({
            topic,
            credentialsFile: credentialsFileUrl,
            email: emailToTest,
          })
          .then((res) => {
            this.growl.success(
              'emailProvider.gmail.CHECK_CONNECTION_SUCCESS_GROWL',
              {
                referenceId: <any>this.growlId,
              },
            );
          })
          .catch((e) => {});
      }),
    )(null);
  }

  streamFormFieldCredentials(): rx.Observable<FormArray> {
    const formFieldsParams$ = new rx.BehaviorSubject<any>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamFormFieldsCredentialsFromBrand(),
          this.streamFormFieldsCredentialsFromCancel(formFieldsParams$),
        ),
      rx.map(({ emailProvider, emailCredentials }) => {
        formFieldsParams$.next({ emailProvider, emailCredentials });
        return getFormArray(emailProvider, emailCredentials);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormFieldsCredentialsFromBrand() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.brand$, this.providerFormControl.value$),
      rx.filter(([brand, provider]) => !_.isNil(brand) && !_.isNil(provider)),
      rx.filter(([brand, provider]) => !_.isNil(brand.emailCredentials)),
      rx.map(([brand, provider]) => {
        const credentials = !_.isNil(brand.emailCredentials[0])
          ? brand.emailCredentials[0].credentials
          : [];
        return {
          emailCredentials: credentials,
          emailProvider: provider,
        };
      }),
    )(null);
  }

  streamFormFieldsCredentialsFromCancel(formFieldsParams$: rx.Observable<any>) {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.withLatestFrom(formFieldsParams$),
      rx.map(([a, formFieldsParams]) => _.cloneDeep(formFieldsParams)),
    )(null);
  }
}

export const EmailProviderManagerComponent = {
  template,
  controller: EmailProviderManagerController,
  bindings: {
    brand: '<',
    reloadBrand: '&',
  },
};
