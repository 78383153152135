import Component from '~/source/common/components/dropdowns/base/component';
import { fetchAllWithPaging } from '~/source/common/utilities/fetch-all-with-paging';

const PAGE_FETCH_SIZE = 500;

class PositionsTradeAssetsController extends Component.controller {
  static $inject = [
    'positionsTradeAssetsService',
    ...Component.controller.$inject,
  ];

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return fetchAllWithPaging(
      PAGE_FETCH_SIZE,
      (page, size) => this.queryPageStep(page, size),
      () => this.dataServiceInst.total,
    );
  }

  /**
   * Peroform paged query.
   *
   * @param page - page number
   * @param size - size of page to fetch
   * @return Promise of query
   */
  queryPageStep(page: number, size: number) {
    return this.dataServiceInst
      .setConfig({ blockUiRef: `dropdown_${this.dropdownId}` })
      .setPage(page, size)
      .getListWithQuery();
  }

  /**
   * overrides the onDataReady method in order to make all the assets uppercase
   * Called by the parent when data is fetched from service.
   * @param {object} data - Data object fetched from service
   * @return {Promise<T>} - resolves to the modified data object
   */
  onDataReady(data) {
    return super.onDataReady(data).then(() => {
      this._data.forEach((el) => {
        el[this.translateDest] = el.name.toUpperCase();
      });

      return this._data;
    });
  }

  get defaultPlaceholder() {
    return 'position.SELECT_PRODUCT';
  }

  /**
   * Name of the propy that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({ controller: PositionsTradeAssetsController });
