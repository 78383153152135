import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { PlatformCode } from '@proftit/crm.api.models.enums';
import template from './risk-manager-dashboard.component.html';
import { menuEntries } from './menu-entries';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { CurrentPlatformOfBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-of-brand-store-service.directive';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { blockUI, growl } from 'angular';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';

const styles = require('./risk-manager-dashboard.component.scss');

export class RiskManagerDashboardController {
  /* require */

  prfCurrentPlatformOfBrand: CurrentPlatformOfBrandStoreServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  tabs$: rx.Observable<any>;

  /* @ngInject */
  constructor(
    readonly blockUI: blockUI.BlockUIService,
    readonly growl: growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
  ) {}

  $onInit() {
    this.tabs$ = this.streamTabs();

    const loginToSelectedPlatformS = this.streamLoginToSelectedPlatform();

    useStreams([loginToSelectedPlatformS.stream$], this.lifecycles.onDestroy$);
  }

  $onChanges() {}

  $onDestroy() {}

  streamTabs() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.obs.of(menuEntries),
          this.prfCurrentPlatformOfBrand.brandPlatformS.stream$.pipe(
            rx.startWith(null),
          ),
        ),
      rx.map(([menuEntries, brandPlatform]) =>
        menuEntries.filter((m) => {
          if (_.isNil(brandPlatform)) {
            return true;
          }
          return m.enabledForPlatforms.includes(
            brandPlatform.platform.code as PlatformCode,
          );
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamLoginToSelectedPlatform() {
    return generateInProcessStream(
      this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
      () =>
        rx.pipe(
          tapStartAsyncWorkInUi(
            this.blockUI,
            this.growl,
            this.growlMessages,
            this.blockUiId,
            this.growlId,
          ),
          rx.switchMap((brandPlatform) => {
            if (_.isNil(brandPlatform)) {
              return this.prfCurrentPlatformSession.logout();
            }

            return this.prfCurrentPlatformSession.login(brandPlatform).pipe(
              tapStopAsyncWorkInUi(
                this.blockUI,
                this.growl,
                this.growlMessages,
                this.blockUiId,
                this.growlId,
              ),
              rx.catchError(() => rx.obs.of(null)),
            );
          }),
          tapStopAsyncWorkInUi(
            this.blockUI,
            this.growl,
            this.growlMessages,
            this.blockUiId,
            this.growlId,
          ),
        ),
      [this.prfCurrentPlatformOfBrand.brandPlatformS.inProcess$],
    );
  }
}

export const RiskManagerDashboardComponent = {
  template,
  require: {
    prfCurrentPlatformOfBrand: '^',
    prfCurrentPlatformSession: '^',
  },
  controller: RiskManagerDashboardController,
};
