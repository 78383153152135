import {
  Controller as SlicedChartController,
  config as slicedChartConfig,
} from './amcharts-sliced.component';

export class Controller extends SlicedChartController {
  /**
   * Build the options object for a pie chart, that will be passed to amcharts
   * Gets options object which will also be merged to the returned options object
   * and can be used to override settings.
   *
   * @param {object} options
   * @returns {object}
   */
  buildOptions(options) {
    return super.buildOptions(
      this.extend(
        {
          type: 'pie',
          radius: '45%',
          colors: [
            '#58adca',
            '#ff8c6d',
            '#f0435f',
            '#c7e7e4',
            '#edd899',
            '#344154',
            '#4a778e',
            '#6c85bd',
            '#b9783f',
            '#b93e3d',
            '#913167',
          ],
          labelsEnabled: false,
          sequencedAnimation: false,
          startDuration: 0,
          titleField: 'category',
          valueField: 'value',
          addClassNames: true,
          classNamePrefix: 'prf-amcharts__pie',
          theme: 'lights',
          legend: {
            align: 'center',
            markerBorderThickness: 4,
            markerLabelGap: 8,
            markerSize: 20,
            markerType: 'line',
            maxColumns: 2,
            valueText: '',
            valueWidth: 15,
            verticalGap: 0,
          },
        },
        options,
      ),
    );
  }
}

export default slicedChartConfig({ controller: Controller });

export const config = (newConfig) =>
  slicedChartConfig({
    controller: Controller,
    ...newConfig,
  });
