import ng, { blockUI } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './exposure-dashboard.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand, Platform } from '@proftit/crm.api.models.entities';
import { FormControl } from '@proftit/ng1.reactive-forms';
import {
  ExposureTab,
  ExposureTabCode,
} from '~/source/exposure/components/exposure-navbar/exposure-navbar.component';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { generateUuid } from '@proftit/general-utilities';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
const styles = require('./exposure-dashboard.component.scss');

const EXPOSURE_SELECTED_BRAND_ID_LOCAL_STORAGE_KEY =
  'exposureSelectedBrandIdLocalStorageKey';
const EXPOSURE_SELECTED_BRAND_PLATFORM_ID_LOCAL_STORAGE_KEY =
  'exposureSelectedBrandPlatformLocalStorageKey';

export class ExposureDashboardController {
  styles = styles;

  ExposureTabCode = ExposureTabCode;

  growlRef = generateGrowlId();

  selectedBrandFormControl = new FormControl<Brand>(null);
  selectedBrandPlatformFormControl = new FormControl<Platform>(null);
  selectedExposureTabFormControl = new FormControl<ExposureTab>(null);

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  isLoggedIn$ = new rx.BehaviorSubject<boolean>(false);
  blockUiId = generateUuid();
  blockUiInstance: blockUI.BlockUIService;

  lifecycles = observeComponentLifecycles(this);
  brands$ = this.streamBrands();
  brandPlatformsToDisplay$ = this.streamBrandPlatformsToDisplay();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly blockUI: blockUI.BlockUIService,
    readonly growl: angular.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
  ) {
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
    useStreams(
      [
        this.isLoggedIn$,
        this.selectedBrandFormControl.value$,
        this.selectedBrandPlatformFormControl.value$,
        this.selectedExposureTabFormControl.value$,
        this.brands$,
        this.streamLoginToPlatform(),
        this.brandPlatformsToDisplay$,
        this.streamSaveSelectedBrandToLocalStorage(),
        this.streamSaveSelectedBrandPlatformToLocalStorage(),
        this.streamPreselectLastSelectedBrandPlatform(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamLoginToPlatform() {
    const actionStream = rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.selectedBrandPlatformFormControl.value$.pipe(
            rx.filter((conn) => !_.isNil(conn)),
            rx.distinctUntilChanged(),
          ),
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
        ),
      rx.switchMap(([brandPlatform, sessionService]) => {
        this.growlMessages.destroyAllMessages(this.growlRef as any);
        this.blockUiInstance.start();
        return sessionService.login(brandPlatform);
      }),
      rx.tap(() => {
        this.blockUiInstance.stop();
        this.isLoggedIn$.next(true);
      }),
      rx.catchError((err) => {
        this.blockUiInstance.stop();
        this.growl.error('login.CONNECTION_FAILED_PLEASE_TRY_LATER', {
          referenceId: <any>this.growlRef,
        });
        this.isLoggedIn$.next(false);
        return rx.pipe(
          () => rx.obs.of(true),
          rx.delay(5000),
          rx.switchMap(() => actionStream(null)),
        )(null);
      }),
      shareReplayRefOne(),
    );
    return actionStream(null);
  }

  streamBrands() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.brandsService()
              .embed(['platformConnections'])
              .expand(['platformType', 'platformConnections.platform'])
              .getListWithQuery()
              .then((b) => b.plain()),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.delay(1000),
      rx.tap((brands) => {
        const savedBrandIdValue = localStorage.getItem(
          EXPOSURE_SELECTED_BRAND_ID_LOCAL_STORAGE_KEY,
        );
        if (_.isNil(savedBrandIdValue)) {
          return;
        }
        const brandId = Number(savedBrandIdValue);
        const lastSelectedBrand = brands.find((b) => b.id === brandId);
        if (_.isNil(lastSelectedBrand)) {
          return;
        }
        this.selectedBrandFormControl.setValueAsFirst(lastSelectedBrand);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamBrandPlatformsToDisplay() {
    return rx.pipe(
      () =>
        this.selectedBrandFormControl.value$.pipe(
          rx.filter((sb) => !_.isNil(sb)),
          rx.distinctUntilKeyChanged('id' as any),
        ),
      rx.map((selectedBrand: Brand) => {
        const nonMT4Connections = selectedBrand.platformConnections.filter(
          (connection) => !MT4_PLATFORMS.includes(connection.platform.code),
        );
        return nonMT4Connections.map((connection) => {
          return {
            ...connection,
            label: connection.platform.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPreselectLastSelectedBrandPlatform() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.brandPlatformsToDisplay$,
          this.selectedBrandFormControl.isAtFirstValue$,
        ),
      rx.filter(([x, isAtFirstValue]: [any, boolean]) => isAtFirstValue),
      rx.tap(([platformsToDisplay, isFirstValue]) => {
        const savedBrandPlatformIdValue = localStorage.getItem(
          EXPOSURE_SELECTED_BRAND_PLATFORM_ID_LOCAL_STORAGE_KEY,
        );
        if (_.isNil(savedBrandPlatformIdValue)) {
          return;
        }
        const brandPlatformId = Number(savedBrandPlatformIdValue);
        const lastSelectedBrandPlatform = platformsToDisplay.find(
          (p) => p.id === brandPlatformId,
        );
        if (_.isNil(lastSelectedBrandPlatform)) {
          return;
        }
        this.selectedBrandPlatformFormControl.setValueAsFirst(
          lastSelectedBrandPlatform,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveSelectedBrandToLocalStorage() {
    return rx.pipe(
      () => this.selectedBrandFormControl.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((brand) => {
        localStorage.setItem(
          EXPOSURE_SELECTED_BRAND_ID_LOCAL_STORAGE_KEY,
          String(brand.id),
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveSelectedBrandPlatformToLocalStorage() {
    return rx.pipe(
      () => this.selectedBrandPlatformFormControl.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((brandPlatform) => {
        localStorage.setItem(
          EXPOSURE_SELECTED_BRAND_PLATFORM_ID_LOCAL_STORAGE_KEY,
          String(brandPlatform.id),
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ExposureDashboardComponent = {
  template,
  controller: ExposureDashboardController,
};
