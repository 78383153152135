import ng, { IHttpService } from 'angular';
import Big from 'big.js';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './exposure-symbol-positions-table.component.html';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import RestService from '~/source/common/services/rest';
import SocketService from '~/source/common/services/socket';
import { exposureSymbolPositionsTableCols } from '~/source/exposure/components/exposure-symbol-positions-table/exposure-symbol-positions-table-cols';
import { exposureSymbolPositionsTableSettings } from '~/source/exposure/components/exposure-symbol-positions-table/exposure-symbol-positions-table-settings';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import {
  PlatformExposureSymbolsService,
  POSITIONS_RESOURCE_PATH,
  SYMBOLS_RESOURCE_PATH,
} from '~/source/exposure/services/platform-exposure-symbols.service';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { appendResource, appendResourceId } from '@proftit/request-client';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CfdPlatformSocketService } from '~/source/management/integrations/risk-manager/services/cfd-platform-socket.service';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { generateUuid } from '@proftit/general-utilities';
import { ExposureSymbol } from '~/source/exposure/components/symbols-exposure-table/symbols-exposure-table.component';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';
import { Brand } from '@proftit/crm.api.models.entities';
import { PlatformCode } from '@proftit/crm.api.models.enums';

const styles = require('./exposure-symbol-positions-table.component.scss');

const GLOBAL_GROWL_ID = 'restService';

enum PlatformDirection {
  Buy = 'buy',
  Sell = 'sell',
}

type PlatformPosition = {
  id: number;
  assetId: number;
  customerId: number;
  brandId: number;
  investment: number;
  direction: string;
  entryRate: number;
  profit: number;
  bid: number;
  ask: number;
  expiryRate: number;
  payout: number;
  takeProfit: number;
  stopLoss: number;
  entryDate: string;
  lastUpdated: string;
  swapCommission: number;
  currentPrice?: number;
  sizeInUnits: number;
  _openPNL?: number;
  exposureInQuotedCurrency: number;
};

export class ExposureSymbolPositionsTableController extends TableLiveController {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'prfCfdPlatformSocketService',
    'prfPlatformExposureSymbolsService',
    'userSettingsService',
    ...TableLiveController.$inject,
  ];

  // injections
  $http: IHttpService;
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  prfPlatformExposureSymbolsService: PlatformExposureSymbolsService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfCfdPlatformSocketService: () => CfdPlatformSocketService;

  socketServiceInstance: CfdPlatformSocketService;
  assetSocketServiceInstance: CfdPlatformSocketService;
  blockUi = generateUuid();

  styles = styles;
  symbol: string;

  blockUiKey = generateBlockuiId();
  growlRef = generateUuid();
  lifecycles = observeComponentLifecycles(this);
  tableColumns;
  positions;

  assetId: number;
  baseCurrencySymbolName: string;
  assetId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'assetId',
  );
  assetBidPrice$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'assetBidPrice',
  );
  assetAskPrice$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'assetAskPrice',
  );

  assetPrecision$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'assetPrecision',
  ).pipe(rx.map((v) => _.defaultTo(2, v)));

  reloadTableButtonAction = new rx.Subject<void>();

  shouldShowReloadTableButton$ = new rx.BehaviorSubject<boolean>(false);
  socketServiceInstance$ = new rx.BehaviorSubject<CfdPlatformSocketService>(
    null,
  );
  dataService$ = new rx.BehaviorSubject<CfdMongoRestAdapter>(null);

  sessionInfo$: rx.Observable<PlatformSessionInfo>;
  trcBrand$: rx.Observable<Brand>;

  /* @ngInject */
  constructor(...args) {
    super(...args);
    // dont save last page of the table in local storage - always start from the 1st page
    this.tableCacheKey = null;

    this.socketServiceInstance = this.prfCfdPlatformSocketService();
    this.assetSocketServiceInstance = this.prfCfdPlatformSocketService();

    this.tableColumns = exposureSymbolPositionsTableCols;
    useStreams(
      [
        this.assetId$,
        this.assetPrecision$,
        this.shouldShowReloadTableButton$,
        this.streamReloadTable(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.sessionInfo$ = this.streamSessionInfo();
    this.trcBrand$ = this.streamTrcBrand();
    useStreams(
      [
        this.sessionInfo$,
        this.trcBrand$,
        this.streamInitDataServiceInstance(),
        this.streamUpdateDataForRow(),
        this.streamShowReloadTableButton(),
        this.streamTableCols(),
        this.streamInitTable(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamShowReloadTableButton() {
    const lastOpenPositionsCount$ = new rx.BehaviorSubject<number>(null);
    return rx.pipe(
      () => rx.obs.combineLatest(this.trcBrand$, this.assetId$),
      rx.switchMap(([trcBrand, assetId]) => {
        return observeChannel(
          this.assetSocketServiceInstance,
          this.buildAssetChannel(trcBrand.id, assetId),
        );
      }),
      rx.withLatestFrom(lastOpenPositionsCount$),
      rx.tap(([assetData, lastCount]: [ExposureSymbol, number]) => {
        if (!_.isNil(lastCount) && lastCount !== assetData.openPositionsCount) {
          this.shouldShowReloadTableButton$.next(true);
        }
        lastOpenPositionsCount$.next(assetData.openPositionsCount);
      }),
      shareReplayRefOne(),
    )(null);
  }

  buildAssetChannel(brandPlatformConnectionId: number, symbolId: number) {
    return `{brand.${brandPlatformConnectionId}}.exposure.symbol.${symbolId}`;
  }

  streamReloadTable() {
    return rx.pipe(
      () => this.reloadTableButtonAction,
      rx.tap(() => {
        this.reloadTable();
        this.shouldShowReloadTableButton$.next(false);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUpdateDataForRow() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.collection$,
          this.assetBidPrice$,
          this.assetAskPrice$,
          this.assetPrecision$,
        ),
      rx.filter((x) => x.every((i) => !_.isNil(i))),
      rx.tap(([p, bidPrice, askPrice, precision]) => {
        this.positions.forEach((position) => {
          if (position.direction === PlatformDirection.Buy) {
            // we need to keep the currentPrice with its entire precision because it is used in other calculations
            position.currentPrice = bidPrice;
            position._currentPriceToDisplay = Number(
              position.currentPrice.toFixed(precision),
            );
          } else {
            // we need to keep the currentPrice with its entire precision because it is used in other calculations
            position.currentPrice = askPrice;
            position._currentPriceToDisplay = Number(
              position.currentPrice.toFixed(precision),
            );
          }
          const { currentPrice, sizeInUnits, entryRate } = position;
          position._openPNL = this.getOpenPNL(
            currentPrice,
            entryRate,
            sizeInUnits,
            precision,
          );

          position.exposureInQuotedCurrency = this.getPositionExposure(
            sizeInUnits,
            currentPrice,
            precision,
          );
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  // the formula for open PNL is: ( currentPrice - entryRate ) * sizeInUnits
  getOpenPNL(
    currentPrice: number,
    entryRate: number,
    sizeInUnits: number,
    precision: number,
  ): number {
    const remainderForOpenPNL = Big(currentPrice).minus(Big(entryRate));
    const openPNLResult = Big(remainderForOpenPNL).times(Big(sizeInUnits));
    return Number(openPNLResult.toFixed(precision));
  }

  getPositionExposure(
    sizeInUnits: number,
    currentPrice: number,
    precision: number,
  ): number {
    // the formula for exposure is: sizeInUnits * currentPrice
    const result = Big(sizeInUnits).times(currentPrice);
    return Number(result.toFixed(precision)) * -1; // we need to revert the sign as requested by Ori B
  }

  $onDestroy() {}

  $onChanges() {}

  parseLoadedData(data: any[]): any[] {
    this.positions = data;
    this.positions.forEach((pos) => {
      pos.baseCurrencySymbolName = this.baseCurrencySymbolName;
    });
    return data;
  }

  get entitiesContainer(): Restangular.ICollection {
    return this.positions;
  }

  get liveEntitiesVarName(): string {
    return 'vm.positions';
  }

  fetchFn(): RestService {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUi });
  }

  get requiredApiFilters() {
    return {
      assetId: this.assetId,
    };
  }

  streamSessionInfo() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((x) => !_.isNil(x)),
      shareReplayRefOne(),
    )(null);
  }

  streamTableCols() {
    return rx.pipe(
      () => this.sessionInfo$,
      rx.tap((sessionInfo: PlatformSessionInfo) => {
        const { code } = sessionInfo.platform;
        this.tableColumns =
          code === PlatformCode.Bundle
            ? this.getColsForBundlesPlatform()
            : this.getDefaultCols();
      }),
      shareReplayRefOne(),
    )(null);
  }

  getColsForBundlesPlatform() {
    const colsToRemoveForBundles = ['swap'];
    return exposureSymbolPositionsTableCols.filter(
      (col) => !colsToRemoveForBundles.includes(col.code),
    );
  }

  getDefaultCols() {
    return exposureSymbolPositionsTableCols;
  }

  streamTrcBrand() {
    return rx.pipe(
      () => this.sessionInfo$,
      rx.map((sessionInfo) => {
        return sessionInfo.trcBrand;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInitDataServiceInstance() {
    return rx.pipe(
      () => this.sessionInfo$,
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUi,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((sessionInfo) => {
        this.socketServiceInstance.setToken(sessionInfo.session.token);
        this.socketServiceInstance.setStreamerUrl(
          sessionInfo.session.streamerUrl,
        );
        this.socketServiceInstance$.next(this.socketServiceInstance);
      }),
      rx.map((sessionInfo) => {
        let req = this.prfPlatformExposureSymbolsService.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );

        req = appendResource(SYMBOLS_RESOURCE_PATH, req);
        req = appendResourceId(sessionInfo.trcBrand.id, req);
        req = appendResource(POSITIONS_RESOURCE_PATH, req);

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUi,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
        this.dataService$.next(instance);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInitTable() {
    return rx.pipe(
      () => this.dataService$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap(() => {
        this.initTable();
      }),
      shareReplayRefOne(),
    )(null);
  }

  get socketService(): SocketService {
    return this.socketServiceInstance;
  }

  get ngTableSettings() {
    return exposureSymbolPositionsTableSettings.ngTable;
  }

  get tableKey() {
    return null;
  }

  getFiltersCategory() {
    return `${this.tableKey}${this.assetId}`;
  }
}

export const ExposureSymbolPositionsTableComponent = {
  template,
  controller: ExposureSymbolPositionsTableController,
  controllerAs: 'vm',
  bindings: {
    symbol: '<',
    assetId: '<',
    assetPrecision: '<',
    baseCurrencySymbolName: '<',
    assetBidPrice: '<',
    assetAskPrice: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};
