import * as _ from '@proftit/lodash';

/**
 * isMinStringLength - Validate value for is minimum string length.
 *
 * @param value
 * @param scope
 * @param element
 * @param attrs
 * @param param
 * @returns validate result
 */
export function isMinStringLength(value, scope, element, attrs, param) {
  if (_.isEmpty(value)) {
    return true;
  }

  return value.length >= parseInt(param, 10);
}
