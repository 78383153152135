import type { growl } from 'angular';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  useStreams,
  inProcessFilter,
  shareReplayRefOne,
} from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { generateGroupForm } from './generate-group-form';
import template from './brand-platform-mt4-group-dialog-item.component.html';
import { CurrentGroupOfBrandPlatformStoreServiceDirectiveController } from '~/source/common/service-directives/current-group-of-brand-platform-store-service.directive';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
const styles = require('./brand-platform-mt4-group-dialog-item.component.scss');

export class BrandPlatformMt4GroupDialogItemController {
  /* require */

  prfCurrentGroupOfBrandPlatform: CurrentGroupOfBrandPlatformStoreServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* bindings */

  onClose: () => void;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  groupFormP = generateGroupForm();

  /* inputs */

  brandPlatformIn$ = observeShareCompChange<BrandPlatform>(
    this.lifecycles.onChanges$,
    'brandPlatform',
  );

  mtGroupIdIn$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'mtGroupId',
  );

  operationIn$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'operation',
  );

  /* streams */

  doOperation$ = new rx.Subject<void>();

  isConfirmButtonDisabledS;

  /* @ngInject */
  constructor(
    readonly growl: growl.IGrowlService,
    readonly growlMessages: growl.IGrowlMessagesService,
  ) {
    useStreams(
      [this.brandPlatformIn$, this.mtGroupIdIn$, this.operationIn$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.isConfirmButtonDisabledS = this.streamIsConfirmButtonDisabled();
    const initGroupS = this.streamInitGroup();
    const initGroupFormS = this.streamInitGroupForm();
    const saveFormS = this.streamSaveForm();
    const loginToSelectedPlatformS = this.streamLoginToSelectedPlatform();

    useStreams(
      [
        initGroupS.stream$,
        initGroupFormS.stream$,
        saveFormS.stream$,
        loginToSelectedPlatformS.stream$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitGroup() {
    return generateInProcessStream(
      rx.obs.combineLatest(
        this.brandPlatformIn$,
        this.mtGroupIdIn$,
        this.operationIn$,
        this.prfCurrentPlatformSession.sessionS.stream$.pipe(
          rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
        ),
      ),
      (inProcess$) =>
        rx.pipe(
          inProcessFilter(inProcess$, (params) =>
            params.every((p) => !_.isNil(p)),
          ),
          rx.switchMap(
            ([_brandPlatform, mtGroupId, operation, sessionInfo]) => {
              if (operation !== 'update') {
                throw new Error('unimplemented');
              }

              return this.prfCurrentGroupOfBrandPlatform.load(
                sessionInfo,
                mtGroupId,
              );
            },
          ),
        ),
    );
  }

  streamInitGroupForm() {
    return generateInProcessStream(
      this.prfCurrentGroupOfBrandPlatform.groupS.stream$,
      () =>
        rx.pipe(
          rx.tap((group) => {
            this.groupFormP.proxy.handler.getLeaf().setValueAsInitial(group);
          }),
        ),
      [this.prfCurrentGroupOfBrandPlatform.groupS.inProcess$],
    );
  }

  streamSaveForm() {
    return generateInProcessStream(this.doOperation$, (inProcess$) =>
      rx.pipe(
        rx.withLatestFrom(this.groupFormP.changes$),
        rx.tap(() =>
          this.growlMessages.destroyAllMessages(this.growlId as any),
        ),
        inProcessFilter(inProcess$, ([_op, changes]) => !_.isEmpty(changes)),
        rx.switchMap(([_op, changes]) => {
          return this.prfCurrentGroupOfBrandPlatform.update(changes).pipe(
            rx.catchError((e) => {
              log.error('error saving group', e);
              this.growl.error(_.get(['data', 'message'], e), {
                referenceId: this.growlId as any,
              });
              return rx.obs.NEVER;
            }),
          );
        }),
        rx.tap(() => this.onClose()),
      ),
    );
  }

  streamIsConfirmButtonDisabled() {
    return generateInProcessStream(
      rx.obs.combineLatest(this.groupFormP.isValid$, this.groupFormP.changes$),
      () =>
        rx.pipe(
          rx.map(([isValid, changes]) => {
            if (_.isEmpty(changes)) {
              return true;
            }

            if (!isValid) {
              return true;
            }

            return false;
          }),
          shareReplayRefOne(),
        ),
    );
  }

  streamLoginToSelectedPlatform() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.switchMap((brandPlatform) => {
          if (_.isNil(brandPlatform)) {
            return this.prfCurrentPlatformSession.logout();
          }

          return this.prfCurrentPlatformSession.login(brandPlatform);
        }),
      ),
    );
  }
}

export const BrandPlatformMt4GroupDialogItemComponent = {
  template,
  controller: BrandPlatformMt4GroupDialogItemController,
  require: {
    prfCurrentGroupOfBrandPlatform: '^',
    prfCurrentPlatformSession: '^',
  },
  bindings: {
    brandPlatform: '<',
    mtGroupId: '<',
    operation: '<',
    onClose: '&',
    onDismiss: '&',
  },
};
