import * as _ from '@proftit/lodash';
import { setTokenHeader } from '@proftit/request-client';
import { initReq } from './init-req';

export function initPrivateReqMt4(
  apiUrl: string,
  token: string,
  resourcePath: string,
) {
  return _.flow([
    () => initReq(resourcePath, apiUrl),
    (req) => setTokenHeader(token, req),
  ])();
}
