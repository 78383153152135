import BaseController from '~/source/common/controllers/base';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './platform-form.html';
import { Brand, Platform } from '@proftit/crm.api.models.entities';
import { brandVoipValidations } from '~/source/management/brand/validations.settings';
import { calcValidationsStrForVoipField } from '~/source/management/brand/components/voip/calc-validations-str-for-voip-field';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import BrandsService from '~/source/management/brand/services/brands';
import { Entity } from '@proftit/crm.api.models.general';
import { PlatformCode } from '@proftit/crm.api.models.enums';
const styles = require('./platform-form.component.scss');

class PlatformFormController extends BaseController {
  excludedPlatforms;
  migratablePlatforms;
  connection;
  isMigrate: boolean;
  platformConnectionInputs;
  prevTargetPlatform;
  brand: Brand;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  onPlatformChangeAction = new rx.Subject<Platform>();

  ruleTemplates$ = this.streamRuleTemplates();
  PlatformCode = PlatformCode;
  keysToShowOnlyInCreateMode;

  /*@ngInject */
  constructor(
    readonly $scope,
    readonly brandModuleSettings,
    readonly brandsService: () => BrandsService,
  ) {
    super();
    useStreams(
      [this.onPlatformChangeAction, this.ruleTemplates$],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onInit() {
    /*
     * list of platforms ids that should be excluded from select
     * because they are already selected on other connections
     */
    this.excludedPlatforms = [];
    this.migratablePlatforms = [];

    // fill in defaults
    _.defaults(this.connection, {
      isSyncEnabled: false,
      isDefault: false,
      credentials: [],
    });
    // If target platform is set, set the 'isMigrate' flag
    this.isMigrate = !!this.connection.targetPlatform;

    if (this.connection.platform) {
      this.initPlatformConnectionInputs();
    }
  }

  /**
   * Creating new object combining 'platform credentials' and 'platform fields'
   *
   * @returns {void}
   */
  onPlatformSelect(platform) {
    this.connection.credentials = [];
    this.initPlatformConnectionInputs();
    this.onPlatformChangeAction.next(platform);
  }

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };

  isCreateMode() {
    return _.isNil(this.connection.id);
  }

  /**
   * Initialize the view platform connection inputs.
   *
   */
  initPlatformConnectionInputs() {
    this.connection.ruleTemplate = { id: null };
    const { fields } = this.connection.platform;
    this.platformConnectionInputs = {};

    fields.forEach((field) => {
      let model = this.connection.credentials.find(
        ({ key }) => key === field.name,
      );

      let inputType;
      let inputInitialValue;
      switch (field.type) {
        case 'password':
          inputType = 'password';
          inputInitialValue = '';
          break;
        case 'checkbox':
          inputType = 'checkbox';
          inputInitialValue = false;
          break;
        case 'select':
          inputType = 'select';
          inputInitialValue = { id: null };
          break;
        default:
          inputType = 'text';
          inputInitialValue = '';
          break;
      }

      if (!model) {
        // if model does not exist in the credentials array, create it and push it
        model = { key: field.name, value: inputInitialValue };
        this.connection.credentials.push(model);
      }

      const validationsStr = calcValidationsStrForVoipField(
        brandVoipValidations[field.type],
        field.isRequired,
      );

      this.platformConnectionInputs[field.name] = {
        model,
        isRequired: field.isRequired,
        validator: validationsStr,
        type: inputType,
      };
    });
  }

  /**
   * Called when the 'isDefault' flag is toggled.
   * If the flag is true, it fires a 'setDefault' platform event.
   */
  onDefaultToggle() {
    if (this.connection.isDefault) {
      this.$scope.$emit('platform:setDefault', this.connection);
    }
  }

  /**
   * Called automatically by the parent's controller, when the 'brand' binding changes
   */
  onBrandChange() {
    /**
     * Known limitation: we are only watching 1 level deep here. so if one of the platform
     * connections changes its platform (only possible when adding more than one form are open at the same time)
     * we will not be aware of this change.
     * But since it's an edge case which shouldn't cause too much issues, we are ignoring it.
     */
    this.$scope.$watchCollection(
      () => this.brand.platformConnections,
      this.onBrandPlatformConnectionsChange.bind(this),
    );
  }

  /**
   * Called when there is a change in the brand 'platformConnections' array, or on one of its elements.
   */
  onBrandPlatformConnectionsChange() {
    // an array of the platforms selected in the other brand's platform forms
    const otherPlatforms = this.brand.platformConnections
      .map(({ platform }) => platform)
      .filter(
        (platform) =>
          !_.isEmpty(platform) &&
          // ignore current form's platform
          platform.id !== _.get('platform.id', this.connection),
      );

    // A derivative of 'otherPlatforms': just the ids (used for dropdown 'exclude' binding)
    this.excludedPlatforms = otherPlatforms.map(({ id }) => id);

    // another derivative: just "migratable" platforms
    this.migratablePlatforms = otherPlatforms.filter(
      (platform) => platform.isMigratable,
    );
  }

  /**
   * Returns true if the "migrate to" dropdown should be shown:
   * If the brand has other platforms which are migratable, and if current platform is migratable
   *
   * @returns {boolean} True if Migrate to dropdown should be shown
   */
  showMigrate() {
    return (
      _.get('platform.isMigratable', this.connection) &&
      this.migratablePlatforms.length > 0
    );
  }

  /**
   * Called when the user checks/unchecks the "migrate" flag
   */
  onToggleMigration() {
    if (!this.isMigrate) {
      // migration disabled
      // save previously selected target platform
      this.prevTargetPlatform = Object.assign(
        {},
        this.connection.targetPlatform,
      );
      // reset the target platform that should be sent to the server
      this.connection.targetPlatform = null;
      return;
    }
    // restore target platform, because migration has been enabled again
    this.connection.targetPlatform = this.prevTargetPlatform;
  }

  streamRuleTemplates() {
    return rx.pipe(
      () => this.onPlatformChangeAction,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((platform) => {
        return rx.obs
          .from(
            this.brandsService()
              .setConfig({ blockUiRef: 'platformConnections' })
              .embed(['platformConnections'])
              .filter({
                'platformConnections.platformId': platform.id,
              })
              .getListWithQuery(),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.map((brands) => {
        return [{ id: null, name: 'None' }, ...brands];
      }),
      shareReplayRefOne(),
    )(null);
  }

  isCryptoEnabled() {
    if (_.isNil(this.connection)) {
      return false;
    }
    if (_.isNil(this.connection.credentials)) {
      return false;
    }
    const isCryptoObj = this.connection.credentials.find(
      (cred) => cred.key === 'isCrypto',
    );
    if (_.isNil(isCryptoObj)) {
      return false;
    }
    return isCryptoObj.value;
  }
}

export default {
  template,
  bindings: {
    connection: '=',
    brand: '<',
    brandPlatformConnections: '<platformConnections',
    remove: '&removeFn',
    removeValidate: '&removeValidateFn',
    keysToShowOnlyInCreateMode: '<',
  },
  controller: PlatformFormController,
  controllerAs: 'vm',
};
