export const cols = [
  {
    title: 'BRAND_NAME',
    field: 'brand',
    fieldFormat: '{{ brand.name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MAX_INVESTMENT',
    field: 'maxInvestment',
    fieldFormat: '{{ maxInvestment }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MINIMUM_INVESTMENT',
    field: 'minInvestmentCfd',
    fieldFormat: '{{ minInvestment }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MAX_TAKE_PROFIT',
    field: '',
    fieldFormat: '{{ maxTakeProfit }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MIN_STOP_LOSS',
    field: '',
    fieldFormat: '{{ minStopLoss }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.DEFAULT_SYMBOL',
    field: '',
    fieldFormat: '{{ defaultAssetName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    renderer: 'swapCommission',
    headerTooltipIcon: 'riskSwap',
    title: 'riskManager.SWAP_COMMISSION',
    field: '',
    fieldFormat:
      '{{ swapCommission }} {{ "cfdPlatformBrandsTable.SWAP_INNER_UNITS_OR" | translate }} {{ (swapRatio | prfZeroOneAsPercentage)|| "0" }} %',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    // This is empty col for control settings
    renderer: 'actions',
    title: '',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
