import * as _ from '@proftit/lodash';
import { HaveLeafBehavior } from './have-leaf-behavior';
import { LeafBehavior } from './leaf-behavior';

interface FieldProxyHandlerConfig {
  isReadonly?: boolean;
  validators?: any[];
}

export class FieldProxyHandler<T> implements HaveLeafBehavior<T> {
  leaf = new LeafBehavior<T>({
    onCalcChanged: () => {
      throw new Error('not implemented');
    },
    onGetChanged: () => {
      throw new Error('not implemented');
    },
    onGetInitial: () => {
      return this.initialValue;
    },
    onGetValue: () => {
      return this.value;
    },
    onSetValue: (value) => {
      this.value = value;
    },
    onSetValueAsInitial: (value) => {
      this.value = value;
      this.initialValue = value;
    },
    onGetValidationContext: () => this,
  });

  initialValue: T;

  value: T;

  constructor(config: FieldProxyHandlerConfig) {
    this.leaf.setIsReadonly(_.defaultTo(false, config.isReadonly));
    this.leaf.setValidators(_.defaultTo([], config.validators));
  }

  getLeaf() {
    return this.leaf;
  }
}
