import ng from 'angular';
import HeaderFieldComponent from './header-field';
import ConfirmFieldComponent from './confirm-field';
import QuestionFieldComponent from './question-field';
import BooleanQuestionFieldComponent from './boolean-question-field';
import MultiSelectFieldComponent from './multi-select-field';
import SingleSelectFieldComponent from './single-select-field';
import DateFieldComponent from './date-field';

const ngModule = ng
  .module('crm.questionnaire.formComponents', [])
  .component(
    'prfQuestionnaireBooleanQuestionField',
    BooleanQuestionFieldComponent,
  )
  .component('prfQuestionnaireConfirmField', ConfirmFieldComponent)
  .component('prfQuestionnaireHeaderField', HeaderFieldComponent)
  .component('prfQuestionnaireMultiSelectField', MultiSelectFieldComponent)
  .component('prfQuestionnaireQuestionField', QuestionFieldComponent)
  .component('prfQuestionnaireSingleSelectField', SingleSelectFieldComponent)
  .component('prfQuestionnaireDateField', DateFieldComponent);

export default ngModule.name;
