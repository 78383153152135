import * as _ from '@proftit/lodash';
import { ContainerBean, BeanValidationResult } from '@proftit/proxy-bean';

export const FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED =
  'FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED';

export function ftdAutoAssignmentWhenOtherFilledUserMustBeFilledProxyValidator(
  bean: ContainerBean,
): BeanValidationResult {
  if (
    _.isNil(bean.proxy['sourceDesk']) &&
    _.isNil(bean.proxy['destinationDesk'])
  ) {
    return {
      isValid: true,
      code: FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED,
    };
  }

  if (!_.isNil(bean.proxy['assigneeUser'])) {
    return {
      isValid: true,
      code: FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED,
    };
  }

  return {
    isValid: false,
    code: FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED,
  };
}
