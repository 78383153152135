import template from './layout.html';
import DownloadsPopupService from '~/source/reports/downloads-popup.service';
import { GeneralSharedWorkerService } from '../services/general-shared-worker.service';
import { TokensService } from '~/source/auth/services/tokens';
import { NoticePopupService } from '~/source/common/services/notice-popup-service';

class Controller {
  /* @ngInject */
  constructor(
    readonly downloadsPopupService: DownloadsPopupService,
    readonly tokensService: TokensService,
    readonly prfGeneralSharedWorkerService: GeneralSharedWorkerService,
    readonly prfNoticePopupService: NoticePopupService,
  ) {}

  $onInit() {
    this.prfGeneralSharedWorkerService.setCurrentLoggedUser(
      this.tokensService.getCachedUser(),
    );
    this.downloadsPopupService.startListening();
  }

  $onDestroy() {
    this.downloadsPopupService.stopListening();
  }
}

const LayoutComponent = {
  template,
  controller: Controller,
};

export default LayoutComponent;
