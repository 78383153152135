export enum UserNotificationCategoryCode {
  Assignments = 'assignments',
  FollowUps = 'followUps',
  FtdAutoAssignment = 'ftdAutoAssignment',
  Verification = 'verification',
  Reports = 'reports',
  Tasks = 'tasks',
  TradingActivity = 'tradingActivity',
  Transactions = 'transactions',
}
