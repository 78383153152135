import log from 'loglevel';

import { BrandsService } from '~/source/management/brand/services/brands';
import { SocketService } from '~/source/common/services/socket';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { getCfdPlatformStreamerUrlCredential } from '~/source/common/models/platform-connection/get-cfd-platform-streamer-url-credential';

export type SocketListener = (msg: string) => void;

export class CfdPlatformSocketService extends SocketService {
  static $inject = ['brandsService', ...SocketService.$inject];

  /* injections */
  brandsService: () => BrandsService;

  token: string;
  platformStreamerUrl: string;

  get socketRegistryKey() {
    return 'cfdPlatformSocket';
  }

  getStreamerUrl() {
    return this.platformStreamerUrl;
  }

  buildSocketQuery() {
    return `token=${this.token}`;
  }

  get channelRoot() {
    return '{feed}.primary.asset';
  }

  setToken(token: string) {
    this.token = token;
  }

  setStreamerUrl(url: string) {
    this.platformStreamerUrl = `${url}/`;
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }
}

export default CfdPlatformSocketService;
