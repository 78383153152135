import BaseController from '~/source/common/controllers/base';
import VerificationStatusMethodsService from '~/source/common/services/verification-status-methods';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './verified-method-form.html';

class Controller extends BaseController {
  selectedMethod: any;
  brand: Brand;

  verificationStatusMethods: any;

  /*@ngInject */
  constructor(
    readonly verifiedMethodsService: VerificationStatusMethodsService,
  ) {
    super();
  }

  /**
   * Get all verification status methods
   * @return {void}
   */
  $onInit() {
    this.selectedMethod = this.selectedMethod || {};

    this.verifiedMethodsService.getListWithQuery().then((data) => {
      this.verificationStatusMethods = data;
    });
  }
}

const VerifiedMethodFormComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    selectedMethod: '=',
  },
  require: {
    form: '^form',
  },
};

export default VerifiedMethodFormComponent;
