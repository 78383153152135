import ng from 'angular';

import { CommunicationsListComponent } from './components/communications-list/communications-list.component';
import { CommunicationsDashboardComponent } from './components/communications-dashboard/communications-dashboard.component';
import { communicationsRouting } from './communications.routing';
import { CommunicationsUpdateComponent } from './components/communications-update/communications-update.component';

export const CommunicationsModule = ng.module(
  'crm.integrations.communications',
  [],
);

CommunicationsModule.config(communicationsRouting);

CommunicationsModule.component(
  'prfCommunicationsDashboard',
  CommunicationsDashboardComponent,
)
  .component('prfCommunicationsList', CommunicationsListComponent)
  .component('prfCommunicationsUpdate', CommunicationsUpdateComponent);

export default CommunicationsModule;
