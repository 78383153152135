import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-region.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Editable } from '~/source/common/utilities/editable';
import { FormArray, FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import {
  MarketingCampaignReward,
  MarketingCampaignRewardsSource,
  MarketingCampaignRewardType,
} from '~/source/common/data-sources/marketing-campaign-rewards-source';
import {
  AutomaticFtdDepositReward,
  AutomaticNonFtdDepositReward,
  AutomaticRegistrationReward,
  Currency,
} from '@proftit/crm.api.models.entities';
import { generateUuid } from '@proftit/general-utilities';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { automaticBonusAllOrNonFilled } from '~/source/marketing/components/automatic-bonus/automatic-bonus-region/bonus-form-group-validators';
import { PlatformCode } from '@proftit/crm.api.models.enums';

const styles = require('./automatic-bonus-region.component.scss');

export class AutomaticBonusRegionController {
  PlatformCode = PlatformCode;
  MarketingCampaignRewardType = MarketingCampaignRewardType;
  regionId: number;
  isCreate: boolean;
  currencies: Currency[];
  onSelectedBonusType: (a: { regionId: number; bonus }) => void;
  onChangeBonuses: (a: {
    bonus: MarketingCampaignRewardsSource;
    bonusesData: any[];
  }) => void;
  onAddRegion: () => void;
  data: any[];
  campaignId: number;
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  editManager = new Editable<any>();
  marketingCampaignsServiceInst: MarketingCampaignsService;
  blockUiId = generateBlockuiId();

  addRegionAction$ = new rx.Subject<void>();
  onAddDepositRowAction = new rx.Subject<{
    currencyId: number;
    fromValue: number;
  }>();

  onDeleteDepositRowAction = new rx.Subject<{ rowUuid: string }>();
  afterSaveAction = new rx.Subject<any[]>();
  clearFormAction = new rx.Subject<void>();

  selectedMarketingCampaignRewardTypeFormControl = new FormControl<
    MarketingCampaignRewardType
  >(null);

  data$ = observeShareCompChange<any[]>(this.lifecycles.onChanges$, 'data');
  currencies$ = observeShareCompChange<Currency[]>(
    this.lifecycles.onChanges$,
    'currencies',
  );
  selectedBonusType$ = observeShareCompChange<MarketingCampaignRewardsSource>(
    this.lifecycles.onChanges$,
    'selectedBonusType',
  );

  model$ = this.streamModel();
  isOneTimeNonFtdBonuses$ = this.streamIsOneTimeNonFtdBonuses();
  isOneTimeNonFtdBonusesFormControl$ = this.streamIsOneTimeNonFtdBonusesFormControl();
  formArrayFromSelectedBonus$ = this.streamFormArrayFromSelectedBonus();
  formArray$ = this.streamFormArray();
  formDataPerCurrency$ = this.streamFormDataPerCurrency();
  currencyIdToCurrencyMap$ = this.streamCurrencyIdToCurrencyMap();
  isFormArrayValid$ = this.streamIsFormArrayValid();

  /* @ngInject */
  constructor(
    readonly marketingCampaignsService: () => MarketingCampaignsService,
  ) {
    useStreams(
      [
        this.editManager.initiator$,
        this.streamAddRegion(),
        this.formArray$.pipe(
          rx.switchMap((arrayControl) => arrayControl.value$),
        ),
        this.isFormArrayValid$,
        this.model$,
        this.formDataPerCurrency$,
        this.isOneTimeNonFtdBonuses$,
        this.streamSave(),
        this.currencyIdToCurrencyMap$,
        this.streamOnChangeIsOneTime(),
        this.streamOnChangeFormArray(),
        this.isOneTimeNonFtdBonusesFormControl$,
        this.selectedMarketingCampaignRewardTypeFormControl.value$,
        this.streamRewardType(),
      ],
      this.lifecycles.onDestroy$,
    );

    this.marketingCampaignsServiceInst = marketingCampaignsService();
  }

  streamIsOneTimeNonFtdBonusesFormControl(): rx.Observable<
    FormControl<boolean>
  > {
    const currentValue = new rx.BehaviorSubject<boolean>(null);
    return rx.pipe(
      () =>
        rx.obs
          .merge(
            this.streamIsOneTimeNonFtdBonusesFromModel(),
            this.streamIsOneTimeNonFtdBonusesFromCancel(currentValue),
            this.streamIsOneTimeNonFtdBonusesFromSave(),
            this.streamIsOneTimeNonFtdBonusesClearAll(),
          )
          .pipe(rx.startWith(false)),
      rx.tap((newValue) => {
        currentValue.next(newValue);
      }),
      rx.map((newValue) => new FormControl<boolean>(newValue)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsOneTimeNonFtdBonusesClearAll() {
    return rx.pipe(
      () => this.clearFormAction,
      rx.map(() => null),
      shareReplayRefOne(),
    )(null);
  }

  streamIsOneTimeNonFtdBonusesFromModel() {
    return rx.pipe(
      () => this.isOneTimeNonFtdBonuses$,
      rx.map((value) => value),
      shareReplayRefOne(),
    )(null);
  }

  streamIsOneTimeNonFtdBonusesFromCancel(currentValue: rx.Observable<boolean>) {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.withLatestFrom(currentValue),
      rx.map(([a, value]) => value),
      shareReplayRefOne(),
    )(null);
  }

  streamIsOneTimeNonFtdBonusesFromSave() {
    return rx.pipe(
      () => this.editManager.afterSaveAction,
      rx.withLatestFrom(this.isOneTimeNonFtdBonuses$),
      rx.map(([a, value]) => value),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamIsFormArrayValid() {
    return rx.pipe(
      () => this.formArray$,
      rx.switchMap((formArray) => formArray.isValid$),
      shareReplayRefOne(),
    )(null);
  }

  streamModel() {
    return rx.pipe(
      () => rx.obs.merge(this.data$, this.afterSaveAction),
      shareReplayRefOne(),
    )(null);
  }

  streamOnChangeIsOneTime() {
    return rx.pipe(
      () =>
        this.isOneTimeNonFtdBonusesFormControl$.pipe(
          rx.switchMap((formControl) => formControl.value$),
        ),
      rx.withLatestFrom(
        this.selectedBonusType$,
        this.formArray$.pipe(
          rx.switchMap((arrayControl) => arrayControl.controls$),
        ),
      ),
      rx.filter(
        ([a, selectedBonus]) =>
          selectedBonus.type === MarketingCampaignReward.DepositNonFtd,
      ),
      rx.tap(([isOneTime, selectedBonus, formGroups]) => {
        formGroups.forEach((formGroup) => {
          ((formGroup as FormGroup).controls.isOneTime as FormControl<
            boolean
          >).setValue(isOneTime as boolean);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCurrencyIdToCurrencyMap() {
    return rx.pipe(
      () => this.currencies$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((currencies) => {
        const currencyIdToCurrencyMap = {};
        currencies.forEach((currency) => {
          currencyIdToCurrencyMap[String(currency.id)] = currency;
        });
        return currencyIdToCurrencyMap;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsOneTimeNonFtdBonuses() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.selectedBonusType$, this.model$),
      rx.filter(([bonus]) => !_.isNil(bonus)),
      rx.filter(
        ([bonus]) => bonus.type === MarketingCampaignReward.DepositNonFtd,
      ),
      rx.filter(([bonusType, data]) => !_.isNil(data)),
      rx.map(([bonusType, data]) => {
        if (data.every((item) => item.isOneTime)) {
          return true;
        }
        if (data.every((item) => !item.isOneTime)) {
          return false;
        }
        throw new Error(
          'Non Ftd bonuses data is not valid. There is a mix between oneTime and multiple bonuses. (isOneTime property)',
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRewardType() {
    const lastValidValue$ = new rx.BehaviorSubject<MarketingCampaignRewardType>(
      null,
    );
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamRewardTypeFromModel(),
          this.streamRewardTypeFromCancel(lastValidValue$),
        ),
      rx.tap((value: MarketingCampaignRewardType) => {
        lastValidValue$.next(value);
        this.selectedMarketingCampaignRewardTypeFormControl.setValue(value);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRewardTypeFromModel(): rx.Observable<MarketingCampaignRewardType> {
    return rx.pipe(
      () => this.model$.pipe(rx.distinctUntilChanged()),
      rx.filter(
        (receivedData) => !_.isNil(receivedData) && receivedData.length > 0,
      ),
      rx.map((receivedData) => {
        if (
          receivedData.every(
            (item) =>
              item.rewardType === this.MarketingCampaignRewardType.Credit,
          )
        ) {
          return this.MarketingCampaignRewardType.Credit;
        }
        if (
          receivedData.every(
            (item) =>
              item.rewardType === this.MarketingCampaignRewardType.Bonus,
          )
        ) {
          return this.MarketingCampaignRewardType.Bonus;
        }
        return null;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRewardTypeFromCancel(
    lastValidValue$: rx.BehaviorSubject<MarketingCampaignRewardType>,
  ): rx.Observable<MarketingCampaignRewardType> {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.withLatestFrom(lastValidValue$),
      rx.map(([a, lastValidValue]) => lastValidValue),
      shareReplayRefOne(),
    )(null);
  }

  streamFormDataPerCurrency() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.currencies$,
          this.formArray$.pipe(
            rx.switchMap((arrayControl) => arrayControl.controls$),
          ),
        ),
      rx.filter(
        ([currencies, formGroups]) =>
          !_.isNil(currencies) && !_.isNil(formGroups),
      ),
      rx.map(([currencies, formGroups]) => {
        const currencyMap = {};
        currencies.map((currency) => {
          const formGroupsForCurrency = formGroups.filter(
            (formGroup) => formGroup.value.currencyId === currency.id,
          );
          if (formGroupsForCurrency.length > 0) {
            currencyMap[currency.id] = formGroupsForCurrency;
          }
        });
        return currencyMap;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnChangeFormArray() {
    const lastValue$ = new rx.BehaviorSubject(null);
    return rx.pipe(
      () =>
        this.formArray$.pipe(
          rx.switchMap((arrayControl) => arrayControl.value$),
        ),
      rx.withLatestFrom(this.selectedBonusType$, this.formArray$, lastValue$),
      rx.filter(([formGroups, bonus]) => !_.isNil(bonus)),
      rx.tap(([formGroups, bonus, formArray, lastValue]) => {
        const nextValue = {
          bonus,
          bonusesData: this.normalizeBonuses(formArray.value, !this.isCreate),
        };

        if (_.isEqual(lastValue, nextValue)) {
          return;
        }

        this.onChangeBonuses(nextValue);
        lastValue$.next(nextValue);
      }),
      shareReplayRefOne(),
    )(null);
  }

  isMultiplierAllowed() {
    return (
      this.selectedMarketingCampaignRewardTypeFormControl.value !==
      MarketingCampaignRewardType.Credit
    );
  }

  getEmptyRegistrationBonus(currencyId: number): AutomaticRegistrationReward {
    return {
      currencyId,
      multiplier: this.isMultiplierAllowed() ? null : undefined,
      rewardAmount: null,
    };
  }

  getEmptyFtdBonus(currencyId: number): AutomaticFtdDepositReward {
    return {
      currencyId,
      rewardAmountType: null,
      rewardAmount: null,
      multiplier: this.isMultiplierAllowed() ? null : undefined,
      from: null,
      to: null,
    };
  }

  getEmptyNonFtdBonus(currencyId: number): AutomaticNonFtdDepositReward {
    return {
      currencyId,
      rewardAmountType: null,
      rewardAmount: null,
      multiplier: this.isMultiplierAllowed() ? null : undefined,
      from: null,
      to: null,
      isOneTime: null,
    };
  }

  getFormGroupsForBonusType(
    bonusType: MarketingCampaignReward,
    data: any[],
    currencyId: number,
  ): FormGroup[] {
    let formGroups: FormGroup[];
    switch (bonusType) {
      case MarketingCampaignReward.Registration:
        if (!_.isNil(data) && data.length > 0) {
          formGroups = data.map((bonus) =>
            this.generateRegistrationFormGroup(bonus),
          );
        } else {
          formGroups = [
            this.generateRegistrationFormGroup(
              this.getEmptyRegistrationBonus(currencyId),
            ),
          ];
        }
        break;
      case MarketingCampaignReward.DepositFtd:
        if (!_.isNil(data) && data.length > 0) {
          formGroups = data.map((bonus) => this.generateFtdFormGroup(bonus));
        } else {
          formGroups = [
            this.generateFtdFormGroup(this.getEmptyFtdBonus(currencyId)),
          ];
        }
        break;
      case MarketingCampaignReward.DepositNonFtd:
        if (!_.isNil(data) && data.length > 0) {
          formGroups = data.map((bonus) => this.generateNonFtdFormGroup(bonus));
        } else {
          formGroups = [
            this.generateNonFtdFormGroup(this.getEmptyNonFtdBonus(currencyId)),
          ];
        }
        break;
      default:
        break;
    }
    return formGroups;
  }

  generateFormArray(
    data: any[],
    bonusType: MarketingCampaignReward,
    currencies: Currency[],
  ) {
    const safeData = _.isNil(data) ? [] : data;
    let formGroups = [];
    currencies.forEach((currency) => {
      const dataForCurrency = safeData.filter(
        (dataItem) => dataItem.currencyId === currency.id,
      );
      const formGroupsForCurrency = this.getFormGroupsForBonusType(
        bonusType,
        dataForCurrency,
        currency.id,
      );
      formGroups = [...formGroups, ...formGroupsForCurrency];
    });
    return new FormArray(formGroups);
  }

  generateRegistrationFormGroup(
    registrationBonus: AutomaticRegistrationReward,
  ) {
    const nullValue = null;
    const controls = {
      id: new FormControl<string>(generateUuid()),
      rewardAmount: new FormControl<number>(
        _.getEs(registrationBonus, 'rewardAmount', nullValue),
      ),
      currencyId: new FormControl<number>(
        _.getEs(registrationBonus, 'currencyId', nullValue),
      ),
    };

    if (this.isMultiplierAllowed()) {
      controls['multiplier'] = new FormControl<number>(
        _.getEs(registrationBonus, 'multiplier', nullValue),
      );
    }

    return new FormGroup(controls as any, {
      validators: [automaticBonusAllOrNonFilled],
    });
  }

  generateFtdFormGroup(ftdBonus: AutomaticFtdDepositReward) {
    const nullValue = null;
    const controls = {
      id: new FormControl<string>(generateUuid()),
      rewardAmount: new FormControl<number>(
        _.defaultTo(nullValue, ftdBonus.rewardAmount),
      ),
      from: new FormControl<number>(_.defaultTo(nullValue, ftdBonus.from)),
      to: new FormControl<number>(_.defaultTo(nullValue, ftdBonus.to)),
      rewardAmountType: new FormControl<string>(
        _.defaultTo(nullValue, ftdBonus.rewardAmountType),
      ),
      currencyId: new FormControl<number>(
        _.defaultTo(nullValue, ftdBonus.currencyId),
      ),
    };
    if (this.isMultiplierAllowed()) {
      controls['multiplier'] = new FormControl<number>(
        _.getEs(ftdBonus, 'multiplier', nullValue),
      );
    }
    return new FormGroup(controls as any, {
      validators: [automaticBonusAllOrNonFilled],
    });
  }

  generateNonFtdFormGroup(nonFtdBonus: AutomaticNonFtdDepositReward) {
    const nullValue = null;

    const controls = {
      id: new FormControl<string>(generateUuid()),
      rewardAmount: new FormControl<number>(
        _.defaultTo(nullValue, nonFtdBonus.rewardAmount),
      ),
      from: new FormControl<number>(_.defaultTo(nullValue, nonFtdBonus.from)),
      to: new FormControl<number>(_.defaultTo(nullValue, nonFtdBonus.to)),
      rewardAmountType: new FormControl<string>(
        _.defaultTo(nullValue, nonFtdBonus.rewardAmountType),
      ),
      currencyId: new FormControl<number>(
        _.defaultTo(nullValue, nonFtdBonus.currencyId),
      ),
      isOneTime: new FormControl<boolean>(
        _.defaultTo(nullValue, nonFtdBonus.isOneTime),
      ),
    };
    if (this.isMultiplierAllowed()) {
      controls['multiplier'] = new FormControl<number>(
        _.getEs(nonFtdBonus, 'multiplier', nullValue),
      );
    }
    return new FormGroup(controls as any, {
      validators: [automaticBonusAllOrNonFilled],
    });
  }

  streamFormArray(): rx.Observable<FormArray> {
    const currentFormArray = new rx.BehaviorSubject<FormArray>(
      new FormArray([]),
    );
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.formArrayFromSelectedBonus$,
          this.streamFormArrayFromRewardTypeChange(currentFormArray),
          this.streamFormArrayFromAddDepositRow(currentFormArray),
          this.streamFormArrayFromDeleteDepositRow(currentFormArray),
          this.streamFormArrayFromCancel(),
          this.streamFormArrayFromSave(),
          this.streamFormArrayFromClearAll(),
        ),
      rx.tap((formArray) => {
        currentFormArray.next(formArray);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromRewardTypeChange(
    latestFormArray: rx.BehaviorSubject<FormArray>,
  ) {
    return rx.pipe(
      () => this.selectedMarketingCampaignRewardTypeFormControl.value$,
      rx.withLatestFrom(
        latestFormArray,
        this.selectedBonusType$,
        this.currencies$,
      ),
      rx.filter(
        ([rewardType, formArray, bonus, currencies]) =>
          !_.isNil(rewardType) && !_.isNil(bonus) && !_.isNil(currencies),
      ),
      rx.map(([rewardType, formArray, bonus, currencies]) => {
        return this.generateFormArray(formArray.value, bonus.type, currencies);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromClearAll() {
    return rx.pipe(
      () => this.clearFormAction,
      rx.withLatestFrom(this.selectedBonusType$, this.currencies$),
      rx.filter(
        ([a, bonusType, currencies]) =>
          !_.isNil(bonusType) && !_.isNil(currencies),
      ),
      rx.map(([a, bonus, currencies]) =>
        this.generateFormArray([], bonus.type, currencies),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromCancel() {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.withLatestFrom(this.selectedBonusType$, this.currencies$, this.model$),
      rx.filter(
        ([a, bonusType, currencies, model]) =>
          !_.isNil(bonusType) && !_.isNil(currencies),
      ),
      rx.map(([a, bonus, currencies, model]) =>
        this.generateFormArray(model, bonus.type, currencies),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromSelectedBonus() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.selectedBonusType$,
          this.currencies$,
          this.model$,
        ),
      rx.filter(
        ([bonusType, currencies, model]) =>
          !_.isNil(bonusType) && !_.isNil(currencies),
      ),
      rx.map(([bonus, currencies, model]) =>
        this.generateFormArray(model, bonus.type, currencies),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromAddDepositRow(currentFormArray: rx.Observable<FormArray>) {
    return rx.pipe(
      () => this.onAddDepositRowAction,
      rx.withLatestFrom(
        currentFormArray,
        this.selectedBonusType$,
        this.isOneTimeNonFtdBonusesFormControl$.pipe(
          rx.switchMap((formControl) => formControl.value$),
        ),
      ),
      rx.map(
        ([
          { currencyId, fromValue },
          currentFormArray,
          selectedBonus,
          isOneTimeNonFtdBonuses,
        ]) => {
          let formGroupToAdd: FormGroup;
          switch (selectedBonus.type) {
            case MarketingCampaignReward.DepositFtd:
              formGroupToAdd = this.generateFtdFormGroup({
                ...this.getEmptyFtdBonus(currencyId),
                from: !_.isNil(fromValue) ? fromValue + 1 : null,
              });
              break;
            case MarketingCampaignReward.DepositNonFtd:
              formGroupToAdd = this.generateNonFtdFormGroup({
                ...this.getEmptyNonFtdBonus(currencyId),
                isOneTime: isOneTimeNonFtdBonuses as boolean,
                from: !_.isNil(fromValue) ? fromValue + 1 : null,
              });
              break;
            default:
              return currentFormArray;
          }
          currentFormArray.push(formGroupToAdd);
          return currentFormArray;
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromDeleteDepositRow(
    currentFormArray: rx.Observable<FormArray>,
  ) {
    return rx.pipe(
      () => this.onDeleteDepositRowAction,
      rx.withLatestFrom(
        currentFormArray.pipe(
          rx.switchMap((arrayControl) => arrayControl.controls$),
        ),
      ),
      rx.map(([{ rowUuid }, formGroups]) => {
        return formGroups.findIndex((formGroup) => {
          return formGroup.value.id === rowUuid;
        });
      }),
      rx.withLatestFrom(currentFormArray),
      rx.map(([index, currentFormArray]) => {
        currentFormArray.removeAt(index);
        return currentFormArray;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormArrayFromSave() {
    return rx.pipe(
      () => this.afterSaveAction,
      rx.withLatestFrom(this.selectedBonusType$, this.currencies$, this.model$),
      rx.filter(
        ([a, bonusType, currencies, model]) =>
          !_.isNil(bonusType) && !_.isNil(currencies),
      ),
      rx.map(([a, bonus, currencies, model]) =>
        this.generateFormArray(model, bonus.type, currencies),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSave() {
    return rx.pipe(
      () => this.editManager.saveAction,
      rx.withLatestFrom(this.formArray$, this.selectedBonusType$),
      rx.filter(([a, formArray, selectedBonus]) => !_.isNil(selectedBonus)),
      rx.map(([a, formArray, selectedBonus]) => {
        return {
          bonusType: selectedBonus.type,
          data: this.normalizeBonuses(formArray.value),
        };
      }),
      rx.switchMap(({ bonusType, data }) => {
        return this.marketingCampaignsServiceInst
          .setConfig({ blockUiRef: this.blockUiId })
          .massReplaceAutomaticRewards(this.campaignId, bonusType, data)
          .catch((e) => {
            return null;
          });
      }),
      rx.filter((x) => !_.isNil(x)),
      rx.tap((data) => {
        this.afterSaveAction.next(data);
        this.editManager.saveIsDoneAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  normalizeBonuses(bonusesData, filterResults = true): any[] {
    const bonuses = bonusesData.map((bonus) => {
      const { id, ...rest } = bonus;
      return rest;
    });
    const filteredBonuses = bonuses.filter((bonus) =>
      filterResults ? !this.areBonusValuesEmpty(bonus) : true,
    );
    return filteredBonuses.map((bonus) => {
      return {
        ...bonus,
        rewardType: this.selectedMarketingCampaignRewardTypeFormControl.value,
      };
    });
  }

  areBonusValuesEmpty(bonus: any): boolean {
    const { currencyId, ...rest } = bonus;
    const keyValuePairs = _.toPairs(rest);
    return !keyValuePairs.every(([key, value]) => !_.isNil(value));
  }

  streamAddRegion() {
    return rx.pipe(
      () => this.addRegionAction$,
      rx.tap(() => {
        this.onAddRegion();
      }),
    )(null);
  }
}

export const AutomaticBonusRegionComponent = {
  template,
  controller: AutomaticBonusRegionController,
  bindings: {
    selectableBonusTypes: '<',
    regionId: '<',
    isAddTypeDisabled: '<',
    currencies: '<',
    selectedBonusType: '<',
    data: '<',
    isCreate: '<',
    campaignId: '<',
    platform: '<',
    onAddRegion: '&',
    onSelectedBonusType: '&',
    onChangeBonuses: '&',
  },
};
