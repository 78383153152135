import RestService from '~/source/common/services/rest';

export default class DepositStatusesService extends RestService {
  get resource() {
    return 'depositStatuses';
  }

  useCache() {
    return true;
  }
}
