import * as _ from '@proftit/lodash';
import { FieldProxyHandler } from '../field-proxy-handler';

const OR_COMBINE = 'OR_COMBINE';

export function orCombineProxyValidatorFactory(validators: any[]) {
  return function orCombineProxyValidator(handler: FieldProxyHandler<any>) {
    const result = validators.reduce((acc, validator) => {
      if (acc && acc.isValid) {
        return acc;
      }

      return validator(handler);
    }, null);

    if (_.isNil(result)) {
      return {
        code: OR_COMBINE,
        isValid: true,
      };
    }

    return result;
  };
}
