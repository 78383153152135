import * as _ from '@proftit/lodash';

/**
 */
export function maxNotRequired(value, scope, element, attrs, param) {
  if (!_.isFinite(parseInt(value, 10))) {
    return true;
  }

  return parseInt(value, 10) <= parseInt(param, 10);
}

export default maxNotRequired;
