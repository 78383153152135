import { observeComponentLifecycles as useLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as _ from '@proftit/lodash';
import { StateService } from '@uirouter/core';

export class CrmAppRouteProviderController {
  lifecycles = useLifecycles(this);

  // @ngInject
  constructor(readonly $state: StateService) {}

  goToCreatePromoCode(p: { brandId: number; platformId: number }) {
    this.$state.go('crm.management.tools.automation.promoCode.create', {
      brandId: p.brandId,
      platformId: p.platformId,
    });
  }

  goToEditPromoCode(promoCodeId: number, brandId: number, platformId: number) {
    this.$state.go('crm.management.tools.automation.promoCode.edit', {
      brandId,
      platformId,
      id: promoCodeId,
    });
  }

  goToListPromoCode({
    brandId,
    platformId,
  }: {
    brandId: number;
    platformId: number;
  }) {
    this.$state.go('crm.management.tools.automation.promoCode.list', {
      brandId,
      platformId,
    });
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const CrmAppRouteProviderComponent = {
  controller: CrmAppRouteProviderController,
  template: '<ng-transclude></ng-transclude>',
  transclude: true,
};
