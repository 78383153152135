import RestService from '~/source/common/services/rest';
import log from 'loglevel';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { CurrencyRate } from '~/source/common/models/currency-rate';
import moment from 'moment';

interface Rate {
  rate: number;
  baseCurrencyCode: string;
}

export class CurrencyRatesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'currencyRates';
  }

  /**
   * This resource is public
   *
   * @returns {string}
   */
  get accessType() {
    return 'public';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }

  /**
   * get all rates of currencies
   * @returns exchange rates
   */
  getCurrencyRates() {
    return this.filter('relevantAt', this.getDate()).getListWithQuery<
      IElementRestNg<CurrencyRate>
    >();
  }

  /**
   * get the rate for a currency
   * @Params rates - array of currencies rates,
   * @Params symbol - the currency to get the rate for,
   * @returns the rate requested if exists
   */
  getRate(rates: Rate[], symbol: string): number {
    if (symbol === 'USD') {
      return 1;
    }
    const rateInfo = rates.find((rate) => symbol === rate.baseCurrencyCode);
    if (rateInfo) {
      return rateInfo.rate;
    }

    log.error('No rates Found for currency', rateInfo?.baseCurrencyCode);
    throw new Error('No rates Found for currency');
  }

  /**
   * get getYesterdayDate as YYYY-MM-DD
   * @returns yesterday date
   */
  getYesterdayDate() {
    return moment(Date.now()).add(-1, 'days').format('YYYY-MM-DD');
  }

  getDate() {
    return moment(Date.now()).format('YYYY-MM-DD');
  }
}

export default CurrencyRatesService;
