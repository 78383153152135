import { CfdPositionStreamerUpdate } from './cfd-position-streamer-update';
import { CustomerPosition } from '@proftit/crm.api.models.entities';

/**
 * Calc profit from cfd streamer update.
 *
 * @param original - Origina position data.
 * @param updates - Incoming streamer update.
 * @return result calculated profit.
 */
export function calcProfitFromCfdUpdate(
  original: CustomerPosition,
  updates: CfdPositionStreamerUpdate,
) {
  return updates.payout - original.investment;
}
