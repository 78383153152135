import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './edit-comment-form.component.html';

const styles = require('./edit-comment-form.component.scss');

import { buildEditCommentForm } from './build-edit-comment-form';
import { useStreams } from '@proftit/rxjs.adjunct';
import { CustomerCommunication } from '@proftit/crm.api.models.entities';
import { DateTzAdjustService } from '~/source/common/services/date-tz-adjust';
import { CustomerCommunicationForm } from '../customer-communicatoin-form';
import moment from 'moment-timezone';

export class EditCommentFormController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  onChange: (x: { updatedCall: CustomerCommunicationForm }) => void;

  minDate: Date = new Date();

  isDatePickerOpen = false;

  form = buildEditCommentForm();

  call$ = observeShareCompChange<CustomerCommunication>(
    this.lifecycles.onChanges$,
    'call',
  );

  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  /* @ngInject */
  constructor(readonly dateTzAdjustService: DateTzAdjustService) {
    useStreams([this.call$, this.form.value$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamFillFormFromCall(),
        this.streamFormValidOut(),
        this.streamNotifyOnChanges(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamFillFormFromCall() {
    return rx.pipe(
      () => this.call$,
      rx.filter((call) => !_.isNil(call)),
      rx.tap((call) => {
        const hasFollowUpDate = !isNilOrEmpty(call.followUpDate);

        (this.form.controls as any).noFollowUpCheck.setValueAsFirst(
          !hasFollowUpDate,
        );

        if (hasFollowUpDate) {
          const followUpDateAsDate = moment(
            call.followUpDate,
            'YYYY-MM-DD hh:mm:ss',
          ).toDate();

          (this.form.controls as any).followUpDateAsDate.setValueAsFirst(
            followUpDateAsDate,
          );
        } else {
          (this.form.controls as any).followUpDateAsDate.setValueAsFirst(
            this.dateTzAdjustService.getUTCAdjustedTime(),
          );
        }
        (this.form.controls as any).communicationSubject.setValueAsFirst(
          call.communicationSubject,
        );
        (this.form.controls as any).details.setValueAsFirst(call.details);
        (this.form.controls as any).communicationStatus.setValueAsFirst(
          call.communicationStatus,
        );
      }),
    )(null);
  }

  streamNotifyOnChanges() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.form.isAtFirstValue$, this.form.value$),
      rx.filter(([first, value]) => !first),
      rx.debounceTime(500),
      rx.map(([first, value]) => value as any),
      rx.filter(
        ({ communicationSubject, details, communicationStatus }) =>
          !_.isNil(communicationSubject) && !_.isNil(communicationStatus),
      ),
      rx.tap(
        ({
          details,
          communicationSubject,
          communicationStatus,
          noFollowUpCheck,
          followUpDateAsDate,
        }) => {
          this.onChange({
            updatedCall: {
              details,
              noFollowUpCheck,
              followUpDateAsDate,
              communicationSubjectId: communicationSubject.id,
              communicationStatusId: communicationStatus.id,
            },
          });
        },
      ),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.form.isValid$,
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }
}

function isNilOrEmpty(val) {
  if (_.isNil(val)) {
    return true;
  }

  if (_.isEmpty(val)) {
    return true;
  }

  return false;
}

export const EditCommentFormComponent = {
  template,
  controller: EditCommentFormController,
  bindings: {
    call: '<',
    onChange: '&',
    onChangeOfFormValidity: '&',
  },
};
