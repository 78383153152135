export const portfolioPositionsTableCols = [
  {
    title: '',
    field: 'selector',
    nonEditable: true,
    removable: false,
    type: 'common',
    show: false,
    class: 'table__checkbox',
  },
  {
    title: 'portfolio.table.ID',
    field: 'id',
    sortable: 'id',
    show: true,
    filterable: false,
    removable: true,
  },
  {
    title: 'portfolio.table.SYMBOL',
    field: 'tradeAsset',
    fieldFormat: '{{ tradeAsset.name }}',
    sortable: false,
    show: true,
    filterable: true,
    removable: true,
  },
  {
    title: 'portfolio.table.TYPE',
    field: 'side',
    filterable: false,
    removable: true,
    show: true,
    sortable: 'side',
  },
  {
    title: 'contact.positions.LEVERAGE',
    field: 'leverage',
    filterable: true,
    removable: true,
    show: true,
    sortable: 'leverage',
  },
  {
    title: 'portfolio.table.SIZE',
    field: 'size',
    fieldFormat: '{{ size | number:tradingAccount.currency.decimals }}',
    filterable: true,
    sortable: 'size',
    removable: true,
    show: true,
  },
  {
    title: 'portfolio.table.LOT',
    field: 'lot',
    fieldFormat: '{{ lot }}',
    filterable: true,
    sortable: 'lot',
    removable: true,
    relevantPlatforms: ['MT4', 'MT5'],
    show: true,
  },
  {
    title: 'portfolio.table.OPEN_TIME',
    field: 'entryDate',
    fieldFormat: '{{ entryDate | amDateFormat:"L LTS"  }}',
    filterable: true,
    sortable: 'entryDate',
    removable: true,
    show: true,
  },
  {
    title: 'portfolio.table.OPEN_PRICE',
    field: 'entryRate',
    fieldFormat: '{{ entryRate | prfToFixed:tradeAsset.tailDigits }}',
    filterable: true,
    sortable: 'entryRate',
    removable: true,
    show: true,
  },
  {
    title: 'portfolio.table.STOP_LOSS',
    field: 'stopLoss',
    fieldFormat: '{{ stopLoss | prfToFixed:tradeAsset.tailDigits }}',
    filterable: true,
    sortable: 'stopLoss',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'portfolio.table.TAKE_PROFIT',
    field: 'takeProfit',
    fieldFormat: '{{ takeProfit | prfToFixed:tradeAsset.tailDigits }}',
    filterable: true,
    sortable: 'takeProfit',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'portfolio.table.SWAP',
    field: 'swap',
    fieldFormat:
      "{{ swap === 0 ? '' : (swap | currency: (currency.code | prfCurrencyCodeToSymbol ) : currency.decimals) }}",

    filterable: true,
    sortable: 'swap',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'portfolio.table.PROFIT',
    field: 'profit',
    fieldName: 'profit',
    fieldFormat: '{{profit | number:tradingAccount.currency.decimals }}',
    filterable: true,
    sortable: 'profit',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'common.GROUP',
    field: 'portfolioPositionsTradingAccountGroup',
    fieldFormat: '{{ tradingAccount.tradingAccountGroup.name }}',
    filterable: true,
    sortable: 'tradingAccountGroupCode',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'FIRSTNAME',
    fieldName: 'firstName',
    fieldFormat: '{{ tradingAccount.customer.firstName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: true,
  },
  {
    title: 'LASTNAME',
    fieldName: 'lastName',
    fieldFormat: '{{ tradingAccount.customer.lastName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: true,
  },
  {
    title: 'common.COUNTRY',
    field: 'portfolioPositionCountry',
    fieldFormat:
      "{{'country.' + tradingAccount.customer.country.alpha2Code | translate}}",
    filterable: true,
    removable: true,
    show: true,
    sortable: 'country',
    type: 'common',
  },
  {
    title: 'CUSTOMER_ID',
    field: 'customer',
    fieldFormat: '{{ tradingAccount.customer.id }}',
    filterable: false,
    sortable: 'customerId',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'portfolio.table.SYNC_REMOTE_ID',
    field: 'platform',
    fieldFormat: '{{ syncRemoteId }}',
    filterable: false,
    sortable: 'syncRemoteId',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'portfolio.table.CURRENCY',
    field: 'currency',
    fieldFormat: '{{ currency.code }}',
    filterable: false,
    sortable: 'currencyId',
    removable: true,
    show: true,
    type: 'common',
  },
  {
    title: 'portfolio.table.EMPLOYEE',
    field: 'customerEmployee',
    fieldFormat:
      '{{ (tradingAccount.customer.user.firstName) + " " + (tradingAccount.customer.user.lastName) | trimText}}',
    filterable: true,
    sortable: false,
    removable: true,
    show: true,
    type: 'common',
  },
  {
    _comment: 'This is empty col for control settings',
    title: 'portfolio.table.COMMUNICATION',
    className: 'communication',
    fieldName: 'communication',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    type: 'common',
  },
  {
    title: '',
    className: 'contactDashboardLink',
    fieldName: 'contactDashboardLink',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    type: 'common',
    nonEditable: true,
  },
];
