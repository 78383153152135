import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import CreditCardTypesService from '~/source/contact/common/services/credit-card-types.service';
import WithdrawalValidationService, {
  WithdrawalValidationError,
} from '../../common/validation.service';
import CustomersService from '~/source/contact/common/services/customers';
import { TradingAccount } from '@proftit/crm.api.models.entities';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';

import template from './manual.html';
import { WithdrawalDepositLinkServiceDirective } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-deposit-link-service-directive';

class WithdrawalManualController extends BaseController {
  // bindings
  withdrawalRequest: WithdrawalRequest;
  account: TradingAccount;
  transferMethodTypes;
  prfWithdrawalDepositLink: WithdrawalDepositLinkServiceDirective;

  cardTypes;
  manualCard; // model
  selectedCard;
  invalidFormErrors: WithdrawalValidationError[];
  onDepositAmountChange: ({ newAmount: number }) => void;
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly creditCardTypesService: () => CreditCardTypesService,
    readonly withdrawalValidationService: WithdrawalValidationService,
    readonly withdrawalSettings,
    readonly customersService: () => CustomersService,
  ) {
    super();

    Object.assign(this, {
      customerServiceInst: this.customersService(),
      // manual card data
      manualCard: {},
    });

    this.fetchCreditCardTypes();
  }

  $onInit() {
    this.prfWithdrawalDepositLink.addCalculator(this);
  }

  $onDestroy() {
    this.prfWithdrawalDepositLink.removeCalculator(this);
  }

  /**
   * fetch credit cards types
   * @returns {Promise}
   */
  fetchCreditCardTypes() {
    return this.creditCardTypesService()
      .getListWithQuery()
      .then((cardTypes) => {
        this.cardTypes = cardTypes;
      });
  }

  /**
   * check whether the amount field valid or not
   * @returns {boolean} returns true when the amount field is valid
   */
  isAmountFieldValid() {
    // chargeback withdrawal amount is not limited to request amount left value
    if (this.withdrawalRequest.withdrawalType.code === 'chargeback') {
      return true;
    }
    // validate manual card
    return (
      this.invalidFormErrors.filter((error) => error.errorType === 'ERROR')
        .length === 0
    );
  }

  /**
   * listen to amount field changes & validate form each call
   * @returns {Object} error message object if exist
   */
  onAmountChange() {
    this.invalidFormErrors = this.withdrawalValidationService.getErrors(
      this.manualCard.amount,
      this.account,
      this.withdrawalRequest,
    );
    const amountWishedToBeDeposited = !_.isNil(this.manualCard.amount)
      ? this.manualCard.amount
      : 0;
    this.onDepositAmountChange({ newAmount: amountWishedToBeDeposited });
  }

  /**
   * submit withdrawal request to server if form is valid
   * called when submit button is click in parent scope
   *
   * @returns {*}
   */
  onCalcRequest() {
    if (!this.isAmountFieldValid()) {
      return;
    }
    const normalized = this.normalize();
    return normalized;
  }

  /**
   * called when a user withdrawal amount from a specific deposits
   *
   * @returns {Array}
   */
  normalize() {
    const cardNumber = _.getEs(
      this.manualCard,
      'creditCard.number',
      this.selectedCard,
    );
    const manualMethodType = this.transferMethodTypes.MANUAL;

    return [
      {
        methodTypeId: manualMethodType.id,
        clearingCompanyId: this.manualCard.clearingCompany.id,
        withdrawalTypeId: this.withdrawalRequest.withdrawalType.id,
        amount: this.manualCard.amount,
        creditCard: {
          creditcardTypeId: this.manualCard.creditCard.id,
          number: cardNumber,
        },
      },
    ];
  }
}

WithdrawalManualController.$inject = [
  '$scope',
  'creditCardTypesService',
  'withdrawalValidationService',
  'withdrawalSettings',
  'customersService',
];

export default {
  template,
  controllerAs: 'vm',
  controller: WithdrawalManualController,
  require: {
    prfWithdrawalDepositLink: '^',
  },
  bindings: {
    account: '<',
    withdrawalRequest: '<',
    transferMethodTypes: '<',
    onDepositAmountChange: '&',
  },
};
