export const contactsImportTableCols = [
  {
    title: 'contactsImportTable.fields.CREATED_AT',
    field: '',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: 'createdAt',
  },
  {
    title: 'contactsImportTable.fields.FILE_NAME',
    field: '',
    fieldFormat: '{{ resource.name }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: null,
  },
  {
    title: 'contactsImportTable.fields.IMPORTED_BY',
    field: '',
    fieldFormat: '{{ creator.firstName }} {{creator.lastName}}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'contactsImportTable.fields.TOTAL_RECORDS',
    field: '',
    fieldFormat: '{{ totalRecords }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'contactsImportTable.fields.NEW_RECORDS',
    field: '',
    fieldFormat: '{{ newRecords }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'contactsImportTable.fields.ERRORS_COUNT',
    field: '',
    fieldFormat: '{{ errorsCount }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'contactsImportTable.fields.BRAND_NAME',
    field: '',
    fieldFormat: '{{ brand.name }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'contactsImportTable.fields.CONTACT_TYPE',
    field: '',
    fieldFormat: '{{ "common.LEADS" | translate }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: '',
    field: '',
    fieldName: 'actions',
    fieldFormat: '',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
];
