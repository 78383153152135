import ng from 'angular';
import { createFactoryForService } from '@proftit/ng1.adjunct';

import { ApiCfdAssetsService } from './api-cfd-assets.service';
import { ApiCfdGroupsService } from './api-cfd-groups.service';
import { ApiMt4AssetsService } from './api-mt4-assets.service';
import { ApiMt4GroupsService } from './api-mt4-groups.service';
import { ApiMt4PlatformService } from './api-mt4-platform.service';
import { ApiTrcBrandsService } from './api-trc-brands.service';
import { ApiMtSecuritiesService } from './api-mt-securities.service';
import { ApiTrcExposureService } from './api-trc-exposure.service';
import { ApiTrcCurrenciesService } from './api-trc-currencies.service';

import { TrcBrandExposureCurrenciesSocketService } from './trc-brand-exposure-currencies-socket.service';
import { TrcBrandExposureCurrenciesTotalSocketService } from './trc-brand-exposure-currencies-total-socket.service';

export const ApiCfdPlatformModule = ng.module('crm.cfdplatform.api', []);
const md = ApiCfdPlatformModule;

md.service('prfApiCfdAssetsService', ApiCfdAssetsService);
md.service('prfApiCfdGroupsService', ApiCfdGroupsService);
md.service('prfApiMt4AssetsService', ApiMt4AssetsService);
md.service('prfApiMt4GroupsService', ApiMt4GroupsService);
md.service('prfApiMt4PlatformService', ApiMt4PlatformService);
md.service('prfApiMtSecuritiesService', ApiMtSecuritiesService);
md.service('prfApiTrcBrands', ApiTrcBrandsService);
md.service('prfApiTrcExposureService', ApiTrcExposureService);
md.service('prfApiTrcCurrenciesService', ApiTrcCurrenciesService);

md.factory(
  'trcBrandExposureCurrenciesSocketService',
  createFactoryForService(TrcBrandExposureCurrenciesSocketService),
);
md.factory(
  'trcBrandExposureCurrenciesTotalSocketService',
  createFactoryForService(TrcBrandExposureCurrenciesTotalSocketService),
);
