import TablePopupController from '~/source/common/components/table/table-popup';
import loginHistorySettings from './login-history-settings.json';
import { Customer } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';

class LoginLogTablePopupController extends TablePopupController {
  static $inject = ['customersService', ...TablePopupController.$inject];

  isSmallModal = loginHistorySettings.isSmallModal;
  customer: Customer;
  dataServiceInstance: CustomersService;

  get cols() {
    return [...loginHistorySettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...loginHistorySettings.popupTable.ngTable };
  }

  get title() {
    return 'contact.LOGIN_HISTORY_TITLE';
  }

  get tableKey() {
    return 'loginHistory';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance.getLoginHistoryRequestsResource(
      this.customer.id,
    );
  }
}

export default LoginLogTablePopupController;
