import { CRMExportService } from './crm-export.service';

export class ReportsExportService extends CRMExportService {
  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'ReportsExport';
  }
}
