import ng from 'angular';

import settings from './settings.json';
import UsersService from './services/users';

// Services
import ActivityLogSocketService from './services/activity-log-socket';
import ManagementBrandDeskFormFormatter from './services/brand-desk-form-formatter';
import DepartmentsService from './services/departments';
import { AllExportsService } from './services/allExports';
import RolesService from './services/roles';
import UserActivityReports from './services/user-activity-reports';
import { NotificationCommunicationTypesService } from './services/notification-communication-types.service';

// Components
import BrandDeskDataComponent from './components/brand-desk/brand-desk-data';
import BrandDeskFormComponent from './components/brand-desk/brand-desk-form';
import UserBrandVoipDataComponent from './components/brand-voip/user-brand-voip-data';
import UserBrandVoipFormComponent from './components/brand-voip/user-brand-voip-form';
import DepartmentSelectComponent from './components/dropdowns/department-select';
import RoleSelectComponent from './components/dropdowns/role-select';
import UserGroupSelectComponent from './components/dropdowns/user-group-select';
import UserDataComponent from './components/user/user-data';
import UserFormComponent from './components/user-form/user-form';
import ActivityLogModalComponent from './components/activity-log-modal';
import UserCreateComponent from './components/user-create';
import UserListComponent from './user-list/user-list';
import UserUpdateComponent from './components/user-update';
import { UserBrandEmailDataComponent } from './components/user-brand-email/user-brand-email-data/user-brand-email-data.component';
import { UserBrandEmailFormComponent } from './components/user-brand-email/user-brand-email-form/user-brand-email-form.component';
import { AvailableLanguage } from '~/source/management/user/services/available-language';
import { NullableDepartmentSelectComponent } from './components/dropdowns/nullable-department-select';
import DataExportLogComponent from './components/data-export-log-popup/data-export-log-popup';

const userModule = ng.module('crm.user', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

const modulePath = 'management/user';

function config($stateProvider, $urlRouterProvider) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/permissions/users',
    '/crm/management/permissions/users/list',
  );

  $stateProvider
    .state('crm.management.permissions.users', {
      url: '/users',
      template: '<ui-view/>',
    })
    .state('crm.management.permissions.users.list', {
      url: '/list?filterGroup',
      component: 'prfUserList',
      data: {
        permissions: {
          only: ['management.users'],
          redirectTo: 'error.unauthorized',
        },
      },
      resolve: {
        /*
         * get user group (by Promise) before page load if filterGroup params exists in url
         * its necessary to do it before the controller construction.
         * there's not way to use a promise (to get userGroup info) because ngTable init
         * expects to get table settings on controller init
         */
        userGroup($stateParams, userGroupsService) {
          const groupId = parseInt($stateParams.filterGroup, 10);

          if (!groupId) {
            return;
          }

          return userGroupsService().getOneWithQuery(groupId);
        },
      },
    })
    .state('crm.management.permissions.users.add', {
      url: '/user-create',
      component: 'prfUserCreate',
      data: {
        permissions: {
          only: ['management.users_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })

    .state('crm.management.permissions.users.update', {
      url: '/user-update/{userId:int}',
      component: 'prfUserUpdate',
      data: {
        permissions: {
          only: ['management.users_U'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

userModule
  .config(config)
  .constant('usersModuleSettings', settings)
  .factory('usersService', UsersService.$factorize())
  .factory(
    'managementBrandDeskFormFormatter',
    ManagementBrandDeskFormFormatter.$factorize(),
  )
  .factory('departmentsService', DepartmentsService.$factorize())
  .factory('exportsService', AllExportsService.$factorize())
  .factory('rolesService', RolesService.$factorize())
  .factory(
    'notificationCommunicationTypesService',
    NotificationCommunicationTypesService.$factorize(),
  )
  .factory('availableLanguagesService', AvailableLanguage.$factorize())
  .service('activityLogSocketService', ActivityLogSocketService)
  .service('userActivityReportsService', UserActivityReports)
  .component('prfBrandDeskData', BrandDeskDataComponent)
  .component('prfBrandDeskForm', BrandDeskFormComponent)
  .component('prfUserBrandVoipData', UserBrandVoipDataComponent)
  .component('prfUserBrandVoipForm', UserBrandVoipFormComponent)
  .component('prfDepartmentSelect', DepartmentSelectComponent)
  .component('prfNullableDepartmentSelect', NullableDepartmentSelectComponent)
  .component('prfRoleSelect', RoleSelectComponent)
  .component('prfUserGroupSelect', UserGroupSelectComponent)
  .component('prfUserData', UserDataComponent)
  .component('prfUserForm', UserFormComponent)
  .component('prfUserCreate', UserCreateComponent)
  .component('prfUserList', UserListComponent)
  .component('prfUserUpdate', UserUpdateComponent)
  .component('prfActivityLogModal', ActivityLogModalComponent)
  .component('prfUserBrandEmailData', UserBrandEmailDataComponent)
  .component('prfUserBrandEmailForm', UserBrandEmailFormComponent)
  .component('prfDataExportLogPopup', DataExportLogComponent);

export default userModule.name;
