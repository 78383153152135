import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './internal-transfers-table-header.component.html';

export class InternalTransfersTableHeaderController extends BaseController {
  /*@ngInject */
  constructor() {
    super();
  }
}

export const InternalTransfersTableHeaderComponent = {
  template,
  controller: InternalTransfersTableHeaderController,
  bindings: {
    showTablePopupButton: '<',
    onOpenAddInternalTransferPopup: '&',
    onOpenInternalTransferTablePopup: '&',
  },
};
