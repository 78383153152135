import * as _ from '@proftit/lodash';

/**
 * Calcualte validations string for brand voip settings.
 *
 * @param settingsValidations -
 * @param serverSettingsIsRequired -
 * @return validation string used in ng validator
 */
export function calcValidationsStrForVoipField(
  typeSettingsValidations: string[],
  serverSettingsIsRequired: boolean,
) {
  return _.flow([
    () => _.defaultTo([], typeSettingsValidations),
    (arr) => (serverSettingsIsRequired ? [...arr, 'required'] : arr),
    (arr) => arr.join(','),
  ])();
}
