import template from './customer-search-result.component.html';
const styles = require('./customer-search-result.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Customer } from '@proftit/crm.api.models.entities';

export class CustomerSearchResultController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  result: Customer;

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const CustomerSearchResultComponent = {
  template,
  controller: CustomerSearchResultController,
  bindings: {
    result: '<',
  },
};
