import { ClearingCompanyType } from '@proftit/crm.api.models.enums';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import RestService from '~/source/common/services/rest';
import * as _ from '@proftit/lodash';

import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import { useStreams } from '@proftit/rxjs.adjunct';

class MobileMoneySelectController extends DropdownController {
  static $inject = [
    'clearingCompanyConnectionsService',
    ...DropdownController.$inject,
  ];

  dataServiceInst: ClearingCompanyConnectionsService;
  lifecycles = observeComponentLifecycles(this);
  brandId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'brandId',
  );
  brandId;

  constructor(dataService: Function | RestService, ...args) {
    super(dataService, ...args);
    useStreams(
      [
        this.brandId$,
        rx.pipe(
          () => this.brandId$,
          rx.filter((x) => !_.isNil(x)),
          rx.tap(() => this.fetchData()),
        )(null),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  init(): void {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return this.dataServiceInst
      .expand(['clearingCompany'])
      .filter({
        brandId: this.brandId,
        'clearingCompany.type': ClearingCompanyType.MobileMoney,
      })
      .getListWithQuery<IElementRestNg<ClearingCompanyConnection>>()
      .then((allConnections) => {
        return allConnections
          .filter((connection) => connection.isActive)
          .map((connection) => connection.clearingCompany);
      });
  }

  get defaultPlaceholder() {
    return 'SELECT_MOBILE_MONEY';
  }
  ClearingCompanyConnection;
  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const MobileMoneySelectComponent = DropdownConfig({
  controller: MobileMoneySelectController,
  bindings: {
    brandId: '<',
  },
});

export default MobileMoneySelectController;
