import commonModule from '../index';

function spaceToHyphen() {
  return function (value) {
    return !value ? '' : value.replace(/ /g, '-');
  };
}
spaceToHyphen.$inject = [];

commonModule.filter('spacetohyphen', spaceToHyphen);
