import * as _ from '@proftit/lodash';
import { ComponentScore } from '../../questionnaire-score-scorings-form/models/component-score';

export function combineScoresWithSchema(
  componentScores: ComponentScore[],
  scoresSchemaList,
) {
  return scoresSchemaList.map((scoreSchema) => {
    const currCompScore = componentScores.find(
      (score) =>
        score.questionnaireComponentsId ===
        scoreSchema.questionnaireComponentsId,
    );

    if (_.isNil(currCompScore)) {
      return scoreSchema;
    }

    const newScoreData = _.flow([
      () => _.toPairs(scoreSchema.scoreData),
      (answersPairs) =>
        answersPairs.map(([answerKey, answerScore]) => {
          const currAnswerScore = currCompScore.scoreData[answerKey];
          if (_.isNil(currAnswerScore)) {
            return [answerKey, answerScore];
          }

          const newAnswerScore = {
            ...currAnswerScore,
          };
          return [answerKey, newAnswerScore];
        }),
      (newAnswers) => _.fromPairs(newAnswers),
    ])();

    return {
      ...scoreSchema,
      maxQuestionScore: currCompScore.maxQuestionScore,
      scoreData: newScoreData,
    };
  });
}
