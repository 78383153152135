import RestService from '~/source/common/services/rest';
import { IPromise } from 'angular';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { AccountAction } from '@proftit/crm.api.models.entities';

export class AccountActionsService extends RestService {
  get resource() {
    return 'accountActions';
  }

  getList(): Promise<AccountAction> {
    return this.getListWithQuery<IElementRestNg<AccountAction>>().then((data) =>
      data.plain(),
    );
  }
}
