/**
 * A simple hash generation from a string.
 *
 * source: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * @param str
 * @returns hash number
 */
export function hashCode(str: string) {
  let hash = 0;
  let chr;

  if (str.length === 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
