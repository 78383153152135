/**
 * Modal open state.
 */
export enum ModalOpenState {
  Open,
  Close,
  Unset,
}

export default ModalOpenState;
