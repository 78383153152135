import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-registration-row.component.html';
import { BrandCurrency } from '~/source/common/models/brand-currency';
import { MarketingCampaignRewardType } from '~/source/common/data-sources/marketing-campaign-rewards-source';

const styles = require('./automatic-bonus-registration-row.component.scss');

export class AutomaticBonusRegistrationRowController {
  MarketingCampaignRewardType = MarketingCampaignRewardType;
  isEdit: boolean;
  currency: BrandCurrency;
  rewardType: MarketingCampaignRewardType;
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AutomaticBonusRegistrationRowComponent = {
  template,
  controller: AutomaticBonusRegistrationRowController,
  bindings: {
    currency: '<',
    formGroup: '<',
    isEdit: '<',
    rewardType: '<',
  },
};
