import { AbstractControl, ValidationResult } from '@proftit/ng1.reactive-forms';
import * as _ from '@proftit/lodash';

export const AUTOMATIC_BONUS_ALL_OR_NON_FILLED =
  'AUTOMATIC_BONUS_ALL_OR_NON_FILLED';

function isAllFilled(values) {
  return values.every((value) => !_.isNil(value));
}

function isAllEmpty(values: any[]) {
  return values.every((value) => _.isNil(value));
}

function checkNonFtdBonus(nonFtdBonus) {
  const { isOneTime, ...rest } = nonFtdBonus;
  return (
    isAllFilled(Object.values(nonFtdBonus)) || isAllEmpty(Object.values(rest))
  );
}

function isAllOrNonFilled(bonusValues) {
  const { id, currencyId, ...rest } = bonusValues;
  const values = _.values(rest);
  const areAllValuesEmpty = isAllEmpty(values);
  if (areAllValuesEmpty) {
    return true;
  }
  const areAllValuesFilled = isAllFilled(values);
  if (areAllValuesFilled) {
    return true;
  }
  if (checkNonFtdBonus(rest)) {
    return true;
  }

  return false;
}

export function automaticBonusAllOrNonFilled(
  control: AbstractControl,
): Promise<ValidationResult> {
  return new Promise((resolve) => {
    if (isAllOrNonFilled(control.value)) {
      return resolve(null);
    }

    return resolve({
      [AUTOMATIC_BONUS_ALL_OR_NON_FILLED]: {
        value: control.value,
      },
    });
  });
}

automaticBonusAllOrNonFilled.validatorName = AUTOMATIC_BONUS_ALL_OR_NON_FILLED;
