import { Platform } from '@proftit/crm.api.models.entities';
import { DisplayContext } from './display-contenxt';

/**
 * Calculate columns for forex table
 *
 * @param tableColums
 * @param platform
 */
export function calculateForexPositionTableCols(
  tableColumns,
  platform: Platform,
  displayContext: DisplayContext,
) {
  const cols = tableColumns.reduce((acc, col) => {
    if (
      col.relevantPlatforms &&
      !col.relevantPlatforms.includes(platform.code)
    ) {
      return acc;
    }

    if (col.displayContexts && !col.displayContexts.includes(displayContext)) {
      return acc;
    }

    acc.push(col);
    return acc;
  }, []);

  return cols;
}
