import Promise from 'bluebird';
import Component from './base/component';

class IsActiveSelect extends Component.controller {
  query() {
    const values = [
      {
        id: 1,
        code: 'ACTIVE',
      },
      {
        id: 0,
        code: 'INACTIVE',
      },
    ];

    return Promise.resolve(values);
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'isActiveSelect';
  }
}

export default Component.config({ controller: IsActiveSelect });
