import template from './design-template-item.component.html';
const styles = require('./design-template-item.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { DesignTemplatesService } from '~/source/common/api-crm-server/services/design-templates.service';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import { switchOn } from '~/source/common/utilities/switch-on';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  DESIGN_TEMPLATE_CREATED,
  DESIGN_TEMPLATE_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';
import { Base64 } from 'js-base64';
import { DesignTemplate } from '@proftit/crm.api.models.entities';

interface ModelChange {
  fieldName: string;
  nextValue: any;
}

enum SubAction {
  Copy = 'copy',
}

export class DesignTemplateItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  onDone: () => void;
  onCancel: () => void;

  blockUiId = 'designTemplateItemBlockUi';
  growlId = 'designTemplateItemGrowl';
  opUpdateUiModel$ = new rx.Subject<ModelChange>();
  opSaveModel$ = new rx.Subject<void>();
  opCancelEditing$ = new rx.Subject<void>();
  isFormValid$ = new rx.BehaviorSubject<boolean>(false);
  model$ = new rx.BehaviorSubject<DesignTemplate>(newDesignTempalte());

  /*@ngInject */
  constructor(
    readonly designTemplatesService: () => DesignTemplatesService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams(
      [
        this.streamLoadModel(),
        this.streamFormChanges(),
        this.streamSaveModel(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamFormChanges() {
    return rx.pipe(
      () => this.opUpdateUiModel$,
      rx.withLatestFrom(this.model$),
      rx.map(([change, form]) => {
        return {
          ...form,
          [change.fieldName]: change.nextValue,
        };
      }),
      rx.tap((form) => this.model$.next(form)),
    )(null);
  }

  streamLoadModel() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.lifecycles.onChanges$.pipe(
            getCompChange<string>('action'),
          ) as rx.Observable<string>,
          this.lifecycles.onChanges$.pipe(getCompChange<string>('subAction')),
          this.lifecycles.onChanges$.pipe(
            getCompChange<number>('id'),
          ) as rx.Observable<number>,
        ),
      rx.switchMap(([action, subAction, id]) => {
        return switchOn(
          {
            create: () => {
              if (subAction === SubAction.Copy) {
                return loadAsCopyDesignTemplate(
                  id,
                  this.designTemplatesService,
                );
              }
              return rx.obs.from([newDesignTempalte()]);
            },
            update: () => loadDesignTemplate(id, this.designTemplatesService),
          },
          action,
          () => {
            throw new Error('unintented');
          },
        );
      }),
      rx.tap((item: DesignTemplate) => this.model$.next(item)),
    )(null);
  }

  streamSaveModel() {
    return rx.pipe(
      () => this.opSaveModel$,
      rx.withLatestFrom(
        this.lifecycles.onChanges$.pipe(
          getCompChange<string>('action'),
        ) as rx.Observable<string>,
        this.model$.pipe(
          rx.map((model) => ({
            ...model,
            content: Base64.encode(model.content),
          })),
        ),
      ),
      rx.map(([a, action, model]) => {
        return switchOn(
          {
            create: () => this.createItem(model),
            update: () => this.updateItem(model),
          },
          action,
          () => {
            throw new Error('unsupported action');
          },
        );
      }),
      rx.switchMap((x) => x),
    )(null);
  }

  createItem(model: DesignTemplate) {
    return rx.obs
      .from(
        this.designTemplatesService()
          .setConfig({
            growlRef: this.growlId,
            blockUiRef: this.blockUiId,
          })
          .createItem(model),
      )
      .pipe(
        rx.tap((result) =>
          this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_CREATED, result),
        ),
        rx.tap(() => this.onDone()),
        dealWithRestErrorForScreen(),
      );
  }

  updateItem(model: DesignTemplate) {
    return rx.obs
      .from(
        this.designTemplatesService()
          .setConfig({
            growlRef: this.growlId,
            blockUiRef: this.blockUiId,
          })
          .updateItem(model.id, model),
      )
      .pipe(
        rx.tap((result) =>
          this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_UPDATED, result),
        ),
        rx.tap(() => this.onDone()),
        dealWithRestErrorForScreen(),
      );
  }
}

function dealWithRestErrorForScreen() {
  return function operator(obs$) {
    return obs$.pipe(rx.catchError(() => rx.obs.EMPTY));
  };
}

function newDesignTempalte(): DesignTemplate {
  return {
    name: '',
    content: '',
    id: null,
    isActive: true,
    isSystem: false,
  };
}

function loadDesignTemplate(
  id: number,
  designTemplatesService: () => DesignTemplatesService,
) {
  return rx.obs.from(designTemplatesService().getItem(id)).pipe(
    rx.map((item) => ({
      ...item,
      content: Base64.decode(item.content),
    })),
    dealWithRestErrorForScreen(),
  );
}

function loadAsCopyDesignTemplate(
  id: number,
  designTemplatesService: () => DesignTemplatesService,
) {
  return rx.obs.from(designTemplatesService().getItem(id)).pipe(
    rx.map((designTemplate) => _.omit(['id'], designTemplate)),
    rx.map((designTemplate) => ({
      ...designTemplate,
      name: `Copy of ${designTemplate.name}`,
      content: Base64.decode(designTemplate.content),
    })),
  );
}

export const DesignTemplateItemComponent = {
  template,
  controller: DesignTemplateItemController,
  bindings: {
    id: '<',
    action: '<',
    subAction: '<',
    onCancel: '&',
    onDone: '&',
  },
};
