import ng from 'angular';
import { BrandDepositWireManagerComponent } from './brand-deposit-wire-manager/brand-deposit-wire-manager.component';
import { BrandDepositWireAccountViewComponent } from './brand-deposit-wire-account-view/brand-deposit-wire-account-view.component';
import { BrandDepositWireFormComponent } from './brand-deposit-wire-form/brand-deposit-wire-form.component';
import { BrandDepositWireListComponent } from './brand-deposit-wire-list/brand-deposit-wire-list.component';
import { BrandDepositWireCurrencyListComponent } from './brand-deposit-wire-currency-list/brand-deposit-wire-currency-list.component';

export default ng
  .module('crm.management.brands.wireInfo', [])
  .component(
    'prfBrandDepositWireCurrencyList',
    BrandDepositWireCurrencyListComponent,
  )
  .component(
    'prfBrandDepositWireAccountView',
    BrandDepositWireAccountViewComponent,
  )
  .component('prfBrandDepositWireList', BrandDepositWireListComponent)
  .component('prfBrandDepositWireManager', BrandDepositWireManagerComponent)
  .component('prfBrandDepositWireForm', BrandDepositWireFormComponent).name;
