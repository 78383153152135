import angular from 'angular';

import Controller from '~/source/common/controllers/base';
import type { StateService } from '@uirouter/angularjs';
import { ManagementPermissionsModifierService } from '../../permissions/permissions-modifier.service';
import { UserGroupsService } from '../services/user-groups';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { UserGroup } from '~/source/common/models/user-group';

export abstract class CreateGroupAbstractController extends Controller {
  $state: StateService;
  userGroupsService: () => UserGroupsService;
  userGroupsServiceInst: UserGroupsService;
  userGroup: any;
  managementPermissionsModifierService: ManagementPermissionsModifierService;
  growl: angular.growl.IGrowlService;

  constructor(...args) {
    super(...args);

    this.userGroupsServiceInst = this.userGroupsService();

    // fetch userGroup from server if copying or just the permission structure if creating new
    this.getUserGroup().then((userGroup) => {
      // init user group model
      this.userGroup = userGroup;
    });
  }

  abstract getUserGroup(): Promise<IElementRestNg<UserGroup>>;

  validatePermissionSelected() {
    // Verify at least 1 permission is selected.
    if (
      !this.managementPermissionsModifierService.isSelected(
        this.userGroup.permissions,
      )
    ) {
      this.growl.error('group.MUST_SELECT_PERMISSION', {
        referenceId: <any>'groupGrowl',
        ttl: 5000,
      });
      return false;
    }

    return true;
  }

  /**
   * Saves the user group in the server and return a promise.
   * @return {Promise} - resolved with the created group or rejected with server error
   */
  saveUserGroup() {
    const normalizedUserGroup = this.userGroupsServiceInst.prepareUserGroup(
      this.userGroup,
    );

    return this.userGroupsServiceInst.postWithQuery(normalizedUserGroup);
  }

  /**
   * Save the created user group and redirect to list page
   */
  createUserGroup() {
    if (this.validatePermissionSelected()) {
      this.saveUserGroup().then(() => {
        this.$state.go('^.list');
      });
    }
  }

  /**
   * Save created user group and redirect to group whitelist edit
   */
  createUserGroupAndConfigIp() {
    if (this.validatePermissionSelected()) {
      this.saveUserGroup().then((group) => {
        this.$state.go('crm.management.permissions.ip-list.manage', {
          groupId: group.id,
        });
      });
    }
  }
}

CreateGroupAbstractController.$inject = [
  '$state',
  '$scope',
  'userGroupsService',
  'growl',
  'managementPermissionsModifierService',
];

export default CreateGroupAbstractController;
