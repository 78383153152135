import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { directivesPriorities } from '../constants/directives-priorities';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { UserSettingsService } from '../services/user-settings';
import {
  Brand,
  BrandPlatform,
  UserSettings,
} from '@proftit/crm.api.models.entities';
import { UserSettingsValueRiskBrandsManager } from './user-settigs-value-risk-brands-manager';

const VERSION = 1;
const SETTINGS_KEY = 'RISK_BRANDS_MANAGER';
const UPDATE_THROTTLE_MS = 2000;
const defaultUserSettings = {
  version: VERSION,
  selectedBrandId: null,
  selectedBrandPlatformId: null,
};

export class CurrentUserSettingsRiskBrandsManagerServiceDirectiveController {
  /* state */

  lifecycles = observeComponentLifecycles(this);

  loadOp$ = new rx.Subject<
    rx.Observable<UserSettings<UserSettingsValueRiskBrandsManager>>
  >();

  settings$ = this.streamSettings();

  /* @ngInject */
  constructor(readonly userSettingsService: UserSettingsService) {
    useStreams([], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams([this.settings$], this.lifecycles.onDestroy$);
  }

  $onChanges() {}

  $onDestroy() {}

  streamSettingsFromLoad() {
    return rx.pipe(
      () => this.loadOp$,
      rx.switchMap((action$) => action$),
      shareReplayRefOne(),
    )(null);
  }

  streamSettings() {
    return rx.pipe(
      () => rx.obs.merge(this.streamSettingsFromLoad()),
      shareReplayRefOne(),
    )(null);
  }

  load() {
    const action$ = rx.pipe(
      () =>
        rx.obs.from(
          this.userSettingsService
            .getSetting<UserSettingsValueRiskBrandsManager>(
              SETTINGS_KEY,
              defaultUserSettings,
            )
            .then((data) => data.plain())
            .catch((e) => {
              log.error('error fetching user setting', e);
              return {};
            }),
        ),
      shareReplayRefOne(),
    )(null);

    this.loadOp$.next(action$);

    return action$;
  }

  syncOneWayBy(
    selectedBrand$: rx.Observable<Brand>,
    selectedBrandPlatform$: rx.Observable<BrandPlatform>,
  ) {
    const action$ = rx.pipe(
      () =>
        rx.obs.combineLatest(
          selectedBrand$.pipe(rx.map((v) => _.get(['id'], v))),
          selectedBrandPlatform$.pipe(rx.map((v) => _.get(['id'], v))),
        ),
      rx.withLatestFrom(this.settings$),
      rx.filter(([[selectedBrandId, selectedBrandPlatformId], settings]) => {
        return !_.isEqual(
          {
            selectedBrandId,
            selectedBrandPlatformId,
            version: VERSION,
          },
          settings.value,
        );
      }),
      rx.throttleTime(UPDATE_THROTTLE_MS),
      rx.switchMap(([[selectedBrandId, selectedBrandPlatformId], settings]) => {
        return this.userSettingsService.setSettingValue(settings.id, {
          selectedBrandId,
          selectedBrandPlatformId,
          version: VERSION,
        });
      }),
    )(null);

    return action$;
  }
}

export const currentUserSettingsRiskBrandsManagerServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentUserSettingsRiskBrandsManagerServiceDirectiveController,
  };
};

currentUserSettingsRiskBrandsManagerServiceDirective.$inject = [] as string[];
