export const customerPageViewAuditLogsTableColumns = [
  {
    title: 'common.DATE',
    field: '',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'auditLogs.fields.VIEWED_BY',
    field: '',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'auditLogs.fields.IP',
    field: 'ip',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.COUNTRY',
    field: 'country',
    fieldFormat: '{{ country }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
