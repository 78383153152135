import BaseController from '~/source/common/controllers/base';
import template from './assign-users-list.html';
import { IScope } from 'angular';
import UsersService from '~/source/management/user/services/users';
import BrandsService from '~/source/management/brand/services/brands';
import TokensService from '~/source/auth/services/tokens';
import { User, Desk } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { filterCompChange } from '~/source/common/utilities/rxjs/operators/filter-comp-change';
import * as rx from '@proftit/rxjs';

interface MarkedUser extends User {
  isSelected: boolean;
}

class Controller extends BaseController {
  lifecycles = observeComponentLifecycles(this);

  // bindings
  desks: Desk[];
  selectedDesk: Desk;
  selectedUsers: User[];
  usersOfSelectedDesk: User[];
  onSelectOfDesk: (a: { desk: Desk }) => void;
  onSelectOfUser: (a: { user: User; isSelected: boolean }) => void;
  onSelectOfAllDeskUsers: () => void;

  isSelectAllChecked: boolean;

  isAllUsersSelected$ = new rx.BehaviorSubject<boolean>(false);
  usersOfSelectedDeskMarked$ = new rx.BehaviorSubject<MarkedUser[]>([]);

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly usersService: () => UsersService,
    readonly brandsService: () => BrandsService,
    readonly tokensService: TokensService,
  ) {
    super();

    useStreams(
      [
        this.streamCalcDeskUsersWithMarks(),
        this.streamCalcIsAllUsersSelected(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  streamCalcDeskUsersWithMarks() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.pipe(
            () => this.lifecycles.onChanges$,
            filterCompChange<User[]>('selectedUsers'),
            rx.map(({ currentValue }) => currentValue),
          )(null),
          rx.pipe(
            () => this.lifecycles.onChanges$,
            filterCompChange<User[]>('usersOfSelectedDesk'),
            rx.map(({ currentValue }) => currentValue),
          )(null),
        ),
      rx.map(([selectedUsers, usersOfSelectedDesk]) => {
        return usersOfSelectedDesk.map((user) => {
          if (selectedUsers.find((u) => u.id === user.id)) {
            return { ...user, isSelected: true };
          }

          return { ...user, isSelected: false };
        });
      }),
      rx.tap((users) => this.usersOfSelectedDeskMarked$.next(users)),
    )(null);
  }

  streamCalcIsAllUsersSelected() {
    return rx.pipe(
      () => this.usersOfSelectedDeskMarked$,
      rx.map((deskUsers) =>
        deskUsers.reduce((acc, user) => (user.isSelected ? acc + 1 : acc), 0),
      ),
      rx.withLatestFrom(this.usersOfSelectedDeskMarked$),
      rx.map(([selectedCount, deskUsers]) =>
        deskUsers.length > 0 ? selectedCount === deskUsers.length : false,
      ),
      rx.tap((isAllUsersSelected) =>
        this.isAllUsersSelected$.next(isAllUsersSelected),
      ),
    )(null);
  }

  /**
   * select a desk & load desk users
   * @param {object} desk {id, name}
   */
  selectDesk(desk) {
    this.onSelectOfDesk({ desk });
    /*
    this.fetchUsers(desk.id);
    this.selectedDesk = desk;
     */
  }

  /**
   * select default desk
   */
  /*
  selectDefaultDesk() {
    if (this.desks.length > 0) {
      const desk = _first(this.desks);

      // select default desk
      this.selectDesk(desk);
    }
  }
     */

  /**
   * called on select user change
   */
  selectUser(user, isSelected) {
    this.onSelectOfUser({ user, isSelected });
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    desks: '<',
    selectedDesk: '<',
    selectedUsers: '<',
    usersOfSelectedDesk: '<',
    isUnassignSelectedFormControl: '<',
    onSelectOfDesk: '&',
    onSelectOfUser: '&',
    onSelectOfAllDeskUsers: '&',
  },
};
