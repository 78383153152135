import template from './email-template-designs-logs-table.component.html';
const styles = require('./email-template-designs-logs-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import * as rx from '@proftit/rxjs';
import { Brand } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { ContentTemplatesLogsService } from '~/source/common/api-crm-server/services/content-templates-logs.service';
import { emailTemplateDesignsLogsTableCols } from './email-template-designs-logs-table.cols';
import { emailTemplateDesignsLogsTableSettings } from './email-template-designs-logs-table.settings';
import { DesignTemplatesLogsService } from '~/source/common/api-crm-server/services/design-templates-logs.service';

export class EmailTemplateDesignsLogsTableController extends TableController {
  static $inject = [
    'designTemplatesLogsService',
    'prfClientGeneralPubsub',
    ...TableController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = 'emailTemplateDesignsTable';
  designTemplatesLogsService: () => DesignTemplatesLogsService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  dataServiceInstance: DesignTemplatesLogsService;
  settings = { ...emailTemplateDesignsLogsTableSettings };
  cols = [...emailTemplateDesignsLogsTableCols];
  brand$ = new rx.BehaviorSubject<Brand>(null);
  isTableInitialized$ = new rx.BehaviorSubject<boolean>(false);

  constructor(...args) {
    super(...args);

    this.dataServiceInstance = this.designTemplatesLogsService();

    useStreams(
      [
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
        this.streamInitTable(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  fetchFn() {
    // todoOld: brand filter
    const sort = { modifiedAt: 'desc' };
    const expand = ['user'];
    const embed = [];
    const config = { blockUiRef: this.blockUiRef };

    return this.dataServiceInstance.resourceFullList(
      config,
      sort,
      expand,
      embed,
    );
  }

  streamInitTable() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.tap(() => this.initTable()),
      rx.tap(() => this.isTableInitialized$.next(true)),
    )(null);
  }
}

export const EmailTemplateDesignsLogsTableComponent = {
  template,
  controller: EmailTemplateDesignsLogsTableController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};
