import Component from './base/component';

class ExperienceRangesController extends Component.controller {
  static $inject = ['experienceRangesService', ...Component.controller.$inject];

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'experienceRanges';
  }

  /**
   * Used if select is nullable, returns nullable entity
   *
   * @returns {Array}
   */
  get nullableElement() {
    return {
      id: null,
      code: 'null',
    };
  }
}

export default Component.config({ controller: ExperienceRangesController });
