export function addDefaultCustomerOnlyFields(customer) {
  return {
    ...customer,
    password: '',
    accountPlatform: null,
    accountCurrency: null,
    accountStatus: null,
    accountIsDemo: false,
  };
}
