import {
  Controller as SerialChartController,
  config as serialChartConfig,
} from './amcharts-serial.component';

export class Controller extends SerialChartController {
  /**
   * Build the options object for a stacked serial chart, that will be passed to amcharts
   * Gets options object which will also be merged to the returned options object
   * and can be used to override settings.
   *
   * @param {object} options
   * @returns {object}
   */
  buildOptions(options) {
    return super.buildOptions(
      this.extend(
        {
          colors: ['#ef435e', '#C7E7E4'],
          categoryAxis: {
            boldLabels: true,
            gridAlpha: 0,
            gridThickness: 0,
          },
          valueAxes: [
            {
              id: 'ValueAxis-1',
              stackType: 'regular',
              axisAlpha: 0,
              axisColor: '#992C2C',
              axisThickness: 0,
              boldLabels: true,
              color: '#9B9B9B',
              gridThickness: 0,
            },
          ],
          legend: {
            align: 'center',
            autoMargins: false,
            marginLeft: 0,
            marginRight: 0,
            markerBorderThickness: 5,
            markerSize: 30,
            markerType: 'line',
            maxColumns: 5,
            valueWidth: 0,
            verticalGap: 0,
          },
        },
        options,
      ),
    );
  }
}

export default serialChartConfig({ controller: Controller });

export const config = (newConfig) =>
  serialChartConfig({ controller: Controller, ...newConfig });
