import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './relative-stats.component.html';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import useStream from '~/source/common/utilities/use-stream';
const styles = require('./relative-stats.component.scss');

export class RelativeStatsController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  relativeStats$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'relativeStats',
  );

  /* @ngInject */
  constructor() {
    useStreams([this.relativeStats$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const RelativeStatsComponent = {
  template,
  controller: RelativeStatsController,
  bindings: {
    relativeStats: '<',
  },
};
