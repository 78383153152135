import type { IHttpService } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  combineInProcesses,
  generateInProcessSubject,
  tapInProcessOn,
  catchErrorInProcessOff,
  tapFinalizeInProcessOff,
  shareReplayRefOne,
} from '@proftit/rxjs.adjunct';
import {
  initRequestDomainResource,
  getAsList,
  setTokenHeader,
  setApiUserCredentialsHeaders,
  jsonServerToCfdMongo,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

import { ngPerformJsonToMongo } from '~/source/common/utilities/ng-perform-json-to-mongo';

export const MT_GROUPS_RESOURCE_PATH = 'MTCustomerGroups';

export class ApiMt4PlatformService {
  /* @ngInject */
  constructor(readonly $http: IHttpService) {}
}
