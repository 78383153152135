import RestService from '~/source/common/services/rest';

const PASSWORD_RESET_EMAIL = 'passwordResetEmails';

class PasswordResetEmail extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return PASSWORD_RESET_EMAIL;
  }
}

export default PasswordResetEmail;
