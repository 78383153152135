import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-manager.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  AutomaticRegistrationReward,
  AutomaticFtdDepositReward,
  AutomaticNonFtdDepositReward,
} from '@proftit/crm.api.models.entities';
import {
  MarketingCampaignReward,
  MarketingCampaignRewardsSource,
  marketingCampaignRewards,
} from '~/source/common/data-sources/marketing-campaign-rewards-source';
import { BrandCurrency } from '~/source/common/models/brand-currency';

const styles = require('./automatic-bonus-manager.component.scss');

export class AutomaticBonusManagerController {
  automaticRegistrationRewards: AutomaticRegistrationReward[];
  automaticDepositRewardsFtd: AutomaticFtdDepositReward[];
  automaticDepositRewardsNonFtd: AutomaticNonFtdDepositReward[];
  brandCurrencies: BrandCurrency[];
  onChangeAutomaticRegistrationBonuses: (a: { bonusesData: any[] }) => void;
  onChangeAutomaticDepositBonusesFtd: (a: { bonusesData: any[] }) => void;
  onChangeAutomaticDepositBonusesNonFtd: (a: { bonusesData: any[] }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  items = marketingCampaignRewards;

  addRegionAction = new rx.Subject<{ bonus: MarketingCampaignRewardsSource }>();
  onBonusChangeAction = new rx.Subject<{
    bonus: MarketingCampaignRewardsSource;
    bonusesData: any[];
  }>();
  selectedBonusTypeAction = new rx.Subject<{
    regionId: number;
    bonus: MarketingCampaignRewardsSource;
  }>();

  automaticRegistrationRewards$ = observeShareCompChange<BrandCurrency[]>(
    this.lifecycles.onChanges$,
    'automaticRegistrationRewards',
  );
  automaticDepositRewardsFtd$ = observeShareCompChange<BrandCurrency[]>(
    this.lifecycles.onChanges$,
    'automaticDepositRewardsFtd',
  );
  automaticDepositRewardsNonFtd$ = observeShareCompChange<BrandCurrency[]>(
    this.lifecycles.onChanges$,
    'automaticDepositRewardsNonFtd',
  );
  isCreate$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'isCreate',
  );

  brandCurrencies$ = observeShareCompChange<BrandCurrency[]>(
    this.lifecycles.onChanges$,
    'brandCurrencies',
  );
  currencies$ = this.streamCurrencies();
  selectedBonusTypes$ = this.streamSelectedBonusTypes();
  selectedBonusTypesPerRegionId$ = this.streamSelectedBonusTypesPerRegionId();
  availableBonusTypes$ = this.streamAvailableBonusTypes();
  isAddTypeDisabled$ = this.streamIsAddTypeDisabled();

  /* @ngInject */
  constructor() {
    useStreams(
      [
        this.selectedBonusTypes$,
        this.availableBonusTypes$,
        this.isAddTypeDisabled$,
        this.selectedBonusTypesPerRegionId$,
        this.currencies$,
        this.streamAutomaticRegistrationRewards(),
        this.streamAutomaticDepositRewardsFtd(),
        this.streamAutomaticDepositRewardsNonFtd(),
        this.streamDefaultBonusRegion(),
        this.streamBonusChange(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBonusChange() {
    return rx.pipe(
      () => this.onBonusChangeAction,
      rx.withLatestFrom(this.isCreate$),
      rx.filter(([a, isCreate]) => isCreate),
      rx.tap(([{ bonus, bonusesData }]) => {
        switch (bonus.type) {
          case MarketingCampaignReward.Registration:
            this.onChangeAutomaticRegistrationBonuses({ bonusesData });
            break;
          case MarketingCampaignReward.DepositFtd:
            this.onChangeAutomaticDepositBonusesFtd({ bonusesData });
            break;
          case MarketingCampaignReward.DepositNonFtd:
            this.onChangeAutomaticDepositBonusesNonFtd({ bonusesData });
            break;
          default:
            break;
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCurrencies() {
    return rx.pipe(
      () => this.brandCurrencies$,
      rx.filter((brandCurrencies) => !_.isNil(brandCurrencies)),
      rx.map((brandCurrencies) =>
        brandCurrencies.map((brandCurrency) => brandCurrency.currency),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedBonusTypesPerRegionId() {
    return rx.pipe(
      () => this.selectedBonusTypes$,
      rx.map((selectedBonuses) => {
        const obj = {};
        selectedBonuses.forEach((bonus) => {
          obj[bonus.regionId] = bonus.bonus;
        });
        return obj;
      }),
      shareReplayRefOne(),
    )(null);
  }

  getDataForBonusType(bonusType: MarketingCampaignReward) {
    switch (bonusType) {
      case MarketingCampaignReward.Registration:
        return this.automaticRegistrationRewards;
      case MarketingCampaignReward.DepositFtd:
        return this.automaticDepositRewardsFtd;
      case MarketingCampaignReward.DepositNonFtd:
        return this.automaticDepositRewardsNonFtd;
      default:
        return null;
    }
  }

  streamIsAddTypeDisabled() {
    return rx.pipe(
      () => this.selectedBonusTypes$,
      rx.map(
        (displayedRegionIds) =>
          displayedRegionIds.length >= marketingCampaignRewards.length,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamAvailableBonusTypes() {
    return rx.pipe(
      () => this.selectedBonusTypes$,
      rx.map((selectedBonusTypes) =>
        selectedBonusTypes.filter((bonusType) => !_.isNil(bonusType.bonus)),
      ),
      rx.map((usedBonusTypes) =>
        usedBonusTypes.map((usedBonusType) => usedBonusType.bonus.type),
      ),
      rx.map((usedBonusCodes) =>
        marketingCampaignRewards.filter(
          (bonus) => !usedBonusCodes.includes(bonus.type),
        ),
      ),
      rx.withLatestFrom(this.selectedBonusTypes$),
      rx.map(([unusedBonuses, selectedBonusTypesWithRegionIds]) => {
        const obj = {};
        selectedBonusTypesWithRegionIds.map((regionBonusType) => {
          const doesRegionHaveBonusSelected = !_.isNil(regionBonusType.bonus);
          let availableBonuses;
          if (!doesRegionHaveBonusSelected) {
            availableBonuses = unusedBonuses;
          } else {
            const { label, type } = regionBonusType.bonus;
            availableBonuses = [{ label, type }, ...unusedBonuses];
          }
          obj[regionBonusType.regionId] = {
            regionId: regionBonusType.regionId,
            availableTypes: availableBonuses,
          };
        });
        return obj;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedBonusTypes() {
    const currentSelectedBonusTypes = new rx.BehaviorSubject<
      { regionId: number; bonus: MarketingCampaignRewardsSource }[]
    >([]);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSelectedBonusTypesFromSelection(currentSelectedBonusTypes),
          this.streamSelectedBonusTypesFromAddition(currentSelectedBonusTypes),
        ),
      rx.tap((newValue) => {
        currentSelectedBonusTypes.next(newValue);
      }),
      rx.startWith([]),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedBonusTypesFromAddition(
    currentSelectedBonusTypes: rx.Observable<any>,
  ) {
    let regionIdCounter = 0;
    return rx.pipe(
      () => this.addRegionAction,
      rx.tap(() => {
        regionIdCounter += 1;
      }),
      rx.withLatestFrom(currentSelectedBonusTypes),
      rx.map(([{ bonus }, currentSelectedBonusTypes]) => [
        ...currentSelectedBonusTypes,
        {
          bonus,
          regionId: String(regionIdCounter),
        },
      ]),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedBonusTypesFromSelection(
    currentSelectedBonusTypes: rx.Observable<any>,
  ) {
    return rx.pipe(
      () => this.selectedBonusTypeAction,
      rx.filter((bonusData) => !_.isNil(bonusData)),
      rx.map(({ regionId, bonus }) => {
        return { regionId, bonus };
      }),
      rx.withLatestFrom(currentSelectedBonusTypes),
      rx.map(([{ regionId, bonus }, currentSelectedBonusTypes]) =>
        currentSelectedBonusTypes.map((selectedBonus) => {
          if (selectedBonus.regionId !== regionId) {
            return selectedBonus;
          }
          return {
            regionId,
            bonus,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamDefaultBonusRegion() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.automaticRegistrationRewards$,
          this.automaticDepositRewardsFtd$,
          this.automaticDepositRewardsNonFtd$,
        ),
      rx.filter((array) => array.every((element) => element !== undefined)),
      rx.filter((array) =>
        array.every((element) => element === null || element.length === 0),
      ),
      rx.tap(() => {
        this.addRegionAction.next({ bonus: null });
      }),
      rx.take(1),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomaticRegistrationRewards() {
    return rx.pipe(
      () => this.automaticRegistrationRewards$,
      rx.filter((x) => !_.isNil(x) && x.length > 0),
      rx.withLatestFrom(this.selectedBonusTypes$),
      rx.filter(([registrationBonuses, selectedBonusTypes]) =>
        selectedBonusTypes.every(
          (bonusMetaData) =>
            bonusMetaData.bonus.type !== MarketingCampaignReward.Registration,
        ),
      ),
      rx.tap(() => {
        const bonus = marketingCampaignRewards.find(
          (bonus) => bonus.type === MarketingCampaignReward.Registration,
        );
        this.addRegionAction.next({ bonus });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomaticDepositRewardsFtd() {
    return rx.pipe(
      () => this.automaticDepositRewardsFtd$,
      rx.filter((x) => !_.isNil(x) && x.length > 0),
      rx.withLatestFrom(this.selectedBonusTypes$),
      rx.filter(([registrationBonuses, selectedBonusTypes]) =>
        selectedBonusTypes.every(
          (bonusMetaData) =>
            bonusMetaData.bonus.type !== MarketingCampaignReward.DepositFtd,
        ),
      ),
      rx.tap(() => {
        const bonus = marketingCampaignRewards.find(
          (bonus) => bonus.type === MarketingCampaignReward.DepositFtd,
        );
        this.addRegionAction.next({ bonus });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomaticDepositRewardsNonFtd() {
    return rx.pipe(
      () => this.automaticDepositRewardsNonFtd$,
      rx.filter((x) => !_.isNil(x) && x.length > 0),
      rx.withLatestFrom(this.selectedBonusTypes$),
      rx.filter(([registrationBonuses, selectedBonusTypes]) =>
        selectedBonusTypes.every(
          (bonusMetaData) =>
            bonusMetaData.bonus.type !== MarketingCampaignReward.DepositNonFtd,
        ),
      ),
      rx.tap(() => {
        const bonus = marketingCampaignRewards.find(
          (bonus) => bonus.type === MarketingCampaignReward.DepositNonFtd,
        );
        this.addRegionAction.next({ bonus });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const AutomaticBonusManagerComponent = {
  template,
  controller: AutomaticBonusManagerController,
  bindings: {
    campaignId: '<',
    brandCurrencies: '<',
    automaticRegistrationRewards: '<',
    automaticDepositRewardsFtd: '<',
    automaticDepositRewardsNonFtd: '<',
    isCreate: '<',
    platform: '<',
    onChangeAutomaticRegistrationBonuses: '&',
    onChangeAutomaticDepositBonusesFtd: '&',
    onChangeAutomaticDepositBonusesNonFtd: '&',
  },
};
