import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './voip-providers-list.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Brand } from '@proftit/crm.api.models.entities';
import { generateUuid } from '@proftit/general-utilities';
import { FormArray, FormControl, FormGroup } from '@proftit/ng1.reactive-forms';

const styles = require('./voip-providers-list.component.scss');

export type VoipRegion = {
  voipProvider: any;
  regionId: string;
  isNewRegion: boolean;
  isInEdit: boolean;
  shouldShowVoip: boolean;
};

export class VoipProvidersListController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  addVoipRegionAction: rx.Subject<void>;
  regionCancelAction = new rx.Subject<VoipRegion>();
  isAddVoipRegionDisabled: boolean;

  allVoipProviders: any[];
  unusedVoipProviders: any;
  regions: any[];

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');
  configuredVoipProvidersForBrand$ = observeShareCompChange<Brand>(
    this.lifecycles.onChanges$,
    'configuredVoipProvidersForBrand',
  );

  model = [];

  /* @ngInject */
  constructor() {
    useStreams(
      [this.brand$, this.configuredVoipProvidersForBrand$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamRegions(),
        this.streamAddRegion(),
        this.streamCancelNewRegion(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamRegions() {
    return rx.pipe(
      () => this.configuredVoipProvidersForBrand$,
      rx.tap((configuredVoips) => {
        // copying to prevent 2 way binding ruining the original data
        const copiedConfiguredVoips = _.cloneDeep(configuredVoips);
        this.regions = this.initVoipRegions(copiedConfiguredVoips);
        this.updateUnusedVoipProviders();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAddRegion() {
    return rx.pipe(
      () => this.addVoipRegionAction,
      rx.tap(() => {
        const isDefault = _.isEmpty(this.regions) ? true : false;
        this.regions = [
          ...this.regions,
          this.getNewVoipRegion(null, true, true, isDefault),
        ];
        this.updateIsAddVoipRegionDisabled();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCancelNewRegion() {
    return rx.pipe(
      () => this.regionCancelAction,
      rx.filter((region) => region.isNewRegion),
      rx.tap((region) => {
        this.regions = this.regions.filter(
          (r) => r.regionId !== region.regionId,
        );
        this.updateIsAddVoipRegionDisabled();
        this.updateUnusedVoipProviders();
      }),
      shareReplayRefOne(),
    )(null);
  }

  updateIsAddVoipRegionDisabled() {
    this.isAddVoipRegionDisabled =
      this.regions.length >= this.allVoipProviders.length;
  }

  initVoipRegions(configuredVoipProvidersForBrand) {
    if (_.isNil(configuredVoipProvidersForBrand)) {
      return [];
    }
    return configuredVoipProvidersForBrand.map((voipProviderData) => {
      return this.getNewVoipRegion(
        voipProviderData,
        false,
        false,
        voipProviderData.isDefault,
      );
    });
  }

  getNewVoipRegion(
    voipData,
    isNewRegion: boolean,
    isInEdit: boolean,
    isDefault: boolean,
  ) {
    const credentials = !_.isNil(voipData) ? voipData.credentials : null;
    const voipProvider = !_.isNil(voipData) ? voipData.voipProvider : null;
    const voipCredentialsId = !_.isNil(voipData) ? voipData.id : null;
    const mappedDids = !_.isNil(voipData) ? voipData.mappedDids : null;

    return {
      isNewRegion,
      isInEdit,
      voipProvider,
      isDefault,
      voipCredentialsId,
      mappedDids,
      voipCredentials: credentials,
      regionId: generateUuid(),
      shouldShowVoip: !_.isNil(voipData) ? voipData.shouldShowVoip : false,
    };
  }

  updateUnusedVoipProviders() {
    if (_.isNil(this.allVoipProviders)) {
      return;
    }
    const usedVoipProviderIdsSet = new Set(
      this.regions
        .map((region) => region.voipProvider)
        .filter((x) => !_.isNil(x))
        .map((x) => x.id),
    );
    this.unusedVoipProviders = this.allVoipProviders.filter(
      (x) => !usedVoipProviderIdsSet.has(x.id),
    );
  }

  onProviderChangeEvent() {
    this.updateUnusedVoipProviders();
  }

  $onDestroy() {}

  $onChanges() {}
}

export const VoipProvidersListComponent = {
  template,
  controller: VoipProvidersListController,
  bindings: {
    brand: '<',
    allVoipProviders: '<',
    configuredVoipProvidersForBrand: '<',
    addVoipRegionAction: '<',
    isAddVoipRegionDisabled: '=',
    reloadProviders: '&',
  },
};
