import BaseController from '~/source/common/controllers/base';
import template from './deposit-details.html';
const childStyles = require('./deposit-details.scss');
import * as _ from '@proftit/lodash';
import { TradingAccountDeposit } from '@proftit/crm.api.models.entities';

class DepositDetailsController extends BaseController {
  childStyles = childStyles;
  deposit: TradingAccountDeposit;
  infoTableToCopy = '';
  customFieldsToCopy = '';
  ewalletDepositUrl: string;

  $onInit() {
    this.ewalletDepositUrl = this.calcEwalletDepositUrl(this.deposit);

    !_.isNil(this.deposit.transactionTransferWire?.customFields)
      ? this.deposit.transactionTransferWire.customFields.map((field) => {
          this.customFieldsToCopy = `
            ${field.key}: ${field.value}
            `;
        })
      : '';

    this.infoTableToCopy = `
      Card holder name: ${
        this.deposit.transactionTransferWire?.holderName || ''
      }
      Bank name: ${this.deposit.transactionTransferWire?.bankName || ''}
      Bank address: ${this.deposit.transactionTransferWire?.bankAddress || ''}
      Bank city: ${this.deposit.transactionTransferWire?.bankCity || ''}
      IBAN: ${this.deposit.transactionTransferWire?.iban || ''}
      SWIFT: ${this.deposit.transactionTransferWire?.swiftCode || ''}
      ${this.customFieldsToCopy}
    `;
  }

  calcEwalletDepositUrl(deposit: TradingAccountDeposit) {
    if (!deposit.currency.isCrypto) {
      return null;
    }

    if (deposit.currency.code === 'BTC') {
      return `https://www.blockchain.com/btc/tx/${deposit.ewalletTransaction.transactionId}`;
    }

    return `https://etherscan.io/tx/${deposit.ewalletTransaction?.transactionId}`;
  }
}

export default {
  template,
  controller: DepositDetailsController,
  bindings: {
    deposit: '<',
  },
};
