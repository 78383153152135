let Level;

(function (Level) {
  Level[Level["Trace"] = 0] = "Trace";
  Level[Level["Debug"] = 1] = "Debug";
  Level[Level["Info"] = 2] = "Info";
  Level[Level["Warn"] = 3] = "Warn";
  Level[Level["Error"] = 4] = "Error";
})(Level || (Level = {}));

export { Level };
