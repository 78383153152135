import ng from 'angular';
import ForgotPasswordComponent from './forgot-password.component';
import ResetPasswordComponent from './reset-password.component';
import EmailSentComponent from './email-sent.component';
import UserNotFoundComponent from './user-not-found.component';

export default ng
  .module('crm.auth.forgotPass', ['crm.auth', 'ui.router'])
  .component('prfForgotPasswordPage', ForgotPasswordComponent)
  .component('prfResetPasswordPage', ResetPasswordComponent)
  .component('prfForgotPassUserNotFoundPage', UserNotFoundComponent)
  .component('prfForgotPassEmailSentPage', EmailSentComponent)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('auth.forgot-password', {
          url: '/forgot-password',
          component: 'prfForgotPasswordPage',
        })
        .state('auth.reset-password', {
          url: '/reset-password?token',
          component: 'prfResetPasswordPage',
        })
        .state('auth.forgot-password.user-not-found', {
          url: '/user-not-found',
          component: 'prfForgotPassUserNotFoundPage',
        })
        .state('auth.forgot-password.email-sent', {
          url: '/email-sent',
          component: 'prfForgotPassEmailSentPage',
        });
    },
  ]).name;
