import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { observeHandlerIsValid } from '../utilities/observe-handler-is-valid';
import { observeHandlerValidationResults } from '../utilities/observe-handler-validation-results';
import { ProxyFormValidationMessageGeneratorService } from '../proxy-form-validation-message-generator.service';
import template from './handler-validation-message.component.html';
const styles = require('./handler-validation-message.component.scss');

export class HandlerValidationMessageController {
  /* injections */

  prfProxyFormValidationMessageGenerator: {
    item$: rx.Observable<ProxyFormValidationMessageGeneratorService>;
  };

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  handlerIn$ = observeShareCompChange<any>(
    this.lifecycles.onChanges$,
    'handler',
  );

  prfProxyFormValidationMessageGenerator$ = new rx.BehaviorSubject<
    ProxyFormValidationMessageGeneratorService
  >(null);

  /* streams */

  isValidS = this.streamIsValid();

  validationResultsS = this.streamValidationResults();

  messageS = this.streamMessage();

  /* @ngInject */
  constructor() {
    useStreams(
      [this.handlerIn$, this.isValidS.stream$, this.validationResultsS.stream$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.prfProxyFormValidationMessageGenerator.item$.pipe(
          rx.tap((service) =>
            this.prfProxyFormValidationMessageGenerator$.next(service),
          ),
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamIsValid() {
    return generateInProcessStream(
      this.handlerIn$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((handler) => observeHandlerIsValid(handler)),
          rx.map(({ isValid }) => isValid),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamValidationResults() {
    return generateInProcessStream(
      this.handlerIn$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((handler) => observeHandlerValidationResults(handler)),
          rx.map(({ validationResults }) => validationResults),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamMessage() {
    return generateInProcessStream(
      rx.obs.combineLatest(
        this.isValidS.stream$,
        this.validationResultsS.stream$,
        this.prfProxyFormValidationMessageGenerator$,
      ),
      (inProcess$) =>
        rx.pipe(
          rx.switchMap(([isValid, validationResults, messageGenerator]) => {
            if (isValid || _.isNil(messageGenerator)) {
              return rx.obs.of(null);
            }

            const firstMessage = validationResults[0];
            return messageGenerator.generate(firstMessage);
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }
}

export const HandlerValidationMessageComponent = {
  template,
  controller: HandlerValidationMessageController,
  bindings: {
    handler: '<',
  },
  require: {
    prfProxyFormValidationMessageGenerator: '^',
  },
};
