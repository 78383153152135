import template from './risk-free-create.html';
import RiskFreeCommonController from './risk-free-base.controller';

class RiskFreeCreateController extends RiskFreeCommonController {
  quantityToAdd = undefined;

  /**
   * Add risk free positions and call the 'onDone' callback on success
   *
   * @return {Promise} resolves on success
   */
  addRiskFree() {
    //  make sure to always pass either a value or an 'undefined' (not null)
    return this.updateRiskFreePositions(
      this.customer.id,
      this.account.id,
      this.quantityToAdd || 0,
    ).then(() => {
      this.account.riskFreeAvailable += this.quantityToAdd;
      this.onDone();
    });
  }
}

export default {
  template,
  controller: RiskFreeCreateController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
  },
};
