import ng from 'angular';
// tslint:disable-next-line:no-duplicate-imports
import type { IPromise } from 'angular';
import template from './task-data.html';
import BaseController from '~/source/common/controllers/base';
import * as _ from '@proftit/lodash';
import { TokensService } from '~/source/auth/services/tokens';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import { TasksService } from '../tasks.service';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';
import { DateTzAdjustService } from '~/source/common/services/date-tz-adjust';
import type { Task } from '@proftit/crm.api.models.entities';
import { DateFormatConstants } from '~/source/common/constants/date-format';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import {
  TASK_UPDATED,
  TASK_CREATED,
} from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import addFns from 'date-fns/add';
import { Moment } from 'moment';

class TasksDataController extends BaseController {
  onDone: () => void;
  isEdit: boolean;
  taskIn: IElementRestNg<Task>;
  task: Partial<
    IElementRestNg<Task | { id: number; dueDate: Moment | string | Date }>
  >;
  isPopup: boolean;

  user: UserTokenModel;
  hasEditPermission: boolean;
  dataServiceInstance: TasksService;
  isNewTask: boolean;
  prevAttributes;

  dueDate: Date;

  /*@ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly tasksService: TasksService,
    readonly dateFormat: DateFormatConstants,
    readonly growl: ng.growl.IGrowlService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly dateTzAdjustService: DateTzAdjustService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();

    this.dataServiceInstance = this.tasksService;
    this.user = this.tokensService.getCachedUser();
    this.hasEditPermission = true;
  }

  /**
   * Returns a promise of a current task (new/from binding/from params) that will be resolved once the data is ready
   *
   * @returns {Promise}
   */
  $onInit() {
    this.isNewTask = !this.taskIn;
    this.task = this.taskIn as any;
    if (_.isEmpty(this.task)) {
      this.task = {};
    }
    this.hasEditPermission = this.hasEditPermissions();

    this.task.dueDate =
      this.task?.dueDate ||
      this.dueDate ||
      this.dateTzAdjustService.getUTCAdjustedTime(
        addFns(new Date(), { minutes: 10 }),
      );
  }

  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = { ...this.task };
    // Enter edit mode
    this.isEdit = true;
  }

  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.task, this.prevAttributes);
    this.isEdit = false;
  }

  /**
   * Save the new task to the server.
   * @param {object} task
   * @returns {Promise}
   */
  createTask(task: Task) {
    return this.dataServiceInstance
      .setConfig({ growlRef: 'taskEditForm', blockUiRef: 'taskEditForm' })
      .postWithQuery(task)
      .then(() => {
        this.onDoneEdit();
      })
      .catch((err) => {
        const message =
          err instanceof Error ? err.message : 'errors.GENERAL_SERVER_ERROR';
        this.growl.error(message, { referenceId: 'taskInfo' as any });
      });
  }

  /**
   * Update task
   * @param {object} task (not in  editable mode task contains only one param: 'taskStatus',
   * in editable mode task contains the whole task params: subject, content, creator, employee, alert, taskStatus)
   * @returns {Promise}
   */
  updateTask(task): Promise<any> {
    const prevNormalized = this.modelNormalizer.normalize(
      _.omit('id', this.prevAttributes),
    );
    let update = this.modelNormalizer.diff(task, prevNormalized);

    if (_.isEmpty(update)) {
      // nothing to update; exit update and resolve.
      this.onDoneEdit();
      return Promise.resolve();
    }
    // not editable mode (user able to change just status field)
    if (!this.hasEditPermission) {
      update = { taskStatusId: task.taskStatusId };
    }

    return ((this.task.patch(update) as any) as Promise<any>).then(() => {
      this.onDoneEdit();
    });
  }

  /**
   * Called before posting new/updated task
   *
   */
  onSubmit() {
    const normalizedTask = this.modelNormalizer.normalize(
      Object.assign({}, _.omit(['id'], this.task)),
    );
    // Convert the "adjusted" date object back to a normal date
    normalizedTask.dueDate = this.dateTzAdjustService.format(
      this.task.dueDate as any,
    );

    if (!this.isNewTask) {
      return this.updateTask(normalizedTask).then((task) =>
        this.prfClientGeneralPubsub.publish(TASK_UPDATED, task),
      );
    }

    return this.createTask(normalizedTask).then((task) =>
      this.prfClientGeneralPubsub.publish(TASK_CREATED, task),
    );
  }

  /**
   * New task: there is no 'model' transferred from promise
   * Editable Task: user == creator
   * (editable : all task fields are in 'edit' mode, not editable: just 'taskStatus' is in edit mode)
   *
   */
  hasEditPermissions() {
    if (_.isEmpty(this.task)) {
      return true;
    }
    return _.getEs(this.task, 'creator.id') === this.user.id;
  }

  onDoneEdit() {
    if (!this.isNewTask) {
      this.task.dueDate = this.dateTzAdjustService.fromUTCAdjustedTime(
        this.task.dueDate as any,
      );
    }

    this.isEdit = false;
    this.onDone();
  }
}

export default {
  template,
  controller: TasksDataController,
  controllerAs: 'vm',
  bindings: {
    onDone: '&',
    isEdit: '<',
    taskIn: '<',
    isPopup: '<',
    dueDate: '<',
  },
};
