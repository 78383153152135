import { CustomerComplianceFileTypeCode } from '@proftit/crm.api.models.enums';

interface FactionData {
  code: string;
  icon: string;
  complianceTypes: string[];
  groupForStatus: string;
}

export const kycCompliancesTypesUiFactions: FactionData[] = [
  {
    code: CustomerComplianceFileTypeCode.IdentityDocument,
    icon: 'svg-i-attachments-id',
    complianceTypes: [CustomerComplianceFileTypeCode.IdentityDocument],
    groupForStatus: CustomerComplianceFileTypeCode.IdentityDocument,
  },
  {
    code: CustomerComplianceFileTypeCode.UtilityBill,
    icon: 'svg-i-attachments-bills',
    complianceTypes: [CustomerComplianceFileTypeCode.UtilityBill],
    groupForStatus: CustomerComplianceFileTypeCode.UtilityBill,
  },
  {
    code: 'complianceCreditCardFaction',
    icon: 'svg-i-attachments-cc',
    complianceTypes: [
      CustomerComplianceFileTypeCode.CreditCardFront,
      CustomerComplianceFileTypeCode.CreditCardBack,
    ],
    groupForStatus: CustomerComplianceFileTypeCode.CreditCardFront,
  },
  {
    code: CustomerComplianceFileTypeCode.DepositDocument,
    icon: 'svg-i-attachments-dod',
    complianceTypes: [CustomerComplianceFileTypeCode.DepositDocument],
    groupForStatus: CustomerComplianceFileTypeCode.DepositDocument,
  },
  {
    code: CustomerComplianceFileTypeCode.Qestionnaire,
    icon: 'svg-i-attachments-questionnaire',
    complianceTypes: [CustomerComplianceFileTypeCode.Qestionnaire],
    groupForStatus: CustomerComplianceFileTypeCode.Qestionnaire,
  },
];
