import BaseController from '~/source/common/controllers/base';
import template from './cms-form.html';

class ComponentController extends BaseController {}

const CmsFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
  require: {
    form: '^form',
  },
};

export default CmsFormComponent;
