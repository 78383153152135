import Component from '~/source/common/components/dropdowns/base/component';
import CustomerIdTypeService from '../services/customer-id-type.service';

export class CustomerIdTypeSelectController extends Component.controller {
  static $inject = ['customerIdTypeService', ...Component.controller.$inject];

  customerIdTypeService: CustomerIdTypeService;

  get defaultPlaceholder() {
    return 'common.SELECT_ID_TYPE';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'contact.idType';
  }

  get nullableElement() {
    return {
      id: null,
      code: 'null',
      nullable: true,
    };
  }
}

const CustomerIdTypeSelectComponent = Component.config({
  controller: CustomerIdTypeSelectController,
});

export default CustomerIdTypeSelectComponent;
