import template from './general-modal-header.html';

class Controller {
  minimized = false;
}

export const GeneralModalHeaderComponent = {
  template,
  controller: Controller,
  bindings: {
    header: '@',
    disableMinimizeOption: '<',
    onCloseButtonClick: '&',
  },
};

export default GeneralModalHeaderComponent;
