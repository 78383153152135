import * as _ from '@proftit/lodash';
import template from './contact-higlights.html';
import ControllerBase from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import { TradingHighlights } from '~/source/common/models/trading-highlights';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class Controller extends ControllerBase {
  // Bindings
  customerId: number;

  highlights: TradingHighlights;

  /*@ngInject*/
  constructor(readonly customersService: () => CustomersService) {
    super();
  }

  $onInit() {
    this.highlights = (this.customersService()
      .setConfig({ blockUiRef: 'highlights' })
      .getStatsResource(this.customerId, 'trading')
      .expand(['tradeReputation'])
      .getOneWithQuery<IElementRestNg<TradingHighlights>>() as any).$object;
  }

  /**
   * Returns highest number of assets in various groups (winning, losing and top)
   *
   * @returns {number}
   */
  get maxQty() {
    // received from server, if empty - return 0
    if (_.isEmpty(this.highlights)) {
      return 0;
    }
    return Math.max(
      this.highlights.winningAssets.length,
      this.highlights.losingAssets.length,
      this.highlights.topAssets.length,
    );
  }

  /**
   * Returns an array with the length of the rows needed to hold the top assets list.
   * so we could ng-repeat this num and create the rows background
   * @returns {Array}
   */
  getTopAssetRowsAr() {
    // Calculate number of rows needed - the largest between winning and losing assets num
    const rowsNum = this.maxQty;
    // create an array the size of this range
    return _.range(0, rowsNum);
  }

  /**
   * Returns true if there is at least one winning or losing asset
   * @returns {boolean}
   */
  hasTopAssets() {
    if (_.isEmpty(this.highlights)) {
      return false;
    }

    return !!this.maxQty;
  }

  getFlagSign(flagsString, index) {
    let result = '';
    if (flagsString) {
      result = flagsString.split('/')[index];
    }
    return result;
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customerId: '<',
  },
};
