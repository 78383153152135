export const brandPlatformMt4GroupSecuritiesTableColumns = [
  {
    field: '',
    fieldFormat: '{{ mtCustomerGroup.name }}',
    filterable: false,
    removable: false,
    sortable: 'name',
    show: true,
    title: 'common.GROUP_NAME',
  },
  {
    field: '',
    fieldFormat: '{{ name }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.SECURITIES',
  },
  {
    field: '',
    fieldFormat: '{{ minVolume }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.MIN_LOTS',
  },
  {
    field: '',
    fieldFormat: '{{ maxVolume }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.MAX_LOTS',
  },
  {
    field: '',
    fieldFormat: '{{ volumeStep }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.STEP',
  },
  {
    field: '',
    fieldFormat: '{{ spread }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.MARKUP',
  },
  {
    // This is empty col for control settings
    filterable: false,
    removable: false,
    renderer: 'actions',
    sortable: false,
    show: true,
    title: '',
  },
];
