export const questionnaireRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.tools.questionnaire', {
        redirectTo: 'crm.management.tools.questionnaire.dashboard',
        url: '/questionnaire',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.questionnaire.dashboard', {
        url: '/dashboard',
        component: 'prfQuestionnaireDashboard',
      })
      .state('crm.management.tools.questionnaire.create', {
        url: '/create',
        component: 'prfQuestionnaireCreate',
      })
      .state('crm.management.tools.questionnaire.edit', {
        url: '/edit/:id?action&langId',
        component: 'prfQuestionnaireEdit',
      })
      .state('crm.management.tools.questionnaire.score', {
        url: '/score',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.questionnaire.score.create', {
        url: '/create',
        component: 'prfQuestionnaireScorePage',
        data: {
          action: 'create',
        },
      })
      .state('crm.management.tools.questionnaire.score.edit', {
        url: '/edit/:id?questionnaireFormId',
        component: 'prfQuestionnaireScorePage',
        data: {
          action: 'update',
        },
      });
  },
];
