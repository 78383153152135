let MessageCode;

(function (MessageCode) {
  MessageCode["SetAppFingerprint"] = "SET_APP_FINGERPRINT";
  MessageCode["SetAppConfig"] = "SET_APP_CONFIG";
  MessageCode["SetCurrentLoggedUser"] = "SET_CURRENT_LOGGED_USER_ACTION";
  MessageCode["UpdateCurrentLoggedUser"] = "UPDATE_CURRENT_LOGGED_USER_ACTION";
  MessageCode["SetIsVoipEnabled"] = "SET_IS_VOIP_ENABLED";
  MessageCode["SetIsCurrentUserLoggedIn"] = "SET_IS_CURRENT_USER_LOOGED_IN";
  MessageCode["RegisterToNewCall"] = "REGISTER_TO_NEW_CALL";
  MessageCode["RegisterToActiveCall"] = "REGISTER_TO_ACTIVE_CALL";
  MessageCode["NotifyHeartbeatNewCall"] = "NOTIFY_HEARBEAT_NEW_CALL";
  MessageCode["NotifyHeartbeatActiveCall"] = "NOTIFY_HEARBEAT_ACTIVE_CALL";
})(MessageCode || (MessageCode = {}));

let OutputMessageCode;

(function (OutputMessageCode) {
  OutputMessageCode["NewCallEmission"] = "NEW_CALL_EMISSSION";
  OutputMessageCode["ActiveCallEmission"] = "ACTIVE_CALL_EMISSSION";
})(OutputMessageCode || (OutputMessageCode = {}));

export { MessageCode, OutputMessageCode };
