import { Permissions } from '~/source/common/models/permission-structure';

export const promoCodesReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          requestedAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'brand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CODE',
        field: 'code',
        fieldName: 'code',
        fieldFormat: '{{ code }}',
        reportFields: ['code'],
        filterable: false,
        sortable: 'code',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.END_DATE',
        field: 'endDate',
        fieldName: 'endDate',
        fieldFormat: '{{ endDate }}',
        reportFields: ['endDate'],
        filterable: true,
        sortable: 'endDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accountStatus.ACTIVE',
        field: 'active',
        fieldName: 'active',
        fieldFormat: '{{ active }}',
        reportFields: ['active'],
        filterable: true,
        sortable: 'active',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PLATFORM',
        field: 'platform',
        fieldName: 'platform',
        fieldFormat: '{{ platform }}',
        reportFields: ['platform'],
        filterable: false,
        sortable: 'platform',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'marketing.campaign.REWARD_TYPE',
        field: 'rewardType',
        fieldName: 'rewardType',
        fieldFormat: '{{ rewardType }}',
        reportFields: ['rewardType'],
        filterable: false,
        sortable: 'rewardType',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.START_DATE',
        field: 'startDate',
        fieldName: 'startDate',
        fieldFormat: '{{ startDate }}',
        reportFields: ['startDate'],
        filterable: true,
        sortable: 'startDate',
        removable: true,
        show: true,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
