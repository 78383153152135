export const bundlesTableCols = [
  {
    id: 'subTableAction',
    // open/close sub table action column
    renderer: 'subTableAction',
    title: '',
    field: '',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    id: 'syncRemote',
    title: 'contact.bundles.SYNC_REMOTE_ID',
    field: 'syncRemote',
    fieldFormat: '{{ syncRemoteId }}',
    sortable: 'syncRemoteId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'name',
    title: 'contact.bundles.NAME',
    field: 'bundleTradeBundle',
    fieldFormat: '{{ name }}',
    sortable: 'name',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'investment',
    title: 'contact.bundles.INVESTMENT',
    field: 'investment',
    fieldFormat:
      '{{ investment | currency: tradingAccount.currency.code : tradingAccount.currency.decimals }}',
    sortable: 'investment',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'entryDate',
    title: 'contact.bundles.ENTRY_DATE',
    field: 'entryDate',
    fieldFormat: '{{ entryDate | amDateFormat:"L LTS" }}',
    sortable: 'entryDate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'expiryDate',
    title: 'contact.bundles.EXPIRY_DATE',
    field: 'expiryDate',
    fieldFormat: '{{ expiryDate | amDateFormat:"L LTS" }}',
    sortable: 'expiryDate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'payout',
    title: 'contact.bundles.PAYOUT',
    field: 'payout',
    fieldFormat:
      '{{ payout | currency: tradingAccount.currency.code : tradingAccount.currency.decimals }}',
    sortable: 'payout',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'profit',
    title: 'contact.bundles.PROFIT',
    field: 'profit',
    fieldFormat:
      '{{ profit | currency: tradingAccount.currency.code : tradingAccount.currency.decimals }}',
    sortable: 'profit',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    id: 'bundleStatus',
    title: 'contact.bundles.STATUS',
    field: 'bundleStatusCode',
    fieldFormat:
      '{{ "position.statuses." + statusCode.toUpperCase() | translate }}',
    sortable: 'statusCode',
    show: true,
    filterable: true,
    removable: false,
  },
];
