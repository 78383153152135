import TransactionController from '../transaction-base.controller';
import bonusSettings from './bonus-settings.json';
import {
  TradingAccount,
  Customer,
  TransactionTransferBonus,
} from '@proftit/crm.api.models.entities';

class BonusCommonController extends TransactionController {
  // bindings
  account: TradingAccount;
  customer: Customer;
  onDone: () => void;

  bonus: Partial<TransactionTransferBonus>;
  /**
   * Returns deposit type
   *
   * @returns {String}
   */
  get depositType() {
    return bonusSettings.types.bonus;
  }

  /**
   * Method that should be used to add bonus
   *
   * @returns {string}
   */
  get depositMethod() {
    return 'addBonus';
  }

  /**
   * Name of event that will cause bonus table to reload
   *
   * @returns {string}
   */
  get reloadTableEvent() {
    return 'bonusesTableReload';
  }
}

export default BonusCommonController;
