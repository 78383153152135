import ng from 'angular';

import { DesignTemplatesService } from './services/design-templates.service';
import { ContentTemplatesService } from './services/content-templates.service';
import { ContentTemplatesLogsService } from './services/content-templates-logs.service';
import { DesignTemplatesLogsService } from './services/design-templates-logs.service';
import { TemplatePlaceholdersService } from './services/template-placeholders.service';
import { ContentTemplatesPreviewsService } from './services/content-templates-previews.service';
import { SystemEmailTypesService } from './services/system-email-types.service';
import { SystemEmailTemplatesService } from './services/system-email-templates.service';
import { ContactsImportsService } from './services/contacts-imports.service';
import { ContactsImportStatusesService } from './services/contacts-import-statuses.service';

export const ApiCrmServerModule = ng.module('crm.apicrmserver', []);

ApiCrmServerModule.service(
  'designTemplatesService',
  DesignTemplatesService.$factorize(),
)
  .factory('contentTemplatesService', ContentTemplatesService.$factorize())
  .factory(
    'designTemplatesLogsService',
    DesignTemplatesLogsService.$factorize(),
  )
  .factory(
    'contentTemplatesLogsService',
    ContentTemplatesLogsService.$factorize(),
  )
  .factory(
    'templatePlaceholdersService',
    TemplatePlaceholdersService.$factorize(),
  )
  .factory(
    'contentTemplatesPreviewsService',
    ContentTemplatesPreviewsService.$factorize(),
  )
  .factory('prfSystemEmailTypesService', SystemEmailTypesService.$factorize())
  .factory(
    'prfSystemEmailTemplatesService',
    SystemEmailTemplatesService.$factorize(),
  )
  .factory('prfContactsImportsService', ContactsImportsService.$factorize())
  .factory(
    'prfContactsImportStatusesService',
    ContactsImportStatusesService.$factorize(),
  );
