import template from './fee-inactivity-details-row.component.html';
const styles = require('./fee-inactivity-details-row.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { CustomersService } from '~/source/contact/common/services/customers';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import {
  Customer,
  TradingAccount,
  InactivityFee,
} from '@proftit/crm.api.models.entities';
import { Fee } from '~/source/common/models/fee';

export class FeeInactivityDetailsRowController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customer: Customer;
  account: TradingAccount;
  fee: Fee;

  inactivityFee$ = new rx.BehaviorSubject<InactivityFee>(null);

  /*@ngInject */
  constructor(readonly customersService: () => CustomersService) {
    useStreams([this.streamGetInactivityDetails()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamGetInactivityDetails() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() =>
        this.customersService()
          .getInactivityFeeResource(
            this.customer.id,
            this.account.id,
            this.fee.id,
          )
          .getListWithQuery<IElementRestNg<InactivityFee>>(),
      ),
      rx.switchMap((x) => rx.obs.from(x)),
      rx.map((fees) => (fees.length === 0 ? null : fees[0])),
      rx.tap((fee) => this.inactivityFee$.next(fee)),
    )(null);
  }
}

export const FeeInactivityDetailsRowComponent = {
  template,
  controller: FeeInactivityDetailsRowController,
  bindings: {
    customer: '<',
    account: '<',
    fee: '<',
  },
};
