import type { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';
import { BrandsService } from '~/source/management/brand/services/brands';
import { BrandStore } from './brand-store';
import { CountriesStore } from './countries-store';

export function useBrandStore(
  brandsService: () => BrandsService,
  countriesStore: CountriesStore,
  lifecycles: ComponentLifecyclesObservables,
) {
  const store = new BrandStore(brandsService, countriesStore, lifecycles);

  return [store];
}
