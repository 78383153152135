import template from './brand-withdrawals-create.component.html';
const styles = require('./brand-withdrawals-create.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class BrandWithdrawalsCreateController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandWithdrawalsCreateComponent = {
  template,
  controller: BrandWithdrawalsCreateController,
  bindings: {
    brand: '<',
  },
};
