import template from './customer-search-select.component.html';
const styles = require('./customer-search-select.component.scss');

import ng from 'angular';
import log from 'loglevel';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { SearchService } from '~/source/search/search.service';
import {
  Customer,
  SearchCustomerResult,
} from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { createNgModelSubject } from '../../utilities/create-ng-model-subject';
import { useStreams } from '@proftit/rxjs.adjunct';
import { generateBlockuiId } from '../../utilities/generate-blockui-id';
import { generateGrowlId } from '../../utilities/generate-growl-id';
import { getCompChange } from '../../utilities/rx-ng-one/operators/get-comp-change';

interface ISearchResponse {
  id: string;
  results: SearchCustomerResult[];
}

const INPUT_DEBOUNCE_DELAY = 300;

export class CustomerSearchSelectController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  onPostSearch: (a: { results: SearchCustomerResult[] }) => void;
  onResultSelect: (a: { customer: SearchCustomerResult }) => void;

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  opResultSelect$ = new rx.Subject<SearchCustomerResult>();
  opSearchTerm$ = new rx.Subject<string>();
  searchTerm$ = this.streamSearchTerm();
  searchTerm = createNgModelSubject(this.searchTerm$, this.opSearchTerm$);
  results$ = this.streamResults();
  showResults$ = this.streamShowResults();

  /*@ngInject */
  constructor(readonly searchService: () => SearchService) {
    useStreams(
      [
        this.searchTerm.asObservable(),
        this.results$,
        this.showResults$,
        this.streamActionOnResultSelected(),
        this.streamActiononPostSearch(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamSearchTerm() {
    return rx.pipe(
      () => this.opSearchTerm$,
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamResults() {
    return rx.pipe(
      () => this.opSearchTerm$,
      rx.debounceTime(INPUT_DEBOUNCE_DELAY),
      rx.switchMap((searchTerm) => {
        // We want to return empty results without server when no search term.
        if (_.isEmpty(searchTerm)) {
          return rx.obs.from([[]]);
        }

        return rx.pipe(
          () => rx.obs.from(this.performSearch(searchTerm)),
          rx.catchError((err, org) => {
            log.error('account search select -> stream search perform -> ajax');
            return rx.obs.from([[]]);
          }),
        )(null);
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamShowResults() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<boolean>('showResults'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamActionOnResultSelected() {
    return rx.pipe(
      () => this.opResultSelect$,
      rx.tap((customer) => this.onResultSelect({ customer })),
    )(null);
  }

  streamActiononPostSearch() {
    return rx.pipe(
      () => this.results$,
      rx.tap((results: SearchCustomerResult[]) =>
        this.onPostSearch({ results }),
      ),
    )(null);
  }

  /**
   * Perform search
   *
   * @returns {undefined}
   */
  performSearch(searchTerm) {
    return this.searchService()
      .setConfig({
        blockUiRef: this.blockUiId,
      })
      .search(searchTerm)
      .findCustomers()
      .then((data) => data.plain())
      .then((data: { results: SearchCustomerResult[] }) => data.results);
  }
}

export const CustomerSearchSelectComponent = {
  template,
  controller: CustomerSearchSelectController,
  bindings: {
    model: '<',
    showResults: '<',
    onPostSearch: '&',
    onResultSelect: '&',
  },
};
