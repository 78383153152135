import ng, { IHttpService } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { tableSettings } from './table-settings';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { cfdTableColumns } from './cfd-table-columns';
import { mtTableColumns } from './mt-table-columns';
import { PlatfromAssetsService } from '../../services/platform-assets.service';
import { PlatfromGroupsService } from '~/source/management/integrations/risk-manager/services/platform-groups.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { CurrentPlatformOfBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-of-brand-store-service.directive';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { PlatformCode, MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { CurrentTrcGroupsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-groups-of-platform-service.directive';
import template from './cfd-platform-brand-assets-activity-log-table.component.html';
import { CurrentTrcAssetsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-assets-of-platform-service.directive';
import { switchOnEx } from '@proftit/general-utilities';
import { EntityWithCode } from '@proftit/crm.api.models.general';
import { TradingCoreAuditLogsApiService } from '../../services/trading-core-audit-logs.api.service';
import { formatGeneralAssetAuditLogBodyToPresentation } from '../../utilities/format-general-asset-audit-log-body-for-presentation';

const styles = require('./cfd-platform-brand-assets-activity-log-table.component.scss');

const GLOBAL_GROWL_ID = 'restService';
const TABLE_KEY = 'tradingCoreBrandAssetsAuditLog';

export class CfdPlatformBrandAssetsActivityLogTableController extends TableController {
  static $inject = [
    'prfTradingCoreAuditLogsApi',
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'prfPlatformAssetsService',
    'prfPlatformGroupsService',
    ...TableController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentPlatformOfBrand: CurrentPlatformOfBrandStoreServiceDirectiveController;

  prfCurrentTrcGroupsOfPlatform: CurrentTrcGroupsOfPlatformServiceDirectiveController;

  prfCurrentTrcAssetsOfPlatform: CurrentTrcAssetsOfPlatformServiceDirectiveController;

  /* bindings */

  /* injections */

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfTradingCoreAuditLogsApi: TradingCoreAuditLogsApiService;
  prfPlatformAssetsService: PlatfromAssetsService;
  prfPlatformGroupsService: PlatfromGroupsService;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  settings = { ...tableSettings };

  cols;

  cols$;

  groups$ = new rx.BehaviorSubject<any[]>([]);
  dataServiceInstance$: rx.Observable<CfdMongoRestAdapter>;
  assets$ = new rx.BehaviorSubject<any[]>([]);
  platformConnectionForFilters: PlatformConnection;

  brandPlatform: BrandPlatform;

  constructor(...args) {
    super(...args);
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance$ = this.streamDataServiceInstance();
    this.cols$ = this.streamTableColumns();

    useStreams(
      [
        this.dataServiceInstance$,
        this.streamAssetsBSubjet(),
        this.streamGroupsBSubject(),
        this.steamStateCurerntPlatform(),
        this.streamInitTable(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamDataServiceInstance(): rx.Observable<CfdMongoRestAdapter> {
    return rx.pipe(
      () =>
        this.prfCurrentPlatformSession.sessionS.stream$.pipe(
          rx.filter((session) => session.isLoggedIn),
        ),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.map((sessionInfo) => {
        const req = this.prfTradingCoreAuditLogsApi.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
    )(null);
  }

  streamTableColumns() {
    return rx.pipe(
      () => this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
      rx.map((brandPlatform) => {
        return switchOnEx(
          {
            [PlatformCode.Cfd]: () => cfdTableColumns,
            [PlatformCode.Bundle]: () => cfdTableColumns,
            [PlatformCode.Mt4]: () => mtTableColumns,
            [PlatformCode.Mt5]: () => mtTableColumns,
          },
          brandPlatform.platform.code,
        );
      }),
      rx.tap((cols) => (this.cols = cols)),
      shareReplayRefOne(),
    )(null);
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.dataServiceInstance$,
          this.assets$,
          this.groups$,
          this.cols$,
        ]),
      rx.tap(() => this.initTable()),
    )(null);
  }

  steamStateCurerntPlatform() {
    return rx.pipe(
      () => this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
      rx.tap((brandPlatform) => {
        this.brandPlatform = brandPlatform;
      }),
    )(null);
  }

  streamGroupsBSubject() {
    return rx.pipe(
      () => this.prfCurrentTrcGroupsOfPlatform.generalGroupsS.stream$,
      rx.tap((groups) => this.groups$.next(groups as any)),
    )(null);
  }

  streamAssetsBSubjet() {
    return rx.pipe(
      () => this.prfCurrentTrcAssetsOfPlatform.generalAssetsS.stream$,
      rx.tap((assets) => this.assets$.next(assets as any)),
    )(null);
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  get tableKey() {
    return TABLE_KEY;
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @example
   * get requiredApiFilters () {
   *   return {
   *       "role.code": {
   *           exclude: "extapi"
   *       }
   *   };
   * }
   * @returns {{}}
   */
  get requiredApiFilters(): any {
    const tableName = calcTableNameForPlatform(
      this.brandPlatform.platform.code,
    );

    return {
      tableName,
      type: ['Create', 'Update', 'Delete', 'Insert'],
    };
  }

  calcCfdAssetName(id: number, items: EntityWithCode[]) {
    const item = items.find((a) => a.id === id);
    if (_.isNil(item)) {
      return id;
    }

    return item.name;
  }

  calcCfdGroupName(id: number, items: EntityWithCode[]) {
    const item = items.find((a) => a.id === id);
    if (_.isNil(item)) {
      return id;
    }
    return item.name;
  }

  /**
   * parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[]): any[] {
    data.forEach((item) => {
      item._requestBodyForPresentation = formatGeneralAssetAuditLogBodyToPresentation(
        item,
      );

      item._assetName = switchOnEx(
        {
          [PlatformCode.Cfd]: () =>
            this.calcCfdAssetName(item.assetId, this.assets$.getValue()),
          [PlatformCode.Bundle]: () =>
            this.calcCfdAssetName(item.assetId, this.assets$.getValue()),
          [PlatformCode.Mt4]: () =>
            this.calcCfdAssetName(item.assetId, this.assets$.getValue()),
          [PlatformCode.Mt5]: () =>
            this.calcCfdAssetName(item.assetId, this.assets$.getValue()),
        },
        this.brandPlatform.platform.code,
      );

      item._groupName = switchOnEx(
        {
          [PlatformCode.Cfd]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
          [PlatformCode.Bundle]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
          [PlatformCode.Mt4]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
          [PlatformCode.Mt5]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
        },
        this.brandPlatform.platform.code,
      );
    });

    return data;
  }
}

function calcTableNameForPlatform(code: PlatformCode) {
  if ([PlatformCode.Cfd, PlatformCode.Bundle].includes(code)) {
    return 'GroupAssetSetting';
  }

  if (MT4_PLATFORMS.includes(code)) {
    return 'MTGroupAsset';
  }

  throw new Error('not implemented');
}

export const CfdPlatformBrandAssetsActivityLogTableComponent = {
  template,
  controllerAs: 'vm',
  controller: CfdPlatformBrandAssetsActivityLogTableController,
  require: {
    prfCurrentPlatformSession: '^',
    prfCurrentPlatformOfBrand: '^',
    prfCurrentTrcGroupsOfPlatform: '^',
    prfCurrentTrcAssetsOfPlatform: '^',
  },
};
