import * as _ from '@proftit/lodash';

import BaseService from '~/source/common/services/baseService';

export class SimpleCacheService extends BaseService {
  cache = {};

  /**
   * Put item in the dictionary cache.
   * @param key
   * @param payload
   */
  put(key: string, payload: any) {
    this.cache[key] = payload;
  }

  /**
   * Remove item from the dictionary cache.
   * @param key
   */
  remove(key: string) {
    this.cache[key] = null;
    delete this.cache[key];
  }

  /**
   * Get an item from the dictionary cache
   * @param key
   * @return item of the cache
   */
  get(key: string) {
    return this.cache[key];
  }
}

SimpleCacheService.$inject = ['featuresFlagsData'];
