import * as rx from '@proftit/rxjs';
import useStream from '~/source/common/utilities/use-stream';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import TableSettings from '~/source/common/models/table-settings';
import CustomersService from '~/source/contact/common/services/customers';
import FeesSocket from '~/source/contact/common/services/fees-socket.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';
import Fee from '~/source/common/models/fee';
import DataAdditional from '../common/models/data-additional';
import ColumnRenderers from '../common/models/column-renderers.enum';
import MenuItem from '../common/models/menu-item';
import feesSettings from '../fees-settings.json';
import template from '../fees-table/fees-table.html';
import feeTemplate from '../add-fee-popup/add-fee-popup.html';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import {
  FEE_STATUS_UPDATE,
  ADD_FEE,
} from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';

class FeeTableController extends TableLiveController {
  static $inject = [
    '$scope',
    'customersService',
    'popupService',
    'feesSocketService',
    'highlightEntityService',
    'tokensService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  config: TableSettings;
  customersService: () => CustomersService;
  feesSocketService: FeesSocket;
  dataServiceInstance: CustomersService;
  fees: IElementRestNg<any>;
  customer: IElementRestNg<Customer>;
  account: IElementRestNg<TradingAccount>;
  popupService: PopupService;
  dataAdditionals: { [id: number]: DataAdditional } = {};
  unsub$ = new rx.Subject<null>();
  prfClientGeneralPubsub: ClientGeneralPubsub;

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      excludeNotifications: [],
      settings: { ...feesSettings, ...(this.config || {}) },
      dataServiceInstance: this.customersService(),
      cols: [...feesSettings.tableColumns],
    });

    this.initTable();

    useStream(this.setStreamFeesPubsub(), this.unsub$);
  }

  setStreamFeesPubsub() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === FEE_STATUS_UPDATE || key === ADD_FEE),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean} The indication of is notification in use for this table.
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service} The socket service used.
   */
  get socketService() {
    return this.feesSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string} Name of the state variable.
   */
  get liveEntitiesVarName() {
    return 'vm.fees';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection} The variable containing the entities.
   */
  get entitiesContainer() {
    return this.fees;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams} table params for this table.
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getFeesResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: 'feesTable' })
      .expand(['updatingUser'])
      .embed(['rollover'])
      .sort({ valueDate: 'desc' });
  }

  /**
   * add currency object into query results. because this data is required for 'amount' field value formatting
   * defined in table-popup.json.
   *
   * @param {Array} data query results
   * @return {Array} returns formatted query results
   */
  parseLoadedData(data) {
    data.forEach((item) => {
      item.currency = this.account.currency;
    });

    this.fees = data;

    // reset stale data infos.
    this.dataAdditionals = this.generateDataAdditionals();

    return data;
  }

  /**
   * Generate data infos accompaning the data. Ui state...
   *
   * @return {object[]} the generated data infos
   */
  generateDataAdditionals() {
    return this.fees.reduce((acc, fee) => {
      const info = new DataAdditional();
      this.calcMenuItemsForRow(fee).then((menuItems) => {
        info.menuItems = menuItems;
      });

      return {
        ...acc,
        [fee.id]: info,
      };
    }, {});
  }

  /**
   * When data is reloaded, clear and referesh the accompaning data additional
   * info array. Ex. For each deposit the system saves a ui state of the action
   * warning line open status.
   *
   * on user Paging Event
   *
   * can be override by a different logic
   *
   * @returns {void}
   */
  onUserPaging(): void {
    this.dataAdditionals = this.generateDataAdditionals();
  }

  /**
   * open add fee modal
   *
   * @return {void}
   */
  openAddFeePopup() {
    this.popupService.open({
      component: 'addFeePopupComponent',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * open fees log popup
   *
   * @return {void}
   */
  openFeeTablePopup() {
    this.popupService.open({
      component: 'prfFeeTablePopupComponent',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  /**
   * Calculate what type of column to show. Mainly use to let the view know
   * if to render regular column or action column instead of relying on last column.
   *
   * @param {object} col - column info.
   * @returns {ColumnRenderers} value representing the renderer to use.
   */
  colRendererToShow(col) {
    if (col.isActionsColumn === true) {
      return ColumnRenderers.Actions;
    }

    return ColumnRenderers.Regular;
  }

  /**
   * Calculate menu items for specific fee.
   *
   * Stateless function.
   *
   * @param {Fee} fee - fee
   * @return {Promise} menu items
   */
  calcMenuItemsForRow(fee: Fee): Promise<MenuItem[]> {
    let menuItems = [];

    if (fee.isDeletable) {
      menuItems = [
        ...menuItems,
        {
          labelCode: 'common.DELETE',
          actionCode: 'delete',
        },
      ];
    }

    return Promise.resolve(menuItems);
  }

  /**
   * Event handler - of fee menu item click
   *
   * @param {string} action code - Action that was clicked in the menu.
   * @param {object} row - specific withdrawal information
   * @returns {void}
   */
  onDropDownAction(actionCode, row) {
    switch (actionCode) {
      case 'delete': {
        this.showDeleteActionWarning(row.id);
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Show the delete action warning line for the specific fee.
   *
   * @param {number} id - fee id
   * @returns {void}
   */
  showDeleteActionWarning(id) {
    this.dataAdditionals[id].showDeleteActionWarning = true;
  }

  /**
   * Perform delete business action on the fee.
   *
   * @param {number} id = fee id
   * @return {Promise} promsie represnt the server action of cancelation.
   */
  deleteFee(id) {
    this.dataAdditionals[id].showDeleteActionWarning = false;

    return this.customersService()
      .deleteFee(this.customer.id, this.account.id, id)
      .then(() => {
        this.dataAdditionals = this.generateDataAdditionals();
      });
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }
}

export default {
  template,
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
  },
  controller: FeeTableController,
  controllerAs: 'vm',
};
