import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { Communication } from '~/source/common/models/communication';
import { CustomerCommunicationStatusCode } from '@proftit/crm.api.models.enums';

export class UserEmailsService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'userEmails';
  }

  getUserGmailCommunications() {
    return this.getListWithQuery();
  }

  getGmailUnarchivedEmails(page: number, pageSize: number) {
    return this.filter({
      isArchived: false,
      'emailProvider.code': 'gmail',
      'communicationStatus.code': CustomerCommunicationStatusCode.EmailReceived,
    })
      .slice(page * pageSize, (page + 1) * pageSize, pageSize)
      .sort('date', 'desc')
      .expand(['customer', 'emailProvider', 'communicationStatus'])
      .getListWithQuery<IElementRestNg<Communication>>();
  }
}
