import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class PendingPositionsForexNotificationsController extends NotificationsController {}

PendingPositionsForexNotificationsController.$inject = [
  // socket sevice needs to be first in the injection. (see base notification controller)
  'pendingPositionsForexSocketService',
  ...NotificationsController.$inject,
];

const PendingPositionsForexNotificationsComponent = {
  ...ComponentOptions,
  controller: PendingPositionsForexNotificationsController,
};

export default PendingPositionsForexNotificationsComponent;
