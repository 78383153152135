const styles = require('./assign-users-list-item.component.scss');

import BaseController from '~/source/common/controllers/base';
import template from './assign-users-list-item.html';
import { IScope } from 'angular';
import { User } from '@proftit/crm.api.models.entities';
import { AssignSplitterController } from '../assign-splitter/assign-splitter.component';

class Controller extends BaseController {
  onLockChange: (a: { user: User }) => void;
  styles = styles;

  user: User & { isLocked: boolean; allocatedCustomers: number };
  splitter: AssignSplitterController;

  /*@ngInject*/
  constructor(readonly $scope: IScope) {
    super();
  }

  $onInit() {
    /*
     * listen to event that broadcast from parent
     * component and when occurs - unlock the user
     */
    this.$scope.$on(this.splitter.unlockUserEventName, () => this.unlock());
  }

  /**
   * Release user for allocation
   */
  lock() {
    this.user.isLocked = true;
  }

  /**
   * Lock the amount of allocated customers to user
   */
  unlock() {
    this.user.isLocked = false;
  }

  onLockChanged() {
    this.onLockChange({ user: this.user });
    this.splitter.split();
  }

  /**
   * Called when user manually allocates customers
   */
  manualAllocation() {
    // calculate max users to allocate
    const max =
      this.splitter.customersToSplit - this.splitter.lockedCustomersNumber;
    const min = 0;

    /*
     * customersToSplit must be greater than lockedCustomersNumber
     * set min value and stop procedure in order to prevent negative values
     */
    if (this.splitter.customersToSplit < this.splitter.lockedCustomersNumber) {
      this.user.allocatedCustomers = min;
      return;
    }

    /*
     * validate the min and max customers that can be allocated
     * min validation
     */
    if (min > this.user.allocatedCustomers) {
      this.user.allocatedCustomers = min;
    }
    // max validation
    if (max < this.user.allocatedCustomers) {
      this.user.allocatedCustomers = max;
    }
    /*
     * if this is the only user selected for allocation
     * then all customers should be allocated to him
     */
    if (this.splitter.totalUsersNumber === 1) {
      this.user.allocatedCustomers = this.splitter.customersToSplit;
    }

    /*
     * temporary lock the component - this will preserve
     * the number of customers that has been manually allocated
     */
    this.lock();
    /*
     * notify parent component that customers should
     * be splited again
     */
    this.splitter.onManualAllocation();
    // unlock the user after modification finish
    this.unlock();
  }

  $onDestroy() {}
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    user: '<',
    // number of allocated customers to user
    allocatedCustomers: '<',
    onAllocationUpdate: '&',
    onUnlock: '&',
    onLockChange: '&',
  },
  require: {
    splitter: '^^prfContactsAssignSplitter',
  },
};
