import * as rx from '@proftit/rxjs';
import {
  combineInProcesses,
  generateInProcessSubject,
  tapInProcessOn,
  catchErrorInProcessOff,
  tapFinalizeInProcessOff,
  useStreams,
  shareReplayRefOne,
  tapLog,
} from '@proftit/rxjs.adjunct';

interface InProcessInfo {
  inProcess: boolean;
  error: Error;
}

export function generateInProcessStream<T, R>(
  streamStart$: rx.Observable<T>,
  streamMiddleOperator: (
    inProcess$: rx.Subject<InProcessInfo>,
  ) => rx.OperatorFunction<T, R>,
  inProcessDeps: rx.Observable<InProcessInfo>[] = [],
) {
  const inProcessSelf$ = generateInProcessSubject();
  const inProcess$ = combineInProcesses([inProcessSelf$, ...inProcessDeps]);

  const streamFn = () =>
    rx.pipe(
      () => streamStart$,
      tapInProcessOn(inProcessSelf$),
      streamMiddleOperator(inProcessSelf$),
      tapFinalizeInProcessOff(inProcessSelf$),
    )(null);

  return {
    inProcess$,
    stream$: streamFn(),
  };
}
