import BaseController from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import { IScope } from 'angular';
import {
  Customer,
  CustomerStatus,
  UserTokenModel,
} from '@proftit/crm.api.models.entities';
import { ExcludeBy } from '~/source/common/components/dropdowns/base/component';
import TokensService from '~/source/auth/services/tokens';
import { filterCustomerStatusesFactory } from '~/source/common/utilities/filter-customer-statuses-factory';

class CustomerStatusUpdatePopupController extends BaseController {
  customersServiceInst: CustomersService;
  localCustomer: Customer;
  $close: Function;
  user: UserTokenModel;
  ExcludeBy = ExcludeBy;

  set customer(value: Customer) {
    this.localCustomer = { ...value };
  }

  filterCustomerStatuses: (
    customerStatuses: CustomerStatus[],
  ) => CustomerStatus[];

  /*@ngInject*/
  constructor(
    readonly customersService: () => CustomersService,
    readonly $scope: IScope & { customer: Customer },
    readonly tokensService: TokensService,
  ) {
    super();
    this.customersServiceInst = this.customersService();

    this.filterCustomerStatuses = filterCustomerStatusesFactory(
      this.tokensService.getCachedUser() as UserTokenModel,
      this.localCustomer.customerStatus,
      true,
    );
  }

  /**
   * update customer status and close modal on success
   */
  ok() {
    this.customersServiceInst
      .setConfig({
        blockUiRef: 'customerStatusUpdatePopup',
      })
      .updateCustomer(this.localCustomer.id, {
        customerStatusId: this.localCustomer.customerStatus.id,
      })
      .then(() => {
        this.$close();
      });
  }
}

export default CustomerStatusUpdatePopupController;
