import * as _ from '@proftit/lodash';
import { FieldProxyHandler } from '../field-proxy-handler';

export const IS_NUMBER = 'IS_NUMBER';

export function isNumberProxyValidator(handler: FieldProxyHandler<any>) {
  if (_.isNil(handler.value)) {
    return {
      code: IS_NUMBER,
      isValid: true,
    };
  }

  const result = _.isNumber(handler.value);

  if (result) {
    return {
      code: IS_NUMBER,
      isValid: true,
    };
  }

  return {
    code: IS_NUMBER,
    isValid: false,
    payload: {
      value: handler.value,
    },
  };
}
