import template from './dragndrop-from-to-box.component.html';
const styles = require('./dragndrop-from-to-box.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

interface DragNDropDropInfo {
  index: number;
  item: any;
  external: any; // todoOld
  type: ItemType;
}

enum ItemType {
  SourceItemType = 'source_item_type',
  TargetItemType = 'target_item_type',
}

export class DragndropFromToBoxController {
  styles = styles;
  ItemType = ItemType;
  lifecycles = observeComponentLifecycles(this);
  opOnPanelDrop$ = new rx.Subject<DragNDropDropInfo>();
  opOutsideDrop$ = new rx.Subject<DragNDropDropInfo>();

  onTargetItemInsert: (a: { item: any }) => void;
  onTargetItemRemove: (a: { item: any }) => void;
  onTargetItemMove: (a: { item: any }) => void;

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.streamNotifyTargetInsert(),
        this.streamNotifyTargetMove(),
        this.streamNotifyTargetRemove(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamNotifyTargetInsert() {
    return rx.pipe(
      () => this.opOnPanelDrop$,
      rx.filter(({ type }) => type === ItemType.SourceItemType),
      rx.tap(({ index, item, type }) => this.onTargetItemInsert({ item })),
    )(null);
  }

  streamNotifyTargetMove() {
    return rx.pipe(
      () => this.opOnPanelDrop$,
      rx.filter(({ type }) => type === ItemType.TargetItemType),
      rx.tap(({ index, item, type }) => this.onTargetItemMove({ item })),
    )(null);
  }

  streamNotifyTargetRemove() {
    return rx.pipe(
      () => this.opOutsideDrop$,
      rx.filter(({ type }) => type === ItemType.TargetItemType),
      rx.tap(({ index, item, type }) => this.onTargetItemRemove({ item })),
    )(null);
  }
}

export const DragndropFromToBoxComponent = {
  template,
  controller: DragndropFromToBoxController,
  bindings: {
    sourceItems: '<',
    targetItems: '<',
    sourceTopText: '<',
    targetTopText: '<',
    targetTopTextTooltip: '<',
    isReadonly: '<',
    onTargetItemInsert: '&',
    onTargetItemRemove: '&',
    onTargetItemMove: '&',
  },
};
