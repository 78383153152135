import { validators, defaultMsg } from '../common/validators/validators';

function validationConf($validationProvider) {
  // Disable validation ok message
  $validationProvider.showSuccessMessage = false;

  $validationProvider.setExpression(validators).setDefaultMsg(defaultMsg);

  $validationProvider.setErrorHTML(
    (msg) =>
      `<span class="control-label has-error prf-id-error-msg" translate="${msg}">Invalid value</span>`,
  );
}

validationConf.$inject = ['$validationProvider'];

export default validationConf;
