import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import template from './campaign-trend-graph.html';
import { IScope } from 'angular';

class Controller extends BaseController {
  // bindings
  data;
  chartOptions;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $translate: angular.translate.ITranslateService,
  ) {
    super();
  }

  $onInit() {
    // translate graph titles
    this.translateGraphTitles();
  }

  /**
   * translate graph titles
   */
  translateGraphTitles() {
    const graphs = this.chartOptions.graphs;
    const graphTitles = _(graphs).pluck('title');

    this.$translate(graphTitles).then((translations) => {
      _.each(graphs, (graph: any) => {
        graph.title = translations[graph.title];
      });
    });
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    chartOptions: '<',
  },
};
