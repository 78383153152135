function angularResizable($provide) {
  $provide.decorator('resizableDirective', ($delegate, localStorageService) => {
    const directive = $delegate[0];
    // store the original link fn
    const originalLinkFn = directive.link;
    const STORAGE_KEY_PREFIX = 'resizeInfo_';
    // replace the compile fn
    directive.compile = function () {
      return function newLinkFn(scope, element) {
        // fire the originalLinkFn
        originalLinkFn.apply($delegate[0], arguments);
        // update element with stored dimensions
        this.setStoredDimensions(scope.rFlex, element);

        scope.$on('angular-resizable.resizeEnd', (...args) =>
          this.onResizeEnd(...args),
        );
      };
    };
    /**
     * Set stored dimensions to the element
     *
     * @param {Boolean} isFlex
     * @param {jQuery} element
     */
    directive.setStoredDimensions = function (isFlex, element) {
      // get resize info if was stored
      const storedInfo = this.fetch(element[0].id);
      const cssProp = isFlex ? 'flexBasis' : 'width';
      // if stored - update element
      if (storedInfo) {
        element[0].style[cssProp] = `${storedInfo.width}px`;
        element[0].style.height = `${storedInfo.height}px`;
      }
    };

    /**
     * Called on resize end event
     *
     * @param {Object} event
     * @param {Object} info - {height: xx (or false}, width: xx (or false), id: 'string'}
     */
    directive.onResizeEnd = function (event, info) {
      // store the dimensions of the element into local storage
      this.store(info.id, info);
    };
    /**
     * Stores element dimensions in local storage
     *
     * @param {string} id - id of the directive
     * @param {object} data - data to store
     */
    directive.store = function (id, data) {
      localStorageService.set(this.getStorageKey(id), data);
    };
    /**
     * Get stored element dimensions from local storage
     *
     * @param {string} id
     * @returns {Object/undefined} - {height: xx (or false}, width: xx (or false), id: 'string'} or undefined
     * when empty
     */
    directive.fetch = function (id) {
      return localStorageService.get(this.getStorageKey(id));
    };
    /**
     * Returns name of the key in the local storage that is
     * related to given id
     *
     * @param {Object} info
     * @return {string}
     */
    directive.getStorageKey = function (id) {
      return STORAGE_KEY_PREFIX + id;
    };

    return $delegate;
  });
}

angularResizable.$inject = ['$provide'];

export default angularResizable;
