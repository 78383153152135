// This file was generated automatically. Tread lightly.

export enum TradingAccountTransactionStatusCode {
  Approved = 'approved',
  Canceled = 'canceled',
  Closed = 'closed',
  Declined = 'declined',
  Deleted = 'deleted',
  Partial = 'partial',
  Pending = 'pending',
  Rejected = 'rejected',
  Requested = 'requested',
  Withdrawable = 'withdrawable',
}
