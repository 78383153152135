import * as rx from '@proftit/rxjs';

export enum ProgressReportType {
  InProgress,
  Completed,
}

interface Progress {
  percentage: number;
  file: any;
  reportType: ProgressReportType;
}

export function observeUploadFile(fileUploadFn): rx.Observable<Progress> {
  return new rx.Observable<Progress>((s) => {
    return fileUploadFn().then(
      (fileUploadResp) => {
        s.next({
          percentage: 100,
          file: fileUploadResp.data,
          reportType: ProgressReportType.Completed,
        });
        s.complete();
      },
      (err) => {
        s.error(err);
      },
      (evt) => {
        const percentage = Math.floor((100.0 * evt.loaded) / evt.total);
        s.next({
          percentage,
          file: null,
          reportType: ProgressReportType.InProgress,
        });
      },
    );
  });
}
