import template from './withdrawal-request-default-details.html';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import CustomersService from '~/source/contact/common/services/customers';
import { IScope } from 'angular';
import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import BaseController from '~/source/common/controllers/base';

const styles = require('./../withdrawal-request.scss');

class WithdrawalRequestDefaultDetailsController extends BaseController {
  withdrawalRequest: any;
  customerServiceInst: CustomersService;
  styles = styles;
  eWallet = 'E-WALLET';

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();

    this.customerServiceInst = this.customersService();
  }

  updateReason(newReason) {
    // send request to the server
    this.customerServiceInst
      .getWithdrawalRequestResource(
        this.withdrawalRequest.customerId,
        this.withdrawalRequest.tradingAccountId,
        this.withdrawalRequest.id,
      )
      .patchWithQuery<IElementRestNg<WithdrawalRequest>>({
        reason: newReason,
      })
      .then(
        (updatedWithdrawalRequest) => {
          this.withdrawalRequest.reason = updatedWithdrawalRequest.reason;
          this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, '');
        },
        (error) => {},
      );
  }
}

export default {
  template,
  controller: WithdrawalRequestDefaultDetailsController,
  bindings: {
    withdrawalRequest: '<',
  },
};
