import template from './risk-free-table.html';
import riskFreePopupTemplate from './risk-free-popup.html';
import riskFreeSettings from './risk-free-settings.json';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import TableSettings from '~/source/common/models/table-settings';
import CustomersService from '~/source/contact/common/services/customers';
import RiskFreesSocket from '~/source/contact/common/services/risk-frees-socket.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';

import * as _ from '@proftit/lodash';

class Controller extends TableLiveController {
  static $inject = [
    ...TableLiveController.$inject,
    'customersService',
    'popupService',
    'riskFreesSocketService',
    'tokensService',
  ];

  config: TableSettings;
  customersService: () => CustomersService;
  riskFreesSocketService: RiskFreesSocket;
  riskFrees: IElementRestNg<any>;
  dataServiceInstance: CustomersService;
  customer: IElementRestNg<Customer>;
  account: IElementRestNg<TradingAccount>;
  popupService: PopupService;

  $onInit() {
    super.$onInit();
    if (_.isEmpty(this.config)) {
      this.config = {};
    }

    Object.assign(this, {
      settings: Object.assign({}, riskFreeSettings),
      dataServiceInstance: this.customersService(),
      excludeNotifications: [],
      cols: [...riskFreeSettings.tableColumns],
    });

    this.initTable();
    this.$scope.$on(
      'riskFreePositionsTableReload',
      this.reloadTable.bind(this),
    );
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService() {
    return this.riskFreesSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.riskFrees';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.riskFrees;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  fetchFn() {
    return this.dataServiceInstance
      .getRiskFreePositionsResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: 'riskFreesTable' })
      .expand(['user'])
      .sort({ date: 'desc' });
  }

  parseLoadedData(data) {
    this.riskFrees = data;
    return data;
  }

  /**
   * Opens the "add riskFree" modal
   * @returns {void}
   */
  openAddRiskFreePopup() {
    this.popupService.open({
      template: riskFreePopupTemplate,
      scope: this.$scope,
      data: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * Open the "risk free log" popup
   *
   * @return {void}
   */
  openRiskFreeTablePopup() {
    this.popupService.open({
      controller: 'riskFreeTablePopupController',
      template: tablePopupTemplate,
      scope: this.$scope,
      data: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
  },
  controllerAs: 'vm',
};
