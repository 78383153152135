import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';

import UserManageController from './user-manage-abstract';

import template from './user-create.html';
import { IPromise } from 'angular';

class Controller extends UserManageController {
  /**
   * Object that represents new user
   *
   * @returns {Promise}
   */
  initUserModel() {
    return this.prfPublicSystemLogin2faStoreService.login2fa$
      .pipe(
        rx.map(({ isEnabled }) => {
          return {
            brands: [],
            isActive: false,
            is2faLoginEnabled: isEnabled,
          };
        }),
        rx.take(1),
      )
      .toPromise() as any;
  }

  /**
   * Posts user to server
   *
   * @returns {$q}
   * @private
   */
  queryUser() {
    /**
     * If this is a super user, omit the brands property, as the super user has global access.
     * otherwise: format brand-desk connection according to the api standard
     */
    const formattedBrandDeskUser =
      this.user.role.code === 'super'
        ? _.omit('brands', this.user)
        : this.brandDeskFormFormatterInst.format(this.user);
    // normalize user
    const normalizedUser = this.modelNormalizer.normalize(
      formattedBrandDeskUser,
    );

    const userPayloadToPost = this.removeEmptyVoipConfigurations(
      normalizedUser,
    );

    // add new user
    return this.usersServiceInst
      .setConfig({ growlRef: 'userForm' })
      .postWithQuery(userPayloadToPost);
  }

  removeEmptyVoipConfigurations(user) {
    if (_.isNil(user.brands)) {
      return user;
    }
    const modifiedBrands = user.brands.map((userBrand) => {
      let filteredVoipConfigurations = [];
      if (!_.isNil(userBrand.voipConfigurations)) {
        filteredVoipConfigurations = userBrand.voipConfigurations.filter(
          (config) => !_.isEmpty(config.extension),
        );
      }
      return {
        ...userBrand,
        voipConfigurations: filteredVoipConfigurations,
      };
    });
    return {
      ...user,
      brands: modifiedBrands,
    };
  }
}

const UserCreateComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default UserCreateComponent;
