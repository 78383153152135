import RestService from '~/source/common/services/rest';
import { TimeInterval } from '@proftit/constants.time';

/**
 * This service is only used for desks which are not related to any brand!
 * for desks related to a brand (most cases) - use the brand service.
 */
class DesksService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'desks';
  }

  get name() {
    return 'desks';
  }

  useCache() {
    return true;
  }

  get cacheOptions() {
    return {
      maxAge: TimeInterval.Hour,
    };
  }

  getDeskResource(id) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }
}

export default DesksService;
