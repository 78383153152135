import {
  CustomerStatus,
  UserTokenModel,
} from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';

export const filterCustomerStatusesFactory = (
  user: UserTokenModel,
  currentCustomerStatus: CustomerStatus | null,
  isPassCurrentStatus: boolean,
) => {
  return (customerStatuses: CustomerStatus[]) => {
    return customerStatuses.filter((customerStatus) => {
      if (_.isNil(customerStatus.departmentId)) {
        return true;
      }

      if (user.role.code === UserRolePositionCode.SuperAdmin) {
        return true;
      }

      if (
        customerStatus.code === currentCustomerStatus?.code &&
        isPassCurrentStatus
      ) {
        return true;
      }

      if (
        customerStatus.departmentId ===
        (user.departmentId || user.department?.id)
      ) {
        return true;
      }

      return false;
    });
  };
};
