import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';
import { QUESTIONNAIRE_SCORE_UPDATED } from '../constants/general-pubsub-keys';

export class QuestionnaireScoresService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'questionnaireScores';
  }

  getScoreResource(id: number) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }
}
