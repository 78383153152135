import template from './attachment-questionnaire-customer-scores-card.component.html';
const styles = require('./attachment-questionnaire-customer-scores-card.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

interface CustomerScore {
  id: number;
  customerId: number;
  questionnaireScoreId: number;
  segmentTypeId: number;
  segmentName: string;
  score: string;
}

export class AttachmentQuestionnaireCustomerScoresCardController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  questionnaireCustomerScores$ = observeShareCompChange<any[]>(
    this.lifecycles.onChanges$,
    'questionnaireCustomerScores',
  );

  isEmpty$ = rx.pipe(
    () => this.questionnaireCustomerScores$,
    rx.map((items) => (items ? items.length === 0 : true)),
    shareReplayRefOne(),
  )(null);

  /*@ngInject */
  constructor() {
    useStreams([this.questionnaireCustomerScores$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AttachmentQuestionnaireCustomerScoresCardComponent = {
  template,
  controller: AttachmentQuestionnaireCustomerScoresCardController,
  bindings: {
    questionnaireCustomerScores: '<',
  },
};
