import * as _ from '@proftit/lodash';
import { IAppConfig } from '~/source/conf/appConfig';

export function calcTranslationApiUrl(
  appConfig: IAppConfig,
  langKey: string,
): string {
  const { simplelocalizeBaseDomain, simplelocalizeClientName } = appConfig;

  if (_.isNil(simplelocalizeClientName)) {
    return `${simplelocalizeBaseDomain}${langKey}`;
  }

  return `${simplelocalizeBaseDomain}${langKey}_${simplelocalizeClientName}`;
}
