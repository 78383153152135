import template from './brand-currencies-demo-deposit-amount-form.component.html';
const styles = require('./brand-currencies-demo-deposit-amount-form.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class BrandCurrenciesDemoDepositAmountFormController {
  onChangesCurrencyConnections: (a: { changeset: any }) => void;
  onChangeOfValidity: (a: { isValid: boolean }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  opSetDemoDepositAmount$ = new rx.Subject<{ val: number; id: number }>();
  currencyConnectionsInput$ = observeShareCompChange<any[]>(
    this.lifecycles.onChanges$,
    'currencyConnections',
  );
  currencyConnections$ = this.streamCurrencyConnections();
  editMode$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'editMode',
  );
  isEnabled$ = this.streamIsEnabled();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.currencyConnectionsInput$,
        this.currencyConnections$,
        this.editMode$,
        this.streamNotifyOnChangeOfValidity(),
        this.streamNotifyOnChangesCurrencyConnections(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamIsEnabled() {
    return rx.pipe(
      () => this.editMode$,
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrencyConnectionsFromInput() {
    return rx.pipe(
      () => this.currencyConnectionsInput$,
      rx.map((conns) => {
        if (_.isNil(conns)) {
          return [];
        }

        return conns.map((conn) =>
          createConnMediator(conn, this.opSetDemoDepositAmount$),
        );
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrencyConnectionsFromEnter(
    currencyConnections$: rx.Observable<any[]>,
  ) {
    return rx.pipe(
      () => this.opSetDemoDepositAmount$,
      rx.withLatestFrom(currencyConnections$),
      rx.map(([op, conns]) => {
        const connInd = conns.findIndex((conn) => conn.id === op.id);
        const conn = conns[connInd];
        const newConn = createConnMediator(conn, this.opSetDemoDepositAmount$);
        newConn.demoDepositAmount = op.val;

        const newConns = [...conns];
        newConns.splice(connInd, 1, newConn);
        return newConns;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrencyConnections() {
    const currencyConnections$ = new rx.BehaviorSubject<any[]>([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamCurrencyConnectionsFromInput(),
          this.streamCurrencyConnectionsFromEnter(currencyConnections$),
        ),
      rx.tap((conns) => currencyConnections$.next(conns)),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamNotifyOnChangeOfValidity() {
    return rx.pipe(
      () => this.currencyConnections$,
      rx.map(() => true),
      rx.tap((isValid) => this.onChangeOfValidity({ isValid })),
    )(null);
  }

  streamNotifyOnChangesCurrencyConnections() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.currencyConnectionsInput$.pipe(rx.map((a) => (a ? a : []))),
          this.currencyConnections$.pipe(rx.map((a) => (a ? a : []))),
        ),
      rx.map(([inputConns, conns]) => {
        if (_.isEmpty(inputConns)) {
          return {
            action: null,
            changes: [],
            data: inputConns,
          };
        }

        const updateItems = conns
          .map((changeConn, index) => ({ index, item: changeConn }))
          .filter(({ item: changeConn, index }) => {
            const inputConn = inputConns.find((c) => c.id === changeConn.id);
            return changeConn.demoDepositAmount !== inputConn.demoDepositAmount;
          });

        if (updateItems.length === 0) {
          return {
            action: null,
            changes: [],
            data: inputConns,
          };
        }

        return {
          action: 'update',
          changes: updateItems,
          data: inputConns,
        };
      }),
      rx.tap((changeset) => this.onChangesCurrencyConnections({ changeset })),
    )(null);
  }
}

function createConnMediator(conn, opSetDemoDepositAmount$) {
  const newConn = Object.defineProperties(
    {
      ...conn,
    },
    {
      _demoDepositAmount: {
        get() {
          return this.demoDepositAmount;
        },
        set: (val) => {
          opSetDemoDepositAmount$.next({ val, id: conn.id });
        },
      },
    },
  );

  return newConn;
}

export const BrandCurrenciesDemoDepositAmountFormComponent = {
  template,
  controller: BrandCurrenciesDemoDepositAmountFormController,
  bindings: {
    currencyConnections: '<',
    editMode: '<',
    onChangeOfValidity: '&',
    onChangesCurrencyConnections: '&',
  },
};
