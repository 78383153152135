import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
// import * as _ from '@proftit/lodash';
import template from './inline-cell-edit.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

const styles = require('./inline-cell-edit.component.scss');

interface Context {
  isEdit: boolean;
  doSave: boolean;
  closeEdit: () => void;
}

export class InlineCellEditController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  editAction = new rx.Subject<void>();
  cancelEditAction = new rx.Subject<void>();
  saveAction = new rx.Subject<void>();

  closeEditAction = new rx.Subject<void>();

  isEdit$ = this.streamIsEdit();

  contextForField$ = this.streamContextForField();

  /* @ngInject */
  constructor() {
    useStreams([this.contextForField$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamIsEdit(): rx.Observable<boolean> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.editAction.pipe(rx.map(() => true)),
          this.cancelEditAction.pipe(rx.map(() => false)),
          this.closeEditAction.pipe(rx.map(() => false)),
        ),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamContextForFieldFromIsEdit() {
    return rx.pipe(
      () => this.isEdit$,
      rx.map((isEdit) => ({
        isEdit,
        doSave: false,
        closeEdit: () => this.closeEditAction.next(),
      })),
      shareReplayRefOne(),
    )(null);
  }

  streamContextForFieldFromSave() {
    return rx.pipe(
      () => this.saveAction,
      rx.switchMap(() =>
        rx.obs.merge(
          rx.obs.of({
            doSave: true,
            isEdit: true,
            closeEdit: () => this.closeEditAction.next(),
          }),
          rx.obs
            .of({
              doSave: false,
              isEdit: true,
              closeEdit: () => this.closeEditAction.next(),
            })
            .pipe(rx.delay(10)),
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamContextForField() {
    const initialContext = {
      isEdit: false,
      doSave: false,
      closeEdit: () => this.closeEditAction.next(),
    };
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamContextForFieldFromIsEdit(),
          this.streamContextForFieldFromSave(),
        ),
      rx.startWith(initialContext),
      shareReplayRefOne(),
    )(null);
  }
}

export const InlineCellEditComponent = {
  template,
  controller: InlineCellEditController,
  bindings: {
    allowEdit: '<',
  },
  transclude: true,
};
