import TableController from '~/source/common/components/table/table.controller';
import DesksService from '~/source/management/brand/services/desks';
import template from './desks-list.component.html';
import RestService from '~/source/common/services/rest';

class DesksListComponent extends TableController {
  static $inject = [
    ...TableController.$inject,
    'desksService',
    'deskModuleSettings',
  ];

  desksService: () => DesksService;
  deskModuleSettings: any;
  settings: any;
  cols: any[];
  deskSearch: string;

  /**
   * Component life cycle method - onInit
   *
   * @return {void}
   */
  $onInit(): void {
    super.$onInit();
    this.dataServiceInstance = this.desksService();
    this.settings = { ...this.deskModuleSettings };
    this.cols = [...this.deskModuleSettings.cols];

    this.initTable();
  }

  parseLoadedData(data) {
    data.forEach((item) => {
      item._countryNames = item.countries
        .map((country) => country.name)
        .join(', ');
      const maxLength = 150;
      if (item._countryNames.length > maxLength) {
        item._countryNames = `${item._countryNames.substring(0, maxLength)}...`;
      }
    });
    return data;
  }

  /**
   * Fetch list from server
   *
   * @return {RestService}
   */
  fetchFn(): RestService {
    /*
     * This component is a crud list items screen. The desire is to show the
     * latest current data.
     *
     * Since the data service is using cache, we need to bust it so the fetch
     * will bring fresh data.
     */
    this.dataServiceInstance.cacheEmpty();

    return this.dataServiceInstance
      .filter('isSystem', false)
      .setConfig({
        blockUiRef: this.blockUiKey,
        errorsTranslationPath: 'desk.errors',
      })
      .embed(['countries']);
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  get tableKey() {
    return 'desks';
  }

  get isInfiniteTable() {
    return true;
  }

  /**
   * deletes list item & reload table
   * @param {Object} item - Restangular model
   * @returns {Promise}
   */
  remove(item) {
    return item.remove().then(() => this.reloadTable());
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    return this.deskSearch ? { q: this.deskSearch } : {};
  }

  onDeskSearchChange() {
    this.tableParams.page(1);

    /*
     * set desk search value to table filter reference object.
     * this change will trigger api call
     */
    this.tableParams.filter().q = this.deskSearch;
  }
}

// todoOld: custom ng1 typescript decorator for component
export default {
  template,
  controller: DesksListComponent,
  controllerAs: 'vm',
};
