import SocketService from '~/source/common/services/socket';

export class RiskFreesSocketService extends SocketService {
  get channelRoot() {
    return 'RiskFree';
  }

  get whiteList() {
    return [];
  }
}

export default RiskFreesSocketService;
