import template from './field-settings-item.component.html';

const styles = require('./field-settings-item.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { NotificationCommunicationTypeCode } from '@proftit/crm.api.models.enums';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { NotificationCommunicationType } from '@proftit/crm.api.models.entities';
import { NotificationsFieldSettings } from './../notifications-field-settings';
import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';

export class FieldSettingsItemController {
  onValueChange: (a: { value: any }) => void;
  onIsActiveChange: (a: { isActive: boolean }) => void;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  NotificationCommunicationTypeCode = NotificationCommunicationTypeCode;
  fieldSettings$ = observeShareCompChange<NotificationsFieldSettings>(
    this.lifecycles.onChanges$,
    'fieldSettings',
  );
  notificationCommunicationTypes$ = observeShareCompChange<
    NotificationCommunicationType[]
  >(this.lifecycles.onChanges$, 'notificationCommunicationTypes');
  roleNotificationsByTypes$ = this.streamRoleNotificationsByTypes();
  form$ = this.streamForm();

  /*@ngInject */
  constructor() {
    useStreams(
      [this.fieldSettings$, this.notificationCommunicationTypes$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.form$.pipe(rx.map((form) => form.value$)),
        this.streamNotifyValueChange(),
        this.streamNotifyIsActiveChange(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamNotifyValueChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.controls.value.value$),
      rx.tap((value) => {
        this.onValueChange({ value });
      }),
    )(null);
  }

  streamNotifyIsActiveChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.controls.isActive.value$),
      rx.tap((isActive) => {
        this.onIsActiveChange({ isActive });
      }),
    )(null);
  }

  streamForm() {
    return rx.pipe(
      () => this.fieldSettings$,
      rx.filter((fieldSettings) => !!fieldSettings),
      rx.map((fieldSettings) => {
        return new FormGroup({
          isActive: new FormControl<boolean>(fieldSettings.isActive),
          value: new FormControl<string | number>(fieldSettings.value),
        } as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRoleNotificationsByTypes() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.fieldSettings$,
          this.notificationCommunicationTypes$,
        ),
      rx.map(([fieldSettings, notifTypes]) => {
        if (!notifTypes || !fieldSettings) {
          return {};
        }
        const aggregatedRoles = notifTypes.map((communicationType) => {
          const filteredRoles = fieldSettings.roleNotifications.filter(
            (notification) =>
              notification.notificationCommunicationTypeId ===
              communicationType.id,
          );
          return [communicationType.code, filteredRoles];
        });
        return _.fromPairs(aggregatedRoles);
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const FieldSettingsItemComponent = {
  template,
  controller: FieldSettingsItemController,
  bindings: {
    fieldSettings: '<',
    fieldSettingsConfig: '<',
    roles: '<',
    notificationCommunicationTypes: '<',
    onRolesChange: '&',
    onEmailsChange: '&',
    onIsActiveChange: '&',
    onValueChange: '&',
    isEdit: '<',
    key: '<',
  },
};
