const styles = require('./cfd-platform-brands-table.component.scss');
import ng, { IHttpService } from 'angular';
import * as rx from '@proftit/rxjs';
import BaseController from '~/source/common/controllers/base';

import template from './cfd-platform-brands-table.component.html';
import { TableController } from '~/source/common/components/table/table.controller';
import { useStream } from '~/source/common/utilities/use-stream';
import { ModalService } from '~/source/common/components/modal/modal.service';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';

import {
  init as restInit,
  getAsList,
  JsonServerRequest,
} from '@proftit/request-client';
import { BrandsService } from '~/source/management/brand/services/brands';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { settings } from './cfd-platform-brands-table-settings';
import { cols } from './cfd-platform-brands-table-cols';
import { CfdPlatformBrandsRestAdapter } from './cfd-platform-brands-rest-adapater';
import { CfdPlatformBrandConfig } from '~/source/common/models/cfd-platform/cfd-platform-brand-config';
import { CfdPlatformBrandConfigsService } from '../../services/cfd-platform-brand-configs.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PLATFORM_BRAND_CONFIG_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { PlatformBrand } from '~/source/common/models/cfd-platform/platform-brand';
import * as _ from '@proftit/lodash';
import { PlatformsWithSwapAbility } from '@proftit/crm.api.models.enums';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import {
  observeShareCompChange,
  observeComponentLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const GLOBAL_GROWL_ID = 'restService';

function isPlatformSupportSwap(row) {
  const platform = _.get(['brand', '_crmPlatformConnection', 'platform'], row);

  if (_.isNil(platform)) {
    return false;
  }

  return PlatformsWithSwapAbility.includes(platform.code);
}

export class CfdPlatformBrandsTableController extends TableController {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'modalService',
    'prfCfdPlatformBrandConfigsService',
    'brandsService',
    'prfClientGeneralPubsub',
    ...TableController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* bindings */

  platformConnection: PlatformConnection;

  lifecycles = observeComponentLifecycles(this);
  styles = styles;

  /* injections */
  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  modalService: ModalService;
  prfCfdPlatformBrandConfigsService: CfdPlatformBrandConfigsService;
  brandsService: () => BrandsService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  platformConnection$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'platformConnection',
  );
  unsub$ = new rx.Subject<void>();
  opInit$ = new rx.Subject<void>();
  opOpenEditPopup$ = new rx.Subject<CfdPlatformBrandConfig>();
  brandsConfigs: any[] = []; // todoOld
  settings = _.cloneDeep(settings);
  cols = cols;

  isPlatformSupportSwap = _.memoize(isPlatformSupportSwap);

  $onInit() {
    super.$onInit();
    useStream(this.streamInitDataServiceInstanceAndTable(), this.unsub$);
    useStream(this.streamOpenEditPopup(), this.unsub$);
    useStream(this.streamRefreshTableOnOutsideNotifications(), this.unsub$);
    this.opInit$.next();
  }

  $onChanges() {}

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamInitDataServiceInstanceAndTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.opInit$,
          this.prfCurrentPlatformSession.sessionS.stream$,
        ),
      rx.filter(([_a, sessionInfo]) => sessionInfo.isLoggedIn),
      rx.map(([_a, sessionInfo]) => {
        return new CfdPlatformBrandsRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          restInit() as JsonServerRequest,
          'name',
          this.brandsService,
          this.prfCfdPlatformBrandConfigsService,
          sessionInfo,
        );
      }),
      rx.tap((instance: CfdPlatformBrandsRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamOpenEditPopup() {
    return rx.pipe(
      () => this.opOpenEditPopup$,
      rx.tap((brandConfig) => this.openEditPopup(brandConfig.brand)),
    )(null);
  }

  streamRefreshTableOnOutsideNotifications() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key, payload }) => key === PLATFORM_BRAND_CONFIG_UPDATED),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  get tableKey() {
    return 'cfdPlatformBrands';
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiKey })
      .setPlatformConnectionFilter(this.platformConnection);
  }

  parseLoadedData(data) {
    this.brandsConfigs = data;
    return data;
  }

  openEditPopup(platformBrand: PlatformBrand) {
    const updatePopup = this.modalService.open({
      component: 'prfCfdPlatformBrandConfigEditDialog',
      resolve: {
        operation: () => 'update',
        platformBrand: () => platformBrand,
      },
    });
  }
}

export const CfdPlatformBrandsTableComponent = {
  template,
  controller: CfdPlatformBrandsTableController,
  controllerAs: 'vm',
  bindings: {
    platformConnection: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};

export default CfdPlatformBrandsTableComponent;
