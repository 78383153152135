import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './dropdown-arrow.component.html';
const styles = require('./dropdown-arrow.component.scss');

export enum ArrowDirection {
  UP = 'up',
  DOWN = 'down',
}

export class DropdownArrowController {
  styles = styles;

  ArrowDirection = ArrowDirection;
  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const DropdownArrowComponent = {
  template,
  controller: DropdownArrowController,
  bindings: {
    direction: '<',
  },
};
