import template from './form-control-validation-message.component.html';
const styles = require('./form-control-validation-message.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { AbstractControl } from '@proftit/ng1.reactive-forms';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';

enum MessageDisplayer {
  TranslateGeneral = 'translate_general',
}

class Controller {
  MessageDisplayer = MessageDisplayer;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  control$ = observeShareCompChange<AbstractControl>(
    this.lifecycles.onChanges$,
    'control',
  );
  validationResults$ = this.streamValidationResults();
  messageDisplayer$ = this.streamMessageDisplayer();
  isShowMessage$ = this.streamIsShowMessage();

  /*@ngInject */
  constructor() {
    useStreams([this.control$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamValidationResults() {
    return rx.pipe(
      () => this.control$,
      rx.switchMap((ctrl) => {
        if (_.isNil(ctrl)) {
          return rx.obs.NEVER;
        }

        return ctrl.validationsResults$;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsShowMessage() {
    return rx.pipe(
      () => this.validationResults$,
      rx.map((results) => !_.isNil(results) && !_.isEmpty(results)),
      shareReplayRefOne(),
    )(null);
  }

  streamMessageDisplayer() {
    return rx.pipe(
      () => rx.obs.from([MessageDisplayer.TranslateGeneral]),
      shareReplayRefOne(),
    )(null);
  }
}

export const FormControlValidationMessageComponent = {
  template,
  controller: Controller,
  bindings: {
    control: '<',
    mainTheme: '<',
  },
};
