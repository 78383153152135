import template from './user-menu-button.html';
const styles = require('./user-menu-button.scss');

import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import TokensService from '~/source/auth/services/tokens';
import UserTokenModel from '~/source/common/models/user-token-model';
import UsersService from '~/source/management/user/services/users';
import { Brand, User } from '@proftit/crm.api.models.entities';
import useStream from '~/source/common/utilities/use-stream';
import { PROFILE_PICTURE_UPLOADED } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { DefaultFilesService } from '~/source/common/services/default-files';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import BrandsService from '~/source/management/brand/services/brands';
import File from '~/source/common/models/file';
import * as rx from '@proftit/rxjs';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';

export class UserMenuButtonController extends BaseController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  images: IElementRestNg<File>[];
  opGetUserPic$: rx.Subject<any> = new rx.Subject<void>();
  getBrandsAction: rx.Subject<any> = new rx.Subject<void>();
  cachedUser: UserTokenModel;
  user: User;
  isPopupShown = {
    userProfileSummaryBar: true,
    profileIconsSelectBar: false,
    userMenu: false,
  };
  isMenuOpen = false;
  isAutoAssignmentEnabledFormControl = new FormControl<boolean>(false);
  streamIsAutoAssignmentEnabledChange$ = this.streamIsAutoAssignmentEnabledChange();
  user$ = this.streamUserPic();
  brands$ = this.streamBrands();
  shouldShowAutoAssignment$ = this.streamShouldShowAutoAssignment();

  /*@ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly usersService: () => UsersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly defaultFilesService: DefaultFilesService,
    readonly brandsService: () => BrandsService,
  ) {
    super();
    this.cachedUser = this.tokensService.getCachedUser();
    useStreams(
      [this.user$, this.shouldShowAutoAssignment$, this.brands$],
      this.lifecycles.onDestroy$,
    );

    this.opGetUserPic$.next(null);
    this.getBrandsAction.next(null);
    this.getUsersPics().then((images) => {
      this.images = images;
    });
  }

  streamBrands() {
    return rx.pipe(
      () => this.getBrandsAction,
      rx.switchMap(() => {
        return this.brandsService()
          .expand(['file:logo'])
          .embed(['desks'])
          .getListWithQuery()
          .catch((e) => null);
      }),
      rx.filter((x) => !_.isNil(x)),
      shareReplayRefOne(),
    )(null);
  }

  streamShouldShowAutoAssignment() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.user$, this.brands$),
      rx.map(([user, brands]) =>
        this.calcShouldShowAutoAssignment(brands, user),
      ),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamIsAutoAssignmentEnabledChange() {
    return rx.pipe(
      () =>
        this.isAutoAssignmentEnabledFormControl.value$.pipe(
          rx.distinctUntilChanged(),
        ),
      rx.withLatestFrom(
        this.isAutoAssignmentEnabledFormControl.isAtFirstValue$,
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.filter(() => !!this.user),
      rx.switchMap(([isAutoAssignmentEnabled]) => {
        const { id } = this.user;
        return this.usersService()
          .setConfig({})
          .getUserResource(id)
          .patchWithQuery({ id, isAutoAssignmentEnabled })
          .catch((e) => {});
      }),
      shareReplayRefOne(),
    )(null);
  }

  isAutoAssignmentAllowedForUser(user: User): boolean {
    if (_.isNil(user) || _.isNil(user.role)) {
      return false;
    }
    const allowedRolesForAutoAssignment = [UserRolePositionCode.Regular];
    return allowedRolesForAutoAssignment.includes(
      user.role.code as UserRolePositionCode,
    );
  }

  calcShouldShowAutoAssignment(brands: Brand[], user: User) {
    let result = false;
    if (!this.isAutoAssignmentAllowedForUser(user)) {
      return result;
    }
    brands.forEach((brand) => {
      if (!brand || !brand.isAutoAssignmentEnabled || !brand.desks) {
        return;
      }
      brand.desks.forEach((desk) => {
        if (!desk || !desk.isAutoAssignmentEnabled) {
          return;
        }
        if (desk.isAutoAssignmentEnabled) {
          result = true;
        }
      });
    });
    return result;
  }

  $onInit() {}

  /**
   * Stream user picture when 1) oninit of component, 2) uploading a pic from managment, 3) changing an avatar
   * @param opGetUserPic$ -
   */
  streamUserPic() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.prfClientGeneralPubsub
            .getObservable()
            .pipe(rx.filter(({ key }) => key === PROFILE_PICTURE_UPLOADED)),
          this.opGetUserPic$,
          this.streamIsAutoAssignmentEnabledChange$,
        ),
      rx.switchMap(() => rx.obs.from(this.getUserPic())),
      rx.tap((user) => {
        this.user = user;
        this.isAutoAssignmentEnabledFormControl.setValueAsFirst(
          user.isAutoAssignmentEnabled,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * gets user pic
   */
  getUserPic() {
    return this.usersService()
      .expand(['department', 'userGroup', 'role', 'profileImage'])
      .getOneWithQuery<IElementRestNg<User>>(this.cachedUser.id)
      .then((data) => data.plain());
  }

  /**
   * shows profileIconsSelectBar component
   */
  showUserPicEditor() {
    this.isPopupShown.userProfileSummaryBar = false;
    this.isPopupShown.profileIconsSelectBar = true;
  }

  /**
   * shows userProfileSummaryBar component
   */
  showUserProfileSummary() {
    this.isPopupShown.userProfileSummaryBar = true;
    this.isPopupShown.profileIconsSelectBar = false;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  /**
   * updates user picture
   * @param newVal - new picture file
   */
  patchPic(pictureUrl) {
    this.usersService()
      .patchElement(this.user.id, { profileImageId: pictureUrl.file.id })
      .then(() => {
        this.opGetUserPic$.next(null);
        this.showUserProfileSummary();
      });
  }

  getUsersPics() {
    return this.defaultFilesService.getDefaultFilesExpaned();
  }

  $onDestroy() {}
}

export const userMenuButtonComponent = {
  template,
  controller: UserMenuButtonController,
};
