import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { ContactsImportStatusesService } from '~/source/common/api-crm-server/services/contacts-import-statuses.service';
import { ContactsImportStatusesCode } from '@proftit/crm.api.models.enums';

export class ContactsImportStatusesStoreService {
  contactsImportStatuses$ = this.streamContactsImportStatuses();
  statusActive$ = this.streamStatusActive();

  /* @ngInject */
  constructor(
    readonly prfContactsImportStatusesService: () => ContactsImportStatusesService,
  ) {}

  streamContactsImportStatuses() {
    return rx.pipe(
      () => rx.obs.from(this.prfContactsImportStatusesService().getAll()),
      shareReplayRefOne(),
    )(null);
  }

  streamStatusActive() {
    return rx.pipe(
      () => this.contactsImportStatuses$,
      rx.map((statuses) =>
        statuses.find((s) => s.code === ContactsImportStatusesCode.Active),
      ),
      shareReplayRefOne(),
    )(null);
  }
}
