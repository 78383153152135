import ng from 'angular';

const styles = require('./automation-dashboard.component.scss');

import BaseController from '~/source/common/controllers/base';

import template from './automation-dashboard.component.html';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';
import { menuEntries } from './menu-entries';

export class AutomationDashboardController extends BaseController {
  styles = styles;

  activeTab: string;
  tabs = menuEntries;

  /*@ngInject */
  constructor(readonly featuresFlags: FeaturesFlagsService) {
    super();
  }
}

export const AutomationDashboardComponent = {
  template,
  controller: AutomationDashboardController,
};
