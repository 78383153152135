import { IPromise } from 'angular';

import Component from '../base/component';
import ItemLabelStrategy from '../base/item-label-strategy';
import { ClearingCompanyType } from '@proftit/crm.api.models.enums';

class ClearingCompaniesSelectController extends Component.controller {
  static $inject = [
    'clearingCompaniesService',
    ...Component.controller.$inject,
  ];

  isSystem: boolean;

  get defaultPlaceholder() {
    return 'common.SELECT_CLEARING_COMPANIES';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  query(): Promise<any> {
    if (this.isSystem !== undefined) {
      this.dataServiceInst.filter({
        isSystem: this.isSystem,
      });
    }

    return super.query();
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.type === ClearingCompanyType.MobileMoney
      ? `${item.name} (${this.$translate.instant('contact.MOBILE_MONEY')})`
      : item.name;
  }
}

const ClearingCompanySelectComponent = Component.config({
  controller: ClearingCompaniesSelectController,
  bindings: {
    isSystem: '=?',
  },
});

export default ClearingCompanySelectComponent;
