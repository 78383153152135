import template from './volume.component.html';
const styles = require('./volume.component.scss');

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';

export class VolumeController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  currentVolume$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'currentVolume',
  );
  volumeScalesAmount$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'volumeScalesAmount',
  );
  activeScalaIndex$ = this.streamActiveScalaIndex();
  volumeScales$ = this.streamVolumeScales();

  // Bindings
  maxVolume: number;
  volumeScalesAmount: number;
  currentVolume: number;

  /*@ngInject */
  constructor() {
    useStreams(
      [this.currentVolume$, this.volumeScalesAmount$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamActiveScalaIndex() {
    return rx.pipe(
      () => this.currentVolume$,
      rx.map((currentVolume) =>
        Math.floor((currentVolume / this.maxVolume) * this.volumeScalesAmount),
      ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamVolumeScales() {
    return rx.pipe(
      () => this.volumeScalesAmount$,
      rx.map((volumeScalesAmount) => Array(volumeScalesAmount).fill('')),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

export const VolumeComponent = {
  template,
  bindings: {
    maxVolume: '<',
    volumeScalesAmount: '<',
    currentVolume: '<',
    onVolumeScalaClick: '&',
    onVolumePlusClick: '&',
    onVolumeMinusClick: '&',
  },
  controller: VolumeController,
};
