import Component from './base/component';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

const NOT_ALLOWED_PLATFORMS = [PlatformTypeCode.Binary];

class PlatformTypesController extends Component.controller {
  static $inject = ['platformTypesService', ...Component.controller.$inject];

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'common.SELECT_INSTRUMENT';
  }

  query() {
    return this.dataServiceInst
      .getListWithQuery()
      .then((data) => data.plain())
      .then((data) =>
        data.filter((a) => !NOT_ALLOWED_PLATFORMS.includes(a.code)),
      );
  }
}

export default Component.config({ controller: PlatformTypesController });
