import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './login-credentials-form.component.html';
const styles = require('./login-credentials-form.component.scss');

export class LoginCredentialsFormController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const LoginCredentialsFormComponent = {
  template,
  controller: LoginCredentialsFormController,
  bindings: {
    credentials: '<',
    login: '&',
  },
};
