import Promise from 'bluebird';
import log from 'loglevel';

let addedListener = false;
function bluebirdRunConfig($rootScope) {
  Promise.config({
    // disable warnings, even on development
    warnings: false,
  });

  /**
   * We need to tell Bluebird to use angular's scheduler.
   * Otherwise, scope won't be $apply"d automatically.
   */
  Promise.setScheduler((fn) => {
    $rootScope.$evalAsync(fn);
  });

  if (!addedListener) {
    // "Run blocks" run multiple times during test, but we only want to bind the method once
    /**
     * Catch all unhandled promise rejections
     * @see {@link http://bluebirdjs.com/docs/api/promise.onunhandledrejectionhandled.html}
     */
    window.addEventListener('unhandledrejection', (e: any) => {
      e.preventDefault();
      log.error('Unhandled rejection', e.detail.reason);
    });

    addedListener = true;
  }
}

bluebirdRunConfig.$inject = ['$rootScope'];

export default bluebirdRunConfig;
