import template from './simple-circle-text.component.html';
const styles = require('./simple-circle-text.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class SimpleCircleTextController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SimpleCircleTextComponent = {
  template,
  controller: SimpleCircleTextController,
  transclude: true,
};
