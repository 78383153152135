import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-data.component.html';
import {
  MarketingCampaignReward,
  MarketingCampaignRewardsSource,
} from '~/source/common/data-sources/marketing-campaign-rewards-source';
import { Currency } from '@proftit/crm.api.models.entities';
const styles = require('./automatic-bonus-data.component.scss');

export class AutomaticBonusDataController {
  isEdit: boolean;
  selectedBonus: MarketingCampaignRewardsSource;
  formGroupsPerCurrency;
  currencyIdToCurrencyMap;
  rewardType: boolean;
  MarketingCampaignReward = MarketingCampaignReward;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AutomaticBonusDataComponent = {
  template,
  controller: AutomaticBonusDataController,
  bindings: {
    isEdit: '<',
    selectedBonus: '<',
    formGroupsPerCurrency: '<',
    currencyIdToCurrencyMap: '<',
    ftdBonusIsOneTimeFormControl: '<',
    rewardType: '<',
  },
};
