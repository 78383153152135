import template from './send-custom-email-form.component.html';
import * as _ from '@proftit/lodash';

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import {
  isNotEmptyStringFormValidator,
  isEmailFormValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { Permissions } from '~/source/common/models/permission-structure';
import TokensService from '~/source/auth/services/tokens';
import { UsersService } from '~/source/management/user/services/users';
import { Customer, User } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

const styles = require('./send-custom-email-form.component.scss');

// https://github.com/tinymce/tinymce/issues/2836
const contentStyle = require('tinymce/skins/ui/oxide/content.min.css');

export class SendCustomEmailFormController {
  customer: Customer;
  onChangeOfModel: (a: { change: any }) => void;
  onChangeOfValidity: (a: { isValid: boolean }) => {};

  Permissions = Permissions;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'model');
  form$ = this.streamForm();

  currentUser$ = this.streamCurrentUser();
  isUserConnectedToGmail$ = this.streamIsUserConnectedToGmail();
  gmailSignature$ = this.streamGmailSignature();
  usersServiceInst = this.usersService();

  addGmailSignatureAction = new rx.Subject<void>();

  editorOptions = {
    content_css: './assets/tinymce/content.css',
    skin: false,
    //  theme: false,
    plugins: [
      'code',
      'autoresize',
      'lists',
      'advlist',
      'link',
      'image',
      'media',
      'table',
      'paste',
    ],
    content_style: contentStyle.toString(),
    // menu: {
    // edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
    // view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
    // insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
    // format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
    // tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
    // table: { title: 'Table', items: 'inserttable tableprops deletetable row column cell' },
    // help: { title: 'Help', items: 'help' }
    // },
    menubar: '',
    toolbar:
      'bold italic underline | styleselect | cut copy paste | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | link image media | code | undo redo',
    contextmenu: 'paste',
  };

  /*@ngInject */
  constructor(
    readonly $validation,
    readonly tokensService: TokensService,
    readonly usersService: () => UsersService,
  ) {
    useStreams(
      [
        this.streamFormChangesOut(),
        this.streamFormValidOut(),
        this.form$.pipe(rx.switchMap((form) => form.value$)),
        this.currentUser$,
        this.isUserConnectedToGmail$,
        this.gmailSignature$,
        this.streamAddGmailSignature(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamGmailSignature() {
    return rx.pipe(
      () => this.isUserConnectedToGmail$,
      rx.filter((isGmailConnected) => isGmailConnected),
      rx.switchMap(() => {
        const currentUser = this.tokensService.getCachedUser();
        if (_.isNil(currentUser) || _.isNil(this.customer.brand.id)) {
          return Promise.resolve('');
        }

        return this.usersServiceInst
          .getUserGmailSignature(Number(currentUser.id), this.customer.brand.id)
          .then((res) => {
            return res.plain().emailSignature;
          })
          .catch((e) => {});
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCurrentUser(): rx.Observable<User> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        const currentUser = this.tokensService.getCachedUser();
        if (_.isNil(currentUser)) {
          return Promise.resolve({});
        }
        const userId = Number(currentUser.id);
        return this.usersServiceInst
          .getUserResource(userId)
          .embed(['brands.emails', 'brands.emailCredentials'])
          .getOneWithQuery<IElementRestNg<User>>()
          .then((res) => {
            return res.plain();
          })
          .catch((e) => {
            return {};
          });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsUserConnectedToGmail() {
    return rx.pipe(
      () => this.currentUser$,
      rx.filter((user) => !_.isNil(user)),
      rx.filter((user) => !_.isNil(user.brands)),
      rx.map((user) => user.brands),
      rx.map((brands) =>
        brands.find((brand) => brand.id === this.customer.brand.id),
      ),
      rx.filter((brand) => !_.isNil(brand)),
      rx.map((brand) => {
        if (
          _.isNil(brand.emailCredentials) ||
          brand.emailCredentials.length < 1
        ) {
          return false;
        }
        if (!brand.emailCredentials[0].isEmailProviderActive) {
          return false;
        }

        const { emails } = brand;
        if (_.isNil(emails)) {
          return false;
        }

        return emails.some((email) => email.isConnected);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamForm() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => {
        return new FormGroup({
          from: new FormControl(model.from, {
            validators: [isEmailFormValidator],
          }),
          subject: new FormControl(model.subject, {
            validators: [isNotEmptyStringFormValidator],
          }),
          body: new FormControl(model.body ? model.body : '', {
            validators: [isNotEmptyStringFormValidator],
          }),
        } as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormChangesOut() {
    return rx.pipe(
      () => this.form$.pipe(rx.switchMap((form) => form.value$)),
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.form$.pipe(rx.switchMap((form) => form.isValid$)),
      rx.tap((isValid: boolean) => this.onChangeOfValidity({ isValid })),
    )(null);
  }

  streamAddGmailSignature() {
    return rx.pipe(
      () => this.addGmailSignatureAction,
      rx.withLatestFrom(this.gmailSignature$, this.form$),
      rx.filter(([a, gmailSignature, form]) => !_.isNil(gmailSignature)),
      rx.tap(([a, gmailSignature, form]) => {
        const body = form.controls.body as FormControl<string>;
        this.appendToEmailBody(body, gmailSignature);
      }),
    )(null);
  }

  appendToEmailBody(body: FormControl<string>, html: string) {
    if (_.isNil(html)) {
      return;
    }
    const currentValue = body.value;
    const newValue = `${currentValue}<div class="prf-gmail-signature">${html}</div>`;
    body.setValue(newValue);
  }
}

export const SendCustomEmailFormComponent = {
  template,
  controller: SendCustomEmailFormController,
  bindings: {
    model: '<',
    customer: '<',
    onChangeOfModel: '&',
    onChangeOfValidity: '&',
  },
};
