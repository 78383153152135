import BaseStatusesController from './base-statuses.controller';
import template from './statuses-management.html';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';

class EwalletsController extends BaseStatusesController {
  static $inject = ['ewalletsService', ...BaseStatusesController.$inject];

  ewalletsService: EwalletsService;
  dataService: EwalletsService;

  shouldAlwaysHaveOneDefault = true;

  $onInit() {
    this.dataService = this.ewalletsService;

    super.$onInit();
  }

  get sectionName() {
    return 'ewallets';
  }
}

export default {
  template,
  controller: EwalletsController,
};
