import Component from '~/source/common/components/dropdowns/base/component';
import * as _ from '@proftit/lodash';

class UserGroupController extends Component.controller {
  static $inject = ['userGroupsService', ...Component.controller.$inject];

  hideSystemGroups: boolean;

  get defaultPlaceholder() {
    return 'management.SELECT_GROUP';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  query(): Promise<any> {
    const filters = _.flow([
      (filters) =>
        this.hideSystemGroups ? { ...filters, isSystem: false } : filters,
    ])({});

    return this.dataServiceInst.filter(filters).getListWithQuery<any>();
  }
}

const UserGroupSelectComponent = Component.config({
  controller: UserGroupController,
  bindings: {
    hideSystemGroups: '<',
  },
});

export default UserGroupSelectComponent;
