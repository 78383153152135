import RestService from '~/source/common/services/rest';

export default class EwalletTransactionStatuses extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'ewalletStatuses';
  }

  useCache() {
    return true;
  }
}
