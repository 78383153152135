import log from 'loglevel';
import { SocketService } from '~/source/common/services/socket';

export class Mt4OrderPnlSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  streamerUrl = '';

  /**
   * Specify socket registry key.
   *
   * there are registry for crm socket, platform socket..
   * Sockets should be shared for all services targeting the same target.
   */
  get socketRegistryKey() {
    return 'mt4Socket';
  }

  getStreamerUrl() {
    return this.streamerUrl;
  }

  get channelRoot() {
    return 'orderpnl';
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }

  /**
   * Set streamer url
   *
   * @param url
   */
  setStreamerUrl(url: string) {
    this.streamerUrl = url;
  }
}

/**
 * Build channel name for account openpnl subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildOrderPnlChannel(accountId: number) {
  return `orderpnl.${accountId}`;
}
