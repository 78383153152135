import s from 'underscore.string';
import template from './search-popup.html';
import BaseController from '~/source/common/controllers/base';
import { SearchService } from './search.service';

class SearchController extends BaseController {
  showPopup = false;
  searchServiceInst: SearchService;
  queryString: string;
  searchResult;

  /*@ngInject */
  constructor(
    readonly searchService: () => SearchService,
    readonly searchSettings,
  ) {
    super();

    this.searchServiceInst = this.searchService();
  }

  /**
   * when user make change on input call search function and
   * Check if string isBlank then stop,
   * else
   * using debounce model in milliseconds with ngModelOptions directive to call fetchDataSearch Function.
   */
  search() {
    if (s.isBlank(this.queryString)) {
      this.searchResult = [];
      this.showPopup = false;
      return;
    }

    this.showPopup = true;
    this.fetchDataSearch();
  }

  /**
   * Update all necessary fields whenever 'hide-element' happens
   * (i.e: whenever we click on some item from the searchDropDown list).
   */
  onHide() {
    this.showPopup = false;
    this.queryString = '';
  }

  /**
   * Fetch all the data required for user search.
   *
   * Calls on change of query on search input.
   */
  fetchDataSearch() {
    const sliceSettings = this.searchSettings.slice;
    this.searchServiceInst
      .setConfig({ blockUiRef: 'searchResult' })
      .search(this.queryString)
      .slice(sliceSettings.start, sliceSettings.end, sliceSettings.limit)
      .getListWithQuery()
      .then((data) => {
        this.searchResult = data;
      })
      .catch((err) => {});
  }
}

export default {
  template,
  controller: SearchController,
  controllerAs: 'vm',
};
