import * as _ from '@proftit/lodash';
import { communicationsReportsSettings } from './communications-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'communicationsReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class CommunicationsReportTableController extends CommonReportsTableController {
  resource: string = 'communications';
  exportResource: string = 'communications';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>communicationsReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'conmunicationsReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'communications_report_table';
  }
}

export const CommunicationsReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: CommunicationsReportTableController,
};
