import template from './ewallet-providers-fields-renderer.component.html';
const styles = require('./ewallet-providers-fields-renderer.component.scss');
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { isNotEmptyStringFormValidator } from '@proftit/ng1.reactive-forms.validators';
import { Credential } from '~/source/management/integrations/ewallet-crypto-providers/ewallets-providers.model';

export class FieldsRendererController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  credentialsFieldFormControl = new FormControl<string>(null);

  validators = [isNotEmptyStringFormValidator];
  credentialsFields: Credential[];

  credentialsFieldsFormControls: FormControl<any>[] = [];
  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const EwalletProvidersFieldsRendererComponent = {
  template,
  controller: FieldsRendererController,
  bindings: {
    onChange: '&',
    credentialsFields: '<',
    isEdit: '<',
  },
};
