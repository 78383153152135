import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';

export class RiskManagerContext {
  setPlatformConnOp$ = new rx.Subject<BrandPlatform>();

  selectedPlatformConn = this.streamSelectedPlatformConn();

  constructor() {}

  streamSelectedPlatformConn() {
    const inProcess$ = new rx.BehaviorSubject<boolean>(false);

    const stream$ = rx.pipe(
      () => this.setPlatformConnOp$,
      rx.startWith(null),
      shareReplayRefOne(),
    )(null) as rx.Observable<BrandPlatform>;

    return {
      stream$,
      inProcess$: inProcess$.asObservable(),
    };
  }

  setSelectedPlatformConn(platformConn: BrandPlatform) {
    this.setPlatformConnOp$.next(platformConn);
  }
}

export function riskManagerContextProviderDirective() {
  return {
    restrict: 'EA',
    require: {},
    link(scope, element, attrs) {},
    controller() {
      this.item = new RiskManagerContext();
    },
  };
}

riskManagerContextProviderDirective.$inject = [];
