/**
 * defines constants for application
 */

import ng from 'angular';
import dateFormat from './date-format';
import tzOffsets from './tz-offsets';
import platformTypesMap from './platform-types-map';
import growlReference from './growl-reference';

export default ng
  .module('crm.constants', [])
  .constant('tzOffsets', tzOffsets)
  .constant('dateFormat', dateFormat)
  .constant('platformTypesMap', platformTypesMap)
  .constant('growlReference', growlReference).name;
