import log from 'loglevel';
import { IHttpService } from 'angular';
import Url from 'url-parse';
import * as _ from '@proftit/lodash';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  getAsList,
  setTokenHeader,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { removeColonAtEnd } from '~/source/common/utilities/remove-colon-at-end';

const CFD_RESOURCE_PATH = 'brands';

export class CfdPlatformBrandsService {
  /*@ngInject */
  constructor(readonly $http: IHttpService) {}

  initReq(reqParam, urlStr: string, resourcePath: string) {
    const url = new Url(urlStr);
    return _.flow([
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(resourcePath, req),
    ])(reqParam);
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl, CFD_RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
    ])();
  }

  getCfdPlatformBrand(apiUrl: string, token: string) {
    try {
      return _.flow([
        () => this.initPrivateReq(apiUrl, token),
        (req) => getAsList(req),
        (req) => cfdMongoToNgHttp(req),
        (httpModel) => ngHttpPerformRequest(this.$http, httpModel),
      ])();
    } catch (err) {
      log.error('error getCfdPlatformBrand', err);
      return Promise.reject(err);
    }
  }
}
