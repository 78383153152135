import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './brand-platform-mt4-group-security-item-form.component.html';
const styles = require('./brand-platform-mt4-group-security-item-form.component.scss');

export class BrandPlatformMt4GroupSecurityItemFormController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandPlatformMt4GroupSecurityItemFormComponent = {
  template,
  controller: BrandPlatformMt4GroupSecurityItemFormController,
  bindings: {
    form: '<',
  },
};
