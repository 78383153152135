import * as rx from '@proftit/rxjs';
import { SystemService } from '../services/system.service';
import { SystemLogin2fa } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { ClientGeneralPubsub } from '../services/client-general-pubsub';

export class SystemLogin2faStoreService {
  loadOp$ = new rx.Subject<rx.Observable<SystemLogin2fa>>();

  saveOp$ = new rx.Subject<rx.Observable<SystemLogin2fa>>();

  login2fa$ = this.streamSystemLogin2fa();

  /* @ngInject */
  constructor(
    readonly prfSystemService: SystemService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {}

  streamSystemLogin2fa() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.loadOp$.pipe(rx.switchMap((action$) => action$)),
          this.saveOp$.pipe(rx.switchMap((action$) => action$)),
        ),
      shareReplayRefOne(),
    )(null);
  }

  load() {
    const action$ = rx.pipe(
      () => rx.obs.from(this.prfSystemService.getSystemLogin2fa()),
      shareReplayRefOne(),
    )(null);

    this.loadOp$.next(action$);

    return action$;
  }

  save(model: SystemLogin2fa) {
    const action$ = rx.pipe(
      () => rx.obs.from(this.prfSystemService.updateSystemLogin2fa(model)),
      rx.tap(() => this.prfClientGeneralPubsub.publishSystemLogin2faUpdated()),
      shareReplayRefOne(),
    )(null);

    return action$;
  }
}
