import ng from 'angular';
import BaseController from '~/source/common/controllers/base';
import * as rx from '@proftit/rxjs';
import { useResolve } from '~/source/contact/contact-page/trading-account/forex/bundles-table-popup/use-resolve';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import template from './private-ewallet-popup.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import { BrandsService } from '~/source/management/brand/services/brands';
import CurrenciesService from '~/source/common/services/currencies';
import { ClipboardService } from 'angular-clipboard';

import { BrandPrivateEwalletProvider } from '~/source/management/integrations/ewallet-crypto-providers/ewallets-providers.model';
import { PrivateEwallet, ProviderCurrency } from './private-ewallet.model';
import { Entity } from '@proftit/crm.api.models.general';
import CustomersService from '~/source/contact/common/services/customers';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PRIVATE_EWALLET_UPDATED } from '~/source/common/constants/general-pubsub-keys';

const allowedCryptoCurrenciesIds = [161, 162, 306, 307];

class PrivateEwalletPopupController extends BaseController {
  blockUiRef = 'privateEwalletPopup';
  blockUiId = 'privateEwalletPopup';
  growlId = 'privateEwalletPopup';
  lifecycles = observeComponentLifecycles(this);
  resolveCt = useResolve<{ customer: Customer; account: TradingAccount }>(
    this.lifecycles,
  );
  usedCurrencyIds = new rx.Subject<void>();
  usedCurrencyIdsList = [];
  loadPrivateEwallets = new rx.Subject<void>();

  customer$ = this.resolveCt.resolve$.pipe(
    rx.map((resolve) => (resolve ? resolve.customer : null)),
    shareReplayRefOne(),
  );

  account$ = this.resolveCt.resolve$.pipe(
    rx.map((resolve) => (resolve ? resolve.account : null)),
    shareReplayRefOne(),
  );

  customer: Customer;
  account: TradingAccount;
  providerCurrencies$ = this.streamProviderCurrencies();
  providerCurrenciesFiltered$ = this.streamProviderCurrenciesFiltered();

  cryptoCurrencies$ = this.streamCryptoCurrencies();
  cryptoCurrenciesFiltered$ = this.streamCryptoCurrenciesFiltered();

  privateEwallets$ = this.streamPrivateEwallets();
  ewallets$ = this.streamEwallets();

  autoGeneratedModel: PrivateEwallet | null = null;
  manualModel: any | null = null;

  isEditMode = false;
  isHasAutoGenerated = false;
  isAutoGenerated = true;
  providerCurrency: ProviderCurrency | null = null;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly currenciesService: CurrenciesService,
    readonly customersService: () => CustomersService,
    readonly ewalletsService: EwalletsService,
    readonly clipboard: ClipboardService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();
    useStreams(
      [
        this.streamSyncResolveToState(),
        this.providerCurrencies$,
        this.privateEwallets$,
        this.cryptoCurrencies$,
        this.cryptoCurrenciesFiltered$,
        this.providerCurrenciesFiltered$,
        this.ewallets$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
  streamSyncResolveToState() {
    return rx.pipe(
      () => this.resolveCt.resolve$,
      rx.tap((resolve) => {
        if (_.isNil(resolve)) {
          this.customer = null;
          this.account = null;
          return;
        }

        this.customer = resolve.customer;
        this.account = resolve.account;
      }),
      rx.catchError(() => rx.obs.NEVER),
    )(null);
  }

  streamPrivateEwallets() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.customer$,
          this.account$,
          this.cryptoCurrencies$,
          this.loadPrivateEwallets,
        ),
      rx.withLatestFrom(this.customer$, this.account$, this.cryptoCurrencies$),
      rx.map(([_, customer, account, cryptoCurrencies]) => {
        return [customer.id, account.id, cryptoCurrencies];
      }),

      rx.switchMap(([customerId, accountId, cryptoCurrencies]) => {
        if (!customerId || !accountId || !cryptoCurrencies) {
          return rx.obs.NEVER;
        }
        return rx.obs
          .from(
            this.fetchCustomerEwallets(customerId, accountId, cryptoCurrencies),
          )
          .pipe(rx.catchError(() => rx.obs.from([])));
      }),
      rx.map((ewallets: PrivateEwallet[]) => {
        return ewallets;
      }),

      rx.tap((privateEwallets) => {
        this.usedCurrencyIdsList = privateEwallets.map(
          (ewallet) => ewallet.currencyId,
        );
        if (privateEwallets.length === 0) {
          this.isEditMode = true;
        }
        this.prfClientGeneralPubsub.publish(PRIVATE_EWALLET_UPDATED, {
          hasEwallets: privateEwallets.length > 0,
        });
        this.usedCurrencyIds.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamProviderCurrencies() {
    return rx.pipe(
      () => this.customer$,
      rx.map((customer) => customer.brand.id),
      rx.switchMap((brandId) => {
        return rx.obs
          .from(this.fetchBrandBrandEwalletProviders(brandId))
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map(
        (
          ewalletProviders: BrandPrivateEwalletProvider[],
        ): ProviderCurrency[] => {
          return ewalletProviders.reduce((acc, provider) => {
            if (!provider.isActive || provider.currencies.length < 1) {
              return acc;
            }
            provider.currencies = provider.currencies.map((c) => {
              return {
                ...c,
                label: c.currency.name,
                providerId: provider.providerId,
              };
            });
            return [...acc, ...provider.currencies];
          }, []);
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamProviderCurrenciesFiltered() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInit$,
          this.usedCurrencyIds,
          this.providerCurrencies$,
        ),
      rx.withLatestFrom(this.providerCurrencies$),
      rx.map(([_, providerCurrencies]) => {
        if (!providerCurrencies) {
          return [];
        }
        return providerCurrencies.filter((providerCurrency) => {
          return !this.usedCurrencyIdsList.includes(
            providerCurrency.currency.id,
          );
        });
      }),
      rx.tap((providerCurrencies) => {
        this.isHasAutoGenerated = providerCurrencies.length > 0;
        if (this.isAutoGenerated && !this.isHasAutoGenerated) {
          this.isAutoGenerated = false;
          this.autoGeneratedModel = null;
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCryptoCurrencies() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => rx.obs.from(this.fetchCryptoCurrencies())),
      rx.map((currencies) => {
        return currencies
          .filter((currency) =>
            allowedCryptoCurrenciesIds.includes(currency.id),
          )
          .map((currency) => {
            return {
              ...currency,
              label: currency.name,
            };
          });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCryptoCurrenciesFiltered() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInit$,
          this.usedCurrencyIds,
          this.cryptoCurrencies$,
        ),
      rx.withLatestFrom(this.cryptoCurrencies$),
      rx.map(([_, currencies]) => {
        if (!currencies) {
          return [];
        }
        return currencies.filter((currency) => {
          return !this.usedCurrencyIdsList.includes(currency.id);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEwallets() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(this.fetchEwallets())
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((ewallets) => {
        return ewallets.map((e) => {
          return {
            ...e,
            label: e.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  fetchBrandBrandEwalletProviders(brandId) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiRef })
      .getBrandEwalletProviders(brandId, [
        'currencies.ewallet',
        'currencies.currency',
      ])
      .then((brand) => {
        return brand.plain() as BrandPrivateEwalletProvider[];
      });
  }

  fetchCryptoCurrencies() {
    return this.currenciesService
      .setConfig({ blockUiRef: this.blockUiRef })
      .filter({ isCrypto: true })
      .getListWithQuery()
      .then((currencies) => {
        return currencies.plain();
      });
  }

  fetchCustomerEwallets(
    customerId: number,
    accountId: number,
    currencies: any[] = [],
  ) {
    return this.customersService()
      .setConfig({ blockUiRef: this.blockUiRef })
      .getAccountEwalletResource(customerId, accountId)
      .getListWithQuery()
      .then((ewallets) => {
        let ewalletsArr: PrivateEwallet[] = ewallets.plain();
        ewalletsArr = ewalletsArr.map((ewallet) => {
          return {
            ...ewallet,
            currencyName: currencies.find((c) => c.id === ewallet.currencyId)
              ?.name,
          };
        });
        return ewalletsArr || [];
      })
      .catch((err) => {
        this.growl.error('server_errors.SERVER_GENERAL');
      });
  }

  fetchEwallets() {
    return this.ewalletsService
      .setConfig({ blockUiRef: this.blockUiRef })
      .getListWithQuery()
      .then((ewallets) => {
        return ewallets.plain();
      })
      .catch(() => {
        this.growl.error('server_errors.SERVER_GENERAL');
      });
  }

  onProviderCryptoCurrencyChange() {
    if (!this.providerCurrency) return;
    this.generateWalletAddress(
      this.customer.id,
      this.account.id,
      this.providerCurrency.providerId,
      this.providerCurrency.currency.id,
    ).then((addressObj: { address: string }) => {
      this.autoGeneratedModel = null;

      this.autoGeneratedModel = {
        ewalletId: this.providerCurrency.ewallet.id,
        currencyId: this.providerCurrency.currency.id,
        providerId: this.providerCurrency.providerId,
        address: addressObj.address,
      };
    });
  }

  generateWalletAddress(
    customerId: number,
    accountId: number,
    providerId: number,
    currencyId: number,
  ) {
    return this.customersService()
      .setConfig({ blockUiRef: this.blockUiRef })
      .generatePrivateEwalletAddress(
        customerId,
        accountId,
        providerId,
        currencyId,
      )
      .then((address) => {
        return address.plain();
      });
  }

  createPrivateEwallet(ewallet: PrivateEwallet) {
    return this.customersService()
      .setConfig({ blockUiRef: 'privateEwalletPopup' })
      .createPrivateEwallet(this.customer.id, this.account.id, ewallet)
      .then((res) => {
        return res.plain();
      });
  }

  removeEwallet(id: number) {
    this.customersService()
      .removePrivateEwallet(this.customer.id, this.account.id, id)
      .then(() => {
        this.usedCurrencyIds.next();
        this.loadPrivateEwallets.next();
      });
  }

  copyToClipboard(address: string) {
    this.clipboard.copyText(address);
  }

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };

  addAutoGenerated() {
    this.createPrivateEwallet(this.autoGeneratedModel).then((res) => {
      this.closeEdit();
      this.loadPrivateEwallets.next();
    });
  }

  addManual() {
    const model: PrivateEwallet = {
      ewalletId: this.manualModel.ewallet.id,
      currencyId: this.manualModel.currency.id,
      address: this.manualModel.address,
    };
    this.createPrivateEwallet(model).then((res) => {
      this.closeEdit();
      this.loadPrivateEwallets.next();
    });
  }

  openEdit() {
    this.isEditMode = true;
  }

  closeEdit() {
    this.providerCurrency = null;
    this.autoGeneratedModel = null;
    this.manualModel = null;
    this.isEditMode = false;
  }
}

export const PrivateEwalletPopupComponent = {
  template,
  controller: PrivateEwalletPopupController,
  controllerAs: '$ctrl',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
