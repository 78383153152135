import template from './fee-rollover-details-row.component.html';
const styles = require('./fee-rollover-details-row.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class FeeRolloverDetailsRowController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const FeeRolloverDetailsRowComponent = {
  template,
  controller: FeeRolloverDetailsRowController,
  bindings: {
    row: '<',
  },
};
