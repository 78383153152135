import BaseController from '~/source/common/controllers/base';
import template from './contact-status-popup.html';
import CustomersService from '~/source/contact/common/services/customers';
import { IScope } from 'angular';
import { Customer } from '@proftit/crm.api.models.entities';
import { CustomerStatus } from '~/source/common/models/customer-status';

class Controller extends BaseController {
  customersServiceInstance: CustomersService;

  // bindings
  brandId: number;
  customers: Customer[];
  isOpen: boolean;
  assignLimit: number;
  tableParamsFilters: any;
  selectAllItems: boolean;

  customerStatus: Partial<CustomerStatus>;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
  ) {
    super();
  }

  $onInit() {
    Object.assign(this, {
      customersServiceInstance: this.customersService(),
      isOpen: false,
    });

    // provide default value to prevent auto assign of nullable value into model in select component
    this.customerStatus = {};
  }

  /**
   * Update user status id
   */
  updateCustomer() {
    // assign all customers
    if (this.selectAllItems) {
      this.updateAllCustomersOnRoute();
      return;
    }

    const customers = this.normalizeCustomers();

    return this.customersServiceInstance
      .setConfig({ blockUiRef: 'updateCustomerStatusIdPopup' })
      .patchCollection('~', customers) // patch with bulk resource
      .then(() => this.onPatchSuccess());
  }

  /**
   * update all customer status id on provided route
   */
  updateAllCustomersOnRoute() {
    return (
      this.customersServiceInstance
        .setConfig({ blockUiRef: 'updateCustomerStatusIdPopup' })
        // only assign customers matching the current filter
        .filter({ ...this.tableParamsFilters(), brandId: this.brandId })
        /*
         * A limit must be sent to the server. Otherwise the default 1k server limit will be applied
         * We are using the "pagination" as "action limit" here. (which is why page is always 1)
         */
        .setPage(1, this.assignLimit)
        // patch with filter resource
        .patchCollection('filter', {
          customerStatusId:
            this.customerStatus.id !== undefined
              ? this.customerStatus.id
              : this.customerStatus,
        })
        .then(() => this.onPatchSuccess())
    );
  }

  /**
   * close popup
   */
  closePopup() {
    this.isOpen = false;
  }

  /**
   * called after customer update
   */
  onPatchSuccess() {
    this.closePopup();

    // let the world know what happened
    this.$scope.$emit('contact:customerStatuses:updated');
  }

  /**
   * normalize customer/s for patch
   *
   * @param {number|null} userId
   */
  normalizeCustomers() {
    if (!Array.isArray(this.customers)) {
      this.customers = [this.customers];
    }

    // customer normalization
    return this.customers.map((customerId) => ({
      id: customerId,
      customerStatusId:
        this.customerStatus.id !== undefined
          ? this.customerStatus.id
          : this.customerStatus,
    }));
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    brandId: '<',
    customers: '<',
    isOpen: '=',
    assignLimit: '<',
    tableParamsFilters: '<',
    selectAllItems: '<',
  },
};
