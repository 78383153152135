import SocketService from '~/source/common/services/socket';

class PositionsBinarySocketService extends SocketService {
  get channelRoot() {
    return 'BinaryPosition';
  }

  get whiteList() {
    return ['expiryDate', 'expiryRate', 'payout', 'positionStatusCode'];
  }
}

export default PositionsBinarySocketService;
