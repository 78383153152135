import template from './intercom.html';
import { IntercomService } from '~/source/common/services/intercom.service';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
const styles = require('./intercom.scss');

class Intercom {
  styles = styles;
  permissionNormalized = PermissionNormalized;
  Permissions = Permissions;
  onClickIntercom: () => void;

  /*@ngInject */
  constructor(readonly intercomService: IntercomService) {}

  showIntercomChat() {
    this.onClickIntercom();
    this.intercomService.showIntercom(false);
  }
}

export const IntercomComponent = {
  template, // html
  controller: Intercom,
  bindings: {
    onClickIntercom: '&',
  },
};
