import { EntityWithCode } from '@proftit/crm.api.models.general';

export interface TradingAccountTransactionStatus extends EntityWithCode {}

export enum TradingAccountTransactionStatusCode {
  Pending = 'pending',
  Requested = 'requested',
  Approved = 'approved',
  Canceled = 'canceled',
  Declined = 'declined',
  Rejected = 'rejected',
  Withdrawable = 'withdrawable',
  Partial = 'partial',
  Closed = 'closed',
  Deleted = 'deleted',
}
