import * as _ from '@proftit/lodash';
import angular from 'angular';

/*
 * @private
 */
const getCodeTranslationPath = _.curry((settings, codeDef, code) => {
  if (_.isNil(code)) {
    return null;
  }

  return `${settings.codes[codeDef].translationPath}.${_.toUpper(code)}`;
});

/**
 * Filter factory. Return filter to convert code to translation.
 *
 * The settings for code to translation conversions resides in the `code-tranlsations.settings.ts` file.
 *
 * @param {service} $translate - translate service
 * @returns {filter} filter instance function
 */
export function codeTranslateFilterFactory(
  $translate: angular.translate.ITranslateService,
  codeTranslationsSettings,
) {
  /**
   * Filter function. Convert code to translation
   *
   * If the code does not exist in the translation, the filter returns a default translation that
   * is specified in the code-translation settings.
   *
   * @param {string} code - code to translate.
   * @param {string} codeDef - the translation settings key to which the code belongs to.
   * @returns {string} translation of the code.
   *
   * @example
   *
   *    {{ status.code | codeTranslate:"pendingPositionForexStatus" }}
   */
  function codeTranslateFilter(code, codeDef) {
    return _.flow([
      getCodeTranslationPath(codeTranslationsSettings, codeDef),
      _.defaultTo(
        codeTranslationsSettings.codes[codeDef].defaultTranslationPath,
      ),
      $translate.instant,
    ])(code);
  }

  return codeTranslateFilter;
}

codeTranslateFilterFactory.$inject = ['$translate', 'codeTranslationsSettings'];

export default codeTranslateFilterFactory;
