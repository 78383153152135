import BaseController from '~/source/common/controllers/base';
const styles = require('./compliance-doc-cell-renderer.component.scss');

import template from './compliance-doc-cell-renderer.component.html';
import moment from 'moment';
import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { GoogleStorageDomain } from '~/source/common/services/private-google-storage-file.service';

class Controller extends BaseController {
  styles = styles;
  minDateForDateButton = moment('2000-01-01');
  maxDateForDateButton = moment('2100-01-01');

  GoogleStorageDomain;

  lifecycles = observeComponentLifecycles(this);

  customerId$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'customerId',
  );
  attachmentId$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'attachmentId',
  );
  privateImageApiParams$ = this.streamPrivateImageApiParams();

  constructor() {
    super();
    useStreams([this.privateImageApiParams$], this.lifecycles.onDestroy$);
    this.GoogleStorageDomain = GoogleStorageDomain;
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamPrivateImageApiParams() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.customerId$, this.attachmentId$),
      rx.map(([customerId, attachmentId]) => {
        return {
          customerId,
          attachmentId,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }
}

const ComplianceDocCellRendererComponent = {
  template,
  controller: Controller,
  bindings: {
    row: '<',
    fieldName: '<',
    renderer: '<',
    colDef: '<',
    customerId: '<',
    attachmentId: '<',
    fileType: '<',
  },
};

export default ComplianceDocCellRendererComponent;
