if (process.env.NODE_ENV !== 'test') {
  // for test env, we don't need CSS
  /* eslint global-require: "off" */
  require('flag-icon-css/css/flag-icon.css');
  // require('@proftit/ng1.dropdown/build/assets/index.css');
  // require('@proftit/ng1.number-code-input/build/assets/index.css');
  // require('@proftit/ng1.prf-ui-kit/build/assets/index.css');
  require('@proftit/theme.crm.client.turquoise-light/src/index.scss');
  require('fullcalendar/dist/fullcalendar.min.css');
  require('../assets/scss/config-styles.scss');
}
import ng from 'angular';
// Angular/2 prep
// import { NgModule, Component } from '@angular/core';

import bluebird from 'bluebird';

// Select which polyfills are actually used (to avoid loading all)
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/promise';
import 'core-js/features/string/starts-with';

import 'jquery';
import 'fullcalendar';
import 'angular-ui-bootstrap';
import 'angular-ui-calendar';
import '@uirouter/angularjs';
import 'ng-file-upload';
import 'angular-animate';
import 'angular-block-ui';
import 'angular-resizable';
import 'restangular/dist/restangular';
import 'angular-cache';
import 'angular-validation/dist/angular-validation';
import 'angular-validation/dist/angular-validation-rule';
import 'angular-growl-v2';
import 'angular-translate';
import 'angular-toggle-switch';
import 'angular-local-storage';
import 'angular-permission';
import 'ng-idle';
import 'angular-sanitize';
import 'bootstrap-daterangepicker';
import 'angular-daterangepicker';
import 'ng-table/bundles/ng-table';
import 'ng-tags-input';
import 'angular-drag-and-drop-lists';
import 'bootstrap';
import 'moment';
import 'angular-media-queries';
import 'angular-moment';
import 'angular-blocks/dist/angular-blocks';
import 'angular-clipboard';
import 'angular-keyboard';
import './tinymce.module';
import 'angular-cookies';

// import 'mimic';

// import 'chosen-js';
import { initializeChosenJquery } from '@proftit/chosen';
initializeChosenJquery(jQuery);

import 'angular-chosen-localytics';
import 'angular-bootstrap-lightbox';
import 'ng-infinite-scroll';
import 'angular-desktop-notification';
import 'angular1-async-filter';
import 'angular-ui-tinymce';
import angularInview from 'angular-inview';

import { declareCrTranscludeModule } from '@code-restory/cr-transclude';
import { defineNg1PrfTranscludeModule } from '@proftit/ng1.prf-transclude';
import { defineNg1ReactiveFormsModule } from '@proftit/ng1.reactive-forms';
import { defineNg1PipesGeneralModule } from '@proftit/ng1.pipes.general';
import { defineNg1DynamicTemplateModule } from '@proftit/ng1.dynamic-template';
import { defineNg1PrfDropdownModule } from '@proftit/ng1.dropdown';
import { defineNg1NumberCodeInputModule } from '@proftit/ng1.number-code-input';
import { definePrfUiKitModule } from '@proftit/ng1.prf-ui-kit';
import ConstantsModule from './common/constants/module';
import './common/loader';
import { AuthModule } from './auth/module';
import AccountingModule from './accounting/module';
import ContactsModule from './contact/module';
import ExposureModule from './exposure/module';
import { ErrorModule } from './error/module';
import ReportsModule from './reports/module';
import CrmReportsModule from './crm-reports/module';
import MarketingModule from './marketing/module';
import PortfolioModule from './portfolio/module';
import SearchModule from './search/module';
import { ManagementModule } from './management/management.module';
import { UiToolkitModule } from './ui-toolkit/ui-toolkit.module';
import { AppReactModule } from './app-react.ngmodule';
import restangularRunConf from './conf/run/restangular-run-conf';
import routerRunConfig from './conf/run/router-run-config';
import permissionsConf from './conf/run/permissions-run-conf';
import idleRunConfig from './conf/run/idle-run-config';

// For Angular/2 bluebird should not be used
import bluebirdRunConfig from './conf/run/bluebird.run';

import screensizeRunConfig from './conf/run/screensize.run';
import localstorageConfig from './conf/run/localstorage.run';
import { configure } from './conf/configure';
import { generalConfig } from './general-config';
import { AppComponent } from './app/app.component';
import { CrmAppStoreProviderComponent } from './app/cfm-app-store-provider.component';
import { CrmAppRouteProviderComponent } from './app/crm-app-route-provider.component';
// import { CurrentUserService } from '@proftit/crm-client-services/build';

const CrTranscludeModule = declareCrTranscludeModule(ng);
const Ng1PrfTranscludeModule = defineNg1PrfTranscludeModule(ng);
const Ng1ReactiveFormsModule = defineNg1ReactiveFormsModule(ng);
const Ng1PipesGeneralModule = defineNg1PipesGeneralModule(ng);
const Ng1DynamicTemplateModule = defineNg1DynamicTemplateModule(ng);
const Ng1PrfDropdownModule = defineNg1PrfDropdownModule(ng);
const NumberCodeInputModule = defineNg1NumberCodeInputModule(ng);
const PrfUiKitModule = definePrfUiKitModule(ng);

export const AppModuleNg = ng.module('crm', [
  'ui.router',
  'ui.calendar',
  'permission',
  'permission.ui',
  'crm.common',
  'crm.contact',
  'ui.bootstrap',
  'ngAnimate',
  'restangular',
  'validation',
  'ngFileUpload',
  'validation.rule',
  'blockUI',
  'angular-growl',
  'angularResizable',
  'pascalprecht.translate',
  'ngTable',
  'ngCookies',
  'ngTagsInput',
  'toggle-switch',
  'LocalStorageModule',
  'ngIdle',
  'ngSanitize',
  'daterangepicker',
  'dndLists',
  'matchMedia',
  'angularMoment',
  'angular-blocks',
  'angular-cache',
  'angular-clipboard',
  'keyboard',
  'localytics.directives', // angular chosen
  'bootstrapLightbox',
  'infinite-scroll',
  'ngDesktopNotification',
  'asyncFilter',
  'ui.tinymce',
  angularInview,
  CrTranscludeModule.name,
  ConstantsModule,
  AuthModule.name,
  AccountingModule,
  ContactsModule,
  ExposureModule,
  ErrorModule.name,
  SearchModule,
  ReportsModule,
  CrmReportsModule,
  MarketingModule,
  PortfolioModule,
  ManagementModule.name,
  UiToolkitModule.name,
  Ng1PrfTranscludeModule.name,
  Ng1ReactiveFormsModule.name,
  Ng1PipesGeneralModule.name,
  Ng1DynamicTemplateModule.name,
  Ng1PrfDropdownModule.name,
  NumberCodeInputModule.name,
  PrfUiKitModule.name,
  AppReactModule.name,
]);

AppModuleNg.config(generalConfig);

configure(AppModuleNg);

AppModuleNg.component('prfCrmAppRouteProvider', CrmAppRouteProviderComponent);
AppModuleNg.component('prfCrmAppStoreProvider', CrmAppStoreProviderComponent);

AppModuleNg.component('prfApp', AppComponent);

AppModuleNg.run(restangularRunConf);
AppModuleNg.run(routerRunConfig);
AppModuleNg.run(permissionsConf);
AppModuleNg.run(idleRunConfig);

// For Angular/2 bluebird should not be used
AppModuleNg.run(bluebirdRunConfig);

AppModuleNg.run(screensizeRunConfig);
AppModuleNg.run(localstorageConfig);

/* Override the default Promise implementation with Bluebird
 * For Angular/2 bluebird should not be used
 */
(<Window & { Promise }>window).Promise = bluebird;
