import BaseController from '~/source/common/controllers/base';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';

import template from './deposit-amount-input.html';

class DepositAmountInputController extends BaseController {
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;
}

export default {
  template,
  controller: DepositAmountInputController,
  bindings: {
    minDeposit: '<',
    currency: '<',
    brand: '<',
    model: '=',
  },
};
