import ng from 'angular';
import listPageSettings from './settings/list.json';

import ManagementBrandCampaignFormFormatter from './services/brand-campaign-form-formatter';
import ManagementIpRangesFormFormatter from './services/ip-ranges-form-formatter';
import BrandCampaignFormComponent from './components/brand-campaign/brand-campaign-form';
import BrandCampaignSelect from './components/dropdowns/brand-campaign-select';
import ApiUserDataComponent from './components/api-user-data/api-user-data';
import ApiUserFormComponent from './components/api-user-form/api-user-form';
import ApiUserCreateComponent from './components/api-user-create/api-user-create.component';
import ApiUserUpdateComponent from './components/api-user-update/api-user-update.component';
import ApiUsersListComponent from './components/api-users-list/api-users-list.component';

const ngModule = ng.module('crm.apiUsers', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

function config($stateProvider, $urlRouterProvider) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/permissions/api-users',
    '/crm/management/permissions/api-users/list',
  );

  $stateProvider
    .state('crm.management.permissions.api-users', {
      url: '/api-users',
      template: '<ui-view/>',
    })
    .state('crm.management.permissions.api-users.list', {
      url: '/list',
      component: 'prfApiUsersListComponent',
    })
    .state('crm.management.permissions.api-users.add', {
      url: '/user-create',
      component: 'prfApiUserCreateComponent',
    })
    .state('crm.management.permissions.api-users.update', {
      url: '/user-update/:userId',
      component: 'prfApiUserUpdateComponent',
    });
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];

ngModule
  .config(config)
  .constant('apiUsersListSettings', listPageSettings)
  .factory(
    'managementBrandCampaignFormFormatter',
    ManagementBrandCampaignFormFormatter.$factorize(),
  )
  .factory(
    'managementIpRangesFormFormatter',
    ManagementIpRangesFormFormatter.$factorize(),
  )
  .component('prfBrandCampaignForm', BrandCampaignFormComponent)
  .component('prfBrandCampaignSelect', BrandCampaignSelect)
  .component('prfApiUserData', ApiUserDataComponent)
  .component('prfApiUserForm', ApiUserFormComponent)
  .component('prfApiUserCreateComponent', ApiUserCreateComponent)
  .component('prfApiUserUpdateComponent', ApiUserUpdateComponent)
  .component('prfApiUsersListComponent', <any>ApiUsersListComponent);

export default ngModule.name;
