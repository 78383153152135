import Promise from 'bluebird';
import Component from './base/component';
import attachmentActionsOptions from './attachment-actions-options';

class AttachmentActionController extends Component.controller {
  values: any[];

  query() {
    this.values = attachmentActionsOptions.map((value, index) => {
      return {
        id: index,
        action: value,
        name: value,
      };
    });
    return Promise.resolve(this.values);
  }

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'Attachments action';
  }
}

export default Component.config({ controller: AttachmentActionController });
