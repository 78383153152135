import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
class RiskFreeCommonController extends BaseController {
  customerServiceInst: CustomersService;

  // bindings
  account: TradingAccount;
  customer: Customer;
  onDone: () => void;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
  ) {
    super();
    this.customerServiceInst = this.customersService();
  }

  /**
   *  Post risk free positions quantity
   *
   * @param {number} customerId - customer id
   * @param {number} accountId - account id
   * @param {number} qty - risk free quantity to add/deduct
   *
   * @returns {Promise} - resolves to the new number of risk free positions
   */
  updateRiskFreePositions(customerId, accountId, qty = 0) {
    // create new risk free (add/deduct)
    return this.customersService()
      .setConfig({
        blockUiRef: 'riskFreeUpdateForm',
        growlRef: 'riskFreeUpdateForm',
      })
      .getRiskFreePositionsResource(customerId, accountId)
      .postWithQuery({ quantity: qty })
      .then((riskFree) => {
        this.$scope.$emit(this.reloadTableEvent);
        return riskFree;
      });
  }

  /**
   * Name of event that will cause risk free table to reload
   *
   * @returns {string}
   */
  get reloadTableEvent() {
    return 'riskFreePositionsTableReload';
  }
}

export default RiskFreeCommonController;
