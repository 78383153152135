import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import {
  Mt4Asset,
  MtGroupAsset,
} from '@proftit/tradingcore.api.models.entities';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { ApiMt4GroupsService } from '~/source/common/api-cfd-platform/api-mt4-groups.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PubsubKey } from '~/source/common/models/pubsub-key';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import { directivesPriorities } from '../constants/directives-priorities';
import { PlatformSessionInfo } from './platform-session-info';

export class CurrentMt4AssetOfGroupStoreServiceDirectiveController {
  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  getOneActionObs$ = new rx.Subject<rx.Observable<Mt4Asset>>();

  saveWithUpdatesActionObs$ = new rx.Subject<rx.Observable<any>>();

  assetS = this.streamAsset();

  /* @ngInject */
  constructor(
    readonly prfApiMt4GroupsService: ApiMt4GroupsService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {}

  streamAssetFromGet() {
    return generateInProcessStream(
      this.getOneActionObs$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(
              rx.catchError((e) => {
                inProcess$.next({ inProcess: false, error: e });
                log.error('error fetching group', e);
                return rx.obs.of(null);
              }),
            ),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamAssetFromUpdate() {
    return generateInProcessStream(
      this.saveWithUpdatesActionObs$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(
              rx.catchError((e) => {
                inProcess$.next({ inProcess: false, error: e });
                log.error('error updating group', e);
                return rx.obs.NEVER;
              }),
            ),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamAsset() {
    const assetFromGetS = this.streamAssetFromGet();
    const assetFromUpdateS = this.streamAssetFromUpdate();

    return generateInProcessStream(
      rx.obs.merge(assetFromGetS.stream$, assetFromUpdateS.stream$),
      () => rx.pipe(shareReplayRefOne()),
      [assetFromGetS.inProcess$, assetFromUpdateS.inProcess$],
    );
  }

  getOne(apiUrl: string, token: string, forexGroupId: number, assetId: number) {
    const action$ = rx.pipe(
      () =>
        rx.obs.from(
          this.prfApiMt4GroupsService.getGroupAsset(
            apiUrl,
            token,
            forexGroupId,
            assetId,
          ),
        ),
      shareReplayRefOne(),
    )(null);

    this.getOneActionObs$.next(action$);
    return action$;
  }

  update(changes: Partial<MtGroupAsset>) {
    const action$ = rx.pipe(
      () => rx.obs.of(changes),
      rx.withLatestFrom(
        this.assetS.stream$,
        this.prfCurrentPlatformSession.sessionS.stream$,
      ),
      rx.switchMap(([changes, asset, sessionInfo]) => {
        if (!sessionInfo.isLoggedIn) {
          throw new Error('update action can not be performed. Not logged in');
        }

        return this.prfApiMt4GroupsService.updateGroupAsset(
          asset.mtCustomerGroup.id,
          asset.id,
          changes,
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      rx.tap(() =>
        this.prfClientGeneralPubsub.publish(PubsubKey.Mt4AssetUpdated, null),
      ),
      shareReplayRefOne(),
    )(null);

    this.saveWithUpdatesActionObs$.next(action$);
    return action$;
  }
}

export const currentMt4AssetOfGroupStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentMt4AssetOfGroupStoreServiceDirectiveController,
  };
};

currentMt4AssetOfGroupStoreServiceDirective.$inject = [] as string[];
