import ng from 'angular';
import moment from 'moment-timezone';
import * as rx from '@proftit/rxjs';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as _ from '@proftit/lodash';
import MarketingDashboardService from '~/source/marketing/services/marketing-stats.service';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';
import BrandsService from '~/source/management/brand/services/brands';
import MarketingCampaignController from '../../campaign.base.controller';
import MarketingStatSummaryGraph from '~/source/common/models/marketing-stat-summary-graph';
import MarketingStatSummarySummary from '~/source/common/models/marketing-stat-summary-summary';
import { Brand, Campaign } from '@proftit/crm.api.models.entities';
import template from './marketing-dashboard.html';

type CampaignsFilter = {
  brandId?: number;
  campaignId?: number;
  time: { gte: number; lte: number };
};

const BRAND_FILTER_NAME = 'brandSingle';

export class MarketingDashboardController extends MarketingCampaignController {
  static $inject = [
    '$scope',
    '$timeout',
    'marketingDashboardService',
    'marketingDashboardSettings',
    'marketingCampaignsService',
    'localStorageService',
    '$translate',
    'brandsService',
    'appConfig',
    ...MarketingCampaignController.$inject,
  ];

  lifecycles = observeComponentLifecycles(this);

  graphs: MarketingStatSummaryGraph;
  summary: MarketingStatSummarySummary;
  defaultFilter;
  datepickerModel;
  marketingDashboardSettings;
  queryParams: CampaignsFilter;
  marketingDashboardService: () => MarketingDashboardService;
  marketingDashboardInst: MarketingDashboardService;
  marketingCampaignsInst: MarketingCampaignsService;
  brandsService: () => BrandsService;
  $timeout: any;

  campaignSearchTerm$ = new rx.BehaviorSubject<string>(null);

  opAddFilterToTable$ = new rx.Subject<{
    clearName: string;
    newFilter: Object;
  }>();

  opRemoveFilterFromTable$ = new rx.Subject<string>();

  tableFilterRemovedByUi$ = new rx.Subject<string>();

  tableFilterAddedByEvent$ = new rx.Subject<any>();

  tableFilterAppliedFromSavedCache$ = new rx.Subject<any>();

  brandChangeFromMainSelect$ = new rx.Subject<Brand>();

  mainBrandSelectDataFetched$ = new rx.Subject<boolean>();

  tableFiltersClearedByBar$ = new rx.Subject<void>();

  selectBrandFromCampaignAction = new rx.Subject<number>();

  selectCampaignAction = new rx.Subject<number>();

  filter$ = new rx.BehaviorSubject<any>(null);

  selectedCampaign = new FormControl<Campaign>(null);

  constructor(...args) {
    super(...args);
  }

  $onInit() {
    super.$onInit();

    const initialDatepickerData = this.marketingDashboardSettings.datepicker
      .initialData;

    Object.assign(this, {
      // init date picker model with initial data
      dataServiceInstance: this.marketingCampaignsService(),
      settings: this.marketingDashboardSettings,
      tableColumns: this.marketingDashboardSettings.campaignsTable.tableColumns,
      blockUiKey: `${this.tableKey}BlockUi`,
      datepickerModel: initialDatepickerData,
      marketingCampaignsInst: this.marketingCampaignsService(),
      marketingDashboardInst: this.marketingDashboardService(),
      timeInitialSettings: initialDatepickerData,
      defaultFilter: {
        time: {
          lte: moment()
            .utc()
            .add(
              initialDatepickerData.endDate.value,
              initialDatepickerData.endDate.unit,
            )
            .endOf('day')
            .unix(),
          gte: moment()
            .utc()
            .add(
              initialDatepickerData.startDate.value,
              initialDatepickerData.startDate.unit,
            )
            .startOf('day')
            .unix(),
        },
      },
    });

    this.getBrandsList().then((brands) => {
      this.defaultFilter.brand = brands.length === 1 ? brands[0] : null;

      this.$scope.$watchGroup(
        [
          'vm.defaultFilter.brand',
          'vm.defaultFilter.campaign',
          'vm.datepickerModel.startDate',
          'vm.datepickerModel.endDate',
        ],
        () => {
          const filter = this.getSummaryDataFilters();

          if (filter === null) {
            return;
          }

          this.filter$.next(filter);
        },
      );

      this.$scope.$watch(
        'vm.defaultFilter.campaign',
        (curr: Campaign, orig: Campaign) => {
          if (_.isNil(curr)) {
            return;
          }

          const targetBrandId = _.get(['brandId'], curr);

          if (_.isNil(targetBrandId)) {
            return;
          }

          if (_.get(['id'], this.defaultFilter.brand) === targetBrandId) {
            return;
          }

          this.selectBrandFromCampaignAction.next(targetBrandId);
          this.$timeout(() => {
            this.selectCampaignAction.next(curr.id);
          });
        },
      );
    });

    useStreams(
      [
        this.streamCampaignSearch(),
        this.streamSyncFilterRemovedByFilterBarUi(),
        this.streamSyncFilterAddedByFilterBarEvent(),
        this.streamSyncTableFilterFromMainBrandSelect(),
        this.streamSyncMainBrandFilterByTableAppliedCached(),
        this.streamSyncMainBrandFilterByBarClearingFilters(),
        this.streamSummaryRequests(),
        this.selectedCampaign.value$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  /**
   * Setter for ngModel campaginSearchTerm.
   *
   * Used as rxjs behavior subject encapsulation.
   */
  set campaignSearchTerm(val: string) {
    if (_.isNil(this.campaignSearchTerm$)) {
      return;
    }
    this.campaignSearchTerm$.next(val);
  }

  /**
   * Getter for ngModel campaginSearchTerm.
   *
   * Used as rxjs behaviour subject encapsulation.
   */
  get campaignSearchTerm() {
    if (_.isNil(this.campaignSearchTerm$)) {
      return null;
    }

    return this.campaignSearchTerm$.getValue();
  }

  get isInfiniteTable() {
    return true;
  }

  get tableKey() {
    return 'marketing-dashboard';
  }

  get ngTableSettings() {
    return this.marketingDashboardSettings.campaignsTable.ngTable;
  }

  /**
   * Create stream for campaign search.
   *
   * @return {Observable} campaign search stream.
   */
  streamCampaignSearch() {
    return rx.pipe(
      () => this.campaignSearchTerm$,
      rx.debounceTime(300),
      rx.filter(() => !_.isNil(this.tableParams)),
      rx.tap((term) => {
        this.tableParams.page(1);
        this.tableParams.filter().q = term;
      }),
    )(null);
  }

  /**
   * Generate stream - Sync main brand filter from bar remove action.
   *
   * @return Observable of the stream.
   */
  streamSyncFilterRemovedByFilterBarUi() {
    return rx.pipe(
      () => this.tableFilterRemovedByUi$,
      rx.filter((filterName) => filterName === BRAND_FILTER_NAME),
      rx.tap(() => (this.defaultFilter.brand = null)),
    )(null);
  }

  /**
   * Generate stream - Sync main brand filter from bar add action.
   *
   * @return Observable of the stream.
   */
  streamSyncFilterAddedByFilterBarEvent() {
    return rx.pipe(
      () => this.tableFilterAddedByEvent$,
      rx.filter((filter) => filter[BRAND_FILTER_NAME]),
      rx.map((filter) => filter[BRAND_FILTER_NAME]),
      rx.tap((brand) => (this.defaultFilter.brand = brand)),
    )(null);
  }

  /**
   * Generate stream - Sync table filter bar by main brand dropdown select.
   *
   * @return Observable of the stream.
   */
  streamSyncTableFilterFromMainBrandSelect() {
    return rx.pipe(
      () => this.brandChangeFromMainSelect$,
      rx.tap((brand) => this.onBrandChange(brand)),
    )(null);
  }

  /**
   *  Get all marketing page selects emittion and picking only the last one
   * @returns void
   */
  streamSummaryRequests() {
    return this.filter$.pipe(
      rx.debounceTime(1000),
      rx.tap((filter) => {
        this.getTabsAndSummaryData(filter);
      }),
    );
  }

  /**
   * Generate stream - Sync main brand dropdown by table applied filter cache on start.
   *
   * @return Observable of the stream.
   */
  streamSyncMainBrandFilterByTableAppliedCached() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.tableFilterAppliedFromSavedCache$.pipe(
            rx.filter((filtersState) => filtersState[BRAND_FILTER_NAME]),
            rx.map((filtersState) => filtersState[BRAND_FILTER_NAME].value),
          ),
          this.mainBrandSelectDataFetched$,
        ),
      rx.filter(([brand, isDataFetched]) => isDataFetched),
      rx.tap(([brand, a]) => (this.defaultFilter.brand = brand)),
    )(null);
  }

  /**
   * Generate stream - Sync main brand filter from bar clear all action.
   *
   * @return Observable of the stream.
   */
  streamSyncMainBrandFilterByBarClearingFilters() {
    return rx.pipe(
      () => this.tableFiltersClearedByBar$,
      rx.tap(() => (this.defaultFilter.brand = null)),
    )(null);
  }

  /**
   * Fetches all the brands and sets the 'brands' property.
   * If no brands are found, throws an exception
   *
   * @throws Error when no brands exist
   * @return {Promise} resolves to brands array
   */
  getBrandsList() {
    return this.brandsService()
      .getListWithQuery()
      .then((brands) => {
        if (brands.length < 1) {
          throw new Error('No brands exist');
        }

        return brands;
      });
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.marketingCampaignsInst
      .setConfig({ blockUiRef: 'campaignsTable' })
      .embed([
        'statistics',
        'trackerParams',
        'brandTrackerParams',
        'trackers',
        'blacklistedCountries',
        'currencyConnections',
      ])
      .expand([
        'pricingModel',
        'brand',
        'campaignStatus',
        'desk',
        'user',
        'currencyConnections.currency',
      ]);
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    const params = <any>{};

    const q = this.campaignSearchTerm$.getValue();
    if (!_.isEmpty(q)) {
      params.q = q;
    }

    return params;
  }

  /**
   * Notify bar when brand dropdown updated.
   *
   * Notify cleared or added.
   *
   * @return none.
   */
  notifyFilterBarOnBrandFilterUpdate(newBrand) {
    if (_.isNil(_.get('id', newBrand))) {
      this.opRemoveFilterFromTable$.next('brand');
      return;
    }

    const newFilter = {};
    newFilter[BRAND_FILTER_NAME] = newBrand;
    const msg = {
      newFilter,
      clearName: BRAND_FILTER_NAME,
    };

    this.opAddFilterToTable$.next(msg);
  }

  /**
   * called on brand change in view
   */
  onBrandChange(newBrand) {
    this.notifyFilterBarOnBrandFilterUpdate(newBrand);

    // set first page for getData()
    this.tableParams.page(1);

    // reload table. changes will be reloaded to table due to requiredApiFilters()
    this.tableParams.reload();
  }

  /**
   * get tabs & summary data
   */
  getTabsAndSummaryData(filter) {
    this.marketingDashboardInst
      .setConfig({ blockUiRef: 'summaryAndCharts' })
      .filter(filter)
      .getOneWithQuery('summary')
      .then((data: any) => {
        this.graphs = data.graph;
        this.summary = data.summary;
      });
  }

  /**
   * create list of selected filters for summary data api call
   * @returns {Object|null} - return object if filters changed since last call, otherwise return null
   */
  getSummaryDataFilters() {
    if (!moment.isMoment(this.datepickerModel.startDate)) {
      return null;
    }

    // prepare params for request
    const queryParams: CampaignsFilter = {
      time: {
        gte: this.datepickerModel.startDate.utc().startOf('day').unix(),
        lte: this.datepickerModel.endDate.utc().endOf('day').unix(),
      },
    };

    // if brand filter is defined add it to queryParams
    if (_.has('brand.id', this.defaultFilter)) {
      queryParams.brandId = this.defaultFilter.brand.id;
    }

    // if campaign filter is defined add it to queryParams
    if (_.has('campaign.id', this.defaultFilter)) {
      queryParams.campaignId = this.defaultFilter.campaign.id;
    }

    if (_.isArray(this.defaultFilter.campaign)) {
      queryParams.campaignId = this.defaultFilter.campaign.map((c) => c.id);
    }

    // no-op if nothing has changed
    if (ng.equals(queryParams, this.queryParams)) {
      return null;
    }

    // save params for next time
    this.queryParams = queryParams;
    return {
      ..._.omit(['brand', 'campaign'], this.defaultFilter),
      ...this.queryParams,
    };
  }

  /**
   * Checks whether the current column is filterable (needed for desk check -
   * should be filterable only after choosing brand)
   * @param {Object} $column - ngTable column
   * @returns {boolean}
   */
  isColumnFilterable($column) {
    const fieldDependencies = this.settings.dependenciesPerFilter[
      $column.field
    ];

    if (fieldDependencies && $column.filterable) {
      return fieldDependencies.every(
        (dependency) =>
          this.defaultFilter[dependency] && this.defaultFilter[dependency].id,
      );
    }

    return super.isColumnFilterable($column);
  }
}

export default {
  template,
  controller: MarketingDashboardController,
  controllerAs: 'vm',
};
