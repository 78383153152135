export const fieldsAndModulesRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.tools.fields-and-modules', {
        redirectTo: 'crm.management.tools.fields-and-modules.list',
        url: '/fields-and-modules',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.fields-and-modules.list', {
        url: '/list',
        component: 'prfStatusesList',
        data: {
          permissions: {
            only: ['management.fields_and_modules'],
            redirectTo: 'error.unauthorized',
          },
        },
      });
  },
];
