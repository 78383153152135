import { IScope } from 'angular';

import ControllerBase from '~/source/common/controllers/base';
import isValidCustomerSystemIp from '~/source/common/utilities/is-valid-customer-system-ip';

import * as _ from '@proftit/lodash';

import template from './edit-group-ip.html';
import { UserGroupsService } from '../../group/services/user-groups';

class Controller extends ControllerBase {
  group: any;
  globalIpList: any;
  isUpdateBtnDisabled: boolean = true;
  ipModel: any;
  globalIpListTags: any;
  userGroupsServiceInstance: UserGroupsService;
  currInput: string;
  initModel: any;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly userGroupsService: () => UserGroupsService,
  ) {
    super();
  }

  /**
   * set function to detect changes in input field
   * @param {val}  val is the text in the input of tags-input
   */
  set inputText(val: string) {
    this.currInput = val;
    this.updateBtnStatus();
  }
  /**
   * get function to detect changes in input field
   */
  get inputText(): string {
    return this.currInput;
  }

  $onInit() {
    this.ipModel = this.createNgTagsArray(this.group.ip);
    this.initModel = [...this.ipModel];
    this.globalIpListTags = this.createNgTagsArray(this.globalIpList);

    this.userGroupsServiceInstance = this.userGroupsService();
  }

  /**
   * Event function - called when ng-tags-input notify after text was sent to the input and
   * is going to be added as a tag.
   *
   * This allows to check the input for validaty and return boolean true/false if to allow
   * insertion of tag to the list.
   *
   * @param {object} tag - tag object { text: string }
   * @return {boolean} allow or not allow insertion of value as a tag to the list.
   */
  onTagAdding(tag) {
    return isValidCustomerSystemIp(tag.text);
  }

  onTagAdded() {
    this.updateBtnStatus();
  }

  /**
   * Event function - called when removing a tag : enable update button
   * @return {void}
   */
  onTagRemoved() {
    this.updateBtnStatus();
  }

  /**
   * disable / enable update button according to changes
   * @return {void}
   */
  updateBtnStatus() {
    if (_.isEmpty(this.currInput) && !_.isEqual(this.ipModel, this.initModel)) {
      this.isUpdateBtnDisabled = false;
    } else if (isValidCustomerSystemIp(this.currInput)) {
      this.isUpdateBtnDisabled = false;
    } else {
      this.isUpdateBtnDisabled = true;
    }
  }

  createNgTagsArray(source) {
    // convert to the format used by ng-tags
    return source.map((ip) => ({ text: ip }));
  }

  /**
   * Update user group with ip collection (whitelist)
   * @param {object} group
   */
  onUpdateGroupIps(group) {
    const groupId = this.group.id;
    const ips = this.ipModel.map(({ text }) => text);

    this.userGroupsServiceInstance.updateUserGroupIps(groupId, ips).then(() => {
      group.isEdit = false;

      // Update group ips (for display)
      group.ip = ips.slice();
      this.globalIpList = this.globalIpList.concat(ips);
    });
  }

  /**
   * Called by NgTagsInput autocomplete directive.
   * Should return an array with of the following format:
   * [{text: "text"}, ... , {text: "text"}]
   * @param {string} $query user's input
   * @returns {*}
   */
  loadAutoCompleteData($query) {
    return this.globalIpListTags.filter(
      (ip) => ip.text && ip.text.indexOf($query.toLowerCase()) !== -1,
    );
  }

  onCancelEdit(group) {
    group.isEdit = false;
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    group: '<',
    globalIpList: '<globalIpList',
  },
};
