import * as rx from '@proftit/rxjs';
import { SystemService } from '../services/system.service';
import { SystemSmtpConfiguration } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export class SystemSmtpConfigurationStoreService {
  loadOp$ = new rx.Subject<rx.Observable<SystemSmtpConfiguration>>();

  saveOp$ = new rx.Subject<rx.Observable<SystemSmtpConfiguration>>();

  smtpConfiguration$ = this.streamSystemSmtpConfiguration();

  /* @ngInject */
  constructor(readonly prfSystemService: SystemService) {}

  streamSystemSmtpConfiguration() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.loadOp$.pipe(rx.switchMap((action$) => action$)),
          this.saveOp$.pipe(rx.switchMap((action$) => action$)),
        ),
      shareReplayRefOne(),
    )(null);
  }

  load() {
    const action$ = rx.pipe(
      () => rx.obs.from(this.prfSystemService.getSmtpConfiguration()),
      shareReplayRefOne(),
    )(null);
    this.loadOp$.next(action$);
    return action$;
  }

  save(model: SystemSmtpConfiguration) {
    const action$ = rx.pipe(
      () => rx.obs.from(this.prfSystemService.updateSmtpConfiguration(model)),
      shareReplayRefOne(),
    )(null);

    return action$;
  }
}
