import template from './confirm-field.html';
import BaseController from '~/source/common/controllers/base';

class Controller extends BaseController {
  model: any;

  $onInit() {
    // this.model is {} by default
    this.model.options = this.model.options || [];
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    shouldPreserveStructure: '<',
    onChange: '&',
  },
};
