import * as _ from '@proftit/lodash';
import TableController from '~/source/common/components/table/table.controller';
import viewPixelCodeTpl from './components/pixel-code-popup/view-pixel-code-modal.html';
import { IHttpParamSerializer, IScope } from 'angular';
import type { StateService } from '@uirouter/angularjs';
import ModalService from '~/source/common/components/modal/modal.service';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';

abstract class MarketingCampaignController extends TableController {
  static $inject = [
    '$scope',
    'marketingCampaignSettings',
    'marketingCampaignsService',
    '$state',
    'modalService',
    '$httpParamSerializer',
    'modelNormalizer',
    ...TableController.$inject,
  ];

  // Injections
  $scope: IScope;
  marketingCampaignSettings;
  marketingCampaignsService: () => MarketingCampaignsService;
  $state: StateService;
  modalService: ModalService;
  $httpParamSerializer: IHttpParamSerializer;
  modelNormalizer: ModelNormalizerService;

  marketingCampaignsServiceInst: MarketingCampaignsService;
  settings;

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      settings: this.marketingCampaignSettings,
      marketingCampaignsServiceInst: this.marketingCampaignsService(),
    });
  }

  /**
   * Called when campaign was successfully added on server or user open popup in marketing dashboard
   * opens model with pixel related to campaign
   */
  generateCode(campaign) {
    return this.modalService.open({
      controller: 'MarketingViewPixelCodeModalController',
      template: viewPixelCodeTpl,
      scope: this.$scope,
      data: {
        url: this.createUrl(campaign),
        brandId: campaign.brandId ? campaign.brandId : campaign.brand.id,
        campaignId: campaign.id,
        cookieTtl: campaign.cookieExpire,
      },
    });
  }

  /**
   * create url parameters to show in pixel popup
   *
   * @param {object} campaign
   * @returns {String}
   */
  createUrl(campaign) {
    const trackersNames = _.mapEs(campaign.brandTrackerParams, 'name');
    const replacePatterns = _.timesEs(
      trackersNames.length,
      () => this.settings.trackerReplacementPattern,
    );
    return this.$httpParamSerializer(
      _.zipObjectEs(trackersNames, replacePatterns),
    );
  }
}

export default MarketingCampaignController;
