import SocketService from '~/source/common/services/socket';

export class PositionsForexSocketService extends SocketService {
  get channelRoot() {
    return 'CustomerPositions';
  }

  get whiteList() {
    return [
      'expiryDate',
      'expiryRate',
      'payout',
      'profit',
      'positionStatusCode',
      'stopLoss',
      'swap',
      'takeProfit',
      'closeReason',
    ];
  }
}

export default PositionsForexSocketService;
