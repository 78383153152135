import type { blockUI, growl } from 'angular';
import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class PrfRxService {
  /* @ngInject */
  constructor(
    protected blockUI: blockUI.BlockUIService,
    protected growl: growl.IGrowlService,
    protected growlMessages: growl.IGrowlMessagesService,
  ) {}

  catchAndRecuperateStream(
    streamFn: () => rx.Observable<any>,
    getGrowlId: () => number,
  ) {
    return rx.catchError((err: Error) => {
      log.error('error in stream', err);

      const growlId = getGrowlId();
      const errorMessage = err.message;

      if (_.isNil(growlId)) {
        this.growl.error(errorMessage, { referenceId: growlId });
      }

      return streamFn();
    });
  }
}
