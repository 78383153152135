import ApiUserAbstractManagerController from '../abstractManager';
import ManagementBrandCampaignFormFormatter from '../../services/brand-campaign-form-formatter';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import UsersService from '~/source/management/user/services/users';

import template from './api-user-create.component.html';

class ApiUserCreateController extends ApiUserAbstractManagerController {
  static $inject = [
    'usersService',
    '$state',
    'modelNormalizer',
    'managementBrandCampaignFormFormatter',
    'brandsService',
  ];

  usersService: () => UsersService;
  managementBrandCampaignFormFormatter: () => ManagementBrandCampaignFormFormatter;
  modelNormalizer: ModelNormalizerService;
  brandCampaignFormFormatterInst: ManagementBrandCampaignFormFormatter;

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      brandCampaignFormFormatterInst: this.managementBrandCampaignFormFormatter(),
    });
  }

  /**
   * Posts user to server
   *
   * this query will be called on onUserFormValid function
   * which is inherited from parent class
   *
   * @returns {*}
   */
  queryUser() {
    // this method format brand-campaign connection according to the api standard
    const formattedBrandCampaignUser = this.brandCampaignFormFormatterInst.format(
      this.apiUserModel,
    );

    // normalize user
    const normalizedUser = this.modelNormalizer.normalize(
      formattedBrandCampaignUser,
    );
    // add new user
    return this.usersServiceInst.postWithQuery(normalizedUser);
  }
}

const ApiUserCreateComponent = {
  template,
  controller: ApiUserCreateController,
  controllerAs: 'vm',
};

export default ApiUserCreateComponent;
