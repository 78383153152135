import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import template from './creditcard-type.html';
const styles = require('./creditcard-type.component.scss');

class CreditCardTypeController extends BaseController {
  _cardType = null;
  onCardTypeSelected;
  styles = styles;
  get cardTypeId() {
    return this._cardType;
  }

  set cardTypeId(creditcardType) {
    this._cardType = creditcardType;
    this.onCardTypeSelected({ creditcardTypeId: this._cardType.id });
  }
}

export const CreditCardTypeComponent = {
  template,
  controller: CreditCardTypeController,
  bindings: {
    cardTypes: '<',
    onCardTypeSelected: '&',
  },
};
