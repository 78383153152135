import _ from 'underscore';
import * as _l from '@proftit/lodash';
import { switchOnEx } from '@proftit/general-utilities';

import BaseController from '~/source/common/controllers/base';
import RestService from '~/source/common/services/rest';
import ItemLabelStrategy from './item-label-strategy';

import selectTemplate from './select.html';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams, tapLog } from '@proftit/rxjs.adjunct';
import { observeShareCompChange } from '@proftit/rxjs.adjunct.ng1';
import angular, { IRootScopeService, IOnChangesObject } from 'angular';
import { TranslationsProvider } from '~/source/common/providers/translations-provider.component';

const styles = require('./dropdown.component.scss');
const RESULTS_HEADER_ID = generateUuid();
const CHOSEN_EVENT_SHOWING_DROPDOWN = 'chosen:showing_dropdown';
const CHOSEN_EVENT_HIDING_DROPDOWN = 'chosen:hiding_dropdown';
const CHOSEN_UPDATE_RESULTS_CONTENT = 'chosen:update_results_content';
const PRF_CHOSEN_UPDATED = 'prf-chosen-updated';

let instanceCount: number = 0;

export enum PreselectType {
  Classic = 'classic',
  Custom = 'custom',
}

export enum ExcludeBy {
  Code = 'byCode',
  Id = 'id',
  Custom = 'custom',
}

/**
 * create component settings object
 * let the user pass component settings & override default settings easily
 *
 * @param {object} newConfig {controller, template, bindings, etc} merge new settings over default settings
 * @returns {object}
 */
export const DropdownConfig = (newConfig: any) => {
  // default config for component
  const defaultConfig = {
    template: selectTemplate,
    controllerAs: 'vm',
    require: {
      prfTranslationsProvider: '?^',
    },
  };
  // merge default bindings with new bindings
  const bindings = Object.assign(
    {
      model: '=',
      disabled: '<',
      name: '@',
      validations: '@',
      multiple: '@',
      nullable: '<',
      exclude: '<',
      excludeBy: '<',
      excludeFn: '<',
      placeholderTerm: '@',
      countVar: '=',
      enableSelectAll: '@',
      disableSearch: '<',
      isAutoOpen: '<',
      onChange: '&',
      selectDefault: '<',
      selectFirst: '<',
      preselectType: '<',
      preselectFn: '<',
      showResultsHeader: '<',
      resultsHeaderText: '<',
      triggerSelectAllResults: '<',
      triggerSelectOne: '<',
      isItemDisabledFn: '<',
      onAfterFetchData: '&',
      onChangeOfSearchResults: '&',
      onChosenClose: '&',
    },
    newConfig.bindings,
  );

  return Object.assign({}, defaultConfig, newConfig, { bindings });
};

/**
 * component controller that used in components that renders select element
 */
export abstract class DropdownController<
  T extends RestService = RestService
> extends BaseController {
  // must be concatenated (in subclass) to an array containing the data service as the first element
  static $inject = [
    '$translate',
    '$scope',
    '$element',
    '$timeout',
    '$rootScope',
  ];

  styles = styles;
  dropdownId: number;

  // Services
  $translate: angular.translate.ITranslateService;
  $scope: angular.IScope;
  $rootScope: IRootScopeService;
  $element: JQuery;
  $timeout: angular.ITimeoutService;
  dataServiceInst: T;

  _data: any[];
  output: any[];
  selectedValues: any; // value (or values) selected (in select element)
  dataPromise: Promise<void>; // a promise which is resolved when server data is ready
  onDestroy$ = new rx.Subject<void>();

  // Bindings
  model: any;
  name: string; // input name
  validations: string; // validators to use
  exclude: number[]; // ids to exclude
  excludeBy: string; // item equal strategy
  isItemDisabledFn: (item: any) => boolean;
  placeholderTerm: string; // placeholder term to translate
  countVar: number; // if set, this will be set to the number of results of server request
  showResultsHeader: boolean;
  resultsHeaderText: string;
  triggerSelectAllResults: rx.Observable<void>;
  triggerSelectOne: rx.Observable<number>;
  onAfterFetchData: (a: { data: any[] }) => {};
  onChangeOfSearchResults: (a: { results: any[] }) => void;

  excludeFn: (data: any[]) => any[];

  preselectType;
  shouldRenderWithCustomTemplate = false;

  preselectFn: (data) => any;

  /**
   * Optional callback (from binding) to execute on select change with the selected value
   */
  onChange: (obj: { newValue: any }) => void;

  onChosenClose: () => void;

  disabled: boolean;
  multiple: boolean;
  nullable: boolean;
  selectDefault: boolean;
  selectFirst: boolean;
  enableSelectAll: boolean;
  disableSearch: boolean;
  isAutoOpen: boolean;
  chosenEventShowingDropdown;
  chosenEventHidingDropdown;
  chosenEventUpdateResultsContent;
  prfChosenEventUpdated;
  resultsOptions;

  prfTranslationsProvider: TranslationsProvider;

  onChange$ = new rx.Subject<IOnChangesObject>();

  placeholderTermIn$ = observeShareCompChange<string>(
    this.onChange$,
    'placeholderTerm',
  );

  placeholderTranslated$: rx.Observable<string>;

  showSelect$: rx.Observable<boolean>;

  chosenReady: Promise<boolean>;

  /**
   * @param {object} dataService this service has a dynamic name. so we get it through constructor assignment.
   * and it should always be assigned in the first position (in $inject) in all the select popups that use it.
   */
  constructor(dataService: Function | RestService, ...args) {
    super(dataService, ...args);

    if (dataService !== this.$translate) {
      /**
       * First argument ("dataService") is not the translate service. means a real service was passed.
       * Set it as the data service instance.
       * It might be a factory function. in that case, invoke it.
       */
      this.dataServiceInst = this.initDataService(dataService);
    }

    this.chosenReady = new Promise((resolve) => {
      this.$element.on('chosen:ready', (e) => {
        resolve(true);
      });
    });

    this.$element.on('chosen:hiding_dropdown', (e) => {
      this.$timeout(() => {
        this.onChosenClose();
      }, 0);
    });

    useStreams([this.onChange$, this.placeholderTermIn$], this.onDestroy$);
  }

  $onInit() {
    this.preselectType = _l.defaultTo(
      PreselectType.Classic,
      this.preselectType,
    );

    this.excludeBy = _l.defaultTo(ExcludeBy.Id, this.excludeBy);

    this.isItemDisabledFn = _l.defaultTo(() => false, this.isItemDisabledFn);

    this.placeholderTranslated$ = this.streamPlaceholderTranslated();

    this.showSelect$ = this.streamShowSelect();

    useStreams(
      [this.streamSelectOne(), this.streamSelectAllResults()],
      this.onDestroy$,
    );

    instanceCount = instanceCount + 1;
    this.dropdownId = instanceCount;

    if (this.dataServiceInst) {
      if (this.dataServiceInst.setConfig) {
        // verify dataServiceInst is a "restService" and can be configured
        this.dataServiceInst.setConfig({
          blockUiRef: this.getBlockUiRef(),
          growlRef: this.getGrowlRef(),
        });
      }
    }

    /*
     * done in separate function in order
     * to enable overriding
     */
    this.init();

    // filter data on every change of "exclude" attribute
    this.$scope.$watch('vm.exclude', () => {
      // only if data has been already fetched then we can filter it
      if (_.isUndefined(this._data)) {
        return;
      }
      // filter  the output
      this.output = this.filterExcluded(this._data);
    });

    this.chosenEventShowingDropdown = (x, extraParams) => {
      if (this.showResultsHeader) {
        const chosenResultsEl = this.$element.find('div.chosen-drop');
        chosenResultsEl.prepend(
          `<div data-prf-id="${RESULTS_HEADER_ID}" class="${this.styles.resultsHeader}">${this.resultsHeaderText}</div>`,
        );
      }
      if (this.shouldRenderWithCustomTemplate) {
        this.renderCustomTemplate(extraParams.chosen.results_data);
      }
    };

    this.chosenEventHidingDropdown = (x) => {
      const displayEl = this.$element.find(
        `div.chosen-drop div[data-prf-id="${RESULTS_HEADER_ID}"]`,
      );
      if (displayEl.length > 0) {
        displayEl.remove();
      }
    };

    this.chosenEventUpdateResultsContent = (evt, data) => {
      this.resultsOptions = data.resultsOptions;
      this.onChangeOfSearchResults({ results: data.resultsOptions });
    };

    this.prfChosenEventUpdated = (e, extraParams) => {
      if (this.shouldRenderWithCustomTemplate) {
        this.renderCustomTemplate(extraParams.chosen.results_data);
      }
    };

    this.$element.on(
      CHOSEN_EVENT_SHOWING_DROPDOWN,
      this.chosenEventShowingDropdown,
    );
    this.$element.on(
      CHOSEN_EVENT_HIDING_DROPDOWN,
      this.chosenEventHidingDropdown,
    );

    this.$element.on(
      CHOSEN_UPDATE_RESULTS_CONTENT,
      this.chosenEventUpdateResultsContent,
    );

    this.$element.on(PRF_CHOSEN_UPDATED, this.prfChosenEventUpdated);
  }

  $onChanges(changes: IOnChangesObject): void {
    super.$onChanges(changes);
    this.onChange$.next(changes);
  }

  $onDestroy() {
    this.onDestroy$.next();

    if (this.chosenEventShowingDropdown) {
      this.$element.off(
        CHOSEN_EVENT_SHOWING_DROPDOWN,
        this.chosenEventShowingDropdown,
      );
    }

    if (this.chosenEventHidingDropdown) {
      this.$element.off(
        CHOSEN_EVENT_HIDING_DROPDOWN,
        this.chosenEventHidingDropdown,
      );
    }

    if (this.chosenEventUpdateResultsContent) {
      this.$element.off(
        CHOSEN_UPDATE_RESULTS_CONTENT,
        this.chosenEventUpdateResultsContent,
      );
    }

    if (this.prfChosenEventUpdated) {
      this.$element.off(PRF_CHOSEN_UPDATED, this.prfChosenEventUpdated);
    }
  }

  renderCustomTemplate(chosenResultsData: any[]) {
    const allRenderedListElements = this.$element.find(
      'ul.chosen-results > li',
    );
    const newListElements = this.buildCustomOptions(
      chosenResultsData,
      allRenderedListElements,
    );
    this.appendCustomOptions(newListElements);
  }

  streamPlaceholderTranslated() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest({
          term: this.placeholderTermIn$,
          translationInfo: this.prfTranslationsProvider
            ? this.prfTranslationsProvider.transaltionsTable$
            : rx.obs.of({}),
        }),
      rx.startWith({ term: this.defaultPlaceholder }),
      rx.map(({ term }) => term),
      rx.map((term) => (_l.isNil(term) ? this.defaultPlaceholder : term)),
      rx.switchMap((term) => {
        if (_l.isNil(term)) {
          return rx.obs.of(this.defaultPlaceholder);
        }

        return this.$translate(term).catch(
          () => this.defaultPlaceholder,
        ) as Promise<string>;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamShowSelect(): rx.Observable<boolean> {
    return rx.pipe(
      () => rx.obs.combineLatest([this.placeholderTranslated$]),
      rx.switchMap(() => {
        return rx.obs.of(true).pipe(rx.delay(0), rx.startWith(false));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectAllResults() {
    return rx.pipe(
      () => this.triggerSelectAllResults || new rx.Subject<void>(),
      rx.map(() => this.resultsOptions),
      rx.map((resultsOptions) =>
        resultsOptions.map((o) =>
          o.value === 'null' ? null : parseInt(o.value, 10),
        ),
      ),
      rx.map((resultsIds) => {
        return this.output.filter((o) => {
          return resultsIds.includes(o.id);
        });
      }),
      rx.delay(1),
      rx.tap((selection) => {
        this.$timeout(() => {
          this.model = [...selection];
          this.selectedValues = [...selection];
        }, 14);
      }),
      rx.delay(1),
    )(null);
  }

  streamSelectOne() {
    return rx.pipe(
      () => _l.defaultTo(new rx.Subject<number>(), this.triggerSelectOne),
      rx.map((targetSelection) => {
        return this.output.filter((o) => {
          return o.id === targetSelection;
        });
      }),
      rx.delay(1),
      rx.tap((selection) => {
        if (_l.isNil(selection) || _.isEmpty(selection)) {
          return;
        }

        this.$timeout(() => {
          this.model = this.multiple ? [...selection] : selection[0];
          this.selectedValues = [...selection];
        }, 14);
      }),
      rx.delay(1),
    )(null);
  }

  getBlockUiRef() {
    return `dropdown_${this.dropdownId}`;
  }

  getGrowlRef() {
    return 'restService';
  }

  initDataService(dataService) {
    const dataServiceInstance = _.isFunction(dataService)
      ? dataService()
      : dataService;
    return dataServiceInstance;
  }

  /**
   * Called on ng-change event for the select.
   * Calls the "onChange" method with the new selected value as "newValue" (if method exists)
   * @return {void}
   */
  onSelectChange(): void {
    if (typeof this.onChange !== 'function') {
      return;
    }

    /*
     * use timeout to make sure $digest'ing has finished. otherwise, it will call onChange before
     * the model has updated in the target scope (inside the onChange)
     */
    this.$timeout(() => {
      // the new value will be available as "newValue" inside the template calling onChange
      this.onChange({ newValue: this.model });
    });
  }

  init(): void {
    // fetch data
    this.fetchData();
  }

  /**
   * Returns true if the "select all" button should be enabled
   * @return {boolean}
   */
  isSelectAllEnabled(): boolean {
    return (
      !this.disabled &&
      this.enableSelectAll &&
      this.output &&
      this.output.length > 0
    );
  }

  /**
   * Runs the query method to fetch data from server.
   * Calls the onDataReady and filterExcluded to prepare the output.
   * Opens the dropdown if autoOpen flag is on
   * @return {Promise<void>} resolves when data is ready
   */
  fetchData(): Promise<void> {
    // get data from server that eventually will fill the select
    this.dataPromise = this.query()
      /*
       * This code fix a race condition when data is coming from cache. Chosen anguar directive
       * does not detect data is changed and does not trigger chosen:updated for auto open to receive.
       */
      .then((data) => this.chosenReady.then(() => data))
      .then((data) => {
        // if the count attribute was set to the directive, add the data count
        if (!_.isUndefined(this.countVar)) {
          this.countVar = data.length;
        }
        this._data = data;
      })
      .then(() => this.onDataReady(this._data))
      .then((output) => {
        this._data = output;

        this.output = this.filterExcluded(this._data);

        if (this.isAutoOpen) {
          this.autoOpen();
        }
      })
      .then(() => {
        this.onAfterFetchData({ data: this._data });
      });

    return this.dataPromise;
  }

  /**
   * Use this method to customize elements for "chosen" to display. This method is meant to be overridden by child components.
   * @param chosenResultsData - an array of data for each item "chosen" holds.
   * @param jqListElements - all the &lt;li&gt; elements rendered inside "chosen" items container. These are the items that are normally rendered (without any customization).
   */
  buildCustomOptions(chosenResultsData: any[], jqListElements: JQuery): any[] {
    return [];
  }

  appendCustomOptions(newListElements) {
    const jqChosenResults = $('ul.chosen-results');
    jqChosenResults.html('');
    jqChosenResults.append(newListElements);
  }

  /**
   * Wait until data has finished loading and chosen is updated,
   * then open the select.
   */
  autoOpen(): void {
    this.dataPromise.then(() => {
      // get the select elements as all the chosen events are bound to it
      const select = this.$element.find('select');
      // every time chosen is updated, open it (unless it is currently loading)
      select.on('chosen:updated', () => {
        if (!select.hasClass('loading')) {
          select.trigger('chosen:open');
        }
      });
    });
  }

  selectAllItems(): void {
    this.dataPromise.then(() => {
      /*
       * must be done with timeout! if not - the chosen closes its dropdown after this code
       * and run over the ng-model with an empty object instead the full one
       */
      this.$timeout(() => {
        // slice() operation clones the array and returns the reference to the new array
        this.model = this.output.slice();
        this.selectedValues = this.model.slice();
      });
    });
  }

  deselectAllItems(): void {
    this.dataPromise.then(() => {
      /*
       * must be done with timeout! if not - the chosen closes its dropdown after this code
       * and run over the ng-model
       */
      this.$timeout(() => {
        this.model = [];
        this.selectedValues = [];
      });
    });
  }

  query(): Promise<any> {
    return this.dataServiceInst.getListWithQuery<any>();
  }

  /**
   * Filters data collection entities that appears at "exclude" array
   *
   * @param {object} data
   * @returns {any[]} - filtered data array
   */
  filterExcluded(data: any[]) {
    let resultData = data;

    if (this.excludeBy === ExcludeBy.Custom) {
      resultData = this.excludeFn(data);
    } else {
      // if 'exclude' model was specified, exclude this model from the data
      if (!_.isEmpty(this.exclude)) {
        const itemEqualFn = this.calcItemEqualStrategy(this.excludeBy);
        resultData = resultData.filter(
          (dataItem) =>
            !_l.find(
              (excludeValue) => itemEqualFn(dataItem, excludeValue),
              this.exclude,
            ),
        );
      }
    }
    return resultData;
  }

  /**
   * Factory for item eual strategy. To be able to filter item also based on
   * 'code' on top of 'id'.
   *
   * @param {string} strategy - null, byCode
   * @return {function} item equal function
   */
  calcItemEqualStrategy(strategy: string) {
    if (strategy === ExcludeBy.Code) {
      return (item, code) => item.code === code;
    }

    return (item, id) => item.id === id;
  }

  /**
   * Returns the placeholder (term) which will be used for the select.
   * If the placeholder-term attribute was defined, it will use it. Otherwise, it will use the default placeholder
   * which should be defined in any of the subclasses.
   * @returns {string}
   */
  get placeholder(): string {
    return this.placeholderTerm
      ? this.placeholderTerm
      : this.defaultPlaceholder;
  }

  /**
   * Default placeholder for the select.
   * Used when no placeholder-term attribute was set.
   *
   * @returns {string}
   */
  get defaultPlaceholder(): string {
    return 'common.PLEASE_SELECT';
  }

  /**
   * Called when data has been fetched from server
   *
   * @param {any[]} data
   * @returns {Promise} - promise of output object of options
   * that select element would be rendered  from
   */
  onDataReady(data: any[]): Promise<any[]> {
    if (this.nullable) {
      // add nullable element
      this.addNullableElement();
      /*
       * if select is nullable and the model is undefined,
       * this means that default element is selected
       */
      if (!this.model) {
        this.model = this.multiple ? [] : this.nullableElement;
      }
    }

    this.setSelectedValues();

    this._data.forEach((el) => {
      if (!_l.isObject(el)) {
        return;
      }

      el._isItemDisabled = this.isItemDisabledFn(el);
    });

    if (this.itemLabelStrategy === ItemLabelStrategy.FieldValue) {
      this._data.forEach((el) => {
        el[this.translateDest] = this.calcLabelForItem(el);
      });
      return Promise.resolve(this._data);
    }

    // return non translated output
    if (_.isEmpty(this.translateSource)) {
      if (!_.isEmpty(this.translateDest)) {
        /**
         * do not translate, but add an alias for the name attribute as the translated dest key.
         * This way in the template we can always show the "translated" key without "if"s
         */
        this._data.forEach((el) => {
          el[this.translateDest] = el.name;
        });
      }
      return Promise.resolve(this._data);
    }
    // Translation source exists. add translation
    return this.addTranslation();
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldTranslation;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.name;
  }

  /**
   * set selected value
   */
  setSelectedValues(): void {
    switchOnEx(
      {
        [PreselectType.Classic]: () => {
          // setting default model value (from api server isDefault field)
          if (this.shouldSelectDefault()) {
            this.model = this._data.find((el) => el.isDefault);
          }

          if (this.shouldSelectFirst()) {
            this.model = this._data[0];
          }
        },
        [PreselectType.Custom]: () => {
          this.model = this.preselectFn(this._data);
        },
      },
      this.preselectType,
    );

    /*
     * setting selected values to model (selected values are different models which come from server,
     * so we need to translate them as well)
     */
    this.selectedValues = this.model;
    // if multiple select - selected elements should be an array
    if (_.isUndefined(this.multiple) && !_.isEmpty(this.selectedValues)) {
      this.selectedValues = [this.selectedValues];

      // Map each selected value to its corresponding object in the data received from server
      this.selectedValues = this.selectedValues.map(
        (value: { id: string | number; [key: string]: any }) =>
          this._data.find((el) => this.compareItemsForSelected(el, value)),
      );
    }
  }

  compareItemsForSelected(itemA: any, itemB: any) {
    return itemA.id === itemB.id;
  }

  /**
   * should the select look for default value in api results
   * set default value when:
   * 1. 'selectDefault' property is set to true (by binding)
   * 2. the model is empty (no selected value is provided)
   * 3. it's a single mode select
   * 4. isDefault property is defined in api results
   * @returns {boolean} true if default should be selected
   */
  shouldSelectDefault(): boolean {
    return (
      this.selectDefault &&
      _.isEmpty(this.model) &&
      _.isUndefined(this.multiple) &&
      this._data[0] &&
      this._data[0].isDefault !== undefined
    );
  }

  /**
   * should the select look for first value in api results
   * set default value when:
   * 1. 'selectFirst' property is set to true (by binding)
   * 2. the model is empty (no selected value is provided)
   * 3. it's a single mode select
   * @returns {boolean} true if default should be selected
   */
  shouldSelectFirst(): boolean {
    return (
      this.selectFirst &&
      _.isEmpty(this.model) &&
      _.isUndefined(this.multiple) &&
      this._data[0]
    );
  }

  /**
   * Adds nullable element to the data the received from server
   *
   * @returns {DropdownController}
   */
  addNullableElement(): DropdownController {
    this._data.unshift(this.nullableElement);
    return this;
  }

  /**
   * Translates data that came from server
   *
   * @private
   * @returns {Promise} promise which resolves to an array of translated values
   */
  addTranslation(): Promise<any[]> {
    let sources: string[] = _.pluck(this._data, this.translateSource);
    /*
     * translations are in lower case, so we need
     * to convert the data
     */
    sources = sources.map(
      (source) => `${this.translationPath}.${source.toUpperCase()}`,
    );

    return (this.$translate(sources) as Promise<Record<string, string>>).then(
      (translatedSources) => {
        if (!_.isEmpty(this.selectedValues)) {
          // translate selected elements
          this.setTranslation(
            this.translateSource,
            this.translateDest,
            translatedSources,
            this.selectedValues,
          );
        }

        // add translation property to all elements and return the output
        return this.setTranslation(
          this.translateSource,
          this.translateDest,
          translatedSources,
          this._data,
        );
      },
    );
  }

  /**
   * Used if select is nullable, returns nullable entity
   *
   * If supports nullable, must be overridden
   *
   * @returns {Object}
   */
  get nullableElement(): any {
    return {};
  }

  /**
   * Path to translations on lang.json file
   * Must be overridden
   *
   * @returns {String}
   */
  get translationPath(): string {
    return '';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource(): string {
    return 'code';
  }

  /**
   * Name of the property that will hold the translation
   *
   * @returns {string}
   */
  get translateDest(): string {
    return 'translatedName';
  }

  /**
   * Set translated string from the source property of the data to
   * destination property
   *
   * @param {string} source - field to translate from source (e.g. 'code')
   * @param {string} dest - destination field for the translated string (in output) (e.g. 'translatedName')
   * @param {object} translations - as received from $translate service
   * @param {object} data - data array to add translations for (received from server query)
   * @returns {object} - modified data, with translation of source property inserted into destination
   * property
   */
  setTranslation(
    source: string,
    dest: string,
    translations: { [key: string]: string },
    data: any[],
  ) {
    const dataAr = Array.isArray(data) ? data : [data];

    data.forEach((element) => {
      Object.assign(
        element,
        _.object(
          [dest],
          [
            translations[
              `${this.translationPath}.${element[source].toUpperCase()}`
            ],
          ],
        ),
      );
    });
    return data;
  }

  /**
   * Calculate classes for option item.
   *
   * Allow override in child component.
   *
   * @param item
   * @return classes as list of string;
   */
  calcClassesForOption(item): string[] {
    return [];
  }
}

export default {
  config: DropdownConfig,
  controller: DropdownController,
};
