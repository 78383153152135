import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'clearing';

export default class ProviderClearingCompaniesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  get accessType() {
    return 'provider';
  }
}
