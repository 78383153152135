import * as _ from '@proftit/lodash';
import angular, { ISCEService } from 'angular';

import Controller from '~/source/common/controllers/base';

import template from './iframe-loader.html';

const config = (newConfig) => {
  const defaultConfig = {
    template,
    controllerAs: 'vm',
  };

  // merge default bindings with new bindings
  const bindings = Object.assign(
    {
      url: '@',
      class: '@attrClass',
      id: '@attrId',
      onReady: '&',
    },
    newConfig.bindings,
  );

  return Object.assign({}, defaultConfig, newConfig, { bindings });
};

class IframeLoaderController extends Controller {
  // must be concatenated (in subclass) to an array
  // containing the data service as the first element
  static $inject = ['$sce', '$scope', '$element'];

  iframe: any;
  $element: any;
  $sce: ISCEService;

  url: string;
  onReady: () => void;

  constructor(...args) {
    super(...args);
  }

  $onInit() {
    Object.assign(this, {
      embedUrl: this.$sce.trustAsResourceUrl(this.url),
      iframe: this.$element.find('iframe'),
    });
    // bind method to iframe "load" event
    this.iframe.on('load', () => this.onIframeLoaded());
  }

  /**
   * Called on iframe loading completed
   */
  onIframeLoaded(...args) {
    if (_.isFunction(this.onReady)) {
      this.onReady();
    }
  }
}

export const IframeLoaderComponent = config({
  controller: IframeLoaderController,
});

export default {
  IframeLoaderController,
  config,
};
