import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { StateService } from '@uirouter/core';
import { format } from 'date-fns';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { TasksNavbarViewOption } from '../prf-tasks-navbar/tasks-navbar-view-option';
import template from './prf-my-tasks-dashboard.component.html';
import moment from 'moment-timezone';
import { DateRange, DateRangeType } from '@proftit/utils-datetime';

const styles = require('./prf-my-tasks-dashboard.component.scss');

export class MyTasksDashboardController {
  styles = styles;
  onDone: () => void;

  TasksNavbarViewOption = TasksNavbarViewOption;

  viewOption = TasksNavbarViewOption.Calendar;

  daterangeType$ = new rx.BehaviorSubject<DateRangeType>(DateRangeType.Day);

  selectedDateRange$ = new rx.BehaviorSubject<DateRange>(null);

  dateText$ = rx.obs
    .combineLatest([this.selectedDateRange$, this.daterangeType$])
    .pipe(
      rx.map(([dateRange, daterangeType]) => {
        if (_.isNil(dateRange)) {
          return '';
        }

        if (daterangeType === DateRangeType.Day) {
          return format(dateRange.start, 'MMM dd yyy');
        }

        return format(dateRange.start, 'MMM yyy');
      }),
      shareReplayRefOne(),
    );

  /* @ngInject */
  constructor(
    readonly popupService: PopupService,
    readonly $state: StateService,
  ) {}

  openNewCrudTaskPopup(dueDate: Date = null) {
    this.popupService.open({
      component: 'prfCrudTaskPopup',
      resolve: {
        dueDate: () => dueDate,
        action: () => 'create',
      },
    });
  }

  openEditCrudTaskPopup(id: number) {
    this.popupService.open({
      component: 'prfCrudTaskPopup',
      resolve: {
        id,
        action: () => 'update',
      },
    });
  }

  goToCustomerPage(id: number) {
    this.$state.go('crm.contacts.view', { id });
    this.onDone();
  }
}

export const MyTasksDashboardComponent = {
  template,
  controller: MyTasksDashboardController,
  bindings: {
    onDone: '&',
  },
};
