import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';
import { generateSimpleMessage } from './validation-message-generators/generate-simple-message';

export class ProxyFormValidationMessageGeneratorService {
  static $inject = [];

  static $require = {
    prfProxyFormValidationMessageGenerator: '?^^',
  };

  onDestroy$ = new rx.Subject<void>();

  generator = generateSimpleMessage;

  parentMessageGenerator$ = new rx.BehaviorSubject<
    ProxyFormValidationMessageGeneratorService
  >(null);

  constructor(requiredCtrls) {
    const parentMessageGenerator$: rx.Observable<ProxyFormValidationMessageGeneratorService> = _.defaultTo(
      rx.obs.of(null),
      _.get(['item$'], requiredCtrls.prfProxyFormValidationMessageGenerator),
    );

    useStreams(
      [
        parentMessageGenerator$.pipe(
          rx.map((service) => this.parentMessageGenerator$.next(service)),
        ),
      ],
      this.onDestroy$,
    );
  }

  generate(messageInfo) {
    return rx.pipe(
      () => rx.obs.from(this.generator(messageInfo)),
      rx.withLatestFrom(this.parentMessageGenerator$),
      rx.switchMap(([message, parentMessageGenerator]) => {
        if (!_.isNil(message)) {
          return rx.obs.of(message);
        }

        if (_.isNil(parentMessageGenerator)) {
          return rx.obs.of(null);
        }

        return parentMessageGenerator.generate(messageInfo);
      }),
    )(null);
  }

  setGenerator(generator) {
    this.generator = generator;
  }
}
