import commonModule from '../index';

function rangeFormat($filter, appConfig) {
  /**
   * Format numeric range
   * If "monetary" is true, also pass the numbers through the "currency" filter to add the base currency sign
   *
   * @example: {minNumber: 5, maxNumber: 10} -> "5-10"
   * @example: {minNumber: 5} -> ">=5"
   * @example: {maxNumber: 10} -> "<=10"
   * @example: {minNumber: 5, maxNumber: 5} -> "5"
   */
  return ({ minNumber, maxNumber }, monetary, digits) => {
    const formatNum = (amount) =>
      monetary
        ? $filter('currency')(amount, appConfig.baseCurrency.sign, digits)
        : amount;

    const minNumberFormatted = formatNum(minNumber);
    const maxNumberFormatted = formatNum(maxNumber);

    // same number. display just one of them
    if (minNumber === maxNumber) {
      return maxNumberFormatted;
    }

    // Only max defined
    if (minNumber === undefined && maxNumber !== undefined) {
      return `≤${maxNumberFormatted}`;
    }

    // Only min defined
    if (maxNumber === undefined && minNumber !== undefined) {
      return `≥${minNumberFormatted}`;
    }

    // Both min and max are defined
    return `${minNumberFormatted} - ${maxNumberFormatted}`;
  };
}

rangeFormat.$inject = ['$filter', 'appConfig'];

commonModule.filter('rangeFormat', rangeFormat);
