import angular from 'angular';
import CreditCardDepositComponent from './creditcard-deposit/creditcard-deposit.component';
import CreditCardDepositFormComponent from './creditcard-deposit-form/creditcard-deposit-form.component';
import ManualDepositComponent from './manual/manual-deposit.component';
import WireRequestComponent from './wire/wire-request.component';
import ConfirmDepositPopupController from './confirm-deposit-popup/confirm-deposit-popup.controller';
import DepositAmountInputComponent from './deposit-amount-input/deposit-amount-input.component';
import { AddTransactionPopupComponent } from './add-transaction-popup/add-transaction-popup.component';
import DepositTablePopupComponent from './deposit-table-popup/deposit-table-popup';
import DepositTableComponent from './deposits-table/deposits-table.component';
import DepositDetailsComponent from './deposit-details/deposit-details.component';
import depositsSettings from './deposits-settings.json';
import PraxisDepositFormComponent from './praxis-deposit-form';
import { AddTransactionExternalCashierComponent } from './add-transaction-external-cashier/add-transaction-external-cashier.component';
import { UnsupportedExternalCashierCreditDepositComponent } from './unsupported-external-cashier-credit-deposit/unsupported-external-cashier-credit-deposit.component';
import { DepositLinkDocumentPopupComponent } from './deposit-link-document-popup/deposit-link-document-popup.component';
import { DepositAttachDocumentButtonComponent } from '~/source/contact/contact-page/trading-account/common/deposit/deposit-attach-document-button/deposit-attach-document-button.component';
import { DepositLinkedDocumentsPopupComponent } from '~/source/contact/contact-page/trading-account/common/deposit/deposit-linked-documents-popup/deposit-linked-documents-popup.component';
import { ClearingCompanyImageSelectorComponent } from '~/source/contact/contact-page/trading-account/common/deposit/clearing-company-image-selector/clearing-company-image-selector.component';
import { OobitDepositCompleteComponent } from '~/source/contact/contact-page/trading-account/common/deposit/oobit-deposit-complete/oobit-deposit-complete.component';
import { DepositTooltipDataComponent } from '~/source/contact/contact-page/trading-account/common/deposit/deposit-tooltip-data/deposit-tooltip-data.component';
import { CryptoDepositComponent } from '~/source/contact/contact-page/trading-account/common/deposit/crypto-deposit/crypto-deposit.component';
import { ConfirmEwalletDepositPopupComponent } from '~/source/contact/contact-page/trading-account/common/deposit/confirm-ewallet-deposit-popup/confirm-ewallet-deposit-popup.component';
import { CreditCardTypeComponent } from './creditcard-type/creditcard-type.component';

import filterPopoverComponent from '~/source/common/components/table-filters/filter-popover/filter-popover.component';
export default angular
  .module('crm.contact.tradingAccount.deposit', [])
  .constant('depositsSettings', depositsSettings)
  .component('prfCreditCardDeposit', CreditCardDepositComponent)
  .component('prfDepositTooltipData', DepositTooltipDataComponent)
  .component('prfCreditCardDepositForm', CreditCardDepositFormComponent)
  .component('prfDepositAmountInput', DepositAmountInputComponent)
  .component('prfDepositsTable', DepositTableComponent as any)
  .component('prfDepositsDetails', DepositDetailsComponent as any)
  .component('prfManualDeposit', ManualDepositComponent)
  .component('prfWireRequest', WireRequestComponent)
  .component('prfPraxisDepositForm', PraxisDepositFormComponent as any)
  .component(
    'prfAddTransactionExternalCashier',
    AddTransactionExternalCashierComponent,
  )
  .component('prfAddTransactionPopup', AddTransactionPopupComponent)
  .component('prfDepositTablePopup', DepositTablePopupComponent)
  .component('prfDepositLinkDocumentPopup', DepositLinkDocumentPopupComponent)
  .component(
    'prfDepositLinkedDocumentsPopup',
    DepositLinkedDocumentsPopupComponent,
  )
  .component(
    'prfDepositAttachDocumentButton',
    DepositAttachDocumentButtonComponent,
  )
  .component(
    'prfUnsupportedExternalCashierCreditDeposit',
    UnsupportedExternalCashierCreditDepositComponent,
  )
  .component(
    'prfClearingCompanyImageSelector',
    ClearingCompanyImageSelectorComponent,
  )
  .component('prfOobitDepositComplete', OobitDepositCompleteComponent)
  .component('prfCryptoDeposit', CryptoDepositComponent)
  .component(
    'prfConfirmEwalletDepositPopup',
    ConfirmEwalletDepositPopupComponent,
  )
  .component('prfCreditCardType', CreditCardTypeComponent)
  .controller('ConfirmDepositPopupController', ConfirmDepositPopupController)
  .name;
