import template from './login-history.html';
import loginHistorySettings from './login-history-settings.json';
import TableController from '~/source/common/components/table/table.controller';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';

class Controller extends TableController {
  static $inject = [
    '$scope',
    'popupService',
    'customersService',
    ...TableController.$inject,
  ];

  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  popupService: PopupService;

  customer: Customer;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      excludeNotifications: [],
      settings: { ...loginHistorySettings },
      dataServiceInstance: this.customersService(),
      cols: [...loginHistorySettings.tableColumns],
    });

    this.initTable();
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getLoginHistoryRequestsResource(this.customer.id)
      .setConfig({ blockUiRef: 'loginHistoryTable' })
      .sort({ timestamp: 'desc' });
  }

  /**
   * Opens activity logs table popup
   * @returns {void}
   *
   */
  openLoginHistoryTablePopup() {
    // open activity logs table popup
    this.popupService.open({
      controller: 'LoginHistoryPopupController',
      template: tablePopupTemplate,
      scope: this.$scope,
      data: {
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
  },
};
