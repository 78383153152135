export const mtTableColumns = [
  {
    title: 'common.MODIFIED_ON',
    field: 'tradingCoreAuditLogTime',
    id: 'time',
    fieldFormat: '{{ dateTime | amDateFormat:"L LTS" }}',
    sortable: 'dateTime',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'tradingCoreBrandGroupsAuditLogTable.GROUP',
    field: 'tradingCoreAuditLogGroups',
    id: 'group',
    fieldFormat: '{{ _groupName }}',
    sortable: false,
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'tradingCoreBrandAssetsAuditLogTable.FIELDS',
    field: '',
    id: 'fields',
    fieldFormat: '{{ _requestBodyForPresentation }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'tradingCoreBrandAssetsAuditLogTable.IP',
    field: 'ip',
    fieldFormat: '{{ ip }}',
    sortable: 'ip',
    show: true,
    filterable: false,
    removable: false,
  },
];
