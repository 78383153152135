import _ from 'underscore';
import * as rx from '@proftit/rxjs';
import * as _l from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import { Brand, MappedDids } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

import template from './voip-data.html';
import { Permissions } from '~/source/common/models/permission-structure';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import BrandsService from '~/source/management/brand/services/brands';
import { VoipRegion } from '~/source/management/brand/components/voip/voip-providers-list/voip-providers-list.component';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CrmAppStoreProviderController } from '../../../../app/cfm-app-store-provider.component';

export class VoipDataController extends BaseController {
  prfCrmAppStoreProvider: CrmAppStoreProviderController;
  brand: IElementRestNg<Brand>;
  Permissions = Permissions;

  lifecycles = observeComponentLifecycles(this);

  blockUiRef = generateBlockuiId();
  growlRef = generateGrowlId();

  regionCancelAction: rx.Subject<VoipRegion>;
  onProviderChangeEvent: () => void;

  region: any;
  previousRegionState: any;
  voipProvider: any;
  voipCredentials: any;
  credentialsObject: any;
  reloadProviders: () => void;

  voipProvider$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'voipProvider',
  );

  /*@ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    super();
    useStreams(
      [this.voipProvider$, this.streamMergedVoipObjectOnVoipProviderChange()],
      this.lifecycles.onDestroy$,
    );
    this.previousRegionState = { ...this.region };
  }

  $onInit() {}

  $onDestroy() {}

  handleUpdateFromReact = (dataFromReact: MappedDids[]) => {
    this.region.mappedDids = dataFromReact.filter(
      (d) => d.dids && d.dids.length > 0,
    );
  };

  streamMergedVoipObjectOnVoipProviderChange() {
    return rx.pipe(
      () => this.voipProvider$,
      rx.tap(() => {
        this.setMergedVoipObject();
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Creating new object combining 'voipProvider credentials' and 'voipProvider.provider fields'
   *
   * @returns {void}
   */
  setMergedVoipObject() {
    if (_l.isNil(this.voipProvider)) {
      return;
    }
    const credentials = this.voipCredentials;
    this.credentialsObject = {};

    if (_.isEmpty(credentials)) {
      return;
    }
    credentials.forEach((credItem) => {
      this.credentialsObject[credItem.key] = credItem ? credItem.value : '';
    });
  }

  /**
   * Enter edit mode:
   * Save current voipProvider model state so we could restore them if the user chooses to cancel
   *
   * @returns {void}
   */
  enterEdit() {
    // Enter edit mode
    this.previousRegionState = _l.cloneDeep(this.region);
    this.region.isInEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state, or remove the model if it didn't exist before
   *
   * @returns {void}
   */
  cancelEdit() {
    // Exit edit mode
    Object.assign(this.region, this.previousRegionState);
    this.onProviderChangeEvent();
    this.regionCancelAction.next(this.region);
  }

  /**
   * This method will set voipCredentials as key value pairs (prepare for server)
   *
   * @returns {Object} - array of {key, value} pairs
   */
  setVoipCredentialsInArray() {
    if (_l.isNil(this.voipProvider)) {
      return null;
    }

    if (_.isEmpty(this.credentialsObject)) {
      return null;
    }
    return this.voipProvider.fields.map((fieldItem) => ({
      key: fieldItem.code,
      value: this.credentialsObject[fieldItem.code],
    }));
  }

  /**
   * Save brand with new voipProvider + voipCredentials (just for 'edit' mode)
   * (create is saved straight from /create.js)
   *
   * @returns {Promise} resolves to the updated brand record on success
   */
  save() {
    const voipProviderId = this.region.voipProvider.id;
    const isDefault = this.region.isDefault;
    const shouldShowVoip = this.region.shouldShowVoip;
    const voipCredentials = this.setVoipCredentialsInArray();

    if (!this.region.isNewRegion) {
      return this.patchVoipConfiguration(
        this.brand.id,
        voipProviderId,
        voipCredentials,
        this.region.voipCredentialsId,
        shouldShowVoip,
        isDefault,
        this.region.mappedDids,
      ).then(() => {
        this.reloadProviders();
      });
    }

    return this.postNewVoipConfiguration(
      this.brand.id,
      voipProviderId,
      voipCredentials,
      shouldShowVoip,
      isDefault,
    ).then(() => {
      this.reloadProviders();
    });
  }

  postNewVoipConfiguration(
    brandId: number,
    voipProviderId: number,
    voipCredentials: any[],
    shouldShowVoip: boolean,
    isDefault: boolean,
  ) {
    return this.brandsService()
      .setConfig({
        blockUiRef: this.blockUiRef,
        growlRef: this.growlRef,
        errorsTranslationPath: 'communications',
      })
      .addVoipProvider(
        this.brand.id,
        voipProviderId,
        voipCredentials,
        shouldShowVoip,
        isDefault,
      )
      .then((updatedBrand) => {
        // change to display mode
        this.region.isInEdit = false;
        this.region.isNewRegion = false;

        return updatedBrand;
      });
  }

  patchVoipConfiguration(
    brandId: number,
    voipProviderId: number,
    voipCredentials: any[],
    voipCredentialsId: number,
    shouldShowVoip: boolean,
    isDefault: boolean,
    mappedDids: MappedDids[],
  ) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiRef, growlRef: this.growlRef })
      .updateVoipProvider(
        this.brand.id,
        voipProviderId,
        voipCredentialsId,
        voipCredentials,
        shouldShowVoip,
        isDefault,
        mappedDids,
      )
      .then((updatedBrand) => {
        // change to display mode
        this.region.isInEdit = false;
        this.region.isNewRegion = false;

        return updatedBrand;
      });
  }
}

const VoipDataComponent = {
  template,
  controller: VoipDataController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    voipProvider: '<',
    voipCredentials: '<',
    regionCancelAction: '<',
    unusedVoipProviders: '=',
    region: '=',
    onProviderChangeEvent: '&',
    reloadProviders: '&',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};

export default VoipDataComponent;
