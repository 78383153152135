import ng from 'angular';
import { react2angular } from 'react2angular';
import { AssignmentPanelTableComposite } from '@proftit/crm-app-assignment-panel';
import { autoAssignmentRouting } from './auto-assignment.routing';
import { AutoAssignmentPanelDashboardComponent } from './components/auto-assignment-panel-dashboard/auto-assignment-panel-dashboard.component';
import { CustomerAssignmentLogsTablePopupComponent } from './components/customer-assignment-logs-table-popup/customer-assignment-logs-table-popup.component';

const dependencies = [];

export const AutoAssignmentModule = ng.module(
  'prf.auto_assignment',
  dependencies,
);

const md = AutoAssignmentModule;
md.component(
  'prfAutoAssignmentPanelDashboardBase',
  AutoAssignmentPanelDashboardComponent,
);
md.component('prfAutoAssignmentPanelDashboard', {
  ...AutoAssignmentPanelDashboardComponent,
  require: {},
  controller() {},
  template: `
    <prf-auto-assignment-panel-dashboard-base
      prf-current-brand
      prf-current-departments
    />
    `,
});
md.component(
  'prfCustomerAssignmentLogsTablePopup',
  CustomerAssignmentLogsTablePopupComponent,
);

md.component(
  'prfAssignmentPanelTable',
  react2angular(AssignmentPanelTableComposite, [
    'crmApp',
    'translationsProvider',
    'dateRange',
    'searchTerm',
    'brand',
    'department',
    'desk',
    'whenAssignmentClick',
  ]),
);

md.config(autoAssignmentRouting);
