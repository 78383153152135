import humanizeString from 'humanize-string';
import * as _ from '@proftit/lodash';

export function calcInfoForBrandAccountStatusAutomationAuditLog(data) {
  return _.flow([
    (d) => transformMainKeysForAuditLog(d),
    (d) => humanizeItem(d),
    // d => JSON.stringify(d, null, 2)
    (d) => formatStatusData(d),
  ])(data);
}

function humanizeObject(data: Record<string, any>) {
  return _.flow([
    () => _.mapKeys((key) => humanizeString(key), data),
    (d) => _.mapValues((v) => humanizeItem(v), d),
  ])();
}

function humanizeArray(data: any[]) {
  return data.map((i) => humanizeItem(i));
}

function humanizeItem(data) {
  if (_.isArray(data)) {
    return humanizeArray(data);
  }

  if (_.isObject(data)) {
    return humanizeObject(data);
  }

  if (_.isString(data)) {
    return humanizeString(data);
  }

  return data;
}

function transformMainKeysForAuditLog(data) {
  return _.mapKeys((k) => {
    if (k === 'isAccountStatusAutomationActive') {
      return 'Active';
    }

    return k;
  }, data);
}

function formatStatusData(data) {
  return _.flow([
    (d) => _.toPairs(d),
    (pairs) => {
      return pairs.map(
        (p) =>
          `<span><b>${p[0]}:</b></span> <span>${JSON.stringify(p[1])}</span>`,
      );
    },
    (lines) => lines.join(', <br/>'),
  ])(data);
}
