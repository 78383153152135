import ng from 'angular';

import BrandsService from '~/source/management/brand/services/brands';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import {
  Brand,
  UserRolePosition,
  NotificationCommunicationType,
  NotificationSettings,
} from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

import template from './notification-settings.html';
import RolesService from '~/source/management/user/services/roles';
import { NotificationCommunicationTypesService } from '~/source/management/user/services/notification-communication-types.service';
import { NotificationCommunicationTypeCode } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { FormControl } from '@proftit/ng1.reactive-forms';
import * as _ from '@proftit/lodash';
import { NotificationsFieldSettings } from './notifications-field-settings';
import { NotificationSettingTypeStore } from '~/source/common/store-services/notification-setting-types-store.service';

class NotificationsSettingsController {
  fieldSettingsRoleTrackersChangeAction = new rx.Subject<any>();
  fieldSettingsCustomEmailsChangeAction = new rx.Subject<any>();
  fieldSettingsIsActiveChangeAction = new rx.Subject<any>();
  fieldSettingsValueChangeAction = new rx.Subject<any>();
  roleTrackers$ = new rx.BehaviorSubject<any[]>([]);
  emailTrackers$ = new rx.BehaviorSubject<any[]>([]);
  submitFormAction = new rx.Subject<void>();
  opCancelAction = new rx.Subject<void>();
  afterSaveAction = new rx.Subject<any>();

  brandsServiceInst: BrandsService;
  brand: Brand;
  modelFromServer: { [key: string]: NotificationSettings };
  isEdit: boolean;
  notificationSettingsModel: any;
  prevAttributes: any;
  lifecycles = observeComponentLifecycles(this);
  roles: UserRolePosition[];
  NotificationCommunicationTypeCode = NotificationCommunicationTypeCode;
  notificationCommunicationTypes: NotificationCommunicationType[];
  selectedBrand = new FormControl<Brand>(null);
  relevantPlatformSettings$ = this.streamRelevantPlatformSettings();
  combineNotificationSettings$ = this.streamCombineNotificationSettings();
  roles$ = this.streamRoles();
  notificationCommunicationTypes$ = this.streamNotificationCommunicationTypes();
  normalizedNotificationsSettingsForUpdate$ = this.streamNormalizedNotificationsSettingsForUpdate();

  /* @ngInject */
  constructor(
    readonly $scope: ng.IScope,
    readonly growl: ng.growl.IGrowlService,
    readonly brandsService: () => BrandsService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly $state: ng.ui.IStateService,
    readonly rolesService: () => RolesService,
    readonly notificationCommunicationTypesService: () => NotificationCommunicationTypesService,
    readonly prfNotificationSettingTypeStore: NotificationSettingTypeStore,
  ) {
    useStreams(
      [
        this.roleTrackers$,
        this.emailTrackers$,
        this.streamSubmitForm(),
        this.selectedBrand.value$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.brandsServiceInst = this.brandsService();
    this.modelFromServer = {};
    this.isEdit = false;
  }

  $onChanges() {}

  $onDestroy(): void {}

  streamRoles() {
    return rx.pipe(
      () => rx.obs.from(this.rolesService().getVisibleRolesReversed()),
      shareReplayRefOne(),
    )(null);
  }

  streamNotificationCommunicationTypes() {
    return rx.pipe(
      () =>
        rx.obs.from(
          this.notificationCommunicationTypesService().getListWithQuery<
            IElementRestNg<NotificationCommunicationType>
          >(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamCombineNotificationsSettingsAfterSave() {
    return rx.pipe(
      () => this.afterSaveAction,
      rx.map((settingsList) => {
        return _.keyBy((settings) => settings.code, settingsList);
      }),
      rx.withLatestFrom(this.relevantPlatformSettings$),
      rx.map(([notificationSettings, platformSettings]) =>
        this.combineSettings(notificationSettings, platformSettings),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamCombineNotificationSettings() {
    const combineNotificationsSettings$ = new rx.BehaviorSubject(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamCombineNotificationSettingsFromBrand(),
          this.streamCombineNotificationsSettingsFromCancel(
            combineNotificationsSettings$,
          ),
          this.streamCombineNotificationsSettingsAfterSave(),
        ),
      rx.tap((value) => combineNotificationsSettings$.next(value)),
      shareReplayRefOne(),
    )(null);
  }

  streamCombineNotificationsSettingsFromCancel(
    combineNotificationsSettings$: rx.Observable<any>,
  ) {
    return rx.pipe(
      () => this.opCancelAction,
      rx.withLatestFrom(combineNotificationsSettings$),
      rx.map(([action, settings]) => {
        return _.cloneDeep(settings);
      }),
    )(null);
  }

  generateInitialDataFromConfig(config) {
    const {
      category,
      code,
      isActive,
      roleNotifications,
      emailNotifications,
      id = null,
      value = null,
    } = config;

    return {
      category,
      code,
      isActive,
      roleNotifications,
      emailNotifications,
      id,
      value,
    };
  }

  combineSettings(notificationSettings, platformSettings) {
    return this.mapDigObject(platformSettings, 2, (config) => {
      let data = notificationSettings[config.code];
      if (!data) {
        data = this.generateInitialDataFromConfig(config);
      }
      return {
        config,
        data,
      };
    });
  }

  streamCombineNotificationSettingsFromBrand(): rx.Observable<
    NotificationsFieldSettings
  > {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.streamNotificationSettingsFromBrand(),
          this.relevantPlatformSettings$,
        ),
      rx.map(([notificationsSettings, platformSettings]) => {
        return this.combineSettings(notificationsSettings, platformSettings);
      }),
      shareReplayRefOne(),
    )(null);
  }

  mapDigObject(obj, level: number, workFn) {
    if (level <= 0) {
      return workFn(obj);
    }
    const entries = _.toPairs(obj);
    const mapResult = entries.map((entry) => {
      const [key, value] = entry;
      return [key, this.mapDigObject(value, level - 1, workFn)];
    });
    return _.fromPairs(mapResult);
  }

  streamNotificationSettingsFromBrand() {
    return rx.pipe(
      () => this.selectedBrand.value$ as rx.Observable<Brand>,
      rx.filter((brand) => !!brand),
      rx.switchMap((brand) => {
        return this.brandsServiceInst
          .getNotificationsResource(brand.id)
          .setConfig({
            growlRef: 'notificationsForm',
            blockUiRef: 'notificationsForm',
          })
          .embed(['roleNotifications', 'emailNotifications'])
          .getListWithQuery<IElementRestNg<NotificationSettings>>();
      }),
      rx.map((data) => data.plain()),
      rx.map((settingsList) => {
        return _.keyBy((settings) => settings.code, settingsList);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRelevantPlatformSettings() {
    return rx.pipe(
      () => this.selectedBrand.value$ as rx.Observable<Brand>,
      rx.filter((brand) => !!brand),
      rx.map((brand) => _.get(['platformType', 'code'], brand)),
      rx.switchMap((platformCode) =>
        this.prfNotificationSettingTypeStore.getByPlatformAndGeneralGroupByCategorySortedbyUi(
          platformCode,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSubmitForm() {
    return rx.pipe(
      () => this.submitFormAction,
      rx.withLatestFrom(
        this.normalizedNotificationsSettingsForUpdate$,
        this.selectedBrand.value$ as rx.Observable<Brand>,
      ),
      rx.switchMap(([a, normalized, brand]) => {
        return this.brandsServiceInst
          .setConfig({
            growlRef: 'notificationsForm',
            blockUiRef: 'notificationsForm',
          })
          .embed(['roleNotifications', 'emailNotifications'])
          .updateNotifications(brand.id, normalized);
      }),
      rx.tap((newSettings) => {
        this.growl.success('notifications.SETTINGS_UPDATE_SUCCESS', {
          referenceId: 'notificationsForm' as any,
        });
        this.isEdit = false;

        this.afterSaveAction.next(newSettings.plain());
      }),
      rx.catchError((err, caughtObservable) => caughtObservable),
    )(null);
  }

  streamNormalizedNotificationsSettingsForUpdate() {
    return rx.pipe(
      () => this.streamNotificationsSettingsForUpdate(),
      rx.map((model) => {
        const newNotificationsSettings = [];
        this.mapDigObject(model, 2, ({ config, data }) => {
          newNotificationsSettings.push(data);
        });
        return newNotificationsSettings;
      }),
      rx.map((notificationsSettingsList) => {
        return notificationsSettingsList.map((notificationSettings) => {
          const { emailNotifications } = notificationSettings;
          const validEmailNotifications = emailNotifications.filter(
            (emailObject) => !_.isEmpty(emailObject.email),
          );
          return {
            ...notificationSettings,
            emailNotifications: validEmailNotifications,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNotificationsSettingsForUpdateFromValueChange(notificationsSettings$) {
    return rx.pipe(
      () => this.fieldSettingsValueChangeAction,
      rx.withLatestFrom(notificationsSettings$),
      rx.map(([actionData, originalSettings]) => {
        const { value, fieldSettings } = actionData;
        return this.mapDigObject(originalSettings, 2, (settings) => {
          if (settings.config.code !== fieldSettings.config.code) {
            return settings;
          }

          return {
            ...settings,
            data: {
              ...settings.data,
              value,
            },
          };
        });
      }),
    )(null);
  }

  streamNotificationsSettingsForUpdateFromIsActiveChange(
    notificationsSettings$,
  ) {
    return rx.pipe(
      () => this.fieldSettingsIsActiveChangeAction,
      rx.withLatestFrom(notificationsSettings$),
      rx.map(([actionData, originalSettings]) => {
        const { isActive, fieldSettings } = actionData;
        return this.mapDigObject(originalSettings, 2, (settings) => {
          if (settings.config.code !== fieldSettings.config.code) {
            return settings;
          }
          return {
            ...settings,
            data: {
              ...settings.data,
              isActive,
            },
          };
        });
      }),
    )(null);
  }

  streamNotificationsSettingsForUpdateFromRolesChange(notificationsSettings$) {
    return rx.pipe(
      () => this.fieldSettingsRoleTrackersChangeAction,
      rx.withLatestFrom(notificationsSettings$),
      rx.map(([actionData, originalSettings]) => {
        const { roleTrackers, fieldSettings, communicationType } = actionData;
        const activeRoleTrackers = roleTrackers.filter(
          (trackedItem) => trackedItem.shouldNotify,
        );
        const activeRoles = activeRoleTrackers.map((trackedItem) => {
          return {
            roleId: trackedItem.role.id,
            notificationCommunicationTypeId: communicationType.id,
          };
        });
        return this.mapDigObject(originalSettings, 2, (settings) => {
          if (settings.config.code !== fieldSettings.config.code) {
            return settings;
          }

          const currentState = settings.data.roleNotifications || [];
          const nextState = currentState.filter(
            (stateItem) =>
              stateItem.notificationCommunicationTypeId !==
              communicationType.id,
          );
          const roleNotificationsToSet = [...nextState, ...activeRoles];
          return {
            ...settings,
            data: {
              ...settings.data,
              roleNotifications: roleNotificationsToSet,
            },
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNotificationsSettingsForUpdateFromEmailsChange(notificationsSettings$) {
    return rx.pipe(
      () => this.fieldSettingsCustomEmailsChangeAction,
      rx.withLatestFrom(notificationsSettings$),
      rx.map(([actionData, originalSettings]) => {
        const { emails, fieldSettings } = actionData;
        return this.mapDigObject(originalSettings, 2, (settings) => {
          if (settings.config.code !== fieldSettings.config.code) {
            return settings;
          }
          return {
            ...settings,
            data: {
              ...settings.data,
              emailNotifications: emails,
            },
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNotificationsSettingsForUpdate() {
    const notificationsSettings$ = new rx.BehaviorSubject<object>({});
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.combineNotificationSettings$,
          this.streamNotificationsSettingsForUpdateFromRolesChange(
            notificationsSettings$,
          ),
          this.streamNotificationsSettingsForUpdateFromEmailsChange(
            notificationsSettings$,
          ),
          this.streamNotificationsSettingsForUpdateFromIsActiveChange(
            notificationsSettings$,
          ),
          this.streamNotificationsSettingsForUpdateFromValueChange(
            notificationsSettings$,
          ),
        ),
      rx.tap((result) => notificationsSettings$.next(result)),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Enter edit mode:
   * Save current notification settings model state so we could restore them if the user chooses to cancel
   *
   * @returns {void}
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = ng.copy(this.notificationSettingsModel);
    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   *
   * @returns {void}
   */
  cancelEdit() {
    this.notificationSettingsModel = this.prevAttributes;
    this.isEdit = false;
    this.opCancelAction.next();
  }
}

export default {
  template,
  controller: NotificationsSettingsController,
  controllerAs: 'vm',
};
