const styles = require('./compliance-attachment.component.scss');
import { IScope } from 'angular';
import log from 'loglevel';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import { Attachment } from '~/source/common/models/attachment';
import { FilesUploadService } from '~/source/common/services/files-upload';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import CustomersService from '~/source/contact/common/services/customers';
import ModalService from '~/source/common/components/modal/modal.service';
import {
  Customer,
  CustomerComplianceFileType,
} from '@proftit/crm.api.models.entities';
import template from './compliance-attachment.html';
import previewTemplate from '../compliance-preview/compliance-preview.html';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
class ComplianceAttachmentController extends BaseController {
  styles = styles;
  complianceDocType: CustomerComplianceFileType;
  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  blockUiInstance;
  fileUploadProgress: number;

  customer: Customer;
  file: any;

  uploadFileAndAttachmentPermissions$ = this.streamUploadFileAndAttachmentPermissions();

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly filesUploadService: FilesUploadService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly customersService: () => CustomersService,
    readonly modalService: ModalService,
    readonly blockUI: any,
    readonly growl,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    super();
    useStreams(
      [this.uploadFileAndAttachmentPermissions$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamUploadFileAndAttachmentPermissions() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          checkCrudPermission(
            PermissionNormalized.ContactsAttachments,
            this.PermPermissionStore,
          ),
          checkCrudPermission(
            PermissionNormalized.ContactsFileUpload,
            this.PermPermissionStore,
          ),
        ]),
      rx.map(
        ([marketingPermission, marketingCampaignsPermission]) =>
          marketingPermission.isCreate && marketingCampaignsPermission.isCreate,
      ),
      shareReplayRefOne(),
    )(null);
  }

  upload(file) {
    if (!file) {
      return;
    }

    // Reset current file (if exists)
    this.file = null;

    /*
     * Start blocking the ui
     * Blocking stops will occur within 'finally' on 'save' method
     */
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
    this.blockUiInstance.start();

    this.filesUploadService.uploadPrivateFile(file).then(
      (resp) => {
        this.save(resp.data);
      },
      (resp) => {
        log.warn(`Error uploading file: ${resp.status}`);
        this.blockUiInstance.stop();
        this.growl.error('server_errors.SERVER_GENERAL', {
          referenceId: this.growlId,
        });
      },
      (evt) => {
        this.fileUploadProgress = _.flow([
          () => Math.min(100, (100.0 * evt.loaded) / evt.total),
          (num) => num.toFixed(2),
        ])();
      },
    );
  }

  save(file) {
    const normalizedModel = this.modelNormalizer.normalize({
      file,
      complianceDocType: this.complianceDocType,
    });

    this.customersService()
      .setConfig({ growlRef: 'complianceForm' })
      .getAttachmentsResource(this.customer.id)
      .postWithQuery(normalizedModel)
      .then((data) => data.plain())
      .then((newCompliance) => {
        this.$scope.$emit('attachment:added', {
          newCompliance,
          complianceDocType: this.complianceDocType,
        });
      })
      .finally(() => {
        this.blockUiInstance.stop();
      });
  }

  /**
   * Opens the 'preview attachments' modal
   * @param {number} docTypeId attachment doc type id
   * @return {void}
   */
  openPreview(docTypeId) {
    this.modalService.open({
      component: 'prfContactCompliancePreviewDialog',
      size: 'lg',
      resolve: {
        customer: () => this.customer,
        complianceDocTypeId: () => docTypeId,
        complianceDocType: () => this.complianceDocType,
      },
    });
  }
}

export default {
  template,
  controller: ComplianceAttachmentController,
  bindings: {
    customer: '<',
    complianceDocType: '<',
    isCategoryComplete: '<',
  },
};
