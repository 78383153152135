import ModalService from './modal.service';
import template from './popup.html';

export class PopupService extends ModalService {
  open(options) {
    return super.open(options, {
      template,
      backdrop: false,
      windowClass: 'prf-dialog prf-dialog--toggle',
      windowTemplateUrl: 'base-popup-window.html',
    });
  }
}

export default PopupService;
