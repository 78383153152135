import * as _ from '@proftit/lodash';
import template from './promo-code-popup.html';
import TablePopupController from '~/source/common/components/table/table-popup';
import promoCodeSettings from './promo-code-settings.json';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

import CustomersService from '~/source/contact/common/services/customers';
import { Customer, PromoCode } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PromoCodeScreenAction } from '../add-promo-code-popup/promo-code-screen-action';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  PROMO_CODE_ADDED,
  PROMO_CODE_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';
const styles = require('./promo-code-popup.component.scss');

class Controller extends TablePopupController {
  styles = styles;

  static $inject = [
    'customersService',
    'blockUI',
    'popupService',
    'prfClientGeneralPubsub',
    ...TablePopupController.$inject,
  ];

  settings = _.cloneDeep(promoCodeSettings);
  cols = [...this.settings.tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  popupService: PopupService;
  customer: Customer;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  promoCodeData;
  lifecycles = observeComponentLifecycles(this);

  sendSmsOp$ = new rx.Subject<{ id: number; code: string }>();

  $onInit() {
    super.$onInit();

    useStreams(
      [this.streamRefreshTable(), this.streamSendSms()],
      this.lifecycles.onDestroy$,
    );
  }

  $onChange() {}

  $onDestroy() {}
  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings.popupTable.ngTable;
  }

  get tableKey() {
    return 'promo-code-popup-table';
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      return;
    }
    this.customer = resolve.customer;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getPromoCodesResource(this.customer.id)
      .expand(['createdBy']);
  }

  parseLoadedData(data) {
    this.promoCodeData = data;

    return data;
  }

  streamRefreshTable() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === PROMO_CODE_ADDED),
      rx.tap(() => {
        this.tableParams.reload();
      }),
    )(null);
  }

  streamSendSms() {
    return rx.pipe(
      () => this.sendSmsOp$,
      rx.switchMap(({ id, code }) => {
        return this.customersService()
          .setConfig({
            blockUiRef: this.blockUiKey,
            growlRef: this.growlRef,
            defaultErrorMessage: 'contact.call.errors.CALL_FAILED',
          })
          .sendSms(
            this.customer.id,
            this.customer.brand.smsCredentials[0].smsProvider.id,
            `${this.customer.brand.name}: Your promo code is: ${code}`,
            null,
            true,
          )
          .then((data) => {
            return { id, data };
          });
      }),
      rx.switchMap(({ id, data }) => {
        return this.customersService()
          .setConfig({
            blockUiRef: this.blockUiKey,
            growlRef: this.growlRef,
            defaultErrorMessage: 'contact.call.errors.CALL_FAILED',
          })
          .updatePromoCode(this.customer.id, id, { isSent: true })
          .then((data) => {
            return { id, data };
          });
      }),
      rx.tap(({ id, data }) => {
        const promoCodeCurrent = this.promoCodeData.find(
          (promoCodeItem) => promoCodeItem.id === id,
        );
        promoCodeCurrent.isSent = true;
      }),
      shareReplayRefOne(),
    )(null);
  }

  editPromoCode(promoCode: PromoCode) {
    this.popupService.open({
      component: 'prfAddPromoCodePopup',
      resolve: {
        customer: () => this.customer,
        brand: () => this.customer.brand,
        promoCode: () => promoCode,
        action: () => PromoCodeScreenAction.Update,
      },
    });
  }
}

export const PromoCodePopupComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
