import moment from 'moment';
import BaseConfig, {
  FilterPopoverSimpleController,
} from '../filter-popover-simple.component';
import template from './filter-popover-date.html';

class FilterPopoverDateController extends FilterPopoverSimpleController {
  noRange = false;
  pickerSettings;
  filterName;

  onFilterNameChange() {
    super.onFilterNameChange();

    this.model = Object.assign({
      startDate: moment(),
      endDate: moment(),
    });

    this.pickerSettings = {
      autoApply: true,
      parentEl: '.prf-js-filter-container',
      alwaysShowCalendars: true,
      showCustomRangeLabel: false,
    };
  }

  applyFilter() {
    if (this.noRange) {
      // If no range was selected, just set the filter to 'null' which should be sent to the server
      this.$scope.$emit('table:filter:add', { [this.filterName]: null });
      return;
    }

    // else: apply the real filter
    super.applyFilter();
  }
}

export default {
  ...BaseConfig,
  template,
  controller: FilterPopoverDateController,
};
