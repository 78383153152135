import * as _ from '@proftit/lodash';

/**
 *
 */
export function switchOn(
  switchHash: { [key: string]: Function },
  condValue: string,
  defaultOn: Function = () => {},
) {
  if (_.has([condValue], switchHash)) {
    return switchHash[condValue]();
  }

  return defaultOn();
}

export default switchOn;
