import ng from 'angular';
import { ClipboardService } from 'angular-clipboard';
import BaseController from '~/source/common/controllers/base';

class FormBuilderViewCodeModalController extends BaseController {
  static $inject = ['clipboard', 'appConfig', ...BaseController.$inject];

  clipboard: ClipboardService;

  /**
   * Copy the form code element content to the clipboard, using the clipboard service.
   *
   * @param {number} id - id
   * @return {void}
   */
  copyToClipboard(id) {
    const formCode = ng.element(`#${id}`).text();

    this.clipboard.copyText(formCode);
  }
}

export default FormBuilderViewCodeModalController;
