// import tableTemplate from '~/source/common/components/table/table-popup.html';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import ModalService from '~/source/common/components/modal/modal.service';

const styles = require('./communication-history-table.component.scss');
import TableLiveController from '~/source/common/components/table/table-live.controller';
import * as _ from '@proftit/lodash';
import statusUpdateTemplate from '../customer-status-update-popup.html';
import communicationHistorySettings from '../communication-history-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import CommunicationsSocketService from '~/source/common/components/call-manager/communications-socket.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import CustomerCommunications from '~/source/common/models/customer-communications';
import {
  Customer,
  CustomerCommunication,
} from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';
import template from './communication-history-table.html';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  COMMUNICATION_ADDED,
  COMMUNICATION_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';
import { CommunicationType } from '~/source/common/models/communication-type';
import * as rx from '@proftit/rxjs';
import { CommunicationTypesService } from '~/source/common/services/communication-types.service';
import { wrapNgPermissionValidatePromise } from '~/source/common/utilities/wrap-ng-permission-validate-promise';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import { collapsibleTableDetails } from '~/source/common/utilities/collapsible-table-details';
import {
  CommunicationMethodCode,
  CommunicationTypeCode,
  VoipProviderCode,
} from '@proftit/crm.api.models.enums';
import { CommunicationTypeCodeForUi } from '~/source/common/models/communication-type-code-for-ui';
import { getResolveChange } from '~/source/common/utilities/rx-ng-one/operators/get-resolve-change';
import { Communication } from '~/source/common/models/communication';
import { getSandboxedIframe } from '@proftit/dom-utilities';
import { ExternalWindowService } from '~/source/common/services/external-window.service';
import { IElement } from 'restangular';

const commTypeFiltersBarIcons = [
  {
    value: CommunicationTypeCodeForUi._All,
    tooltip: 'communicationTypes.ALL',
    icon: '#communication-all',
  },
  {
    value: CommunicationTypeCode.Comment,
    tooltip: 'communicationTypes.COMMENT',
    icon: '#communication-comments',
  },
  {
    value: CommunicationTypeCode.Call,
    tooltip: 'communicationTypes.CALL',
    icon: '#communication-call',
  },
  {
    value: CommunicationTypeCode.Sms,
    tooltip: 'communicationTypes.SMS',
    icon: '#communication-sms',
  },
  {
    value: CommunicationTypeCode.Email,
    tooltip: 'communicationTypes.EMAIL',
    icon: '#communication-email',
  },
];

class Controller extends TableLiveController {
  externalWindowService: ExternalWindowService;
  CommunicationTypeCode = CommunicationTypeCode;

  styles = styles;

  PermissionNormalized = PermissionNormalized;

  blockUiId = 'callsTable';

  lifecycles: {
    onInit$: rx.Observable<void>;
    onChanges$: rx.Observable<any>;
    onDestroy$: rx.Observable<any>;
  } = observeComponentLifecycles(this);

  collapsibleDetails = collapsibleTableDetails(this.collection$, false, false);

  collapseButton = {
    toggleCollapseAction: this.collapsibleDetails.tableToggleExpansionAction,
    expanded$: this.collapsibleDetails.buttonStatus$,
  };
  customersService: () => CustomersService;
  // @ts-ignore
  customersServiceInst = this.customersService();
  communicationsSocketService: CommunicationsSocketService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  communicationTypesService: CommunicationTypesService;

  calls: IElementRestNg<any>;
  customer: IElementRestNg<Customer>;
  popupService: PopupService;
  PermPermissionStore: ng.permission.PermissionStore;
  hasFavoriteUpdatePermission: boolean;
  cols: any[];
  commTypeFiltersBarIcons = commTypeFiltersBarIcons;
  opCommTypeFilterClick$ = new rx.Subject<CommunicationTypeCode>();
  expandRowDetailsAction = new rx.Subject<Communication>();
  previewFullEmailAction = new rx.Subject<Communication>();
  selectedCommType$ = this.streamSelectedCommType();
  selectedFilterBarIcon$ = this.streamSelectedFilterBarIcon();
  selectedCommType: CommunicationType;
  dataReload$ = this.streamDataReload();
  dataInit$ = this.streamDataInit();
  modalService: ModalService;
  Permissions = Permissions;
  archivePerm$ = this.streamArchivePerm();

  customer$ = observeShareCompChange<IElementRestNg<Customer>>(
    this.lifecycles.onChanges$,
    'customer',
  ).pipe(rx.tap((customer) => (this.customer = customer)));

  audioPlayerFileFormats$ = this.streamAudioPlayerFileFormats();

  constructor(...args) {
    super(...args);

    // copy table settings json
    const callHistorySettingsCopy = _.cloneDeep(communicationHistorySettings);

    Object.assign(this, {
      dataServiceInstance: this.customersService(),
      settings: callHistorySettingsCopy,
      cols: [...callHistorySettingsCopy.tableColumns],
    });

    useStreams(
      [
        this.customer$,
        this.streamCommunicationsChanged(),
        this.selectedCommType$,
        this.selectedFilterBarIcon$,
        this.dataInit$,
        this.dataReload$,
        this.collapsibleDetails.main$,
        this.streamExpandRowDetails(),
        this.streamPreviewFullEmail(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  streamCommunicationsChanged() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) =>
        [COMMUNICATION_ADDED, COMMUNICATION_UPDATED].includes(key),
      ),
      rx.filter(
        ({ payload }) => payload.method === CommunicationMethodCode.Manual,
      ),
      // the table is reloading anyway, so no need to notify
      rx.tap(() => this.reloadTable()),
      rx.switchMap(() =>
        checkCrudPermission(
          PermissionNormalized.ContactsPersonalInfoStatus,
          this.PermPermissionStore,
        ),
      ),
      rx.tap((permission) => {
        if (permission.isUpdate) {
          this.openCustomerStatusUpdatePopup();
        }
      }),
    )(null);
  }

  streamSelectedCommType() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInit$.pipe(
            rx.map(() => CommunicationTypeCodeForUi._All),
          ),
          this.opCommTypeFilterClick$,
        ),
      rx.switchMap((commTypeCode) =>
        this.communicationTypesService.getItemByCode(commTypeCode),
      ),
      rx.map((commType) =>
        _.defaultTo(
          { id: null, code: CommunicationTypeCodeForUi._All, name: null },
          commType,
        ),
      ),
      rx.tap((commType) => (this.selectedCommType = commType)),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  stopClickEventReachingParentForCol(event, col) {
    const colsToStopPropagation = ['recordingFile'];
    if (colsToStopPropagation.includes(col.fieldName)) {
      event.stopPropagation();
    }
  }

  streamSelectedFilterBarIcon() {
    return rx.pipe(
      () => this.selectedCommType$,
      rx.map((commType) => {
        return this.commTypeFiltersBarIcons.find(
          (barIcon) => barIcon.value === commType.code,
        );
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamDataInit() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.obs.from(
            wrapNgPermissionValidatePromise(
              this.PermPermissionStore.getPermissionDefinition(
                'contacts.communications.favorite',
              ),
            ),
          ),
          rx.obs.from(
            wrapNgPermissionValidatePromise(
              this.PermPermissionStore.getPermissionDefinition(
                'contacts.communications.favorite_U',
              ),
            ),
          ),
          checkCrudPermission(
            PermissionNormalized.ContactsCommunicationsRecordings,
            this.PermPermissionStore,
          ),
        ),
      rx.tap(([viewPermission, updatePermission, recordingPermissions]) => {
        // get favorite column from column array
        const favoriteColumn = _.find(
          (col) => col.fieldName === 'isFavorite',
          this.cols,
        );

        favoriteColumn.show = viewPermission;
        this.hasFavoriteUpdatePermission = updatePermission;

        const recordingColumn = _.find(
          (col) => col.fieldName === 'recordingFile',
          this.cols,
        );
        recordingColumn.show = recordingPermissions.isView;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamDataReload() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isInitTable$.pipe(rx.filter((x) => x)),
          this.selectedCommType$.pipe(rx.skip(1)),
        ),
      rx.withLatestFrom(this.selectedCommType$),
      rx.tap(([a, commTypeCode]) => this.tableReload()),
    )(null);
  }

  streamArchivePerm() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ContactsCommunicationsExportCommunicationsHistory,
          this.PermPermissionStore,
        ),
      shareReplayRefOne(),
    )(null);
  }

  get pageKey() {
    return 'call';
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService() {
    return this.communicationsSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.calls';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.calls;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    const config = { blockUiRef: this.blockUiId };
    const expand = [
      'user',
      'communicationStatus',
      'communicationSubject',
      'type',
      'provider',
    ];
    const sort = { date: 'desc' };

    return (this
      .dataServiceInstance as CustomersService).getCommunicationsResourceHelper(
      this.customer,
      config,
      expand,
      sort,
      {},
    );
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @example
   * get requiredApiFilters () {
   *   return {
   *       "role.code": {
   *           exclude: "extapi"
   *       }
   *   };
   * }
   * @returns {{}}
   */
  get requiredApiFilters(): any {
    const commType = this.selectedCommType;

    const filter = _.flow([
      () => ({}),
      (f) =>
        commType && commType.code !== CommunicationTypeCodeForUi._All
          ? { ...f, typeId: commType.id }
          : f,
    ])();

    return filter;
  }

  parseLoadedData(data) {
    data.forEach((item) => {
      item._calculatedDetails = this.getCommunicationSnippet(item);
    });
    this.calls = data;
    return data;
  }

  tableReload() {
    this.tableParams.page(1);
    this.tableParams.reload();
  }

  addNewCall(commTypeP: CommunicationType) {
    new Promise((resolve, reject) => {
      if (commTypeP.code === CommunicationTypeCodeForUi._All) {
        this.communicationTypesService
          .getCommTypeComment()
          .then((commType) => resolve(commType));
        return;
      }

      resolve(commTypeP);
    }).then((commType) => {
      this.popupService.open({
        component: 'AddCallPopup',
        resolve: {
          communicationType: () => commType,
          customer: () => this.customer,
        },
      });
    });
  }

  openCallHistoryTablePopup() {
    // open add callHistory popup
    this.popupService.open({
      component: 'prfCommunicationHistoryTablePopup',
      resolve: {
        customer: this.customer,
      },
    });
  }

  openCustomerStatusUpdatePopup() {
    this.popupService.open({
      controller: 'CustomerStatusUpdatePopupController',
      template: statusUpdateTemplate,
      data: {
        customer: this.customer,
      },
    });
  }

  /**
   * hide filter action in column actions
   * @return {boolean}
   */
  isColumnFilterable() {
    return false;
  }

  /**
   * update isFavorite to server
   * @param {object} call
   * @return {void}
   */
  toggleFavorite(call: IElementRestNg<CustomerCommunications>) {
    if (!this.hasFavoriteUpdatePermission) {
      return;
    }
    const isFavorite = !call.isFavorite;
    call.patch({ isFavorite }).then(() => (call.isFavorite = isFavorite));
  }

  streamAudioPlayerFileFormats() {
    return rx.pipe(
      () => this.collection$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((communications: any[]) => {
        return communications.reduce((acc, currComm) => {
          const { provider } = currComm;

          if (!_.isNil(provider)) {
            let fileFormatForCommunication = [];

            switch (provider.code) {
              case VoipProviderCode.Voicespin:
                fileFormatForCommunication = ['wav'];
                break;
              case VoipProviderCode.Commpeak:
                fileFormatForCommunication = ['mp3'];
                break;
            }

            acc[currComm.id] = fileFormatForCommunication;
          }

          return acc;
        }, {});
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamExpandRowDetails() {
    return rx.pipe(
      () => this.expandRowDetailsAction,
      rx.tap((communication) => {
        if (this.isEmailCommunication(communication)) {
          this.previewFullEmailAction.next(communication);
        } else {
          this.collapsibleDetails.toggleRowShowDetailsAction.next(
            communication.id,
          );
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  isEmailCommunication(communication: Communication): boolean {
    if (_.isNil(communication)) {
      return false;
    }
    if (_.isNil(communication.type)) {
      return false;
    }
    return communication.type.code === CommunicationTypeCode.Email;
  }

  streamPreviewFullEmail() {
    return rx.pipe(
      () => this.previewFullEmailAction,
      rx.map((communication) => {
        const body = this.getCommunicationBody(communication, true);
        const subject = this.getCommunicationSubject(communication);
        return {
          body,
          communication,
          subject,
        };
      }),
      rx.switchMap(({ body, communication, subject }) => {
        if (!_.isNil(body) && !_.isNil(subject)) {
          return Promise.resolve({ body, subject });
        }
        return this.customersServiceInst
          .setConfig({ blockUiRef: this.blockUiId })
          .getCommunicationPreview(this.customer.id, communication.id)
          .then((res) => res.plain())
          .catch(() => null);
      }),
      rx.filter(
        (res) => !_.isNil(res) && !_.isNil(res.body) && !_.isNil(res.subject),
      ),
      rx.tap(({ body, subject, attachmentsCount }) => {
        const sandboxedIframe = getSandboxedIframe(
          body,
          subject,
          attachmentsCount,
        );
        this.externalWindowService.openContentInCenter(sandboxedIframe);
      }),
      shareReplayRefOne(),
    )(null);
  }

  getCommunicationBody(communication: Communication, isEmail: boolean): string {
    if (_.isNil(communication.details)) {
      return '';
    }

    if (isEmail) {
      let detailsObject;
      try {
        detailsObject = JSON.parse(communication.details);
      } catch (e) {
        // if the flow ends up here, the details are not of JSON type. We now know that the details are a plain string (pre-gmail format), and should simply return them as is.
        return communication.details;
      }
      if (_.isNil(detailsObject)) {
        return null;
      }
      return detailsObject.body;
    }
    return communication.details;
  }

  getCommunicationSnippet(communication: Communication): string {
    if (_.isNil(communication.details)) {
      return '';
    }

    if (this.isEmailCommunication(communication)) {
      let detailsObject;
      try {
        detailsObject = JSON.parse(communication.details);
      } catch (e) {
        return communication.details;
      }
      if (_.isNil(detailsObject) || _.isNil(detailsObject.snippet)) {
        return '';
      }
      return _.unescape(detailsObject.snippet);
    }
    return communication.details;
  }

  getCommunicationSubject(communication: Communication): string {
    const hasCommunicationSubjectName = !_.isNil(
      communication.communicationSubject.name,
    );
    let detailsObject;
    try {
      detailsObject = JSON.parse(communication.details);
    } catch (e) {
      if (hasCommunicationSubjectName) {
        return communication.communicationSubject.name;
      }
      return '';
    }
    if (_.isNil(detailsObject) || _.isNil(detailsObject.subject)) {
      return '';
    }
    return detailsObject.subject;
  }

  openEditCommentPopup(callP: IElement & CustomerCommunication) {
    const call = callP.plain();
    this.modalService.open({
      component: 'prfEditCommentPopup',
      size: 'lg',
      resolve: {
        call,
        customerId: this.customer.id,
      },
    });
  }

  downloadArchive() {
    this.customersServiceInst.getDownloadCommunicationArchive(this.customer.id);
  }
}

Controller.$inject = [
  'popupService',
  'customersService',
  'communicationsSocketService',
  'PermPermissionStore',
  'prfClientGeneralPubsub',
  'communicationTypesService',
  'externalWindowService',
  'modalService',
  'PermPermissionStore',
  ...TableLiveController.$inject,
];

export default {
  template,
  bindings: {
    customer: '<',
  },
  controller: Controller,
  controllerAs: 'vm',
};
