import type { ui } from 'angular';
import { Permissions } from '~/source/common/models/permission-structure';

export const autoAssignmentRouting = ($stateProvider: ui.IStateProvider) => {
  const sp = $stateProvider;

  sp.state('crm.auto-assignment', {
    url: '/auto-assignment',
    component: 'prfAutoAssignmentPanelDashboard',
    data: {
      permissions: {
        only: [Permissions.AssignmentPannel.Read],
        redirectTo: 'error.unauthorized',
      },
    },
  });
};

autoAssignmentRouting.$inject = ['$stateProvider'];
