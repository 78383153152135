import { IScope } from 'angular';

import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import Fee from '~/source/common/models/fee';
import FeeType from '~/source/common/models/fee-type';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import FeeTypesService from '~/source/contact/common/services/fee-types.service';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import template from './add-fee-popup.html';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { ADD_FEE } from '~/source/common/constants/general-pubsub-keys';

class AddFeePopupController extends BaseController {
  account: TradingAccount;
  customer: Customer;
  close: () => void;

  fee: Partial<Fee> = {
    type: {
      id: 1,
      code: 'commission',
      name: 'Commission',
    },
  };

  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly feeTypesService: () => FeeTypesService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();
  }

  $onInit() {
    this.feeTypesService()
      .setConfig({ blockUiRef: 'feeForm' })
      .getListWithQuery<IElementRestNg<FeeType>>()
      .then((feeTypes) => {
        const inactivityFeeType = feeTypes.find(
          (feeType) => feeType.code === 'inactivity',
        );
        this.fee = { ...this.fee, type: inactivityFeeType };
      });
  }

  set resolve(accountCustomer) {
    if (_.isNil(accountCustomer)) {
      return;
    }
    this.account = accountCustomer.account;
    this.customer = accountCustomer.customer;
  }

  buildModelForSend(fee) {
    return _.flow(
      () => _.omit(['type', 'status'], fee),
      (x) => this.modelNormalizer.normalize(x),
      (x) => ({ ...x, typeCode: fee.type.code }),
      (x) => ({ ...x, statusCode: 'approved' }),
    )();
  }

  /**
   * called when form is validated
   * sends request to server that will add new fee record
   * fee records are auto approved, so there is no need to send a request like in withdrawal component
   */
  onSubmit() {
    const normalizedModel = this.buildModelForSend(this.fee);

    // send request to the server
    this.customersService()
      .setConfig({
        growlRef: 'feeForm',
        blockUiRef: 'feeForm',
        errorsTranslationPath: 'fees.serverErrors',
      })
      .getFeesResource(this.customer.id, this.account.id)
      .postWithQuery(normalizedModel)
      .then(this.onAddFeeSuccess.bind(this));
  }

  /**
   * Called upon fee successfully added on server
   *
   * @param {object} addedFee newly added fee
   */
  onAddFeeSuccess(addedFee) {
    // fire event that will eventually will refresh the fees table
    this.prfClientGeneralPubsub.publish(ADD_FEE, '');
    // close the modal
    this.close();
  }
}

export default {
  template,
  controller: AddFeePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
