import * as _ from '@proftit/lodash';
import { IScope } from 'angular';

import BaseController from '~/source/common/controllers/base';
import DesksService from '~/source/management/brand/services/desks';
import { Desk } from '@proftit/crm.api.models.entities';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

import template from './desk-form.html';

class ComponentController extends BaseController {
  desk: Desk;
  formIndex: string;
  allDesks: any;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly desksService: () => DesksService,
  ) {
    super();
  }

  $onInit() {
    _.defaults(this.desk, {
      name: '',
      countries: [],
      languages: [],
    });

    this.formIndex = _.uniqueIdEs();

    // Embed is intentionally not used here - it is too heavy
    this.allDesks = this.desksService()
      .setConfig({
        blockUiRef: `deskForm_${this.formIndex}`,
        growlRef: `deskForm_${this.formIndex}`,
      })
      .getListWithQuery().$object;
  }

  /**
   * Called when a desk is selected from the existing desks list.
   * Copies the selected countries and languages.
   *
   * @param {object} selectedDesk
   */
  onDeskSelect(selectedDesk) {
    if (selectedDesk.id === this.desk.id) {
      return; // this desk is already selected
    }

    // reset selected id/countries/languages
    this.clearSelectedValues();

    this.desk.id = selectedDesk.id;

    // Embed the selected desk's countries and languages
    this.desksService()
      .setConfig({
        blockUiRef: `deskForm_${this.formIndex}`,
        growlRef: `deskForm_${this.formIndex}`,
      })
      .embed(['languages', 'countries'])
      .getOneWithQuery<IElementRestNg<Desk>>(selectedDesk.id)
      .then(({ languages, countries }) => {
        // Copy selected values (copy, don't override!)
        Array.isArray(countries) && this.desk.countries.push(...countries);
        Array.isArray(languages) && this.desk.languages.push(...languages);
      });
  }

  /**
   * Called on desk name input blur event.
   *
   * Tries to find a desk by that name in the existing desks list and selects it, if found.
   *
   * Otherwise, clears the countries and languages options.
   */
  onDeskNameBlur() {
    // check if a desk by that name exists (case insensitive)
    const foundDesk = this.allDesks.find(
      (desk) => desk.name.toLowerCase() === this.desk.name.toLowerCase(),
    );

    // if a desk that is different than the selected desk was found, select it.
    if (foundDesk) {
      this.onDeskSelect(foundDesk);
      return;
    }

    this.clearSelectedNewDeskValue();
  }

  /**
   * Clears details of the currently selected desk regarding when dealing with new desk editing.
   * - when previous selection was existing desk -> clear all.
   * - when previous selection was new desk -> keep values.
   * @private
   */
  clearSelectedNewDeskValue() {
    if (this.desk.id) {
      this.clearSelectedValues();
    }
  }

  /**
   * Clears the details of the currently selected desk (all but the desk name)
   * @private
   */
  clearSelectedValues() {
    delete this.desk.id;

    // empty the array without overwriting
    this.desk.countries.length = 0;
    this.desk.languages.length = 0;
  }
}

const DeskFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    desk: '=',
    removeElement: '&removeFn',
    removeValidate: '&removeValidateFn',
  },
};

export default DeskFormComponent;
