import * as _ from '@proftit/lodash';
import BaseStatusesController from './base-statuses.controller';
import CustomerStatuses from '~/source/contact/common/services/customer-statuses.service';
import template from './statuses-management.html';

class CustomerStatusesController extends BaseStatusesController {
  static $inject = [
    'customerStatusesService',
    ...BaseStatusesController.$inject,
  ];

  customerStatusesService: () => CustomerStatuses;
  dataService: CustomerStatuses;
  shouldAlwaysHaveOneDefault = true;
  shouldShowAutoAfterFtdCheckbox = true;

  $onInit() {
    this.dataService = this.customerStatusesService();

    super.$onInit();
  }

  get sectionName() {
    return 'customerStatuses';
  }

  hasAssignedDepartmentFeature() {
    return true;
  }

  /*
   * get a list of statuses from bounded data service instance
   */
  getStatuses(searchTerm = null) {
    /*
     * This component is a crud list items screen. The desire is to show the
     * latest current data.
     *
     * Since the data service is using cache, we need to bust it so the fetch
     * will bring fresh data.
     */
    this.dataService.cacheEmpty();

    let filter = { ...this.requiredApiFilters() };
    if (!_.isEmpty(searchTerm)) {
      filter = { ...filter, q: searchTerm };
    }

    return this.dataService
      .setConfig({
        blockUiRef: `${this.sectionName}BlockUi`,
        growlRef: `${this.sectionName}Growl`,
        errorsTranslationPath: 'statuses.errors',
      })
      .filter(filter)
      .expand('department')
      .getListWithQuery()
      .then((list) => {
        this.statuses = list;
      });
  }
}

export default {
  template,
  controller: CustomerStatusesController,
};
