import template from './link-customer-item.component.html';
const styles = require('./link-customer-item.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import {
  Customer,
  Brand,
  SearchCustomerResult,
} from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';
import { CustomersService } from '../../services/customers';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import log from 'loglevel';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { CUSTOMER_UPDATED } from '~/source/common/constants/general-pubsub-keys';

function asComponentRestObs(promise, onErrorValue = () => []) {
  return rx.obs.from(promise).pipe(
    rx.catchError((err, caught) => {
      log.error('error in component request', err);
      return onErrorValue();
    }),
  );
}

export class LinkCustomerItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  onClose: () => void;

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  title = 'linkCustomerItem.TITLE';

  opSwitchIsActiveOnCustomer$ = new rx.Subject<boolean>();
  opRemoveLinkUi$ = new rx.Subject<number>();
  opToggleIsActiveLink$ = new rx.Subject<{ id: number; isActive: boolean }>();
  opAddCustomerToLinks$ = new rx.Subject<SearchCustomerResult>();
  opSaveLinks$ = new rx.Subject<void>();
  customer$ = this.streamCustomer();
  brand$ = this.streamBrand();
  linkedCustomers$ = this.streamLinkedCustomers();

  /*@ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams(
      [
        this.customer$,
        this.linkedCustomers$,
        this.brand$,
        this.streamActionSwitchIsActiveOnCustomer(),
        this.streamActionSaveLinks(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCustomerFromInput() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<number>('customerId'),
      rx.map((customerId) =>
        this.customersService()
          .setConfig({
            growlRef: this.growlId,
            blockUiRef: this.blockUiId,
          })
          .getCustomer(customerId),
      ),
      rx.switchMap((x) => rx.obs.from(x)),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCustomerFromPubsubCustomerUpdate(customer$: rx.Observable<Customer>) {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === CUSTOMER_UPDATED),
      rx.withLatestFrom(customer$),
      rx.map(([{ key, payload }, prevCustomer]) => {
        return {
          ...prevCustomer,
          ...payload,
        };
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCustomer() {
    const customer$ = new rx.BehaviorSubject<Customer>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamCustomerFromInput(),
          this.streamCustomerFromPubsubCustomerUpdate(customer$),
        ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
      rx.tap((customer) => customer$.next(customer)),
    )(null);
  }

  streamBrand() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<Brand>('brand'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamActionSwitchIsActiveOnCustomer() {
    return rx.pipe(
      () => this.opSwitchIsActiveOnCustomer$,
      rx.withLatestFrom(this.customer$),
      rx.map(([isActive, customer]) =>
        this.customersService()
          .setConfig({
            growlRef: this.growlId,
            blockUiRef: this.blockUiId,
          })
          .updateIsActive(customer.id, isActive),
      ),
      rx.switchMap((promise) => rx.obs.from(promise)),
      rx.withLatestFrom(this.customer$),
      rx.catchError((err) => {
        log.error('error setting is active', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  streamLinkedCustomersFromInput() {
    return rx.pipe(
      () => this.customer$,
      rx.switchMap((customer) =>
        asComponentRestObs(
          this.customersService().getCustomerLinks(customer.id),
        ),
      ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamLinkedCustomerFromRemoveOp(
    linkedCustomers$: rx.Observable<Customer[]>,
  ) {
    return rx.pipe(
      () => this.opRemoveLinkUi$,
      rx.withLatestFrom(linkedCustomers$, this.customer$),
      rx.filter(([removeId, linkedCustomers, mainCustomer]) => {
        const targetCustomer = linkedCustomers.find(
          (customer) => customer.id === removeId,
        );
        if (_.isNil(targetCustomer)) {
          return false;
        }

        if (
          targetCustomer.brandId === mainCustomer.brandId &&
          targetCustomer.email === mainCustomer.email
        ) {
          return false;
        }

        return true;
      }),
      rx.map(([removeId, linkedCustomers]) =>
        _.reject((x) => x.id === removeId, linkedCustomers),
      ),
    )(null);
  }

  streamLinkedCustomers() {
    const linkedCustomers$ = new rx.BehaviorSubject<Customer[]>([]);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamLinkedCustomersFromInput(),
          this.streamLinkedCustomerFromRemoveOp(linkedCustomers$),
          this.streamLinkCustomersFromActionToggleIsActiveLink(
            linkedCustomers$,
          ),
          this.streamLinkCustomersFromActionAddCustomerToLinksUI(
            linkedCustomers$,
          ),
        ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
      rx.tap((links) => linkedCustomers$.next(links)),
    )(null);
  }

  streamLinkCustomersFromActionToggleIsActiveLink(
    linkedCustomers$: rx.Observable<Customer[]>,
  ) {
    return rx.pipe(
      () => this.opToggleIsActiveLink$,
      rx.withLatestFrom(linkedCustomers$),
      rx.map(([{ id, isActive }, linkedCustomers]) =>
        linkedCustomers.reduce((accLinks, customer) => {
          if (customer.id !== id) {
            accLinks.push(customer);
            return accLinks;
          }

          const newLink = {
            ...customer,
            isActive,
          };

          accLinks.push(newLink);
          return accLinks;
        }, []),
      ),
    )(null);
  }

  streamLinkCustomersFromActionAddCustomerToLinksUI(
    linkedCustomers$: rx.Observable<Customer[]>,
  ) {
    return rx.pipe(
      () => this.opAddCustomerToLinks$,
      rx.withLatestFrom(this.customer$, linkedCustomers$),
      rx.map(([customerForAdd, mainCustomer, linkedCustomers]) => {
        if (customerForAdd.id === mainCustomer.id) {
          return linkedCustomers;
        }

        if (
          linkedCustomers.find((customer) => customer.id === customerForAdd.id)
        ) {
          return linkedCustomers;
        }

        return [...linkedCustomers, customerForAdd];
      }),
    )(null);
  }

  streamActionSaveLinks() {
    return rx.pipe(
      () => this.opSaveLinks$,
      rx.withLatestFrom(this.customer$, this.linkedCustomers$),
      rx.switchMap(([op, customer, linkedCustomers]) => {
        const normData = normilizeDataForPut(linkedCustomers);
        return rx.obs.from(
          this.customersService()
            .setConfig({
              growlRef: this.growlId,
              blockUiRef: this.blockUiId,
            })
            .updateCustomerLinks(customer.id, normData),
        );
      }),
      rx.tap(() => this.onClose()),
      rx.catchError((err, caught) => caught),
    )(null);
  }
}

function normilizeDataForPut(linkedCustomers: Customer[]) {
  return linkedCustomers.map((customer) => ({
    linkedCustomerId: customer.id,
    isActive: customer.isActive,
  }));
}

export const LinkCustomerItemComponent = {
  template,
  controller: LinkCustomerItemController,
  bindings: {
    brand: '<',
    customerId: '<',
    onCancel: '&',
    onClose: '&',
  },
};
