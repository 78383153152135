import BaseController from '~/source/common/controllers/base';
import moment from 'moment';
import template from './date-picker.html';
import angular, { IScope, ITimeoutService } from 'angular';

class DatePickerController extends BaseController {
  model;
  title;
  showInput;
  settings;
  isAutoOpen: boolean;

  currentRange;

  /*@ngInject */
  constructor(
    readonly $element: JQuery,
    readonly $timeout: ITimeoutService,
    readonly $scope: IScope,
    readonly $translate: angular.translate.ITranslateService,
    readonly datePickerSettings: any,
  ) {
    super();
  }

  $onInit() {
    this.$scope.$watchCollection('vm.model', this.onDateChange.bind(this));

    if (this.isAutoOpen) {
      this.autoOpen();
    }

    if (!this.settings) {
      this.settings = {};
    }

    // set default local format for out filter date picker
    if (!this.settings.locale) {
      this.settings.locale = {
        format: 'LL',
      };
    }
  }

  onDateChange() {
    const from = this.model.startDate;
    const to = this.model.endDate;
    const lastWeek = moment().subtract(7, 'days');
    const lastMonth = moment().subtract(1, 'month');
    const now = moment();

    // Clear current range
    this.currentRange = null;

    // When selected date is from 1 week ago till today, current range is "week"
    if (lastWeek.isSame(from, 'day') && now.isSame(to, 'day')) {
      this.currentRange = 'week';
    }

    // When selected date is from 1 month ago till today, current range is "month"
    if (lastMonth.isSame(from, 'day') && now.isSame(to, 'day')) {
      this.currentRange = 'month';
    }

    // Add ranges to date pickers
    this.extendDateWithRanges();
  }

  /**
   *  Add ranges to date pickers
   */
  extendDateWithRanges() {
    const rangesListName = this.datePickerSettings.datePicker.ranges;

    this.$translate(rangesListName).then((translations) => {
      Object.assign(this.settings, {
        ranges: {
          [translations['datePicker.TODAY']]: [moment(), moment()],
          [translations['datePicker.YESTERDAY']]: [
            moment().subtract(1, 'days'),
            moment().subtract(1, 'days'),
          ],
          [translations['datePicker.LAST_WEEK']]: [
            moment().subtract(6, 'days'),
            moment(),
          ],
          [translations['datePicker.LAST_THIRTY_DAYS']]: [
            moment().subtract(29, 'days'),
            moment(),
          ],
          [translations['datePicker.LAST_THREE_MONTHS']]: [
            moment().subtract(3, 'months'),
            moment(),
          ],
          [translations['datePicker.THIS_MONTH']]: [
            moment().startOf('month'),
            moment().endOf('month'),
          ],
          [translations['datePicker.LAST_MONTH']]: [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
        },
      });
    });
  }

  /**
   * AutoOpens the date filter.
   */
  autoOpen() {
    this.$timeout(() => {
      const elemToClick = this.$element.find('[date-range-picker]');
      if (elemToClick.length > 0) {
        elemToClick.trigger('click');
      }
    });
  }
}

export default {
  template,
  controller: DatePickerController,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    title: '@',
    showInput: '<?',
    settings: '=',
    isAutoOpen: '<',
  },
};

export { DatePickerController };
