import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';
import {
  initRequestDomainResource,
  getAsList,
  setTokenHeader,
  jsonServerToCfdMongo,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { Brand as TrcBrand } from '@proftit/tradingcore.api.models.entities';

const RESOURCE_PATH = 'Brands';

export class ApiTrcBrandsService {
  /* @ngInject */
  constructor(readonly $http: IHttpService) {}

  getMyBrand(apiUrl: string, token: string): Promise<TrcBrand> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => jsonServerToCfdMongo('', req),
      (req) => cfdMongoToNgHttp(req),
      (req) => {
        const url = `${req.url}?query={"includes":["MTBrandServers"]}`;
        return {
          ...req,
          url,
        };
      },
      (req) => ngHttpPerformRequest<TrcBrand[]>(this.$http, req),
      (req) => req.then((resp: { data: TrcBrand[] }) => resp.data),
      (req) =>
        req.then((data: TrcBrand[]) => (data.length > 0 ? data[0] : data)),
    ])();
  }
}
