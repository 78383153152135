import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'CRMExport/downloadReports';

export class CrmDownloadsService extends RestService {
  static $inject = [...RestService.$inject];

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }
}
