import RestService from '~/source/common/services/rest';

const TRADING_ACCOUNT_TYPES_RESOURCE = 'tradingAccountTypes';

export default class PlatformsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'platforms';
  }

  get accessType() {
    return 'public';
  }

  /**
   * Build url to list of platforms related to given brand
   *
   * @param {number} platformId - the brand to fetch platforms for
   * @returns {RestService} - self instance, for chaining
   */
  getTradingAccountsResource(platformId) {
    return this.resourceBuildStart()
      .getElement(platformId)
      .getNestedCollection(TRADING_ACCOUNT_TYPES_RESOURCE)
      .resourceBuildEnd();
  }

  useCache() {
    return true;
  }
}
