import RestService from '~/source/common/services/rest';
import { CustomerPositionStatusesCode } from '@proftit/crm.api.models.enums';
import { CustomerPositionStatus } from '@proftit/crm.api.models.entities/src';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { IPromise } from 'angular';

const openPositionsTypes = [
  CustomerPositionStatusesCode.Open,
  CustomerPositionStatusesCode.ForexOpen,
];

class PositionsStatusesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'positionStatusesForex';
  }

  useCache() {
    return true;
  }

  getAll(): Promise<CustomerPositionStatus[]> {
    return this.getListWithQuery<
      IElementRestNg<CustomerPositionStatus>
    >().then((data) => data.plain());
  }

  getOpenPositionsStatuses(): Promise<CustomerPositionStatus[]> {
    return this.filter({
      code: openPositionsTypes,
    })
      .getListWithQuery<IElementRestNg<CustomerPositionStatus>>()
      .then((data) => data.plain());
  }

  getOpenPositionsStatusesIds(): Promise<number[]> {
    return this.getOpenPositionsStatuses().then((statuses) =>
      statuses.map((status) => status.id),
    );
  }
}

export default PositionsStatusesService;
