import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

const styles = require('./cfd-platform-brand-config-form.component.scss');
import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './cfd-platform-brand-config-form.component.html';
import { CfdPlatformBrandConfig } from '~/source/common/models/cfd-platform/cfd-platform-brand-config';
import {
  NgModelChange,
  createNgModelObsMediator,
} from '~/source/common/utilities/create-ng-model-obs-mediator';
import { useStream } from '~/source/common/utilities/use-stream';
import { definePropSwapRatioAsPercentage } from '../../utilities/define-prop-swap-ratio-as-percentage';

export class CfdPlatformBrandConfigFormController extends BaseController {
  styles = styles;

  /* Bindings */
  brandConfig: CfdPlatformBrandConfig;
  onChangeOfModel: (a: { change: NgModelChange }) => {};
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  unsub$ = new rx.Subject<void>();
  modelIn$ = new rx.BehaviorSubject<CfdPlatformBrandConfig>(null);
  modelUpdatesOut$ = new rx.Subject<NgModelChange>();
  modelLocal = {};
  platformBrandConfigForm;

  /*@ngInject */
  constructor(readonly $validation) {
    super();
  }

  groupAssetsAsOptions = _.memoize((assets) =>
    assets
      ? assets.map((asset) => ({ value: asset.id, label: asset.name }))
      : [],
  );

  $onInit() {
    this.modelLocal = createNgModelObsMediator(
      [
        'minInvestment',
        'maxInvestment',
        'maxTakeProfit',
        'minStopLoss',
        'swapCommission',
        'asset',
      ],
      this.modelIn$,
      this.modelUpdatesOut$,
    );

    definePropSwapRatioAsPercentage(
      this.modelLocal,
      this.modelIn$,
      this.modelUpdatesOut$,
    );

    useStream(this.streamModelOut(), this.unsub$);
    useStream(this.streamFormValidOut(), this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onModelChange(model: CfdPlatformBrandConfig) {
    this.modelIn$.next(model);
  }

  streamModelOut() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.debounceTime(150),
      rx.map(() =>
        checkValidForm(this.$validation, this.platformBrandConfigForm),
      ),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }
}

function checkValidForm($validation: any, form: any): boolean {
  /*
  Bug in angular-validation library / angular form. form.$valid shows undefined while form
  is valid. This checks the $invalid prop first to mitigate this bug.
  */
  if (!form.$invalid) {
    return true;
  }

  return $validation.checkValid(form);
}

export const CfdPlatformBrandConfigFormComponent = {
  template,
  controller: CfdPlatformBrandConfigFormController,
  bindings: {
    model: '<',
    showSwapCommission: '<',
    onChangeOfModel: '&',
    onChangeOfFormValidity: '&',
    defaultAssetsSelect: '<',
  },
};

export default CfdPlatformBrandConfigFormComponent;
