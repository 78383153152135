import TablePopupController from '~/source/common/components/table/table-popup';
import creditSettings from './credit-settings.json';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';

class CreditTablePopupController extends TablePopupController {
  static $inject = ['customersService', ...TablePopupController.$inject];

  customer: Customer;
  account: TradingAccount;

  dataServiceInstance: CustomersService;

  get cols() {
    return [...creditSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...creditSettings.tablePopup.ngTable };
  }

  get title() {
    return 'contact.tradingAccount.CREDIT';
  }

  get tableKey() {
    return 'credit';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getCreditsResource(this.customer.id, this.account.id)
      .expand(['user', 'promoCode']);
  }

  /**
   * add currency object into query results. because this data is required for 'amount' field value formatting
   * defined in table-popup.json.
   *
   * @param {Array} data query results
   * @return {Array} returns formatted query results
   */
  parseLoadedData(data) {
    data.forEach((item) => {
      item.currency = this.account.currency;
    });

    return data;
  }
}

export default CreditTablePopupController;
