import TablePopupController from '~/source/common/components/table/table-popup';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class PendingPositionTablePopupBaseController extends TablePopupController {
  static $inject = ['customersService', ...TablePopupController.$inject];

  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: IElementRestNg<Customer>;

  get title() {
    return 'pendingPosition.PENDING_POSITION';
  }
}

export default PendingPositionTablePopupBaseController;
