import angular from 'angular';
import Inputmask from 'inputmask';

const InputMaskDirective = () => ({
  require: 'ngModel',

  restrict: 'A',

  link($scope, element, attrs, ngModelCtrl) {
    const opts = $scope.$eval(attrs.prfInputMask);
    if (angular.isObject(opts)) {
      opts.placeholder = opts.placeholder || '';
    }

    Inputmask(opts).mask(element);

    element.on('keyup', () => {
      $scope.$apply(() => {
        ngModelCtrl.$setViewValue(element.val());
      });
    });
  },
});

export default InputMaskDirective;
