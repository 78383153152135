import { IPromise } from 'angular';
import * as _ from '@proftit/lodash';

import CustomersService from '~/source/contact/common/services/customers';
import { CRMExportService } from '~/source/common/services/crm-export.service';
import KibiDownloadsService from '~/source/reports/kibi-downloads.service';
import BrandsService from '~/source/management/brand/services/brands';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export enum GoogleStorageDomain {
  Attachments = 'attachments',
}

export enum GoogleStorageResources {
  KIBI = 'kibi_exports',
  CRM_EXPORT = 'crm_exports',
  BALANCE_LOG = 'balance_logs_exports',
}

const ATTACHMENTS = GoogleStorageDomain.Attachments.valueOf();

export class PrivateGoogleStorageFileService {
  savedData = {
    [ATTACHMENTS]: {},
  };

  constructor(
    readonly customersService: () => CustomersService,
    readonly prfCRMExportService: () => CRMExportService,
    readonly balanceLogsExportService: () => CRMExportService,
    readonly kibiDownloadsService: KibiDownloadsService,
    readonly brandsService: () => BrandsService,
  ) {}

  attachmentsKeyBuilder(customerId: number, attachmentId: number) {
    return `attachments_customer_${customerId}_attachment_${attachmentId}`;
  }

  getStoredValue(domain: GoogleStorageDomain, storageKey: string) {
    return this.savedData[domain][storageKey];
  }

  storeValue(domain: GoogleStorageDomain, storageKey: string, value: any) {
    this.savedData[domain][storageKey] = value;
  }

  removeValue(domain: GoogleStorageDomain, storageKey: string) {
    delete this.savedData[domain][storageKey];
  }

  getAttachmentFileUrl(
    customerId: number,
    attachmentId: number,
    blockUiId: string,
  ): rx.Observable<string> {
    const storageKey = this.attachmentsKeyBuilder(customerId, attachmentId);
    const storageValue = this.getStoredValue(
      GoogleStorageDomain.Attachments,
      storageKey,
    );
    if (!_.isNil(storageValue)) {
      return storageValue;
    }

    const observable = new rx.Observable<string>((subscriber) => {
      let localFileUrlRef = null;

      const customersServiceLocalInst = this.customersService();
      customersServiceLocalInst
        .getPrivateAttachmentResource(customerId, attachmentId)
        .setConfig({ blockUiRef: blockUiId })
        .customGetWithConfig({ responseType: 'blob' })
        .then((res) => {
          const { lastResponseHeaders } = customersServiceLocalInst;
          const blob = new Blob([res], {
            type: lastResponseHeaders['content-type'],
          });
          const localFileUrl = URL.createObjectURL(blob);
          localFileUrlRef = localFileUrl;
          subscriber.next(localFileUrl);
        });

      return () => {
        if (!_.isNil(localFileUrlRef)) {
          URL.revokeObjectURL(localFileUrlRef);
        }
        this.removeValue(GoogleStorageDomain.Attachments, storageKey);
      };
    }).pipe(shareReplayRefOne());

    this.storeValue(GoogleStorageDomain.Attachments, storageKey, observable);
    return observable;
  }

  getCrmExportFileUrl(notificationId: number) {
    const crmExportServiceLocalInst = this.prfCRMExportService();
    return crmExportServiceLocalInst
      .downloadReport(notificationId)
      .then((blob) => {
        const fileName = this.getFileNameFromResponseHeaders(
          crmExportServiceLocalInst.lastResponseHeaders,
        );
        const localFileUrl = URL.createObjectURL(blob);

        return {
          fileName,
          localFileUrl,
        };
      })
      .catch((e) => {});
  }

  getBalanceLogFileUrl(notificationId: number) {
    const balanceLogsExportServiceLocalInst = this.balanceLogsExportService();
    return balanceLogsExportServiceLocalInst
      .downloadReport(notificationId)
      .then((blob) => {
        const fileName = this.getFileNameFromResponseHeaders(
          balanceLogsExportServiceLocalInst.lastResponseHeaders,
        );
        const localFileUrl = URL.createObjectURL(blob);

        return {
          fileName,
          localFileUrl,
        };
      })
      .catch((e) => {});
  }

  getKibiFileUrl(notificationId: number) {
    const kibiDownloadsServiceLocalInst = this.kibiDownloadsService;
    return kibiDownloadsServiceLocalInst
      .downloadReport(notificationId)
      .then((blob) => {
        const fileName = this.getFileNameFromResponseHeaders(
          kibiDownloadsServiceLocalInst.lastResponseHeaders,
        );
        const localFileUrl = URL.createObjectURL(blob);

        return {
          fileName,
          localFileUrl,
        };
      })
      .catch((e) => {});
  }

  getContactsImportErrorFileUrl(
    brandId: number,
    fileId: number,
    blockUiId: string,
  ) {
    const brandsServiceLocalInst = this.brandsService();
    return brandsServiceLocalInst
      .setConfig({ blockUiRef: blockUiId })
      .downloadContactsImportErrorReport(brandId, fileId)
      .then((blob) => {
        const fileName = this.getFileNameFromResponseHeaders(
          brandsServiceLocalInst.lastResponseHeaders,
        );
        const localFileUrl = URL.createObjectURL(blob);
        return {
          fileName,
          localFileUrl,
        };
      })
      .catch((e) => {});
  }

  getFileNameFromResponseHeaders(headers) {
    const contentDisposition = headers['content-disposition'];
    const fileNameAnchorString = 'filename=';
    const fileNameStartIndex =
      contentDisposition.indexOf(fileNameAnchorString) +
      fileNameAnchorString.length;
    const fileName = contentDisposition.substring(fileNameStartIndex);
    return fileName;
  }
}
