import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { SystemEmailTypesService } from '~/source/common/api-crm-server/services/system-email-types.service';

export class SystemEmailTypesStoreService {
  systemEmailTypes$ = this.streamSystemEmailTypes();

  /* @ngInject */
  constructor(
    readonly prfSystemEmailTypesService: () => SystemEmailTypesService,
  ) {}

  streamSystemEmailTypes() {
    return rx.pipe(
      () => rx.obs.from(this.prfSystemEmailTypesService().getAll()),
      shareReplayRefOne(),
    )(null);
  }
}
