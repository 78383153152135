import RestService from '~/source/common/services/rest';

export class NotificationsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'notifications';
  }

  get name() {
    return 'notifications';
  }
}

export default NotificationsService;
