import * as _ from '@proftit/lodash';
import SocketService from '~/source/common/services/socket';
import PendingPositionsStatusesForexService from './pending-positions-statuses-forex.service';

export class PendingPositionsForexSocketService extends SocketService {
  static $inject = [
    'pendingPositionsStatusesForexService',
    ...SocketService.$inject,
  ];

  pendingPositionsStatusesForexService: PendingPositionsStatusesForexService;

  get channelRoot() {
    return 'PendingOrder';
  }

  /**
   * Expand statusId in a message returned from the streamer.
   *
   * This is a magic function called in `SocketService.parse`. The data is expanded
   * mutably. The return value is not used.
   *
   * @param {object} data - pending position record message from the streamer.
   * @returns {Promise} - Promise signaling end of the expand operation.
   */
  parseStatusId(data) {
    if (_.isNil(data.statusId)) {
      return Promise.resolve();
    }

    return this.pendingPositionsStatusesForexService
      .setConfig({ suppressGrowl: true, suppressBlockUi: true })
      .getListWithQuery()
      .then((statuses) => {
        const newStatusExpanded = statuses.find(
          (status) => status.id === data.statusId,
        );

        data.status = { ...newStatusExpanded };
        return data;
      });
  }
}

export default PendingPositionsForexSocketService;
