import { CustomerStoreProviderController } from '~/source/contact/common/components/customer-store-provider/customer-store-provider.component';

const styles = require('./profile.component.scss');
import * as _ from '@proftit/lodash';
import template from './profile.html';
import CustomersService from '~/source/contact/common/services/customers';
import ModalService from '~/source/common/components/modal/modal.service';
import {
  Customer,
  Brand,
  CustomerStatus,
  UserTokenModel,
} from '@proftit/crm.api.models.entities';
import TokensService from '~/source/auth/services/tokens';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { UsersService } from '~/source/management/user/services/users';
import { createNgModelSubject } from '~/source/common/utilities/create-ng-model-subject';
import * as rx from '@proftit/rxjs';
import { StateService } from '@uirouter/core';
import {
  Permissions,
  PermissionNormalized,
} from '~/source/common/models/permission-structure';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { CUSTOMER_LINK_CHANGED } from '~/source/common/constants/general-pubsub-keys';
import { CustomerNavigationService } from '~/source/common/services/customer-navigation.service';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import ng from 'angular';
import { ExcludeBy } from '~/source/common/components/dropdowns/base/component';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';

class Controller {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  Permissions = Permissions;
  ExcludeBy = ExcludeBy;

  customersServiceInst: CustomersService;

  brand: Brand;
  customer: Customer;
  prevAttributes: Customer;
  userRoleCode: string;
  user: UserTokenModel;

  notifyContactInfoUpdatedOp$ = new rx.Subject<void>();
  switchPrimaryPhoneNumberAction$ = new rx.Subject<void>();
  updateContactInfo$ = new rx.Subject<string>();

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );
  opSelectLinkedCustomer$ = new rx.Subject<{ id: number }>();
  selectedLinkedCustomer$ = this.streamSelectedLinkedCustomer();
  selectedLinkedCustomer = createNgModelSubject<{ id: number }>(
    this.selectedLinkedCustomer$,
    this.opSelectLinkedCustomer$,
  );

  hasCustomerLinkPermission$ = this.streamHasCustomerLinkPermission();

  isCustomerLinksChanged$ = this.streamIsCustomerLinksChanged();
  isCustomerHasLinks$ = this.streamIsCustomerHasLinks();

  phoneForCopy$ = this.streamPhoneForCopy();
  secondaryPhoneForCopy$ = this.streamSecondaryPhoneForCopy();
  hasSecondaryPhone$ = this.streamHasSecondaryPhone();
  prfCustomerStoreProvider: CustomerStoreProviderController;
  propertiesMap: Record<string, string[]> = {
    name: ['firstName', 'lastName'],
    phone: ['phone'],
    phone2: ['phone2'],
    fullPhone: ['phone', 'countryPrefix'],
    fullPhone2: ['phone2', 'countryPrefix2'],
    email: ['email'],
  };

  isEdit = {
    name: false,
    phone: false,
    fullPhone: false,
    fullPhone2: false,
    email: false,
  };

  filterCustomerStatuses: (
    customerStatuses: CustomerStatus[],
  ) => CustomerStatus[];

  /* @ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly tokensService: TokensService,
    readonly modalService: ModalService,
    readonly $scope: angular.IScope,
    readonly usersService: () => UsersService,
    readonly $state: StateService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly prfCustomerNavigationService: CustomerNavigationService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    this.filterCustomerStatuses = (customerStatuses: CustomerStatus[]) => {
      return customerStatuses.filter((customerStatus) => {
        if (_.isNil(customerStatus.departmentId)) {
          return true;
        }

        if (this.user.role.code === UserRolePositionCode.SuperAdmin) {
          return true;
        }

        if (customerStatus.code === this.customer.customerStatus?.code) {
          return true;
        }

        if (
          customerStatus.departmentId ===
          (this.user.departmentId || this.user.department?.id)
        ) {
          return true;
        }

        return false;
      });
    };

    useStreams([this.customer$], this.lifecycles.onDestroy$);
  }

  private streamHasCustomerLinkPermission() {
    return checkCrudPermission(
      PermissionNormalized.ContactsLinkCustomers,
      this.PermPermissionStore,
    ).pipe(
      rx.map((p) => p.isView),
      shareReplayRefOne(),
    );
  }

  $onInit() {
    this.customersServiceInst = this.customersService();
    this.userRoleCode = this.tokensService.getCachedUser().role.code;
    this.user = this.tokensService.getCachedUser() as any;

    useStreams(
      [
        this.isCustomerHasLinks$,
        this.selectedLinkedCustomer$,
        this.selectedLinkedCustomer.asObservable(),
        this.streamMoveToLinkedCustomerPageWhenClick(),
        this.secondaryPhoneForCopy$,
        this.hasSecondaryPhone$,
        this.streamSwitchPrimaryPhoneNumber(),
        this.streamUpdateContactInfo(),
        this.hasCustomerLinkPermission$,
      ],
      this.lifecycles.onDestroy$,
    );

    this.prevAttributes = { ...this.customer };
  }

  $onChanges() {}

  $onDestroy() {}

  streamSwitchPrimaryPhoneNumber() {
    return rx.pipe(
      () => this.switchPrimaryPhoneNumberAction$,
      rx.switchMap(() =>
        this.prfCustomerStoreProvider.customerStoreService.swapPhones(),
      ),
      rx.tap(() => {
        this.cancelEditMode('fullPhone');
        this.cancelEditMode('fullPhone2');
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamHasSecondaryPhone() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.customer$,
          this.notifyContactInfoUpdatedOp$.pipe(rx.map(() => this.customer)),
        ),
      rx.map((customer) => {
        return !_.isNil(customer.phone2) && !_.isEmpty(customer.phone2);
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Stream - selected linked customer.
   *
   * @returns observable
   */
  streamSelectedLinkedCustomer() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => ({ id: this.customer.id })),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - action to move to linked customer page
   *
   * @returns observable
   */
  streamMoveToLinkedCustomerPageWhenClick() {
    return rx.pipe(
      () => this.opSelectLinkedCustomer$,
      rx.tap((customer) => {
        if (customer != null) {
          this.$state.go('crm.contacts.view', { id: customer.id });
        }
      }),
    )(null);
  }

  streamIsCustomerLinksChanged() {
    return rx.pipe(
      () =>
        this.prfClientGeneralPubsub
          .getObservable()
          .pipe(rx.filter((x) => x.key === CUSTOMER_LINK_CHANGED)),
      rx.startWith(false),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamIsCustomerHasLinks() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest({
          hasCustomerLinkPermission: this.hasCustomerLinkPermission$,
          customer: rx.obs.merge(
            this.customer$,
            this.prfClientGeneralPubsub
              .getObservable()
              .pipe(rx.filter((x) => x.key === CUSTOMER_LINK_CHANGED)),
          ),
        }),
      rx.withLatestFrom(this.customer$),
      rx.filter(
        ([{ hasCustomerLinkPermission }, customer]) =>
          hasCustomerLinkPermission,
      ),
      rx.switchMap(([{ hasCustomerLinkPermission }, customer]) => {
        if (!hasCustomerLinkPermission) {
          return rx.obs.from([false]);
        }

        return rx.obs.from(
          this.customersService()
            .setConfig({
              blockUiRef: 'blockContactInfoData',
              suppressBlockUi: true,
            })
            .getIsCustomerHasLinks(customer.id),
        );
      }),
      rx.catchError((err, caught) => rx.obs.from([[]])),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamPhoneForCopy() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.customer$,
          this.notifyContactInfoUpdatedOp$.pipe(rx.map(() => this.customer)),
        ),
      rx.map((customer) => this.calcCustomerFullPhone(customer)),
      shareReplayRefOne(),
    )(null);
  }

  streamSecondaryPhoneForCopy() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.customer$,
          this.notifyContactInfoUpdatedOp$.pipe(rx.map(() => this.customer)),
        ),
      rx.map((customer: Customer) =>
        this.calcSecondaryCustomerFullPhone(customer),
      ),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Enter edit mode for given field.
   * Backup current customer attributes and change "isEdit" to true.
   * @param {string} field - field to update
   * @return {void}
   */
  enterEditMode(field) {
    // Shallow-copy the customer fields (for backup, in case of cancelling)
    this.propertiesMap[field].forEach((fieldName) => {
      this.prevAttributes[fieldName] = this.customer[fieldName];
    });
    const maskCharacterRegex = new RegExp('(x|X)');
    if (
      ['phone', 'phone2'].includes(field) &&
      maskCharacterRegex.test(this.customer[field])
    ) {
      this.customer[field] = '';
    }
    this.isEdit[field] = true;
  }

  /**
   * Cancel edit mode for given field.
   * Restore saved customer attributes, and change isEdit to false.
   * @param {string} field - field to update
   * @return {void}
   */
  cancelEditMode(field) {
    this.propertiesMap[field].forEach((fieldName) => {
      this.customer[fieldName] = this.prevAttributes[fieldName];
    });
    this.isEdit[field] = false;
  }

  /**
   * Performs a PATCH to the customer in order to update one of the customer's field values.
   *
   * On success, changes back to display mode.
   * @param {string} field
   */

  streamUpdateContactInfo() {
    // const updatedFields = this.propertiesMap[field];
    return rx.pipe(
      () => this.updateContactInfo$,
      rx.switchMap((field) => {
        const updatedFields = this.propertiesMap[field];
        return this.prfCustomerStoreProvider.customerStoreService
          .updateCustomerInfo(updatedFields as string[])
          .pipe(rx.map(() => field));
      }),
      rx.tap((field) => (this.isEdit[field] = false)),
      rx.tap(() => this.notifyContactInfoUpdatedOp$.next()),
    )(null);
  }

  /**
   * update customer status - called on customer status change
   */
  updateCustomerStatus() {
    this.customersServiceInst
      .setConfig({
        blockUiRef: 'blockContactInfoData',
      })
      .updateCustomer(this.customer.id, {
        customerStatusId:
          this.customer.customerStatus.id !== undefined
            ? this.customer.customerStatus.id
            : null,
      });
  }

  /**
   * Open send email dialog
   */
  showEmailDialog() {
    this.modalService.open({
      component: 'prfSendEmailDialog',
      resolve: {
        brand: this.brand,
        customer: this.customer,
      },
      size: 'lg',
    });
  }

  openSendSmsDialog() {
    this.modalService.open({
      component: 'prfClickToSendDialog',
      resolve: {
        brand: this.brand,
        customer: this.customer,
      },
    });
  }

  previousCustomerOffset() {
    return parseInt(this.$state.params.currentOffset, 10);
  }

  nextCustomerOffset() {
    return parseInt(this.$state.params.currentOffset, 10) + 2;
  }

  totalCustomers() {
    return this.$state.params.total;
  }

  /**
   * Open link customers popup
   */
  openLinkCustomersPopup() {
    this.modalService.open({
      component: 'prfLinkCustomerDialog',
      resolve: {
        brand: this.brand,
        customerId: this.customer.id,
      },
      size: 'lg',
    });
  }

  calcCustomerFullPhone(customer: Customer) {
    return _.flow([
      (str) =>
        _.isNil(customer.countryPrefix)
          ? str
          : `${str}${customer.countryPrefix}`,
      (str) => (_.isNil(customer.phone) ? str : `${str}${customer.phone}`),
    ])('');
  }

  calcSecondaryCustomerFullPhone(customer: Customer) {
    return _.flow([
      (str) =>
        _.isNil(customer.countryPrefix2)
          ? str
          : `${str}${customer.countryPrefix2}`,
      (str) => (_.isNil(customer.phone2) ? str : `${str}${customer.phone2}`),
    ])('');
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  require: { prfCustomerStoreProvider: '^' },
  bindings: {
    brand: '<',
    customer: '<',
  },
};
