import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

function fromNgScopeEvent($scope, eventName) {
  return rx.Observable.create(subscriber => {
    const deregister = $scope.$on(eventName, (event, ...args) => {
      subscriber.next({
        event,
        args
      });
    });
    $scope.$on('$destroy', () => {
      subscriber.complete();
      deregister();
    });
    return () => deregister();
  });
}

function observeComponentLifecycles(contextP) {
  const context = contextP;
  const origOnInit = context.$onInit;
  const origOnDestroy = context.$onDestroy;
  const origOnChanges = context.$onChanges;
  const origPostLink = context.$postLink;

  if ([origOnInit, origOnDestroy, origOnChanges].some(x => _.isNil(x))) {
    throw new Error('All lifecycles must be implemented');
  }

  const onInit$ = new rx.Subject();
  const onInitShared$ = new rx.BehaviorSubject(false);
  const onDestroy$ = new rx.Subject();
  const onChanges$ = new rx.Subject();
  const onPostLink$ = new rx.Subject();

  context.$onInit = () => {
    origOnInit.call(context);
    onInit$.next();
    onInitShared$.next(true);
  };

  context.$onDestroy = () => {
    origOnDestroy.call(context);
    onDestroy$.next();
    onDestroy$.complete();
  };

  context.$onChanges = changesObj => {
    origOnChanges.call(context, changesObj);
    onChanges$.next(changesObj);
  };

  context.$postLink = () => {
    if (origPostLink) {
      origPostLink.call(context);
    }

    onPostLink$.next();
  };

  return {
    onInit$,
    onInitShared$,
    onDestroy$,
    onChanges$,
    onPostLink$
  };
}

function filterCompChange(propName) {
  return function operator(obs$) {
    return rx.pipe(() => obs$, rx.map(changes => changes[propName]), rx.filter(propChange => !_.isNil(propChange)))(null);
  };
}

function getCompChange(propName) {
  return function operator(obs$) {
    return rx.pipe(() => obs$, filterCompChange(propName), rx.map(a => a.currentValue))(null);
  };
}

function observeShareCompChange(onChanges$, propName) {
  return rx.pipe(() => onChanges$, getCompChange(propName), rx.shareReplay({
    bufferSize: 1,
    refCount: true
  }))(null);
}

/*
 * Hook for observing angularjs scope event.
 *
 * @param livecycles - Source component lifecycles as observables.
 * @param $scope - Source compoent scope.
 * @param eventName
 * @tapFn - Side effect work function to activate when event is trigger.
 *
 * @returns - Object includes scope event observable.
 */

function observeNgScopeEvent(lifecycles, $scope, eventName, tapFn) {
  const scopeEvent$ = fromNgScopeEvent($scope, eventName);
  scopeEvent$.pipe(rx.tap(({
    event,
    args
  }) => {
    if (tapFn) {
      tapFn(event, args);
    }
  }), rx.takeUntil(lifecycles.onDestroy$)).subscribe();
  return {
    scopeEvent$
  };
}

function useLifecycles(contextP) {
  const context = contextP;
  const origOnInit = context.$onInit;
  const origOnDestroy = context.$onDestroy;
  const origOnChanges = context.$onChanges;
  const origPostLink = context.$postLink;

  if ([origOnInit, origOnDestroy, origOnChanges].some(x => _.isNil(x))) {
    throw new Error('All lifecycles must be implemented');
  }

  const onInit$ = new rx.Subject();
  const onInitShared$ = new rx.BehaviorSubject(false);
  const onDestroy$ = new rx.Subject();
  const onChanges$ = new rx.Subject();
  const onPostLink$ = new rx.Subject();

  context.$onInit = () => {
    origOnInit.call(context);
    onInit$.next();
    onInitShared$.next(true);
  };

  context.$onDestroy = () => {
    origOnDestroy.call(context);
    onDestroy$.next();
    onDestroy$.complete();
  };

  context.$onChanges = changesObj => {
    origOnChanges.call(context, changesObj);
    onChanges$.next(changesObj);
  };

  context.$postLink = () => {
    if (origPostLink) {
      origPostLink.call(context);
    }

    onPostLink$.next();
  };

  return {
    onInit$,
    onInitShared$,
    onDestroy$,
    onChanges$,
    onPostLink$
  };
}

export { filterCompChange, fromNgScopeEvent, getCompChange, observeComponentLifecycles, observeNgScopeEvent, observeShareCompChange, useLifecycles };
