import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { AccountStatusAutomationMap } from '@proftit/crm.api.models.entities';

export const getAccountStatusAutomationMapFormGroups = (
  accountStatusAutomationMaps: AccountStatusAutomationMap[],
) => {
  return accountStatusAutomationMaps.map((map) => {
    return new FormGroup({
      customerVerifiedStatus: new FormControl({
        id: map.customerVerifiedStatus.id,
        code: map.customerVerifiedStatus.code,
        name: map.customerVerifiedStatus.name,
      }),
      accountStatus: new FormControl({
        id: map.accountStatus.id,
        code: map.accountStatus.code,
        name: map.accountStatus.name,
      }),
    } as any);
  });
};
