import { switchOn } from '@proftit/general-utilities';

function defaultMessageGenerator(messageInfo) {
  return Promise.resolve(`Validation error. Code: ${messageInfo.code}`);
}

export function generateSimpleMessage(messageInfo) {
  return switchOn({}, messageInfo.code, () =>
    defaultMessageGenerator(messageInfo),
  );
}
