import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export const streamActiveClearingCompanyConnections = (
  clearingCompanyConnectionsForBrand$: rx.Observable<any[]>,
) => {
  return rx.pipe(
    () => clearingCompanyConnectionsForBrand$,
    rx.map((connections) =>
      connections.filter((connection) => connection.isActive),
    ),
    rx.map((activeConnections) =>
      _.sortBy((connection) => connection.order, activeConnections),
    ),
    shareReplayRefOne(),
  )(null);
};
