import template from './sms-provider-form.component.html';

const styles = require('./sms-provider-form.component.scss');

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import {
  Brand,
  CommunicationProvider,
  CommunicationProviderCredentials,
  MappedDids,
} from '@proftit/crm.api.models.entities';
import { pipeLog, shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import { calcValidationsStrForVoipField } from '~/source/management/brand/components/voip/calc-validations-str-for-voip-field';
import { brandVoipValidations } from '~/source/management/brand/validations.settings';
import { FormControl } from '@proftit/ng1.reactive-forms';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { CrmAppStoreProviderController } from '../../../../../app/cfm-app-store-provider.component';

interface UiSmsCredentials extends CommunicationProviderCredentials {
  validators: string;
  translationKey: string;
}

function prepareCredentialFieldForUi(credentialField, code) {
  const validators = calcValidationsStrForVoipField(
    brandVoipValidations[credentialField.type],
    credentialField.isRequired,
  );
  const translationKey = `smsProvider.providers.${code}.${credentialField.key.toUpperCase()}`;
  return {
    ...credentialField,
    validators,
    translationKey,
  };
}

function normalizedCredentials({
  key,
  value,
}: UiSmsCredentials): CommunicationProviderCredentials {
  return { key, value };
}

export class SmsProviderFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  prfCrmAppStoreProvider: CrmAppStoreProviderController;

  onChange: (x: { smsCredentials; smsProvider; mappedDids }) => void;

  onMappedDidChanged$ = new rx.BehaviorSubject<MappedDids[]>([]);

  handleUpdateFromReact = (dataFromReact: MappedDids[]) => {
    const mappedDids = dataFromReact.filter((d) => d.dids && d.dids.length > 0);
    this.onMappedDidChanged$.next(mappedDids);
  };

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  providerControl = new FormControl<CommunicationProvider>(null);

  mergedCredentialsFields$ = this.streamMergedCredentialsFields();

  onCredentialChangeSubject = new rx.Subject<UiSmsCredentials>();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.brand$,
        this.streamProviderFromBrand(),
        this.streamNotifyOnChange(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamProviderFromBrand() {
    return rx.pipe(
      () => this.brand$,
      rx.filter(
        (brand) =>
          !_.isNil(_.get(['smsCredentials', '0', 'smsProvider'], brand)),
      ),
      rx.tap((brand) => {
        this.providerControl.model = brand.smsCredentials[0].smsProvider;
        this.onMappedDidChanged$.next(brand.smsCredentials[0].mappedDids);
      }),
    )(null);
  }

  streamMergedCredentialsFields(): rx.Observable<UiSmsCredentials[]> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.brand$,
          this.providerControl.value$ as rx.Observable<CommunicationProvider>,
        ),
      rx.filter(
        ([brand, provider]) => !_.isEmpty(brand) && !_.isEmpty(provider),
      ),
      rx.map(([brand, provider]) => {
        return provider.fields.map((field) => ({
          key: field.code,
          type: field.type,
          value: null,
          isRequired: field.isRequired,
        }));
      }),
      rx.withLatestFrom(this.brand$),
      rx.map(([providerFields, brand]) => {
        const smsCredentials =
          brand.smsCredentials.length === 0
            ? []
            : brand.smsCredentials[0].credentials;
        return providerFields.map((field) => {
          let existingFieldValues;
          if (smsCredentials.length > 0) {
            existingFieldValues = smsCredentials.find(
              (cred) => cred.key === field.key,
            );
          }
          if (_.isNil(existingFieldValues)) {
            return field;
          }
          return { ...field, ...existingFieldValues };
        });
      }),
      rx.withLatestFrom(this.providerControl.value$ as rx.Observable<any>),
      rx.map(([credentialsFields, provider]) => {
        return credentialsFields.map((credentialField) => {
          return prepareCredentialFieldForUi(credentialField, provider.code);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyOnChange() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.providerControl.value$,
          this.onCredentialChangeSubject,
          this.onMappedDidChanged$,
        ),
      rx.debounceTime(500),
      rx.withLatestFrom(
        this.providerControl.value$.pipe(
          rx.map((smsProvider: IElementRestNg<CommunicationProvider>) =>
            !_.isNil(smsProvider) && smsProvider.plain
              ? smsProvider.plain()
              : smsProvider,
          ),
        ),
        this.mergedCredentialsFields$,
        this.onMappedDidChanged$,
      ),
      rx.map(([a, smsProvider, smsCredentials, mappedDids]) => {
        return {
          smsProvider,
          mappedDids,
          smsCredentials: smsCredentials.map((credential) =>
            normalizedCredentials(credential),
          ),
        };
      }),
      rx.tap(({ smsProvider, smsCredentials, mappedDids }) => {
        return this.onChange({
          smsCredentials,
          smsProvider,
          mappedDids,
        });
      }),
    )(null);
  }
}

export const SmsProviderFormComponent = {
  template,
  controller: SmsProviderFormController,
  bindings: {
    brand: '<',
    onChange: '&',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
