import * as _ from '@proftit/lodash';

/**
 */
export function maxFloat(value, scope, element, attrs, param) {
  const parsedVal = parseFloat(value);

  if (!_.isFinite(parsedVal)) {
    return true;
  }

  return parseFloat(value) <= parseFloat(param);
}

export default maxFloat;
