import TableController from '~/source/common/components/table/table.controller';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { UserGroupsService } from '../../services/user-groups';
import deleteGroupModalTemplate from '~/source/management/group/templates/delete-group-modal.html';
import template from './group-list.html';
import { IScope } from 'angular';

class Controller extends TableController {
  static $inject = [
    ...TableController.$inject,
    '$scope',
    'userGroupsService',
    'groupsModuleSettings',
    'modalService',
  ];

  $scope: IScope;

  userGroupsService: () => UserGroupsService;
  modalService: ModalService;
  groupsModuleSettings: any;
  dataServiceInstance: UserGroupsService;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      dataServiceInstance: this.userGroupsService(),
      settings: Object.assign({}, this.groupsModuleSettings),
      cols: [...this.groupsModuleSettings.cols],
    });

    // arrow function is used here for the lexical binding
    this.$scope.$on('group.deleted', () => this.tableParams.reload());
  }

  get isInfiniteTable() {
    return true;
  }

  get tableKey() {
    return 'groups';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    return { isSystem: false };
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * Called when the user clicks the "delete" button on a group.
   *
   * Chooses which action to perform:
   * If the group is not empty - show a popup to decide what to do
   * If it is empty - simply delete it.
   * @param {object} group
   */
  onUsersGroupDelete(group) {
    if (group.totalMembers > 0) {
      this.handleDeleteGroupWithUsers(group);
    } else {
      this.deleteEmptyGroup(group);
    }
  }

  /**
   * Called on an empty group delete.
   * Deletes the group and reloads the table.
   * @param {object} group
   */
  deleteEmptyGroup(group) {
    group.remove().then(() => {
      this.$scope.$emit('group.deleted');
    });
  }

  /**
   * Called on non-empty group delete.
   * Shows a popup to decide what to do.
   * @param {object} group
   */
  handleDeleteGroupWithUsers(group) {
    this.modalService.open({
      controller: 'DeleteGroupModalController',
      template: deleteGroupModalTemplate,
      scope: this.$scope,
      data: {
        group,
      },
    });
  }
}

export const GroupListComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};
