import { IPromise } from 'angular';
import RestService from '~/source/common/services/rest';

import { IFieldsAndModulesService } from '~/source/management/fields-and-modules/i-fields-and-modules-service';
import { FieldsAndModulesModel } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

const SERVICE_RESOURCE = 'ewallets';
const BATCH_ACTION_INDICATION = '~';

export class EwalletsService extends RestService
  implements IFieldsAndModulesService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  getEwalletResource(id: number) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }

  updateIsDefault(
    id: number,
    isDefault: boolean,
  ): Promise<FieldsAndModulesModel> {
    return this.getEwalletResource(id)
      .patchWithQuery<IElementRestNg<FieldsAndModulesModel>>({ id, isDefault })
      .then((patchedModel) => patchedModel);
  }
}

export default EwalletsService;
