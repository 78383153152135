import * as _ from '@proftit/lodash';

/**
 * Move item to first on the list in an array.
 * @param compareFn
 * @param items
 * @return items array with the selected item on first.
 */
export function moveToFirst(compareFn: (x) => boolean, items: any[]) {
  if (_.isNil(items)) {
    return items;
  }

  const selectedItem = items.find(compareFn);
  if (_.isNil(selectedItem)) {
    return items;
  }

  const itemsWitout = _.reject(compareFn, items);
  const newItems = [selectedItem, ...itemsWitout];

  return newItems;
}
