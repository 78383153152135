import GroupCreateAbstractController from './createAbstract';

export class GroupAddController extends GroupCreateAbstractController {
  permissionStructuresService;
  managementPermissionsModifierService;

  /**
   * This is called getUserGroup because it returns sort-of an empty group,
   * just with the permission structure.
   *
   * in the copy controller, this method actually returns a group.
   *
   * @returns {Promise}
   */
  getUserGroup() {
    return this.permissionStructuresService
      .getListWithQuery()
      .then((permissionStructure) => ({
        permissions: this.managementPermissionsModifierService.modify(
          permissionStructure,
        ),
      }));
  }
}

GroupAddController.$inject = [
  'permissionStructuresService',
  'managementPermissionsModifierService',
  ...GroupCreateAbstractController.$inject,
];
