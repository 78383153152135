import { IScope } from 'angular';
import log from 'loglevel';

import BaseController from '~/source/common/controllers/base';
import { FilesUploadService } from '~/source/common/services/files-upload';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './brand-form.html';

class ComponentController extends BaseController {
  brand: Brand;

  logoUploadProgress: number;
  form: any;
  onLogoUpload: () => void;
  onLogoUploadDone: () => void;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly filesUploadService: FilesUploadService,
  ) {
    super();
  }

  upload(file) {
    if (!file) {
      return;
    }

    this.brand.logoId = null;

    this.filesUploadService
      .uploadPublicFile(file)
      .then(
        (resp) => {
          this.brand.logo = resp.data; // set the file for the thumbnail
          this.brand.logoId = resp.data.id; // set id for the submitted brand object
        },
        (resp) => {
          log.warn(`Error uploading file: ${resp.status}`);
        },
        (evt) => {
          this.logoUploadProgress = Math.min(
            100,
            Math.floor((100.0 * evt.loaded) / evt.total),
          );
          // this will invalidate the logo field during upload
          this.onLogoUpload();
        },
      )
      .finally(() => {
        this.onLogoUploadDone();
      });
  }
}

const BrandFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    onLogoUpload: '&',
    onLogoUploadDone: '&',
  },
};

export default BrandFormComponent;
