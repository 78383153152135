import * as _ from '@proftit/lodash';
import { switchOnEx } from '@proftit/general-utilities';

let BeanType;

(function (BeanType) {
  BeanType["ObjectBean"] = "object_bean";
  BeanType["ArrayBean"] = "array_bean";
  BeanType["ValueBean"] = "value_bean";
})(BeanType || (BeanType = {}));

function getBeanChildren(bean) {
  return bean.children;
}

function getBeanChildrenBeansAsArray(bean) {
  const children = getBeanChildren(bean);
  return switchOnEx({
    [BeanType.ObjectBean]: () => _.toPairs(children).map(([_key, child]) => child.bean),
    [BeanType.ArrayBean]: () => children.map(c => c.bean)
  }, bean.type);
}

function getBeanValidationResults(bean) {
  return bean.validationResults;
}

const ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR = 'ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR';
function allChildrenAreValidProxyValidator(bean) {
  const childrenBeans = getBeanChildrenBeansAsArray(bean);
  const childNotValid = childrenBeans.find(c => !c.isValid);

  if (_.isNil(childNotValid)) {
    return {
      isValid: true,
      code: ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR
    };
  }

  const firstNotValidResult = getBeanValidationResults(childNotValid).find(r => !r.isValid);
  return {
    isValid: false,
    code: ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR,
    payload: {
      firstNotValidResult
    }
  };
}

function addValueListenerToBean(bean, listener) {
  bean.valueListeners.push(listener);
}

function addIsValidListenerToBean(bean, listener) {
  bean.isValidListeners.push(listener);
  return bean;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

let numerator$2 = 0;
function generateValueBean(paramsP = {}) {
  const params = _objectSpread2({
    validators: []
  }, paramsP);

  numerator$2 += 1;
  return {
    type: BeanType.ValueBean,
    _id: `valueBean:${numerator$2}`,
    isNil: true,
    value: null,
    isValid: true,
    isValidListeners: [],
    valueListeners: [],
    validators: params.validators,
    validationResults: []
  };
}

/// <reference lib="dom" />
/// <reference lib="es2016" />
function setBeanProxy(beanP, proxy) {
  const bean = beanP;
  bean.proxy = proxy;
}

const BEAN_PROP_GET_KEY = '_bean';

function getBeanIsNil(bean) {
  return bean.isNil;
}

function getWholeValueOfValueBean(bean) {
  return bean.value;
}

function getWholeValueOfBean(bean) {
  if (getBeanIsNil(bean)) {
    return null;
  }

  return switchOnEx({
    [BeanType.ValueBean]: () => getWholeValueOfValueBean(bean),
    [BeanType.ArrayBean]: () => bean.proxy,
    [BeanType.ObjectBean]: () => bean.proxy
  }, bean.type);
}

function getContainerBeanChildValue(bean, key) {
  if (!_.has([key], bean.children)) {
    return undefined;
  }

  const child = bean.children[key];
  return getWholeValueOfBean(child.bean);
}

function isContainerBeanHasKey(bean, key) {
  return _.has([key], bean.children);
}

function removeValueListenerFromBean(bean, listener) {
  _.pullAllBy(l => l === listener, bean.valueListeners);

  return bean;
}

function removeIsValidListenerFromBean(bean, listener) {
  _.pullAllBy(l => l === listener, bean.isValidListeners);

  return bean;
}

function setBeanIsNil(beanP, value) {
  const bean = beanP;

  if (_.isNil(value)) {
    bean.isNil = true;
    return;
  }

  bean.isNil = false;
}

function setWholeObjectBean(bean, value) {
  _.toPairs(bean.children).forEach(([_key, child]) => {
    removeValueListenerFromBean(child.bean, child.valueListener);
    removeIsValidListenerFromBean(child.bean, child.isValidListener);
  });
  /* eslint-disable-next-line no-param-reassign */


  bean.baseValue = {};
  setBeanIsNil(bean, value);

  if (_.isNil(value)) {
    return bean;
  }

  const selfKeys = Object.keys(bean.children).sort();
  const valueKeys = Object.keys(value).sort();

  if (!_.isEqual(selfKeys, valueKeys)) {
    throw new Error(`can not whole set object been with mismach keys, selfKeys: ${JSON.stringify(selfKeys)}, valueKeys: ${JSON.stringify(valueKeys)}`);
  }

  _.flow([() => _.toPairs(bean.children), pairs => {
    pairs.forEach(([key, {
      bean
    }]) => {
      const newVal = value[key];
      setWholeBean(bean, newVal);
    });
    return pairs;
  }])();

  return bean;
}

function getArrayBeanNewItemFactory(bean) {
  return bean.newItemFactory;
}

function notifyBeanValueListeners(bean) {
  const value = getWholeValueOfBean(bean);
  bean.valueListeners.forEach(ls => ls(value));
}

function notifyContainerBeanChildValueChanged(bean, _childName, _child, _value) {
  notifyBeanValueListeners(bean);
}

function getBeanValidators(bean) {
  return bean.validators;
}

function calcBeanIsValid(bean) {
  const validators = getBeanValidators(bean);
  const allResults = validators.reduce((acc, validator) => {
    const result = validator(bean);
    acc.push(result);
    return acc;
  }, []);
  const isValid = allResults.every(r => r.isValid);
  return {
    isValid,
    results: allResults
  };
}

function setCalcBeanIsValid(beanP) {
  const bean = beanP;
  const {
    isValid,
    results
  } = calcBeanIsValid(bean);
  bean.isValid = isValid;
  bean.validationResults = results;
}

function getBeanIsValid(bean) {
  return bean.isValid;
}

function notifyBeanIsValidListeners(bean) {
  const isValid = getBeanIsValid(bean);
  bean.isValidListeners.forEach(ls => ls(isValid));
}

function setContainerBeanChild(parentP, childName, child) {
  const parent = parentP;

  const valueListener = value => {
    parent.baseValue = switchOnEx({
      [BeanType.ArrayBean]: () => [],
      [BeanType.ObjectBean]: () => {
        return parentP.proxy;
      }
    }, parent.type);
    notifyContainerBeanChildValueChanged(parent);
  };

  const isValidListener = _isValid => {
    setCalcBeanIsValid(parent);
    notifyBeanIsValidListeners(parent);
  };

  addValueListenerToBean(child, valueListener);
  addIsValidListenerToBean(child, isValidListener);
  const childData = {
    valueListener,
    isValidListener,
    bean: child
  };
  parent.children[childName] = childData;
}

function setWholeArrayBean(beanP, values) {
  const bean = beanP;
  bean.children.forEach(child => {
    removeValueListenerFromBean(child.bean, child.valueListener);
    removeIsValidListenerFromBean(child.bean, child.isValidListener);
  });
  bean.children = [];
  bean.baseValue = [];
  setBeanIsNil(bean, values);

  if (_.isNil(values)) {
    return bean;
  }

  const newItemFactory = getArrayBeanNewItemFactory(bean);
  values.forEach((v, index) => {
    const childBean = newItemFactory();
    setWholeBean(childBean, v);
    setContainerBeanChild(bean, index, childBean);
  });
  return bean;
}

function setWholeValueBean(bean, value) {
  setBeanIsNil(bean, value);
  /* eslint-disable-next-line no-param-reassign */

  bean.value = value;
  return bean;
}

function setWholeBean(bean, value) {
  switchOnEx({
    [BeanType.ObjectBean]: () => setWholeObjectBean(bean, value),
    [BeanType.ArrayBean]: () => setWholeArrayBean(bean, value),
    [BeanType.ValueBean]: () => setWholeValueBean(bean, value)
  }, bean.type);
  setCalcBeanIsValid(bean);
  notifyBeanValueListeners(bean);
  notifyBeanIsValidListeners(bean);
  return bean;
}

function getBeanType(bean) {
  return bean.type;
}

function setArrayBeanChildValue(bean, key, value) {
  const index = _.toNumber(key);

  if (!_.isFinite(index)) {
    throw new Error(`trying to set value on not number index in array. ${key}`);
  }

  const newItemFactory = getArrayBeanNewItemFactory(bean);
  const childBean = newItemFactory();
  setContainerBeanChild(bean, index, childBean);
  setWholeBean(childBean, value);
}

function setObjectBeanChildValue(bean, key, value) {
  if (!_.has([key], bean.children)) {
    throw new Error(`trying to set value for non existing field bean. field: ${key}`);
  }

  const child = bean.children[key];

  if (_.isNil(child)) {
    throw new Error('unimplemented');
  }

  setWholeBean(child.bean, value);
}

function setContainerBeanChildValue(bean, key, value) {
  if (getBeanIsNil(bean)) {
    throw new Error('trying to set value for nil bean');
  }

  return switchOnEx({
    [BeanType.ArrayBean]: () => setArrayBeanChildValue(bean, key, value),
    [BeanType.ObjectBean]: () => setObjectBeanChildValue(bean, key, value)
  }, getBeanType(bean));
}

function getOwnKeysOfObjectBean(bean) {
  return Object.keys(bean.children);
}

function getOwnKeysOfArrayBean(bean) {
  return Object.keys(bean.children);
}

function getOwnKeysOfBean(bean) {
  return switchOnEx({
    [BeanType.ObjectBean]: () => getOwnKeysOfObjectBean(bean),
    [BeanType.ArrayBean]: () => getOwnKeysOfArrayBean(bean)
  }, bean.type);
}

const ARRAY_FUNCTIONS = ['filter', 'find', 'findIndex', 'forEach', 'map', 'reduce'];
function generateProxyHandlerForArrayBean(bean, getProxyInst) {
  const handler = {
    get: (_obj, key) => {
      if (key === BEAN_PROP_GET_KEY) {
        return bean;
      }

      if (key === 'constructor') {
        return Array;
      }

      if (key === 'length') {
        return bean.children.length;
      }

      if (ARRAY_FUNCTIONS.includes(key)) {
        return Array.prototype[key].bind(getProxyInst());
      }

      if (key === 'push') {
        return pushVal => {
          const nextLength = bean.children.length + 1;
          setContainerBeanChildValue(bean, `${bean.children.length}`, pushVal);
          /* eslint-disable-next-line no-param-reassign */

          bean.children.length = nextLength;
        };
      }

      if (key === 'splice') {
        return (start, count) => {
          if (count > 1) {
            throw new Error('unimplemented splice multi');
          }

          const [deletedItem] = bean.children.splice(start, count);
          removeIsValidListenerFromBean(deletedItem.bean, deletedItem.isValidListener);
          removeValueListenerFromBean(deletedItem.bean, deletedItem.valueListener);
          notifyContainerBeanChildValueChanged(bean, start, deletedItem.bean);
          setCalcBeanIsValid(bean);
          notifyBeanIsValidListeners(bean);
          return [deletedItem];
        };
      }

      return getContainerBeanChildValue(bean, key);
    },
    getOwnPropertyDescriptor: (_target, key) => {
      if (!isContainerBeanHasKey(bean, key)) {
        return {
          value: undefined,
          writeble: true,
          enumerable: true,
          // https://stackoverflow.com/questions/40921884/create-dynamic-non-configurable-properties-using-proxy
          configurable: true
        };
      }

      const value = getContainerBeanChildValue(bean, key);
      return {
        value,
        writeble: true,
        enumerable: true,
        // https://stackoverflow.com/questions/40921884/create-dynamic-non-configurable-properties-using-proxy
        configurable: true
      };
    },
    getPrototypeOf: _target => {
      return Array;
    },
    has: (_target, key) => {
      if (key === 'length') {
        return true;
      }

      return Object.keys(bean.children).includes(key);
    },
    set: (_obj, key, value) => {
      if (key === 'length') {
        // currently not needed to handled. Maybe handle it explicitly.
        throw new Error('proxy array does not support setting length directly');
      }

      setContainerBeanChildValue(bean, key, value);
      /* Indicate success for proxy operation.
       * False will make the proxy throw error. */

      return true;
    },
    ownKeys: _target => {
      return getOwnKeysOfBean(bean);
    }
  };
  return handler;
}

// import {switchOnEx} from '@proftit/general-utilities';
function generateArrayProxy(bean) {
  let proxyInst;
  const handler = generateProxyHandlerForArrayBean(bean, () => proxyInst);
  /* prototype [];
   * https://stackoverflow.com/questions/41170131/can-i-create-an-object-for-which-array-isarray-returns-true-without-using-the
   */

  proxyInst = new Proxy([], handler);
  return proxyInst;
}

let numerator$1 = 0;
function generateArrayBean(paramsP) {
  const params = _objectSpread2({
    newItemFactory: () => generateValueBean(),
    validators: [allChildrenAreValidProxyValidator]
  }, paramsP);

  numerator$1 += 1;
  const bean = {
    type: BeanType.ArrayBean,
    _id: `arraybean-${numerator$1}`,
    baseValue: [],
    isNil: true,
    isValid: true,
    proxy: null,
    children: [],
    isValidListeners: [],
    newItemFactory: params.newItemFactory,
    valueListeners: [],
    validators: params.validators,
    validationResults: []
  };
  setBeanProxy(bean, generateArrayProxy(bean));
  return bean;
}

function generateProxyHandlerForObjectBean(bean) {
  const handler = {
    get: (_obj, key) => {
      if (key === BEAN_PROP_GET_KEY) {
        return bean;
      }

      if (key === 'constructor') {
        return Object;
      }

      return getContainerBeanChildValue(bean, key);
    },
    getOwnPropertyDescriptor: (_target, key) => {
      if (!isContainerBeanHasKey(bean, key)) {
        return {
          value: undefined,
          writeble: true,
          enumerable: true,
          // https://stackoverflow.com/questions/40921884/create-dynamic-non-configurable-properties-using-proxy
          configurable: true
        };
      }

      const value = getContainerBeanChildValue(bean, key);
      return {
        value,
        writeble: true,
        enumerable: true,
        // https://stackoverflow.com/questions/40921884/create-dynamic-non-configurable-properties-using-proxy
        configurable: true
      };
    },
    getPrototypeOf: _target => {
      return Object;
    },
    has: (_target, key) => {
      return Object.keys(bean.children).includes(key);
    },
    set: (_obj, key, value) => {
      setContainerBeanChildValue(bean, key, value);
      /* Indicate success for proxy operation.
       * False will make the proxy throw error. */

      return true;
    },
    ownKeys: _target => {
      return getOwnKeysOfBean(bean);
    }
  };
  return handler;
}

// import {switchOnEx} from '@proftit/general-utilities';

function generateObjectProxy(bean) {
  const handler = generateProxyHandlerForObjectBean(bean); // prototype {};

  const proxyInst = new Proxy({}, handler);
  return proxyInst;
}

let numerator = 0;
function generateObjectBean(paramsP) {
  const params = _objectSpread2({
    children: {},
    validators: [allChildrenAreValidProxyValidator]
  }, paramsP);

  numerator += 1;
  const bean = {
    type: BeanType.ObjectBean,
    _id: `objectbean-${numerator}`,
    isNil: true,
    isValid: true,
    proxy: null,
    baseValue: {},
    children: {},
    isValidListeners: [],
    valueListeners: [],
    validators: params.validators,
    validationResults: []
  };

  if (!_.isEmpty(params.children)) {
    _.flow([() => _.toPairs(params.children), pairs => {
      pairs.forEach(([key, childBean]) => setContainerBeanChild(bean, key, childBean));
    }])();
  }

  setBeanProxy(bean, generateObjectProxy(bean));
  return bean;
}

function getBeanProxy(bean) {
  return bean.proxy;
}

function getChildDataOfBean(parent, childName) {
  return parent.children[childName];
}

function getProxyBean(proxy) {
  /* eslint-disable-next-line no-underscore-dangle */
  return proxy._bean;
}

const LOGICAL_AND_PROXY_VALIDATOR_FACTORY = 'LOGICAL_AND_PROXY_VALIDATOR_FACTORY';
function logicalAndProxyValidatorFactory(validatorsList) {
  return function validator(bean) {
    return validatorsList.reduce((acc, validator) => {
      if (!acc.isValid) {
        return acc;
      }

      const result = validator(bean);

      if (result.isValid) {
        return acc;
      }

      return result;
    }, {
      isValid: true,
      code: LOGICAL_AND_PROXY_VALIDATOR_FACTORY
    });
  };
}

function setWholeAsInitialBean(bean, value) {
  return setWholeBean(bean, value);
}

export { ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR, addIsValidListenerToBean, addValueListenerToBean, allChildrenAreValidProxyValidator, generateArrayBean, generateObjectBean, generateValueBean, getBeanProxy, getBeanValidationResults, getChildDataOfBean, getProxyBean, getWholeValueOfBean, logicalAndProxyValidatorFactory, removeIsValidListenerFromBean, removeValueListenerFromBean, setWholeAsInitialBean, setWholeBean };
