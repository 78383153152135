import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { NotificationSettingTypeTable } from '@proftit/notification-setting.api.data';
import {
  NotificationSettingCategoryCode,
  NotificationSettingTypeCode,
} from '@proftit/notification-setting.api.enums';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { uiSortOrderNotificationSettingTypes as uiSortOrder } from './ui-sort-order-notification-setting-types';
import { sortNestedGroupedNotificationSettingTypes } from './utilities/sort-nested-grouped-notification-setting-types';

export class NotificationSettingTypeStore {
  notificationSettingTypes$ = rx.obs.of(NotificationSettingTypeTable);
  uiSortOrder = uiSortOrder;

  /* @ngInject */
  constructor() {}

  getByPlatformAndGeneral(platformType: PlatformTypeCode) {
    return rx.pipe(
      () => this.notificationSettingTypes$,
      rx.map((types) =>
        types.filter(
          (t) => t.platformType === platformType || _.isNil(t.platformType),
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  getByPlatformAndGeneralGroupByCategory(platformType: PlatformTypeCode) {
    return rx.pipe(
      () => this.getByPlatformAndGeneral(platformType),
      rx.map((types) =>
        _.groupBy((settingType) => settingType.category, types),
      ),
      shareReplayRefOne(),
    )(null);
  }

  getByPlatformAndGeneralGroupByCategorySortedbyUi(
    platformType: PlatformTypeCode,
  ) {
    return rx.pipe(
      () => this.getByPlatformAndGeneralGroupByCategory(platformType),
      rx.map((settingTypesGrouped) =>
        sortNestedGroupedNotificationSettingTypes(
          settingTypesGrouped,
          this.uiSortOrder,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }
}
