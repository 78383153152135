import headerField from '~/source/management/questionnaire/form-components/header-field';
import Component from './base/component';

class DataExportReportIndexTypeSelectController extends Component.controller {
  static $inject = ['exportIndexesService', ...Component.controller.$inject];

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource(): string {
    return null; // return null, so it will NOT be translated.
  }
}

export default Component.config({
  controller: DataExportReportIndexTypeSelectController,
});
