import { ISCEService } from 'angular';
import marked from 'marked';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import template from './general-terms.component.html';

class Controller extends BaseController {
  textPreview: string = '';
  field: any;
  generatePreviewThrottled: () => void;

  /*@ngInject*/
  constructor(readonly $sce: ISCEService) {
    super();
  }

  $onInit() {
    this.generatePreviewThrottled = _.throttleFp(300, () => {
      this.textPreview = this.$sce.trustAsHtml(marked(this.field.data));
    });

    this.generatePreview();
  }

  generatePreview() {
    this.generatePreviewThrottled();
  }
}

export const GeneralTermsComponent = {
  template,
  controller: Controller,
  bindings: {
    field: '<model',
  },
};

export default GeneralTermsComponent;
