import Promise from 'bluebird';
import Component from '~/source/common/components/dropdowns/base/component';

class BuySellTradeDirectionSelectController extends Component.controller {
  query() {
    const positionTradeDirections = [
      { id: 1, name: 'buy', translationPath: 'BUY' },
      { id: 2, name: 'sell', translationPath: 'SELL' },
    ];

    return Promise.resolve(positionTradeDirections);
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'translationPath';
  }

  /**
   * Path to translations on lang.json file
   * Must be overridden
   *
   * @returns {String}
   */
  get translationPath() {
    return 'exposure.symbolsExposure.positions';
  }
}

export const BuySellTradeDirectionSelectComponent = Component.config({
  controller: BuySellTradeDirectionSelectController,
});
