import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class DepositNotificationsController extends NotificationsController {
  static $inject = [
    'depositsSocketService',
    ...NotificationsController.$inject,
  ];
}

export default {
  ...ComponentOptions,
  controller: DepositNotificationsController,
};
