import ng, { IComponentOptions } from 'angular';
import settings from './settings.json';
import WireInfoModule from './components/wireInfo/module';
import BrandsService from './services/brands';
import DesksService from './services/desks';
import { BrandCreateComponent } from './components/brand-create/brand-create.component';
import {
  BrandUpdateComponent,
  BrandUpdateInnerComponent,
} from './components/brand-update/brand-update.component';
import BrandsListController from './controllers/list';
import BlacklistedCountriesDataComponent from './components/blacklistedCountries/blacklisted-countries-data';
import BlacklistedCountriesFormComponent from './components/blacklistedCountries/blacklisted-countries-form';
import BrandDataComponent from './components/brand/brand-data';
import BrandFormComponent from './components/brand/brand-form/brand-form';
import CmsDataComponent from './components/cms/cms-data';
import CmsFormComponent from './components/cms/cms-form';
import CurrencyDataComponent from './components/currency/currency-data';
import CurrencyFormComponent from './components/currency/currency-form';
import DeskDataComponent from './components/desk/desk-data';
import DeskFormComponent from './components/desk/desk-form';
import MinimumDepositDataComponent from './components/minimum-deposit-data/minimum-deposit-data';
import MinimumDepositFormComponent from './components/minimum-deposit-form/minimum-deposit-form';
import VerifiedMethodDataComponent from './components/verifiedMethod/verified-method-data';
import VerifiedMethodFormComponent from './components/verifiedMethod/verified-method-form';
import VoipDataComponent from './components/voip/voip-data';
import VoipFormComponent from './components/voip/voip-form';

import { SmsProviderDataComponent } from './components/sms-provider/sms-provider-data/sms-provider-data.component';
import { SmsProviderFormComponent } from './components/sms-provider/sms-provider-form/sms-provider-form.component';
import { CredentialsFieldsRendererComponent } from './components/sms-provider/credentials-fields-renderer/credentials-fields-renderer.component';
import { SmsProviderManagerComponent } from './components/sms-provider/sms-provider-manager/sms-provider-manager.component';
import { BrandWithdrawalsComponent } from './components/brand-withdrawals/brand-withdrawals.component';
import { BrandWithdrawalsCreateComponent } from './components/brand-withdrawals-create/brand-withdrawals-create.component';
import { LoginMethodFormComponent } from './components/login-method-form/login-method-form.component';
import { SecurityManagerComponent } from './components/security/security-manager/security-manager.component';
import { SecurityDataComponent } from './components/security/security-data/security-data.component';
import { SecurityFormComponent } from './components/security/security-form/security-form.component';
import { BrandLoginMethodDataComponent } from './components/brand-login-method-data/brand-login-method-data.component';
import { EmailProviderManagerComponent } from './components/email-provider/email-provider-manager/email-provider-manager.component';
import { EmailProviderDataComponent } from '~/source/management/brand/components/email-provider/email-provider-data/email-provider-data.component';
import { EmailProviderFormComponent } from '~/source/management/brand/components/email-provider/email-provider-form/email-provider-form.component';
import { DecimalModelConverterComponent } from '~/source/management/brand/components/minimum-deposit-form/decimal-model-converter';
import { VoipProvidersListComponent } from '~/source/management/brand/components/voip/voip-providers-list/voip-providers-list.component';
import { brandRouting } from './brand.routing';

const ngModule = ng.module('crm.brand', [
  'crm.constants',
  'crm.common',
  'ui.router',
  WireInfoModule,
]);
const modulePath = 'management/brand';

const components: { token: string; comp: IComponentOptions }[] = [
  { token: 'prfBrandUpdate', comp: BrandUpdateComponent },
  { token: 'prfBrandUpdateInner', comp: BrandUpdateInnerComponent },
];

components.forEach(({ token, comp }) => ngModule.component(token, comp));

ngModule
  .config(brandRouting)
  .constant('brandModuleSettings', settings)
  .factory('brandsService', BrandsService.$factorize())
  .factory('desksService', DesksService.$factorize())
  .controller('BrandsListController', BrandsListController)
  .component('prfBrandCreate', BrandCreateComponent)
  .component('prfBlacklistedCountriesData', BlacklistedCountriesDataComponent)
  .component('prfBlacklistedCountriesForm', BlacklistedCountriesFormComponent)
  .component('prfBrandData', BrandDataComponent)
  .component('prfBrandForm', BrandFormComponent)
  .component('prfCmsData', CmsDataComponent)
  .component('prfCmsForm', CmsFormComponent)
  .component('prfCurrencyData', CurrencyDataComponent)
  .component('prfCurrencyForm', CurrencyFormComponent)
  .component('prfDeskData', DeskDataComponent)
  .component('prfDeskForm', DeskFormComponent)
  .component('prfMinimumDepositData', MinimumDepositDataComponent)
  .component('prfMinimumDepositForm', MinimumDepositFormComponent)
  .component('prfVerifiedMethodData', VerifiedMethodDataComponent)
  .component('prfVerifiedMethodForm', VerifiedMethodFormComponent)
  .component('prfVoipProvidersList', VoipProvidersListComponent)
  .component('prfBrandVoipData', VoipDataComponent)
  .component('prfBrandVoipForm', VoipFormComponent)
  .component('prfSmsProviderManager', SmsProviderManagerComponent)
  .component('decimalModelConverter', DecimalModelConverterComponent)
  .component('prfSmsProviderData', SmsProviderDataComponent)
  .component('prfSmsProviderForm', SmsProviderFormComponent)
  .component('prfCredentialsFieldsRenderer', CredentialsFieldsRendererComponent)
  .component('prfBrandWithdrawals', BrandWithdrawalsComponent)
  .component('prfBrandWithdrawalsCreate', BrandWithdrawalsCreateComponent)
  .component('prfLoginMethodForm', LoginMethodFormComponent)
  .component('prfSecurityManager', SecurityManagerComponent)
  .component('prfSecurityData', SecurityDataComponent)
  .component('prfSecurityForm', SecurityFormComponent)
  .component('prfBrandLoginMethodData', BrandLoginMethodDataComponent)
  .component('prfEmailProviderManager', EmailProviderManagerComponent)
  .component('prfEmailProviderData', EmailProviderDataComponent)
  .component('prfEmailProviderForm', EmailProviderFormComponent);

export default ngModule.name;
