import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './account-search-result.component.html';
import { TradingAccount } from '@proftit/crm.api.models.entities';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

export class AccountSearchResultController extends BaseController {
  result: TradingAccount;
  PermissionNormalized = PermissionNormalized;
}

export const AccountSearchResultComponent = {
  template,
  controller: AccountSearchResultController,
  bindings: {
    result: '<',
    highlightedField: '<',
  },
};
