import * as _ from '@proftit/lodash';
import ng from 'angular';
import log from 'loglevel';

import BaseController from '~/source/common/controllers/base';
import ModalService from '~/source/common/components/modal/modal.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import useStream from '~/source/common/utilities/use-stream';

const baseStyles = require('./base-statuses.scss');

import * as rx from '@proftit/rxjs';
import { FieldsAndModulesModel } from '@proftit/crm.api.models.entities/src';
import { Department } from '~/source/common/models/department';

class BaseStatusesController extends BaseController {
  static $inject = ['$scope', 'modalService', '$translate'];
  baseStyles = baseStyles;

  modalService: ModalService;
  $translate: ng.translate.ITranslateService;

  dataService: any;
  statuses: any[];
  deletePopup: ng.ui.bootstrap.IModalInstanceService;

  currentlyEditedModels = new Set();

  // create object for previous values
  prevValues = {};
  shouldAlwaysHaveOneDefault = false;
  shouldAlwaysHaveOneAutoAfterFtd = false;
  shouldShowDefaultCheckbox = true;
  shouldShowAutoAfterFtdCheckbox = false;

  searchTerm$ = new rx.BehaviorSubject<string>('');
  isTableInitialized$ = new rx.BehaviorSubject<boolean>(false);
  showNewButton$ = this.streamShowNewButtonDefault();
  unsub$ = new rx.Subject<void>();

  set searchTerm(value) {
    this.searchTerm$.next(value);
  }

  get searchTerm() {
    return this.searchTerm$.getValue();
  }

  $onInit() {
    this.getStatuses().then(() => this.isTableInitialized$.next(true));

    useStream(this.streamPerformSearch(), this.unsub$);
  }

  $onDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamPerformSearch() {
    return rx.pipe(
      () => this.searchTerm$,
      rx.debounceTime(600),
      rx.map((searchTerm) => {
        this.getStatuses(searchTerm);
      }),
    )(null);
  }

  /**
   * Observable for showing the new button
   *
   * @return observable
   */
  streamShowNewButtonDefault() {
    return rx.pipe(() => rx.obs.from([true]))(null);
  }

  shouldShowEditButton(
    model,
    shouldShowDefaultCheckbox,
    shouldShowAutoAfterFtdCheckbox,
  ) {
    if (this.currentlyEditedModels.has(model.id)) {
      return false;
    }
    if (!model.isSystem) {
      return true;
    }
    if (shouldShowDefaultCheckbox || shouldShowAutoAfterFtdCheckbox) {
      return true;
    }
    return false;
  }

  isRowDefaultAndLocked(isSelected: boolean) {
    if (this.shouldAlwaysHaveOneDefault && isSelected) {
      return true;
    }
    return false;
  }

  isRowAutoAfterFtdAndLocked(isSelected: boolean) {
    if (this.shouldAlwaysHaveOneAutoAfterFtd && isSelected) {
      return true;
    }
    return false;
  }

  /**
   * Calculate and get the update confirm dialog needed values.
   *
   * @param (object) model
   * @return {Promise} the promise that will be resolved to the dialog values.
   * @private
   */
  getUpdateConfirmValues(model) {
    return Promise.all([
      this.getUpdateConfirmHeader(),
      this.getUpdateConfirmConfirmText(model),
      this.$translate('common.OK'),
      this.$translate('common.CANCEL'),
      this.getConfirmDialogTheme(),
    ]).then(([header, confirmText, ok, cancel, dialogTheme]) => ({
      header,
      confirmText,
      ok,
      cancel,
      dialogTheme,
    }));
  }

  /**
   * Calculate and get the delete confirm dialog needed values.
   *
   * @param (object) model
   * @return {Promise} the promise that will be resolved to the dialog values.
   * @private
   */
  getDeleteModalValues(model) {
    return Promise.all([
      this.getDeleteHeader(),
      this.getDeleteText(model),
      this.$translate('common.OK'),
      this.$translate('common.CANCEL'),
      this.getDeleteDialogTheme(),
    ]).then(([header, confirmText, ok, cancel, dialogTheme]) => ({
      header,
      confirmText,
      ok,
      cancel,
      dialogTheme,
    }));
  }

  /**
   * Calc the update confirm dialog header.
   *
   * @return {Promise} Promise that will be resolved to the header value;
   */
  getUpdateConfirmHeader() {
    return this.$translate('statuses.CONFIRM_MODAL_TITLE');
  }

  /**
   * Calc the delete confirm dialog header.
   *
   * @return {Promise} Promise that will be resolved to the header value;
   */
  getDeleteHeader() {
    return this.$translate('statuses.DELETE_MODAL_TITLE');
  }

  /**
   * Calc the update confirm theme class.
   *
   * @return {Object} with class, can add more styles;
   */
  getConfirmDialogTheme() {
    return { class: 'confirm' };
  }

  /**
   * Calc the delete confirm dialog header.
   *
   * @return {Object} with class, can add more styles;
   */
  getDeleteDialogTheme() {
    return { class: 'delete' };
  }

  /**
   * Calc the update confirm dialog confirm text.
   *
   * @return {Promise} Promise that will be resolved to the confirm text value;
   */
  getUpdateConfirmConfirmText(model) {
    return this.$translate('statuses.CONFIRM_TEXT', {
      from: this.prevValues[model.id],
      to: model.name,
    });
  }

  /**
   * Calc the delete confirm dialog confirm text.
   *
   * @return {Promise} Promise that will be resolved to the confirm text value;
   */
  getDeleteText(model) {
    return this.$translate('statuses.DELETE_TEXT', {
      status: this.prevValues[model.id],
    });
  }

  /**
   * Section name. Must be overriden by subclasses
   * Used for growl and blockUi
   */
  get sectionName() {
    return 'Base';
  }

  /**
   * Allow inheriting components to define their own required filter by
   * overriding this method.
   *
   * @returns {object} required filters
   */
  requiredApiFilters() {
    return {};
  }

  /*
   * get a list of statuses from bounded data service instance
   */
  getStatuses(searchTerm = null) {
    /*
     * This component is a crud list items screen. The desire is to show the
     * latest current data.
     *
     * Since the data service is using cache, we need to bust it so the fetch
     * will bring fresh data.
     */
    this.dataService.cacheEmpty();

    let filter = { ...this.requiredApiFilters() };
    if (!_.isEmpty(searchTerm)) {
      filter = { ...filter, q: searchTerm };
    }

    return this.dataService
      .setConfig({
        blockUiRef: `${this.sectionName}BlockUi`,
        growlRef: `${this.sectionName}Growl`,
        errorsTranslationPath: 'statuses.errors',
      })
      .filter(filter)
      .getListWithQuery()
      .then((list) => {
        this.statuses = list;
      });
  }

  /**
   * update statuses with altered status name
   * @param {Object} model - Restangular model to patch
   */
  update(model) {
    model.patch().then(() => {
      this.currentlyEditedModels.delete(model.id);
    });
  }

  /**
   * Hook to perform additinal model transformation before the additon operation.
   *
   * Can be override by subclasses.
   *
   * @param model
   * @return transformed model
   */
  beforeModelAdd(model) {
    return {
      name: model.name,
    };
  }

  /**
   * add a new status
   * @param {Restangular} model status model
   */
  add(modelP) {
    const model = this.beforeModelAdd(modelP);

    return this.dataService.postWithQuery(model).then((res) => {
      // close edit mode
      this.currentlyEditedModels.delete(modelP.id);
      this.updateModelByServerResponse(res);
    });
  }

  /**
   * Update and replace added item with the restangular server response item
   *
   * We need to replace the reference, not only merge into it. That is because
   * doing only merging is make the newly merged restangular object behave
   * incorrectly when making update request (without refresh). This is due to
   * probebly the losing of the prototype method scope connection. Restangular
   * does duck typing for the element restangular methods.
   *
   * @param {object}
   * @return {void}
   */
  updateModelByServerResponse(serverModel: IElementRestNg<any>) {
    const idx = this.findModelIndexInListByUniqeField(
      this.statuses,
      serverModel,
    );
    if (idx < 0) {
      log.error(
        'added model not found to be updated from server',
        serverModel,
        this.statuses,
      );
      return;
    }

    // replace temporary model object with restangular response.
    this.statuses.splice(idx, 1, serverModel);
  }

  /**
   * Find an item in the statuses/list by it's unique property.
   *
   * @param {[object]} list - the model list.
   * @param {object} serverModel - the returned restangularized server response model.
   * @return {number} the index found
   */
  findModelIndexInListByUniqeField(list, serverModel) {
    return list.findIndex((item) => item.name === serverModel.name);
  }

  /**
   * add a new status
   * @param {Restangular} model status model
   * @param {ElasticFieldsElementController} $element elastic ui element includes contract method
   */
  delete(model, $element) {
    return model.remove().then(() => {
      // remove element from ui
      $element.contract();
      // close edit mode
      this.currentlyEditedModels.delete(model.id);
    });
  }

  /**
   * add a new status input field to statuses list which is visible in the ui and make sure its in edit mode
   * @param {ElasticFieldsCollectionController} $collection elastic ui collection includes expand method
   */
  addStatusSlot($collection) {
    // add a new status slot
    $collection.expand();

    // starts new status in edit mode
    const newStatusModel = _.lastEs(this.statuses);
    newStatusModel._isNew = true;
    this.enterEditMode(newStatusModel);
  }

  /**
   * enter edit mode and keep name values for future restore operation
   * @param {Restangular} model status model
   */
  enterEditMode(model) {
    this.prevValues[model.id] = model.name;
    this.currentlyEditedModels.add(model.id);
  }

  /**
   * close edit and restore previous name, in case is a new status remove it from statuses & ui
   * @param {Restangular} model status model
   * @param {ElasticFieldsCollectionController} $collection elastic ui collection includes expand method
   */
  cancelEditMode(model, $collection) {
    // existing status,
    if (model.id) {
      model.name = this.prevValues[model.id];
      this.currentlyEditedModels.delete(model.id);
    }

    // remove status slot
    if (model.id === undefined) {
      $collection.contract(model);
    }
  }

  /**
   * open a confirm modal when a user want to edit status
   * @param {Restangular} model status model
   * @returns {void}
   */
  openConfirmPopup(model) {
    // nothing changed so do nothing
    if (this.prevValues[model.id] === model.name) {
      return Promise.resolve();
    }

    return this.getUpdateConfirmValues(model).then((trans) => {
      const updatePopup = this.modalService.open({
        component: 'prfConfirmDialog',
        resolve: {
          headerText: () => trans.header,
          confirmText: () => trans.confirmText,
          okText: () => trans.ok,
          cancelText: () => trans.cancel,
          dialogTheme: () => trans.dialogTheme,
        },
      });

      // dialog cancel op throws. therefor we need to catch it and dismiss it.
      updatePopup.result
        .then(() => {
          this.update(model);
        })
        .catch(() => {});
    });
  }

  /**
   * open a confirm modal when a user want to delete status
   * @returns {void}
   */
  openDeleteConfirmPopup(model, $element) {
    return this.getDeleteModalValues(model).then((trans) => {
      const DeletePopup = this.modalService.open({
        component: 'prfConfirmDialog',
        resolve: {
          headerText: () => trans.header,
          confirmText: () => trans.confirmText,
          okText: () => trans.ok,
          cancelText: () => trans.cancel,
          dialogTheme: () => trans.dialogTheme,
        },
      });
      // dialog cancel op throws. therefor we need to catch it and dismiss it.
      DeletePopup.result
        .then(() => {
          this.delete(model, $element);
          DeletePopup.close();
        })
        .catch((err) => {
          log.warn('err removing element ::', err);
        });
    });
  }

  /**
   * Signal for the view that the model is not to be deleted.
   *
   * @param {object} model - the model.
   * @return {boolean} true to enable delete, false otherwise.
   */
  isDeleteEnabled(model) {
    return true;
  }

  /**
   * Get indication for showing filter bar.
   *
   * Can be override in subclasses.
   *
   * @return indication for showing the bar
   */
  showFiltersBar() {
    return false;
  }

  setDefaultValue(
    allModels: FieldsAndModulesModel[],
    model: FieldsAndModulesModel,
  ) {
    const { id, isDefault } = model;
    this.dataService
      .updateIsDefault(id, isDefault)
      .then((newModel) => {
        allModels.forEach((oldModel) => {
          if (oldModel.id !== newModel.id) {
            oldModel.isDefault = false;
          }
        });
      })
      // reverting isDefault to whatever value it was before the call to the API
      .catch(() => {
        model.isDefault = !model.isDefault;
      });
  }

  setAutoAfterFtd(
    allModels: FieldsAndModulesModel[],
    model: FieldsAndModulesModel,
  ) {
    const { id, isAutoAfterFtd } = model;

    this.dataService
      .updateIsAutoAfterFtd(id, isAutoAfterFtd)
      .then((newModel) => {
        allModels.forEach((oldModel) => {
          if (oldModel.id !== newModel.id) {
            oldModel.isAutoAfterFtd = false;
          }
        });
      })
      // reverting isDefault to whatever value it was before the call to the API
      .catch(() => {
        model.isAutoAfterFtd = !model.isAutoAfterFtd;
      });
  }

  setDepartment(
    allModels: CustomerStatusFieldsAndModulesModel[],
    model: CustomerStatusFieldsAndModulesModel,
  ) {
    const { id, department } = model;
    this.dataService
      .updateDepartment(id, department?.id)
      .then((newModel) => {})
      .catch(() => {});
  }
}

interface CustomerStatusFieldsAndModulesModel extends FieldsAndModulesModel {
  department: Department;
}

export default BaseStatusesController;
