import * as rx from '@proftit/rxjs';

/**
 *
 */
export function createObsGetterSetter<T>(propName: string, context: Object) {
  const obs$ = new rx.BehaviorSubject<T>(null);
  let prevValue = undefined;

  Object.defineProperty(context, propName, {
    get() {
      return obs$.getValue();
    },

    set(value: T) {
      if (prevValue === value) {
        return;
      }

      prevValue = value;
      obs$.next(value);
    },
  });

  return obs$;
}
