import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-deposit-row.component.html';
import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { Currency } from '@proftit/crm.api.models.entities';
import { MarketingCampaignRewardType } from '~/source/common/data-sources/marketing-campaign-rewards-source';
const styles = require('./automatic-bonus-deposit-row.component.scss');

export class AutomaticBonusDepositRowController {
  MarketingCampaignRewardType = MarketingCampaignRewardType;
  isEdit: boolean;
  rowIndex: number;
  currency: Currency;
  rewardType: MarketingCampaignRewardType;
  onAddDepositRow: (a: { currencyId; fromValue }) => void;
  onDeleteDepositRow: (a: { rowUuid }) => void;
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AutomaticBonusDepositRowComponent = {
  template,
  controller: AutomaticBonusDepositRowController,
  bindings: {
    isEdit: '<',
    rowIndex: '<',
    currency: '<',
    formGroup: '<',
    shouldShowTrashIcon: '<',
    rewardType: '<',
    onAddDepositRow: '&',
    onDeleteDepositRow: '&',
  },
};
