import * as _ from '@proftit/lodash';
import { convertStringToCustomerPropertyValue } from '../utilities/convert-string-to-customer-property-value';
import { convertCustomerPropValueToPresentationText } from '../utilities/convert-customer-prop-value-to-presentation-text';
import { switchOn } from '../utilities/switch-on';
import angular from 'angular';

function convertToCustomerPropertyValuePresenation(value, typeHint) {
  return _.flow([
    () => convertStringToCustomerPropertyValue(value, typeHint),
    (text) => convertCustomerPropValueToPresentationText(typeHint, text),
  ])(null);
}

function convertLanguage($translate, langParam) {
  if (_.isEmpty(langParam)) {
    return '';
  }

  return _.flow(
    () => langParam,
    (lang) => lang.split('.'),
    (langParts) => `${langParts[0].toLowerCase()}.${langParts[1]}`,
    (lang) => $translate.instant(lang),
  )();
}

function convertToCustomerOrigPropertyValuePresentation(
  $translate,
  value,
  field,
) {
  return switchOn(
    {
      languageId: () => convertLanguage($translate, value),
    },
    field,
    () => value,
  );
}

export function auditLogFieldDisplay(
  $translate: angular.translate.ITranslateService,
) {
  return function (value, modelName, field, typeHint) {
    try {
      return switchOn(
        {
          CustomerPropertyValue: () =>
            convertToCustomerPropertyValuePresenation(value, typeHint),
          Customer: () =>
            convertToCustomerOrigPropertyValuePresentation(
              $translate,
              value,
              field,
            ),
        },
        modelName,
        () => {
          return value;
        },
      );
    } catch {
      return value;
    }
  };
}
