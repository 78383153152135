import ng from 'angular';
import { contactsImportRouting } from './contacts-import.routing';
import { ContactsImportDashboardComponent } from './containers/contacts-import-dashboard/contacts-import-dashboard.component';
import { ContactsImportTableComponent } from '~/source/management/contacts-import/components/contacts-import-table/contacts-import-table.component';
import { ContactsImportCreateComponent } from '~/source/management/contacts-import/components/contacts-import-create/contacts-import-create.component';
import { SetUpImportFilesInfoComponent } from '~/source/management/contacts-import/components/set-up-import-files-info/set-up-import-files-info.component';
import { ImportRequirementsInfoComponent } from '~/source/management/contacts-import/components/import-requirements-info/import-requirements-info.component';
import { DownloadTemplateInfoComponent } from '~/source/management/contacts-import/components/download-template-info/download-template-info.component';
import { ImportFileFormComponent } from '~/source/management/contacts-import/components/import-file-form/import-file-form.component';
import { ContactsImportConfirmDialogComponent } from '~/source/management/contacts-import/components/contacts-import-confirm-dialog/contacts-import-confirm-dialog.component';

const dependencies = [];

export const ContactsImportModule = ng.module(
  'crm.management.contacts-import',
  dependencies,
);

ContactsImportModule.config(contactsImportRouting);

ContactsImportModule.component(
  'prfContactsImportDashboard',
  ContactsImportDashboardComponent,
)
  .component('prfContactsImportTable', ContactsImportTableComponent)
  .component('prfContactsImportCreate', ContactsImportCreateComponent)
  .component('prfSetUpImportFilesInfo', SetUpImportFilesInfoComponent)
  .component('prfImportRequirementsInfo', ImportRequirementsInfoComponent)
  .component('prfDownloadTemplateInfo', DownloadTemplateInfoComponent)
  .component('prfImportFileForm', ImportFileFormComponent)
  .component('prfContactsConfirmDialog', ContactsImportConfirmDialogComponent);
