import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import CustomerPropertyTypesService from '~/source/common/services/customer-property-types.service';
import CustomerPropertiesService from '~/source/common/services/customer-properties.service';
import CustomerPropertyType from '~/source/common/models/customer-property-type';
import CustomerProperty from '~/source/common/models/customer-property';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import useStream from '~/source/common/utilities/use-stream';

import template from './customer-dynamic-properties-editor-manager.component.html';
import * as rx from '@proftit/rxjs';

class Controller extends BaseController {
  unsub$ = new rx.Subject<void>();
  customerPropertiesTypes$ = new rx.BehaviorSubject<CustomerPropertyType[]>([]);
  customerProperties$ = new rx.BehaviorSubject<
    IElementRestNg<CustomerProperty>[]
  >([]);
  opSaveCustomerProperty$ = new rx.Subject<{ id: number; info: any }>();
  opDeleteCustomerProperty$ = new rx.Subject<number>();

  /*@ngInject */
  constructor(
    readonly customerPropertyTypesService: CustomerPropertyTypesService,
    readonly customerPropertiesService: CustomerPropertiesService,
  ) {
    super();
  }

  $onInit() {
    useStream(
      this.streamCustomerPropertiesTypes(
        rx.obs.from(this.getCustomerPropertiesTypes()),
      ),
      this.unsub$,
    );

    useStream(
      this.streamCustomerProperties(rx.obs.from(this.getCustomerProperties())),
      this.unsub$,
    );

    useStream(
      this.streamSaveCustomerProperty(
        this.opSaveCustomerProperty$,
        this.customerProperties$,
      ),
      this.unsub$,
    );

    useStream(
      this.streamDeleteCustomerProperty(
        this.opDeleteCustomerProperty$,
        this.customerProperties$,
      ),
      this.unsub$,
    );
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamCustomerPropertiesTypes(
    customerPropertiesTypesP$: rx.Observable<CustomerPropertyType[]>,
  ) {
    return rx.pipe(
      () => customerPropertiesTypesP$,
      rx.tap((propTypes) => this.customerPropertiesTypes$.next(propTypes)),
    )(null);
  }

  streamCustomerProperties(
    customerPropertiesP$: rx.Observable<ICollectionRestNg<CustomerProperty>>,
  ) {
    return rx.pipe(
      () => customerPropertiesP$,
      rx.tap((props) => this.customerProperties$.next(props)),
    )(null);
  }

  streamSaveCustomerProperty(
    opSaveCustomerProperty$: rx.Subject<{ id: number; info: any }>,
    customerProperties$: rx.Observable<IElementRestNg<CustomerProperty>[]>,
  ) {
    return rx.pipe(
      () => opSaveCustomerProperty$,
      rx.withLatestFrom(customerProperties$),
      rx.mergeMap(([{ id, info }, props]) => {
        if (_.isNil(id)) {
          return rx.obs.from(this.customerPropertiesService.insert(info));
        }

        const foundItem = props.find((prop) => prop.id === id);
        return rx.obs.from((foundItem.patch(info) as any) as Promise<any>);
      }),
      rx.mergeMap((x) => rx.obs.from(this.getCustomerProperties())),
      rx.tap((props) => this.customerProperties$.next(props)),
      rx.catchError((err, caught) => {
        return caught;
      }),
    )(null);
  }

  streamDeleteCustomerProperty(
    opDeleteCustomerProperty$: rx.Observable<number>,
    customerProperties$: rx.Observable<IElementRestNg<CustomerProperty>[]>,
  ) {
    return rx.pipe(
      () => opDeleteCustomerProperty$,
      rx.withLatestFrom(customerProperties$),
      rx.map(([id, props]) => props.find((prop) => prop.id === id)),
      rx.switchMap((prop) =>
        rx.obs.from((prop.remove() as any) as Promise<any>),
      ),
      rx.switchMap((x) => rx.obs.from(this.getCustomerProperties())),
      rx.tap((props) => this.customerProperties$.next(props)),
      rx.catchError((err, caught) => {
        return caught;
      }),
    )(null);
  }

  getCustomerPropertiesTypes() {
    return this.customerPropertyTypesService
      .getListWithQuery<IElementRestNg<CustomerPropertyType>>()
      .then((items) => items.plain());
  }

  getCustomerProperties() {
    return this.customerPropertiesService.getListWithQuery<
      IElementRestNg<CustomerProperty>
    >();
  }
}

const CustomerDynamicPropertiesEditorManagerComponent = {
  template,
  controller: Controller,
  bindings: {},
};

export default CustomerDynamicPropertiesEditorManagerComponent;
