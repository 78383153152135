import RestService from '~/source/common/services/rest';

export class CustomerIdTypeService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string} name identifier of the resource
   */
  get resource() {
    return 'customerIdTypes';
  }

  useCache(): boolean {
    return true;
  }
}

export default CustomerIdTypeService;
