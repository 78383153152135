import type ng from 'angular';
import RestService from '~/source/common/services/rest';
import { IPromise } from 'restangular';
import { UserRolePosition } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { TimeInterval } from '@proftit/constants.time';

export class RolesService extends RestService {
  /**
   * Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'roles';
  }

  useCache() {
    return true;
  }

  getVisibleRolesReversed(): Promise<UserRolePosition[]> {
    return this.exclude('code', 'extapi')
      .sort('hierarchy', 'desc')
      .getListWithQuery<IElementRestNg<UserRolePosition>>();
  }

  /**
   * Override default cache options
   */
  get cacheOptions() {
    return {
      maxAge: TimeInterval.Day,
    };
  }

  getAll(): Promise<UserRolePosition[]> {
    return this.getListWithQuery<
      IElementRestNg<UserRolePosition>
    >().then((data) => data.plain());
  }
}

export default RolesService;
