import template from './signup-form-confirm-terms-field.component.html';
const styles = require('./signup-form-confirm-terms-field.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class SignupFormConfirmTermsFieldController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SignupFormConfirmTermsFieldComponent = {
  template,
  controller: SignupFormConfirmTermsFieldController,
  bindings: {
    field: '<model',
  },
};
