import template from './renderer.component.html';

const styles = require('./renderer.component.scss');

import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { PlayerState } from '../player-state';

export class RendererController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  PlayerState = PlayerState;

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const RendererComponent = {
  template,
  bindings: {
    maxVolume: '<',
    volumeScalesAmount: '<',
    duration: '<',
    currentTimePosition: '<',
    currentVolume: '<',
    playStatus: '<',
    onPlayClick: '&',
    onPauseClick: '&',
    onStopClick: '&',
    onTimelineClick: '&',
    onVolumeScalaClick: '&',
    onVolumePlusClick: '&',
    onVolumeMinusClick: '&',
  },
  controller: RendererController,
};
