import { IScope } from 'angular';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { generateUuid } from '@proftit/general-utilities';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand, User } from '@proftit/crm.api.models.entities';

import template from './brand-desk-form.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';

class ComponentController extends BaseController {
  brand: Brand;
  user: User;
  getLogo: () => Promise<Brand>;

  compId = generateUuid();
  lifecycles = observeComponentLifecycles(this);

  brandsServiceInst: BrandsService;
  brandLogo: string;
  excludedBrands: number[];

  brands$ = observeShareCompChange(this.lifecycles.onChanges$, 'brands');

  usedBrandIds$ = new rx.BehaviorSubject<number[]>([]);

  availableBrands$ = this.streamAvailableBrands();

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly brandsService: () => BrandsService,
  ) {
    super();
    useStreams(
      [this.brands$, this.availableBrands$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    Object.assign(this, {
      brandsServiceInst: this.brandsService(),
      brandLogo: null,
    });

    this.$scope.$watch('vm.brand', () => {
      /*
       * Because of a bug in UI select
       * n===o condition is needed
       */
      if (!this.brand || !this.brand.id) {
        return;
      }

      this.getLogo().then((brand) => {
        this.brandLogo = brand.logo;
      });
    });

    this.$scope.$watchCollection(
      () => _.defaultTo([], _.get(['brands'], this.user)),
      this.onUserBrandsChange.bind(this),
    );
  }

  $onDestroy() {}

  /**
   * Called when there is a change in the user 'brands' array, or on one of its elements.
   * @return {void}
   */
  onUserBrandsChange() {
    // an array of the brands selected in the other user's brand forms
    this.excludedBrands = (_.defaultTo(
      [],
      _.get(['brands'], this.user),
    ) as Brand[])
      .filter(
        (brand) =>
          _.has('id', brand) && // ignore empty objects
          // ignore current form's platform
          brand.id !== _.get(['id'], this.brand),
      )
      // we just need the id, for the 'exclude' binding of the dropdown
      .map(({ id }) => id);
    this.usedBrandIds$.next(this.excludedBrands);
  }

  streamAvailableBrands() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.brands$, this.usedBrandIds$),
      rx.filter((x) => x.every((item) => !_.isNil(item))),
      rx.map(([brands, usedBrandIds]: [Brand[], number[]]) => {
        return brands.filter((b) => !usedBrandIds.includes(b.id));
      }),
      shareReplayRefOne(),
    )(null);
  }
}

const BrandDeskFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brands: '<',
    desks: '=',
    brand: '=',
    getLogo: '&getLogoFn',
    user: '<',
    isNew: '<',
  },
};

export default BrandDeskFormComponent;
