import TablePopupController from '~/source/common/components/table/table-popup';
import communicationHistorySettings from '../communication-history-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import CustomerCommunications from '~/source/common/models/customer-communications';
import template from './communication-history-popup.html';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { collapsibleTableDetails } from '~/source/common/utilities/collapsible-table-details';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  CommunicationMethodCode,
  CommunicationTypeCode,
  VoipProviderCode,
} from '@proftit/crm.api.models.enums';
import { getResolveChange } from '~/source/common/utilities/rx-ng-one/operators/get-resolve-change';
import { Communication } from '~/source/common/models/communication';
import brand from '~/source/management/brand';

const styles = require('./communication-history-popup.component.scss');

interface ICallHistoryPopup {
  customer: Customer;
}

class Controller extends TablePopupController {
  static $inject = [
    'customersService',
    'PermPermissionStore',
    ...TablePopupController.$inject,
  ];

  CommunicationTypeCode = CommunicationTypeCode;

  styles = styles;
  PermissionNormalized = PermissionNormalized;
  PermPermissionStore: ng.permission.PermissionStore;
  hasFavoriteViewPermission: boolean;
  hasFavoriteUpdatePermission: boolean;
  callHistorySettingsCopy: any;
  lifecycles = observeComponentLifecycles(this);

  collapsibleDetails = collapsibleTableDetails(this.collection$, true, false);

  collapseButton = {
    toggleCollapseAction: this.collapsibleDetails.tableToggleExpansionAction,
    expanded$: this.collapsibleDetails.buttonStatus$,
  };

  customer$ = rx.pipe(
    () => this.lifecycles.onChanges$,
    getResolveChange<Customer>('customer'),
    rx.tap((customer) => (this.customer = customer)),
    shareReplayRefOne(),
  )(null);

  audioPlayerFileFormats$ = this.streamAudioPlayerFileFormats();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    // copy table settings json
    this.callHistorySettingsCopy = _.cloneDeep(communicationHistorySettings);

    // get favorite column from column array
    const favoriteColumn = _.find(
      (col) => col.fieldName === 'isFavorite',
      this.cols,
    );

    // get favorite column from column array
    const recordingColumn = _.find(
      (col) => col.fieldName === 'recordingFile',
      this.cols,
    );

    // is there a view permission on favorite column
    this.PermPermissionStore.getPermissionDefinition(
      'contacts.communications.favorite',
    )
      .validatePermission()
      .then(
        () => {
          this.hasFavoriteViewPermission = true;
          favoriteColumn.show = true;
        },
        () => {
          this.hasFavoriteViewPermission = false;
          favoriteColumn.show = false;
        },
      );

    // is there an update  permission on favorite column
    this.PermPermissionStore.getPermissionDefinition(
      'contacts.communications.favorite_U',
    )
      .validatePermission()
      .then(
        () => (this.hasFavoriteUpdatePermission = true),
        (err) => (this.hasFavoriteUpdatePermission = false),
      );

    // is there an view permission on recording column
    this.PermPermissionStore.getPermissionDefinition(
      `${PermissionNormalized.ContactsCommunicationsRecordings}`,
    )
      .validatePermission()
      .then(
        () => (recordingColumn.show = true),
        () => (recordingColumn.show = false),
      );

    useStreams(
      [
        this.customer$,
        this.collapsibleDetails.main$,
        this.audioPlayerFileFormats$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  dataServiceInstance: CustomersService;
  customer: Customer;

  get cols() {
    return [...this.callHistorySettingsCopy.tableColumns];
  }

  get ngTableSettings() {
    return { ...this.callHistorySettingsCopy.popupTable.ngTable };
  }

  get title() {
    return 'contact.COMMUNICATION_HISTORY';
  }

  get tableKey() {
    return 'callHistory';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getCommunicationsResource(this.customer.id)
      .expand([
        'user',
        'communicationStatus',
        'communicationSubject',
        'type',
        'provider',
      ])
      .sort({ date: 'desc' });
  }

  parseLoadedData(data: any[]): any[] {
    data.forEach((item) => {
      item._calculatedDetails = this.getCommunicationSnippet(item);
    });
    return data;
  }

  getCommunicationSnippet(communication: Communication): string {
    if (_.isNil(communication.details)) {
      return '';
    }

    if (this.isEmailCommunication(communication)) {
      let detailsObject;
      try {
        detailsObject = JSON.parse(communication.details);
      } catch (e) {
        return communication.details;
      }
      if (_.isNil(detailsObject) || _.isNil(detailsObject.snippet)) {
        return '';
      }
      return _.unescape(detailsObject.snippet);
    }
    return communication.details;
  }

  stopClickEventReachingParentForCol(event, col) {
    const colsToStopPropagation = ['recordingFile'];
    if (colsToStopPropagation.includes(col.fieldName)) {
      event.stopPropagation();
    }
  }

  isEmailCommunication(communication: Communication): boolean {
    if (_.isNil(communication)) {
      return false;
    }
    if (_.isNil(communication.type)) {
      return false;
    }
    return communication.type.code === CommunicationTypeCode.Email;
  }

  /**
   * update isFavorite to server
   * @param {object} call
   * @return {void}
   */
  toggleFavorite(call: IElementRestNg<CustomerCommunications>) {
    if (!this.hasFavoriteUpdatePermission) {
      return;
    }
    const isFavorite = !call.isFavorite;
    call.patch({ isFavorite }).then(() => (call.isFavorite = isFavorite));
  }

  streamAudioPlayerFileFormats() {
    return rx.pipe(
      () => this.collection$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((communications: any[]) => {
        return communications.reduce((acc, currComm) => {
          const { provider } = currComm;
          if (!_.isNil(provider)) {
            let fileFormatForCommunication = [];

            switch (provider.code) {
              case VoipProviderCode.Voicespin:
                fileFormatForCommunication = ['wav'];
                break;
              case VoipProviderCode.Commpeak:
                fileFormatForCommunication = ['mp3'];
                break;
            }

            acc[currComm.id] = fileFormatForCommunication;
          }

          return acc;
        }, {});
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const CommunicationHistoryTablePopupComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
