import template from './lock-toggle.component.html';
const styles = require('./lock-toggle.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class LockToggleController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const LockToggleComponent = {
  template,
  controller: LockToggleController,
  bindings: {
    isLocked: '<',
    onChange: '&',
  },
};
