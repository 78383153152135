import { ITimeoutService } from 'angular';
import * as _ from '@proftit/lodash';
import template from './confirm-withdrawal-popup.html';
import BaseController from '~/source/common/controllers/base';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import { BrandEwalletService } from '~/source/management/brand-ewallet/services/brand-ewallet.service';
import { useStream } from '~/source/common/utilities/use-stream';
import {
  Customer,
  Brand,
  TradingAccount,
  ClearingCompanyConnection,
} from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { BrandEwallet } from '~/source/common/models/brand-ewallet';

import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  ClearingCompanyType,
  MT4_PLATFORMS,
} from '@proftit/crm.api.models.enums';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';

class ConfirmWithdrawalPopupComponent extends BaseController {
  lifecycles = observeComponentLifecycles(this);
  withdrawalRequest: WithdrawalRequest;
  customer: Customer;
  accountId: number;
  account$ = new rx.BehaviorSubject<TradingAccount>(null);
  contactId: number;
  activeTab: string;
  isBrandHasEwallets = false;
  unsub$ = new rx.Subject<void>();
  opInit$ = new rx.Subject<void>();
  isBrandHasEwallets$ = new rx.BehaviorSubject<boolean>(false);
  isBrandHasMobileMoney$ = this.streamIsBrandHasMobileMoney();
  withdrawalRequest$ = new rx.BehaviorSubject<WithdrawalRequest>(null);
  isMobileMoneyTabDisabled$ = new rx.BehaviorSubject<boolean>(false);
  shouldDisplayFreeMargin$ = this.streamShouldDisplayFreeMargin();

  /*@ngInject */
  constructor(
    readonly $timeout: ITimeoutService,
    readonly brandEwalletService: BrandEwalletService,
    readonly clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService,
  ) {
    super();
    useStreams(
      [this.shouldDisplayFreeMargin$, this.isBrandHasMobileMoney$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStream(this.streamDisableMobileMoneyTabCalc(), this.unsub$);

    // default active tab: either by the method type, or 'card' if it's empty
    this.activeTab =
      this.withdrawalRequest.transferMethodTypeCode || 'CARD_WITHDRAWAL';

    this.opInit$.next();
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamIsBrandHasMobileMoney() {
    return rx.pipe(
      () => this.opInit$,
      rx.map(() => this.customer.brand),
      rx.switchMap((brand) => this.getMoblieMonyForBrand(brand)),
      rx.map((brandMobileMoney) => brandMobileMoney.length > 0),
      shareReplayRefOne(),
    )(null);
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.accountId = null;
      this.customer = null;
      this.contactId = null;
      return;
    }
    this.accountId = resolve.accountId;
    this.account$.next(resolve.account);
    this.customer = resolve.customer;
    this.contactId = resolve.contactId;
    this.withdrawalRequest = resolve.withdrawalRequest;
    this.withdrawalRequest$.next(resolve.withdrawalRequest);
  }

  streamShouldDisplayFreeMargin() {
    return rx.pipe(
      () => this.account$,
      rx.filter((account) => !_.isNil(account)),
      rx.map((account) => {
        const { platform } = account;
        return MT4_PLATFORMS.includes(platform.code);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDisableMobileMoneyTabCalc() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isBrandHasMobileMoney$,
          this.withdrawalRequest$,
        ),
      rx.map(([isbrandHasMobileMoney, withdrawalRequest]) =>
        this.calcDisableMobileMoneyTab(
          isbrandHasMobileMoney,
          withdrawalRequest,
        ),
      ),
      rx.tap((isMobileMoneyTabDisabled) =>
        this.isMobileMoneyTabDisabled$.next(isMobileMoneyTabDisabled),
      ),
    )(null);
  }

  getEwalletsForBrand(brand: Brand): rx.Observable<BrandEwallet[]> {
    return rx.obs.from(
      this.brandEwalletService
        .filter({ brandId: brand.id })
        .getListWithQuery<IElementRestNg<BrandEwallet>>()
        .then((data) => data.plain()),
    );
  }

  getMoblieMonyForBrand(brand: Brand) {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.clearingCompanyConnectionsService()
              .expand(['clearingCompany'])
              .filter({
                brandId: brand.id,
                'clearingCompany.type': ClearingCompanyType.MobileMoney,
              })
              .getListWithQuery<IElementRestNg<ClearingCompanyConnection>>(),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((allConnections) =>
        allConnections.filter((connection) => connection.isActive),
      ),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Disable the wire tab if this is not a regular withdrawal,
   * or if the user specifically asked for a card withdrawal
   * @return {boolean}
   */
  isWireTabDisabled() {
    if (
      _.getEs(this.withdrawalRequest, 'withdrawalType.code', 'regular') !==
      'regular'
    ) {
      return true;
    }

    if (_.isEmpty(this.withdrawalRequest.transferMethodTypeCode)) {
      return false;
    }

    if (this.withdrawalRequest.transferMethodTypeCode === 'WIRE_WITHDRAWAL') {
      return false;
    }

    return true;
  }

  /**
   * Disable the credit card tab if the user specifically asked for a wire withdrawal
   *
   * @return {boolean}
   */
  isCreditCardTabDisabled() {
    if (_.isEmpty(this.withdrawalRequest.transferMethodTypeCode)) {
      return false;
    }

    if (this.withdrawalRequest.transferMethodTypeCode === 'CARD_WITHDRAWAL') {
      return false;
    }

    return true;
  }

  calcDisableEwalletTab(isBrandHasEwallets, withdrawalRequest) {
    if (
      _.getEs(withdrawalRequest, 'withdrawalType.code', 'regular') !== 'regular'
    ) {
      return true;
    }

    if (!isBrandHasEwallets) {
      return true;
    }

    if (_.isEmpty(withdrawalRequest.transferMethodTypeCode)) {
      return false;
    }

    if (withdrawalRequest.transferMethodTypeCode === 'E-WALLET') {
      return false;
    }

    return true;
  }

  calcDisableMobileMoneyTab(isBrandHasMobileMoney, withdrawalRequest) {
    if (
      _.getEs(withdrawalRequest, 'withdrawalType.code', 'regular') !== 'regular'
    ) {
      return true;
    }

    if (!isBrandHasMobileMoney) {
      return true;
    }

    if (_.isEmpty(withdrawalRequest.transferMethodTypeCode)) {
      return false;
    }

    if (
      withdrawalRequest.transferMethodTypeCode === 'MOBILE_MONEY_WITHDRAWAL'
    ) {
      return false;
    }

    return true;
  }
}

export default {
  template,
  controller: ConfirmWithdrawalPopupComponent,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
