import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { Base64 } from 'js-base64';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import {
  ContentTemplate,
  SystemContentTemplate,
} from '@proftit/crm.api.models.entities';
import { TemplateType } from '~/source/common/models/template-type';
import { ContentTemplateTypeCode } from '@proftit/crm.api.models.enums';
import template from './content-template-editor-form.component.html';

const styles = require('./content-template-editor-form.component.scss');

// https://github.com/tinymce/tinymce/issues/2836
const contentStyle = require('tinymce/skins/ui/oxide/content.min.css');

export class ContentTemplateEditorFormController {
  onChange: (a: { change }) => void;

  onIsValid: (a: { isValid: boolean }) => void;

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<ContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  editorOptions = {
    content_css: './assets/tinymce/content.css',
    skin: false,
    //  theme: false,
    plugins: [
      'code',
      'autoresize',
      'lists',
      'advlist',
      'link',
      'image',
      'media',
      'table',
      'paste',
    ],
    content_style: contentStyle.toString(),
    // menu: {
    // edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
    // view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
    // insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
    // format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
    // tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
    // table: { title: 'Table', items: 'inserttable tableprops deletetable row column cell' },
    // help: { title: 'Help', items: 'help' }
    // },
    menubar: '',
    toolbar:
      'bold italic underline | styleselect | cut copy paste | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | link image media | code | undo redo',
    contextmenu: 'paste',
  };

  typesToShow$ = this.streamTypesToShow();

  form$ = this.streamForm();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.model$,
        this.form$.pipe(rx.switchMap((f) => f.value$)),
        this.streamNotifyChange(),
        this.streamNotifyIsValid(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => modelToForm(model)),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) =>
        rx.obs.combineLatest(
          form.value$ as rx.Observable<ContentTemplate>,
          form.isAtFirstValue$,
        ),
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.map(([change, a]) => change.content),
      rx.map((contentDecoded: string) => Base64.encode(contentDecoded)),
      rx.tap((content) => this.onChange({ change: { content } })),
    )(null);
  }

  streamNotifyIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onIsValid({ isValid })),
    )(null);
  }

  streamTypesToShow() {
    return rx.pipe(
      () => this.model$,
      rx.map((contentTemplate) => {
        return _.flow([
          () => [null],
          (types) =>
            contentTemplate.type === ContentTemplateTypeCode.System
              ? [
                  ...types,
                  (contentTemplate as SystemContentTemplate).system.type.code,
                ]
              : types,
        ])();
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

function modelToForm(model: ContentTemplate) {
  const contentDecoded = Base64.decode(model.content);

  return new FormGroup({
    content: new FormControl(contentDecoded),
  } as any);
}

export const ContentTemplateEditorFormComponent = {
  template,
  controller: ContentTemplateEditorFormController,
  bindings: {
    model: '<',
    onChange: '&',
    onIsValid: '&',
  },
};
