import PopupService from '~/source/common/components/modal/popup.service';

import template from './marketing-tab.html';
import TableController from '~/source/common/components/table/table.controller';
import * as _ from '@proftit/lodash';
import promoCodeSettings from './promo-code-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer, PromoCode } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PROMO_CODE_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { PromoCodeScreenAction } from '../add-promo-code-popup/promo-code-screen-action';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import { CustomerStoreProviderController } from '~/source/contact/common/components/customer-store-provider/customer-store-provider.component';

const styles = require('./marketing-tab.component.scss');

class Controller extends TableController {
  popupService: PopupService;
  styles = styles;
  Permissions = Permissions;
  lifecycles = observeComponentLifecycles(this);

  promoCodeData;
  static $inject = [
    'customersService',
    'popupService',
    'blockUI',
    'prfClientGeneralPubsub',
    ...TableController.$inject,
  ];

  settings = _.cloneDeep(promoCodeSettings);
  cols = [...this.settings.tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;

  prfClientGeneralPubsub: ClientGeneralPubsub;

  sendSmsOp$ = new rx.Subject<{ id: number; code: string }>();

  offerNameEdit$ = new rx.Subject();
  offerNameCancel$ = new rx.Subject();
  offerSave$ = new rx.Subject();
  originalOfferName$ = this.offerNameEdit$.pipe(
    rx.map(() => {
      return this.customer.productName;
    }),
    shareReplayRefOne(),
  );

  offerNameIsEdit$ = rx.obs.merge(
    this.offerNameEdit$.pipe(rx.map(() => true)),
    this.offerNameCancel$.pipe(rx.map(() => false)),
    this.offerSave$.pipe(rx.map(() => false)),
  );

  marketingInfoEdit$ = new rx.Subject();
  marketingInfoCancel$ = new rx.Subject();
  marketingInfoSave$ = new rx.Subject();
  originalMarketingInfo$ = this.marketingInfoEdit$.pipe(
    rx.map(() => {
      return this.customer.marketingInfo;
    }),
    shareReplayRefOne(),
  );

  marketingInfoIsEdit$ = rx.obs.merge(
    this.marketingInfoEdit$.pipe(rx.map(() => true)),
    this.marketingInfoCancel$.pipe(rx.map(() => false)),
    this.marketingInfoSave$.pipe(rx.map(() => false)),
  );

  $onInit() {
    super.$onInit();

    useStreams(
      [
        this.streamSendSms(),
        this.streamRefreshTable(),
        this.streamCancelOfferName(),
        this.streamSaveOfferName(),
        this.offerNameIsEdit$,
        this.streamCancelMarketingInfo(),
        this.marketingInfoIsEdit$,
        this.streamSaveMarketingInfo(),
      ],
      this.lifecycles.onDestroy$,
    );

    Object.assign(this, {
      dataServiceInstance: this.customersService(),
    });
    this.initTable();
  }

  $onChange() {}

  $onDestroy() {}

  streamCancelOfferName() {
    return rx.pipe(
      () => this.offerNameCancel$,
      rx.withLatestFrom(this.originalOfferName$),
      rx.tap(([a, originalOfferName]) => {
        this.customer.productName = originalOfferName;
      }),
    )(null);
  }

  streamCancelMarketingInfo() {
    return rx.pipe(
      () => this.marketingInfoCancel$,
      rx.withLatestFrom(this.originalMarketingInfo$),
      rx.tap(([a, originalMarketingInfo]) => {
        this.customer.marketingInfo = originalMarketingInfo;
      }),
    )(null);
  }

  streamSaveOfferName() {
    return rx.pipe(
      () => this.offerSave$,
      rx.switchMap(() => {
        return this.customersService().updateCustomer(this.customer.id, {
          productName: this.customer.productName,
        });
      }),
    )(null);
  }

  streamSaveMarketingInfo() {
    return rx.pipe(
      () => this.marketingInfoSave$,
      rx.switchMap(() => {
        return this.customersService().updateCustomer(this.customer.id, {
          marketingInfo: this.customer.marketingInfo,
        });
      }),
    )(null);
  }

  streamSendSms() {
    return rx.pipe(
      () => this.sendSmsOp$,
      rx.switchMap(({ id, code }) => {
        return this.customersService()
          .setConfig({
            blockUiRef: this.blockUiKey,
            growlRef: this.growlRef,
            defaultErrorMessage: 'contact.call.errors.CALL_FAILED',
          })
          .sendSms(
            this.customer.id,
            this.customer.brand.smsCredentials[0].smsProvider.id,
            `${this.customer.brand.name}: Your promo code is: ${code}`,
            null,
            true,
          )
          .then((data) => {
            return { id, data };
          });
      }),
      rx.switchMap(({ id, data }) => {
        return this.customersService()
          .setConfig({
            blockUiRef: this.blockUiKey,
            growlRef: this.growlRef,
            defaultErrorMessage: 'contact.call.errors.CALL_FAILED',
          })
          .updatePromoCode(this.customer.id, id, { isSent: true })
          .then((data) => {
            return { id, data };
          });
      }),
      rx.tap(({ id, data }) => {
        const promoCodeCurrent = this.promoCodeData.find(
          (promoCodeItem) => promoCodeItem.id === id,
        );
        promoCodeCurrent.isSent = true;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamRefreshTable() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === PROMO_CODE_ADDED),
      rx.tap(() => {
        this.tableParams.reload();
      }),
    )(null);
  }

  parseLoadedData(data) {
    this.promoCodeData = data;

    return data;
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  get tableKey() {
    return 'marketing-tap-promo-code';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getPromoCodesResource(this.customer.id)
      .setConfig({ blockUiRef: this.blockUiKey });
  }

  openPromoCodeInfo() {
    this.popupService.open({
      component: 'prfPromoCodePopup',
      resolve: {
        customer: this.customer,
      },
    });
  }

  openAddPromoCodePopUp() {
    this.popupService.open({
      component: 'prfAddPromoCodePopup',
      resolve: {
        customer: () => this.customer,
        brand: () => this.customer.brand,
        action: () => PromoCodeScreenAction.Create,
      },
    });
  }

  editPromoCode(promoCode: PromoCode) {
    this.popupService.open({
      component: 'prfAddPromoCodePopup',
      resolve: {
        customer: () => this.customer,
        brand: () => this.customer.brand,
        promoCode: () => promoCode,
        action: () => PromoCodeScreenAction.Update,
      },
    });
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
  },
};
