import template from './edit-bar.component.html';
const styles = require('./edit-bar.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class EditBarController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const EditBarComponent = {
  template,
  controller: EditBarController,
  bindings: {
    isEdit: '<',
    onEditClick: '&',
    onCancelClick: '&',
    onConfirmClick: '&',
  },
};
