import template from './customer-trading-accounts-audit-log-table.component.html';
const styles = require('./customer-trading-accounts-audit-log-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { TableController } from '~/source/common/components/table/table.controller';
import { customerTradingAccountsAuditLogsTableColumns } from './customer-trading-accounts-audit-logs-table-columns';
import { customerTradingAccountsAuditLogsTableSettings } from './customer-trading-accounts-audit-logs-table-settings';
import { CustomersService } from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import { PopupService } from '~/source/common/components/modal/popup.service';

export class CustomerTradingAccountsAuditLogTableController extends TableController {
  static $inject = [
    'popupService',
    'customersService',
    'brandsService',
    ...TableController.$inject,
  ];

  customersService: () => CustomersService;
  popupService: PopupService;

  customer: Customer;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  blockUid = generateBlockuiId();
  cols = [...customerTradingAccountsAuditLogsTableColumns];
  tableSettings = { ...customerTradingAccountsAuditLogsTableSettings };
  dataServiceInstance: CustomersService;

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.customersService();
    this.initTable();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.tableSettings;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getTradingAccountAuditLogResource(this.customer.id)
      .setConfig({ blockUiRef: this.blockUid })
      .sort({ requestedAt: 'desc' });
  }

  openTablePopup() {
    this.popupService.open({
      component: 'prfCustomerTradingAccountsAuditLogPopup',
      resolve: {
        customer: this.customer,
      },
    });
  }
}

export const CustomerTradingAccountsAuditLogTableComponent = {
  template,
  controller: CustomerTradingAccountsAuditLogTableController,
  bindings: {
    customer: '<',
  },
};
