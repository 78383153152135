import { IHttpService } from 'angular';

import * as _ from '@proftit/lodash';

import { BrandsService } from '~/source/management/brand/services/brands';
import { IRestServiceInstance } from '~/source/common/services/rest';
import {
  getAsList,
  JsonServerRequest,
  setFilters as jsSetFilter,
  slice as jsonSrvSlice,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { CfdPlatformBrandConfigsService } from '../../services/cfd-platform-brand-configs.service';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { UserBrandPlatformSession } from '~/source/common/models/user-brand-platform-session';
import { Platform } from '@proftit/crm.api.models.entities';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';

export class CfdPlatformBrandsRestAdapter implements IRestServiceInstance {
  blockUiRef: string;
  growlRef: string = 'restService';

  total = 0;

  platformConnectionFilter: PlatformConnection;

  constructor(
    readonly $http: IHttpService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    private request: JsonServerRequest,
    readonly searchFieldName: string,
    readonly brandsService: () => BrandsService,
    readonly cfdPlatformBrandsService: CfdPlatformBrandConfigsService,
    readonly sessionInfo: PlatformSessionInfo,
  ) {}

  setConfig({ blockUiRef, growlRef }) {
    if (!_.isUndefined(blockUiRef)) {
      this.blockUiRef = blockUiRef;
    }

    if (!_.isUndefined(growlRef)) {
      this.growlRef = growlRef;
    }

    return this;
  }

  embed(...args) {
    return this;
  }

  cacheEmpty() {}

  filter(filters) {
    this.request = jsSetFilter(filters, this.request) as any;
    return this;
  }

  sort(...args) {
    return this;
  }

  slice(start: number, end: number, limit: number) {
    this.request = jsonSrvSlice(start, end, limit, this.request);
    return this;
  }

  /**
   * Customize function for fetching data only for one platform conneciton.
   *
   * @params platformConnection
   * @returns adapter instance.
   */
  setPlatformConnectionFilter(platformConnection: PlatformConnection) {
    this.platformConnectionFilter = platformConnection;

    return this;
  }

  getListWithQuery() {
    // get list of house's brands with cfd platform
    // for each brand ->
    //   get platform access info - url, public token
    //   get config info
    // arrange all brands cofig info into one list

    this.startBlockUi();
    this.growlMessages.destroyAllMessages(<any>this.growlRef);

    return this.brandsService()
      .getBrandsCfdPlatformConnections()
      .then((platformConns: PlatformConnection[]) => {
        if (_.isNil(this.platformConnectionFilter)) {
          return platformConns;
        }

        return platformConns.filter(
          (p) => p.id === this.platformConnectionFilter.id,
        );
      })
      .then((platformConns: PlatformConnection[]) =>
        platformConns.map((platformConn) => {
          return this.getCfdPlatformBrandConfigs(
            this.sessionInfo.session,
            this.sessionInfo.platform,
          )
            .then((r) => r.data)
            .then((data) =>
              data.map((config) => {
                const newConfig = _.set(
                  ['brand', '_crmPlatformConnection'],
                  platformConn,
                  config,
                );
                return newConfig;
              }),
            );
        }),
      )
      .then((getConfigsPromises) => Promise.all(getConfigsPromises))
      .then((resps: any) => _.flatten(resps))
      .then((configs) => {
        this.setTotalFromResponse(configs);
        return configs;
      })
      .then(
        (x) => {
          this.stopBlockUi();
          return x;
        },
        (err) => {
          this.stopBlockUi();
          const errorMessage = 'error in query';
          this.growl.error(errorMessage, { referenceId: <any>this.growlRef });
          throw err;
        },
      );
  }

  getCfdPlatformBrandConfigs(
    session: UserBrandPlatformSession,
    platform: Platform,
  ) {
    return _.flow([
      () =>
        this.cfdPlatformBrandsService.initPrivateReq(
          session.apiUrl,
          session.token,
          platform,
        ),
      (req) => getAsList(req),
      (req) => cfdMongoToNgHttp(req),
      (httpModel) => ngHttpPerformRequest(this.$http, httpModel),
    ])();
  }

  setTotalFromResponse(configs) {
    this.total = configs.length;
  }

  startBlockUi() {
    if (_.isNil(this.blockUiRef)) {
      return null;
    }

    const blockUiInstance = this.blockUI.instances.get(this.blockUiRef);

    if (_.isNil(blockUiInstance)) {
      return null;
    }

    blockUiInstance.start();
    return null;
  }

  stopBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.blockUiRef);
    if (_.isNil(blockUiInstance)) {
      return;
    }

    blockUiInstance.stop();
    return null;
  }
}
