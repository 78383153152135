import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { IOnChangesObject } from 'angular';
import { getCompChange } from './get-comp-change';

export function getResolveChange<T>(propName: string) {
  return function operator(obs$: rx.Observable<IOnChangesObject>) {
    return rx.pipe(
      () => obs$,
      getCompChange<{ [key: string]: T }>('resolve'),
      rx.map((resolve) => _.get([propName], resolve)),
    )(null);
  };
}
