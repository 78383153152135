import ng from 'angular';
import { generateUuid } from '@proftit/general-utilities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import template from './user-brand-email-form.component.html';

const styles = require('./user-brand-email-form.component.scss');

export class UserBrandEmailFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  compId = generateUuid();

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const UserBrandEmailFormComponent = {
  template,
  controller: UserBrandEmailFormController,
  bindings: {
    brand: '<',
    user: '<',
  },
};
