import ng, { IScope } from 'angular';
import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import template from './campaign-minimum-deposit-data.html';
import BrandsService from '~/source/management/brand/services/brands';
import type { StateService } from '@uirouter/angularjs';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { CurrenciesService } from '~/source/common/services/currencies';
import { BrandCurrency } from '~/source/common/models/brand-currency';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';

class CampaignMinimumDepositDataController extends BaseController {
  marketingCampaignsServiceInstance: MarketingCampaignsService;
  brandsInstance: BrandsService;

  isEdit: boolean = false;
  originalBrandId: number;

  currencies: Partial<BrandCurrency>[];
  prevAttributes: Partial<BrandCurrency>[];

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly brandsService: () => BrandsService,
    readonly $state: StateService,
    readonly $stateParams: any,
    readonly modelNormalizer: ModelNormalizerService,
    readonly currenciesService: CurrenciesService,
    readonly marketingCampaignsService: () => MarketingCampaignsService,
  ) {
    super();
    this.marketingCampaignsServiceInstance = marketingCampaignsService();
  }

  $onInit() {
    this.brandsInstance = this.brandsService();

    /*
     * watch brand changes and load min deposit + currencies from campaign or from brand
     * load from brand if campaign.currencies is empty
     */
    this.$scope.$watch('vm.brand.id', (nVal: number, oVal) => {
      if (this.originalBrandId && nVal === this.originalBrandId) {
        /*
         * this is the original campaign brand,
         * in this way we want to show the original currency values
         */
        this.currencies = ng.copy(this.prevAttributes);

        // stop here, because we already loaded the right currency list
        return;
      }

      if (nVal && !oVal) {
        // user changed brand. keep original brand id
        this.originalBrandId = nVal;

        // store original campaign currencies array.
        this.prevAttributes = ng.copy(this.currencies);
      }

      /*
       * stop here if brand id value didn't changed.
       * stop here if its the first brand id assigned in the process and there are currencies available
       */
      if (
        (this.currencies && this.currencies.length !== 0 && !oVal) ||
        nVal === oVal
      ) {
        return;
      }

      /*
       * get brand default currencies + min deposit
       * because brand changes & campaign currencies is empty
       */
      this.getCurrencies(nVal).then((data) => {
        /*
         * get only currency & minimum deposit. loss record id in the proccess
         * because normalizer normalize it which produce unwanted results
         */
        this.currencies = _.map(data, ({ currency, minimumDeposit }) => ({
          currency,
          minimumDeposit,
        }));
      });
    });
  }

  /**
   * get brand currencies
   * @param {number} brandId
   * @returns {*|{method, params, headers}}
   */
  getCurrencies(brandId) {
    return this.brandsInstance
      .getCurrenciesResource(brandId)
      .expand('currency')
      .getListWithQuery();
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = ng.copy(this.currencies);

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    this.currencies = ng.copy(this.prevAttributes);

    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    // get the properties we need then normalize
    const currencies = _.map(
      this.currencies,
      ({ currency, minimumDeposit }) => ({
        currency,
        minimumDeposit,
      }),
    );
    const normalizedCurrencies = this.modelNormalizer.normalize(currencies);

    this.marketingCampaignsServiceInstance
      .setConfig({ blockUiRef: 'campaignMinimumDepositForm' })
      .updateCurrencies(this.$state.params.campaignId, normalizedCurrencies)
      .then(() => {
        this.isEdit = false;
      });
  }
}

export default {
  template,
  bindings: {
    brand: '<',
    currencies: '=selectedCurrencies',
    hideDefault: '<',
  },
  controller: CampaignMinimumDepositDataController,
  controllerAs: 'vm',
};
