import * as _ from '@proftit/lodash';
import angular from 'angular';

import template from './single-select-field.html';
import BaseController from '~/source/common/controllers/base';

class Controller extends BaseController {
  /* bindings */
  placeholder: string;
  optionPlaceholder: string;
  addOptionLabel: string;
  model: any;
  onChange: () => void;

  defaultPlaceholderCode = 'questionnaire.components.single_select.TITLE';
  defaultOptionPlaceholderCode =
    'questionnaire.components.single_select.ANSWER_PLACEHOLDER';
  defaultAddOptionLabel = 'questionnaire.form.ADD_ANSWER';

  /*@ngInject */
  constructor(readonly $translate: angular.translate.ITranslateService) {
    super();

    this.placeholder = _.defaultTo(
      this.getDefaultPlaceholder(),
      this.placeholder,
    );
    this.optionPlaceholder = _.defaultTo(
      this.getDefaultOptionPlaceholder(),
      this.optionPlaceholder,
    );
    this.addOptionLabel = _.defaultTo(
      this.getDefaultAddOptionLabel(),
      this.addOptionLabel,
    );
  }

  $onInit() {
    // this.model is {} by default
    this.model.options = this.model.options || [];
  }

  getDefaultPlaceholder(): string {
    return this.$translate.instant(this.defaultPlaceholderCode);
  }

  getDefaultOptionPlaceholder(): string {
    return this.$translate.instant(this.defaultOptionPlaceholderCode);
  }

  getDefaultAddOptionLabel(): string {
    return this.$translate.instant(this.defaultAddOptionLabel);
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    shouldPreserveStructure: '<',
    placeholder: '@',
    optionPlaceholder: '@',
    addOptionLabel: '@',
    onChange: '&',
  },
};
