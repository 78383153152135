import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-deposit-manager.component.html';
import { BrandCurrency } from '~/source/common/models/brand-currency';
import {
  MarketingCampaignReward,
  MarketingCampaignRewardsSource,
} from '~/source/common/data-sources/marketing-campaign-rewards-source';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

const styles = require('./automatic-bonus-deposit-manager.component.scss');

export class AutomaticBonusDepositManagerController {
  isEdit: boolean;
  styles = styles;
  selectedBonus: MarketingCampaignRewardsSource;
  formGroupsPerCurrency;
  currencyIdToCurrencyMap;
  onAddDepositRow: (a: { currencyId; fromValue }) => void;
  onDeleteDepositRow: (a: { rowUuid }) => void;

  lifecycles = observeComponentLifecycles(this);
  MarketingCampaignReward = MarketingCampaignReward;

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AutomaticBonusDepositManagerComponent = {
  template,
  controller: AutomaticBonusDepositManagerController,
  bindings: {
    isEdit: '<',
    formGroupsPerCurrency: '<',
    selectedBonus: '<',
    ftdBonusIsOneTimeFormControl: '<',
    currencyIdToCurrencyMap: '<',
    rewardType: '<',
    onAddDepositRow: '&',
    onDeleteDepositRow: '&',
  },
};
