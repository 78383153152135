import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import TransferMethodTypes from '~/source/contact/common/services/transfer-method-types.service';
import CustomersService from '~/source/contact/common/services/customers';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import {
  WithdrawalValidationError,
  default as WithdrawalValidationService,
} from '../common/validation.service';
import { TradingAccount } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import template from './wire.html';
import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
const styles = require('./wire.scss');

const WIRE_FIELDS = [
  'accountNumber',
  'firstName',
  'lastName',
  'bankName',
  'bankAddress',
  'bankCountry',
  'bankCity',
  'swiftCode',
  'iban',
];

class WireWithdrawalController extends BaseController {
  styles = styles;

  // bindings
  $close: () => void;
  contactId: number;
  accountId: number;
  withdrawalRequest: WithdrawalRequest;
  shouldDisplayNegativeFreeMarginWarning: boolean;

  account: TradingAccount;

  withdrawalWire;
  transferMethodTypes;
  customerServiceInst: CustomersService;
  invalidFormErrors: WithdrawalValidationError[];
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly transferMethodTypesService: TransferMethodTypes,
    readonly withdrawalValidationService: WithdrawalValidationService,
    readonly withdrawalSettings,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();
  }

  $onInit() {
    this.customerServiceInst = this.customersService();
    this.withdrawalWire = this.createWithdrawalModel();

    // get account related to withdrawal
    this.getAccount();

    this.getTransferMethodTypes();
  }

  /**
   * Creates the withdrawal model, based on the withdrawal request object.
   * @return {any} - Withdrawal model object
   */
  createWithdrawalModel() {
    const wireDetails = this.withdrawalRequest.transactionTransferWire;
    if (_.has('bankCountryId', wireDetails)) {
      // initialize "bankCountry" object, for dropdown auto select
      wireDetails.bankCountry = { id: wireDetails.bankCountryId };
    }
    const model = _.pick(
      WIRE_FIELDS,
      this.withdrawalRequest.transactionTransferWire,
    );
    model.withdrawalType = this.withdrawalRequest.withdrawalType;
    return model;
  }

  /**
   * get customer trading account info, including balance, withdrawable amount etc...
   * @returns {Promise} returns a promise which resolved on success
   */
  getAccount() {
    return this.customerServiceInst
      .getAccountResource(this.contactId, this.accountId)
      .expand(['currency'])
      .getOneWithQuery<IElementRestNg<TradingAccount>>()
      .then((account: TradingAccount) => {
        this.account = { ...account };
      });
  }

  /**
   * get transaction method types for submitting with the right methodTypeId
   * @returns {Promise} returns a promise which resolved on success
   */
  getTransferMethodTypes() {
    // get types of credit cards from server
    return this.transferMethodTypesService
      .getListWithQuery()
      .then((transferMethodTypes) => {
        // index object by 'code' property
        this.transferMethodTypes = _.keyBy('code', transferMethodTypes);
      });
  }

  /**
   * return error message if exists
   * @returns {Object} error message object if exist
   */
  onAmountChange() {
    this.invalidFormErrors = this.withdrawalValidationService.getErrors(
      this.withdrawalWire.amount,
      this.account,
      this.withdrawalRequest,
    );

    const nonNullAmount = !_.isNil(this.withdrawalWire.amount)
      ? this.withdrawalWire.amount
      : 0;
    this.shouldDisplayNegativeFreeMarginWarning =
      nonNullAmount > this.account.freeMargin;
  }

  /**
   * is the form valid or not
   * @override
   * @returns {boolean} is this form valid or not
   */
  isAmountFieldValid() {
    return (
      this.invalidFormErrors.filter((error) => error.errorType === 'ERROR')
        .length === 0
    );
  }

  /**
   *  called upon withdrawals by credit cards form is valid
   * @returns {Promise|void}
   */
  submit() {
    // format data received from form
    const withdrawals = this.normalize();

    // post bulk request of withdrawals
    return this.customerServiceInst
      .setConfig({
        growlRef: 'withdrawalForm',
        blockUiRef: 'withdrawalForm',
        errorsTranslationPath: 'withdrawal.errors',
      })
      .getLinkedWithdrawalsResource(
        this.contactId,
        this.accountId,
        this.withdrawalRequest.id,
      )
      .postWithQuery(withdrawals)
      .then(() => {
        // reload withdrawal requests table
        this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, '');
        // close the modal
        this.$close();
      });
  }

  /**
   * normalize wire withdrawals for save
   * @override
   * @returns {Array}
   */
  normalize() {
    // set methodTypeId, helps the server to recognized this withdrawal process as wired withdrawal
    this.withdrawalWire.methodType = this.transferMethodTypes.WIRE_WITHDRAWAL;

    return this.modelNormalizer.normalize(this.withdrawalWire);
  }
}

export default {
  template,
  controller: WireWithdrawalController,
  controllerAs: 'vm',
  bindings: {
    $close: '<',
    contactId: '<',
    accountId: '<',
    withdrawalRequest: '<',
    shouldDisplayFreeMargin: '<',
  },
};
