import template from './customer-trading-accounts-audit-log-popup.component.html';
const styles = require('./customer-trading-accounts-audit-log-popup.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import * as rx from '@proftit/rxjs';
import { settings } from './settings';
import { tableColumns } from './table-columns';
import * as _ from '@proftit/lodash';
import { CustomersService } from '~/source/contact/common/services/customers';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { Customer } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';

interface Resolve {
  customer: Customer;
}

export class CustomerTradingAccountsAuditLogPopupController extends TablePopupController {
  static $inject = [
    'customersService',
    'blockUI',
    ...TablePopupController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  settings = _.cloneDeep(settings);
  cols = [...tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;

  customer$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  ).pipe(
    rx.map((r) => _.get(['customer'], r)),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((customer) => (this.customer = customer)),
  );
  opInitTable$ = new rx.Subject<number>();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams([this.streamInitTable()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.opInitTable$,
          this.customer$.pipe(rx.filter((c) => !_.isNil(c))),
        ),
      rx.tap(([rows, customer]) => this.initTable(rows)),
    )(null);
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings;
  }

  get tableKey() {
    return 'customerTradingAccountsAuditLogPopup';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getTradingAccountAuditLogResource(this.customer.id)
      .sort({ requestedAt: 'desc' });
  }
}

export const CustomerTradingAccountsAuditLogPopupComponent = {
  template,
  controller: CustomerTradingAccountsAuditLogPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
