import Component from '~/source/common/components/dropdowns/base/component';

class RoleController extends Component.controller {
  static $inject = ['rolesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'management.SELECT_ROLE';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

const RoleSelectComponent = Component.config({
  controller: RoleController,
});

export default RoleSelectComponent;
