import * as _ from '@proftit/lodash';
import { IScope, ITimeoutService } from 'angular';
import BaseController from '~/source/common/controllers/base';
import template from './clearing-companies-form.html';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { StateService, StateParams } from '@uirouter/core';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import BrandsService from '~/source/management/brand/services/brands';
import { setDynamicFieldsForClearingCompanyConnection } from '~/source/common/utilities/set-dynamic-fields-for-clearing-company-connection';
import useStream from '~/source/common/utilities/use-stream';
import * as rx from '@proftit/rxjs';

import { Brand } from '@proftit/crm.api.models.entities';
const styles = require('./clearing-companies-form.scss');
class Controller extends BaseController {
  styles = styles;
  clearingCompanyConnection: IElementRestNg<ClearingCompanyConnection>;
  currencies: String[];
  clearingCompanyConnection$ = new rx.BehaviorSubject<
    ClearingCompanyConnection[]
  >([]);
  brand$ = new rx.BehaviorSubject<Brand>(null);
  unsub$ = new rx.Subject<void>();

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $stateParams: StateParams,
    readonly $state: StateService,
    readonly brandsService: () => BrandsService,
    readonly clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService,
  ) {
    super();
  }

  $onInit() {
    useStream(this.streamCalcCurrencies(), this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   *  onChanges for changes in ClearingCompanyConnection
   *
   *  @returns {void}
   */
  onClearingCompanyConnectionChange(newConn) {
    this.clearingCompanyConnection$.next(newConn);
    if (!newConn) {
      this.brand$.next(null);
      return;
    }
    this.brand$.next(newConn.brand);
  }

  /**
   * Create stream for currencies.
   *
   * @return {Observable} currencies stream.
   */
  streamCalcCurrencies() {
    return rx.pipe(
      () => this.brand$, // input streams
      rx.mergeMap((brand) => {
        if (!_.isNil(brand)) {
          return rx.obs.from(this.getCurrencies(brand.id));
        }
        return rx.obs.from([]);
      }),
      rx.map((currenciesConn) =>
        currenciesConn.map((curr) => curr.currency.code),
      ),
      rx.tap((finalCurr) => (this.currencies = finalCurr)),
    )(null);
  }

  /**
   *  drop down brand
   *  on select emit brand$ next
   *  @returns {void}
   */
  brandChanged(brand) {
    this.brand$.next(brand);
  }

  /**
   *  drop down clearing companies
   *  on select company create dynamic field list
   *  @returns {void}
   */
  companyChanged() {
    this.clearingCompanyConnection.credentials = setDynamicFieldsForClearingCompanyConnection(
      this.clearingCompanyConnection.clearingCompany.fields,
      this.currencies,
    );
  }

  /**
   * get brand currencies
   * @param {id} number
   * @returns {Observable}
   */
  getCurrencies(id) {
    return this.brandsService()
      .getCurrenciesResource(id)
      .expand('currency')
      .getListWithQuery();
  }

  onCredentialsUpdate(newCredentials) {
    this.clearingCompanyConnection.credentials = newCredentials;
  }
}

const ClearingCompaniesFormComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    clearingCompanyConnection: '<',
  },
};

export default ClearingCompaniesFormComponent;
