import template from './trading-core-brands-audit-log-table-popup.component.html';
const styles = require('./trading-core-brands-audit-log-table-popup.component.scss');

import ng, { IHttpService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import { TradingCoreWebRequestLogRecordService } from '../../services/trading-core-web-request-log-record.service';
import { settings } from './settings';
import { tableColumns } from './table-columns';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import {
  getAsList,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import {
  getPlatformResourcePathForPlatform,
  CfdPlatformBrandConfigsService,
} from '../../services/cfd-platform-brand-configs.service';
import { formatJsonToDlimitedItemsString } from '../../utilities/format-json-to-delimited-items-string';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { CurrentPlatformOfBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-of-brand-store-service.directive';
import { TradingCoreAuditLogsApiService } from '../../services/trading-core-audit-logs.api.service';
import { PlatformCode } from '@proftit/crm.api.models.enums';

interface Resolve {
  brandPlatform: BrandPlatform;
}

const TABLE_KEY = 'tradingCoreBrandsAuditLogTablePopup';

export class TradingCoreBrandsAuditLogTablePopupController extends TablePopupController {
  static $inject = [
    'prfTradingCoreAuditLogsApi',
    'cfdPlatformBrandsService',
    'blockUI',
    'growl',
    'growlMessages',
    '$http',
    ...TablePopupController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentPlatformOfBrand: CurrentPlatformOfBrandStoreServiceDirectiveController;

  /* bindings */

  /* injections */

  prfTradingCoreAuditLogsApi: TradingCoreAuditLogsApiService;

  cfdPlatformBrandsService: CfdPlatformBrandConfigsService;

  blockUI: ng.blockUI.BlockUIService;

  growl: ng.growl.IGrowlService;

  growlMessages: ng.growl.IGrowlMessagesService;

  $http: IHttpService;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  settings = _.cloneDeep(settings);

  cols = [...tableColumns];

  opInitTable$ = new rx.Subject<number>();

  dataServiceInstance$;

  brands$ = new rx.BehaviorSubject<any[]>([]);

  /* inputs */

  resolveIn$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  brandPlatformIn$ = this.resolveIn$.pipe(
    rx.filter((a) => !_.isNil(a)),
    rx.map(({ brandPlatform }) => brandPlatform),
    shareReplayRefOne(),
  );

  /* state */

  brandPlatform: BrandPlatform;

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams(
      [this.resolveIn$, this.brandPlatformIn$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance$ = this.streamDataServiceInstance();

    useStreams(
      [
        this.steamStateCurerntPlatform(),
        this.dataServiceInstance$,
        this.streamInitTable(),
        this.streamBrandsBSubjet(),
        this.streamLoginToSelectedPlatform().stream$,
        this.streamSyncCurrentPlatformOfBrand().stream$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  steamStateCurerntPlatform() {
    return rx.pipe(
      () => this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
      rx.tap((brandPlatform) => {
        this.brandPlatform = brandPlatform;
      }),
    )(null);
  }

  streamDataServiceInstance() {
    return rx.pipe(
      () =>
        this.prfCurrentPlatformSession.sessionS.stream$.pipe(
          rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
        ),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        this.growlRef,
      ),
      rx.map((sessionInfo) => {
        const req = this.prfTradingCoreAuditLogsApi.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        this.growlRef,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.opInitTable$,
          this.dataServiceInstance$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
        ]),
      rx.tap(([rows]) => this.initTable(rows)),
    )(null);
  }

  streamBrandsBSubjet() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.map((sessionInfo) => {
        if (!sessionInfo.isLoggedIn) {
          return rx.obs.of([]);
        }

        return _.flow([
          () =>
            this.cfdPlatformBrandsService.initPrivateReqWithSession(
              sessionInfo.session.apiUrl,
              sessionInfo.session.token,
            ),
          (req) => getAsList(req),
          (req) => cfdMongoToNgHttp(req),
        ])();
      }),
      rx.switchMap((req) =>
        rx.obs.from(ngHttpPerformRequest(this.$http, req) as Promise<any>),
      ),
      rx.map((resp: any) => resp.data),
      rx.catchError(() => {
        return rx.obs.EMPTY;
      }),
      rx.tap((brands) => this.brands$.next(brands as any)),
    )(null);
  }

  streamLoginToSelectedPlatform() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.switchMap((brandPlatform) => {
          if (_.isNil(brandPlatform)) {
            return this.prfCurrentPlatformSession.logout();
          }

          return this.prfCurrentPlatformSession.login(brandPlatform);
        }),
      ),
    );
  }

  streamSyncCurrentPlatformOfBrand() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.tap((brandPlatform) => {
          this.prfCurrentPlatformOfBrand.setBrandPlatform(brandPlatform);
        }),
      ),
    );
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings;
  }

  get tableKey() {
    return TABLE_KEY;
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @example
   * get requiredApiFilters () {
   *   return {
   *       "role.code": {
   *           exclude: "extapi"
   *       }
   *   };
   * }
   * @returns {{}}
   */

  get requiredApiFilters(): any {
    const tableName = calcTableNameForPlatform(
      this.brandPlatform.platform.code,
    );

    return {
      tableName,
      type: ['Create', 'Update', 'Delete', 'Insert'],
    };
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance.sort({ time: 'desc' });
  }

  /*
  calcBrandName(brandIdStr: string) {
    const brandId = parseInt(brandIdStr, 10);
    const brands = this.brands$.getValue();
    const brand = brands.find((a) => a.id === brandId);
    if (_.isNil(brand)) {
      return brandId;
    }

    return brand.name;
  }
  */

  /**
   * parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[]): any[] {
    data.forEach((item) => {
      item._requestBodyForPresentation = formatJsonToDlimitedItemsString(
        item.newValues,
      );
    });
    return data;
  }
}

function calcTableNameForPlatform(code: PlatformCode) {
  if ([PlatformCode.Cfd, PlatformCode.Bundle].includes(code)) {
    return ['Config', 'VolumeRisk'];
  }

  throw new Error('not implemented');
}

export const TradingCoreBrandsAuditLogTablePopupComponent = {
  template,
  controller: TradingCoreBrandsAuditLogTablePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
    prfCurrentPlatformOfBrand: '^',
  },
};
