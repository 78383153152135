import template from './account-types-dropdown.html';
import BaseController from '~/source/common/controllers/base';
import PlatformsService from '~/source/contact/common/services/platforms-public.service';
import { TradingAccountType } from '~/source/common/models/trading-account-type';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class AccountTypesDropdownController extends BaseController {
  accountTypes: TradingAccountType[];

  // bindings
  onSelect: () => void;
  model;
  platformId: number;

  /*@ngInject*/
  constructor(readonly platformsPublicService: PlatformsService) {
    super();
  }

  $onInit() {
    // get account types list
    this.accountTypes = this.platformsPublicService
      .getTradingAccountsResource(this.platformId)
      .setConfig({ blockUiRef: 'accountTypes' })
      .getListWithQuery<IElementRestNg<TradingAccountType>>().$object;
  }
}

export default {
  template,
  controller: AccountTypesDropdownController,
  bindings: {
    onSelect: '&',
    model: '<',
    platformId: '<',
  },
};
