import BaseController from '~/source/common/controllers/base';
import template from './contact-unassign.html';
import { IScope } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
class ContactUnassignController extends BaseController {
  // bindings
  brandId: number;
  customers: Customer[];
  assignLimit: number;
  tableParams: any;
  selectAllItems: boolean;

  customersServiceInstance: CustomersService;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
  ) {
    super();
    this.customersServiceInstance = customersService();
  }

  /**
   * unassign selected customers
   * @returns {Promise}
   */
  unassign() {
    // assign all customers
    if (this.selectAllItems) {
      return this.unassignFilter();
    }

    const customers = this.normalize();

    return this.customersServiceInstance
      .setConfig({ blockUiRef: 'unassignCustomersComponent' })
      .patchCollection('~', customers) // patch with bulk resource
      .then(() => this.onPatchSuccess());
  }

  /**
   * Unassign all customers to user, there could be thousands of customers, so in this case we unassign
   * customers to users using filter route
   * @returns {Promise}
   */
  unassignFilter() {
    return (
      this.customersServiceInstance
        .setConfig({ blockUiRef: 'unassignCustomersComponent' })
        /*
         * only unassign customers matching the current filter & brandId
         * brandId don't exists in tableParams filters. because its added to current filter
         * (in parent controller) as a requiredApiFilters.
         */
        .filter({ ...this.tableParams.filter(), brandId: this.brandId })
        .sort(this.tableParams.sorting())
        /*
         * A limit must be sent to the server. Otherwise the default 1k server limit will be applied
         * We are using the "pagination" as "action limit" here. (which is why page is always 1)
         */
        .setPage(1, this.assignLimit)
        // patch with filter resource
        .patchCollection('filter', { userId: null })
        .then(() => this.onPatchSuccess())
    );
  }

  /**
   * normalize customer/s for patch
   */
  normalize() {
    if (!Array.isArray(this.customers)) {
      this.customers = [this.customers];
    }

    // customer normalization
    return this.customers.map((customerId) => ({
      id: customerId,
      userId: null,
    }));
  }

  /**
   * called after customer update
   */
  onPatchSuccess() {
    // cancel batch mode
    this.selectAllItems = false;

    /*
     * let the world know what happened
     * adding unassign to prevent table reload triggered by event
     */
    this.$scope.$emit('contact:assignToUser:updated', { unassign: true });
  }
}

export default {
  template,
  bindings: {
    brandId: '<',
    customers: '<',
    assignLimit: '<',
    tableParams: '<',
    selectAllItems: '<',
  },
  controllerAs: 'vm',
  controller: ContactUnassignController,
};
