import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import convertStringToCustomerPropertyValue from '~/source/common/utilities/convert-string-to-customer-property-value';
import CustomerPropertyValue from '~/source/common/models/customer-property-value';
import useStream from '~/source/common/utilities/use-stream';
import * as rx from '@proftit/rxjs';

import template from './customer-property-value-renderer.component.html';

class Controller extends BaseController {
  unsub$ = new rx.Subject<void>();
  propType$ = new rx.BehaviorSubject<String>(null);
  propValueStr$ = new rx.BehaviorSubject<String>(null);
  propValue$ = new rx.BehaviorSubject<String>(null);

  $onInit() {
    useStream(this.streamPropValue(), this.unsub$);
  }

  onPropertyValueChange(prop: CustomerPropertyValue) {
    if (_.isNil(prop)) {
      return;
    }

    this.propType$.next(prop.property.typeCode);
    this.propValueStr$.next(prop.value);
  }

  streamPropValue() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.propType$, this.propValueStr$),
      rx.map(([propType, propValue]) =>
        convertStringToCustomerPropertyValue(propValue, propType),
      ),
      rx.tap((value) => this.propValue$.next(value)),
    )(null);
  }
}

const CustomerPropertyValueRendererComponent = {
  template,
  controller: Controller,
  bindings: {
    propertyValue: '<',
  },
};

export default CustomerPropertyValueRendererComponent;
