const styles = require('./trading-accounts-container.component.scss');

import log from 'loglevel';
import BaseController from '~/source/common/controllers/base';
import template from './trading-accounts-container.html';
import { IScope, ITimeoutService } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import { StateParams } from '@uirouter/core';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { ClientGeneralPubsub } from 'source/common/services/client-general-pubsub';
import { ACCOUNT_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { reachedMarginLevel } from '@proftit/crm.api.models.enums';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

function parseActiveAccountId(accountIdStr) {
  return parseInt(accountIdStr, 10);
}

class Controller extends BaseController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  accounts$ = new rx.BehaviorSubject<TradingAccount[]>([]);

  activeAccountId$ = new rx.BehaviorSubject<number>(null);

  opUpdateAccountName$ = new rx.Subject<{
    name: string;
    account: TradingAccount;
  }>();

  customersServiceInst = this.customersService();

  customer: Customer;

  createAccount: Function;

  reachedMarginLevel = reachedMarginLevel;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $stateParams: StateParams,
    readonly $timeout: ITimeoutService,
    readonly customersService: () => CustomersService,
    readonly platformTypesMap: any,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();

    useStreams(
      [
        this.customer$,
        this.streamGetAccounts(),
        this.streamSetActiveAccountOnAccountsOnFirst(),
        this.streamSetAccountOnAdded(),
        this.streamUpdateAccountName(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  get activeAccountId() {
    return this.activeAccountId$.getValue();
  }

  set activeAccountId(val: number) {
    this.activeAccountId$.next(val);
  }

  streamGetAccounts() {
    return rx.pipe(
      () => this.customer$,
      rx.startWith(null),
      rx.pairwise(),
      rx.filter(([prev, curr]) => prev?.id !== curr?.id),
      rx.switchMap(([a, customer]) => {
        if (_.isNil(customer)) {
          return rx.obs.from([[]]);
        }

        return rx.obs.from(this.fetchCustomerAccounts(customer)).pipe(
          rx.catchError(() => {
            log.error('error fetching accounts for customer:', customer);
            return rx.obs.from([[]]);
          }),
        );
      }),
      rx.tap((accounts) => this.accounts$.next(accounts)),
    )(null);
  }

  streamSetActiveAccountOnAccountsOnFirst() {
    return rx.pipe(
      () => this.accounts$,
      rx.map((accounts) => {
        if (accounts.length === 0) {
          return null;
        }

        if (_.isNil(this.$stateParams.accountId)) {
          return accounts[0].id;
        }

        const activeAccountId = parseActiveAccountId(
          this.$stateParams.accountId,
        );

        if (accounts.find((ac) => ac.id === activeAccountId)) {
          return activeAccountId;
        }

        return null;
      }),
      rx.tap((activeId) => this.activeAccountId$.next(activeId)),
      rx.map(() => rx.obs.EMPTY),
    )(null);
  }

  streamSetAccountOnAdded() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === ACCOUNT_ADDED),
      rx.map((x) => x.payload as TradingAccount),
      rx.withLatestFrom(this.accounts$),
      rx.tap(([newAccount, accounts]) =>
        this.accounts$.next([...accounts, newAccount]),
      ),
      rx.delay(1),
      rx.tap(([newAccount, a]) => this.activeAccountId$.next(newAccount.id)),
    )(null);
  }

  streamUpdateAccountName() {
    return rx.pipe(
      () => this.opUpdateAccountName$,
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([{ name, account }, customer]) => {
        return rx.obs
          .from(
            this.customersServiceInst.updateAccount(
              customer.id,
              account.type,
              account.id,
              { name },
            ),
          )
          .pipe(rx.catchError((err, caught) => rx.obs.from([null])));
      }),
    )(null);
  }

  /**
   * Get all the customer's trading accounts and set them to 'this.accounts'
   * @return {Promise} resolved to the fetched accounts
   */
  fetchCustomerAccounts(customer: Customer) {
    const platformType = this.platformTypesMap[customer.brand.platformTypeId];
    /**
     * Additional request expands for each of the trading account types
     * @type {{binary: [string], forex: [string]}}
     */
    const expandsByType = {
      binary: ['tradingAccountType'],
      forex: ['tradingAccountGroup'],
    };
    const platformExpands = expandsByType[platformType];

    return this.customersServiceInst
      .getAccountsResource(customer.id, platformType)
      .setConfig({
        blockUiRef: 'tradingAccounts',
        growlRef: 'tradingAccounts',
        errorsTranslationPath: 'contact.errors',
      })
      .expand(['platform', 'currency', ...platformExpands])
      .getListWithQuery<IElementRestNg<TradingAccount>>()
      .then((accounts) => accounts.plain())
      .then((accounts) =>
        accounts.map((account) => ({
          ...account,
          statusCode: { code: account.statusCode },
        })),
      );
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    customer: '<',
    createAccount: '&',
  },
};
