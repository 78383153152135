import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './translated-questionnaire-table.component.html';
import TableController from '~/source/common/components/table/table.controller';
import RestService from '~/source/common/services/rest';
const styles = require('./translated-questionnaire-table.component.scss');
import { translatedQuestionnaireTableCols } from './translated-questionnaire-table-cols';
import { translatedQuestionnaireTableSettings } from './translated-questionnaire-table-settings';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { NgTableParams as NgTableParamsTypeAlias } from 'ng-table';
import { generateUuid } from '@proftit/general-utilities';
import { QuestionnaireUserAction } from '~/source/management/questionnaire/manage.controller';
import QuestionnaireService from '~/source/management/questionnaire/questionnaire.service';

export class TranslatedQuestionnaireTableController {
  styles = styles;

  tableColumns;
  reloadTable: () => void;
  QuestionnaireUserAction = QuestionnaireUserAction;

  lifecycles = observeComponentLifecycles(this);
  questionnaire$ = observeShareCompChange<any[]>(
    this.lifecycles.onChanges$,
    'questionnaire',
  );

  tableParams: NgTableParamsTypeAlias<any>;

  /* @ngInject */
  constructor(
    readonly NgTableParams: typeof NgTableParamsTypeAlias,
    readonly questionnaireService: () => QuestionnaireService,
  ) {
    useStreams([this.questionnaire$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.tableColumns = translatedQuestionnaireTableCols;
    useStreams([this.streamOnDatasetChange()], this.lifecycles.onDestroy$);
  }

  filterBaseQuestionnaire(questionnaire: any): any[] {
    return questionnaire.languages
      .filter((lang) => !lang.isDefault)
      .map((lang) => {
        return {
          ...lang,
          guid: generateUuid(),
        };
      });
  }

  streamOnDatasetChange() {
    return rx.pipe(
      () => this.questionnaire$,
      rx.tap((questionnaire: any[]) => {
        this.tableParams = new this.NgTableParams(
          {},
          {
            dataset: this.filterBaseQuestionnaire(questionnaire),
          },
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  get ngTableSettings() {
    return translatedQuestionnaireTableSettings.ngTable;
  }

  get tableKey() {
    return null;
  }

  toggleActive(row) {
    this.questionnaireService()
      .getLanguageResource(row.formId, row.id)
      .patchWithQuery({
        isActive: !row.isActive,
      })
      .then(() => {
        this.reloadTable();
      });
  }

  $onDestroy() {}

  $onChanges() {}
}

export const TranslatedQuestionnaireTableComponent = {
  template,
  controller: TranslatedQuestionnaireTableController,
  controllerAs: 'vm',
  bindings: {
    existingTranslations: '<',
    questionnaire: '<',
    reloadTable: '&',
  },
};
