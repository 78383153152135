import ng from 'angular';

import BaseController from '~/source/common/controllers/base';
import TokensService from '../services/tokens';

export class LogoutController extends BaseController {
  static $inject = ['tokensService', '$state', 'Keepalive', 'Idle'];

  tokensService: TokensService;
  $state: ng.ui.IStateService;
  Idle: any; // todoOld: notworking.  ng.idle.IIdleService;

  constructor(...args) {
    super(...args);

    this.tokensService.logout().finally(() => {
      this.Idle.unwatch();
      this.$state.go('auth.login');
    });
  }
}

export default {
  controller: LogoutController,
};
