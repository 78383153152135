import BaseController from '~/source/common/controllers/base';
import template from './api-user-data.html';

class Controller extends BaseController {
  // bindings
  user: any;
  form: any;
  onUserValid: Function;

  isEdit: boolean;
  prevAttributes: any;

  $onInit() {
    this.isEdit = false;
  }

  save() {
    // save the user
    this.onUserValid().then(() => {
      // change to display mode
      this.isEdit = false;
    });
  }

  /**
   * Enter edit mode:
   * Save current user model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    this.prevAttributes = {
      username: this.user.username,
      userGroup: { ...this.user.userGroup },
      whitelistIps: [...this.user.whitelistIps],
    };

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.user, this.prevAttributes);

    // Exit edit mode
    this.isEdit = false;
  }
}

const ApiUserDataComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    user: '<',
    form: '<',
    onUserValid: '&onUserValidFn',
  },
};

export default ApiUserDataComponent;
