import RestService from '~/source/common/services/rest';

export class AccountStatusAutomationTypesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'accountStatusAutomationTypes';
  }
}
