import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './automatic-bonus-form.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import {
  MarketingCampaignReward,
  MarketingCampaignRewardsSource,
} from '~/source/common/data-sources/marketing-campaign-rewards-source';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Currency } from '@proftit/crm.api.models.entities';

const styles = require('./automatic-bonus-form.component.scss');

export class AutomaticBonusFormController {
  selectedBonusType: MarketingCampaignRewardsSource;
  selectableBonusTypes: any[];
  onSelectedBonusType: ({
    regionId: number,
    bonus: MarketingCampaignRewardsSource,
  }) => void;
  regionId: number;
  rewardType: boolean;
  styles = styles;

  MarketingCampaignReward = MarketingCampaignReward;

  lifecycles = observeComponentLifecycles(this);
  selectedBonusFormControl = new FormControl<MarketingCampaignRewardsSource>(
    null,
  );

  selectedBonusType$ = observeShareCompChange<MarketingCampaignRewardsSource>(
    this.lifecycles.onChanges$,
    'selectedBonusType',
  );

  /* @ngInject */
  constructor() {
    useStreams(
      [
        this.selectedBonusFormControl.value$,
        this.streamOnSelectedBonusType(),
        this.streamSelectedBonusType(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  selectionCompare(a, b) {
    if (_.isNil(a) && _.isNil(b)) {
      return true;
    }
    if (_.isNil(a) || _.isNil(b)) {
      return false;
    }
    return a.label === b.label;
  }

  streamSelectedBonusType() {
    return rx.pipe(
      () => this.selectedBonusType$,
      rx.tap((value) => {
        this.selectedBonusFormControl.setValue(value);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnSelectedBonusType() {
    const selectedBonusType = new rx.BehaviorSubject<any>(null);
    return rx.pipe(
      () =>
        this.selectedBonusFormControl.value$.pipe(rx.distinctUntilChanged()),
      rx.filter((selectedBonus) => !_.isNil(selectedBonus)),
      rx.withLatestFrom(selectedBonusType),
      rx.filter(
        ([selectedBonus, previousSelectedBonus]: [
          MarketingCampaignRewardsSource,
          MarketingCampaignRewardsSource,
        ]) =>
          selectedBonus !== previousSelectedBonus &&
          selectedBonus.label !==
            (!_.isNil(previousSelectedBonus)
              ? previousSelectedBonus.label
              : ''),
      ),
      rx.tap(([selectedBonus]) => {
        selectedBonusType.next(selectedBonus);
        this.onSelectedBonusType({
          regionId: this.regionId,
          bonus: selectedBonus,
        });
      }),
    )(null);
  }
}

export const AutomaticBonusFormComponent = {
  template,
  controller: AutomaticBonusFormController,
  bindings: {
    isEdit: '<',
    selectableBonusTypes: '<',
    selectedBonusType: '<',
    regionId: '<',
    formGroupsPerCurrency: '<',
    ftdBonusIsOneTimeFormControl: '<',
    currencyIdToCurrencyMap: '<',
    rewardType: '<',
    onAddDepositRow: '&',
    onDeleteDepositRow: '&',
    onSelectedBonusType: '&',
  },
};
