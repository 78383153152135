import ng from 'angular';
import * as rx from '@proftit/rxjs';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { permissionUpdateFor } from '../../permission-update-for';
import { permissionDeleteFor } from '../../permission-delete-for';
import { permissionCreateFor } from '../../permission-create-for';
import { wrapNgPermissionValidatePromise } from '../../wrap-ng-permission-validate-promise';

export interface CrudPermissionInfo {
  isView: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  isCreate: boolean;
}

/**
 * checkCrudPermission - Get crud permission info for user and permission name.a
 *
 * @param permission
 * @param PermPermissionStore - permission service
 * @returns Crud permission info
 */
export function checkCrudPermission(
  permission: PermissionNormalized,
  PermPermissionStore: ng.permission.PermissionStore,
): rx.Observable<CrudPermissionInfo> {
  return rx.obs
    .forkJoin([
      rx.obs.from(
        wrapNgPermissionValidatePromise(
          PermPermissionStore.getPermissionDefinition(permission),
        ),
      ),
      rx.obs.from(
        wrapNgPermissionValidatePromise(
          PermPermissionStore.getPermissionDefinition(
            permissionUpdateFor(permission),
          ),
        ),
      ),
      rx.obs.from(
        wrapNgPermissionValidatePromise(
          PermPermissionStore.getPermissionDefinition(
            permissionDeleteFor(permission),
          ),
        ),
      ),
      rx.obs.from(
        wrapNgPermissionValidatePromise(
          PermPermissionStore.getPermissionDefinition(
            permissionCreateFor(permission),
          ),
        ),
      ),
    ])
    .pipe(
      rx.map(([isView, isUpdate, isDelete, isCreate]) => ({
        isView,
        isUpdate,
        isDelete,
        isCreate,
      })),
    );
}
