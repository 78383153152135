import { FormControl } from '@proftit/ng1.reactive-forms';
import * as _ from '@proftit/lodash';
import { Language } from '@proftit/crm.api.models.entities';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';

export const IS_UNIQUE_AS_LANGUAGE_FOR_TEMPLATE_TO_BRAND_SYSTEM_TRIGGER =
  'IS_UNIQUE_AS_LANGUAGE_FOR_TEMPLATE_TO_BRAND_SYSTEM_TRIGGER';

export function isUniqueAsLanguageForTemplateToBrandSystemTrigger(
  systemId: number,
  prfSystemEmailTemplatesService: SystemEmailTemplatesService,
) {
  return (ctrl: FormControl<Language>) => {
    if (_.isNil(systemId)) {
      return Promise.resolve(null);
    }

    if (_.isNil(_.get(['id'], ctrl.value))) {
      return Promise.resolve(null);
    }

    return prfSystemEmailTemplatesService
      .getOneWithAllContentTemplates(systemId)
      .then((systemEmailTemplate) => systemEmailTemplate.contentTemplates)
      .then((templates) => {
        const found = templates.find((t) => t.language.id === ctrl.value.id);
        if (_.isNil(found)) {
          return null;
        }

        return {
          [IS_UNIQUE_AS_LANGUAGE_FOR_TEMPLATE_TO_BRAND_SYSTEM_TRIGGER]: {},
        };
      });
  };
}
