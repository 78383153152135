import * as _ from '@proftit/lodash';
import { feesReportsSettings } from './fees-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'feesReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class feesReportTableController extends CommonReportsTableController {
  resource: string = 'fees';
  exportResource: string = 'crm_fees';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>feesReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'feesReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'fees_report_table';
  }
}

export const FeesReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: feesReportTableController,
};
