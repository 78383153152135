import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import { Brand } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';

export class ContentTemplatesLogsService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'contentTemplatesLogs';
  }

  /**
   *
   */
  resourceFullListByBrand(brand: Brand, config, sort, expand, embed) {
    const filters = _.flow([
      (fl) => (brand ? { ...fl, brandId: brand.id } : fl),
    ])({});

    return this.setConfig(config)
      .filter(filters)
      .sort(sort)
      .expand(expand)
      .embed(embed);
  }
}
