import * as _ from '@proftit/lodash';
import TablePopupController from '~/source/common/components/table/table-popup';
import template from './geographic-table-popup.html';
import GeographicStatsService from '~/source/marketing/services/geographic-stats.service';
import settings from './../geographic-tab/geographic-settings.json';

import PopupService from '~/source/common/components/modal/popup.service';
import moment from 'moment-timezone';

class Controller extends TablePopupController {
  static $inject = [
    '$scope',
    'geographicStatsService',
    'popupService',
    ...TablePopupController.$inject,
  ];

  // bindings
  resolve: any;
  geographicStatsService: () => GeographicStatsService;
  popupService: PopupService;
  // @ts-ignore
  dataServiceInstance = this.geographicStatsService();
  settings = _.cloneDeep(settings);
  cols: any[] = [...settings.cols];

  get ngTableDataParams() {
    return this.settings.tablePopup.ngTable.parameters;
  }

  get ngTableSettings() {
    return this.settings.tablePopup.ngTable;
  }

  get tableKey() {
    return 'geographic';
  }

  query() {
    return this.dataServiceInstance.filter(this.resolve.filter);
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    resolve: '<',
  },
};
