import * as _ from '@proftit/lodash';

const INVALID_TOKEN_CODES = [
  'ERROR_AUTH_SRV',
  'ERROR_AUTH_SRV_CONTEXT',
  'ERROR_AUTH_BEARER_STRUCTURE',
  'ERROR_AUTH_BEARER_SIGNATURE',
  'ERROR_AUTH_BEARER_CONTEXT',
  'ERROR_AUTH_SRV_BLACKLIST',
  'ERROR_AUTH_SRV_CONTEXT_EXPIRED',
];
const OBSOLETE_CREDENTIALS_ERROR_CODE = 'ERROR_AUTH_SRV_OBSOLETE_CREDENTIALS';
const LAST_LOGIN_DETAILS_ERROR_DETAILS = 'LastUserLogin';

function restangularRunConf(Restangular, $state, appConfig, clockService) {
  // this is actually only used for legacy calls which do not inherit from RestService:
  Restangular.setBaseUrl(appConfig.connections.api);

  /*
   * this is currently used for both legacy services and for new services which use RestService
   * once all the calls will use the RestService, this interceptor should be moved to the base rest service
   */
  Restangular.addErrorInterceptor((resp) => {
    const errorCode = resp.data && resp.data.code;
    switch (resp.status) {
      case 0:
        // General error: most likely due to CORS
        $state.go('error.general');
        break;
      case 401: // server unauthorized error
        if ($state.current.name !== 'auth.login') {
          if (errorCode === OBSOLETE_CREDENTIALS_ERROR_CODE) {
            const lastLoginDetails = _.flow([
              (x) => _.get(['data', 'details'], x),
              (x) => _.defaultTo([], x),
              (x) =>
                x.find(
                  (details) =>
                    details['@type'] === LAST_LOGIN_DETAILS_ERROR_DETAILS,
                ),
              (x) => _.defaultTo({}, x),
            ])(resp);

            $state.go('error.loggedInFromAnotherLocation', {
              username: lastLoginDetails.username,
              ip: lastLoginDetails.ip,
              country: lastLoginDetails.country,
              browser: lastLoginDetails.browser,
            });
          } else {
            // login page has its own error handling
            $state.go('error.unauthorized');
          }
        }
        break;
      case 412: // Precondition failed. usually means token is invalid
        if (INVALID_TOKEN_CODES.indexOf(errorCode) !== -1) {
          $state.go('auth.login');
        }
    }

    return true; // mark error as NOT handled
  });

  /**
   * Server date interceptor.
   * Whenever a request contains a "Date", update the clock with the new value
   */
  Restangular.addResponseInterceptor((data, operation, what, url, response) => {
    const serverDate = response.headers('Date');
    if (serverDate && !response.config.cache) {
      // Ignore if cache is enabled or if no date was set
      clockService.onServerDateChange(serverDate);
    }
    return data;
  });
}
restangularRunConf.$inject = [
  'Restangular',
  '$state',
  'appConfig',
  'clockService',
];

export default restangularRunConf;
