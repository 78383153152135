import log from 'loglevel';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import {
  // generateInProcessSubject,
  tapInProcessOn,
  catchErrorInProcessOff,
  tapFinalizeInProcessOff,
  shareReplayRefOne,
  // tapLog,
  useStreams,
  InProcessInfo,
} from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  Brand,
  Desk,
  FtdAutoAssignment,
  User,
} from '@proftit/crm.api.models.entities';
import {
  BrandsService,
  FtdAutoAssigmentData,
} from '~/source/management/brand/services/brands';
// import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import {
  CFD_MT4_PLATFORMS,
  RISK_MANAGEMENT_MT4_PLATFORMS,
  CFD_PLATFORMS,
  RISK_MANAGEMENT_PLATFORMS,
  PlatformCode,
  FtdAutoAssignmentType,
} from '@proftit/crm.api.models.enums';
import { directivesPriorities } from '../constants/directives-priorities';
import { IElementRestNg } from '../models/ielement-rest-ng';
import { UsersService } from '~/source/management/user/services/users';

const BRAND_EXPANDED_FIELDS = [
  'ftdAutoAssignmentCustomerStatus',
  'ftdAutoAssignments.sourceDesk',
  'ftdAutoAssignments.destinationDesk',
  'ftdAutoAssignments.assigneeUser',
];

const BRAND_EMBEDED_FIELDS = ['ftdAutoAssignments'];

export class CurrentBrandStoreServiceDirectiveController {
  lifecycles = observeComponentLifecycles(this);

  loadBrandOp$ = new rx.Subject<number>();

  saveFtdAutoAssignmentOp$ = new rx.Subject<FtdAutoAssigmentData>();

  brandInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  brand$ = this.streamBrand();

  cfdMt4brandPlatformsInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  cfdMt4brandPlatforms$ = this.streamCfdMt4BrandPlatforms();

  mt4brandPlatformsInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  mt4BrandPlatforms$ = this.streamMt4BrandPlatforms();

  cfdBrandPlatformsInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  cfdBrandPlatforms$ = this.streamCfdBrandPlatforms();

  desksInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  usersInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  desks$ = this.streamDesks();

  users$ = this.streamUsers();

  cfdLikeBrandPlatformsInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  cfdLikeBrandPlatforms$ = this.streamCfdLikeBrandPlatforms();

  riskManagmentPlatformsInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  riskManagmentPlatforms$ = this.streamRiskManagementPlatforms();

  ftdAutoAssigmentForNonAssigneeList$ = this.streamFtdAutoAssigmentForNonAssigneeList();

  ftdAutoAssigmentForAssigneeList$ = this.streamFtdAutoAssigmentForAssigneeList();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly usersService: () => UsersService,
  ) {
    useStreams([this.brand$, this.desks$, this.users$], new rx.Subject());
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBrandFromLoad(inProcess$: rx.Subject<InProcessInfo>) {
    const streamFn = () =>
      rx.pipe(
        () => this.loadBrandOp$,
        tapInProcessOn(inProcess$),
        rx.switchMap((brandId) => {
          if (_.isNil(brandId)) {
            return rx.obs.of(null);
          }

          return rx.obs.from(
            this.brandsService()
              .getBrandResource(brandId)
              .expand(BRAND_EXPANDED_FIELDS)
              .embed(BRAND_EMBEDED_FIELDS)
              .getOneWithQuery<IElementRestNg<Brand>>()
              .then((data) => data.plain()),
          );
        }),
        tapFinalizeInProcessOff(inProcess$),
        shareReplayRefOne(),
      )(null);

    return streamFn();
  }

  streamBrandFromSaveAutoFtd(
    brand$: rx.Observable<Brand>,
    inProcess$: rx.Subject<InProcessInfo>,
  ) {
    const streamFn = rx.pipe(
      () => this.saveFtdAutoAssignmentOp$,
      tapInProcessOn(inProcess$),
      rx.withLatestFrom(brand$),
      rx.switchMap(([updateData, brand]) => {
        return this.brandsService()
          .expand(BRAND_EXPANDED_FIELDS)
          .embed(BRAND_EMBEDED_FIELDS)
          .updateBrandFtdAutoAssigment(brand.id, updateData);
      }),
      catchErrorInProcessOff(inProcess$, () => streamFn()),
      tapFinalizeInProcessOff(inProcess$),
      shareReplayRefOne(),
    );

    return streamFn();
  }

  streamBrand() {
    const brand$ = new rx.BehaviorSubject<Brand>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamBrandFromLoad(this.brandInProcess$),
          this.streamBrandFromSaveAutoFtd(brand$, this.brandInProcess$),
        ),
      rx.tap((brand) => brand$.next(brand)),
      shareReplayRefOne(),
    )(null);
  }

  streamCfdMt4BrandPlatforms() {
    const stream = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.cfdMt4brandPlatformsInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([]);
        }

        return this.brandsService()
          .getPlatformConnectionList(brand.id)
          .catch((e) => {
            log.error('error fetching platforms list for brand', e);
            return [];
          });
      }),
      rx.map((brandPlatforms) =>
        brandPlatforms.filter((bp) =>
          CFD_MT4_PLATFORMS.includes(bp.platform.code),
        ),
      ),
      tapFinalizeInProcessOff(this.cfdMt4brandPlatformsInProcess$),
      shareReplayRefOne(),
    )(null);

    return rx.obs.merge(
      stream,
      this.brandInProcess$.pipe(
        rx.tap((inProcess) =>
          this.cfdMt4brandPlatformsInProcess$.next(inProcess),
        ),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    );
  }

  streamRiskManagementPlatforms() {
    const stream$ = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.riskManagmentPlatformsInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([]);
        }

        return this.brandsService()
          .getPlatformConnectionList(brand.id)
          .catch((e) => {
            log.error('error fetching platforms list for brand', e);
            return [];
          });
      }),
      rx.map((brandPlatforms) =>
        brandPlatforms.filter((bp) =>
          RISK_MANAGEMENT_PLATFORMS.includes(bp.platform.code),
        ),
      ),
      tapFinalizeInProcessOff(this.riskManagmentPlatformsInProcess$),
      shareReplayRefOne(),
    )(null);

    return rx.obs.merge(
      stream$,
      this.brandInProcess$.pipe(
        rx.tap((inProcess) =>
          this.riskManagmentPlatformsInProcess$.next(inProcess),
        ),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    );
  }

  streamMt4BrandPlatforms() {
    const streamFn = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.mt4brandPlatformsInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([]);
        }

        return this.brandsService()
          .getPlatformConnectionList(brand.id)
          .catch((e) => {
            log.error('error fetching platforms list for brand', e);
            return [];
          });
      }),
      rx.map((brandPlatforms) =>
        brandPlatforms.filter((bp) =>
          RISK_MANAGEMENT_MT4_PLATFORMS.includes(bp.platform.code),
        ),
      ),
      tapFinalizeInProcessOff(this.mt4brandPlatformsInProcess$),
      shareReplayRefOne(),
      rx.catchError((e) => {
        log.error('error in streamMt4BrandPlatforms', e);
        return streamFn();
      }),
    );

    return rx.obs.merge(
      streamFn(),
      this.brandInProcess$.pipe(
        rx.tap((inProcess) => this.mt4brandPlatformsInProcess$.next(inProcess)),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    );
  }

  streamCfdBrandPlatforms() {
    const stream$ = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.cfdBrandPlatformsInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([]);
        }

        return this.brandsService()
          .getPlatformConnectionList(brand.id)
          .catch((e) => {
            log.error('error fetching platforms list for brand', e);
            return [];
          });
      }),
      rx.map((brandPlatforms) =>
        brandPlatforms.filter((bp) => CFD_PLATFORMS.includes(bp.platform.code)),
      ),
      tapFinalizeInProcessOff(this.cfdBrandPlatformsInProcess$),
      shareReplayRefOne(),
    )(null);

    return rx.obs.merge(
      stream$,
      this.brandInProcess$.pipe(
        rx.tap((inProcess) => this.cfdBrandPlatformsInProcess$.next(inProcess)),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    );
  }

  streamDesks(): rx.Observable<Desk[]> {
    const streamFn = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.desksInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([] as Desk[]);
        }

        return rx.obs.from(this.brandsService().getDesks(brand.id));
      }),
      tapFinalizeInProcessOff(this.desksInProcess$),
      shareReplayRefOne(),
      rx.catchError((e) => {
        log.error('error in streamDesks', e);
        return streamFn();
      }),
    );

    const stream$ = streamFn();

    return rx.obs.merge(
      stream$,
      this.brandInProcess$.pipe(
        rx.tap((inProcess) => this.desksInProcess$.next(inProcess)),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    ) as any;
  }

  streamUsers(): rx.Observable<User[]> {
    const streamFn = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.usersInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([]);
        }

        return rx.obs.from(this.usersService().getUsersForBrand(brand.id));
      }),
      tapFinalizeInProcessOff(this.desksInProcess$),
      shareReplayRefOne(),
      rx.catchError((e) => {
        log.error('error in streamDesks', e);
        return streamFn();
      }),
    );

    const stream$ = streamFn();

    return rx.obs.merge(
      stream$,
      this.brandInProcess$.pipe(
        rx.tap((inProcess) => this.desksInProcess$.next(inProcess)),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    ) as any;
  }

  streamCfdLikeBrandPlatforms() {
    const stream$ = rx.pipe(
      () => this.brand$,
      tapInProcessOn(this.cfdLikeBrandPlatformsInProcess$),
      rx.switchMap((brand) => {
        if (_.isNil(brand)) {
          return rx.obs.of([]);
        }

        return this.brandsService()
          .getPlatformConnectionList(brand.id)
          .catch((e) => {
            log.error('error fetching platforms list for brand', e);
            return [];
          });
      }),
      rx.map((brandPlatforms) =>
        brandPlatforms.filter((bp) =>
          [PlatformCode.Cfd, PlatformCode.Bundle].includes(bp.platform.code),
        ),
      ),
      tapFinalizeInProcessOff(this.cfdLikeBrandPlatformsInProcess$),
      shareReplayRefOne(),
    )(null);

    return rx.obs.merge(
      stream$,
      this.brandInProcess$.pipe(
        rx.tap((inProcess) =>
          this.cfdLikeBrandPlatformsInProcess$.next(inProcess),
        ),
        rx.switchMap(() => rx.obs.NEVER),
      ),
    );
  }

  streamFtdAutoAssigmentForNonAssigneeList(): rx.Observable<
    FtdAutoAssignment[]
  > {
    return rx.pipe(
      () => this.brand$,
      rx.map((brand) => brand.ftdAutoAssignments),
      rx.map((ftdAutoAssignments) =>
        ftdAutoAssignments.filter(
          (a) => a.type === FtdAutoAssignmentType.NonAssignee,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamFtdAutoAssigmentForAssigneeList(): rx.Observable<FtdAutoAssignment[]> {
    return rx.pipe(
      () => this.brand$,
      rx.map((brand) => brand.ftdAutoAssignments),
      rx.map((ftdAutoAssignments) =>
        ftdAutoAssignments.filter(
          (a) => a.type === FtdAutoAssignmentType.Assignee,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  loadBrand(brandId: number) {
    this.loadBrandOp$.next(brandId);
  }

  saveFtdAutoAssignment(data: FtdAutoAssigmentData) {
    this.saveFtdAutoAssignmentOp$.next(data);
  }
}

export const currentBrandStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {},
    bindToController: true,
    controller: CurrentBrandStoreServiceDirectiveController,
  };
};

currentBrandStoreServiceDirective.$inject = [] as string[];
