import ng, { IScope } from 'angular';
import log from 'loglevel';

import BaseController from '~/source/common/controllers/base';

import template from './internal-transfer-details-row.component.html';
import { CustomersService } from '~/source/contact/common/services/customers';
import { InternalTransfer } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { INTERNAL_TRANSFER_UPDATED } from '~/source/common/constants/general-pubsub-keys';

const styles = require('./internal-transfer-details-row.component.scss');

export class InternalTransferDetailsRowController extends BaseController {
  styles = styles;
  internalTransfer: InternalTransfer;
  customerServiceInst: CustomersService;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();

    this.customerServiceInst = this.customersService();
  }

  updateReason(newReason) {
    // send request to the server
    this.customerServiceInst
      .getInternalTransferResource(
        this.internalTransfer.sourceTradingAccount.customerId,
        this.internalTransfer.sourceTradingAccount.id,
        this.internalTransfer.id,
      )
      .patchWithQuery<IElementRestNg<InternalTransfer>>({
        reason: newReason,
      })
      .then(
        (updatedRecord) => {
          this.internalTransfer.reason = updatedRecord.reason;
          this.prfClientGeneralPubsub.publish(
            INTERNAL_TRANSFER_UPDATED,
            updatedRecord,
          );
        },
        (error) => {
          log.error(
            'error editing internal transfer reason',
            error,
            error.data,
            error.response,
          );
        },
      );
  }
}

export const InternalTransferDetailsRowComponent = {
  template,
  controller: InternalTransferDetailsRowController,
  bindings: {
    internalTransfer: '<',
  },
};
