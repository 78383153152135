import FormFormatter from '../../../common/services/base-form-formatter';

export default class TradingAccountFormFormatter extends FormFormatter {
  get map() {
    return {
      currencyId: [
        {
          method: 'extract',
          params: ['id'],
        },
      ],
      platformId: [
        {
          method: 'extract',
          params: ['id'],
        },
      ],
      tradingAccountTypeId: [
        {
          method: 'extract',
          params: ['id'],
        },
      ],
      statusCode: [
        {
          method: 'extract',
          params: ['code'],
        },
      ],
      tradingAccountGroupId: [
        {
          method: 'extract',
          params: ['id'],
        },
      ],
    };
  }
}
