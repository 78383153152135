import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { CurrentUserStoreService } from './current-user-store.service';
import { observeChannel } from '../utilities/observe-channel';
import { CommunicationsSocketService } from '../components/call-manager/communications-socket.service';
import { getUserOwnCustomerCommunicationChannelName } from '../utilities/get-user-own-customer-communication-channel-name';
import { CommunicationsService } from '../components/call-manager/communications.service';
import { observeTransitionSuccess } from '../utilities/rxjs/observables/observe-transition-success';
import { RouterStoreService } from './router-store.service';
import { GeneralSharedWorkerService } from '../services/general-shared-worker.service';

export class CommunicationsStoreService {
  _newCall$ = rx.pipe(
    () => this.prfGeneralSharedWorkerService.newCall$,
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  _activeCall$ = rx.pipe(
    () => this.prfGeneralSharedWorkerService.activeCall$,
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  /* @ngInject */
  constructor(
    readonly prfGeneralSharedWorkerService: GeneralSharedWorkerService,
    readonly prfCurrentUserStore: CurrentUserStoreService,
    readonly communicationsService: CommunicationsService,
    readonly communicationsSocketService: CommunicationsSocketService,
    readonly prfRouterStore: RouterStoreService,
  ) {}

  get newCall$() {
    return this._newCall$;
  }

  get activeCall$() {
    return this._activeCall$;
  }
}
