export const symbolsExposureTableSettings = {
  ngTable: {
    parameters: {
      count: 10,
      page: 1,
    },
    settings: {
      counts: [10, 25, 50],
    },
  },
};
