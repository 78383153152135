import Component from './base/component';
import { PublicBrandsService } from '~/source/common/services/brands-public';
import { CurrenciesService } from '~/source/common/services/currencies';
import { Brand, Currency } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class CryptoCurrencySelectController extends Component.controller {
  static $inject = [
    'publicBrandsService',
    'currenciesService',
    ...Component.controller.$inject,
  ];

  dataServiceInst: PublicBrandsService;
  brand: Brand;
  currenciesService: CurrenciesService;

  get defaultPlaceholder() {
    return 'ewallet.CRYPTO_CURRENCY';
  }

  init() {
    this._data = [];

    this.$scope.$watch('vm.brand', () => {
      this.output = []; // reset states when the brand changes

      if (!this.brand) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Fetches the brand's currencies and returns a promise
   * which resolves to an array of currency objects.
   *
   * @returns {Promise}
   */
  query() {
    return this.currenciesService
      .filter({ isCrypto: true })
      .getListWithQuery()
      .then((data) => data.plain() as Currency[])
      .catch((e) => [] as Currency[]);
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: CryptoCurrencySelectController,
  bindings: {
    brand: '<',
  },
});
