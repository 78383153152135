import SocketService from '~/source/common/services/socket';

export class BundleSocketService extends SocketService {
  get channelRoot() {
    return 'Bundle';
  }

  /**
   * Picked object properties for streamer entityToUpdate
   *
   * Argument of table-live.controller.ts > operateStreamer > onEntityUpdate
   * and socket.ts > wrapListener > filter > _.pick
   *
   * @return string{[]}
   */
  get whiteList() {
    return [
      'statusCode',
      'investment',
      'payout',
      'profit',
      'entryDate',
      'expiryDate',
    ];
  }
}
