import RestService from '~/source/common/services/rest';
import _ from 'underscore';

export class ManagementPermissionsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'permissions';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }
}
