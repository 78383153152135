import { IScope } from 'angular';
import UiRouter from '@uirouter/core';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import CurrenciesService from '~/source/common/services/currencies';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './minimum-deposit-data.html';

class ComponentController extends BaseController {
  brand: Brand;

  brandsInstance: BrandsService;
  isEdit: boolean;
  prevAttributes: any;
  selectedCurrencies: any;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $stateParams: UiRouter.StateParams,
    readonly modelNormalizer: ModelNormalizerService,
    readonly currenciesService: CurrenciesService,
    readonly $scope: IScope,
  ) {
    super();

    this.brandsInstance = this.brandsService();
    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   * @return {void}
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = _.cloneDeep([...this.selectedCurrencies]);

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   * @return {void}
   */
  cancelEdit() {
    // Restore pre-edit state
    this.selectedCurrencies = this.prevAttributes;
    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    this.brandsInstance
      .setConfig({
        blockUiRef: 'brandMinimumDepositForm',
        growlRef: 'brandMinimumDepositForm',
      })
      .updateCurrencies(this.brand.id, this.selectedCurrencies)
      .then(() => {
        this.isEdit = false;
      });
  }
}

const MinimumDepositDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    separatedMinimumDeposits: '<',
    brand: '<',
    selectedCurrencies: '<',
  },
};

export default MinimumDepositDataComponent;
