import { IScope, IRootScopeService } from 'angular';

import BaseController from '~/source/common/controllers/base';
import predefinedFilters from './predefined-filters.json';

import template from './filter-group-form.html';
import { UserFilters } from '~/source/common/services/user-filters';

class FilterGroupFormController extends BaseController {
  group;
  category;

  groupName: string;
  allGroups;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly userFiltersService: UserFilters,
    readonly $rootScope: IRootScopeService,
  ) {
    super();
  }

  $onInit() {
    this.groupName = '';
    this.getAllUserFilters();
  }

  /**
   * Get all filter groups from server
   * @returns {Promise}
   */
  getAllUserFilters() {
    return this.userFiltersService
      .setConfig({
        growlRef: 'filterGroupModal',
        blockUiRef: 'filterGroupModal',
      })
      .filter({ filterTypeCode: this.category })
      .getListWithQuery()
      .then((customFilters) => {
        this.allGroups = customFilters.concat(predefinedFilters);
      });
  }

  /**
   * Called when a group is selected from the existing group list.
   *
   * @param {object} selectedGroup
   */
  onGroupSelect(selectedGroup) {
    this.group = selectedGroup;
    this.$scope.$emit('filterGroupForm:groupSelected', { existing: true });
  }

  /**
   * Called on group name changes.
   * Tries to find a group by that name in the existing allGroups list and selects it, if found.
   */
  onGroupNameChange() {
    // check if a group by that name exists
    const foundGroup = this.allGroups.find(
      (item) =>
        item.name.toString().toLocaleLowerCase() ===
        this.groupName.toString().toLowerCase(),
    );

    // if a group that is different than the selected group was found, select it.
    if (foundGroup) {
      this.onGroupSelect(foundGroup);
      return;
    }

    this.group = { name: this.groupName };
    // new group to save
    this.$scope.$emit('filterGroupForm:groupSelected', { existing: false });
  }
}

export default {
  template,
  controller: FilterGroupFormController,
  bindings: {
    group: '=',
    category: '<',
  },
};
