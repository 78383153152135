export function normalizeFieldsToIds(model, keys) {
  return keys.reduce(
    (acc, key) => {
      const newName = `${key}Id`;
      const newValue = model[key].id;

      acc[newName] = newValue;
      delete acc[key];

      return acc;
    },
    { ...model },
  );
}
