import template from './bundles-table.component.html';
import * as _ from '@proftit/lodash';

import TableLiveController from '~/source/common/components/table/table-live.controller';
import { CustomersService } from '~/source/contact/common/services/customers';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { SocketService } from '~/source/common/services/socket';
import { BundleSocketService } from '../position/bundles-socket.service';
import { bundlesTableSettings } from './bundles-table-settings';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { bundlesTableCols } from '../bundles-table-cols';
import { Bundle } from '~/source/common/models/bundle';
import { ICollectionRestNg } from '~/source/common/models/icollection-rest-ng';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
  ComponentLifecyclesObservables,
} from '@proftit/rxjs.adjunct.ng1';

interface RowInfo {
  bundle: Bundle;
  isSubTableActionOpen: boolean;
}

export class BundlesTableController extends TableLiveController {
  static $inject = [
    'customersService',
    'bundlesSocketService',
    'popupService',
    ...TableLiveController.$inject,
  ];

  /*
   * Bindings
   */
  customer: Customer;
  account: TradingAccount;

  /*
   * Injections
   */

  customersService: () => CustomersService;
  bundlesSocketService: BundleSocketService;
  popupService: PopupService;

  lifecycles = observeComponentLifecycles(this);

  settings = _.cloneDeep(bundlesTableSettings);
  dataServiceInstance: CustomersService;
  bundles: Restangular.ICollection;
  unsub$ = new rx.Subject<void>();
  rowsInfos$ = new rx.BehaviorSubject<{ [id: number]: RowInfo }>({});
  incomingRows$ = new rx.Subject<Bundle[]>();
  isSubTableActionOpen$ = new rx.BehaviorSubject<boolean>(true);
  opOpenCloseSubTable$ = new rx.Subject<number>();

  colsModel: { value: any[] };

  constructor(...args: any[]) {
    super(...args);

    this.dataServiceInstance = this.customersService();

    const { colsModel } = initBundlesTable(this.lifecycles, () =>
      this.initTable(),
    );

    this.colsModel = colsModel;

    useStreams(
      [this.streamCalcRowsInfo(), this.streamSubTableOpening()],
      this.unsub$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();

    this.unsub$.next();
    this.unsub$.complete();
  }

  streamCalcRowsInfo() {
    return rx.pipe(
      () => this.incomingRows$,
      rx.map((bundles) => bundles.map((b) => this.createRowInfoForBundle(b))),
      rx.map((rowsInfosArray) => _.keyBy((o) => o.bundle.id, rowsInfosArray)),
      rx.tap((rowsInfos) => this.rowsInfos$.next(rowsInfos)),
    )(null);
  }

  streamSubTableOpening() {
    return rx.pipe(
      () => this.opOpenCloseSubTable$,
      rx.withLatestFrom(this.rowsInfos$),
      rx.map(([id, rowsInfos]) => rowsInfos[id]),
      rx.withLatestFrom(this.rowsInfos$),
      rx.map(([selectedRowInfo, rowsInfos]) => {
        return {
          ...rowsInfos,
          [selectedRowInfo.bundle.id]: {
            ...selectedRowInfo,
            isSubTableActionOpen: !selectedRowInfo.isSubTableActionOpen,
          },
        };
      }),
      rx.tap((rowsInfos) => this.rowsInfos$.next(rowsInfos)),
    )(null);
  }

  createRowInfoForBundle(bundle: Bundle) {
    return {
      bundle,
      isSubTableActionOpen: true,
    };
  }

  get pageKey(): string {
    return 'bundle';
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return this.bundlesSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.bundles';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.bundles;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean} - inidication whether to show colum actions.
   */
  showColumnActions() {
    return false;
  }

  /**
   * Method to allow doing work after entity was updated from socket.
   *
   * @param {object} entity - updated entity
   * @return {void}
   */
  onAfterEntityUpdate(entity) {}

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getBundlesResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: 'bundlesTable' })
      .expand([
        'tradingAccount',
        'tradingAccount.currency',
        'tradingAccount.platform',
      ])
      .sort({ entryDate: 'desc' });
  }

  parseLoadedData(data: ICollectionRestNg<Bundle>) {
    this.bundles = data;
    this.incomingRows$.next(data);

    return data;
  }

  openListTablePopup() {
    this.popupService.open({
      component: 'prfBundlesTablePopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }
}

function initBundlesTable(
  lifecycles: ComponentLifecyclesObservables,
  initTable: () => void,
) {
  const colsModel: { value: any[] } = { value: [] };

  const cols$ = observeShareCompChange<boolean>(
    lifecycles.onChanges$,
    'isPortfolioPage',
  ).pipe(
    rx.map((isPortfolioPage) => {
      if (isPortfolioPage) {
        return bundlesTableCols.filter((col) => {
          if (['profit', 'expiryDate'].includes(col.id)) {
            return false;
          }

          return true;
        });
      }

      return bundlesTableCols;
    }),
    rx.tap((columns) => (colsModel.value = columns)),
    shareReplayRefOne(),
  );

  const initTable$ = lifecycles.onInitShared$.pipe(
    rx.filter((isInit) => isInit),
    rx.switchMap(() => cols$),
    rx.tap(() => {
      initTable();
    }),
  );

  useStreams([initTable$, cols$], lifecycles.onDestroy$);

  return { initTable$, cols$, colsModel };
}

export const BundlesTableComponent = {
  template,
  controller: BundlesTableController,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
    isPortfolioPage: '<',
  },
};
