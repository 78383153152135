import AbstractChartComponent from './abstract-charts.controller';
import template from './withdrawal-charts.html';
const styles = require('./withdrawal-charts.scss');

class WithdrawalChartsController extends AbstractChartComponent {
  styles = styles;

  get blockUiInstanceName() {
    return 'withdrawalChartOptionsBlockUI';
  }

  get transactionType() {
    return 'withdrawals';
  }
}

export default {
  template,
  controller: WithdrawalChartsController,
  controllerAs: 'vm',
  bindings: {
    defaultData: '<',
    startDate: '<',
    endDate: '<',
    relativeStartDate: '<',
    relativeEndDate: '<',
    brandId: '<',
  },
};
