import * as _ from '@proftit/lodash';

import { IScope } from 'angular';
import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './cms-data.html';

class ComponentController extends BaseController {
  brand: Brand;

  isEdit: boolean;
  prevAttributes: any;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly brandsService: () => BrandsService,
  ) {
    super();

    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = _.pick(
      [
        'passwordResetURL',
        'outgoingEmail',
        'depositConfirmationUrl',
        'depositConfirmationEmail',
        'complianceEmail',
        'websiteAddress',
        'systemReplyToEmail',
        'click2emailReplyToEmail',
      ],
      this.brand,
    );

    // Enter edit mode
    this.isEdit = true;
  }

  save() {
    const normalizedModel = _.pick(
      [
        'passwordResetURL',
        'outgoingEmail',
        'depositConfirmationUrl',
        'depositConfirmationEmail',
        'complianceEmail',
        'websiteAddress',
        'systemReplyToEmail',
        'click2emailReplyToEmail',
      ],
      this.brand,
    );

    // save the brand
    this.brandsService()
      .getBrandResource(this.brand.id)
      .setConfig({ blockUiRef: 'cmsData' })
      .patchWithQuery(normalizedModel)
      .then(() => {
        // change to display mode
        this.isEdit = false;
      });
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.brand, this.prevAttributes);
    // Exit edit mode
    this.isEdit = false;
  }
}

const CmsDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};

export default CmsDataComponent;
