import log from 'loglevel';
import BaseController from '~/source/common/controllers/base';
import template from './collapsible-list.html';
import { IScope } from 'angular';

class Controller extends BaseController {
  // bindings
  items: any[];
  limit: number;
  format: string;

  currentLimit: number;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $translate: angular.translate.ITranslateService,
  ) {
    super();
  }

  // Each formatter here is expected to return a promise which resolves to an array
  formatters = {
    country: this.translateCountryNames.bind(this),
    language: this.translateLanguageNames.bind(this),
  };

  list = [];
  expandedList = [];
  collapsedList = [];

  $onInit() {
    this.currentLimit = this.limit || 4;
  }

  toggle() {
    this.list =
      this.list === this.collapsedList ? this.expandedList : this.collapsedList;
  }

  /**
   * One of the possible formatters: country names translations
   * @param {array<object>} list - array of items to translate
   * @return {Promise} - resolved to translated country names
   */
  translateCountryNames(list) {
    const termsList = list.map(({ alpha2Code }) => `country.${alpha2Code}`);
    return this.$translate(termsList).then((translations) =>
      Object.values(translations),
    );
  }

  /**
   * One of the possible formatters: language names translations
   * @param {array<object>} list - array of items to translate
   * @return {Promise} - resolved to translated country names
   */
  translateLanguageNames(list) {
    const termsList = list.map(({ code2 }) => `lang.${code2}`);
    return this.$translate(termsList).then((translations) =>
      Object.values(translations),
    );
  }

  /**
   * Format the list: if there is a configured formatter, use it
   * otherwise, return the list as is
   *
   * @param {array} list - array of items to translate
   * @return {Promise} - resolves to the formatted list
   */
  formatList(list) {
    if (this.formatters[this.format]) {
      return this.formatters[this.format](list);
    }

    return Promise.resolve(list);
  }

  /**
   * Called by parent when the 'items' binding changes
   *
   * Iterates through the items and formats each
   * @return {void}
   */
  onItemsChange() {
    if (!Array.isArray(this.items)) {
      return;
    }

    this.formatList(this.items)
      .then((formatted) => {
        // create two lists in advance, to save resources
        this.expandedList = formatted;
        this.collapsedList = formatted.slice(0, this.limit);
        // initial settings:
        this.list = this.collapsedList;
      })
      .catch((e) => {
        log.warn('Unable to format item %s', e);
      });
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    format: '@',
    items: '<',
    limit: '<',
  },
  controllerAs: 'vm',
};
