import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { createScoreDataFormGroup } from './create-score-data-form-group';
import { ComponentScore } from '../models/component-score';
import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { switchOn } from '@proftit/general-utilities';
import { calcMaxScoreFromAnswers } from '../../utilities/calc-max-score-from-answers';
import { calcAggragatedScoreFromAnswers } from '../../utilities/calc-aggragated-score-from-answers';
import { calcMaxScoreForQuestion } from '../../utilities/calc-max-score-for-question';
import {
  isNotNilFormValidator,
  optionalNumberMustBeNonNegativeFormValidator,
  optionalMaxNumberFormValidatorFactory,
} from '@proftit/ng1.reactive-forms.validators';
import {
  atLeastOneQuestionScoresCombinationMustBeEqualOrAboveTheMax,
  allQuestionScoresMustNotExceed,
} from '@proftit/crm.web.reactive-validations';

export function createComonentScoreFormGroup(componentScore: ComponentScore) {
  const scoreData = createScoreDataFormGroup(componentScore.scoreData);

  const conf = _.flow([
    (conf) => ({
      ...conf,
      scoreData,
      questionnaireComponentsId: new FormControl(
        componentScore.questionnaireComponentsId,
      ),
    }),
    (conf) => {
      if (
        componentScore._questionInfo.type !==
        QuestionnaireComponentTypeCode.MultiSelect
      ) {
        return conf;
      }

      return {
        ...conf,
        maxQuestionScore: new FormControl(componentScore.maxQuestionScore, {
          validators: [
            isNotNilFormValidator,
            optionalNumberMustBeNonNegativeFormValidator,
            optionalMaxNumberFormValidatorFactory(100),
          ],
        }),
      };
    },
  ])({});

  const validators = _.flow([
    (validators) => {
      if (
        componentScore._questionInfo.type ===
        QuestionnaireComponentTypeCode.MultiSelect
      ) {
        return [
          ...validators,
          atLeastOneQuestionScoresCombinationMustBeEqualOrAboveTheMax,
        ];
      }

      if (
        componentScore._questionInfo.type ===
        QuestionnaireComponentTypeCode.Confirm
      ) {
        return [...validators, allQuestionScoresMustNotExceed(100)];
      }

      return validators;
    },
  ])([]);

  return new FormGroup(conf, {
    validators,
    metaInfo: {
      question: componentScore._questionInfo,
    },
    calcValues: {
      maxScoreForQuestion: streamMaxScoreForQuesiton,
    },
  });
}

function streamMaxScoreForQuesiton(value$, metaInfo$) {
  return rx.pipe(
    () => rx.obs.combineLatest(value$, metaInfo$),
    rx.map(([value, metaInfo]) => {
      const questionType = _.get(['question', 'type'], metaInfo);
      return calcMaxScoreForQuestion(value, questionType);
    }),
  )(null);
}
