import angular from 'angular';
import BaseController from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';
import template from './contact-activity.html';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';

class ComponentController extends BaseController {
  static $inject = [
    'customersService',
    '$scope',
    'popupService',
    'featuresFlags',
    'PermPermissionStore',
  ];

  customersService: () => CustomersService;
  customer: Customer;
  popupService: PopupService;
  featuresFlags: FeaturesFlagsService;
  PermPermissionStore: angular.permission.PermissionStore;

  /**
   * Assign customer to user, by user selection per page.
   * Passed as a callback to the 'user assign dropdown' component.
   *
   * @param {object|null} [user] - the user which will be assigned to customer. if null, user will be unassigned
   * @param {object} [desk] - selected desk
   */
  assignToUser(user, desk) {
    return this.customersService()
      .setConfig({ blockUiRef: 'assignToPopup' })
      .patchElement(this.customer.id, {
        deskId: desk.id,
        userId: user ? user.id : null,
      })
      .then(() => {
        this.customer.desk = desk;
        this.customer.user = user;
      });
  }
}

export default {
  template,
  controllerAs: 'vm',
  controller: ComponentController,
  bindings: {
    customer: '<',
  },
};
