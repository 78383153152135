// This file was generated automatically. Tread lightly.

export enum TradingAccountTransferMethodTypeCode {
  ThreeD = '3D',
  Apm = 'APM',
  BonusFund = 'BONUS_FUND',
  BonusProduct = 'BONUS_PRODUCT',
  CardDeposit = 'CARD_DEPOSIT',
  CardWithdrawal = 'CARD_WITHDRAWAL',
  Cash = 'Cash',
  CashU = 'cashU',
  EWallet = 'E-WALLET',
  ExternalCardDeposit = 'EXTERNAL_CARD_DEPOSIT',
  InternationalWire = 'InternationalWire',
  LocalWire = 'LocalWire',
  Manual = 'MANUAL',
  Neteller = 'Neteller',
  Qiwi = 'Qiwi',
  SafeCharge = 'safeCharge',
  Unset = 'UNSET',
  WebMoney = 'WebMoney',
  WesternUnion = 'Western Union',
  WireDeposit = 'WIRE_DEPOSIT',
  WireWithdrawal = 'WIRE_WITHDRAWAL',
  MobileMoney = 'MOBILE_MONEY_WITHDRAWAL',
  ManualEWalletWithdrawal = 'MANUAL_E-WALLET_WITHDRAWAL',
}
