import template from './contacts-notifications-manager.component.html';
const styles = require('./contacts-notifications-manager.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import CustomersSocket from '~/source/contact/common/services/customers-socket';
import TokensService from '~/source/auth/services/tokens';
import * as rx from '@proftit/rxjs';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

export class ContactsNotificationsManagerController {
  reloadTable: () => void;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  channel = this.buildChannel();
  onNotificationsIconClickAction = new rx.Subject<void>();
  newCustomerAmount$ = this.streamNewCustomerAmount();

  /*@ngInject */
  constructor(
    readonly customersSocketService: CustomersSocket,
    readonly tokensService: TokensService,
  ) {
    useStreams([this.streamNotifyReloadTable()], this.lifecycles.onDestroy$);
  }

  streamNewCustomerAmount() {
    const currentAmount$ = new rx.BehaviorSubject<number>(0);
    return rx.pipe(
      () =>
        rx.obs.merge(
          observeChannel(this.customersSocketService, this.channel).pipe(
            rx.withLatestFrom(currentAmount$),
            rx.map(([json, currentAmount]) => currentAmount + 1),
          ),
          this.onNotificationsIconClickAction.pipe(rx.map(() => 0)),
        ),
      rx.startWith(0),
      rx.tap((x: number) => currentAmount$.next(x)),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyReloadTable() {
    return rx.pipe(
      () => this.onNotificationsIconClickAction,
      rx.tap(() => {
        this.reloadTable();
      }),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  buildChannel(): string {
    return `user.${this.tokensService.getCachedUser().id}.${
      this.customersSocketService.channelRoot
    }.new`;
  }
}

export const ContactsNotificationsManagerComponent = {
  template,
  controller: ContactsNotificationsManagerController,
  bindings: {
    reloadTable: '&',
  },
};
