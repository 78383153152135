import ng from 'angular';
import {
  observeShareCompChange,
  observeComponentLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './chosen-multiple-dropdown.component.html';
const styles = require('./chosen-multiple-dropdown.component.scss');
import { useStreams } from '@proftit/rxjs.adjunct';

export class ChosenMultipleDropdownController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  model;
  options;
  options$ = observeShareCompChange(this.lifecycles.onChanges$, 'options');

  onSelectChange: () => void;

  /* @ngInject */

  constructor() {
    useStreams([this.options$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  selectAllItems() {
    this.model = [...this.options];
    this.onSelectChange();
  }

  deselectAllItems() {
    this.model = null;
    this.onSelectChange();
  }
}

export const ChosenMultipleDropdownComponent = {
  template,
  controller: ChosenMultipleDropdownController,
  bindings: {
    options: '<',
    disabled: '<',
    model: '=',
    placeholder: '<',
    enableSelectAll: '<',
    onSelectChange: '&',
    name: '<',
  },
};
