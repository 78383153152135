import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'availableLanguages';

export class AvailableLanguage extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  getUserAvailableLanguages() {
    return this.setConfig({ suppressBlockUi: true })
      .getListWithQuery()
      .then((availableLang) => availableLang)
      .then((data) => data.plain());
  }
}
