import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './inline-growl.component.html';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
const styles = require('./inline-growl.component.scss');

export class InlineGrowlController {
  /* bindings */

  onCompInit: (a: { growlId: string }) => void;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  growlId = generateGrowlId();

  /* @ngInject */
  constructor() {}

  $onInit() {
    this.onCompInit({ growlId: this.growlId });
  }

  $onDestroy() {}

  $onChanges() {}
}

export const InlineGrowlComponent = {
  template,
  controller: InlineGrowlController,
  bindings: {
    onCompInit: '&',
  },
};
