import ng from 'angular';

import BaseController from '~/source/common/controllers/base';
import UsersService from '~/source/management/user/services/users';
import TokensService from '../services/tokens';
import template from './reset-password.html';

class ResetPasswordController extends BaseController {
  static $inject = [
    'usersService',
    '$state',
    '$location',
    'tokensService',
    'growl',
  ];

  usersService: () => UsersService;
  $state: ng.ui.IStateService;
  $location: ng.ILocationService;
  growl: ng.growl.IGrowlService;
  tokensService: TokensService;
  usersServiceInst: UsersService;
  password: string;

  constructor(...rest) {
    super(...rest);

    Object.assign(this, {
      usersServiceInst: this.usersService(),
    });
  }

  onValidated() {
    const { token } = this.$location.search();

    if (!token) {
      this.growl.error('login.MUST_HAVE_TOKEN', {
        referenceId: <any>'resetPass',
      });
      return Promise.reject(new Error('No token'));
    }

    // logout user before password reset because user token is overwriting the reset password token
    return this.tokensService.logout().finally(() =>
      // reset password
      this.usersServiceInst
        .resetPassword(this.password, token)
        .then(() => {
          this.$state.go('auth.login');
        })
        .catch(() => {
          this.$state.go('error.unauthorized');
        }),
    );
  }
}

export default {
  template,
  controller: ResetPasswordController,
  controllerAs: 'vm',
};
