import $ from 'jquery';

/* tslint:disable:max-line-length */

/**
 * Hide element directive allows both:
 *  1) hide element, default attribute property is event {string}.  example: prf-hide-element="{event: 'click'}"
 *  2) remove element from DOM, attribute have two properties, event {string} and expression {string} - set property or call function.
 *     example: prf-hide-element="{event: 'click', onHide: 'vm.showPopUp = false'}"
 *
 * Use case:
 *
 * Default:
 *  <div class="popup" prf-hide-element="{event: 'click'}" prf-stop-event="click">
 *      content
 * </div>
 *
 *  Optional:
 * <div class="popup" ng-if="vm.showPopUp" prf-hide-element="{event: 'click', onHide: 'vm.showPopUp = false'}" prf-stop-event="click">
 *      content
 * </div>
 *
 */
export const HideElementDirective = [
  '$document',
  '$timeout',
  ($document, $timeout) => ({
    restrict: 'A',
    link(scope, element, attrs) {
      let isPrfHideElementDisabled;
      attrs.$observe('isPrfHideElementDisabled', (newValue) => {
        isPrfHideElementDisabled = newValue === 'true';
      });
      // get options - executes object that contain properties event and/or expression(on the parent scope)
      const options = scope.$eval(attrs.prfHideElement);
      const eventType = options.event || 'click';
      const toggleHandleElement = $(options.toggleHandle);

      /**
       * Hide element: if an 'onHide' expression was supplied call it. otherwise, just hide using jQuery.
       * @return {void}
       */
      function hideElement() {
        if (options.onHide) {
          // execute the expression under $apply, as this is a jQuery event
          scope.$evalAsync(options.onHide);
        } else {
          // default behavior, hide element
          $(element).hide();
        }
      }

      /**
       * Checks if the click is outside the element. if so - hide element.
       * @param {jQuery.Event} e - jquery click event
       * @return {void}
       */
      function onDocumentClick(e) {
        if (isPrfHideElementDisabled) {
          return;
        }
        if (
          element[0].contains(e.target) ||
          // if a toggle handle was defined, make sure the click was outside of it as well
          (toggleHandleElement.length &&
            toggleHandleElement[0].contains(e.target))
        ) {
          return;
        }
        hideElement();
      }

      /**
       * Checks if the key pressed is 'Escape'. if so - hide element.
       * @param {jQuery.Event} e - jquery keypress event
       * @return {void}
       */
      function onDocumentKeyDown(e) {
        const ESCAPE_KEY = 27;
        // jquery normalizes 'which' so it would work on different browsers
        if (e.which === ESCAPE_KEY) {
          hideElement();
        }
      }

      /*
       * Bind the listeners after $timeout, to make sure it would only bind after element was opened
       * (if it is a popup, it might take a bit until it finishes opening - so we need to prevent it from closing)
       */
      $timeout(() => {
        $document.on(eventType, onDocumentClick);
        $document.on('keydown', onDocumentKeyDown);
      });

      // when element removed from DOM, remove listeners
      scope.$on('$destroy', () => {
        $document.off(eventType, onDocumentClick);
        $document.off('keydown', onDocumentKeyDown);
      });
    },
  }),
];
