import { Task } from '@proftit/crm.api.models.entities';
import { EntityCode, TaskStatusCode } from '@proftit/crm.api.models.enums';
import { switchOnEx } from '@proftit/general-utilities';
import moment from 'moment-timezone';
import { CrmCalEvent } from '../full-calendar/types/crm-cal-event';

export function taskToCalEvent(task: Task): CrmCalEvent {
  const { fontColor, backgroundColor } = calcColorForTask(task);
  const id = genTaskId(task);

  return {
    id,
    extendedProps: {
      entityId: task.id,
      entityCode: EntityCode.Task,
    },
    title: task.subject,
    start: moment(task.dueDate).toISOString(),
    end: moment(task.dueDate).toISOString(),
    color: backgroundColor,
    textColor: fontColor,
  };
}

function calcColorForTask(task: Task) {
  return switchOnEx(
    {
      [TaskStatusCode.Open]: () => ({
        backgroundColor: '#039be5',
        fontColor: 'white',
      }),
      [TaskStatusCode.InProgress]: () => ({
        backgroundColor: '#e4c441',
        fontColor: 'white',
      }),
      [TaskStatusCode.Done]: () => ({
        backgroundColor: '#33b679',
        fontColor: 'white',
      }),
    },
    task.taskStatus.code,
  );
}

function genTaskId(task: Task) {
  return `task-${task.id}`;
}
