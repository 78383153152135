import Controller from '~/source/common/controllers/base';
import { UserGroupsService } from '../services/user-groups';

export class GroupEditController extends Controller {
  userGroupsServiceInst: UserGroupsService;
  userGroup;
  isEditPermissionRequested: boolean;

  /*@ngInject */
  constructor(
    readonly userGroupsService: () => UserGroupsService,
    readonly permissionStructuresService,
    readonly growl,
    readonly managementPermissionsModifierService,
    readonly $stateParams,
  ) {
    super();
    let permissionsStructurePromise;
    let userGroupPromise;
    let groupPermissionsPromise;

    this.userGroupsServiceInst = this.userGroupsService();
    this.isEditPermissionRequested = false;
    (permissionsStructurePromise = this.permissionStructuresService.getListWithQuery()),
      (userGroupPromise = this.userGroupsServiceInst.getOneWithQuery(
        this.$stateParams.id,
      )),
      (groupPermissionsPromise = this.userGroupsServiceInst
        .resourceBuildStart()
        .getElement(this.$stateParams.id)
        .getNestedCollection('permissions')
        .resourceBuildEnd()
        .getListWithQuery());

    Promise.all([
      permissionsStructurePromise,
      groupPermissionsPromise,
      userGroupPromise,
    ]).then((data) => {
      const [permissionsStructure, permissions, userGroup] = data;
      /**
       * Convert permissions structure to the tree structure needed by the directive
       * and assign the edited group permissions to it
       */
      userGroup.permissions = this.managementPermissionsModifierService.modify(
        permissionsStructure.plain(),
        permissions,
      );

      this.userGroup = userGroup;
    });
  }

  enterToEditPermissionsMode(newState: boolean) {
    this.isEditPermissionRequested = newState;
  }
}
