import _ from 'underscore';
import commonModule from '../index';

/**
 *  Converts a date acceptable by the Date function, and converts it to ISO-8601 formatted date
 */
function dateToISO() {
  return function (input) {
    if (_.isEmpty(input)) {
      return undefined;
    }

    let output;
    try {
      output = new Date(input).toISOString();
    } catch (e) {} // silence invalid dates. keep the output as undefined;

    return output;
  };
}
dateToISO.$inject = [];

commonModule.filter('dateToISO', dateToISO);
