import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './crm-login-2fa-manager.component.html';
import { SmsProvidersStoreService } from '~/source/common/store-services/sms-providers-store.service';
import { Permissions } from '~/source/common/models/permission-structure';
import { SystemLogin2faStoreService } from '~/source/common/store-services/system-login-2fa-store.service';
import { generateSystemLogin2faForm } from './generate-system-login-2fa-form';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  CommunicationProvider,
  // SystemLogin2fa,
  CommunicationProviderFormsField,
  CommunicationProviderCredentials,
} from '@proftit/crm.api.models.entities';
import {
  observeObjectProxyFieldValue,
  observeProxyIsValid,
} from '@proftit/proxy-bean-rxjs';
import {
  getProxyBean,
  setWholeAsInitialBean,
  setWholeBean,
} from '@proftit/proxy-bean';
const styles = require('./crm-login-2fa-manager.component.scss');

export class CrmLogin2faManagerController {
  styles = styles;

  getProxyBean = getProxyBean;

  editPermissions = [Permissions.ManagementSystemConfiguration.Update];

  lifecycles = observeComponentLifecycles(this);

  mainFormF = generateSystemLogin2faForm();

  smsProviderSelectionComperator = (
    itemA: CommunicationProvider,
    itemB: CommunicationProvider,
  ) => {
    return _.get(['id'], itemA) === _.get(['id'], itemB);
  };

  cancelEditOp$ = new rx.Subject<void>();

  activateEditOp$ = new rx.Subject<void>();

  saveOp$ = new rx.Subject<void>();

  isInEdit$ = this.streamIsInEdit();

  isValidLogin2fa$ = observeProxyIsValid(this.mainFormF);

  getProviderCredUid = _.memoizeEs(
    (providerId: number, credCode: string) => {
      return `provider:${providerId}-cred:${credCode}`;
    },
    (a, b) => `${a}-${b}`,
  );

  /* @ngInject */
  constructor(
    readonly prfSmsProvidersStore: SmsProvidersStoreService,
    readonly prfSystemLogin2faStore: SystemLogin2faStoreService,
  ) {}

  $onInit() {
    useStreams(
      [this.streamFormMutable(), this.streamSave()],
      this.lifecycles.onDestroy$,
    );

    this.prfSystemLogin2faStore.load();
  }

  $onDestroy() {}

  $onChanges() {}

  streamFormMutableFromModel() {
    return rx.pipe(
      () => this.prfSystemLogin2faStore.login2fa$,
      rx.withLatestFrom(this.prfSmsProvidersStore.smsProviders$),
      rx.tap(([model, smsProviders]) => {
        const smsProvider = smsProviders.find(
          (pr) => pr.id === model.providerId,
        );
        const smsCredentialsSpreaded = calcSmsCredentialsSpreaded(
          model.smsCredentials,
          _.getEs(smsProvider, ['fields'], []),
        );

        setWholeBean(getProxyBean(this.mainFormF), {
          smsCredentialsSpreaded,
          isEnabled: model.isEnabled,
          provider: smsProvider,
        });
      }),
    )(null);
  }

  streamFormMutableFromProvider() {
    return rx.pipe(
      () =>
        observeObjectProxyFieldValue<CommunicationProvider>(
          this.mainFormF,
          'provider',
        ),
      rx.distinctUntilChanged(),
      rx.tap((provider) => {
        if (_.isNil(provider)) {
          this.mainFormF.smsCredentialsSpreaded = [];
          return;
        }

        const smsCredentialsSpreaded = calcSmsCredentialsSpreaded(
          [],
          provider.fields,
        );

        this.mainFormF.smsCredentialsSpreaded = smsCredentialsSpreaded;
      }),
    )(null);
  }

  streamFormMutableFromCancelEdit() {
    return rx.pipe(
      () => this.activateEditOp$,
      rx.map(() => _.cloneDeep(this.mainFormF)),
      rx.switchMap((prevValue) => {
        return this.cancelEditOp$.pipe(rx.map(() => prevValue));
      }),
      rx.tap((formValue) =>
        setWholeAsInitialBean(this.mainFormF._bean, formValue),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamFormMutable() {
    return rx.pipe(() =>
      rx.obs.merge(
        this.streamFormMutableFromModel(),
        this.streamFormMutableFromProvider(),
        this.streamFormMutableFromCancelEdit(),
      ),
    )(null);
  }

  streamIsInEdit() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.cancelEditOp$.pipe(rx.map(() => false)),
          this.activateEditOp$.pipe(rx.map(() => true)),
          this.saveOp$.pipe(rx.map(() => false)),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamSave() {
    return rx.pipe(
      () => this.saveOp$,
      rx.switchMap(() => {
        const normModel = normalizeFormToModel(this.mainFormF);
        return this.prfSystemLogin2faStore.save(normModel);
      }),
    )(null);
  }
}

function normalizeFormToModel(form) {
  return {
    isEnabled: form.isEnabled,
    providerId: form.provider.id,
    smsCredentials: form.smsCredentialsSpreaded.map((crd) => crd.value),
  };
}

function calcSmsCredentialsSpreaded(
  creds: CommunicationProviderCredentials[],
  fields: CommunicationProviderFormsField[],
) {
  return fields.map((field) => {
    const value = creds.find((cr) => cr.key === field.code);
    return createCommCredentialFromValAndSchema(field, value);
  });
}

function createCommCredentialFromValAndSchema(
  schema: CommunicationProviderFormsField,
  valueP: CommunicationProviderCredentials,
) {
  const value = _.defaultTo(
    {
      key: schema.code,
      value: null,
    },
    valueP,
  );

  return {
    schema,
    value,
  };
}

export const CrmLogin2faManagerComponent = {
  template,
  controller: CrmLogin2faManagerController,
};
