import { customerTradingAccountsAuditLogsTableColumns } from '../customer-trading-accounts-audit-log-table/customer-trading-accounts-audit-logs-table-columns';

export const tableColumns = [
  {
    title: 'customerTradingAccountsAuditLogsTable.TRADING_ACCOUNT_ID',
    field: '',
    fieldFormat: '{{ modelId }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.DATE',
    field: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: 'createdAt',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.FIELD',
    field: '',
    fieldFormat: '{{ presentationFieldName }}',
    sortable: 'presentationFieldName',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    field: 'newVal',
    fieldFormat: '{{ newVal | prfAuditLogFieldDisplay:model:field:typeHint }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: 'newVal',
    title: 'common.VALUE',
  },
  {
    field: 'oldVal',
    fieldFormat: '{{ oldVal | prfAuditLogFieldDisplay:model:field:typeHint }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: 'oldVal',
    title: 'common.OLD_VALUE',
  },
  {
    field: 'user',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'userId',
    title: 'auditLogs.fields.CHANGED_BY',
  },
  {
    field: 'createdByEnum',
    fieldFormat:
      '{{ ("auditLogs.fields.createdBy." + createdBy) | translate  }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'createdBy',
    title: 'auditLogs.fields.USER_TYPE',
  },
  {
    field: 'ip',
    fieldFormat: '{{ ip }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: 'ip',
    title: 'auditLogs.fields.IP',
  },
];
