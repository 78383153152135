import TablePopupController from '~/source/common/components/table/table-popup';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import template from './fees-table-popup.html';
import feesSettings from '../fees-settings.json';

import * as _ from '@proftit/lodash';
import DataAdditional from '../common/models/data-additional';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import Fee from '~/source/common/models/fee';
import MenuItem from '../common/models/menu-item';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { FEE_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import * as rx from '@proftit/rxjs';
import { useStream } from '~/source/common/utilities/use-stream';

class FeeTablePopupComponent extends TablePopupController {
  static $inject = [
    'customersService',
    'prfClientGeneralPubsub',
    ...TablePopupController.$inject,
  ];

  account: TradingAccount;
  customer: Customer;
  $close: () => void;
  dataAdditionals: { [id: number]: DataAdditional } = {};
  fees: IElementRestNg<any>;
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  displayWarning: boolean[];
  prfClientGeneralPubsub: ClientGeneralPubsub;
  unsub$: rx.Subject<any> = new rx.Subject<null>();

  $onInit() {
    super.$onInit();
    useStream(this.streamFeeUpdateStatus(), this.unsub$);
  }

  get cols() {
    return [...feesSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...feesSettings.tablePopup.ngTable };
  }

  get title() {
    return 'FEES';
  }

  get tableKey() {
    return 'fee';
  }

  streamFeeUpdateStatus() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === FEE_STATUS_UPDATE, ''),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      this.account = null;
      return;
    }
    this.customer = resolve.customer;
    this.account = resolve.account;
  }

  /**
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getFeesResource(this.customer.id, this.account.id)
      .expand(['updatingUser']);
  }

  /**
   * add currency object into query results. because this data is required for 'amount' field value formatting
   * defined in table-popup.json.
   *
   * @param {Array} data query results
   * @return {Array} returns formatted query results
   */
  parseLoadedData(data) {
    data.forEach((item) => {
      item.currency = this.account.currency;
    });
    if (!this.fees) {
      this.fees = data;
    } else {
      this.fees = _.unionWith(
        (a: any, b: any) => a.id === b.id,
        data,
        this.fees,
      );
    }
    this.dataAdditionals = this.generateDataAdditionals();
    return data;
  }

  /**
   * Generate data infos accompaning the data. Ui state...
   *
   * @return {object[]} the generated data infos
   */
  generateDataAdditionals() {
    return this.fees.reduce((acc, fee) => {
      const info = new DataAdditional();
      this.calcMenuItemsForRow(fee).then((menuItems) => {
        info.menuItems = menuItems;
      });

      return {
        ...acc,
        [fee.id]: info,
      };
    }, {});
  }

  /**
   * Calculate menu items for specific fee.
   *
   * Stateless function.
   *
   * @param {Fee} fee - fee
   * @return {Promise} menu items
   */
  calcMenuItemsForRow(fee: Fee): Promise<MenuItem[]> {
    let menuItems = [];

    if (fee.isDeletable) {
      menuItems = [
        ...menuItems,
        {
          labelCode: 'common.DELETE',
          actionCode: 'delete',
        },
      ];
    }

    return Promise.resolve(menuItems);
  }

  /**
   * Perform delete business action on the fee.
   *
   * @param {number} id = fee id
   * @return {Promise} promsie represnt the server action of cancelation.
   */
  deleteFee(fee, $feeIndex) {
    this.displayWarning[$feeIndex] = !this.displayWarning[$feeIndex];
    return this.customersService()
      .deleteFee(this.customer.id, this.account.id, fee.id)
      .then(() => {
        this.dataAdditionals = this.generateDataAdditionals();
        this.prfClientGeneralPubsub.publish(FEE_STATUS_UPDATE, '');
      });
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }
}

export default {
  template,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  controller: FeeTablePopupComponent,
  controllerAs: 'vm',
};
