import ng, { ui } from 'angular';
import tasksService, { TasksService } from './tasks.service';
import tasksStatusesService from './tasks-statuses.service';
import { TasksPopupComponent } from './tasks-popup/tasks-popup.component';
import TasksListTableComponent from './tasks-list.component';
import TasksSelectComponent from './dropdowns/tasks-statuses-select';
import TaskDataComponent from './task/task-data.component';
import TaskFormComponent from './task/task-form.component';
import { MyTasksDashboardComponent } from './prf-my-tasks-dashboard/prf-my-tasks-dashboard.component';
import { PrfTasksNavbarComponent } from './prf-tasks-navbar/prf-tasks-navbar.component';
import { PrfTasksCalendarComponent } from './prf-tasks-calendar/prf-tasks-calendar.componet';
import { prfUiCalendarRefDirective } from './prf-ui-calendar-ref.directive';
import { CrudTaskPopupComponent } from './crud-task-popup/crud-task-popup.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';

export const TasksModule = ng.module('crm.tasks', ['crm.common', 'ui.router']);

TasksModule.component('prfTaskData', TaskDataComponent)
  .directive('prfUiCalendarRef', prfUiCalendarRefDirective)
  .component('prfTaskForm', TaskFormComponent)
  .component('prfTasksListTable', TasksListTableComponent)
  .component('prfTasksStatusesSelect', TasksSelectComponent)
  .component('prfTasksPopup', TasksPopupComponent)
  .component('prfMyTasksDashboard', MyTasksDashboardComponent)
  .component('prfTasksNavbar', PrfTasksNavbarComponent)
  .component('prfTasksCalendar', PrfTasksCalendarComponent)
  .component('prfCrudTaskPopup', CrudTaskPopupComponent)
  .component('prfCheckboxGroup', CheckboxGroupComponent)
  .service('tasksService', tasksService)
  .service('tasksStatusesService', tasksStatusesService)
  .config([
    '$stateProvider',
    ($stateProvider: ui.IStateProvider) => {
      $stateProvider
        .state('crm.tasks', {
          url: '/tasks',
          template: '<ui-view/>',
        })
        .state('crm.tasks.item', {
          url: '/:id',
          component: 'prfTaskData',
          resolve: {
            task: ($transition$: any, tasksService: TasksService) =>
              tasksService
                .expand(['creator', 'employee', 'taskStatus'])
                .getOneWithQuery($transition$.params().id),
          },
        });
    },
  ]);
