import * as _ from '@proftit/lodash';
import { IScope, ITimeoutService } from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './number-range.html';

class Controller extends BaseController {
  isAutoOpen: boolean;
  model: any;
  settings: any;

  isOpen: boolean;
  focus: Function;
  viewNumbers: any;
  minimumRange: number;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $element: JQuery,
    readonly $timeout: ITimeoutService,
  ) {
    super();
  }

  $onInit() {
    this.isOpen = !!this.isAutoOpen;

    this.minimumRange = _.defaultTo(1, _.get('settings.minimumRange', this));

    /*
     * reset display values when got object with (minNumber, maxNumber) null values.
     * this is the expected object received from contacts-filter directive when user choose 'clear filters'
     */
    this.$scope.$watchCollection(
      () => this.model,
      this.onModelChange.bind(this),
    );
  }

  /**
   * this function is called on dropdown toggle BY uib-popup
   */
  toggled() {
    // if isAutoOpen=true isopen must be true. toggle otherwise
    this.isOpen = this.isAutoOpen ? true : !this.isOpen;

    // update placeholder text only when dropdown is closing
    if (!this.isOpen) {
      this.updateViewNumbers();
    }
  }

  /**
   * called by $watch when model is changed from popup inputs or bindings
   *
   * @param {object|mixed} nVal new model value
   * @param {object|mixed} oVal previous model value
   */
  onModelChange(nVal, oVal) {
    if (this.allowNegativeValues()) {
      return;
    }

    // set min value 0 when max value changes & min value is empty.
    if (
      this.model &&
      _.isNil(this.model.minNumber) &&
      nVal &&
      oVal &&
      nVal.maxNumber !== oVal.maxNumber
    ) {
      this.model.minNumber = 0;
    }

    // dropdown is closed. that means model updated from outside. update view numbers
    if (!this.isOpen) {
      this.updateViewNumbers();
    }
  }

  /**
   * whether or not allow negative values in min & max numbers
   * @returns {boolean}
   */
  allowNegativeValues() {
    return !!(this.settings && this.settings.allowNegativeValues);
  }

  /**
   * update values for dropdown placeholder
   */
  updateViewNumbers() {
    // update display values numbers on dropdown close
    this.viewNumbers = Object.assign({}, this.model);

    // set display minValue to 0 when model.maxValue provided and model.minValue is empty
    if (
      !this.allowNegativeValues() &&
      this.viewNumbers.maxNumber > 0 &&
      !this.viewNumbers.minNumber
    ) {
      this.viewNumbers.minNumber = 0;
    }

    // set display max value to be empty
    if (this.viewNumbers.minNumber && !this.viewNumbers.maxNumber) {
      this.viewNumbers.maxNumber = '';
    }
  }

  $postLink() {
    // focus pointer on first minNumber field
    this.focus = () => {
      this.$element.find('input[name=min]').focus();
    };

    if (this.isAutoOpen) {
      this.$timeout(() => {
        this.focus();
      });
    }
  }

  /**
   * Calculate the amount for the 'minimum' validation for the 'max' number field.
   *
   * return {number}
   */
  getMinForMaxNumberInput() {
    const minNumber = _.get('model.minNumber', this);
    const minimumRange = _.isNil(minNumber)
      ? Number.NEGATIVE_INFINITY
      : minNumber + this.minimumRange;

    return minimumRange;
  }
}

const NumberRangeComponent = {
  template,
  controller: Controller,
  bindings: {
    model: '=',
    title: '@',
    currency: '@',
    settings: '<',
    isAutoOpen: '<',
  },
};

export default NumberRangeComponent;
