import * as _ from '@proftit/lodash';
import ng from 'angular';

const directiveName = 'prfNgModelInitialValidity';

/**
 * This directive initialize the ngModel field is valid state.
 *
 * This was needed as angular-validation has a bug in which
 * the option initial-validity is not working.
 * https://github.com/hueitan/angular-validation/issues/286
 * Library is not maintained and we rely on another fork so
 * solution is to use components/directive combinations architecture.
 */
export const ngModelInitialValidityDirective = ($parse) => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModelCtrl) => {
      const initialValidity = $parse(attrs[directiveName])(scope);

      if (_.isBoolean(initialValidity)) {
        ngModelCtrl.$setValidity(ngModelCtrl.$name, initialValidity);
      }
    },
  };
};

ngModelInitialValidityDirective.$inject = ['$parse'];
