import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { Entity } from '@proftit/crm.api.models.general';

/**
 * Separate row meta collapsible interface
 */
interface RowMeta {
  id: number;
  showDetails: boolean;
}

/**
 * status table rows aggregations of rowMeta.showDetails
 */
enum AggregateRowsState {
  allCollapsing,
  allExpanding,
  notAllSame,
}

/**
 * MiniStream for calculate partial state of buttonStatus subject
 * Action from clicking on toggle button
 *
 * @param buttonStatusSubject
 * @param toggleExpansionAction
 */
function streamButtonStatusFromToggleExpansion(
  buttonStatusSubject: rx.Observable<boolean>,
  toggleExpansionAction: rx.Subject<void>,
) {
  return rx.pipe(
    () => toggleExpansionAction,
    rx.withLatestFrom(buttonStatusSubject),
    rx.map(([a, buttonStatus]) => !buttonStatus),
    shareReplayRefOne(),
  )(null);
}

/**
 * MiniStream for calculate partial state of buttonStatus subject
 * Action from aggregation row status equality change
 *
 * @param buttonStatusSubject
 * @param sumOfRowsChangeAction
 */
function streamButtonStatusFromRowChange(
  buttonStatusSubject: rx.Observable<boolean>,
  sumOfRowsChangeAction: rx.Subject<AggregateRowsState>,
) {
  return rx.pipe(
    () => sumOfRowsChangeAction,
    rx.withLatestFrom(buttonStatusSubject),
    rx.map(([aggregateRowsState, buttonStatus]) => {
      if (
        aggregateRowsState === AggregateRowsState.allExpanding &&
        !buttonStatus
      ) {
        return true;
      }
      if (
        aggregateRowsState === AggregateRowsState.allCollapsing &&
        buttonStatus
      ) {
        return false;
      }

      return null;
    }),
    rx.filter((buttonStatus) => !_.isNil(buttonStatus)),
    shareReplayRefOne(),
  )(null);
}

/**
 * Stream for calculate current state of buttonStatus$ observable stream
 * Actions merged from:
 * 1. clicking on toggle button
 * 2. aggregation row status equality change
 *
 * @param initialValue
 * @param toggleExpansionAction
 * @param sumOfRowsChangeAction
 */
function streamButtonStatus(
  initialValue: boolean,
  toggleExpansionAction: rx.Subject<void>,
  sumOfRowsChangeAction: rx.Subject<AggregateRowsState>,
) {
  const buttonStatusSubject = new rx.BehaviorSubject<boolean>(initialValue);

  return rx.pipe(
    () =>
      rx.obs.merge(
        streamButtonStatusFromToggleExpansion(
          buttonStatusSubject,
          toggleExpansionAction,
        ),
        streamButtonStatusFromRowChange(
          buttonStatusSubject,
          sumOfRowsChangeAction,
        ),
      ),
    rx.startWith(initialValue),
    rx.tap((buttonStatus) => buttonStatusSubject.next(buttonStatus)),
    shareReplayRefOne(),
  )(null);
}

/**
 * Stream build new row meta from incoming collections
 * the initialValue gets from `buttonStatus$`
 * if `keepRowsState` equal to true all cells
 * keep his self rowMeta when new page of collections loading
 *
 * @param rowsMeta$
 * @param collection$
 * @param buttonStatus$
 * @param keepRowsState
 */
function streamRowsMetaFromCollection(
  rowsMeta$: rx.Observable<Record<number, RowMeta>>,
  collection$: rx.Observable<Entity[]>,
  buttonStatus$: rx.Observable<boolean>,
  keepRowsState: boolean,
): rx.Observable<Record<number, RowMeta>> {
  return rx.pipe(
    () => collection$,
    rx.withLatestFrom(rowsMeta$, buttonStatus$),
    rx.map(([rows, rowsMeta, buttonStatus]) => {
      if (keepRowsState) {
        const newRows = rows.reduce((acc, row) => {
          if (rowsMeta[row.id]) {
            return acc;
          }
          return [...acc, row];
        }, []);

        const newRowsMeta: RowMeta[] = newRows.map((row) => ({
          id: row.id,
          showDetails: buttonStatus,
        }));

        return [..._.values(rowsMeta), ...newRowsMeta];
      }
      return rows.map((row) => ({
        id: row.id,
        showDetails: buttonStatus,
      }));
    }),
    rx.map((rows) => {
      return _.keyBy((row) => row.id, rows);
    }),
    shareReplayRefOne(),
  )(null);
}

/**
 * Stream listen to buttonStatus$ and when its changing
 * he assign all rowMeta.showDetails param to his value
 *
 * @param rowsMeta$
 * @param buttonStatus$
 */
function streamRowsMetaFromButtonStatus(
  rowsMeta$: rx.Observable<Record<number, RowMeta>>,
  buttonStatus$: rx.Observable<boolean>,
) {
  return rx.pipe(
    () => buttonStatus$,
    rx.withLatestFrom(rowsMeta$),
    rx.map(([buttonStatus, rowsMeta]) => {
      return _.mapValues(
        (rowMeta) => ({
          ...rowMeta,
          showDetails: buttonStatus,
        }),
        rowsMeta,
      );
    }),
    shareReplayRefOne(),
  )(null);
}

/**
 * change separate row collapsible state from
 * click event action on the specific row
 *
 * @param rowsMeta$
 * @param toggleRowShowDetailsAction
 */
function streamToggleRowSowDetails(
  rowsMeta$: rx.Observable<Record<number, RowMeta>>,
  toggleRowShowDetailsAction: rx.Observable<number>,
) {
  return rx.pipe(
    () => toggleRowShowDetailsAction,
    rx.withLatestFrom(rowsMeta$),
    rx.map(([id, rowsMeta]) => {
      const origRow = rowsMeta[id];
      const newRowMeta = {
        ...origRow,
        showDetails: !origRow.showDetails,
      };
      const newRows = { ...rowsMeta };
      newRows[id] = newRowMeta;

      return newRows;
    }),
    shareReplayRefOne(),
  )(null);
}

/**
 * calculate rows meta collapsible state
 * from three previous streams
 *
 * @param collection$
 * @param buttonStatus$
 * @param keepRowsState
 * @param toggleRowShowDetailsAction
 */
function streamRowsMeta(
  collection$,
  buttonStatus$,
  keepRowsState,
  toggleRowShowDetailsAction,
) {
  const rowsMetaSubject = new rx.BehaviorSubject<Record<number, RowMeta>>({});
  return rx.pipe(
    () =>
      rx.obs.merge(
        streamRowsMetaFromCollection(
          rowsMetaSubject,
          collection$,
          buttonStatus$,
          keepRowsState,
        ),
        streamRowsMetaFromButtonStatus(rowsMetaSubject, buttonStatus$),
        streamToggleRowSowDetails(rowsMetaSubject, toggleRowShowDetailsAction),
      ),
    rx.startWith({}),
    rx.tap((rowsMeta) => rowsMetaSubject.next(rowsMeta)),
    shareReplayRefOne(),
  )(null);
}

/**
 * calculate aggregation rows expansion status
 * when rowsMeta$ change
 * side effect pulse send to sumOfRowsChangeAction
 * with AggregateRowsState argument
 *
 * @param rowsMeta$
 * @param sumOfRowsChangeAction
 */
function streamAggregateRowsExpansion(
  rowsMeta$: rx.Observable<Record<number, RowMeta>>,
  sumOfRowsChangeAction: rx.Subject<AggregateRowsState>,
): rx.Observable<AggregateRowsState> {
  return rx.pipe(
    () => rowsMeta$,
    rx.map((rows) => {
      const values = _.values(rows);
      if (values.length === 0 || values.every((value) => !value.showDetails)) {
        return AggregateRowsState.allCollapsing;
      }
      if (values.every((value) => value.showDetails)) {
        return AggregateRowsState.allExpanding;
      }
      return AggregateRowsState.notAllSame;
    }),
    rx.tap((AggregateRowsExpansionStatus) =>
      sumOfRowsChangeAction.next(AggregateRowsExpansionStatus),
    ),
    shareReplayRefOne(),
  )(null);
}

/**
 * Run main stream in constructor useStreams function
 * from wrapper controller
 * to stream aggregateRowsExpansion
 *
 * @param rowsMeta$
 * @param aggregateRowsExpansion
 */
function streamMain(
  rowsMeta$: rx.Observable<Record<number, RowMeta>>,
  aggregateRowsExpansion: rx.Observable<AggregateRowsState>,
) {
  return rx.pipe(
    () => rx.obs.merge(rowsMeta$, aggregateRowsExpansion),
    shareReplayRefOne(),
  )(null);
}

/**
 * the main exported function that saves Expandable/Collapsible
 * states for table rows in returns object
 *
 * @param collection$
 * @param keepRowsState
 * @param expandedInitialValue
 */
export function collapsibleTableDetails(
  collection$: rx.Observable<Entity[]>,
  keepRowsState: boolean,
  expandedInitialValue: boolean,
) {
  const tableToggleExpansionAction = new rx.Subject<void>();
  const toggleRowShowDetailsAction = new rx.Subject<number>();
  const sumOfRowsChangeAction = new rx.Subject<AggregateRowsState>();
  const buttonStatus$ = streamButtonStatus(
    expandedInitialValue,
    tableToggleExpansionAction,
    sumOfRowsChangeAction,
  );
  const rowsMeta$ = streamRowsMeta(
    collection$,
    buttonStatus$,
    keepRowsState,
    toggleRowShowDetailsAction,
  );
  const aggregateRowsExpansion = streamAggregateRowsExpansion(
    rowsMeta$,
    sumOfRowsChangeAction,
  );

  const main$ = streamMain(rowsMeta$, aggregateRowsExpansion);

  return {
    tableToggleExpansionAction,
    toggleRowShowDetailsAction,
    buttonStatus$,
    rowsMeta$,
    main$,
  };
}
