import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';

type State = Record<string, boolean>;

export function useTrackerState(
  life: ComponentLifecyclesObservables,
  trackerTypes,
) {
  const setInvalidOpt$: rx.Subject<string> = new rx.Subject();
  const setValidOpt$: rx.Subject<string> = new rx.Subject();
  const areValid$ = streamAreValid();
  const isAllValid$ = streamIsAllValid();

  function setInvalid(type) {
    setInvalidOpt$.next(type);
  }

  function setValid(type) {
    setValidOpt$.next(type);
  }

  function streamSetInvalid(
    state$: rx.Observable<State>,
  ): rx.Observable<State> {
    return rx.pipe(
      () => setInvalidOpt$,
      rx.withLatestFrom(state$),
      rx.map(([type, state]) => ({ ...state, [type]: false })),
      shareReplayRefOne(),
    )(null);
  }

  function streamSetValid(state$: rx.Observable<State>): rx.Observable<State> {
    return rx.pipe(
      () => setValidOpt$,
      rx.withLatestFrom(state$),
      rx.map(([type, state]) => ({ ...state, [type]: true })),
      shareReplayRefOne(),
    )(null);
  }

  function streamAreValid(): rx.Observable<State> {
    const trackerTypesState = _.fromPairs(
      trackerTypes.map((type) => [type, true]),
    );

    const state$ = new rx.BehaviorSubject<State>(trackerTypesState as any);

    return rx.pipe(
      () => rx.obs.merge(streamSetInvalid(state$), streamSetValid(state$)),
      rx.startWith(trackerTypesState),
      rx.tap((state) => state$.next(state as any)),
      shareReplayRefOne(),
    )(null) as rx.Observable<any>;
  }

  function streamIsAllValid() {
    return rx.pipe(
      () => areValid$,
      rx.map((areValid) => Object.values(areValid).every((item) => item)),
      shareReplayRefOne(),
    )(null);
  }

  useStreams([areValid$, isAllValid$], life.onDestroy$);

  return {
    setInvalid,
    setValid,
    isAllValid$,
  };
}
