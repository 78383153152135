import template from './confirm-buttons.component.html';
const styles = require('./confirm-buttons.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class ConfirmButtonsController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ConfirmButtonsComponent = {
  template,
  controller: ConfirmButtonsController,
  bindings: {
    cancelText: '<',
    confirmText: '<',
    disableConfirm: '<',
    onCancelClick: '&',
    onConfirmClick: '&',
  },
};
