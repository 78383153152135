import template from './brand-account-status-automation-audit-log-popup.component.html';
const styles = require('./brand-account-status-automation-audit-log-popup.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Brand } from '@proftit/crm.api.models.entities';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import * as rx from '@proftit/rxjs';
import { settings } from './settings';
import { tableColumns } from './table-columns';
import * as _ from '@proftit/lodash';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { useStreams } from '@proftit/rxjs.adjunct';
import { BrandsService } from '~/source/management/brand/services/brands';
import { calcInfoForBrandAccountStatusAutomationAuditLog } from '../../shared/calc-info-for-brand-account-status-automation-audit-log';

interface Resolve {
  brand: Brand;
}

class Controller extends TablePopupController {
  static $inject = [
    'brandsService',
    'blockUI',
    ...TablePopupController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  settings = _.cloneDeep(settings);
  cols = [...tableColumns];
  brandsService: () => BrandsService;
  dataServiceInstance: BrandsService;
  brand: Brand;

  brand$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  ).pipe(
    rx.map((r) => _.get(['brand'], r)),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((brand) => (this.brand = brand)),
  );
  opInitTable$ = new rx.Subject<number>();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams([this.streamInitTable()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.opInitTable$,
          this.brand$.pipe(rx.filter((c) => !_.isNil(c))),
        ),
      rx.tap(([rows, brand]) => this.initTable(rows)),
    )(null);
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings;
  }

  get tableKey() {
    return 'brandAccountStatusAutomationAuditLogPopup';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getAccountStatusAutomationLogsResource(this.brand.id)
      .expand(['user'])
      .sort({ requestedAt: 'desc' });
  }

  /**
   * parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[]): any[] {
    data.forEach((record) => {
      record._dataAsPrintable = calcInfoForBrandAccountStatusAutomationAuditLog(
        record.data,
      );
    });
    return data;
  }
}

export const BrandAccountStatusAutomationAuditLogPopupComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
