import * as _ from '@proftit/lodash';
import { isClearingCompanyIsBlocking } from '~/source/common/models/clearing-company/is-clearing-company-is-blocking';

export function clearingPriorityIsBlockingLast(priorityList) {
  if (_.isNil(priorityList)) {
    return true;
  }

  const isBlockingCompanyIndex = priorityList.findIndex((priority) =>
    isClearingCompanyIsBlocking(
      _.get(['clearingCompanyConnection', 'clearingCompany'], priority),
    ),
  );

  if (isBlockingCompanyIndex < 0) {
    return true;
  }

  if (isBlockingCompanyIndex === priorityList.length - 1) {
    return true;
  }

  const company =
    priorityList[isBlockingCompanyIndex].clearingCompanyConnection
      .clearingCompany;

  return {
    result: false,
    message: `${company.name} as a blocking clearing company must be last in a priority list.`,
  };
}
