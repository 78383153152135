import ng from 'angular';
import settings from '../withdrawal-settings.json';

import CreditCardComponent from './credit-card.component';
import RegularWithdrawalComponent from './credit-card/regular/regular.component';
import ChargebackWithdrawalComponent from './credit-card/chargeback.component';
import ManualWithdrawalComponent from './credit-card/manual/manual.component';
import WithdrawalRequestInfoComponent from './common/withdrawal-request-info/withdrawal-request-info.component';
import WireComponent from './wire/wire.component';
import ValidationService from './common/validation.service';
import WithdrawalTablePopupComponent from './confirm-withdrawal-popup/confirm-withdrawal-popup.component';
import { WithdrawalEwalletComponent } from './withdrawal-ewallet/withdrawal-ewallet.component';
import { WithdrawalMobileMoneyComponent } from './withdrawal-mobile-money/withdrawal-mobile-money.component';
import { MobileMoneySelectComponent } from './mobile-money-select/mobile-money-select.component';

export default ng
  .module('crm.contact.tradingAccount.withdrawal.confirm', [])
  .constant('withdrawalSettings', settings)
  .service('withdrawalValidationService', ValidationService)
  .component('prfWithdrawalCreditCard', CreditCardComponent)
  .component('prfWithdrawalRequestInfo', WithdrawalRequestInfoComponent)
  .component('prfWithdrawalManual', ManualWithdrawalComponent)
  .component('prfWithdrawalChargeback', ChargebackWithdrawalComponent)
  .component('prfWithdrawalRegular', RegularWithdrawalComponent)
  .component('prfWithdrawalWire', WireComponent)
  .component('prfWithdrawalEwallet', WithdrawalEwalletComponent)
  .component('prfWithdrawalMobileMoney', WithdrawalMobileMoneyComponent)
  .component('prfMobileMoneySelect', MobileMoneySelectComponent)
  .component('prfConfirmWithdrawalPopup', WithdrawalTablePopupComponent).name;
