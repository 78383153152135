import { FormArray } from '@proftit/ng1.reactive-forms';
import { createComonentScoreFormGroup } from './create-component-score-form-group';
import { ComponentScore } from '../models/component-score';
import { allScoresShouldAccumulateToOneHundredFormValidator } from '../../utilities/all-scores-should-accumulate-to-one-hundred.form-validator';

export function createComponentsScoresFormArray(
  componentScoresList: ComponentScore[],
) {
  const questionsScoresCtrls = componentScoresList.map((componentScore) =>
    createComonentScoreFormGroup(componentScore),
  );

  return new FormArray(questionsScoresCtrls, {
    validators: [allScoresShouldAccumulateToOneHundredFormValidator as any],
  });
}
