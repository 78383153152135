import * as _ from '@proftit/lodash';

import { Changeset } from './changeset';

export function resetFieldUpdate(fieldName: string, changeset: Changeset<any>) {
  const changes = _.reject(
    (change) => change.fieldName === fieldName,
    changeset.changes,
  );

  return {
    ...changeset,
    changes,
  };
}
