import ng from 'angular';
import { shareReplayRefOne, useStreams, pipeLog } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './yup-validation-error.component.html';
import { YupValidationCode } from '../../yup-validtion/yup-validation-code';
const styles = require('./yup-validation-error.component.scss');

interface YupError {
  key: string;
  code: YupValidationCode;
}

export class YupValidationErrorController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  errors$ = observeShareCompChange<YupError[]>(
    this.lifecycles.onChanges$,
    'errors',
  );

  key$ = observeShareCompChange<string>(this.lifecycles.onChanges$, 'key');

  error$ = this.streamError();

  errors;

  /* @ngInject */
  constructor(readonly $translate: angular.translate.ITranslateService) {
    useStreams([this.streamError()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamError() {
    return rx.pipe(
      () => rx.obs.combineLatest([this.errors$, this.key$]),
      // rx.catchError((err, caught) => {
      //   log.error('error translating validaiton message', err);
      // }),
      rx.map(([errors, key]) => {
        if (_.isNil(errors)) {
          return null;
        }
        return errors.find((item) => item.key === key);
      }),
      rx.switchMap((error) => {
        if (_.isNil(error)) {
          return rx.obs.of(null);
        }

        return rx.obs.from(
          (this.$translate(`yupValidation.${error.code}`) as any) as Promise<
            string
          >,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const YupValidationErrorComponent = {
  template,
  controller: YupValidationErrorController,
  bindings: {
    errors: '<',
    key: '<',
  },
};
