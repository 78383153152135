import template from './brand-account-status-automation-general-form.component.html';
const styles = require('./brand-account-status-automation-general-form.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { Brand } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';

export class BrandAccountStatusAutomationGeneralFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandAccountStatusAutomationGeneralFormComponent = {
  template,
  controller: BrandAccountStatusAutomationGeneralFormController,
};
