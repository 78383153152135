import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import UsersService from '~/source/management/user/services/users';
import { Brand, User } from '@proftit/crm.api.models.entities';

import template from './brand-desk-data.html';

class ComponentController extends BaseController {
  getLogo: () => Promise<Brand>;
  brand: Brand;
  user: User;

  brandsServiceInst: BrandsService;
  usersServiceInst: UsersService;
  brandLogo: string;
  prevAttributes: any;
  isEdit: boolean;
  desks: any[];

  /*@ngInject */
  constructor(
    readonly modelNormalizer: ModelNormalizerService,
    readonly brandsService: () => BrandsService,
    readonly $scope: IScope,
    readonly usersService: () => UsersService,
  ) {
    super();
    /**
     *  initial state is determined according to the id: if the brand has no id,
     *  it must be a new brand, so enter edit mode
     */
    Object.assign(this, {
      brandsServiceInst: this.brandsService(),
      usersServiceInst: this.usersService(),
      brandLogo: null,
    });

    this.$scope.$watch('vm.brand', () => {
      if (_.isEmpty(this.brand)) {
        return;
      }

      this.getLogo().then((brand) => {
        this.brandLogo = brand.logo;
      });
    });
  }

  /**
   * Enter edit mode:
   * Save current desks so it can be restored if the user chooses to cancel
   *
   * @returns {void}
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = _.cloneDeep(this.desks);

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous desks
   *
   * @returns {void}
   */
  cancelEdit() {
    // Restore pre-edit state
    this.desks = this.prevAttributes;
    // Exit edit mode
    this.isEdit = false;
  }

  /**
   * Save the changed brand/desk.
   *
   * Note that we are using POST, which is unusual:
   * in this case, a POST request with an existing "brandId" will override the previous one,
   * effectively acting as an update.
   * @return {void}
   */
  save() {
    this.usersServiceInst
      .addBrandConnection(this.user.id, {
        brandId: this.brand.id,
        desks: this.desks.map((desk) => desk.id),
      })
      .then(() => {
        this.isEdit = false;
      });
  }
}

const BrandDeskDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    desks: '=',
    brand: '=',
    brands: '<',
    user: '<',
    removeBrandDesk: '&removeFn',
    getLogo: '&getLogoFn',
  },
};

export default BrandDeskDataComponent;
