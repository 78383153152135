import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import PopupService from '~/source/common/components/modal/popup.service';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import type { IModalInstanceService } from 'angular-ui-bootstrap';

interface PopupState {
  instance: IModalInstanceService;
  isOpen: boolean;
}

export class PfTasksListPopupService {
  unsub$ = new rx.Subject<void>();

  openOp$ = new rx.Subject<void>();

  closeOp$ = new rx.Subject<void>();

  popupState$ = this.streamPopupState();

  /* @ngInject */
  constructor(readonly popupService: PopupService) {
    useStreams([this.popupState$], this.unsub$);
  }

  openPopupComponent() {
    return this.popupService.open({
      component: 'prfTasksPopup',
      shouldCloseOnRouteChange: false,
    });
  }

  streamPopupState() {
    const popupStateSubject = new rx.BehaviorSubject<PopupState>({
      instance: null,
      isOpen: false,
    });

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPopupStateFromClose(popupStateSubject),
          this.streamPopupStateFromOpen(popupStateSubject),
          this.streamPopupStateFromClosingEvent(popupStateSubject),
        ),
      rx.tap((popupState: PopupState) => popupStateSubject.next(popupState)),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateCloseFromAction(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => this.closeOp$,
      rx.withLatestFrom(popupState$),
      rx.filter(
        ([_op, popupState]) => popupState.instance && popupState.isOpen,
      ),
      rx.map(([_op, popupState]) => {
        popupState.instance.close();

        return {
          instance: null,
          isOpen: false,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateFromClose(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => this.streamPopupStateCloseFromAction(popupState$),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateFromOpen(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => this.openOp$,
      rx.withLatestFrom(popupState$),
      rx.map(([_a, popupState]) => popupState),
      rx.filter((popupState) => !popupState.isOpen),
      rx.map((popupState) => {
        const instance = this.openPopupComponent();

        return {
          instance,
          isOpen: true,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateFromClosingEvent(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => popupState$,
      rx.filter((popupState) => popupState.isOpen),
      rx.switchMap(
        ({ instance }) =>
          (instance.result
            .then(() => true)
            .catch(() => true) as any) as Promise<boolean>,
      ),
      rx.withLatestFrom(popupState$),
      rx.map(([_a, popupState]) => ({ ...popupState, isOpen: false })),
      shareReplayRefOne(),
    )(null);
  }

  open() {
    this.openOp$.next();
  }

  closePopup() {
    this.closeOp$.next();
  }
}
