import * as _ from '@proftit/lodash';
import angular from 'angular';

export function mapTranslationsArray(
  translationsObg: Record<string, string>,
  $translate: angular.translate.ITranslateService,
): Promise<Record<string, string>> {
  const translateArray = Object.values(translationsObg);
  return (($translate(translateArray) as any) as Promise<
    Record<string, string>
  >)
    .then((translations) =>
      Object.keys(translationsObg).map((key, index) => {
        const translationCode = translateArray[index];
        return [key, translations[translationCode]];
      }),
    )
    .then((pairs) => _.fromPairs(pairs));
}
