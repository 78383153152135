import BaseController from '~/source/common/controllers/base';
import template from './marketing-stats.html';

class MarketingStatsController extends BaseController {}

export default {
  template,
  controller: MarketingStatsController,
  controllerAs: 'vm',
  bindings: {
    stats: '<',
  },
};
