import ng from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';

import template from './praxis-deposit-form.component.html';

/**
 * Praxis deposit form.
 *
 * Statefull component. Gets a customer and an account, perform a server request to calc
 * the praxis deposit url form, and show it via iframe.
 */
class PraxisDepositFormComponent extends BaseController {
  static $inject = ['$sce', 'customersService'];

  $sce: ng.ISCEService;
  customersService: () => CustomersService;
  customerServiceInst: CustomersService;
  customer: Customer;
  account: TradingAccount;
  depositUrl: string;

  /**
   * Component life cycle method. on start of life.
   *
   * @returns {void}
   * @private
   */
  $onInit() {
    this.customerServiceInst = this.customersService();
    this.calcDepositUrl(this.customer, this.account);
  }

  /**
   * Component Life cycle methdo. on input change - customer property.
   *
   * magic function - called by BaseController $onChanges.
   *
   * @returns {void}
   */
  onCustomerChange() {
    this.calcDepositUrl(this.customer, this.account);
  }

  /**
   * Calculate the deposit url for the iframe.
   *
   * Statefull. It sets the results on the controller.
   *
   * @param {Customer} customer - Customer
   * @param {TradingAccount} account - Trading account
   * @returns {void}
   * @private
   */
  calcDepositUrl(customer: Customer, account: TradingAccount) {
    if (_.isNil(this.customerServiceInst)) {
      return;
    }

    this.customerServiceInst
      .getCashierDetails(customer.id, account.id)
      .then(([firstCashier]: any) => {
        this.depositUrl = this.processResponseIframeUrl(
          firstCashier.cashierData.iframeUrl,
        );
      });
  }

  /**
   * Process the iframe url to be used in the template.
   *
   * @param {string} url - url to process
   * @returns {object} stanitazed resource url object to be used in templates
   * @private
   */
  processResponseIframeUrl(url: string) {
    return this.$sce.trustAsResourceUrl(url);
  }
}

export default {
  template,
  controller: PraxisDepositFormComponent,
  bindings: {
    customer: '<',
    account: '=',
    onDone: '&',
  },
};
