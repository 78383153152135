export enum CustomerCommunicationSubjectCode {
  SysWithdrawalRequestEmail = 'sys_withdrawal_request_email',
  SysWithdrawalDeclinedEmail = 'sys_withdrawal_declined_email',
  SysWithdrawalApprovalEmail = 'sys_withdrawal_approval_email',
  SysResetPasswordEmail = 'sys_reset_password_email',
  SysRegistrationEmail = 'sys_registration_email',
  SysInternalTransferEmail = 'sys_internal_transfer_email',
  SysDocumentsDeclinedEmail = 'sys_documents_declined_email',
  SysDocumentsApprovalEmail = 'sys_documents_approval_email',
  SysDepositDeclinedEmail = 'sys_deposit_declined_email',
  SysDepositConfirmationEmail = 'sys_deposit_confirmation_email',
  SysBonusGivenEmail = 'sys_bonus_given_email',
  OutboundSms = 'outbound_sms',
  OutboundEmailTemplate = 'outbound_email_template',
  OutboundCustomEmail = 'outbound_custom_email',
  OutboundCall = 'outbound_call',
  Other = 'other',
  IncomingEmail = 'incoming_email',
  InboundCall = 'inbound_call',
  Compliance = 'compliance',
  FirstCall = 'first_call',
  FollowUp = 'follow_up',
  Support = 'support',
  Training = 'training',
}
