import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import Url from 'url-parse';
import type { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';
import type { IHttpService } from 'angular';
import { initCrmApi, types, CrmApi } from '@proftit/crm-api-client';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as requ from '@proftit/requ';
import type { IAppConfig } from '../conf/appConfig';
import type { UserTokenModel } from '../common/models/user-token-model';
import type { AppTag } from '../common/utilities/get-browser-info';
import { TokensService } from '../auth/services/tokens';
type RequestJson = requ.json.types.RequestJson;

export class CrmAppStore {
  userSession = useUserSession(this.lifecycles, this.tokensService);

  loggedCrmApiS = useLoggedCrmApi(
    this.lifecycles,
    this.appConfig,
    this.appTag,
    this.userSession.user$,
    this.$http,
  );

  constructor(
    readonly lifecycles: ComponentLifecyclesObservables,
    readonly tokensService: TokensService,
    readonly appConfig: IAppConfig,
    readonly appTag: AppTag,
    readonly $http: IHttpService,
  ) {}
}

function useUserSession(
  lifecycles: ComponentLifecyclesObservables,
  tokensService: TokensService,
) {
  return {
    user$: tokensService.user$,
  };
}

function useLoggedCrmApi(
  lifecycles: ComponentLifecyclesObservables,
  appConfig: IAppConfig,
  appTag: AppTag,
  user$: rx.Observable<UserTokenModel>,
  $http: IHttpService,
) {
  const url = new Url(appConfig.connections.api);

  const execute: (
    req: RequestJson,
  ) => Promise<types.NormalizedResponse<any>> = _.flow([
    (req) => requ.jsonNgHttp.jsonToNgHttp(req),
    (req) => $http<any>(req),
    (reqPromise) =>
      reqPromise.then((resp) => {
        return {
          headers: resp.headers(),
          data: resp.data,
          status: resp.status,
          statusText: resp.statusText,
        };
      }),
  ]);

  const crmApi$: rx.Observable<CrmApi> = rx.pipe(
    () => user$,
    rx.map((userSession) => {
      if (_.isNil(userSession)) {
        return null;
      }

      return initCrmApi({
        execute,
        protocol: url.protocol.slice(0, -1) as any,
        domain: url.hostname,
        port: parseInt(url.port, 10),
        token: userSession.jwt,
        murmur: appTag.murmur,
      });
    }),
    shareReplayRefOne(),
  )(null);

  useStreams([crmApi$], lifecycles.onDestroy$);

  return {
    crmApi$,
  };
}
