import { useStreams } from '@proftit/rxjs.adjunct';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './tasks-popup.html';

class TasksPopupController {
  lifecycles = observeComponentLifecycles(this);
  close: () => void;

  /* @ngInject */
  constructor(readonly prfCurrentUserStore: CurrentUserStoreService) {
    useStreams([], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams([this.streamLoggedOut()], this.lifecycles.onDestroy$);
  }

  $onDestroy() {}

  $onChanges() {}

  streamLoggedOut() {
    return rx.pipe(
      () => this.prfCurrentUserStore.isLoggedIn$,
      rx.filter((x) => !x),
      rx.tap(() => this.close()),
    )(null);
  }
}

export const TasksPopupComponent = {
  template,
  controller: TasksPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
