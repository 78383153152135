import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './bundles-table-col-renderer.component.html';

export class BundlesTableColRendererController extends BaseController {
  /*@ngInject */
  constructor() {
    super();
  }
}

export const BundlesTableColRendererComponent = {
  template,
  controller: BundlesTableColRendererController,
  bindings: {
    col: '<',
    row: '<',
    isSubTableActionOpen: '<',
    onSubTableActionClick: '&',
  },
};
