import template from './questionnaire-score-segments-form.component.html';
const styles = require('./questionnaire-score-segments-form.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  QuestionnaireScore,
  QuestionnaireScoreSegment,
} from '@proftit/crm.api.models.entities';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import {
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
} from '@proftit/ng1.reactive-forms';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import { genereateNewSegmentControl } from './utilities/generate-new-segment-control';
import { generateSegment } from '../utilities/generate-segment';
import { generateSegmentsArray } from '../utilities/generate-segments-array';
import * as _ from '@proftit/lodash';

export class QuestionnaireScoreSegmentsFormController {
  onChangeValue: (a: { value }) => void;
  onChangeIsValid: (a: { isValid }) => void;
  onChangeIsPristine: (a: { isPristine }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  opAddSegment$ = new rx.Subject<void>();
  opDeleteSegment$ = new rx.Subject<string>();
  model$ = observeShareCompChange<Partial<QuestionnaireScoreSegment[]>>(
    this.lifecycles.onChanges$,
    'model',
  );
  form$ = this.streamForm();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.model$,
        this.streamFormSegmentsToValues(),
        this.streamActionNotifyChangeValue(),
        this.streamActionNotifyChangeIsValid(),
        this.streamActionNotifyChangeIsPristine(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamFormFromModel() {
    return rx.pipe(
      () => this.model$,
      rx.map((segments) => generateSegmentsArray(segments)),
      shareReplayRefOne(),
    )(null);
  }

  streamFormFromAddSegmentAction(form$: rx.Observable<FormArray>) {
    return rx.pipe(
      () => this.opAddSegment$,
      rx.withLatestFrom(form$),
      rx.map(([op, form]) => {
        const newSegment = generateSegment();
        const lastSegment = _.last(form.value as QuestionnaireScoreSegment[]);
        if (!_.isNil(lastSegment) && _.isNumber(lastSegment.end)) {
          newSegment.start = lastSegment.end + 1;
        }
        const newControl = genereateNewSegmentControl(
          newSegment,
          form.controls.length,
        );

        form.push(newControl);
        return form;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamFormFromDeleteSegmentAction(form$: rx.Observable<FormArray>) {
    return rx.pipe(
      () => this.opDeleteSegment$,
      rx.withLatestFrom(form$),
      rx.map(([targetId, form]) =>
        form.controls.findIndex(
          (segmentCtrl) =>
            (segmentCtrl as FormGroup).controls.id.value === targetId,
        ),
      ),
      rx.withLatestFrom(form$),
      rx.map(([targetIndex, form]) => {
        form.removeAt(targetIndex);
        return form;
      }),
      rx.map((form) => {
        const lastSegment = _.last(form.controls);
        if (_.isNil(lastSegment)) {
          return form;
        }

        const endFormInput = (lastSegment as any).controls.end;
        endFormInput.model = 100;

        return form;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamForm() {
    const form$ = new rx.BehaviorSubject<FormArray>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamFormFromModel(),
          this.streamFormFromAddSegmentAction(form$),
          this.streamFormFromDeleteSegmentAction(form$),
        ),
      rx.tap((form) => form$.next(form)),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamFormSegmentsToValues() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.controls$ as rx.Observable<FormGroup[]>),
      rx.switchMap((segmentsCtrls: FormGroup[]) => {
        const startsObsList = segmentsCtrls.map(
          (segmentCtrl: FormGroup, segmentIndex) => {
            return segmentCtrl.controls.start.value$.pipe(
              rx.map((value) => ({ value, segmentIndex })),
            );
          },
        );

        return rx.obs.merge(...startsObsList);
      }),
      rx.withLatestFrom(
        this.form$.pipe(
          rx.switchMap((form) => form.controls$ as rx.Observable<FormGroup[]>),
        ),
      ),
      rx.filter(([{ value, segmentIndex }, segmentsCtrls]) => segmentIndex > 0),
      rx.filter(([{ value, segmentIndex }, segmentsCtrls]) => {
        const prevSegmentCtrl = segmentsCtrls[segmentIndex - 1];
        const prevValue = (prevSegmentCtrl.controls.end as FormControl<any>)
          .model;

        return prevValue !== value - 1;
      }),
      rx.tap(([{ value, segmentIndex }, segmentsCtrls]) => {
        const prevSegmentCtrl = segmentsCtrls[segmentIndex - 1];
        (prevSegmentCtrl.controls.end as FormControl<any>).model = value - 1;
      }),
    )(null);
  }

  streamActionNotifyChangeValue() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.value$),
      rx.tap((value) => this.onChangeValue({ value })),
    )(null);
  }

  streamActionNotifyChangeIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onChangeIsValid({ isValid })),
    )(null);
  }

  streamActionNotifyChangeIsPristine() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isPristine$),
      rx.tap((isPristine) => this.onChangeIsPristine({ isPristine })),
    )(null);
  }
}

export const QuestionnaireScoreSegmentsFormComponent = {
  template,
  controller: QuestionnaireScoreSegmentsFormController,
  bindings: {
    model: '<',
    onChangeValue: '&',
    onChangeIsValid: '&',
    onChangeIsPristine: '&',
  },
};
