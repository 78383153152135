import { RestService } from '~/source/common/services/rest';
// import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
// import { IPromise } from 'angular';

export class CustomerAssignmentLogsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'customerAssignmentLogs';
  }
}
