import * as _ from '@proftit/lodash';
import Component from './base/component';
import { Brand } from '@proftit/crm.api.models.entities';
import { PublicBrandsService } from '~/source/common/services/brands-public';

class PlatformController extends Component.controller {
  static $inject = ['publicBrandsService', ...Component.controller.$inject];

  dataServiceInst: PublicBrandsService;
  // bindings
  brand: Brand;
  selectDefault: boolean;

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];

    this.$scope.$watch('vm.brand', () => {
      this.output = []; // reset states when the brand changes

      if (!this.brand) {
        return;
      }

      this.fetchData();
    });

    this.$scope.$watch('vm.selectDefault', this.initModel.bind(this));
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst
      .getPlatformsResource(this.brand.id)
      .expand('platformType')
      .getListWithQuery();
  }

  fetchData() {
    return super.fetchData().then(() => this.initModel());
  }

  /**
   * Sets model (if has not been set) according selectDefault flag
   */
  initModel() {
    if (_.isUndefined(this.brand)) {
      // if brand is undefined, model (platform) cant be initialized
      return;
    }
    // if model is defined(platform) and it related to selected platform - no initialization needed
    if (
      !_.isEmpty(this.model) &&
      this._data.map(({ id }) => id).includes(this.model.id)
    ) {
      return;
    }

    // set default platform only if select default is checked, otherwise keep provided model
    if (this.selectDefault) {
      this.model = this.defaultPlatform;
    }
    if (this.selectFirst && !_.isEmpty(this._data)) {
      this.model = this._data[0];
    }
  }

  /**
   * Return default platform for selected brand
   *
   * @returns {Object}
   */
  get defaultPlatform() {
    /*
     * We can't know if "platform" was expended (depends on where this component was used)
     * so try both options (expanded and not expanded)
     */
    const defPlatformId = this.defaultConnection.platform
      ? this.defaultConnection.platform.id // expanded
      : this.defaultConnection.platformId; // not expanded
    return _.find({ id: defPlatformId }, this._data);
  }

  get defaultConnection() {
    return _.findEs(this.brand.platformConnections, {
      isDefault: true,
    });
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: PlatformController,
  bindings: {
    brand: '=',
    selectDefault: '=',
  },
});
