import Promise from 'bluebird';
import Component from '~/source/common/components/dropdowns/base/component';
import { User } from '@proftit/crm.api.models.entities';

class UserAssignableSelectController extends Component.controller {
  static $inject = ['usersService', ...Component.controller.$inject];

  // bindings
  brandId: number;
  deskId: number;
  forceUserDeskDependency: boolean;
  isActive: boolean;

  /**
   *  Fetch users on selected brand, desk change
   */
  init() {
    this.$scope.$watchGroup(['vm.brandId', 'vm.deskId'], () => {
      if (!this.brandId) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Get users filtered by desk and brand
   *
   * @returns {Promise}
   */
  query() {
    // if forceUserDeskDependency is true both brandId & deskId are required
    if (this.forceUserDeskDependency && (!this.brandId || !this.deskId)) {
      return Promise.resolve([]);
    }

    const filters = {
      // show active users by default, otherwise use provided bindings value
      isActive: this.isActive === undefined ? true : this.isActive,
      'role.code': {
        exclude: 'extapi',
      },
      brandId: this.brandId,
      deskId: this.deskId,
    };

    return this.dataServiceInst
      .expand('role')
      .setConfig({ blockUiRef: `dropdown_${this.dropdownId}` })
      .filter(filters)
      .getListWithQuery()
      .then((users) => {
        users.forEach((user: User & { name: string }) => {
          user.name = `${user.firstName} ${user.lastName}`;
        });
        return users;
      });
  }

  /**
   * set translate source to firstName to avoid errors
   */
  get translateSource() {
    return '';
  }

  /**
   * don't really try to translate firstNames, just give it an imaginary path
   */
  get translationPath() {
    return 'imaginary.path';
  }

  /**
   * Used if select is nullable, returns nullable entity
   * translate firstName property because this select has dynamic values and its not translated
   *
   * @returns {Object}
   */
  get nullableElement() {
    return {
      id: null,
      name: this.$translate.instant('user.SELECT_NO_USER'),
    };
  }
}

export default Component.config({
  controller: UserAssignableSelectController,
  bindings: {
    isActive: '<',
    brandId: '=',
    deskId: '=',
    /*
     * set true to make sure that users in the select are dependent on both brandId & deskId
     * in other words, disable this field unless brandId & deskId are provided
     */
    forceUserDeskDependency: '<',
  },
});
