import Component from './base/component';
import * as _ from '@proftit/lodash';

class TradingAccountStatusController extends Component.controller {
  static $inject = [
    'tradingAccountStatusesService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'common.PLEASE_SELECT';
  }

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'contact.STATUS_CODE';
  }

  setSelectedValues() {
    /**
     * Setting selected values to model (selected values are different models which come from server,
     * so we need to translate them as well)
     */
    this.selectedValues = this.model;
    // if multiple select - selected elements should be an array
    if (this.multiple === undefined && !_.isEmpty(this.selectedValues)) {
      this.selectedValues = [this.selectedValues];

      // Map each selected value to its corresponding object in the data received from server
      this.selectedValues = this.selectedValues.map((value) =>
        this._data.find((el) => el.code === value.code),
      );
      this.model.id = this.selectedValues[0].id;
    }
  }
}

export default Component.config({ controller: TradingAccountStatusController });
