import angular from 'angular';
import ContactInfoComponent from './contact-info/contact-info.component';
import ContactInfoDisplayComponent from './contact-info-display/contact-info-display.component';
import ContactInfoUpdateComponent from './contact-info-update/contact-info-update.component';

export default angular
  .module('crm.contact.sidebar.info', [])
  .component('prfContactInfo', ContactInfoComponent)
  .component('prfContactInfoDisplay', ContactInfoDisplayComponent)
  .component('prfContactInfoUpdate', ContactInfoUpdateComponent).name;
