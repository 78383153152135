import ng, { IPromise, IScope } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './deposit-selection.component.html';
import {
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import TransferMethodTypes from '~/source/contact/common/services/transfer-method-types.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
const styles = require('./deposit-selection.component.scss');

export class DepositSelectionController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  onNoValidDeposit: () => void;
  contactId: number;
  accountId: number;
  withdrawalRequest: WithdrawalRequest;
  selectedCard;

  account: TradingAccount;
  deposits: TradingAccountDeposit[];
  cardStatusMap: { [key: string]: string };
  cardStatus;
  transferMethodTypes;
  cards: string[];
  disableManualCard: boolean;
  loadedAllCards = false;
  onDepositAmountChange: ({ newAmount }) => void;

  customerServiceInst: CustomersService;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly $translate: ng.translate.ITranslateService,
    readonly transferMethodTypesService: TransferMethodTypes,
    readonly withdrawalSettings,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    this.customerServiceInst = this.customersService();
  }

  $onInit() {
    Object.assign(this, {
      // list of credit cards for drop down selection
      cards: [],
      cardStatus: this.withdrawalSettings.cardStatus,
      // map cards to status, approved or external (manual)
      cardStatusMap: {},
    });

    this.initSelectedCreditCard();

    this.getTransferMethodTypes();

    // get trading account info
    this.getAccount();

    // get account deposits
    this.getAccountDeposits()
      .then(() => {
        return Promise.all([
          Promise.resolve(this.getApprovedCards()),
          this.getManualCardOption(),
        ]);
      })
      .then((x: any) => {
        const [approvedCards, manualOption] = x;
        if (this.disableManualCard) {
          this.cards = [...approvedCards];
        } else {
          this.cards = [...approvedCards, manualOption];
        }
      })
      .then(() => {
        this.loadedAllCards = true;
        this.checkForNoValidDeposit();
      });
  }

  checkForNoValidDeposit() {
    if (
      _.isNil(this.cardStatusMap[this.selectedCard]) &&
      this.withdrawalRequest.deposit
    ) {
      if (!_.isNil(this.onNoValidDeposit)) {
        this.onNoValidDeposit();
      }
    }
  }

  $onDestroy() {}

  $onChanges() {}

  initSelectedCreditCard() {
    if (this.withdrawalRequest.deposit) {
      this.selectedCard = this.withdrawalRequest.deposit.transactionTransferCreditCard.cardNumber;
    }
  }

  /**
   * get transaction method types for submitting with the right methodTypeId
   * @returns {Promise} returns a promise which resolved on success
   */
  getTransferMethodTypes() {
    // get types of credit cards from server
    return this.transferMethodTypesService
      .getListWithQuery()
      .then((transferMethodTypes) => {
        // index object by 'code' property
        this.transferMethodTypes = _.keyBy('code', transferMethodTypes);
      });
  }

  /**
   * get customer trading account info, including balance, withdrawable amount etc...
   * @returns {Promise} returns a promise which resolved on success
   */
  getAccount() {
    return this.customerServiceInst
      .getAccountResource(this.contactId, this.accountId)
      .expand(['currency'])
      .getOneWithQuery<IElementRestNg<TradingAccount>>()
      .then((account) => {
        this.account = account;
      });
  }

  /**
   * get deposits belongs to current trading account
   * @returns {Promise} returns a promise which resolved on success
   */
  getAccountDeposits() {
    return this.customerServiceInst
      .getDepositsResource(this.contactId, this.accountId)
      .filter({
        transactionStatusCode: TradingAccountTransactionStatusCode.Approved,
        isChargeBack: 0,
      })
      .gte('amountWithdrawable', 1) // get only deposits with withdrawable amount left
      .expand([
        'currency',
        'transactionTransferCreditCard',
        'transactionTransferCreditCard.clearingCompany',
      ])
      .getListWithQuery()
      .then((deposits) => {
        this.deposits = deposits;
      });
  }

  /**
   * add "add card manually" item to credit card select. it will be use to show external card form.
   *
   * @returns {Promise} returns a promise which resolved on success
   */
  getManualCardOption(): Promise<string> {
    return ((this.$translate('user.ADD_CARD_MANUALLY') as any) as Promise<
      string
    >).then((addCardManuallyTranslation) => {
      this.cardStatusMap[addCardManuallyTranslation] = this.cardStatus.EXTERNAL;
      return addCardManuallyTranslation;
    });
  }

  /**
   * get approved credit cards that are linked to deposits
   * @returns {Array.<string>} returns a promise which resolves to array containing approved card numbers
   */
  getApprovedCards(): string[] {
    // use Set object to prevent credit card duplications
    const cards = new Set<string>();

    _.each((deposit) => {
      const cardNumber = _.get(
        'transactionTransferCreditCard.cardNumber',
        deposit,
      );
      if (cardNumber) {
        this.cardStatusMap[cardNumber] = this.cardStatus.APPROVED;
        cards.add(cardNumber);
      }
    }, this.deposits);

    return [...Array.from(cards)];
  }

  processDepositAmountChange(newAmount) {
    this.onDepositAmountChange({ newAmount });
  }
}

export const DepositSelectionComponent = {
  template,
  controller: DepositSelectionController,
  bindings: {
    onNoValidDeposit: '&',
    contactId: '<',
    accountId: '<',
    withdrawalRequest: '<',
    selectOnlyOne: '<',
    disableManualCard: '<',
    alwaysShowDepositExtraInfo: '<',
    onDepositAmountChange: '&',
  },
};
