import TablePopupController from '~/source/common/components/table/table-popup';
import bonusSettings from '../bonus-settings.json';
import {
  TradingAccount,
  Customer,
  TransactionTransferBonus,
} from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import template from './bonus-table-popup.html';
import * as _ from '@proftit/lodash';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { BONUS_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import * as rx from '@proftit/rxjs';
import { useStream } from '~/source/common/utilities/use-stream';
import { generateBonusAdditionals } from '../utilities/generate-bonus-additionals/generate-bonus-additionals';
import { BonusAdditional } from '../utilities/bonus-additional.class';

class BonusTablePopupComponent extends TablePopupController {
  static $inject = [
    'customersService',
    'prfClientGeneralPubsub',
    ...TablePopupController.$inject,
  ];

  bonusAdditional: { [key: number]: BonusAdditional } = {};
  account: TradingAccount;
  customer: Customer;
  bonuses: IElementRestNg<any>;
  dataServiceInstance: CustomersService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  unsub$ = new rx.Subject<void>();

  $onInit() {
    super.$onInit();
    useStream(this.streamBonusUpdateStatus(), this.unsub$);
  }

  streamBonusUpdateStatus() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === BONUS_STATUS_UPDATE),
      rx.tap(() => {
        this.reloadTable();
      }),
    )(null);
  }

  get cols() {
    return [...bonusSettings.tableColumns].filter((column) =>
      ['common', this.account.type].includes(column.type),
    );
  }

  get ngTableSettings() {
    return { ...bonusSettings.tablePopup.ngTable };
  }

  get title() {
    return 'BONUS';
  }

  get tableKey() {
    return 'bonus';
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      this.account = null;
      return;
    }
    this.customer = resolve.customer;
    this.account = resolve.account;
  }

  parseLoadedData(data) {
    if (!this.bonuses) {
      this.bonuses = data;
    } else {
      this.bonuses = _.unionWith(
        (a: any, b: any) => a.id === b.id,
        data,
        this.bonuses,
      );
    }
    this.bonusAdditional = generateBonusAdditionals(this.bonuses);
    return data;
  }

  /**
   * perform action from dropdown
   * @param  actionCode - cancel / confirm bonus
   * @param  bonus - bonus
   */
  onDropDownAction(actionCode: string, bonus: TransactionTransferBonus) {
    const actionsDispatch = {
      cancel: () => (this.bonusAdditional[bonus.id].showActionWarning = true),
      startConfirm: () => this.onApproveBonus(bonus),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  /**
   * Called on bonus cancelation by user
   *
   * @param {object} bonus
   */
  onCancelBonus(bonus) {
    this.dataServiceInstance
      .cancelBonus(this.customer.id, this.account.id, bonus.id)
      .then(() => this.reloadTable());
  }

  /**
   * Called on bonus approvel by user
   *
   * @param {object} bonus
   */
  onApproveBonus(bonus: TransactionTransferBonus) {
    this.dataServiceInstance
      .approveBonus(this.customer.id, this.account.id, bonus.id)
      .then(() => {
        this.reloadTable();
      });
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getBonusesResource(this.customer.id, this.account.id)
      .expand(['currency', 'user', 'canceler', 'promoCode']);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }
}

export default {
  template,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  controller: BonusTablePopupComponent,
  controllerAs: 'vm',
};
