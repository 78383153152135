import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

import template from './checkbox-group.component.html';
import { CheckboxGroupItem } from './checkbox-group-item';
import { generateInputModels } from './generate-input-models';

const styles = require('./checkbox-group.component.scss');

export class CheckboxGroupController {
  styles = styles;

  onModelChange: (a: { selectedItems: CheckboxGroupItem<Object>[] }) => void;

  lifecycles = observeComponentLifecycles(this);

  inputModels: Map<Object, { value: boolean }>;

  /* @ngInject */
  constructor() {
    const inputModels = generateInputModels(this.lifecycles, (params) =>
      this.onModelChange(params),
    );

    this.inputModels = inputModels;
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const CheckboxGroupComponent = {
  template,
  controller: CheckboxGroupController,
  bindings: {
    items: '<',
    model: '<',
    onModelChange: '&',
  },
};
