import * as _ from '@proftit/lodash';

const IS_NOT_NIL_VALIDATION = 'IS_NOT_NIL_VALIDATION';
function isNotNilFormValidator(control) {
  return new Promise(resolve => {
    if (!_.isNil(control.value)) {
      resolve(null);
    }

    return resolve({
      [IS_NOT_NIL_VALIDATION]: {
        value: control.value
      }
    });
  });
}
isNotNilFormValidator.validationName = IS_NOT_NIL_VALIDATION;

let Validators;

(function (Validators) {
  Validators["IsNotEmptyStringValidation"] = "IS_NOT_EMPTY_STRING_VALIDATION";
  Validators["IsNotNilValidation"] = "IS_NOT_NIL_VALIDATION";
  Validators["IsStringNotEmptyOrOtherNotNilValidation"] = "IS_STRING_NOT_EMPTY_OR_OTHER_NOT_NIL_VALIDATION";
  Validators["OptionalMaxNumber"] = "OPTIONAL_MAX_NUMBER";
  Validators["OptionalNumberMustBeNonNegative"] = "OPTIONAL_NUMBER_MUST_BE_NON_NEGATIVE";
  Validators["RequireValidation"] = "REQUIRE_VALIDATION";
  Validators["isEmailFormValidator"] = "IS_EMAIL_VALIDATION";
  Validators["IsNotEmptyArray"] = "IS_NOT_EMPTY_ARRAY";
})(Validators || (Validators = {}));

function isNotEmptyArrayValidator(control) {
  return new Promise(resolve => {
    if (!_.isArray(control.value)) {
      resolve(null);
    }

    if (!_.isEmpty(control.value)) {
      resolve(null);
    }

    return resolve({
      [Validators.IsNotEmptyArray]: {
        value: control.value
      }
    });
  });
}
isNotEmptyArrayValidator.validationName = Validators.IsNotEmptyArray;

const IS_NOT_EMPTY_STRING_VALIDATION = 'IS_NOT_EMPTY_STRING_VALIDATION';
function isNotEmptyStringFormValidator(control) {
  return new Promise(resolve => {
    if (_.isString(control.value) && !_.isEmpty(control.value)) {
      resolve(null);
    }

    return resolve({
      [IS_NOT_EMPTY_STRING_VALIDATION]: {
        value: control.value
      }
    });
  });
}
isNotEmptyStringFormValidator.validatorName = IS_NOT_EMPTY_STRING_VALIDATION;

const IS_STRING_NOT_EMPTY_OR_OTHER_NOT_NIL_VALIDATION = 'IS_STRING_NOT_EMPTY_OR_OTHER_NOT_NIL_VALIDATION';
function isStringNotEmptyOrOtherNotNilFormValidator(control) {
  return new Promise(resolve => {
    if (_.isString(control.value)) {
      if (_.isEmpty(control.value)) {
        return resolve({
          [IS_STRING_NOT_EMPTY_OR_OTHER_NOT_NIL_VALIDATION]: {
            value: control.value
          }
        });
      }

      return resolve(null);
    }

    if (_.isNil(control.value)) {
      return resolve({
        [IS_STRING_NOT_EMPTY_OR_OTHER_NOT_NIL_VALIDATION]: {
          value: control.value
        }
      });
    }

    return resolve(null);
  });
}
isStringNotEmptyOrOtherNotNilFormValidator.validationName = IS_STRING_NOT_EMPTY_OR_OTHER_NOT_NIL_VALIDATION;

function validator(control, max) {
  return new Promise(resolve => {
    if (!_.isNumber(control.value)) {
      return resolve(null);
    }

    if (control.value > max) {
      return resolve({
        [Validators.OptionalMaxNumber]: {
          value: control.value,
          maxValue: max
        }
      });
    }

    return resolve(null);
  });
}

function optionalMaxNumberFormValidatorFactory(max) {
  return control => validator(control, max);
}
optionalMaxNumberFormValidatorFactory.validationName = Validators.OptionalMaxNumber;

function optionalNumberMustBeNonNegativeFormValidator(control) {
  return new Promise(resolve => {
    if (!_.isNumber(control.value)) {
      return resolve(null);
    }

    if (control.value < 0) {
      return resolve({
        [Validators.OptionalNumberMustBeNonNegative]: {
          value: control.value
        }
      });
    }

    return resolve(null);
  });
}
optionalNumberMustBeNonNegativeFormValidator.validationName = Validators.OptionalNumberMustBeNonNegative;

const REQUIRE_VALIDATION = 'REQUIRE_VALIDATION';
function requireFormValidator(control) {
  return new Promise(resolve => {
    if (!_.isNil(control.value)) {
      resolve(null);
    }

    if (_.isString(control.value) && !_.isEmpty(control.value)) {
      resolve(null);
    }

    return resolve({
      [REQUIRE_VALIDATION]: {
        value: control.value
      }
    });
  });
}
requireFormValidator.validationName = REQUIRE_VALIDATION;

/// <reference lib="dom" />
/// <reference lib="es2016" />
const IS_EMAIL_VALIDATION = 'IS_EMAIL_VALIDATION';
/* eslint-disable-next-line no-useless-escape, @typescript-eslint/no-inferrable-types */

const EMAIL_REGEX = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,60}|[0-9]{1,3})(\]?)$/;
function isEmailFormValidator(control) {
  return new Promise(resolve => {
    if (EMAIL_REGEX.test(control.value)) {
      resolve(null);
    }

    return resolve({
      [IS_EMAIL_VALIDATION]: {
        value: control.value
      }
    });
  });
}
isEmailFormValidator.validationName = IS_EMAIL_VALIDATION;

export { isEmailFormValidator, isNotEmptyArrayValidator, isNotEmptyStringFormValidator, isNotNilFormValidator, isStringNotEmptyOrOtherNotNilFormValidator, optionalMaxNumberFormValidatorFactory, optionalNumberMustBeNonNegativeFormValidator, requireFormValidator };
