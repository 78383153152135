import { SocketService } from '~/source/common/services/socket';
import { hashCode } from '../utilities/hash-code';

export class BundleStatsSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  streamerUrl = '';
  token: string;

  /**
   * Specify socket registry key.
   *
   * there are registry for crm socket, platform socket..
   * Sockets should be shared for all services targeting the same target.
   */
  get socketRegistryKey() {
    const tokenHash = hashCode(this.token);
    const urlHash = hashCode(this.streamerUrl);
    return `bundlePlatformSocket-token=${tokenHash}&url=${urlHash}`;
  }

  getStreamerUrl() {
    return this.streamerUrl;
  }

  get channelRoot() {
    return 'brandpnl';
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }

  /**
   * Set streamer info
   *
   * @param host - host
   * @param port - port
   */
  setStreamerUrl(url: string) {
    this.streamerUrl = url;
  }

  setToken(token: string) {
    this.token = token;
  }

  /**
   * Build channel name for brand stats subscription.
   *
   * @param brand - brand name.
   * @return channel name.
   */
  buildBundleStatsChannel(id: number) {
    return `{brand.${id}}.metrics_by_currencies`;
  }
}
