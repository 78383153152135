import template from './account-status-dashboard.component.html';

const styles = require('./account-status-dashboard.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { Brand } from '@proftit/crm.api.models.entities';
import { AccountStatusAutomation } from '~/source/common/models/account-status-automation';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams } from '@proftit/rxjs.adjunct';

export class AccountStatusDashboardController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  selectedBrand = new FormControl<Brand>(null);

  /*@ngInject */
  constructor(readonly popupService: PopupService) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  openLogsPopupTable(
    brand: Brand,
    accountStatusAutomation: AccountStatusAutomation,
  ) {
    this.popupService.open({
      component: 'prfAccountStatusAutomationLogsPopupTable',
      resolve: {
        brand,
        accountStatusAutomation,
      },
    });
  }
}

export const AccountStatusDashboardComponent = {
  template,
  controller: AccountStatusDashboardController,
};
