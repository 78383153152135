import template from './ui-router-tabs-style.component.html';

const styles = require('./ui-router-tabs-style.component.scss');

import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class UiRouterTabsStyleController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const UiRouterTabsStyleComponent = {
  template,
  controller: UiRouterTabsStyleController,
  bindings: {
    list: '<',
  },
};
