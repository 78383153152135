import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  ComponentLifecyclesObservables,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { CheckboxGroupItem } from '~/source/tasks/checkbox-group/checkbox-group-item';

function regenerateInterfaceFromSelectedItems(
  itemsInterface: Map<Object, { value: boolean }>,
  items: CheckboxGroupItem<Object>[],
  selectedItems: Object[],
  onModelChange: (a: { selectedItems: any[] }) => void,
): void {
  itemsInterface.clear();

  items.forEach((item) => {
    const selectedItem = Object.defineProperty<{ value: boolean }>(
      {} as any,
      'value',
      {
        get: () => {
          const foundModel = selectedItems.find((x) => x === item.value);
          return !!foundModel;
        },
        set: (isChecked) => {
          const newSelectedItems = isChecked
            ? [...selectedItems, item.value]
            : selectedItems.filter((x) => x !== item.value);
          onModelChange({ selectedItems: newSelectedItems });
        },
      },
    );
    itemsInterface.set(item, selectedItem);
  });
}

export function generateInputModels(
  lifecycles: ComponentLifecyclesObservables,
  onModelChange: (a: { selectedItems: any[] }) => void,
): Map<Object, { value: boolean }> {
  const itemsInterface = new Map<Object, { value: boolean }>();

  const items$ = observeShareCompChange<CheckboxGroupItem<Object>[]>(
    lifecycles.onChanges$,
    'items',
  );

  const model$ = observeShareCompChange<Object[]>(
    lifecycles.onChanges$,
    'model',
  );

  const stream = rx.pipe(
    () =>
      rx.obs.combineLatest(
        items$.pipe(rx.map((items) => (_.isNil(items) ? [] : items))),
        model$,
      ),
    rx.tap(([items, selectedItems]) => {
      regenerateInterfaceFromSelectedItems(
        itemsInterface,
        items,
        selectedItems,
        onModelChange,
      );
    }),
  )(null);

  stream.pipe(rx.takeUntil(lifecycles.onDestroy$)).subscribe();

  return itemsInterface;
}
