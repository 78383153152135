import * as _ from '@proftit/lodash';
import Component from '~/source/common/components/dropdowns/base/component';
import { PlatfromAssetsService } from '../../services/platform-assets.service';
import { IHttpService, IPromise } from 'angular';
import * as rx from '@proftit/rxjs';
import {
  getAsList,
  jsonServerToCfdMongo,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CurrentTrcAssetsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-assets-of-platform-service.directive';

export class CfdPlatformAssetNameSelectController extends Component.controller {
  static $inject = [
    ...Component.controller.$inject,
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentTrcAssetsOfPlatform: CurrentTrcAssetsOfPlatformServiceDirectiveController;

  /* injected */

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfPlatformAssetsService: PlatfromAssetsService;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];

    useStreams([this.streamData()], this.lifecycles.onDestroy$);
  }

  streamData() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.tap((session) => {
        this._data = [];

        if (!session.isLoggedIn) {
          return;
        }

        this.fetchData();
      }),
    )(null);
  }

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return rx
      .pipe(
        () => rx.obs.of(true),
        rx.tap(() => this.startBlockUi()),
        rx.switchMap(
          () => this.prfCurrentTrcAssetsOfPlatform.generalAssetsS.stream$,
        ),
        rx.finalize(() => {
          this.stopBlockUi();
        }),
        rx.take(1),
      )(null)
      .toPromise() as any;
  }

  startBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.getBlockUiRef());

    if (_.isNil(blockUiInstance)) {
      return null;
    }

    blockUiInstance.start();
    return null;
  }

  stopBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.getBlockUiRef());
    if (_.isNil(blockUiInstance)) {
      return;
    }

    blockUiInstance.stop();
    return null;
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Assets',
    };
  }

  get defaultPlaceholder() {
    return 'SELECT_ASSET';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const CfdPlatformAssetNameSelectComponent = Component.config({
  controller: CfdPlatformAssetNameSelectController,
  require: {
    prfCurrentPlatformSession: '^',
    prfCurrentTrcAssetsOfPlatform: '^',
  },
});

export default CfdPlatformAssetNameSelectComponent;
