import _ from 'underscore';
import moment from 'moment';

function localStorageDecorator($provide) {
  let oGet;
  let oSet;

  $provide.decorator('localStorageService', ($delegate) => {
    oGet = $delegate.get;
    oSet = $delegate.set;
    /**
     * Get data that is related to a given key
     *
     * @param {String} key
     * @returns {Mixed/null} - return null if data not found or not valid
     */
    $delegate.get = function (key) {
      const cachedData = oGet(key);
      // no data found in cache
      if (_.isNull(cachedData)) {
        return null;
      }
      // check if the data is valid
      if (this._isValid(cachedData) === false) {
        // remove not valid data
        this.remove(key);
        return null;
      }
      // now we can return the value
      return cachedData.value;
    };
    /**
     *
     *
     * @param {Object} cachedData
     * @returns {boolean}
     * @private
     * @returns {Boolean}
     */
    $delegate._isValid = function (cachedData) {
      let now;

      if (_.isNull(cachedData.expires)) {
        // expired property not set, that means that data is valid
        return true;
      }
      // get current unix time
      now = moment().unix();

      if (cachedData.expires < now) {
        // expires time is passed - this means that data not valid
        return false;
      }
      // finally - every thing is ok
      return true;
    };
    /**
     * Set value to local storage, if ttl is passed , expires property would set
     * also
     *
     * @param {String} key
     * @param {Mixed} value
     * @param {Int} ttl - time to live in seconds
     * @returns {*}
     */
    $delegate.set = function (key, value, ttl) {
      let expires = null;
      let cachedData = {};

      if (_.isNumber(ttl)) {
        expires = moment().unix() + ttl;
      }

      cachedData = {
        expires,
        value,
      };

      return oSet.call(this, key, cachedData);
    };

    return $delegate;
  });
}

export default localStorageDecorator;

localStorageDecorator.$inject = ['$provide'];
