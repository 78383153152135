import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';
import { useStream } from '~/source/common/utilities/use-stream';

import { BrandsService } from '~/source/management/brand/services/brands';
import { ItemLabelStrategy } from '~/source/common/components/dropdowns/base/item-label-strategy';
import { Brand } from '@proftit/crm.api.models.entities';
import { CfdPlatforms } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

class Controller extends DropdownController {
  static $inject = ['brandsService', ...DropdownController.$inject];

  dataServiceInst: BrandsService;

  unsub$ = new rx.Subject<void>();
  brand$ = new rx.BehaviorSubject<Brand>(null);
  onlyCfd: boolean;

  constructor(a, ...args) {
    super(a, ...args);

    useStream(this.streamBrandChange(), this.unsub$);
  }

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];
  }

  onBrandChange(brand: Brand) {
    this.brand$.next(brand);
  }

  streamBrandChange() {
    return rx.pipe(
      () => this.brand$,
      rx.tap(() => {
        this.output = [];
      }),
      rx.filter((brand) => !_.isNil(brand)),
      rx.tap(() => this.fetchData()),
    )(null);
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst
      .getPlatformsResource(this.brand$.getValue().id)
      .expand(['platform'])
      .getListWithQuery()
      .then((data) => data.plain())
      .then((data) => {
        if (this.onlyCfd) {
          return data.filter((platformConn) =>
            CfdPlatforms.includes(platformConn.platform.code),
          );
        }
        return data;
      });
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.platform.name;
  }
}

export const BrandPlatformConnectionSelectComponent = DropdownConfig({
  controller: Controller,
  bindings: {
    brand: '<',
    onlyCfd: '<',
  },
});
