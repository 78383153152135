import CreditCommonController from '../credits-base.controller';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

import template from './credit-create.html';
import { IScope } from 'angular';
import { CustomersService } from '~/source/contact/common/services/customers';

const DEPOSIT_SAFEGUARD_TIME = 300;

class Controller extends CreditCommonController {
  // bindings
  customer: Customer;
  account: TradingAccount;
  onDone: () => void;

  lifecycles = observeComponentLifecycles(this);

  amountToAdd: number;
  note = '';
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  makeDepositAction = new rx.Subject<void>();

  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
  ) {
    super($scope, customersService);

    useStreams([this.streamMakeDeposit()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    Object.assign(this, {
      amountToAdd: undefined,
    });
  }

  $onDestroy() {}

  /**
   * Called before posting funds bonus
   */
  streamMakeDeposit() {
    return rx.pipe(
      () => this.makeDepositAction,
      rx.debounceTime(DEPOSIT_SAFEGUARD_TIME),
      rx.switchMap(() =>
        rx.obs
          .from(
            this.postCredit(this.customer.id, this.account.id, {
              amount: this.amountToAdd || 0,
              note: this.note,
            }),
          )
          .pipe(
            rx.catchError(() => {
              return rx.obs.NEVER;
            }),
          ),
      ),
      rx.tap(() => this.onDone()),
    )(null);
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
  },
};
