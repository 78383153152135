import { IScope } from 'angular';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import CurrenciesService from '~/source/common/services/currencies';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './currency-data.html';

class ComponentController extends BaseController {
  brand: Brand;
  selectedCurrencies: any;

  brandsInstance: BrandsService;
  isEdit: boolean;
  prevAttributes: any;
  selectedCurrenciesInstance: any;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly brandsService: () => BrandsService,
    readonly $stateParams: any,
    readonly modelNormalizer: ModelNormalizerService,
    readonly currenciesService: CurrenciesService,
  ) {
    super();

    this.brandsInstance = this.brandsService();
    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = [...this.selectedCurrencies];

    // Use instance for edits until save
    this.selectedCurrenciesInstance = [...this.selectedCurrencies];

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    this.selectedCurrencies = this.prevAttributes;

    // Exit edit mode
    this.isEdit = false;
  }

  /**
   * Check if default language exists. if none, assign first language
   *
   * default language is set in minimum deposit form. but if a user is adding new languages or removing
   * language which is a default language. we'll automatically assign the first language as default
   */
  setDefaultCurrencyIfNeeded() {
    const defaultExists = this.selectedCurrenciesInstance.filter(
      (currency) => currency.isDefault,
    );

    if (defaultExists.length === 0) {
      if (this.selectedCurrenciesInstance.length > 0) {
        this.selectedCurrenciesInstance[0].isDefault = true;
      }
    }
  }

  save() {
    // set default currency if none selected
    this.setDefaultCurrencyIfNeeded();

    this.brandsInstance
      .setConfig({ growlRef: 'brandCurrencyForm' })
      .updateCurrencies(this.brand.id, this.selectedCurrenciesInstance)
      .then(() => {
        this.isEdit = false;
        this.selectedCurrencies = this.selectedCurrenciesInstance;
      });
  }
}

const CurrencyDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    selectedCurrencies: '=',
  },
};

export default CurrencyDataComponent;
