import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  combineInProcesses,
  generateInProcessSubject,
  tapInProcessOn,
  tapFinalizeInProcessOff,
  shareReplayRefOne,
} from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { PlatformCode, MT4_PLATFORMS } from '@proftit/crm.api.models.enums';

import { directivesPriorities } from '../constants/directives-priorities';

export class CurrentPlatformOfBrandStoreServiceDirectiveController {
  setBrandPlatformOp$ = new rx.Subject<BrandPlatform>();

  brandPlatformS = this.streamBrandPlatform();

  credentialsAsHashS = this.streamCredentialsAsHash();

  isCfdPlatformS = this.streamIsCfdPlatform();

  isCfdLikePlatformS = this.streamIsCfdLikePlatform();

  isMt4PlatformS = this.streamIsMt4Platform();

  /* @ngInject */
  constructor() {}

  streamBrandPlatform() {
    const inProcess$ = generateInProcessSubject();
    const brandPlatform$ = new rx.BehaviorSubject<BrandPlatform>(null);

    const streamFn = () =>
      rx.pipe(
        () => this.setBrandPlatformOp$,
        tapInProcessOn(inProcess$),
        rx.tap((bp) => brandPlatform$.next(bp)),
        tapFinalizeInProcessOff(inProcess$),
        shareReplayRefOne(),
      )(null);

    return {
      inProcess$,
      stream$: streamFn(),
    };
  }

  streamCredentialsAsHash() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([
      inProcessSelf$,
      this.brandPlatformS.inProcess$,
    ]);

    const streamFn = () =>
      rx.pipe(
        () => this.brandPlatformS.stream$,
        tapInProcessOn(inProcessSelf$),
        rx.map((bp) => {
          if (_.isNil(bp)) {
            return {};
          }

          return bp.credentials.reduce((acc, cred) => {
            return {
              ...acc,
              [cred.key]: cred.value,
            };
          }, {});
        }),
        rx.startWith({}),
        tapFinalizeInProcessOff(inProcessSelf$),
        shareReplayRefOne(),
      )(null);

    return {
      inProcess$,
      stream$: streamFn(),
    };
  }

  streamIsCfdPlatform() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([inProcessSelf$]);

    const streamFn = () =>
      rx.pipe(
        () => this.brandPlatformS.stream$,
        rx.map((bp) => {
          if (_.isNil(bp)) {
            return false;
          }
          return bp.platform.code === PlatformCode.Cfd;
        }),
        shareReplayRefOne(),
      )(null);

    return {
      inProcess$,
      stream$: streamFn(),
    };
  }

  streamIsCfdLikePlatform() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([inProcessSelf$]);

    const streamFn = () =>
      rx.pipe(
        () => this.brandPlatformS.stream$,
        rx.map((bp) => {
          if (_.isNil(bp)) {
            return false;
          }
          return [PlatformCode.Cfd, PlatformCode.Bundle].includes(
            bp.platform.code,
          );
        }),
        shareReplayRefOne(),
      )(null);

    return {
      inProcess$,
      stream$: streamFn(),
    };
  }

  streamIsMt4Platform() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([inProcessSelf$]);

    const streamFn = () =>
      rx.pipe(
        () => this.brandPlatformS.stream$,
        rx.map((bp) => {
          if (_.isNil(bp)) {
            return false;
          }
          return MT4_PLATFORMS.includes(bp.platform.code);
        }),
        shareReplayRefOne(),
      )(null);

    return {
      inProcess$,
      stream$: streamFn(),
    };
  }

  setBrandPlatform(brandPlatform: BrandPlatform) {
    this.setBrandPlatformOp$.next(brandPlatform);
  }
}

export const currentPlatformOfBrandStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {},
    bindToController: true,
    controller: CurrentPlatformOfBrandStoreServiceDirectiveController,
  };
};

currentPlatformOfBrandStoreServiceDirective.$inject = [] as string[];
