import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';
import { EwalletsService } from '../../services/ewallets.service';

class EbrandSelectController extends DropdownController {
  static $inject = ['ewalletsService', ...DropdownController.$inject];

  dataServiceInst: EwalletsService;

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return this.dataServiceInst.embed(['fields']).getListWithQuery();
  }

  get defaultPlaceholder() {
    return 'SELECT_WALLET';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const EwalletSelectComponent = DropdownConfig({
  controller: EbrandSelectController,
});

export default EwalletSelectComponent;
