import angular from 'angular';

import datePickerSettings from './date-picker.json';
import DateRangePickerComponent from './date-picker-bar.component';
import DateFilterComponent from './date-picker.component';

export const DatePickerModule = angular.module('crm.common.datePicker', []);

DatePickerModule.constant('datePickerSettings', datePickerSettings)
  .component('prfDatePickerBar', DateRangePickerComponent)
  .component('prfDatePicker', DateFilterComponent);
