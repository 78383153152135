import _ from 'underscore';
import RestService from '~/source/common/services/rest';

class Service extends RestService {
  /**
   * Initiates accounts start synchronization from gold-i
   *
   * @param {Mixed array/number} accounts -account to sync
   * @param {int} customer id
   */
  sync(accountsParam, customerId) {
    // convert to array
    const accounts = _.isArray(accountsParam) ? accountsParam : [accountsParam];

    this.resourceBuildStart()
      .getElement(customerId)
      .getNestedCollection('accountSynchronizations')
      .resourceBuildEnd()
      .postWithQuery({
        tradingAccountIds: accounts,
      });
  }

  get resource() {
    return 'customers';
  }
}

export default Service;
