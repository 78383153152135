import * as _ from '@proftit/lodash';
import { Brand } from '@proftit/crm.api.models.entities';

/**
 * Filter factory. Return asTextList Filter.
 *
 * @return {Function} asTextList filter
 */
export function filterOnlyBrandsWithAudoUpdateCampaignsFilterFactory() {
  /**
   * Convert number to fixed string representation.
   *
   * @param input - string to truncate.
   * @param limit - length to show.
   * @reutrn {string} string representation of the source.
   */
  function filterOnlyBrandsWithAudoUpdateCampaigns(input: any[]): Object[] {
    if (_.isNil(input)) {
      return input;
    }

    if (!_.isArray(input)) {
      return input;
    }

    return input.filter((brand) => brand.shouldAutoUpdateCampaigns);
  }

  return filterOnlyBrandsWithAudoUpdateCampaigns;
}

filterOnlyBrandsWithAudoUpdateCampaignsFilterFactory.$inject = [];
