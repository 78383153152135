import * as _ from '@proftit/lodash';

import * as rx from '@proftit/rxjs';

export interface NgModelChange {
  fieldName: string;
  nextValue: any;
}

export function createNgModelObsMediator(
  props: string[],
  objSubjectIn$: rx.BehaviorSubject<any>,
  objSubjectOut$: rx.Subject<NgModelChange>,
) {
  return props.reduce((mediator, propName) => {
    /*
     * https://github.com/hueitan/angular-validation/issues/103
     * angular-validation does not work with ngModel getterSetters.
     * Therefore we need to use a different get/set approche.
     */

    /*
    return {
      ...mediator,
      [propName]: (val?: any) => {
        const currObj = _.defaultTo({}, objSubjectIn$.getValue());
        if (_.isUndefined(val)) {
          return currObj[propName];
        }

        const resp = {
          fieldName: propName,
          nextValue: val,
        };

        objSubjectOut$.next(resp);
      },
    };
    */

    return Object.defineProperty(mediator, propName, {
      get() {
        const currObj = _.defaultTo({}, objSubjectIn$.getValue());
        return currObj[propName];
      },

      set(val) {
        const resp = {
          fieldName: propName,
          nextValue: val,
        };

        objSubjectOut$.next(resp);
      },
    });
  }, {});
}
