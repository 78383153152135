import * as rx from '@proftit/rxjs';

const styles = require('./assign-splitter.component.scss');

import BaseController from '~/source/common/controllers/base';
import template from './assign-splitter.html';
import { User, Desk } from '@proftit/crm.api.models.entities';
import { IScope } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { filterCompChange } from '~/source/common/utilities/rxjs/operators/filter-comp-change';
import * as _ from '@proftit/lodash';
import { DeskedGroupedUsers } from '../models/desk-grouped-users';
import { SplitInfoUser } from '../models/split-info-user';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';

const UNLOCK_USERS_EVENT = 'users.unlock';

function isChangeInGroupedUsersOnlyUsers(groupedUsers1, groupedUsers2) {
  if (_.keys(groupedUsers1).length !== _.keys(groupedUsers2).length) {
    return false;
  }

  let isEqual = true;

  _.keys(groupedUsers1).forEach((deskId) => {
    const deskUsers1 = groupedUsers1[deskId];
    const deskUsers2 = groupedUsers2[deskId];

    if (deskUsers1.length !== deskUsers2.length) {
      isEqual = false;
    }

    if (
      deskUsers1.some(
        (user1) => deskUsers2.find((user2) => user2.id === user1.id) === null,
      )
    ) {
      isEqual = false;
    }
  });

  return isEqual;
}

export class AssignSplitterController extends BaseController {
  lifecycles = observeComponentLifecycles(this);
  styles = styles;

  // bindings
  customersToSplit: number;
  desks: Desk[];
  groupedUsers: DeskedGroupedUsers;
  onChangeOfSplits: (x: { groupedUsers: DeskedGroupedUsers }) => void;

  users: SplitInfoUser[];

  groupedUsersLocal$ = new rx.BehaviorSubject<DeskedGroupedUsers>({});
  presentGroupedUsers$ = new rx.BehaviorSubject<any[]>([]);
  desks$ = new rx.BehaviorSubject<Desk[]>([]);

  /*@ngInject*/
  constructor(readonly $scope: IScope) {
    super();

    useStreams(
      [
        this.streamCalcGroupedUsersLocalCopy(),
        this.streamCalcUsers(),
        this.streamCalcPresentationGroupedUsers(),
        this.streamCustomersToSplitChange(),
        observeCompChange<Desk[]>(
          this.desks$,
          'desks',
          this.lifecycles.onChanges$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  streamCalcGroupedUsersLocalCopy() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      filterCompChange<DeskedGroupedUsers>('groupedUsers'),
      rx.map(({ currentValue }) => currentValue),
      rx.map((groupedUsers) =>
        _.mapValues(
          (users) => users.map((user) => ({ ...user })),
          groupedUsers,
        ),
      ),
      rx.tap((groupedUsers) => this.groupedUsersLocal$.next(groupedUsers)),
      rx.distinctUntilChanged((groupedUsers1, groupedUsers2) => {
        return isChangeInGroupedUsersOnlyUsers(groupedUsers1, groupedUsers2);
      }),
      rx.tap(() => this.split()),
    )(null);
  }

  streamCalcPresentationGroupedUsers() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.pipe(
            () => this.groupedUsersLocal$,
            rx.map((groupedUsers) => _.toPairs(groupedUsers)),
            rx.map((groupedUsersList) =>
              groupedUsersList.map(([deskId, users]) => ({ deskId, users })),
            ),
          )(null),
          this.desks$,
        ),
      rx.map(([groupedUsersList, desks]) =>
        groupedUsersList.map((g) => ({
          users: g.users,
          desk: desks.find((desk) => desk.id === parseInt(g.deskId, 10)),
        })),
      ),
      rx.tap((groupedUsersList) =>
        this.presentGroupedUsers$.next(groupedUsersList),
      ),
    )(null);
  }

  streamCalcUsers() {
    return rx.pipe(
      () => this.groupedUsersLocal$,
      rx.map((groupedUsers) => _.values(groupedUsers)),
      rx.map((groupedUsersAsList) => _.flatten(groupedUsersAsList)),
      rx.tap((users) => (this.users = users)),
    )(null);
  }

  streamCustomersToSplitChange() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      filterCompChange<{ desk: Desk; users: User[] }[]>('customersToSplit'),
      rx.map(({ currentValue }) => currentValue),
      rx.tap(() => this.split()),
    )(null);
  }

  /**
   * Split unlocked customers to unlocked users
   */
  split() {
    // calculate number of customers that should be allocated
    const chunkSize = Math.floor(
      this.unlockedCustomersNumber / this.unlockedUsersNumber,
    );
    const remainder = this.unlockedCustomersNumber % this.unlockedUsersNumber;

    let changedDetected = false;

    // iterate over unlocked users and allocate customers
    _.eachEs(this.unlockedUsers, (user, i) => {
      let userAllocatedCustomers = Math.max(chunkSize, 0); // don't allow negative values
      // add the remainder to first unlocked user
      if (!i && userAllocatedCustomers + remainder > -1) {
        userAllocatedCustomers += remainder;
      }

      if (user.allocatedCustomers === userAllocatedCustomers) {
        return;
      }

      changedDetected = true;
      user.allocatedCustomers = userAllocatedCustomers;
    });

    if (changedDetected) {
      this.onChangeOfSplits({
        groupedUsers: this.groupedUsersLocal$.getValue(),
      });
    }
  }

  /**
   * Called from child component when number of customers allocated to user changed
   */
  onManualAllocation() {
    // split the customers
    this.split();
  }

  /**
   * Unlock all users
   */
  unlockAllUsers() {
    // broadcast event to all child components
    this.$scope.$broadcast(UNLOCK_USERS_EVENT);
  }

  /**
   * Returns name of the event that fired when all users should be unlocked
   * @returns {string}
   */
  get unlockUserEventName() {
    return UNLOCK_USERS_EVENT;
  }

  /**
   * Returns number of customers that allocated to locked users
   *
   * @returns {int}
   */
  get lockedCustomersNumber(): number {
    return this.lockedUsers
      .map(({ allocatedCustomers }) => allocatedCustomers)
      .reduce((memo, num) => memo + num, 0);
  }

  /**
   * Returns number of customers that allocated to unlocked users
   *
   * @returns {int}
   */
  get unlockedCustomersNumber() {
    return this.customersToSplit - this.lockedCustomersNumber;
  }

  /**
   * Returns number of unlocked users
   *
   * @returns {int}
   */
  get unlockedUsersNumber() {
    return this.totalUsersNumber - this.lockedUsersNumber;
  }

  /**
   * Returns collection of unlocked users
   *
   * @returns {Array}
   */
  get unlockedUsers() {
    return _.filter(
      {
        isLocked: false,
      },
      this.users,
    );
  }

  /**
   * Returns number of locked users
   *
   * @returns {int}
   */
  get lockedUsersNumber() {
    return this.lockedUsers.length;
  }

  /**
   * Returns collection of locked users
   *
   * @returns {Array}
   */
  get lockedUsers() {
    return _.filter(
      {
        isLocked: true,
      },
      this.users,
    );
  }

  /**
   * Returns total number of users
   *
   * @returns {int}
   */
  get totalUsersNumber() {
    return this.users.length;
  }
}

export default {
  template,
  controller: AssignSplitterController,
  controllerAs: 'vm',
  bindings: {
    customersToSplit: '<',
    desks: '<',
    groupedUsers: '<',
    onChangeOfSplits: '&',
    onLockChange: '&',
  },
};
