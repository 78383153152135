export const brandPlatformMt4GroupAssetsTableColumns = [
  {
    field: 'tradingCoregAssets',
    fieldFormat: '{{ symbol }}',
    filterable: true,
    removable: false,
    sortable: 'symbol',
    show: true,
    title: 'riskManager.SYMBOL_NAME',
  },
  {
    field: '',
    fieldFormat: '{{ mtCustomerGroup.name }}',
    filterable: false,
    removable: false,
    sortable: 'name',
    show: true,
    title: 'common.GROUP_NAME',
  },
  {
    field: '',
    fieldFormat: '{{ precision }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.PIP_DIGIT',
  },
  {
    field: '',
    fieldFormat: '{{ mtSecuritiesName }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.SECURITY_TYPE',
  },
  {
    field: '',
    fieldFormat: '{{ contractSize }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.CONTRACT_SIZE',
  },
  {
    field: '',
    fieldFormat: '{{ _rawSpread }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.RAW_SPREAD',
  },
  {
    field: '',
    fieldFormat: '{{ mtGroupSecurities.spread }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.MARKUP',
  },
  {
    field: '',
    fieldFormat: '{{ _totalSpread }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.TOTAL_SPREAD',
  },
  {
    field: '',
    fieldFormat: '{{ percentage }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.MARGIN_PERCENTAGE_SYMBOL',
  },
  {
    field: '',
    fieldFormat: '{{ swapLong }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.LONG_SWAP',
  },
  {
    field: '',
    fieldFormat: '{{ swapShort }}',
    filterable: false,
    removable: false,
    sortable: false,
    show: true,
    title: 'common.SHORT_SWAP',
  },
  {
    // This is empty col for control settings
    filterable: false,
    removable: false,
    renderer: 'actions',
    sortable: false,
    show: true,
    title: '',
  },
];
