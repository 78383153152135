import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'brands';
const CURRENCIES_RESOURCE = 'currencies';
const GROUPS_RESOURCE = 'groups';
const PLATFORMS_RESOURCE = 'platforms';

export class PublicBrandsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  /**
   * This resource is public
   *
   * @returns {string}
   */
  get accessType() {
    return 'public';
  }

  /**
   * Build url to list of currencies related to given brand
   *
   * @param {int} brandId - the brand to fetch accounts for
   * @returns {RestService} - self instance, for chaining
   */
  getCurrenciesResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(CURRENCIES_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url to list of platforms related to given brand
   *
   * @param {number} brandId - the brand to fetch platforms for
   * @returns {RestService} - self instance, for chaining
   */
  getPlatformsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(PLATFORMS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url to list the groups related to specific brand and platform.
   * @param {number} brandId - brand id
   * @param {number} platformId - platform id
   * @return {RestService} - self instance, for chaining
   */
  getGroupsResource(brandId: number, platformId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(PLATFORMS_RESOURCE, platformId)
      .getNestedCollection(GROUPS_RESOURCE)
      .resourceBuildEnd();
  }
}
