import { ContactsImport } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { ContactsImportTableAction } from './contacts-import-table.actions';

export function calcMenuItem(item: ContactsImport) {
  let actions = [];

  if (item.newRecords > 0) {
    actions = [
      ...actions,
      {
        actionCode: ContactsImportTableAction.ViewContacts,
        labelCode: 'contactsImportTable.actions.VIEW_CONTACTS',
      },
    ];
  }

  if (!_.isEmpty(item.errorFilePath)) {
    actions = [
      ...actions,
      {
        actionCode: ContactsImportTableAction.DownloadErrors,
        labelCode: 'contactsImportTable.actions.DOWNLOAD_ERRORS',
      },
    ];
  }

  return actions;
}
