import SocketService from '~/source/common/services/socket';

export class TradingAccountBinarySocketService extends SocketService {
  get channelRoot() {
    return 'BinaryTradingAccount';
  }

  get whiteList() {
    return [
      'balance',
      'bonus',
      'deposit',
      'netDeposit',
      'pnl',
      'isAccountActive',
      'turnover',
      'turnoverGoal',
      'winRate',
      'withdrawal',
      'withdrawable',
      'withdrawableBonusesSum',
      'revenue',
      'netDeposit',
      'validPositionsCount',
      'openPositionsCount',
      'fee',
      'rolloverFee',
      'name',
      'margin',
      'freeMargin',
      'marginLevel',
      'statusCode',
    ];
  }
}

export default TradingAccountBinarySocketService;
