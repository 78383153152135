import * as rx from '@proftit/rxjs';
import { IScope } from 'angular';

interface IEventPayload<T> {
  event: any;
  payload: T;
}

export function observeScopeEvent<T>(
  eventName: string,
  $scope: IScope,
): rx.Observable<{ event: any; payload: T }> {
  return new rx.Observable(
    (subscriber: rx.Subscriber<{ event: any; payload: T }>) => {
      const scopeUnreg = $scope.$on(eventName, (event, payload: T) => {
        subscriber.next({ event, payload });
      });

      return () => scopeUnreg();
    },
  );
}
