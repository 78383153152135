import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
const styles = require('./compliance.component.scss');

import * as rx from '@proftit/rxjs';
import BrandsService from '~/source/management/brand/services/brands';
import {
  Customer,
  Brand,
  CustomerComplianceFileType,
} from '@proftit/crm.api.models.entities';
import CustomerComplianceStatusesService from '~/source/contact/common/services/customer-compliance-statuses.service';
import CustomersService from '~/source/contact/common/services/customers';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import PopupService from '~/source/common/components/modal/popup.service';
import CustomerComplianceRemovedClientPubsubService from '~/source/common/services/customer-compliance-removed-client-pubsub.service';
import template from './compliance.html';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { FeaturesFlagsService } from '~/source/common/services/features-flags.service';
import { IScope } from 'angular';
import { useStreams } from '@proftit/rxjs.adjunct';
import { ComplianceDockTypesManagementService } from '~/source/common/api-crm-server/services/compliance-dock-types-management.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { kycCompliancesTypesUiFactions } from './compliance-types-ui-factions';
import { observeScopeEvent } from '~/source/common/utilities/rxjs/observables/observe-scope-event';
import { CustomerCompliance } from '~/source/common/models/customer-compliance';
import { IPromise } from 'restangular';
import { getIconForFileStatus } from '~/source/common/utilities/compliance-status-icon';
import {
  ATTACHMENT_ADDED_TO_DEPOSIT,
  CUSTOMER_REGULATION_FILE_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';

const ADDITIONAL_ATTACHMENT_ICON = 'svg-i-attachments-additional';

class Controller {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  isVerificationMethodManually: boolean;
  isEdit: boolean;
  customer: IElementRestNg<Customer>;
  brand: Brand;
  prevAttributes: {
    customerComplianceStatus?: any;
  };

  customer$ = observeShareCompChange<IElementRestNg<Customer>>(
    this.lifecycles.onChanges$,
    'customer',
  );
  customerExistingComplianceTypes$ = this.streamCustomerExistingComplianceTypes();
  customerFullComlianceTypes$ = this.streamCustomerFullComlianceTypes();
  kycAttachmentsFactions$ = this.streamKycAttachmentsFactions();
  additionalAttachemtsFactions$ = this.streamAdditionalAttachmentsFactions();

  /* @ngInject */
  constructor(
    readonly customerComplianceStatusesService: () => CustomerComplianceStatusesService,
    readonly prfComplianceDockTypesManagementService: () => ComplianceDockTypesManagementService,
    readonly brandsService: () => BrandsService,
    readonly customersService: () => CustomersService,
    readonly $scope: IScope,
    readonly customerComplianceTypeService,
    readonly popupService: PopupService,
    readonly featuresFlags: FeaturesFlagsService,
    readonly prfCustomerComplianceRemovedClientPubsub: CustomerComplianceRemovedClientPubsubService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams([this.customer$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.isVerificationMethodManually = false;
    this.isEdit = false;

    useStreams(
      [this.kycAttachmentsFactions$, this.additionalAttachemtsFactions$],
      this.lifecycles.onDestroy$,
    );

    this.setVerifiedStatusMethod();
  }

  $onChanges() {}

  $onDestroy() {}

  getLastAttachmentData(complianceDocTypeId: number, customer: Customer) {
    return this.customersService().getLastAttachmentData(
      customer.id,
      this.growlId,
      this.blockUiId,
      complianceDocTypeId,
    );
  }

  streamCustomerExistingComplianceTypesFromInit() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.switchMap(() => rx.obs.from(this.fetchCustomerComplianceDocTypes())),
    )(null);
  }

  streamExistingCompFromEventAttachmentAdd(
    customerExistingComplianceTypes$: rx.Observable<
      CustomerComplianceFileType[]
    >,
  ) {
    return rx.pipe(
      () =>
        observeScopeEvent<{
          newCompliance: CustomerCompliance;
          complianceDocType: CustomerComplianceFileType;
        }>('attachment:added', this.$scope),
      rx.withLatestFrom(customerExistingComplianceTypes$),
      rx.map(([{ event, payload }, customerComplianceDocTypes]) => {
        if (
          customerComplianceDocTypes.find(
            (x) => x.id === payload.newCompliance.id,
          )
        ) {
          return customerComplianceDocTypes;
        }

        return [...customerComplianceDocTypes, payload.complianceDocType];
      }),
    )(null);
  }

  streamExistingCompFromEventAttachmentRemove() {
    return rx.pipe(
      () => this.prfCustomerComplianceRemovedClientPubsub.getObs(),
      rx.switchMap(() => rx.obs.from(this.fetchCustomerComplianceDocTypes())),
    )(null);
  }

  streamExistingCompFromEventAttachmentAddedAtDepositTable() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === ATTACHMENT_ADDED_TO_DEPOSIT),
      rx.switchMap(() => rx.obs.from(this.fetchCustomerComplianceDocTypes())),
    )(null);
  }

  streamCustomerExistingComplianceTypes() {
    const customerExistingComplianceTypes$ = new rx.BehaviorSubject<
      CustomerComplianceFileType[]
    >([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamCustomerExistingComplianceTypesFromInit(),
          this.streamExistingCompFromEventAttachmentAdd(
            customerExistingComplianceTypes$,
          ),
          this.streamExistingCompFromEventAttachmentRemove(),
          this.streamExistingCompFromEventAttachmentAddedAtDepositTable(),
        ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
      rx.tap((x) => customerExistingComplianceTypes$.next(x)),
    )(null);
  }

  streamCustomerFullComlianceTypes() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.customerExistingComplianceTypes$,
          rx.obs.from(this.fetchComplianceDoctypes()),
        ),
      rx.map(([customerExistingCompTypes, allCompTypes]) => {
        return allCompTypes.map((complianceType) => {
          const hasOneAtLeast =
            customerExistingCompTypes.filter(
              (c) => c.code === complianceType.code,
            ).length > 0;
          const isCategoryComplete = hasOneAtLeast;

          return {
            hasOneAtLeast,
            isCategoryComplete,
            type: complianceType,
          };
        });
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamKycAttachmentsFactions() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInitShared$,
          this.prfClientGeneralPubsub
            .getObservable()
            .pipe(
              rx.filter(({ key }) => key === CUSTOMER_REGULATION_FILE_UPDATED),
            ),
        ),
      rx.switchMap(() => this.customerFullComlianceTypes$),
      rx.map((customerFullComlianceTypes) =>
        customerFullComlianceTypes.filter((x) => x.type.isSystem),
      ),
      rx.map((customerFullComlianceTypes) => {
        return kycCompliancesTypesUiFactions.reduce(
          (fullAcc, { code, icon, complianceTypes, groupForStatus }) => {
            const groups = complianceTypes.reduce((acc, complianceTypeCode) => {
              const found = customerFullComlianceTypes.find(
                (x) => x.type.code === complianceTypeCode,
              );

              if (found) {
                acc.push(found);
              }

              return acc;
            }, []);

            if (groups.length === 0) {
              return fullAcc;
            }
            return [
              ...fullAcc,
              {
                code,
                icon,
                groups,
                groupForStatus,
              },
            ];
          },
          [],
        );
      }),
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([complianceArray, customer]) => {
        const promiseArray = complianceArray.map((singleCompliance) =>
          this.enhanceSingleCompliance(singleCompliance, customer),
        );
        return Promise.all(promiseArray);
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  enhanceSingleCompliance(singleCompliance, customer: Customer): Promise<any> {
    const { groups } = singleCompliance;

    const group = groups.find((group) => {
      const { type } = group;
      if (!type) {
        return false;
      }
      if (!singleCompliance.groupForStatus) {
        return type.code === singleCompliance.code;
      }
      return type.code === singleCompliance.groupForStatus;
    });

    if (!group) {
      return Promise.resolve(singleCompliance);
    }

    const { hasOneAtLeast, type } = group;
    const { id } = type;

    if (!hasOneAtLeast) {
      return Promise.resolve(singleCompliance);
    }
    return this.getLastAttachmentData(id, customer).then((data) => {
      if (data.length === 0) {
        return singleCompliance;
      }

      const status = data[0].statusCode;
      const iconToSet = getIconForFileStatus(status, singleCompliance.icon);

      return {
        ...singleCompliance,
        icon: iconToSet,
      };
    }) as Promise<any>;
  }

  streamAdditionalAttachmentsFactions() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInitShared$,
          this.prfClientGeneralPubsub
            .getObservable()
            .pipe(
              rx.filter(({ key }) => key === CUSTOMER_REGULATION_FILE_UPDATED),
            ),
        ),
      rx.switchMap(() => this.customerFullComlianceTypes$),
      rx.map((compsByType) => compsByType.filter((x) => !x.type.isSystem)),
      rx.map((compsByType) => {
        return compsByType.map((x) => {
          return {
            code: x.type.code,
            icon: ADDITIONAL_ATTACHMENT_ICON,
            groups: [x],
          };
        });
      }),
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([complianceArray, customer]) => {
        const promiseArray = complianceArray.map((singleCompliance) =>
          this.enhanceSingleCompliance(singleCompliance, customer),
        );
        return Promise.all(promiseArray);
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  fetchCustomerComplianceDocTypes() {
    return this.customersService()
      .setConfig({ growlRef: this.growlId, blockUiRef: this.blockUiId })
      .getCustomerComplianceDocTypes(this.customer.id)
      .getListWithQuery<IElementRestNg<CustomerComplianceFileType>>()
      .then((data) => data.plain());
  }

  fetchComplianceDoctypes() {
    return this.prfComplianceDockTypesManagementService()
      .setConfig({
        blockUiRef: this.blockUiId,
        growlRef: this.growlId,
      })
      .getAll();
  }

  /**
   * Gets verification method per current brand and sets 'isVerificationMethodManually'
   * @return {void}
   */
  setVerifiedStatusMethod() {
    this.brandsService()
      .expand(['attachmentsVerifiedMethod'])
      .getOneWithQuery(this.customer.brand.id)
      .then((brand: IElementRestNg<Brand>) => {
        this.brand = brand;
        this.isVerificationMethodManually =
          brand.attachmentsVerifiedMethod.code === 'manually';
      });
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   * @returns {void}
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = {
      customerComplianceStatus: { ...this.customer.customerComplianceStatus },
    };

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   * @returns {void}
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.customer, this.prevAttributes);
    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    if (
      this.customer.customerComplianceStatus.id ===
      this.prevAttributes.customerComplianceStatus.id
    ) {
      return Promise.resolve(null);
    }

    // Save the customer changes
    return this.customer
      .patch({
        customerComplianceStatusId: this.customer.customerComplianceStatus.id,
      })
      .then(() => {
        // change to display mode
        this.isEdit = false;
      });
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
  },
};
