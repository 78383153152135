export const contactsImportRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.tools.contacts-import', {
        redirectTo: 'crm.management.tools.contacts-import.dashboard',
        url: '/contacts-import',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.contacts-import.dashboard', {
        url: '/dashboard',
        component: 'prfContactsImportDashboard',
      })
      .state('crm.management.tools.contacts-import.create', {
        url: '/create',
        component: 'prfContactsImportCreate',
      });
  },
];
