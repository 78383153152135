import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './edit-box.component.html';

export class EditBoxController extends BaseController {}

export const EditBoxComponent = {
  template,
  controller: EditBoxController,
  transclude: true,
  bindings: {
    isInEdit: '<',
    onRemoveClick: '&',
    onEditClick: '&',
    onCancelClick: '&',
    onSaveClick: '&',
  },
};

export default EditBoxComponent;
