import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';

import template from './cfd-platform-brand-assets-bulk-update.component.html';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { CfdPlatformBrandConfigsService } from '../../services/cfd-platform-brand-configs.service';

import { PlatformBrand } from '~/source/common/models/cfd-platform/platform-brand';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, shareReplayRefOne, tapLog } from '@proftit/rxjs.adjunct';
import Big from 'big.js';

import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { SymbolCategoryStoreServiceDirectiveController } from '~/source/common/service-directives/symbol-category-store-service.directive';
import { PipDigitsStoreServiceDirectiveController } from '~/source/common/service-directives/pip-digits-store-service.directive';
import { CurrentTrcGroupsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-groups-of-platform-service.directive';
import { GroupAssetsStoreServiceDirectiveController } from '~/source/common/service-directives/group-assets-store-service.directive';
import { createCfdAssetsBulkUpdate } from './create-cfd-assets-bulk-update-validation';
import { PLATFORM_ASSET_UPDATED } from '~/source/common/constants/general-pubsub-keys';

export interface ICfdPlatformBrandAssetsBulkUpdateComponent {
  brandPlatform: PlatformBrand;
}
const styles = require('./cfd-platform-brand-assets-bulk-update.component.scss');

export interface MainFormCfdAssetsBulkUpdate {
  symbolCategory: any;
  pipDigits: any;
  groups: any;
  assets: any;
  spread: any;
  leverage: any;
  swapCommission: any;
  swapRatioAsPercentage: any;
  minInvestment: any;
  maxInvestment: any;
}

export interface IEnableInputs {
  spread: boolean;
  leverage: boolean;
  swap: boolean;
  minInvestment: boolean;
  maxInvestment: boolean;
}

export class CfdPlatformBrandAssetsBulkUpdateController {
  styles = styles;
  close: () => void;

  resolve: ICfdPlatformBrandAssetsBulkUpdateComponent;
  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);
  prfSymbolCategory$ = new rx.BehaviorSubject<
    SymbolCategoryStoreServiceDirectiveController
  >(null);
  prfPipDigits$ = new rx.BehaviorSubject<
    PipDigitsStoreServiceDirectiveController
  >(null);

  prfCurrentTrcGroupsOfPlatform$ = new rx.BehaviorSubject<
    CurrentTrcGroupsOfPlatformServiceDirectiveController
  >(null);

  prfGroupAssets$ = new rx.BehaviorSubject<
    GroupAssetsStoreServiceDirectiveController
  >(null);

  enableInputs = {
    spread: false,
    leverage: false,
    swap: false,
    minInvestment: false,
    maxInvestment: false,
  };

  lifecycles = observeComponentLifecycles(this);

  title = 'common.BULK_UPDATE';
  blockUiId = 'cfdPlatformBrandAssetsBulkUpdateBlockUi';
  growlId = 'cfdPlatformBrandAssetsBulkUpdateGrowl';

  mainForm: MainFormCfdAssetsBulkUpdate = {
    symbolCategory: null,
    pipDigits: null,
    groups: null,
    assets: null,
    spread: null,
    leverage: null,
    swapCommission: null,
    swapRatioAsPercentage: null,
    minInvestment: null,
    maxInvestment: null,
  };

  resolve$ = observeShareCompChange<ICfdPlatformBrandAssetsBulkUpdateComponent>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  brandPlatform$ = this.resolve$.pipe(
    rx.map(({ brandPlatform }) => brandPlatform),
    shareReplayRefOne(),
  );

  doOperation$ = new rx.Subject<void>();
  symbolCategoryChanged$ = new rx.Subject<void>();
  changePipDigitsFilter$ = this.streamChangePipDigitsFilter();
  pipDigitsChanged$ = new rx.Subject<void>();
  changeAssetsFilter$ = this.streamChangeAssetsFilter();
  changeOp$ = new rx.Subject<void>();

  validation$ = this.streamValidation();

  isLoading$ = this.streamIsLoading();

  symbolCategoryAsOptions = _.memoize((symboleCategories) =>
    symboleCategories
      ? symboleCategories.map((category) => ({
          value: category,
          label: category,
        }))
      : [],
  );

  pipDigitsAsOptions = _.memoize((pipDigits) =>
    pipDigits
      ? pipDigits.map((pipDigit) => ({ value: pipDigit, label: pipDigit }))
      : [],
  );

  currentTrcGroupsOfPlatformAsOptions = _.memoize((Groups) =>
    Groups
      ? Groups.map((Group) => ({ value: Group.id, label: Group.name }))
      : [],
  );

  groupAssetsAsOptions = _.memoize((assets) =>
    assets
      ? assets.map((asset) => ({ value: asset.id, label: asset.name }))
      : [],
  );

  /*@ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly prfCfdPlatformBrandConfigsService: CfdPlatformBrandConfigsService,
  ) {
    useStreams([this.resolve$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamSession(),
        this.streamInitPopup(),

        this.changePipDigitsFilter$,
        this.changeAssetsFilter$,
        this.streamDoOperation() as any,
        this.growlStream(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamDoOperation() {
    const streamFn = rx.pipe(
      () => this.doOperation$,
      rx.switchMap(() => {
        return createCfdAssetsBulkUpdate(this.enableInputs).isValid(
          this.mainForm,
        );
      }),
      rx.filter((isValidBulkAssetes) => isValidBulkAssetes),
      rx.map(() => {
        const changes = _.flow([
          (change) =>
            this.enableInputs.leverage
              ? { ...change, leverage: this.mainForm.leverage }
              : change,
          (change) =>
            this.enableInputs.spread
              ? { ...change, spread: this.mainForm.spread }
              : change,
          (change) =>
            this.enableInputs.swap
              ? { ...change, swapCommission: this.mainForm.swapCommission }
              : change,
          (change) =>
            this.enableInputs.swap
              ? {
                  ...change,
                  swapRatio: _.isNil(this.mainForm.swapRatioAsPercentage)
                    ? null
                    : Number(
                        Big(this.mainForm.swapRatioAsPercentage)
                          .times(100)
                          .div(10000)
                          .toString(),
                      ),
                }
              : change,
          (change) =>
            this.enableInputs.maxInvestment
              ? { ...change, maxInvestment: this.mainForm.maxInvestment }
              : change,
          (change) =>
            this.enableInputs.minInvestment
              ? { ...change, minInvestment: this.mainForm.minInvestment }
              : change,
        ])({});

        return _.flow([
          (data) => ({
            ...data,
            groupsIds: this.mainForm.groups.map((group) => group.value),
          }),
          (data) => ({
            ...data,
            assetsIds: this.mainForm.assets.map((group) => group.value),
          }),
          (data) => ({ ...data, changes }),
        ])({});
      }),
      rx.withLatestFrom(
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.switchMap((service) => service.sessionS.stream$),
          rx.filter((session) => session.isLoggedIn),
        ),
        this.prfGroupAssets$.pipe(rx.filter((x) => !_.isNil(x))),
      ),
      rx.tap(([payload, prfCurrentPlatformSession, prfGroupAssets]) => {
        prfGroupAssets.updateBulk(payload);
      }),
      rx.switchMap(
        ([payload, prfCurrentPlatformSession, prfGroupAssets]) =>
          prfGroupAssets.groupAssetsFromBulkUpdate$,
      ),
      rx.tap(() => {
        this.close();
        this.prfClientGeneralPubsub.publish(PLATFORM_ASSET_UPDATED, null);
      }),
      rx.catchError((err) => {
        return streamFn(null);
      }),
      shareReplayRefOne(),
    );

    return streamFn(null);
  }

  growlStream() {
    const streamFn = rx.pipe(
      () => this.prfGroupAssets$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((groupAssets) => groupAssets.updateInProgress$),
      rx.map((progress) => progress.error),
      rx.filter((error) => !_.isNil(error)),
      rx.tap((error) =>
        this.growl.error(error.message, { referenceId: this.growlId as any }),
      ),
    );

    return streamFn(null);
  }

  private streamInitPopup(): rx.Observable<any> {
    return rx.obs
      .combineLatest([
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.switchMap((service) => service.sessionS.stream$),
          rx.filter((session) => session.isLoggedIn),
        ),
        this.prfSymbolCategory$.pipe(rx.filter((x) => !_.isNil(x))),
        this.prfPipDigits$.pipe(rx.filter((x) => !_.isNil(x))),
        this.prfCurrentTrcGroupsOfPlatform$.pipe(rx.filter((x) => !_.isNil(x))),
        this.prfGroupAssets$.pipe(rx.filter((x) => !_.isNil(x))),
      ])
      .pipe(
        rx.tap(
          ([
            platformSession,
            symbolCategory,
            pipDigits,
            currentTrcGroupsOfPlatform,
            groupAssets,
          ]) => {
            symbolCategory.load();
            pipDigits.load();
            groupAssets.load();
          },
        ),
      );
  }

  $onChanges() {}

  $onDestroy() {}

  streamSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.brandPlatform$,
          this.prfCurrentPlatformSession$,
        ),
      rx.filter((params) => params.every((p) => !_.isNil(p))),
      rx.switchMap(([brandPlatform, sessionStore]) => {
        return sessionStore.login(brandPlatform);
      }),
    )(null);
  }

  streamValidation() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.changeOp$,
          this.symbolCategoryChanged$,
          this.pipDigitsChanged$,
        ),
      rx.tap(() => {
        this.growlMessages.destroyAllMessages(this.growlId as any);
      }),
      rx.debounceTime(300),
      rx.switchMap(() =>
        createCfdAssetsBulkUpdate(this.enableInputs)
          .validate(this.mainForm, { abortEarly: false })
          .then(() => ({ isValid: true, errors: null }))
          .catch((err) => {
            return {
              isValid: false,
              errors: err.errors,
            };
          }),
      ),
      rx.startWith({
        isValid: false,
        errors: null,
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamChangePipDigitsFilter() {
    return rx.pipe(
      () => this.symbolCategoryChanged$,
      rx.delay(0),
      rx.withLatestFrom(
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.switchMap((service) => service.sessionS.stream$),
          rx.filter((session) => session.isLoggedIn),
        ),
        this.prfPipDigits$.pipe(rx.filter((x) => !_.isNil(x))),
      ),
      rx.tap(([a, platformSession, pipDigits]) => {
        const filters = !_.isNil(this.mainForm.symbolCategory)
          ? this.mainForm.symbolCategory.map((category) => category.value)
          : [];
        pipDigits.filter(filters);
      }),
    )(null);
  }

  streamChangeAssetsFilter() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.prfCurrentTrcGroupsOfPlatform$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((service) => service.generalGroupsS.stream$),
            rx.filter((groups) => groups.length > 0),
            rx.take(1),
          ),
          this.symbolCategoryChanged$.pipe(rx.startWith(true)),
          this.pipDigitsChanged$.pipe(rx.startWith(true)),
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((service) => service.sessionS.stream$),
            rx.filter((session) => session.isLoggedIn),
          ),
          this.prfGroupAssets$.pipe(rx.filter((x) => !_.isNil(x))),
        ]),
      rx.delay(0),
      rx.tap(([groups, b, c, platformSession, groupAssets]) => {
        const filters = _.flow(
          (filter) => {
            if (!_.isEmpty(this.mainForm.symbolCategory)) {
              filter.categories = this.mainForm.symbolCategory.map(
                (category) => category.value,
              );
            }
            return filter;
          },
          (filter) => {
            if (!_.isEmpty(this.mainForm.pipDigits)) {
              filter.pipDigits = this.mainForm.pipDigits.map(
                (pipDigit) => pipDigit.value,
              );
            }
            return filter;
          },
        )({});

        groupAssets.filter(groups[0].id, filters);
      }),
    )(null);
  }

  streamIsLoading() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfSymbolCategory$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((symbolCategory) => symbolCategory.inProgress$),
          ),
          this.prfCurrentTrcGroupsOfPlatform$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap(
              (currentTrcGroupsOfPlatform) =>
                currentTrcGroupsOfPlatform.generalGroupsS.inProcess$,
            ),
            rx.map((serviceInProcess) => serviceInProcess.inProcess),
          ),
          this.prfPipDigits$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((pipDigits) => pipDigits.inProgress$),
          ),
          this.prfGroupAssets$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((groupAssets) => groupAssets.updateInProgress$),
            rx.map((progress) => progress.inProgress),
          ),
          this.prfGroupAssets$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((groupAssets) => groupAssets.getInProgress$),
          ),
        ),
      rx.map((conds) => conds.some((c) => c)),
      shareReplayRefOne(),
    )(null);
  }

  setCurrentPlatformSession(
    currentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController,
  ) {
    this.prfCurrentPlatformSession$.next(currentPlatformSession);
  }
}

export const CfdPlatformBrandAssetsBulkUpdateComponent = {
  template,
  controller: CfdPlatformBrandAssetsBulkUpdateController,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
