import angular from 'angular';
import PositionsBinarySocketService from './positions-binary-socket.service';
import PositionsStatusesBinaryService from './positions-statuses-binary.service';
import PositionsStatusesBinarySelectComponent from './positions-statuses-binary-select.component';
import PositionsTableBinaryComponent from './positions-table-binary.component';
import PositionTableBinaryPopupController from './positions-table-binary-popup-controller';

export default angular
  .module('crm.contact.tradingAccount.binary.position', [])
  .service('positionsBinarySocketService', PositionsBinarySocketService)
  .service('positionsStatusesBinaryService', PositionsStatusesBinaryService)
  .component(
    'prfPositionsStatusesBinarySelect',
    PositionsStatusesBinarySelectComponent,
  )
  .component('prfPositionsTableBinary', <any>PositionsTableBinaryComponent)
  .controller(
    'positionsTableBinaryPopupController',
    PositionTableBinaryPopupController,
  ).name;
