import angular from 'angular';

import BonusStatusesSelect from './bonus-statuses-select';
import CommunicationsStatusesSelect from './communications-statuses-select';
import CommunicationsSubjectsSelect from './communications-subjects-select';
import CustomerComplianceStatusesSelect from './customer-compliance-statuses-select';
import CustomerAccountStatusesSelect from './customer-account-statuses-select';
import CustomerComplianceTypesSelect from './customer-compliance-type-select';
import CustomerStatusesSelect from './customer-statuses-select';
import DepositStatusesSelect from './deposit-statuses-select';
import FeeTypesSelect from './fee-types-select';
import FeeTypesUserUsableSelect from './fee-types-user-usable-select';
import MigratedStatusesSelect from './migrated-statuses-select';
import PositionProductsSelect from './positions-products-select';
import PositionTradeAssetsSelect from './positions-trade-asset-select';
import PositionTradeDirectionsSelect from './positions-trade-direction-select';
import TradeReputationTypesSelect from './trade-reputation-types-select';
import TradingAccountGroupsSelect from './trading-account-groups-select';
import TradingAccountTypesSelect from './trading-account-types-select';
import UserAssignableSelect from './user-assignable-select';
import CustomerRegulationFileStatusesSelect from './customer-regulation-file-statuses-select';
import { CommunicatonTypesSelectComponent } from './communication-types-select';
import { CommunicationsSubjectByCommTypeSelectComponent } from './communications-subjects-by-comm-type-select';
import { CommunicationsStatusesByCommTypeSelectComponent } from './communications-statuses-by-comm-type-select';
import { ContactsImportSelectComponent } from './contacts-import-select';
import { BuySellTradeDirectionSelectComponent } from './buy-sell-trade-direction-select';

const ngModule = angular
  .module('contact.dropdowns', [])
  .component('prfBonusStatusesSelect', BonusStatusesSelect)
  .component('prfCommunicationsStatusesSelect', CommunicationsStatusesSelect)
  .component(
    'prfCommunicationsStatusesByCommTypeSelect',
    CommunicationsStatusesByCommTypeSelectComponent,
  )
  .component('prfCommunicationsSubjectSelect', CommunicationsSubjectsSelect)
  .component(
    'prfCommunicationsSubjectByCommTypeSelect',
    CommunicationsSubjectByCommTypeSelectComponent,
  )
  .component('prfCommunicationTypesSelect', CommunicatonTypesSelectComponent)
  .component(
    'prfCustomerComplianceStatusesSelect',
    CustomerComplianceStatusesSelect,
  )
  .component('prfCustomerComplianceTypeSelect', CustomerComplianceTypesSelect)
  .component('prfContactsImportSelect', ContactsImportSelectComponent)
  .component('prfCustomerStatusesSelect', CustomerStatusesSelect)
  .component('prfCustomerAccountStatusesSelect', CustomerAccountStatusesSelect)
  .component('prfDepositStatusesSelect', DepositStatusesSelect)
  .component('prfFeeTypesSelect', FeeTypesSelect)
  .component('prfFeeTypesUserUsableSelect', FeeTypesUserUsableSelect)
  .component('prfMigratedStatusesSelect', MigratedStatusesSelect)
  .component('prfPositionsProductsSelect', PositionProductsSelect)
  .component('prfPositionsTradeAssetsSelect', PositionTradeAssetsSelect)
  .component('prfPositionsTradeDirectionSelect', PositionTradeDirectionsSelect)
  .component('prfTradeReputationTypesSelect', TradeReputationTypesSelect)
  .component('prfTradingAccountGroupsSelect', TradingAccountGroupsSelect)
  .component('prfTradingAccountTypeSelect', TradingAccountTypesSelect)
  .component('prfUserAssignableSelect', UserAssignableSelect)
  .component(
    'prfBuySellTradeDirectionSelect',
    BuySellTradeDirectionSelectComponent,
  )
  .component(
    'prfCustomerRegulationFileStatusesSelect',
    CustomerRegulationFileStatusesSelect,
  );

export default ngModule.name;
