import BaseController from '~/source/common/controllers/base';
import template from './contact-table-selection-bar.html';
import { IScope } from 'angular';
import { Customer } from '@proftit/crm.api.models.entities';
class ContactsSelectionBarController extends BaseController {
  // bindings
  selectAllItems: boolean;
  assignLimit: number;
  serverAssignLimit: number;
  totalRecords: number;
  selectedItems: Customer[];

  maximumInputAssignLimit: number;
  categoryItemsList: any[];

  /* @ngInject */
  constructor(readonly $scope: IScope) {
    super();
  }

  $onInit() {
    Object.assign(this, {
      customersList: [], // Customers collection for patch method, to assign user by id
      categoryItemsList: [],
      /*
       * the limit of changes allowed in customer mass update/post when selectAllItems=true
       * on init this value is equal to the number of selected customers. when the user change
       * the assignLimit still the input field won't allow it to pass the maximumAssignLimit or the totalRecords
       */
      assignLimit: this.selectedItems.length,
      /*
       * the maximum assign limit allowed for batch filter mode. used  as max number to validate the
       * assignLimit input field
       */
      maximumInputAssignLimit: this.getMaximumInputAssignLimit(),
    });

    this.categoryItemsList.length = 0; // reset category items (displayed on the right column)
  }

  /**
   * listen to changes in selected customer list (user clicked on checkbox).
   * on changes turn off batch operation
   * @param {object} changes
   */
  $onChanges(changes) {
    if (changes.selectedItems && changes.selectedItems.currentValue) {
      /*
       * represent the number of customers to update, (changeable in the view)
       * when not in batch mode. its just a counter for selected customers
       */
      this.assignLimit = this.selectedItems.length;
      this.selectAllItems = false;
    }

    if (changes.totalRecords) {
      this.maximumInputAssignLimit = this.getMaximumInputAssignLimit();
    }
  }

  /**
   * called when assignLimit input changes. it seems the user user to make a batch operation.
   * set selectAllItems=true
   */
  onInputChange() {
    this.selectAllItems = true;
  }

  /**
   * Option select all records in the current filter.
   */
  selectAll() {
    this.selectAllItems = true;
    this.assignLimit = this.getMaximumInputAssignLimit();
  }

  /**
   * the maximum records allowed to to assign in one batch post.
   * @returns {number}
   */
  getMaximumInputAssignLimit() {
    return Math.min(this.serverAssignLimit, this.totalRecords);
  }
}

export default {
  template,
  transclude: true,
  controller: ContactsSelectionBarController,
  bindings: {
    // if true the user is in batch mode
    selectAllItems: '=',
    // the limit of records to affect when user selection is in batch mode, this value iss filled by the user
    assignLimit: '=',
    // the maximum assign limit the server can handle. assignLimit can't be greater than this
    serverAssignLimit: '<',
    // the total records available for batch operation
    totalRecords: '<',
    // the selected customers
    selectedItems: '<',
  },
  controllerAs: 'vm',
};
