import { IScope } from 'angular';

import template from './blacklisted-countries-form.html';
import BaseController from '~/source/common/controllers/base';

class ComponentController extends BaseController {
  /*@ngInject */
  constructor(readonly $scope: IScope) {
    super();
  }
}

const BlacklistedCountiresFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    selectedCountries: '=',
    isEdit: '<',
    hideDefault: '<',
    isCountryDisabledFn: '<',
  },
};

export default BlacklistedCountiresFormComponent;
