import ng from 'angular';
import { PortfolioDashboardComponent } from './components/portfolio-dashboard/portfolio-dashboard.component';
import { PortfolioTableComponent } from './components/portfolio-table/portfolio-table.component';
import { PortfolioStatsBarComponent } from './components/portfolio-stats-bar/portfolio-stats-bar';
import { PortfolioPositionsTableComponent } from './components/portfolio-positions-table/portfolio-positions-table.component';

export default ng
  .module('crm.portfolio', [])
  .component('prfPortfolioDashboard', PortfolioDashboardComponent)
  .component('prfPortfolioStatsBar', PortfolioStatsBarComponent)
  .component('prfPortfolioTable', PortfolioTableComponent)
  .component('prfPortfolioPositionsTable', PortfolioPositionsTableComponent)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.portfolio-dashboard', {
        url: '/portfolio-dashboard',
        component: 'prfPortfolioDashboard',
        data: {
          pageTitle: 'PORTFOLIO',
        },
      });
    },
  ]).name;
