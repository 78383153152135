import template from './brand-is-demo-deposit-active-form.component.html';
const styles = require('./brand-is-demo-deposit-active-form.component.scss');
import ng from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  createNgModelObsMediator,
  NgModelChange,
} from '~/source/common/utilities/create-ng-model-obs-mediator';
import { createNgModelSubject } from '~/source/common/utilities/create-ng-model-subject';
import { Brand } from '@proftit/crm.api.models.entities';

export class Controller {
  onChangeOfModel: (a: { model: any }) => void;
  onChangeOfValidity: (a: { isValid: boolean }) => {};

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  brandIsDemoDepositActiveForm;
  brandInput$ = observeShareCompChange<Brand>(
    this.lifecycles.onChanges$,
    'brand',
  );
  editModeInput$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'editMode',
  );
  form$ = this.streamForm();
  isEnabled$ = this.streamIsEnabled();

  /* @ngInject */
  constructor(readonly $validation) {
    useStreams(
      [
        this.brandInput$,
        this.editModeInput$,
        this.streamNotifyOnChangesOfModel(),
        this.streamFormValidOut(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.brandInput$,
          this.editModeInput$.pipe(
            rx.distinctUntilChanged(),
            rx.withLatestFrom(this.brandInput$),
            rx.map(([a, brandInput]) => brandInput),
          ),
        ),
      rx.filter((brand) => !_.isNil(brand)),
      rx.map((brand) => {
        return new FormGroup({
          isDemoDepositActive: new FormControl(brand.isDemoDepositActive),
        } as any);
      }),
      shareReplayRefOne(),
    )(null) as rx.Observable<FormGroup>;
  }

  streamIsEnabled() {
    return rx.pipe(() => this.editModeInput$, shareReplayRefOne())(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid: boolean) => this.onChangeOfValidity({ isValid })),
    )(null);
  }

  streamNotifyOnChangesOfModel() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.value$),
      rx.tap((model) => this.onChangeOfModel({ model })),
    )(null);
  }
}

export const BrandIsDemoDepositActiveFormComponent = {
  template,
  controller: Controller,
  bindings: {
    brand: '<',
    editMode: '<',
    onChangeOfModel: '&',
    onChangeOfValidity: '&',
  },
};
