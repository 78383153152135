import * as _ from '@proftit/lodash';
import { ChangedListener } from './changed-listener';
import { InitialListener } from './initial-listener';
import { IsValidListener } from './is-valid-listener';
import { ValidationResultsListener } from './validation-results-listener';
import { ValidationResult } from './validation-result';
import { ValueListener } from './value-listener';

interface ItemProxyValidator {
  (value): ValidationResult;
}

interface LeafConfig<T> {
  onCalcChanged: () => void;
  onGetChanged: () => T;
  onGetInitial: () => T;
  onGetValue: () => T;
  onSetValue: (value) => void;
  onSetValueAsInitial: (value) => void;
  onGetValidationContext: () => any;
}

export class LeafBehavior<T> {
  changedListeners: ChangedListener[] = [];

  initialListeners: InitialListener<T>[] = [];

  isReadonly = false;

  isValid = true;

  value: T;

  isValidListeners: IsValidListener[] = [];

  onCalcChanged: () => void;

  onGetChanged: () => T;

  onGetInitial: () => T;

  onGetValue: () => T;

  onSetValue: (value: T) => void;

  onSetValueAsInitial: (value: T) => void;

  onGetValidationContext: () => any;

  validationResultsListners: ValidationResultsListener[] = [];

  valueListeners: ValueListener<T>[] = [];

  validators: ItemProxyValidator[];

  validationResults: ValidationResult[] = [];

  constructor(config: LeafConfig<T>) {
    this.onCalcChanged = config.onCalcChanged;
    this.onGetChanged = config.onGetChanged;
    this.onGetInitial = config.onGetInitial;
    this.onGetValue = config.onGetValue;
    this.onSetValue = config.onSetValue;
    this.onSetValueAsInitial = config.onSetValueAsInitial;
    this.onGetValidationContext = config.onGetValidationContext;
  }

  addChangedListener(listener: ChangedListener) {
    this.changedListeners.push(listener);
  }

  addInitialListener(listener: InitialListener<T>) {
    this.initialListeners.push(listener);
  }

  addIsValidListener(listener: IsValidListener) {
    this.isValidListeners.push(listener);
  }

  addValidationResultsListener(listener: ValidationResultsListener) {
    this.validationResultsListners.push(listener);
  }

  addValueListener(listener: ValueListener<T>) {
    this.valueListeners.push(listener);
  }

  addValidator(validator) {
    this.validators.push(validator);
  }

  calcChanged() {
    this.onCalcChanged();
    this.notifyChangedListener();
  }

  getValue() {
    return this.onGetValue();
  }

  notifyChangedListener() {
    const changed = this.onGetChanged();

    this.changedListeners.forEach((listener) => {
      listener({ changed });
    });
  }

  notifyInitialListener() {
    const initial = this.onGetInitial();

    this.initialListeners.forEach((listener) => {
      listener({ initial });
    });
  }

  notifyIsValidListeners() {
    this.isValidListeners.forEach((listener) => {
      listener({ isValid: this.isValid });
    });
  }

  notifyValidationResultsListener() {
    this.validationResultsListners.forEach((listener) => {
      listener({ validationResults: this.validationResults });
    });
  }

  notifyValueListener() {
    const value = this.onGetValue();

    this.valueListeners.forEach((listener) => {
      listener({ value });
    });
  }

  removeChangedListener(listener: ChangedListener) {
    this.changedListeners = _.remove(
      (l) => l === listener,
      this.changedListeners,
    );
  }

  removeInitialListener(listener: InitialListener<T>) {
    this.initialListeners = _.remove(
      (l) => l === listener,
      this.initialListeners,
    );
  }

  removeIsValidListener(listener: IsValidListener) {
    this.isValidListeners = _.remove(
      (l) => l === listener,
      this.isValidListeners,
    );
  }

  removeValidationResultsListener(listener: ValidationResultsListener) {
    this.validationResultsListners = _.remove(
      (l) => l === listener,
      this.validationResultsListners,
    );
  }

  removeValueListener(listener: ValueListener<T>) {
    this.valueListeners = _.remove((l) => l === listener, this.valueListeners);
  }

  runValidators() {
    this.isValid = true;
    this.validationResults = [];

    const validationResults = this.validators.reduce((results, validator) => {
      const result = validator(this.onGetValidationContext());
      if (result.isValid) {
        return results;
      }

      results.push(result);

      return results;
    }, []);

    if (validationResults.length > 0) {
      this.isValid = false;
      this.validationResults = validationResults;
    }

    this.notifyIsValidListeners();
    this.notifyValidationResultsListener();
  }

  setIsReadonly(value: boolean) {
    this.isReadonly = value;
  }

  setValidators(validators) {
    this.validators = validators;
  }

  setValue(value: T) {
    this.onSetValue(value);
    this.runValidators();
    this.notifyValueListener();
    // this.calcChanged();
  }

  setValueAsInitial(value: T) {
    this.onSetValueAsInitial(value);
    this.runValidators();
    this.notifyInitialListener();
  }
}
