export const integrationsRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state(`crm.management.integration.platforms`, {
      abstract: true,
      url: `/platforms`,
      template: '<ui-view/>',
    });
  },
];
