import Component from './base/component';
import { PlatformType } from '@proftit/crm.api.models.entities';

class PlatformController extends Component.controller {
  static $inject = ['platformsService', ...Component.controller.$inject];
  platformType: PlatformType;

  /**
   *  Fetch platforms on brand platform type change
   */
  init() {
    this.$scope.$watch('vm.platformType', () => {
      if (!this.platformType) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst
      .filter({ platformTypeId: this.platformType.id })
      .getListWithQuery();
  }

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'common.SELECT_PLATFORM';
  }
}

export default Component.config({
  controller: PlatformController,
  bindings: {
    platformType: '<',
  },
});
