import template from './new-contact-assignment-manager.component.html';

const styles = require('./new-contact-assignment-manager.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { Brand } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import BrandsService from '~/source/management/brand/services/brands';
import { AutoAssignmentPatch } from '~/source/management/automation/containers/auto-assignment/auto-assignment-patch';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import PopupService from '~/source/common/components/modal/popup.service';

type AutoAssignmentPatchAggregator = {
  patchBrand?: AutoAssignmentPatch;
  patchDesks?: AutoAssignmentPatch[];
};

type Patch = {
  id?: number;
  isAutoAssignmentEnabled?: boolean;
  desks?: AutoAssignmentPatch[];
};

export class NewContactAssigmentManagerController {
  /* bindings */

  brand: Brand;

  /* state */

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  opOpenEdit$ = new rx.Subject<void>();
  opCancelEdit$ = new rx.Subject<void>();
  opCloseEdit$ = new rx.Subject<void>();
  opSave$ = new rx.Subject<void>();
  afterSaveAction$ = new rx.Subject<void>();

  brandIn$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  isInEdit$ = this.streamIsInEdit();
  showEditActionLink$ = this.streamShowEditActionLink();
  brandAutoAssignmentChangeAction = new rx.Subject<AutoAssignmentPatch>();
  deskAutoAssignmentChangeAction = new rx.Subject<AutoAssignmentPatch>();
  brandFromSave$ = this.streamBrandFromSave();
  brandFromSelect$ = this.streamBrandFromSelect();
  brand$ = this.streamBrand();
  save$ = this.streamSave();

  blockUiId = generateBlockuiId();

  /*@ngInject */
  constructor(
    readonly PermPermissionStore: ng.permission.PermissionStore,
    readonly brandsService: () => BrandsService,
    readonly popupService: PopupService,
  ) {
    useStreams([this.save$], this.lifecycles.onDestroy$);
  }

  streamBrand() {
    const currentBrand$ = new rx.BehaviorSubject(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.brandFromSelect$,
          this.brandFromSave$,
          this.streamCurrentBrandOnCancel(currentBrand$),
        ),
      rx.tap((value) => {
        currentBrand$.next(value);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCurrentBrandOnCancel(currentBrand$: rx.Observable<Brand>) {
    return rx.pipe(
      () => this.opCancelEdit$,
      rx.withLatestFrom(currentBrand$),
      rx.map(([action, currentBrand]) => _.cloneDeep(currentBrand)),
    )(null);
  }

  streamBrandFromSelect() {
    return rx.pipe(
      () => this.brandIn$,
      rx.filter((brand) => !_.isNil(brand)),
      rx.switchMap((brand) => this.getBrand(brand)),
    )(null);
  }

  streamBrandFromSave() {
    return rx.pipe(
      () => this.afterSaveAction$,
      rx.withLatestFrom(this.brandIn$),
      rx.filter((brand) => !_.isNil(brand)),
      rx.switchMap(([a, brand]) => this.getBrand(brand)),
    )(null);
  }

  getBrand(brand: Brand) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiId })
      .embed('desks')
      .getOneWithQuery<IElementRestNg<Brand>>(brand.id)
      .then((res) => res.plain())
      .catch((e) => {});
  }

  streamAssignmentChangesOnEmptyEvents() {
    return rx.pipe(
      () => this.brand$,
      rx.map(() => {
        return {};
      }),
    )(null);
  }

  streamAssignmentChangesFromBrandAssignmentChanges(
    autoAssignmentChanges$: rx.Observable<AutoAssignmentPatchAggregator>,
  ): rx.Observable<AutoAssignmentPatchAggregator> {
    return rx.pipe(
      () => this.brandAutoAssignmentChangeAction,
      rx.withLatestFrom(autoAssignmentChanges$),
      rx.map(([actionData, changeAggregator]) => {
        const { id, isAutoAssignmentEnabled } = actionData;
        return {
          ...changeAggregator,
          patchBrand: {
            id,
            isAutoAssignmentEnabled,
          },
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAssignmentChangesFromDeskAssignmentChanges(
    autoAssignmentChanges$: rx.Observable<AutoAssignmentPatchAggregator>,
  ): rx.Observable<AutoAssignmentPatchAggregator> {
    return rx.pipe(
      () => this.deskAutoAssignmentChangeAction,
      rx.withLatestFrom(autoAssignmentChanges$),
      rx.map(([actionData, changeAggregator]) => {
        const { id, isAutoAssignmentEnabled } = actionData;
        const newDeskPatch = {
          id,
          isAutoAssignmentEnabled,
        };
        let newDeskPatchArray;
        if (changeAggregator.patchDesks) {
          const filteredArray = changeAggregator.patchDesks.filter(
            (deskPatch) => deskPatch.id !== newDeskPatch.id,
          );
          newDeskPatchArray = [...filteredArray, newDeskPatch];
        } else {
          newDeskPatchArray = [newDeskPatch];
        }
        return {
          ...changeAggregator,
          patchDesks: newDeskPatchArray,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAssignmentChanges(): rx.Observable<AutoAssignmentPatchAggregator> {
    const autoAssignmentChanges$ = new rx.BehaviorSubject<
      AutoAssignmentPatchAggregator
    >({});
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamAssignmentChangesOnEmptyEvents(),
          this.streamAssignmentChangesFromBrandAssignmentChanges(
            autoAssignmentChanges$,
          ),
          this.streamAssignmentChangesFromDeskAssignmentChanges(
            autoAssignmentChanges$,
          ),
        ),
      rx.tap((latestCalculatedChanges) => {
        autoAssignmentChanges$.next(latestCalculatedChanges);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsInEdit() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.opOpenEdit$.pipe(rx.map(() => true)),
          this.opCancelEdit$.pipe(rx.map(() => false)),
          this.opCloseEdit$.pipe(rx.map(() => false)),
          this.brandIn$.pipe(rx.map(() => false)),
        ),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  normalizeAutoAssignmentChangesForUpdate(
    autoAssignmentChanges: AutoAssignmentPatchAggregator,
  ): Patch {
    const { patchBrand, patchDesks } = autoAssignmentChanges;
    if (!patchBrand && !patchDesks) {
      return null;
    }
    let objToReturn: Patch = {};
    if (patchBrand) {
      objToReturn = { ...patchBrand };
    }
    if (patchDesks) {
      objToReturn.desks = [...patchDesks];
    }
    return objToReturn;
  }

  streamSave() {
    return rx.pipe(
      () => this.opSave$,
      rx.withLatestFrom(this.streamAssignmentChanges(), this.brandIn$),
      rx.switchMap(([saveAction, autoAssignmentChanges, selectedBrand]) => {
        const normalizedData = this.normalizeAutoAssignmentChangesForUpdate(
          autoAssignmentChanges,
        );
        if (!normalizedData) {
          return Promise.resolve();
        }
        return this.brandsService()
          .setConfig({ blockUiRef: this.blockUiId })
          .getBrandResource(selectedBrand.id)
          .embed(['desks'])
          .patchWithQuery(normalizedData)
          .catch((err) => {});
      }),
      rx.tap((value) => {
        this.opCloseEdit$.next();
        this.afterSaveAction$.next();
      }),
    )(null);
  }

  streamShowEditActionLink() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isInEdit$,
          checkCrudPermission(
            PermissionNormalized.AutoAssignments,
            this.PermPermissionStore,
          ),
        ),
      rx.map(([isInEdit, perm]) => {
        if (isInEdit) {
          return false;
        }

        if (!perm.isUpdate) {
          return false;
        }

        return true;
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  openLogsPopupTable() {
    this.popupService.open({
      component: 'prfAutoAssignmentAuditLogPopup',
      resolve: {
        brand: this.brand,
      },
    });
  }
}

export const NewContactAssigmentManagerComponent = {
  template,
  controller: NewContactAssigmentManagerController,
  bindings: {
    brand: '<',
  },
};
