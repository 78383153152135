import _ from 'underscore';
import FormFormatter from '../../../common/services/base-form-formatter';

export default class WithdrawalCreditCardFormFormatter extends FormFormatter {
  model: any;

  preFormat() {
    const withdrawals = [];

    /**
     * withdrawal should be converted from {94: {amount:50, depositId:94}, 14: {amount:500, depositId:14}}
     * to - [{amount:50, depositId:94}, 14: {amount:500, depositId:14}]
     *
     * @type {Array}
     */
    _.each(this.model, (withdrawal: any, id) => {
      // don't post unselected or empty amount
      if (withdrawal.selected && withdrawal.amount > 0) {
        // this property should not be sent to the server
        delete withdrawal.selected;

        withdrawals.push(Object.assign({}, withdrawal, { depositId: id }));
      }
    });

    return withdrawals;
  }
}
