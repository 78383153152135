import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './brand-deposit-wire-currency-list.component.html';
import { BrandCurrency } from '~/source/common/models/brand-currency';
const styles = require('./brand-deposit-wire-currency-list.component.scss');

export class WireCurrencyListController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  brandCurrencies: BrandCurrency[];
  onCurrencyClicked: (a: { brandCurrency: BrandCurrency }) => void;
  selectedBrandCurrency: BrandCurrency;
  isCurrencyInEdit: boolean;

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandDepositWireCurrencyListComponent = {
  template,
  controller: WireCurrencyListController,
  bindings: {
    brandCurrencies: '<',
    onCurrencyClicked: '&',
    selectedBrandCurrency: '<',
    isCurrencyInEdit: '<',
  },
};
