import { TemplateType } from '~/source/common/models/template-type';
import { ContentTemplateTypeCode } from '@proftit/crm.api.models.enums';

export const emailsRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.tools.emails', {
        redirectTo: 'crm.management.tools.emails.dashboard',
        url: '/emails',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.emails.dashboard', {
        url: '/dashboard',
        component: 'prfEmailsDashboard',
        redirectTo: 'crm.management.tools.emails.dashboard.systememails',
      })
      .state('crm.management.tools.emails.dashboard.systememails', {
        url: '/system-emails',
        component: 'prfSystemEmails',
      })
      .state('crm.management.tools.emails.dashboard.customemailtemplates', {
        url: '/custom-emails-templates',
        component: 'prfEmailTemplatesDashboard',
      })
      .state('crm.management.tools.emails.contenttemplate', {
        url: '/content-template',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.emails.contenttemplate.create', {
        url: '/create',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.emails.contenttemplate.create.custom', {
        url: '/custom',
        component: 'prfContentTemplatePage',
        data: {
          action: 'create',
          templateType: ContentTemplateTypeCode.Custom,
        },
      })
      .state('crm.management.tools.emails.contenttemplate.create.system', {
        url: '/system',
        component: 'prfContentTemplatePage',
        data: {
          action: 'create',
          templateType: ContentTemplateTypeCode.System,
        },
      })
      .state('crm.management.tools.emails.contenttemplate.update', {
        url: '/update',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.emails.contenttemplate.update.custom', {
        url: '/custom/{id:int}',
        component: 'prfContentTemplatePage',
        data: {
          action: 'update',
          templateType: ContentTemplateTypeCode.Custom,
        },
      })
      .state('crm.management.tools.emails.contenttemplate.update.system', {
        url: '/system/{parentId:int}/content-templates/{id:int}',
        component: 'prfContentTemplatePage',
        data: {
          action: 'update',
          templateType: ContentTemplateTypeCode.System,
        },
      })
      .state('crm.management.tools.emails.contenttemplate.copy', {
        url: '/copy',
        template: '<ui-view/>',
      })
      .state('crm.management.tools.emails.contenttemplate.copy.custom', {
        url: '/custom/{id:int}',
        component: 'prfContentTemplatePage',
        data: {
          action: 'create',
          subAction: 'copy',
          templateType: ContentTemplateTypeCode.Custom,
        },
      })
      .state('crm.management.tools.emails.contenttemplate.copy.system', {
        url: '/system/{parentId:int}/content-templates/{id:int}',
        component: 'prfContentTemplatePage',
        data: {
          action: 'create',
          subAction: 'copy',
          templateType: ContentTemplateTypeCode.System,
        },
      });
  },
];
