import * as _ from '@proftit/lodash';
import { ObjectProxyHandler } from './object-proxy-handler';

export function generateObjectProxy<T extends Record<string, any>>(params) {
  const handlerParams = {
    validators: [..._.defaultTo([], params.validators)],
  };

  const objectHandler = new ObjectProxyHandler(handlerParams);

  const handler = {
    get: (obj, key: string) => {
      if (key === 'handler') {
        return objectHandler;
      }

      if (!objectHandler.hasKey(key)) {
        return undefined;
      }

      return objectHandler.getValueFor(key);
    },

    getOwnPropertyDescriptor: (target: Object, key: string) => {
      if (!objectHandler.hasKey(key)) {
        return {
          value: undefined,
          writeble: true,
          enumerable: true,
          // https://stackoverflow.com/questions/40921884/create-dynamic-non-configurable-properties-using-proxy
          configurable: true,
        };
      }

      const value = objectHandler.getValueFor(key);

      return {
        value,
        writeble: true,
        enumerable: true,
        // https://stackoverflow.com/questions/40921884/create-dynamic-non-configurable-properties-using-proxy
        configurable: true,
      };
    },

    set: (obj, key: string, value: any) => {
      objectHandler.setValueFor(key, value);

      // Indicate success for proxy operation. False will make the proxy throw error.
      return true;
    },

    ownKeys: (target: object): (keyof T)[] => {
      const keys = objectHandler.getKeys();
      return keys;
    },
  };

  const proxy = new Proxy({}, handler as any);

  return proxy;
}
