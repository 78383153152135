import log from 'loglevel';
import type { IParseService, IScope } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { PlatformSessionInfo } from './platform-session-info';
import { directivesPriorities } from '../constants/directives-priorities';
import { ApiTrcBrandsService } from '../api-cfd-platform/api-trc-brands.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';

interface NgSelfAttrs {
  normalize(name: string): string;
  $addClass(classVal: string): void;
  $removeClass(classVal: string): void;
  $updateClass(newClasses: string, oldClasses: string): void;
  $observe(key: string, fn: Function): void;
  $set(name: string, value: string): void;
  $attr: Record<string, string>;
}

interface CurrentPlatformSessionNgAttrs extends NgSelfAttrs {
  prfCurrentPlatformSession: string;
}

export class CurrentPlatformSessionStoreServiceDirectiveController {
  lifecycles = observeComponentLifecycles(this);

  loginObsOp$ = new rx.Subject<rx.Observable<PlatformSessionInfo>>();

  logoutObsOp$ = new rx.Subject<rx.Observable<PlatformSessionInfo>>();

  sessionS = this.streamSession();

  mtServerId$ = this.streamMtServerId();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly prfApiTrcBrands: ApiTrcBrandsService,
    readonly $scope: IScope,
    readonly $parse: IParseService,
    readonly $attrs: CurrentPlatformSessionNgAttrs,
  ) {
    useStreams(
      [this.sessionS.stream$, this.mtServerId$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    const mainAttrExpFn = this.$parse(this.$attrs.prfCurrentPlatformSession);
    const newScope: any = this.$scope.$new(false, this.$scope);
    newScope.service = this;
    mainAttrExpFn(newScope);
    newScope.$destroy();
  }

  $onChanges() {}

  $onDestroy() {}

  streamSessionFromLogin() {
    return generateInProcessStream(
      this.loginObsOp$,
      () =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(rx.catchError(() => rx.obs.NEVER)),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamSessionFromLogout() {
    return generateInProcessStream(
      this.logoutObsOp$,
      () =>
        rx.pipe(
          rx.switchMap((action$) => action$),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamSession() {
    const sessionFromLoginS = this.streamSessionFromLogin();
    const sessionFromLogoutS = this.streamSessionFromLogout();

    return generateInProcessStream(
      rx.obs.merge(sessionFromLoginS.stream$, sessionFromLogoutS.stream$),
      () => rx.pipe(shareReplayRefOne()),
      [sessionFromLoginS.inProcess$],
    );
  }

  streamMtServerId() {
    return rx.pipe(
      () => this.sessionS.stream$,
      rx.switchMap((session) =>
        rx.obs.of(session).pipe(
          rx.map((session) => session.trcBrand.mtBrandServers[0].mtServerId),
          rx.catchError((e) => {
            log.debug('brand does not have mt server id', e);
            return rx.obs.of(null);
          }),
        ),
      ),
      rx.startWith(null),
      shareReplayRefOne(),
    )(null);
  }

  login(brandPlatform: BrandPlatform) {
    const action$ = rx.pipe(
      () => rx.obs.of(brandPlatform),
      rx.switchMap((brandPlatform) => {
        return this.brandsService()
          .createPlatformSession(brandPlatform.brandId, brandPlatform.id)
          .then((session) => ({ session, brandPlatform }));
      }),
      rx.map(({ session, brandPlatform }) => {
        return {
          session,
          isLoggedIn: true,
          platform: brandPlatform.platform,
          senderCompId: getSenderCompId(brandPlatform),
        };
      }),
      rx.switchMap((sessionInfo) => {
        return this.prfApiTrcBrands
          .getMyBrand(sessionInfo.session.apiUrl, sessionInfo.session.token)
          .then((trcBrand) => ({
            ...sessionInfo,
            trcBrand,
          }));
      }),
      shareReplayRefOne(),
    )(null);

    this.loginObsOp$.next(action$);
    return action$;
  }

  logout() {
    const action$ = rx.pipe(
      () => rx.obs.of(true),
      rx.map(() => ({
        platform: null,
        session: null,
        isLoggedIn: false,
        trcBrand: null,
        senderCompId: null,
      })),
      shareReplayRefOne(),
    )(null);

    this.logoutObsOp$.next(action$);
    return action$;
  }
}

function getSenderCompId(brandPlatform: BrandPlatform) {
  if (!MT4_PLATFORMS.includes(brandPlatform.platform.code)) {
    return null;
  }

  const senderCompIdValue = brandPlatform.credentials.find(
    (cred) => cred.key === 'senderCompId',
  ).value;

  return senderCompIdValue;
}

export const currentPlatformSessionStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {},
    bindToController: true,
    controller: CurrentPlatformSessionStoreServiceDirectiveController,
  };
};

currentPlatformSessionStoreServiceDirective.$inject = [] as string[];
