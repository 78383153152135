import { IScope } from 'angular';

import Component from '~/source/common/components/dropdowns/base/component';

class DepartmentSelectController extends Component.controller {
  static $inject = ['departmentsService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'management.SELECT_DEPARTMENT';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

const DepartmentSelectComponent = Component.config({
  controller: DepartmentSelectController,
});

export default DepartmentSelectComponent;
