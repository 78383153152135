import { IScope } from 'angular';
import * as _ from '@proftit/lodash';
import log from 'loglevel';

import BaseController from '~/source/common/controllers/base';
import template from './contact-info.html';
import { Customer } from '@proftit/crm.api.models.entities';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { CustomerStoreProviderController } from '~/source/contact/common/components/customer-store-provider/customer-store-provider.component';

class Controller extends BaseController {
  update: boolean;
  customer: IElementRestNg<Customer>;
  prevAttributes: IElementRestNg<Customer>;

  prfCustomerStoreProvider: CustomerStoreProviderController;

  /*@ngInject*/
  constructor(readonly $scope: IScope) {
    super();

    this.update = false;
  }

  clearMaskedPhoneNumbers(
    customer: IElementRestNg<Customer>,
  ): IElementRestNg<Customer> {
    const { phone2 } = customer;
    const customerToReturn = { ...customer };
    const maskCharacterRegex = new RegExp('(x|X)');
    if (phone2 && maskCharacterRegex.test(phone2)) {
      customerToReturn.phone2 = '';
    }
    return customerToReturn;
  }

  onEditClick() {
    // Save pre-edit state
    this.prevAttributes = { ...this.customer };
    this.customer = this.clearMaskedPhoneNumbers(this.customer);
    this.update = true;
  }

  onCancelEdit() {
    // Restore pre-edit state
    Object.assign(this.customer, this.prevAttributes);
    this.update = false;
  }

  /**
   * Normalizes the changes object and PATCH's the customer.
   *
   * If object is empty, simply log and return.
   * @param {object} changes Customer attributes to update
   * @return void
   */
  onUpdateCustomer(changes: Partial<Customer>) {
    if (_.isEmpty(changes)) {
      log.warn('Nothing to update');
      // Exit update mode
      this.update = false;
      return;
    }

    this.prfCustomerStoreProvider.customerStoreService
      .updateAllChanges(changes)
      .then((customer) => {
        // Timezone might have been updated as a side affect of a location change
        this.$scope.$emit('contact:update:success', this.customer);
        this.update = false;
      });
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
  },
  require: {
    prfCustomerStoreProvider: '^',
  },
};
