import template from './gmail-notification-center-popup.component.html';
const styles = require('./gmail-notification-center-popup.component.scss');

import ng, { IWindowService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import PopupService from '~/source/common/components/modal/popup.service';
import { StateService } from '@uirouter/core';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { CustomerCommunicationStatusCode } from '@proftit/crm.api.models.enums';

export class GmailNotificationCenterPopupController {
  CustomerCommunicationStatusCode = CustomerCommunicationStatusCode;

  shouldShowPopup: boolean;
  notifications: any[];
  shouldPinPopup: boolean;
  isFetchingNotifications: boolean;
  shouldShowNewEmailsButton: boolean;
  isReloadingNotifications: boolean;
  scrollTopActionId: number;
  onPinPopupToggle: () => void;
  onMarkNotificationAsRead: (a: { id: number }) => void;
  onNotificationsNextPage: () => void;
  onReloadNotifications: () => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  popupPinToggleAction = new rx.Subject<void>();
  onExpandNotificationAction = new rx.Subject<{ id: number }>();
  onOpenEmailCenterPopupAction = new rx.Subject<{
    filter: CustomerCommunicationStatusCode;
  }>();

  isReloadingNotifications$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'isReloadingNotifications',
  );

  notificationsBlockUi = generateBlockuiId();

  /*@ngInject */
  constructor(
    readonly popupService: PopupService,
    readonly $state: StateService,
    readonly $window: IWindowService,
    readonly blockUI: ng.blockUI.BlockUIService,
  ) {
    useStreams([this.isReloadingNotifications$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamPopupPinToggleAction(),
        this.streamIsReloadingNotifications(),
        this.streamOpenEmailCenter(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamPopupPinToggleAction() {
    return rx.pipe(
      () => this.popupPinToggleAction,
      rx.tap(() => {
        this.onPinPopupToggle();
      }),
    )(null);
  }

  openTasksPopup() {
    this.popupService.open({
      component: 'prfTasksPopup',
    });
  }

  openCustomerPageInNewTab({ $event, id, notificationId }) {
    $event.stopPropagation();
    this.onMarkNotificationAsRead({ id: notificationId });
    const url = this.$state.href('crm.contacts.view', { id });
    this.$window.open(url, '_blank');
  }

  streamIsReloadingNotifications() {
    return rx.pipe(
      () => this.isReloadingNotifications$,
      rx.tap((isReloadingNotifications) => {
        const blockUiId = this.blockUI.instances.get(this.notificationsBlockUi);
        if (isReloadingNotifications) {
          blockUiId.start();
        } else {
          blockUiId.stop();
        }
      }),
    )(null);
  }

  streamOpenEmailCenter() {
    return rx.pipe(
      () => this.onOpenEmailCenterPopupAction,
      rx.tap(({ filter }) => {
        this.popupService.open({
          component: 'prfEmailCenterPopup',
          resolve: {
            filter: () => filter,
          },
        });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const GmailNotificationCenterPopupComponent = {
  template,
  controller: GmailNotificationCenterPopupController,
  bindings: {
    shouldShowPopup: '<',
    notifications: '<',
    shouldPinPopup: '<',
    isFetchingNotifications: '<',
    shouldShowNewEmailsButton: '<',
    isReloadingNotifications: '<',
    scrollTopActionId: '<',
    onPinPopupToggle: '&',
    onMarkNotificationAsRead: '&',
    onNotificationsNextPage: '&',
    onReloadNotifications: '&',
    onClearPanel: '&',
  },
};
