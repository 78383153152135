import log from 'loglevel';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { FormControl } from '@proftit/ng1.reactive-forms';
import {
  Brand,
  BrandPlatform,
  UserSettings,
} from '@proftit/crm.api.models.entities';
import { CfdGroup, Mt4Group } from '@proftit/tradingcore.api.models.entities';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { blockUI, growl } from 'angular';

const SET_ONCE_TIMEOUT_MS = 14000;
const STEP_DELAY_MS = 100;

export function applyUserSettingsBrandPlatformAndGroup(
  blockUI: blockUI.BlockUIService,
  blockUiId: string,
  growl: growl.IGrowlService,
  growlMessages: growl.IGrowlMessagesService,
  growlId: string,
  selectedBrandF: FormControl<Brand>,
  selectedBrandPlatformF: FormControl<BrandPlatform>,
  selectedGroupF: FormControl<Mt4Group | CfdGroup>,
  userSettingsStore: { load: () => rx.Observable<UserSettings<any>> },
  brandsOptions$: rx.Observable<Brand[]>,
  brandPlatformsOptions$: rx.Observable<BrandPlatform[]>,
  groupsOptions$: rx.Observable<(CfdGroup | Mt4Group)[]>,
  doSelectGroup = true,
) {
  return rx.pipe(
    tapStartAsyncWorkInUi(blockUI, growl, growlMessages, blockUiId, growlId),
    rx.tap(() => {
      selectedBrandF.setValue(null);
      selectedBrandPlatformF.setValue(null);
      selectedGroupF.setValue(null);
    }),
    rx.switchMap(() => userSettingsStore.load()),
    rx.take(1),
    rx.switchMap((settings: any) =>
      restoreSelections(
        doSelectGroup,
        settings as any,
        selectedBrandF,
        selectedBrandPlatformF,
        selectedGroupF,
        brandsOptions$,
        brandPlatformsOptions$,
        groupsOptions$,
      ),
    ),
    rx.take(1),
    tapStopAsyncWorkInUi(blockUI, growl, growlMessages, blockUiId, growlId),
  );
}

function restoreSelections(
  doSelectGroup: boolean,
  settings: UserSettings<any>,
  selectedBrandF: FormControl<Brand>,
  selectedBrandPlatformF: FormControl<BrandPlatform>,
  selectedGroupF: FormControl<Mt4Group | CfdGroup>,
  brandsOptions$: rx.Observable<Brand[]>,
  brandPlatformsOptions$: rx.Observable<BrandPlatform[]>,
  groupsOptions$: rx.Observable<(CfdGroup | Mt4Group)[]>,
): rx.Observable<any> {
  return rx.pipe(
    () => brandsOptions$,
    rx.filter((b) => b.length > 0),
    rx.delay(STEP_DELAY_MS),
    rx.timeout(SET_ONCE_TIMEOUT_MS),
    rx.tap((brands) => {
      const selectedBrandId = _.get(['value', 'selectedBrandId'], settings);
      const foundBrand = brands.find((b) => b.id === selectedBrandId);
      if (_.isNil(foundBrand)) {
        throw new Error('shortcut set');
      }

      selectedBrandF.setValue(foundBrand);
    }),
    rx.switchMap(() => brandPlatformsOptions$),
    rx.filter((b) => b.length > 0),
    rx.delay(STEP_DELAY_MS),
    rx.timeout(SET_ONCE_TIMEOUT_MS),
    rx.tap((platforms: BrandPlatform[]) => {
      const selectedBrandPlatformId = _.get(
        ['value', 'selectedBrandPlatformId'],
        settings,
      );
      const foundPlatform = platforms.find(
        (p) => p.id === selectedBrandPlatformId,
      );
      if (_.isNil(foundPlatform)) {
        throw new Error('shortcut set');
      }

      selectedBrandPlatformF.setValue(foundPlatform);
    }),
    rx.switchMap(() => {
      if (!doSelectGroup) {
        return rx.obs.of(true);
      }

      return rx.pipe(
        () => rx.obs.of(true),
        rx.switchMap(() => groupsOptions$),
        rx.filter((b: (CfdGroup | Mt4Group)[]) => b.length > 0),
        rx.delay(STEP_DELAY_MS),
        rx.timeout(SET_ONCE_TIMEOUT_MS),
        rx.tap((groups: (CfdGroup | Mt4Group)[]) => {
          const selectedGroupId = _.get(['value', 'selectedGroupId'], settings);
          const foundGroup = groups.find((g: any) => g.id === selectedGroupId);
          if (_.isNil(foundGroup)) {
            throw new Error('shortcut set');
          }

          selectedGroupF.setValue(foundGroup);
        }),
      )(null);
    }),
    rx.catchError((e) => {
      log.warn('error sync once one way to in current user settings');
      return rx.obs.of(true);
    }),
  )(null) as any;
}
