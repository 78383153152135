import * as _ from '@proftit/lodash';

import BaseController from '../../../controllers/base';
import template from './filter-popover-simple.html';

class FilterPopoverSimpleController extends BaseController {
  onFilterClose: () => void;

  // init filter model
  model = null;
  attributes: { 'is-auto-open': boolean; multiple?: boolean } = {
    // default attributes for all filter popovers
    'is-auto-open': true,
  };
  exclude = false;
  popoverSettings;
  filtersSettings;
  filterName;

  /**
   * Called by parent controller when the 'filterName' binding changes
   */
  onFilterNameChange() {
    // selected filter settings
    this.popoverSettings = this.filtersSettings[this.filterName].popover;

    if (!this.popoverSettings) {
      throw new Error(`No popover settings for filter '${this.filterName}'`);
    }

    if (this.popoverSettings.componentAttributes) {
      _.eachEs(this.popoverSettings.componentAttributes, (attr, key) => {
        /**
         * stringify any attribute, as they are passed to the template and therefore must be strings.
         * for the same reason, the strings cannot contain double quotes. replace them with single.
         */
        this.attributes[key] = JSON.stringify(attr).replace(/"/g, "'");
      });
    }
  }

  /**
   * Apply selected filter by emitting a 'filter:add' event with it.
   */
  applyFilter() {
    if (!this.model) {
      return;
    }

    this.onFilterClose();

    // If the filter is 'restangularized' - normalize it by using 'plain()' method of restangular
    const toPlain = (item) => (item.restangularized ? item.plain() : item);

    const model = Array.isArray(this.model)
      ? this.model.map(toPlain)
      : toPlain(this.model);

    // Normalize the selected models to a valid filter
    this.$scope.$emit('table:filter:add', {
      [this.filterName]: this.exclude ? { exclude: model } : model,
    });
  }
}

FilterPopoverSimpleController.$inject = ['$scope', 'filtersSettings'];

export default {
  template,
  controllerAs: 'vm',
  controller: FilterPopoverSimpleController,
  bindings: {
    onFilterClose: '&',
    brand: '<',
    brandId: '<',
    platformId: '<',
    platformConnection: '<',
    filterName: '<',
  },
};

export { FilterPopoverSimpleController };
