import angular from 'angular';
import ContactActivity from './contact-activity/contact-activity.component';
import MarketingInfoComponent from './marketing-info/marketing-info.component';
import NewsFeedComponent from './news-feed/news-feed.component';
import ProfileComponent from './profile/profile.component';
import LoginAsComponent from './login-as/login-as.component';
import TilesComponent from './tile-list.component';
import { SiteTrackingActivityManagerComponent } from '~/source/contact/contact-page/tile-list/site-tracking-activity/site-tracking-activity-manager/site-tracking-activity-manager.component';
import { SiteTrackingActivityRowComponent } from '~/source/contact/contact-page/tile-list/site-tracking-activity/site-tracking-activity-row/site-tracking-activity-row.component';

export default angular
  .module('contact.contactPage.tiles', [])
  .component('prfContactPageTiles', TilesComponent)
  .component('prfContactActivity', ContactActivity)
  .component('prfContactMarketingInfo', MarketingInfoComponent)
  .component('prfContactNewsFeed', NewsFeedComponent)
  .component('prfContactProfile', ProfileComponent)
  .component(
    'prfSiteTrackingActivityManager',
    SiteTrackingActivityManagerComponent,
  )
  .component('prfSiteTrackingActivityRow', SiteTrackingActivityRowComponent)
  .component('prfContactLoginAs', LoginAsComponent).name;
