import log from 'loglevel';

function routerRunConf(
  $transitions,
  tokensService,
  $uibModalStack,
  growlMessages,
  growlReference,
  $state,
  appConfig,
) {
  $state.defaultErrorHandler((error) => {
    if (error.type !== 2) {
      // ignore "The transition has been superseded" errors
      // without this, error will go silently without any trace
      log.error(error);
      $state.go('error.general');
      // Close all open dialogs
      $uibModalStack.dismissAll();
    }
  });

  // If user is trying to reach login page but he is already logged in, redirect him to the default state
  $transitions.onStart({ to: 'auth.login' }, () => {
    if (tokensService.isLoggedIn()) {
      $state.go(appConfig.defaultState);
    }
  });

  // On any state change, close all the global growl messages
  $transitions.onSuccess({}, () => {
    growlMessages.destroyAllMessages(growlReference.APPLICATION);
  });
}

routerRunConf.$inject = [
  '$transitions',
  'tokensService',
  '$uibModalStack',
  'growlMessages',
  'growlReference',
  '$state',
  'appConfig',
  '$trace',
];

export default routerRunConf;
