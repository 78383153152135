import Component from '~/source/common/components/dropdowns/base/component';

class MigratedStatusesController extends Component.controller {
  static $inject = ['migratedStatusesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.SELECT_STATUS';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'contact.migratedStatuses';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'code';
  }

  get nullableElement() {
    return {
      id: null,
      code: 'null',
      nullable: true,
    };
  }
}

export default Component.config({ controller: MigratedStatusesController });
