import { SocketService } from '~/source/common/services/socket';

/**
 * Build channel name for cfd account marginLevel subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildCfdAccountMarginLevelChannel(accountSyncId: number) {
  return `{customer.${accountSyncId}}.marginLevel`;
}

export class CfdPlatformAccountMarginLevelSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  token: string;
  platformStreamerUrl: string;

  get socketRegistryKey() {
    return 'cfdPlatformSocket';
  }

  get channelRoot() {
    return null;
  }

  getStreamerUrl() {
    return this.platformStreamerUrl;
  }

  buildSocketQuery() {
    return `token=${this.token}`;
  }

  setToken(token: string) {
    this.token = token;
  }

  setStreamerUrl(url: string) {
    this.platformStreamerUrl = `${url}/`;
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }
}
