import * as _ from '@proftit/lodash';

import template from './password-form.html';

class Controller {
  passwordValidation: string;
  model: any;

  $onInit() {
    this.passwordValidation = _.defaultTo(
      'minlength=5,maxlength=15',
      this.passwordValidation,
    );
  }

  /**
   * When the form is destroy, cleanup: remove the password field from the model.
   */
  $onDestroy() {
    delete this.model.password;
  }
}

const PasswordFormComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    passwordValidation: '<',
  },
};

export default PasswordFormComponent;
