import template from './auto-assignment-list.component.html';

const styles = require('./auto-assignment-list.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { AutoAssignmentPatch } from '~/source/management/automation/containers/auto-assignment/auto-assignment-patch';
import { Brand } from '@proftit/crm.api.models.entities';

export class AutoAssignmentListController {
  isInEdit: boolean;
  brand: Brand;
  onBrandAutoAssignmentChange: (objToPass: AutoAssignmentPatch) => void;
  onDeskAutoAssignmentChange: (objToPass: AutoAssignmentPatch) => void;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');
  isBrandAutoAssignmentEnabledFormControl = new FormControl<boolean>(null);

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.brand$,
        this.streamInitIsBrandAutoAssignmentEnabledFormControl(),
        this.streamEmitIsBrandAutoAssignmentEnabledChange(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamInitIsBrandAutoAssignmentEnabledFormControl() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((brand) => !_.isNil(brand)),
      rx.tap((brand) => {
        this.isBrandAutoAssignmentEnabledFormControl.setValueAsFirst(
          brand.isAutoAssignmentEnabled,
        );
      }),
    )(null);
  }

  streamEmitIsBrandAutoAssignmentEnabledChange() {
    return rx.pipe(
      () =>
        this.isBrandAutoAssignmentEnabledFormControl.value$.pipe(
          rx.distinctUntilChanged(),
        ),
      rx.withLatestFrom(
        this.isBrandAutoAssignmentEnabledFormControl.isAtFirstValue$,
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.tap(([isAutoAssignmentEnabled]) => {
        this.onBrandAutoAssignmentChange({
          isAutoAssignmentEnabled,
          id: this.brand.id,
        } as any);
      }),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AutoAssignmentListComponent = {
  template,
  controller: AutoAssignmentListController,
  bindings: {
    brand: '<',
    isInEdit: '<',
    onBrandAutoAssignmentChange: '&',
    onDeskAutoAssignmentChange: '&',
  },
};
