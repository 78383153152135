// in property DisplayContext in filters use the value not the key i.e. lower case (displayContexts: ['portfolioMt4Table'])

export enum DisplayContext {
  ContactMain = 'contactMain',
  ContactExpand = 'contactExpand',
  PortfolioMain = 'portfolioMain',
  PortfolioExpand = 'portfolioExpand',
  PortfolioCfdTable = 'portfolioCfdTable',
  PortfolioMt4Table = 'portfolioMt4Table',
}
