import { calcAccountTypeChannelPart } from './calc-account-type-channel-part';

/**
 * build channel for subscription
 * @param accountType - forex / binary
 * @param id - account id
 * @return channel name
 */
export function buildCrmAccountChannelName(
  userId: number,
  accountType: string,
  accountId: number,
) {
  const accountTypeChannelPart = calcAccountTypeChannelPart(accountType);
  return `user.${userId}.${accountTypeChannelPart}.${accountId}`;
}
