import * as _ from '@proftit/lodash';
import ModalOpenState from './modal-open-state';

/**
 * Represents the public state setters for the ModalInfo.
 */
export interface IModalInfo {
  openState: ModalOpenState;
}

/**
 * Modal info
 */
export class ModalInfo {
  constructor(public openState: ModalOpenState) {}

  /**
   * Factory function to generate ModalInfo with defaults for state.
   *
   * @param {IModalInfo} [paramsP] - parameters to the modal to override.
   * @returns {ModalInfo} newly created modal instance.
   */
  static create(params: Partial<IModalInfo> = {}): ModalInfo {
    const modal = new ModalInfo(
      _.defaultTo(ModalOpenState.Open)(params.openState),
    );
    return modal;
  }
}

export default ModalInfo;
