import * as _ from '@proftit/lodash';

interface ScriptInfo {
  name: string;
  src: string;
}

interface ScriptInfoStatus {
  scriptInfo: ScriptInfo;
  loaded: boolean;
}

const BASE_SCRIPTS: ScriptInfo[] = [];

function initScriptIntoStatus(scriptInfo: ScriptInfo) {
  return {
    scriptInfo,
    loaded: false,
  };
}

export class ScriptsService {
  scripts: { [key: string]: ScriptInfoStatus } = _.keyBy(
    (x) => x.scriptInfo.name,
    BASE_SCRIPTS.map((scriptInfo) => initScriptIntoStatus(scriptInfo)),
  );

  load(...scripts: string[]) {
    const loadPromises = scripts.map((scriptName) =>
      this.loadScript(scriptName),
    );
    return Promise.all(loadPromises);
  }

  loadScript(scriptName: string) {
    const status = this.scripts[scriptName];

    if (_.isNil(status)) {
      throw new Error('Not allowed not load non defined script');
    }

    return new Promise((resolve, reject) => {
      if (status.loaded) {
        resolve({ script: scriptName, loaded: true, status: 'Already Loaded' });
        return;
      }

      const script = this.createScript(status, resolve, reject);
      this.injectScript(script);
    });
  }

  createScript(scriptInfoStatus: ScriptInfoStatus, resolve, reject) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptInfoStatus.scriptInfo.src;

    script.onerror = (error: any) =>
      resolve({
        script: scriptInfoStatus.scriptInfo.name,
        loaded: false,
        status: 'Loaded',
      });

    // IE
    if ((script as any).readyState) {
      (script as any).onreadystatechange = () => {
        if (['loaded', 'complete'].includes((script as any).readyState)) {
          (script as any).onreadystatechange = null;
          this.scripts[scriptInfoStatus.scriptInfo.name] = {
            ...scriptInfoStatus,
            loaded: true,
          };
          resolve({
            script: scriptInfoStatus.scriptInfo.name,
            loaded: true,
            status: 'Loaded',
          });
          return;
        }
      };
      return script;
    }

    script.onload = () => {
      this.scripts[scriptInfoStatus.scriptInfo.name] = {
        ...scriptInfoStatus,
        loaded: true,
      };

      resolve({
        script: scriptInfoStatus.scriptInfo.name,
        loaded: true,
        status: 'Loaded',
      });
    };

    return script;
  }

  injectScript(script) {
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
