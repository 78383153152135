import ng, { IHttpService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './symbols-exposure-table.component.html';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import RestService from '~/source/common/services/rest';
import { symbolsExposureCols } from '~/source/exposure/components/symbols-exposure-table/symbols-exposure-table-cols';
import { symbolsExposureTableSettings } from '~/source/exposure/components/symbols-exposure-table/symbols-exposure-table-settings';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { SocketService } from '~/source/common/services/socket';
import { CfdPlatformSocketService } from '~/source/management/integrations/risk-manager/services/cfd-platform-socket.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { appendResource, appendResourceId } from '@proftit/request-client';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { PlatfromAssetsService } from '~/source/management/integrations/risk-manager/services/platform-assets.service';
import {
  PlatformExposureSymbolsService,
  SYMBOLS_RESOURCE_PATH,
} from '~/source/exposure/services/platform-exposure-symbols.service';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import UserSettings from '~/source/common/services/user-settings';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { generateUuid } from '@proftit/general-utilities';
const styles = require('./symbols-exposure-table.component.scss');

const GLOBAL_GROWL_ID = 'restService';
const SYMBOLS_EXPOSURE_TABLE_PAGE_SIZE_KEY = 'symbolsExposureDashboardPageSize';

export type ExposureSymbol = {
  assetId: number;
  symbol: string;
  totalPositions: number;
  netPositionSize: number;
  netExposure: number;
  openPnl: number;
  averagePriceNetPosition: number;
  marketPrice: number;
  longPositionsOpenVolume: number;
  longPositionsLeveragedOpenVolume: number;
  shortPositionsOpenVolume: number;
  shortPositionsLeveragedOpenVolume: number;
  openPositionsCount: number;
  tradersCount: number;
};

export class SymbolsExposureTableController extends TableLiveController {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'prfCfdPlatformSocketService',
    'prfPlatformExposureSymbolsService',
    'userSettingsService',
    'PermPermissionStore',
    ...TableLiveController.$inject,
  ];

  $http: IHttpService;
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  prfPlatformExposureSymbolsService: PlatformExposureSymbolsService;
  userSettingsService: UserSettings;
  PermPermissionStore: ng.permission.PermissionStore;
  userSettingsInstance: any;
  exportAsCsvBlockUi = generateUuid();

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  // injections
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfCfdPlatformSocketService: () => CfdPlatformSocketService;

  tableColumns;
  socketServiceInstance: CfdPlatformSocketService;

  exportTableAsCsvAction = new rx.Subject<void>();

  dataService$ = new rx.BehaviorSubject<CfdMongoRestAdapter>(null);
  trcBrandRef;
  symbols;

  tblNumOfRows: { count: number };

  /* @ngInject */
  constructor(...args) {
    super(...args);

    this.tableColumns = symbolsExposureCols;
  }

  $onInit() {
    super.$onInit();
    this.socketServiceInstance = this.prfCfdPlatformSocketService();

    useStreams(
      [
        this.streamInitDataServiceInstance(),
        this.streamInitTable(),
        this.streamExportTableAsCsv(),
      ],
      this.lifecycles.onDestroy$,
    );

    this.tblNumOfRows = {
      count: symbolsExposureTableSettings.ngTable.parameters.count,
    };
  }

  fetchFn(): RestService {
    return this.dataServiceInstance.setConfig({
      blockUiRef: this.blockUiKey,
    });
  }

  streamExportTableAsCsv() {
    return rx.pipe(
      () => this.exportTableAsCsvAction,
      rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.switchMap(([a, sessionInfo]) => {
        return this.prfPlatformExposureSymbolsService.exportSymbolsReport(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      shareReplayRefOne(),
    )(null);
  }

  parseLoadedData(data: any[]): any[] {
    data.forEach((item) => {
      item.id = item.assetId;
    });
    this.symbols = data;
    return data;
  }

  setNumberOfRows(numOfRows): void {
    super.setNumberOfRows(numOfRows.count);
    // save to server
    this.userSettingsService.setSettingValue(
      this.userSettingsInstance.id,
      numOfRows.count,
    );
  }

  streamInitDataServiceInstance() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((x) => !_.isNil(x)),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((sessionInfo) => {
        this.trcBrandRef = sessionInfo.trcBrand;
        this.socketServiceInstance.setToken(sessionInfo.session.token);
        this.socketServiceInstance.setStreamerUrl(
          sessionInfo.session.streamerUrl,
        );
      }),
      rx.map((sessionInfo) => {
        let req = this.prfPlatformExposureSymbolsService.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
        req = appendResource(SYMBOLS_RESOURCE_PATH, req);

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
        this.dataService$.next(instance);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInitTable() {
    return rx.pipe(
      () => this.dataService$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap(() => {
        return this.userSettingsService
          .setConfig({ blockUiRef: this.blockUiKey })
          .getSetting(SYMBOLS_EXPOSURE_TABLE_PAGE_SIZE_KEY, 10)
          .then((res) => {
            this.userSettingsInstance = res;
            this.tblNumOfRows = Object.assign(
              {},
              {
                count: res.value,
                id: numRowsOptions.indexOf(res.value),
                name: res.value,
              },
            );
            this.initTable(this.tblNumOfRows.count);
            return res;
          });
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {
    super.$onDestroy();
  }

  $onChanges() {}

  buildChannel(symbolId: number): string {
    return `{brand.${this.trcBrandRef.id}}.exposure.symbol.${symbolId}`;
  }

  get socketService(): SocketService {
    return this.socketServiceInstance;
  }

  get tableKey() {
    return 'symbols-exposure-table';
  }

  get ngTableSettings() {
    return symbolsExposureTableSettings.ngTable;
  }

  get entitiesContainer(): Restangular.ICollection {
    return this.symbols;
  }

  get liveEntitiesVarName(): string {
    return 'vm.symbols';
  }

  findEntityToUpdateFromStreamer(entities, newData) {
    return entities.find(({ id }) => id === newData.assetId);
  }
}

export const SymbolsExposureTableComponent = {
  template,
  controller: SymbolsExposureTableController,
  controllerAs: 'vm',
  bindings: {},
  require: {
    prfCurrentPlatformSession: '^',
  },
};
