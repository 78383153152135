import template from './ewallet-providers-currency-renderer.component.html';
const styles = require('./ewallet-providers-currency-renderer.component.scss');
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';

export class RendererController {
  styles = styles;
  currency: any;
  reloadProviders: any;
  lifecycles = observeComponentLifecycles(this);
  blockUiRef = generateBlockuiId();
  unsub$ = new rx.Subject<void>();
  ewalletSelectionComparator = (itemA, itemB) =>
    !_.isNil(itemA) && !_.isNil(itemB) && itemA.id === itemB.id;

  /*@ngInject */
  constructor(readonly ewalletsService: EwalletsService) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const EwalletProvidersCurrenciesRendererComponent = {
  template,
  controller: RendererController,
  bindings: {
    onChange: '&',
    reloadProviders: '&',
    currency: '=',
    ewalletsList: '<',
    isEdit: '<',
  },
};
