import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Brand, ContentTemplate } from '@proftit/crm.api.models.entities';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import template from './content-template-general-form.component.html';
import {
  isNotNilFormValidator,
  isStringNotEmptyOrOtherNotNilFormValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { isUniqueAsLanguageForTemplateToBrandSystemTrigger } from './is-unique-as-language-for-tempalte-to-brand-system-trigger';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import { SubCrudOperation } from '~/source/common/models/sub-crud-operation';
import { ContentTemplateTypeCode } from '@proftit/crm.api.models.enums';

const styles = require('./content-template-general-form.component.scss');

export class ContentTemplateGeneralFormController {
  onChange: (a: { change }) => void;
  onIsValid: (a: { isValid: boolean }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<ContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  action$ = observeShareCompChange<CrudOpeartion>(
    this.lifecycles.onChanges$,
    'action',
  );

  subAction$ = observeShareCompChange<SubCrudOperation>(
    this.lifecycles.onChanges$,
    'subAction',
  );

  form$ = this.streamForm();

  /*@ngInject */
  constructor(
    readonly prfSystemEmailTemplatesService: () => SystemEmailTemplatesService,
  ) {
    useStreams(
      [
        this.model$,
        this.action$,
        this.subAction$,
        this.form$.pipe(rx.switchMap((f) => f.value$)),
        this.streamNotifyChange(),
        this.streamNotifyIsValid(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.model$, this.action$, this.subAction$),
      rx.map(([model, action, subAction]) =>
        modelToForm(
          model,
          action,
          subAction,
          this.prfSystemEmailTemplatesService(),
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) =>
        rx.obs.combineLatest(form.value$, form.isAtFirstValue$),
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.tap(([change, a]) => this.onChange({ change })),
    )(null);
  }

  streamNotifyIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onIsValid({ isValid })),
    )(null);
  }
}

function modelToForm(
  model: ContentTemplate,
  action: CrudOpeartion,
  subAction: SubCrudOperation,
  prfSystemEmailTemplatesService: SystemEmailTemplatesService,
) {
  const languageValidatiors = _.flow([
    (vs) => [...vs, isNotNilFormValidator],
    (vs) => {
      if (
        model.type === ContentTemplateTypeCode.System &&
        subAction === SubCrudOperation.Copy
      ) {
        const validator = isUniqueAsLanguageForTemplateToBrandSystemTrigger(
          (model as any).system.id,
          prfSystemEmailTemplatesService,
        ) as any;

        return [...vs, validator];
      }

      return vs;
    },
  ])([]);

  return new FormGroup({
    name: new FormControl(model.name, {
      validators: [isStringNotEmptyOrOtherNotNilFormValidator],
    }),
    isActive: new FormControl(model.isActive),
    subject: new FormControl(model.subject, {
      validators: [isStringNotEmptyOrOtherNotNilFormValidator],
    }),
    language: new FormControl(model.language, {
      validators: languageValidatiors,
    }),
  } as any);
}

export const ContentTemplateGeneralFormComponent = {
  template,
  controller: ContentTemplateGeneralFormController,
  bindings: {
    model: '<',
    action: '<',
    subAction: '<',
    onChange: '&',
    onIsValid: '&',
  },
};
