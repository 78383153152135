import { createCurrenciesCredentialsGenesis } from '~/source/common/utilities/create-currencies-credentials-genesis';

/**
 * create dynamic fields
 *
 * @param {Array} newFields
 * @param {Object} clearingCompanyConnection
 */
export function setDynamicFieldsForClearingCompanyConnection(
  newFields,
  currencies,
) {
  const listFields = newFields;
  return listFields.map((name) => {
    if (name === 'tokens') {
      return {
        key: name,
        value: createCurrenciesCredentialsGenesis(currencies),
      };
    }
    return {
      key: name,
      value: '',
    };
  });
}

export default setDynamicFieldsForClearingCompanyConnection;
