import ng from 'angular';

import CashierListComponent from './cashier-list/cashier-list.component';
import CashierEditComponent from './cashier-edit/cashier-edit.component';
import cashierSelect from './cashiers-select.component';
import settings from './cashier-settings.json';
import cashiersService from './cashiers.service';

export default ng
  .module('crm.management.cashier', [])

  // todoOld: <any> is a hack. getting typing error
  .component('prfCashierList', <any>CashierListComponent)
  .component('prfCashierEdit', <any>CashierEditComponent)
  .service('cashierService', <any>cashiersService)
  .component('prfCashierSelect', <any>cashierSelect)

  .constant('cashierModuleSettings', settings)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('crm.management.integration.clearing.cashier', {
          url: '/cashier',
          template: '<ui-view/>',
          redirectTo: 'crm.management.integration.clearing.cashier.list',
        })
        .state('crm.management.integration.clearing.cashier.list', {
          url: '/list',
          component: 'prfCashierList',
          data: {
            permissions: {
              only: ['management.brands.cashier'],
              redirectTo: 'error.unauthorized',
            },
          },
        })
        .state('crm.management.integration.clearing.cashier.edit', {
          url: '/edit/:id',
          component: 'prfCashierEdit',
          data: {
            permissions: {
              only: ['management.brands.cashier_U'],
              redirectTo: 'error.unauthorized',
            },
          },
        });
    },
  ]).name;
