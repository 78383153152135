import template from './tile-list.html';

class TileList {
  constructor(readonly appConfig: any) {}
}

export default {
  template,
  controller: TileList,
  bindings: {
    brand: '<',
    customer: '<',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfCustomerStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
