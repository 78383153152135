import { PermissionNormalized } from '../models/permission-structure';

/**
 * Get permission syntax variation for delete.
 *
 * @param permission
 * @return permission name for create
 */
export function permissionDeleteFor(permission: PermissionNormalized) {
  return `${permission}_D`;
}
