import {
  OutputMessageCode,
  MessageCode,
} from '@proftit/crm.client.worker.shared.general.messages';
import * as rx from '@proftit/rxjs';

export const WORKER_ACK_TIME = 10000;
export enum EmissionType {
  ACK = 'ACK',
  OUTPUT = 'OUTPUT',
}
export interface WorkerEmission {
  msg: MessageEvent;
  emissionType: EmissionType;
}

export function registerForWorkerEmission(
  workerObservaableCode: MessageCode,
  outputEmissionCode: OutputMessageCode,
  heartbeatCode: MessageCode,
  workerMessage$: rx.Observable<MessageEvent>,
  worker$: rx.Observable<SharedWorker>,
): rx.Observable<WorkerEmission> {
  const msg$ = rx.pipe(
    () => workerMessage$,
    rx.filter((msg) => msg.data.type === outputEmissionCode),
  )(null);

  const register$ = rx.pipe(
    () => rx.obs.from([true]),
    rx.withLatestFrom(worker$),
    rx.tap(([a, worker]) => {
      worker.port.postMessage({
        type: workerObservaableCode,
        payload: {},
      });
    }),
    rx.switchMap(() => rx.obs.timer(WORKER_ACK_TIME, WORKER_ACK_TIME)),
    rx.withLatestFrom(worker$),
    rx.tap(([count, worker]) => {
      worker.port.postMessage({
        type: heartbeatCode,
        payload: {},
      });
    }),
  )(null);

  return rx.pipe(() =>
    rx.obs.merge(
      msg$.pipe(rx.map((msg) => ({ msg, emissionType: EmissionType.OUTPUT }))),
      register$.pipe(
        rx.map((a) => ({ emissionType: EmissionType.ACK, msg: null })),
      ),
    ),
  )(null);
}
