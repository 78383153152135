import BaseController from '~/source/common/controllers/base';
import template from './clearing-company-credentials-editor.html';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import useStream from '~/source/common/utilities/use-stream';
import { createCurrenciesCredentialsGenesis } from '~/source/common/utilities/create-currencies-credentials-genesis';

class Controller extends BaseController {
  generalCredentials: { key: string; value: string }[];
  currencyCredentials: { [key: string]: { [key: string]: string } } = {};
  credentials$: rx.BehaviorSubject<
    { key: string; value: any }[]
  > = new rx.BehaviorSubject([]);
  currencies$: rx.BehaviorSubject<string[]> = new rx.BehaviorSubject([]);
  unsub$ = new rx.Subject<void>();
  opCredentialsChange$ = new rx.Subject<void>();
  onCredentialsUpdate: Function;

  constructor() {
    super();
  }

  $onInit() {
    useStream(this.streamGeneralCredentials(), this.unsub$);
    useStream(this.streamCurrencyCredentials(), this.unsub$);
    useStream(this.streamEmitCredentials(), this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   *  on change credentials
   *  emit credentials$ next
   *  @returns {void}
   */
  onCredentialsChange(credentials) {
    this.credentials$.next(credentials);
  }

  /**
   *  on change currencies
   *  emit currencies$ next
   *  @returns {void}
   */
  onCurrenciesChange(currencies) {
    this.currencies$.next(currencies);
  }

  /**
   * Create stream for generalCredentials.
   *
   * @return {Observable} GeneralCredentials stream.
   */
  streamGeneralCredentials() {
    return rx.pipe(
      () => this.credentials$,
      rx.map((credentials) => {
        if (credentials) {
          return credentials.filter((obj) => obj.key !== 'tokens');
        }
        return [];
      }),
      rx.tap(
        (generalCredentials) => (this.generalCredentials = generalCredentials),
      ),
    )(null);
  }

  /**
   * Create stream for CurrencyCredentials.
   *
   * @return {Observable} CurrencyCredentials stream.
   */
  streamCurrencyCredentials() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.credentials$.pipe(
            rx.map((credentials) => {
              if (credentials) {
                return credentials.find((obj) => obj.key === 'tokens');
              }
            }),
            rx.map((credentialsToken) =>
              credentialsToken ? credentialsToken.value : null,
            ),
          ),
          this.currencies$.pipe(
            rx.map((currencies) => {
              if (currencies) {
                return createCurrenciesCredentialsGenesis(currencies);
              }
              return {};
            }),
          ),
        ),
      rx.map(([tokens, emptyCurrenciesCredentials]) => {
        if (_.isNil(tokens)) {
          // if no currencies on brand
          return null;
        }
        return _.mapValuesEs(
          emptyCurrenciesCredentials,
          (currencyCredentials, currency) => {
            if (tokens[currency]) {
              return tokens[currency];
            }
            return currencyCredentials;
          },
        );
      }),
      rx.tap((finalCred) => (this.currencyCredentials = finalCred)),
    )(null);
  }

  /**
   * Create stream for credentials.
   *
   * @return {Observable} credentials stream.
   */
  streamEmitCredentials() {
    return rx.pipe(
      () => this.opCredentialsChange$,
      rx.map(() => {
        if (!this.currencyCredentials) {
          return this.generalCredentials;
        }
        return [
          ...this.generalCredentials,
          { key: 'tokens', value: this.currencyCredentials },
        ];
      }),
      rx.tap((newCredentials) => this.onCredentialsUpdate({ newCredentials })),
    )(null);
  }
}

const ClearingCompanyCredentialsEditorComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    credentials: '<',
    currencies: '<',
    onCredentialsUpdate: '&',
  },
};

export default ClearingCompanyCredentialsEditorComponent;
