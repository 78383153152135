import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import PopupService from '~/source/common/components/modal/popup.service';
import ng from 'angular';
import { ContactsImportSocketService } from '~/source/common/services/contacts-import-socket-service';
import { ContactsImportStoreService } from '~/source/common/store-services/contacts-import-store.service';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';

interface PopupState {
  instance: ng.ui.bootstrap.IModalInstanceService;
  isOpen: boolean;
}

export class NoticePopupService {
  unsub$ = new rx.Subject<void>();
  openPopupOp$ = new rx.Subject<void>();
  closePopupOp$ = new rx.Subject<void>();
  popupState$ = this.streamPopupState();

  /* @ngInject */
  constructor(
    readonly popupService: PopupService,
    readonly prfContactsImportStoreService: ContactsImportStoreService,
    readonly prfCurrentUserStore: CurrentUserStoreService,
  ) {
    useStreams([this.popupState$], this.unsub$);
  }

  openPopupComponent() {
    return this.popupService.open({
      component: 'prfNoticePopupList',
      openedClass: 'notice-wrapper',
      shouldCloseOnRouteChange: false,
    });
  }

  streamPopupState() {
    const popupStateSubject = new rx.BehaviorSubject<PopupState>({
      instance: null,
      isOpen: false,
    });
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPopupStateClose(popupStateSubject),
          this.streamPopupStateOpen(popupStateSubject),
        ),
      rx.tap((popupState) => popupStateSubject.next(popupState)),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateCloseFromAction(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => this.closePopupOp$,
      rx.withLatestFrom(popupState$),
      rx.filter(([a, popupState]) => popupState.instance && popupState.isOpen),
      rx.map(([a, popupState]) => {
        popupState.instance.close();

        return {
          instance: null,
          isOpen: false,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateCloseFromLogout(popupState$: rx.Observable<PopupState>) {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.filter((user) => _.isNil(user)),
      rx.withLatestFrom(popupState$),
      rx.filter(([a, popupState]) => popupState.instance && popupState.isOpen),
      rx.map(([a, popupState]) => {
        popupState.instance.close();

        return {
          instance: null,
          isOpen: false,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateClose(popupState$: rx.Observable<PopupState>) {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPopupStateCloseFromAction(popupState$),
          this.streamPopupStateCloseFromLogout(popupState$),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateOpen(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.prfContactsImportStoreService.whenHasActiveContactsImports$,
          this.openPopupOp$,
        ),
      rx.withLatestFrom(popupState$),
      rx.filter(([a, popupState]) => !popupState.isOpen),
      rx.map(() => {
        const instance = this.openPopupComponent();

        return {
          instance,
          isOpen: true,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  closePopup() {
    this.closePopupOp$.next();
  }
}
