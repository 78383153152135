import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';

import template from './radio-group.component.html';
import * as rx from '@proftit/rxjs';

export interface InputOption {
  label: string;
  value: any;
}

export interface Option {
  label: string;
  value: string;
  source: any;
}

class Controller extends BaseController {
  /*
   * bindings
   */

  name: string;
  onSelect: (x: { value: any }) => void;

  /*
   * locals
   */

  /**
   * Local state - used for notifying observables chains that the component is
   * destroyed and all subscriptions should be disposed of. Each subscription chain
   * that starts with `takeUntil($this.unsub$)` will be shortcuted and exited.
   */
  unsub$ = new rx.Subject<void>();

  inputOptions$ = new rx.BehaviorSubject<InputOption[]>([]);
  options$ = new rx.BehaviorSubject<Option[]>([]);
  radioModel$ = new rx.BehaviorSubject<string>(null);
  selectedValue$ = new rx.BehaviorSubject<any>(null);

  inputName: string;

  /**
   * Setter-Getter to represent the model for the radio. Instead of a regular varialbe,
   * this push the change to a reactive subject variable.
   * In this way we capture the changes event done to the radio and propage those
   * changes to a reactive stream. We then can use the stream to further calculate
   * other changes rely on it.
   */
  set radioModel(val: string) {
    this.radioModel$.next(val);
  }
  get radioModel() {
    return this.radioModel$.value;
  }

  /*
   * Lifecycles
   */

  constructor() {
    super();
  }

  $onInit() {
    this.setChainOptionsGeneration();
    this.setChainRadioModelSetting();
    this.setChainModelChangeNotify();

    this.inputName = this.name ? this.name : _.uniqueId(`prf-radio-id-`);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onOptionsChange(curr) {
    this.inputOptions$.next(curr);
  }

  onSelectedValueChange(curr) {
    this.selectedValue$.next(curr);
  }

  /*
   * Methods
   */

  /**
   * Setup and subscribe to the options generation reactive chain.
   *
   * The chain calculate the radio options ui structure from the input options sent
   * from outside. Each time the input options is updated from outside, the radio
   * options structure are updated as well.
   *
   * @example - RadioOption calculated
   *   [{label: 'option1', value: {id:1, name: 'value1'}] =>
   *     [{label: 'option1', value: 0, source: {id:1, name: 'value1'}]
   *
   * @return {void}
   */
  setChainOptionsGeneration() {
    rx.pipe(
      () => this.inputOptions$,
      rx.takeUntil(this.unsub$),
      rx.map((inputOptions) => _.defaultTo([], inputOptions)),
      rx.map((inputOptions) =>
        inputOptions.map((opt, idx) => ({
          label: opt.label,
          value: `${idx}`,
          source: opt,
        })),
      ),
      rx.tap((options) => this.options$.next(options)),
    )(null).subscribe();
  }

  /**
   * Setup and subscribe to the ui radio model calculation.
   *
   * The model is effected by the input of selectedValue, and the input
   * of options. When either one of them is changes, the ui radio model is
   * recaluldated.
   *
   * @return {void}
   */
  setChainRadioModelSetting() {
    rx.pipe(
      () => rx.obs.combineLatest(this.selectedValue$, this.options$),
      rx.takeUntil(this.unsub$),
      rx.map(([selectedValue, options]) =>
        options.find((opt) => opt.source.value === selectedValue),
      ),
      rx.map((opt) => _.defaultTo({ value: '' }, opt)),
      rx.tap((selectedOption) => (this.radioModel = selectedOption.value)),
    )(null).subscribe();
  }

  /**
   * Setup and subscribe to the model change notification chain.
   *
   * When the radio model is changed (by user selection or input), an
   * output event is emitted to notify the change to the user of
   * the component.
   *
   * @return {void}
   */
  setChainModelChangeNotify() {
    rx.pipe(
      () => this.radioModel$,
      rx.takeUntil(this.unsub$),
      rx.filter((radioModel) => !_.isEmpty(radioModel)),
      rx.withLatestFrom(this.options$),
      rx.map(([radioModel, options]) =>
        options.find((opt) => opt.value === radioModel),
      ),
      rx.filter((opt) => !_.isNil(opt)),
      rx.map((opt) => opt.source),
      rx.tap((sourceOption) => this.onSelect({ value: sourceOption.value })),
    )(null).subscribe();
  }
}

export const RadioGroupComponent = {
  template,
  controller: Controller,
  bindings: {
    name: '<',
    options: '<',
    selectedValue: '<',
    onSelect: '&',
  },
};

export default RadioGroupComponent;
