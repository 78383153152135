import Component from '~/source/common/components/dropdowns/base/component';

class CustomerStatusesController extends Component.controller {
  static $inject = ['customerStatusesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.PLEASE_SELECT';
  }

  /**
   * Disable translation by returning an empty value;
   * Since the statuses are synced from different brands, we cannot translate them.
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  get nullableElement() {
    return {
      id: null,
      name: this.$translate.instant('common.NO_STATUS'),
    };
  }
}

export default Component.config({ controller: CustomerStatusesController });
