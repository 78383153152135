import template from './third-party-tracking-data.html';
import BaseController from '~/source/common/controllers/base';
import _ from 'underscore';
import type { StateService } from '@uirouter/angularjs';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { Campaign } from '@proftit/crm.api.models.entities';
import { IScope } from 'angular';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';

const FIELDS = ['trackers', 'trackerParams', 'brandTrackerParams'];

class ComponentController extends BaseController {
  marketingCampaignsServiceInstance: MarketingCampaignsService;
  isEdit: boolean = false;
  mainForm;
  campaign: Campaign;
  prevAttributes;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly marketingCampaignsService: () => MarketingCampaignsService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly $state: StateService,
    readonly $validation: any,
  ) {
    super();
    this.marketingCampaignsServiceInstance = marketingCampaignsService();
  }

  /**
   * Enter edit mode:
   * Save current model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = [...this.campaign.trackerParams];

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    this.campaign = { ...this.campaign, trackerParams: this.prevAttributes };

    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    // get the required fields
    const normalizedCampaignData = _.pick(this.campaign, FIELDS);

    this.marketingCampaignsServiceInstance
      .setConfig({ blockUiRef: 'brandThirdPartyTrackingForm' })
      .updateCampaign(this.$state.params.campaignId, normalizedCampaignData)
      .then(() => {
        this.isEdit = false;
      });
  }

  onServerFireClicked() {
    this.$validation.validate(this.mainForm);
  }
}

export default {
  template,
  bindings: {
    campaign: '=',
  },
  controller: ComponentController,
  controllerAs: 'vm',
};
