import template from './brand-withdrawals.component.html';
const styles = require('./brand-withdrawals.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { Brand } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';
import { FormControl } from '@proftit/ng1.reactive-forms';
import BrandsService from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';

export class BrandWithdrawalsController {
  brand: Brand;

  styles = styles;
  blockUiId = generateBlockuiId();
  lifecycles = observeComponentLifecycles(this);

  editAction = new rx.Subject<void>();
  cancelEditAction = new rx.Subject<void>();
  saveAction = new rx.Subject<void>();
  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');
  isEdit$ = new rx.BehaviorSubject<boolean>(false);
  isMultipleWithdrawalsEnabledFormControl = new FormControl<boolean>(false);
  save$ = this.streamSave();

  brandsServiceInst = this.brandsService();

  /*@ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    useStreams(
      [
        this.streamIsMultipleWithdrawalsEnabled(),
        this.isMultipleWithdrawalsEnabledFormControl.value$,
        this.streamEdit(),
        this.streamCancelEdit(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamIsMultipleWithdrawalsEnabled() {
    const isMultipleWithdrawalsEnabled$ = new rx.BehaviorSubject<boolean>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamIsMultipleWithdrawalsEnabledFromBrand(),
          this.streamIsMultipleWithdrawalsEnabledFromCancel(
            isMultipleWithdrawalsEnabled$,
          ),
          this.streamIsMultipleWithdrawalsEnabledFromSave(),
        ),
      rx.tap((value) => {
        isMultipleWithdrawalsEnabled$.next(value);
        this.isMultipleWithdrawalsEnabledFormControl.setValueAsFirst(value);
      }),
    )(null);
  }

  streamIsMultipleWithdrawalsEnabledFromBrand() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((brand) => !_.isNil(brand)),
      rx.map((brand) => brand.isMultipleWithdrawalsEnabled),
    )(null);
  }

  streamIsMultipleWithdrawalsEnabledFromCancel(
    isMultipleWithdrawalsEnabled$: rx.Observable<boolean>,
  ) {
    return rx.pipe(
      () => this.cancelEditAction,
      rx.withLatestFrom(isMultipleWithdrawalsEnabled$),
      rx.map(
        ([a, isMultipleWithdrawalsEnabled]) => isMultipleWithdrawalsEnabled,
      ),
    )(null);
  }

  streamIsMultipleWithdrawalsEnabledFromSave() {
    return rx.pipe(
      () => this.save$,
      rx.filter((brand) => !_.isNil(brand)),
      rx.map((brand) => brand.isMultipleWithdrawalsEnabled),
    )(null);
  }

  streamEdit() {
    return rx.pipe(
      () => this.editAction,
      rx.tap(() => {
        this.isEdit$.next(true);
      }),
    )(null);
  }

  streamCancelEdit() {
    return rx.pipe(
      () => this.cancelEditAction,
      rx.tap(() => {
        this.isEdit$.next(false);
      }),
    )(null);
  }

  streamSave(): rx.Observable<Brand> {
    return rx.pipe(
      () => this.saveAction,
      rx.withLatestFrom(
        this.isMultipleWithdrawalsEnabledFormControl.value$,
        this.brand$,
      ),
      rx.mergeMap(([a, isMultipleWithdrawalsEnabled, brand]) => {
        return this.brandsServiceInst
          .setConfig({ blockUiRef: this.blockUiId })
          .updateIsMultipleWithdrawalsEnabled(
            brand.id,
            isMultipleWithdrawalsEnabled as any,
          )
          .then((res) => {
            this.isEdit$.next(false);
            return res;
          })
          .catch((err) => {
            return null;
          });
      }),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandWithdrawalsComponent = {
  template,
  controller: BrandWithdrawalsController,
  bindings: {
    brand: '<',
  },
};
