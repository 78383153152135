import * as _ from '@proftit/lodash';

export const regExpNotRequired = function (regexp) {
  return function (value) {
    if (_.isNil(value) || value === '') {
      return true;
    }
    return regexp.test(value);
  };
};
