import ng from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import { Credential, BrandEwallet } from '~/source/common/models/brand-ewallet';
import { Brand } from '@proftit/crm.api.models.entities';
import { Ewallet, EwalletField } from '~/source/common/models/ewallet';
import useStream from '~/source/common/utilities/use-stream';

import template from './brand-ewallet-editor.component.html';

interface CredentialChange {
  index: number;
  key: string;
  value: string;
}

interface UiCredential extends Credential {
  label: string;
}

export interface BrandEwalletEditorComponentBindings {
  brandEwallet: BrandEwallet;
  credentialsSchema: EwalletField[];
  onChangeBrand: (a: { brand: Brand }) => {};
  onChangeEwallet: (a: { ewallet: Ewallet }) => {};
  onChangeCredential: (a: { credential: Credential }) => {};
}

/**
 * Stateless component for editing/showing brand-ewallet entity.
 */
export class BrandEwalletEditorController extends BaseController
  implements BrandEwalletEditorComponentBindings {
  /* Bindings */
  brandEwallet: BrandEwallet;
  credentialsSchema: EwalletField[];
  onChangeBrand: (a: { brand: Brand }) => {};
  onChangeEwallet: (a: { ewallet: Ewallet }) => {};
  onChangeCredential: (a: { credential: Credential }) => {};

  brandLocal: Brand;
  ewalletLocal: Ewallet;
  unsub$ = new rx.Subject<void>();
  brandEwallet$ = new rx.BehaviorSubject<BrandEwallet>(null);
  credentialsSchema$ = new rx.BehaviorSubject<EwalletField[]>([]);
  fullCredentials$ = new rx.BehaviorSubject<UiCredential[]>([]);
  opBrandChange$ = new rx.Subject<Brand>();
  opEwalletChange$ = new rx.Subject<Ewallet>();
  opCredentialChange$ = new rx.Subject<CredentialChange>();

  /*@ngInject */
  constructor() {
    super();
  }

  /**
   * Lifecycle method - onInit
   *
   * @return {void}
   */
  $onInit() {
    useStream(this.streamCurrentBrand(), this.unsub$);
    useStream(this.streamCurrentEwallet(), this.unsub$);
    useStream(this.streamCurrentFullCredentials(), this.unsub$);
    useStream(this.streamBrandChange(), this.unsub$);
    useStream(this.streamEwalletChange(), this.unsub$);
    useStream(this.streamCredentialChange(), this.unsub$);
  }

  /**
   * Lifecycle method - onDestroy
   *
   * @return {void}
   */
  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * Lifecycle event - on change of brand-ewallet input
   * @param brandEwallet - brand-ewallet
   * @return {void}
   */
  onBrandEwalletChange(brandEwallet: BrandEwallet) {
    this.brandEwallet$.next(brandEwallet);
  }

  /**
   * Lifecycle event - on change of credentials schema input
   * @param credentialsSchema - credentialsSchema
   * @return {void}
   */
  onCredentialsSchemaChange(credentialsSchema: EwalletField[]) {
    this.credentialsSchema$.next(credentialsSchema);
  }

  /**
   * Calc brand by brand-ewallet.
   *
   * We need a separate instance so ng-model won't polute the state.
   *
   * @return {Observable} observable of the calculation.
   */
  streamCurrentBrand() {
    return rx.pipe(
      () => this.brandEwallet$,
      rx.map((brandEwallet) => _.get('brand', brandEwallet)),
      rx.tap((brand) => {
        this.brandLocal = brand;
      }),
    )(null);
  }

  /**
   * Calc ewallet by brand-ewallet
   *
   * We need a separate instance so ng-model won't polute the state.
   *
   * @return {Observable} observable of the calculation.
   */
  streamCurrentEwallet() {
    return rx.pipe(
      () => this.brandEwallet$,
      rx.map((brandEwallet) => _.get('ewallet', brandEwallet)),
      rx.tap((ewallet) => {
        this.ewalletLocal = ewallet;
      }),
    )(null);
  }

  /**
   * Calc full credentails including schema empty values.
   *
   * @return {Observable} observable of the calculation.
   */
  streamCurrentFullCredentials() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.brandEwallet$.pipe(
            rx.map((brandEwallet) => _.get('credentials', brandEwallet)),
          ),
          this.credentialsSchema$.pipe(rx.map((x) => _.defaultTo([], x))),
        ),
      rx.map(([credentials, credentialsSchema]) =>
        this.calcFullCredentials(credentials, credentialsSchema),
      ),
      rx.tap((fullCredentials) => this.fullCredentials$.next(fullCredentials)),
    )(null);
  }

  /**
   * Notify of the brand change.
   *
   * @return observable of the ation.
   */
  streamBrandChange() {
    return rx.pipe(
      () => this.opBrandChange$,
      rx.tap((brand) => this.onChangeBrand({ brand })),
    )(null);
  }

  /**
   * Notify of the ewallet change.
   *
   * @return observable of the ation.
   */
  streamEwalletChange() {
    return rx.pipe(
      () => this.opEwalletChange$,
      rx.tap((ewallet) => this.onChangeEwallet({ ewallet })),
    )(null);
  }

  /**
   * Notify of credential change.
   *
   * @return observable of the action.
   */
  streamCredentialChange() {
    return rx.pipe(
      () => this.opCredentialChange$,
      rx.map((change) =>
        this.onChangeCredential({
          credential: { key: change.key, value: change.value },
        }),
      ),
    )(null);
  }

  /**
   * Calc full credentails base on credentails and schema.
   *
   * @param credentlials - credentials.
   * @param credentialsSchema - schema.
   * @return full credentials.
   */
  calcFullCredentials(
    credentials: Credential[],
    credentialsSchema: EwalletField[],
  ): UiCredential[] {
    return credentialsSchema.map((schemaField) => {
      const currCredential = credentials.find(
        ({ key }) => key === schemaField.code,
      );
      const calcValue = _.defaultTo('', _.get('value', currCredential));

      return {
        key: schemaField.code,
        label: schemaField.name,
        value: calcValue,
      };
    });
  }
}

export const BrandEwalletEditorComponent = {
  template,
  controller: BrandEwalletEditorController,
  bindings: {
    brandEwallet: '<',
    credentialsSchema: '<',
    onChangeBrand: '&',
    onChangeEwallet: '&',
    onChangeCredential: '&',
  },
};

export default BrandEwalletEditorComponent;
