import log from 'loglevel';
import { SocketService } from '~/source/common/services/socket';
import { hashCode } from '../utilities/hash-code';

export class Mt4BrandStatsSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  streamerUrl = '';

  /**
   * Specify socket registry key.
   *
   * there are registry for crm socket, platform socket..
   * Sockets should be shared for all services targeting the same target.
   */
  get socketRegistryKey() {
    const urlHash = hashCode(this.streamerUrl);
    return `mt4Socket&url=${urlHash}`;
  }

  getStreamerUrl() {
    return this.streamerUrl;
  }

  get channelRoot() {
    return 'brandpnl';
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }

  /**
   * Set streamer url
   *
   * @param url
   */
  setStreamerUrl(url: string) {
    this.streamerUrl = url;
  }
}

/**
 * Build channel name for brand stats subscription.
 *
 * @param senderCompId - senderComp id.
 * @return channel name.
 */
export function buildMt4BrandsStatsChannel(senderCompId: string) {
  return `brandpnl.${senderCompId}`;
}
