import SearchResultsBaseController from './search-results-table.controller';
import template from './search-results-table-customers.html';

class CustomersResultsController extends SearchResultsBaseController {
  cols = [...this.searchResultsModuleSettings.cols.customers];

  /**
   * Return a fetch method name from the 'searchService'
   * Will be called by parent controller
   * @returns {string} method name in 'searchService'
   */
  get searchMethodName() {
    return 'findCustomers';
  }
}

export default {
  template,
  controller: CustomersResultsController,
};
