import { ICollection } from 'restangular';
import SocketService from '~/source/common/services/socket';
import CallsStatuses from '~/source/contact/common/services/communications-statuses.service';

export class CommunicationsSocketService extends SocketService {
  static $inject = ['communicationsStatusesService', ...SocketService.$inject];

  communicationsStatusesService: () => CallsStatuses;

  get channelRoot() {
    return 'CustomerCommunication';
  }

  get whitelist() {
    return ['communicationStatusId', 'isActive'];
  }

  parseCommunicationStatusId(data) {
    return (
      this.communicationsStatusesService()
        // this is a background operation. don't block or show errors
        .setConfig({ suppressGrowl: true, suppressBlockUi: true })
        .getListWithQuery()
        .then((communicationStatuses: ICollection) =>
          communicationStatuses.plain(),
        )
        .then((communicationStatuses) => {
          data.communicationStatus = communicationStatuses.find(
            (status) => status.id === data.communicationStatusId,
          );
        })
    );
  }
}

export default CommunicationsSocketService;
