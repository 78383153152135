import angular from 'angular';
import CreditsModule from './credit/module';
import PrivateEwalletPopupModule from './private-ewallet-popup/module';
import PositionsModule from './position/module';
import AccountActionsBarForexComponent from './trading-account-actions-bar-forex/trading-account-actions-bar-forex.component';
import TradingAccountForexComponent from './trading-account-forex/trading-account-forex.component';

import TradingAccountSocketService from './trading-account-socket.service';
import PendingPositionsTableForexComponent from './pending-positions-table-forex/pending-positions-table-forex.component';

import PendingPositionsTableForexPopupController from './pending-positions-table-forex-popup/pending-positions-table-forex-popup.controller';

import { BundlesTableComponent } from './bundles-table/bundles-table.component';
import { BundlesTablePopupComponent } from './bundles-table-popup/bundles-table-popup.component';
import { BundlesTableColRendererComponent } from './bundles-table-col-renderer/bundles-table-col-renderer.component';
import { BundlePositionsTableComponent } from './bundle-positions-table/bundle-positions-table.component';
import { BundlesStatusesSelectComponent } from './bundles-statuses-select/bundles-statuses-select.component';
import { TradeBundlesService } from './trade-bundles.service';
import { TradeBundleSelectComponent } from './trade-bundle-select/trade-bundle-select.component';
import { PortfolioForexPositionSocketService } from './portfolio-forex-position-socket.service';
import { IsDemoSelectComponent } from './is-demo-select/is-demo-select.component';
import { PrivateEwalletPopupComponent } from './private-ewallet-popup/private-ewallet-popup.component';
import component from '~/source/common/components/dropdowns/base/component';
export const TradingAccountForexModule = angular.module(
  'crm.contact.tradingAccount.forex',
  [CreditsModule, PositionsModule, PrivateEwalletPopupModule],
);

TradingAccountForexModule.service(
  'tradingAccountForexSocketService',
  <any>TradingAccountSocketService,
)
  .service('prfTradeBundlesService', TradeBundlesService)
  .service(
    'portfolioForexPositionSocketService',
    PortfolioForexPositionSocketService,
  )
  .controller(
    'prfPendingPositionsTableForexPopupController',
    PendingPositionsTableForexPopupController,
  )
  .component(
    'prfTradingAccountActionsBarForex',
    <any>AccountActionsBarForexComponent,
  )
  .component('prfTradingAccountForex', <any>TradingAccountForexComponent)
  .component(
    'prfPendingPositionsTableForex',
    <any>PendingPositionsTableForexComponent,
  )
  .component('prfBundlesTable', BundlesTableComponent)
  .component('prfBundlesTablePopup', BundlesTablePopupComponent)
  .component('prfBundlesTableColRenderer', BundlesTableColRendererComponent)
  .component('prfBundlePositionsTable', BundlePositionsTableComponent)
  .component('prfBundlesStatusesSelect', BundlesStatusesSelectComponent)
  .component('prfTradeBundleSelect', TradeBundleSelectComponent)
  .component('prfIsDemoSelect', IsDemoSelectComponent);
