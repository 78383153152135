import template from './attachments-additionals-list-management.component.html';
const styles = require('./attachments-additionals-list-management.component.scss');

import log from 'loglevel';
import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ComplianceDockTypesManagementService } from '~/source/common/api-crm-server/services/compliance-dock-types-management.service';
import { useStreams } from '@proftit/rxjs.adjunct';
import { createNgModelSubject } from '~/source/common/utilities/create-ng-model-subject';
import { CustomerComplianceFileType } from '@proftit/crm.api.models.entities';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED,
  CUSTOMER_COMPLIANCE_DOC_TYPE_CREATED,
} from '~/source/common/constants/general-pubsub-keys';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import { switchOn } from '~/source/common/utilities/switch-on';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

const SEARCH_DEBOUNCE = 600;

interface ItemUi {
  model: CustomerComplianceFileType;
  uiModel: CustomerComplianceFileType;
  isInEdit: boolean;
  action: CrudOpeartion;
}

export class AttachmentsAdditionalsListManagementController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  CrudOpeartion = CrudOpeartion;

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  opSearchTerm$ = new rx.Subject<string>();
  opSetIsVisibleOnItem$ = new rx.Subject<{
    id: number;
    isVisible: boolean;
    action: CrudOpeartion;
  }>();
  opOpenEditOnItem$ = new rx.Subject<number>();
  opCancelEditOnItem$ = new rx.Subject<number>();
  opSaveEditOnItem$ = new rx.Subject<number>();
  opAddNewItemUi$ = new rx.Subject<void>();
  searchTerm$ = this.streamSearchTerm();
  searchTerm = createNgModelSubject<string>(
    this.searchTerm$,
    this.opSearchTerm$,
  );
  itemsUi$ = this.streamItemsUi();
  hasPermissionForAdding$ = this.streamHasPermissionForAdding();
  hasPermissionForEditBar$ = this.streamHasPermissionForEditBar();

  /*@ngInject */
  constructor(
    readonly prfComplianceDockTypesManagementService: () => ComplianceDockTypesManagementService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [
        this.itemsUi$,
        this.searchTerm.asObservable(),
        this.searchTerm$,
        this.streamActionSetIsVisibleOnItem(),
        this.streamActionSaveEditItem(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamItemsUiFromInit() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => ''),
      rx.switchMap((searchTerm) =>
        rx.obs.from(this.fetchComplianceDoctypes(searchTerm)).pipe(
          rx.catchError((err, caught) => {
            log.error('error fetching items');
            return rx.obs.from([[]]);
          }),
        ),
      ),
      rx.map((items) =>
        items.map((item) => this.createUiItem(item, CrudOpeartion.Update)),
      ),
    )(null);
  }

  streamItemsUiFromSearch() {
    return rx.pipe(
      () => this.opSearchTerm$.pipe(rx.debounceTime(SEARCH_DEBOUNCE)),
      rx.switchMap((searchTerm) =>
        rx.obs.from(this.fetchComplianceDoctypes(searchTerm)).pipe(
          rx.catchError((err, caught) => {
            log.error('error fetching items');
            return rx.obs.from([[]]);
          }),
        ),
      ),
      rx.map((items) =>
        items.map((item) => this.createUiItem(item, CrudOpeartion.Update)),
      ),
    )(null);
  }

  streamItemsUiFromItemCreate(itemsUi$: rx.Observable<ItemUi[]>) {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === CUSTOMER_COMPLIANCE_DOC_TYPE_CREATED),
      rx.map(({ payload }) => payload),
      rx.withLatestFrom(itemsUi$),
      rx.map(([{ original, created }, itemsUi]) => {
        return itemsUi.reduce((acc, itemUi) => {
          if (itemUi.model.id !== original.id) {
            return [...acc, itemUi];
          }

          return [
            ...acc,
            this.createUiItem(
              {
                ...itemUi.model,
                ...created,
              },
              CrudOpeartion.Update,
            ),
          ];
        }, []);
      }),
    )(null);
  }

  streamItemsUiFromItemUpdate(itemsUi$: rx.Observable<ItemUi[]>) {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED),
      rx.map(({ payload }) => payload),
      rx.withLatestFrom(itemsUi$),
      rx.map(([modelUpdate, itemsUi]) => {
        return itemsUi.reduce((acc, itemUi) => {
          if (itemUi.model.id !== modelUpdate.id) {
            return [...acc, itemUi];
          }

          return [
            ...acc,
            this.createUiItem(
              {
                ...itemUi.model,
                ...modelUpdate,
              },
              CrudOpeartion.Update,
            ),
          ];
        }, []);
      }),
    )(null);
  }

  streamItemsUiFromEditOnItemAction(itemsUi$: rx.Observable<ItemUi[]>) {
    return rx.pipe(
      () => this.opOpenEditOnItem$,
      rx.withLatestFrom(itemsUi$),
      rx.map(([targetId, itemsUi]) => {
        const targetIndex = itemsUi.findIndex(
          (item) => item.model.id === targetId,
        );
        return _.set([targetIndex, 'isInEdit'], true, itemsUi);
      }),
    )(null);
  }

  streamItemsUiFromCancelEditOnItemAction(itemsUi$: rx.Observable<ItemUi[]>) {
    return rx.pipe(
      () => this.opCancelEditOnItem$,
      rx.withLatestFrom(itemsUi$),
      rx.map(([targetId, itemsUi]) => {
        const targetIndex = itemsUi.findIndex(
          (item) => item.model.id === targetId,
        );
        const target = itemsUi[targetIndex];
        // no cancel on temp created items.
        if (target.action === CrudOpeartion.Create) {
          return itemsUi;
        }

        return _.set(
          [targetIndex],
          this.createUiItem(itemsUi[targetIndex].model, CrudOpeartion.Update),
          itemsUi,
        );
      }),
    )(null);
  }

  streamItemsUiFromAddNewItemUiAction(itemsUi$: rx.Observable<ItemUi[]>) {
    return rx.pipe(
      () => this.opAddNewItemUi$,
      rx.withLatestFrom(itemsUi$),
      rx.map(([a, itemsUi]) => {
        const newItem = this.createUiItem(
          {
            id: `temp-id-${generateUuid()}` as any,
            name: '',
            code: null,
            isSystem: false,
            isVisible: false,
          },
          CrudOpeartion.Create,
        );

        newItem.isInEdit = true;

        return [...itemsUi, newItem];
      }),
    )(null);
  }

  streamItemsUi() {
    const itemsUi$ = new rx.BehaviorSubject<ItemUi[]>([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamItemsUiFromInit(),
          this.streamItemsUiFromSearch(),
          this.streamItemsUiFromItemUpdate(itemsUi$),
          this.streamItemsUiFromItemCreate(itemsUi$),
          this.streamItemsUiFromEditOnItemAction(itemsUi$),
          this.streamItemsUiFromCancelEditOnItemAction(itemsUi$),
          this.streamItemsUiFromAddNewItemUiAction(itemsUi$),
        ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
      rx.tap((itemsUi) => itemsUi$.next(itemsUi)),
    )(null);
  }

  streamSearchTerm() {
    return rx.pipe(
      () => this.opSearchTerm$,
      rx.startWith(''),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamActionSetIsVisibleOnItem() {
    return rx.pipe(
      () => this.opSetIsVisibleOnItem$,
      rx.switchMap(({ id, isVisible, action }) => {
        if (action === CrudOpeartion.Create) {
          return rx.obs.from([[]]);
        }

        return rx.obs
          .from(
            this.prfComplianceDockTypesManagementService()
              .setConfig({
                blockUiRef: this.blockUiId,
                growlRef: this.growlId,
              })
              .updateIsVisible(id, isVisible),
          )
          .pipe(
            rx.catchError((err, caught) => {
              log.error('error setting is visible');
              return rx.obs.from([[]]);
            }),
          );
      }),
    )(null);
  }

  streamActionSaveEditItem() {
    return rx.pipe(
      () => this.opSaveEditOnItem$,
      rx.withLatestFrom(this.itemsUi$),
      rx.switchMap(([id, itemsUi]) => {
        const itemUi = itemsUi.find((item) => item.model.id === id);

        return switchOn(
          {
            [CrudOpeartion.Create]: () => this.createItem(itemUi),
            [CrudOpeartion.Update]: () => this.updateItem(id, itemUi),
          },
          itemUi.action,
          () => {
            throw new Error('unimlemented action for save');
          },
        );
      }),
    )(null);
  }

  streamHasPermissionForAdding() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ManagementFieldsAndModules,
          this.PermPermissionStore,
        ),
      rx.map((permissionCrud) => permissionCrud.isCreate),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamHasPermissionForEditBar() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          checkCrudPermission(
            PermissionNormalized.ManagementFieldsAndModules,
            this.PermPermissionStore,
          ),
          this.itemsUi$,
        ),
      rx.map(([permissionCrud, itemsUi]) => {
        return itemsUi.reduce((acc, itemUi) => {
          if (
            itemUi.action === CrudOpeartion.Create &&
            permissionCrud.isCreate
          ) {
            acc[itemUi.model.id] = true;
            return acc;
          }

          if (
            itemUi.action === CrudOpeartion.Update &&
            permissionCrud.isUpdate
          ) {
            acc[itemUi.model.id] = true;
            return acc;
          }

          acc[itemUi.model.id] = false;
          return acc;
        }, {});
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  createItem(itemUi: ItemUi) {
    return rx.obs
      .from(
        this.prfComplianceDockTypesManagementService()
          .setConfig({
            blockUiRef: this.blockUiId,
            growlRef: this.growlId,
          })
          .createItem(itemUi.uiModel),
      )
      .pipe(
        rx.catchError((err, caught) => {
          log.error('error updating item');
          return rx.obs.from([[]]);
        }),
      );
  }

  updateItem(id: number, itemUi: ItemUi) {
    return rx.obs
      .from(
        this.prfComplianceDockTypesManagementService()
          .setConfig({
            blockUiRef: this.blockUiId,
            growlRef: this.growlId,
          })
          .updateItem(id, {
            name: itemUi.uiModel.name,
          }),
      )
      .pipe(
        rx.catchError((err, caught) => {
          log.error('error updating item');
          return rx.obs.from([[]]);
        }),
      );
  }

  fetchComplianceDoctypes(searchTerm: string) {
    return this.prfComplianceDockTypesManagementService()
      .setConfig({
        blockUiRef: this.blockUiId,
        growlRef: this.growlId,
      })
      .filter({
        isSystem: false,
      })
      .getBySearch(searchTerm);
  }

  createUiItem(model: CustomerComplianceFileType, action: CrudOpeartion) {
    const showInKycWidget = Object.defineProperties(
      {},
      {
        model: {
          get: () => {
            return model.isVisible;
          },
          set: (showInKycWidget) => {
            this.opSetIsVisibleOnItem$.next({
              action,
              isVisible: showInKycWidget,
              id: model.id,
            });
          },
        },
      },
    );

    const uiModel = {
      ...model,
    };

    const itemUi = {
      model,
      uiModel,
      showInKycWidget,
      action,
      isInEdit: false,
    };

    return itemUi;
  }
}

export const AttachmentsAdditionalsListManagementComponent = {
  template,
  controller: AttachmentsAdditionalsListManagementController,
};
