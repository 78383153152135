import template from './scoring-table.component.html';
import { TableController } from '~/source/common/components/table/table.controller';
const styles = require('./scoring-table.component.scss');

import ng from 'angular';
import log from 'loglevel';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { tableSettings } from './table-settings';
import { tableColumns } from './table-columns';
import { ActionMenuDropdownItem } from '~/source/common/components/actions-menu-dropdown/action-menu-dropdown-item';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { switchOn } from '~/source/common/utilities/switch-on';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { QuestionnaireScoresService } from '~/source/common/services/questionnaire-scores.service';
import type { StateService } from '@uirouter/angularjs';
import QuestionnaireService from '../questionnaire.service';
import { QUESTIONNAIRE_SCORE_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { QuestionnaireScore } from '@proftit/crm.api.models.entities';

enum ScoringActionCode {
  None = 'none',
  Edit = 'edit',
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export class ScoringTableController extends TableController {
  static $inject = [
    'questionnaireScoresService',
    'questionnaireService',
    'prfClientGeneralPubsub',
    'modalService',
    'PermPermissionStore',
    '$state',
    ...TableController.$inject,
  ];

  questionnaireScoresService: () => QuestionnaireScoresService;
  questionnaireService: () => QuestionnaireService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  modalService: ModalService;
  PermPermissionStore: ng.permission.PermissionStore;
  $state: StateService;
  dataServiceInstance: QuestionnaireScoresService;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = generateBlockuiId();
  settings = { ...tableSettings };
  cols = [...tableColumns];
  collectionMenuItems$ = new rx.BehaviorSubject<{
    [key: string]: ActionMenuDropdownItem;
  }>({});
  opMenuAction$ = new rx.Subject<{
    actionCode: ScoringActionCode;
    id: number;
    questionnaireFormId: number;
  }>();

  constructor(...args) {
    super(...args);

    useStreams(
      [
        this.streamInitTable(),
        this.streamCalcMenuItems(),
        this.streamMenuActionPerform(),
        this.streamRefreshTableOnExternalNotice(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.questionnaireScoresService();
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitTable() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamCalcMenuItems() {
    return rx.pipe(
      () => this.collection$,
      rx.map((items) =>
        items.map((item) => ({
          id: item.id,
          menu: calcMenuItemForScoring(item as any),
        })),
      ),
      rx.map((itemsMenus) =>
        itemsMenus.reduce((acc, itemMenu) => {
          acc[itemMenu.id] = itemMenu.menu;
          return acc;
        }, {}),
      ),
      rx.tap((menus) => this.collectionMenuItems$.next(menus)),
    )(null);
  }

  streamMenuActionPerform() {
    return rx.pipe(
      () => this.opMenuAction$,
      rx.tap((action) => {
        const actions = {
          [ScoringActionCode.Activate]: () =>
            this.questionnaireService().activateQuestionnaireScore(
              action.questionnaireFormId,
              action.id,
            ),
          [ScoringActionCode.Deactivate]: () =>
            this.questionnaireService().deactivateQuestionnaireScore(
              action.questionnaireFormId,
              action.id,
            ),
          [ScoringActionCode.Edit]: () =>
            this.$state.go('^.score.edit', {
              id: action.id,
              questionnaireFormId: action.questionnaireFormId,
            }),
        };
        switchOn(actions, action.actionCode, () => {
          log.error('unhandled action', action);
          throw new Error('unhandled action');
        });
      }),
      rx.catchError((err, obs) => {
        log.error('error on action', err);
        return obs;
      }),
    )(null);
  }

  streamRefreshTableOnExternalNotice() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => [QUESTIONNAIRE_SCORE_UPDATED].includes(key)),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  fetchFn() {
    // todoOld: brand filter

    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiRef })
      .expand(['questionnaireForm', 'questionnaireForm.brand'])
      .embed(['questionnaireForm'])
      .sort({ updatedAt: 'desc' });
  }
}

function calcMenuItemForScoring(item) {
  return _.flow([
    () => [],
    (menu) => [
      ...menu,
      {
        actionCode: ScoringActionCode.Edit,
        labelCode: 'common.EDIT',
      },
    ],
    (menu) => [...menu, calcActivateMenuItem(item)],
  ])();
}

function calcActivateMenuItem(item: QuestionnaireScore) {
  if (item.isActive) {
    return {
      actionCode: ScoringActionCode.Deactivate,
      labelCode: 'common.DEACTIVATE',
    };
  }

  return {
    actionCode: ScoringActionCode.Activate,
    labelCode: 'common.ACTIVATE',
  };
}

export const ScoringTableComponent = {
  template,
  controller: ScoringTableController,
  controllerAs: 'vm',
};
