import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './edit-comment-popup.component.html';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import CustomersService from '~/source/contact/common/services/customers';
import { COMMUNICATION_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import log from 'loglevel';
import { CustomerCommunication } from '@proftit/crm.api.models.entities';
import { DateTzAdjustService } from '~/source/common/services/date-tz-adjust';
import { CustomerCommunicationForm } from '../customer-communicatoin-form';

const styles = require('./edit-comment-popup.component.scss');

export class EditCommentPopupController {
  styles = styles;

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  lifecycles = observeComponentLifecycles(this);

  close: () => void;

  resolve$ = observeShareCompChange<{
    customerId: number;
    call: CustomerCommunication;
  }>(this.lifecycles.onChanges$, 'resolve');

  customerId$ = this.resolve$.pipe(
    rx.map(({ customerId }) => customerId),
    shareReplayRefOne(),
  );
  call$ = this.resolve$.pipe(
    rx.map(({ call }) => call),
    shareReplayRefOne(),
  );

  updateCommentAction = new rx.Subject<void>();
  updatedCall$ = new rx.BehaviorSubject<Partial<CustomerCommunicationForm>>({});
  isFormValid$ = new rx.BehaviorSubject<boolean>(false);

  /* @ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly dateTzAdjustService: DateTzAdjustService,
  ) {
    useStreams(
      [
        this.streamUpdateComment(),
        this.customerId$,
        this.call$,
        this.updatedCall$,
        this.isFormValid$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  updateComment(call) {}

  streamUpdateComment() {
    const streamFn = rx.pipe(
      () => this.updateCommentAction,
      rx.withLatestFrom(this.updatedCall$, this.customerId$, this.call$),
      rx.filter(([a, updatedCall, customerId, call]) => !_.isNil(updatedCall)),
      rx.switchMap(([a, updatedCall, customerId, call]) => {
        const normUpdatedCall = this.normlizeCallForSave(updatedCall);

        return rx.obs.from(
          this.customersService()
            .setConfig({
              blockUiRef: this.blockUiId,
              growlRef: this.blockUiId,
            })
            .expand(['type', 'communicationSubject', 'communicationStatus'])
            .updateCommunication(customerId, call.id, normUpdatedCall),
        );
      }),
      rx.tap(() => {
        this.close();
      }),
      rx.catchError((err, caught) => {
        log.error('error update communication', err);
        return streamFn();
      }),
    );

    return streamFn(null);
  }

  normlizeCallForSave(comm: Partial<CustomerCommunicationForm>) {
    if (comm.noFollowUpCheck) {
      return _.flow([
        (item) => _.omit(['noFollowUpCheck'], item),
        (item) => ({
          ...item,
          followUpDate: null,
        }),
      ])(comm);
    }

    return _.flow([
      (item) => _.omit(['noFollowUpCheck'], item),
      (item) => ({
        ...item,
        followUpDate: this.dateTzAdjustService.format(comm.followUpDateAsDate),
      }),
    ])(comm);
  }
}

export const EditCommentPopupComponent = {
  template,
  controller: EditCommentPopupController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
