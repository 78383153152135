import log from 'loglevel';
import { StateService, StateOrName, RawParams } from '@uirouter/core';
import angular from 'angular';

import template from './notifications-center-popup.html';
import logPopupTemplate from '../notifications-center-log-popup.html';
import BaseController from '../../../controllers/base';
import settings from '../notifications-settings.json';
import { SocketListener } from '~/source/common/services/socket';
import NotificationsSocketService from '../notifications-socket.service';
import NotificationsService from '../notifications.service';
import TokensService from '~/source/auth/services/tokens';
import PopupService from '~/source/common/components/modal/popup.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import Notification from '~/source/common/models/notification';
import NotificationChannelType from '~/source/common/models/notification-channel-type';

import * as _ from '@proftit/lodash';
import {
  GoogleStorageResources,
  PrivateGoogleStorageFileService,
} from '~/source/common/services/private-google-storage-file.service';
import { downloadLocalFileUrl } from '@proftit/dom-utilities';
import { UserNotificationCategoryCode } from '@proftit/crm.api.models.enums';
import { UserNotification } from '@proftit/crm.api.models.entities';

/**
 * Popup that shows the user it's notifcations and allow it to interact with them.
 */
export class NotificationsCenterPopupController extends BaseController {
  static $inject = [
    ...BaseController.$inject,
    '$state',
    '$window',
    'popupService',
    'notificationsService',
    'notificationsSocketService',
    'tokensService',
    'desktopNotification',
    'privateGoogleStorageFileService',
  ];

  onNotificationNewWrapped: SocketListener;
  notificationsSocketService: NotificationsSocketService;
  shouldFetchNotifications: boolean;
  notificationsService: NotificationsService;
  privateGoogleStorageFileService: PrivateGoogleStorageFileService;
  isPopupShown: boolean;
  togglePopup: () => void;
  $state: StateService;
  tokensService: TokensService;
  notificationsChannel: string;
  notifications: IElementRestNg<Notification>[];
  desktopNotification: angular.desktopNotification.IDesktopNotificationService;
  $window: angular.IWindowService;
  popupService: PopupService;
  notificationsSubs: Map<
    number,
    { notificationId: number; channel: string; callback: SocketListener }
  >;

  constructor(...args) {
    super(...args);

    this.notificationsSubs = new Map();
    this.notifications = null;
    this.onNotificationNewWrapped = this.notificationsSocketService.wrapListener(
      this.onNotificationNew.bind(this),
    );
    this.shouldFetchNotifications = true;
  }

  $onInit() {
    this.setNotificationsChannel();
    this.startListening();
  }

  get notificationsLimit() {
    return settings.notificationsLimit;
  }

  get isBeforeFirstFetch(): boolean {
    return _.isNil(this.notifications);
  }

  /**
   * Called automagically by BaseController when the "isPopupShown" flag is changed
   *
   * @return {void}
   */
  onIsPopupShownChange() {
    // popup is being displayed and the "shouldFetch" flag is on - fetch now.
    if (this.isPopupShown && this.shouldFetchNotifications) {
      this.fetchNotifications();
    }
  }

  /**
   * Called when a static link notification is clicked.
   * Redirects to the notification relevant resource, closes the popup and marks as read.
   * @param {object} notification - notification restangularized object
   * @return {Promise} a Promise which is resolved when redirect and "mark as read" is done.
   */
  onRegularNotificationClick(notification) {
    return this.goToNotificationResource(notification);
  }

  onNotificationClick({ notification, $event }) {
    $event.preventDefault();
    const isAsyncFileDownload = this.isAsyncFileDownload(notification);
    const promise = isAsyncFileDownload
      ? this.asyncFileDownload(notification)
      : this.onRegularNotificationClick(notification);
    return promise.then(() => {
      if (this.isPopupShown) {
        this.togglePopup();
      }
      return this.markAsRead(notification);
    });
  }

  /**
   * Redirects to the notification's relevant resource.
   * If a filter id was given - redirect to "item" page. otherwise - redirect to list page.
   * @param {object} notification - notification restangularized object
   * @param {boolean} [returnUrl] - when this is true, function will return the URL instead of redirecting to it
   * @return {Promise|string} when returnUrl is false: a Promise representing the state of the transition.
   *                          when returnUrl is true: the generated resource url
   */
  goToNotificationResource(notification: UserNotification, returnUrl = false) {
    const { resource, filter } = notification;
    const resourceInfo = _.get(['resourceStatesMap', resource], settings);

    if (
      notification.category === UserNotificationCategoryCode.FtdAutoAssignment
    ) {
      return Promise.resolve();
    }

    /*
     * In case of direct link notification, we want to present the direct
     * link to the user. There is no ui state to go to.
     */
    if (_.get('type', resourceInfo) === 'directLink') {
      const parsedDirectLinkFilter = JSON.parse(filter);
      if (returnUrl) {
        return parsedDirectLinkFilter.link;
      }

      /*
       * this will not redirect if the link response is not presentable by
       * the browser.
       */
      this.$window.location = parsedDirectLinkFilter.link;
      return Promise.resolve();
    }

    const stateFunc = returnUrl
      ? this.$state.href
      : (res: StateOrName, params: RawParams) =>
          this.$state.go(res, params, { reload: true });

    try {
      // Attempt to parse the filter as a JSON string
      const parsedFilter = JSON.parse(filter);
      if (parsedFilter.id) {
        return stateFunc(
          settings.resourceStatesMap[resource].item,
          parsedFilter,
        );
      }
    } catch (e) {
      // not json. either a quick filter or no filter at all
      if (_.isString(filter)) {
        return stateFunc(settings.resourceStatesMap[resource].list, { filter });
      }
    }

    // fallback: invalid filter or no filter at all
    return (<any>stateFunc)((<any>settings).resourceStatesMap[resource].list);
  }

  /**
   * Generates a link for the given notification's resource
   * @param {object} notification - Notification object
   * @return {string} - relative link
   */
  generateNotificationLink(notification: UserNotification) {
    if (this.isAsyncFileDownload(notification)) {
      return '';
    }

    if (
      notification.category === UserNotificationCategoryCode.FtdAutoAssignment
    ) {
      return '';
    }

    return this.goToNotificationResource(notification, true);
  }

  /**
   * Marks notification as read (patch to server)
   * @param {object} notification - notification object
   * @return {Promise} A promise which is resolved when PATCH is done
   */
  markAsRead(notification) {
    return this.notificationsService.patchElement(notification.id, {
      status: 'read',
    });
  }

  /**
   * archive all notifications (patch to server)
   * @return {Promise} A promise which is resolved when PATCH is done
   */
  archiveAllNotifications() {
    return this.notificationsService
      .patchCollection('filter', { status: 'archived' })
      .then(() => {
        if (this.isPopupShown) {
          this.fetchNotifications();
        } else {
          // popup is closed. just raise the flag to fetch notifications next time the window opens
          this.shouldFetchNotifications = true;
        }
      });
  }

  /**
   * Marks notification as archived (patch to server)
   * @param {object} notification - notification object
   * @return {Promise} A promise which is resolved when PATCH is done
   */
  archiveNotification({ notification }) {
    // after the status change, the streamer will automatically call "onNotificationRemoved" to remove it from list
    return this.notificationsService.patchElement(notification.id, {
      status: 'archived',
    });
  }

  /**
   * Deletes notification from server
   * @param {object} notification - notification object
   * @return {Promise} A promise which is resolved when DELETE is done
   */
  removeNotification({ notification }) {
    return this.notificationsService.removeElement(notification.id).then(() => {
      this.onNotificationRemoved(notification);
    });
  }

  /**
   * Build streamer notifications channel name
   * Must run when user is logged in
   * @return {string} channel name
   */
  setNotificationsChannel() {
    const user = this.tokensService.getCachedUser();
    this.notificationsChannel = this.calcNotificationChannelName(
      NotificationChannelType.New,
    );
  }

  /**
   * Subscribe to new calls
   * @return {void}
   */
  startListening() {
    this.notificationsSocketService.subscribe(
      this.notificationsChannel,
      this.onNotificationNewWrapped,
    );
  }

  /**
   * Un-subscribe from new calls
   * @return {void}
   */
  stopListening() {
    this.notificationsSocketService.unsubscribe(
      this.notificationsChannel,
      this.onNotificationNewWrapped,
    );
  }

  /**
   * Called by the streamer when on an update to the notification channel
   * @param {object} notification - Parsed notifications object from streamer
   * @return {void}
   */
  onNotificationNew(notification) {
    if (this.isBeforeFirstFetch) {
      // notifications weren't fetched yet, show new notifications on desktop only
      if (notification.status === 'new') {
        // always show new notifications on desktop
        this.showDesktopNotification(notification);
      }
      log.debug(
        'Ignoring notification update on popup: notifications were not fetched yet.',
      );
      return;
    }

    this.addNotification(notification);
    this.showDesktopNotification(notification);
  }

  /**
   * Show desktop notification
   * @param {object} notification - Notification object from server
   * @return {void}
   */
  showDesktopNotification(notification) {
    const title = `PROFTIT - ${_.startCase(notification.category)}`;
    this.desktopNotification.show(title, {
      body: notification.notification,
      icon: 'assets/img/fav/favicon.png',
      tag: `proftit_${notification.id}`, // set a tag to avoid double notifications
      onClick: () => this.onDesktopNotificationClick(notification),
    });
  }

  /**
   * Called when a desktop notification is clicked.
   *
   * Opens the notification in a new window (or tab) and marks it as read.
   * @param {object} notification - The clicked notification
   * @return {void}
   */
  onDesktopNotificationClick(notification) {
    const isAsyncFileDownload = this.isAsyncFileDownload(notification);
    if (!isAsyncFileDownload) {
      this.downloadFileUsingStaticLink(notification);
    } else {
      this.asyncFileDownload(notification).then(() => {
        this.markAsRead(notification);
      });
    }
  }

  downloadFileUsingStaticLink(notification) {
    const notificationLink = this.generateNotificationLink(notification);
    // open in a new window (usually tab; depends on browser)
    this.$window.open(notificationLink, '_blank');
    // at the same time, mark the notification as read
    this.markAsRead(notification);
  }

  /**
   * Called when a notification which exists on the list is updated.
   * Removes it if the update is "archive" status, or updates the attributes otherwise.
   * @param {object} notificationUpdate - the updated notification
   * @return {void}
   */
  onExistingNotificationUpdate(notificationUpdate) {
    const existingNotification = this.notifications.find(
      (item) => item.id === notificationUpdate.id,
    );

    if (existingNotification === undefined) {
      log.warn(
        'Got notification update for not new, non existing notification',
        notificationUpdate,
      );
      return;
    }

    if (notificationUpdate.status === 'archived') {
      // notification was archived. remove it
      this.onNotificationRemoved(existingNotification);
      return;
    }

    // this notification is already in the list. just update its attributes
    log.info('Updating existing notification', notificationUpdate);
    Object.assign(existingNotification, notificationUpdate);
  }

  /**
   * Called when a notification is removed
   * @param {object} removedNotification - Removed notification object
   * @return {void}
   */
  onNotificationRemoved(removedNotification) {
    // remove the notification from the notifications array
    log.info('Removing notification', removedNotification);
    this.notifications = this.notifications.filter(
      (item) => item.id !== removedNotification.id,
    );
    this.unsubscribeToNotificationUpdate(removedNotification);

    // we need to get a new notification to replace the deleted one
    if (this.isPopupShown) {
      this.fetchNotifications();
    } else {
      // popup is closed. just raise the flag to fetch notifications next time the window opens
      this.shouldFetchNotifications = true;
    }
  }

  /**
   * Fetch notifications
   * @return {Promise} promise which resolves to the fetched notifications
   */
  fetchNotifications() {
    log.info('Fetching notifications from API');
    return this.notificationsService
      .filter('status', ['new', 'read'])
      .sort('date', 'desc')
      .setPage(1, this.notificationsLimit)
      .getListWithQuery()
      .then((notifications) => {
        this.setNotifications(notifications);
        this.shouldFetchNotifications = false; // after fetching once, block future fetches (use streamer)
        return notifications;
      });
  }

  /**
   * Open the "notification center log" popup
   *
   * @return {void}
   */
  openNotificationCenterTablePopup() {
    this.popupService.open({
      controller: 'notificationsCenterLogPopupController',
      template: logPopupTemplate,
      scope: this.$scope,
    });
  }

  /**
   * Calls on scope destroy.
   * Stops listening for new notifications
   * @return {void}
   */
  $onDestroy() {
    this.stopListening();
  }

  /**
   * Set current notifications list as batch operation. Clear all previous ones before.
   *
   * @param {Notification[]} notifications - Notification to set as current.
   * @returns {void}
   */
  setNotifications(notifications: IElementRestNg<Notification>[]) {
    this.unsubscribeUpdateForAllNotifications();

    this.notifications = notifications;

    this.notifications.forEach((notification) => {
      const callback = this.notificationsSocketService.wrapListener(
        this.onExistingNotificationUpdate.bind(this),
      );
      this.subscribeToNotifcationUpdate(notification, callback);
    });
  }

  /**
   * Unsubscribe to all notifications updates as batch operation.
   *
   * @returns {void}
   */
  unsubscribeUpdateForAllNotifications() {
    if (_.isNil(this.notifications)) {
      return;
    }

    this.notifications.forEach((notification) => {
      this.unsubscribeToNotificationUpdate(notification);
    });
  }

  /**
   * Add single notification to current notifications list.
   * - This will subscribe to updates for the notification.
   * - In case max amount of notifications as reached, the oldest one will be removed.
   *
   * @param {IElementRestNg<Notification>} notification - Notification to add.
   * @returns {void}
   */
  addNotification(notification: IElementRestNg<Notification>): void {
    // Adds it to the list (and removes an old notification if needed)
    log.info('Pushing new notification', notification);

    this.notifications.unshift(notification);

    const callback = this.notificationsSocketService.wrapListener(
      this.onExistingNotificationUpdate.bind(this),
    );
    this.subscribeToNotifcationUpdate(notification, callback);

    // too much notifications. pop the oldest one
    if (this.notifications.length > this.notificationsLimit) {
      log.debug('Popping oldest notification');
      this.removeOldestNotification();
    }
  }

  /**
   * Subscribe to notification update and keep the subscription for later unsubscribe.
   *
   * @param {IElementRestNg<Notification>} notification - Notificaiton to subscribe to.
   * @param {SocketListener} callback - Function to activate when update notice as arrived.
   * @returns {void}
   */
  subscribeToNotifcationUpdate(
    notification: IElementRestNg<Notification>,
    callback: SocketListener,
  ) {
    const channel = this.calcNotificationChannelName(
      NotificationChannelType.Update,
      notification,
    );
    this.notificationsSocketService.subscribe(channel, callback);

    this.notificationsSubs.set(notification.id, {
      channel,
      callback,
      notificationId: notification.id,
    });
  }

  /**
   * Unsubscribe to notification update.
   *
   * @param {IElementRestNg<Notification>} notification - Notification to unsubscribe from.
   * @returns {void}
   */
  unsubscribeToNotificationUpdate(
    notification: IElementRestNg<Notification>,
  ): void {
    const sub = this.notificationsSubs.get(notification.id);
    // notification from input does not get subscribed. Need to fix
    if (_.isNil(sub)) {
      return;
    }
    this.notificationsSocketService.unsubscribe(sub.channel, sub.callback);
    this.notificationsSubs.delete(notification.id);
  }

  /**
   * Get notification channel name per usage need.
   *
   * @param {NotificationChannelType} channelType - Channel type: new, update...
   * @param {IElementRestNg<Notification>} [notification] - Notification info.
   *   When 'update', register to specific notification id.
   * @returns {string} notification channel name.
   */
  calcNotificationChannelName(
    channelType: NotificationChannelType,
    notification?: IElementRestNg<Notification>,
  ): string {
    const user = this.tokensService.getCachedUser();

    // Check channelType, and return calculated channel name based on it's value.
    return _.cond([
      [
        _.eq(NotificationChannelType.New),
        () =>
          `user.${user.id}.own.${this.notificationsSocketService.channelRoot}.new`,
      ],
      [
        _.eq(NotificationChannelType.Update),
        () =>
          `user.${user.id}.${this.notificationsSocketService.channelRoot}.${notification.id}`,
      ],
    ])(channelType);
  }

  /**
   * Remove oldest notification from the notifications list.
   * - This will also clean the related subscription for the notification.
   *
   * @returns {void}
   */
  removeOldestNotification() {
    const notification = this.notifications.pop();
    this.unsubscribeToNotificationUpdate(notification);
  }

  isAsyncFileDownload(notification) {
    const asyncResources = [
      GoogleStorageResources.KIBI,
      GoogleStorageResources.CRM_EXPORT,
      GoogleStorageResources.BALANCE_LOG,
    ];
    const { resource } = notification;
    return asyncResources.includes(resource);
  }

  asyncFileDownload(notification): Promise<any> {
    return this.getFileUrl(notification).then(({ fileName, localFileUrl }) => {
      downloadLocalFileUrl(localFileUrl, fileName);
      URL.revokeObjectURL(localFileUrl);
    });
  }

  getFileUrl(notification) {
    const { filter, resource } = notification;
    let filterId;
    try {
      filterId = JSON.parse(filter).id;
    } catch (e) {
      return Promise.reject(e);
    }
    switch (resource) {
      case GoogleStorageResources.CRM_EXPORT:
        return this.privateGoogleStorageFileService.getCrmExportFileUrl(
          filterId,
        );
      case GoogleStorageResources.BALANCE_LOG:
        return this.privateGoogleStorageFileService.getBalanceLogFileUrl(
          filterId,
        );
      case GoogleStorageResources.KIBI:
        return this.privateGoogleStorageFileService.getKibiFileUrl(filterId);
      default:
        return Promise.reject('unknown notification resource');
    }
  }
}

const notificationsCenterPopupComponent = {
  template,
  controller: NotificationsCenterPopupController,
  bindings: {
    togglePopup: '&',
    isPopupShown: '<',
    notifications: '<',
  },
};

export default notificationsCenterPopupComponent;
