import ng from 'angular';

import { systemConfigurationsRouting } from './system-configurations.routing';
import { SystemConfigurationsDashboardComponent } from './components/system-configurations-dashboard/system-configurations-dashboard.component';
import { CrmLogin2faManagerComponent } from './components/crm-login-2fa-manager/crm-login-2fa-manager.component';
import { CrmSmtpConfigurationComponent } from './components/crm-smtp-configuration/crm-smtp-configuration.component';

import { PrfBeanValidationMessageComponent } from './components/crm-login-2fa-manager/prf-bean-validation-message/prf-bean-validation-message.component';

export const SystemConfigurationsModule = ng.module(
  'crm.management.integrations.system_configurations',
  [],
);

const md = SystemConfigurationsModule;

md.config(systemConfigurationsRouting);
md.component(
  'prfSystemConfigurationsDashboard',
  SystemConfigurationsDashboardComponent,
);
md.component('prfCrmLogin2faManager', CrmLogin2faManagerComponent);
md.component('prfCrmSmtpConfiguration', CrmSmtpConfigurationComponent);
md.component('prfBeanValidationMessage', PrfBeanValidationMessageComponent);
