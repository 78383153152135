import template from './link-customer-dialog.component.html';
const styles = require('./link-customer-dialog.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Brand, Customer } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { getResolveChange } from '~/source/common/utilities/rx-ng-one/operators/get-resolve-change';
import { subscribeForBeforeConstructorChanges } from '~/source/common/utilities/rx-ng-one/utils/subscribe-for-before-constructor-changes';

export class LinkCustomerDialogController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  brand$ = this.streamBrand();
  customerId$ = this.streamCustomerId();

  /*@ngInject */
  constructor() {
    subscribeForBeforeConstructorChanges(
      [this.customerId$, this.brand$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBrand() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<Customer>('brand'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCustomerId() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<number>('customerId'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

export const LinkCustomerDialogComponent = {
  template,
  controller: LinkCustomerDialogController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
