import type { ui } from 'angular';

export const LoginRouting = [
  '$stateProvider',
  ($stateProvider: ui.IStateProvider) => {
    $stateProvider
      .state('auth.login', {
        url: '/login',
        component: 'prfLoginPage',
        params: {
          token: null,
        },
      })
      .state('auth.logout', {
        url: '/logout',
        component: 'prfLogoutPage',
        data: {
          permissions: {
            only: ['loggedIn'],
            redirectTo: 'auth.login',
          },
        },
      });
  },
];
