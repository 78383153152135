import RestService from '~/source/common/services/rest';

export class DefaultFilesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'defaultFiles';
  }

  /**
   * get default user files
   */
  getDefaultFilesExpaned() {
    return this.expand(['file']).getListWithQuery();
  }
}

export default DefaultFilesService;
