import ng from 'angular';
import log from 'loglevel';

import { BaseController } from '~/source/common/controllers/base';

import template from './account-search-select.component.html';

import { SearchService } from '~/source/search/search.service';
import { TradingAccount } from '@proftit/crm.api.models.entities';
import { ItemLabelStrategy } from '~/source/common/components/dropdowns/base/item-label-strategy';
import * as rx from '@proftit/rxjs';
import { useStream } from '~/source/common/utilities/use-stream';
import * as _ from '@proftit/lodash';

interface ISearchResponse {
  id: string;
  results: TradingAccount[];
}

const INPUT_DEBOUNCE_DELAY = 300;

export class AccountSearchSelectController extends BaseController {
  onSelectOfAccount: (a: { account: TradingAccount }) => void;

  unsub$ = new rx.Subject<void>();
  inputModel$ = new rx.BehaviorSubject<string>('');
  searchWanted$ = new rx.BehaviorSubject<boolean>(false);
  results$ = new rx.BehaviorSubject<TradingAccount[]>([]);
  showResults$ = new rx.BehaviorSubject<boolean>(false);
  opResultSelect$ = new rx.Subject<TradingAccount>();

  /*@ngInject */
  constructor(readonly searchService: () => SearchService) {
    super();
  }

  $onInit() {
    useStream(this.streamSearchPerforming(), this.unsub$);
    useStream(this.streamSelecting(), this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  set inputModel(val) {
    this.inputModel$.next(val);
    this.searchWanted$.next(true);
  }

  get inputModel() {
    return this.inputModel$.getValue();
  }

  onModelChange(model) {
    this.searchWanted$.next(false);
    this.showResults$.next(false);
    this.inputModel$.next(_.get('id', model));
  }

  streamSearchPerforming() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.inputModel$, this.searchWanted$),
      rx.debounceTime(INPUT_DEBOUNCE_DELAY),
      rx.filter(([a, searchWanted]) => searchWanted),
      rx.switchMap(([inputModel, searchWanted]) => {
        // We want to return empty results without server when no search term.
        if (_.isEmpty(inputModel)) {
          return rx.obs.from([[]]);
        }

        return rx.pipe(
          () => rx.obs.from(this.performSearch(inputModel)),
          rx.catchError((err, org) => {
            log.error('account search select -> stream search perform -> ajax');
            return rx.obs.from([[]]);
          }),
        )(null);
      }),
      rx.tap(() => this.showResults$.next(true)),
      rx.tap((results) => this.results$.next(results)),
    )(null);
  }

  streamSelecting() {
    return rx.pipe(
      () => this.opResultSelect$,
      rx.tap(() => this.showResults$.next(false)),
      rx.tap((result) => this.onSelectOfAccount({ account: result })),
    )(null);
  }

  performSearch(searchTerm) {
    return this.searchService()
      .setConfig({
        blockUiRef: 'searchResults',
      })
      .search(searchTerm)
      .findTradingAccounts()
      .then((data: any) => data.results);
  }
}

export const AccountSearchSelectComponent = {
  template,
  controller: AccountSearchSelectController,
  bindings: {
    model: '<',
    validations: '<',
    onSelectOfAccount: '&',
  },
  transclude: true,
};
