import Component from '~/source/common/components/dropdowns/base/component';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { CommunicationType } from '~/source/common/models/communication-type';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import * as _ from '@proftit/lodash';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { CommunicationSubjectsStoreService } from '~/source/common/store-services/communication-subjects-store.service';

class CommunicationsSubjectByCommTypeSelect extends Component.controller {
  static $inject = [
    ...Component.controller.$inject,
    'communicationSubjectsStoreService',
  ];

  lifecycles = observeComponentLifecycles(this);
  communicationType$ = new rx.BehaviorSubject<CommunicationType>(null);
  communicationSubjectsStoreService: CommunicationSubjectsStoreService;

  constructor(a, ...args) {
    super(a, ...args);

    useStreams(
      [
        observeCompChange(
          this.communicationType$,
          'communicationType',
          this.lifecycles.onChanges$,
        ),
        this.streamData(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  get defaultPlaceholder() {
    return 'common.SELECT_SUBJECT';
  }

  /**
   * Path to translation
   *
   * @returns {string} translation path
   */
  get translationPath() {
    return 'contact.communicationSubjects';
  }

  /**
   * Name of the property that should be translated
   *
   * Override default translate source 'code' because call subject
   * is now a dynamic list, that can be changed by the user. Therefore
   * some of its values will not have translations in the contants language
   * file. Therefore, translation is not applicable for this kind of list.
   * We will show 'name' value instead.
   *
   * @returns {string}
   */
  get translateSource(): string {
    return null;
  }

  query() {
    const commType = this.communicationType$.getValue();
    return this.communicationSubjectsStoreService
      .streamSubjectsForCommunication(commType)
      .pipe(rx.take(1))
      .toPromise();
  }

  streamData() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.lifecycles.onInit$,
          this.communicationType$,
          this.communicationSubjectsStoreService.communicationSubjects$,
        ),
      rx.tap(([a, commType, types]) => {
        const prevModel = this.model;
        const prevSelectedValues = this.selectedValues;
        this.selectedValues = null;
        this.output = [];
        this.model = null;
        this.fetchData().then(() => {
          if (_.isNil(prevModel)) {
            return;
          }

          const temp = this._data.find(({ code }) => code === prevModel.code);

          if (!_.isNil(temp)) {
            this.model = temp;
            this.selectedValues = [temp];
          }
        });
      }),
    )(null);
  }
}

export const CommunicationsSubjectByCommTypeSelectComponent = Component.config({
  controller: CommunicationsSubjectByCommTypeSelect,
  bindings: {
    communicationType: '<',
  },
});
