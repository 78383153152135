import template from './update-shared-group-filter-modal.component.html';

import ng, { IScope } from 'angular';
import { DepartmentsService } from '~/source/management/user/services/departments';
import BaseController from '~/source/common/controllers/base';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Department } from '~/source/common/models/department';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { UserFilters } from '~/source/common/services/user-filters';
import { UserFilter } from '~/source/common/models/user-filter';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { TABLE_FILTER_UPDATE_DROPDOWN_MODEL } from '~/source/common/constants/general-pubsub-keys';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  SharedGroupFiltersPivot,
  UserDepartmentCode,
} from '@proftit/crm.api.models.entities';
import { SharedGroupFiltersPivotService } from '~/source/common/services/shared-group-filters-pivot.service';

interface DepartmentSelection {
  department: Department;
  isSelected: boolean;
}

export class UpdateSharedGroupFilterModalController extends BaseController {
  close: () => void;

  lifecycles = observeComponentLifecycles(this);

  resolve$ = observeShareCompChange<{ filterId: number }>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  filterId$ = this.resolve$.pipe(
    rx.map((resolve) => resolve.filterId),
    shareReplayRefOne(),
  );

  departments$ = this.streamDepartments();

  filterData$ = this.streamFilterData();

  departmentsSelection$ = new rx.BehaviorSubject<DepartmentSelection[]>([]);
  opSetSelectedDept$ = new rx.Subject<{
    isSelected: boolean;
    deptId: number;
  }>();
  opSave$ = new rx.Subject<void>();
  confirmButtonTextCode$ = new rx.BehaviorSubject<string>('SHARE');
  confirmButtonIcon$ = new rx.BehaviorSubject<string>('pf-share');
  formProxy = {};
  growlId = 'updateSharedGroupFilterModalGrowl';
  blockUiId = 'updateSharedGroupFilterModalBlockUi';
  title = 'user.SHARE_FILTER_GROUP';
  isFormValid$ = this.streamIsFormValid();

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly departmentsService: () => DepartmentsService,
    readonly sharedGroupFiltersPivotService: SharedGroupFiltersPivotService,
    readonly userFiltersService: UserFilters,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();

    useStreams([this.resolve$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamCalcDepartmentsSelection(),
        this.streamCalcFormProxy(),
        this.streamCalcDepartmentsSelectionFromSelectAction(),
        this.streamSaveFilterDepartments(),
        this.streamCalcConfirmButtonText(),
        this.streamCalcConfirmButtonIcon(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges(changes: ng.IOnChangesObject): void {}

  $onDestroy() {}

  streamDepartments() {
    return rx.pipe(
      () => rx.obs.defer(() => rx.obs.from(this.getDepartments())),
      shareReplayRefOne(),
    )(null);
  }

  streamFilterData() {
    return rx.pipe(
      () => this.filterId$,
      rx.switchMap((id) => rx.obs.from(this.getFilterData(id))),
      rx.catchError(() => rx.obs.from([])),
      shareReplayRefOne(),
    )(null);
  }

  streamCalcDepartmentsSelection() {
    return rx.pipe(
      () => rx.obs.combineLatest([this.departments$, this.filterData$]),
      rx.map(([departments, filterData]) => {
        if (_.isNil(departments) || _.isNil(filterData)) {
          return [];
        }

        return this.calcDepartmentSelection(departments, filterData);
      }),
      rx.tap((deptsSelection) =>
        this.departmentsSelection$.next(deptsSelection),
      ),
    )(null);
  }

  streamCalcFormProxy() {
    return rx.pipe(
      () => this.departmentsSelection$,
      rx.map((deptsSelection) => this.calcFormProxy(deptsSelection)),
      rx.tap((formProxy) => (this.formProxy = formProxy)),
    )(null);
  }

  streamCalcDepartmentsSelectionFromSelectAction() {
    return rx.pipe(
      () => this.opSetSelectedDept$,
      rx.withLatestFrom(this.departmentsSelection$),
      rx.map(([opSetSelected, deptsSelection]) => {
        const index = _.findIndex(
          (x) => x.department.id === opSetSelected.deptId,
          deptsSelection,
        );
        const newItem = {
          ...deptsSelection[index],
          isSelected: opSetSelected.isSelected,
        };
        const newDeptsSelection = [...deptsSelection];
        newDeptsSelection.splice(index, 1, newItem);

        return newDeptsSelection;
      }),
      rx.tap((deptsSelection) =>
        this.departmentsSelection$.next(deptsSelection),
      ),
    )(null);
  }

  streamSaveFilterDepartments() {
    return rx.pipe(
      () => this.opSave$,
      rx.withLatestFrom(this.departmentsSelection$, this.filterId$),
      rx.switchMap(([a, deptsSelection, userFilterId]) =>
        rx.pipe(
          () =>
            rx.obs.from(
              this.saveFilterDepartments(deptsSelection, userFilterId),
            ),
          rx.tap(() => this.close()),
          rx.tap((filter) =>
            this.prfClientGeneralPubsub.publish(
              TABLE_FILTER_UPDATE_DROPDOWN_MODEL,
              {
                filter,
                isUpdate: true,
              },
            ),
          ),
          rx.catchError(() => rx.obs.from([])),
        )(null),
      ),
    )(null);
  }

  streamCalcConfirmButtonText() {
    return rx.pipe(
      () => this.filterData$,
      rx.filter((filter) => !_.isNil(filter)),
      rx.map((filterData) => filterData.length > 0),
      rx.map((hasAssocDepts) => (hasAssocDepts ? 'UPDATE' : 'common.SHARE')),
      rx.tap((textCode) => this.confirmButtonTextCode$.next(textCode)),
    )(null);
  }

  streamCalcConfirmButtonIcon() {
    return rx.pipe(
      () => this.filterData$,
      rx.filter((filterData) => !_.isNil(filterData)),
      rx.map((filterData) => filterData.length > 0),
      rx.map((hasAssocDepts) => (hasAssocDepts ? 'pf-refresh' : 'pf-share')),
      rx.tap((textCode) => this.confirmButtonIcon$.next(textCode)),
    )(null);
  }

  streamIsFormValid() {
    return rx.pipe(() => rx.obs.from([true]))(null);
  }

  getDepartments() {
    return this.departmentsService()
      .setConfig({ blockUiRef: this.blockUiId, growlRef: this.growlId })
      .getListWithQuery<IElementRestNg<Department>>();
  }

  getFilterData(id: number): Promise<SharedGroupFiltersPivot[]> {
    return this.sharedGroupFiltersPivotService
      .setConfig({ blockUiRef: this.blockUiId, growlRef: this.growlId })
      .getFilterData(id);
  }

  calcDepartmentSelection(
    departments: UserDepartmentCode[],
    filterDepartments: SharedGroupFiltersPivot[],
  ) {
    return departments.map((dept) => ({
      department: dept,
      isSelected: filterDepartments.some((x) => x.departmentId === dept.id),
    }));
  }

  calcFormProxy(deptsSelection: DepartmentSelection[]) {
    const opSetSelectedDept$ = this.opSetSelectedDept$;

    return deptsSelection.reduce((acc, deptSelection) => {
      Object.defineProperty(acc, deptSelection.department.id, {
        get() {
          return deptSelection.isSelected;
        },
        set(isSelected) {
          opSetSelectedDept$.next({
            isSelected,
            deptId: deptSelection.department.id,
          });
        },
      });

      return acc;
    }, {});
  }

  saveFilterDepartments(deptsSelection, userFilterId: number) {
    const departmentsConns = deptsSelection
      .filter((x) => x.isSelected)
      .map((x) => ({ departmentId: x.department.id }));

    return this.userFiltersService
      .setConfig({ blockUiRef: this.blockUiId, growlRef: this.growlId })
      .overwriteSharedGroupFilters(userFilterId, departmentsConns);
  }
}

export const UpdateSharedGroupFilterModalComponent = {
  template,
  controller: UpdateSharedGroupFilterModalController,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
