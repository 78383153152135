import template from './questionnaire-score-item.component.html';
const styles = require('./questionnaire-score-item.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  getCompChange,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { switchOn } from '~/source/common/utilities/switch-on';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { requestWithComponent } from '~/source/common/utilities/request-with-component';
import { QuestionnaireScoresService } from '~/source/common/services/questionnaire-scores.service';
import QuestionnaireService from '../questionnaire.service';
import { IPromise } from 'restangular';
import {
  QuestionnaireScore,
  QuestionnaireForm,
  QuestionnaireComponent,
  QuestionnaireComponentScore,
} from '@proftit/crm.api.models.entities';
import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';
import { normalizeFieldsToIds } from './utilities/normalize-fields-to-id';
import { combineScoresWithSchema } from './utilities/combine-score-with-schema';
import { createNewQuestionnaireScore } from './utilities/create-new-questionnaire-score';
import { createScoreStructureFromQuestion } from './utilities/create-score-structure-from-question';
import { generateUuid } from '@proftit/general-utilities';
import { generateDefaultFailedSegment } from '../utilities/generate-default-failed-segment';
import { generateDefaultPassedSegment } from '../utilities/generate-default-passed-segment';
import { generateSegment } from '../utilities/generate-segment';

const CREATE_TITLE = 'questionnaireScoreItem.TITLE_CREATE';
const UPDATE_TITLE = 'questionnaireScoreItem.TITLE_UPDATE';

const UnscoreableQuestionnaireComponentTypes = [
  QuestionnaireComponentTypeCode.Header,
  QuestionnaireComponentTypeCode.Question,
];

export class QuestionnaireScoreItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  opSave$ = new rx.Subject<void>();

  modelGeneralValue$ = new rx.BehaviorSubject<any>(null);
  modelGeneralIsValid$ = new rx.BehaviorSubject<boolean>(null);
  modelGeneralIsPristine$ = new rx.BehaviorSubject<boolean>(null);

  modelSegmentsValue$ = new rx.BehaviorSubject<any>(null);
  modelSegmentsIsValid$ = new rx.BehaviorSubject<boolean>(null);
  modelSegmentsIsPristine$ = new rx.BehaviorSubject<boolean>(null);

  modelUnifiedScoresValue$ = new rx.BehaviorSubject<any>(null);
  modelUnifiedScoresIsValid$ = new rx.BehaviorSubject<boolean>(null);
  modelUnifiedScoresPristine$ = new rx.BehaviorSubject<boolean>(null);

  action$ = observeShareCompChange<CrudOpeartion>(
    this.lifecycles.onChanges$,
    'action',
  );
  id$ = observeShareCompChange<number>(this.lifecycles.onChanges$, 'id');
  questionnaireFormId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'questionnaireFormId',
  );
  pageTitle$ = this.streamPageTitle();
  model$ = this.streamModel();
  modelGeneral$ = this.streamModelGeneral();
  modelSegments$ = this.streamModelSegments();
  modelUnifiedScores$ = this.streamModelUnifiedScores();
  isModelValid$ = this.streamIsModelValid();
  isDisableSelectQuestionnaireForm$ = this.streamIsDisableSelectQuestionnaireForm();

  /*@ngInject */
  constructor(
    readonly questionnaireScoresService: () => QuestionnaireScoresService,
    readonly questionnaireService: () => QuestionnaireService,
    readonly $state: ng.ui.IStateService,
  ) {
    useStreams(
      [this.pageTitle$, this.model$, this.action$, this.streamActionSave()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamPageTitle() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<string>('action'),
      rx.map((action) => {
        return switchOn(
          {
            [CrudOpeartion.Create]: () => CREATE_TITLE,
            [CrudOpeartion.Update]: () => UPDATE_TITLE,
          },
          action,
          () => {
            throw new Error('unhandled action');
          },
        );
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamModel(): rx.Observable<Partial<QuestionnaireScore>> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.action$,
          this.id$.pipe(rx.startWith(null)),
          this.questionnaireFormId$.pipe(rx.startWith(null)),
        ),
      rx.filter(([action, id, questionnaireFormId]) =>
        switchOn(
          {
            [CrudOpeartion.Create]: () => true,
            [CrudOpeartion.Update]: () =>
              [action, id, questionnaireFormId].every((x) => !_.isNil(x)),
          },
          action,
          () => false,
        ),
      ),
      rx.switchMap(([action, id, questionnaireFormId]) => {
        return switchOn(
          {
            create: () => rx.obs.from([createNewQuestionnaireScore()]),
            update: () =>
              requestWithComponent(
                (s) =>
                  s
                    .expand(['questionnaireForm'])
                    .embed(['segments', 'componentScores'])
                    .getQestionnaireScore(questionnaireFormId, id) as Promise<
                    QuestionnaireScore
                  >,
                () => rx.obs.NEVER,
                this.questionnaireService(),
                this,
              ) as rx.Observable<QuestionnaireScore>,
          },
          action,
          () => {
            throw new Error('unimplemented action');
          },
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamModelGeneral() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) =>
        _.pick(['id', 'name', 'isActive', 'questionnaireForm'], model),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamModelSegments() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.model$, this.action$),
      rx.map(([model, action]) => _.get(['segments'], model)),
      rx.map((segments) => _.defaultTo([], segments)),
      rx.withLatestFrom(this.action$),
      rx.map(([segments, action]) => {
        return switchOn(
          {
            [CrudOpeartion.Create]: () => {
              const firstSegment = generateDefaultFailedSegment();
              const secondSegment = generateDefaultPassedSegment();

              return [...segments, firstSegment, secondSegment];
            },
          },
          action,
          () => segments,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamModelScores() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => model.componentScores),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedQuestionnaireFormQuestions() {
    return rx.pipe(
      () => this.modelGeneralValue$,
      rx.map((generalValue) => _.get(['questionnaireForm'], generalValue)),
      rx.distinctUntilChanged(),
      rx.switchMap((questionnaireForm) => {
        if (_.isNil(questionnaireForm)) {
          return rx.obs.from([null]);
        }

        return requestWithComponent(
          (s) =>
            s
              .embed(['components'])
              .getQuestionnaire(questionnaireForm.id) as Promise<
              QuestionnaireForm
            >,
          () => rx.obs.EMPTY,
          this.questionnaireService(),
          this,
        ) as rx.Observable<QuestionnaireForm>;
      }),
      rx.map((questionnaireForm) => {
        if (_.isNil(questionnaireForm)) {
          return [];
        }
        return _.defaultTo([], questionnaireForm.components);
      }),
      rx.map((questions) =>
        questions.filter(
          (q) => !UnscoreableQuestionnaireComponentTypes.includes(q.type),
        ),
      ),
      rx.map((questions) =>
        questions.map((q) => createScoreStructureFromQuestion(q)),
      ),
    )(null);
  }

  streamModelUnifiedScores() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.streamModelScores(),
          this.streamSelectedQuestionnaireFormQuestions(),
        ),
      rx.map(([componentScores, scoresSchema]) =>
        combineScoresWithSchema(componentScores as any, scoresSchema),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamIsModelValid() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.modelGeneralIsValid$,
          this.modelSegmentsIsValid$,
          this.modelUnifiedScoresIsValid$,
        ),
      rx.map((isValidList) => isValidList.every((v) => v)),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamIsDisableSelectQuestionnaireForm() {
    return rx.pipe(
      () => this.action$,
      rx.map((action) =>
        switchOn(
          {
            [CrudOpeartion.Create]: () => false,
            [CrudOpeartion.Update]: () => true,
          },
          action,
          () => {
            throw new Error('unhandled action');
          },
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamActionSave() {
    return rx.pipe(
      () => this.opSave$,
      rx.withLatestFrom(
        this.model$,
        this.modelGeneralValue$,
        this.modelSegmentsValue$,
        this.modelUnifiedScoresValue$,
      ),
      rx.map(([op, model, general, segments, unifiedScores]) => {
        return {
          ...model,
          ...general,
          segments,
          componentScores: unifiedScores,
        };
      }),
      rx.map((model) => normalizeFieldsToIds(model, ['questionnaireForm'])),
      rx.withLatestFrom(this.action$),
      rx.switchMap(([model, action]) => {
        return switchOn(
          {
            [CrudOpeartion.Create]: () =>
              requestWithComponent(
                (s) =>
                  s.createQuestionnaireScore(
                    model.questionnaireFormId,
                    model,
                  ) as Promise<QuestionnaireScore>,
                () => rx.obs.NEVER,
                this.questionnaireService(),
                this,
              ) as rx.Observable<QuestionnaireScore>,
            [CrudOpeartion.Update]: () =>
              requestWithComponent(
                (s) =>
                  s.updateQuestionnaireScore(
                    model.questionnaireFormId,
                    model.id,
                    model,
                  ) as Promise<QuestionnaireScore>,
                () => rx.obs.NEVER,
                this.questionnaireService(),
                this,
              ) as rx.Observable<QuestionnaireScore>,
          },
          action,
          () => {
            throw new Error('unhandled action');
          },
        );
      }),
      rx.tap(() => {
        this.$state.go('crm.management.tools.questionnaire.dashboard');
      }),
    )(null);
  }
}

export const QuestionnaireScoreItemComponent = {
  template,
  controller: QuestionnaireScoreItemController,
  bindings: {
    action: '<',
    id: '<',
    questionnaireFormId: '<',
  },
};
