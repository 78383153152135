import ng from 'angular';

import LoginComponent from './login.component';
import LogoutComponent from './logout.component';
import { LoginRouting } from './login.routing';
import { LoginAuthFormComponent } from './login-auth-form/login-auth-form.component';
import { LoginCredentialsFormComponent } from './login-credentials-form/login-credentials-form.component';

export const LoginModule = ng.module('crm.auth.login', [
  'ui.router',
  'permission',
]);

const md = LoginModule;
md.component('prfLoginPage', LoginComponent);
md.component('prfLogoutPage', LogoutComponent);
md.component('prfLoginAuthForm', LoginAuthFormComponent);
md.component('prfLoginCredentialsForm', LoginCredentialsFormComponent);

md.config(LoginRouting);
