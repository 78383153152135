import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import ManageController, {
  QuestionnaireUserAction,
} from '../manage.controller';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import Questionnaire from '~/source/common/models/questionnaire';

import template from '../form.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

class Controller extends ManageController {
  static $inject = [...ManageController.$inject];

  model: IElementRestNg<Questionnaire>;

  shouldDisableInputsFromQueryAction$ = this.streamShouldDisableInputsFromQueryAction();

  constructor(...args) {
    super(...args);
    useStreams(
      [this.shouldDisableInputsFromQueryAction$],
      this.lifecycles.onDestroy$,
    );
  }

  /**
   * called by constructor on init
   * @returns {Promise} returns a promise which resolves with the current edited questionnaire model
   */
  initQuestionnaireModel(langCode) {
    return this.questionnaireService()
      .setConfig({
        growlRef: 'questionnaireForm',
        blockUiRef: 'questionnaireForm',
      })
      .customGetWithHeaders(
        this.$state.params.id,
        {
          '_expand[]': ['brand', 'languages.language'],
          '_embed[]': ['components', 'languages'],
        },
        {
          'Accept-Language': langCode,
        },
      )
      .then((res) => {
        const langForForm = res
          .plain()
          .languages.find((l) => l.language.code2 === langCode);
        const isActive = !_.isNil(langForForm) ? langForForm.isActive : false;
        return {
          isActive,
          ...res.plain(),
        };
      });
  }

  streamShouldDisableInputsFromQueryAction() {
    return rx.pipe(
      () => this.userActionFromQueryString$,
      rx.map(
        (action: QuestionnaireUserAction) =>
          ![
            QuestionnaireUserAction.CopyForm,
            QuestionnaireUserAction.Edit,
          ].includes(action),
      ),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * patch form
   * @return {Promise}
   */
  submitForm() {
    // normalize form components
    const normalized = this.normalize();

    const { language: languageOfCurrentForm } = normalized;
    const initialTranslationLangEntity = normalized.languages.find(
      (languageEntity) =>
        languageEntity.language.id === Number(this.$state.params.langId),
    );
    normalized.languages = [
      { languageId: languageOfCurrentForm.id, isActive: normalized.isActive },
    ] as any[];

    const userActionFromQuery = this.userActionFromQueryStringBS$.getValue();
    if (userActionFromQuery !== QuestionnaireUserAction.CopyForm) {
      normalized.parentId = normalized.parentId || normalized.id;
    }

    if (
      userActionFromQuery !== QuestionnaireUserAction.CopyForm &&
      userActionFromQuery !== QuestionnaireUserAction.AddLang
    ) {
      normalized.languages[0].id = initialTranslationLangEntity?.id;
    }

    const headerLangCode2Value =
      userActionFromQuery === QuestionnaireUserAction.Edit
        ? initialTranslationLangEntity.language.code2
        : languageOfCurrentForm.code2;

    return this.questionnaireService()
      .setConfig({
        growlRef: 'questionnaireForm',
        blockUiRef: 'questionnaireForm',
      })
      .customPostWithHeaders(normalized, '', null, {
        'Accept-Language': headerLangCode2Value,
      })
      .then((res) => {
        this.hasModelChangedFromManualUpdateAction.next(false);
        this.$state.go('^.edit', {
          id: res.id,
          langId: normalized.language.id,
          action: this.QuestionnaireUserAction.Edit,
        });
      });
  }

  /**
   * normalize patch data
   */
  normalize() {
    const form = _.cloneDeep(this.model) as any;

    form.components = this.normalizeComponents(form.components);

    form.brandId = form.brand.id;
    delete form.brand;

    if (
      this.userActionFromQueryStringBS$.value ===
      QuestionnaireUserAction.CopyForm
    ) {
      delete form.id;
      delete form.parentId;
      delete form.version;
      delete form.isHead;
      const formWithoutIds = this.removeIdsFromComponents(form);
      return formWithoutIds;
    }

    return form;
  }

  removeIdsFromComponents(form) {
    const cleanForm = _.cloneDeep(form);
    const { components } = cleanForm;
    components.forEach((comp) => {
      delete comp.id;
      const { options } = comp;
      if (!_.isNil(options)) {
        options.forEach((opt) => {
          delete opt.id;
          delete opt.component_id;
        });
      }
    });
    return cleanForm;
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
};
