import { IWindowService } from 'angular';

/**
 */
export class ExternalWindowService {
  static $inject = ['$window'];

  constructor(readonly $window: IWindowService) {}

  openContentInCenter(content: string) {
    const attrs = popupCenter(800, 700, this.$window);
    const previewWindow = this.$window.open('', 'previewWindow', attrs);
    if (this.$window.focus) {
      previewWindow.focus();
    }
    previewWindow.document.body.innerHTML = content;
  }
}

export function popupCenter(w, h, window) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const attrs = `scrollbars=yes, width=${w / systemZoom}, height=${
    h / systemZoom
  }, top=${top}, left=${left}`;

  return attrs;
}
