import RestService from '~/source/common/services/rest';
import * as _ from '@proftit/lodash';
import { IElementRestNg } from '../../models/ielement-rest-ng';
import { ContentTemplatesPreview } from '../../models/content-templates-preview';

export class ContentTemplatesPreviewsService extends RestService {
  static $inject = [...RestService.$inject];

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'contentTemplatesPreview';
  }

  /**
   * Get item
   */
  getItem(id: number) {
    return this.getOneWithQuery<IElementRestNg<ContentTemplatesPreview>>(
      id,
    ).then((data) => data.plain());
  }
}
