import template from './editable-value-control.component.html';
const styles = require('./editable-value-control.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

enum EditModes {
  Edit = 'edit',
  Cancel = 'cancel',
  Save = 'save',
}

export class EditableValueControlController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  formControl$ = observeShareCompChange<FormControl<string>>(
    this.lifecycles.onChanges$,
    'formControl',
  );

  EditModes = EditModes;
  editModeAction = new rx.Subject<EditModes>();
  editMode$ = this.streamEditMode();

  /*@ngInject */
  constructor() {
    useStreams(
      [this.formControl$, this.editMode$, this.streamSetFormControlValue()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamEnterEditMode() {
    return rx.pipe(
      () => this.editModeAction,
      rx.filter((editMode) => editMode === EditModes.Edit),
      rx.map(() => true),
    )(null);
  }

  streamCancelEditMode() {
    return rx.pipe(
      () => this.editModeAction,
      rx.filter((editMode) => editMode === EditModes.Cancel),
      rx.map(() => false),
    )(null);
  }

  streamSaveEditMode() {
    return rx.pipe(
      () => this.editModeAction,
      rx.filter((editMode) => editMode === EditModes.Save),
      rx.map(() => false),
    )(null);
  }

  streamEditMode() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamEnterEditMode(),
          this.streamCancelEditMode(),
          this.streamSaveEditMode(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamSetFormControlValueFromEdit(): rx.Observable<string> {
    return rx.pipe(
      () => this.editModeAction,
      rx.filter((editMode) => editMode === EditModes.Edit),
      rx.withLatestFrom(
        this.formControl$.pipe(
          rx.switchMap((formControl: any) => formControl.value$),
        ),
      ),
      rx.map(([a, fromControlValue]) => fromControlValue as string),
    )(null);
  }

  streamSetFormControlValueFromCancel(
    fromControlValue$,
  ): rx.Observable<string> {
    return rx.pipe(
      () => this.editModeAction,
      rx.filter((editMode) => editMode === EditModes.Cancel),
      rx.withLatestFrom(fromControlValue$, this.formControl$),
      rx.tap(([a, value, fromControl]) =>
        fromControl.setValue(value as string),
      ),
      rx.map(([a, value, fromControl]) => value as string),
    )(null);
  }

  streamSetFormControlValue() {
    const fromControlValue$ = new rx.BehaviorSubject<string>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSetFormControlValueFromEdit(),
          this.streamSetFormControlValueFromCancel(fromControlValue$),
        ),
      rx.tap((value) => fromControlValue$.next(value)),
    )(null);
  }
}

export const EditableValueControlComponent = {
  template,
  bindings: {
    formControl: '<',
    editPermission: '<',
  },
  controller: EditableValueControlController,
};
