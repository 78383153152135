import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';

export class UserCurrenciesService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'currencies';
  }
}
