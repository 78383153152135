import SocketService from '~/source/common/services/socket';

class NotificationsSocketService extends SocketService {
  get channelRoot() {
    return 'UserNotification';
  }
}

NotificationsSocketService.$inject = [...SocketService.$inject];

export default NotificationsSocketService;
