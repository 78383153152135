import { Entity } from '@proftit/crm.api.models.general';

export interface FeeType extends Entity {
  code: string;
  name: string;
}

export enum FeeTypeCodes {
  Commission = 'commission',
  Inactivity = 'inactivity',
  Psp = 'psp',
}

export default FeeType;
