import type { ITranscludeFunction } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateUuid } from '@proftit/general-utilities';
import template from './simple-dropdown-item.component.html';
const styles = require('./simple-dropdown-item.component.scss');

export class SimpleDropdownItemController {
  item: any;

  isSelected: boolean;

  styles = styles;

  mainId = generateUuid();

  lifecycles = observeComponentLifecycles(this);

  // inputs

  itemInput$ = observeShareCompChange<object>(
    this.lifecycles.onChanges$,
    'item',
  );

  isSelectedInput$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'isSelected',
  );

  // state

  childData$ = this.streamChildData();

  /* @ngInject */
  constructor(readonly $transclude: ITranscludeFunction) {
    useStreams(
      [this.itemInput$, this.isSelectedInput$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamChildData() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.itemInput$, this.isSelectedInput$),
      rx.map(([item, isSelected]) => ({ item, isSelected })),
      rx.startWith({}),
      shareReplayRefOne(),
    )(null);
  }
}

export const SimpleDropdownItemComponent = {
  template,
  controller: SimpleDropdownItemController,
  transclude: true,
  bindings: {
    item: '<',
    isSelected: '<',
  },
};
