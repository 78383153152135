import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './email-center-popup.component.html';
import BaseController from '~/source/common/controllers/base';
import TablePopupController from '~/source/common/components/table/table-popup';
import emailCenterPopupSettings from './email-center-popup-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import { getSandboxedIframe } from '@proftit/dom-utilities';
import { ExternalWindowService } from '~/source/common/services/external-window.service';
import { CustomerCommunicationStatusCode } from '@proftit/crm.api.models.enums';
import { Communication } from '~/source/common/models/communication';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import TokensService from '~/source/auth/services/tokens';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { User } from '@proftit/crm.api.models.entities';
import UsersService from '~/source/management/user/services/users';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { EmailCenterService } from '~/source/common/services/email-center.service';

const styles = require('./email-center-popup.component.scss');

interface EmailExtendedCommunication extends Communication {
  _parsedDetails: {
    body: string;
    subject: string;
    attachmentsCount: number;
  };
}

export class EmailCenterPopupController extends TablePopupController<
  EmailCenterService,
  EmailExtendedCommunication
> {
  static $inject = [
    'prfEmailCenterService',
    'customersService',
    'externalWindowService',
    'tokensService',
    'usersService',
    ...TablePopupController.$inject,
  ];

  CustomerCommunicationStatusCode = CustomerCommunicationStatusCode;

  customersService: () => CustomersService;
  externalWindowService: ExternalWindowService;
  tokensService: TokensService;
  usersService: () => UsersService;
  prfEmailCenterService: EmailCenterService;
  resolve: {
    filter: CustomerCommunicationStatusCode;
  };

  queryFilter: CustomerCommunicationStatusCode;

  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  emailCenterPopupSettingsCopy;
  dataServiceInstance: EmailCenterService;

  onSearchFocusAction = new rx.Subject();
  onSearchBlurAction = new rx.Subject();
  clearSearchStringAction = new rx.Subject();

  selectedUserEmailFormControl = new FormControl<{ label: string }>(null);
  selectedUserEmail$ = new rx.BehaviorSubject<string>(null);
  searchStringFormControl = new FormControl<string>('');
  searchPageToken$ = new rx.BehaviorSubject<string>(null);
  isInSearchMode$ = new rx.BehaviorSubject<boolean>(false);
  resetPageTokenAction = new rx.Subject<void>();

  cachedUser$ = this.streamCachedUser();
  user$ = this.streamUser();
  userEmails$ = this.streamUserEmails();
  userEmailDropdownItems$ = this.streamUserEmailDropdownItems();
  shouldHideEmailDropdown$ = this.streamShouldHideEmailDropdown();

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.selectedUserEmailFormControl.value$,
        this.selectedUserEmail$,
        this.cachedUser$,
        this.user$,
        this.userEmails$,
        this.userEmailDropdownItems$,
        this.shouldHideEmailDropdown$,
        this.streamSearchStringFromClear(),
        this.streamSelectedUserEmail(),
        this.streamSearchEmails(),
        this.streamResetPageToken(),
      ],
      this.lifecycles.onDestroy$,
    );
    this.emailCenterPopupSettingsCopy = _.cloneDeep(emailCenterPopupSettings);
    this.queryFilter = this.resolve.filter;
  }

  $onDestroy() {}

  $onChanges() {}

  isLoadMoreDisabled(): boolean {
    if (_.isNil(this.isInSearchMode$) || !this.isInSearchMode$.getValue()) {
      return super.isLoadMoreDisabled();
    }
    if (this.getDataInProgress) {
      return true;
    }
    return this.searchPageToken$.getValue() === '';
  }

  streamResetPageToken() {
    return rx.pipe(
      () => this.resetPageTokenAction,
      rx.tap(() => {
        this.searchPageToken$.next(null);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedUserEmail() {
    return rx.pipe(
      () => this.selectedUserEmailFormControl.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((value) => {
        this.selectedUserEmail$.next(value.label);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSearchStringFromClear() {
    return rx.pipe(
      () => this.clearSearchStringAction,
      rx.tap(() => {
        this.clearTheTable();

        this.isInSearchMode$.next(false);
        this.resetPageTokenAction.next();
        this.searchStringFormControl.setValue(null);

        this.refreshTheTable();
      }),
      shareReplayRefOne(),
    )(null);
  }

  clearTheTable() {
    this.collection = [] as any;
    this.collection$.next([]);
  }

  scrollToTopOfTheTable() {
    const infiniteScrollTable = $(
      '[data-prf-id="email-center-popup-component-infinity-table"]',
    ).get(0);
    infiniteScrollTable.scroll(0, 0);
  }

  refreshTheTable() {
    // scroll to the top of the table, so we'll always load the first set of data, and so we won't load everything all at once.
    this.scrollToTopOfTheTable();

    if (this.tableParams.page() === 1) {
      this.reloadTable();
    } else {
      this.resetNgTablePage();
    }
  }

  streamCachedUser() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => this.tokensService.getCachedUser()),
      rx.filter((user) => !_.isNil(user)),
      shareReplayRefOne(),
    )(null);
  }

  streamUser() {
    return rx.pipe(
      () => this.cachedUser$,
      rx.filter((cachedUser) => !_.isNil(cachedUser)),
      rx.switchMap((cachedUser) => {
        return rx.obs
          .from(
            this.usersService()
              .embed(['brands.emails', 'brands.emailCredentials'])
              .getOneWithQuery<IElementRestNg<User>>(cachedUser.id),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUserEmails() {
    return rx.pipe(
      () => this.user$,
      rx.map((user) => {
        const brandsWithActiveEmailProvider = user.brands.filter(
          (brand) =>
            brand.emailCredentials.length > 0 &&
            brand.emailCredentials[0].isEmailProviderActive,
        );
        const emailObjects = brandsWithActiveEmailProvider.reduce(
          (acc, brand) => {
            const brandConnectedEmails = brand.emails.filter(
              (email) => email.isConnected,
            );
            return [...acc, ...brandConnectedEmails];
          },
          [],
        );
        return emailObjects.map((emailObj) => emailObj.email);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUserEmailDropdownItems() {
    return rx.pipe(
      () => this.userEmails$,
      rx.map((emails) => {
        return emails.map((email) => {
          return {
            label: email,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamShouldHideEmailDropdown() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamShouldHideEmailDropdownFromSearchFocus(),
          this.streamShouldHideEmailDropdownFromSearchBlur(),
        ),
      rx.startWith(true),
      shareReplayRefOne(),
    )(null);
  }

  streamShouldHideEmailDropdownFromSearchFocus() {
    return rx.pipe(
      () => this.onSearchFocusAction,
      rx.map((e) => false),
      shareReplayRefOne(),
    )(null);
  }

  streamShouldHideEmailDropdownFromSearchBlur() {
    return rx.pipe(
      () => this.onSearchBlurAction,
      rx.withLatestFrom(this.searchStringFormControl.value$),
      // isNil is here on purpose. only if its nil or empty, we should hide the email dropdown.
      rx.filter(
        ([a, searchString]) => _.isNil(searchString) || _.isEmpty(searchString),
      ),
      rx.map((e) => true),
      shareReplayRefOne(),
    )(null);
  }

  get cols() {
    return [...this.emailCenterPopupSettingsCopy.tableColumns];
  }

  get ngTableSettings() {
    return { ...this.emailCenterPopupSettingsCopy.popupTable.ngTable };
  }

  get title() {
    return 'common.EMAIL_CENTER';
  }

  get tableKey() {
    return 'emailCenterPopup';
  }

  streamSearchEmails() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.searchStringFormControl.value$,
          this.selectedUserEmail$,
        ]),
      rx.filter(
        ([searchString, userEmail]) =>
          (!_.isNil(searchString) || !_.isEmpty(searchString)) &&
          !_.isNil(userEmail),
      ),
      rx.debounceTime(500),
      rx.tap(() => {
        this.isInSearchMode$.next(true);
        this.resetPageTokenAction.next();

        this.clearTheTable();
        this.refreshTheTable();
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    const { customerId } = this.normalizedFilters as any;
    return this.dataServiceInstance.getEmails(
      this.queryFilter,
      this.searchStringFormControl.value,
      this.selectedUserEmail$.getValue(),
      customerId,
      this.searchPageToken$.getValue(),
    );
  }

  onFiltersChange(event, filterModels) {
    this.clearTheTable();
    this.resetPageTokenAction.next();
    this.scrollToTopOfTheTable();
    super.onFiltersChange(event, filterModels);
  }

  sortBy($column, event): void {
    this.clearTheTable();
    this.resetPageTokenAction.next();
    this.scrollToTopOfTheTable();
    super.sortBy($column, event);
  }

  parseLoadedData(
    data: EmailExtendedCommunication[],
  ): EmailExtendedCommunication[] {
    const {
      lastResponseHeaders,
    } = this.dataServiceInstance.prfEmailSearchService;
    if (!_.isNil(lastResponseHeaders) && this.isInSearchMode$.getValue()) {
      const pageTokenHeader = lastResponseHeaders['pagetoken'];
      this.searchPageToken$.next(pageTokenHeader);
    }

    const newData = data.map((item) => {
      try {
        const detailsObj = JSON.parse(item.details);
        item._parsedDetails = detailsObj;
        return item;
      } catch (e) {
        return item;
      }
    });
    return newData;
  }

  switchFilterTab(newFilter: CustomerCommunicationStatusCode) {
    if (this.queryFilter === newFilter) {
      return;
    }
    this.queryFilter = newFilter;
    this.clearSearchStringAction.next(null);
  }

  openEmailPreview(customerId: number, communicationId: number) {
    const commIndexInCollection = this.collection.findIndex(
      (x) => x.id === communicationId,
    );

    return this.customersService()
      .setConfig({ blockUiRef: this.blockUiKey })
      .getCommunicationPreview(customerId, communicationId)
      .then((res) => {
        const { body, subject, attachmentsCount } = res.plain();
        const sandboxedIframe = getSandboxedIframe(
          body,
          subject,
          attachmentsCount,
        );
        this.externalWindowService.openContentInCenter(sandboxedIframe);
        this.collection[commIndexInCollection].isUnread = false;
      })
      .catch(() => null);
  }
}

export const EmailCenterPopupComponent = {
  template,
  controller: EmailCenterPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
