import * as _ from '@proftit/lodash';
import ng from 'angular';

import { ngValidationMaxSmsLength } from '@proftit/validations';
import {
  validatePassword,
  returnCodes,
} from './validations/password-validation';
import decimalNumber from './validations/decimal-number';
import minFloat from './validations/min-float';
import maxFloat from './validations/max-float';
import maxDecimalPoints from './validations/max-decimal-points';
import { noneNegativeDecimalNumberRegex } from './validations/none-negative-decimal-number';
import { nonePositiveDecimalNumberRegex } from './validations/none-positive-decimal-number';
import ibanValidator from './validations/iban.validator';
import { clearingPriorityIsBlockingLast } from './validations/clearing-priority-is-blocking-last';
import { maxCodepoints } from './validations/max-codepoints';
import { isMinStringLength } from './validations/is-min-string-lengh';
import { maxNotRequired } from './validations/max-not-required';
import { minNotRequired } from './validations/min-not-required';
import { stringMaxDecimalPoints } from '~/source/common/validators/validations/string-max-decimal-points';
import { regExpNotRequired } from './reg-exp-not-required';

const injector = ng.injector(['ng']);
const $parse = injector.get('$parse');

export const validators = {
  stringMaxDecimalPoints,
  decimalNumber,
  minFloat,
  maxFloat,
  maxDecimalPoints,
  clearingPriorityIsBlockingLast,
  maxCodepoints,
  ngValidationMaxSmsLength,
  maxNotRequired,
  minNotRequired,
  minStringLength: isMinStringLength,

  noneNegativeDecimalNumber: noneNegativeDecimalNumberRegex,
  nonePositiveDecimalNumber: nonePositiveDecimalNumberRegex,
  iban: ibanValidator,

  /**
   * override validator default email regex
   * increase email domain TLD to almost maximum length of 60 chars long
   */
  email: regExpNotRequired(
    /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,60}|[0-9]{1,3})(\]?)$/,
  ),

  /**
   * override validator default url regex because its buggy (validation is always true when port is provided).
   */
  // tslint:disable-next-line:max-line-length
  url: regExpNotRequired(
    /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  ),

  /**
   * regex validator for ip address
   */
  ip: regExpNotRequired(
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
  ),

  /**
   * regex validator for port address
   */
  port: regExpNotRequired(/^([0-9]){1,5}$/),

  startsByLetter: regExpNotRequired(/^[a-zA-Z]/),

  number: regExpNotRequired(/^-?\d+$/),

  naturalNumber: regExpNotRequired(/^\d+$/),

  nonPositiveNumber: regExpNotRequired(/^((-\d+)|(0+))$/),

  samePass(value, scope, element, attrs, param) {
    const pas = $parse(attrs.match)(scope);
    return pas === value;
  },

  // require this field in case the 'other' field is required
  requiredIf(value, scope, element, attrs, param) {
    const otherFieldFromScope = scope.$eval(param);

    if (otherFieldFromScope) {
      return !!value;
    }
    return true;
  },

  custom(value, scope, element, attrs, param) {
    const refModel = $parse(attrs.refModel)(scope);
    return scope.$eval(attrs.validationFunction, { value, model: refModel });
  },

  fileSize(value, scope, element, attrs, param) {
    const fileSize = $parse(attrs.validationFileSize)(scope.$parent);
    let file = $parse(attrs.ngModel)(scope.$parent);

    if (ng.isArray(file)) {
      file = file[0];
    }

    if (!file) {
      return true;
    }
    return file.size / (1024 * 1024) <= Number(fileSize);
  },

  /**
   * validate the exact length of the value
   * using this validation require settings custom error message like in the example
   *
   * @example <input type="number"
   *       validator="length=4"
   *       length-error-message="{{ 'validation.EXACT_LENGTH' | translate : {length : 4} }}"
   *       ng-model="vm.model">
   */
  exactLength(value, scope, element, attrs, param) {
    return value && value.toString().length === parseInt(param, 10);
  },

  min(value, scope, element, attrs, param) {
    return parseInt(value, 10) >= parseInt(param, 10);
  },

  max(value, scope, element, attrs, param) {
    return parseInt(value, 10) <= parseInt(param, 10);
  },

  /**
   * Luhn algorithm in JavaScript: validate credit card number supplied as string of numbers
   *
   * @param {String} ccNum - credit card number as a string
   */
  ccNumber(ccNum) {
    let len = ccNum.length;
    let bit = 1;
    let sum = 0;
    let val;

    while (len) {
      len = len - 1;
      val = parseInt(ccNum.charAt(len), 10);
      sum += (bit ^= 1) ? [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][val] : val;
    }

    return sum && sum % 10 === 0;
  },

  password: (password) => {
    switch (validatePassword(password)) {
      case returnCodes.VALID: {
        return true;
      }
      case returnCodes.PASSWORD_EMPTY: {
        return { result: false, message: 'validation.PASSWORD_EMPTY' };
      }
      case returnCodes.PASSWORD_TOO_SHORT: {
        return { result: false, message: 'validation.PASSWORD_TOO_SHORT' };
      }
      case returnCodes.PASSWORD_MIN_CHAR_TYPES: {
        return { result: false, message: 'validation.PASSWORD_MIN_CHAR_TYPES' };
      }
      default: {
        return { result: false, message: 'validation.PASSWORD_GENERAL_ERROR' };
      }
    }
  },
  /**
   * Validate that the value is inside the given range that defined by min and
   * max attributes
   *
   * @param {number} value
   * @param {object} scope
   * @param {object} element
   * @param {object} attrs
   * @returns {*}
   */
  range(value, scope, element, attrs) {
    if (value >= parseInt(attrs.min, 10) && value <= parseInt(attrs.max, 10)) {
      return value;
    }
  },

  /**
   * Validate number range
   *
   * @param {number} maxNumberValue
   * @param {object} scope
   * @param {object} element
   * @param {object} attrs
   * @returns {boolean}
   */
  greaterThan(maxNumberValue, scope, element, attrs) {
    const minNumberValue = attrs.greaterThan;

    if (!maxNumberValue || !minNumberValue) {
      // It's valid because we have nothing to compare against
      return true;
    }
    if (
      attrs.allowEmptyMaxNumber &&
      attrs.allowEmptyMaxNumber === 'true' &&
      !maxNumberValue
    ) {
      // It's valid because we allow max number to be empty
      return true;
    }

    // It's valid when max number (value) is bigger than min number (comparisonModel)
    return Number(maxNumberValue) > Number(minNumberValue);
  },

  /**
   * Uses underscore's isEmpty to verify that the current value is not empty.
   * Useful to verify an array or an object is not empty.
   * @param {any} value
   * @returns {boolean}
   */
  notEmpty(value) {
    return !_.isEmpty(value);
  },

  emptyarr(value, scope, element, attrs, param) {
    const val = $parse(attrs.ngModel)(scope.$parent);
    if (!val) {
      return false;
    }
    if (!ng.isArray(val)) {
      return true;
    }

    return val.length > 0;
  },

  thirdPartyTrackingFormat: regExpNotRequired(/^[a-zA-Z\[\]\_\-]+$/),

  required(value) {
    return value !== undefined && value !== null && value !== '';
  },
};

export const defaultMsg = {
  required: {
    error: 'validation.REQUIRED',
  },
  url: {
    error: 'validation.URL',
  },
  email: {
    error: 'validation.EMAIL',
  },
  ip: {
    error: 'validation.IP',
  },
  port: {
    error: 'validation.PORT',
  },
  number: {
    error: 'validation.NUMBER',
  },
  naturalNumber: {
    error: 'validation.NATURAL_NUMBER',
  },
  nonPositiveNumber: {
    error: 'validation.NON_POSITIVE_NUMBER',
  },
  decimalNumber: {
    error: 'validation.DECIMAL_NUMBER',
  },
  exactLength: {
    error: 'validation.INPUT_ERROR',
  },
  minlength: {
    error: 'validation.MIN_LENGTH',
  },
  maxlength: {
    error: 'validation.MAX_LENGTH',
  },
  samePass: {
    error: 'validation.MUST_MATCH',
  },

  requiredIf: {
    error: 'validation.BOTH_VALUES_FILLED_OR_EMPTY',
  },

  custom: {
    error: 'validation.INPUT_ERROR',
  },

  fileSize: {
    error: 'validation.FILE_SIZE_EXCEEDED',
  },

  emptyarr: {
    error: 'validation.MUST_SELECT',
  },

  notEmpty: {
    error: 'validation.MUST_SELECT',
  },

  range: {
    error: 'validation.RANGE_EXCEEDED',
  },

  min: {
    error: 'validation.TOO_LOW',
  },
  minNotRequired: {
    error: 'validation.TOO_LOW',
  },
  stringMaxDecimalPoints: {
    error: 'validation.MAX_DECIMAL_POINTS',
  },
  minFloat: {
    error: 'validation.TOO_LOW',
  },

  max: {
    error: 'validation.TOO_HIGH',
  },
  maxNotRequired: {
    error: 'validation.TOO_HIGH',
  },
  maxFloat: {
    error: 'validation.TOO_HIGH',
  },

  greaterThan: {
    error: 'validation.GREATER_THAN_MIN_VALUE',
  },
  ccNumber: {
    error: 'validation.CC_NUMBER',
  },
  password: {
    error: 'validation.PASSWORD_GENERAL_ERROR',
  },
  startsByLetter: {
    error: 'validation.STARTS_BY_LETTER',
  },
  thirdPartyTrackingFormat: {
    error: 'validation.THIRD_PARTY_TRACKING_FORMAT_PATTERN',
  },
  maxDecimalPoints: {
    error: 'validation.MAX_DECIMAL_POINTS',
  },
  noneNegativeDecimalNumber: {
    error: 'validation.NONE_NEGATIVE_DECIMAL_NUMBER',
  },
  nonePositiveDecimalNumber: {
    error: 'validation.NONE_POSITIVE_DECIMAL_NUMBER',
  },
  iban: {
    error: 'validation.IBAN_MAX_LENGTH',
  },
  clearingPriorityIsBlockingLast: {
    error: 'clearing.BLOCKING_COMPANY_MUST_BE_LAST',
  },
  maxCodepoints: {
    error: 'validation.MAX_LENGTH',
  },
  ngValidationMaxSmsLength: {
    error: 'validation.MAX_LENGTH',
  },
  minStringLength: {
    error: 'validation.MIN_LENGTH',
  },
};
