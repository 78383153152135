import { StateService } from '@uirouter/angularjs';

import BaseController from '~/source/common/controllers/base';
import DesksService from '~/source/management/brand/services/desks';
import { Desk } from '@proftit/crm.api.models.entities';

import template from './desk-create.component.html';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

class DeskCreateComponent extends BaseController {
  static $inject = [
    ...BaseController.$inject,
    '$state',
    'desksService',
    'modelNormalizer',
  ];

  lifecycles = observeComponentLifecycles(this);
  model: Desk;
  desksService: () => DesksService;
  dataServiceInstance: DesksService;
  $state: StateService;
  modelNormalizer: any;
  isFormDisabled = true;

  /**
   * Component life cycle - onInit
   *
   * @returns {void}
   */
  $onInit(): void {
    this.dataServiceInstance = this.desksService();
    this.model = this.generateDesk();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    super.$onChanges(changes);
  }

  $onDestroy() {}

  /**
   * Generate a new (non persistant yet) desk.
   *
   * @return {Desk} a new empty desk instance.
   */
  generateDesk(): Desk {
    return {
      id: null,
      name: '',
      countries: [],
      languages: [],
      isSystem: false,
      isRegistrationDesk: false,
    };
  }

  /**
   * Save current data to the server.
   *
   * @returns {Promise<any>}
   */
  save(): Promise<any> {
    const modelForCreate = _.omit(['id'], this.model);
    const normalizedData = this.modelNormalizer.normalize(modelForCreate);
    return this.saveToServer(normalizedData).then(() => {
      this.$state.go('^.list');
    });
  }

  /**
   * Set the data service error pane and blocking pane before use.
   *
   * The blocking page is reset to `service.name` after each rest operation there
   * for we need to set it before each operation.
   *
   * @return {void}
   */
  setDataServiceGrowlAndBlock() {
    this.dataServiceInstance.setConfig({
      growlRef: 'deskForm',
      blockUiRef: 'deskCreateBlockUi',
    });
  }

  private saveToServer(data): Promise<Desk> {
    this.setDataServiceGrowlAndBlock();

    return this.dataServiceInstance.postWithQuery<IElementRestNg<Desk>>(data);
  }

  onFormAuthorized() {
    this.isFormDisabled = false;
  }

  onFormUnAuthorized() {
    this.isFormDisabled = true;
  }
}

export default {
  template,
  controller: DeskCreateComponent,
};
