import ng from 'angular';
import { StateService, StateParams } from '@uirouter/core';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import useStream from '~/source/common/utilities/use-stream';
import { Credential, BrandEwallet } from '~/source/common/models/brand-ewallet';
import { Brand } from '@proftit/crm.api.models.entities';
import { Ewallet, EwalletField } from '~/source/common/models/ewallet';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import BrandEwalletService from '~/source/management/brand-ewallet/services/brand-ewallet.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

import template from './brand-ewallet-edit-manager.component.html';

enum CrudAction {
  Create,
  Update,
}

/**
 * Brand ewallet entity editor manager.
 *
 * Statefull component that handle the aspect of getting and updating the entity
 * to the server and updating the editor form view model.
 */
export class BrandEwalletManagerEditController extends BaseController {
  unsub$ = new rx.Subject<void>();
  opOnInit$ = new rx.Subject<void>();
  brandEwallet$ = new rx.BehaviorSubject<BrandEwallet>(null);
  walletFieldsAsSchema$ = new rx.BehaviorSubject<EwalletField[]>([]);
  crudAction$ = new rx.BehaviorSubject<CrudAction>(CrudAction.Create);
  submitLabel$ = new rx.BehaviorSubject<string>(null);
  opBrandChange$ = new rx.Subject<Brand>();
  opEwalletChange$ = new rx.Subject<Ewallet>();
  opCredentialChange$ = new rx.Subject<Credential>();
  opUpdate$ = new rx.Subject<void>();

  /*@ngInject */
  constructor(
    readonly $state: StateService,
    readonly $stateParams: StateParams,
    readonly brandEwalletService: BrandEwalletService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    super();
  }

  /**
   * Lifecycle method - onInit
   *
   * @return {void}
   */
  $onInit() {
    useStream(this.streamEntityInit(), this.unsub$);
    useStream(this.streamCrudActionCalc(), this.unsub$);
    useStream(this.streamSubmitLabelCalc(), this.unsub$);
    useStream(this.streamEwalletFieldsGenerate(), this.unsub$);
    useStream(this.streamBrandChange(), this.unsub$);
    useStream(this.streamEwalletChange(), this.unsub$);
    useStream(this.streamCredentialChange(), this.unsub$);
    useStream(this.streamRequestUpdate(), this.unsub$);

    this.opOnInit$.next();
  }

  /**
   * Lifecycle method - onDestroy
   *
   * @return {void}
   */
  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * Initialize the brand ewallet entity. Triggered by entering the component.
   * When create mode, then newly template generated. When edit mode, the entity
   * is fetched from the database.
   *
   * @return {void}
   */
  streamEntityInit() {
    return rx.pipe(
      () => this.opOnInit$,
      rx.switchMap(() => {
        if (_.isNil(this.$stateParams.id)) {
          return rx.obs.from([{ id: null, credentials: [] }]);
        }

        return rx.obs.from(this.getBrandEwallet(this.$stateParams.id));
      }),
      rx.tap((brandEwallet) => this.brandEwallet$.next(brandEwallet)),
    )(null);
  }

  /**
   * Calc crud action. Trigger by component entering.
   * When id present, the operation is edit. else it is create.
   *
   * @return {Observable} observable of the calculation.
   */
  streamCrudActionCalc() {
    return rx.pipe(
      () => this.opOnInit$,
      rx.map(() => this.calcCrudAction(this.$stateParams)),
      rx.tap((action) => this.crudAction$.next(action)),
    )(null);
  }

  /**
   * Calculate the submit label. Trigger by crud action update.
   *
   * @return {Observable} observable of the calculation.
   */
  streamSubmitLabelCalc() {
    return rx.pipe(
      () => this.crudAction$,
      rx.map((crudAction) => this.calcSubmitLabel(crudAction)),
      rx.tap((label) => this.submitLabel$.next(label)),
    )(null);
  }

  /**
   * Recalcuate brand-ewallet based on brand change.
   *
   * @return {Observable} observable of the calculation.
   */
  streamBrandChange() {
    return rx.pipe(
      () => this.opBrandChange$,
      rx.withLatestFrom(this.brandEwallet$),
      rx.map(([brand, brandEwallet]) => ({ ...brandEwallet, brand })),
      rx.tap((brandEwallet) => this.brandEwallet$.next(brandEwallet)),
    )(null);
  }

  /**
   * Recalcuate brand-ewallet based on ewallet change.
   *
   * @return {Observable} observable of the calculation.
   */
  streamEwalletChange() {
    return rx.pipe(
      () => this.opEwalletChange$,
      rx.withLatestFrom(this.brandEwallet$),
      rx.map(([ewallet, brandEwallet]) => ({ ...brandEwallet, ewallet })),
      rx.tap((brandEwallet) => this.brandEwallet$.next(brandEwallet)),
    )(null);
  }

  /**
   * Calcuate credentials schema based on brand-ewallet.ewallet change.
   *
   * @return {Observable} observable of the calculation.
   */
  streamEwalletFieldsGenerate() {
    return rx.pipe(
      () => this.brandEwallet$,
      rx.map((brandEwallet) =>
        _.defaultTo([], _.get(['ewallet', 'fields'], brandEwallet)),
      ),
      rx.tap((fields) => this.walletFieldsAsSchema$.next(fields)),
    )(null);
  }

  /**
   * Recalcuate brand-ewallet based on credentials change.
   *
   * @return {Observable} observable of the calculation.
   */
  streamCredentialChange() {
    return rx.pipe(
      () => this.opCredentialChange$,
      rx.withLatestFrom(this.brandEwallet$),
      rx.map(([updatedCredential, brandEwallet]) => {
        const newCredentials = [
          ...brandEwallet.credentials.filter(
            (credential) => credential.key !== updatedCredential.key,
          ),
          updatedCredential,
        ];

        return {
          ...brandEwallet,
          credentials: newCredentials,
        };
      }),
      rx.tap((brandEwallet) => this.brandEwallet$.next(brandEwallet)),
    )(null);
  }

  /**
   * Submit a server create/update request. Trigger by user click.
   *
   * @return {Observable} observable of the request sequence.
   */
  streamRequestUpdate() {
    return rx.pipe(
      () => this.opUpdate$,
      rx.withLatestFrom(this.brandEwallet$, this.crudAction$),
      rx.filter(([a, brandEwallet, c]) => !_.isNil(brandEwallet)),
      rx.switchMap(([a, brandEwallet, crudAction]) => {
        let serverOp$;

        const credentials = brandEwallet.credentials;
        const normData = _.flow([
          () => this.modelNormalizer.normalize(brandEwallet),
          (normData) => _.omit(['id'], normData),
        ])();
        normData.credentials = credentials;

        if (crudAction === CrudAction.Create) {
          serverOp$ = rx.obs.from(
            this.brandEwalletService
              .expand(['brand', 'ewallet'])
              .embed(['ewallet.fields'])
              .postWithQuery(normData),
          );
        } else if (crudAction === CrudAction.Update) {
          serverOp$ = rx.obs.from(
            this.brandEwalletService
              .expand(['brand', 'ewallet'])
              .embed(['ewallet.fields'])
              .patchElement(brandEwallet.id, normData),
          );
        }

        return serverOp$;
      }),
      rx.tap(() => this.$state.go('^.list')),
      rx.catchError((err, obs) => obs),
    )(null);
  }

  /**
   * Get brand-ewallet from server.
   *
   * @return {Promise} promise of the get request.
   * @private
   */
  getBrandEwallet(id: number) {
    return this.brandEwalletService
      .expand(['brand', 'ewallet'])
      .embed(['ewallet.fields'])
      .getOneWithQuery<IElementRestNg<BrandEwallet>>(id)
      .then((data) => data.plain());
  }

  /**
   * Calcuate crud action.
   *
   * @param {StateParams} $stateParams: state params
   * @return {CrudAction} crud action
   */
  calcCrudAction($stateParams: StateParams) {
    if (_.isNil($stateParams.id)) {
      return CrudAction.Create;
    }

    return CrudAction.Update;
  }

  /**
   * Calcualte submit label.
   *
   * @param {CrudAction} action - action.
   * @return {string} the label string.
   */
  calcSubmitLabel(action: CrudAction) {
    if (action === CrudAction.Create) {
      return 'SAVE';
    }

    if (action === CrudAction.Update) {
      return 'UPDATE';
    }

    return '';
  }
}

export const BrandEwalletEditManagerComponent = {
  template,
  controller: BrandEwalletManagerEditController,
};

export default BrandEwalletEditManagerComponent;
