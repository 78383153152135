import * as _ from '@proftit/lodash';

import { CustomerPropertyValue } from '~/source/common/models/customer-property-value';
import switchOn from '~/source/common/utilities/switch-on';

/**
 * Check whether customer property value is considered empty.
 *
 * For boolean is nil, for other (texts) its empty.
 *
 * @propVal - property value
 * @return - true if empty, false otherwise.
 */
export function isCustomerPropertyValueEmpty(
  propVal: CustomerPropertyValue & { value: any },
) {
  return switchOn(
    {
      bool: () => _.isNil(propVal.value),
    },
    propVal.property.typeCode,
    () => _.isEmpty(propVal.value),
  );
}

export default isCustomerPropertyValueEmpty;
