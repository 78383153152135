import template from './brand-update.component.html';

const styles = require('./brand-update.component.scss');

import { StateParams } from '@uirouter/core';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { Permissions } from '~/source/common/models/permission-structure';

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandProviderInner } from '~/source/common/providers/brand-provider.component';
import { useStreams } from '@proftit/rxjs.adjunct';

export class BrandUpdateController extends BaseController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  isEdit: boolean;

  brand: IElementRestNg<Brand>;

  Permissions = Permissions;

  prfBrandProviderInner: BrandProviderInner;

  /* @ngInject */
  constructor(
    readonly $stateParams: StateParams,
    readonly brandsService: () => BrandsService,
  ) {
    super();
  }

  $onInit() {
    this.isEdit = false;

    this.prfBrandProviderInner.store.load(this.$stateParams.id);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const BrandUpdateComponent = {
  template: `
    <prf-brand-provider>
      <prf-brand-update-inner>
      </prf-brand-update-inner>
    <prf-brand-provider>
  `,
};

export const BrandUpdateInnerComponent = {
  template,
  controller: BrandUpdateController,
  controllerAs: 'vm',
  require: {
    prfBrandProviderInner: '^',
  },
};
