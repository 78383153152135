import { TransitionService, HookMatchCriteria } from '@uirouter/core';

import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { SocketListener } from '~/source/common/services/socket';
import UsersService from '~/source/management/user/services/users';
import CommunicationsSocketService from './communications-socket.service';
import TokensService from '~/source/auth/services/tokens';
import CustomersService from '~/source/contact/common/services/customers';
import PopupService from '~/source/common/components/modal/popup.service';
import ModalOpenState from '~/source/common/models/modal-open-state';
import { CommunicationsService } from './communications.service';

import { CommunicationsStoreService } from '../../store-services/communications-store.service';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { CurrentUserStoreService } from '../../store-services/current-user-store.service';
import { observeTransitionSuccess } from '../../utilities/rxjs/observables/observe-transition-success';
import { RouterStoreService } from '../../store-services/router-store.service';
import { CALL_ONGOING } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';

export class CallManagerController {
  activeCalls: Set<number>;
  onCallsNewWrapped: SocketListener;
  callsChannel: string;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor(
    readonly communicationsService: CommunicationsService,
    readonly communicationsSocketService: CommunicationsSocketService,
    readonly popupService: PopupService,
    readonly $transitions: TransitionService,
    readonly tokensService: TokensService,
    readonly customersService: () => CustomersService,
    readonly prfCommunicationsStore: CommunicationsStoreService,
    readonly prfCurrentUserStore: CurrentUserStoreService,
    readonly prfRouterStore: RouterStoreService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    this.activeCalls = new Set();

    useStreams([this.streamOpenActiveCallPopup()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamRouteChange() {
    return rx.pipe(() =>
      observeTransitionSuccess(this.$transitions, { to: 'crm.**' }),
    )(null);
  }

  streamOpenPopupFromCallOngoingPubsub() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === CALL_ONGOING),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Called on successful route change.
   * Route might be changed during an active call, so we must check for any
   * active calls and re-open the call window
   */
  streamOpenActiveCallPopup() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.prfCommunicationsStore.activeCall$.pipe(
            rx.filter((x) => !_.isNil(x)),
          ),
          this.prfCurrentUserStore.isVoipEnabled$,
          this.prfCurrentUserStore.isLoggedIn$.pipe(rx.filter((x) => x)),
          this.prfRouterStore
            .routeChangeSuccessAfterInitWithoutSubstateChanges$,
          this.streamRouteChange(),
          this.streamOpenPopupFromCallOngoingPubsub(),
        ),
      rx.withLatestFrom(this.prfCommunicationsStore.activeCall$),
      rx.filter(([a, activeCall]) => activeCall),
      rx.tap(([a, activeCall]) => this.openPopup(activeCall)),
    )(null);
  }

  /**
   * Opens active call popup
   * @param {object} call - call object
   * @return {void}
   */
  openPopup(call) {
    if (this.activeCalls.has(call.id)) {
      return; // already open
    }

    const modal = this.popupService.open({
      controller: 'CallPopupController',
      // call template will be decided by the popup controller itself, according to call status
      template: '<ng-include src="vm.callTemplate" />',
      windowClass: 'prf-dialog prf-dialog--call',
      data: {
        call,
        referenceId: call.id,
      },
      openStateOnNew: ModalOpenState.Close,
    });
    // Manage a set of open windows, to avoid opening the same call twice
    this.activeCalls.add(call.id);

    modal.result.finally(() => {
      this.activeCalls.delete(call.id);
    });
  }
}

export default {
  controller: CallManagerController,
};
