import { SocketService } from '~/source/common/services/socket';

export class InternalTransfersSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  get channelRoot() {
    return 'InternalTransfer';
  }

  get whiteList() {
    return [];
  }
}
