import * as _ from '@proftit/lodash';

import FormFormatter from '../../../common/services/base-form-formatter';

class ManagementIpRangesFormFormatter extends FormFormatter {
  constructor(...args) {
    super(...args);
  }

  get map() {
    return {
      whitelistIps: [
        {
          method: 'normalizeWhiteListIps',
          params: [],
        },
      ],
    };
  }

  /**
   * Modify whiteListIps in order to match API requets
   *
   * @returns {object}
   *
   * @example
   *
   * this.model.whitelistIps = [{ipAddress: "44.1.2.3", someOtherField: "someValue1"}]
   * normalizeWhiteListIps()
   * // return: [{ipAddress: "44.1.2.3"}]
   *
   */
  normalizeWhiteListIps() {
    return _.map(
      (ip: any) => ({
        ipAddress: ip.ipAddress,
      }),
      this.model.whitelistIps,
    );
  }
}

export default ManagementIpRangesFormFormatter;
