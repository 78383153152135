const CURRENCIES_SYMBOLS = {
  BTC: '₿',
  XRP: 'XRP',
  XMR: 'ɱ',
  LTC: 'Ł',
  ETH: 'Ξ',
};

/**
 * Get symbol for crypto currency.
 *
 * Current Intl browser does not support crypto currencies. That is why manual code is needed.
 *
 * @param currency
 * @return symbol
 */
export function getSymbolForCryptoCurrency(currency: string) {
  if (CURRENCIES_SYMBOLS.hasOwnProperty(currency.toUpperCase())) {
    return CURRENCIES_SYMBOLS[currency.toUpperCase()];
  }

  return currency;
}
