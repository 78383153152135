import Component from './base/component';
import { fetchAllWithPaging } from '../../utilities/fetch-all-with-paging';

const PAGE_FETCH_SIZE = 500;

class CampaignSelectController extends Component.controller {
  static $inject = [
    'marketingCampaignsService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'marketing.SELECT_CAMPAIGN';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return fetchAllWithPaging(
      PAGE_FETCH_SIZE,
      (page, size) => this.queryPageStep(page, size),
      () => this.dataServiceInst.total,
    );
  }

  queryPageStep(page: number, size: number) {
    return this.dataServiceInst.setPage(page, size).getListWithQuery();
  }
}

export default Component.config({ controller: CampaignSelectController });
