import { IPromise } from 'angular';
import { SystemLogin2fa } from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { IElementRestNg } from '../models/ielement-rest-ng';
import { SystemSmtpConfiguration } from '@proftit/crm.api.models.entities/src';

const LOGIN_2FA_RESOURCE = 'login2fa';

const SMTP_CONFIGURATION = 'smtpConfiguration';

export class SystemService extends RestService {
  get resource() {
    return 'system';
  }

  getSystemLogin2faResource() {
    return this.resourceBuildStart()
      .getElement(LOGIN_2FA_RESOURCE)
      .resourceBuildEnd();
  }

  getSystemLogin2fa(): Promise<SystemLogin2fa> {
    return this.getSystemLogin2faResource()
      .getOneWithQuery<IElementRestNg<SystemLogin2fa>>()
      .then((data) => data.plain());
  }

  updateSystemLogin2fa(model) {
    return this.getSystemLogin2faResource()
      .patchWithQuery<IElementRestNg<SystemLogin2fa>>({
        ...model,
      })
      .then((data) => data.plain());
  }

  getSmtpConfigurationResource() {
    return this.resourceBuildStart()
      .getElement(SMTP_CONFIGURATION)
      .resourceBuildEnd();
  }

  getSmtpConfiguration(): Promise<SystemSmtpConfiguration> {
    return this.getSmtpConfigurationResource()
      .getOneWithQuery<IElementRestNg<SystemSmtpConfiguration>>()
      .then((data) => data.plain());
  }

  updateSmtpConfiguration(model) {
    return this.getSmtpConfigurationResource()
      .patchWithQuery<IElementRestNg<SystemSmtpConfiguration>>({
        ...model,
      })
      .then((data) => data.plain());
  }
}
