import RestService from '~/source/common/services/rest';

export class PortfolioService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'portfolio';
  }

  /**
   *  Get Accounts By brand
   *
   * @returns { Promise }
   */
  getAccountsByBrand(brandId: number, platformId: number) {
    return this.filter({
      'customer.brandId': brandId,
      'platform.id': platformId,
    })
      .embed([
        'customer.brand.smsCredentials',
        'customer.brand.voipCredentials',
      ])
      .expand([
        'customer',
        'customer.country',
        'platform',
        'currency',
        'customer.user',
        'customer.brand',
        'customer.brand.voipCredentials.voipProvider',
        'customer.brand.smsCredentials.smsProvider',
      ]);
  }
}

export default PortfolioService;
