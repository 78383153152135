import BaseController from '~/source/common/controllers/base';
import { Customer } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';

class SendEmailPopupController extends BaseController {
  /*@ngInject*/
  constructor(
    readonly customersService: () => CustomersService,
    readonly growl: angular.growl.IGrowlService,
  ) {
    super();
  }
  // From popup scope data
  customer: Customer;
  email: string;
  $close: Function;

  send() {
    this.customersService()
      .setConfig({ growlRef: 'sendEmailForm', blockUiRef: 'sendEmailForm' })
      .getEmailsResource(this.customer.id)
      .postWithQuery(this.email)
      .then(() => {
        this.growl.success('contact.EMAIL_SEND_SUCCESS', {
          referenceId: 'restService' as any,
        });
        this.$close();
      });
  }
}

export default SendEmailPopupController;
