import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import UsersService from '~/source/management/user/services/users';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './brand-campaign-form.html';

class ComponentController extends BaseController {
  static $inject = ['usersService', '$scope', 'brandsService'];

  // injections
  usersService: () => UsersService;
  brandsService: () => BrandsService;

  // bindings
  isNew: boolean;
  brand: Brand;
  getLogo: (a: { value: Brand }) => Promise<Brand>;
  remove: Function;
  userId: number;

  brandLogo: any;
  usersServiceInst: UsersService;

  // @ts-ignore
  isEdit: boolean;
  prevBrand: Brand = null;
  brandList;
  /**
   * list of brands ids that should be excluded from select
   * because they are already selected on other brands
   */
  excludedBrands = [];

  /**
   * Component life cycle event - on init.
   * @returns {void}
   */
  $onInit() {
    this.brandList = this.brandList || [];
    this.isEdit = this.isNew;

    Object.assign(this, {
      usersServiceInst: this.usersService(),
      brandsServiceInst: this.brandsService(),
      brandLogo: null,
    });

    this.$scope.$watch('vm.brand', () => {
      /*
       * Because of a bug in UI select
       * n===o condition is needed
       */
      if (!this.brand || !this.brand.id) {
        return;
      }

      this.brand.shouldAutoUpdateCampaigns =
        this.brand.shouldAutoUpdateCampaigns || false;

      this.getLogo({ value: this.brand }).then((brand) => {
        this.brandLogo = brand.logo;
      });
    });

    this.$scope.$watchCollection(
      'vm.brandList',
      this.onBrandListChange.bind(this),
    );
  }

  /**
   * Called when there is a change in the 'brandList' array, or on one of its elements.
   */
  onBrandListChange() {
    // an array of the Brands selected in the other brand's campaigns forms
    this.excludedBrands = this.brandList
      .filter(
        (brand) =>
          !_.isEmpty(brand) &&
          // ignore current form's brand
          brand.id !== _.getEs(this.brand, 'id'),
      )
      // A derivative of 'excludedBrands': just the ids (used for dropdown 'exclude' binding)
      .map(({ id }) => id);
  }

  /**
   * Enter edit mode:
   */
  enterEdit() {
    // Enter edit mode
    this.isEdit = true;

    this.prevBrand = _.cloneDeep(this.brand);
  }

  /**
   * Remove this component in a new campaign,
   * or just exit edit mode in existing campaign
   */
  onEditDone() {
    if (this.isNew) {
      this.remove();
    } else {
      this.isEdit = false;
    }
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    this.brand = this.prevBrand;
    this.onEditDone();
  }

  /**
   * Add Brand's Campaigns to user
   *
   * @param {object} brand
   */
  save(brand) {
    this.usersServiceInst
      .addBrandConnection(this.userId, {
        campaigns: brand.campaigns.map(({ id }) => id),
        brandId: brand.id,
        shouldAutoUpdateCampaigns: brand.shouldAutoUpdateCampaigns,
      })
      .then(() => {
        if (this.isNew) {
          this.brandList.push(brand);
        }
        this.onEditDone();
      });
  }
}

const BrandCampaignFormComponent = {
  template,
  bindings: {
    brand: '=',
    remove: '&removeFn',
    removeValidate: '&removeValidateFn',
    getLogo: '&getLogoFn',
    userId: '<',
    showControls: '<',
    isNew: '<',
    brandList: '<',
  },
  controller: ComponentController,
  controllerAs: 'vm',
};

export default BrandCampaignFormComponent;
