import BaseController from '~/source/common/controllers/base';

import template from './actions-menu-dropdown.component.html';
const styles = require('./actions-menu-dropdown.component.scss');

class ActionsMenuDropdownComponent extends BaseController {
  static $inject = [];

  styles = styles;

  isOpen = false;
}

export default {
  template,
  controller: ActionsMenuDropdownComponent,
  bindings: {
    items: '<',
    hideWhenNoAction: '<',
    onAction: '&',
  },
};
