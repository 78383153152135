export const emailTemplatesContentsLogsTableCols = [
  {
    title: 'contentTemplatesLogs.fields.UPDATED_AT',
    field: '',
    id: 'updatedAt',
    fieldFormat: '{{ modifiedAt | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contentTemplatesLogs.fields.TEMPLATE_NAME',
    field: '',
    id: 'templateName',
    fieldFormat: '{{ templateName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contentTemplatesLogs.fields.LANGUAGE',
    field: '',
    id: 'language',
    fieldFormat: '{{ language }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contentTemplatesLogs.fields.FIELD',
    field: '',
    id: 'field',
    fieldFormat: '{{ field }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contentTemplatesLogs.fields.USER',
    field: '',
    id: 'user',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contentTemplatesLogs.fields.IP',
    field: 'ip',
    fieldFormat: '{{ ip }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
