import template from './brand-inactivity-fee-form.component.html';
const styles = require('./brand-inactivity-fee-form.component.scss');

import ng from 'angular';

import BaseController from '~/source/common/controllers/base';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import {
  createNgModelObsMediator,
  NgModelChange,
} from '~/source/common/utilities/create-ng-model-obs-mediator';
import {
  InactivityFeeSetting,
  InactivityCriteria,
} from '@proftit/crm.api.models.entities';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { filterCompChange } from '~/source/common/utilities/rxjs/observables/filter-comp-change';
import * as _ from '@proftit/lodash';
import moment from 'moment';
import * as rx from '@proftit/rxjs';

interface DragDropItem {
  id: any;
  label: string;
}

export class BrandInactivityFeeFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  inputModel;
  model$ = new rx.BehaviorSubject<InactivityFeeSetting>(null);
  modelUpdatesOut$ = new rx.Subject<NgModelChange>();
  brandInactivityFeeForm;
  inactivityCriteriasDragSet$ = new rx.BehaviorSubject<DragDropItem[]>([]);
  inactivityCriteriasDragSelection$ = new rx.BehaviorSubject<DragDropItem[]>(
    [],
  );
  opInsertDragCriteria$ = new rx.Subject<DragDropItem>();
  opRemoveDragCriteria$ = new rx.Subject<DragDropItem>();
  opMoveDragCriteria$ = new rx.Subject<DragDropItem>();

  onChangeOfModel: (a: { change: NgModelChange }) => {};
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  minDateForStartDate = moment('2000-01-01');
  maxDateForStartDate = moment('2100-01-01');

  set inactivityCriteriasLength(val) {}
  get inactivityCriteriasLength() {
    return this.inactivityCriteriasDragSelection$.getValue().length;
  }

  /*@ngInject */
  constructor(
    readonly $validation,
    readonly $translate: angular.translate.ITranslateService,
  ) {
    this.inputModel = createNgModelObsMediator(
      [
        'isActive',
        'startDate',
        'unitsChargeSize',
        'percentChargeSize',
        'inactivityPeriodLength',
        'cycleLength',
      ],
      this.model$,
      this.modelUpdatesOut$,
    );

    useStreams(
      [
        this.streamModelOut(),
        this.streamFormValidOut(),
        this.streamFeeCriteriasDragSet(),
        this.streamFeeCriteriasDragSelection(),
        this.streamUiRemoveCriteria(),
        this.streamUiInsertCriteria(),
        observeCompChange<InactivityFeeSetting>(
          this.model$,
          'model',
          this.lifecycles.onChanges$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamModelOut() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.debounceTime(150),
      rx.map(() => this.$validation.checkValid(this.brandInactivityFeeForm)),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }

  streamFeeCriteriasDragSet() {
    return rx.pipe(
      () => this.obsInactivityCriteriasSet(),
      rx.map((currentValue) =>
        currentValue.map((x) => ({
          label: this.$translate.instant(`inactivityFee.criterias.${x.code}`),
          id: x.code,
        })),
      ),
      rx.tap((sourceDragItems) =>
        this.inactivityCriteriasDragSet$.next(sourceDragItems),
      ),
    )(null);
  }

  streamFeeCriteriasDragSelection() {
    return rx.pipe(
      () =>
        filterCompChange<InactivityFeeSetting>(
          'model',
          this.lifecycles.onChanges$,
        ),
      rx.map(({ currentValue }) => currentValue.inactivityCriteria),
      rx.map((selectedCriterias) => {
        if (_.isNil(selectedCriterias)) {
          return [];
        }
        return selectedCriterias.map((x) => ({
          label: this.$translate.instant(`inactivityFee.criterias.${x.code}`),
          id: x.code,
        }));
      }),
      rx.tap((selectedDragCriterias) =>
        this.inactivityCriteriasDragSelection$.next(selectedDragCriterias),
      ),
    )(null);
  }

  streamUiRemoveCriteria() {
    return rx.pipe(
      () => this.opRemoveDragCriteria$,
      rx.withLatestFrom(this.model$),
      rx.map(([dragItem, model]) =>
        model.inactivityCriteria.filter((x) => x.code !== dragItem.id),
      ),
      rx.map((inactivityCriteria) => ({
        fieldName: 'inactivityCriteria',
        nextValue: inactivityCriteria,
      })),
      rx.tap((change) => this.modelUpdatesOut$.next(change)),
    )(null);
  }

  streamUiInsertCriteria() {
    return rx.pipe(
      () => this.opInsertDragCriteria$,
      rx.withLatestFrom(this.model$, this.obsInactivityCriteriasSet()),
      rx.filter(([dragItem, model, a]) =>
        _.isNil(model.inactivityCriteria.find((x) => x.code === dragItem.id)),
      ),
      rx.map(([dragItem, model, criteriasSet]) => {
        const criteria = criteriasSet.find((x) => x.code === dragItem.id);

        return [...model.inactivityCriteria, criteria];
      }),
      rx.map((inactivityCriteria) => ({
        fieldName: 'inactivityCriteria',
        nextValue: inactivityCriteria,
      })),
      rx.tap((change) => this.modelUpdatesOut$.next(change)),
    )(null);
  }

  obsInactivityCriteriasSet() {
    return rx.pipe(
      () =>
        filterCompChange<InactivityCriteria[]>(
          'inactivityCriteriasSet',
          this.lifecycles.onChanges$,
        ),
      rx.map(({ currentValue }) => currentValue),
      rx.map((val) => (_.isNil(val) ? [] : val)),
    )(null);
  }
}

export const BrandInactivityFeeFormComponent = {
  template,
  controller: BrandInactivityFeeFormController,
  bindings: {
    model: '<',
    inactivityCriteriasSet: '<',
    isReadonly: '<',
    onChangeOfModel: '&',
    onChangeOfFormValidity: '&',
  },
};
