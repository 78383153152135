import BaseConfig, {
  FilterPopoverSimpleController,
} from './filter-popover-simple.component';
import * as rx from '@proftit/rxjs';

import template from './filter-popover-select.html';

class FilterPopoverSelectController extends FilterPopoverSimpleController {
  selectAllResultsAction = new rx.Subject<void>();
  selectAllResultsTrigger$ = this.selectAllResultsAction.asObservable();

  onFilterNameChange() {
    super.onFilterNameChange();
    const filter = this.filtersSettings[this.filterName];
    if (filter.state.multiple) {
      this.attributes.multiple = true;
    }
  }

  selectAllResults() {
    this.selectAllResultsAction.next();
  }
}

export default {
  ...BaseConfig,
  template,
  controller: FilterPopoverSelectController,
};
