import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';

import Url from 'url-parse';

import download from 'downloadjs';
import { downloadLocalFileUrl } from '@proftit/dom-utilities';

import { CfdGroup } from '@proftit/tradingcore.api.models.entities';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  addHeader,
  getAsOne,
  getAsList,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  create as genReqCreate,
  appendResource,
  setResponseType,
  setTokenHeader,
  jsonServerToCfdMongo,
  cfdMongoToNgHttp,
  performRequest as ngHttpPerformRequest,
} from '@proftit/request-client';

import { removeColonAtEnd } from '@proftit/general-utilities';

const RESOURCE_PATH = 'Exposure';
const CURRENCIES_PATH = 'currencies';
const FILE_PATH = 'file';

export class ApiTrcExposureService {
  /* @ngInject */
  constructor(readonly $http: IHttpService) {}

  initReq(reqParam, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      () => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(RESOURCE_PATH, req),
    ])(reqParam);
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  getCurrenciesTotals(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => appendResource('currencies', req),
      (req) => appendResource('totals', req),
      (req) => getAsOne(req),
      (req) => jsonServerToCfdMongo('', req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest(this.$http, req),
      (req) => req.then((resp: { data }) => resp.data),
      (req) => req.then((data) => (data.length > 0 ? data[0] : data)),
    ])();
  }

  exportCurrencyReport(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => setResponseType('blob', req),
      (req) => appendResource(CURRENCIES_PATH, req),
      (req) => appendResource(FILE_PATH, req),
      (req) => addHeader('Accept', '*/*', req),
      (req) => genReqCreate(req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest(this.$http, req),
      (req) =>
        req.then((fileData) => {
          const fileName = 'currencies.xlsx';
          return download(
            fileData.data,
            fileName,
            fileData.headers('content-type'),
          );
        }),
    ])();
  }
}
