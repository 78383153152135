import type { growl } from 'angular';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandPlatform, ForexGroup } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { CurrentMt4AssetOfGroupStoreServiceDirectiveController } from '~/source/common/service-directives/current-mt4-asset-of-group-store-service.directive';
import { generateAssetForm } from './generate-asset-form';
import { normializeMt4AssetForUpdate } from './normalize-mt4-asset-for-update';
import template from './brand-platform-mt4-group-asset-item-dialog.component.html';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { CurrentTrcStreamerOfBrandPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-steamer-of-brand-platform-service.directive';
import { MtAssetFeed } from '@proftit/tradingcore.api.models.entities';
import { calcRawSpreadFromAsset } from '../../utilities/calc-raw-spread-from-asset';
import { calcTotalSpreadFromAsset } from '../../utilities/calc-total-spread-from-asset';
const styles = require('./brand-platform-mt4-group-asset-item-dialog.component.scss');

interface Resolve {
  assetId: number;
  brandPlatform: BrandPlatform;
  forexGroup: ForexGroup;
  operation: string;
}

export class BrandPlatformMt4GroupAssetItemDialogController {
  /* bindings */

  close: (a: { $value: any }) => void;

  dismiss: (a: { $value: any }) => void;

  /* require */

  prfCurrentMt4AssetOfGroup: CurrentMt4AssetOfGroupStoreServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentTrcStreamerOfBrandPlatform: CurrentTrcStreamerOfBrandPlatformServiceDirectiveController;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  assetFormP = generateAssetForm();

  /* inputs */

  resolveIn$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  assetIdIn$ = this.resolveIn$.pipe(
    rx.map(({ assetId }) => assetId),
    shareReplayRefOne(),
  );

  brandPlatformIn$ = this.resolveIn$.pipe(
    rx.map(({ brandPlatform }) => brandPlatform),
    shareReplayRefOne(),
  );

  forexGroupIn$ = this.resolveIn$.pipe(
    rx.map(({ forexGroup }) => forexGroup),
    shareReplayRefOne(),
  );

  operationIn$ = this.resolveIn$.pipe(
    rx.map(({ operation }) => operation),
    shareReplayRefOne(),
  );

  /* streams */

  doOperation$ = new rx.Subject<void>();

  assetFormS;

  isConfirmButtonDisabledS;

  rawSpread$: rx.Observable<number>;

  totalSpread$: rx.Observable<number>;

  /* @ngInject */
  constructor(
    readonly growl: growl.IGrowlService,
    readonly growlMessages: growl.IGrowlMessagesService,
  ) {
    useStreams([this.resolveIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.assetFormS = this.streamAssetForm();

    this.isConfirmButtonDisabledS = this.streamIsConfirmButtonDisabled();

    const assetFromInitS = this.streamAssetFromInit();

    this.rawSpread$ = this.streamRawSpread();

    this.totalSpread$ = this.streamTotalSpread();

    useStreams(
      [
        this.assetFormS.stream$,
        assetFromInitS.stream$,
        this.streamUpdate(),
        this.streamLoginToSelectedPlatform().stream$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamAssetFromInit() {
    return generateInProcessStream(
      rx.obs.combineLatest(
        this.assetIdIn$,
        this.brandPlatformIn$,
        this.forexGroupIn$,
        this.operationIn$,
        this.prfCurrentPlatformSession.sessionS.stream$.pipe(
          rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
        ),
      ),
      () =>
        rx.pipe(
          rx.filter((params) => params.every((p) => !_.isNil(p))),
          rx.switchMap(
            ([assetId, brandPlatform, forexGroup, operation, sessionInfo]) => {
              if (operation !== 'update') {
                throw new Error('unimplemented');
              }

              return this.prfCurrentMt4AssetOfGroup.getOne(
                sessionInfo.session.apiUrl,
                sessionInfo.session.token,
                forexGroup.id,
                assetId,
              );
            },
          ),
        ),
    );
  }

  streamAssetForm() {
    return generateInProcessStream(
      this.prfCurrentMt4AssetOfGroup.assetS.stream$,
      () =>
        rx.pipe(
          rx.tap((asset) => {
            this.assetFormP.proxy.handler.getLeaf().setValueAsInitial(asset);
          }),
        ),
      [this.prfCurrentMt4AssetOfGroup.assetS.inProcess$],
    );
  }

  streamIsConfirmButtonDisabled() {
    return generateInProcessStream(
      rx.obs.combineLatest(this.assetFormP.isValid$, this.assetFormP.changes$),
      () =>
        rx.pipe(
          rx.map(([isValid, changes]) => {
            if (_.isEmpty(changes)) {
              return true;
            }

            if (!isValid) {
              return true;
            }

            return false;
          }),
          shareReplayRefOne(),
        ),
    );
  }

  streamUpdate() {
    return rx.pipe(
      () => this.doOperation$,
      rx.withLatestFrom(this.assetFormP.changes$),
      rx.filter(([_op, changes]) => !_.isEmpty(changes)),
      rx.map(([_a, changes]) => normializeMt4AssetForUpdate(changes)),
      rx.switchMap((changes) => {
        return this.prfCurrentMt4AssetOfGroup.update(changes).pipe(
          rx.catchError((e) => {
            log.error('error saving asset', e);
            this.growl.error(_.get(['data', 'message'], e), {
              referenceId: this.growlId as any,
            });
            return rx.obs.NEVER;
          }),
        );
      }),
      rx.tap(() => this.close({ $value: null })),
    )(null);
  }

  streamLoginToSelectedPlatform() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.switchMap((brandPlatform) => {
          if (_.isNil(brandPlatform)) {
            return this.prfCurrentPlatformSession.logout();
          }

          return this.prfCurrentPlatformSession.login(brandPlatform);
        }),
      ),
    );
  }

  streamRawSpread() {
    return rx.pipe(
      () => this.prfCurrentMt4AssetOfGroup.assetS.stream$,
      rx.switchMap((asset) =>
        this.prfCurrentTrcStreamerOfBrandPlatform.observeMtFeedPerAsset(
          asset.symbol,
        ),
      ),
      rx.map((feedUpdates) => calcRawSpreadFromAsset(feedUpdates)),
      rx.map((big) => Number(big.toString())),
      shareReplayRefOne(),
    )(null);
  }

  streamTotalSpread() {
    return rx.pipe(
      () => this.prfCurrentMt4AssetOfGroup.assetS.stream$,
      rx.switchMap((asset) =>
        this.prfCurrentTrcStreamerOfBrandPlatform.observeMtFeedPerAsset(
          asset.symbol,
        ),
      ),
      rx.withLatestFrom(this.prfCurrentMt4AssetOfGroup.assetS.stream$),
      rx.map(([feedUpdates, asset]) =>
        calcTotalSpreadFromAsset(feedUpdates, asset),
      ),
      rx.map((big) => Number(big.toString())),
      shareReplayRefOne(),
    )(null);
  }
}

function calcFieldWithDefault(fieldValue: number | string, isDefault: boolean) {
  if (isDefault) {
    return 'default';
  }

  return fieldValue;
}

export const BrandPlatformMt4GroupAssetItemDialogComponent = {
  template,
  controller: BrandPlatformMt4GroupAssetItemDialogController,
  require: {
    prfCurrentMt4AssetOfGroup: '^',
    prfCurrentPlatformSession: '^',
    prfCurrentTrcStreamerOfBrandPlatform: '^',
  },
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
