import ng, { IHttpService } from 'angular';
import { BrandPlatform, ForexGroup } from '@proftit/crm.api.models.entities';
import {
  Mt4Asset,
  MtGroupAsset,
} from '@proftit/tradingcore.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { TableLiveController } from '~/source/common/components/table/table-live.controller';
import { SocketService } from '~/source/common/services/socket';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { ModalService } from '~/source/common/components/modal/modal.service';
import {
  ApiMt4GroupsService,
  RESOURCE_PATH,
} from '~/source/common/api-cfd-platform/api-mt4-groups.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { initPrivateReqMt4 } from '~/source/common/api-cfd-platform/trading-core-request-utilities/init-private-req-mt4';
import { brandPlatformMt4GroupAssetsTableColumns } from './brand-platform-mt4-group-assets-table-columns';
import { settings } from './brand-platform-mt4-assets-table-settings';
import template from './brand-platform-mt4-group-assets-table.component.html';
import { CurrentTrcStreamerOfBrandPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-steamer-of-brand-platform-service.directive';
import { calcRawSpreadFromAsset } from '../../utilities/calc-raw-spread-from-asset';
import { calcTotalSpreadFromAsset } from '../../utilities/calc-total-spread-from-asset';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
const styles = require('./brand-platform-mt4-group-assets-table.component.scss');

const GLOBAL_GROWL_ID = 'restService';

export class BrandPlatformMt4GroupAssetsTableController extends TableLiveController {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'modalService',
    'prfApiMt4GroupsService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  /* require */

  prfCurrentTrcStreamerOfBrandPlatform: CurrentTrcStreamerOfBrandPlatformServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* injections */

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  modalService: ModalService;
  prfApiMt4GroupsService: ApiMt4GroupsService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  /* bindings */

  brandPlatform: BrandPlatform;
  forexGroup: ForexGroup;

  /* state */

  styles = styles;

  cols = [...brandPlatformMt4GroupAssetsTableColumns];

  settings = _.cloneDeep(settings);

  lifecycles = observeComponentLifecycles(this);

  brandPlatformIn$ = observeShareCompChange<BrandPlatform>(
    this.lifecycles.onChanges$,
    'brandPlatform',
  );

  forexGroupIn$ = observeShareCompChange<ForexGroup>(
    this.lifecycles.onChanges$,
    'forexGroup',
  );

  assets: Restangular.ICollection;

  assets$ = new rx.BehaviorSubject<MtGroupAsset[]>([]);

  opOpenEditPopup$ = new rx.Subject<Mt4Asset>();

  constructor(...args) {
    super(...args);

    useStreams(
      [this.brandPlatformIn$, this.forexGroupIn$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamInitTable(),
        this.streamOpenEditPopup(),
        this.streamItemsStreamerUpdates(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {
    super.$onDestroy();
  }

  get tableKey() {
    return 'mt4BrandPlatformAssets';
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return null;
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId: number): string {
    return null;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.assets';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.assets;
  }

  isStreamingOn() {
    return false;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  parseLoadedData(data) {
    this.assets = data;
    this.assets$.next(data);
    return data;
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfCurrentPlatformSession.sessionS.stream$.pipe(
            rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
          ),
          this.forexGroupIn$,
        ),
      rx.filter((deps) => deps.every((d) => !_.isNil(d))),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.map(([sessionInfo, forexGroup]) => {
        let req = initPrivateReqMt4(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
          RESOURCE_PATH,
        );
        req = this.prfApiMt4GroupsService.reqGroupAssets(req, forexGroup.id);

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamItemsStreamerUpdates() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.assets$, this.forexGroupIn$),
      rx.switchMap(([assets, group]) => {
        if (_.isEmpty(assets)) {
          return rx.obs.NEVER;
        }

        const assetsUpdates = assets.reduce(
          (list: MtGroupAsset[], asset: MtGroupAsset) => {
            const mtGroupAsset$ = this.prfCurrentTrcStreamerOfBrandPlatform.observeMtGroupMtAsset(
              (group as any).id,
              (asset as any).id,
            );

            list.push(mtGroupAsset$ as any);

            const mtGroupAssetTick$ = this.prfCurrentTrcStreamerOfBrandPlatform
              .observeMtFeedPerAsset(asset.symbol)
              .pipe(
                rx.map((feedUpdates) => {
                  const rawSpread = calcRawSpreadFromAsset(feedUpdates);
                  const totalSpread = calcTotalSpreadFromAsset(
                    feedUpdates,
                    asset as any,
                  );

                  return {
                    id: (asset as any).id,
                    _rawSpread: Number(rawSpread.toString()),
                    _totalSpread: Number(totalSpread.toString()),
                  };
                }),
              );

            list.push(mtGroupAssetTick$ as any);

            return list;
          },
          [],
        );

        return rx.obs.merge(...(assetsUpdates as any[]));
      }),
      rx.withLatestFrom(this.assets$),
      rx.tap(([assetUpdate, assets]) => {
        const origAsset = assets.find(
          (a: MtGroupAsset) => (a as any).id === (assetUpdate as any).id,
        );
        if (_.isNil(origAsset)) {
          return;
        }

        Object.assign(
          origAsset,
          _.pick(
            [
              'swapShort',
              'swapLong',
              'percentage',
              'contractSize',
              '_rawSpread',
              '_totalSpread',
            ],
            assetUpdate,
          ),
        );
      }),
    )(null);
  }

  streamOpenEditPopup() {
    return rx.pipe(
      () => this.opOpenEditPopup$,
      rx.withLatestFrom(this.brandPlatformIn$, this.forexGroupIn$),
      rx.tap(([asset, brandPlatform, group]) =>
        this.openEditPopup(brandPlatform, group, asset),
      ),
    )(null);
  }

  openEditPopup(
    brandPlatform: BrandPlatform,
    forexGroup: ForexGroup,
    asset: MtGroupAsset,
  ) {
    this.modalService.open({
      component: 'prfBrandPlatformMt4GroupAssetItemDialog',
      resolve: {
        assetId: () => (asset as any).id,
        brandPlatform: () => brandPlatform,
        forexGroup: () => forexGroup,
        operation: () => 'update',
      },
    });
  }

  get requiredApiFilters(): any {
    return { tradeState: ['Full access', 'Close only'] };
  }
}

export const BrandPlatformMt4GroupAssetsTableComponent = {
  template,
  controller: BrandPlatformMt4GroupAssetsTableController,
  controllerAs: 'vm',
  bindings: {
    brandPlatform: '<',
    forexGroup: '<',
  },
  require: {
    prfCurrentTrcStreamerOfBrandPlatform: '^',
    prfCurrentPlatformSession: '^',
  },
};
