import { WithdrawalDepositLinkServiceDirective } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-deposit-link-service-directive';
import { IWithdrawalDepositLinker } from '~/source/contact/contact-page/trading-account/common/withdrawal/i-withdrawal-deposit-linker';

const styles = require('./regular.scss');
import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import {
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import { CreditCardWithdrawalController } from '../../credit-card.component';
import TransferMethodTypes from '~/source/contact/common/services/transfer-method-types.service';
import WithdrawalValidationService, {
  WithdrawalValidationError,
} from '../../common/validation.service';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';

import template from './regular.html';

interface UiDeposit extends TradingAccountDeposit {
  selected?: boolean;
  amount: number; // todoOld: this is for now for overriding the error. Need ivestigation why the field is used.
}

class ApprovedDepositsController extends BaseController
  implements IWithdrawalDepositLinker {
  styles = styles;
  customerServiceInst: CustomersService;
  prfWithdrawalDepositLink: WithdrawalDepositLinkServiceDirective;

  creditcardComponent: CreditCardWithdrawalController;

  // bindings
  account: TradingAccount;
  deposits: UiDeposit[];
  selectedCard;
  withdrawalRequest: WithdrawalRequest;
  transferMethodTypes;
  invalidFormErrors: WithdrawalValidationError[];
  cacheCardFormErrors: WithdrawalValidationError;
  totalWithdrawal: number;
  totalDeposits: number;
  amountLeft: number;
  onDepositAmountChange: ({ newAmount }) => void;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly transferMethodTypesService: TransferMethodTypes,
    readonly customersService: () => CustomersService,
    readonly withdrawalValidationService: WithdrawalValidationService,
  ) {
    super();

    Object.assign(this, {
      customerServiceInst: this.customersService(),
      selectedCardTotals: {},
      cacheCardFormErrors: {},
      invalidFormErrors: [],
    });
  }

  $onInit() {
    this.prfWithdrawalDepositLink.addCalculator(this);
  }

  $onDestroy() {
    this.prfWithdrawalDepositLink.removeCalculator(this);
  }

  /**
   * called on change of withdrawal amount, update all total numbers needed by the ui
   */
  onWithdrawalChange() {
    this.totalWithdrawal = this.calculateTotalWithdrawal();
    this.totalDeposits = this.calculateCreditCardTotalDeposits();
    this.amountLeft =
      this.calculateCreditCardTotalWithdrawable() -
      this.calculateCreditCardTotalAmount();

    this.invalidFormErrors = this.withdrawalValidationService.getErrors(
      this.totalWithdrawal,
      this.account,
      this.withdrawalRequest,
    );

    // keep validation errors for selected card
    this.cacheCardFormErrors[this.selectedCard] = this.invalidFormErrors;

    this.onDepositLinkChange();
  }

  /**
   * invoke onWithdrawalChange when selectedCard value change
   */
  onSelectedCardChange() {
    this.onWithdrawalChange();
  }

  /**
   * sum of all the selected amounts for withdrawal action
   * @returns {Number}
   */
  calculateTotalWithdrawal() {
    return this.deposits
      .filter((deposit) => deposit.selected)
      .map(({ amount }) => amount)
      .reduce(_.addEs, 0);
  }

  /**
   * sum of all deposit (amountApproved) belongs to the selected credit card
   * @returns {Number}
   */
  calculateCreditCardTotalDeposits() {
    return (
      this.deposits
        // filter transactionTransferCreditCard = null  (remove wire transfer)
        .filter((deposit) => deposit.transactionTransferCreditCard)
        .filter(
          (deposit) =>
            this.selectedCard ===
            deposit.transactionTransferCreditCard.cardNumber,
        )
        .map(({ amountApproved }) => amountApproved)
        .reduce(_.addEs, 0)
    );
  }

  /**
   * sum of the deposit withdrawable amount belongs to the selected credit card
   * @returns {Number}
   */
  calculateCreditCardTotalWithdrawable() {
    return (
      this.deposits
        // filter transactionTransferCreditCard = null  (remove wire transfer)
        .filter((deposit) => deposit.transactionTransferCreditCard)
        .filter(
          (deposit) =>
            this.selectedCard ===
            deposit.transactionTransferCreditCard.cardNumber,
        )
        .map(({ amountWithdrawable }) => amountWithdrawable)
        .reduce(_.addEs, 0)
    );
  }

  /**
   * sum of all the selected amounts belongs to the selected credit card
   * @returns {Number}
   */
  calculateCreditCardTotalAmount() {
    return this.deposits
      .filter(
        (deposit) =>
          deposit.selected &&
          this.selectedCard ===
            deposit.transactionTransferCreditCard.cardNumber,
      )
      .map(({ amount }) => amount)
      .reduce(_.addEs, 0);
  }

  /**
   * called when a user withdrawal amount from a specific deposits
   *
   * @returns {Array}
   */
  normalize() {
    const approvedMethodType = this.transferMethodTypes.CARD_WITHDRAWAL;

    return this.deposits
      .filter((deposit) => deposit.selected)
      .map((deposit) => ({
        methodTypeId: approvedMethodType.id,
        amount: deposit.amount,
        depositId: deposit.id,
        withdrawalTypeId: this.withdrawalRequest.withdrawalType.id,
      }));
  }

  /**
   * submit withdrawal request to server if form is valid
   * called when submit button is click in parent scope
   *
   * @returns {*}
   */
  onCalcRequest() {
    if (!this.isAmountValid()) {
      return;
    }

    const normalized = this.normalize();
    return normalized;
  }

  onDepositLinkChange() {
    const amountWishedToBeDeposited = this.deposits.reduce((acc, deposit) => {
      if (deposit.selected) {
        const depositAmount = !_.isNil(deposit.amount) ? deposit.amount : 0;
        return acc + depositAmount;
      }
      return acc;
    }, 0);
    this.onDepositAmountChange({ newAmount: amountWishedToBeDeposited });
  }

  /**
   * is the amount form field valid or not
   * @returns {boolean} returns true when the amount field is valid
   */
  isAmountValid() {
    return (
      this.invalidFormErrors.filter((error) => error.errorType === 'ERROR')
        .length === 0
    );
  }
}

export default {
  template,
  controller: ApprovedDepositsController,
  controllerAs: 'vm',
  require: {
    prfWithdrawalDepositLink: '^',
  },
  bindings: {
    account: '<',
    deposits: '<',
    selectedCard: '<',
    withdrawalRequest: '<',
    transferMethodTypes: '<',
    onDepositAmountChange: '&',
  },
};
