import template from './sms-provider-manager.component.html';

const styles = require('./sms-provider-manager.component.scss');

import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import BrandsService from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { Brand, MappedDids } from '@proftit/crm.api.models.entities';
import { IElement } from 'restangular';
import { pipeLog, shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CrmAppStoreProviderController } from '../../../../../app/cfm-app-store-provider.component';

interface ExpectedCredentialField {
  key: string;
  type: string;
  value: string;
  validators: string;
  translationKey: string;
  isRequired: boolean;
}

export class SmsProviderManagerController {
  prfCrmAppStoreProvider: CrmAppStoreProviderController;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  isEdit = false;
  isCreate: boolean;
  reloadBrand: () => void;

  brand: Brand;
  mappedDids: any;
  blockUiRef = generateBlockuiId();
  growlRef = generateGrowlId();

  smsDataChangeSubject = new rx.BehaviorSubject<{
    smsCredentials;
    smsProvider;
    mappedDids;
  }>({
    smsCredentials: [],
    smsProvider: null,
    mappedDids: [],
  });

  /*@ngInject */
  constructor(readonly brandsService: () => BrandsService) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  enterEdit() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = false;
  }

  /**
   * Save brand with new smsProvider + smsCredentials (just for 'edit' mode)
   * (create is saved straight from /create.js)
   *
   * @returns {Promise} resolves to the updated brand record on success
   */
  save({ smsCredentials, smsProvider, mappedDids }) {
    if (_.isNil(smsProvider)) {
      this.isEdit = false;
      return;
    }

    const smsProviderId = smsProvider.id;
    // should set as key value pairs whenever the smsCredentials is an "object"
    const smsCredentialsNormalized = [
      ...smsCredentials.map(({ key, value }) => ({
        key,
        value,
      })),
    ];

    const isCreateMode = this.brand.smsCredentials.length === 0;

    const postSaveActions = (updatedBrand) => {
      // change to display mode
      this.isEdit = false;
      this.brand.smsCredentials = [updatedBrand];
      this.reloadBrand();
      return updatedBrand;
    };

    if (isCreateMode) {
      return this.brandsService()
        .setConfig({ blockUiRef: this.blockUiRef, growlRef: this.growlRef })
        .createSmsCredentials(
          this.brand.id,
          smsProviderId,
          smsCredentialsNormalized,
        )
        .then((res) => res.plain())
        .then((updatedBrand) => {
          return postSaveActions(updatedBrand);
        });
    }

    const mDids =
      mappedDids !== undefined
        ? mappedDids
        : this.brand.smsCredentials[0].mappedDids;

    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiRef, growlRef: this.growlRef })
      .updateSmsProvider(
        this.brand.id,
        smsProviderId,
        this.brand.smsCredentials[0].id,
        smsCredentialsNormalized,
        mDids,
      )
      .then((res) => res.plain())
      .then((updatedBrand) => {
        postSaveActions(updatedBrand);
      });
  }

  update({ smsCredentials, smsProvider }) {
    this.brand.smsCredentials = smsCredentials;
    this.brand.smsProvider = smsProvider;
  }
}

export const SmsProviderManagerComponent = {
  template,
  controller: SmsProviderManagerController,
  bindings: {
    brand: '<',
    isCreate: '<',
    reloadBrand: '&',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
