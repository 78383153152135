import template from './parameters-to-track-data.html';
import BaseController from '~/source/common/controllers/base';
import type { StateService } from '@uirouter/angularjs';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { IScope } from 'angular';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';

const FIELDS = ['parse'];

class ParametersToTrackDataController extends BaseController {
  marketingCampaignsServiceInstance: MarketingCampaignsService;
  isEdit: boolean = false;

  prevAttributes;
  // bindings
  models; // brand-tracks-params
  min: number;
  max: number;
  initialQty: number;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $state: StateService,
    readonly marketingCampaignsService: () => MarketingCampaignsService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    super();

    this.marketingCampaignsServiceInstance = marketingCampaignsService();

    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = [...this.models];

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    this.models = this.prevAttributes;

    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    const brandTrackerParamsData = {
      brandTrackerParams: this.models,
    };

    this.marketingCampaignsServiceInstance
      .setConfig({ blockUiRef: 'brandTrackerParamsForm' })
      .updateCampaign(this.$state.params.campaignId, brandTrackerParamsData)
      .then((res) => {
        this.isEdit = false;
      });
  }
}

export default {
  template,
  bindings: {
    models: '=',
    min: '<',
    max: '<',
    initialQty: '<',
  },
  controller: ParametersToTrackDataController,
  controllerAs: 'vm',
};
