import * as _ from '@proftit/lodash';
import { HaveLeafBehavior } from './have-leaf-behavior';
import { IsValidListener } from './is-valid-listener';
import { ValidationResultsListener } from './validation-results-listener';
import { LeafBehavior } from './leaf-behavior';
import { allFieldsAreValidProxyValidator } from './validators/all-fields-are-valid-proxy-validator';

interface ObjectProxyHandlerConfig {
  isReadonly: boolean;
  validators: any[];
}

export class ObjectProxyHandler<T extends Record<string, any>>
  implements HaveLeafBehavior<T> {
  leaf = new LeafBehavior<T>({
    onCalcChanged: () => {
      throw new Error('unimplemented');
    },
    onGetChanged: () => {
      throw new Error('unimplemented');
    },
    onGetInitial: () => {
      return this.initialValue;
    },
    onGetValue: () => {
      return this.value;
    },
    onSetValue: (value) => {
      throw new Error('not implemented');
    },
    onSetValueAsInitial: (objValue) => {
      Object.keys(objValue).forEach((key) => {
        const nextValue = objValue[key];

        const field = this.fields[key];
        field.getLeaf().setValueAsInitial(nextValue);
      });

      this.initialValue = objValue;
    },
    onGetValidationContext: () => this,
  });

  fields: Record<string, HaveLeafBehavior<any>> = {};

  isValid = true;

  isValidListeners: IsValidListener[] = [];

  initialValue: T = null;

  validationResults = [];

  validationResultsListners: ValidationResultsListener[] = [];

  validators = [];

  value: T = null;

  childValueListener = ({ value }) => {
    this.getLeaf().runValidators();
    this.runValueCalc();
  };

  constructor(config: Partial<ObjectProxyHandlerConfig>) {
    this.leaf.setIsReadonly(_.defaultTo(false, config.isReadonly));
    this.leaf.setValidators(_.defaultTo([], config.validators));
    this.leaf.addValidator(allFieldsAreValidProxyValidator);
  }

  addFieldHandler(fieldName: string, handler) {
    this.fields = {
      ...this.fields,
      [fieldName]: handler,
    };

    handler.getLeaf().addValueListener(this.childValueListener);
  }

  runValueCalc() {
    const value = _.mapValues((field) => {
      return field.getLeaf().getValue();
    }, this.fields) as T;

    this.value = value;
    this.getLeaf().notifyValueListener();
  }

  hasKey(key: string) {
    return _.has([key], this.fields);
  }

  getLeaf() {
    return this.leaf;
  }

  getKeys() {
    return Object.keys(this.fields);
  }

  getFieldHandler(key: string) {
    if (!_.has([key], this.fields)) {
      return undefined;
    }

    return this.fields[key];
  }

  getValueFor(key: string) {
    if (!_.has([key], this.fields)) {
      return undefined;
    }

    return this.fields[key].getLeaf().getValue();
  }

  setValueFor(key: string, value: any) {
    if (!_.has([key], this.fields)) {
      throw new Error('trying to set value for non existing field handler');
    }

    this.fields[key].getLeaf().setValue(value);
  }
}
