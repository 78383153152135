import {
  TransitionService,
  HookMatchCriteria,
  Transition,
} from '@uirouter/core';
import * as rx from '@proftit/rxjs';

export function observeTransitionSuccess(
  $transitions: TransitionService,
  transitionParams: HookMatchCriteria,
) {
  return new rx.Observable<Transition>((subscriber) => {
    const deregisterFn = $transitions.onSuccess(
      transitionParams,
      (transition: Transition) => {
        subscriber.next(transition);
      },
    );

    return () => deregisterFn();
  });
}
