import * as rx from '@proftit/rxjs';
import template from './contacts-dashboard-cell.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  Customer,
  CustomerComplianceStatus,
  CustomerStatus,
  UserTokenModel,
} from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import log from 'loglevel';
import { switchOn } from '@proftit/general-utilities';
import { ExcludeBy } from '~/source/common/components/dropdowns/base/component';
import TokensService from '~/source/auth/services/tokens';
import { filterCustomerStatusesFactory } from '~/source/common/utilities/filter-customer-statuses-factory';

const styles = require('./contacts-dashboard-cell.component.scss');

interface TableColumn {
  field: string;
}

export class ContactsDashboardCellController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  ExcludeBy = ExcludeBy;

  customerComplianceStatusEdit: CustomerComplianceStatus;
  customerStatusEdit: CustomerStatus;

  customerIn$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );
  colIn$ = observeShareCompChange<TableColumn>(
    this.lifecycles.onChanges$,
    'col',
  );
  isEditIn$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'isEdit',
  );
  doSaveIn$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'doSave',
  );

  onAfterSave: (a: { updatedCustomer: Customer }) => void;

  filterCustomerStatuses: (
    customerStatuses: CustomerStatus[],
  ) => CustomerStatus[];

  filterCustomerStatusesFactory: (
    user: UserTokenModel,
    currentCustomerStatus: CustomerStatus,
    isPassCurrentStatus: boolean,
  ) => (customerStatuses: CustomerStatus[]) => CustomerStatus[];

  /* @ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly tokensService: TokensService,
  ) {
    this.filterCustomerStatuses = filterCustomerStatusesFactory(
      this.tokensService.getCachedUser() as UserTokenModel,
      null,
      false,
    );

    useStreams(
      [
        this.customerIn$,
        this.colIn$,
        this.streamSave(),
        this.streamSyncEditMode(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamSyncEditMode() {
    return rx.pipe(
      () => this.isEditIn$,
      rx.filter((isEdit) => isEdit),
      rx.withLatestFrom(this.customerIn$),
      rx.tap(([isEdit, customer]) => {
        this.customerComplianceStatusEdit = customer.customerComplianceStatus;
        this.customerStatusEdit = customer.customerStatus;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSave() {
    return rx.pipe(
      () => this.doSaveIn$,
      rx.distinctUntilChanged(),
      rx.filter((doSave) => doSave),
      rx.withLatestFrom(this.customerIn$, this.colIn$),
      rx.switchMap(([a, customer, { field }]) => {
        const promise = switchOn(
          {
            customerStatus: () => this.updateCustomerStatus(customer),
            customerComplianceStatus: () =>
              this.updateCustomerComplianceStatus(customer),
          },
          field,
          () => {
            throw new Error('unimplemented');
          },
        ) as Promise<Customer>;

        return rx.obs.from(promise).pipe(
          rx.catchError((err, caught) => {
            log.error('error updating customer:', err);

            return rx.obs.NEVER;
          }),
        );
      }),
      rx.tap((updatedCustomer) => {
        this.onAfterSave({ updatedCustomer });
      }),
    )(null);
  }

  updateCustomerStatus(customer: Customer) {
    return this.customersService()
      .setConfig({
        blockUiRef: this.blockUiId,
      })
      .expand(['customerStatus'])
      .updateCustomer(customer.id, {
        customerStatusId: this.customerStatusEdit.id,
      })
      .then((customer) => ({ customerStatus: customer.customerStatus }));
  }

  updateCustomerComplianceStatus(customer: Customer) {
    return this.customersService()
      .setConfig({
        blockUiRef: this.blockUiId,
      })
      .expand(['customerComplianceStatus'])
      .updateCustomer(customer.id, {
        customerComplianceStatusId: this.customerComplianceStatusEdit.id,
      })
      .then((customer) => ({
        customerComplianceStatus: customer.customerComplianceStatus,
      }));
  }
}

export const ContactsDashboardCellComponent = {
  template,
  controller: ContactsDashboardCellController,
  bindings: {
    allowRead: '<',
    isEdit: '<',
    doSave: '<',
    onAfterSave: '&',
    col: '<',
    customer: '<',
  },
};
