const styles = require('./base-statuses.scss');
import BaseStatusesController from './base-statuses.controller';
import { CommunicationsStatusesService } from '~/source/contact/common/services/communications-statuses.service';
import template from './statuses-management.html';
import { CommunicationType } from '~/source/common/models/communication-type';
import { CommunicationTypeCode } from '@proftit/crm.api.models.enums';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { CommunicationTypesService } from '~/source/common/services/communication-types.service';
import { communicationTypeFilterItems } from './communication-type-filter-items';
import { CommunicationTypeCodeForUi } from '~/source/common/models/communication-type-code-for-ui';

class CommunicationStatusesController extends BaseStatusesController {
  static $inject = [
    'communicationsStatusesService',
    'communicationTypesService',
    ...BaseStatusesController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  communicationsStatusesService: () => CommunicationsStatusesService;
  communicationTypesService: CommunicationTypesService;
  dataService: CommunicationsStatusesService;

  filterItems = communicationTypeFilterItems;
  opFilterClick$ = new rx.Subject<CommunicationTypeCode>();
  selectedCommType$ = this.streamSelectedCommType();
  selectedFilterBarItem$ = this.streamSelectedFilterBarItem();
  dataReload$ = this.streamDataReload();
  selectedCommType: CommunicationType;
  showNewButton$ = this.streamShowNewButton();

  constructor(...args) {
    super(...args);

    useStreams(
      [this.selectedCommType$, this.selectedFilterBarItem$, this.dataReload$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.dataService = this.communicationsStatusesService();

    super.$onInit();
  }

  streamSelectedCommType() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.lifecycles.onInit$.pipe(
            rx.map(() => CommunicationTypeCode.Call),
          ),
          this.opFilterClick$,
        ),
      rx.switchMap((commTypeCode) =>
        this.communicationTypesService.getItemByCode(commTypeCode),
      ),
      rx.map((commType) =>
        _.defaultTo(
          { id: null, code: CommunicationTypeCode.Call, name: null },
          commType,
        ),
      ),
      rx.tap((commType) => (this.selectedCommType = commType)),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamSelectedFilterBarItem() {
    return rx.pipe(
      () => this.selectedCommType$,
      rx.map((commType) => {
        return this.filterItems.find(
          (barIcon) => barIcon.value === commType.code,
        );
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamDataReload() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isTableInitialized$.pipe(rx.filter((isInit) => isInit)),
          this.selectedCommType$,
        ),
      rx.withLatestFrom(this.selectedCommType$),
      rx.tap(([a, commTypeCode]) => this.getStatuses()),
    )(null);
  }

  /**
   * Observable for showing the new button
   *
   * @return observable
   */
  streamShowNewButton() {
    return rx.pipe(
      () => this.selectedCommType$,
      rx.map((commType) => commType.code !== CommunicationTypeCodeForUi._All),
    )(null);
  }

  get sectionName() {
    return 'communicationStatuses';
  }

  /**
   * Allow inheriting components to define their own required filter by
   * overriding this method.
   *
   * @returns {object} required filters
   */
  requiredApiFilters() {
    const commType = this.selectedCommType;

    const filter = _.flow([
      () => ({}),
      (f) =>
        commType && commType.code !== CommunicationTypeCodeForUi._All
          ? { ...f, typeId: commType.id }
          : f,
    ])();

    return filter;
  }

  /**
   * Get indication for showing filter bar.
   *
   * Can be override in subclasses.
   *
   * @return indication for showing the bar
   */
  showFiltersBar() {
    return true;
  }

  /**
   * Hook to perform additinal model transformation before the additon operation.
   *
   * Can be override by subclasses.
   *
   * @param model
   * @return transformed model
   */
  beforeModelAdd(modelP) {
    const model = super.beforeModelAdd(modelP);
    const commType = this.selectedCommType;

    return {
      ...model,
      typeId: commType.id,
    };
  }

  /**
   * Calc the update confirm dialog header.
   *
   * @return {Promise} Promise that will be resolved to the header value;
   */
  getUpdateConfirmHeader() {
    return this.$translate('statuses.EDIT_COMMUNICATION_STATUS_MODAL_TITLE');
  }

  /**
   * Calc the delete confirm dialog header.
   *
   * @return {Promise} Promise that will be resolved to the header value;
   */
  getDeleteHeader() {
    return this.$translate('statuses.DELETE_MODAL_TITLE_STATUS');
  }

  /**
   * Calc the update confirm dialog confirm text.
   *
   * @return {Promise} Promise that will be resolved to the confirm text value;
   */
  getUpdateConfirmConfirmText(model) {
    return this.$translate('statuses.EDIT_COMMUNICATION_STATUS_MODAL_TEXT', {
      from: this.prevValues[model.id],
      to: model.name,
    });
  }

  /**
   * Calc the delete confirm dialog confirm text.
   *
   * @return {Promise} Promise that will be resolved to the confirm text value;
   */
  getDeleteText(model) {
    return this.$translate('statuses.DELETE_TEXT_COMMUNICATION_STATUS', {
      status: this.prevValues[model.id],
    });
  }
}

export default {
  template,
  controller: CommunicationStatusesController,
};
