/**
 *
 * @param {number} amount - currency amount.
 * @param {number} unitSize - size of minimal decimal currency amount.
 * @return {number} currency as integer unit amount.
 *
 * @example
 *
 *   calcAsCurrencyUnitAmount(0.0025, 4)
 *   // 25
 *
 */
export function calcAsCurrencyUnitAmount(amount, unitSize) {
  return amount * 10 ** unitSize;
}

export default calcAsCurrencyUnitAmount;
