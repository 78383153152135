import BaseController from '~/source/common/controllers/base';
import template from './withdrawal-request-canceled-details.html';
const styles = require('./withdrawal-request.scss');

class WithdrawalRequestCanceledDetailsController extends BaseController {
  withdrawalRequest: any;
  styles = styles;
}

export default {
  template,
  controller: WithdrawalRequestCanceledDetailsController,
  bindings: {
    withdrawalRequest: '<',
  },
};
