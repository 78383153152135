import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import PositionsStatusesService from '~/source/contact/contact-page/trading-account/forex/position/positions-statuses-forex.service';
import { CustomerPositionStatusesCode } from '@proftit/crm.api.models.enums';

const openPositionsTypes = [
  CustomerPositionStatusesCode.Open,
  CustomerPositionStatusesCode.ForexOpen,
];

export class CustomerPositionStatusesStoreService {
  customerPositionStatuses$ = this.streamCustomerPositionStatuses();
  customerPositionStatusesIds$ = this.streamCustomerPositionStatusesIds();
  openCustomerPositionStatuses$ = this.streamOpenCustomerPositionStatuses();
  openCustomerPositionStatusesIds$ = this.streamOpenCustomerPositionStatusesIds();

  /* @ngInject */
  constructor(
    readonly positionsStatusesForexService: PositionsStatusesService,
  ) {}

  streamCustomerPositionStatuses() {
    return rx.pipe(
      () => rx.obs.from(this.positionsStatusesForexService.getAll()),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomerPositionStatusesIds() {
    return rx.pipe(
      () => this.customerPositionStatuses$,
      rx.map((statuses) => statuses.map((status) => status.id)),
      shareReplayRefOne(),
    )(null);
  }

  streamOpenCustomerPositionStatuses() {
    return rx.pipe(
      () => this.customerPositionStatuses$,
      rx.map((statuses) =>
        statuses.filter((status) => openPositionsTypes.includes(status.code)),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamOpenCustomerPositionStatusesIds() {
    return rx.pipe(
      () => this.openCustomerPositionStatuses$,
      rx.map((statuses) => statuses.map((status) => status.id)),
      shareReplayRefOne(),
    )(null);
  }
}
