import template from './positions-table-binary.html';
import positionsSettings from './positions-binary-settings.json';
import PositionsTableBase from '../../common/positions/positions-table-base';
import PositionsBinarySocketService from './positions-binary-socket.service';
import CustomersService from '~/source/contact/common/services/customers';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class PositionsTableBinaryController extends PositionsTableBase {
  static $inject = [
    'positionsBinarySocketService',
    ...PositionsTableBase.$inject,
  ];

  positionsBinarySocketService: PositionsBinarySocketService;
  dataServiceInstance: CustomersService;
  customer: IElementRestNg<Customer>;
  account: IElementRestNg<TradingAccount>;
  positions: IElementRestNg<any>;

  get cols() {
    return [
      ...positionsSettings.tableColumns,
      ...positionsSettings.table.extraColumns,
    ];
  }

  get ngTableSettings() {
    return {
      ...positionsSettings.table.ngTable,
    };
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Object} Socket service instance
   */
  get socketService() {
    return this.positionsBinarySocketService;
  }

  /**
   * Build a request to fetch binary positions.
   * Called by parent's "getData" ngTable method
   * @returns {RestService} restService instance, for chaining
   */
  getDataRequest() {
    return this.dataServiceInstance
      .getPositionsResource(this.customer.id, 'binary', this.account.id)
      .setConfig({ blockUiRef: 'positionsTable' })
      .expand([
        'currency',
        'positionStatus',
        'tradeAsset',
        'tradeDirection',
        'tradeProduct',
        'tradingAccount',
        'tradingAccount.currency',
        'tradingAccount.platform',
        'tradingAccount.customer',
        'tradingAccount.customer.brand',
      ])
      .filter({
        positionStatusCode: {
          exclude: 'rollover',
        },
      })
      .embed(['rolloverMetadata'])
      .sort({ entryDate: 'desc' });
  }

  parseLoadedData(positions) {
    // sum period values for ui
    positions.forEach((position) => {
      if (position.rolloverMetadata) {
        position.aggregatedPeriod = position.rolloverMetadata
          .map(({ period }) => period || 0)
          .reduce((memo, val) => memo + val, 0);
      }
    });

    this.positions = positions;

    return positions;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean} indication of whether to show columns actions.
   */
  showColumnActions() {
    return false;
  }
}

export default {
  template,
  controller: PositionsTableBinaryController,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};
