import * as rx from '@proftit/rxjs';
import { PublicSystemLogin2faService } from '../services/public-system-login-2fa.service';
import { SystemLogin2fa } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { ClientGeneralPubsub } from '../services/client-general-pubsub';
import { PubsubKey } from '../models/pubsub-key';

export class PublicSystemLogin2faStoreService {
  loadOp$ = new rx.Subject<rx.Observable<SystemLogin2fa>>();

  unsub$ = new rx.Subject<void>();

  login2fa$ = this.streamSystemLogin2fa();

  /* @ngInject */
  constructor(
    readonly prfPublicSystemLogin2faService: PublicSystemLogin2faService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams([this.login2fa$], this.unsub$);

    this.load();
  }

  streamSystemLogin2fa() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSystemLogin2faFromLoad(),
          this.streamSystemLogin2faFromPubsubChange(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamSystemLogin2faFromLoad() {
    return rx.pipe(
      () => this.loadOp$,
      rx.switchMap((action$) => action$),
    )(null);
  }

  streamSystemLogin2faFromPubsubChange() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === PubsubKey.SystemLogin2faUpdated),
      rx.switchMap(() =>
        this.prfPublicSystemLogin2faService.getSystemLogin2fa(),
      ),
    )(null);
  }

  load() {
    const action$ = rx.pipe(
      () =>
        rx.obs.from(this.prfPublicSystemLogin2faService.getSystemLogin2fa()),
      shareReplayRefOne(),
    )(null);

    this.loadOp$.next(action$);

    return action$;
  }
}
