import * as _ from '@proftit/lodash';
import io from 'socket.io-client/socket.io';

import BaseService from '~/source/common/services/baseService';

export class SocketRegistryService extends BaseService {
  sockets: { [key: string]: io.Socket } = {};

  addSocket(key: string, socket: io.Socket) {
    this.sockets = {
      ...this.sockets,
      [key]: socket,
    };
  }

  removeSocket(key: string) {
    this.sockets = _.omit([key], this.sockets);
  }

  getSocket(key: string) {
    return this.sockets[key];
  }
}
