import * as _ from '@proftit/lodash';
import {
  Customer,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';

import { TradingAccountTransferMethodTypeCode } from '@proftit/crm.api.models.enums';

const CardDepositMethods = [
  TradingAccountTransferMethodTypeCode.CardDeposit,
  TradingAccountTransferMethodTypeCode.ExternalCardDeposit,
];

/**
 * Calculate card holder for relevant deposit.
 * @param deposit - deposit.
 * @param customer - deposit's customer.
 * @return calcuated card holder name.
 */
export function calcCardHodlerForDeposit(
  deposit: TradingAccountDeposit,
  customer: Customer,
) {
  if (
    !CardDepositMethods.includes(
      deposit.transferMethodTypeCode as TradingAccountTransferMethodTypeCode,
    )
  ) {
    return null;
  }

  const specificCardHolder = _.get(
    ['transactionTransferCreditCard', 'customerCreditcard'],
    deposit,
  );

  if (
    !_.isNil(specificCardHolder) &&
    specificCardHolder.firstName &&
    specificCardHolder.lastName
  ) {
    return `${specificCardHolder.firstName} ${specificCardHolder.lastName}`;
  }

  if (customer && customer.firstName && customer.lastName) {
    return `${customer.firstName} ${customer.lastName}`;
  }

  return null;
}
