import BaseController from '~/source/common/controllers/base';
import template from './login-as.html';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import { Permissions } from '~/source/common/models/permission-structure';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';
import { growl, IWindowService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';

enum SendMethodCode {
  Sms = 'sms',
  Email = 'email',
}

class Controller {
  Permissions = Permissions;
  SendMethodCode = SendMethodCode;
  lifecycles = observeComponentLifecycles(this);

  customer: Customer;

  sendVerificationCodeAction = new rx.Subject<SendMethodCode>();

  /*ngInject*/
  constructor(
    readonly customersService: () => CustomersService,
    readonly growl: growl.IGrowlService,
    readonly $window: IWindowService,
  ) {
    useStreams([this.streamSendVerificationCode()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  /**
   * Generate a token for this customer and a url to the brand's website with the token.
   */
  loginAs() {
    return this.customersService()
      .setConfig({
        blockUiRef: 'loginAsBlock',
        growlRef: 'restService',
      })
      .generateToken(this.customer.id)
      .then(({ token }) => {
        const loginLink = this.generateLoginUrl(token);
        this.$window.open(loginLink);
      });
  }

  /**
   * Generate login url based on customer's brand and token
   *
   * @throws {Error} if customer brand url is not set
   * @param {string} token
   * @return {string}
   */
  generateLoginUrl(token) {
    const { websiteAddress } = this.customer.brand;
    if (!websiteAddress) {
      this.growl.error('contact.loginAs.NO_BRAND_URL', {
        referenceId: 'restService' as any,
      });
      throw new Error(
        'Cannot login as customer: Brand website url is not configured',
      );
    }

    return `${websiteAddress}#?prfToken=${token}`;
  }

  streamSendVerificationCode() {
    return rx.pipe(
      () => this.sendVerificationCodeAction,
      rx.tap((sendMethodCode) => {
        this.customersService().sendVerificationCode(
          this.customer.id,
          sendMethodCode,
        );
      }),
    )(null);
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    customer: '<',
  },
};
