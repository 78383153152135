import template from './brand-demo-deposit-automation-dashboard.component.html';
import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Brand } from '@proftit/crm.api.models.entities';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams } from '@proftit/rxjs.adjunct';
import { createNgModelSubject } from '~/source/common/utilities/create-ng-model-subject';
import * as rx from '@proftit/rxjs';
const styles = require('./brand-demo-deposit-automation-dashboard.component.scss');

export class BrandDemoDepositAutomationDashboardController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  selectedBrand$ = new rx.BehaviorSubject<Brand>(null);
  selectedBrand = createNgModelSubject(
    this.selectedBrand$,
    this.selectedBrand$,
  );

  /*@ngInject */
  constructor() {
    useStreams([this.selectedBrand.asObservable()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandDemoDepositAutomationDashboardComponent = {
  template,
  controller: BrandDemoDepositAutomationDashboardController,
};
