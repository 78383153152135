import { IRootScopeService } from 'angular';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import {
  fromNgScopeEvent,
  ComponentLifecyclesObservables,
} from '@proftit/rxjs.adjunct.ng1';
import {
  ITranslateService,
  CustomEvent as AngularTranslateEvents,
} from '@proftit/types-angular-translations';

export function useAngularTranslate(
  $translate: ITranslateService,
  $rootScope: IRootScopeService,
  lifecycles: ComponentLifecyclesObservables,
) {
  const translationLoadingSuccess$ = fromNgScopeEvent(
    $rootScope,
    AngularTranslateEvents.TranslateLoadingSuccess,
  ).pipe(
    rx.map(({ args: [{ language }] }) => language),
    shareReplayRefOne(),
  );

  const currentLang$ = streamCurrentLang($translate, $rootScope);
  const translationsTable$ = streamTranslationsTable(
    $translate,
    $rootScope,
    translationLoadingSuccess$,
  );

  useStreams([currentLang$, translationsTable$], lifecycles.onDestroy$);

  return {
    currentLang$,
    translationsTable$,
    translationLoadingSuccess$,
  };
}

export function streamTranslationsTable(
  $translate: ITranslateService,
  $rootScope: IRootScopeService,
  translationLoadingSuccess$: rx.Observable<any>,
) {
  return rx.obs
    .merge(
      translationLoadingSuccess$,
      fromNgScopeEvent(
        $rootScope,
        AngularTranslateEvents.TranslateLoadingEnd,
      ).pipe(rx.map(({ args: [{ language }] }) => language)),
      fromNgScopeEvent(
        $rootScope,
        AngularTranslateEvents.TranslateRefreshEnd,
      ).pipe(rx.map(({ args: [{ language }] }) => language)),
    )
    .pipe(
      rx.debounceTime(15),
      rx.map(() => $translate.use()),
      rx.scan((acc, lang) => {
        const langTable = $translate.getTranslationTable(lang);
        return {
          ...acc,
          [lang]: langTable,
        };
      }, {}),
      shareReplayRefOne(),
    );
}

export function streamCurrentLang(
  $translate: ITranslateService,
  $rootScope: IRootScopeService,
) {
  return rx.obs
    .merge(
      fromNgScopeEvent(
        $rootScope,
        AngularTranslateEvents.TranslateChangeSuccess,
      ),
      fromNgScopeEvent($rootScope, AngularTranslateEvents.TranslateChangeEnd),
    )
    .pipe(
      rx.debounceTime(5),
      rx.map(() => $translate.use()),
      rx.startWith($translate.use()),
      shareReplayRefOne(),
    );
}
