import BaseController from '~/source/common/controllers/base';
import template from './inline-edit-field.component.html';
import { INgModelController } from 'angular';

export class InlineEditFieldController extends BaseController {
  ngModel: INgModelController;
  localValue: string;
  previousValue: string;
  onUpdate: (x: { newValue: string }) => void;

  isEdit = false;

  $onInit(): void {
    this.ngModel.$render = () => {
      this.localValue = this.ngModel.$viewValue;
    };
  }

  /*
   * onChange lifeCycle event - value
   * @param: newValue
   * @return nothing
   */
  saveInitialValue(): void {
    this.previousValue = this.localValue;
  }

  activateEdit() {
    this.saveInitialValue();
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = false;
    this.localValue = this.previousValue;
  }

  confirmEdit() {
    this.isEdit = false;

    if (this.localValue === this.previousValue) {
      return;
    }

    this.ngModel.$setViewValue(this.localValue);
    this.onUpdate({ newValue: this.localValue });
  }
}

export const InlineEditFieldComponent = {
  template,
  controller: InlineEditFieldController,
  bindings: {
    fieldName: '<',
    onUpdate: '&',
  },
  require: { ngModel: '' }, // get a hold of NgModelController
};

export default InlineEditFieldComponent;
