import { CommunicationTypeCode } from '@proftit/crm.api.models.enums';
import { CommunicationTypeCodeForUi } from '~/source/common/models/communication-type-code-for-ui';

export const commTypeAll = {
  value: CommunicationTypeCodeForUi._All,
  label: 'communicationTypes.ALL',
};

export const communicationTypeFilterItems = [
  {
    value: CommunicationTypeCode.Comment,
    label: 'communicationTypes.COMMENT',
  },
  {
    value: CommunicationTypeCode.Call,
    label: 'communicationTypes.CALL',
  },
  {
    value: CommunicationTypeCode.Sms,
    label: 'communicationTypes.SMS',
  },
  {
    value: CommunicationTypeCode.Email,
    label: 'communicationTypes.EMAIL',
  },
];

export const communicationTypeFilterItemsAll = [
  commTypeAll,
  ...communicationTypeFilterItems,
];
