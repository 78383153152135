import template from './orange-inline-editor.component.html';
const styles = require('./orange-inline-editor.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class OrangeInlineEditorController {
  onEditClick;
  onCancelClick;
  onConfirmClick;
  isEdit;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const OrangeInlineEditorComponent = {
  template,
  controller: OrangeInlineEditorController,
  transclude: {
    view: 'prfTrnView',
    edit: 'prfTrnEdit',
  },
  bindings: {
    isEdit: '<',
    onEditClick: '&',
    onCancelClick: '&',
    onConfirmClick: '&',
  },
};
