import * as _ from '@proftit/lodash';
import { FieldProxyHandler } from '../field-proxy-handler';

export const IS_STRING_CONSTANT_VALUE = 'IS_STRING_CONSTANT_VALUE';

export function isStringValueProxyValidatorFactory(validValues: string[]) {
  return function isStringValueProxyValidator(handler: FieldProxyHandler<any>) {
    if (_.isNil(handler.value)) {
      return {
        code: IS_STRING_CONSTANT_VALUE,
        isValid: true,
      };
    }

    const result = validValues.includes(handler.value);

    if (result) {
      return {
        code: IS_STRING_CONSTANT_VALUE,
        isValid: true,
      };
    }

    return {
      code: IS_STRING_CONSTANT_VALUE,
      isValid: false,
      payload: {
        validValues,
        value: handler.value,
      },
    };
  };
}
