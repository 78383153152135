import { IPromise } from 'angular';
import { SystemLogin2fa } from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { IElementRestNg } from '../models/ielement-rest-ng';

const MAIN_RESOURCE = 'system';
const FA_LOGIN_RESOURCE = 'login2fa';

export class PublicSystemLogin2faService extends RestService {
  get resource() {
    return MAIN_RESOURCE;
  }

  get accessType() {
    return 'public';
  }

  getSystemLogin2fa(): Promise<SystemLogin2fa> {
    return this.resourceBuildStart()
      .getElement(FA_LOGIN_RESOURCE)
      .resourceBuildEnd()
      .customGetWithQuery<IElementRestNg<SystemLogin2fa>>()
      .then((data) => data.plain());
  }
}
