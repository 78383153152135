import template from './filter-label.html';
const styles = require('./filter-label.component.scss');

class Controller {
  styles = styles;
}

export default {
  template,
  controller: Controller,
  bindings: {
    filter: '<',
    key: '<',
    showFilterExcludeActions: '<',
    filterExcluded: '<',
    onFilterRemoveFn: '<',
    onIsActiveToggleFn: '<',
    onExcludeFilterFn: '<',
    onIncludeFilterFn: '<',
  },
  controllerAs: 'vm',
};
