import ng from 'angular';

import FieldsAndModulesListComponent from './fields-and-modules-list/fields-and-modules-list.component';
import CustomerStatusesComponent from './customer-statuses.component';
import EwalletsComponent from './ewallets.component';
import CommunicationStatusesComponent from './communication-statuses.component';
import CommunicationSubjectsComponent from './communication-subjects/communication-subjects.component';
import ComplianceStatusesComponent from './compliance-statuses.component';
import ConfigurableClearingCompanyManagementComponent from './configurable-clearing-company-management/configurable-clearing-company-management.component';
import CustomerDynamicPropertiesEditorComponent from './customer-dynamic-properties-editor/customer-dynamic-properties-editor.component';
import CustomerDynamicPropertiesEditorManagerComponent from './customer-dynamic-properties-editor-manager/customer-dynamic-properties-editor-manager.component';
import EditBoxComponent from './edit-box/edit-box.component';
import { FieldsAndModulesListSelectComponent } from './fields-and-modules-list-select.component';
import { AttachmentsManagementDashboardComponent } from './attachments-management-dashboard/attachments-management-dashboard.component';
import { fieldsAndModulesRouting } from './fields-and-modules.routing';
import { AttachmentsAdditionalsListManagementComponent } from './attachments-additionals-list-management/attachments-additionals-list-management.component';
import { AttachmentsKycListManagementComponent } from './attachments-kyc-list-management/attachments-kyc-list-management.component';

export const FieldsAndModulesModule = ng.module(
  'crm.fieldsAndModulesManagement',
  ['crm.common', 'ui.router'],
);

FieldsAndModulesModule.component(
  'prfStatusesList',
  <any>FieldsAndModulesListComponent,
)
  .component('prfCustomerStatusesManagement', <any>CustomerStatusesComponent)
  .component('prfEwalletsManagement', <any>EwalletsComponent)
  .component(
    'prfCommunicationStatusesManagement',
    <any>CommunicationStatusesComponent,
  )
  .component(
    'prfComplianceStatusesManagement',
    <any>ComplianceStatusesComponent,
  )
  .component(
    'prfConfigurableClearingCompanyManagement',
    <any>ConfigurableClearingCompanyManagementComponent,
  )
  .component('prfCommunicationSubjects', <any>CommunicationSubjectsComponent)
  .component(
    'prfCustomerDynamicPropertiesEditor',
    CustomerDynamicPropertiesEditorComponent,
  )
  .component(
    'prfCustomerDynamicPropertiesEditorManager',
    CustomerDynamicPropertiesEditorManagerComponent,
  )
  .component('prfEditBox', EditBoxComponent)
  .component(
    'prfFieldsAndModulesListSelect',
    FieldsAndModulesListSelectComponent,
  )
  .component(
    'prfAttachmentsManagementDashboard',
    AttachmentsManagementDashboardComponent,
  )
  .component(
    'prfAttachmentsAdditionalsListManagement',
    AttachmentsAdditionalsListManagementComponent,
  )
  .component(
    'prfAttachmentsKycListManagement',
    AttachmentsKycListManagementComponent,
  )
  .config(fieldsAndModulesRouting);
