import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';
import {
  CfdMongoRequest,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

export function ngPerformMongo<T>($http: IHttpService, reqP: CfdMongoRequest) {
  return _.flow([
    (req) => cfdMongoToNgHttp(req),
    (req) => ngHttpPerformRequest<T>($http, req),
  ])(reqP);
}
