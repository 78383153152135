import { toDelimitedItemsString } from '@proftit/general-utilities';
import * as _ from '@proftit/lodash';

export function formatJsonToDlimitedItemsString(
  str: string,
  processDataFn: (data: Record<string, any>) => Record<string, any> = (x) => x,
) {
  try {
    return _.flow([
      JSON.parse,
      processDataFn,
      (x) => toDelimitedItemsString(x, { keyTransformer: _.startCase }),
    ])(str);
  } catch {}

  return str;
}
