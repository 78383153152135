import ng from 'angular';
import log from 'loglevel';
import { CustomersService } from '~/source/contact/common/services/customers';
import * as _ from '@proftit/lodash';
import template from './bundle-positions-table.component.html';
import { NgTableParams as aNgTableParams } from 'ng-table/src/core/ngTableParams';
import { bundlePositionsTableCols } from './bundle-positions-table-cols';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { PositionsForexSocketService } from '../position/positions-forex-socket.service';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import TokensService from '~/source/auth/services/tokens';
import { Position } from '~/source/common/models/position';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { Bundle } from '~/source/common/models/bundle';
import * as rx from '@proftit/rxjs';
import { streamSubscribeToPositionSocketsUpdates } from '../../common/positions/utils/stream-subscribe-to-position-sockets-updates';
import { Mt4OrderPnlSocketService } from '~/source/common/services/mt4-order-pnl-socket.service';
import { CfdPlatformPositionPayoutSocketService } from '~/source/common/api-cfd-platform/cfd-platform-position-payout-socket.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const OPEN_PNL_UPDATE_INTERVAL = 5000;

export class BundlePositionsTableController {
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  lifecycles: {
    onInit$: rx.Observable<void>;
    onInitShared$: rx.Observable<boolean>;
    onChanges$: rx.Observable<any>;
    onDestroy$: rx.Observable<any>;
  } = observeComponentLifecycles(this);
  cols = bundlePositionsTableCols;
  tableParams: aNgTableParams<Position>;
  positions$ = new rx.BehaviorSubject<Position[]>([]);
  cachedUser: UserTokenModel;
  customerId: number;
  accountId: number;
  bundleId: number;

  updateEntityOp$ = new rx.Subject<Position>();

  /* @ngInject */
  constructor(
    readonly NgTableParams: typeof aNgTableParams,
    readonly customersService: () => CustomersService,
    readonly positionsForexSocketService: PositionsForexSocketService,
    readonly tokensService: TokensService,
    readonly mt4OrderPnlSocketService: () => Mt4OrderPnlSocketService,
    readonly cfdPlatformPositionPayoutSocketService: () => CfdPlatformPositionPayoutSocketService,
  ) {
    this.cachedUser = this.tokensService.getCachedUser();

    useStreams(
      [
        this.streamGetPositions(),
        this.streamCalcNgTable(),
        this.streamManageSocketSubscription(),
        observeCompChange<Position[]>(
          this.positions$,
          'positions',
          this.lifecycles.onChanges$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        streamSubscribeToPositionSocketsUpdates(
          this.positions$,
          OPEN_PNL_UPDATE_INTERVAL,
          this.mt4OrderPnlSocketService,
          this.cfdPlatformPositionPayoutSocketService,
          (props: Position) => this.updateEntityOp$.next(props),
          this.prfCurrentPlatformSession,
          new rx.Subject(),
        ),
        this.streamUpdateEntities(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamGetPositions() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.mergeMap(() =>
        rx.obs.from(
          this.fetchFn(this.customerId, this.accountId, this.bundleId),
        ),
      ),
      rx.map((bundle) => bundle.positionsForex),
      rx.tap((positions) => this.positions$.next(positions)),
    )(null);
  }

  streamCalcNgTable() {
    return rx.pipe(
      () => this.positions$,
      rx.map(
        (positions) =>
          new this.NgTableParams(
            {},
            {
              dataset: positions,
            },
          ),
      ),
      rx.tap((ngTableParams) => (this.tableParams = ngTableParams)),
    )(null);
  }

  streamManageSocketSubscription() {
    return rx.pipe(
      () => this.positions$,
      rx.distinctUntilChanged((previousPositions, currentPositions) => {
        if (previousPositions.length !== currentPositions.length) {
          return false;
        }
        return _.isEqualWith(
          (a, b) => a.id === b.id,
          previousPositions,
          currentPositions,
        );
      }),
      rx.switchMap((positions) => {
        const positionsOps = positions.map((position) => {
          return rx.pipe(
            // 1. subscribe to each bundlePosition
            // 2. unsub when bundlePosition Status changed to 'close'
            () =>
              observeChannel(
                this.positionsForexSocketService,
                this.buildChannel(position.id),
              ),
            rx.withLatestFrom(this.positions$),
            // 3. arrayMerge when socket report change
            rx.map(([positionUpdate, positions]) => {
              const positionToUpdate = positions.find(
                (position) => position.id === (positionUpdate as Position).id,
              );
              const positionAfterUpdate = {
                ...positionToUpdate,
                ...(positionUpdate as Position),
              };
              return positions.reduce((acc, position) => {
                if (position.id === (positionUpdate as Position).id) {
                  return [...acc, positionAfterUpdate];
                }
                return [...acc, position];
              }, []);
            }),
            // 4. insert newPositions to table
            rx.tap((newPositions) => this.positions$.next(newPositions)),
          )(null);
        });
        return rx.obs.merge(...positionsOps);
      }),
      rx.catchError((err, caught$) => {
        log.error('streamer bundle position error', err);
        return rx.obs.EMPTY;
      }),
    )(null);
  }

  buildChannel(elementId: number): string {
    return `user.${this.cachedUser.id}.${this.positionsForexSocketService.channelRoot}.${elementId}`;
  }

  fetchFn(customerId: number, accountId: number, bundleId: number) {
    return this.customersService()
      .getBundleResource(customerId, accountId, bundleId)
      .setConfig({ blockUiRef: 'bundlePositionsTable' })
      .embed(['positionsForex'])
      .expand([
        'positionsForex.currency',
        'positionsForex.positionStatus',
        'positionsForex.tradeAsset',
        'positionsForex.tradingAccount.currency',
        'positionsForex.tradingAccount.platform',
      ])
      .sort({ entryDate: 'desc' })
      .getOneWithQuery<IElementRestNg<Bundle>>();
  }

  streamUpdateEntities() {
    return rx.pipe(
      () => this.positions$,
      rx.switchMap(() => this.updateEntityOp$),
      rx.withLatestFrom(this.positions$),
      rx.tap(([updates, positions]) => {
        const found = positions.find((p) => p.id === updates.id);
        if (_.isNil(found)) {
          return;
        }

        Object.assign(found, updates);
      }),
    )(null);
  }
}

export const BundlePositionsTableComponent = {
  template,
  controller: BundlePositionsTableController,
  bindings: {
    bundleId: '<',
    accountId: '<',
    customerId: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};
