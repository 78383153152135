import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import {
  // generateInProcessSubject,
  tapInProcessOn,
  // catchErrorInProcessOff,
  tapFinalizeInProcessOff,
  shareReplayRefOne,
  // tapLog,
  useStreams,
  InProcessInfo,
} from '@proftit/rxjs.adjunct';
import { directivesPriorities } from '../constants/directives-priorities';
import { DepartmentsService } from '~/source/management/user/services/departments';
import IElementRestNg from '../models/ielement-rest-ng';
import { UserDepartmentCode } from '@proftit/crm.api.models.entities';

export class CurrentDepartmentsStoreServiceDirectiveController {
  lifecycles = observeComponentLifecycles(this);

  departmentsInProcess$ = new rx.BehaviorSubject<InProcessInfo>({
    inProcess: false,
    error: null,
  });

  departments$ = this.streamDepartments();

  constructor(readonly departmentsService: () => DepartmentsService) {}

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamDepartments(): rx.Observable<UserDepartmentCode[]> {
    const streamFn = rx.pipe(
      () => rx.obs.of(true),
      tapInProcessOn(this.departmentsInProcess$),
      rx.switchMap(() =>
        this.departmentsService()
          .getListWithQuery<IElementRestNg<UserDepartmentCode>>()
          .then((data) => data.plain()),
      ),
      tapFinalizeInProcessOff(this.departmentsInProcess$),
      shareReplayRefOne(),
      rx.catchError(() => streamFn(null)),
    );

    return streamFn(null);
  }
}

export const currentDepartmentsStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {},
    bindToController: true,
    controller: CurrentDepartmentsStoreServiceDirectiveController,
  };
};

currentDepartmentsStoreServiceDirective.$inject = [] as string[];
