import Promise from 'bluebird';
import Component from './base/component';
import { BooleanSelectOptions } from './boolean-select-item';

class BooleanController extends Component.controller {
  query() {
    const values = BooleanSelectOptions;

    return Promise.resolve(values);
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'booleanSelect';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'name';
  }
}

export default Component.config({ controller: BooleanController });
