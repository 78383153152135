import RestService from '~/source/common/services/rest';

const PUBLIC = 'public';

export class FilesUploadService extends RestService {
  static $inject = ['Upload', ...RestService.$inject];
  Upload: any;

  /**
   * Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'files';
  }

  uploadPrivateFile(file, options: any = {}) {
    /**
     * Restangular unforutnely does not easily support file uploading
     * @see {@link https://github.com/mgonto/restangular/issues/420}
     *
     * so we use this ng-file-upload service instead, which is much better for uploading.
     * @see {@link https://github.com/danialfarid/ng-file-upload}
     */
    return this.Upload.upload({
      url: `${this.baseUrl}/${this.resource}`,
      data: { file },
      ...options,
    });
  }

  uploadPublicFile(file, options: any = {}) {
    return this.Upload.upload({
      url: `${this.baseUrl}/${this.resource}/${PUBLIC}`,
      data: { file },
      ...options,
    });
  }
}
