import 'core-js/modules/es.array.iterator.js';
import 'core-js/modules/web.dom-collections.iterator.js';
import * as _ from '@proftit/lodash-fp';
import 'core-js/modules/es.regexp.exec.js';
import 'core-js/modules/es.string.replace.js';
import 'core-js/modules/es.regexp.to-string.js';
import 'core-js/modules/es.array.reduce.js';
import 'core-js/modules/es.regexp.constructor.js';
import 'core-js/modules/es.parse-float.js';
import 'core-js/modules/es.object.assign.js';
import 'core-js/modules/es.promise.js';
import { __awaiter } from 'tslib';

/**
 * A convenience function, to get a hashMap from an array.
 * @param array - The array that will be used to create the hashMap.
 * @param key - Indicates the field name that will be used to extract the value out of each element, to use as keys for the hashMap.
 * @returns - A hashMap, where the keys are the values at <strong>key</strong> for each element of the array, and the value for each key is the element itself.
 */

function arrayToHashMap(array, key) {
  // @ts-ignore
  const hashMap = new Map();
  array.forEach(element => {
    const keyForElement = element[key];

    if (_.isNil(keyForElement)) {
      return;
    }

    hashMap.set(keyForElement, element);
  });
  return hashMap;
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
/*
 *
 */

function generateRandomChar() {
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

/*
 *
 */

function generateRandomStringArray(length, randomCharGenerator = generateRandomChar) {
  const result = [];

  for (let i = 0; i < length; i++) {
    result.push(randomCharGenerator());
  }

  return result;
}

/*
 *
 */

function generateRandomString(length) {
  const result = generateRandomStringArray(length);
  return result.join('');
}

function getWithDefault(path, defaultVal, source) {
  const val = _.get(path, source);

  if (!_.isUndefined(val)) {
    return val;
  }

  return defaultVal;
}

/**
 * Taken from https://stackoverflow.com/a/8809472/9747899
 */
function generateUuid() {
  let d = new Date().getTime();

  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    /* eslint-disable-next-line no-bitwise */
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    /* eslint-disable-next-line no-bitwise */

    return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
}

/*
 * Return whether a subject is an Array.
 *
 * Add type guard so typechecking can rely on the compile
 * type data to know further down the code path that we
 * are dealing with array.
 *
 * @param subject - subject to test for is array.
 * @returns wether the subject is array or not.
 */

function isArray(subject) {
  return _.isArray(subject);
}

/*
 * Return whether a subject is a string.
 *
 * Add type guard so typechecking can rely on the compile
 * type data to know further down the code path that we
 * are dealing with string.
 *
 * @param subject - subject to test for is string.
 * @returns wether the subject is string or not.
 */

function isString(subject) {
  return _.isString(subject);
}

function objShallowDifference(target, base) {
  return _.flow([() => _.toPairs(target), pairs => {
    if (_.isNil(base)) {
      return target;
    }

    return pairs.reduce((accDiff, [key, val]) => {
      const baseVal = base[key];

      if (val !== baseVal) {
        accDiff[key] = val;
      }

      return accDiff;
    }, {});
  }])();
}

/*
 * Parse string into number.
 *
 * @param {string|number} value - String containing number value, or a number.
 * @param {string} locale=navigator.language - The locale to parse according to
 * @return {number} The parsed number
 *
 * @example
 *
 *     parseNumber('1.234')
 *     // 1.234
 *
 * source:
 * https://stackoverflow.com/questions/11665884/how-can-i-parse-a-string-with-a-comma-thousand-separator-to-a-number
 */

function parseNumber(value, locale = navigator.language) {
  // If value is already a number, return as is.
  if (_.isFinite(value)) {
    return value;
  }
  /*
   * We want to get the currect period chacaracter for the selected locale.
   * In german its ',' instead of '.' for instance.
   */


  const decimalChar = Intl.NumberFormat(locale).format(1.1).charAt(1); // Construct a pattern that represent all the valid characters in a number.

  const cleanPattern = new RegExp(`[^-+0-9${decimalChar}]`, 'g');
  /*
   * Remove all unnessasery characters and keep just the nessarry ones to represent a number.
   * For example ',' if the decimalChar is '.'
   */

  const cleaned = value.replace(cleanPattern, '');
  /*
   * Normalize the cleand string number into standard number that use '.' for decimals.
   */

  const normalized = cleaned.replace(decimalChar, '.'); // Use the standard `parsrFloat` to parse the normalized string number

  return parseFloat(normalized);
}

function removeColonAtEnd(str) {
  return str.replace(/:$/, '');
}

function switchOn(switchHash, condValue,
/* eslint-disable-next-line */
defaultOn = () => {
  throw new Error('not implemented');
}) {
  if (_.has([condValue], switchHash)) {
    return switchHash[condValue]();
  }

  return defaultOn();
}

/*
 * Switch on statement with default throw action on unhandled keys.
 */

function switchOnEx(switchHash, condValue) {
  const defaultOn = () => {
    /* eslint-disable no-console */
    // tslint:disable-next-line:no-console
    console.error('unhandled switch case', condValue);
    /* eslint-enable no-console */

    throw new Error('unhandled switch case');
  };

  return switchOn(switchHash, condValue, defaultOn);
}

/**
 * toDelimitedItemsString
 *
 * @param source
 * @param paramsP?
 * @returns string represntation of source
 */

function toDelimitedItemsString(source, paramsP) {
  const params = Object.assign({
    keyValSeparator: ': ',
    delimiter: ', ',
    valueTransformer: x => x,
    keyTransformer: x => x
  }, paramsP);
  return _.flow([r => _.toPairs(r), r => r.map(keyVal => `${params.keyTransformer(keyVal[0])}${params.keyValSeparator}${params.valueTransformer(keyVal[1])}`), keyVals => keyVals.join(params.delimiter)])(source);
}

function getByCodeFrom(list, codeValue, lookupKey = 'code') {
  return list.find(item => item[lookupKey] == codeValue);
}

function getByNestedCodeFrom(list, codeValue, nestedKey, lookupKey = 'code') {
  return list.find(item => item[nestedKey][lookupKey] == codeValue);
}

function asyncWaitUntil(condition, waitTime = 10, loopCount = 0, maxIterations = 5) {
  return __awaiter(this, void 0, void 0, function* () {
    if (loopCount > maxIterations) return Promise.reject('Max iteration reach');
    const {
      success,
      result
    } = yield condition(loopCount);

    if (success) {
      return Promise.resolve(result);
    }

    yield sleep(waitTime);
    return asyncWaitUntil(condition, waitTime * 2, loopCount + 1, maxIterations);
  });
}

function sleep(duration) {
  return new Promise(resolve => {
    setTimeout(resolve, duration);
  });
}

export { arrayToHashMap, asyncWaitUntil, generateRandomChar, generateRandomString, generateRandomStringArray, generateUuid, getByCodeFrom, getByNestedCodeFrom, getWithDefault, isArray, isString, objShallowDifference, parseNumber, removeColonAtEnd, sleep, switchOn, switchOnEx, toDelimitedItemsString };
