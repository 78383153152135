import template from './hover-edit.component.html';
const styles = require('./hover-edit.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class HoverEditController {
  isEdit: boolean;
  isSaveDisabled: boolean;
  onEditClick;
  onCancelClick;
  onSaveClick;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const HoverEditComponent = {
  template,
  controller: HoverEditController,
  bindings: {
    isEdit: '<',
    isSaveDisabled: '<',
    alwaysShow: '<',
    onEditClick: '&',
    onCancelClick: '&',
    onSaveClick: '&',
  },
};
