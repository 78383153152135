import BaseController from '~/source/common/controllers/base';
import template from './my-filters-dropdown.html';
import modalTemplate from '../delete-filter-modal.html';
import predefinedFilters from '../predefined-filters.json';
import { IScope, ISCEService } from 'angular';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { UserFilters } from '~/source/common/services/user-filters';
import { SharedGroupFiltersService } from '~/source/common/services/shared-group-filters.service';
import * as _ from '@proftit/lodash';
import { PopupService } from '../../../modal/popup.service';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { SharedGroupFilter } from '~/source/common/models/shared-group-filter';
import { UserFilter } from '~/source/common/models/user-filter';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { TABLE_FILTER_UPDATE_DROPDOWN_MODEL } from '~/source/common/constants/general-pubsub-keys';
import { TokensService } from '~/source/auth/services/tokens';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import * as rx from '@proftit/rxjs';
import { wrapNgPermissionValidatePromise } from '~/source/common/utilities/wrap-ng-permission-validate-promise';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';

function sharedGroupFiltersToGroupedUserFilters(sharedGroupFilters) {
  return _.flow([
    // group by filter id
    (filtersAssocs) =>
      _.groupBy((filterAssoc) => filterAssoc.userFilter.id, filtersAssocs),
    // create userFilter object with sharedGroupFilters property
    (groupedFilters) =>
      _.mapValues(
        (group) => ({
          ...group[0].userFilter,
          sharedGroupFilters: group.map((sharedGroupFilter) => ({
            department: sharedGroupFilter.department,
          })),
        }),
        groupedFilters,
      ),
    // convert result object to list
    (objSharedGroupFilters) => _.values(objSharedGroupFilters),
  ])(sharedGroupFilters);
}

interface DisplayedFilters {
  systemFilters: UserFilter[];
  sharedUserAndGroupFilters: UserFilter[];
  privateUserFilters: UserFilter[];
}

class MyFiltersDropdownController extends BaseController {
  category;
  filtersState;
  model;

  UserRolePositionCode = UserRolePositionCode;

  isOpen: boolean;

  unsub$ = new rx.Subject<void>();
  opGetAllFilters$ = new rx.Subject<void>();
  opCalcDisplayedFilters$ = new rx.Subject<void>();
  displayedFilters$ = new rx.BehaviorSubject<DisplayedFilters>({
    systemFilters: [],
    sharedUserAndGroupFilters: [],
    privateUserFilters: [],
  });
  systemFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  groupedSharedGroupFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  sharedUserAndGroupFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  userFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  privateUserFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  sharedUserFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  nonUserGroupedSharedGroupFilters$ = new rx.BehaviorSubject<UserFilter[]>([]);
  user$ = new rx.BehaviorSubject<UserTokenModel>(null);
  filterState$ = new rx.BehaviorSubject<any>(null);
  calcSharedFilterTooltipMem: Function;
  hasSharedGroupFiltersPerm$ = new rx.BehaviorSubject<boolean>(false);

  /*@ngInject */
  constructor(
    readonly userFiltersService: UserFilters,
    readonly modalService: ModalService,
    readonly popupService: PopupService,
    readonly $scope: IScope,
    readonly sharedGroupFiltersService: SharedGroupFiltersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly tokensService: TokensService,
    readonly $sce: ISCEService,
    readonly $sanitize,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    super();

    this.calcSharedFilterTooltipMem = _.memoize((filter) => {
      return this.calcSharedFilterTooltip(filter);
    });
  }

  $onInit() {
    this.filtersState = this.filtersState || {};

    useStreams(
      [
        this.streamCalcUser(),
        this.streamFetchAllFilters(),
        this.streamCalcPrivateAndSharedUserFilters(),
        this.streamCalcNonUserSharedGroupFilters(),
        this.streamCalcSharedUserAndGroupFilters(),
        this.streamCalcDisplayedFilters(),
        this.streamUpdateFromDropdownUpdateEvent(),
        this.streamUpdateSelectedFromFilterState(),
        this.streamCalcHasSharedGroupFiltersViewPerm(),
      ],
      this.unsub$,
    );

    this.opCalcDisplayedFilters$.next();
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamCalcUser() {
    return rx.pipe(
      () => rx.obs.of(this.tokensService.getCachedUser()),
      rx.tap((user) => this.user$.next(user)),
    )(null);
  }

  streamFetchAllFilters() {
    return rx.pipe(
      () => this.opGetAllFilters$,
      rx.switchMap(() =>
        rx.obs.forkJoin(
          rx.obs.from(this.fetchSystemFilters()),
          this.streamFetchSharedFilter(),
          rx.obs.from(this.fetchUserFilters()),
        ),
      ),
      rx.map(([systemFilters, sharedGroupFilters, userFilters]) => {
        const groupedSharedGroupFilters = sharedGroupFiltersToGroupedUserFilters(
          sharedGroupFilters,
        );

        return [systemFilters, groupedSharedGroupFilters, userFilters];
      }),
      rx.tap(([systemFilters, groupedSharedGroupFilters, userFilters]) => {
        this.systemFilters$.next(systemFilters);
        this.groupedSharedGroupFilters$.next(groupedSharedGroupFilters);
        this.userFilters$.next(userFilters);
      }),
    )(null);
  }

  streamCalcPrivateAndSharedUserFilters() {
    return rx.pipe(
      () => this.userFilters$,
      rx.map((userFilters) => userFilters.filter((filter) => !filter.isSystem)),
      rx.map((userFilters) => {
        const privateUserFilters = userFilters.filter(
          (filter) => filter.sharedGroupFilters.length === 0,
        );
        const sharedUserFilters = userFilters.filter(
          (filter) => filter.sharedGroupFilters.length > 0,
        );

        return { privateUserFilters, sharedUserFilters };
      }),
      rx.tap(({ privateUserFilters, sharedUserFilters }) => {
        this.privateUserFilters$.next(privateUserFilters);
        this.sharedUserFilters$.next(sharedUserFilters);
      }),
    )(null);
  }

  streamCalcNonUserSharedGroupFilters() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.groupedSharedGroupFilters$, this.user$),
      rx.map(([groupedSharedGroupFilters, user]) => {
        if (_.isNil(user)) {
          return groupedSharedGroupFilters;
        }

        return groupedSharedGroupFilters.filter(
          (filter) => filter.userId !== user.id,
        );
      }),
      rx.tap((filters) => this.nonUserGroupedSharedGroupFilters$.next(filters)),
    )(null);
  }

  streamCalcSharedUserAndGroupFilters() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.nonUserGroupedSharedGroupFilters$,
          this.sharedUserFilters$,
          this.hasSharedGroupFiltersPerm$,
        ),
      rx.map(
        ([nonUserSharedGroupFilters, sharedUserFilters, hashSharedPerm]) => {
          if (!hashSharedPerm) {
            return [...sharedUserFilters];
          }

          return [...nonUserSharedGroupFilters, ...sharedUserFilters];
        },
      ),
      rx.tap((filters) => this.sharedUserAndGroupFilters$.next(filters)),
    )(null);
  }

  streamCalcDisplayedFilters() {
    return rx.pipe(
      () => this.opCalcDisplayedFilters$,
      rx.tap(() => this.opGetAllFilters$.next()),
      rx.switchMap(() =>
        rx.obs.combineLatest(
          this.systemFilters$,
          this.sharedUserAndGroupFilters$,
          this.privateUserFilters$,
        ),
      ),
      rx.map(
        ([systemFilters, sharedUserAndGroupFilters, privateUserFilters]) => {
          return {
            systemFilters,
            sharedUserAndGroupFilters,
            privateUserFilters,
          };
        },
      ),
      rx.tap((displayedFilters) =>
        this.displayedFilters$.next(displayedFilters),
      ),
    )(null);
  }

  streamUpdateFromDropdownUpdateEvent() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === TABLE_FILTER_UPDATE_DROPDOWN_MODEL),
      rx.tap(() => this.opGetAllFilters$.next()),
      rx.tap(({ payload }) => (this.model = payload.filter)),
    )(null);
  }

  streamUpdateSelectedFromFilterState() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.filterState$, this.displayedFilters$),
      rx.map(([filterState, displayedFilters]) => {
        const allFilters = [
          ...displayedFilters.systemFilters,
          ...displayedFilters.sharedUserAndGroupFilters,
          ...displayedFilters.privateUserFilters,
        ];

        const filterFound = allFilters.find((filter) =>
          _.isEqual(filter.filters, filterState),
        );

        return filterFound;
      }),
      rx.tap((filter) => {
        this.onSelect(filter || {});
      }),
    )(null);
  }

  streamCalcHasSharedGroupFiltersViewPerm() {
    return rx.pipe(
      () => this.opCalcDisplayedFilters$,
      rx.map(() =>
        wrapNgPermissionValidatePromise(
          this.PermPermissionStore.getPermissionDefinition(
            'contacts.personalinfo.sharedgroupfilters',
          ),
        ),
      ),
      rx.switchMap((x) => rx.obs.from(x)),
      rx.tap((hasPerm) => this.hasSharedGroupFiltersPerm$.next(hasPerm)),
    )(null);
  }

  fetchSystemFilters() {
    return Promise.resolve(
      predefinedFilters.filter(
        (filter) => filter.filterTypeCode === this.category,
      ),
    );
  }

  /**
   * Fetch all the user filters and populates the "this.filters" object with two lists:
   * - system: all the filters with "isSystem" = true (predefined filters)
   * - custom: all the filters with "isSystem" = false (user custom filters)
   *
   * @return {Promise} Promise which is resolved to the created "filters" object
   */
  fetchUserFilters() {
    return this.userFiltersService
      .setConfig({ blockUiRef: 'myFilters' })
      .filter('filterTypeCode', this.category)
      .embed(['sharedGroupFilters'])
      .expand(['sharedGroupFilters.department'])
      .sort('name', 'asc')
      .getListWithQuery()
      .then((data) => data.plain());
  }

  streamFetchSharedFilter() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          checkCrudPermission(
            PermissionNormalized.ContactPersonalInfoSharedGroupFilters,
            this.PermPermissionStore,
          ),
        ]),
      rx.switchMap(([sharedGroupFiltersPermission]) => {
        if (!sharedGroupFiltersPermission.isView) {
          return rx.obs.of([]);
        }
        return this.fetchSharedGroupFilters();
      }),
      shareReplayRefOne(),
    )(null);
  }

  fetchSharedGroupFilters() {
    return this.sharedGroupFiltersService
      .setConfig({ blockUiRef: 'myFilters' })
      .expand(['userFilter', 'department'])
      .getListWithQuery<IElementRestNg<SharedGroupFilter>>()
      .then((data) => data.plain());
  }

  /**
   * Called when the "delete" button of a filter is clicked.
   * Opens the remove confirm modal.
   *
   * @param {object} filter selected filter object
   * @return {void}
   */
  openRemoveModal(filter) {
    this.isOpen = false;

    this.modalService.open({
      controller: 'DeleteFilterModalController',
      template: modalTemplate,
      scope: this.$scope,
      data: {
        filter,
        onRemove: this.onRemove.bind(this),
      },
    });
  }

  openShareModal(filter) {
    this.isOpen = false;

    this.modalService.open({
      component: 'prfUpdateSharedGroupFilterModal',
      resolve: {
        filterId: () => filter.id,
      },
    });
  }

  /**
   * Called when a filter is selected.
   * Sets the model and closes the dropdown.
   * @param {object} filter filter object
   * @return {void}
   */
  onSelect(filter) {
    this.model = filter;
    this.isOpen = false;
  }

  /**
   * Called automagically by the parent class when the "filtersState" input changes.
   * Detects if one of the filters in the list should be selected (if it matches the current filters state)
   */
  onFiltersStateChange(filterState) {
    this.filterState$.next(filterState);
  }

  /**
   * Called when a filter is deleted.
   * Resets the selected model.
   *
   * @param {object} removedFilter
   * @return {void}
   */
  onRemove(removedFilter) {
    this.opCalcDisplayedFilters$.next();
  }

  calcSharedFilterTooltip(filter: UserFilter) {
    const deptsNames = filter.sharedGroupFilters.map((x) => x.department.name);
    const deptsLi = deptsNames.map(
      (name) => `<li>${this.$sanitize(name)}</li>`,
    );
    const html = `<ul>${deptsLi.join('')}</ul> `;

    return this.$sce.trustAsHtml(html);
  }
}

export default {
  template,
  controller: MyFiltersDropdownController,
  bindings: {
    category: '<',
    filtersState: '<',
    model: '=',
  },
};
