import template from './click-to-send-form.component.html';
const styles = require('./click-to-send-form.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { Customer, Brand } from '@proftit/crm.api.models.entities';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  SourceDisplayTypeCode,
  VoipProviderCode,
} from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import { getMaxSmsLength } from '@proftit/validations';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import * as _ from '@proftit/lodash';
import { CommunicationProviderFormFieldCode } from '@proftit/crm.api.models.enums/src/communication-provider-form-field-code';
import {
  CustomerPhoneNumber,
  getAllFullNumbersForCustomer,
} from '~/source/common/utilities/customer-phone-number/get-all-full-numbers-for-customer';
import { FormControl } from '@proftit/ng1.reactive-forms';
import BrandsService from '~/source/management/brand/services/brands';

export class ClickToSendFormController {
  onSenderNumberConfirm;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  growlId = generateGrowlId();

  brand: Brand;
  customer: Customer;
  phone: string;
  senderNumber: string;
  message: string;
  selectedCustomerPhoneNumberFormControl: FormControl<
    { id: number; label: string } & CustomerPhoneNumber
  >;
  onChangeOfCustmerPhone: (a: { value: string }) => void;
  onChangeOfSenderNumber: (a: { value: string }) => void;
  onChangeOfMessage: (a: { value: string }) => void;
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};
  brand$ = new rx.BehaviorSubject<Brand>(null);
  phone$ = new rx.BehaviorSubject<string>('');
  countryPrefix$ = new rx.BehaviorSubject<string>('');
  isEditPhone$ = new rx.BehaviorSubject<boolean>(false);
  isEditSenderNumber$ = new rx.BehaviorSubject<boolean>(false);
  message$ = new rx.BehaviorSubject<string>('');
  senderNumber$ = new rx.BehaviorSubject<string>('');
  phoneLocal: string;
  countryPrefixLocal: string;
  clickToSendForm;
  SourceDisplayTypeCode = SourceDisplayTypeCode;
  maxSmsLength$ = new rx.BehaviorSubject<number>(null);

  customer$ = observeShareCompChange(this.lifecycles.onChanges$, 'customer');

  customerPhoneNumbers$ = this.streamCustomerPhoneNumbers();
  selectedCustomerPhoneNumber$ = this.streamSelectedCustomerPhoneNumber();
  smsNumbersDisplay$ = this.streamSmsNumbersDisplay();
  availableSmsNumbers$ = this.streamAvailableSmsNumbers();
  smsNumbersToDisplay$ = this.streamSmsNumbersToDisplay();
  messageLocal: string;
  set senderNumberLocal(val: string) {
    this.onChangeOfSenderNumber({ value: val });
  }
  get senderNumberLocal() {
    return this.senderNumber$.getValue();
  }

  /*@ngInject */
  constructor(
    readonly $validation,
    readonly brandsService: () => BrandsService,
  ) {
    useStreams(
      [
        observeCompChange(this.phone$, 'phone', this.lifecycles.onChanges$),
        observeCompChange(
          this.countryPrefix$,
          'countryPrefix',
          this.lifecycles.onChanges$,
        ),
        observeCompChange(
          this.isEditPhone$,
          'isEditPhone',
          this.lifecycles.onChanges$,
        ),
        observeCompChange(
          this.isEditSenderNumber$,
          'isEditSenderNumber',
          this.lifecycles.onChanges$,
        ),
        observeCompChange(
          this.senderNumber$,
          'senderNumber',
          this.lifecycles.onChanges$,
        ),
        observeCompChange(this.brand$, 'brand', this.lifecycles.onChanges$),
        observeCompChange(this.message$, 'message', this.lifecycles.onChanges$),
        this.customer$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamEditPhone(),
        this.streamEditCountryPrefix(),
        this.streamEditSenderNumber(),
        this.streamFormValidOut(),
        this.streamBrandChange(),
        this.customerPhoneNumbers$,
        this.selectedCustomerPhoneNumber$,
        this.selectedCustomerPhoneNumberFormControl.value$,
        this.streamPreselectFirstSmsNumberOnLoad(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  onMessageChange() {
    this.onChangeOfMessage({ value: this.messageLocal });
    this.maxSmsLength$.next(getMaxSmsLength(this.messageLocal));
  }

  streamPreselectFirstSmsNumberOnLoad() {
    return rx.pipe(
      () => this.smsNumbersToDisplay$,
      rx.filter((x) => !_.isNil(x) && x.length > 0),
      rx.tap((numbers) => {
        this.senderNumber$.next(numbers[0] as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAvailableSmsNumbers() {
    return rx.pipe(
      () => this.smsNumbersDisplay$,
      rx.filter(
        (smsSourceType) => smsSourceType === SourceDisplayTypeCode.List,
      ),
      rx.withLatestFrom(this.brand$),
      rx.map(([a, brand]) => brand),
      rx.filter((b) => !_.isNil(b)),
      rx.switchMap((brand: Brand) => {
        return rx.obs
          .from(
            this.brandsService()
              .getMappedDidsSmsNumbersResource(
                brand.id,
                brand.smsCredentials[0].smsProvider.id,
              )
              .setConfig({ growlRef: this.growlId })
              .getListWithQuery(),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSmsNumbersToDisplay() {
    return rx.pipe(
      () => this.availableSmsNumbers$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((smsNumbers) => {
        return smsNumbers.map((numberObj: any) => {
          return {
            ...numberObj,
            label: numberObj.smsNumber.number,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamBrandChange() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((brand) => !!brand),
      rx.filter(
        (brand) =>
          !_.isNil(brand.smsCredentials) && brand.smsCredentials.length > 0,
      ),
      rx.tap((brand) => {
        const { sourceDisplayType } = brand.smsCredentials[0].smsProvider;

        if (sourceDisplayType === SourceDisplayTypeCode.FreeText) {
          const senderName = this.getSmsSenderName(brand);
          if (!_.isNull(senderName)) {
            this.senderNumber$.next(senderName);
            return;
          }
          this.senderNumber$.next(brand.name);
        }
      }),
    )(null);
  }

  isVoicespin(brand: Brand) {
    return (
      brand.smsCredentials[0].smsProvider.code === VoipProviderCode.VoicespinSms
    );
  }

  isCommpeak(brand: Brand) {
    return (
      brand.smsCredentials[0].smsProvider.code === VoipProviderCode.CommpeakSms
    );
  }

  getSmsSenderName(brand: Brand) {
    if (
      !_.isNil(brand.smsCredentials) &&
      (this.isVoicespin(brand) || this.isCommpeak(brand))
    ) {
      const fieldName = this.isVoicespin(brand)
        ? CommunicationProviderFormFieldCode.SmsSender
        : CommunicationProviderFormFieldCode.CommpeakSmsSenderCode;

      const smsSenderObject = brand.smsCredentials[0].credentials.find(
        (obj) => obj.key === fieldName,
      );
      if (!_.isNil(smsSenderObject)) {
        const senderNumber = smsSenderObject.value;
        return senderNumber;
      }
      return null;
    }
    return null;
  }

  streamSmsNumbersDisplay() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((brand) => !!brand),
      rx.filter(
        (brand) =>
          !_.isNil(brand.smsCredentials) && brand.smsCredentials.length > 0,
      ),
      rx.map((brand) => brand.smsCredentials[0].smsProvider.sourceDisplayType),
      shareReplayRefOne(),
    )(null);
  }

  streamEditPhone() {
    return rx.pipe(
      () => this.isEditPhone$,
      rx.withLatestFrom(this.phone$),
      rx.tap(([isEdit, phone]) => {
        const maskCharacterRegex = new RegExp('(x|X)');
        if (isEdit && !maskCharacterRegex.test(phone)) {
          this.phoneLocal = phone;
        } else {
          this.phoneLocal = '';
        }
      }),
    )(null);
  }

  streamEditCountryPrefix() {
    return rx.pipe(
      () => this.isEditPhone$,
      rx.withLatestFrom(this.countryPrefix$),
      rx.tap(([isEdit, countryPrefix]) => {
        if (isEdit) {
          this.countryPrefixLocal = countryPrefix;
        } else {
          this.countryPrefixLocal = '';
        }
      }),
    )(null);
  }

  streamEditSenderNumber() {
    return rx.pipe(
      () => this.senderNumber$,
      rx.tap((senderNumber) => (this.senderNumberLocal = senderNumber)),
    )(null);
  }

  streamCustomerPhoneNumbers() {
    return rx.pipe(
      () => this.customer$,
      rx.map((customer: Customer) => getAllFullNumbersForCustomer(customer)),
      rx.map((numbers: CustomerPhoneNumber[]) => {
        return numbers.map((numObj, i) => {
          return {
            ...numObj,
            id: i,
            label: numObj.phoneNumber,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedCustomerPhoneNumber() {
    return rx.pipe(
      () => this.streamSelectedCustomerPhoneNumberFromInit(),
      rx.tap((value: any) => {
        this.selectedCustomerPhoneNumberFormControl.setValue(value);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedCustomerPhoneNumberFromInit() {
    return rx.pipe(
      () => this.customerPhoneNumbers$,
      rx.filter((x) => !_.isNil(x) && x.length > 0),
      rx.map((numbers) => numbers[0]),
      shareReplayRefOne(),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.message$,
          this.phone$,
          this.countryPrefix$,
          this.senderNumber$,
        ),
      rx.debounceTime(150),
      rx.map(() => this.$validation.checkValid(this.clickToSendForm)),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }
}

export const ClickToSendFormComponent = {
  template,
  controller: ClickToSendFormController,
  bindings: {
    brand: '<',
    customer: '<',
    phone: '<',
    countryPrefix: '<',
    senderNumber: '<',
    message: '<',
    isEditPhone: '<',
    selectedCustomerPhoneNumberFormControl: '<',
    onPhoneEditRequest: '&',
    onPhoneEditCancel: '&',
    onPhoneEditConfirm: '&',
    onChangeOfMessage: '&',
    onChangeOfSenderNumber: '&',
    onChangeOfFormValidity: '&',
    isEditSenderNumber: '<',
    onSenderNumberRequest: '&',
    onSenderNumberCancel: '&',
    onSenderNumberConfirm: '&',
  },
};
