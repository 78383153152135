export const CUSTOM_EMAIL_TEMPLATE_DELETED = 'CUSTOM_EMAIL_TEMPLATE_DELETED';
export const CUSTOM_EMAIL_TEMPLATE_UPDATED = 'CUSTOM_EMAIL_TEMPLATE_UPDATED';
export const PLATFORM_ASSET_UPDATED = 'PLATFORM_ASSET_UPDATED';
export const PLATFORM_BRAND_CONFIG_UPDATED = 'PLATFORM_BRAND_CONFIG_UPDATED';
export const INTERNAL_TRANSFER_ADDED = 'INTERNAL_TRANSFER_ADDED';
export const TABLE_FILTER_UPDATE_DROPDOWN_MODEL =
  'TABLE_FILTER_UPDATE_DROPDOWN_MODEL';
export const WITHDRAWAL_STATUS_UPDATE = 'WITHDRAWAL_STATUS_UPDATE';
export const BONUS_STATUS_UPDATE = 'BONUS_STATUS_UPDATE';
export const INTERNAL_TRANSFER_UPDATED = 'INTERNAL_TRANSFER_UPDATED';
export const DEPOSIT_STATUS_UPDATE = 'DEPOSIT_STATUS_UPDATE';
export const DEPOSIT_TABLE_RELOAD = 'DEPOSIT_TABLE_RELOAD';
export const FEE_STATUS_UPDATE = 'FEE_STATUS_UPDATE';
export const ADD_FEE = 'ADD_FEE';
export const COMMUNICATION_ADDED = 'COMMUNICATION_ADDED';
export const COMMUNICATION_UPDATED = 'COMMUNICATION_UPDATED';
export const INACTIVITY_FEE_ADDED = 'INACTIVITY_FEE_ADDED';
export const PROFILE_PICTURE_UPLOADED = 'PROFILE_PICTURE_UPLOADED';
export const CONTACT_ADDED = 'CONTACT_ADDED';
export const ACCOUNT_ADDED = 'ACCOUNT_ADDED';
export const DESIGN_TEMPLATE_DELETED = 'DESIGN_TEMPLATE_DELETED';
export const DESIGN_TEMPLATE_UPDATED = 'DESIGN_TEMPLATE_UPDATED';
export const DESIGN_TEMPLATE_CREATED = 'DESIGN_TEMPLATE_CREATED';
export const CONTENT_TEMPLATE_DELETED = 'CONTENT_TEMPLATE_DELETED';
export const CONTENT_TEMPLATE_UPDATED = 'CONTENT_TEMPLATE_UPDATED';
export const CONTENT_TEMPLATE_CREATED = 'CONTENT_TEMPLATE_CREATED';
export const CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED =
  'CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED';
export const CUSTOMER_COMPLIANCE_DOC_TYPE_CREATED =
  'CUSTOMER_COMPLIANCE_DOC_TYPE_CREATED';
export const CUSTOMER_LINK_CHANGED = 'CUSTOMER_LINK_CHANGED';
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
export const QUESTIONNAIRE_SCORE_UPDATED = 'QUESTIONNAIRE_SCORE_UPDATED';
export const CUSTOMER_REGULATION_FILE_UPDATED =
  'CUSTOMER_REGULATION_FILE_UPDATED';
export const ATTACHMENT_ADDED_TO_DEPOSIT = 'ATTACHMENT_ADDED_TO_DEPOSIT';
export const ATTACHMENT_REMOVED_FROM_DEPOSIT =
  'ATTACHMENT_REMOVED_FROM_DEPOSIT';
export const TASK_UPDATED = 'TASK_UPDATED';
export const TASK_CREATED = 'TASK_CREATED';
export const CALL_ONGOING = 'CALL_ONGOING';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SETTINGS_UPDATED = 'USER_SETTINGS_UPDATED';
export const PROMO_CODE_ADDED = 'PROMO_CODE_ADDED';
export const PROMO_CODE_UPDATED = 'PROMO_CODE_UPDATED';
export const TRADING_ACCOUNT_STREAMER_UPDATE =
  'TRADING_ACCOUNT_STREAMER_UPDATE';
export const PRIVATE_EWALLET_UPDATED = 'PRIVATE_EWALLET_UPDATED';
