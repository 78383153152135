import Promise from 'bluebird';
import Component from '~/source/common/components/dropdowns/base/component';

class PositionsTradeDirectionController extends Component.controller {
  query() {
    const positionTradeDirections = [
      { id: 1, name: 'CALL' },
      { id: 2, name: 'PUT' },
    ];

    return Promise.resolve(positionTradeDirections);
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'name';
  }

  /**
   * Path to translations on lang.json file
   * Must be overridden
   *
   * @returns {String}
   */
  get translationPath() {
    return 'contact.tradeDirections';
  }
}

export default Component.config({
  controller: PositionsTradeDirectionController,
});
