export const tableSettings = {
  parameters: {
    count: 4,
    page: 1,
    sorting: {
      createdAt: 'desc',
    },
  },
  settings: {
    counts: [],
  },
};
