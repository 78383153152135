import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { IAttributes, IParseService, IScope } from 'angular';

export class WithdrawalDepositLinkServiceDirective {
  lifecycles = observeComponentLifecycles(this);
  calculators = [];

  /* @ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $parse: IParseService,
    readonly $attrs: IAttributes,
  ) {}

  $onInit() {
    const mainExpFn = this.$parse(this.$attrs.prfWithdrawalDepositLink);

    const newScope: any = this.$scope.$new(false, this.$scope);
    newScope.service = this;
    mainExpFn(newScope);
    newScope.$destroy();
  }

  $onChanges() {}

  $onDestroy() {}

  addCalculator(calculator) {
    this.calculators.push(calculator);
  }

  removeCalculator(calculator) {
    this.calculators = this.calculators.filter((c) => c !== calculator);
  }

  requestCalc() {
    // we are assuming only one calculator will be loaded each time, so we just use the first and only one.
    return this.calculators[0].onCalcRequest();
  }
}

export const prfWithdrawalDepositLinkServiceDirective = () => {
  return {
    restrict: 'A',
    require: {},
    bindToController: true,
    controller: WithdrawalDepositLinkServiceDirective,
  };
};

prfWithdrawalDepositLinkServiceDirective.$inject = [] as string[];
