import template from './inactivity-fee-setting-logs-popup-table.component.html';
const styles = require('./inactivity-fee-setting-logs-popup-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import { BrandsService } from '~/source/management/brand/services/brands';
import { useStreams } from '@proftit/rxjs.adjunct';
import { inactivityFeeActivityLogTablePopupCols } from './inactivity-fee-activity-log-table-popup.cols';
import { inactivityFeeSettingLogsPopupTableSettings } from './inactivity-fee-setting-logs-popup-table.settings';
import { ICollectionRestNg } from '~/source/common/models/icollection-rest-ng';
import {
  Brand,
  InactivityFeeSetting,
  InactivityFeeSettingLog,
} from '@proftit/crm.api.models.entities';
import { filterCompChange } from '~/source/common/utilities/rxjs/operators/filter-comp-change';
import * as _ from '@proftit/lodash';
import { calcInfoForLogRecord } from '../../shared/calc-info-for-log-record';
import * as rx from '@proftit/rxjs';

export class InactivityFeeSettingLogsPopupTableController extends TablePopupController {
  static $inject = [
    'brandsService',
    '$translate',
    ...TablePopupController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  dataServiceInstance: BrandsService;
  $translate: angular.translate.ITranslateService;
  brand$ = new rx.BehaviorSubject<Brand>(null);
  fee$ = new rx.BehaviorSubject<InactivityFeeSetting>(null);
  incomingRows$ = new rx.Subject<InactivityFeeSetting[]>();

  constructor(a, ...args) {
    super(a, ...args);

    useStreams([this.streamInputResolve()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  get cols() {
    return [...inactivityFeeActivityLogTablePopupCols];
  }

  get ngTableSettings() {
    return { ...inactivityFeeSettingLogsPopupTableSettings };
  }

  get title() {
    return 'inactivityFee.INACTIVITY_FEE_ACTIVITY_LOG_POPUP_TABLE_HEADER';
  }

  get tableKey() {
    return 'inactivityFeeLogs';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getInactivityFeeSettingLogsResource(
        this.brand$.getValue().id,
        this.fee$.getValue().id,
      )
      .expand(['user'])
      .sort({ createdAt: 'desc' });
  }

  parseLoadedData(data: ICollectionRestNg<InactivityFeeSettingLog>) {
    data.forEach(
      (rec) =>
        ((rec as any)._info = calcInfoForLogRecord(rec, this.$translate)),
    );

    this.incomingRows$.next(data);

    return data;
  }

  streamInputResolve() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      filterCompChange<any>('resolve'),
      rx.map(({ currentValue }) => currentValue),
      rx.tap((resolve) => {
        this.brand$.next(_.get('brand', resolve));
        this.fee$.next(_.get('fee', resolve));
      }),
    )(null);
  }
}

export const InactivityFeeSettingLogsPopupTableComponent = {
  template,
  controller: InactivityFeeSettingLogsPopupTableController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
