import template from './contacts-import-table.component.html';

const styles = require('./contacts-import-table.component.scss');

import ng, { IWindowService } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { ContactsImportsService } from '~/source/common/api-crm-server/services/contacts-imports.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import type { StateService } from '@uirouter/angularjs';
import { contactsImportTableSettings } from '~/source/management/contacts-import/components/contacts-import-table/contacts-import-table.settings';
import { contactsImportTableCols } from '~/source/management/contacts-import/components/contacts-import-table/contacts-import-table.cols';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { calcMenuItem } from './calc-menu-item';
import { switchOn } from '@proftit/general-utilities';
import log from 'loglevel';
import { ContactsImportTableAction } from './contacts-import-table.actions';
import { ContactsImport } from '@proftit/crm.api.models.entities';
import { PrivateGoogleStorageFileService } from '~/source/common/services/private-google-storage-file.service';
import { downloadLocalFileUrl } from '@proftit/dom-utilities';

export class ContactsImportTableController extends TableController {
  static $inject = [
    'prfContactsImportsService',
    'prfClientGeneralPubsub',
    'PermPermissionStore',
    '$state',
    '$window',
    'privateGoogleStorageFileService',
    ...TableController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = generateBlockuiId();

  isTableInitialized$ = new rx.BehaviorSubject<boolean>(false);
  prfClientGeneralPubsub: ClientGeneralPubsub;
  privateGoogleStorageFileService: PrivateGoogleStorageFileService;
  PermPermissionStore: ng.permission.PermissionStore;
  $state: StateService;
  $window: IWindowService;
  prfContactsImportsService: () => ContactsImportsService;
  dataServiceInstance: ContactsImportsService;
  settings = { ...contactsImportTableSettings };
  cols = [...contactsImportTableCols];

  opMenuAction$ = new rx.Subject<{
    actionCode: ContactsImportTableAction;
    item: ContactsImport;
  }>();

  collectionMenuItems$ = this.streamCollectionMenuItems();

  constructor(...args) {
    super(...args);

    this.dataServiceInstance = this.prfContactsImportsService();

    useStreams(
      [this.streamInitTable(), this.streamMenuActionPerform()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  fetchFn() {
    // todoOld: brand filter
    const sort = { createdAt: 'desc' };
    const expand = ['resource', 'brand', 'creator'];
    const embed = [];
    const config = { blockUiRef: this.blockUiRef };

    return this.dataServiceInstance.resourceFullList({
      config,
      expand,
      embed,
    });
  }

  streamInitTable() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.tap(() => this.initTable()),
      rx.tap(() => this.isTableInitialized$.next(true)),
    )(null);
  }

  streamCollectionMenuItems() {
    return rx.pipe(
      () => this.collection$,
      rx.map((items) =>
        items.map((item) => ({
          id: item.id,
          menu: calcMenuItem(item as ContactsImport),
        })),
      ),
      rx.map((itemsMenus) =>
        itemsMenus.reduce((acc, itemMenu) => {
          acc[itemMenu.id] = itemMenu.menu;
          return acc;
        }, {}),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamMenuActionPerform() {
    return rx.pipe(
      () => this.opMenuAction$,
      rx.tap((action) => {
        const actions = {
          [ContactsImportTableAction.DownloadErrors]: () => {
            this.downloadErrorsFile(action.item.id, action.item.brand.id);
          },
          [ContactsImportTableAction.ViewContacts]: () =>
            this.$state.go('crm.contacts.list', {
              contactsImportId: action.item.id,
            }),
        };
        switchOn(actions, action.actionCode, () => {
          log.error('unhandled action', action);
          throw new Error('unhandled action');
        });
      }),
      rx.catchError((err, obs) => {
        log.error('error on action', err);
        return obs;
      }),
    )(null);
  }

  downloadErrorsFile(fileId: number, brandId: number) {
    this.privateGoogleStorageFileService
      .getContactsImportErrorFileUrl(brandId, fileId, this.blockUiRef)
      .then(({ localFileUrl, fileName }) => {
        downloadLocalFileUrl(localFileUrl, fileName);
        URL.revokeObjectURL(localFileUrl);
      })
      .catch((e) => {});
  }
}

export const ContactsImportTableComponent = {
  template,
  controllerAs: 'vm',
  controller: ContactsImportTableController,
};
