import template from './trading-account-binary.html';
import { binaryAccountTotalsConfig } from '../account-totals-config';

class TradingAccountBinaryController {
  totalsTilesConfig = binaryAccountTotalsConfig;
}

export default {
  template,
  controller: TradingAccountBinaryController,
  bindings: {
    customer: '<',
    account: '<',
  },
};
