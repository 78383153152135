import Component from './base/component';
import BrandsService from '~/source/management/brand/services/brands';

class BrandSelectController extends Component.controller {
  static $inject = ['brandsService', ...Component.controller.$inject];

  dataServiceInst: BrandsService;

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return this.dataServiceInst
      .expand(['platformType', 'platformConnections.platform', 'file:logo'])
      .embed(['platformConnections'])
      .getListWithQuery();
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Brands',
    };
  }

  get defaultPlaceholder() {
    return 'SELECT_BRAND';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({ controller: BrandSelectController });
