import RestService from '~/source/common/services/rest';

export class TradingAccountStatuses extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'tradingAccountStatuses';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }
  /**
   * This resource is public
   *
   * @returns {string}
   */
  get accessType() {
    return 'user';
  }
}
