const styles = require('./chargeback.scss');

import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import template from './chargeback.html';
import { IScope } from 'angular';
import WithdrawalValidationService from '../common/validation.service';
import CustomersService from '~/source/contact/common/services/customers';
import {
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import { CreditCard } from '~/source/common/models/credit-card';
import { CreditCardWithdrawalController } from '../credit-card.component';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { WithdrawalDepositLinkServiceDirective } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-deposit-link-service-directive';
import { IWithdrawalDepositLinker } from '~/source/contact/contact-page/trading-account/common/withdrawal/i-withdrawal-deposit-linker';

interface UiDeposit extends TradingAccountDeposit {
  selected: boolean;
}

class WithdrawalChargebackController extends BaseController
  implements IWithdrawalDepositLinker {
  lifecycles = observeComponentLifecycles(this);
  styles = styles;
  selectedCardTotals;
  transferMethodTypes;
  totalWithdrawal: number;
  totalDeposits: number;
  amountLeft: number;
  selectOnlyOne: boolean;
  alwaysShowExtraInfo: boolean;
  prfWithdrawalDepositLink: WithdrawalDepositLinkServiceDirective;
  onDepositAmountChange: ({ newAmount: number }) => void;

  creditcardComponent: CreditCardWithdrawalController;
  submitParent: Function;
  account: TradingAccount;
  deposits: ICollectionRestNg<UiDeposit>;
  selectedCard: CreditCard;
  withdrawalRequest: WithdrawalRequest;
  amountOfSelectedDeposits: number;

  withdrawalRequest$ = observeShareCompChange<WithdrawalRequest>(
    this.lifecycles.onChanges$,
    'withdrawalRequest',
  );

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly withdrawalValidationService: WithdrawalValidationService,
    readonly customersService: CustomersService,
  ) {
    super();

    useStreams([this.withdrawalRequest$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [this.streamSelectPreselectedDeposit()],
      this.lifecycles.onDestroy$,
    );

    this.selectedCardTotals = {};

    this.onWithdrawalChange();

    this.prfWithdrawalDepositLink.addCalculator(this);
  }

  $onChanges() {}

  $onDestroy() {
    this.prfWithdrawalDepositLink.removeCalculator(this);
  }

  streamSelectPreselectedDeposit() {
    return rx.pipe(
      () => this.withdrawalRequest$,
      rx.filter(
        (withdrawalRequest) =>
          !_.isNil(withdrawalRequest) && !_.isNil(withdrawalRequest.deposit),
      ),
      rx.tap((withdrawalRequest) => {
        this.deposits.forEach((d) => {
          if (d.id === withdrawalRequest.deposit.id) {
            d.selected = true;
          }
        });
        this.onWithdrawalChange();
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * called on change of withdrawal amount, update all total numbers needed by the ui
   */
  onWithdrawalChange() {
    this.totalWithdrawal = this.calculateTotalWithdrawal();
    this.totalDeposits = this.calculateCreditCardTotalDeposits();
    this.amountLeft =
      this.calculateCreditCardTotalWithdrawable() -
      this.calculateCreditCardTotalAmount();

    this.amountOfSelectedDeposits = this.getAmountOfSelectedDeposits();
    this.onDepositLinkChange();
  }

  getAmountOfSelectedDeposits() {
    return this.deposits.filter((d) => d.selected).length;
  }

  /**
   * called when selectedCard (binding) value change
   */
  onSelectedCardChange() {
    this.onWithdrawalChange();
  }

  /**
   * sum of all the selected amounts for withdrawal action
   * @returns {Number}
   */
  calculateTotalWithdrawal() {
    return this.deposits
      .filter((deposit) => deposit.selected)
      .map(({ amountApproved }) => amountApproved)
      .reduce(_.addEs, 0);
  }

  /**
   * sum of all deposit (amountApproved) belongs to the selected credit card
   * @returns {Number}
   */
  calculateCreditCardTotalDeposits() {
    return (
      this.deposits
        // filter transactionTransferCreditCard = null  (remove wire transfer)
        .filter((deposit) => deposit.transactionTransferCreditCard)
        .filter(
          (deposit) =>
            this.selectedCard ===
            deposit.transactionTransferCreditCard.cardNumber,
        )
        .map(({ amountApproved }) => amountApproved)
        .reduce(_.addEs, 0)
    );
  }

  /**
   * sum of the deposit withdrawable amount belongs to the selected credit card
   * @returns {Number}
   */
  calculateCreditCardTotalWithdrawable() {
    return (
      this.deposits
        // filter transactionTransferCreditCard = null  (remove wire transfer)
        .filter((deposit) => deposit.transactionTransferCreditCard)
        .filter(
          (deposit) =>
            this.selectedCard ===
            deposit.transactionTransferCreditCard.cardNumber,
        )
        .map(({ amountWithdrawable }) => amountWithdrawable)
        .reduce(_.addEs, 0)
    );
  }

  /**
   * sum of all the selected amounts belongs to the selected credit card
   * @returns {Number}
   */
  calculateCreditCardTotalAmount() {
    return this.deposits
      .filter(
        (deposit) =>
          deposit.selected &&
          this.selectedCard ===
            deposit.transactionTransferCreditCard.cardNumber,
      )
      .map(({ amountApproved }) => amountApproved)
      .reduce(_.addEs, 0);
  }

  /**
   * submit withdrawal request to server if form is valid
   * called when submit button is click in parent scope
   *
   * @returns {*}
   */
  onCalcRequest() {
    const normalized = this.normalize();
    return normalized;
  }

  /**
   * normalize withdrawals for save
   * @returns {Array}
   */
  normalize() {
    const approvedMethodType = this.transferMethodTypes.CARD_WITHDRAWAL;
    return this.deposits
      .filter((deposit) => deposit.selected)
      .map((deposit) => ({
        methodTypeId: approvedMethodType.id,
        depositId: deposit.id,
        withdrawalTypeId: this.withdrawalRequest.withdrawalType.id,
      }));
  }

  onDepositLinkChange() {
    const amountWishedToBeDeposited = this.deposits.reduce((acc, deposit) => {
      if (deposit.selected) {
        const depositAmount = !_.isNil(deposit.amountApproved)
          ? deposit.amountApproved
          : 0;
        return acc + depositAmount;
      }
      return acc;
    }, 0);
    this.onDepositAmountChange({ newAmount: amountWishedToBeDeposited });
  }
}

export default {
  template,
  controller: WithdrawalChargebackController,
  controllerAs: 'vm',
  require: {
    prfWithdrawalDepositLink: '^',
  },
  bindings: {
    account: '<',
    deposits: '<',
    selectedCard: '<',
    withdrawalRequest: '<',
    transferMethodTypes: '<',
    selectOnlyOne: '<',
    alwaysShowExtraInfo: '<',
    submitParent: '&',
    onDepositAmountChange: '&',
  },
};
