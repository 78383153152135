import template from './manager.component.html';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { Howl } from 'howler';
import { PlayerState } from '../player-state';
import { VolumeActions } from '../volume-actions';
import { switchOn } from '@proftit/general-utilities';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ITimeoutService } from 'angular';

const styles = require('./manager.component.scss');

const MAX_VOLUME = 100;
const INITIAL_VOLUME = MAX_VOLUME / 2;
const VOLUME_SCALES_AMOUNT = 4;
const PLAYER_SAMPLING_INTERVAL = 500;

function convertIntToVolume(volume, maxVolume) {
  return volume / maxVolume;
}

function convertVolumeToInt(volume, maxVolume) {
  return volume * maxVolume;
}

function getSoundId(mediaPlayer): number {
  return (mediaPlayer as any)._sounds[0]._id;
}

function createEventTarget(mediaPlayer) {
  return {
    on(eventName, callback) {
      return mediaPlayer.on(eventName, callback, getSoundId(mediaPlayer));
    },
    off(eventName, callback) {
      return mediaPlayer.off(eventName, callback, getSoundId(mediaPlayer));
    },
  };
}

export class ManagerController {
  fileFormats: string[];

  MAX_VOLUME = MAX_VOLUME;
  VOLUME_SCALES_AMOUNT = VOLUME_SCALES_AMOUNT;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  opReload$ = new rx.Subject<void>();
  opPlay$ = new rx.Subject<void>();
  opPause$ = new rx.Subject<void>();
  opStop$ = new rx.Subject<void>();
  opSetTimeline$ = new rx.Subject<number>();
  opSetVolume$ = new rx.Subject<number>();
  opIncreaseVolume$ = new rx.Subject<void>();
  opDecreaseVolume$ = new rx.Subject<void>();
  desiredVolumeSubject = new rx.BehaviorSubject(INITIAL_VOLUME);
  audioSourceUrl$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'audioSourceUrl',
  );
  showComponent$ = this.streamShowComponent();
  mediaPlayer$ = this.streamMediaPlayer();
  playerLoaded$ = this.streamPlayerLoaded();
  mediaDuration$ = this.streamMediaDuration();
  playStatus$ = this.streamPlayStatus();
  currentTimeline$ = this.streamCurrentTimeline();
  currentVolume$ = this.streamCurrentVolume();

  // Bindings
  audioSourceUrl: string;

  /*@ngInject */
  constructor(readonly $timeout: ITimeoutService) {
    useStreams(
      [
        this.streamPlayingActions(),
        this.streamVolumeChangingActions(),
        this.streamTimelineChangingActions(),
        this.streamSetMediaPlayerVolume(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamShowComponent() {
    return rx.pipe(
      () => rx.obs.from([true]),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamMediaPlayer(): rx.Observable<Howl> {
    return rx.pipe(
      () => rx.obs.merge(this.audioSourceUrl$),
      rx.switchMap(() => this.opPlay$.pipe(rx.take(1))),
      rx.withLatestFrom(this.audioSourceUrl$, this.desiredVolumeSubject),
      rx.switchMap(([a, url]) => {
        return new rx.Observable<Howl>((subscriber) => {
          // Howler.unload();

          const options = _.flow([
            (opts) => ({
              ...opts,
              src: [url],
              volume: convertIntToVolume(
                this.desiredVolumeSubject.getValue(),
                MAX_VOLUME,
              ),
            }),
            (opts) => {
              if (_.isArray(this.fileFormats) && this.fileFormats.length > 0) {
                return {
                  ...opts,
                  format: this.fileFormats,
                };
              }

              return {
                ...opts,
              };
            },
          ])({});

          let mediaPlayer = new Howl(options);

          subscriber.next(mediaPlayer);

          return () => {
            mediaPlayer.unload();
            mediaPlayer = null;
          };
        });
      }),
      // tslint:disable-next-line:deprecation
      rx.startWith(null),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamPlayerLoaded(): rx.Observable<boolean> {
    return rx.pipe(
      () => this.mediaPlayer$,
      rx.switchMap((mediaPlayer) => {
        if (!mediaPlayer) {
          return rx.obs.from([false]);
        }
        if (mediaPlayer.state() === 'loaded') {
          return rx.obs.from([true]);
        }
        return rx.obs
          .fromEvent(createEventTarget(mediaPlayer), 'load')
          .pipe(rx.map(() => true));
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamMediaDuration() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.playerLoaded$, this.mediaPlayer$),
      rx.map(([playerLoaded, mediaPlayer]) => {
        if (!playerLoaded || _.isNil(mediaPlayer)) {
          return 0;
        }
        return mediaPlayer.duration(getSoundId(mediaPlayer));
      }),
      rx.startWith(0),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamPlayStatus() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.playerLoaded$, this.mediaPlayer$),
      rx.switchMap(([playerLoaded, mediaPlayer]) => {
        if (!playerLoaded || _.isNil(mediaPlayer)) {
          return rx.obs.from([PlayerState.Unloaded]);
        }
        return rx.obs.merge(
          rx.obs
            .fromEvent(createEventTarget(mediaPlayer), 'play')
            .pipe(rx.map(() => PlayerState.Playing)),
          rx.obs
            .fromEvent(createEventTarget(mediaPlayer), 'pause')
            .pipe(rx.map(() => PlayerState.Pause)),
          rx.obs
            .fromEvent(createEventTarget(mediaPlayer), 'stop')
            .pipe(rx.map(() => PlayerState.Done)),
          rx.obs
            .fromEvent(createEventTarget(mediaPlayer), 'end')
            .pipe(rx.map(() => PlayerState.Done)),
        );
      }),
      rx.startWith(PlayerState.Unloaded),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrentTimeline() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamCurrentTimelineFromPlaying(),
          this.streamCurrentTimelineFromUnloaded(),
          this.streamCurrentTimelineFromPausedStopped(),
        ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrentTimelineFromUnloaded() {
    return rx.pipe(
      () => this.playStatus$,
      rx.filter((playStatus) => playStatus === PlayerState.Unloaded),
      rx.withLatestFrom(this.mediaPlayer$),
      rx.map(([iteration, mediaPlayer]) => 0),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrentTimelineFromPlaying() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.playerLoaded$,
          this.mediaPlayer$,
          this.playStatus$,
        ),
      rx.switchMap(([playerLoaded, mediaPlayer, playStatus]) => {
        if (
          !playerLoaded ||
          _.isNil(mediaPlayer) ||
          playStatus !== PlayerState.Playing
        ) {
          return rx.obs.from([0]);
        }
        return rx.obs
          .interval(PLAYER_SAMPLING_INTERVAL)
          .pipe(
            rx.takeUntil(
              this.playStatus$.pipe(
                rx.filter((playStatus) => playStatus !== PlayerState.Playing),
              ),
            ),
          );
      }),
      rx.withLatestFrom(this.mediaPlayer$),
      rx.map(([iteration, mediaPlayer]) => {
        if (_.isNil(mediaPlayer)) {
          return 0;
        }
        return mediaPlayer.seek(getSoundId(mediaPlayer));
      }),
      rx.startWith(0),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrentTimelineFromPausedStopped() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.playerLoaded$,
          this.mediaPlayer$,
          this.playStatus$,
        ),
      rx.switchMap(([playerLoaded, mediaPlayer, playStatus]) => {
        if (
          !playerLoaded ||
          _.isNil(mediaPlayer) ||
          ![PlayerState.Done, PlayerState.Pause].includes(playStatus)
        ) {
          return rx.obs.EMPTY;
        }

        return rx.obs.fromEvent(createEventTarget(mediaPlayer), 'seek');
      }),
      rx.withLatestFrom(this.mediaPlayer$),
      rx.map(([a, mediaPlayer]) => mediaPlayer.seek(getSoundId(mediaPlayer))),
    )(null);
  }

  streamCurrentVolume() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          rx.obs.combineLatest(this.playerLoaded$, this.mediaPlayer$).pipe(
            rx.filter(
              ([playerLoaded, mediaPlayer]) =>
                playerLoaded && !_.isNil(mediaPlayer),
            ),
            rx.switchMap(([playerLoaded, mediaPlayer]) => {
              return rx.obs
                .fromEvent(createEventTarget(mediaPlayer), 'volume')
                .pipe(
                  rx.map(() =>
                    convertVolumeToInt(
                      mediaPlayer.volume(getSoundId(mediaPlayer)),
                      MAX_VOLUME,
                    ),
                  ),
                );
            }),
          ),

          this.desiredVolumeSubject.pipe(
            rx.withLatestFrom(this.mediaPlayer$.pipe(rx.startWith(null))),
            rx.filter(([desiredVolumeSubject, mediaPlayer]) =>
              _.isNil(mediaPlayer),
            ),
            rx.map(
              ([desiredVolumeSubject, mediaPlayer]) => desiredVolumeSubject,
            ),
            rx.startWith(INITIAL_VOLUME),
          ),
        ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /* side effects streams*/
  streamPlayingActions() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.opPlay$.pipe(rx.map(() => PlayerState.Playing)),
          this.opPause$.pipe(rx.map(() => PlayerState.Pause)),
          this.opStop$.pipe(rx.map(() => PlayerState.Done)),
          this.playerLoaded$.pipe(
            rx.filter((playerLoaded) => playerLoaded),
            rx.take(1),
            rx.map(() => PlayerState.Playing),
          ),
        ),
      rx.withLatestFrom(this.mediaPlayer$, this.audioSourceUrl$),
      rx.tap(([desiredStatus, mediaPlayer, audioUrl]) => {
        if (_.isNil(mediaPlayer)) {
          return;
        }

        if (mediaPlayer.state() === 'unloaded') {
        }

        if (mediaPlayer.state() === 'loading') {
        }
        const isPlaying =
          mediaPlayer.state() === 'loaded' &&
          mediaPlayer.playing(getSoundId(mediaPlayer));
        switchOn(
          {
            [PlayerState.Playing]: () => {
              if (isPlaying) {
                return;
              }
              mediaPlayer.play(getSoundId(mediaPlayer));
            },
            [PlayerState.Pause]: () => {
              if (!isPlaying) {
                return;
              }
              mediaPlayer.pause(getSoundId(mediaPlayer));
            },
            [PlayerState.Done]: () => {
              mediaPlayer.stop(getSoundId(mediaPlayer));
            },
          },
          desiredStatus,
          () => null,
        );
      }),
    )(null);
  }

  streamVolumeChangingActions() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.opSetVolume$.pipe(
            rx.map((scalaIndex) => ({
              scalaIndex,
              action: VolumeActions.ScalaIndicator,
            })),
          ),
          this.opIncreaseVolume$.pipe(
            rx.map(() => ({
              action: VolumeActions.Plus,
              scalaIndex: null,
            })),
          ),
          this.opDecreaseVolume$.pipe(
            rx.map(() => ({
              action: VolumeActions.Minus,
              scalaIndex: null,
            })),
          ),
        ),
      rx.withLatestFrom(this.currentVolume$),
      rx.tap(([{ action, scalaIndex }, currentVolume]) => {
        let volume = currentVolume;
        switchOn(
          {
            [VolumeActions.ScalaIndicator]: () => {
              volume = (MAX_VOLUME / VOLUME_SCALES_AMOUNT) * scalaIndex;
            },

            [VolumeActions.Plus]: () => {
              if (currentVolume >= MAX_VOLUME) {
                return;
              }
              volume = currentVolume + 10;
            },
            [VolumeActions.Minus]: () => {
              if (currentVolume <= 0) {
                return;
              }
              volume = currentVolume - 10;
            },
          },
          action,
          () => null,
        );
        if (this.desiredVolumeSubject.getValue() === volume) {
          return;
        }
        this.desiredVolumeSubject.next(volume);
      }),
    )(null);
  }

  /**
   * set volume using player function
   *
   * if desiredVolumeSubject change
   * if mediaPlayer state unload return
   * if getVolume from mediaPlayer equal to desiredVolumeSubject.getValue() return
   * else update mediaPlayer volume using set function
   *
   * @return sideEffects stream
   * */

  streamSetMediaPlayerVolume() {
    return rx.pipe(
      () => this.desiredVolumeSubject,
      rx.withLatestFrom(this.mediaPlayer$),
      rx.tap(([desiredVolumeSubject, mediaPlayer]) => {
        if (_.isNil(mediaPlayer) || mediaPlayer.state() === 'unloaded') {
          return;
        }
        if (
          desiredVolumeSubject === mediaPlayer.volume(getSoundId(mediaPlayer))
        ) {
          return;
        }
        mediaPlayer.volume(
          convertIntToVolume(desiredVolumeSubject, MAX_VOLUME),
          getSoundId(mediaPlayer),
        );
      }),
    )(null);
  }

  streamTimelineChangingActions() {
    return rx.pipe(
      () => this.opSetTimeline$,
      rx.withLatestFrom(this.mediaPlayer$),
      rx.tap(([desiredTimeline, mediaPlayer]) => {
        if (_.isNil(mediaPlayer)) {
          return;
        }
        mediaPlayer.seek(desiredTimeline, getSoundId(mediaPlayer));
      }),
    )(null);
  }
}

export const ManagerComponent = {
  template,
  bindings: {
    audioSourceUrl: '<',
    fileFormats: '<',
  },
  controller: ManagerController,
};
