import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { ComplianceDockTypesManagementService } from '../api-crm-server/services/compliance-dock-types-management.service';
import { CustomerComplianceFileTypeCode } from '@proftit/crm.api.models.enums';
import log from 'loglevel';

export class CustomerComplianceFileTypesStoreService {
  customerComplianceFileTypes$ = this.streamCustomerComplianceFileTypes();
  customerComplianceDepositFileType$ = this.streamCustomerComplianceDepositFileType();
  customerComplianceDepositFileTypeId$ = this.streamCustomerComplianceDepositFileTypeId();

  /* @ngInject */
  constructor(
    readonly prfComplianceDockTypesManagementService: () => ComplianceDockTypesManagementService,
  ) {}

  streamCustomerComplianceFileTypes() {
    return rx.pipe(
      () =>
        rx.obs
          .from(this.prfComplianceDockTypesManagementService().getAll())
          .pipe(
            rx.catchError((err, caught) => {
              log.error('error fetching compliance file types:', err);

              return rx.obs.from([]);
            }),
          ),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomerComplianceDepositFileType() {
    return rx.pipe(
      () => this.customerComplianceFileTypes$,
      rx.map((types) =>
        types.find(
          (type) =>
            type.code === CustomerComplianceFileTypeCode.DepositDocument,
        ),
      ),
      rx.filter((type) => !_.isNil(type)),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomerComplianceDepositFileTypeId() {
    return rx.pipe(
      () => this.customerComplianceDepositFileType$,
      rx.map((type) => type.id),
      shareReplayRefOne(),
    )(null);
  }
}
