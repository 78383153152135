import template from './text-form-control.component.html';
const styles = require('./text-form-control.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class TextFormControlController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const TextFormControlComponent = {
  template,
  controller: TextFormControlController,
  bindings: {
    label: '<',
    formControl: '<',
  },
};
