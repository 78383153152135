import BaseController from '~/source/common/controllers/base';

import template from './filter-popover.html';
import { Brand } from '@proftit/crm.api.models.entities';
import { PlatformConnection } from '~/source/common/models/platform-connection';

class FilterPopoverController extends BaseController {
  brand: Brand;
  platformConnection: PlatformConnection;
  filterName: string;
  onFilterClose: () => void;
  filterType;

  /*@ngInject */
  constructor(readonly filtersSettings) {
    super();
  }

  $onInit() {
    const filter = this.filtersSettings[this.filterName];
    if (!filter) {
      throw new Error(`No settings for filter "${this.filterName}"`);
    }
    this.filterType = filter.popover.filterType;
  }
}

export default {
  template,
  controller: FilterPopoverController,
  bindings: {
    brand: '<',
    brandId: '<',
    platformId: '<',
    platformConnection: '<',
    filterName: '<',
    onFilterClose: '&',
  },
};
