export interface DateFormatConstants {
  MYSQL_DATETIME: string;
  MYSQL_DATE: string;
  UNIX_TIMESTAMP: string;
  UNIX_MILI_TIMESTAMP: string;
}

export const dateFormat = {
  MYSQL_DATETIME: 'YYYY-MM-DD HH:mm:ss',
  MYSQL_DATE: 'YYYY-MM-DD',
  UNIX_TIMESTAMP: 'X',
  UNIX_MILI_TIMESTAMP: 'x',
};

export default dateFormat;
