import ipaddr from 'ipaddr.js';

/**
 * Return wheter the ip is valid ipv6.
 *
 * @param {string} ip - ip.
 * @return {boolean} whether the ip is valid.
 */
export function isValidIpv6(ip: string) {
  return ipaddr.IPv6.isValid(ip);
}

export default isValidIpv6;
