export const brandConnectivityRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state(`crm.management.integration.platforms.connectivity`, {
        redirectTo: `crm.management.integration.platforms.connectivity.list`,
        url: `/connectivity`,
        template: '<ui-view/>',
      })
      .state(`crm.management.integration.platforms.connectivity.list`, {
        url: '/list',
        component: 'prfBrandsList',
        resolve: {
          resourceLink: () =>
            `crm.management.integration.platforms.connectivity.manage`,
          title: () => `management.integration.platforms.MANAGE_PLATFORMS`,
        },
      })
      .state(`crm.management.integration.platforms.connectivity.manage`, {
        component: 'prfPlatformsManage',
        url: '/manage/:brandId',
      });
  },
];
