import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

class DecimalModelConverter {
  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<number>(this.lifecycles.onChanges$, 'model');
  maxDecimalDigits$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'maxDecimalDigits',
  );

  onModelChange;
  context;
  localModel;
  lastValidModel;
  modelString: string;

  streamUpdateLocalValues() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.model$, this.maxDecimalDigits$),
      rx.tap(([val, maxDecimalDigits]: [number, number]) => {
        if (val === this.lastValidModel) {
          return;
        }
        this.localModel = val;
        this.lastValidModel = val;
        this.modelString = val.toFixed(maxDecimalDigits);
      }),
      shareReplayRefOne(),
    )(null);
  }

  constructor() {
    useStreams([this.streamUpdateLocalValues()], this.lifecycles.onDestroy$);
    const decimalModel = Object.defineProperty({}, 'value', {
      get: () => {
        return this.modelString;
      },
      set: (val) => {
        this.modelString = val;
        const numberRepresentation = Number(val);
        if (numberRepresentation === this.lastValidModel) {
          return;
        }

        this.lastValidModel = numberRepresentation;
        this.onModelChange({ value: numberRepresentation });
      },
    });

    this.context = {
      decimalModel,
    };
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const DecimalModelConverterComponent = {
  controller: DecimalModelConverter,
  template: `<cr-transclude context="$ctrl.context"></cr-transclude>`,
  transclude: true,
  bindings: {
    model: '<',
    maxDecimalDigits: '<',
    onModelChange: '&',
  },
};
