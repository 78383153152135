import SocketService from '~/source/common/services/socket';

class CustomerSiteLocationTrackingSocket extends SocketService {
  get channelRoot() {
    return '';
  }

  get whiteList() {
    return [];
  }
}

export default CustomerSiteLocationTrackingSocket;
