import { IScope } from 'angular';
import _ from 'underscore';
import moment from 'moment';

import BaseController from '~/source/common/controllers/base';
import CustomersService from '~/source/contact/common/services/customers';
import {
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { DEPOSIT_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';

interface Bindings {
  contactId: number;
  accountId: number;
  platformType: string;
}

class ConfirmDepositPopupController extends BaseController {
  customerServiceInst: CustomersService;
  account: TradingAccount;
  approval;
  $close: () => void;
  deposit: IElementRestNg<TradingAccountDeposit>;

  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope & Bindings,
    readonly customersService: () => CustomersService,
    readonly depositsSettings,
    readonly dateFormat,
    readonly prfClientGeneralPubsub,
  ) {
    super();

    Object.assign(this, {
      customerServiceInst: this.customersService(),
      settings: this.depositsSettings.depositConfirm,
      approval: {},
    });

    // get account related to deposit
    this.customerServiceInst
      .getAccountResource(
        this.$scope.contactId,
        this.$scope.accountId,
        true, // endBuild
        this.$scope.platformType,
      )
      .expand(['currency'])
      .getOneWithQuery<IElementRestNg<TradingAccount>>()
      .then((account) => {
        this.account = account;
      });
  }

  confirmDeposit() {
    if (_.isEmpty(this.approval.receivedDate)) {
      this.approval.receivedDate = moment.utc();
    }

    _.extend(this.approval, {
      transactionStatusCode: TradingAccountTransactionStatusCode.Approved,
      receivedDate: this.approval.receivedDate.format(
        this.dateFormat.MYSQL_DATETIME,
      ),
    });

    // temporary
    this.approval.transactionTransferWire.id = 8;

    this.deposit.patch(this.approval).then(() => {
      /*
       * emit event that will eventually will cause to
       * deposits table to reload data from api
       */
      this.$scope.$emit('depositsTableReload');
      this.prfClientGeneralPubsub.publish(DEPOSIT_STATUS_UPDATE);
      this.$close();
    });
  }
}

export default ConfirmDepositPopupController;
