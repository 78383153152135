import template from './marketing-info.html';

import TableController from '~/source/common/components/table/table.controller';
import * as _ from '@proftit/lodash';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Permissions } from '~/source/common/models/permission-structure';
const styles = require('./marketing-info.component.scss');

class Controller {
  Permissions = Permissions;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor(readonly customersService: () => CustomersService) {}

  customer: Customer;
  offerNameEdit$ = new rx.Subject();
  offerNameCancel$ = new rx.Subject();
  offerSave$ = new rx.Subject();
  originalOfferName$ = this.offerNameEdit$.pipe(
    rx.map(() => {
      return this.customer.productName;
    }),
    shareReplayRefOne(),
  );
  offerNameIsEdit$ = rx.obs.merge(
    this.offerNameEdit$.pipe(rx.map(() => true)),
    this.offerNameCancel$.pipe(rx.map(() => false)),
    this.offerSave$.pipe(rx.map(() => false)),
  );

  $onInit() {
    useStreams(
      [
        this.streamCancelOfferName(),
        this.streamSaveOfferName(),
        this.offerNameIsEdit$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  streamCancelOfferName() {
    return rx.pipe(
      () => this.offerNameCancel$,
      rx.withLatestFrom(this.originalOfferName$),
      rx.tap(([a, originalOfferName]) => {
        this.customer.productName = originalOfferName;
      }),
    )(null);
  }

  streamSaveOfferName() {
    return rx.pipe(
      () => this.offerSave$,
      rx.switchMap(() => {
        return this.customersService().updateCustomer(this.customer.id, {
          productName: this.customer.productName,
        });
      }),
    )(null);
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    customer: '<',
  },
};
