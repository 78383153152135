import TableController from '~/source/common/components/table/table.controller';
import template from './geographic-tab.html';
import GeographicStatsService from '~/source/marketing/services/geographic-stats.service';
import settings from './geographic-settings.json';
import * as _ from '@proftit/lodash';
import PopupService from '~/source/common/components/modal/popup.service';
import moment from 'moment-timezone';
import { useStream } from '~/source/common/utilities/use-stream';
import * as rx from '@proftit/rxjs';

import { Brand } from '@proftit/crm.api.models.entities';

class Controller extends TableController {
  static $inject = [
    '$scope',
    'geographicStatsService',
    'popupService',
    ...TableController.$inject,
  ];

  geographicStatsService: () => GeographicStatsService;
  popupService: PopupService;
  filter: { brandId: number; brand: Brand; time: { gte: number; lte: number } };
  unsub$ = new rx.Subject<void>();
  filter$ = new rx.BehaviorSubject<any>(null);
  // @ts-ignore
  dataServiceInstance = this.geographicStatsService();
  settings = _.cloneDeep(settings);
  cols: any[] = [...settings.cols];

  $onInit() {
    super.$onInit();
    useStream(this.streamFilter(), this.unsub$);
    this.initTable();
  }

  onFilterChange(filter) {
    const date_gte = moment
      .unix(filter.time.gte)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');

    const date_lte = moment
      .unix(filter.time.lte)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');

    const finalFilter = {
      date_lte,
      date_gte,
      brandId: filter.brandId,
      campaignId: filter.campaignId,
    };

    this.filter$.next(finalFilter);
  }

  streamFilter() {
    return rx.pipe(
      () => this.filter$,
      rx.filter(() => !_.isNil(this.ngTableDataParams)),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  get tableKey() {
    return 'geographicTable';
  }

  showColumnActions() {
    return false;
  }

  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: 'geographicTable' })
      .filter(this.filter$.getValue());
  }

  openGeographicPopup() {
    this.popupService.open({
      component: 'prfGeographicTablePopup',
      resolve: {
        filter: this.filter$.getValue(),
      },
    });
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    filter: '<',
  },
};
