import * as _ from '@proftit/lodash';

import FormFormatter from '~/source/common/services/base-form-formatter';

let self;

class ManagementBrandCampaignFormFormatter extends FormFormatter {
  constructor(...args) {
    super(...args);

    self = this;
  }

  get map() {
    return {
      brands: [
        {
          method: 'normalizeCampaigns',
          params: [],
        },
      ],
    };
  }

  /**
   * Modify brands and campaigns in order to match API requets
   *
   * Output example - brands [{
   *   brandId: 3
   *   campaigns: [3, ,4 , 5]
   * }]
   */
  normalizeCampaigns() {
    return _.map((brand) => {
      brand.campaigns = _.map((campaign) => campaign.id, brand.campaigns);

      return {
        campaigns: brand.campaigns,
        shouldAutoUpdateCampaigns: brand.shouldAutoUpdateCampaigns,
        brandId: brand.id,
      };
    }, self.model.brands);
  }
}

export default ManagementBrandCampaignFormFormatter;
