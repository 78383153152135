import Component from '../../common/components/dropdowns/base/component';

class CashierSelectController extends Component.controller {
  static $inject = ['cashierService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'clearing.cashier.SELECT_CASHIER';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({ controller: CashierSelectController });
