/**
 * Calc last call tooltip
 * @param callNumber: string;
 * @return html tooltip
 */
export function calcLastCallTooltip(callNumber: string) {
  if (!callNumber) {
    return 'no last calls';
  }

  return `<p>Current number is :  ${callNumber}<br>  Select the number which will be<br> displayed as an incoming call</p>`;
}
