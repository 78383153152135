import template from './sms-provider-data.component.html';
import { CrmAppStoreProviderController } from '../../../../../app/cfm-app-store-provider.component';

const styles = require('./sms-provider-data.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { pipeLog, shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Brand } from '@proftit/crm.api.models.entities';
import { Permissions } from '~/source/common/models/permission-structure';

function prepareCredentialForUi(credential, code) {
  const translationKey = `smsProvider.providers.${code}.${credential.key.toUpperCase()}`;
  return {
    ...credential,
    translationKey,
  };
}

export class SmsProviderDataController {
  prfCrmAppStoreProvider: CrmAppStoreProviderController;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  Permissions = Permissions;

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  preparedSmsCredentials$ = this.streamPreparedSmsCredentials();
  smsProvider$ = this.streamSmsProvider();

  /*@ngInject */
  constructor() {
    useStreams([this.brand$, this.smsProvider$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  noop() {}

  streamSmsProvider() {
    return rx.pipe(
      () => this.brand$,
      rx.filter(
        (brand) =>
          !_.isNil(brand) &&
          !_.isNil(brand.smsCredentials) &&
          brand.smsCredentials.length > 0,
      ),
      rx.map((brand) => brand.smsCredentials[0].smsProvider),
      shareReplayRefOne(),
    )(null);
  }

  streamPreparedSmsCredentials() {
    return rx.pipe(
      () => this.brand$,
      rx.filter(
        (brand) =>
          !!brand &&
          !_.isNil(brand.smsCredentials) &&
          brand.smsCredentials.length > 0,
      ),
      rx.map((brand) => {
        const smsData = brand.smsCredentials[0];
        const smsCredentials = _.defaultTo([], smsData.credentials);
        return smsCredentials.map((credential) => {
          return prepareCredentialForUi(credential, smsData.smsProvider.code);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const SmsProviderDataComponent = {
  template,
  controller: SmsProviderDataController,
  bindings: {
    onEnterEdit: '&',
    brand: '<',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
