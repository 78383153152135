import ModalService from '~/source/common/components/modal/modal.service';

const styles = require('./manage.component.scss');
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import BaseController from '~/source/common/controllers/base';
import template from './manage.html';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';

import { Brand } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { streamClearingCompanyConnectionsForBrandFromData } from './streams/clearing-company-connections-for-brand-from-data';
import { streamToggleIsActiveCompanyConnection } from '~/source/management/clearing-companies/components/manage/streams/toggle-is-active-company-connection';
import { streamClearingCompanyConnectionsForBrand } from '~/source/management/clearing-companies/components/manage/streams/clearing-company-connections-for-brand';
import { streamActiveClearingCompanyConnections } from '~/source/management/clearing-companies/components/manage/streams/active-clearing-company-connections';
import { streamDisabledClearingCompanyConnections } from '~/source/management/clearing-companies/components/manage/streams/disabled-clearing-company-connections';
import { ClearingCompanyConnectionsListType } from '~/source/management/clearing-companies/components/manage/clearing-company-connections-list-type';
import { PreselectType } from '~/source/common/components/dropdowns/base/component';

const LAST_SELECTED_BRAND_ID_KEY =
  'prf.custom.management.clearingCompanies.lastSelectedBrandId';

class Controller extends BaseController {
  static $inject = ['clearingCompanyConnectionsService', 'modalService'];
  styles = styles;
  ClearingCompanyConnectionsListType = ClearingCompanyConnectionsListType;
  PreselectType = PreselectType;

  lifecycles = observeComponentLifecycles(this);
  clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService;
  modalService: ModalService;
  blockUiId = generateBlockuiId();

  brandFormControl = new FormControl<Brand>(null);
  onFormItemDroppedInActiveListAction = new rx.Subject<any>();
  onFormItemDroppedInDisabledListAction = new rx.Subject<any>();
  reorderActiveCompanyConnectionsAction = new rx.Subject<{ data: any }>();
  toggleIsActiveCompanyConnectionAction = new rx.Subject<{ data: any }>();

  toggleIsActiveCompanyConnection$ = streamToggleIsActiveCompanyConnection(
    this.toggleIsActiveCompanyConnectionAction,
    // @ts-ignore
    this.clearingCompanyConnectionsService(),
  );
  clearingCompanyConnectionsForBrandFromData$ = streamClearingCompanyConnectionsForBrandFromData(
    this.toggleIsActiveCompanyConnection$,
    this.brandFormControl,
    // @ts-ignore
    this.clearingCompanyConnectionsService(),
  );
  clearingCompanyConnectionsForBrand$ = streamClearingCompanyConnectionsForBrand(
    this.clearingCompanyConnectionsForBrandFromData$,
    this.reorderActiveCompanyConnectionsAction,
    // @ts-ignore
    this.clearingCompanyConnectionsService(),
    this.blockUiId,
    this.brandFormControl,
  );
  activeClearingCompanyConnections$ = streamActiveClearingCompanyConnections(
    this.clearingCompanyConnectionsForBrand$,
  );
  disabledClearingCompanyConnections$ = streamDisabledClearingCompanyConnections(
    this.clearingCompanyConnectionsForBrand$,
  );

  constructor(...args) {
    super(...args);
    useStreams(
      [
        this.brandFormControl.value$,
        this.clearingCompanyConnectionsForBrandFromData$,
        this.toggleIsActiveCompanyConnection$,
        this.clearingCompanyConnectionsForBrand$,
        this.streamOpenReorderApprovalDialog(),
        this.activeClearingCompanyConnections$,
        this.disabledClearingCompanyConnections$,
        this.streamOnFormItemDroppedInDisabledList(),
        this.streamSaveSelectedBrandIdToLocalStorage(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  preselectLastSelectedBrand(brands: Brand[]) {
    const storedValue = localStorage.getItem(LAST_SELECTED_BRAND_ID_KEY);
    if (_.isNil(storedValue)) {
      return null;
    }

    const lastSelectedBrandId = Number(storedValue);
    return brands.find((brand) => brand.id === lastSelectedBrandId);
  }

  streamSaveSelectedBrandIdToLocalStorage() {
    return rx.pipe(
      () => this.brandFormControl.value$,
      rx.filter((value) => !_.isNil(value)),
      rx.tap((value) => {
        localStorage.setItem(LAST_SELECTED_BRAND_ID_KEY, String(value.id));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnFormItemDroppedInDisabledList() {
    return rx.pipe(
      () => this.onFormItemDroppedInDisabledListAction,
      rx.switchMap((data) => {
        const combinedData = {
          ...data,
          isActive: false,
        };
        return (this.openApprovalDialog(
          combinedData,
          'clearing.DISABLE_CLEARING_ORDER_WARNING',
        )
          .result.then(() => {
            return {
              data: combinedData,
              modalConfirmed: true,
            };
          })
          .catch(() => {
            return {
              modalConfirmed: false,
              data: null,
            };
          }) as any) as Promise<{ modalConfirmed: boolean; data: any }>;
      }),
      rx.tap(({ modalConfirmed, data }) => {
        if (modalConfirmed) {
          this.toggleIsActiveCompanyConnectionAction.next({ data });
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  openApprovalDialog(data, message) {
    return this.modalService.open({
      component: 'prfClearingCompanyReorderApprovalDialog',
      resolve: {
        data,
        message: () => message,
      },
    });
  }

  streamOpenReorderApprovalDialog() {
    return rx.pipe(
      () => this.onFormItemDroppedInActiveListAction,
      rx.withLatestFrom(
        this.activeClearingCompanyConnections$,
        this.disabledClearingCompanyConnections$,
      ),
      rx.switchMap(([data, activeConnections, disabledConnections]) => {
        const { item } = data;
        const allConnections = [...activeConnections, ...disabledConnections];
        const previousIndex = allConnections.findIndex(
          (connection) =>
            connection.clearingCompany.id === item.clearingCompany.id,
        );
        const combinedData = {
          ...data,
          previousIndex,
        };
        return (this.openApprovalDialog(
          combinedData,
          'clearing.CHANGE_CLEARING_ORDER_WARNING',
        )
          .result.then(() => {
            return {
              data: combinedData,
              modalConfirmed: true,
            };
          })
          .catch(() => {
            return {
              modalConfirmed: false,
              data: null,
            };
          }) as any) as Promise<{ modalConfirmed: boolean; data: any }>;
      }),
      rx.tap(({ modalConfirmed, data }) => {
        if (modalConfirmed) {
          this.reorderActiveCompanyConnectionsAction.next({ data });
        }
      }),
      shareReplayRefOne(),
    )(null);
  }
}

const ClearingCompaniesManageComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default ClearingCompaniesManageComponent;
