import BaseController from '~/source/common/controllers/base';
import moment, { Moment } from 'moment';
import { IIntervalService, IPromise } from 'angular';
import CommunicationsSocketService from '~/source/common/components/call-manager/communications-socket.service';
import { SocketListener } from '~/source/common/services/socket';
import TokensService from '~/source/auth/services/tokens';
import CustomersService from '~/source/contact/common/services/customers';

class CallPopupController extends BaseController {
  static $inject = [
    '$interval',
    '$scope',
    'tokensService',
    'communicationsSocketService',
    'customersService',
  ];

  $close: () => void;

  // Services
  $interval: IIntervalService;
  communicationsSocketService: CommunicationsSocketService;
  tokensService: TokensService;
  customersService: () => CustomersService;

  // Bindings
  call;

  // Members
  callDuration: Moment;
  customerTime;
  customerTimeInterval: any;
  callDurationInterval: any;
  onCallUpdateWrapped: SocketListener;
  callsUpdateChannel: string;

  /**
   * This is not a component. therefore this function will NOT run automatically by angular.
   * But since we want to mimic the component behaviour, it is called in the template by ng-init.
   */
  init() {
    this.startCallDuration();

    this.customerTime = moment().utcOffset(this.call.customer.timeZoneOffset);
    // customer time should always update
    this.customerTimeInterval = this.$interval(() => {
      this.customerTime = moment().utcOffset(this.call.customer.timeZoneOffset);
    }, 1000);

    this.onCallUpdateWrapped = this.communicationsSocketService.wrapListener(
      this.onCallUpdate.bind(this),
    );

    this.callsUpdateChannel = this.buildCallsUpdateChannel();
    this.communicationsSocketService.subscribe(
      this.callsUpdateChannel,
      this.onCallUpdateWrapped,
    );
    this.$scope.$on('modal.closing', this.onModalClose.bind(this));
  }

  /**
   * Gets the popup call template according to the current call status
   * Used by the popup opener ('call-manager.component').
   * @return {string} popup template url
   */
  get callTemplate() {
    const status =
      this.call.isActive || this.call.communicationStatus.code === 'calling'
        ? 'active'
        : 'inactive';
    // this template must be precached (see bottom of this file)
    return `call-popup-${status}.html`;
  }

  /**
   * Build streamer call update channel name
   * @return {string} channel name
   */
  buildCallsUpdateChannel() {
    const user = this.tokensService.getCachedUser();
    return `user.${user.id}.${this.communicationsSocketService.channelRoot}.${this.call.id}`;
  }

  /**
   * Called by the streamer when receiving a call update
   * @param {object} updatedProperties - updated call properties
   * @return {void}
   */
  onCallUpdate(updatedProperties) {
    const prevIsActive = this.call.isActive;
    Object.assign(this.call, updatedProperties);
    // called has changed from active to inactive. hang up
    if (prevIsActive && !updatedProperties.isActive) {
      this.onCallEnded();
    }
  }

  /**
   * Start counting the call duration.
   * Count will start at the call date and increased every second.
   * @return {void}
   */
  startCallDuration() {
    const timeSinceCall = moment.utc().diff(this.call.date);
    this.callDuration = moment.utc(timeSinceCall);

    this.callDurationInterval = this.$interval(() => {
      this.callDuration.add(1, 'second');
    }, 1000);
  }

  /**
   * End the call by sending "isActive=false" to server.
   * @return {Promise} - resolved when the update has completed
   */
  endCall() {
    return this.customersService()
      .setConfig({
        suppressBlockUi: false,
        suppressGrowl: false,
        blockUiRef: 'activeCall',
        growlRef: 'activeCall',
      })
      .endCall(this.call)
      .then(() => {
        this.call.isActive = false;
        this.onCallEnded();
        this.$close();
      });
  }

  /**
   * Called when a call ends (either by user or by streamer)
   * @return {void}
   */
  onCallEnded() {
    this.$interval.cancel(this.callDurationInterval);
  }

  /**
   * Calls on modal close
   * Stops listening for call updates and stops timers
   * @return {void}
   */
  onModalClose() {
    this.communicationsSocketService.unsubscribe(
      this.callsUpdateChannel,
      this.onCallUpdateWrapped,
    );
    this.$interval.cancel(this.callDurationInterval);
    this.$interval.cancel(this.customerTimeInterval);
  }
}

export default CallPopupController;
