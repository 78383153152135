import * as yup from 'yup';
import * as _ from '@proftit/lodash';
import { YupValidationCode } from '~/source/common/yup-validtion/yup-validation-code';

export function createCfdAssetsBulkUpdate(fieldToValidate) {
  const schemaObj = _.flow([
    (schema) => ({
      symbolCategory: yup.mixed().required(),
      pipDigits: yup.mixed().required(),
      groups: yup.mixed().required(),
      assets: yup.mixed().required(),
    }),
    (schema) =>
      fieldToValidate.spread
        ? { ...schema, spread: yup.number().integer().nullable() }
        : schema,
    (schema) =>
      fieldToValidate.leverage
        ? { ...schema, leverage: yup.number().integer().min(1).nullable() }
        : schema,
    (schema) =>
      fieldToValidate.swap
        ? {
            ...schema,
            swapCommission: yup.number().min(0).nullable(),
            swapRatioAsPercentage: (yup.number().min(0).max(100) as any)
              .maxDecimalPoints(2)
              .nullable(),
          }
        : schema,
    (schema) =>
      fieldToValidate.minInvestment
        ? { ...schema, minInvestment: yup.number().positive().nullable() }
        : schema,
    (schema) =>
      fieldToValidate.maxInvestment
        ? { ...schema, maxInvestment: yup.number().positive().nullable() }
        : schema,
  ])({});

  return yup
    .object()
    .shape(schemaObj)
    .test(
      'shouldChooseOnefieldToEdit',
      ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: 'chooseOne',
        code: YupValidationCode.chooseOne,
      }),
      (value, context) => {
        return [
          fieldToValidate.spread,
          fieldToValidate.leverage,
          fieldToValidate.swap,
          fieldToValidate.minInvestment,
          fieldToValidate.maxInvestment,
        ].some((field) => field);
      },
    );
}
