import template from './account-totals.component.html';
const styles = require('./account-totals.component.scss');

import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';

import { TradingAccount } from '@proftit/crm.api.models.entities';
import { TotalsTile } from '../../common/totals-tile';
import { TotalTilePart } from '../../common/total-tile-part';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { pipeLog, shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { BalanceLogsExportService } from '~/source/common/services/balance-log-export.sevice';

import { Permissions } from '~/source/common/models/permission-structure';

export class AccountTotalsController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  account: TradingAccount;
  tilesConfig: TotalsTile[];

  accountIn$ = observeShareCompChange<TradingAccount>(
    this.lifecycles.onChanges$,
    'account',
  );
  tilesConfigIn$ = observeShareCompChange<TotalsTile[]>(
    this.lifecycles.onChanges$,
    'tilesConfig',
  );

  startBalanceLogExportAction = new rx.Subject<void>();

  customizedTilesConfig$ = this.streamCustomizedTilesConfig();
  balanceLogsExportServiceInst: BalanceLogsExportService;
  Permissions = Permissions;

  /*@ngInject */
  constructor(
    readonly balanceLogsExportService: () => BalanceLogsExportService,
  ) {
    useStreams(
      [
        this.accountIn$,
        this.tilesConfigIn$,
        this.customizedTilesConfig$,
        this.streamStartBalanceLogExport(),
      ],
      this.lifecycles.onDestroy$,
    );
    this.balanceLogsExportServiceInst = this.balanceLogsExportService();
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamCustomizedTilesConfig() {
    return rx.pipe(
      () => this.tilesConfigIn$,
      rx.filter(
        (tilesConfig) => !_.isNil(tilesConfig) && !_.isNil(tilesConfig.length),
      ),
      rx.map((tilesConfig) =>
        tilesConfig.map((config) => {
          const shouldShowBalanceLogExportButton =
            config.primary.field === 'balance';
          return {
            ...config,
            shouldShowBalanceLogExportButton,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamStartBalanceLogExport() {
    return rx.pipe(
      () => this.startBalanceLogExportAction,
      rx.withLatestFrom(this.accountIn$),
      rx.tap(([a, account]) => {
        const tradingAccountId = account.id;
        this.balanceLogsExportServiceInst.startBalanceLogsExport(
          tradingAccountId,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  isTileToShow(stats: TotalsTile, account: TradingAccount) {
    if (_.isNil(stats.condition)) {
      return true;
    }

    return stats.condition(account);
  }

  isPartTileToShow(
    partType: string,
    part: TotalTilePart,
    account: TradingAccount,
  ) {
    if (partType !== 'secondary') {
      return true;
    }

    if (_.isNil(part.condition)) {
      return true;
    }

    return part.condition(account);
  }
}

export const AccountTotalsComponent = {
  template,
  controller: AccountTotalsController,
  bindings: {
    account: '<',
    tilesConfig: '<',
  },
};
