import template from './ewallet-crypto-providers-update.component.html';
import * as _ from '@proftit/lodash';
import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import UiRouter from '@uirouter/core';
import { BrandsService } from '~/source/management/brand/services/brands';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { Permissions } from '~/source/common/models/permission-structure';
import { VoipProvidersService } from '~/source/common/services/voip-providers';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';
import { EwalletsProvidersService } from '../../servises/ewalletProviders.service';
import {
  BrandEwalletProvider,
  UpdateEwalletProvider,
} from '~/source/management/integrations/ewallet-crypto-providers/ewallets-providers.model';

const styles = require('./communications-update.component.scss');

type IsSaveDisabled = Record<string, boolean>;

export class EwalletProvidersUpdateController {
  ewalletProviders;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateBlockuiId();
  growlId = this.blockUiId;
  Permissions = Permissions;
  reloadBrandAction = new rx.Subject<void>();
  blockUiRef = this.blockUiId;
  brandEwalletProviders$ = this.streamBrandEwalletsProviders();
  ewalletProviders$ = this.streamProviders();
  ewalletProvidersModel: BrandEwalletProvider[] = [];
  ewallets$ = this.streamEwallets();
  mergedProviders$ = this.streamMergedProviders();
  isValidToSave: IsSaveDisabled = {};
  isEdit = false;

  /*@ngInject */
  constructor(
    readonly $stateParams: UiRouter.StateParams,
    readonly voipProvidersService: VoipProvidersService,
    readonly brandsService: () => BrandsService,
    readonly ewalletsService: EwalletsService,
    readonly ewalletProvidersService: EwalletsProvidersService,
    readonly $scope: ng.IScope,
  ) {
    useStreams(
      [
        this.brandEwalletProviders$,
        this.ewalletProviders$,
        this.mergedProviders$,
        this.ewallets$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /**
   * This method is responsible for updating the state of the eWallet providers model.
   * It checks if any changes have been made to the model and applies them if necessary.
   * The method performs the following steps:
   * 1. It initializes an object to keep track of the active currencies for each provider.
   * 2. It checks if the current state of the eWallet providers model is valid for saving.
   * 3. It iterates over each provider in the model and for each provider, it iterates over its currencies.
   *    If a currency is active, it adds the provider's ID to the selectedActiveCurrencies object.
   * 4. It creates a deep copy of the eWallet providers model and maps over it. For each provider, it maps over its currencies.
   *    It sets the isDisabled property of each currency based on whether it is selected in the selectedActiveCurrencies object.
   * 5. It checks if the updated copy of the eWallet providers model is different from the current state.
   *    If it is, it updates the state of the eWallet providers model and triggers a digest cycle in AngularJS.
   */
  dataChanged() {
    let selectedActiveCurrencies = {};
    this.isValidToSave = this.checkIsValidToSave(this.ewalletProvidersModel);

    this.ewalletProvidersModel.forEach((provider) => {
      provider.currencies.forEach((currency) => {
        if (currency.isActive) {
          selectedActiveCurrencies[`${currency.code}`] = provider.providerId;
        }
      });
    });

    let providersCopy = _.cloneDeep(this.ewalletProvidersModel).map(
      (provider: BrandEwalletProvider) => {
        const currencies = provider.currencies.map((currency) => {
          currency.isDisabled = false;
          return {
            ...currency,
            isDisabled:
              selectedActiveCurrencies[`${currency.code}`] &&
              selectedActiveCurrencies[`${currency.code}`] !==
                provider.providerId,
          };
        });
        provider = {
          ...provider,
          currencies,
        };

        return provider;
      },
    );

    if (!_.isEqual(providersCopy, this.ewalletProvidersModel)) {
      setTimeout(() => {
        this.ewalletProvidersModel = providersCopy;
        this.$scope.$apply();
      });
    }
  }

  checkIsValidToSave(
    providers: BrandEwalletProvider[],
  ): {
    [key: string]: boolean;
  } {
    return providers.reduce((acc, provider) => {
      const currenciesValidArr = provider.currencies.map((c) => {
        if (!c.isActive) {
          return true;
        }
        return !!(c.isActive && c.ewallet);
      });
      let isCurrencyValid = currenciesValidArr.every((c) => c);
      let isCredentialsValid = provider.credentials.every((c) => {
        return c.isRequired ? !!c.value : true;
      });

      //const isValid = provider.currencies.some((c) => c.isActive && );
      acc[provider.providerId] =
        isCurrencyValid && isCredentialsValid && provider.apiUrl !== '';
      return acc;
    }, {});
  }

  /**
   * This method is responsible for creating a stream of eWallet providers.
   * It performs the following steps:
   * 1. It merges the initialization lifecycle event and the brand reload action into a single observable.
   * 2. It switches the context of the observable to the result of the fetchEwalletProviders method.
   * 3. It maps the providers to themselves, or to an empty array if they are not defined.
   * 4. It shares the latest value of the observable with new subscribers, and retains it for future computations.
   *
   * @returns {Observable} An observable that emits the list of eWallet providers.
   */
  streamProviders() {
    return rx.pipe(
      () => rx.obs.merge(this.lifecycles.onInit$, this.reloadBrandAction),
      rx.switchMap(() => this.fetchEwalletProviders()),
      rx.map((providers) => providers || []),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * This method is responsible for creating a stream of brand eWallet providers.
   * It performs the following steps:
   * 1. It merges the initialization lifecycle event and the brand reload action into a single observable.
   * 2. It maps the observable to the brandId from the state parameters.
   * 3. It switches the context of the observable to the result of the fetchBrand method, passing the brandId as an argument.
   * 4. It shares the latest value of the observable with new subscribers, and retains it for future computations.
   *
   * @returns {Observable} An observable that emits the list of brand eWallet providers.
   */
  streamBrandEwalletsProviders() {
    return rx.pipe(
      () => rx.obs.merge(this.lifecycles.onInit$, this.reloadBrandAction),
      rx.map(() => this.$stateParams.brandId),
      rx.switchMap((brandId) => this.fetchBrand(brandId)),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * This method is responsible for creating a stream of merged eWallet providers.
   * It performs the following steps:
   * 1. It combines the latest values of the eWallet providers and brand eWallet providers observables into a single observable.
   * 2. It maps the observable to a new array of providers. For each provider, it performs the following steps:
   *    a. It finds the corresponding brand provider.
   *    b. It merges the credentials of the provider and the brand provider.
   *    c. It merges the currencies of the provider and the brand provider.
   *    d. It creates a new provider object with the merged data.
   *    e. If the brand provider exists, it sets the id of the new provider to the id of the brand provider.
   * 3. It updates the state of the eWallet providers model with the new array of providers and triggers the dataChanged method.
   * 4. It shares the latest value of the observable with new subscribers, and retains it for future computations.
   *
   * @returns {Observable} An observable that emits the list of merged eWallet providers.
   */
  streamMergedProviders(): rx.Observable<BrandEwalletProvider[]> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.ewalletProviders$,
          this.brandEwalletProviders$,
        ),
      rx.map(([providers, brandProviders]) => {
        return providers.map((provider) => {
          const brandProvider = brandProviders.find(
            (bp) => bp.providerId === provider.id,
          );

          const credentials = this.mergeCredentials(provider, brandProvider);

          const currencies = this.mergeCurrency(provider, brandProvider);

          const pr: BrandEwalletProvider = {
            id: brandProvider ? brandProvider.id : null,
            name: provider.name,
            code: provider.code,
            brandId: this.$stateParams.brandId,
            providerId: provider.id,
            isActive: brandProvider ? brandProvider.isActive : false,
            apiUrl: brandProvider ? brandProvider.apiUrl : '',
            credentials: credentials,
            currencies,
          };

          return pr;
        });
      }),
      rx.tap((providers) => {
        this.ewalletProvidersModel = providers;
        this.dataChanged();
        return providers;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * This method is responsible for creating a stream of eWallets.
   * It performs the following steps:
   * 1. It filters the initialization lifecycle event to only pass through truthy values.
   * 2. It switches the context of the observable to the result of the getListWithQuery method from the ewalletsService.
   *    This method fetches a list of eWallets and returns a promise that resolves to the plain response.
   *    If an error occurs during this process, it catches the error and returns an observable that never emits.
   * 3. It maps the eWallets to a new array of eWallets. For each eWallet, it adds a label property that is equal to the eWallet's name.
   * 4. It shares the latest value of the observable with new subscribers, and retains it for future computations.
   *
   * @returns {Observable} An observable that emits the list of eWallets.
   */
  streamEwallets() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.ewalletsService
              .setConfig({ blockUiRef: this.blockUiRef })
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((ewallets) => {
        return ewallets.map((e) => {
          return {
            ...e,
            label: e.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * This method is responsible for merging the credentials of a provider and a brand provider.
   * It performs the following steps:
   * 1. It maps over the fields of the provider.
   * 2. For each field, it checks if the brand provider exists and if it has a credential with the same name as the field.
   * 3. If such a credential exists, it assigns its value to the credential variable. Otherwise, it assigns null to the credential variable.
   * 4. It creates a new field object with the same properties as the original field, but with the value property set to the value of the credential variable (or an empty string if the credential variable is null).
   * 5. It also adds a label property to the new field object, which is equal to the name of the field.
   * 6. It returns the new field object.
   *
   * @param {Object} provider - The provider whose fields are to be mapped over.
   * @param {Object} brandProvider - The brand provider whose credentials are to be merged with the fields of the provider.
   * @returns {Array} An array of new field objects with merged credentials.
   */
  mergeCredentials(provider, brandProvider) {
    return provider.fields.map((field) => {
      const credential = brandProvider
        ? brandProvider.credentials[field.name]
        : null;

      return {
        ...field,
        value: credential ? credential : '',
        label: field.name,
      };
    });
  }

  /**
   * This method is responsible for merging the currencies of a provider and a brand provider.
   * It performs the following steps:
   * 1. It maps over the currencies of the provider.
   * 2. For each currency, it checks if the brand provider exists and if it has a currency with the same currencyId.
   * 3. If such a currency exists, it assigns its ewallet to the ewallet variable. Otherwise, it assigns null to the ewallet variable.
   * 4. If the ewallet exists, it adds a label property to the ewallet, which is equal to the ewallet's name.
   * 5. It creates a new currency object with the same properties as the original currency, but with the ewallet property set to the ewallet variable (or null if the ewallet variable is null).
   * 6. It also adds a label property to the new currency object, which is equal to the code of the currency.
   * 7. It returns the new currency object.
   *
   * @param {Object} provider - The provider whose currencies are to be mapped over.
   * @param {Object} brandProvider - The brand provider whose currencies are to be merged with the currencies of the provider.
   * @returns {Array} An array of new currency objects with merged currencies.
   */
  mergeCurrency(provider, brandProvider) {
    return provider.currencies.map((c) => {
      const brandCurrency = brandProvider
        ? brandProvider.currencies.find((bc) => bc.currencyId === c.currency.id)
        : null;

      let ewallet = brandCurrency ? brandCurrency.ewallet : null;
      if (ewallet) {
        ewallet = {
          ...ewallet,
          label: ewallet.name,
        };
      }

      return {
        ...c,
        label: c.currency.name,
        ewallet: ewallet,
        isActive: !!brandCurrency,
        isDisabled: brandCurrency ? brandCurrency.isDisabled : false,
      };
    });
  }

  /**
   * This method is responsible for updating a provider.
   * It performs the following steps:
   * 1. It normalizes the provider at the specified index in the eWallet providers model.
   * 2. It calls the patchBrandEwalletProviders method from the brandsService, passing the brandId from the state parameters, the id of the provider, and the normalized provider as arguments.
   * 3. If the update is successful, it triggers the brand reload action.
   *
   * @param {number} index - The index of the provider in the eWallet providers model.
   * @param {number} id - The id of the provider.
   */
  updateProvider(index: number, id: number) {
    const provider = this.normalizeProviders(this.ewalletProvidersModel[index]);

    this.brandsService()
      .setConfig({
        blockUiRef: 'brandEwalletsProvidersBlockUi',
        growlRef: this.growlId,
      })
      .patchBrandEwalletProviders(this.$stateParams.brandId, id, provider)
      .then(() => {
        this.cancelEdit();
      });
  }

  /**
   * This method is responsible for creating a provider.
   * It performs the following steps:
   * 1. It normalizes the provider at the specified index in the eWallet providers model.
   * 2. It calls the createBrandEwalletProvider method from the brandsService, passing the brandId from the state parameters and the normalized provider as arguments.
   * 3. If the creation is successful, it triggers the brand reload action.
   *
   * @param {number} index - The index of the provider in the eWallet providers model.
   */

  createProvider(index: number) {
    const provider = this.normalizeProviders(this.ewalletProvidersModel[index]);

    this.brandsService()
      .setConfig({
        blockUiRef: 'brandEwalletsProvidersBlockUi',
        growlRef: this.growlId,
      })
      .createBrandEwalletProvider(this.$stateParams.brandId, provider)
      .then(() => {
        this.cancelEdit();
      });
  }

  /**
   * This method is responsible for normalizing a provider.
   * It performs the following steps:
   * 1. It creates a new provider object with the same properties as the original provider, but with the credentials property set to an object that maps the name of each credential to its value.
   * 2. It also maps the currencies of the provider to a new array of currencies. For each currency, it creates a new currency object with the currencyId and ewalletId properties set to the id of the currency and the id of the ewallet, respectively.
   * 3. If the provider has an id, it sets the providerId property of the new provider object to the id of the provider.
   * 4. It returns the new provider object.
   *
   * @param {BrandEwalletProvider} provider - The provider to be normalized.
   * @returns {UpdateEwalletProvider} The normalized provider.
   */
  normalizeProviders(provider: any): UpdateEwalletProvider {
    return {
      providerId: provider.providerId,
      isActive: provider.isActive,
      apiUrl: provider.apiUrl,
      credentials: provider.credentials.reduce((acc, c) => {
        acc[c.name] = c.value;
        return acc;
      }, {}),
      currencies: provider.currencies
        .filter((c) => c.isActive)
        .map((c) => {
          return {
            currencyId: c.currency.id,
            ewalletId: c.ewallet.id,
          };
        }),
    };
  }

  /**
   * This method is responsible for fetching a brand.
   * It performs the following steps:
   * 1. It calls the getBrandEwalletProviders method from the brandsService, passing the brandId as an argument.
   * 2. It returns a promise that resolves to the plain response of the method.
   *
   * @param {number} brandId - The id of the brand.
   * @returns {Promise} A promise that resolves to the plain response of the getBrandEwalletProviders method.
   */
  fetchBrand(brandId) {
    return this.brandsService()
      .getBrandEwalletProviders(brandId, ['currencies.ewallet'])
      .then((brand) => {
        return brand.plain();
      });
  }

  /**
   * This method is responsible for fetching eWallet providers.
   * It performs the following steps:
   * 1. It calls the getEwalletProvidersResourceWithCurrencies method from the ewalletProvidersService.
   * 2. It returns a promise that resolves to the plain response of the method.
   *
   * @returns {Promise} A promise that resolves to the plain response of the getEwalletProvidersResourceWithCurrencies method.
   */
  fetchEwalletProviders() {
    return this.ewalletProvidersService
      .getEwalletProvidersResourceWithCurrencies()
      .then((res) => {
        return res.plain();
      });
  }

  enterEdit() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = false;
    this.reloadBrandAction.next();
  }
}

export const EwalletCryptoProvidersUpdateComponent = {
  template,
  controller: EwalletProvidersUpdateController,
};
