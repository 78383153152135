import RestService from '~/source/common/services/rest';
import { CacheOptions } from 'cachefactory';
import { IFieldsAndModulesService } from '~/source/management/fields-and-modules/i-fields-and-modules-service';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { FieldsAndModulesModel } from '@proftit/crm.api.models.entities';
import { IPromise } from 'angular';
import { TimeInterval } from '@proftit/constants.time';

export class CustomerComplianceStatusesService extends RestService
  implements IFieldsAndModulesService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'customerComplianceStatuses';
  }

  useCache(): boolean {
    return true;
  }

  get cacheOptions(): CacheOptions {
    return {
      maxAge: TimeInterval.Hour,
    };
  }

  getCustomerComplianceStatusesResource(id: number) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }

  updateIsDefault(
    id: number,
    isDefault: boolean,
  ): Promise<FieldsAndModulesModel> {
    return this.getCustomerComplianceStatusesResource(id)
      .patchWithQuery<IElementRestNg<FieldsAndModulesModel>>({
        id,
        isDefault,
      })
      .then((patchedModel) => patchedModel);
  }
}

export default CustomerComplianceStatusesService;
