import * as _ from '@proftit/lodash';

import ManageController from '../form.controller';
import SignupFormsService from '../signup-forms.service';
import SignupForm from '~/source/common/models/signup-form';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

import template from '../form.html';

class SignupFormEditController extends ManageController {
  signupFormsService: SignupFormsService;

  /**
   * called by constructor on init
   * @returns {Promise} returns a promise which resolves with the current edited signupForm model
   */
  initSignupFormModel() {
    const process = this.signupFormsService
      .setConfig({ growlRef: 'signupForm', blockUiRef: 'signupForm' })
      .expand(['brand', 'platformType'])
      .getOneWithQuery<IElementRestNg<SignupForm>>(this.$state.params.id)
      .then((data) => data.plain())
      .then((form) => this.prepareForm(form));

    return Promise.resolve(process);
  }

  /**
   * Prepare form model from server for editing.
   * Map each of the components to its matching field config
   *
   * @param {object} formModel - Form model
   * @returns {void}
   */
  prepareForm(formModel) {
    const platformType = formModel.platformType.code;
    const fields = this.getSignupFormFieldsByType(platformType);
    this.originalFormFields = fields;
    // Merge fields configuration into the form components

    formModel.formComponents = this.mergeComponentsConfiguration(
      formModel.formComponents,
      fields,
    );

    let uid = 0;
    // the form fields collection should only contain fields which do not already exist in the form model
    this.formFields = _.differenceByEs(
      fields,
      formModel.formComponents,
      (def: any) => {
        /*
         * instances of a mutlipalable field are to be consider unique each one.
         * There should be in the tools panel regardless whether they are on the form.
         */
        if (def.isMultiple) {
          uid += 1;
          return uid;
        }

        return def.field;
      },
    );

    /*
     * `formFields` are intialized here and used in the ui in ng-repeat. Problem is that
     * ng-repeat puts mutabliy on the list items a $$hashKey. Since the origin of the fields
     * is from signupFormModuleSettings singleton service, those hash keys stays on
     * the instances and when entering the screen again from navigation - not reload - ng-repeat
     * throws error on duplicates.
     *
     * This clones the formFields so it could be used on the ui in ng-repeat without changing the
     * original formFields data.
     */
    this.formFields = this.formFields.map((formField) => ({ ...formField }));

    return formModel;
  }

  /**
   * Merge the fields configuration into the form components
   *
   * @example
   * mergeComponentsConfiguration(
   *  [{field: "firstName", "model: "name" }, {field: "lastName"}],
   *  [{field: "lastName", "type": "input" }, {field: "firstName", "type": "input" }]
   *  -> [{field: "firstName", model: "name", "type": "input" }, {field: "lastName", "type": "input" }]
   *
   * @param {array} formComponents - Form model components
   * @param {array} fieldsConfig - Fields configurations array
   * @return {array} Merged form components array
   */
  mergeComponentsConfiguration(formComponents, fieldsConfig) {
    return formComponents.map((modelComponent) => {
      const fieldConf = fieldsConfig.find(
        (conf) => modelComponent.field === conf.field,
      );
      return _.defaults(modelComponent, fieldConf);
    });
  }

  /**
   * Normalizes the form model and sends it to the server as PATCH
   *
   * @return {Promise} promise of the submit request
   */
  submitForm() {
    // normalize form components
    const normalized = this.normalize();

    return this.signupFormsService
      .resourceBuildStart()
      .getElement(this.model.id)
      .resourceBuildEnd()
      .setConfig({ growlRef: 'signupForm', blockUiRef: 'signupForm' })
      .patchWithQuery(normalized)
      .then(this.onSubmitSuccess.bind(this));
  }

  /**
   * Normalize form model: use the model normalizer on the form model,
   * and then the 'normalizeComponents' method on the form components.
   * Uses copies, should not mutate the original form model.
   *
   * @return {*} the normalized form model
   */
  normalize() {
    const normalized = this.modelNormalizer.normalize(_.omit('id', this.model));
    normalized.formComponents = this.normalizeComponents(
      normalized.formComponents,
    );

    return normalized;
  }
}

export default {
  template,
  controller: SignupFormEditController as any,
  controllerAs: 'vm',
};
