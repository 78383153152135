import template from './auto-assignment-audit-log-popup.component.html';
const styles = require('./auto-assignment-audit-log-popup.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { tableColumns } from './table-columns';
import { tableSettings } from './table-settings';
import { BrandsService } from '~/source/management/brand/services/brands';
import { Brand, Customer } from '@proftit/crm.api.models.entities';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { useStreams } from '@proftit/rxjs.adjunct';

interface Resolve {
  brand: Brand;
}

export class AutoAssignmentAuditLogPopupController extends TablePopupController {
  static $inject = ['brandsService', ...TablePopupController.$inject];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  growlId = generateGrowlId();
  tableSettings = _.cloneDeep(tableSettings);
  cols = [...tableColumns];
  brandsService: () => BrandsService;
  dataServiceInstance: BrandsService;
  brand: Brand;

  brand$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  ).pipe(
    rx.map((resolve) => _.get(['brand'], resolve)),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((brand) => (this.brand = brand)),
  );
  opInitTable$ = new rx.Subject<number>();

  /*@ngInject */
  constructor(dataService, ...args) {
    super(dataService, ...args);
    useStreams([this.streamInitTable()], this.lifecycles.onDestroy$);
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.opInitTable$,
          this.brand$.pipe(rx.filter((c) => !_.isNil(c))),
        ),
      rx.tap(([rows, brand]) => {
        this.initTable(rows);
      }),
    )(null);
  }

  get tableKey() {
    return 'autoAssignmentAuditLogPopup';
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.tableSettings;
  }

  query() {
    return this.dataServiceInstance
      .getAutoAssignmentLogsResource(this.brand.id)
      .expand(['user', 'brand']);
  }
}

export const AutoAssignmentAuditLogPopupComponent = {
  template,
  controller: AutoAssignmentAuditLogPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
