import * as _ from '@proftit/lodash';
import { ClearingCompany } from '../clearing-company';

const blockingCompanies = [];

export function isClearingCompanyIsBlocking(clearingCompany: ClearingCompany) {
  if (_.isNil(clearingCompany)) {
    return false;
  }

  if (blockingCompanies.includes(clearingCompany.code)) {
    return true;
  }

  return false;
}
