import { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';
import { CountriesService } from '../services/countries';
import { CountriesStore } from './countries-store';

export function useCountriesStore(
  countriesService: () => CountriesService,
  lifecycles: ComponentLifecyclesObservables,
) {
  const store = new CountriesStore(countriesService, lifecycles);

  return [store];
}
