import template from './notifications-custom-email.component.html';
const styles = require('./notifications-custom-email.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { EmailNotification } from '@proftit/crm.api.models.entities';

const MAX_EMAIL_AMOUNT = 10;

export class NotificationsCustomEmailController {
  emails;
  onStateChange: (a: { emails: EmailNotification[] }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  inputEmails$ = observeShareCompChange<EmailNotification[]>(
    this.lifecycles.onChanges$,
    'emails',
  );

  onEmailChangeAction = new rx.Subject<{
    emailString: string;
    index: number;
  }>();
  addEmailAction = new rx.Subject<void>();
  emails$ = this.streamEmails();

  maxEmails = MAX_EMAIL_AMOUNT;

  /*@ngInject */
  constructor() {
    useStreams([this.inputEmails$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [this.streamEmails(), this.streamNotifyOnStateChange()],
      this.lifecycles.onDestroy$,
    );
  }

  streamEmailsFromInputEmails() {
    return rx.pipe(
      () => this.inputEmails$,
      rx.map((inputEmails) => {
        if (!inputEmails || inputEmails.length === 0) {
          return [{ email: '' }];
        }
        return inputEmails;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEmails() {
    const emails$ = new rx.BehaviorSubject<EmailNotification[]>([]);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamEmailsFromInputEmails(),
          this.streamEmailsFromAdd(emails$),
          this.streamEmailsFromChange(emails$),
        ),
      rx.tap((emails) => emails$.next(emails)),
      shareReplayRefOne(),
    )(null);
  }

  streamEmailsFromAdd(emails$: rx.Observable<EmailNotification[]>) {
    return rx.pipe(
      () => this.addEmailAction,
      rx.withLatestFrom(emails$),
      rx.filter(([action, emails]) => emails.length < MAX_EMAIL_AMOUNT),
      rx.map(([action, oldEmailsState]) => [...oldEmailsState, { email: '' }]),
      shareReplayRefOne(),
    )(null);
  }

  streamEmailsFromChange(emails$: rx.Observable<EmailNotification[]>) {
    return rx.pipe(
      () => this.onEmailChangeAction,
      rx.withLatestFrom(emails$),
      rx.map(([{ emailString, index }, emails]) => {
        return emails.map((oldEmail, emailIndex) => {
          if (index === emailIndex) {
            return { email: emailString };
          }
          return oldEmail;
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyOnStateChange() {
    return rx.pipe(
      () => this.onEmailChangeAction,
      rx.withLatestFrom(this.emails$),
      rx.tap(([action, emails]) => {
        this.onStateChange({ emails });
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const NotificationsCustomEmailComponent = {
  template,
  controller: NotificationsCustomEmailController,
  bindings: {
    onStateChange: '&',
    disabled: '<',
    emails: '<',
  },
};
