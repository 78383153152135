import * as _ from '@proftit/lodash';

import Promise from 'bluebird';

import { JSONObject } from '~/source/types/JsonObject';
import SocketService from '~/source/common/services/socket';
import CustomersService from '~/source/contact/common/services/customers';
export default class WithdrawalsSocket extends SocketService {
  static $inject = ['customersService', ...SocketService.$inject];

  customersService: () => CustomersService;

  get channelRoot() {
    return 'TradingAccountWithdrawal';
  }

  get whiteList() {
    return [];
  }

  /**
   * General parse of returned record from streamer.
   *
   * This is user to do overal parsing before any 'parseField...' methods.
   * Here we use it to retrieve the deposit with the required expanded fields
   * instead of doing that for each field via it's seperated endpoint.
   * Now we don't need to access 'users' endpoint.
   *
   * @param {object} data - the streamer provided record
   * @return {Promise} promise that resolve after the record has been general parsed.
   */
  generalParse(data): Promise<any> {
    const expandFieldsNames = ['handler', 'user'];
    const fieldsToExapnds = _.isEmpty(this.whiteList)
      ? expandFieldsNames
      : _.intersection(expandFieldsNames, this.whiteList);

    return (
      this.customersService()
        .getWithdrawalRequestResource(
          data.customerId,
          data.tradingAccountId,
          data.id,
        )
        // this is a background operation. don't block or show errors
        .setConfig({
          suppressGrowl: true,
          suppressBlockUi: true,
        })
        .expand(fieldsToExapnds)
        .getOneWithQuery()
        .then((withdrawal) => {
          const expendedFields = _.pick(fieldsToExapnds, withdrawal);
          Object.assign(data, expendedFields);

          return data;
        }) as Promise<any>
    );
  }
}
