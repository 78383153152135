import angular from 'angular';

import FilterPopoverComponent from './filter-popover.component';
import FilterPopoverSimpleComponent from './filter-popover-simple.component';
import FilterPopoverDateComponent from './filter-popover-date/filter-popover-date.component';
import FilterPopoverSelectComponent from './filter-popover-select.component';

export const FilterPopoverModule = angular
  .module('crm.common.tableFilters.filterPopover', [])
  .component('prfFilterPopover', FilterPopoverComponent)
  .component('prfFilterPopoverDate', FilterPopoverDateComponent)
  .component('prfFilterPopoverSelect', FilterPopoverSelectComponent)
  .component('prfFilterPopoverSimple', FilterPopoverSimpleComponent);
