import template from './clock.html';
import BaseController from '~/source/common/controllers/base';

class ClockController extends BaseController {
  static $inject = ['clockService'];
}

export default {
  template,
  controller: ClockController,
};
