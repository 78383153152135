import { IScope } from 'angular';
import log from 'loglevel';
import BaseController from '~/source/common/controllers/base';
import template from './user-form.html';
import { FilesUploadService } from '~/source/common/services/files-upload';
import { User } from '@proftit/crm.api.models.entities';
import TokensService from '~/source/auth/services/tokens';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';
import { PublicSystemLogin2faStoreService } from '~/source/common/store-services/public-system-login-2fa-store.service';

class ComponentController extends BaseController {
  isPasswordEdit: boolean;
  resetByEmail: boolean;
  user: User;
  hideSystemGroups: boolean;
  UserRolePositionCode = UserRolePositionCode;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly filesUploadService: FilesUploadService,
    readonly blockUI: any,
    readonly tokensService: TokensService,
    readonly prfPublicSystemLogin2faStoreService: PublicSystemLogin2faStoreService,
  ) {
    super();

    Object.assign(this, {
      isPasswordEdit: false,
      resetByEmail: true,
    });
  }

  $onInit() {
    this.hideSystemGroups = true;
  }

  togglePasswordEditState() {
    this.isPasswordEdit = !this.isPasswordEdit;
  }

  uploadPhoto(file) {
    if (!file) {
      return;
    }

    const blockUiReference = 'imageUpload';
    const blockUiInstance = this.blockUI.instances.get(blockUiReference);
    blockUiInstance.start();
    this.filesUploadService.uploadPublicFile(file).then(
      (resp) => {
        this.user.profileImage = resp.data;
        blockUiInstance.stop();
      },
      (resp) => {
        log.warn(`Error uploading file: ${resp.status}`);
        blockUiInstance.stop();
      },
    );
  }
}

const UserFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    user: '=',
    form: '=',
    isEdit: '<',
  },
};

export default UserFormComponent;
