import * as _ from '@proftit/lodash';

import switchOn from '~/source/common/utilities/switch-on';

/**
 *
 */
export function convertStringToCustomerPropertyValue(valueParam, typeCode) {
  if (_.isNil(valueParam)) {
    return valueParam;
  }

  return switchOn(
    {
      bool: () => valueParam === 'true',
      text: () => valueParam,
      select: () => valueParam,
      multi_select: () => valueParam.split('<<delimiter>>'),
      date: () => valueParam,
    },
    typeCode,
    () => {
      throw new Error('unimplemented');
    },
  );
}

export default convertStringToCustomerPropertyValue;
