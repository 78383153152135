import * as _ from '@proftit/lodash';

function componentCreatorDirective($compile) {
  return {
    restrict: 'EA',
    scope: {
      componentName: '<',
      brand: '<',
      brandId: '<',
      platformId: '<',
      platformConnection: '<',
      model: '=',
      attributes: '<',
      triggerSelectAllResults: '<',
      shouldPreserveStructure: '<',
      onChangeOfSearchResults: '&',
      onChange: '&',
    },
    link(scope, element) {
      let templateExtra = '';

      // extract and add all filter's params (settings, count-var, nullable..)
      _.forOwnEs(scope.attributes, (val, key) => {
        templateExtra += ` ${key}="${val}"`;
      });

      const template = `
        <${scope.componentName}
          model="model"
          brand="brand"
          brand-id="brandId"
          platform-id="platformId"
          platform-connection="platformConnection"
          trigger-select-all-results="triggerSelectAllResults"
          on-change-of-search-results="onChangeOfSearchResults({ results: results })"
          on-change="onChange()"
         should-preserve-structure="shouldPreserveStructure" 
          ${templateExtra}>
        </${scope.componentName}>`;

      // replace this element's content with the template
      element.html(template);

      // compile and link it
      $compile(element.contents())(scope);
    },
  };
}

componentCreatorDirective.$inject = ['$compile'];

export default componentCreatorDirective;
