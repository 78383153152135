/**
 * Importing specific function from lodash-es here, because otherwise the tree-shaking won't work and
 * the entire lodash module would be required.
 * @see {@link https://github.com/webpack/webpack/issues/1750}
 * This will be resolved once we upgrade to webpack 4
 */
import * as _ from '@proftit/lodash';
import TableController from './table.controller';
import RestService, {
  IRestServiceInstance,
} from '~/source/common/services/rest';
import { Entity } from '@proftit/crm.api.models.general';

export class TablePopupController<
  T extends IRestServiceInstance = IRestServiceInstance,
  S extends Entity = Entity
> extends TableController<T, S> {
  static $inject = ['filtersSettings', ...TableController.$inject];
  // set this to true (by subclass) to make the popup narrow
  isSmallModal = false;

  constructor(dataService, ...args) {
    super(dataService, ...args);
    // set data service instance. if it's factorized, run it to get an instance
    this.dataServiceInstance = _.isFunctionEs(dataService)
      ? dataService()
      : dataService;
  }

  get isInfiniteTable(): boolean {
    return true;
  }

  /**
   * Override this method to enable the "saved filters" dropdown
   * @return {boolean} false by default
   */
  get enableSavedFilters(): boolean {
    return false;
  }

  /**
   * Override this method if you want to implement a custom query to the service instance
   * @return {object} data service instance (with filters applied, if needed)
   */
  query(): IRestServiceInstance {
    return this.dataServiceInstance;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.query().setConfig({
      blockUiRef: this.blockUiKey,
      growlRef: this.growlRef,
    });
  }
}

export default TablePopupController;
