import template from './gmail-notification-list.component.html';
const styles = require('./gmail-notification-list.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';

export class GmailNotificationListController {
  isFetchingNotifications: boolean;
  isReloadingNotifications: boolean;
  notifications: any[];
  newNotificationsFromStreamerCount: number;
  scrollTopActionId: number;
  onNotificationsNextPage: () => Promise<any>;
  onMarkNotificationAsRead: (a: { id: number }) => void;
  onReloadNotifications: () => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  infiniteScrollBlockUi = generateBlockuiId();
  wholeListBlockUi = generateBlockuiId();

  infiniteScrollSettings = {
    loadMore: this.loadMore,
    disabledFn: this.disabledFunction,
    distance: '0.1',
    parent: 'true',
    immediateCheck: 'true',
  };
  isFetchingNotifications$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'isFetchingNotifications',
  );

  isReloadingNotifications$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'isReloadingNotifications',
  );

  /*@ngInject */
  constructor(readonly blockUI: ng.blockUI.BlockUIService) {
    useStreams(
      [this.streamIsFetchingNotifications()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamIsFetchingNotifications() {
    return rx.pipe(
      () => this.isFetchingNotifications$,
      rx.tap((isFetchingNotifications) => {
        const blockUiId = this.blockUI.instances.get(
          this.infiniteScrollBlockUi,
        );
        if (isFetchingNotifications) {
          blockUiId.start();
        } else {
          blockUiId.stop();
        }
      }),
    )(null);
  }

  loadMore() {
    // this function can be called multiple times in the same loading action, so this is a way to block unnecessary API calls.
    if (this.isFetchingNotifications) {
      return;
    }
    this.onNotificationsNextPage();
  }

  disabledFunction() {
    return false;
  }
}

export const GmailNotificationListComponent = {
  template,
  controller: GmailNotificationListController,
  bindings: {
    notifications: '<',
    scrollTopActionId: '<',
    isFetchingNotifications: '<',
    isReloadingNotifications: '<',
    onExpandNotification: '&',
    onOpenCustomerPage: '&',
    onNotificationsNextPage: '&',
    onMarkNotificationAsRead: '&',
    onReloadNotifications: '&',
  },
};
