import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles, observeShareCompChange } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

/* eslint-disable @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, no-empty, class-methods-use-this */
class PrfTranscludeController {
  // inputs
  // state
  constructor($element) {
    this.lifecycles = observeComponentLifecycles(this);
    this.customDataInput$ = observeShareCompChange(this.lifecycles.onChanges$, 'customData');
    this.slotInput$ = observeShareCompChange(this.lifecycles.onChanges$, 'slot');
    this.transcludeFnInput$ = observeShareCompChange(this.lifecycles.onChanges$, 'transcludeFn');
    this.transcludedInfo$ = this.streamTranscludedInfo();
    this.$element = $element;
    useStreams([this.customDataInput$, this.slotInput$, this.transcludeFnInput$, this.streamSyncTranscludeElement(), this.streamSyncTranscludeData()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamTranscludedInfo() {
    return rx.pipe(() => rx.obs.combineLatest(this.transcludeFnInput$, this.slotInput$), rx.switchMap(([$transclude, slot]) => {
      return new rx.Observable(s => {
        let element;
        let scope;
        $transclude((clone, cloneScope) => {
          element = clone;
          scope = cloneScope;
          s.next({
            element,
            scope
          });
        }, undefined, slot);
        return () => {
          if (element) {
            try {
              element.remove();
            } catch (err) {// handle error here
            }
          }

          if (scope) {
            scope.$destroy();
          }
        };
      });
    }), shareReplayRefOne())(null);
  }

  streamSyncTranscludeElement() {
    const lastInfo$ = new rx.BehaviorSubject(null);
    return rx.pipe(() => this.transcludedInfo$, rx.withLatestFrom(lastInfo$), rx.tap(([{
      element
    }, lastInfo]) => {
      if (lastInfo) {
        lastInfo.element.remove();
        lastInfo.scope.$destroy();
      }

      this.$element.append(element);
    }), rx.tap(([info, _a]) => lastInfo$.next(info)))(null);
  }

  streamSyncTranscludeData() {
    const lastCustomData$ = new rx.BehaviorSubject(null);
    return rx.pipe(() => rx.obs.combineLatest(this.transcludedInfo$, this.customDataInput$), rx.withLatestFrom(lastCustomData$), rx.tap(([[{
      scope
    }, customData], lastCustomData]) => {
      if (lastCustomData) {
        _.omitEs(scope, Object.keys(lastCustomData));
      }

      Object.assign(scope, customData);
    }), rx.tap(([[_a, customData], _lastCustomData]) => {
      lastCustomData$.next(customData);
    }))(null);
  }

}
PrfTranscludeController.$inject = ['$element'];
const PrfTranscludeComponent = {
  template: '',
  controller: PrfTranscludeController,
  bindings: {
    customData: '<',
    slot: '<',
    transcludeFn: '<'
  }
};

function defineNg1PrfTranscludeModule(angular) {
  const ngModule = angular.module('prfNg1PrfTransclude', []);
  ngModule.component('prfTransclude', PrfTranscludeComponent);
  return ngModule;
}

export { defineNg1PrfTranscludeModule };
