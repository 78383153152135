import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import template from './edit.html';
import { StateService, StateParams } from '@uirouter/core';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';

class Controller extends BaseController {
  static $inject = [
    '$scope',
    'clearingCompanyConnectionsService',
    'modelNormalizer',
    '$state',
    '$stateParams',
  ];

  clearingCompanyConnection: IElementRestNg<ClearingCompanyConnection>;
  clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService;
  modelNormalizer: ModelNormalizerService;
  $state: StateService;
  clearingCompanyConnectionsServiceInst: ClearingCompanyConnectionsService;
  $stateParams: StateParams;

  constructor(...args) {
    super(...args);

    this.clearingCompanyConnectionsServiceInst = this.clearingCompanyConnectionsService();
    // load clearing company connection by id
    this.fetchClearingCompanyConnection();
  }

  /**
   *  fetch clearingCompany connection resource by id
   *  also include brand and clearingCompany resources
   */
  fetchClearingCompanyConnection() {
    this.clearingCompanyConnectionsServiceInst
      .expand(['brand', 'clearingCompany', 'displayFile'])
      .getOneWithQuery<IElementRestNg<ClearingCompanyConnection>>(
        this.$stateParams.id,
      )
      .then((data) => {
        this.clearingCompanyConnection = data;
      });
  }

  /**
   *  update clearing company Connection
   */
  update() {
    // omit id, needed for proper operation of normalizer
    const data = _.omit(this.clearingCompanyConnection.plain(), 'id');

    // prepare data for patch
    const normalizedData = this.modelNormalizer.normalize(data);

    // send patch request to server
    this.clearingCompanyConnection.patch(normalizedData).then(() => {
      this.$state.go('^.list');
    });
  }
}

const ClearingCompaniesEditComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default ClearingCompaniesEditComponent;
