import angular from 'angular';
import InitInfiniteTableDirective from './init-infinite-table.directive';
import { TableColumnsComponent } from './table-columns/table-columns.component';
import { TableColumnsPopup } from '~/source/common/components/table/table-columns-popup/table-columns-popup.component';

export default angular
  .module('crm.common.table', [])
  .directive('prfInitInfiniteTable', InitInfiniteTableDirective)
  .component('prfTableColumns', TableColumnsComponent)
  .component('prfTableColumnsPopup', TableColumnsPopup).name;
