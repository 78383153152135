import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './prf-inview-container.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { defaultInviewOptions } from '~/source/common/components/prf-inview-container/default-inview-options';
const styles = require('./prf-inview-container.component.scss');

type InviewState = {
  wasContainerSeen: boolean;
};

export class PrfInviewContainerController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  inviewChangeAction = new rx.Subject<boolean>();
  wasAlreadySeen$ = new rx.BehaviorSubject<boolean>(false);

  inviewOptions = defaultInviewOptions;
  context$ = this.streamContext();

  /* @ngInject */
  constructor() {
    useStreams([this.context$], this.lifecycles.onDestroy$);
  }

  streamContext(): rx.Observable<InviewState> {
    return rx.pipe(
      () => this.inviewChangeAction,
      rx.withLatestFrom(this.wasAlreadySeen$),
      rx.filter(
        ([actionData, wasAlreadySeen]) => actionData && !wasAlreadySeen,
      ),
      rx.map(() => {
        return { wasContainerSeen: true };
      }),
      rx.tap(() => {
        this.wasAlreadySeen$.next(true);
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const PrfInviewContainerComponent = {
  template,
  controller: PrfInviewContainerController,
  transclude: true,
};
