import ng from 'angular';
import { AppModuleNg } from './app.ngmodule';

import appConfig from './conf/appConfig';
import { getBrowserInfo } from '~/source/common/utilities/get-browser-info';

ng.element(document).ready(() => {
  Promise.all([$.getJSON('proftit.config.json'), getBrowserInfo()]).then(
    ([configData, appTag]) => {
      AppModuleNg.constant('appConfig', {
        ...appConfig,
        ...(configData as any),
      });
      AppModuleNg.constant('prfAppTag', appTag);
      ng.bootstrap(document, ['crm']);
    },
  );
});
