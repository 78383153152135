import ControllerBase from '~/source/common/controllers/base';
import template from './create.html';

class Controller extends ControllerBase {}

const PriorityManagementComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default PriorityManagementComponent;
