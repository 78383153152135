/* eslint-disable-next-line no-shadow */
let TimeInterval;

(function (TimeInterval) {
  TimeInterval[TimeInterval["Millisecond"] = 1] = "Millisecond";
  TimeInterval[TimeInterval["Second"] = TimeInterval.Millisecond * 1000] = "Second";
  TimeInterval[TimeInterval["Minute"] = TimeInterval.Second * 60] = "Minute";
  TimeInterval[TimeInterval["Hour"] = TimeInterval.Minute * 60] = "Hour";
  TimeInterval[TimeInterval["Day"] = TimeInterval.Hour * 24] = "Day";
  TimeInterval[TimeInterval["Week"] = TimeInterval.Day * 7] = "Week";
})(TimeInterval || (TimeInterval = {}));

export { TimeInterval };
