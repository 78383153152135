import { IScope } from 'angular';

import Component from '~/source/common/components/dropdowns/base/component';

export class NullableDepartmentSelectController extends Component.controller {
  static $inject = ['departmentsService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'management.SELECT_DEPARTMENT';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  get nullableElement() {
    return {
      id: null,
      name: 'No department',
    };
  }
}

export const NullableDepartmentSelectComponent = Component.config({
  controller: NullableDepartmentSelectController,
});
