import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';
import CommunicationsSocketService from '~/source/common/components/call-manager/communications-socket.service';

class CallsNotificationsController extends NotificationsController {
  communicationsSocketService: CommunicationsSocketService;
  customerId: number;

  /**
   * Returns channel for updates. By default only data that related
   * to selected account will be sent. Can be overridden by child classes
   *
   * @returns {string}
   */
  buildChannel() {
    const user = this.tokensService.getCachedUser();
    return `user.${user.id}.customer.${this.customerId}.${this.communicationsSocketService.channelRoot}.new`;
  }
}

CallsNotificationsController.$inject = [
  'communicationsSocketService',
  ...NotificationsController.$inject,
];

export default {
  ...ComponentOptions,
  controller: CallsNotificationsController,
  bindings: {
    ...ComponentOptions.bindings,
    customerId: '<',
  },
};
