export enum BrandPlatformCredentialTypeCode {
  RiskManagerUrl = 'riskManagerUrl',
  Username = 'username',
  Password = 'password',
  Host = 'host',
  Port = 'port',
  SenderCompId = 'senderCompId',
  TargetCompId = 'targetCompId',
  PublicApiToken = 'publicApiToken',
  StreamerUrl = 'streamerUrl',
}
