import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  // ItemProxy,
  // allChildrenAreValidProxyValidator,
  // ValueBean,
  generateValueBean,
  getBeanProxy,
  generateObjectBean,
  // logicalAndProxyValidatorFactory,
  // generateArrayBean,
  // getWholeValueOfBean,
  setWholeBean,
} from '@proftit/proxy-bean';
import template from './auto-assigment-dashboard.component.html';
import { BrandsStoreService } from '~/source/common/store-services/brands-store.service';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
const styles = require('./auto-assigment-dashboard.component.scss');

export class AutoAssigmentDashboardController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  mainForm = generateMainForm();

  inProcess$ = this.streamInProcess();

  /* @ngInject */
  constructor(readonly prfBrandsStore: BrandsStoreService) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamInProcess() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfBrandsStore.brandsS.inProcess$.pipe(
            rx.map((inProcess) => inProcess.inProcess),
          ),
        ),
      rx.map((list) => list.some((inProcess) => inProcess)),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }
}

function generateMainForm() {
  const bean = generateObjectBean({
    children: {
      brand: generateValueBean(),
    },
  });

  setWholeBean(bean, { brand: null });

  return getBeanProxy(bean);
}

export const AutoAssigmentDashboardComponent = {
  template,
  controller: AutoAssigmentDashboardController,
};
