import TablePopupController from '~/source/common/components/table/table-popup';
import CustomersService from '~/source/contact/common/services/customers';
import {
  Mt4OrderPnlSocketService,
  buildOrderPnlChannel,
} from '~/source/common/services/mt4-order-pnl-socket.service';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import log from 'loglevel';
import { Position } from '~/source/common/models/position';
import { BrandsService } from '~/source/management/brand/services/brands';
import * as rx from '@proftit/rxjs';
import { streamSubscribeToPositionSocketsUpdates } from './utils/stream-subscribe-to-position-sockets-updates';
import { CfdPlatformPositionPayoutSocketService } from '~/source/common/api-cfd-platform/cfd-platform-position-payout-socket.service';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const OPEN_PNL_UPDATE_INTERVAL = 5000;

export interface SocketOperation {
  listenTo: (data) => void;
  stopListening: (data) => void;
}

class PositionTablePopupController extends TablePopupController {
  static $inject = [
    'customersService',
    'mt4OrderPnlSocketService',
    'brandsService',
    'cfdPlatformPositionPayoutSocketService',
    ...TablePopupController.$inject,
  ];

  // bindings
  customer: Customer;

  account: TradingAccount;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  dataServiceInstance: CustomersService;
  mt4OrderPnlSocketService: () => Mt4OrderPnlSocketService;
  mt4OrderPnlSocketServiceInstance$ = new rx.BehaviorSubject<
    Mt4OrderPnlSocketService
  >(null);
  cfdPlatformPositionPayoutSocketService: () => CfdPlatformPositionPayoutSocketService;
  brandsService: () => BrandsService;

  socketsOperations: SocketOperation[] = [];
  onDestroy$ = new rx.Subject<void>();
  positions$ = new rx.BehaviorSubject<Position[]>([]);
  account$ = new rx.BehaviorSubject<TradingAccount>(null);
  customer$ = new rx.BehaviorSubject<Customer>(null);

  constructor(x, ...args) {
    super(x, ...args);

    // This is a controller used not in a component but view/controller.
    this.$scope.$on('$destroy', () => {
      this.onDestroy$.next();
    });

    this.$scope.$watch('account', (account: TradingAccount) => {
      this.account$.next(account);
    });

    this.$scope.$watch('customer', (customer: Customer) => {
      this.customer$.next(customer);
    });
  }

  $onInit() {
    super.$onInit();
    useStreams(
      [
        streamSubscribeToPositionSocketsUpdates(
          this.collection$,
          OPEN_PNL_UPDATE_INTERVAL,
          this.mt4OrderPnlSocketService,
          this.cfdPlatformPositionPayoutSocketService,
          (props) => this.onEntityUpdate(props),
          this.prfCurrentPlatformSession,
          new rx.Subject(),
        ),
      ],
      this.onDestroy$,
    );
  }

  get title() {
    return 'POSITION';
  }

  onEntityUpdate(props) {
    const item = this.collection.find((x: Position) => x.id === props.id);

    if (_.isNil(item)) {
      log.warn('no item found for socket incoming message', props);
      return;
    }

    Object.assign(item, props);
  }
}

export default PositionTablePopupController;
