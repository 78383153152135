import TableController from '~/source/common/components/table/table.controller';
import template from './brands-list.html';
import brandListSettings from './brands-list-settings.json';
import { BrandsService } from '../brand/services/brands';

export class BrandsListController extends TableController {
  brandsService: () => BrandsService;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      dataServiceInstance: this.brandsService(),
      settings: Object.assign({}, brandListSettings),
      blockUiKey: `${this.tableKey}BlockUi`,
      // Disable params caching: unlike the contacts list, we don't want it to remember current page/sort etc
      filterCacheKey: null,
      tableCacheKey: null,
    });

    this.initTable();
  }

  get tableKey() {
    return 'brandsApiList';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiKey })
      .embed(['platformConnections'])
      .expand(['file:logo', 'platformConnections.platform']);
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }
}

BrandsListController.$inject = ['brandsService', ...TableController.$inject];

export default {
  template,
  controller: BrandsListController,
  bindings: {
    resourceLink: '<',
    title: '<',
  },
};
