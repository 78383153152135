import ng from 'angular';
import * as rx from '@proftit/rxjs';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { permissionUpdateFor } from '../../permission-update-for';
import { permissionDeleteFor } from '../../permission-delete-for';
import { permissionCreateFor } from '../../permission-create-for';
import { wrapNgPermissionValidatePromise } from '../../wrap-ng-permission-validate-promise';

export function checkEmailTemplatePermission(
  PermPermissionStore: ng.permission.PermissionStore,
) {
  return rx.obs.forkJoin([
    rx.obs.from(
      wrapNgPermissionValidatePromise(
        PermPermissionStore.getPermissionDefinition(
          permissionUpdateFor(PermissionNormalized.ManagementEmailTemplate),
        ),
      ),
    ),
    rx.obs.from(
      wrapNgPermissionValidatePromise(
        PermPermissionStore.getPermissionDefinition(
          permissionDeleteFor(PermissionNormalized.ManagementEmailTemplate),
        ),
      ),
    ),
    rx.obs.from(
      wrapNgPermissionValidatePromise(
        PermPermissionStore.getPermissionDefinition(
          permissionCreateFor(PermissionNormalized.ManagementEmailTemplate),
        ),
      ),
    ),
  ]);
}
