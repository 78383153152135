import * as _ from '@proftit/lodash';
import { signupFormFieldTable } from '@proftit/signup-form.api.entities';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

/**
 * Get a list of the form fields according to the selected platform type.
 *
 * @param {string} platformType - platform type
 * @return {*[]} form fields
 */
export function getSignupFormFieldsByType(
  platformType: PlatformTypeCode,
  fields = signupFormFieldTable,
) {
  const common = fields.filter((f) => _.isNil(f.platformType));
  const perPlatform = fields.filter((f) => f.platformType === platformType);

  // Set the available form components array
  return [...common, ...perPlatform];
}
