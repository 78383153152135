import TableController from '~/source/common/components/table/table.controller';
import template from './cashier-list.component.html';
import settings from '../cashier-settings.json';
import BrandsService from '../../brand/services/brands';

class CashierListComponent extends TableController {
  static $inject = ['brandsService', ...TableController.$inject];

  brandsService: () => BrandsService;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      dataServiceInstance: this.brandsService(),
      settings: Object.assign({}, settings),
      blockUiKey: `${this.tableKey}BlockUi`,
      // Disable params caching: unlike the contacts list, we don't want it to remember current page/sort etc
      filterCacheKey: null,
      tableCacheKey: null,
    });

    this.initTable();
  }

  get tableKey() {
    return 'brandsCashiersList';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiKey })
      .expand(['file:logo', 'cashier']);
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /*
   * Called by the permission-on-unauthorized directive.
   * remove href attribute from <a> link.
   *
   * @param {Element} element - <a> dom element
   */
  onEditUnauthorized(element) {
    element.removeAttr('href');
  }
}

// todoOld: custom ng1 typescript decorator for component
export default {
  template,
  controller: CashierListComponent,
};
