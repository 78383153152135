export enum UserAccessApiGroupCodeCode {
  SystemDefaultGroup = 'initsuper',
  SystemImporterDefaultGroup = 'syncuser',
  Admin = 'admin',
  PtSupport = 'PtSupport',
  ApiUser = 'apiuser',
  Conversion = 'conversion',
  Retention = 'retention',
  BackOffice = 'backoffice',
  ConversionRetentionManager = 'conversionretentionmanager',
  TesterViewer = 'testerviewer',
  BackOfficeManager = 'managerbackoffice',
}
