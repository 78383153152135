import Component from './base/component';

class CountryController extends Component.controller {
  static $inject = ['countriesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.SELECT_COUNTRY';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  get nullableElement() {
    const name = this.$translate.instant('common.NOT_SPECIFIED');

    return {
      name,
      id: null,
      code: 'null',
      alpha2Code: 'null',
      nullable: true,
    };
  }
}

export default Component.config({ controller: CountryController });
