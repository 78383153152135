import Component from './base/component';

class PlatformTypesController extends Component.controller {
  static $inject = ['platformTypesService', ...Component.controller.$inject];

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'common.SELECT_INSTRUMENT';
  }
}

export default Component.config({ controller: PlatformTypesController });
