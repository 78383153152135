// This file was generated automatically. Tread lightly.

export enum CampaignTrackerParamTypeCode {
  Address = 'address',
  Amount = 'amount',
  Contactid = 'contactid',
  Email = 'email',
  Firstname = 'firstname',
  Lastname = 'lastname',
  Phone = 'phone',
}
