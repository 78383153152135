import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';
import { BrandEwalletService } from '../../services/brand-ewallet.service';
import { Brand } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { BrandEwallet } from '~/source/common/models/brand-ewallet';
import { ItemLabelStrategy } from '~/source/common/components/dropdowns/base/item-label-strategy';

class BrandEwalletSelectController extends DropdownController {
  static $inject = ['brandEwalletService', ...DropdownController.$inject];

  // bindings
  brand: Brand;

  dataServiceInst: BrandEwalletService;

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];

    this.$scope.$watch('vm.brand', () => {
      this.output = []; // reset states when the brand changes

      if (!this.brand) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return this.dataServiceInst
      .filter({ brandId: this.brand.id })
      .expand(['brand', 'ewallet'])
      .getListWithQuery<IElementRestNg<BrandEwallet>>();
  }

  get defaultPlaceholder() {
    return 'SELECT_WALLET';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.ewallet.name;
  }
}

export const BrandEwalletSelectComponent = DropdownConfig({
  controller: BrandEwalletSelectController,
  bindings: {
    brand: '=',
  },
});

export default BrandEwalletSelectComponent;
