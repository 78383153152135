/**
 * Receives a string representation of a number (e.g. "25.050") and removes trailing zeros, if there are any.
 *
 * Silently returns non-string values
 *
 *  @example
 * {{25.0500 | baseCurrencyFormat }} -> "25.05"
 * {{25.50 | baseCurrencyFormat : 2 }} -> "25.5"
 * {{25.00 | baseCurrencyFormat : 2 : true }} -> "25"
 *
 * @return {(value: string) => string}
 */
export function removeTrailingZeros() {
  return (value: string) => {
    if (typeof value !== 'string') {
      // if value is not string, silently return as-is
      return value;
    }
    return value
      .replace(/(?!\.)0+$/, '') // remove all the zeros after the decimal point
      .replace(/\.$/, ''); // if the point remained without any zeros after it, ("25."), remove the point.
  };
}

export default removeTrailingZeros;
