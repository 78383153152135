import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import UsersService, {
  BrandVoipConfiguration,
} from '~/source/management/user/services/users';
import { Brand, VoipProvider } from '@proftit/crm.api.models.entities';

import template from './user-brand-voip-data.html';

class ComponentController extends BaseController {
  brand: Brand;
  voipConfiguration: BrandVoipConfiguration;
  voipProvider: VoipProvider;

  prevAttributes: any;
  isEdit: boolean;
  usersServiceInst: UsersService;
  userId: number;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly usersService: () => UsersService,
  ) {
    super();
  }

  $onInit() {
    Object.assign(this, {
      usersServiceInst: this.usersService(),
    });
  }

  /**
   * Enter edit mode:
   * Save current configurationsFromServer state so it can be restored if the user chooses to cancel
   *
   * @returns {void}
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = { ...this.voipConfiguration };

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous configurationsFromServer state, or remove if it didn't exist before
   *
   * @returns {void}
   */
  cancelEdit() {
    // Restore pre-edit state
    this.voipConfiguration = this.prevAttributes;
    // Exit edit mode
    this.isEdit = false;
  }

  /**
   * is voip configurations fields filled
   *
   * @returns {boolean}
   */
  isEmptyConfigurations() {
    return (
      (_.isNil(this.voipConfiguration.extension) &&
        _.isNil(this.voipConfiguration.sip)) ||
      (String(this.voipConfiguration.extension).length === 0 &&
        this.voipConfiguration.sip.length === 0)
    );
  }

  /**
   * Delete voipConfigurations from existing brand
   *
   * @returns {Promise}
   */
  deleteVoipConfigurations() {
    this.usersServiceInst
      .getVoipConfigurationsResource(
        this.userId,
        this.brand.id,
        this.voipConfiguration.id,
      )
      .removeWithQuery()
      .then(() => {
        this.isEdit = false;
        this.voipConfiguration = {} as any;
      });
  }

  /**
   * Update brand with voipConfigurations
   *
   * @returns {Promise}
   */
  update() {
    this.usersServiceInst
      .updateUserVoip(this.userId, this.brand.id, this.voipConfiguration)
      .then(() => {
        this.isEdit = false;
      });
  }

  /**
   * Save new voipConfigurations on an existing brand
   * (create new voipConfigurations on new brand is saved straight from /create.js)
   *
   * @returns {*}
   */
  save() {
    if (this.isEmptyConfigurations()) {
      this.isEdit = false;
      return;
    }

    const payload = {
      ...this.voipConfiguration,
      providerId: this.voipProvider.id,
    };
    this.usersServiceInst
      .getVoipConfigurationsPostResource(this.userId, this.brand.id)
      .postWithQuery(payload)
      .then((voipConfig) => {
        this.isEdit = false;
        (this.voipConfiguration as any)._isNew = false;
        this.voipConfiguration.id = Number(voipConfig.id);
      });
  }

  /**
   * Called when 'save' is clicked - calles save/edit/delete methods
   *
   * @returns {*}
   */
  saveClicked() {
    // save new voipConfigurations on existing brand
    if (_.isEmpty(this.prevAttributes)) {
      return this.save();
    }

    if (this.prevAttributes._isNew) {
      return this.save();
    }

    // delete existing voip configurations
    if (this.isEmptyConfigurations()) {
      return this.deleteVoipConfigurations();
    }
    // edit voipConfigurations
    return this.update();
  }
}

const UserBrandVoipDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    userId: '<',
    voipConfiguration: '<',
    voipProvider: '<',
  },
};

export default UserBrandVoipDataComponent;
