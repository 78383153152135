import {
  NotificationSettingCategoryCode,
  NotificationSettingTypeCode,
} from '@proftit/notification-setting.api.enums';

export const uiSortOrderNotificationSettingTypes = [
  {
    category: NotificationSettingCategoryCode.NewContact,
    children: [NotificationSettingTypeCode.Signup],
  },
  {
    category: NotificationSettingCategoryCode.Assignments,
    children: [NotificationSettingTypeCode.Assignments],
  },
  {
    category: NotificationSettingCategoryCode.FollowUps,
    children: [NotificationSettingTypeCode.FollowUps],
  },
  {
    category: NotificationSettingCategoryCode.TradingActivity,
    children: [
      NotificationSettingTypeCode.ContactIsOnline,
      NotificationSettingTypeCode.InvestmentAbove,
      NotificationSettingTypeCode.MarginLevelAbove,
      NotificationSettingTypeCode.MarginLevelReached,
      NotificationSettingTypeCode.MarginStopOut,
      NotificationSettingTypeCode.TotalOpenVolumeAbove,
      NotificationSettingTypeCode.TotalTradingAccountPnLAbove,
      NotificationSettingTypeCode.TotalTradingAccountPnLBelow,
      NotificationSettingTypeCode.TotalTurnoverAbove,
      NotificationSettingTypeCode.TradeSizeAbove,
    ],
  },
  {
    category: NotificationSettingCategoryCode.Transactions,
    children: [
      NotificationSettingTypeCode.ApprovedBonuses,
      NotificationSettingTypeCode.ApprovedCredit,
      NotificationSettingTypeCode.ApprovedWithdrawals,
      NotificationSettingTypeCode.CanceledWithdrawals,
      NotificationSettingTypeCode.DepositApprovedAmountAbove,
      NotificationSettingTypeCode.RequestedDeposit,
      NotificationSettingTypeCode.FailedDepositAmountAbove,
      NotificationSettingTypeCode.TransactionsApprovedAmountAbove,
      NotificationSettingTypeCode.TurnoverGoalReached,
      NotificationSettingTypeCode.VolumeGoalReached,
      NotificationSettingTypeCode.WithdrawalRequests,
    ],
  },
  {
    category: NotificationSettingCategoryCode.Verification,
    children: [
      NotificationSettingTypeCode.RegulationFiles,
      NotificationSettingTypeCode.Verification,
      NotificationSettingTypeCode.FileExpiry,
    ],
  },
];
