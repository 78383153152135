import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import AddWithdrawalReqNotifierService from '~/source/common/services/add-withdrawal-request-notifier.service';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import template from './add-withdrawal-request-popup.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { WithdrawalDepositLinkServiceDirective } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-deposit-link-service-directive';

interface AccountCustomer {
  account: TradingAccount;
  customer: Customer;
}

export type WithdrawalDepositLink = {
  methodTypeId: number;
  depositId: number;
  withdrawalTypeId: number;
};

class AddWithdrawalPopupCtrl implements AccountCustomer {
  lifecycles = observeComponentLifecycles(this);

  isChargeble: boolean;
  withdrawalRequest: WithdrawalRequest;
  close: () => void;
  customer: Customer;
  account: TradingAccount;
  withdrawalDepositLink: WithdrawalDepositLinkServiceDirective;

  customerServiceInst: CustomersService;

  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;
  showDepositSelectionFormControl = new FormControl<boolean>(false);
  chargebackSelectedDeposit: any = {};

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly addWithdrawalReqNotifierService: AddWithdrawalReqNotifierService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    useStreams(
      [this.showDepositSelectionFormControl.value$],
      this.lifecycles.onDestroy$,
    );

    Object.assign(this, {
      withdrawalRequest: {},
      customerServiceInst: this.customersService(),
    });
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  /**
   *  component gets passed data by the 'resolve' binding parameter.
   * We use a setter to automatically extract the incoming value and destructur
   * it to the different values passed.
   */
  set resolve(accountCustomer) {
    if (_.isNil(accountCustomer)) {
      return;
    }
    this.account = accountCustomer.account;
    this.customer = accountCustomer.customer;
  }

  /**
   * Called when form is validated
   *
   * Sends request to server that will add ne withdrawal request
   */
  onValidated() {
    if (this.showDepositSelectionFormControl.value) {
      const normalized = this.withdrawalDepositLink.requestCalc();
      this.processSelectedDeposit({ normalized });
      this.submitWithdrawalRequest();
    } else {
      this.submitWithdrawalRequest();
    }
  }

  submitWithdrawalRequest() {
    const normalizedModel = this.modelNormalizer.normalize(
      this.withdrawalRequest,
    );
    if (this.isChargeble) {
      normalizedModel.depositId = this.chargebackSelectedDeposit.depositId;
    }

    // send request to the server
    this.customerServiceInst
      .setConfig({
        growlRef: 'withdrawalForm',
        blockUiRef: 'withdrawalForm',
        errorsTranslationPath: 'withdrawal.errors',
      })
      .getWithdrawalRequestsResource(this.customer.id, this.account.id)
      .expand(['currency'])
      .postWithQuery(normalizedModel)
      .then((withdrawalRequest) => {
        this.onAddWithdrawalSuccess(withdrawalRequest);
      });
  }

  /**
   * Called upon withdrawal request was successfully added on server
   *
   * @param {object} withdrawalRequest
   */
  onAddWithdrawalSuccess(withdrawalRequest) {
    // fire next that will eventually will refresh the withdrawal request table
    this.addWithdrawalReqNotifierService.next();
    // close the modal
    this.close();
  }

  /**
   * called when withdrawal type selection changes
   * @param newType
   */
  onWithdrawalTypeChange(newType) {
    if (!newType) {
      return;
    }
    if (newType.code === 'chargeback') {
      this.isChargeble = true;
      delete this.withdrawalRequest.amountRequested;
    } else {
      this.isChargeble = false;
    }
  }

  processSelectedDeposit({
    normalized,
  }: {
    normalized: WithdrawalDepositLink[];
  }) {
    if (normalized.length === 0) {
      return;
    }
    this.chargebackSelectedDeposit = normalized[0];
  }
}

export default {
  template,
  controller: AddWithdrawalPopupCtrl,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
