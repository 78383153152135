import BaseController from '~/source/common/controllers/base';
import { UserFilters } from '~/source/common/services/user-filters';
import { IScope } from 'angular';

class DeleteFilterModalController extends BaseController {
  $close: () => void;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly userFiltersService: UserFilters,
  ) {
    super();
  }

  /**
   * Remove the filter from server and close the modal.
   * @param {object} filter filter to delete
   */
  remove(filter) {
    this.userFiltersService.removeElement(filter.id).then(() => {
      this.$close();
      (this.$scope as any).onRemove(filter);
    });
  }
}

export default DeleteFilterModalController;
