import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'brandEwallets';

export class BrandEwalletService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  deleteBrandEwallet(brandEwalletID) {
    return this.resourceBuildStart()
      .getElement(brandEwalletID)
      .resourceBuildEnd()
      .removeWithQuery();
  }
}

export default BrandEwalletService;
