export const tableColumns = [
  {
    title: 'automation.autoAssignmentsDashboard.activityLog.MODIFIED_ON',
    field: 'createdAt',
    id: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: 'createdAt',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'automation.autoAssignmentsDashboard.activityLog.ACTION',
    field: '',
    id: 'action',
    fieldFormat: '{{ action }}',
    sortable: 'action',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.BRAND',
    field: '',
    id: 'brand',
    fieldFormat: '{{ brand.name }}',
    sortable: 'brandId',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.SOURCE_DESK',
    field: '',
    id: 'sourceDesk',
    fieldFormat:
      '{{ sourceDesk ? sourceDesk.name : ("common.NO_DESK" | translate) }}',
    sortable: 'sourceDeskId',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.DESTIONATION_DESK',
    field: '',
    id: 'destinationDesk',
    fieldFormat:
      '{{ destinationDesk ? destinationDesk.name : ("common.NO_DESK" | translate) }}',
    sortable: 'destinationDeskId',
    show: true,
    filterable: false,
    removable: false,
  },
  /*
  {
    title: 'common.ASSIGNEE_USER',
    field: '',
    id: 'assigneeUser',
    fieldFormat: '{{ assigneeUser ? assigneeUser.username : ("common.NO_USER" | translate) }}',
    sortable: 'assigneeUserId',
    show: true,
    filterable: false,
    removable: false,
  },
  */
  {
    title: 'automation.autoAssignmentsDashboard.activityLog.CHANGED_BY',
    field: 'automationAutoAssignmentAuditLogChangedBy',
    id: 'user',
    fieldFormat:
      '{{ user ? (user.firstName + " " + user.lastName) : ("common.NO_USER" | translate) }}',
    sortable: 'userId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'automation.autoAssignmentsDashboard.activityLog.IP',
    field: '',
    id: 'ip',
    fieldFormat: '{{ userIp }}',
    sortable: 'userIp',
    show: true,
    filterable: false,
    removable: false,
  },
];
