import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './brand-platforms-dropdown.component.html';
const styles = require('./brand-platforms-dropdown.component.scss');

export class BrandPlatformsDropdownController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  theme: string;

  /* @ngInject */
  constructor() {}

  $onInit() {
    this.theme = _.defaultTo('crm_classic', this.theme);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const BrandPlatformsDropdownComponent = {
  template,
  controller: BrandPlatformsDropdownController,
  bindings: {
    theme: '<',
    items: '<',
    selectedItem: '=',
    selectionCompare: '<',
    isReadonly: '<',
  },
};
