import angular from 'angular';
import DeleteFilterModalController from './delete-filter-modal.controller';
import FilterGroupFormComponent from './filter-group-form.component';
import FilterGroupPopupController from './filter-group-modal';
import MyFiltersDropdownComponent from './my-filters-dropdown/my-filters-dropdown.component';
import { UpdateSharedGroupFilterModalComponent } from './update-shared-group-filter-modal/update-shared-group-filter-modal.component';

export default angular
  .module('crm.common.filterGroup', [])
  .controller('DeleteFilterModalController', DeleteFilterModalController)
  .controller('FilterGroupPopupController', FilterGroupPopupController)
  .component('prfFilterGroupForm', FilterGroupFormComponent)
  .component('prfMyFiltersDropdown', MyFiltersDropdownComponent)
  .component(
    'prfUpdateSharedGroupFilterModal',
    UpdateSharedGroupFilterModalComponent,
  ).name;
