import _ from 'underscore';
import BaseService from '~/source/common/services/baseService';
import module from '../../module';
import { IHttpService } from 'angular';

const MIN_BINCHECK_LENGTH = 6;
export default class BinCheckerService extends BaseService {
  static $inject = ['$http'];

  $http: IHttpService;

  // map of cc providers names
  ccMap = {
    MASTERCARD: 'mastercard',
    VISA: 'visa',
    MAESTRO: 'maestro',
    'AMERICAN EXPRESS': 'amex',
  };

  /**
   * Check bin of provided ccNum
   *
   * @param {String} ccNum
   * @returns {*|Promise.<TResult>}
   */
  check(ccNum) {
    // preform bin check
    return this.$http({
      method: 'GET',
      url: 'https://api.bincodes.com/bin/',
      params: {
        format: 'json',
        api_key: 'e00365d3c7d140ada6d3c0bd7cdf9f5e',
        bin: ccNum.substring(0, MIN_BINCHECK_LENGTH),
      },
    }).then((response) => this.onFulfilled(response));
  }

  /**
   * Called when bin received from service
   *
   * @param {Object} response
   * @returns {Object|false} - return false if check failed
   */
  onFulfilled(response) {
    /*
     * Example of valid data:
     * data: {
     * bank: "ROYAL BANK OF SCOTLAND PLC"
     * bin:"675964"
     * card:"MAESTRO"
     * country:"UNITED KINGDOM"
     * countrycode:"GB"
     * level:""
     * phone:"0800 161 5153"
     * type:"DEBIT"
     * valid:"true"
     * website:"HTTP://WWW.RBS.CO.UK/"
     * }
     * Example of response when bin check failed
     * data: {
     * error:"1011"
     * message:"Invalid BIN"
     * valid:"false"
     * }
     */
    const binData = response.data;
    // bin check failed - just return the response
    if (binData.valid === 'false') {
      return false;
    }
    /*
     * bin valid
     * map cc card company name to code as it named
     * at proftit
     */
    binData.card = this.ccMap[binData.card];
    // could not match supported card type
    if (_.isUndefined(binData.card)) {
      return false;
    }

    return binData;
  }
}
