import TablePopupController from '~/source/common/components/table/table-popup';
import settings from './notifications-settings.json';
import { NotificationsService } from './notifications.service.js';

class NotificationsCenterLogPopupController extends TablePopupController {
  static $inject = ['notificationsService', ...TablePopupController.$inject];

  searchString: string;

  notificationsService: NotificationsService;

  get cols() {
    return [...settings.tableColumns];
  }

  get ngTableSettings() {
    return { ...settings.tablePopup.ngTable };
  }

  get title() {
    return 'notifications.NOTIFICATIONS_CENTER';
  }

  get tableKey() {
    return 'notificationsCenterLogs';
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    return {
      q: this.searchString,
    };
  }

  /**
   * called on search string change
   * Just reload the table. the search string will be added as
   * required api filter anyway.
   * @return {void}
   */
  onSearchChange() {
    this.resetNgTablePage();
    this.tableParams.reload();
  }
}

export default NotificationsCenterLogPopupController;
