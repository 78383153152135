import ng from 'angular';
import { UdarryFrameComponent } from './udarry-frame/udarry-frame.component';
import { DragndropFromToBoxComponent } from './dragndrop-from-to-box/dragndrop-from-to-box.component';
import { ConfirmButtonsComponent } from './confirm-buttons/confirm-buttons.component';
import { SimpleCircleTextComponent } from './simple-circle-text/simple-circle-text.component';
import { OrangeInlineEditorComponent } from './orange-inline-editor/orange-inline-editor.component';
import { userProfileSummaryBarComponent } from './user-profile-summary-bar/user-profile-summary-bar';
import { profileIconsSelectBarComponent } from './profile-icons-select-bar/profile-icons-select-bar';
import { YigguFrameComponent } from './yiggu-frame/yiggu-frame.component';
import { OnOffIndicatorComponent } from './on-off-indicator/on-off-indicator.component';
import { SvgSymbolsComponent } from '~/source/ui-toolkit/svg-symbols/svg-symbols.component';
import { CyllaIconsBarComponent } from './cylla-icons-bar/cylla-icons-bar.component';
import { AlphabyItemsBarComponent } from './alphaby-items-bar/alphaby-items-bar.component';
import { EditBarComponent } from './edit-bar/edit-bar.component';
import { FernisFrameComponent } from './fernis-frame/fernis-frame.component';
import { LockToggleComponent } from './lock-toggle/lock-toggle.component';
import { UiRouterTabsStyleComponent } from '~/source/ui-toolkit/ui-router-tabs-style/ui-router-tabs-style.component';
import { KingoProgressBarComponent } from '~/source/ui-toolkit/kingo-progress-bar/kingo-progress-bar.component';
import { EditableValueControlComponent } from '~/source/ui-toolkit/editable-value-control/editable-value-control.component';
import { HoverEditComponent } from './hover-edit/hover-edit.component';
import { TextFormControlComponent } from './text-form-control/text-form-control.component';
import { InlineGrowlComponent } from './inline-growl/inline-growl.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { EditConfirmLineComponent } from './edit-confirm-line/edit-confirm-line.component';
import { InlineCellEditComponent } from '~/source/ui-toolkit/inline-cell-edit/inline-cell-edit.component';

const dependencies = [];

export const UiToolkitModule = ng.module('prf.ui_toolkit', dependencies);

UiToolkitModule.component('prfUdarryFrame', UdarryFrameComponent)
  .component('prfCheckbox', CheckboxComponent)
  .component('prfDragndropFromToBox', DragndropFromToBoxComponent)
  .component('prfFormField', FormFieldComponent)
  .component('prfConfirmButtons', ConfirmButtonsComponent)
  .component('prfSimpleCircleText', SimpleCircleTextComponent)
  .component('prfOrangeInlineEditor', OrangeInlineEditorComponent)
  .component('prfUserProfileSummaryBar', userProfileSummaryBarComponent)
  .component('prfYigguFrame', YigguFrameComponent)
  .component('prfProfileIconsSelectBar', profileIconsSelectBarComponent)
  .component('prfOnOffIndicator', OnOffIndicatorComponent)
  .component('prfSvgSymbols', SvgSymbolsComponent)
  .component('prfCyllaIconsBar', CyllaIconsBarComponent)
  .component('prfEditBar', EditBarComponent)
  .component('prfFernisFrame', FernisFrameComponent)
  .component('prfLockToggle', LockToggleComponent)
  .component('prfAlphabyItemsBar', AlphabyItemsBarComponent)
  .component('prfUiRouterTabsStyle', UiRouterTabsStyleComponent)
  .component('prfKingoProgressBar', KingoProgressBarComponent)
  .component('prfEditableValueControl', EditableValueControlComponent)
  .component('prfHoverEdit', HoverEditComponent)
  .component('prfInlineGrowl', InlineGrowlComponent)
  .component('prfEditConfirmLine', EditConfirmLineComponent)
  .component('prfTextFormControl', TextFormControlComponent)
  .component('prfInlineCellEdit', InlineCellEditComponent);
