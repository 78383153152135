import { Permissions } from '~/source/common/models/permission-structure';

export const depositsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          transactionDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'reports.titles.TRANSACTION_DATE',
        field: 'transactionDate',
        fieldName: 'transactionDate',
        fieldFormat: '{{ transactionDate }}',
        reportFields: ['transactionDate'],
        filterable: true,
        sortable: 'transactionDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.CONFIRMED_DATE',
        field: 'confirmedAt',
        fieldName: 'confirmedAt',
        fieldFormat: '{{ confirmedAt }}',
        reportFields: ['confirmedAt'],
        filterable: true,
        sortable: 'confirmedAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'deposits.AMOUNT_APPROVED',
        field: 'amountApproved',
        fieldName: 'amountApproved',
        fieldFormat: '{{ amountApproved | number : 2}}',
        reportFields: ['amountApproved'],
        filterable: true,
        sortable: 'amountApproved',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_APPROVED_BASIC',
        field: 'amountApprovedBasic',
        fieldName: 'amountApprovedBasic',
        fieldFormat:
          '{{ amountApprovedBasic  | baseCurrencyFormat : 2 : true}}',
        reportFields: ['amountApprovedBasic'],
        filterable: true,
        sortable: 'amountApprovedBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.FOUR_LAST_DIGITS',
        field: 'fourLastDigits',
        fieldName: 'fourLastDigits',
        fieldFormat: '{{ fourLastDigits }}',
        reportFields: ['fourLastDigits'],
        filterable: false,
        sortable: 'fourLastDigits',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.METHOD',
        field: 'reportsTransferMethodType',
        fieldName: 'method',
        fieldFormat: '{{ method }}',
        reportFields: ['method'],
        filterable: true,
        sortable: 'method',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.NOTE',
        field: 'note',
        fieldName: 'note',
        fieldFormat: '{{ note }}',
        reportFields: ['note'],
        filterable: false,
        sortable: 'note',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.TRANSACTION_ID',
        field: 'transactionId',
        fieldName: 'transactionId',
        fieldFormat: '{{ transactionId }}',
        reportFields: ['transactionId'],
        filterable: false,
        sortable: 'transactionId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.ACCOUNT_MANAGER',
        field: 'userFullname',
        fieldName: 'userFullname',
        fieldFormat: '{{ userFullname }}',
        reportFields: ['userFullname'],
        filterable: true,
        sortable: 'userFullname',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.charts.OWNER',
        field: 'reportsOwner',
        fieldName: 'owner',
        fieldFormat: '{{ owner }}',
        reportFields: ['owner'],
        filterable: true,
        sortable: 'owner',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'deposits.OWNER_DESK',
        field: 'ownerDesk',
        fieldName: 'ownerDesk',
        fieldFormat: '{{ ownerDesk }}',
        reportFields: ['ownerDesk'],
        filterable: true,
        sortable: 'ownerDesk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.PLATFORM',
        field: 'platform',
        fieldName: 'platform',
        fieldFormat: '{{ platform }}',
        reportFields: ['platform'],
        filterable: false,
        sortable: 'platform',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'transactionStatusCode',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'accountName',
        fieldName: 'accountName',
        fieldFormat: '{{ accountName }}',
        reportFields: ['accountName'],
        filterable: false,
        sortable: 'accountName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        fieldFormat: '{{ externalAccountId }}',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.fields.DEPOSIT_ID',
        field: 'id',
        fieldName: 'id',
        fieldFormat: '{{ id }}',
        reportFields: ['id'],
        filterable: false,
        sortable: 'id',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CLEARING_COMPANY',
        field: 'reportsClearingCompany',
        fieldName: 'clearingCompany',
        fieldFormat: '{{ clearingCompany }}',
        reportFields: ['clearingCompany'],
        filterable: true,
        sortable: 'clearingCompany',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_DEPOSIT_COUNT',
        field: 'totalDepositsCnt',
        fieldName: 'totalDepositsCnt',
        fieldFormat: '{{ totalDepositsCnt }}',
        reportFields: ['totalDepositsCnt'],
        filterable: true,
        sortable: 'totalDepositsCnt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.FTD',
        field: 'ftd',
        fieldName: 'ftd',
        fieldFormat: '{{ ftd }}',
        reportFields: ['ftd'],
        filterable: true,
        sortable: 'ftd',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reporrts.titles.PROCESSING_EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        fieldFormat: '{{ real }}',
        reportFields: ['real'],
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_WITHDRAWABLE_BALANCE',
        field: 'accountWithdrawableBalance',
        fieldName: 'accountWithdrawableBalance',
        fieldFormat: '{{ accountWithdrawableBalance | number : 2 }}',
        reportFields: ['accountWithdrawableBalance'],
        filterable: true,
        sortable: 'accountWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'amount',
        fieldName: 'amount',
        fieldFormat: '{{ amount | number : 2}}',
        reportFields: ['amount'],
        filterable: true,
        sortable: 'amount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_BASIC',
        field: 'amountBasic',
        fieldName: 'amountBasic',
        fieldFormat: '{{ amountBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['amountBasic'],
        filterable: true,
        sortable: 'amountBasic',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_WITHDRAWABLE',
        field: 'amountWithdrawable',
        fieldName: 'amountWithdrawable',
        fieldFormat: '{{ amountWithdrawable | number : 2}}',
        reportFields: ['amountWithdrawable'],
        filterable: true,
        sortable: 'amountWithdrawable',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.APPROVED_BY_ACCOUNT_FIRST_NAME',
        field: 'approvedByAccountFirstName',
        fieldName: 'approvedByAccountFirstName',
        fieldFormat: '{{ approvedByAccountFirstName }}',
        reportFields: ['approvedByAccountFirstName'],
        filterable: false,
        sortable: 'approvedByAccountFirstName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.APPROVED_BY_LAST_NAME',
        field: 'approvedByLastName',
        fieldName: 'approvedByLastName',
        fieldFormat: '{{ approvedByLastName }}',
        reportFields: ['approvedByLastName'],
        filterable: false,
        sortable: 'approvedByLastName',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_BONUS',
        field: 'bonusTotal',
        fieldName: 'bonusTotal',
        fieldFormat: '{{ bonusTotal | number : 2 }}',
        reportFields: ['bonusTotal'],
        filterable: true,
        sortable: 'bonusTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        fieldFormat: '{{ createdAt }}',
        reportFields: ['createdAt'],
        filterable: true,
        sortable: 'createdAt',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_CASH_PNL',
        field: 'customerCashPnl',
        fieldName: 'customerCashPnl',
        fieldFormat: '{{ customerCashPnl | number : 2}}',
        reportFields: ['customerCashPnl'],
        filterable: true,
        sortable: 'customerCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_WITHDRAWABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        fieldFormat: '{{ customerWithdrawableBalance }}',
        reportFields: ['customerWithdrawableBalance'],
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{ department }}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.DEPOSIT_EXTERNAL_ID',
        field: 'depositExternalId',
        fieldName: 'depositExternalId',
        fieldFormat: '{{ depositExternalId }}',
        reportFields: ['depositExternalId'],
        filterable: false,
        sortable: 'depositExternalId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'portfolio.stats.TOTAL_EQUITY',
        field: 'equityTotal',
        fieldName: 'equityTotal',
        fieldFormat: '{{ equityTotal | number : 2 }}',
        reportFields: ['equityTotal'],
        filterable: true,
        sortable: 'equityTotal',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.LANGUAGE',
        field: 'reportsLanguage',
        fieldName: 'language',
        fieldFormat: '{{ language }}',
        reportFields: ['language'],
        filterable: true,
        sortable: 'language',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.PROMO_CODE_ID',
        field: 'promoCodeId',
        fieldName: 'promoCodeId',
        fieldFormat: '{{ promoCodeId > 0 ? promoCodeId : "" }}',
        reportFields: ['promoCodeId'],
        filterable: false,
        sortable: 'promoCodeId',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.REQUESTED_DATE',
        field: 'requestedAt',
        fieldName: 'requestedAt',
        fieldFormat: '{{ requestedAt }}',
        reportFields: ['requestedAt'],
        filterable: true,
        sortable: 'requestedAt',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
