import CreditCommonController from '../credits-base.controller';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

import template from './credit-deduct.html';
import { IScope } from 'angular';
import { CustomersService } from '~/source/contact/common/services/customers';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';

const DEPOSIT_SAFEGUARD_TIME = 300;

class CreditDeductController extends CreditCommonController {
  // bindings
  customer: Customer;
  account: TradingAccount;
  onDone: () => void;

  lifecycles = observeComponentLifecycles(this);

  deductAction = new rx.Subject<void>();

  amountToDeduct: number;
  note = '';

  maxAmountToDeduct: number;

  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
  ) {
    super($scope, customersService);

    useStreams([this.streamDeductCredit()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.amountToDeduct = null;
    this.maxAmountToDeduct = calcMaxAmountToDeduct(this.account);
  }

  $onDestroy() {}

  /**
   * Called before posting funds bonus
   */
  streamDeductCredit() {
    return rx.pipe(
      () => this.deductAction,
      rx.debounceTime(DEPOSIT_SAFEGUARD_TIME),
      rx.switchMap(() =>
        this.postCredit(this.customer.id, this.account.id, {
          amount: 0 - (this.amountToDeduct || 0),
          note: this.note,
        }),
      ),
      rx.tap(() => this.onDone()),
    )(null);
  }
}

function calcMaxAmountToDeduct(account: TradingAccount) {
  if (MT4_PLATFORMS.includes(account.platform.code)) {
    return account.credit;
  }

  return Math.min(account.credit, account.balance);
}

export default {
  template,
  controller: CreditDeductController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
  },
};
