import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import * as rx from '@proftit/rxjs';
import { generateUuid } from '@proftit/general-utilities';

export function initI18Next(
  translationNeededOp$: rx.Subject<{
    language: string;
    namespace: string;
    callback: Function;
  }>,
  currentLang: string,
  debug: boolean = false,
) {
  const i18n = i18next.createInstance();

  // for debugging propose. To identify that only one instance of i18n is shared
  (i18n as any).aGuid = generateUuid();

  i18n
    /*
     * initReactI18next save instance globally in the import module.
     * It could have created problems regarding instance sharing before.
     * Instead, the options given to options.react are copied from it.
     */
    // .use(initReactI18next)
    .use(
      resourcesToBackend(
        (
          language: string,
          namespace: string,
          callback: (err: Error, resources: Object) => void,
        ) => {
          translationNeededOp$.next({ language, namespace, callback });
        },
      ),
    )
    .init({
      debug,
      lng: currentLang,

      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      /*
       * The fallback option made problem regarding pending loading lang that
       * never ends for some reason. Then the t function never translate after
       * language change
       */
      fallbackLng: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        bindI18n: 'languageChanged loaded',
        bindI18nStore: '',
        // nsMode: 'fallback' // loop through all namespaces given to hook, HOC, render prop for key lookup
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
        // hashTransKey: key => key // calculate a key for Trans component based on defaultValue
        useSuspense: true,
      },
    });

  return i18n;
}
