import ng, { IHttpService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import { PlatfromGroupsService } from '../../services/platform-groups.service';
import { settings } from './settings';
import { tableColumns } from './table-columns';
import PlatformConnection from '~/source/common/models/platform-connection';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { formatJsonToDlimitedItemsString } from '../../utilities/format-json-to-delimited-items-string';
import template from './trading-core-groups-audit-log-table-popup.component.html';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { CurrentPlatformOfBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-of-brand-store-service.directive';
import { CurrentTrcGroupsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-groups-of-platform-service.directive';
import { PlatformCode, MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { TradingCoreAuditLogsApiService } from '../../services/trading-core-audit-logs.api.service';
import { switchOnEx } from '@proftit/general-utilities';
import { EntityWithCode } from '@proftit/crm.api.models.general';
const styles = require('./trading-core-groups-audit-log-table-popup.component.scss');

interface Resolve {
  brandPlatform: BrandPlatform;
}

const TABLE_KEY = 'tradingCoreGroupsAuditLogTablePopup';

export class TradingCoreGroupsAuditLogTablePopupController extends TablePopupController {
  static $inject = [
    'prfTradingCoreAuditLogsApi',
    'prfPlatformGroupsService',
    'blockUI',
    'growl',
    'growlMessages',
    '$http',
    ...TablePopupController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentPlatformOfBrand: CurrentPlatformOfBrandStoreServiceDirectiveController;

  prfCurrentTrcGroupsOfPlatform: CurrentTrcGroupsOfPlatformServiceDirectiveController;

  /* bindings */

  /* injections */

  prfTradingCoreAuditLogsApi: TradingCoreAuditLogsApiService;

  prfPlatformGroupsService: PlatfromGroupsService;

  blockUI: ng.blockUI.BlockUIService;

  growl: ng.growl.IGrowlService;

  growlMessages: ng.growl.IGrowlMessagesService;

  $http: IHttpService;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  settings = _.cloneDeep(settings);

  cols = [...tableColumns];

  platformConnection: PlatformConnection;
  platformConnection$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  ).pipe(
    rx.map((r) => _.get(['platformConnection'], r)),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((conn) => (this.platformConnection = conn)),
  );
  opInitTable$ = new rx.Subject<number>();
  dataServiceInstance$;
  groups$ = new rx.BehaviorSubject<any[]>([]);

  brandPlatform: BrandPlatform;

  /* inputs */

  resolveIn$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  brandPlatformIn$ = this.resolveIn$.pipe(
    rx.filter((a) => !_.isNil(a)),
    rx.map(({ brandPlatform }) => brandPlatform),
    shareReplayRefOne(),
  );

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams(
      [this.resolveIn$, this.brandPlatformIn$],
      this.lifecycles.onDestroy$,
    );

    useStreams(
      [this.resolveIn$, this.brandPlatformIn$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance$ = this.streamDataServiceInstance();

    useStreams(
      [
        this.steamStateCurerntPlatform(),
        this.streamLoginToSelectedPlatform().stream$,
        this.streamSyncCurrentPlatformOfBrand().stream$,
        this.dataServiceInstance$,
        this.streamInitTable(),
        this.streamGroupsBSubject(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  steamStateCurerntPlatform() {
    return rx.pipe(
      () => this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
      rx.tap((brandPlatform) => {
        this.brandPlatform = brandPlatform;
      }),
    )(null);
  }

  streamLoginToSelectedPlatform() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.switchMap((brandPlatform) => {
          if (_.isNil(brandPlatform)) {
            return this.prfCurrentPlatformSession.logout();
          }

          return this.prfCurrentPlatformSession.login(brandPlatform);
        }),
      ),
    );
  }

  streamSyncCurrentPlatformOfBrand() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.tap((brandPlatform) => {
          this.prfCurrentPlatformOfBrand.setBrandPlatform(brandPlatform);
        }),
      ),
    );
  }

  streamDataServiceInstance() {
    return rx.pipe(
      () =>
        this.prfCurrentPlatformSession.sessionS.stream$.pipe(
          rx.filter((session) => session.isLoggedIn),
        ),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        this.growlRef,
      ),
      rx.map((session) => {
        const req = this.prfTradingCoreAuditLogsApi.initPrivateReq(
          session.session.apiUrl,
          session.session.token,
        );

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        this.growlRef,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.opInitTable$,
          this.dataServiceInstance$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
          this.groups$.pipe(rx.filter((groups) => groups.length > 0)),
        ]),
      rx.tap(([rows]) => this.initTable(rows)),
    )(null);
  }

  streamGroupsBSubject() {
    return rx.pipe(
      () => this.prfCurrentTrcGroupsOfPlatform.generalGroupsS.stream$,
      rx.tap((groups) => this.groups$.next(groups as any)),
    )(null);
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings;
  }

  get tableKey() {
    return TABLE_KEY;
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @example
   * get requiredApiFilters () {
   *   return {
   *       "role.code": {
   *           exclude: "extapi"
   *       }
   *   };
   * }
   * @returns {{}}
   */
  get requiredApiFilters(): any {
    const tableName = calcTableNameForPlatform(
      this.brandPlatform.platform.code,
    );

    return {
      tableName,
      type: ['Create', 'Update', 'Delete', 'Insert'],
    };
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance.sort({ dateTime: 'desc' });
  }

  calcCfdGroupName(id: number, items: EntityWithCode[]) {
    const item = items.find((a) => a.id === id);
    if (_.isNil(item)) {
      return id;
    }
    return item.name;
  }

  /**
   * parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[]): any[] {
    data.forEach((item) => {
      item._requestBodyForPresentation = formatJsonToDlimitedItemsString(
        item.newValues,
      );

      item._groupName = switchOnEx(
        {
          [PlatformCode.Cfd]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
          [PlatformCode.Bundle]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
          [PlatformCode.Mt4]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
          [PlatformCode.Mt5]: () =>
            this.calcCfdGroupName(item.groupId, this.groups$.getValue()),
        },
        this.brandPlatform.platform.code,
      );
    });
    return data;
  }
}

function calcTableNameForPlatform(code: PlatformCode) {
  if ([PlatformCode.Cfd, PlatformCode.Bundle].includes(code)) {
    return 'Group';
  }

  if (MT4_PLATFORMS.includes(code)) {
    return 'MTCustomerGroup';
  }

  throw new Error('not implemented');
}

export const TradingCoreGroupsAuditLogTablePopupComponent = {
  template,
  controller: TradingCoreGroupsAuditLogTablePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
    prfCurrentPlatformOfBrand: '^',
    prfCurrentTrcGroupsOfPlatform: '^',
  },
};
