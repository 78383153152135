import ng from 'angular';
import moment from 'moment';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import UsersService from '~/source/management/user/services/users';
import { User } from '@proftit/crm.api.models.entities';
import ActivityLogSocketService from '../services/activity-log-socket';
import UserActivityReports from '../services/user-activity-reports';
import TokensService from '~/source/auth/services/tokens';
import UserActivityLog from '~/source/common/models/user-activity-log';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import Promise from 'bluebird';

import template from './activity-log-modal.html';

interface UserActivityLogInfoResponse {
  info: {
    channel: string;
  };
}

class Controller extends BaseController {
  static $inject = [
    'usersService',
    'dateFormat',
    'usersModuleSettings',
    'tokensService',
    'activityLogSocketService',
    'userActivityReportsService',
    'blockUI',
    ...BaseController.$inject,
  ];

  close: (value?: any) => void;
  dismiess: (value?: any) => void;

  /**
   * ui bootstrap way of sending data to component is through 'resolve' property binding.
   * Currenly no other way supported. there is open PR regarding
   * setting directly component custom bindngs but development was stoped.
   * https://github.com/austinleroy/bootstrap/commit/180d57eda8ec713f8e2b89c4cad385d7d4a994b6
   *
   * There for, we can mimic custom binding by taking the resolve bidning and separating it
   * to the different variables.
   *
   * using 'set' on specific binding is an alternative way to using $onChanges and checking the change.
   *
   * @param {object} val: custom data resloved for the component.
   * @return {void}
   */
  set resolve(val: { user: User }) {
    if (_.isNil(val)) {
      return;
    }

    this.user = val.user;
  }

  usersService: () => UsersService;
  usersModuleSettings: any;
  blockUI: ng.blockUI.BlockUIService;
  activityLogSocketService: ActivityLogSocketService;
  userActivityReportsService: UserActivityReports;
  tokensService: TokensService;

  user: User;
  usersServiceInst: UsersService;
  blockUiInstance;
  datepickerModel: any;

  constructor(...args) {
    super(...args);

    // init daterange picker model with initial data from config
    Object.assign(this, {
      usersServiceInst: this.usersService(),
      settings: this.usersModuleSettings,
      datepickerModel: this.usersModuleSettings.datepicker.initialData,
      blockUiInstance: this.blockUI.instances.get('activityLog'),
    });

    this.onLogReady = this.onLogReady.bind(this);
  }

  /**
   * fetch activity data by user id and date from/to
   */
  fetchActivityLog() {
    // Start blocking the ui
    this.blockUiInstance.start();

    this.usersServiceInst
      .setConfig({ suppressBlockUi: true, growlRef: 'activityLog' })
      .getActivityLogsResource(this.user.id)
      .filter({
        from: this.datepickerModel.startDate.unix(),
        to: this.datepickerModel.endDate.unix(),
      })
      .getOneWithQuery<IElementRestNg<UserActivityLogInfoResponse>>()
      // This request returns a channel we need to listen to and wait for the log
      .then((data) => this.waitLog(data.info.channel))
      // log is ready! get it
      .then((logFileName) => this.onLogReady(logFileName))
      .finally(() => {
        // only stop the blocking when all the requests and listeners are done
        this.blockUiInstance.stop();
      });
  }

  /**
   * Listen to "channel" to wait for the log file to be ready.
   * @param {string} channel - log file channel
   * @return {Promise} - resolved with the log file name when log is ready
   */
  waitLog(channel) {
    let logReadyFn;
    return new Promise((resolve) => {
      logReadyFn = (logFileName) => resolve(logFileName);
      this.activityLogSocketService.subscribe(channel, logReadyFn);
    }).finally(() => {
      // must use "finally" to avoid touching the resolved value (file name)
      this.activityLogSocketService.unsubscribe(channel, logReadyFn);
    });
  }

  /**
   * Called when log is ready.
   * Requests the log file from the server
   * @param {string} logFileName - log file name to fetch
   * @return {Promise} - resolved when log fetch is complete
   */
  onLogReady(logFileName) {
    return this.userActivityReportsService
      .setConfig({ suppressBlockUi: true, growlRef: 'activityLog' })
      .getOneWithQuery(logFileName)
      .then((data) => {
        this.createCsvFile(data);
      });
  }

  /**
   * create file csv contain the data about activity user
   *
   * @param {object} response
   */
  createCsvFile(response) {
    const userInfo = this.tokensService.getCachedUser();
    const format = moment().format('L');
    const logName = `${format}_${userInfo.firstName}_${userInfo.lastName}_Activity-Log`;
    const hiddenElement = document.createElement('a');

    hiddenElement.href = `data:attachment/csv,${encodeURI(
      response.replace(/"/g, "'"),
    )}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${logName}.csv`;
    hiddenElement.click();

    this.close();
  }
}

const ActivityLogModalComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<',
  },
};

export default ActivityLogModalComponent;
