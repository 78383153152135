import BaseController from '~/source/common/controllers/base';
import UsersService from '~/source/management/user/services/users';
import BrandsService from '~/source/management/brand/services/brands';
import ModelNormalizer from '~/source/common/services/model-normalizer';
import BrandDeskFormFormatter from '../services/brand-desk-form-formatter';
import { IElement } from 'restangular';
import { StateService } from '@uirouter/angularjs';
import { IPromise } from 'angular';
import { PublicSystemLogin2faStoreService } from '~/source/common/store-services/public-system-login-2fa-store.service';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Brand, CommunicationProvider } from '@proftit/crm.api.models.entities';

type UserModel = any; // todoOld add types

abstract class UserManageAbstractController extends BaseController {
  static $inject = [
    '$q',
    'modelNormalizer',
    'usersService',
    'brandsService',
    '$state',
    'managementBrandDeskFormFormatter',
    '$scope',
    'prfPublicSystemLogin2faStoreService',
  ];

  /** Injected services **/
  $state: StateService;
  $scope: angular.IScope;
  modelNormalizer: ModelNormalizer;
  usersService: () => UsersService;
  brandsService: () => BrandsService;
  brandsServiceInst: BrandsService;
  usersServiceInst: UsersService;
  managementBrandDeskFormFormatter: () => BrandDeskFormFormatter;
  brandDeskFormFormatterInst: BrandDeskFormFormatter;
  prfPublicSystemLogin2faStoreService: PublicSystemLogin2faStoreService;

  lifecycles = observeComponentLifecycles(this);

  user: UserModel;

  brands$ = this.streamBrands();

  $onInit() {
    useStreams([this.brands$], this.lifecycles.onDestroy$);

    Object.assign(this, {
      usersServiceInst: this.usersService(),
      brandsServiceInst: this.brandsService(),
      brandDeskFormFormatterInst: this.managementBrandDeskFormFormatter(),
    });

    // get user - initUserModel implemented in child classes
    this.initUserModel().then((user) => {
      // init user
      this.user = user;
    });
  }

  $onDestroy() {}

  updateVoipConfigurationsFromUserInput({
    brand,
    voipProvider,
    voipConfiguration,
  }: {
    brand: Brand;
    voipProvider: CommunicationProvider;
    voipConfiguration: any;
  }) {
    const filteredConfigurations = brand.voipConfigurations.filter(
      (vc) => vc.providerId !== voipProvider.id,
    );
    const newVoipConfiguration = {
      ...voipConfiguration,
      providerId: voipProvider.id,
    };

    brand.voipConfigurations = [
      ...filteredConfigurations,
      newVoipConfiguration,
    ];
  }

  streamBrands() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.brandsService()
              .embed(['voipCredentials', 'emailCredentials'])
              .expand('voipCredentials.voipProvider')
              .getListWithQuery(),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.map((restAngularBrands) => {
        return restAngularBrands.plain().map((b) => {
          return {
            ...b,
            voipConfigurations: b.voipConfigurations || [],
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  abstract initUserModel(): Promise<UserModel>;

  abstract queryUser(): Promise<IElement>;

  /**
   *  Called when create/update user form is valid
   */
  onUserFormValid() {
    // create/update user
    return this.queryUser().then(() =>
      this.$state.go('crm.management.permissions.users.list'),
    );
  }

  /**
   * Gets brand logo from the server
   *
   * @param {Object} brand
   * @returns {Restangular}
   */
  getBrandLogo(brand: any) {
    return this.brandsServiceInst.expand('file:logo').getOneWithQuery(brand.id);
  }

  /**
   * When Add/Update has been canceled
   */
  onCancel() {
    this.$state.go('crm.management.permissions.users.list');
  }
}

export default UserManageAbstractController;
