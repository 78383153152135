// This file was generated automatically. Tread lightly.

export enum UserFilterTypeCode {
  Bonuses = 'bonuses',
  Calls = 'calls',
  Contacts = 'contacts',
  Deposits = 'deposits',
  Portfolio = 'portfolio',
  PortfolioPositions = 'portfolioPositions',
  Positions = 'positions',
  Withdrawals = 'withdrawals',
}
