import {
  FormGroup,
  FormArray,
  AbstractControl,
} from '@proftit/ng1.reactive-forms';
import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';
import { switchOn } from '@proftit/general-utilities';
import { calcMaxScoreForQuestion } from './calc-max-score-for-question';

const ALL_SCORES_SHOULD_ACCUMULATE_TO_ONE_HUNDRED =
  'ALL_SCORES_SHOULD_ACCUMULATE_TO_ONE_HUNDRED';
const EXPECTED_AGGREGATED_SCORE = 100;

export function allScoresShouldAccumulateToOneHundredFormValidator(
  ctrl: FormArray,
) {
  return new Promise((resolve) => {
    const questionsCtrls = ctrl.controls;
    const currentAllScore = questionsCtrls.reduce((acc, questionCtrl) => {
      const quesitonMaxScore = calcMaxScoreForQuestion(
        questionCtrl.value,
        (questionCtrl as FormGroup).metaInfo.question.type,
      );
      return acc + quesitonMaxScore;
    }, 0);

    if (currentAllScore !== EXPECTED_AGGREGATED_SCORE) {
      return resolve({
        [ALL_SCORES_SHOULD_ACCUMULATE_TO_ONE_HUNDRED]: {
          currentAllScore,
          value: ctrl.value,
        },
      });
    }

    return resolve(null);
  });
}

allScoresShouldAccumulateToOneHundredFormValidator.validatorName = ALL_SCORES_SHOULD_ACCUMULATE_TO_ONE_HUNDRED;
