import Component from '~/source/common/components/dropdowns/base/component';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CommunicationType } from '~/source/common/models/communication-type';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

class Controller extends Component.controller {
  static $inject = [
    'communicationsStatusesService',
    ...Component.controller.$inject,
  ];

  lifecycles = observeComponentLifecycles(this);
  communicationType$ = new rx.BehaviorSubject<CommunicationType>(null);

  constructor(a, ...args) {
    super(a, ...args);

    useStreams(
      [
        observeCompChange(
          this.communicationType$,
          'communicationType',
          this.lifecycles.onChanges$,
        ),
        this.streamData(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  get defaultPlaceholder() {
    return 'common.SELECT_STATUS';
  }

  /**
   * Disable translations
   *
   * @returns {string} empty
   */
  get translateSource() {
    return '';
  }

  query() {
    const commType = this.communicationType$.getValue();

    const filter = _.flow(
      () => ({}),
      (f) => (commType ? { ...f, typeId: commType.id } : f),
    )();

    return this.dataServiceInst
      .filter(filter)
      .getListWithQuery()
      .then((data) => data.plain());
  }

  streamData() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(this.lifecycles.onInit$, this.communicationType$),
      rx.tap(([a, commType]) => {
        const prevModel = this.model;
        const prevSelectedValues = this.selectedValues;
        this.selectedValues = null;
        this.model = null;
        this.output = [];
        this.fetchData().then(() => {
          if (_.isNil(prevModel)) {
            return;
          }

          const temp = this._data.find(({ code }) => code === prevModel.code);

          if (!_.isNil(temp)) {
            this.model = temp;
            this.selectedValues = [temp];
          }
        });
      }),
    )(null);
  }
}

export const CommunicationsStatusesByCommTypeSelectComponent = Component.config(
  {
    controller: Controller,
    bindings: {
      communicationType: '<',
    },
  },
);
