import { switchOn } from '@proftit/general-utilities';

function getErrorHtmlGeneral(msgKey, msgVal) {
  const realMessageCode = `validation.${msgKey}`;

  return `<span 
    class="control-label has-error prf-id-error-msg" 
    translate="${realMessageCode}"
    translate-values="{ currentScore: ${msgVal.currentScore} }"
  >Invalid value</span>`;
}

export function validationMessagesConf(prfValidationMessagesProvider) {
  prfValidationMessagesProvider.setErrorHtml((msgKey, msgVal = {}) => {
    return switchOn({}, msgKey, () => getErrorHtmlGeneral(msgKey, msgVal));
  });
}

validationMessagesConf.$inject = ['prfValidationMessagesProvider'];
