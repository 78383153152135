import * as _ from '@proftit/lodash-fp';
import { CashierCode } from '@proftit/crm.api.models.enums';
import { switchOn } from '@proftit/general-utilities';

function getForGeneralCashier(deposit) {
  return _.get(['transactionTransferCreditCard', 'transactionCode'], deposit);
}

function getForPraxis(deposit) {
  return _.get(['transactionTransferCreditCard', 'confirmationCode'], deposit);
}

function getProcessGuidForTransaction(deposit, cashier) {
  return switchOn({
    [CashierCode.Praxis]: () => getForPraxis(deposit)
  }, cashier.code, () => getForGeneralCashier(deposit));
}

export { getProcessGuidForTransaction };
