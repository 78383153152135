export enum CustomerPositionStatusesCode {
  Open = 'open',
  Rollover = 'rollover',
  Canceled = 'canceled',
  Won = 'won',
  Lost = 'lost',
  Tie = 'tie',
  Sold = 'sold',
  Sync = 'sync',
  Pending = 'pending',
  ForexOpen = 'forex_open',
  ForexClose = 'forex_close',
  ForexCanceled = 'forex_canceled',
}
