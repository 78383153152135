import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import PopupService from '~/source/common/components/modal/popup.service';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import { TimeInterval } from '@proftit/constants.time';
import type { IModalInstanceService } from 'angular-ui-bootstrap';

interface PopupState {
  instance: IModalInstanceService;
  isOpen: boolean;
}

const LAST_LOGIN_POPUP_DISPLAYED_DURATION = 13 * TimeInterval.Second;

export class LastLoginNoticePopupService {
  unsub$ = new rx.Subject<void>();
  openPopupOp$ = new rx.Subject<void>();
  closePopupOp$ = new rx.Subject<void>();
  popupState$ = this.streamPopupState();

  /* @ngInject */
  constructor(
    readonly popupService: PopupService,
    readonly prfCurrentUserStore: CurrentUserStoreService,
  ) {
    useStreams([this.popupState$], this.unsub$);
  }

  openPopupComponent() {
    return this.popupService.open({
      component: 'prfLastLoginNotice',
      openedClass: 'last-login-notice-wrapper',
      shouldCloseOnRouteChange: false,
    });
  }

  streamPopupState() {
    const popupStateSubject = new rx.BehaviorSubject<PopupState>({
      instance: null,
      isOpen: false,
    });
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPopupStateFromClose(popupStateSubject),
          this.streamPopupStateFromLogin(popupStateSubject),
          this.streamPopupStateFromAfterOpen(popupStateSubject),
        ),
      rx.tap((popupState: PopupState) => popupStateSubject.next(popupState)),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateCloseFromAction(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => this.closePopupOp$,
      rx.withLatestFrom(popupState$),
      rx.filter(([op, popupState]) => popupState.instance && popupState.isOpen),
      rx.map(([op, popupState]) => {
        popupState.instance.close();

        return {
          instance: null,
          isOpen: false,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateCloseFromLogout(popupState$: rx.Observable<PopupState>) {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.filter((user) => _.isNil(user)),
      rx.withLatestFrom(popupState$),
      rx.filter(([a, popupState]) => popupState.instance && popupState.isOpen),
      rx.map(([a, popupState]) => {
        popupState.instance.close();

        return {
          instance: null,
          isOpen: false,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateFromClose(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPopupStateCloseFromAction(popupState$),
          this.streamPopupStateCloseFromLogout(popupState$),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateFromAfterOpen(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      () => popupState$,
      rx.filter((popupState) => popupState.isOpen),
      rx.delay(LAST_LOGIN_POPUP_DISPLAYED_DURATION),
      rx.tap(() => this.closePopup()),
      rx.switchMap(() => rx.obs.NEVER),
      shareReplayRefOne(),
    )(null);
  }

  streamPopupStateFromLogin(
    popupState$: rx.Observable<PopupState>,
  ): rx.Observable<PopupState> {
    return rx.pipe(
      // () => this.prfCurrentUserStore.currentLoggedUser$,
      () => this.prfCurrentUserStore.loginEvent$,
      rx.withLatestFrom(popupState$),
      rx.map(([a, popupState]) => popupState),
      rx.filter((popupState) => !popupState.isOpen),
      rx.map((popupState) => {
        const instance = this.openPopupComponent();

        return {
          instance,
          isOpen: true,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  closePopup() {
    this.closePopupOp$.next();
  }
}
