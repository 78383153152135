import * as _ from '@proftit/lodash';

export function calcMaxAggragateScoreFromAnswers(value) {
  return _.flow([
    () => _.toPairs(value.scoreData),
    (answers) =>
      answers.reduce((acc, [answerKey, answerVal]) => {
        if (!_.isNumber(answerVal.score)) {
          return acc;
        }

        return acc + answerVal.score;
      }, 0),
  ])();
}
