import * as _ from '@proftit/lodash';

const MINIMUM_PASSWORD_LENGTH = 5;
const MIN_CHAR_TYPE_FOR_PASSWORD = 2;
const lowercaseRegex = /[a-z]+/;
const uppercaseRegex = /[A-Z]+/;
const digitRegex = /\d+/;

export const returnCodes = {
  VALID: 0,
  PASSWORD_EMPTY: 1,
  PASSWORD_TOO_SHORT: 2,
  PASSWORD_MIN_CHAR_TYPES: 3,
};

export function validatePassword(password) {
  if (_.isEmpty(password)) {
    return returnCodes.PASSWORD_EMPTY;
  }

  if (password.length < MINIMUM_PASSWORD_LENGTH) {
    return returnCodes.PASSWORD_TOO_SHORT;
  }

  const validCharTypesCount = [
    lowercaseRegex.test(password),
    uppercaseRegex.test(password),
    digitRegex.test(password),
  ].reduce((acc, val) => (val ? acc + 1 : acc), 0);

  if (validCharTypesCount < MIN_CHAR_TYPE_FOR_PASSWORD) {
    return returnCodes.PASSWORD_MIN_CHAR_TYPES;
  }

  return returnCodes.VALID;
}
