import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './single-notification.component.html';
const styles = require('./single-notification.component.scss');

export class SingleNotificationController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SingleNotificationComponent = {
  template,
  controller: SingleNotificationController,
  bindings: {
    notification: '<',
    href: '<',
    removeNotification: '&',
    archiveNotification: '&',
    onClick: '&',
  },
};
