import ng from 'angular';
import { react2angular } from 'react2angular';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './react-module.css';
import { DashboardComposite } from '@proftit/crm-app-dashboard';
import { LocalConditionsComposite } from '@proftit/crm-app-customer-page';
import { ReferAFriendComposite } from '@proftit/crm-app-rewards-configuration';

import {
  VoipDidMappingComposite,
  SmsDidMappingComposite,
} from '@proftit/crm-app-did-mapping';

import {
  PromoCodeListComposite,
  PromoCodeComposite,
} from '@proftit/crm-app-management';

import { MtGroupMappingComposite } from '@proftit/crm-app-mt-group-mapping';

export const AppReactModule = ng.module('prfReact', []);

AppReactModule.component(
  'prfDashboardReact',
  react2angular(DashboardComposite, ['crmApp', 'translationsProvider']),
);

AppReactModule.component(
  'prfLocalConditionsReact',
  react2angular(LocalConditionsComposite, [
    'crmApp',
    'customerProvider',
    'translationsProvider',
  ]),
);

AppReactModule.component(
  'prfDidMapping',
  react2angular(VoipDidMappingComposite, [
    'provider',
    'region',
    'editMode',
    'updateFunc',
    'crmApp',
    'brand',
    'translationsProvider',
  ]),
);

AppReactModule.component(
  'prfSmsDidMapping',
  react2angular(SmsDidMappingComposite, [
    'provider',
    'region',
    'editMode',
    'updateFunc',
    'crmApp',
    'brand',
    'translationsProvider',
  ]),
);

AppReactModule.component(
  'prfMtGroupMapping',
  react2angular(MtGroupMappingComposite, [
    'platformConnectionId',
    'brandId',
    'translationsProvider',
    'crmApp',
  ]),
);
