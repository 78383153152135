import { IPromise } from 'restangular';
import { SystemEmailType } from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';

export class SystemEmailTypesService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'systemEmailTypes';
  }

  /**
   * Resource access type: user/customer/public.
   * Will be used to determine which api prefix to use.
   *
   * The default is defined here, but can be overriden by subclasses.
   * @returns {string}
   */
  get accessType(): string {
    return 'public';
  }

  getAll(): Promise<SystemEmailType[]> {
    return this.getListWithQuery<
      IElementRestNg<SystemEmailType>
    >().then((data) => data.plain()) as any;
  }
}
