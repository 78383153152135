import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { generateUuid, switchOn } from '@proftit/general-utilities';

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

class FormArray {
  constructor(controls, paramsP = {}) {
    this.metaInfoB$ = new rx.BehaviorSubject(null);
    this.opPush$ = new rx.Subject();
    this.opRemoveAt$ = new rx.Subject();
    this.opClear$ = new rx.Subject();
    this.opSetNewValues$ = new rx.Subject();
    this.validators$ = new rx.BehaviorSubject([]);
    this.controls$B = this.streamControls$B();
    this.value$B = this.streamValue$B();
    this.validationsResults$ = this.streamValidationsResults();
    this.isValidByChildren$ = this.streamIsValidByChildren();
    this.isValidByValidators$ = this.streamIsValidByValidators();
    this.isValid$ = this.streamIsValid();
    this.isPristine$ = this.streamIsPristine();
    this.isAtFirstValue$ = this.streamIsAtFirstValue();

    const params = _objectSpread2({
      metaInfo: null,
      validators: []
    }, paramsP);

    this.metaInfoB$.next(params.metaInfo);
    this.validators$.next(params.validators);
    this.controls$B.setValue(controls);
  }

  get value() {
    return this.value$B.getValue();
  }

  get value$() {
    return this.value$B.getObservable();
  }

  get controls() {
    return this.controls$B.getValue();
  }

  get controls$() {
    return this.controls$B.getObservable();
  }

  get metaInfo() {
    return this.metaInfoB$.getValue();
  }

  get metaInfo$() {
    return this.metaInfoB$.asObservable();
  }

  push(control) {
    this.opPush$.next(control);
  }

  removeAt(index) {
    this.opRemoveAt$.next(index);
  }

  clear() {
    this.opClear$.next();
  }

  setNewValues(controls) {
    this.opSetNewValues$.next(controls);
  }

  streamControlsFromPush(controls$) {
    return rx.pipe(() => this.opPush$, rx.withLatestFrom(controls$), rx.map(([newControl, controls]) => [...controls, newControl]), shareReplayRefOne())(null);
  }

  streamControlsFromRemoveAt(controls$) {
    return rx.pipe(() => this.opRemoveAt$, rx.withLatestFrom(controls$), rx.map(([targetIndex, controls]) => _.pullAt([targetIndex], controls)), shareReplayRefOne())(null);
  }

  streamControlsFromClear() {
    return rx.pipe(() => this.opClear$, rx.map(() => []), shareReplayRefOne())(null);
  }

  streamControlsFromNewValues() {
    return rx.pipe(() => this.opSetNewValues$, rx.map(newControls => newControls), shareReplayRefOne())(null);
  }

  streamControls$B() {
    const controls$ = new rx.BehaviorSubject([]);
    const obs$ = rx.pipe(() => rx.obs.merge(controls$.pipe(rx.distinctUntilChanged()), this.streamControlsFromPush(controls$), this.streamControlsFromRemoveAt(controls$), this.streamControlsFromClear(), this.streamControlsFromNewValues()), rx.tap(controls => controls$.next(controls)), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      setValue: val => controls$.next(val),
      getValue: () => controls$.getValue()
    };
  }

  streamValue$B() {
    const value$ = new rx.BehaviorSubject(null);
    const obs$ = rx.pipe(() => this.controls$B.getObservable(), rx.map(ctrls => ctrls.map(ctrl => ctrl.value$)), rx.switchMap(value$List => {
      if (value$List.length === 0) {
        return rx.obs.of([]);
      }

      return rx.obs.combineLatest(value$List);
    }), rx.tap(value => {
      value$.next(value);
    }), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      getValue: () => value$.getValue()
    };
  }

  streamIsValidByChildren() {
    return rx.pipe(() => this.controls$B.getObservable(), rx.map(ctrls => ctrls.map(ctrl => ctrl.isValid$)), rx.switchMap(isValid$List => rx.obs.combineLatest(isValid$List)), rx.map(isValidList => isValidList.every(x => x === true)), rx.startWith(true), shareReplayRefOne())(null);
  }

  streamValidationsResults() {
    return rx.pipe(() => this.value$B.getObservable(), rx.switchMap(() => this.validators$), rx.map(validators => validators.map(v => rx.obs.from(v(this)))), rx.switchMap(validations$List => rx.obs.combineLatest(validations$List)), rx.map(results => results.reduce((acc, r) => _objectSpread2(_objectSpread2({}, acc), r), {})), rx.startWith({}), shareReplayRefOne())(null);
  }

  streamIsValidByValidators() {
    return rx.pipe(() => this.validationsResults$, rx.map(results => _.isEmpty(results)), rx.startWith(true), shareReplayRefOne())(null);
  }

  streamIsValid() {
    return rx.pipe(() => rx.obs.combineLatest(this.isValidByChildren$, this.isValidByValidators$), rx.map(isValidList => isValidList.every(v => v === true)), shareReplayRefOne(), rx.catchError((err, caught) => {
      // eslint-disable-next-line no-console
      console.error(' array -> is valid -> error', err);
      return caught;
    }))(null);
  }

  streamIsPristine() {
    return rx.pipe(() => this.controls$B.getObservable(), rx.map(ctrls => ctrls.map(ctrl => ctrl.isPristine$)), rx.switchMap(isPristine$List => rx.obs.combineLatest(isPristine$List)), rx.map(isPristineList => isPristineList.every(x => x === true)), shareReplayRefOne())(null);
  }

  streamIsAtFirstValue() {
    return rx.pipe(() => this.controls$B.getObservable(), rx.map(ctrls => ctrls.map(ctrl => ctrl.isAtFirstValue$)), rx.switchMap(isAtFirstValue$List => rx.obs.combineLatest(isAtFirstValue$List)), rx.map(isAtFirstValueList => isAtFirstValueList.every(x => x === true)), shareReplayRefOne())(null);
  }

}

class FormControl {
  resetIsAtFirst() {
    this.resetIsAtFirstAction.next();
  }

  constructor(initialValue, paramsP) {
    this.opSetValue$ = new rx.Subject();
    this.opSetIsReadonly$ = new rx.Subject();
    this.initialValue$ = new rx.BehaviorSubject(null);
    this.initialIsReadonly$ = new rx.BehaviorSubject(null);
    this.validators$ = new rx.BehaviorSubject([]);
    this.resetIsAtFirstAction = new rx.Subject();
    this.setValueAsFirstAction = new rx.Subject();
    this.value$B = this.streamValue$B();
    this.validationsResults$ = this.streamValidationsResults();
    this.isValid$ = this.streamIsValid();
    this.isPristine$ = this.streamIsPristine();
    this.isReadonly$B = this.streamIsReadonly();
    this.isAtFirstValue$ = this.streamIsAtFirstValue();

    const params = _objectSpread2({
      validators: [],
      isReadonly: false
    }, paramsP);

    this.validators$.next(params.validators);
    this.initialValue$.next(initialValue);
    this.initialIsReadonly$.next(params.isReadonly);
  }

  get value() {
    return this.value$B.getValue();
  }

  get value$() {
    return this.value$B.getObservable();
  }

  setValue(value) {
    this.opSetValue$.next(value);
  }

  get isReadonly$() {
    return this.isReadonly$B.getObservable();
  }

  set isReadonly(value) {
    this.opSetIsReadonly$.next(value);
  }

  get model() {
    return this.value$B.getValue();
  }

  set model(val) {
    this.opSetValue$.next(val);
  }

  streamValue$B() {
    const value$ = new rx.BehaviorSubject(undefined);
    const obs$ = rx.pipe(() => rx.obs.merge(this.initialValue$.pipe(rx.takeUntil(value$.pipe(rx.filter(value => !_.isUndefined(value))))), value$.pipe(rx.distinctUntilChanged()), this.opSetValue$, this.setValueAsFirstAction), rx.tap(value => value$.next(value)), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      getValue: () => value$.getValue()
    };
  }

  streamValidationsResults() {
    return rx.pipe(() => this.value$B.getObservable(), rx.switchMap(() => this.validators$), rx.map(validators => validators.map(v => rx.obs.from(v(this)))), rx.switchMap(validations$List => rx.obs.combineLatest(validations$List)), rx.map(results => results.reduce((acc, r) => _objectSpread2(_objectSpread2({}, acc), r), {})), rx.startWith({}), shareReplayRefOne())(null);
  }

  streamIsValid() {
    return rx.pipe(() => this.validationsResults$, rx.map(results => _.isEmpty(results)), rx.startWith(true), shareReplayRefOne())(null);
  }

  streamIsPristine() {
    return rx.pipe(() => rx.obs.combineLatest(this.value$, this.initialValue$), rx.map(([value, initialValue]) => value === initialValue), rx.startWith(true), shareReplayRefOne())(null);
  }

  streamIsReadonly() {
    const isReadonly$ = new rx.BehaviorSubject(undefined);
    const obs$ = rx.pipe(() => rx.obs.merge(this.initialIsReadonly$.pipe(rx.takeUntil(isReadonly$.pipe(rx.filter(isReadonly => !_.isUndefined(isReadonly))))), isReadonly$.pipe(rx.distinctUntilChanged()), this.opSetIsReadonly$), rx.tap(isReadonly => isReadonly$.next(isReadonly)), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      getValue: () => isReadonly$.getValue(),
      setValue: v => this.opSetIsReadonly$.next(v)
    };
  }

  setValueAsFirst(value) {
    this.setValueAsFirstAction.next(value);
  }

  streamIsAtFirstValue() {
    return rx.pipe(() => rx.obs.merge(this.resetIsAtFirstAction.pipe(rx.map(() => true)), this.opSetValue$.pipe(rx.map(() => false)), this.setValueAsFirstAction.pipe(rx.map(() => true))), rx.startWith(true), shareReplayRefOne())(null);
  }

}

class FormGroup {
  constructor(controls, paramsP = {}) {
    this.metaInfoB$ = new rx.BehaviorSubject(null);
    this.opSetIsReadonly$ = new rx.Subject();
    this.initialIsReadonly$ = new rx.BehaviorSubject(null);
    this.validators$ = new rx.BehaviorSubject([]);
    this.calcValues$B = this.streamCalcValues$B();
    this.controls$B = this.streamControls$B();
    this.value$B = this.streamValue$B();
    this.validationsResults$ = this.streamValidationsResults();
    this.isValid$ = this.streamIsValid();
    this.isPristine$ = this.streamIsPristine();
    this.isReadonly$B = this.streamIsReadonly();
    this.isAtFirstValue$ = this.streamIsAtFirstValue();

    const params = _objectSpread2({
      metaInfo: null,
      validators: [],
      calcValues: {},
      isReadonly: false
    }, paramsP);

    this.metaInfoB$.next(params.metaInfo);
    this.validators$.next(params.validators);
    this.calcValues$B.setValue(params.calcValues);
    this.initialIsReadonly$.next(params.isReadonly);
    this.controls$B.setValue(controls);
  }

  get value() {
    return this.value$B.getValue();
  }

  get value$() {
    return this.value$B.getObservable();
  }

  get controls() {
    return this.controls$B.getValue();
  }

  get controls$() {
    return this.controls$B.getObservable();
  }

  get metaInfo() {
    return this.metaInfoB$.getValue();
  }

  get metaInfo$() {
    return this.metaInfoB$.asObservable();
  }

  get calcValues$() {
    return this.calcValues$B.getObservable();
  }

  get isReadonly$() {
    return this.isReadonly$B.getObservable();
  }

  set isReadonly(value) {
    this.opSetIsReadonly$.next(value);
  }
  /* eslint-disable-next-line */


  streamControls$B() {
    const controls$ = new rx.BehaviorSubject({});
    const obs$ = rx.pipe(() => rx.obs.merge(controls$.pipe(rx.distinctUntilChanged())), rx.tap(controls => controls$.next(controls)), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      setValue: val => controls$.next(val),
      getValue: () => controls$.getValue()
    };
  }

  streamCalcValues$B() {
    const calcValues$ = new rx.BehaviorSubject(null);
    const obs$ = rx.pipe(() => calcValues$.pipe(rx.distinctUntilChanged()), rx.map(calcValuesFns => {
      return _.mapValues(fn => fn(this.value$B.getObservable(), this.metaInfoB$.asObservable()), calcValuesFns);
    }), rx.map(calcValuesFns => _.mapKeys(key => `${key}$`, calcValuesFns)), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      setValue: val => calcValues$.next(val),
      getValue: () => calcValues$.getValue()
    };
  }

  streamValue$B() {
    const value$ = new rx.BehaviorSubject(null);
    const obs$ = rx.pipe(() => this.controls$B.getObservable(), rx.map(controls => _.toPairs(controls)), rx.map(ctrlPairs => ctrlPairs.map(([ctrlKey, ctrl]) => ctrl.value$.pipe(rx.map(value => ({
      value,
      key: ctrlKey
    }))))), rx.switchMap(value$List => rx.obs.combineLatest(value$List)), rx.map(values => values.reduce((acc, {
      value,
      key
    }) => {
      acc[key] = value;
      return acc;
    }, {})), rx.tap(value => {
      value$.next(value);
    }), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      getValue: () => value$.getValue()
    };
  }

  streamIsValidByChildren() {
    return rx.pipe( // return pipeLog('*** group -> is valid by children$',
    () => this.controls$B.getObservable(), rx.map(controls => _.toPairs(controls)),
    /* eslint-disable-next-line */
    rx.map(ctrlPairs => ctrlPairs.map(([ctrlKey, ctrl]) => ctrl.isValid$)), rx.switchMap(isValid$List => rx.obs.combineLatest(isValid$List)), rx.map(isValidList => isValidList.every(x => x === true)), rx.startWith(true), shareReplayRefOne())(null);
  }

  streamValidationsResults() {
    return rx.pipe( // return pipeLog('*** group -> validation results',
    () => this.value$B.getObservable(), rx.switchMap(() => this.validators$), rx.map(validators => validators.map(v => rx.obs.from(v(this)))), rx.switchMap(validations$List => rx.obs.combineLatest(validations$List)), rx.map(results => results.reduce((acc, r) => _objectSpread2(_objectSpread2({}, acc), r), {})), rx.startWith({}), shareReplayRefOne())(null);
  }

  streamIsValidByValidators() {
    return rx.pipe( // return pipeLog('*** group -> is valid by validators',
    () => this.validationsResults$, rx.map(results => _.isEmpty(results)), rx.startWith(true), shareReplayRefOne())(null);
  }

  streamIsValid() {
    return rx.pipe(() => rx.obs.combineLatest(this.streamIsValidByChildren(), this.streamIsValidByValidators()), rx.map(isValidList => isValidList.every(v => v === true)), shareReplayRefOne())(null);
  }

  streamIsPristine() {
    return rx.pipe(() => this.controls$B.getObservable(), rx.map(controls => _.toPairs(controls)),
    /* eslint-disable-next-line */
    rx.map(ctrlPairs => ctrlPairs.map(([ctrlKey, ctrl]) => ctrl.isPristine$)), rx.switchMap(isPristine$List => rx.obs.combineLatest(isPristine$List)), rx.map(isPristineList => isPristineList.every(x => x === true)), shareReplayRefOne())(null);
  }

  streamIsReadonly() {
    const isReadonly$ = new rx.BehaviorSubject(undefined);
    const obs$ = rx.pipe(() => rx.obs.merge(this.initialIsReadonly$.pipe(rx.takeUntil(isReadonly$.pipe(rx.filter(isReadonly => !_.isUndefined(isReadonly))))), isReadonly$.pipe(rx.distinctUntilChanged()), this.opSetIsReadonly$), rx.tap(isReadonly => isReadonly$.next(isReadonly)), shareReplayRefOne())(null);
    return {
      getObservable: () => obs$,
      getValue: () => isReadonly$.getValue(),
      setValue: v => this.opSetIsReadonly$.next(v)
    };
  }

  streamIsAtFirstValue() {
    return rx.pipe(() => this.controls$B.getObservable(), rx.map(controls => _.toPairs(controls)),
    /* eslint-disable-next-line */
    rx.map(ctrlPairs => ctrlPairs.map(([ctrlKey, ctrl]) => ctrl.isAtFirstValue$)), rx.switchMap(isAtFirstValue$List => rx.obs.combineLatest(isAtFirstValue$List)), rx.map(isAtFirstValueList => isAtFirstValueList.every(x => x === true)), shareReplayRefOne())(null);
  }

}

const directiveName$1 = 'prfFormControl';
const formControlDirective = () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModelCtrl) => {
      const unsub$ = new rx.Subject();
      const formControl$ = new rx.BehaviorSubject(null);

      function streamActionSetValidity() {
        return rx.pipe(() => formControl$, rx.switchMap(ctrl => ctrl ? ctrl.isValid$ : rx.obs.EMPTY), rx.distinctUntilChanged(), rx.tap(isValid => {
          ngModelCtrl.$setValidity(ngModelCtrl.$name, isValid);
        }))(null);
      }

      useStreams([streamActionSetValidity()], unsub$);
      scope.$watch(attrs[directiveName$1], newVal => {
        formControl$.next(newVal);
      });
      scope.$on('$destroy', () => {
        unsub$.next();
        unsub$.complete();
      });
    }
  };
};
formControlDirective.$inject = [];
formControlDirective.directiveName = directiveName$1;

const directiveName = 'prfValidationMessage';
const MESSAGE_PLACEMENT_ATTRIBUTE = 'prfValidationMessagePlacement';
var MessagePlacement;

(function (MessagePlacement) {
  MessagePlacement["After"] = "after";
  MessagePlacement["Append"] = "append";
})(MessagePlacement || (MessagePlacement = {}));

const validationMessageDirective = ($compile, prfValidationMessages) => {
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const unsub$ = new rx.Subject();
      const formControl$ = new rx.BehaviorSubject(null);
      const opMessagePlacement$ = new rx.Subject();

      function streamMessagePlacement() {
        return rx.pipe(() => opMessagePlacement$, rx.map(messagePlacement => _.defaultTo(MessagePlacement.After, messagePlacement)), rx.startWith(MessagePlacement.After), rx.distinctUntilChanged(), shareReplayRefOne())(null);
      }

      const messagePlacement$ = streamMessagePlacement();
      const containerId = generateUuid();

      function getCreateMessageElementAfter() {
        let el = element.next(`#${containerId}`);

        if (el.length === 0) {
          element.after(`<span id="${containerId}"></span>`);
          el = element.next(`#${containerId}`);
        }

        return el;
      }

      function getCreateMessageElementAppend() {
        let el = element.find(`#${containerId}`);

        if (el.length === 0) {
          element.append(`<span id="${containerId}"></span>`);
          el = element.find(`#${containerId}`);
        }

        return el;
      }

      function getCreateMessageElement(messagePlacement) {
        return switchOn({
          [MessagePlacement.After]: () => getCreateMessageElementAfter(),
          [MessagePlacement.Append]: () => getCreateMessageElementAppend()
        }, messagePlacement, () => {
          throw new Error('unimplemented message placement');
        });
      }

      function hideErrorMessage(messagePlacement) {
        const msgEl = getCreateMessageElement(messagePlacement);
        msgEl.css('display', 'none');
      }

      function displayErrorMessage(messageKey, messageVal, messagePlacement) {
        const msgEl = getCreateMessageElement(messagePlacement);
        const errorEl = $compile(prfValidationMessages.getErrorHtml(messageKey, messageVal, element, attrs))(scope);
        msgEl.html('').append(errorEl);
        msgEl.css('display', '');
      }

      function streamActionDisplayMessage() {
        return rx.pipe(() => rx.obs.combineLatest(formControl$, messagePlacement$), rx.switchMap(([ctrl]) => {
          if (_.isNil(ctrl)) {
            return rx.obs.from([[true, {}]]);
          }

          return rx.obs.combineLatest(ctrl.isValid$, ctrl.validationsResults$);
        }), rx.distinctUntilChanged(), rx.withLatestFrom(messagePlacement$), rx.tap(([[isValid, validationsResults], messagePlacement]) => {
          const resultsPairs = _.toPairs(validationsResults);

          if (isValid || resultsPairs.length === 0) {
            hideErrorMessage(messagePlacement);
            return;
          }

          const firstError = resultsPairs[0];
          displayErrorMessage(firstError[0], firstError[1], messagePlacement);
        }))(null);
      }

      useStreams([streamActionDisplayMessage()], unsub$);
      scope.$watch(attrs[directiveName], newVal => {
        formControl$.next(newVal);
      });
      scope.$watch(attrs[MESSAGE_PLACEMENT_ATTRIBUTE], newVal => {
        opMessagePlacement$.next(newVal);
      });
      scope.$on('$destroy', () => {
        unsub$.next();
        unsub$.complete();
      });
    }
  };
};
validationMessageDirective.$inject = ['$compile', 'prfValidationMessages'];
validationMessageDirective.directiveName = directiveName;

function defaultErrorHtml(message) {
  return `<p class="validation-invalid">${message}</p>`;
}

function validationMessagesProvider() {
  const service = {
    getErrorHtml: defaultErrorHtml
  };

  this.setErrorHtml = fn => {
    service.getErrorHtml = fn;
  };

  this.$get = [() => {
    return service;
  }];
}

function defineNg1ReactiveFormsModule(angular) {
  const Ng1ReactiveFormsModule = angular.module('prfNg1ReactiveForms', []);
  Ng1ReactiveFormsModule.provider('prfValidationMessages', validationMessagesProvider).directive(validationMessageDirective.directiveName, validationMessageDirective).directive(formControlDirective.directiveName, formControlDirective);
  return Ng1ReactiveFormsModule;
}

export { FormArray, FormControl, FormGroup, defineNg1ReactiveFormsModule };
