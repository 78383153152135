import ng from 'angular';
import { currentBrandStoreServiceDirective } from './current-brand-store-service.directive';
import { currentGroupOfBrandPlatformStoreServiceDirective } from './current-group-of-brand-platform-store-service.directive';
import { currentMt4AssetOfGroupStoreServiceDirective } from './current-mt4-asset-of-group-store-service.directive';
import { currentMt4SecurityOfGroupStoreServiceDirective } from './current-mt4-security-of-group-store-service.directive';
import { currentPlatformOfBrandStoreServiceDirective } from './current-platform-of-brand-store-service.directive';
import { currentPlatformSessionStoreServiceDirective } from './current-platform-session-store-service.directive';
import { currentTrcGroupsOfPlatformServiceDirective } from './current-trc-groups-of-platform-service.directive';
import { currentUserSettingsRiskAssetManagerServiceDirective } from './current-user-settings-risk-asset-manager-service.directive';
import { currentTrcAssetsOfPlatformServiceDirective } from './current-trc-assets-of-platform-service.directive';
import { currentUserSettingsRiskSecuritiesManagerServiceDirective } from './current-user-settings-risk-securities-manager-service.directive';
import { currentUserSettingsRiskGroupsManagerServiceDirective } from './current-user-settings-risk-groups-manager-service.directive';
import { currentUserSettingsRiskBrandsManagerServiceDirective } from './current-user-settings-risk-brands-manager-service.directive';
import { currentTrcStreamerOfBrandPlatformServiceDirective } from './current-trc-steamer-of-brand-platform-service.directive';
import { currentTrcMtSecuritiesOfPlatformServiceDirective } from './current-trc-mt-securities-of-platform-service.directive';
import { currentDepartmentsStoreServiceDirective } from './current-departments-store-service.directive';
import { symbolCategoryStoreServiceDirective } from './symbol-category-store-service.directive';
import { pipDigitsStoreServiceDirective } from './pip-digits-store-service.directive';
import { groupAssetsStoreServiceDirective } from './group-assets-store-service.directive';
export const ServiceDirectivesModule = ng.module('crm.serviceDirectives', []);

const md = ServiceDirectivesModule;

md.directive('prfCurrentBrand', currentBrandStoreServiceDirective);
md.directive('prfCurrentDepartments', currentDepartmentsStoreServiceDirective);
md.directive(
  'prfCurrentGroupOfBrandPlatform',
  currentGroupOfBrandPlatformStoreServiceDirective,
);
md.directive(
  'prfCurrentMt4AssetOfGroup',
  currentMt4AssetOfGroupStoreServiceDirective,
);
md.directive(
  'prfCurrentMt4SecurityOfGroup',
  currentMt4SecurityOfGroupStoreServiceDirective,
);
md.directive(
  'prfCurrentPlatformOfBrand',
  currentPlatformOfBrandStoreServiceDirective,
);
md.directive(
  'prfCurrentPlatformSession',
  currentPlatformSessionStoreServiceDirective,
);
md.directive(
  'prfCurrentTrcAssetsOfPlatform',
  currentTrcAssetsOfPlatformServiceDirective,
);
md.directive(
  'prfCurrentTrcMtSecuritiesOfPlatform',
  currentTrcMtSecuritiesOfPlatformServiceDirective,
);
md.directive(
  'prfCurrentTrcStreamerOfBrandPlatform',
  currentTrcStreamerOfBrandPlatformServiceDirective,
);
md.directive(
  'prfCurrentUserSettingsRiskAssetManager',
  currentUserSettingsRiskAssetManagerServiceDirective,
);
md.directive(
  'prfCurrentUserSettingsRiskBrandsManager',
  currentUserSettingsRiskBrandsManagerServiceDirective,
);
md.directive(
  'prfCurrentUserSettingsRiskGroupsManager',
  currentUserSettingsRiskGroupsManagerServiceDirective,
);
md.directive(
  'prfCurrentUserSettingsRiskSecuritiesManager',
  currentUserSettingsRiskSecuritiesManagerServiceDirective,
);
md.directive(
  'prfCurrentTrcGroupsOfPlatform',
  currentTrcGroupsOfPlatformServiceDirective,
);
md.directive('prfSymbolCategory', symbolCategoryStoreServiceDirective);
md.directive('prfPipDigits', pipDigitsStoreServiceDirective);
md.directive('prfGroupAssets', groupAssetsStoreServiceDirective);
