import * as _ from '@proftit/lodash';

import { UserEmailsService } from '~/source/common/services/user-emails.service';
import { EmailSearchService } from '~/source/common/services/email-search.service';
import { CustomerCommunicationStatusCode } from '@proftit/crm.api.models.enums';
import RestService from '~/source/common/services/rest';

export class EmailCenterService extends RestService {
  static $inject = [
    ...RestService.$inject,
    'userEmailsService',
    'prfEmailSearchService',
  ];

  userEmailsService: UserEmailsService;
  prfEmailSearchService: EmailSearchService;

  get resource() {
    return '';
  }

  getEmails(
    commStatusCode: CustomerCommunicationStatusCode,
    searchString: string,
    userEmail: string,
    customerId: number,
    pageToken: string,
  ) {
    if (
      _.isNil(userEmail) ||
      _.isNil(searchString) ||
      _.isEmpty(searchString)
    ) {
      return this.getAllEmails(commStatusCode);
    }
    return this.prfEmailSearchService.getSearchEmails(
      commStatusCode,
      searchString,
      userEmail,
      customerId,
      pageToken,
    );
  }

  private getAllEmails(commStatusCode: CustomerCommunicationStatusCode) {
    return this.userEmailsService
      .expand(['communicationStatus', 'emailProvider', 'customer'])
      .filter('communicationStatus.code', commStatusCode)
      .sort({ date: 'desc' });
  }
}
