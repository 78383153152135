import * as _ from '@proftit/lodash';
import type { ObjectProxyHandler } from '../object-proxy-handler';

const FIELD_IS_VALID = 'FIELD_IS_VALID';
const ALL_FIELDS_ARE_VALID = 'FIELDS_NOT_VALID';

export function allFieldsAreValidProxyValidator<T>(
  context: ObjectProxyHandler<T>,
) {
  const childrenResults = Object.keys(context.fields).reduce(
    (results, fieldName) => {
      const field = context.fields[fieldName];
      if (field.getLeaf().isValid) {
        return results;
      }

      results.push({
        code: FIELD_IS_VALID,
        isValid: false,
        payload: field.getLeaf().validationResults,
      });

      return results;
    },
    [],
  );

  if (_.isEmpty(childrenResults)) {
    return {
      code: ALL_FIELDS_ARE_VALID,
      isValid: true,
    };
  }

  return {
    code: ALL_FIELDS_ARE_VALID,
    isValid: false,
    payload: {
      results: childrenResults,
    },
  };
}
