import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
// import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

import {
  useStreams,
  syncTwoWay,
  shareReplayRefOne,
} from '@proftit/rxjs.adjunct';
import { Brand, BrandPlatform } from '@proftit/crm.api.models.entities';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { ExcludeBy } from '~/source/common/components/dropdowns/base/component';
import { BrandsService } from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { CurrentBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-brand-store-service.directive';
import { CurrentGroupOfBrandPlatformStoreServiceDirectiveController } from '~/source/common/service-directives/current-group-of-brand-platform-store-service.directive';
import { CurrentPlatformOfBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-of-brand-store-service.directive';
import { BrandsStoreService } from '~/source/common/store-services/brands-store.service';
import template from './cfd-platform-brand-assets-manager.component.html';
import { CurrentTrcGroupsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-groups-of-platform-service.directive';
import { CurrentUserSettingsRiskAssetManagerServiceDirectiveController } from '~/source/common/service-directives/current-user-settings-risk-asset-manager-service.directive';
import { Mt4Group, CfdGroup } from '@proftit/tradingcore.api.models.entities';
import { Entity } from '@proftit/crm.api.models.general';
import { applyUserSettingsBrandPlatformAndGroup } from '../../utilities/apply-user-settings-brand-platform-and-group';
import { blockUI, growl } from 'angular';
import ModalService from '~/source/common/components/modal/modal.service';
import { Permissions } from '~/source/common/models/permission-structure';
const styles = require('./cfd-platform-brand-assets-manager.component.scss');

export class CfdPlatformBrandAssetsManagerController {
  /* bindings */
  Permissions = Permissions;

  prfCurrentBrand: CurrentBrandStoreServiceDirectiveController;

  prfCurrentGroupOfBrandPlatform: CurrentGroupOfBrandPlatformStoreServiceDirectiveController;

  prfCurrentTrcGroupsOfPlatform: CurrentTrcGroupsOfPlatformServiceDirectiveController;

  prfCurrentPlatformOfBrand: CurrentPlatformOfBrandStoreServiceDirectiveController;

  prfCurrentUserSettingsRiskAssetManager: CurrentUserSettingsRiskAssetManagerServiceDirectiveController;

  /* state */

  styles = styles;

  ExcludeBy = ExcludeBy;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  selectedBrandBlockUi = generateBlockuiId();

  selectedBrandPlatformBlockUi = generateBlockuiId();

  selectedGroupBlockUi = generateBlockuiId();

  growlId = generateGrowlId();

  selectedBrandF = new FormControl<Brand>(null);

  selectedBrandPlatformF = new FormControl<BrandPlatform>(null);

  selectedGroupF = new FormControl<Mt4Group | CfdGroup>(null);

  openBulkUpdatePopupOp$ = new rx.Subject<void>();

  streamOpenBulkUpdatePopup$ = this.streamOpenBulkUpdatePopup();

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };

  /* @ngInject */
  constructor(
    readonly blockUI: blockUI.BlockUIService,
    readonly growl: growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly brandsService: () => BrandsService,
    readonly popupService: PopupService,
    readonly prfBrandsStore: BrandsStoreService,
    readonly modalService: ModalService,
  ) {
    useStreams(
      [
        this.selectedBrandF.value$,
        this.selectedBrandPlatformF.value$,
        this.selectedGroupF.value$,
        this.streamOpenBulkUpdatePopup$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        syncTwoWay(
          this.prfCurrentBrand.brand$,
          (brand: Brand) =>
            this.prfCurrentBrand.loadBrand(_.get(['id'], brand)),
          this.selectedBrandF.value$,
          (val: Brand) => this.selectedBrandF.setValue(val),
          (brandA: Brand, brandB: Brand) =>
            _.get(['id'], brandA) === _.get(['id'], brandB),
        ),
        syncTwoWay(
          this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
          (val: BrandPlatform) =>
            this.prfCurrentPlatformOfBrand.setBrandPlatform(val),
          this.selectedBrandPlatformF.value$,
          (val: BrandPlatform) => this.selectedBrandPlatformF.setValue(val),
          (brandPlatformA: BrandPlatform, brandPlatformB: BrandPlatform) =>
            _.get(['id'], brandPlatformA) === _.get(['id'], brandPlatformB),
        ),
        rx.pipe(
          () => rx.obs.of(true),
          applyUserSettingsBrandPlatformAndGroup(
            this.blockUI,
            this.blockUiId,
            this.growl,
            this.growlMessages,
            this.growlId,
            this.selectedBrandF,
            this.selectedBrandPlatformF,
            this.selectedGroupF,
            this.prfCurrentUserSettingsRiskAssetManager,
            this.prfBrandsStore.brandsWithCfdMt4S.stream$,
            this.prfCurrentBrand.cfdMt4brandPlatforms$,
            this.prfCurrentTrcGroupsOfPlatform.generalGroupsS.stream$,
          ),
          rx.switchMap(() =>
            this.prfCurrentUserSettingsRiskAssetManager.syncOneWayBy(
              this.selectedBrandF.value$,
              this.selectedBrandPlatformF.value$,
              this.selectedGroupF.value$,
            ),
          ),
        )(null),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamOpenBulkUpdatePopup() {
    return rx.pipe(
      () => this.openBulkUpdatePopupOp$,
      rx.withLatestFrom(this.selectedBrandPlatformF.value$),
      rx.tap(([a, selectedBrandPlatform]) => {
        this.modalService.open({
          component: 'prfCfdPlatformBrandAssetsBulkUpdate',
          resolve: {
            brandPlatform: () => selectedBrandPlatform,
          },
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  openAuditLogTablePopup(brandPlatform: BrandPlatform) {
    this.popupService.open({
      component: 'prfTradingCoreAssetsAuditLogTablePopup',
      resolve: {
        brandPlatform: () => brandPlatform,
      },
    });
  }
}

export const CfdPlatformBrandAssetsManagerComponent = {
  template,
  controller: CfdPlatformBrandAssetsManagerController,
  require: {
    prfCurrentBrand: '^',
    prfCurrentPlatformOfBrand: '^',
    prfCurrentTrcGroupsOfPlatform: '^',
    prfCurrentGroupOfBrandPlatform: '^',
    prfCurrentUserSettingsRiskAssetManager: '^',
  },
};
