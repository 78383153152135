/**
 * calculate how many table rows can fit into a container. then init provided table init function.
 *
 * this directive helps when using infinite scroll, cause not loading enough results on first load will prevent
 * infinite scrolling from loading more results.
 *
 * when calculated value is ready init table bounded function will be invoked
 *
 * Usage:
 *
 * <any prf-init-infinite-table="vm.initTable(rows)">
 * </any>
 *
 */
import angular from 'angular';

function initInfiniteTableDirective($timeout) {
  const ROW_HEIGHT = 37;

  return {
    restrict: 'A',
    scope: {
      prfInitInfiniteTable: '&',
    },
    link(scope, element) {
      /*
       * calculate the number of rows to load in an infinite table and
       * pass the value to the callback (prfInitInfiniteTable)
       */
      function onElementHeightReady() {
        const containerHeight = element.innerHeight();
        const calculateNumOfRows = parseInt(
          (containerHeight / ROW_HEIGHT).toString(),
          10,
        );

        scope.prfInitInfiniteTable({ rows: calculateNumOfRows });
      }

      /**
       * we need to make sure the container height has already been set:
       * if this is the first time the page loads: wait for window load event, to make sure css rules have been applied.
       * otherwise - just wait for DOM to finish rendering (using $timeout)
       */
      if (document.readyState !== 'complete') {
        angular.element(window).bind('load', onElementHeightReady);
      } else {
        $timeout(onElementHeightReady);
      }
    },
  };
}

initInfiniteTableDirective.$inject = ['$timeout'];

export default initInfiniteTableDirective;
