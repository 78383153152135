import angular from 'angular';
import type { StateService } from '@uirouter/angularjs';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';
import BaseController from '~/source/common/controllers/base';
import { TokensService } from '~/source/auth/services/tokens';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';

const styles = require('./main-menu.scss');

import template from './main-menu.html';
import { PopupService } from '../modal/popup.service';
import { PfTasksListPopupService } from '../../services/pf-tasks-list-popup.service';

class Controller {
  styles = styles;
  available = true;
  permissionNormalized = PermissionNormalized;

  Permissions = Permissions;

  lifecycles = observeComponentLifecycles(this);
  isMarketingAllowed: boolean;

  hasMarketingPermissions$ = this.streamHasMarketingPermissions();

  /*@ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly Idle: angular.idle.IIdleService,
    readonly $state: StateService,
    readonly featuresFlags: FeaturesFlagsService,
    readonly popupService: PopupService,
    readonly pfTasksListPopupService: PfTasksListPopupService,
    readonly PermPermissionStore: angular.permission.PermissionStore,
  ) {
    useStreams([this.hasMarketingPermissions$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  toggleState() {
    // toggle state
    this.available = !this.available;
    // todoOld: implement functionality
  }

  streamHasMarketingPermissions() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          checkCrudPermission(
            PermissionNormalized.Marketing,
            this.PermPermissionStore,
          ),
          checkCrudPermission(
            PermissionNormalized.MarketingCampaigns,
            this.PermPermissionStore,
          ),
        ),
      rx.map(
        ([marketingPermission, marketingCampaignsPermission]) =>
          marketingPermission.isView && marketingCampaignsPermission.isView,
      ),
      shareReplayRefOne(),
    )(null);
  }

  openTaskListPopup() {
    this.pfTasksListPopupService.open();
  }
}

export const MainMenuComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};
