import angular from 'angular';
import WithdrawalConfirmModule from './confirm/module';
import AddWithdrawalPopupComponent from './add-withdrawal-request-popup/add-withdrawal-request-popup.component';
import WithdrawalStatusUpdatePopupController from './withdrawal-status-update-popup.controller';
import WithdrawalTableComponent from './withdrawal-table/withdrawal-table.component';
import WithdrawalRequestDetailsComponent from './withdrawal-request-details/withdrawal-request-details.component';
import WithdrawalRequestDefaultDetailsComponent from './withdrawal-request-details/withdrawal-request-default-details/withdrawal-request-default-details.component';
import WithdrawalRequestClosedDetailsComponent from './withdrawal-request-details/withdrawal-request-closed-details.component';
import WithdrawalRequestCanceledDetailsComponent from './withdrawal-request-details/withdrawal-request-canceled-details.component';
import { WithdrawalTransferDetailsComponent } from './withdrawal-transfer-details/withdrawal-transfer-details.component';
import RelatedWithdrawalsComponent from './related-withdrawals/related-withdrawals.component';
import WithdrawalTablePopupComponent from './withdrawal-table-popup/withdrawal-table-popup';
import { DepositSelectionComponent } from '~/source/contact/contact-page/trading-account/common/withdrawal/deposit-selection/deposit-selection.component';
import { prfWithdrawalDepositLinkServiceDirective } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-deposit-link-service-directive';

export default angular
  .module('crm.contact.tradingAccount.withdrawal', [WithdrawalConfirmModule])
  .component('prfWithdrawalsTable', WithdrawalTableComponent)
  .component('prfWithdrawalRequestDetails', WithdrawalRequestDetailsComponent)
  .component(
    'prfWithdrawalRequestDefaultDetails',
    WithdrawalRequestDefaultDetailsComponent,
  )
  .component(
    'prfWithdrawalRequestClosedDetails',
    WithdrawalRequestClosedDetailsComponent,
  )
  .component(
    'prfWithdrawalRequestCanceledDetails',
    WithdrawalRequestCanceledDetailsComponent,
  )
  .component('prfRelatedWithdrawals', RelatedWithdrawalsComponent)
  .component('prfWithdrawalTransferDetails', WithdrawalTransferDetailsComponent)
  .component('prfAddWithdrawalPopupComponent', AddWithdrawalPopupComponent)
  .component('prfWithdrawalTablePopup', WithdrawalTablePopupComponent)
  .component('prfDepositSelection', DepositSelectionComponent)
  .directive(
    'prfWithdrawalDepositLink',
    prfWithdrawalDepositLinkServiceDirective,
  )
  .controller(
    'WithdrawalStatusUpdatePopupController',
    WithdrawalStatusUpdatePopupController,
  ).name;
