import BaseService from '~/source/common/services/baseService';
import log from 'loglevel';
import { IIntervalService, IPromise } from 'angular';

class ClockService extends BaseService {
  _offset = 0;
  _interval: any;
  time: number;

  static $inject = ['$interval'];

  constructor(readonly $interval: IIntervalService) {
    super();
    // Initiate the clock
    this.startTicking();
  }

  /**
   * Called when receiving a server response which contains a "Date" header.
   * If the new server time is different than what this class already has set, it will override it.
   *
   * @param {string} newDate date in RFC 1123 date format (e.g. 15 Nov 1994 08:12:31 GMT)
   * @return {void}
   */
  onServerDateChange(newDate: string) {
    const serverDateMs = new Date(newDate).valueOf();

    if (serverDateMs !== this.time) {
      log.debug('New server time: %s', newDate);
      this.syncTo(serverDateMs);
    }
  }

  /**
   * Sync the clock to the given time:
   * Reset old clock and starts ticking with new time.
   *
   * @param {number} time - unix timestamp
   * @return {void}
   */
  syncTo(time: number) {
    if (this._interval) {
      // If already running, clear it first
      this.stopTicking();
    }
    // Set offset between server time and user system time
    this._offset = time - new Date().getTime();
    // Start ticking!
    this.startTicking(time);
  }

  /**
   * Starts the clock: updates the time every second
   * If no time is passed- uses "now"
   * @param {number} [time] - time to set to (in utc epoch). Uses current time if no value is passed.
   * @return {void}
   */
  startTicking(time?: number) {
    this.time = time || new Date().getTime();

    this._interval = this.$interval(this.tick.bind(this), 1000);
  }

  /**
   * Updates the clock to current user time + offset from server
   * @return {void}
   */
  tick() {
    this.time = new Date().getTime() + this._offset;
  }

  /**
   * Stops the clock: clear the interval
   * @return {void}
   */
  stopTicking() {
    this.$interval.cancel(this._interval);
  }

  /**
   * Returns current clock time
   * @return {number} unix-epoch time
   */
  getTime() {
    return this.time;
  }
}

export default ClockService;
