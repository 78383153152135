import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import { Credit } from '@proftit/crm.api.models.entities';

class CreditsBaseController extends BaseController {
  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
  ) {
    super();
  }

  /**
   *  Post credits positions quantity
   *
   * @param {number} customerId - customer id
   * @param {number} accountId - account id
   * @param {number} qty - credit amount to add/deduct
   *
   * @returns {Promise} - resolves to new credits
   */
  postCredit(customerId, accountId, creditData: Credit) {
    // create new credit request (add/deduct)
    return this.customersService()
      .setConfig({
        blockUiRef: 'creditUpdateForm',
        growlRef: 'creditUpdateForm',
      })
      .getCreditsResource(customerId, accountId)
      .postWithQuery(creditData)
      .then((credit) => {
        this.$scope.$emit(this.reloadTableEvent);
        return credit;
      });
  }

  /**
   * Name of event that will cause credit table to reload
   *
   * @returns {string}
   */
  get reloadTableEvent() {
    return 'creditsTableReload';
  }
}

export default CreditsBaseController;
