import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './exposure-navbar.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Brand, Platform } from '@proftit/crm.api.models.entities';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { Entity } from '@proftit/crm.api.models.general';
const styles = require('./exposure-navbar.component.scss');

export type ExposureTab = {
  name: string;
  code: ExposureTabCode;
};

export enum ExposureTabCode {
  Symbols = 'SymbolsExposure',
  Currency = 'CurrencyExposure',
}

export class ExposureNavbarController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  brandPlatformsToDisplay;
  selectedBrandFormControl: FormControl<Brand>;
  selectedBrandPlatformFormControl: FormControl<Platform>;
  selectedExposureTabFormControl: FormControl<ExposureTab>;

  brands$ = observeShareCompChange<Brand[]>(
    this.lifecycles.onChanges$,
    'brands',
  );
  exposureTabClickAction = new rx.Subject<ExposureTab>();

  exposureTabs$ = this.streamExposureTabs();
  selectedExposureTab$ = this.streamSelectedExposureTab();
  brandsToDisplay$ = this.streamBrandsToDisplay();

  /* @ngInject */
  constructor(readonly $translate: angular.translate.ITranslateService) {
    useStreams(
      [
        this.exposureTabs$,
        this.selectedExposureTab$,
        this.brands$,
        this.brandsToDisplay$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamBrandsToDisplay() {
    return rx.pipe(
      () => this.brands$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((brands: Brand[]) => {
        return brands.filter((brand) => {
          if (_.isNil(brand.platformConnections)) {
            return false;
          }
          const doesBrandHaveOnlyMT4Connections = brand.platformConnections.every(
            (connection) => MT4_PLATFORMS.includes(connection.platform.code),
          );
          return !doesBrandHaveOnlyMT4Connections;
        });
      }),
      rx.map((brands: Brand[]) => {
        return brands.map((brand) => {
          return {
            ...brand,
            label: brand.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamExposureTabs(): rx.Observable<ExposureTab[]> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        return [
          {
            name: this.$translate.instant('exposure.SYMBOLS_EXPOSURE'),
            code: ExposureTabCode.Symbols,
          },
          {
            name: this.$translate.instant('exposure.CURRENCY_EXPOSURE'),
            code: ExposureTabCode.Currency,
          },
        ];
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedExposureTab() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSelectedExposureTabFromInit(),
          this.streamSelectedExposureTabFromClick(),
        ),
      rx.tap((selectedTab: ExposureTab) => {
        this.selectedExposureTabFormControl.setValue(selectedTab);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedExposureTabFromInit() {
    return rx.pipe(
      () => this.exposureTabs$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((tabs) => tabs[0]),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedExposureTabFromClick() {
    return rx.pipe(
      () => this.exposureTabClickAction,
      shareReplayRefOne(),
    )(null);
  }

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ExposureNavbarComponent = {
  template,
  controller: ExposureNavbarController,
  bindings: {
    brands: '<',
    brandPlatformsToDisplay: '<',
    selectedBrandFormControl: '<',
    selectedBrandPlatformFormControl: '<',
    selectedExposureTabFormControl: '<',
  },
};
