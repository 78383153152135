import template from './user-report-generation-download-list.html';
import TokensService from '~/source/auth/services/tokens';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { DownloadStatus } from '~/source/common/models/download-status';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import {
  userKibiExportNewChannelName,
  userCrmExportNewChannelName,
  userBalanceLogExportNewChannelName,
} from '@proftit/crm.api.channels';
import { CrmDownloadsSocketService } from '../crm-downloads-socket.service';
import { CrmDownloadsService } from '../crm-downloads.service';
import { DownloadSource } from '../download-source';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { KibiDownloadsService } from '../kibi-downloads.service';
import log from 'loglevel';
import { Download } from '~/source/common/models/download';
import { BalanceLogDownloadsService } from '~/source/reports/balance-log-downloads.service';

class Controller {
  draggable: any;

  lifecycles = observeComponentLifecycles(this);
  opDownloadDone$ = new rx.Subject<Download>();
  user$ = this.streamUser();
  newDownloadsExists$ = this.streamNewDownloadsExists();
  downloadsList$ = this.streamDownloadsList();
  downloadPopupClosing$ = this.streamDownloadPopupClosing();
  draggableSettings$ = this.streamDraggableSettings();

  /* @ngInject */
  constructor(
    readonly kibiDownloadsService: KibiDownloadsService,
    readonly crmDownloadsService: CrmDownloadsService,
    readonly balanceLogDownloadsService: () => BalanceLogDownloadsService,
    readonly downloadsPopupService,
    readonly kibiDownloadSocketService,
    readonly crmDownloadsSocketService: CrmDownloadsSocketService,
    readonly tokensService: TokensService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [this.downloadPopupClosing$, this.draggableSettings$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamUser() {
    return rx.pipe(
      () => rx.obs.from([this.tokensService.getCachedUser()]),
      shareReplayRefOne<UserTokenModel>(),
    )(null);
  }

  streamNewKibiDownloadsFromSocket() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ReportingKibiExport,
          this.PermPermissionStore,
        ),
      rx.filter((permissionCrud: any) => permissionCrud.isView),
      rx.switchMap(() => this.user$),
      rx.switchMap((user: UserTokenModel) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userKibiExportNewChannelName(user.id);
        return observeChannel(this.kibiDownloadSocketService, channelName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewCrmDownloadsFromSocket() {
    return rx.pipe(
      () => this.user$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userCrmExportNewChannelName(user.id);
        return observeChannel(this.crmDownloadsSocketService, channelName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewBalanceLogDownloadsFromSocket() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.BalanceLogExport,
          this.PermPermissionStore,
        ),
      rx.filter((permissionCrud: any) => permissionCrud.isView),
      rx.switchMap(() => this.user$),
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userBalanceLogExportNewChannelName(user.id);
        return observeChannel(this.crmDownloadsSocketService, channelName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewDownloadsExists() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamNewKibiDownloadsFromSocket().pipe(rx.map(() => true)),
          this.streamNewCrmDownloadsFromSocket().pipe(rx.map(() => true)),
          this.streamNewBalanceLogDownloadsFromSocket().pipe(
            rx.map(() => true),
          ),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveKibiDownloadsFromReq() {
    return rx.pipe(
      () =>
        rx.obs.from(
          this.kibiDownloadsService
            .filter({ status: DownloadStatus.Active })
            .setConfig({
              blockUiRef: 'downloadsTableBlockUi',
              growlRef: 'downloadsTable',
            })
            .getListWithQuery<IElementRestNg<any>>(),
        ),
      rx.map((data) => data.plain()),
      rx.catchError((err) => {
        log.error('error fetching kib downloads', err);
        return rx.obs.from([[]]);
      }),
    )(null);
  }

  streamActiveCrmDownloadsFromReq() {
    return rx.pipe(
      () =>
        rx.obs.from(
          this.crmDownloadsService
            .filter({ status: DownloadStatus.Active })
            .setConfig({
              blockUiRef: 'downloadsTableBlockUi',
              growlRef: 'downloadsTable',
            })
            .getListWithQuery(),
        ),
      rx.map((data) => data.plain()),
      rx.catchError((err) => {
        log.error('error fetching crm downloads', err);
        return rx.obs.from([[]]);
      }),
    )(null);
  }

  streamActiveBalanceLogDownloadsFromReq() {
    return rx.pipe(
      () =>
        rx.obs.from(
          this.balanceLogDownloadsService()
            .filter({ status: DownloadStatus.Active })
            .setConfig({
              blockUiRef: 'downloadsTableBlockUi',
              growlRef: 'downloadsTable',
            })
            .getListWithQuery(),
        ),
      rx.map((data) => data.plain()),
      rx.catchError((err) => {
        log.error('error fetching balance log downloads', err);
        return rx.obs.from([[]]);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDownloadsList() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.newDownloadsExists$,
          this.opDownloadDone$,
          this.lifecycles.onInit$,
        ),
      rx.switchMap(() =>
        rx.obs.forkJoin({
          kibi: this.streamActiveKibiDownloadsFromReq().pipe(
            rx.map((downloads) =>
              downloads.map((download) => ({
                download,
                downloadSource: DownloadSource.Kibi,
              })),
            ),
          ),
          crm: this.streamActiveCrmDownloadsFromReq().pipe(
            rx.map((downloads) =>
              downloads.map((download) => ({
                download,
                downloadSource: DownloadSource.CrmExport,
              })),
            ),
          ),
          balanceLog: this.streamActiveBalanceLogDownloadsFromReq().pipe(
            rx.map((downloads) =>
              downloads.map((download) => ({
                download,
                downloadSource: DownloadSource.BalanceLogExport,
              })),
            ),
          ),
        }),
      ),
      rx.map(({ kibi, crm, balanceLog }) => [...kibi, ...crm, ...balanceLog]),
      shareReplayRefOne(),
    )(null);
  }

  streamDraggableSettings() {
    return rx.pipe(
      () => this.downloadsList$,
      rx.tap((list) => {
        this.draggable.setIsOffsetType(true);
      }),
    )(null);
  }

  streamDownloadPopupClosing() {
    return rx.pipe(
      () => this.downloadsList$,
      rx.filter((list) => list.length === 0),
      rx.tap(() => {
        this.downloadsPopupService.closePopup();
      }),
    )(null);
  }
}

const UserReportGenerationDownloadListComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  require: {
    draggable: '^prfDraggble',
  },
};

export default UserReportGenerationDownloadListComponent;
