import template from './communications-dashboard.component.html';
const styles = require('./communications-dashboard.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class CommunicationsDashboardController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const CommunicationsDashboardComponent = {
  template,
  controller: CommunicationsDashboardController,
};
