import { TotalsTile } from '../common/totals-tile';

export const binaryAccountTotalsConfig: TotalsTile[] = [
  {
    primary: {
      title: 'contact.tradingAccount.BALANCE',
      field: 'balance',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.DEPOSITS',
      field: 'deposit',
      type: 'monetary',
      fractionSize: 2,
    },
    secondary: {
      title: 'contact.tradingAccount.NET',
      field: 'netDeposit',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.BONUS',
      field: 'bonus',
      type: 'monetary',
    },
    secondary: {
      title: 'contact.tradingAccount.WITHDRAWABLE',
      field: 'withdrawableBonusesSum',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.RISK_FREE',
      field: 'riskFreeAvailable',
      type: 'number',
    },
    secondary: {
      title: 'contact.tradingAccount.RISK_FREE_USED',
      field: 'riskFreeUsed',
      type: 'number',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.WITHDRAWALS',
      field: 'withdrawal',
      type: 'monetary',
      fractionSize: 2,
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.TURNOVER',
      field: 'turnover',
      type: 'monetary',
    },
    secondary: {
      title: 'contact.tradingAccount.TURNOVER_GOAL',
      field: 'turnoverGoal',
      type: 'monetary',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.PNL',
      field: 'pnl',
      type: 'monetary',
      fractionSize: 2,
    },
    secondary: {
      title: 'contact.tradingAccount.WIN_RATE',
      field: 'winRate',
      type: 'percentage',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.POSITIONS',
      field: 'validPositionsCount',
      type: 'number',
    },
    secondary: {
      title: 'contact.tradingAccount.POSITIONS_OPEN',
      field: 'openPositionsCount',
      type: 'number',
    },
  },
  {
    primary: {
      title: 'contact.tradingAccount.FEES',
      field: 'fee',
      type: 'monetary',
    },
    secondary: {
      title: 'contact.tradingAccount.ROLLOVER',
      field: 'rolloverFee',
      type: 'monetary',
    },
  },
];
