import RestService from '~/source/common/services/rest';

class CashiersService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'cashiers';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }
}

export default CashiersService;
