import module from '../../index';
import angular, { IScope } from 'angular';
import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';

const maskSuffix = 'XXXX';

class PhoneMaskController extends BaseController {
  latestState;

  /*@ngInject */
  constructor(readonly $scope: IScope) {
    super();
  }

  $postLink() {
    // save latest state
    this.latestState = (this.$scope as any).model;
  }

  /**
   * called on input field keyPress
   * decides whether or not to prevent forbidden change before its happened
   *
   * @param {Object} event click event
   */
  onKeypress(event) {
    const { selectionStart, value } = event.currentTarget;
    const isAllowed = this.isCursorPositionChangeAllowed(
      value.length,
      selectionStart,
    );

    if (!isAllowed) {
      // stop change before happening
      event.preventDefault();
      event.stopPropagation();
    } else {
      // store changes
      this.latestState = value;
    }
  }

  /**
   * called on input field keyUp
   * decides whether or not to prevent forbidden change after its happened
   *
   * @param {Object} event click event
   */
  onKeyup(event, ngModel) {
    const { value } = event.currentTarget;

    if (this.isKeyUpValid(event)) {
      // store changes
      this.latestState = value;
    } else {
      // restore prev state by update element's value
      this.$scope.$apply(() => {
        /*
         * it's purpose is to propagate the value set in the view
         * (e.g. by the user's interacting with an input)
         * through the ngModel pipeline (parsers, validators) to the model (JS object)
         */
        ngModel.$setViewValue(this.latestState);
        ngModel.$render();
      });
    }
  }

  /**
   * is this keyup event valid or not
   * @param {Object} event
   * @returns {boolean} returns true when keyup event is valid, returns false otherwise
   */
  isKeyUpValid(event) {
    const { selectionStart, value } = event.currentTarget;
    const DEL = 8;
    const BACKSPACE = 46;
    const isForbiddenKey = [DEL, BACKSPACE].includes(
      event.which || event.keyCode,
    );
    const isCursorInAllowedPosition = this.isCursorPositionChangeAllowed(
      value.length,
      selectionStart,
    );

    // value can't be empty & must end with maskSuffix value
    if (!value || (_.isString(value) && !value.endsWith(maskSuffix))) {
      return false;
    }

    // user can't press on delete|backspace when cursor is on on of the last four maskSuffix chars
    if (isForbiddenKey && !isCursorInAllowedPosition) {
      return false;
    }

    return true;
  }

  /**
   * is input field change allowed based on cursor position in input field
   * @param {Number} inputLength
   * @param {Number} cursorPositionStart
   * @returns {boolean} returns true if cursor is in a valid position
   */
  isCursorPositionChangeAllowed(inputLength, cursorPositionStart) {
    const fieldLengthWithoutMask = inputLength - 4;
    return cursorPositionStart <= fieldLengthWithoutMask;
  }
}

function phoneMask() {
  return {
    restrict: 'A',
    require: ['ngModel', 'prfPhoneMask'],
    scope: {
      model: '<ngModel',
    },
    controller: PhoneMaskController,
    link: (scope, element, attrs, [ngModel, controller]) => {
      const isMasked =
        _.isString(scope.model) && scope.model.endsWith(maskSuffix);

      // if this field is not masked, stop here, there is no need to bind the events
      if (!isMasked) {
        return;
      }

      // bind events
      element.keypress('keypress', (event) => controller.onKeypress(event));
      element.keyup('keyup', (event) => controller.onKeyup(event, ngModel));
    },
  };
}

module.directive('prfPhoneMask', phoneMask);
