import * as _ from '@proftit/lodash';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import CustomersService from '~/source/contact/common/services/customers';
import { ICollection, IElement } from 'restangular';
import RestService from '~/source/common/services/rest';
import PopupService from '~/source/common/components/modal/popup.service';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

abstract class Controller extends TableLiveController {
  static $inject = [
    '$scope',
    'popupService',
    'customersService',
    'highlightEntityService',
    'tokensService',
    ...TableLiveController.$inject,
  ];

  customersService: () => CustomersService;
  pendingPositions: ICollection;
  account: IElementRestNg<TradingAccount>;
  popupService: PopupService;
  customer: IElementRestNg<Customer>;
  dataServiceInstance: CustomersService;

  abstract getDataRequest(): RestService;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      dataServiceInstance: this.customersService(),
    });

    this.initTable();
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.pendingPositions';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.pendingPositions;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.getDataRequest();
  }

  /**
   * Open the "positions log" popup
   *
   * @return {void}
   */
  openPendingPositionTablePopup() {
    const accountType = _.capitalize(this.account.type);
    this.popupService.open({
      controller: `prfPendingPositionsTable${accountType}PopupController`,
      template: tablePopupTemplate,
      scope: this.$scope,
      data: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }
}

export default Controller;
