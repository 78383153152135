import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';

export const patchIsActiveConnection = (
  connectionId: number,
  isActive: boolean,
  clearingCompanyConnectionsService: ClearingCompanyConnectionsService,
) => {
  return clearingCompanyConnectionsService
    .patchConnection(connectionId, {
      isActive,
    })
    .catch((e) => null);
};
