import {
  Controller as BaseChartController,
  config as baseChartConfig,
} from './amcharts';

export class Controller extends BaseChartController {
  /**
   * Build the options object for a serial chart, that will be passed to amcharts
   * Gets options object which will also be merged to the returned options object
   * and can be used to override settings.
   *
   * @param {object} options
   * @returns {object}
   */
  buildOptions(options) {
    return super.buildOptions(
      this.extend(
        {
          type: 'serial',
          categoryField: 'category',
          colors: [
            '#344154',
            '#edd899',
            '#c7e7e4',
            '#ff8c6f',
            '#e63f51',
            '#bac3d2',
            '#60b1cc',
            '#4a778e',
            '#6c85bd',
            '#43aea8',
          ],
          addClassNames: true,
          classNamePrefix: 'prf-amcharts__serial',
          categoryAxis: {
            gridPosition: 'start',
            tickLength: 0,
            axisAlpha: 0,
            axisThickness: 0,
            color: '#7D7D7D',
          },
        },
        options,
      ),
    );
  }
}
export default baseChartConfig({ controller: Controller });

export const config = (newConfig) =>
  baseChartConfig({ controller: Controller, ...newConfig });
