import angular from 'angular';

import AddFeePopupComponent from './add-fee-popup/add-fee-popup.component';
import FeeTableComponent from './fees-table/fees-table.component';
import FeeTablePopupComponent from './fees-table-popup/fees-table-popup.component';
import { FeeDetailsRowComponent } from './fee-details-row/fee-details-row.component';
import { FeeInactivityDetailsRowComponent } from './fee-inactivity-details-row/fee-inactivity-details-row.component';
import { FeeRolloverDetailsRowComponent } from './fee-rollover-details-row/fee-rollover-details-row.component';
import { FeeGeneralDetailsRowComponent } from './fee-general-details-row/fee-general-details-row.component';

export const FeeModule = angular.module('crm.contact.tradingAccount.fees', []);

FeeModule.component('prfFeesTable', FeeTableComponent)
  .component('addFeePopupComponent', AddFeePopupComponent)
  .component('prfFeeDetailsRow', FeeDetailsRowComponent)
  .component('prfFeeInactivityDetailsRow', FeeInactivityDetailsRowComponent)
  .component('prfFeeRolloverDetailsRow', FeeRolloverDetailsRowComponent)
  .component('prfFeeGeneralDetailsRow', FeeGeneralDetailsRowComponent)
  .component('prfFeeTablePopupComponent', FeeTablePopupComponent);
