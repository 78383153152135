import TableController from '~/source/common/components/table/table.controller';
import UsersService from '~/source/management/user/services/users';
import ModalService from '~/source/common/components/modal/modal.service';
import template from './user-list.html';
import { MenuItem } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-table-popup/withdrawal-table-popup';
import { User } from '@proftit/crm.api.models.entities';
import ng from 'angular';
import * as _ from '@proftit/lodash';

import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import TokensService from '~/source/auth/services/tokens';
import Promise from 'bluebird';
import { wrapNgPermissionValidatePromise } from '~/source/common/utilities/wrap-ng-permission-validate-promise';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import PopupService from '~/source/common/components/modal/popup.service';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';

class DataAdditional {
  menuItems: MenuItem[] = [];
  showActionWarning: boolean = false;
}

enum MenuItemRow {
  Login = 'login',
  Deactivate = 'deactivate',
  NotificationsActivation = 'notificationsActivation',
  ActivityLog = 'activityLog',
}

class Controller extends TableController {
  usersService: () => UsersService;
  usersModuleSettings: any;
  userGroup: any;
  modalService: ModalService;
  dataAdditionals: { [id: number]: DataAdditional } = {};
  dataServiceInstance: UsersService;
  settings: any;
  userSearch: string;
  normalizedFilters: any;
  users: User[];
  PermPermissionStore: ng.permission.PermissionStore;
  loginAsUser$: rx.BehaviorSubject<any> = new rx.BehaviorSubject<any>(null);
  lifecycles = observeComponentLifecycles(this);
  tokensService: TokensService;
  $state: ng.ui.IStateService;
  Idle: any;
  cachedUser: UserTokenModel;
  popupService: PopupService;
  UserRolePositionCode = UserRolePositionCode;

  static $inject = [
    '$scope',
    'filterCache',
    'filterService',
    'PermPermissionStore',
    'blockUI',
    'modalService',
    'usersService',
    'usersModuleSettings',
    'tokensService',
    '$state',
    'Idle',
    'popupService',
    ...TableController.$inject,
  ];

  constructor(...args) {
    super(...args);
    Object.assign(this, {
      dataServiceInstance: this.usersService(),
      settings: Object.assign({}, this.usersModuleSettings.usersTable),
      quickFilters: this.usersModuleSettings.usersTable.quickFilters,
      cols: [...this.usersModuleSettings.usersTable.cols],
    });

    this.cachedUser = this.tokensService.getCachedUser();
  }

  $onInit(): void {
    super.$onInit();

    /**
     * add active userGroup table filter after disabling all other filters
     * userGroup comes from the router, based on 'filterGroup' url param
     */
    if (this.userGroup) {
      this.replaceFilters({ userGroup: this.userGroup.plain() });
    }

    useStreams([this.streamLoginAsUser()], this.lifecycles.onDestroy$);
  }

  get isInfiniteTable() {
    return true;
  }

  get tableKey() {
    return 'manage-users';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .expand(['userGroup', 'role', 'creator', 'department'])
      .embed(['brands', 'brands.desks', 'brands.voipConfigurations'])
      .setConfig({ blockUiRef: this.blockUiKey });
  }

  parseLoadedData(data) {
    this.users = data;
    this.dataAdditionals = {
      ...this.dataAdditionals,
      ...this.generateDataAdditionals(this.users),
    };
    return data;
  }

  /**
   * Generate data infos accompaning the data. Ui state...
   *
   * @return {object[]} the generated data infos
   */
  generateDataAdditionals(users: User[]) {
    const result = users.reduce((acc, user) => {
      const info = new DataAdditional();
      calcMenuItemsForUserRow(
        user,
        this.PermPermissionStore,
        this.cachedUser,
      ).then((menuItems) => {
        info.menuItems = menuItems;
      });

      return {
        ...acc,
        [user.id]: info,
      };
    }, {});

    return result;
  }

  onDropDownAction(actionCode, user) {
    const actionsDispatch = {
      [MenuAction.LoginAsUser]: () => this.loginAsUser(user),
      [MenuAction.ToggleUserStatus]: () => this.toggleUserStatus(user),
      [MenuAction.OnActivityLog]: () => this.onActivityLog(user),
      [MenuAction.DisableNotifications]: () =>
        this.disableNotificationsForUser(user),
      [MenuAction.ActivateNotifications]: () =>
        this.activateNotificationsForUser(user),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  disableNotificationsForUser(user: User) {
    return this.usersService()
      .disableNotifications(user.id)
      .then(() => {
        user.disableNotifications = true;

        this.dataAdditionals = {
          ...this.dataAdditionals,
          ...this.generateDataAdditionals(this.users),
        };
      });
  }

  activateNotificationsForUser(user: User) {
    return this.usersService()
      .activateNotifications(user.id)
      .then(() => {
        user.disableNotifications = false;

        this.dataAdditionals = {
          ...this.dataAdditionals,
          ...this.generateDataAdditionals(this.users),
        };
      });
  }

  streamLoginAsUser() {
    return rx.pipe(
      () => this.loginAsUser$,
      rx.filter((user: User): any => !!user && user.id),
      rx.switchMap((user) =>
        rx.obs.from(this.dataServiceInstance.generateToken(user)),
      ),
      rx.tap((token) =>
        this.tokensService.logout().finally(() => {
          this.Idle.unwatch();
          this.$state.go('auth.login', { token });
        }),
      ),
    )(null);
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    const common = this.userSearch
      ? {
          q: this.userSearch,
        }
      : {};

    // userGroup filter exists in the url. show only results from the selected group
    if (this.userGroup) {
      return {
        ...common,
        userGroupId: this.userGroup.id,
      };
    }

    // don't hide external users when filtering user groups or roles
    if (this.normalizedFilters.roleId || this.normalizedFilters.userGroupId) {
      return { ...common };
    }

    //
    return {
      q: this.userSearch,
      ...common,
      'role.code': {
        exclude: 'extapi',
      },
    };
  }

  get ngTableSettings() {
    return this.usersModuleSettings.usersTable.ngTable;
  }

  /**
   * called on user search changes in view
   * @return {void}
   */
  onUserSearchChange() {
    this.tableParams.page(1);

    /*
     * set user search value to table filter reference object.
     * this change will trigger api call
     */
    this.tableParams.filter().q = this.userSearch;
  }

  /**
   * activate/deactivate user
   *
   * @param {Object} user
   */
  toggleUserStatus(user) {
    // toggle the status
    const isActive = !user.isActive;

    // patch request to server
    user
      .patch({
        isActive,
      })
      .then(() => {
        user.isActive = isActive;

        this.dataAdditionals = {
          ...this.dataAdditionals,
          ...this.generateDataAdditionals(this.users),
        };
      });
  }

  onActivityLog(user) {
    this.modalService.open({
      component: 'prfActivityLogModal',
      scope: this.$scope,
      resolve: {
        user() {
          return user;
        },
      },
    });
  }

  private loginAsUser(user: User) {
    this.loginAsUser$.next(user);
  }

  $onChanges() {}

  $onDestroy() {}

  /**
   * Open Data export log popup table.
   *
   * @return {void}
   */
  showDataExportLog() {
    this.popupService.open({
      component: 'prfDataExportLogPopup',
      resolve: {},
    });
  }
}

function addToMenuItems(
  menuItems: MenuItem[],
  menuItemRow: MenuItemRow,
  user: User,
) {
  switch (menuItemRow) {
    case MenuItemRow.Login:
      return [
        ...menuItems,
        {
          labelCode: 'LOGIN_AS_USER',
          actionCode: MenuAction.LoginAsUser,
        },
      ];
    case MenuItemRow.Deactivate:
      return [
        ...menuItems,
        {
          labelCode: user.isActive ? 'common.DEACTIVATE' : 'common.ACTIVATE',
          actionCode: MenuAction.ToggleUserStatus,
        },
      ];
    case MenuItemRow.ActivityLog:
      return [
        ...menuItems,
        {
          labelCode: 'ACTIVITY_LOG',
          actionCode: MenuAction.OnActivityLog,
        },
      ];
    case MenuItemRow.NotificationsActivation:
      return [
        ...menuItems,
        {
          labelCode: user.disableNotifications
            ? 'common.ACTIVATE_NOTIFICATIONS'
            : 'common.DISABLE_NOTIFICATIONS',
          actionCode: user.disableNotifications
            ? MenuAction.ActivateNotifications
            : MenuAction.DisableNotifications,
        },
      ];
    default:
      throw new Error('not implemented');
  }
}

/**
 * Calculate menu items for specific user.
 *
 * Stateless function.
 *
 * @param {user}
 * @return {Promise} promise of menu items
 */
function calcMenuItemsForUserRow(
  user: User,
  permStore,
  cachedUser: UserTokenModel,
): Promise<MenuItem[]> {
  const updateUserStatusPromise = wrapNgPermissionValidatePromise(
    permStore.getPermissionDefinition('management.users_U'),
  );

  const activityLogPromise = wrapNgPermissionValidatePromise(
    permStore.getPermissionDefinition('management.users.activitylogs'),
  );

  return Promise.all([updateUserStatusPromise, activityLogPromise]).then(
    ([hasUpdatePermit, hasLogPermit]) => {
      let menuItems = [];

      menuItems = addToMenuItems(
        menuItems,
        MenuItemRow.NotificationsActivation,
        user,
      );

      if (
        cachedUser.role.code === 'super' &&
        cachedUser.isSystem &&
        user.username !== cachedUser.username
      ) {
        menuItems = addToMenuItems(menuItems, MenuItemRow.Login, user);
      }
      if (hasUpdatePermit) {
        menuItems = addToMenuItems(menuItems, MenuItemRow.Deactivate, user);
      }
      if (hasLogPermit) {
        menuItems = addToMenuItems(menuItems, MenuItemRow.ActivityLog, user);
      }
      return menuItems;
    },
  );
}

enum MenuAction {
  LoginAsUser = 'loginAsUser',
  ToggleUserStatus = 'toggleUserStatus',
  OnActivityLog = 'onActivityLog',
  DisableNotifications = 'disableNotifications',
  ActivateNotifications = 'activateNotifications',
}

const UserListComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    userGroup: '<',
  },
};

export default UserListComponent;
