import template from './questionnaire-score-general-form.component.html';
const styles = require('./questionnaire-score-general-form.component.scss');
import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import {
  QuestionnaireScore,
  QuestionnaireForm,
} from '@proftit/crm.api.models.entities';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import {
  isNotNilFormValidator,
  isNotEmptyStringFormValidator,
} from '@proftit/ng1.reactive-forms.validators';

export class QuestionnaireScoreGeneralFormController {
  onChangeIsPristine: (x: { isPristine: any }) => void;
  onChangeIsValid: (x: { isValid: any }) => void;
  onChangeValue: (x: { value: any }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  model$ = observeShareCompChange<Partial<QuestionnaireScore>>(
    this.lifecycles.onChanges$,
    'model',
  );
  form$ = this.streamForm();

  /* @ngInject */
  constructor() {
    useStreams(
      [
        this.form$,
        this.streamActionNotifyChangeValue(),
        this.streamActionNotifyChangeIsValid(),
        this.streamActionNotifyChangeIsPristine(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => {
        return new FormGroup({
          questionnaireForm: new FormControl<QuestionnaireForm>(
            model.questionnaireForm,
            {
              validators: [isNotNilFormValidator],
            },
          ),
          isActive: new FormControl<boolean>(model.isActive, {
            validators: [isNotNilFormValidator],
          }),
          name: new FormControl<string>(model.name, {
            validators: [isNotEmptyStringFormValidator],
          }),
        } as any);
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamActionNotifyChangeValue() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.value$),
      rx.tap((value) => this.onChangeValue({ value })),
    )(null);
  }

  streamActionNotifyChangeIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onChangeIsValid({ isValid })),
    )(null);
  }

  streamActionNotifyChangeIsPristine() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isPristine$),
      rx.tap((isPristine) => this.onChangeIsPristine({ isPristine })),
    )(null);
  }
}

export const QuestionnaireScoreGeneralFormComponent = {
  template,
  controller: QuestionnaireScoreGeneralFormController,
  bindings: {
    model: '<',
    isDisableSelectQuestionnaireForm: '<',
    onChangeValue: '&',
    onChangeIsValid: '&',
    onChangeIsPristine: '&',
  },
};
