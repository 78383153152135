import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import TableController from '~/source/common/components/table/table.controller';
import QuestionnaireService from '../questionnaire.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import Questionnaire from '~/source/common/models/questionnaire';

import template from './questionnaire-list.component.html';
import { generateUuid } from '@proftit/general-utilities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { QuestionnaireUserAction } from '~/source/management/questionnaire/manage.controller';
import BrandsService from '~/source/management/brand/services/brands';
import { FormControl } from '@proftit/ng1.reactive-forms';
const styles = require('./questionnaire-list.scss');

type UpdateShowDetailsActions = {
  guid: string;
  renderer: string;
};

class Controller extends TableController {
  styles = styles;
  questionnaireService: () => QuestionnaireService;
  brandsService: () => BrandsService;
  questionnaireModuleSettings: any;
  QuestionnaireUserAction = QuestionnaireUserAction;

  static $inject = [
    ...TableController.$inject,
    '$scope',
    'brandsService',
    'questionnaireService',
    'questionnaireModuleSettings',
  ];

  lifecycles = observeComponentLifecycles(this);

  updateShowDetailsInRowAction = new rx.Subject<UpdateShowDetailsActions>();
  selectedBrandFormControl = new FormControl(null);

  showDetailsMap$ = this.streamShowDetailsMap();
  brandsToDisplay$ = this.streamBrandsToDisplay();

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.showDetailsMap$,
        this.brandsToDisplay$,
        this.selectedBrandFormControl.value$,
        this.streamReloadQuestionnaireTableAfterBrandSelect(),
      ],
      this.lifecycles.onDestroy$,
    );
    Object.assign(this, {
      dataServiceInstance: this.questionnaireService(),
      settings: Object.assign({}, this.questionnaireModuleSettings),
      cols: [...this.questionnaireModuleSettings.cols],
      // Disable params caching: we don't want it to remember current page/sort etc after refresh
      filterCacheKey: null,
      tableCacheKey: null,
    });

    this.initTable();
  }

  streamBrandsToDisplay() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.brandsService()
              .getListWithQuery()
              .then((r) => r.plain()),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamShowDetailsMap() {
    const currState$ = new rx.BehaviorSubject<Map<string, boolean>>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamShowDetailsMapFromUserAction(currState$),
          this.streamShowDetailsMapOnInit(),
        ),
      rx.tap((newVal: Map<string, boolean>) => {
        currState$.next(newVal);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamShowDetailsMapFromUserAction(
    currentMap: rx.BehaviorSubject<Map<string, boolean>>,
  ): rx.Observable<Map<string, boolean>> {
    return rx.pipe(
      () => this.updateShowDetailsInRowAction,
      rx.withLatestFrom(currentMap),
      rx.filter(([action, detailsMap]) => action.renderer !== 'actions'),
      rx.map(
        ([action, detailsMap]: [
          UpdateShowDetailsActions,
          Map<string, boolean>,
        ]) => {
          const { guid } = action;
          const newMap = new Map(detailsMap);
          const oldValueForGuid = newMap.get(guid);
          if (_.isNil(oldValueForGuid)) {
            newMap.set(guid, true);
          } else {
            newMap.set(guid, !oldValueForGuid);
          }
          return newMap;
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamShowDetailsMapOnInit(): rx.Observable<Map<string, boolean>> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        return new Map();
      }),
      shareReplayRefOne(),
    )(null);
  }

  get tableKey() {
    return 'questionnaire';
  }

  $onDestroy() {}

  streamReloadQuestionnaireTableAfterBrandSelect() {
    return rx.pipe(
      () => this.selectedBrandFormControl.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.distinctUntilChanged(),
      rx.tap(() => {
        this.reloadTable();
      }),
      shareReplayRefOne(),
    )(null);
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    const selectedBrandId = _.isNil(this.selectedBrandFormControl.value)
      ? undefined
      : this.selectedBrandFormControl.value.id;

    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiKey })
      .filter({ brandId: selectedBrandId })
      .expand(['brand', 'languages.language'])
      .sort({ name: 'asc' })
      .embed(['languages']);
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  parseLoadedData(data: any[]): any[] {
    data.forEach((q, i) => {
      const baseLangForQuest = q.languages.find((lang) => lang.isDefault);
      q._baseLanguage = { ...baseLangForQuest };
      q._guid = q.id;
    });
    return data;
  }

  /**
   * activate/deactivate list item & reload table
   * @param {Object} item Restangular model
   * @returns {Promise}
   */
  activate(item) {
    this.questionnaireService()
      .getLanguageResource(item._baseLanguage.formId, item._baseLanguage.id)
      .patchWithQuery({
        isActive: !item._baseLanguage.isActive,
      })
      .then(() => {
        this.reloadTable();
      });
  }

  get requiredApiFilters(): any {
    return {
      isHead: true,
    };
  }

  loadQuestionnaire(id: number) {
    return this.questionnaireService()
      .setConfig({ blockUiRef: this.blockUiKey })
      .embed(['components'])
      .getOneWithQuery<IElementRestNg<Questionnaire>>(id);
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
};
