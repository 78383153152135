import template from './customer-assignment-logs-table-popup.component.html';
const styles = require('./customer-assignment-logs-table-popup.component.scss');

import ng, { IHttpService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import { customerAssignmentLogsSettigns } from './customer-assignment-logs-settings';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { customerAssignmentLogsTableColumns } from './customer-assignment-logs-columns';
import { CustomerAssignmentLogsService } from '~/source/common/services/customer-assignment-logs.service';
import {
  Brand,
  Desk,
  AssignmentCounts,
} from '@proftit/crm.api.models.entities';
import { Department } from '~/source/common/models/department';
import { AssignmentFilter } from '../auto-assignment-panel-dashboard/assignment-filter';
import { YesOption } from '~/source/common/components/dropdowns/boolean-select-item';

const TABLE_KEY = 'customerAssignmentLogsTablePopup';

interface Resolve {
  brand: Brand;
  department: Department;
  desk: Desk;
  dateRange: {
    startDate: any;
    endDate: any;
  };
  assignmentCounts: AssignmentCounts;
  assigmentFilter: AssignmentFilter;
}

export class CustomerAssignmentLogsTablePopupController extends TablePopupController<
  CustomerAssignmentLogsService
> {
  static $inject = [
    'prfCustomerAssignmentLogsService',
    'blockUI',
    'growl',
    'growlMessages',
    '$http',
    ...TablePopupController.$inject,
  ];

  /* require */

  /* bindings */

  /* injections */

  prfCustomerAssignmentLogsService: () => CustomerAssignmentLogsService;

  blockUI: ng.blockUI.BlockUIService;

  growl: ng.growl.IGrowlService;

  growlMessages: ng.growl.IGrowlMessagesService;

  $http: IHttpService;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  settings = _.cloneDeep(customerAssignmentLogsSettigns);

  cols = _.cloneDeep(customerAssignmentLogsTableColumns);

  opInitTable$ = new rx.Subject<number>();

  /* inputs */

  resolveIn$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  opAddFilterToTable$ = new rx.Subject<{
    clearName: string[];
    newFilter: Object;
  }>();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams([this.resolveIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.prfCustomerAssignmentLogsService();

    useStreams(
      [this.streamInitTable(), this.streamFiltersToFiltersBar()],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitTable() {
    return rx.pipe(
      () => this.opInitTable$,
      rx.tap((rows) => this.initTable(rows)),
    )(null);
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings;
  }

  get tableKey() {
    return TABLE_KEY;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance.expand([
      'brand',
      'desk',
      'country',
      'department',
    ]);
  }

  streamFiltersToFiltersBar() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((isInit) => isInit),
      rx.delay(20),
      rx.withLatestFrom(this.resolveIn$),
      rx.map(([a, resolve]) => convertResolveToFilter(resolve)),
      rx.filter((newFilter) => !_.isNil(newFilter)),
      rx.map((newFilter) => ({
        newFilter,
        clearName: [
          'brand',
          'deskAll',
          'department',
          'createdAt',
          'assignedToRaw',
          'isAssignmentNew',
        ],
      })),
      rx.tap((msg) => this.opAddFilterToTable$.next(msg as any)),
    )(null);
  }
}

function convertResolveToFilter(resolve: Resolve) {
  return _.flow([
    (fl) => {
      if (_.isNil(resolve.brand)) {
        return fl;
      }

      return {
        ...fl,
        brand: [resolve.brand],
      };
    },
    (fl) => {
      if (_.isNil(resolve.desk)) {
        return fl;
      }

      return {
        ...fl,
        deskAll: [resolve.desk],
      };
    },
    (fl) => {
      if (_.isNil(resolve.department)) {
        return fl;
      }

      return {
        ...fl,
        department: resolve.department,
      };
    },
    (fl) => {
      if (_.isNil(resolve.dateRange)) {
        return fl;
      }

      return {
        ...fl,
        createdAt: {
          endDate: resolve.dateRange.endDate,
          startDate: resolve.dateRange.startDate,
        },
      };
    },
    (fl) => {
      if (_.isNil(resolve.assignmentCounts)) {
        return fl;
      }

      return {
        ...fl,
        assignedToRaw: [
          {
            id: resolve.assignmentCounts.id,
            firstName: resolve.assignmentCounts.firstName,
            lastName: resolve.assignmentCounts.lastName,
            username: resolve.assignmentCounts.username,
          },
        ],
      };
    },
    (fl) => {
      if (_.isNil(resolve.assigmentFilter)) {
        return fl;
      }

      if (resolve.assigmentFilter === AssignmentFilter.New) {
        return {
          ...fl,
          isAssignmentNew: YesOption,
        };
      }
      return fl;
    },
  ])({});
}

export const CustomerAssignmentLogsTablePopupComponent = {
  template,
  controller: CustomerAssignmentLogsTablePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
