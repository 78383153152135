import type { StateProvider } from '@uirouter/angularjs';
import { Permissions } from '~/source/common/models/permission-structure';

export const systemConfigurationsRouting = [
  '$stateProvider',
  ($stateProvider: StateProvider) => {
    $stateProvider.state('crm.management.integration.system_configurations', {
      url: '/system-configurations',
      component: 'prfSystemConfigurationsDashboard',
      data: {
        permissions: {
          only: [Permissions.ManagementSystemConfiguration.Read],
          redirectTo: 'error.unauthorized',
        },
      },
    });
  },
];
