import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';

class ElasticFieldsElementController extends BaseController {
  $scope: any;
  id: number;

  $onInit() {
    /*
     * set element id - useful for name attribute of form fields,
     * as they need to be static and not affected by length change of the collection as index does
     * in general we will use the index for model and id for name attribute
     */
    this.id = this.$scope.$collection.totalCreated;
  }

  isExpandVisible() {
    // check if there is next sibling
    const isSiblingsExists =
      this.$scope.$index !== this.$scope.$collection.models.length - 1;

    return !isSiblingsExists && this.$scope.$collection.isExpandable();
  }

  isContractVisible() {
    return this.$scope.$collection.isContractible();
  }

  /**
   * Proxy to collection method that will ad another
   * element to itself
   *
   * @returns {boolean}
   */
  expand() {
    return this.$scope.$collection.expand();
  }

  /**
   * Removes element from the collection by destroying
   * the directive
   *
   * @returns {boolean}
   */
  contract() {
    return this.$scope.$collection.contract(this.$scope.model);
  }
}

ElasticFieldsElementController.$inject = ['$scope'];

export const ElasticFieldsElementDirective = () => ({
  restrict: 'E',
  transclude: true,
  controller: ElasticFieldsElementController,
  controllerAs: '$element',
  require: '^prfElasticFieldsCollection',
  link($scope: any, $element, attrs, collectionCtrl: any, transclude) {
    // Pass the required collection to the scope
    $scope.$collection = collectionCtrl;

    // this is a never decreasing number on purpose: it should be unique to serve as a constant id.
    collectionCtrl.totalCreated += 1;

    transclude((clone) => {
      $element.append(clone);
    });
  },
});
