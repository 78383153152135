import TableController from '~/source/common/components/table/table.controller';
import UsersService from '~/source/management/user/services/users';
import ModalService from '~/source/common/components/modal/modal.service';

import template from './api-users-list.component.html';

class ApiUsersListController extends TableController {
  static $inject = [
    ...TableController.$inject,
    '$scope',
    'usersService',
    'apiUsersListSettings',
    'modalService',
  ];

  usersService: () => UsersService;
  apiUsersListSettings: any;
  modalService: ModalService;

  userSearch: string;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      dataServiceInstance: this.usersService(),
      settings: Object.assign({}, this.apiUsersListSettings),
      quickFilters: this.apiUsersListSettings.quickFilters,
      cols: [...this.apiUsersListSettings.cols],
    });
  }

  get isInfiniteTable() {
    return true;
  }

  get tableKey() {
    return 'api-users';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .embed(['brands', 'whitelistIps', 'brands.campaigns'])
      .expand(['role', 'creator'])
      .setConfig({ blockUiRef: this.blockUiKey });
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    return {
      q: this.userSearch,
      'role.code': 'extapi',
    };
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.apiUsersListSettings.ngTable;
  }

  /**
   * called on user search changes in view
   */
  onApiUserSearchChange() {
    this.tableParams.page(1);

    /*
     * set user search value to table filter reference object.
     * this change will trigger api call
     */
    this.tableParams.filter().q = this.userSearch;
  }

  /**
   * activate/deactivate user
   *
   * @param {Object} user
   */
  toggleUserStatus(user) {
    // toggle the status
    const isActive = !user.isActive;

    // patch request to server
    user
      .patch({
        isActive,
      })
      .then(() => {
        user.isActive = isActive;
      });
  }

  onActivityLog(e, user) {
    e.stopPropagation();
    e.preventDefault();

    this.modalService.open({
      component: 'prfActivityLogModal',
      scope: this.$scope,
      resolve: {
        user() {
          return user;
        },
      },
    });
  }
}

const ApiUsersListComponent = {
  template,
  controller: ApiUsersListController,
  controllerAs: 'vm',
};

export default ApiUsersListComponent;
