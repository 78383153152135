import * as _ from '@proftit/lodash';
import { IWindowService, ITimeoutService, IRootScopeService } from 'angular';
import { TransitionService } from '@uirouter/core';

export class PageTitleService {
  /* @ngInject */
  constructor(
    readonly $transitions: TransitionService,
    readonly $window: IWindowService,
    readonly $timeout: ITimeoutService,
    readonly $translate: angular.translate.ITranslateService,
    readonly $rootScope: IRootScopeService,
  ) {
    let pageTitleCode;
    $transitions.onSuccess({}, (transition) => {
      pageTitleCode = transition.to().data?.pageTitle || null;

      $timeout(() => {
        $window.document.title = this.getPageTitle(pageTitleCode);
      });
    });

    $rootScope.$on('$translateChangeSuccess', () => {
      $window.document.title = this.getPageTitle(pageTitleCode);
    });
  }

  getPageTitle(PageTitleCode) {
    const proftit = this.$translate.instant('pageTitle.PROFTIT');

    if (!PageTitleCode) {
      return proftit;
    }

    const title = this.$translate.instant(`pageTitle.${PageTitleCode}`);
    return `${proftit} - ${title}`;
  }
}
