import log from 'loglevel';
import { calcKeepAliveInterval } from '~/source/common/utilities/calc-keep-alive-interval';
import * as _ from '@proftit/lodash';
import { TokensService } from '~/source/auth/services/tokens';
import type { ITimeoutService, idle } from 'angular';

function runConfig(
  tokensService: TokensService,
  Keepalive: idle.IKeepAliveService,
  Idle: idle.IIdleService,
  $timeout: ITimeoutService,
  localStorageService: Storage,
) {
  if (!tokensService.isLoggedIn()) {
    return;
  }

  const user = tokensService.getCachedUser();
  const keepAliveInterval = calcKeepAliveInterval(
    user.sessionTime,
    localStorageService,
  );
  const now = Date.now();
  const nextKeepAlive: Date = ((now + keepAliveInterval * 1000) as any) as Date;

  // User is logged in. set keepalive to run when the session is 90% ending
  Keepalive.setInterval(keepAliveInterval);
  log.info(
    'keepalive interval set to',
    keepAliveInterval,
    'Next will be at ',
    new Date(nextKeepAlive),
  );
  // Start watching for inactivity
  Idle.watch();

  /**
   * Check if the active session will expire before the next keepalive ping will happen.
   * If it is the case, initiate a manual keepalive before this happens.
   */
  const cacheExpires = tokensService.getCacheInfo().expires;

  if (cacheExpires <= nextKeepAlive) {
    const keepAliveTimeout = _.flow([
      // @ts-ignore
      () => Math.floor(cacheExpires - now),
      (x) => calcKeepAliveInterval(x, localStorageService),
    ])();

    // Cache will expire before the next keepalive!
    log.info(
      'Cache will expire before the next keepalive. Issuing a keepalive in %s seconds',
      keepAliveTimeout / 1000,
    );
    log.info('Next keepalive will be at ', new Date(now + keepAliveTimeout));

    // Schedule manual keepalive before session expires
    $timeout(() => {
      // restart keepalive: stop it, manually ping, and start interval again.
      Keepalive.stop();
      tokensService.keepAlive().then(() => {
        Keepalive.start();
      });
    }, keepAliveTimeout);
  }
}
runConfig.$inject = [
  'tokensService',
  'Keepalive',
  'Idle',
  '$timeout',
  'localStorageService',
];

export default runConfig;
