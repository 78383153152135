import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { PlatformCode } from '@proftit/crm.api.models.enums';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import { ApiMtSecuritiesService } from '~/source/common/api-cfd-platform/api-mt-securities.service';
import { directivesPriorities } from '../constants/directives-priorities';
import { MtSecurity } from '@proftit/tradingcore.api.models.entities';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums/src';

export class CurrentTrcMtSecuritiesOfPlatformServiceDirectiveController {
  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  mtSecurities$: rx.Observable<MtSecurity[]>;

  /* @ngInject */
  constructor(readonly prfApiMtSecuritiesService: ApiMtSecuritiesService) {}

  $onInit() {
    this.mtSecurities$ = this.streamMtSecurities();
  }

  streamMtSecurities() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.switchMap((sessionInfo) => {
        if (!sessionInfo.isLoggedIn) {
          return rx.obs.of([]);
        }

        if (!MT4_PLATFORMS.includes(sessionInfo.platform.code)) {
          return rx.obs.of([]);
        }

        return this.prfApiMtSecuritiesService
          .getSecurities(sessionInfo.session.apiUrl, sessionInfo.session.token)
          .catch((e: Error) => {
            log.error('error fetching mt4 platform securities', e);
            return [];
          });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const currentTrcMtSecuritiesOfPlatformServiceDirective = () => {
  return {
    priority: directivesPriorities.serviceDirective,
    restrict: 'A',
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentTrcMtSecuritiesOfPlatformServiceDirectiveController,
  };
};

currentTrcMtSecuritiesOfPlatformServiceDirective.$inject = [] as string[];
