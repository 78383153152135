import { Permissions } from '~/source/common/models/permission-structure';

export const ewalletCryptoProvidersRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.integration.ewallet-crypto-providers', {
        redirectTo: 'crm.management.integration.ewallet-crypto-providers.list',
        url: '/ewallet-crypto-providers',
        component: 'prfEwalletCryptoProvidersDashboard',
        data: {
          permissions: {
            only: ['management.brands.ewalletscredentials'],
            redirectTo: 'error.unauthorized',
          },
        },
      })
      .state('crm.management.integration.ewallet-crypto-providers.list', {
        url: '/list',
        component: 'prfEwalletCryptoProvidersList',
      })
      .state(`crm.management.integration.ewallet-crypto-providers.update`, {
        component: 'prfEwalletCryptoProvidersUpdate',
        url: '/update/:brandId',
      });
  },
];
