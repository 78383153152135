import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './prf-bean-validation-message.component.html';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { Bean, getBeanValidationResults } from '@proftit/proxy-bean';
import { observeBeanIsValid } from '@proftit/proxy-bean-rxjs';
import { ProxyFormValidationMessageGeneratorService } from '~/source/common/proxy-form.ng1/proxy-form-validation-message-generator.service';
const styles = require('./prf-bean-validation-message.component.scss');

export class PrfBeanValidationMessageController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  prfProxyFormValidationMessageGenerator: {
    item$: rx.Observable<ProxyFormValidationMessageGeneratorService>;
  };

  prfProxyFormValidationMessageGenerator$ = new rx.BehaviorSubject<
    ProxyFormValidationMessageGeneratorService
  >(null);

  beanIn$ = observeShareCompChange<Bean>(this.lifecycles.onChanges$, 'bean');

  isValid$ = this.streamIsValid();

  validationResults$ = this.streamValidationResults();

  message$ = this.streamMessage();

  /* @ngInject */
  constructor() {
    useStreams(
      [
        this.beanIn$,
        this.lifecycles.onInit$.pipe(
          rx.switchMap(() => this.prfProxyFormValidationMessageGenerator.item$),
          rx.tap((service) =>
            this.prfProxyFormValidationMessageGenerator$.next(service),
          ),
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamIsValid() {
    return rx.pipe(
      () => this.beanIn$,
      rx.distinctUntilChanged(),
      rx.switchMap((bean) => observeBeanIsValid(bean)),
      shareReplayRefOne(),
    )(null);
  }

  streamValidationResults() {
    return rx.pipe(
      () => this.isValid$,
      rx.withLatestFrom(this.beanIn$),
      rx.map(([_isValid, bean]) => getBeanValidationResults(bean)),
      shareReplayRefOne(),
    )(null);
  }

  streamMessage() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isValid$,
          this.validationResults$,
          this.prfProxyFormValidationMessageGenerator$,
        ),
      rx.switchMap(([isValid, validationResults, messageGenerator]) => {
        if (isValid || _.isNil(messageGenerator)) {
          return rx.obs.of(null);
        }

        const firstMessage = validationResults.find((r) => !r.isValid);
        return messageGenerator.generate(firstMessage);
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const PrfBeanValidationMessageComponent = {
  template,
  controller: PrfBeanValidationMessageController,
  bindings: {
    bean: '<',
  },
  require: {
    prfProxyFormValidationMessageGenerator: '^',
  },
};
