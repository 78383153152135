import Component from './base/component';
import { Country } from '@proftit/crm.api.models.entities';

class StateController extends Component.controller {
  static $inject = ['countryStatesService', ...Component.controller.$inject];

  country: Country;

  /**
   *  Fetch states on selected country change
   */
  init() {
    this.$scope.$watch('vm.country', () => {
      this.output = []; // reset states when the country changes

      if (!this.country) {
        return;
      }

      if (this.country.isHasStates) {
        this.fetchData();
      }
    });
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst
      .filter('countryId', this.country.id)
      .getListWithQuery();
  }

  get defaultPlaceholder() {
    return 'common.SELECT_STATE';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: StateController,
  bindings: {
    country: '=',
  },
});
