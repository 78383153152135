import { generateUuid } from '@proftit/general-utilities';

const PASSED_SEGMENT_NAME = 'Passed';

export function generateDefaultPassedSegment() {
  return {
    id: `clientid-${generateUuid()}` as any,
    name: PASSED_SEGMENT_NAME,
    start: 56,
    end: 100,
  };
}
