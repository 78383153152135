import template from './portfolio-table.html';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import { PortfolioService } from '~/source/common/services/portfolio.service';
import { dashboardSettings } from './dashboard-settings';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import log from 'loglevel';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import { buildCrmAccountChannelName } from '~/source/common/api-crm-server/build-crm-account-channel-name';
import { BrandsService } from '~/source/management/brand/services/brands';
import {
  Mt4AccountPnlSocketService,
  buildMt4AccountPnlChannel,
} from '~/source/common/services/mt4-account-pnl-socket.service';
import {
  CfdPlatformAccountOpenPnlSocketService,
  buildCfdAccountPnlChannel,
} from '~/source/common/api-cfd-platform/cfd-platform-account-open-pnl-socket.service';
import {
  buildBundleAccountPnlChannel,
  BundlePlatformAccountOpenPnlSocketService,
} from '~/source/common/api-bundle-platform/bundle-platform-account-open-pnl-socket.service';
import { CfdBundlePlatformAccountPnlSocketUpdates } from '~/source/contact/contact-page/trading-account/trading-accounts-container/cfd-platform-account-pnl-socket-updates';
import * as _ from '@proftit/lodash';
import { switchOn } from '~/source/common/utilities/switch-on';
import TradingAccountBinarySocketService from '~/source/contact/contact-page/trading-account/binary/trading-account-socket.service';
import TradingAccountForexSocketService from '~/source/contact/contact-page/trading-account/forex/trading-account-socket.service';
import ModalService from '~/source/common/components/modal/modal.service';
import sendEmailTemplate from '~/source/contact/contact-page/send-email/send-email-popup.html';
import { calculateForexPositionTableCols } from '~/source/contact/contact-page/trading-account/forex/position/calculate-forex-position-table-cols';
import { DisplayContext } from '~/source/contact/contact-page/trading-account/forex/position/display-contenxt';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import {
  Brand,
  Platform,
  TradingAccount,
  Customer,
} from '@proftit/crm.api.models.entities';
import { PlatformCode, MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { UserBrandPlatformSession } from '~/source/common/models/user-brand-platform-session';

const ACCOUNT_PNL_UPDATE_THROTTLE = 5000;
const styles = require('./portfolio-table.scss');
const platforms = {
  MT4: dashboardSettings.dashboardTable,
  CFD: dashboardSettings.dashboardTable,
  BUNDLE: dashboardSettings.dashboardTable,
};

interface ItemUpdates {
  id: number;
  pnl?: number;
  marginLevel: number;
  freeMargin: number;
  _openPnl?: number;
  total?: number;
  margin?: number;
  free_margin?: number;
}

export class Controller extends TableLiveController {
  styles = styles;
  brand: Brand;
  platform: Platform;
  lifecycles = observeComponentLifecycles(this);

  localSearchTerm$ = new rx.BehaviorSubject<string>(null);
  PermissionNormalized = PermissionNormalized;

  PlatformCode = PlatformCode;

  static $inject = [
    'portfolioService',
    'cfdPlatformAccountOpenPnlSocketService',
    'cfdPlatformAccountFreeMarginSocketService',
    'cfdPlatformAccountMarginLevelSocketService',
    'bundlePlatformAccountOpenPnlSocketService',
    'brandsService',
    'modalService',
    'mt4AccountPnlSocketService',
    'mt4AccountFreeMarginSocketService',
    'mt4AccountMarginLevelSocketService',
    'tradingAccountForexSocketService',
    'tradingAccountBinarySocketService',
    '$scope',
    ...TableLiveController.$inject,
  ];

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  modalService: ModalService;
  accounts: TradingAccount[];
  user$ = new rx.BehaviorSubject<UserTokenModel>(null);
  settings: any;
  portfolioService: () => PortfolioService;
  dataServiceInstance: PortfolioService;
  brandsService: () => BrandsService;
  tableColumns: any;
  customer$: rx.Subject<Customer> = new rx.Subject<Customer>();
  mt4AccountPnlSocketService: () => Mt4AccountPnlSocketService;
  cfdPlatformAccountOpenPnlSocketService: () => CfdPlatformAccountOpenPnlSocketService;
  bundlePlatformAccountOpenPnlSocketService: () => BundlePlatformAccountOpenPnlSocketService;
  accounts$ = new rx.BehaviorSubject<TradingAccount[]>([]);
  tradingAccountBinarySocketService: TradingAccountBinarySocketService;
  tradingAccountForexSocketService: TradingAccountForexSocketService;
  $scope: angular.IScope;

  platform$ = observeShareCompChange<Platform>(
    this.lifecycles.onChanges$,
    'platform',
  );

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  itemsId$ = new rx.BehaviorSubject<number[]>([]);
  onTableColumnsChanged: any;
  tableKeyBinding: string;
  tblNumOfRows: any;

  brandCfdStreamerAccessInfoRegistry = {};
  brandBundleStreamerAccessInfoRegistry = {};
  brandMt4StreamerAccessInfoRegistry = {};

  constructor(...args) {
    super(...args);
    this.settings = dashboardSettings;
    this.tableColumns = this.settings.dashboardTable.cols;
    this.user$.next(this.tokensService.getCachedUser());

    useStreams(
      [this.platform$, this.brand$, this.localSearchTerm$],
      this.lifecycles.onDestroy$,
    );

    this.dataServiceInstance = this.portfolioService();
  }

  $onInit() {
    super.$onInit();

    useStreams(
      [
        this.streamSocketAccountUpdates(),
        this.streamPlatformChange(),
        this.streamItemsIds(),
      ],
      this.lifecycles.onDestroy$,
    );

    const tempCountRows =
      dashboardSettings.dashboardTable.ngTable.parameters.count;
    this.tblNumOfRows = {
      count: tempCountRows,
      id: numRowsOptions.indexOf(
        numRowsOptions.find((count) => tempCountRows === count),
      ),
      name: tempCountRows,
    };
    this.initTable(this.tblNumOfRows.count);
  }

  get tableKey() {
    if (!_.isNil(this.tableKeyBinding)) {
      return this.tableKeyBinding;
    }
    return 'portfolio';
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.dashboardTable.ngTable;
  }

  get searchTerm() {
    return this.localSearchTerm$.getValue();
  }

  set searchTerm(searchVal) {
    this.localSearchTerm$.next(searchVal);
    if (_.isNil(this.tableParams)) {
      return;
    }
    this.tableParams.page(1);
    if (!_.isNil(searchVal) && searchVal.length > 0) {
      this.tableParams.filter().q = searchVal;
    } else {
      this.tableParams.filter().q = undefined;
    }
  }

  get requiredApiFilters() {
    const filters: any = {};
    if (!_.isNil(this.searchTerm) && this.searchTerm.length > 0) {
      filters.q = this.searchTerm;
    }
    return filters;
  }

  get liveEntitiesVarName() {
    return null;
  }

  get entitiesContainer() {
    return null;
  }

  get socketService() {
    return null;
  }

  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: 'portfolioTableBlock' })
      .getAccountsByBrand(this.brand.id, this.platform.id);
  }

  $onChanges() {}

  streamItemsIds() {
    return rx.pipe(
      () => this.collection$,
      rx.map((items) => items.map((item) => item.id)),
      rx.tap((itemsIds) => this.itemsId$.next(itemsIds)),
    )(null);
  }

  /**
   * Stream for platform change.
   */
  streamPlatformChange() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(this.platform$, this.brand$, this.isInitTable$),
      rx.filter(
        ([platform, brand, isInitTable]) => !_.isNil(platform) && isInitTable,
      ),
      rx.map(([platform, brand, isInitTable]) => platform),
      rx.map((platform) => {
        if (MT4_PLATFORMS.includes(platform.code)) {
          return calculateForexPositionTableCols(
            this.settings.dashboardTable.cols,
            platform,
            DisplayContext.PortfolioMt4Table,
          );
        }
        return calculateForexPositionTableCols(
          this.settings.dashboardTable.cols,
          platform,
          DisplayContext.PortfolioCfdTable,
        );
      }),
      rx.tap((newCols) => {
        this.tableColumns = newCols;
        this.onTableColumnsChanged({ newCols });
      }),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  /**
   * builds observer of crm updates
   */
  buildCrmUpdatesObserver(user: UserTokenModel, account: TradingAccount) {
    const crmChannelName = buildCrmAccountChannelName(
      user.id as number,
      account.type,
      account.id,
    );
    const crmSocketService = this.getSocketServiceForType(account.type);
    const crmUpdates$ = observeChannel(crmSocketService, crmChannelName);

    return crmUpdates$;
  }

  /**
   * builds observer of Mt4 updates
   */
  buildMt4UpdatesObserve(
    session: UserBrandPlatformSession,
    account: TradingAccount,
  ) {
    const servicePnlInstance = this.mt4AccountPnlSocketService();
    // servicePnlInstance.setToken(session.token);
    servicePnlInstance.setStreamerUrl(session.streamerUrl);

    const channelPnl = buildMt4AccountPnlChannel(account.syncRemoteId);
    return observeChannel(servicePnlInstance, channelPnl);
  }

  /**
   * builds observer of Cfd updates
   */
  buildCfdUpdatesObserve(
    session: UserBrandPlatformSession,
    account: TradingAccount,
  ) {
    const servicePnlInstance = this.cfdPlatformAccountOpenPnlSocketService();
    servicePnlInstance.setToken(session.token);
    servicePnlInstance.setStreamerUrl(session.streamerUrl);

    const channelPnl = buildCfdAccountPnlChannel(account.syncRemoteId);

    return observeChannel<CfdBundlePlatformAccountPnlSocketUpdates>(
      servicePnlInstance,
      channelPnl,
    );
  }

  /**
   * builds observer of Bundle updates
   */
  buildBundleUpdatesObserve(
    session: UserBrandPlatformSession,
    account: TradingAccount,
  ) {
    const servicePnlInstance = this.bundlePlatformAccountOpenPnlSocketService();
    servicePnlInstance.setToken(session.token);
    servicePnlInstance.setStreamerUrl(session.streamerUrl);

    const channelPnl = buildBundleAccountPnlChannel(account.syncRemoteId);
    return observeChannel<CfdBundlePlatformAccountPnlSocketUpdates>(
      servicePnlInstance,
      channelPnl,
    );
  }

  /**
   * Stream for change in accounts.
   *
   */
  streamSocketAccountUpdates() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.accounts$,
          this.prfCurrentPlatformSession.sessionS.stream$,
        ),
      rx.withLatestFrom(this.user$),
      rx.switchMap(([[accounts, sessionInfo], user]) => {
        if (accounts.length < 1) {
          return rx.obs.EMPTY;
        }

        if (!sessionInfo.isLoggedIn) {
          return rx.obs.EMPTY;
        }

        const socketsUpdates: rx.Observable<{
          itemUpdates: ItemUpdates;
        }>[] = [];

        accounts.forEach((account) => {
          const crmUpdates$ = this.buildCrmUpdatesObserver(user, account).pipe(
            rx.map((itemUpdates: ItemUpdates) => ({
              id: account.id,
              _openPnl: itemUpdates.pnl,
              marginLevel: itemUpdates.marginLevel,
              freeMargin: itemUpdates.freeMargin,
            })),
            rx.map((itemUpdates) => ({ itemUpdates })),
          );

          socketsUpdates.push(crmUpdates$);
          if (MT4_PLATFORMS.includes(this.platform.code)) {
            const mt4Updates$ = this.buildMt4UpdatesObserve(
              sessionInfo.session,
              account,
            ).pipe(
              rx.throttleTime(
                ACCOUNT_PNL_UPDATE_THROTTLE,
                rx.scheduler.asyncScheduler,
                {
                  leading: true,
                  trailing: true,
                },
              ),
              rx.map((itemUpdates: ItemUpdates) => ({
                id: account.id,
                _openPnl: itemUpdates.pnl,
                marginLevel: +parseFloat(
                  `${
                    itemUpdates.margin === 0
                      ? 0
                      : (account.equity / itemUpdates.margin) * 100
                  }`,
                ).toFixed(2),
                freeMargin: +parseFloat(`${itemUpdates.free_margin}`).toFixed(
                  2,
                ),
              })),
              rx.map((itemUpdates) => ({ itemUpdates })),
            );

            socketsUpdates.push(mt4Updates$);
          }
          if (this.platform.code === PlatformCode.Cfd) {
            const cfdUpdates$ = this.buildCfdUpdatesObserve(
              sessionInfo.session,
              account,
            ).pipe(
              rx.throttleTime(
                ACCOUNT_PNL_UPDATE_THROTTLE,
                rx.scheduler.asyncScheduler,
                {
                  leading: true,
                  trailing: true,
                },
              ),
              rx.map((itemUpdates: ItemUpdates) => ({
                id: account.id,
                _openPnl: itemUpdates.total,
                marginLevel: itemUpdates.marginLevel,
                freeMargin: itemUpdates.freeMargin,
              })),
              rx.map((itemUpdates) => ({ itemUpdates })),
            );

            socketsUpdates.push(cfdUpdates$);
          }

          if (this.platform.code === PlatformCode.Bundle) {
            const cfdUpdates$ = this.buildBundleUpdatesObserve(
              sessionInfo.session,
              account,
            ).pipe(
              rx.throttleTime(
                ACCOUNT_PNL_UPDATE_THROTTLE,
                rx.scheduler.asyncScheduler,
                {
                  leading: false,
                  trailing: true,
                },
              ),
              rx.map((itemUpdates: ItemUpdates) => ({
                id: account.id,
                _openPnl: itemUpdates.total,
                marginLevel: itemUpdates.marginLevel,
                freeMargin: itemUpdates.freeMargin,
              })),
              rx.map((itemUpdates) => ({ itemUpdates })),
            );

            socketsUpdates.push(cfdUpdates$);
          }
        });
        return rx.obs.merge(...socketsUpdates);
      }),
      rx.withLatestFrom(this.accounts$),
      rx.tap(([{ itemUpdates }, accounts]) => {
        const account = accounts.find((act) => act.id === itemUpdates.id);

        if (_.isNil(account)) {
          log.warn('account not found in accounts to update.');
          return accounts;
        }
        Object.assign(account, itemUpdates);
      }),
    )(null);
  }

  parseLoadedData(data) {
    this.accounts = data;
    this.accounts$.next(data);
    return this.accounts;
  }

  getSocketServiceForType(type: string) {
    return switchOn(
      {
        forex: () => this.tradingAccountForexSocketService,
        binary: () => this.tradingAccountBinarySocketService,
      },
      type,
      () => {
        throw new Error('unexpected account type');
      },
    );
  }

  /**
   * sets tableParams to new number of rows
   * @param {number} numOfRows is the number of rows the table will have in every page
   * @returns {void}
   */
  setNumberOfRows(numOfRows): void {
    super.setNumberOfRows(numOfRows.count);
  }

  $onDestroy() {}
}

export const PortfolioTableComponent = {
  template,
  controller: Controller,
  bindings: {
    brand: '<',
    platform: '<',
    onTableColumnsChanged: '&',
    skipDealFilters: '<',
    tableKeyBinding: '<',
    searchTerm: '<',
  },
  controllerAs: 'vm',
  require: {
    prfCurrentPlatformSession: '^',
  },
};

export default PortfolioTableComponent;
