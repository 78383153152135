import moment from 'moment';
import BaseService from './baseService';

/*
 * This service is needed for working with date in the "uib-timepicker" directive.
 * Since the timepicker doesn't support UTC, we have to adjust it manually, and then reverse
 * the adjustment when sending it to server.
 * this could be removed if they add UTC support for the time picker
 * @see https://github.com/angular-ui/bootstrap/issues/5594
 * (Or if we simply write our own time picker)
 */

export class DateTzAdjustService extends BaseService {
  static $inject = ['dateFormat'];
  dateFormat;

  browserTzOffset = new Date().getTimezoneOffset();

  /**
   * Get current date object adjusted to the current browser timezone offset
   * @param {Date} [dateObject] - Date object to convert. If undefined, current time will be used.
   * @return {Date} JS Date object with timezone offset added
   */
  getUTCAdjustedTime(dateObject?: Date) {
    return moment(dateObject).add(this.browserTzOffset, 'minutes').toDate();
  }

  /**
   * Convert the given date object to a Moment instance, with the browser timezone offset subtracted.
   * Basically, reverses what getUTCAdjustedTime does and returns a Moment.
   *
   * @param {Date} [dateObject] - Date object to convert. If undefined, current time will be used.
   * @return {moment} - moment instance with timezone offset subtracted
   */
  fromUTCAdjustedTime(dateObject?: Date) {
    return moment(dateObject).subtract(this.browserTzOffset, 'minutes');
  }

  /**
   * Converts an "adjusted" date model back to normal UTC date model,
   * then formats it in mysql date time format (for server)
   * @param {Date} dateModel - Date object model
   * @return {string} - mysql formatted date object (e.g. "2018-12-02 10:00:00")
   */
  format(dateModel: Date): string {
    // Convert the "adjusted" date object back to a normal date
    return this.fromUTCAdjustedTime(dateModel).format(
      this.dateFormat.MYSQL_DATETIME,
    );
  }
}
