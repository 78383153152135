import Component from './base/component';
import DesksService from '~/source/management/brand/services/desks';

export class DeskAllSelectController extends Component.controller<
  DesksService
> {
  static $inject = ['desksService', ...Component.controller.$inject];

  /**
   * Preform request
   *
   * @returns rest
   */
  query() {
    return this.dataServiceInst.getListWithQuery();
  }
  /**
   * Name of the property that should be translated
   *
   * @returns translate source
   */
  get translateSource(): string {
    return '';
  }

  get defaultPlaceholder() {
    return 'desk.SELECT_DESK';
  }

  /**
   * Used if select is nullable, returns nullable entity
   *
   * translate name property because this select has dynamic values and its not translated
   *
   * @returns nullabe def for list item
   */
  get nullableElement() {
    return {
      id: null,
      name: this.$translate.instant('desk.SELECT_NO_DESKS'),
    };
  }
}

export const DeskAllSelectComponent = Component.config({
  controller: DeskAllSelectController,
  bindings: {
    nullable: '=',
  },
});
