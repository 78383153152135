import { IScope } from 'angular';
import * as _ from '@proftit/lodash';
import { NgTableParams as INgTableParams } from 'ng-table';

import BaseController from '~/source/common/controllers/base';
import useStream from '~/source/common/utilities/use-stream';

import template from './campaigns-tab.html';
import * as rx from '@proftit/rxjs';

const CHART_ID = 'campaignsGraph';

interface CampaignStat {
  id: number;
  name: string;
}

class CampaignsTabController extends BaseController {
  // bindings
  data: CampaignStat[] = [];
  chartOptions;
  tableOptions;

  chart;
  tableData;
  chartData;

  unsub$ = new rx.Subject<void>();

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly NgTableParams: typeof INgTableParams,
  ) {
    super();

    // listen to chart update event
    this.$scope.$on('slicedChart:chart:dataUpdated', (scope, chart) => {
      if (chart.div && chart.div.id === CHART_ID) {
        /*
         * use chart data (instead the raw api data) because it contains slice colors and indexes
         * required by the table
         */
        this.chartData = _.map(
          ({ color, title }) => ({
            color,
            title,
          }),
          chart.chartData,
        );

        // init/update table
        this.initTable(this.chartData);

        // keep chart object after render is completed
        this.chart = chart;
      }
    });
  }

  $onChanges(changes) {
    // listen to campaign data changes
    if (changes.data && changes.data.currentValue) {
      // filter api data
      if (changes.data.currentValue.length > 0) {
        // sorting data for chart use as we did for table data in 'marketing/settings/dashboard.json'
        this.data.sort((a, b) => a.name.localeCompare(b.name));
        this.data = this.filterApiData(changes.data.currentValue);
      }
    }
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * filter api data, collect only items with positive values
   *
   * @param {array} data
   * @returns {Array}
   */
  filterApiData(data) {
    return _.filterEs(data, (item) => item[this.chartOptions.valueField] > 0);
  }

  initTable(dataset) {
    // extend ngTable settings with dataset
    const ngTableSettings = Object.assign(
      {},
      this.tableOptions.ngTable.settings,
      { dataset },
    );

    this.tableData = new this.NgTableParams(
      this.tableOptions.ngTable.parameters,
      ngTableSettings,
    );
  }

  /**
   * calculates a pie slice index based on the current page and row index
   * @param {number} index
   */
  calcSliceCurrentIndex(index) {
    const pageSize = this.tableOptions.ngTable.parameters.count;
    return pageSize * (this.tableData.page() - 1) + index;
  }

  /**
   * trigger chart rollOverSlice
   * @param {number} index
   */
  hoverSlice(index) {
    this.chart.rollOverSlice(this.calcSliceCurrentIndex(index));
  }

  /**
   * trigger chart "clickSlice"
   * @param {number} index
   * @return {void}
   */
  clickSlice(index) {
    this.chart.clickSlice(this.calcSliceCurrentIndex(index));
  }

  /**
   * trigger chart rollOutSlice
   * @param {number} index
   */
  blurSlice(index) {
    this.chart.rollOutSlice(this.calcSliceCurrentIndex(index));
  }

  onGraphSliceSelected(dataContext) {}

  onGraphSliceUnselected(dataContext) {}
}

export default {
  template,
  controller: CampaignsTabController,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    chartOptions: '<',
    tableOptions: '<',
  },
};
