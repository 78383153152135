import angular from 'angular';
import CallManagerComponent from './call-manager.component';
import CallPopupController from './call-popup.controller';
import CommunicationsService from './communications.service';
import CommunicationsSocketService from './communications-socket.service';
import callPopupActiveTemplate from './call-popup-active.html';
import callPopupInActiveTemplate from './call-popup-inactive.html';

export default angular
  .module('crm.common.callManager', [])
  .component('prfCallManager', CallManagerComponent)
  .controller('CallPopupController', CallPopupController)
  .service('communicationsService', CommunicationsService)
  .service('communicationsSocketService', CommunicationsSocketService)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put('call-popup-active.html', callPopupActiveTemplate);
      $templateCache.put('call-popup-inactive.html', callPopupInActiveTemplate);
    },
  ]).name;
