import template from './play.component.html';
const styles = require('./play.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class PlayController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const PlayComponent = {
  template,
  bindings: {
    onClick: '&',
  },
  controller: PlayController,
};
