import type {
  IAttributes,
  ITimeoutService,
  IParseService,
  IScope,
} from 'angular';
import * as _ from '@proftit/lodash';
import { getFullCalendar } from './full-calendar/get-full-calendar';

const UI_CALENDAR_PRIORITY = 1000;

export function prfUiCalendarRefDirective(
  $parse: IParseService,
  $timeout: ITimeoutService,
  uiCalendarConfig: any,
) {
  return {
    restrict: 'A',
    priority: UI_CALENDAR_PRIORITY + 1,
    link(scope: IScope, _element: JQuery, attrs: IAttributes, _ctrls: {}) {
      // let ui calendar finish init and adding instance to the service;
      $timeout(() => {
        const calendarId = attrs.calendar;
        if (_.isNil(calendarId)) {
          return;
        }

        const calendarElement = uiCalendarConfig.calendars[calendarId];
        if (_.isNil(calendarElement)) {
          throw new Error(
            `calendar instance was not found for id: ${calendarId}`,
          );
        }

        const calendar = getFullCalendar(calendarElement);

        executeAttrsExpression($parse, scope, attrs.prfUiCalendarRef, {
          calendarElement,
          calendar,
        });
      });
    },
    controller() {},
    require: {
      uiCalendar: '',
    },
  };
}

function executeAttrsExpression(
  $parse: IParseService,
  $scope: IScope,
  exprStr: string,
  scopeParams: Record<string, any>,
) {
  const exprFn = $parse(exprStr);

  const newScope = $scope.$new(false, $scope);
  Object.assign(newScope, scopeParams);

  exprFn(newScope);

  newScope.$destroy();
}

prfUiCalendarRefDirective.$inject = ['$parse', '$timeout', 'uiCalendarConfig'];
