import _ from 'underscore';

import Controller from '~/source/common/controllers/base';
import template from './table-columns.html';
import { IScope } from 'angular';
import { UserSettingsService } from '~/source/common/services/user-settings';

const STORAGE_UPDATE_THROTTLE = 1000;

class TableColumnsController extends Controller {
  cols;
  category;

  updateStorageThrottled: () => void;
  settingsRecord;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly userSettingsService: UserSettingsService,
  ) {
    super();
    this.updateStorageThrottled = _.debounce(
      this.updateStorage.bind(this),
      STORAGE_UPDATE_THROTTLE,
    );
  }

  $onInit() {
    this.$scope.$on('table:column:removed', (scope, field) => {
      this.hideColumn(field);
      this.updateStorageThrottled();
    });
  }

  /**
   * get cache key name
   * @returns {string} return cache key name
   */
  get settingsKey() {
    return `${this.category}TableColumns`;
  }

  /**
   * Gets the current table column settings array as an object with field name as key and column options as val
   * Ignored non editable columns
   * Example:
   * Table columns: [
   *      { field: "checkbox", nontEditable: true, show: true },
   *      { field: "name", show: true },
   *      { field: "email", show: false }
   * ]
   * Returned object: {name: { show: true }, email: { show: false} }
   *
   * @return {object}
   */
  get currentColumnSettings() {
    const colsSettings = {};
    this.cols
      .filter((col) => !col.nonEditable)
      .forEach((col) => {
        colsSettings[col.field] = { show: col.show };
      });
    return colsSettings;
  }

  /**
   * Gets user column settings record from server.
   *
   * Caches the retrieved record in memory so consequent calls will use it.
   *
   * @return {Promise} - resolves to the setting record
   */
  get userColumnSettings() {
    if (!this.settingsRecord) {
      this.settingsRecord = this.userSettingsService.getSetting(
        this.settingsKey,
        this.currentColumnSettings,
      );
    }

    return this.settingsRecord;
  }

  /**
   * hide column by field name
   * @param {String} field
   */
  hideColumn(field) {
    this.cols.find((col) => col.field === field).show = false;
  }

  /**
   * listen to table cols bindings change.
   * merge provided columns with columns visibility defined in storage
   */
  onColsChange() {
    if (!this.cols || this.cols.length === 0) {
      return;
    }

    this.userColumnSettings.then((userColumns) => {
      // set column visibility according to the user's selected columns
      this.setColumnVisibility(userColumns.value);
    });
  }

  /**
   * merge local storage column visibility data into default column settings
   *
   * @example
   * settings format [{field: "date", show: true}, {field: "name", show: true}]
   * storage format {date: {show: false}, name: {show: true}}
   * merge result => [{field: "date", show: false}, {field: "name", show: true}]
   *
   * @param {Object} storageCols
   */
  setColumnVisibility(storageCols) {
    this.cols
      // only change the 'show' of columns which are explicitly defined in storage. keep the rest as-is.
      .filter((col) => storageCols[col.field] !== undefined)
      .forEach((col) => {
        col.show = storageCols[col.field].show;
      });
  }

  /**
   * updates local storage that contains user selection of visible/invisible table columns
   * called by view on a checkbox click (throttled)
   * @return {Promise} resolves to updated record (restangularized)
   */
  updateStorage() {
    this.userColumnSettings.then((userColumns) =>
      this.userSettingsService.setSettingValue(
        userColumns.id,
        this.currentColumnSettings,
      ),
    );
  }
}

export const TableColumnsComponent = {
  template,
  controllerAs: 'vm',
  controller: TableColumnsController,
  bindings: {
    cols: '<',
    category: '<',
  },
};
