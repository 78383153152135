import type { IScope, ITranscludeFunction } from 'angular';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateUuid } from '@proftit/general-utilities';
import template from './simple-dropdown-selected-item.component.html';
const styles = require('./simple-dropdown-selected-item.component.scss');

export class SimpleDropdownSelectedItemController {
  isOpen: boolean;

  item: any;

  placeholder: string;

  styles = styles;

  mainId = generateUuid();

  itemContainerId = generateUuid();

  lifecycles = observeComponentLifecycles(this);

  clone: JQuery;

  cloneScope: IScope;

  // inputs

  isOpenInput$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'isOpen',
  );

  itemInput$ = observeShareCompChange<object>(
    this.lifecycles.onChanges$,
    'item',
  );

  placeholderInput$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'placeholder',
  );

  // state

  childData$ = this.streamChildData();

  /* @ngInject */
  constructor(readonly $transclude: ITranscludeFunction) {
    useStreams(
      [this.isOpenInput$, this.itemInput$, this.placeholderInput$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamChildData() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isOpenInput$,
          this.itemInput$,
          this.placeholderInput$,
        ),
      rx.map(([isOpen, item, placeholder]) => {
        return { isOpen, item, placeholder };
      }),
      rx.startWith({}),
      shareReplayRefOne(),
    )(null);
  }
}

export const SimpleDropdownSelectedItemComponent = {
  template,
  controller: SimpleDropdownSelectedItemController,
  transclude: true,
  bindings: {
    isOpen: '<',
    item: '<',
    placeholder: '<',
  },
};
