/**
 * Map platform type ids to their code.
 * Since this data will likely never change, this can save us the need to do requests to fetch this data
 */
export const platformTypesMap = {
  1: 'binary',
  2: 'forex',
};

export default platformTypesMap;
