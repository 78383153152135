import log from 'loglevel';

const exceptionHandler = (exception, cause) => {
  // if the error origin is server (from base-rest), it was already handled by growl. mark it as a warn
  const level = exception.isServerError ? 'warn' : 'error';
  log[level](exception.message, exception, cause);
};

const exceptionHandlerFactory = () => exceptionHandler;

export default exceptionHandlerFactory;
