import ng from 'angular';
import log from 'loglevel';
import { SignupFormField } from '@proftit/signup-form.api.entities';
import {
  BrandRegistrationForm,
  Brand,
  PlatformType,
} from '@proftit/crm.api.models.entities';
import BaseController from '~/source/common/controllers/base';
import formCodeTemplate from './view-form-code-modal.html';
import ModalService from '~/source/common/components/modal/modal.service';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { getSignupFormFieldsByType } from './utilities/get-signup-form-fields-by-type';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CustomerLoginTypeCode } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { FormFieldCode } from './form-field-code';
const styles = require('./form.component.scss');

interface DragNDropDropInfo {
  index: number;
  item: any;
  external: boolean;
  type: ItemType;
}

enum ItemType {
  SourceItemType = 'elements',
  TargetItemType = 'item',
}

abstract class SignupFormAbstractController extends BaseController {
  static $inject = [
    '$scope',
    'signupFormModuleSettings',
    'signupFormsService',
    'modelNormalizer',
    '$state',
    'modalService',
  ];

  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  signupFormModuleSettings: any;
  model: BrandRegistrationForm;
  modalService: ModalService;
  $state: ng.ui.IStateService;
  opOnSourceDrop$ = new rx.Subject<DragNDropDropInfo>();
  formFields: SignupFormField[];
  modelNormalizer: ModelNormalizerService;

  // @ts-ignore
  fieldTypeMap = this.signupFormModuleSettings.fieldTypeMap;
  originalFormFields = [];

  getSignupFormFieldsByType = getSignupFormFieldsByType;

  constructor(...injectedProviders) {
    super(...injectedProviders);
    useStreams([this.streamSourceDrop()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.initSignupFormModel().then((data) => {
      this.model = data;
      // listen to changes on instrument and type (customer/lead) selection
      this.$scope.$watch(
        'vm.model.platformType',
        this.onInstrumentChange.bind(this),
      );
      this.$scope.$watch('vm.model.isLead', this.onIsLeadChange.bind(this));
      this.$scope.$watch('vm.model.brand', (newVal, oldVal) =>
        this.onBrandChange(newVal, oldVal),
      );
    });
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * This function is called whenever an item is dropped on the inventory of fields.
   * It should not accept fields marked as "required".
   */
  sourceDrop({ index, item, external, type }) {
    if (this.isRequired(item)) {
      return true;
    }
    this.opOnSourceDrop$.next({ index, item, external, type });
    return true;
  }

  /**
   * Reacts to items being dropped on to the source div of the elements.
   */
  streamSourceDrop() {
    return rx.pipe(
      () => this.opOnSourceDrop$,
      rx.tap((dndValue) => {
        const { item } = dndValue;
        const { field } = item;
        this.addToFormFields(field);
      }),
    )(null);
  }

  /**
   * @return {Promise} promise
   */
  abstract initSignupFormModel(): Promise<any>;

  /**
   * When the isLead flag changes, set required fields for current form type.
   *
   * @param {boolean} newVal - new value
   * @param {boolean} oldVal - old value
   * @return {void}
   */
  onIsLeadChange(newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }

    this.setForm();
  }

  onBrandChange(newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }

    this.setForm();
  }

  /**
   * Called on change instrument by user
   *
   * @param {boolean} newVal - new value
   * @param {boolean} oldVal - old value
   * @return {void}
   */
  onInstrumentChange(newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }

    this.setForm();
  }

  /**
   * Set the available form components and the created form components,
   * based on the selected platform type and form type.
   *
   * Note: it will reset the current form
   *
   * @return {void}
   */
  setForm() {
    if (!_.get('platformType', this.model)) {
      return;
    }
    const formFields = this.getSignupFormFieldsByType(
      this.model.platformType.code,
    );

    this.originalFormFields = formFields;
    // Required fields go to current form's components, the rest go to the available form fields
    [this.model.formComponents, this.formFields] = _.partitionEs<
      SignupFormField
    >(_.cloneDeep(formFields), (field) => this.isRequired(field));
    this.sortFormFields();
  }

  sortFormFields() {
    this.formFields.sort((itemA, itemB) =>
      itemA.field >= itemB.field ? 1 : -1,
    );
  }

  /**
   * Returns true if the given field is a required one (based on the current form type)
   *
   * Fields of type isMultiple can not be considered required.
   *
   * @param {object} field - field
   * @return {boolean} is required indication
   */
  isRequired(field) {
    const formType = this.model && this.model.isLead ? 'lead' : 'customer';
    return (
      !!field.requiredBySystem &&
      field.requiredBySystem.includes(formType) &&
      !field.isMultiple
    );
  }

  /**
   * called when a form element is dropped outside the list, the item will be removed
   *
   * the callback is called by the the angular-drag-and-drop-lists library
   * for more information look for dnd-drop on https://github.com/marceljuenemann/angular-drag-and-drop-lists
   *
   * @param {number} index - Field index (in form components array)
   * @param {object} item - Form field
   * @return {boolean} return true to allow the drop or false to prevent it
   */
  onOutsideDrop(index, item, external, itemType) {
    /*
     * drop is allowed on this element
     * if element source dnd-effect-allowed="move" the element will be
     * removed from source list by the plugin
     */
    if (this.isRequired(item)) {
      log.warn('Cannot remove required field "%s"', item.field);
      return false; // block the drop
    }

    this.addToFormFields(item.field);
    // Allow drop (item will be removed)
    return true;
  }

  /**
   * Drag drop event handler - when items is moved from the tools panel.
   *
   * @param {number} index - index of the item.
   * @return {void}
   */
  onToolsPanelItemMoved(index) {
    const fieldItem = this.formFields[index];

    // if field type is not singulary on a form, keep it in the tools panel.
    if (fieldItem.isMultiple) {
      return;
    }

    this.formFields.splice(index, 1);
  }

  /**
   * Drag drop event handler - when form item is moved. To different location in the
   * form, or to outside of the form.
   *
   * @param {number} index - index of the item.
   * @return {void}
   */
  onFormItemMoved(index) {
    this.model.formComponents.splice(index, 1);
  }

  /**
   * called after a successful post/patch.
   * Opens the 'view code' modal, then redirects to the list page when it is closed.
   * @param {*} response - response
   * @return {void}
   */
  onSubmitSuccess(response) {
    this.openViewCodeModal(response.formKey)
      // result is a promise which resolves when a modal is closed and rejected when a modal is dismissed.
      .result.then(() => {
        this.$state.go('^.list');
      });
  }

  /**
   * normalize form components for save/update action
   * @param {Array} components returns form components array with order property for each element
   * @return {*} normalized components
   */
  normalizeComponents(components) {
    const nullV = null;

    return components.map((component, index) => ({
      field: component.field,
      data: component.data,
      defaultValueForEndUser: _.defaultTo(
        nullV,
        component.defaultValueForEndUser,
      ),
      isMultiple: _.defaultTo(false, component.isMultiple),
      hasAutoFillOption: _.defaultTo(false, component.hasAutoFillOption),
      hasDefaultValueForAdmin: _.defaultTo(
        false,
        component.hasDefaultValueForAdmin,
      ),
      requiredBySystem: _.defaultTo([], component.requiredBySystem),
      isRequiredForEndUser: _.defaultTo(false, component.isRequiredForEndUser),
      isEnabledForSelectingValueInAdmin: _.defaultTo(
        false,
        component.isEnabledForSelectingValueInAdmin,
      ),
      order: index,
    }));
  }

  addToFormFields(fieldCode) {
    if (this.formFields.some((value) => value.field === fieldCode)) {
      return;
    }
    const fieldToAdd = this.originalFormFields.find(
      (originalField) => originalField.field === fieldCode,
    );
    if (!fieldToAdd) {
      throw new Error('Warning, Field was not found');
    }
    this.formFields.push(fieldToAdd);
    this.sortFormFields();
  }

  /**
   * Remove a field from the form array
   * @param {number} index - index of field to remove
   * @return {void}
   */
  remove(index, formItem) {
    this.model.formComponents.splice(index, 1);
    const { field } = formItem;
    this.addToFormFields(field);
  }

  /**
   * Opens the 'View form code' modal
   * @param {string} formKey - form key
   * @return {$uibModal} the newly created modal
   */
  openViewCodeModal(formKey) {
    return this.modalService.open({
      controller: 'FormBuilderViewCodeModalController',
      template: formCodeTemplate,
      // don't allowed modal closing by clicking on the background
      backdrop: 'static',
      scope: this.$scope,
      data: {
        formKey,
        brandId: this.model.brand.id,
      },
    });
  }
}

export default SignupFormAbstractController;
