import Promise from 'bluebird';
import template from './minute-select.html';
import BaseDropdown from './base/component';

class MinuteController extends BaseDropdown.controller {
  query() {
    return Promise.resolve([5, 15, 30]);
  }

  // Prevent translation
  get translateSource() {
    return '';
  }

  // We use a custom template. avoid adding "translatedName" attribute
  get translateDest() {
    return '';
  }
}

export default BaseDropdown.config({
  template,
  controller: MinuteController,
});
