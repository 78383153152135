import _ from 'underscore';
import BaseService from '~/source/common/services/baseService';
import { ITimeoutService } from 'angular';

const HIGHLIGHT_CLASS_NAME = 'prf-js-is-updated-color';
const HIGHLIGHT_TIME = 5000;

export class HighlightEntityService extends BaseService {
  static $inject = ['$timeout'];

  $timeout: ITimeoutService;

  highlight(entity) {
    // update the account
    _.extend(
      entity,
      // add highlight class
      {
        transitionClass: HIGHLIGHT_CLASS_NAME,
      },
    );

    // remove highlight class after defined amount of time
    this.$timeout(() => {
      entity.transitionClass = '';
    }, HIGHLIGHT_TIME);
  }

  highlightPure(getEntity: () => Record<string, any>) {
    const entity = getEntity();

    Object.assign(entity, {
      transitionClass: HIGHLIGHT_CLASS_NAME,
    });

    // remove highlight class after defined amount of time
    this.$timeout(() => {
      const entity = getEntity();
      entity.transitionClass = '';
    }, HIGHLIGHT_TIME);
  }
}
