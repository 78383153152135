import isValidCustomerSystemIp from '~/source/common/utilities/is-valid-customer-system-ip';
import BaseController from '~/source/common/controllers/base';
import TokensService from '~/source/auth/services/tokens';

import template from './api-user-form.html';

class Controller extends BaseController {
  hideSystemGroups: boolean;

  /*@ngInject */
  constructor(readonly tokensService: TokensService) {
    super();
  }

  $onInit() {
    this.hideSystemGroups = !this.tokensService.getCachedUser().isSystem;
  }

  /**
   * Event function - called when ng-tags-input notify after text was sent to the input and
   * is going to be added as a tag.
   *
   * This allows to check the input for validaty and return boolean true/false if to allow
   * insertion of tag to the list.
   *
   * @param {object} tag - tag object { text: string }
   * @return {boolean} allow or not allow insertion of value as a tag to the list.
   */
  onTagAdding(tag) {
    return isValidCustomerSystemIp(tag.ipAddress);
  }
}

const ApiUserFormComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    user: '<',
    isEdit: '<',
  },
};

export default ApiUserFormComponent;
