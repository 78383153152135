export function formatAssertionError(error) {
  let str = '*** Assertion Error ***\n';
  str += `${error.message}\n`;

  if (error.showDiff) {
    str += '\n';
    str += `- actual: ${JSON.stringify(error.actual)}\n`;
    str += `- expected: ${JSON.stringify(error.expected)}\n`;
  }

  return str;
}

export default formatAssertionError;
