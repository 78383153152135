import Component from '~/source/common/components/dropdowns/base/component';
import PlatformsService from '~/source/contact/common/services/platforms-public.service';

class TradingAccountTypesController extends Component.controller {
  static $inject = ['platformsPublicService', ...Component.controller.$inject];

  platformId: number;

  // Override default functionally with a no-op
  init() {}

  $onChanges(changesObj) {
    if (changesObj.platformId && this.platformId) {
      // fetch data again when platform type changes
      this.fetchData();
    }
  }

  query() {
    return (<PlatformsService>this.dataServiceInst)
      .getTradingAccountsResource(this.platformId)
      .getListWithQuery();
  }

  get defaultPlaceholder() {
    return 'common.SELECT_TYPE';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: TradingAccountTypesController,
  bindings: {
    platformId: '<',
  },
});
