import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { createAuthForm } from './create-auth-form';
import template from './login-auth-form.component.html';
const styles = require('./login-auth-form.component.scss');

export class LoginAuthFormController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  authForm = createAuthForm();

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const LoginAuthFormComponent = {
  template,
  controller: LoginAuthFormController,
  bindings: {
    auth: '&',
    resendCode: '&',
  },
};
