import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './verified-method-data.html';

class Controller extends BaseController {
  brand: Brand;

  prevAttributes: any;
  isEdit: boolean;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    super();
  }

  $onInit() {
    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current verification method model state so we could restore them
   * if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = {
      attachmentsVerifiedMethod: { ...this.brand.attachmentsVerifiedMethod },
    };

    // Enter edit mode
    this.isEdit = true;
  }

  save(newMethod) {
    const normalizedModel = {
      attachmentsVerifiedMethodId: newMethod.id,
      // `isRegulated` is duck typed model helper on brand in the view.
      isRegulated: (this.brand as any).isRegulated,
    };

    // save the brand
    return this.brandsService()
      .getBrandResource(this.brand.id)
      .setConfig({ blockUiRef: 'verifiesMethod' })
      .patchWithQuery(normalizedModel)
      .then(() => {
        this.isEdit = false;
        this.brand.attachmentsVerifiedMethod = newMethod;
      });
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    this.brand.attachmentsVerifiedMethod = this.prevAttributes.attachmentsVerifiedMethod;
    // Exit edit mode
    this.isEdit = false;
  }
}

const VerifiedMethodDataComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};

export default VerifiedMethodDataComponent;
