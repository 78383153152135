import Component from './base/component';

class TransactionStatusesController extends Component.controller {
  static $inject = [
    'transactionStatusesService',
    ...Component.controller.$inject,
  ];

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'transaction.statuses';
  }
}

export default Component.config({ controller: TransactionStatusesController });
