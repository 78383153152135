import * as _ from '@proftit/lodash';

import Restangular from 'restangular';

interface Model {
  id?: number;
}

export class ModelNormalizerService {
  static $inject = ['Restangular'];

  constructor(readonly restangular: Restangular.IService) {}

  /**
   * Normalizes the model.
   *
   * Creates a deep clone of the model and convert all sub-models (object which contains id)
   * to only "ids" (strips the objects)
   *
   * Works recursively.
   *
   * For example:
   *
   * { id: 2, country: {id: 2} } -> { id: 2, countryId: 2}
   * @param {object} model - model to be normalized
   * @returns {object} normalized model
   */
  normalize(modelParam, shouldStripRestAngular = true) {
    const normalizedModel: Model = {};
    let model = modelParam;

    // Model must be an object to be normalized!
    if (typeof model !== 'object') {
      return model;
    }

    if (model.id) {
      // Simplest case. When the model has an ID, we only need to keep this field. the others are irrelevant
      normalizedModel.id = model.id;

      return normalizedModel;
    }

    // this if was added because stripRestangular removes keys that seem trivial to use.
    // For example, it will strip a key called "options" if the model contains such a key, anywhere in the structure.
    // It is better to use .plain() on your model when you GET it, and not use the strip function.
    if (shouldStripRestAngular) {
      // Strip restangular fields
      model = this.restangular.stripRestangular(model);
    }

    _.eachEs(model, (val, prop) => {
      // ignore: angular special keys and functions
      if (
        (typeof prop === 'string' && prop.charAt(0) === '$') ||
        typeof val === 'function'
      ) {
        return;
      }

      // Check for deeper levels first: array, then object and recurse.

      if (_.isArrayEs(val)) {
        normalizedModel[prop] = [];
        val.forEach((el, key) => {
          const normalizedArrayValue = this.normalize(
            el,
            shouldStripRestAngular,
          );
          // for primitives: keep only defined value. for objects: keep only non empty objects
          if (
            (!_.isObjectEs(normalizedArrayValue) &&
              normalizedArrayValue !== undefined) ||
            !_.isEmpty(normalizedArrayValue)
          ) {
            normalizedModel[prop][key] = normalizedArrayValue;
          }
        });

        return;
      }

      if (_.isObjectEs(val)) {
        // id property is defined
        if ((val as any).id !== undefined) {
          // if this value has an id, create a new key for the id only.
          normalizedModel[`${prop}Id`] = (val as any).id;
          return;
        }

        // normalize next level
        const normalizedObject = this.normalize(val, shouldStripRestAngular);
        if (!_.isEmpty(normalizedObject)) {
          // Only add the normalized object if it's not empty
          normalizedModel[prop] = normalizedObject;
        }

        return;
      }

      // primitive value. no deeper levels
      normalizedModel[prop] = _.cloneEs(val);
    });

    return normalizedModel;
  }

  /**
   * Gets only the changed fields of the model.
   * Useful for making clean patch requests, with the minimal needed changes.
   * Note that it will only work if both inputs have the same structure, so you are
   * responsible to normalize both before.
   *
   * @example
   * diff({statusId: 1, userId: 2}, {statusId: 2, userId: 2}) -> {statusId: 2}
   *
   * @param {object} model - Current model
   * @param {object} prevModel - Previous model
   * @return {object} - model with only the different keys
   */
  diff(model, prevModel) {
    // get keys whose value differs from prev model
    return _.pickByEs(
      model,
      (value, key) => !_.isEqualEs(model[key], prevModel[key]),
    );
  }
}

export default ModelNormalizerService;
