import Promise from 'bluebird';
import Component from '../base/component';

/**
 * This select acts as sort of a "stub" in a way that it doesn't actually do any data requests:
 * instead, it relies on external data to be passed to it,
 * and it will just render it as-is.
 */
class ExternalDataSelectController extends Component.controller {
  data: any[];

  query(): Promise<any[]> {
    return Promise.resolve(this.data);
  }

  /**
   * Disable translation
   *
   * @returns {string}
   */
  get translateSource(): string {
    return '';
  }
}

const ExternalDataSelectComponent = Component.config({
  controller: ExternalDataSelectController,
  bindings: {
    data: '<',
  },
});

export default ExternalDataSelectComponent;
