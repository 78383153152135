import RestService from '~/source/common/services/rest';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';

const SERVICE_RESOURCE = 'clearingCompanyConnections';
const BATCH_ACTION_INDICATION = '~';

class ClearingCompanyConnectionsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  patchForBrand(brandId: number, data: { id: number; order: number }[]) {
    return this.resourceBuildStart()
      .getElement(BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .filter({
        brand: brandId,
      })
      .expand(['brand', 'clearingCompany'])
      .patchWithQuery(data);
  }

  patchConnection(connectionId: number, data: { isActive: boolean }) {
    return this.resourceBuildStart()
      .getElement(connectionId)
      .resourceBuildEnd()
      .expand(['brand', 'clearingCompany'])
      .patchWithQuery<IElementRestNg<ClearingCompanyConnection>>(data);
  }
}

export default ClearingCompanyConnectionsService;
