import * as _ from '@proftit/lodash';

import moment from 'moment';
import ngModule from '../index';

function filter(appConfig, $translate, $interpolate, filtersSettings) {
  // general formatter
  const formatters = {
    /**
     * use the name field
     * @example {id:1, name: "dudu"}, balanceToal: 1112.21 => dudu
     * @returns {String} returns the name field
     */
    name: (fieldName, dataObj) => {
      const fieldValue = dataObj[fieldName];

      if (_.isEmpty(fieldValue) || _.isUndefined(fieldValue.name)) {
        return null;
      }

      return fieldValue.name;
    },

    /**
     * group all name fields of array and return a comma-separated string
     * @example [{id:1, name:"name 1"},{is: 2, name: "name 2"},{id: 3, name: "name 3"}] => name 1, name 2, name 3
     * @returns {String} returns comma-separated string of names
     */
    names: (fieldName, dataObj) => {
      const fieldValue = dataObj[fieldName];

      if (_.isEmpty(fieldValue)) {
        return null;
      }

      return fieldValue.map(({ name }) => name).join(', ');
    },

    /**
     * group all name fields of brands campaigns array and return a
     * comma-separated string as (id-name, id-name)
     * @example [{id:1, name:"newName"},{id: 2, name: "testName"}] => 1-newName, 2-testName
     * @returns {String} returns comma-separated string of names
     */
    apiUserCampaigns: (fieldName, dataObj) => {
      if (!dataObj || !dataObj.brands) {
        return null;
      }

      const allCampaigns = _.mapEs(dataObj.brands, 'campaigns');
      return _.flattenEs(allCampaigns)
        .map((campaign) => `${campaign.id} - ${campaign.name}`)
        .join(' ,');
    },

    /**
     * group all apiUser ip addresses fields and return a comma-separated string
     * @example [{id:1, ipAddress:"127.11.12.11"},{id: 2, ipAddress: "122.13.12.11"}] => 127.11.12.11, 122.13.12.11
     * @returns {String} returns comma-separated string of ips
     */
    apiUserWhitelistIps: (fieldName, dataObj) => {
      // array for all whitelistIps object in dataObj
      const fieldValue = dataObj[fieldName];

      if (_.isEmpty(fieldValue)) {
        return null;
      }

      return fieldValue.map(({ ipAddress }) => ipAddress).join(', ');
    },

    /**
     * group all name fields of array and return a comma-separated string
     * @example [{id:1, name:"name 1"},{is: 2, name: "name 2"},{id: 3, name: "name 3"}] => name 1, name 2, name 3
     * @returns {String} returns comma-separated string of names
     */
    minimumDeposits: (fieldName, dataObj) => {
      const fieldValue = dataObj[fieldName];

      if (_.isEmpty(fieldValue)) {
        return null;
      }

      return fieldValue
        .map(
          ({ value, minimumDeposit, currency }) =>
            `${currency.code} ${value !== undefined ? value : minimumDeposit}`,
        )
        .join(', ');
    },

    /**
     * combine firstName + lastName
     * @example {firstName: "john", lastName: "da-Man"} => john da-Man
     * @returns {string} returns full name
     */
    fullName: (fieldName, dataObj) => {
      const fieldValue = dataObj[fieldName];

      if (
        _.isEmpty(fieldValue) ||
        (_.isUndefined(fieldValue.firstName) &&
          _.isUndefined(fieldValue.lastName))
      ) {
        return null;
      }

      return `${fieldValue.firstName ? fieldValue.firstName : ''} ${
        fieldValue.lastName ? fieldValue.lastName : ''
      }`;
    },

    /**
     * format currency
     * @example 10.04 => USD 10
     * @returns {String} returns rounded number with a currency name
     */
    currency: (fieldName, dataObj) =>
      dataObj[fieldName] === undefined || dataObj[fieldName] === null
        ? ''
        : `${appConfig.baseCurrency.code} ${Math.round(dataObj[fieldName])}`,

    /**
     * convert time zone offset to local customer time
     * @example "2017-08-31 00:05:36" =>  August 31, 2017 5:36 AM
     * @returns {String} returns local customer time with am/pm indicator
     */
    localTime: (fieldName, dataObj) =>
      moment().utcOffset(dataObj[fieldName]).format('LLL'),
  };

  // assign fields to formatters
  const fieldFormatter = {
    country: formatters.name,
    customerStatus: formatters.name,
    brand: formatters.name,
    brands: formatters.names,
    desk: formatters.name,
    campaign: formatters.name,
    timeZoneOffset: formatters.localTime,
    communicationStatus: formatters.name,
    migratedStatus: formatters.name,
    tradeReputation: formatters.name,
    user: formatters.fullName,
    customerComplianceStatus: formatters.name,
    blacklistedCountries: formatters.names,
    minimumDeposits: formatters.minimumDeposits,
    currencyConnections: formatters.minimumDeposits,
    apiUserCampaigns: formatters.apiUserCampaigns,
    whitelistIps: formatters.apiUserWhitelistIps,
  };

  /**
   * parse table cell field values
   *
   * @param {Object} dataObj table row data
   * @param {String} field name of the current parsed field
   * @param {String} fieldFormat field format for interpolate
   *
   * @returns {String} parsed table cell field
   */
  return function (dataObj, { field, fieldFormat }) {
    // initial value
    let value = dataObj[field];

    if (fieldFormat) {
      value = $interpolate(fieldFormat)(dataObj);
    } else if (_.isFunctionEs(fieldFormatter[field])) {
      /*
       * find a formatter for the specific field and use it
       * if non will be found, value will be returned as-is
       */
      value = fieldFormatter[field](field, dataObj);
      /**
       * If this field has a filter configuration, try to deduce the needed formatter based on it
       */
    } else if (_.hasEs(filtersSettings, `${field}.state.type`)) {
      const filterType = _.getEs(filtersSettings, `${field}.state.type`);
      const filterTypesMap = {
        // if filter is 'monetary range', it must mean this is a currency field
        monetaryRange: formatters.currency,
      };
      if (filterTypesMap[filterType]) {
        value = filterTypesMap[filterType](field, dataObj);
      }
    }

    return value;
  };
}

filter.$inject = ['appConfig', '$translate', '$interpolate', 'filtersSettings'];

ngModule.filter('tableFieldFormat', filter);
