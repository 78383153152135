/***
 * Activate fetching function until all data is retrive.
 */
export function fetchAllWithPaging(
  size: number,
  fetchFn: (page: number, size: number) => any,
  getTotalFn: () => number,
  currPage = 1,
) {
  return fetchFn(currPage, size).then((data) => {
    const isLastPage = (currPage - 1) * size + data.length >= getTotalFn();

    if (isLastPage) {
      return data;
    }

    return fetchAllWithPaging(
      size,
      fetchFn,
      getTotalFn,
      currPage + 1,
    ).then((nextPageData) => [...data, ...nextPageData]);
  });
}
