import RestService from '~/source/common/services/rest';

export class KibiReportCsvService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'kibi/downloadReports';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return false;
  }

  create({
    kibi_query,
    columnAliases,
    columns,
    indexPatternId,
    indexPatternTitle,
  }) {
    return this.postWithQuery({
      kibi_query,
      columnAliases,
      columns,
      indexPatternId,
      indexPatternTitle,
    });
  }
}

export default KibiReportCsvService;
