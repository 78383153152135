export const translatedQuestionnaireTableCols = [
  {
    renderer: 'name',
    title: 'questionnaire.translations.NAME',
    field: '',
    fieldFormat: '{{ name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    renderer: 'redirectLink',
    title: 'questionnaire.translations.REDIRECT_LINK',
    field: '',
    fieldFormat: '{{ redirectUrl }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'questionnaire.translations.LANGUAGE',
    field: '',
    fieldFormat: '{{ language.name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'questionnaire.translations.STATUS',
    field: '',
    fieldFormat:
      '{{ isActive | booleanToWord:"booleanToWord.active" | translate }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    '//comment': 'empty column for the cogwheel of actions',
    title: '',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
