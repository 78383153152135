import { CustomContentTemplate, Brand } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { CustomEmailTemplatesTableAction } from './custom-email-templates-table-actions';

export function calcMenuItem(
  item: CustomContentTemplate,
  updatePerm,
  deletePerm,
  createPerm,
) {
  return _.flow([
    () => [],
    (menu) =>
      updatePerm
        ? [
            ...menu,
            {
              actionCode: CustomEmailTemplatesTableAction.Edit,
              labelCode: 'common.EDIT',
            },
          ]
        : menu,
    (menu) => (updatePerm ? [...menu, calcActivateMenuItem(item)] : menu),
    (menu) => calcCopyMenuItem(item, createPerm, menu),
    (menu) =>
      deletePerm
        ? [
            ...menu,
            {
              actionCode: CustomEmailTemplatesTableAction.Delete,
              labelCode: 'common.DELETE',
            },
          ]
        : menu,
  ])();
}

function calcActivateMenuItem(item: CustomContentTemplate) {
  if (item.isActive) {
    return {
      actionCode: CustomEmailTemplatesTableAction.Deactivate,
      labelCode: 'common.DEACTIVATE',
    };
  }

  return {
    actionCode: CustomEmailTemplatesTableAction.Activate,
    labelCode: 'common.ACTIVATE',
  };
}

function calcCopyMenuItem(item, createPerm: boolean, menu) {
  if (!createPerm) {
    return menu;
  }

  return [
    ...menu,
    {
      actionCode: CustomEmailTemplatesTableAction.Copy,
      labelCode: 'common.COPY',
    },
  ];
}
