import { ClearingCompanyConnectionsListType } from './../clearing-company-connections-list-type';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import { patchIsActiveConnection } from './../helper-functions/patch-is-active-connection';

export const patchConnectionIsActiveAsTrue = (
  formAction,
  dataForBrand,
  clearingCompanyConnectionsService: ClearingCompanyConnectionsService,
) => {
  const { data } = formAction;
  const isActive =
    data.type === ClearingCompanyConnectionsListType.ActiveClearingCompany;
  if (isActive) {
    return Promise.resolve([formAction, dataForBrand]);
  }
  return patchIsActiveConnection(
    data.item.id,
    true,
    clearingCompanyConnectionsService,
  ).then(() => {
    return [formAction, dataForBrand];
  });
};
