import ng, { ITimeoutService } from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './if-container.component.html';

export class IfContainerController extends BaseController {
  localTrigger;

  /* @ngInject */
  constructor(readonly $timeout: ITimeoutService) {
    super();
  }

  onTriggerChange(val) {
    this.localTrigger = null;

    this.$timeout(() => {
      this.localTrigger = val;
    }, 0);
  }
}

export const IfContainerComponent = {
  template,
  controller: IfContainerController,
  bindings: {
    trigger: '<',
  },
  transclude: true,
};
