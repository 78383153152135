import TableController from '../common/components/table/table.controller';
import { SearchService } from './search.service';

abstract class SearchResultsBaseController extends TableController {
  static $inject = [
    'searchService',
    '$stateParams',
    'searchResultsModuleSettings',
    ...TableController.$inject,
  ];

  searchService: () => SearchService;
  searchResultsModuleSettings;

  resultsCount: number;
  abstract get searchMethodName(): string;

  // Disable params caching: we don't want it to remember current page/sort etc
  filterCacheKey = null;
  tableCacheKey = null;

  constructor(...args) {
    super(...args);

    this.settings = this.searchResultsModuleSettings;
    this.dataServiceInstance = this.searchService();
  }

  $onInit() {
    super.$onInit();
    this.initTable();
  }

  get tableKey() {
    return 'search';
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /*
   * Override the getData method, as we need to use a custom fetch function here which fetches
   * a single item ("one") and not list
   */
  getData(params) {
    /**
     * Params from the state are uri-encoded. must encode them before sending them to the $http service,
     * as the $http service also does encoding. so we want to avoid doing double-encoding.
     */
    const searchString = decodeURIComponent(this.$stateParams.searchString);
    const count = params.count();
    const page = params.page();

    const searchService = this.dataServiceInstance
      .setConfig({ blockUiRef: 'searchTableBlock' })
      .setPage(page, count)
      .search(searchString);

    return searchService[this.searchMethodName]() // call function on search service to fetch data by category type
      .then((data) => {
        params.total(this.dataServiceInstance.total);
        this.resultsCount = this.dataServiceInstance.total;
        return data.results;
      });
  }

  /**
   * Needed for super abstract method cover. Not in use.
   */
  fetchFn() {
    return null;
  }
}

export default SearchResultsBaseController;
