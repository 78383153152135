import ng, { ui } from 'angular';
import ForgotPasswordModule from './forgot-password/module';
import { LoginModule } from './login/login.module';
import AuthenticationService from './services/authentication';
import TokensService from './services/tokens';
import PasswordResetEmailService from './services/password-reset-email';

export const AuthModule = ng.module('crm.auth', [
  'ui.router',
  'crm.common',
  ForgotPasswordModule,
  LoginModule.name,
]);

const md = AuthModule;
md.service('authenticationService', AuthenticationService);
md.service('passwordResetEmail', PasswordResetEmailService);
md.service('tokensService', TokensService);
md.config([
  '$stateProvider',
  ($stateProvider: ui.IStateProvider) => {
    $stateProvider.state('auth', {
      abstract: true,
      url: '/auth',
      template: '<ui-view/>',
    });
  },
]);
