import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import template from './cfd-platform-group-edit-dialog.html';
import * as _ from '@proftit/lodash';
import { PlatfromGroupsService } from '../../services/platform-groups.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { CfdPlatformGroup } from '~/source/common/models/cfd-platform/cfd-platform-group';
import { NgModelChange } from '~/source/common/utilities/create-ng-model-obs-mediator';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';

import { Changeset } from '~/source/common/utilities/changeset/changeset';
import { resetFieldUpdate } from '~/source/common/utilities/changeset/reset-field-update';
import { setFieldUpdate } from '~/source/common/utilities/changeset/set-field-update';
import { init as initChangeset } from '~/source/common/utilities/changeset/init';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import {
  PlatformsWithSwapAbility,
  PlatformCode,
} from '@proftit/crm.api.models.enums';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';

export interface ICfdPlatformGroupEditComponent {
  groupId: number;
  operation: CrudOpeartion;
  platformConnection: PlatformConnection;
}

export class CfdPlatformBrandGroupEditDialogController {
  resolve: ICfdPlatformGroupEditComponent;

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  lifecycles = observeComponentLifecycles(this);

  resolve$ = observeShareCompChange<ICfdPlatformGroupEditComponent>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  operation$ = this.resolve$.pipe(
    rx.map(({ operation }) => operation),
    shareReplayRefOne(),
  );

  platformConnection$ = this.resolve$.pipe(
    rx.map(({ platformConnection }) => platformConnection),
    shareReplayRefOne(),
  );

  groupId$ = this.resolve$.pipe(
    rx.map(({ groupId }) => groupId),
    shareReplayRefOne(),
  );

  group$ = new rx.BehaviorSubject<CfdPlatformGroup>(null);

  doOperation$ = new rx.Subject<void>();

  opUpdateUiGroup$ = new rx.Subject<NgModelChange>();

  changeSet$ = new rx.BehaviorSubject<Changeset<CfdPlatformGroup>>(null);

  isFormValid$ = new rx.BehaviorSubject<boolean>(true);

  showSwapCommission$ = new rx.BehaviorSubject<boolean>(false);

  isConfirmButtonDisabled$ = this.streamIsConfirmButtomDisabled();

  initScrean$ = this.streamInitScreen();

  isLoading$ = this.streamIsLoading();

  close: () => void;

  title: string;

  blockUiId = 'cfdPlatformBrandGroupEditDialogBlockUi';

  growlId = 'cfdPlatformBrandGroupEditDialogGrowl';

  /*@ngInject */
  constructor(
    readonly prfPlatformGroupsService: PlatfromGroupsService,
    readonly brandsService: () => BrandsService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
  ) {
    useStreams(
      [this.resolve$, this.operation$, this.platformConnection$, this.groupId$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamSession(),
        this.initScrean$,
        this.streamDoOperation(),
        this.streamUiGroupUpdating(),
        this.streamCalcShowSwapCommission(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  streamSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.platformConnection$,
          this.prfCurrentPlatformSession$,
        ),
      rx.filter((params) => params.every((p) => !_.isNil(p))),
      rx.switchMap(([conn, sessionStore]) => {
        return sessionStore.login(conn);
      }),
    )(null);
  }

  streamInitScreen() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.operation$,
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((store) => !_.isNil(store)),
            rx.switchMap((store) => store.sessionS.stream$),
          ),
          this.groupId$,
          this.lifecycles.onInitShared$.pipe(rx.filter((isInit) => isInit)),
        ),
      rx.filter(
        ([_operation, sessionInfo, _groupId, _a]) => sessionInfo.isLoggedIn,
      ),
      this.tapStartAsyncWorkInUiMain(),
      rx.switchMap(([operation, sessionInfo, groupId]) => {
        this.title =
          operation === CrudOpeartion.Create
            ? 'riskManager.ADD_GROUP'
            : 'riskManager.EDIT_GROUP';
        if (operation === CrudOpeartion.Create) {
          return rx.obs.from([
            {
              name: '',
              leverage: 0,
              spread: 0,
            },
          ]);
        }
        if (operation === CrudOpeartion.Update) {
          const req = this.prfPlatformGroupsService.initPrivateReq(
            sessionInfo.session.apiUrl,
            sessionInfo.session.token,
          );
          return rx.pipe(
            () =>
              rx.obs.from(this.prfPlatformGroupsService.getAsOne(groupId, req)),
            rx.catchError((_err, _caught$) => rx.obs.from([null])),
          )(null);
        }

        throw new Error('not implemented');
      }),
      this.tapStopAsyncWorkInUiMain(),
      rx.tap((group: CfdPlatformGroup) => {
        const normalizedGroup = {
          ...group,
          maxInvestment: group.maxInvestment
            ? (group.maxInvestment as any).usd
            : group.maxInvestment,
          minInvestment: group.minInvestment
            ? (group.minInvestment as any).usd
            : group.minInvestment,
        };
        this.group$.next(normalizedGroup);
        this.changeSet$.next(initChangeset(normalizedGroup));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDoOperation() {
    return rx.pipe(
      () => this.doOperation$,
      rx.withLatestFrom(
        this.group$,
        this.changeSet$,
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((service) => !_.isNil(service)),
          rx.switchMap((service) => service.sessionS.stream$),
        ),
        this.operation$,
      ),
      this.tapStartAsyncWorkInUiMain(),
      rx.switchMap(([a, group, changeset, sessionInfo, operation]) => {
        const operationThread = (() => {
          if (operation === CrudOpeartion.Update) {
            return rx.obs.from(this.updateGroup(changeset, sessionInfo));
          }
          if (operation === CrudOpeartion.Create) {
            return rx.obs.from(this.createGroup(group, sessionInfo));
          }

          throw new Error('not implemented');
        })();

        return operationThread.pipe(rx.tap((res) => this.close()));
      }),
      this.tapStopAsyncWorkInUiMain(),
      rx.catchError((err, obs) => {
        return obs;
      }),
    )(null);
  }

  streamUiGroupUpdating() {
    return rx.pipe(
      () => this.opUpdateUiGroup$,
      rx.withLatestFrom(this.changeSet$, this.group$),
      rx.map(([change, changeset, currentGroup]) => {
        const newChangeset = (function () {
          const origValue = _.defaultTo(
            null,
            changeset.original[change.fieldName],
          );

          if (origValue === change.nextValue) {
            return resetFieldUpdate(change.fieldName, changeset);
          }

          return setFieldUpdate(change.fieldName, change.nextValue, changeset);
        })();
        const newGroup = {
          ...currentGroup,
          [change.fieldName]: change.nextValue,
        };
        return [newGroup, newChangeset];
      }),
      rx.tap(([group, changeset]) => {
        this.group$.next(group as CfdPlatformGroup);
        this.changeSet$.next(changeset as Changeset<CfdPlatformGroup>);
      }),
    )(null);
  }

  streamCalcShowSwapCommission() {
    return rx.pipe(
      () => this.platformConnection$,
      rx.map((platformConnection) => platformConnection.platform),
      rx.map((platform) =>
        PlatformsWithSwapAbility.includes(platform.code as PlatformCode),
      ),
      rx.tap((show) => this.showSwapCommission$.next(show)),
    )(null);
  }

  streamIsConfirmButtomDisabled() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.isFormValid$, this.changeSet$),
      rx.map(([isFormValid, changeset]) => {
        if (!isFormValid) {
          return true;
        }

        if (_.isNil(changeset)) {
          return true;
        }

        return changeset.changes.length === 0;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamIsLoading() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.initScrean$.pipe(
            rx.map(() => false),
            rx.startWith(true),
          ),
        ),
      rx.map((conds) => conds.some((c) => c)),
      shareReplayRefOne(),
    )(null);
  }

  updateGroup(
    changeset: Changeset<CfdPlatformGroup>,
    sessionInfo: PlatformSessionInfo,
  ) {
    const groupUpdates = changeset.changes.reduce((acc, change) => {
      return {
        ...acc,
        [change.fieldName]: change.nextValue,
      };
    }, {});

    const req = this.prfPlatformGroupsService.initPrivateReq(
      sessionInfo.session.apiUrl,
      sessionInfo.session.token,
    );
    return this.prfPlatformGroupsService.update(
      changeset.original.id,
      groupUpdates,
      req,
    );
  }

  createGroup(group: CfdPlatformGroup, sessionInfo: PlatformSessionInfo) {
    const req = this.prfPlatformGroupsService.initPrivateReq(
      sessionInfo.session.apiUrl,
      sessionInfo.session.token,
    );
    return this.prfPlatformGroupsService.create(group, req);
  }

  tapStartAsyncWorkInUiMain() {
    return tapStartAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  tapStopAsyncWorkInUiMain() {
    return tapStopAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  setCurrentPlatformSession(
    currentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController,
  ) {
    this.prfCurrentPlatformSession$.next(currentPlatformSession);
  }
}

export const CfdPlatformBrandGroupEditDialogComponent = {
  template,
  controller: CfdPlatformBrandGroupEditDialogController,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};

export default CfdPlatformBrandGroupEditDialogComponent;
