import RestService from '~/source/common/services/rest';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import log from 'loglevel';

export function requestWithComponent(
  workFn,
  defaultOnErrorFn,
  restService: RestService,
  component,
) {
  return rx.obs
    .from(
      _.flow([
        (s) => (_.isFunction(s) ? s() : s),
        (s) =>
          s.setConfig({
            growlRef: component.growlId,
            blockUiRef: component.blockUiId,
          }),
        (s) => workFn(s),
      ])(restService),
    )
    .pipe(
      rx.catchError((err, caught) => {
        log.error('error perform request', err);
        return defaultOnErrorFn();
      }),
    );
}
