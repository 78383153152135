import ng, { IOnChangesObject } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { TemplatePlaceholdersService } from '~/source/common/api-crm-server/services/template-placeholders.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { ClipboardService } from 'angular-clipboard';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import log from 'loglevel';
import { TemplatePlaceholder } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';
import template from './template-placeholder-simple-list.component.html';

const styles = require('./template-placeholder-simple-list.component.scss');

function generateInputChangeComponetStream<T>(
  fieldName: string,
  onChanges$: rx.Observable<IOnChangesObject>,
) {
  return rx.pipe(
    () => onChanges$,
    getCompChange<T>(fieldName),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);
}

export class TemplatePlaceholderSimpleListController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  typesToShow$ = generateInputChangeComponetStream<string[]>(
    'typesToShow',
    this.lifecycles.onChanges$,
  );
  templatePlaceholders$ = this.streamTemplatePlaceholders();

  /*@ngInject */
  constructor(
    readonly templatePlaceholdersService: () => TemplatePlaceholdersService,
    readonly clipboard: ClipboardService,
    readonly growl: angular.growl.IGrowlService,
  ) {
    useStreams([this.typesToShow$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamTemplatePlaceholders() {
    return rx.pipe(
      () => this.typesToShow$,
      rx.switchMap((types) => {
        return rx.obs
          .from(
            this.templatePlaceholdersService()
              .setConfig({
                blockUiRef: this.blockUiId,
                growlRef: this.growlId,
              })
              .getByTypes(types),
          )
          .pipe(
            rx.catchError((error, caught) => {
              log.error('error fetching placeholders', error);
              return rx.obs.from([[] as TemplatePlaceholder[]]);
            }),
          );
      }),
      rx.map((tags) =>
        tags.map((tag) => ({ id: tag.id, label: `%${tag.code}%` })),
      ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  copyTextToClipboard(text: string) {
    this.clipboard.copyText(text);
    this.growl.info('Text was copied to clipboard', {
      referenceId: this.growlId as any,
      ttl: 3000,
    });
  }
}

export const TemplatePlaceholderSimpleListComponent = {
  template,
  controller: TemplatePlaceholderSimpleListController,
  bindings: {
    typesToShow: '<',
  },
};
