import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import template from './user-report-generation-download-handler.html';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import { Download } from '~/source/common/models/download';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { DownloadStatus } from '~/source/common/models/download-status';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  userKibiExportUpdateChannelName,
  userCrmExportUpdateChannelName,
  userBalanceLogExportUpdateChannelName,
} from '@proftit/crm.api.channels';
import { DownloadSource } from '../download-source';
import { CrmDownloadsSocketService } from '../crm-downloads-socket.service';
import { switchOn } from '@proftit/general-utilities';
import { KibiDownloadsService } from '../kibi-downloads.service';
import { CrmDownloadsService } from '../crm-downloads.service';
import { BalanceLogDownloadsService } from '~/source/reports/balance-log-downloads.service';

class Controller {
  onChangeOfDownload: (a: { change }) => {};
  user: UserTokenModel;
  download: IElementRestNg<Download>;

  lifecycles = observeComponentLifecycles(this);
  inputDownload$ = observeShareCompChange<Download>(
    this.lifecycles.onChanges$,
    'download',
  );
  inputUser$ = observeShareCompChange<UserTokenModel>(
    this.lifecycles.onChanges$,
    'user',
  );
  inputDownloadSource$ = observeShareCompChange<DownloadSource>(
    this.lifecycles.onChanges$,
    'downloadSource',
  );
  opCancelDownload$ = new rx.Subject<void>();
  downloadCanceledS$ = new rx.Subject<Download>();
  download$ = this.streamDownload();
  showDownload$ = this.streamShowDownload();

  showConfirmCancel = false;
  blockUiId = 'userReportGenerateDownloadHandlerBlockUi';
  growlId = 'userReportGenerateDownloadHandlerGrowl';

  /* @ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly kibiDownloadsService: KibiDownloadsService,
    readonly crmDownloadsService: CrmDownloadsService,
    readonly balanceLogDownloadsService: () => BalanceLogDownloadsService,
    readonly kibiDownloadSocketService,
    readonly crmDownloadsSocketService: CrmDownloadsSocketService,
  ) {
    useStreams(
      [
        this.inputDownload$,
        this.inputUser$,
        this.inputDownloadSource$,
        this.streamNotifyChangeOfDownload(),
        this.streamCancelDownload(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamKibiDownloadFromSocket(): rx.Observable<Download> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.inputUser$,
          this.inputDownload$,
          this.inputDownloadSource$,
        ),
      rx.filter(
        ([user, download, downloadSource]) =>
          downloadSource === DownloadSource.Kibi,
      ),
      rx.switchMap(([user, download]) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userKibiExportUpdateChannelName(
          user.id,
          download.id,
        );
        return observeChannel<Download>(
          this.kibiDownloadSocketService,
          channelName,
        );
      }),
      shareReplayRefOne<Download>(),
    )(null);
  }

  streamCrmDownloadFromSocket(): rx.Observable<Download> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.inputUser$,
          this.inputDownload$,
          this.inputDownloadSource$,
        ),
      rx.filter(
        ([user, download, downloadSource]) =>
          downloadSource === DownloadSource.CrmExport,
      ),
      rx.switchMap(([user, download]) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userCrmExportUpdateChannelName(
          user.id,
          download.id,
        );
        return observeChannel<Download>(
          this.crmDownloadsSocketService,
          channelName,
        );
      }),
      shareReplayRefOne<Download>(),
    )(null);
  }

  streamBalanceLogDownloadFromSocket(): rx.Observable<Download> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.inputUser$,
          this.inputDownload$,
          this.inputDownloadSource$,
        ),
      rx.filter(
        ([user, download, downloadSource]) =>
          downloadSource === DownloadSource.BalanceLogExport,
      ),
      rx.switchMap(([user, download]) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userBalanceLogExportUpdateChannelName(
          user.id,
          download.id,
        );
        return observeChannel<Download>(
          this.crmDownloadsSocketService,
          channelName,
        );
      }),
      shareReplayRefOne<Download>(),
    )(null);
  }

  streamDownloadSocketUpdates(): rx.Observable<Download> {
    return rx.pipe(
      () => rx.obs.combineLatest(this.inputUser$, this.inputDownloadSource$),
      rx.switchMap(([user, downloadSource]) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        return switchOn(
          {
            [DownloadSource.Kibi]: () => this.streamKibiDownloadFromSocket(),
            [DownloadSource.CrmExport]: () =>
              this.streamCrmDownloadFromSocket(),
            [DownloadSource.BalanceLogExport]: () =>
              this.streamBalanceLogDownloadFromSocket(),
          },
          downloadSource,
          () => {
            throw new Error('unimplemented download source');
          },
        );
      }),
      shareReplayRefOne<Download>(),
    )(null);
  }

  streamDownload(): rx.Observable<Download> {
    return rx.pipe(
      () =>
        rx.obs.merge(this.inputDownload$, this.streamDownloadSocketUpdates()),
      shareReplayRefOne(),
    )(null);
  }

  streamShowDownload() {
    return rx.pipe(
      () => this.download$,
      rx.map((download) => {
        if (download.status === 'cancelled') {
          return false;
        }

        if (download.status === 'done') {
          return false;
        }

        return true;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDownloadCompleted() {
    return rx.pipe(
      () => this.download$,
      rx.filter(
        (download) =>
          download.progress >= 100 && download.status === DownloadStatus.Done,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyChangeOfDownload() {
    return rx.pipe(
      () =>
        rx.obs.merge(this.streamDownloadCompleted(), this.downloadCanceledS$),
      rx.tap((download) => {
        this.onChangeOfDownload({ change: download });
      }),
    )(null);
  }

  streamCancelDownload() {
    return rx.pipe(
      () => this.opCancelDownload$,
      rx.withLatestFrom(this.download$, this.inputDownloadSource$),
      rx.filter(([op, download, downloadSource]) => !_.isNil(download)),
      rx.switchMap(([op, download, downloadSource]) =>
        rx.obs.from(this.sendCancelReq(download, downloadSource)),
      ),
      rx.tap((download) => this.downloadCanceledS$.next(download)),
    )(null);
  }

  sendCancelReq(
    download: Download,
    downloadSource: DownloadSource,
  ): Promise<Download> {
    let service;
    switch (downloadSource) {
      case DownloadSource.Kibi:
        service = this.kibiDownloadsService;
        break;
      case DownloadSource.CrmExport:
        service = this.crmDownloadsService;
        break;
      case DownloadSource.BalanceLogExport:
        service = this.balanceLogDownloadsService();
        break;
      default:
        throw new Error(
          `Unknown DownloadSource was provided. ${downloadSource}`,
        );
    }

    return service
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
      })
      .patchElement(download.id, {
        status: DownloadStatus.Cancelled,
      });
  }
}

const UserReportGenerationDownloadHandlerComponent = {
  template,
  controller: Controller,
  bindings: {
    download: '<',
    downloadSource: '<',
    user: '<',
    onChangeOfDownload: '&',
  },
};

export default UserReportGenerationDownloadHandlerComponent;
