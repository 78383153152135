import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class RiskFreeNotificationsController extends NotificationsController {}

RiskFreeNotificationsController.$inject = [
  'riskFreesSocketService',
  ...NotificationsController.$inject,
];

export default {
  ...ComponentOptions,
  controller: RiskFreeNotificationsController,
};
