import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';

export class DesignTemplatesLogsService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'designTemplatesLogs';
  }

  /**
   *
   */
  resourceFullList(config, sort, expand, embed) {
    return this.setConfig(config).sort(sort).expand(expand).embed(embed);
  }
}
