import ng, { growl } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  ItemProxy,
  // ValueBean,
  generateArrayBean,
  generateValueBean,
  getBeanProxy,
  getProxyBean,
  generateObjectBean,
  // logicalAndProxyValidatorFactory,
  // getWholeValueOfBean,
  setWholeBean,
  allChildrenAreValidProxyValidator,
} from '@proftit/proxy-bean';
import {
  observeObjectProxyFieldValue,
  observeProxyIsValid,
  observeObjectProxyValue,
} from '@proftit/proxy-bean-rxjs';
import template from './ftd-assigment-manager.component.html';
import {
  Brand,
  CustomerStatus,
  FtdAutoAssignment,
} from '@proftit/crm.api.models.entities';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { CurrentBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-brand-store-service.directive';
import { FtdAutoAssigmentData } from '~/source/management/brand/services/brands';
import { generateUuid } from '@proftit/general-utilities';
import { FtdAutoAssignmentType } from '@proftit/crm.api.models.enums';
import { PrfRxService } from '~/source/common/services/prf-rx.service';
import { emptyUserOption } from '../empty-user-option';
import { ftdAutoAssignmentDestIsNotEmptyIfSourceIsFilledProxyValidator } from './ftd-auto-assignment-dest-is-not-empty-if-source-is-filled.proxy-validator';
import { ftdAutoAssignmentSourceIsNotEmptyIfDestIsFilledProxyValidator } from './ftd-auto-assignment-source-is-not-empty-if-dest-is-filled.proxy-validator';
import { emptyDeskOption } from '../empty-desk-option';
import { ftdAutoAssignmentWhenOtherFilledUserMustBeFilledProxyValidator } from './ftd-auto-assignment-when-other-filed-user-must-is-filled.proxy-validator';
import PopupService from '~/source/common/components/modal/popup.service';
import { ftdAutoAssignmentsUniquebySourceDeskProxyValidator } from './ftd-auto-assignments-uniuqe-by-source-desk.proxy-validator';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
const styles = require('./ftd-assigment-manager.component.scss');

interface MainForm {
  isNotAssigneeFtdAutoAssignmentEnabled: boolean;
  isAssigneeFtdAutoAssignmentEnabled: boolean;
  isAssigneeFtdAutoAssignmentWithDelayEnabled: boolean;
  isAssigneeFtdAutoAssignmentByCustomerStatusEnabled: boolean;
  ftdAutoAssignmentDelay: number;
  ftdAutoAssignmentCustomerStatus: CustomerStatus;
  ftdAutoAssigmentForNonAssigneeList: FtdAutoAssignment[];
  ftdAutoAssigmentForAssigneeList: FtdAutoAssignment[];
}

export class FtdAssigmentManagerController {
  /* require */

  prfCurrentBrand: CurrentBrandStoreServiceDirectiveController;

  /* bindings */

  brand: Brand;

  /* state */

  styles = styles;

  getProxyBean = getProxyBean;

  lifecycles = observeComponentLifecycles(this);

  mainForm = generateMainForm();

  growlId: number;

  cancelEditOp$ = new rx.Subject<void>();

  activateEditOp$ = new rx.Subject<void>();

  saveOp$ = new rx.Subject<void>();

  deleteFtdAssignmentNonAssigneeOp$ = new rx.Subject<number>();

  addFtdAssignmentNonAssigneeOp$ = new rx.Subject<void>();

  deleteFtdAssignmentAssigneeOp$ = new rx.Subject<number>();

  addFtdAssignmentAssigneeOp$ = new rx.Subject<void>();

  brandIn$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  brandSaving$ = this.streamBrandSaving();

  isInEdit$ = this.streamIsInEdit();

  showUnassignedAutoFtdBox$ = this.streamShowUnassignedAutoFtdBox();

  showAssignedAutoFtdBox$ = this.streamShowAssignedAutoFtdBox();

  isEditEnabled$ = this.streamIsEditEnabled();

  showEditAction$ = this.streamShowEditAction();

  /* @ngInject */
  constructor(
    readonly prfRx: PrfRxService,
    readonly growl: growl.IGrowlService,
    readonly growlMessages: growl.IGrowlMessagesService,
    readonly popupService: PopupService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [this.brandIn$, this.streamSyncBrandInToStore()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [this.streamFormMutable(), this.brandSaving$, this.streamErrorShowing()],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamErrorShowing() {
    return rx.pipe(
      () => this.prfCurrentBrand.brandInProcess$,
      rx.distinctUntilKeyChanged('inProcess'),
      rx.tap((inProcess) => {
        if (inProcess.error) {
          this.growl.error(inProcess.error.message, {
            referenceId: this.growlId,
          });
        } else {
          this.growlMessages.destroyAllMessages(this.growlId);
        }
      }),
    )(null);
  }

  streamSyncBrandInToStore() {
    return rx.pipe(
      () => this.brandIn$,
      rx.filter((brand) => !_.isNil(brand)),
      rx.tap((brand) => this.prfCurrentBrand.loadBrand(brand.id)),
    )(null);
  }

  streamFormMutableFromBrand() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfCurrentBrand.brand$,
          this.prfCurrentBrand.ftdAutoAssigmentForNonAssigneeList$,
          this.prfCurrentBrand.ftdAutoAssigmentForAssigneeList$,
        ),
      rx.tap(
        ([
          brand,
          ftdAutoAssigmentForNonAssigneeList,
          ftdAutoAssigmentForAssigneeList,
        ]) => {
          setFormWhole(
            this.mainForm,
            brand,
            ftdAutoAssigmentForNonAssigneeList,
            ftdAutoAssigmentForAssigneeList,
          );

          addEmptyFtdRowsToFormIfNeeded(this.mainForm);
        },
      ),
    )(null);
  }

  streamFormMutableFromDeleteFtdNonAssignee() {
    const streamFn = rx.pipe(
      () => this.deleteFtdAssignmentNonAssigneeOp$,
      rx.tap((id) => {
        const index = this.mainForm.ftdAutoAssigmentForNonAssigneeList.findIndex(
          (a) => a.id === id,
        );
        if (_.isNil(index)) {
          throw new Error('trying to delete non existing assignment');
        }

        this.mainForm.ftdAutoAssigmentForNonAssigneeList.splice(index, 1);
      }),
    );

    return streamFn(null);
  }

  streamFormMutableFromDeleteFtdAssignee() {
    const streamFn = rx.pipe(
      () => this.deleteFtdAssignmentAssigneeOp$,
      rx.tap((id) => {
        const index = this.mainForm.ftdAutoAssigmentForAssigneeList.findIndex(
          (a) => a.id === id,
        );
        if (_.isNil(index)) {
          throw new Error('trying to delete non existing assignment');
        }

        this.mainForm.ftdAutoAssigmentForAssigneeList.splice(index, 1);
      }),
    );

    return streamFn(null);
  }

  streamFormMutableFromAddFtdNonAssignee() {
    const streamFn = rx.pipe(
      () => this.addFtdAssignmentNonAssigneeOp$,
      rx.tap(() => {
        this.mainForm.ftdAutoAssigmentForNonAssigneeList.push(
          generateEmptyBrandFtdAutoAssigment(FtdAutoAssignmentType.NonAssignee),
        );
      }),
    );

    return streamFn(null);
  }

  streamFormMutableFromAddFtdAssignee() {
    const streamFn = rx.pipe(
      () => this.addFtdAssignmentAssigneeOp$,
      rx.tap(() => {
        this.mainForm.ftdAutoAssigmentForAssigneeList.push(
          generateEmptyBrandFtdAutoAssigment(FtdAutoAssignmentType.Assignee),
        );
      }),
    );

    return streamFn(null);
  }

  streamFormMutalbeFromCancel() {
    const streamFn = rx.pipe(
      () => this.cancelEditOp$,
      rx.withLatestFrom(
        this.prfCurrentBrand.brand$,
        this.prfCurrentBrand.ftdAutoAssigmentForNonAssigneeList$,
        this.prfCurrentBrand.ftdAutoAssigmentForAssigneeList$,
      ),
      rx.tap(
        ([
          _a,
          brand,
          ftdAutoAssigmentForNonAssigneeList,
          ftdAutoAssigmentForAssigneeList,
        ]) => {
          setFormWhole(
            this.mainForm,
            brand,
            ftdAutoAssigmentForNonAssigneeList,
            ftdAutoAssigmentForAssigneeList,
          );

          addEmptyFtdRowsToFormIfNeeded(this.mainForm);
        },
      ),
    );

    return streamFn(null);
  }

  streamFormMutable() {
    return rx.pipe(() =>
      rx.obs.merge(
        this.streamFormMutableFromBrand(),
        this.streamFormMutableFromDeleteFtdNonAssignee(),
        this.streamFormMutableFromDeleteFtdAssignee(),
        this.streamFormMutalbeFromCancel(),
        this.streamFormMutableFromAddFtdNonAssignee(),
        this.streamFormMutableFromAddFtdAssignee(),
      ),
    )(null);
  }

  streamBrandSaving() {
    const streamFn = rx.pipe(
      () => this.saveOp$,
      rx.withLatestFrom(
        observeObjectProxyValue<MainForm>(this.mainForm).pipe(
          rx.startWith(this.mainForm),
        ),
      ),
      rx.map(([a, formData]) => normalizeMainFormData(formData)),
      rx.tap((data) => {
        this.prfCurrentBrand.saveFtdAutoAssignment(
          data as FtdAutoAssigmentData,
        );
      }),
      rx.switchMap(() =>
        this.prfCurrentBrand.brandInProcess$.pipe(
          rx.filter((inProcess) => !inProcess.inProcess),
          rx.take(1),
          rx.map((inProcess) => {
            if (inProcess.error) {
              throw inProcess.error;
            }

            return inProcess;
          }),
        ),
      ),
      shareReplayRefOne(),
      this.prfRx.catchAndRecuperateStream(
        () => streamFn(null),
        () => this.growlId,
      ),
    );

    return streamFn(null);
  }

  streamIsInEdit() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.cancelEditOp$.pipe(rx.map(() => false)),
          this.activateEditOp$.pipe(rx.map(() => true)),
          this.brandSaving$.pipe(rx.map(() => false)),
        ),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamShowUnassignedAutoFtdBox() {
    return rx.pipe(
      () =>
        observeObjectProxyFieldValue(
          this.mainForm,
          'isNotAssigneeFtdAutoAssignmentEnabled',
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamShowAssignedAutoFtdBox() {
    return rx.pipe(
      () =>
        observeObjectProxyFieldValue(
          this.mainForm,
          'isAssigneeFtdAutoAssignmentEnabled',
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamIsEditEnabled() {
    return rx.pipe(
      () => observeProxyIsValid(this.mainForm),
      shareReplayRefOne(),
    )(null);
  }

  openLogsPopupTable() {
    this.popupService.open({
      component: 'prfFtdAssignmentAuditLogPopup',
      resolve: {
        brand: this.brand,
      },
    });
  }

  streamShowEditAction() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isInEdit$,
          checkCrudPermission(
            PermissionNormalized.AutoAssignments,
            this.PermPermissionStore,
          ),
        ),
      rx.map(([isInEdit, perm]) => {
        if (isInEdit) {
          return false;
        }

        if (!perm.isUpdate) {
          return false;
        }

        return true;
      }),
      shareReplayRefOne(),
    )(null);
  }
}

function generateMainForm(): ItemProxy<MainForm> {
  const bean = generateObjectBean({
    children: {
      isNotAssigneeFtdAutoAssignmentEnabled: generateValueBean(),
      isAssigneeFtdAutoAssignmentEnabled: generateValueBean(),
      isAssigneeFtdAutoAssignmentWithDelayEnabled: generateValueBean(),
      isAssigneeFtdAutoAssignmentByCustomerStatusEnabled: generateValueBean(),
      ftdAutoAssignmentDelay: generateValueBean(),
      ftdAutoAssignmentCustomerStatus: generateValueBean(),
      ftdAutoAssigmentForNonAssigneeList: genereateArrayBeanFtdAutoAssigment(),
      ftdAutoAssigmentForAssigneeList: genereateArrayBeanFtdAutoAssigment(),
    },
  });
  return getBeanProxy(bean);
}

function genereateArrayBeanFtdAutoAssigment() {
  return generateArrayBean({
    newItemFactory: () => generateBeanFtdAutoAssigment(),
    validators: [
      allChildrenAreValidProxyValidator,
      ftdAutoAssignmentsUniquebySourceDeskProxyValidator,
    ],
  });
}

function generateBeanFtdAutoAssigment() {
  return generateObjectBean({
    children: {
      id: generateValueBean(),
      brandId: generateValueBean(),
      createdAt: generateValueBean(),
      updatedAt: generateValueBean(),
      type: generateValueBean(),
      sourceDesk: generateValueBean(),
      destinationDesk: generateValueBean(),
      assigneeUser: generateValueBean(),
    },
    validators: [
      allChildrenAreValidProxyValidator,
      ftdAutoAssignmentDestIsNotEmptyIfSourceIsFilledProxyValidator,
      ftdAutoAssignmentSourceIsNotEmptyIfDestIsFilledProxyValidator,
      ftdAutoAssignmentWhenOtherFilledUserMustBeFilledProxyValidator,
    ],
  });
}

function normalizeMainFormData(formData: MainForm): Brand {
  const ftdAssigneeList = formData.ftdAutoAssigmentForAssigneeList
    .filter((a) => !isFtdAssignmentEmptyLine(a))
    .map((a) => normFtdAssignFormLine(a));

  const ftdNonAssigneeList = formData.ftdAutoAssigmentForNonAssigneeList
    .filter((a) => !isFtdAssignmentEmptyLine(a))
    .map((a) => normFtdAssignFormLine(a));

  const ftdAutoAssignments = [...ftdAssigneeList, ...ftdNonAssigneeList];

  return {
    ftdAutoAssignments,
    isNotAssigneeFtdAutoAssignmentEnabled:
      formData.isNotAssigneeFtdAutoAssignmentEnabled,
    isAssigneeFtdAutoAssignmentEnabled:
      formData.isAssigneeFtdAutoAssignmentEnabled,
    isAssigneeFtdAutoAssignmentWithDelayEnabled:
      formData.isAssigneeFtdAutoAssignmentWithDelayEnabled,
    isAssigneeFtdAutoAssignmentByCustomerStatusEnabled:
      formData.isAssigneeFtdAutoAssignmentByCustomerStatusEnabled,
    ftdAutoAssignmentDelay: formData.ftdAutoAssignmentDelay,
    ftdAutoAssignmentCustomerStatusId: _.get(
      ['ftdAutoAssignmentCustomerStatus', 'id'],
      formData,
    ),
  };
}

function generateEmptyBrandFtdAutoAssigment(
  type: FtdAutoAssignmentType,
): FtdAutoAssignment {
  return {
    type,
    brandId: null,
    createdAt: null,
    updatedAt: null,
    id: generateUuid() as any,
    sourceDesk: null,
    destinationDesk: null,
    assigneeUser: null,
  };
}

function setFormWhole(
  mainForm: ItemProxy<MainForm>,
  brand: Brand,
  ftdAutoAssigmentForNonAssigneeListP: FtdAutoAssignment[],
  ftdAutoAssigmentForAssigneeListP: FtdAutoAssignment[],
) {
  const ftdAutoAssigmentForNonAssigneeList = ftdAutoAssigmentForNonAssigneeListP.map(
    (assignment) => convertAssignmentToUiAssignment(assignment),
  );

  const ftdAutoAssigmentForAssigneeList = ftdAutoAssigmentForAssigneeListP.map(
    (assignment) => convertAssignmentToUiAssignment(assignment),
  );

  setWholeBean(getProxyBean(mainForm), {
    ftdAutoAssigmentForNonAssigneeList,

    ftdAutoAssigmentForAssigneeList,

    isNotAssigneeFtdAutoAssignmentEnabled:
      brand.isNotAssigneeFtdAutoAssignmentEnabled,

    isAssigneeFtdAutoAssignmentEnabled:
      brand.isAssigneeFtdAutoAssignmentEnabled,

    isAssigneeFtdAutoAssignmentWithDelayEnabled:
      brand.isAssigneeFtdAutoAssignmentWithDelayEnabled,

    isAssigneeFtdAutoAssignmentByCustomerStatusEnabled:
      brand.isAssigneeFtdAutoAssignmentByCustomerStatusEnabled,

    ftdAutoAssignmentDelay: brand.ftdAutoAssignmentDelay,

    ftdAutoAssignmentCustomerStatus: brand.ftdAutoAssignmentCustomerStatus,
  });
}

function addEmptyFtdRowsToFormIfNeeded(mainForm: ItemProxy<MainForm>) {
  if (mainForm.ftdAutoAssigmentForNonAssigneeList.length === 0) {
    mainForm.ftdAutoAssigmentForNonAssigneeList.push(
      generateEmptyBrandFtdAutoAssigment(FtdAutoAssignmentType.NonAssignee),
    );
  }

  if (mainForm.ftdAutoAssigmentForAssigneeList.length === 0) {
    mainForm.ftdAutoAssigmentForAssigneeList.push(
      generateEmptyBrandFtdAutoAssigment(FtdAutoAssignmentType.Assignee),
    );
  }
}

function normFtdAssignFormLine(line) {
  return {
    type: line.type,
    sourceDeskId: line.sourceDesk.id,
    destinationDeskId: line.destinationDesk.id,
    assigneeUserId: line.assigneeUser.id,
  };
}

function isFtdAssignmentEmptyLine(line) {
  return [line.sourceDesk, line.destinationDesk, line.assigneeUser].every((x) =>
    _.isNil(x),
  );
}

function convertAssignmentToUiAssignment(assignment: FtdAutoAssignment) {
  return _.flow([
    (item) =>
      _.isNil(item.assigneeUser)
        ? { ...item, assigneeUser: emptyUserOption }
        : item,
    (item) =>
      _.isNil(item.sourceDesk)
        ? { ...item, sourceDesk: emptyDeskOption }
        : item,
  ])(assignment);
}

export const FtdAssigmentManagerComponent = {
  template,
  controller: FtdAssigmentManagerController,
  require: {
    prfCurrentBrand: '^',
  },
  bindings: {
    brand: '<',
  },
};
