import * as _ from '@proftit/lodash';
import { depositsWithdrawalsReportsSettings } from './deposits-withdrawals-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY =
  'deposits-withdrawalsReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class depositsWithdrawalsReportTableController extends CommonReportsTableController {
  resource: string = 'depositsWithdrawals';
  exportResource: string = 'deposits_withdrawals';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>depositsWithdrawalsReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'depositsWithdrawalsReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'deposits_withdrawals_report_table';
  }
}

export const DepositsWithdrawalsReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: depositsWithdrawalsReportTableController,
};
