import { FormArray } from '@proftit/ng1.reactive-forms';
import {
  scoreMustHaveOneAndOnlyOneFailedSegmentFormValidator,
  scoreMustHaveSuccessivePointsRangeFormValidator,
} from '@proftit/crm.web.reactive-validations';
import { genereateNewSegmentControl } from '../questionnaire-score-segments-form/utilities/generate-new-segment-control';
import { allSegmentsScoresShouldRangeFromZeroToOneHundred } from './all-segments-scores-should-range-from-zero-to-one-hundred.form-validator';

export function generateSegmentsArray(segments) {
  return new FormArray(
    segments.map((segment, segmentIndex) =>
      genereateNewSegmentControl(segment, segmentIndex),
    ),
    {
      validators: [
        scoreMustHaveOneAndOnlyOneFailedSegmentFormValidator,
        scoreMustHaveSuccessivePointsRangeFormValidator,
        allSegmentsScoresShouldRangeFromZeroToOneHundred,
      ],
    },
  );
}
