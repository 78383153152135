import ng from 'angular';
import _ from 'underscore';

import template from './permissions-edit.html';
import BaseController from '~/source/common/controllers/base';
import { ManagementPermissionsModifierService } from '../../../permissions/permissions-modifier.service';
import { UserGroupsService } from '../../services/user-groups';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { useStreams } from '@proftit/rxjs.adjunct';

class Controller extends BaseController {
  lifecycles = observeComponentLifecycles(this);
  // Initial mode
  isEdit = false;
  userGroupsServiceInst: UserGroupsService;
  permissionsBackup: any;
  userGroup: any;
  changePermissionsModeInManagerComponent: (boolean) => void;
  isEditPermissionRequested$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'isEditPermissionRequested',
  );

  /*@ngInject */
  constructor(
    readonly userGroupsService: () => UserGroupsService,
    readonly managementPermissionsModifierService: ManagementPermissionsModifierService,
    readonly growl: ng.growl.IGrowlService,
  ) {
    super();

    this.userGroupsServiceInst = this.userGroupsService();
    useStreams(
      [
        this.isEditPermissionRequested$,
        this.streamHandleRequestToEditPermissionFromWrapperComponent(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamHandleRequestToEditPermissionFromWrapperComponent() {
    return rx.pipe(
      () => this.isEditPermissionRequested$,
      rx.tap((x) => {
        if (x) {
          this.onEdit();
        }
        return x;
      }),
    )(null);
  }

  /**
   * Saves a permissions copy and enters edit mode
   */
  onEdit() {
    this.permissionsBackup = ng.copy(this.userGroup.permissions);
    this.changePermissionsModeInManagerComponent({ newState: true });
  }

  /**
   * Restores permissions from copy and exits edit mode
   */
  cancelEdit() {
    this.userGroup.permissions = this.permissionsBackup;
    this.changePermissionsModeInManagerComponent({ newState: false });
  }

  updatePermissions() {
    // Verify at least 1 permission is selected.
    if (
      !this.managementPermissionsModifierService.isSelected(
        this.userGroup.permissions,
      )
    ) {
      this.growl.error('group.MUST_SELECT_PERMISSION', {
        referenceId: <any>'permissionsGrowl',
        ttl: 5000,
      });
      return false;
    }

    const normalizedPermissions =
      // discard the root. we added it manually anyway. the real permissions are children
      this.managementPermissionsModifierService
        // reset every node to remove the parent ref node and avoid circular structure
        .resetParent(ng.copy(this.userGroup.permissions)).children;

    return this.userGroup
      .patch({
        permissions: normalizedPermissions,
      })
      .then(() => {
        this.changePermissionsModeInManagerComponent({ newState: false });
      });
  }
}

export const PermissiosEditComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    userGroup: '<',
    isEditPermissionRequested: '<',
    changePermissionsModeInManagerComponent: '&',
  },
};
