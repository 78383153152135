import BaseController from '~/source/common/controllers/base';
import template from './assign-status.html';
import CustomerStatusesService from '~/source/contact/common/services/customer-statuses.service';
import { CustomerStatus } from '~/source/common/models/customer-status';

class Controller extends BaseController {
  customerStatuses: CustomerStatus[];
  model: CustomerStatus;

  /*@ngInject*/
  constructor(readonly customerStatusesService: () => CustomerStatusesService) {
    super();
  }

  $onInit() {
    this.fetchCustomerStatuses();
  }

  /**
   * fetch customer statuses
   */
  fetchCustomerStatuses() {
    return this.customerStatusesService()
      .getListWithQuery()
      .then((customerStatuses) => {
        this.customerStatuses = customerStatuses;
      });
  }

  select(status) {
    this.model = status;
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    model: '=',
  },
};
