import PositionsTableBasePopupController from '../../common/positions/positions-table-popup-base-controller';
import positionsSettings from './positions-binary-settings.json';
import { ICollection } from 'restangular';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
class PositionTableBinaryPopupController extends PositionsTableBasePopupController {
  // from popup data
  positions: ICollection;
  account: TradingAccount;
  customer: Customer;

  get cols() {
    return [...positionsSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...positionsSettings.tablePopup.ngTable };
  }

  get tableKey() {
    return 'positionsBinary';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getPositionsResource(
        this.customer.id,
        this.account.type,
        this.account.id,
      )
      .expand([
        'currency',
        'positionStatus',
        'tradeAsset',
        'tradeDirection',
        'tradeProduct',
        'tradingAccount',
        'tradingAccount.currency',
        'tradingAccount.platform',
        'tradingAccount.customer',
        'tradingAccount.customer.brand',
      ]);
  }
}

export default PositionTableBinaryPopupController;
