import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';
import { calcMaxScoreFromAnswers } from './calc-max-score-from-answers';
import { calcAggragatedScoreFromAnswers } from './calc-aggragated-score-from-answers';
import { switchOn } from '@proftit/general-utilities';
import { calcMaxAggragateScoreFromAnswers } from './calc-max-aggragate-score-from-answers';

export function calcMaxScoreForQuestion(scoreData, questionType) {
  return switchOn(
    {
      [QuestionnaireComponentTypeCode.SingleSelect]: () =>
        calcMaxScoreFromAnswers(scoreData),
      [QuestionnaireComponentTypeCode.MultiSelect]: () =>
        scoreData.maxQuestionScore,
      [QuestionnaireComponentTypeCode.BooleanQuestion]: () =>
        calcMaxScoreFromAnswers(scoreData),
      [QuestionnaireComponentTypeCode.Confirm]: () =>
        calcMaxAggragateScoreFromAnswers(scoreData),
    },
    questionType,
    () => 0,
  );
}
