import * as _ from '@proftit/lodash';
import template from './accounting-stats.html';
import fieldsConfig from './settings/stats.json';
import BaseController from '~/source/common/controllers/base';
const styles = require('./accounting-stats.scss');

export interface IAccountingStatsPrimary {
  title: string;
  field: string;
  type: string;
  fractionSize?: number;
}

export interface IAccountingStatsSecondary {
  field: string;
}

export interface IAccountingStatsConfig {
  primary: IAccountingStatsPrimary;
  secondary?: IAccountingStatsSecondary;
}

class AccountingStatsController extends BaseController {
  styles = styles;
  static $inject = ['appConfig'];

  constructor(readonly appConfig: any) {
    super();
  }

  statsConfig: IAccountingStatsConfig[];
  stats;

  $onInit() {
    this.statsConfig = fieldsConfig['tableColumns'];
  }

  isFinite(data) {
    return _.isFinite(data);
  }
}

export default {
  template,
  controller: AccountingStatsController,
  controllerAs: 'vm',
  bindings: {
    stats: '<',
  },
};
