import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './last-login-notice.component.html';

const styles = require('./last-login-notice.component.scss');
import UserTokenModel from '~/source/common/models/user-token-model';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

interface LastLogin {
  date: string;
  ip: string;
  computer: string;
}

export class LastLoginNoticeController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  lastLogin$ = this.streamLastLogin();

  disableMinimizeOption = true;

  /* @ngInject */
  constructor(
    readonly prfCurrentUserStore: CurrentUserStoreService,
    readonly $translate: angular.translate.ITranslateService,
  ) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamLastLogin(): rx.Observable<LastLogin> {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.filter((user) => !_.isNil(user)),
      rx.map((user) => user.lastLogin),
      rx.map((lastLogin) => {
        if (_.isNil(lastLogin)) {
          return null;
        }

        const ip = [
          lastLogin.ip,
          _.isNil(lastLogin.country) || lastLogin.country === 'unspecified'
            ? this.$translate.instant('lastLogin.COUNTRY_UNKNOWN')
            : lastLogin.country,
        ].join(', ');
        const computer = [
          _.isNil(lastLogin.deviceModel) ||
          lastLogin.deviceModel === 'unspecified'
            ? this.$translate.instant('lastLogin.DEVICE_MODEL_UNKNOWN')
            : lastLogin.deviceModel,
          _.isNil(lastLogin.deviceName) ||
          lastLogin.deviceName === 'unspecified'
            ? this.$translate.instant('lastLogin.DEVICE_NAME_UNKNOWN')
            : lastLogin.deviceName,
          _.isNil(lastLogin.city) || lastLogin.city === 'unspecified'
            ? this.$translate.instant('lastLogin.CITY_UNKNOWN')
            : lastLogin.city,
        ].join(', ');
        return {
          ip,
          computer,
          date: lastLogin.loginDate,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const LastLoginNoticeComponent = {
  template,
  controller: LastLoginNoticeController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
