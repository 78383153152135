import _ from 'underscore';
import ng from 'angular';

import BaseService from '~/source/common/services/baseService';

interface Permission {
  actionC: boolean;
  actionR: boolean;
  actionU: boolean;
  actionD: boolean;
  children: any;
}

export class ManagementPermissionsModifierService extends BaseService {
  constructor(...args) {
    super(...args);
  }

  modify(permissionsStructParam, values = []) {
    /*
     * permissions received from server should be added as
     * child permission of the root permission
     */
    const permissionsStruct = _.extend(this.root, {
      children: permissionsStructParam,
    });

    // modify permission object to fit permission-tree directive
    this.iterateChildren(this.build.bind(this), permissionsStruct, null, {
      children: values,
    });

    return permissionsStruct;
  }

  /**
   * Returns true if permission itself or one it children is selected
   *
   * @param {Object} permission
   * @returns {Boolean}
   */
  isSelected(permission) {
    let selected = false;

    const check = function (item) {
      if (selected) {
        return;
      }

      selected = !!(
        item.actionC ||
        item.actionR ||
        item.actionU ||
        item.actionD
      );
    };

    this.iterateChildren(check, permission);

    return selected;
  }

  /**
   * Reset parent references for all the tree nodes
   *
   * @param {Object} permission
   */
  resetParent(permission) {
    const clonedPermission = ng.copy(permission);
    const reset = function (item) {
      item.parent = {};
    };

    this.iterateChildren(reset, clonedPermission);

    return clonedPermission;
  }

  /**
   * Recursively iterate over child permissions
   *
   * @param {Function} callback
   * @param {Object} permission - permission object
   * @param {Object} [parent] - parent permission object
   * @param {object} [values] - permission values to assign
   * @private
   */
  iterateChildren(
    callback,
    permission,
    parent = null,
    values: Partial<Permission> = {},
  ) {
    callback(permission, parent, values);
    // define parent permission
    if (_.isEmpty(permission.children)) {
      return;
    }

    permission.children.map((child, key) =>
      this.iterateChildren(
        callback,
        child,
        permission,
        values.children && values.children[key],
      ),
    );
  }

  /**
   * Callback for recursive iteration over children permissions
   *
   * Sets the "crud" permissions for the node, using the passed values.
   *
   * @param {Object} permission
   * @param {Object} parent
   * @param {object} [values] - permission values to assign
   * @private
   */
  build(permission, parent, values: Partial<Permission> = {}) {
    // set parent permission as a property of a child
    permission.parent = parent;

    if (_.isArray(permission) || permission.root) {
      return;
    }

    Object.assign(permission, {
      actionC: !!values.actionC,
      actionR: !!values.actionR,
      actionU: !!values.actionU,
      actionD: !!values.actionD,
    });
  }

  /**
   * Creates and return a root node to be used as the root for the permissions tree.
   *
   * @returns {{name: string, root: boolean, level: number}}
   */
  get root() {
    return {
      name: 'root',
      root: true,
      level: 0,
    };
  }
}
