import log from 'loglevel';
import template from './notifications-center.html';
import BaseController from '../../controllers/base';
import { TokensService } from '~/source/auth/services/tokens';
import { UserSocketService } from '../../services/user-socket';
import { SocketListener } from '../../services/socket';

class NotificationsCenterController extends BaseController {
  isPopupShown: boolean;
  onUnreadNotificationsUpdateWrapped: SocketListener;
  unreadNotificationsCount: number;
  unreadNotificationsChannel: string;

  /*@ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly userSocketService: UserSocketService,
    readonly desktopNotification,
  ) {
    super();

    this.onUnreadNotificationsUpdateWrapped = this.userSocketService.wrapListener(
      this.onUnreadNotificationsUpdate.bind(this),
    );
  }

  $onInit() {
    this.isPopupShown = false;
    this.unreadNotificationsCount =
      this.tokensService.getCachedUser().unreadNotifications || 0;
    this.setNotificationsChannel();
    this.startListening();
    this.requestDesktopNotifications()
      .then(() => {
        log.info('Desktop notifications are enabled');
      })
      .catch((err) => {
        log.warn('Desktop notifications are disabled: ', err);
      });
  }

  /**
   * Opens/closes the notification center popup
   * @return {void}
   */
  togglePopup() {
    this.isPopupShown = !this.isPopupShown;
  }

  /**
   * Build streamer notifications channel name
   * Must run when user is logged in
   * @return {string} channel name
   */
  setNotificationsChannel() {
    const user = this.tokensService.getCachedUser();
    this.unreadNotificationsChannel = `user.${user.id}.${this.userSocketService.channelRoot}`;
  }

  /**
   * Subscribe to new calls
   * @return {void}
   */
  startListening() {
    this.userSocketService.subscribe(
      this.unreadNotificationsChannel,
      this.onUnreadNotificationsUpdateWrapped,
    );
  }

  /**
   * Un-subscribe from new calls
   * @return {void}
   */
  stopListening() {
    this.userSocketService.unsubscribe(
      this.unreadNotificationsChannel,
      this.onUnreadNotificationsUpdateWrapped,
    );
  }

  /**
   * Called by streamer when the unread notification count changes.
   * @param {number} userUpdate - Updated user model
   * @return {void}
   */
  onUnreadNotificationsUpdate({ unreadNotifications }) {
    log.info('Unread notifications count updated to', unreadNotifications);
    this.unreadNotificationsCount = unreadNotifications;
    // Update the token's cache with the updated property
    this.tokensService.updateUserCache({ unreadNotifications });
  }

  /**
   * Calls on scope destroy.
   * Stops listening for new notifications
   * @return {void}
   */
  $onDestroy() {
    this.stopListening();
  }

  /**
   * Setup desktop notifications:
   * If supported by browser, request permission to show desktop notification
   * @return {Promise} resolves on permission granted, rejects on denied/ignored (with reason)
   */
  requestDesktopNotifications() {
    if (!this.desktopNotification.isSupported()) {
      return Promise.reject(
        new Error('Desktop notifications are not supported in this browser'),
      );
    }

    if (this.desktopNotification.currentPermission() === 'granted') {
      return Promise.resolve();
    }

    if (this.desktopNotification.currentPermission() === 'denied') {
      // don't bother to ask again
      return Promise.reject(
        new Error('User has rejected desktop notifications'),
      );
    }

    return this.desktopNotification.requestPermission().catch((permission) => {
      if (permission === 'default') {
        return Promise.reject(new Error('User ignored notifications request'));
      }
      // if we got to the catch and the permission is not 'default', it must be 'declined'
      return Promise.reject(
        new Error('User has rejected desktop notifications'),
      );
    });
  }
}

const notificationsCenterComponent = {
  template,
  controller: NotificationsCenterController,
};

export default notificationsCenterComponent;
