import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import template from './single-number-input.component.html';
const styles = require('./single-number-input.component.scss');

export class SingleNumberInputController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SingleNumberInputComponent = {
  template,
  controller: SingleNumberInputController,
  bindings: {
    model: '=',
  },
};
