export const managementRouting = ($stateProvider) => {
  $stateProvider
    .state('crm.management', {
      abstract: true,
      url: '/management',
      component: 'prfManagementSideMenu',
      data: {
        permissions: {
          only: ['management'],
          redirectTo: 'error.unauthorized',
        },
        pageTitle: 'MANAGEMENT',
      },
    })
    // Create abstract states for all sub-categories
    .state('crm.management.permissions', {
      abstract: true,
      url: '/permissions',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_PERMISSIONS',
      },
    })
    .state('crm.management.tools', {
      abstract: true,
      url: '/tools',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_TOOLS',
      },
    })
    .state('crm.management.notifications', {
      abstract: true,
      url: '/notifications',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_NOTIFICATIONS',
      },
    })
    .state('crm.management.automation', {
      abstract: true,
      url: '/automation',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_AUTOMATION',
      },
    })
    .state('crm.management.integration.ewallet', {
      abstract: true,
      url: '/ewallet',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_INTEGRATION',
      },
    })
    .state('crm.management.integration', {
      abstract: true,
      url: '/integration',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_INTEGRATION',
      },
    })
    .state('crm.management.about', {
      url: '/about',
      component: 'prfAbout',
      data: {
        pageTitle: 'MANAGEMENT_ABOUT',
      },
    });
};

managementRouting.$inject = ['$stateProvider'];
