import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { ModelChange } from '~/source/common/utilities/model-change';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  ContentTemplate,
  DesignTemplate,
} from '@proftit/crm.api.models.entities';
import { isNotNilFormValidator } from '@proftit/ng1.reactive-forms.validators';
import template from './content-template-design-form.component.html';
import { ExcludeBy } from '~/source/common/components/dropdowns/base/component';

const styles = require('./content-template-design-form.component.scss');

export class ContentTemplateDesignFormController {
  onChange: (a: { change }) => void;

  onIsValid: (a: { isValid: boolean }) => void;

  filterDesignTemplatesBinded: (data: DesignTemplate[]) => DesignTemplate[];

  styles = styles;

  ExcludeBy = ExcludeBy;

  lifecycles = observeComponentLifecycles(this);

  model: ContentTemplate;

  model$ = observeShareCompChange<ContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  form$ = this.streamForm();

  /*@ngInject */
  constructor() {
    this.filterDesignTemplatesBinded = (templates: DesignTemplate[]) => {
      return this.filterDesignTemplates(templates);
    };

    useStreams(
      [
        this.model$,
        this.form$.pipe(rx.switchMap((f) => f.value$)),
        this.streamNotifyChange(),
        this.streamNotifyIsValid(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm(): rx.Observable<FormGroup> {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => modelToForm(model)),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) =>
        rx.obs.combineLatest(form.value$, form.isAtFirstValue$),
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.tap(([change, a]) => this.onChange({ change })),
    )(null);
  }

  streamNotifyIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onIsValid({ isValid })),
    )(null);
  }

  filterDesignTemplates(designs: DesignTemplate[]) {
    const currentDesign = _.get(['designTemplate'], this.model);

    return designs.filter((d) => {
      if (d.isActive) {
        return true;
      }

      if (d.id === _.get(['id'], currentDesign)) {
        return true;
      }

      return false;
    });
  }
}

function modelToForm(model: ContentTemplate) {
  return new FormGroup({
    designTemplate: new FormControl(model.designTemplate, {
      validators: [isNotNilFormValidator],
    }),
  } as any);
}

export const ContentTemplateDesignFormComponent = {
  template,
  controller: ContentTemplateDesignFormController,
  bindings: {
    model: '<',
    onChange: '&',
    onIsValid: '&',
  },
};
