import {
  Brand,
  ContentTemplate,
  CustomContentTemplate,
  Language,
} from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import {
  ContentTemplateTypes,
  ContentTemplateTypeCode,
} from '@proftit/crm.api.models.enums';
import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import {
  CONTENT_TEMPLATE_DELETED,
  CONTENT_TEMPLATE_UPDATED,
} from '../../constants/general-pubsub-keys';
import { IElementRestNg } from '../../models/ielement-rest-ng';

export class ContentTemplatesService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'contentTemplates';
  }

  /**
   *
   */
  resourceFullListByBrandAndType(
    brand: Brand,
    type: ContentTemplateTypeCode,
    sort,
    expand,
    embed,
  ) {
    const filter = _.flow([
      (x) => (_.isNil(brand) ? x : { ...x, 'brands.id': brand.id }),
      (x) => (_.isNil(type) ? x : { ...x, type }),
    ])({});

    return this.sort(sort).filter(filter).expand(expand).embed(embed);
  }

  /**
   *
   */
  resourceListByBrandLanguageType(
    brand: Brand,
    language: Language,
    typeCode: ContentTemplateTypeCode,
    sort,
    expand,
    embed,
  ) {
    const filter = _.flow([
      (x) => (_.isNil(brand) ? x : { ...x, 'brands.id': brand.id }),
      (x) => (_.isNil(language) ? x : { ...x, languageId: language.id }),
      (x) => (_.isNil(typeCode) ? x : { ...x, type: typeCode }),
    ])({});

    return this.sort(sort).filter(filter).expand(expand).embed(embed);
  }

  /**
   * Create item.
   *
   * @param item
   * @returns work promise
   */
  createItem(item: Partial<ContentTemplate>) {
    return this.postWithQuery<IElementRestNg<ContentTemplate>>(item).then(
      (data) => {
        return data.plain();
      },
    );
  }

  /**
   * Update item.
   *
   * @param item
   * @return work promise
   */
  updateItem(id: number, item: ContentTemplate) {
    return this.patchElement<IElementRestNg<CustomContentTemplate>>(id, item);
  }

  /**
   * Get item
   */
  getFullItem(id: number) {
    return this.expand(['createdBy', 'designTemplate', 'language'])
      .embed(['brands', 'departments'])
      .getOneWithQuery<IElementRestNg<ContentTemplate>>(id)
      .then((data) => data.plain());
  }

  /**
   * Delete item
   *
   * Notifiy pubsub of successful action.
   *
   * @param id
   * @return work promise
   */
  deleteItem(id: number) {
    return this.removeElement(id).then((x) => {
      this.prfClientGeneralPubsub.publish(CONTENT_TEMPLATE_DELETED, x);
      return x;
    });
  }

  /**
   * Activate item
   *
   * Publish pubsub on success update.
   *
   * @param id
   * @return work promise whith updated entity
   */
  activateItem(id: number) {
    /*
     * brands and departments are special props for the entity that are flashed each update
     * so we need to send their existing values.
     */
    return this.getFullItem(id)
      .then((data) => {
        return this.patchElement(id, {
          brands: data.brands,
          departments: data.departments,
          isActive: true,
        });
      })
      .then((x) => {
        this.prfClientGeneralPubsub.publish(CONTENT_TEMPLATE_UPDATED, x);
        return x;
      });
  }

  /**
   * Deactivate item
   *
   * Publish pubsub on success update.
   *
   * @param id
   * @return work promise whith updated entity
   */
  deactivateItem(id: number) {
    /*
     * brands and departments are special props for the entity that are flashed each update
     * so we need to send their existing values.
     */
    return this.getFullItem(id)
      .then((data) => {
        return this.patchElement(id, {
          brands: data.brands,
          departments: data.departments,
          isActive: false,
        });
      })
      .then((x) => {
        this.prfClientGeneralPubsub.publish(CONTENT_TEMPLATE_UPDATED, x);
        return x;
      });
  }

  getAllForSystemEmailTemplate(systemEmailTempalteId: number) {
    return this.filter({
      systemId: systemEmailTempalteId,
    }).expand(['language']);
  }

  getAllCustomForBrandAndLanguage(brand: Brand, language: Language) {
    return this.resourceListByBrandLanguageType(
      brand,
      language,
      ContentTemplateTypeCode.Custom,
      {},
      [],
      [],
    );
  }

  getAllCustomForBrand(brand: Brand) {
    return this.resourceListByBrandLanguageType(
      brand,
      null,
      ContentTemplateTypeCode.Custom,
      {},
      ['language'],
      [],
    ).getListWithQuery<IElementRestNg<CustomContentTemplate>>();
  }
}
