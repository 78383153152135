import * as _ from '@proftit/lodash';
import Big from 'big.js';

/**
 * Filter factory.
 *
 * @return {Function} filter
 */

function zeroOneAsPercentageFilterFactory() {
  /**
   * Convert number to percentage (..0-1.. => ..0-100..)
   *
   * @param input - number
   * @returns numer as percentage representation.
   */
  function zeroOneAsPercentage(input, truncateTo = 2) {
    if (_.isNil(input)) {
      return null;
    }

    const inputAsBig = Big(input);
    const truncateToAsBig = Big(10).pow(truncateTo);
    return Number(inputAsBig.times(truncateToAsBig).times(100).div(truncateToAsBig));
  }

  return zeroOneAsPercentage;
}
zeroOneAsPercentageFilterFactory.$inject = [];

function defineNg1PipesGeneralModule(angular) {
  const Ng1PipesGeneralModule = angular.module('prfNg1PipesModule', []);
  Ng1PipesGeneralModule.filter('prfZeroOneAsPercentage', zeroOneAsPercentageFilterFactory);
  return Ng1PipesGeneralModule;
}

export { defineNg1PipesGeneralModule };
