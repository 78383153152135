import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './currency-to-currency-box.component.html';
import { Currency } from '@proftit/crm.api.models.entities';

export class CurrencyToCurrencyBoxController extends BaseController {
  sourceCurrency: Currency;
  targetCurrency: Currency;

  /*@ngInject */
  constructor() {
    super();
  }
}

export const CurrencyToCurrencyBoxComponent = {
  template,
  controller: CurrencyToCurrencyBoxController,
  bindings: {
    sourceCurrency: '<',
    targetCurrency: '<',
  },
};
