import angular from 'angular';
import BinaryPositionsModule from './position/module';
import BinaryRiskFreeModule from './risk-free/module';

import AccountActionsBarComponent from './trading-account-actions-bar-binary.component';
import TradingAccountBinaryComponent from './trading-account-binary/trading-account-binary.component';
import TradingAccountSocketService from './trading-account-socket.service';

export default angular
  .module('crm.contact.tradingAccount.binary', [
    BinaryPositionsModule,
    BinaryRiskFreeModule,
  ])
  .component('prfTradingAccountActionsBarBinary', AccountActionsBarComponent)
  .component('prfTradingAccountBinary', TradingAccountBinaryComponent)
  .service('tradingAccountBinarySocketService', TradingAccountSocketService)
  .name;
