import { IWindowService, IIntervalService, ITimeoutService } from 'angular';
import { StateService } from '@uirouter/core';
import _ from 'underscore';
import * as _l from '@proftit/lodash';

import Url from 'url-parse';
import IframeLoader from '../common/components/iframe-loader/iframe-loader';
import TokensService from '~/source/auth/services/tokens';
import KibiReportCsvService from '~/source/common/services/kibi-report-csv.service';
import useStream from '~/source/common/utilities/use-stream';
import { DownloadsPopupService } from '~/source/reports/downloads-popup.service';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import $ from 'jquery';
import log from 'loglevel';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { USER_UPDATE } from '~/source/common/constants/general-pubsub-keys';

const REDIRECT_PATH = 'app/kibana#/dashboard';
const SAMPLE_KIBI_READY_INTERVAL = 200;

export enum MessageType {
  KibiClientMessaging = 'KIBI_CLIENT_MESSAGING',
}

export enum MessageSubType {
  MessageErrorResponse = 'MESSAGE_ERROR_RESPONSE',
  MessageResponse = 'MESSAGE_RESPONSE',
  MessageRequest = 'MESSAGE_REQUEST',
}

export enum KibiCommand {
  GenerateCsv = 'COMMAND_GENERATE_CSV',
  CreateCustomerLinks = 'CREATE_CUSTOMER_LINKS',
  SetJWTToken = 'SET_JWT_TOKEN',
  AcknowledgeJWTToken = 'ACKNOWLEDGE_JWT_TOKEN',
  StartedListeningToCRM = 'STARTED_LISTENING_TO_CRM',
}

interface WindowMessageEvent extends Event {
  origin: string;
  data: {
    type: MessageType;
  };
}

interface KibiCrmClientEvent extends WindowMessageEvent {
  data: {
    type: MessageType;
    subtype: MessageSubType;
    messageId: string;
    command: KibiCommand;
    params: any;
  };
}

class ReportsIframeLoaderComponent extends IframeLoader.IframeLoaderController {
  static $inject = [
    '$interval',
    'tokensService',
    '$window',
    'kibiReportCsvService',
    'downloadsPopupService',
    '$state',
    'prfClientGeneralPubsub',
    '$cookies',
    ...IframeLoader.IframeLoaderController.$inject,
  ];

  loggedIn: boolean;
  iframe;
  url: string;
  origin: string;
  urlParsed: Url<any>;
  unsub$ = new rx.Subject<void>();

  kibiCrmClientEvent$ = this.streamKibiCrmClientEvent();
  downloadsPopupService: DownloadsPopupService;
  $state: StateService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  $cookies: any;

  constructor(
    readonly $interval: IIntervalService,
    readonly tokensService: TokensService,
    readonly $window: IWindowService,
    readonly kibiReportCsvService: KibiReportCsvService,
    ...args
  ) {
    super($interval, tokensService, $window, kibiReportCsvService, ...args);
  }

  $onInit() {
    super.$onInit();

    // mark user as logged out from kibi
    this.loggedIn = false;
    // preserve correct context
    this.onUserTokenUpdate = this.onUserTokenUpdate.bind(this);
    this.updateToken(true);
    // if token has been updated we will pass it to kibi
    useStream(this.streamOnUserUpdate(), this.unsub$);
    useStream(this.kibiCrmClientEvent$, this.unsub$);
    useStream(this.streamGenerateCsv(), this.unsub$);
    useStream(this.streamCreateCustomerLinksForKibi(), this.unsub$);
    useStream(this.streamAcknowledgeKibiLogin(), this.unsub$);
    useStream(this.streamKibiStartedListeningToCRM(), this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamOnUserUpdate() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === USER_UPDATE),
      rx.tap(() => {
        this.onUserTokenUpdate();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCreateCustomerLinksForKibi() {
    const streamFn = rx.pipe(
      () => this.kibiCrmClientEvent$,
      rx.filter(
        (event) => event.data.command === KibiCommand.CreateCustomerLinks,
      ),
      rx.tap((event) => {
        const { customerIds } = event.data;

        const customerIdsSet = new Set(customerIds);
        const uniqueCustomerIds = Array.from(customerIdsSet);

        const customerLinkData = uniqueCustomerIds.map((customerId) => {
          const href = this.$state.href(
            'crm.contacts.view',
            { id: customerId },
            { absolute: true },
          );
          return {
            customerId,
            customerPageLink: href,
          };
        });
        const contentWindow = this.getKibiContentWindow();
        contentWindow.postMessage(
          {
            messageId: event.data.messageId,
            type: MessageType.KibiClientMessaging,
            subtype: MessageSubType.MessageResponse,
            linksData: customerLinkData,
          },
          this.urlParsed.origin,
        );
      }),
      rx.catchError((err) => {
        log.error('streamCreateCustomerLinksForKibi failed with error', err);
        return streamFn(null);
      }),
      shareReplayRefOne(),
    );

    return streamFn(null);
  }

  streamKibiCrmClientEvent() {
    return rx.pipe(
      () => rx.obs.fromEvent(this.$window, 'message'),
      rx.filter(
        (event: MessageEvent) => event.origin === this.urlParsed.origin,
      ),
      rx.filter(
        (event) =>
          _l.get(['data', 'type'], event) === MessageType.KibiClientMessaging,
      ),
    )(null);
  }

  streamGenerateCsv() {
    return rx.pipe(
      () => this.kibiCrmClientEvent$,
      rx.filter((event) => !_l.isNil(event)),
      rx.filter((event) => event.data.command === KibiCommand.GenerateCsv),
      rx.tap((event) => this.downloadsPopupService.openPopup()),
      rx.mergeMap((event) =>
        rx.pipe(
          () =>
            rx.obs.from(this.kibiReportCsvService.create(event.data.params)),
          rx.tap(() => {
            const contentWindow = this.getKibiContentWindow();
            contentWindow.postMessage(
              {
                type: MessageType.KibiClientMessaging,
                subtype: MessageSubType.MessageResponse,
                messageId: event.data.messageId,
              },
              this.urlParsed.origin,
            );
          }),
          rx.catchError((err, obs) => {
            const contentWindow = this.getKibiContentWindow();

            contentWindow.postMessage(
              {
                type: MessageType.KibiClientMessaging,
                subtype: MessageSubType.MessageErrorResponse,
                messageId: event.data.messageId,
                errorMessage: err.message,
              },
              this.urlParsed.origin,
            );

            return rx.obs.from([]);
          }),
        )(null),
      ),
    )(null);
  }

  onUrlChange(newUrl) {
    this.urlParsed = new Url(this.url);
  }

  /**
   *  Called on user token update (after keep a live)
   */
  onUserTokenUpdate() {
    this.updateToken();
  }

  /**
   * Called when iframe fully loaded
   */
  onIframeLoaded(...args) {
    super.onIframeLoaded(...args);
  }

  streamKibiStartedListeningToCRM() {
    const streamFn = rx.pipe(
      () => this.kibiCrmClientEvent$,
      rx.filter(
        (event) => event.data.command === KibiCommand.StartedListeningToCRM,
      ),
      rx.tap(() => {
        // preform login if necessary
        if (!this.loggedIn) {
          this.updateToken(true);
        }
      }),
      rx.catchError((err) => {
        log.error('streamKibiStartedListeningToCRM failed with error', err);
        return streamFn(null);
      }),
      shareReplayRefOne(),
    );
    return streamFn(null);
  }

  /**
   * Login into kibi using crm jwt
   * @param {Boolean} refresh - determines if iframe refresh needed
   */
  updateToken(isInitialLogin = false) {
    const contentWindow = this.getKibiContentWindow();
    let jwt = this.tokensService.getCachedUser().jwt;
    // filter "Bearer " from jwt to match kibi requirements
    jwt = jwt.replace('Bearer ', '');

    const parts = location.hostname.split('.');
    let upperLevelDomain;
    if (parts.length <= 2) {
      upperLevelDomain = location.hostname;
    } else {
      const subdomain = parts.shift();
      upperLevelDomain = parts.join('.');
    }

    // Settig cookie
    this.$cookies.put('kibi_jwt', jwt, {
      domain: upperLevelDomain,
      path: '/',
    });

    // contentWindow.postMessage(
    //   {
    //     jwt,
    //     isInitialLogin,
    //     type: MessageType.KibiClientMessaging,
    //     subtype: MessageSubType.MessageRequest,
    //     kibiCommand: KibiCommand.SetJWTToken,
    //   },
    //   this.urlParsed.origin,
    // );
  }

  streamAcknowledgeKibiLogin() {
    return rx.pipe(
      () => this.kibiCrmClientEvent$,
      rx.filter(
        (event) => event.data.command === KibiCommand.AcknowledgeJWTToken,
      ),
      rx.tap(() => {
        this.loggedIn = true;
      }),
      shareReplayRefOne(),
    )(null);
  }

  getKibiContentWindow() {
    return this.iframe.get(0).contentWindow;
  }
}

export default IframeLoader.config({
  controller: ReportsIframeLoaderComponent,
});
