import SearchResultsBaseController from './search-results-table.controller';
import template from './search-results-table-trading-accounts.html';

class TradingAccountsResultsController extends SearchResultsBaseController {
  cols = [...this.searchResultsModuleSettings.cols.tradingAccounts];

  /**
   * Return a fetch method name from the 'searchService'
   * Will be called by parent controller
   * @returns {string} method name in 'searchService'
   */
  get searchMethodName() {
    return 'findTradingAccounts';
  }
}

export default {
  template,
  controller: TradingAccountsResultsController,
};
