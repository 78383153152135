import template from './gmail-notification.component.html';
const styles = require('./gmail-notification.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import ModalService from '~/source/common/components/modal/modal.service';
import { ExternalWindowService } from '~/source/common/services/external-window.service';
import { Customer } from '@proftit/crm.api.models.entities';
import { getSandboxedIframe } from '@proftit/dom-utilities';
import { Communication } from '~/source/common/models/communication';
import BrandsService from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import CustomersService from '~/source/contact/common/services/customers';

export class GmailNotificationController {
  onMarkNotificationAsRead: (a: { id: number }) => void;
  onExpandNotification: (a: { id: number }) => void;
  onOpenCustomerPage: (a: { $event }) => void;
  notification: Communication;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  ellipsisCharacterAmount = 60;
  expandNotificationAction = new rx.Subject<void>();
  brandsServiceInst = this.brandsService();
  customersServiceInst = this.customersService();
  blockUiId = generateBlockuiId();

  notification$ = observeShareCompChange<Communication>(
    this.lifecycles.onChanges$,
    'notification',
  );

  customerFullName$ = this.streamCustomerFullName();
  emailSnippet$ = this.streamEmailSnippet();
  emailSubject$ = this.streamEmailSubject();

  /*@ngInject */
  constructor(
    readonly modalService: ModalService,
    readonly externalWindowService: ExternalWindowService,
    readonly brandsService: () => BrandsService,
    readonly customersService: () => CustomersService,
  ) {
    useStreams(
      [
        this.streamExpandNotification(),
        this.customerFullName$,
        this.emailSnippet$,
        this.emailSubject$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamExpandNotification() {
    return rx.pipe(
      () => this.expandNotificationAction,
      rx.tap(() => {
        this.onExpandNotification({ id: this.notification.id });
        this.onMarkNotificationAsRead({ id: this.notification.id });
      }),
      rx.map(() => this.getCommunicationBody(this.notification, true)),
      rx.switchMap((body) => {
        if (!_.isNil(body)) {
          return Promise.resolve(body);
        }
        return this.customersServiceInst
          .setConfig({ blockUiRef: this.blockUiId })
          .getCommunicationPreview(
            this.notification.customer.id,
            this.notification.id,
          )
          .then((res) => res.plain())
          .catch((e) => {
            return null;
          });
      }),
      rx.filter(
        (res) => !_.isNil(res) && !_.isNil(res.body) && !_.isNil(res.subject),
      ),
      rx.tap(({ body, subject, attachmentsCount }) => {
        const sandboxedIframe = getSandboxedIframe(
          body,
          subject,
          attachmentsCount,
        );
        this.externalWindowService.openContentInCenter(sandboxedIframe);
      }),
    )(null);
  }

  getCommunicationBody(communication: Communication, isEmail: boolean): string {
    if (_.isNil(communication.details)) {
      return '';
    }

    if (isEmail) {
      let detailsObject;
      try {
        detailsObject = JSON.parse(communication.details);
      } catch (e) {
        // if the flow ends up here, the details are not of JSON type. We now know that the details are a plain string (pre-gmail format), and should simply return them as is.
        return communication.details;
      }
      if (_.isNil(detailsObject)) {
        return null;
      }
      return detailsObject.body;
    }
    return communication.details;
  }

  openSendEmailDialog(customer: Customer) {
    this.modalService.open({
      component: 'prfSendEmailDialog',
      resolve: {
        customer,
        brand: customer.brand,
      },
      size: 'lg',
    });
  }

  /**
   * Open send email dialog
   */
  showEmailDialog($event: MouseEvent) {
    $event.stopPropagation();
    this.onMarkNotificationAsRead({ id: this.notification.id });

    if (!_.isNil(this.notification.customer.brand)) {
      this.openSendEmailDialog(this.notification.customer);
    } else {
      this.brandsServiceInst
        .setConfig({ blockUiRef: this.blockUiId })
        .getOneWithQuery(this.notification.customer.brandId)
        .then((res) => {
          this.notification.customer.brand = res.plain();
          this.openSendEmailDialog(this.notification.customer);
        })
        .catch(() => {});
    }
  }

  streamCustomerFullName() {
    return rx.pipe(
      () => this.notification$,
      rx.filter((notification) => !_.isNil(notification)),
      rx.map((notification) => notification.customer),
      rx.filter((customer) => !_.isNil(customer)),
      rx.map((customer) => `${customer.firstName} ${customer.lastName}`),
      rx.startWith(''),
      shareReplayRefOne(),
    )(null);
  }

  streamEmailSnippet() {
    return rx.pipe(
      () => this.notification$,
      rx.filter((notification) => !_.isNil(notification)),
      rx.map((notification) => notification.details),
      rx.filter((details) => !_.isNil(details)),
      rx.map((details) => {
        let detailsObject;
        try {
          detailsObject = JSON.parse(details);
        } catch (e) {
          return '';
        }
        if (_.isNil(detailsObject) || _.isNil(detailsObject.snippet)) {
          return '';
        }
        return _.unescape(detailsObject.snippet);
      }),
      rx.startWith(''),
      shareReplayRefOne(),
    )(null);
  }

  streamEmailSubject() {
    return rx.pipe(
      () => this.notification$,
      rx.filter((notification) => !_.isNil(notification)),
      rx.map((notification) => notification.details),
      rx.filter((details) => !_.isNil(details)),
      rx.map((details) => {
        let detailsObject;
        try {
          detailsObject = JSON.parse(details);
        } catch (e) {
          return '';
        }
        if (_.isNil(detailsObject) || _.isNil(detailsObject.subject)) {
          return '';
        }
        return detailsObject.subject;
      }),
      rx.startWith(''),
      shareReplayRefOne(),
    )(null);
  }
}

export const GmailNotificationComponent = {
  template,
  controller: GmailNotificationController,
  bindings: {
    notification: '<',
    onExpandNotification: '&',
    onOpenCustomerPage: '&',
    onMarkNotificationAsRead: '&',
  },
};
