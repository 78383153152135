import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { ApiMt4GroupsService } from '~/source/common/api-cfd-platform/api-mt4-groups.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PubsubKey } from '~/source/common/models/pubsub-key';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import { directivesPriorities } from '../constants/directives-priorities';
import { PlatformSessionInfo } from './platform-session-info';

interface MtSecurity {}

export class CurrentMt4SecurityOfGroupStoreServiceDirectiveController {
  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  getOneActionObs$ = new rx.Subject<rx.Observable<any>>();

  updateActionObs$ = new rx.Subject<rx.Observable<any>>();

  securityS = this.streamSecurity();

  /* @ngInject */
  constructor(
    readonly prfApiMt4GroupsService: ApiMt4GroupsService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams([this.securityS.stream$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamSecurityFromGet() {
    return generateInProcessStream(
      this.getOneActionObs$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(
              rx.catchError((e) => {
                inProcess$.next({ inProcess: false, error: e });
                log.error('error fetching security', e);
                return rx.obs.of(null);
              }),
            ),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamSecurityFromUpdate() {
    return generateInProcessStream(
      this.updateActionObs$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(
              rx.catchError((e) => {
                inProcess$.next({ inProcess: false, error: e });
                log.error('error updating security', e);
                return rx.obs.NEVER;
              }),
            ),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamSecurity() {
    const securityFromGetS = this.streamSecurityFromGet();
    const securityFromUpdateS = this.streamSecurityFromUpdate();

    return generateInProcessStream(
      rx.obs.merge(securityFromGetS.stream$, securityFromUpdateS.stream$),
      () => rx.pipe(shareReplayRefOne()),
      [securityFromGetS.inProcess$, securityFromUpdateS.inProcess$],
    );
  }

  load(
    sessionInfo: PlatformSessionInfo,
    mtGroupId: number,
    securityId: number,
  ) {
    const action$ = rx.pipe(
      () =>
        rx.obs.from(
          this.prfApiMt4GroupsService.getGroupSecurity(
            sessionInfo.session.apiUrl,
            sessionInfo.session.token,
            mtGroupId,
            securityId,
          ),
        ),
      shareReplayRefOne(),
    )(null);

    this.getOneActionObs$.next(action$);
    return action$;
  }

  update(changes: Partial<MtSecurity>) {
    const action$ = rx.pipe(
      () => rx.obs.of(changes),
      rx.withLatestFrom(
        this.securityS.stream$,
        this.prfCurrentPlatformSession.sessionS.stream$,
      ),
      rx.switchMap(([changes, security, sessionInfo]) => {
        if (!sessionInfo.isLoggedIn) {
          throw new Error('update action can not be performed. Not logged in');
        }

        return this.prfApiMt4GroupsService.updateGroupSecurity(
          security.mtCustomerGroup.id,
          security.id,
          changes,
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      rx.tap(() =>
        this.prfClientGeneralPubsub.publish(PubsubKey.Mt4SecurityUpdated, null),
      ),
      shareReplayRefOne(),
    )(null);

    this.updateActionObs$.next(action$);
    return action$;
  }
}

export const currentMt4SecurityOfGroupStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentMt4SecurityOfGroupStoreServiceDirectiveController,
  };
};

currentMt4SecurityOfGroupStoreServiceDirective.$inject = [] as string[];
