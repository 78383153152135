import Component from './base/component';
import { Customer, Language } from '@proftit/crm.api.models.entities';
import { CustomersService } from '~/source/contact/common/services/customers';
import { ItemLabelStrategy } from './base/item-label-strategy';
import {
  observeShareCompChange,
  observeComponentLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

class Controller extends Component.controller<CustomersService> {
  static $inject = ['customersService', ...Component.controller.$inject];

  lifecycles = observeComponentLifecycles(this);

  customer: Customer;

  language: Language;

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  language$ = observeShareCompChange<Language>(
    this.lifecycles.onChanges$,
    'language',
  );

  constructor(d, ...args) {
    super(d, ...args);

    useStreams([this.streamFetchData()], this.lifecycles.onDestroy$);
  }

  init() {}

  streamFetchData() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.customer$, this.language$),
      rx.tap(([customer, language]) => {
        this.fetchData();
      }),
    )(null);
  }

  get defaultPlaceholder() {
    return 'emailTemplates.SELECT_CONTENT_TEMPLATE';
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst.getContentTemplatesByLanguage(
      this.customer.id,
      this.language.id,
    );
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.name;
  }
}

export const CustomerContentTemplateSelectComponent = Component.config({
  controller: Controller,
  bindings: {
    customer: '<',
    language: '<',
  },
});
