import template from './gmail-notification-center.component.html';

const styles = require('./gmail-notification-center.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { getUserOwnCustomerCommunicationChannelName } from '~/source/common/utilities/get-user-own-customer-communication-channel-name';
import TokensService from '~/source/auth/services/tokens';
import CommunicationsService from '~/source/common/components/call-manager/communications.service';
import { Communication } from '~/source/common/models/communication';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import CommunicationsSocketService from '~/source/common/components/call-manager/communications-socket.service';
import {
  CustomerCommunicationSubjectCode,
  CommunicationProviderCode,
} from '@proftit/crm.api.models.enums';
import UsersService from '~/source/management/user/services/users';
import { User } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { ClearEmailNotificationsService } from '~/source/common/services/clear-email-notifications.service';
import { UserSocketService } from '~/source/common/services/user-socket';
import { UserEmailsService } from '~/source/common/services/user-emails.service';

const NOTIFICATIONS_PAGE_SIZE = 20;

export class GmailNotificationCenterController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  togglePopupAction = new rx.Subject<void>();
  togglePinPopup$ = new rx.Subject<boolean>();
  loadMoreNotificationsAction = new rx.Subject<void>();
  nextPageAction = new rx.Subject<void>();
  onMarkNotificationAsReadAction = new rx.Subject<{ id: number }>();
  reloadNotificationsAction = new rx.Subject<void>();
  onClearPanelAction = new rx.Subject<void>();

  pageNumber$ = new rx.BehaviorSubject<number>(0);
  isFetchingNotifications$ = new rx.BehaviorSubject<boolean>(false);
  isReloadingNotifications$ = new rx.BehaviorSubject<boolean>(false);
  totalNotificationsCount$ = new rx.BehaviorSubject<number>(0);
  notifications$ = new rx.BehaviorSubject<Communication[]>([]);
  shouldShowPopup$ = new rx.BehaviorSubject<boolean>(false);
  newNotificationsFromStreamerCount$ = new rx.BehaviorSubject<number>(0);
  shouldShowNewEmailsButton$ = new rx.BehaviorSubject<boolean>(false);
  scrollTopActionId$ = new rx.BehaviorSubject<number>(0);

  isGmailEnabled$ = this.streamIsGmailEnabled();
  shouldPinPopup$ = this.streamShouldPinPopup();
  unreadNotificationsCount$ = this.streamUnreadNotificationsCount();

  usersServiceInst: UsersService;

  /*@ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly communicationsService: CommunicationsService,
    readonly communicationsSocketService: CommunicationsSocketService,
    readonly usersService: () => UsersService,
    readonly clearEmailNotificationsService: ClearEmailNotificationsService,
    readonly userSocketService: UserSocketService,
    readonly userEmailsService: UserEmailsService,
  ) {
    this.usersServiceInst = this.usersService();

    useStreams(
      [
        this.streamTogglePopup(),
        this.streamPageNumber(),
        this.streamMarkNotificationAsRead(),
        this.streamNewGmailCommunicationsFromStreamer(),
        this.streamNotifications(),
        this.streamOnClearPanel(),
        this.shouldPinPopup$,
        this.unreadNotificationsCount$,
        this.isGmailEnabled$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  buildChannelNewUserCommunications(userId: number) {
    return `user.${userId}.own.CustomerCommunication.new`;
  }

  buildChannelUserRoot(userId: number) {
    return `user.${userId}.${this.userSocketService.channelRoot}`;
  }

  streamIsGmailEnabled() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        const user = this.tokensService.getCachedUser();
        if (_.isNil(user)) {
          return Promise.resolve(false);
        }
        return this.usersServiceInst
          .embed(['brands.emails', 'brands.emailCredentials'])
          .getOneWithQuery<IElementRestNg<User>>(user.id)
          .then((res) => {
            const { brands } = res;
            if (_.isNil(brands)) {
              return false;
            }
            const brandsWithEmailProviderActive = brands.filter(
              (brand) =>
                brand.emailCredentials.length > 0 &&
                brand.emailCredentials[0].isEmailProviderActive,
            );
            return brandsWithEmailProviderActive.some((brand) => {
              const { emails } = brand;
              if (_.isNil(emails)) {
                return false;
              }
              return emails.some((email) => email.isConnected);
            });
          })
          .catch((e) => false);
      }),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamUnreadNotificationsCount() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamUnreadNotificationsCountFromInit(),
          this.streamUnreadNotificationsCountFromStreamerUpdate(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamUnreadNotificationsCountFromInit() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        const cachedUser = this.tokensService.getCachedUser();
        if (_.isNil(cachedUser)) {
          return 0;
        }
        return cachedUser.unreadEmailNotifications || 0;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUnreadNotificationsCountFromStreamerUpdate() {
    const user = this.tokensService.getCachedUser();
    if (_.isNil(user) || _.isNil(user.id)) {
      return rx.obs.EMPTY;
    }
    const unreadNotificationsChannel = this.buildChannelUserRoot(
      Number(user.id),
    );

    return rx.pipe(
      () => observeChannel(this.userSocketService, unreadNotificationsChannel),
      rx.map((user: User) => user.unreadEmailNotifications),
      rx.tap((unreadEmailNotifications) => {
        this.tokensService.updateUserCache({
          unreadEmailNotifications,
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewGmailCommunicationsFromStreamer() {
    const user = this.tokensService.getCachedUser();
    if (_.isNil(user)) {
      return rx.obs.EMPTY;
    }
    return rx.pipe(
      () =>
        observeChannel(
          this.communicationsSocketService,
          this.buildChannelNewUserCommunications(Number(user.id)),
        ),
      rx.filter(
        (communication: Communication) =>
          communication.providerCode === CommunicationProviderCode.Gmail &&
          communication.subjectCode ===
            CustomerCommunicationSubjectCode.IncomingEmail,
      ),
      rx.withLatestFrom(this.newNotificationsFromStreamerCount$),
      rx.tap(([message, currentNotificationsFromStreamerCount]) => {
        this.newNotificationsFromStreamerCount$.next(
          currentNotificationsFromStreamerCount + 1,
        );
        this.shouldShowNewEmailsButton$.next(true);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPageNumber() {
    return rx.pipe(
      () => this.nextPageAction,
      rx.withLatestFrom(this.pageNumber$, this.totalNotificationsCount$),
      rx.filter(
        ([a, pageNumber, totalNotificationsCount]) =>
          (pageNumber + 1) * NOTIFICATIONS_PAGE_SIZE < totalNotificationsCount,
      ),
      rx.tap(([a, pageNumber]) => {
        this.pageNumber$.next(pageNumber + 1);
        this.loadMoreNotificationsAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamTogglePopup() {
    return rx.pipe(
      () => this.togglePopupAction,
      rx.withLatestFrom(
        this.shouldShowPopup$,
        this.newNotificationsFromStreamerCount$,
      ),
      rx.tap(([a, shouldShowPopup, newNotificationsFromStreamerCount]) => {
        const newPopupState = !shouldShowPopup;
        this.shouldShowPopup$.next(newPopupState);
        if (newPopupState && newNotificationsFromStreamerCount > 0) {
          this.reloadNotificationsAction.next();
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamShouldPinPopup() {
    return rx.pipe(
      () => this.togglePinPopup$,
      rx.map((shouldPinPopup) => shouldPinPopup),
      rx.startWith(false),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifications() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPagedNotifications(),
          this.streamNotificationsFromReload(),
        ),
      rx.tap((notifications) => {
        this.notifications$.next(notifications);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPagedNotifications(): rx.Observable<Communication[]> {
    return rx.pipe(
      () =>
        rx.obs.merge(this.isGmailEnabled$, this.loadMoreNotificationsAction),
      rx.withLatestFrom(this.pageNumber$, this.isGmailEnabled$),
      rx.filter(([a, b, isGmailEnabled]) => isGmailEnabled),
      rx.tap(() => {
        this.isFetchingNotifications$.next(true);
      }),
      rx.switchMap(([a, pageNumber]) => {
        return this.getNotifications(pageNumber);
      }),
      rx.filter((gmailEmails) => !_.isNil(gmailEmails)),
      rx.withLatestFrom(this.notifications$),
      rx.map(([gmailEmails, notifications]) => {
        const allNotifications = [...notifications, ...gmailEmails];
        return allNotifications;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNotificationsFromReload() {
    return rx.pipe(
      () => this.reloadNotificationsAction,
      rx.tap(() => {
        this.isReloadingNotifications$.next(true);
      }),
      rx.switchMap(() => {
        return this.getNotifications(0);
      }),
      rx.filter((emails) => !_.isNil(emails)),
      rx.withLatestFrom(this.scrollTopActionId$),
      rx.tap(([notificationsFromFirstPage, scrollTopActionId]) => {
        this.scrollTopActionId$.next(scrollTopActionId + 1);
        this.pageNumber$.next(0);
        this.shouldShowNewEmailsButton$.next(false);
      }),
      rx.map(([notificationsFromFirstPage, scrollTopActionId]) => {
        return notificationsFromFirstPage;
      }),
      shareReplayRefOne(),
    )(null);
  }

  getNotifications(page: number) {
    const userEmailsService = this.userEmailsService;
    return userEmailsService
      .setConfig({ suppressBlockUi: true })
      .getGmailUnarchivedEmails(page, NOTIFICATIONS_PAGE_SIZE)
      .then((res) => {
        this.totalNotificationsCount$.next(userEmailsService.total);
        return res.plain();
      })
      .catch((e) => {
        return null;
      })
      .finally(() => {
        this.isFetchingNotifications$.next(false);
        this.isReloadingNotifications$.next(false);
      });
  }

  streamMarkNotificationAsRead() {
    return rx.pipe(
      () => this.onMarkNotificationAsReadAction,
      rx.withLatestFrom(this.notifications$),
      rx.tap(([{ id }, notifications]) => {
        const notificationIndex = notifications.findIndex(
          (email) => email.id === id,
        );
        if (notificationIndex < 0) {
          return;
        }
        const newNotifications = _.set(
          [notificationIndex, 'isUnread'],
          false,
          notifications,
        );
        this.notifications$.next(newNotifications);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnClearPanel() {
    return rx.pipe(
      () => this.onClearPanelAction,
      rx.switchMap(() => {
        return this.clearEmailNotificationsService
          .getEmptyResource()
          .postWithQuery({})
          .catch(() => null);
      }),
      rx.filter((x) => !_.isNil(x)),
      rx.tap(() => {
        this.reloadNotificationsAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const GmailNotificationCenterComponent = {
  template,
  controller: GmailNotificationCenterController,
};
