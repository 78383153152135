import template from './logged-in-from-another-location-error.component.html';
const styles = require('./logged-in-from-another-location-error.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import type { StateService } from '@uirouter/angularjs';

export class LoggedInFromAnotherLocationErrorController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  username: string;
  ip: string;
  country: string;
  browser: string;

  /*@ngInject */
  constructor(readonly $state: StateService) {}

  $onInit() {
    this.username = this.$state.params.username;
    this.ip = this.$state.params.ip;
    this.country = this.$state.params.country;
    this.browser = this.$state.params.browser;
  }

  $onDestroy() {}

  $onChanges() {}
}

export const LoggedInFromAnotherLocationErrorComponent = {
  template,
  controller: LoggedInFromAnotherLocationErrorController,
};
