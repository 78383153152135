import template from './add-contact-form.component.html';

const styles = require('./add-contact-form.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { filterCompChange } from '../../utilities/rxjs/observables/filter-comp-change';
import {
  Country,
  Brand,
  Platform,
  Currency,
  Desk,
  User,
  Campaign,
} from '@proftit/crm.api.models.entities';
import { createObsGetterSetter } from '../../utilities/create-obs-getter-setter';
import { AddContactFormModel } from './add-contact-form-model';
import { wrapAsChange } from '../../utilities/wrap-as-change';
import { TradingAccountStatus } from '../../models/trading-account-status';
import { ForexGroup } from '../../models/forex-group';
import { Permissions } from '~/source/common/models/permission-structure';
import * as rx from '@proftit/rxjs';
import BaseController from '../../controllers/base';

interface ModelChange {
  fieldName: string;
  nextValue: any;
}

/**
 *
 */
export class AddContactFormController extends BaseController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  Permissions = Permissions;

  model;
  showCustomerFields: boolean;
  onChangeOfModel: (a: { change: ModelChange }) => void;
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  customerTypeOptions = [
    { value: true, label: 'Lead' },
    { value: false, label: 'Customer' },
  ];
  accountIsDemoOptions = [
    { value: false, label: 'Real' },
    { value: true, label: 'Demo' },
  ];

  addContactForm;
  local: Partial<AddContactFormModel> = {};
  isLead$: rx.BehaviorSubject<boolean>;
  firstName$: rx.BehaviorSubject<string>;
  lastName$: rx.BehaviorSubject<string>;
  email$: rx.BehaviorSubject<string>;
  password$: rx.BehaviorSubject<string>;
  phone$: rx.BehaviorSubject<string>;
  countryPrefix$: rx.BehaviorSubject<string>;
  country$: rx.BehaviorSubject<Country>;
  brand$: rx.BehaviorSubject<Brand>;
  desk$: rx.BehaviorSubject<Desk>;
  user$: rx.BehaviorSubject<User>;
  campaign$: rx.BehaviorSubject<Campaign>;
  accountPlatform$: rx.BehaviorSubject<Platform>;
  accountCurrency$: rx.BehaviorSubject<Currency>;
  accountStatus$: rx.BehaviorSubject<TradingAccountStatus>;
  accountIsDemo$: rx.BehaviorSubject<boolean>;
  accountGroup$: rx.BehaviorSubject<ForexGroup>;
  modelChangeOut$ = new rx.Subject<ModelChange>();

  /*@ngInject */
  constructor(readonly $validation) {
    super();
    this.isLead$ = createObsGetterSetter<boolean>('isLead', this.local);
    this.firstName$ = createObsGetterSetter<string>('firstName', this.local);
    this.lastName$ = createObsGetterSetter<string>('lastName', this.local);
    this.email$ = createObsGetterSetter<string>('email', this.local);
    this.password$ = createObsGetterSetter<string>('password', this.local);
    this.phone$ = createObsGetterSetter<string>('phone', this.local);
    this.countryPrefix$ = createObsGetterSetter<string>(
      'countryPrefix',
      this.local,
    );
    this.country$ = createObsGetterSetter<Country>('country', this.local);
    this.brand$ = createObsGetterSetter<Brand>('brand', this.local);
    this.campaign$ = createObsGetterSetter<Campaign>('campaign', this.local);
    this.desk$ = createObsGetterSetter<Desk>('desk', this.local);
    this.user$ = createObsGetterSetter<User>('user', this.local);
    this.accountPlatform$ = createObsGetterSetter<Platform>(
      'accountPlatform',
      this.local,
    );
    this.accountCurrency$ = createObsGetterSetter<Currency>(
      'accountCurrency',
      this.local,
    );
    this.accountStatus$ = createObsGetterSetter<TradingAccountStatus>(
      'accountStatus',
      this.local,
    );
    this.accountGroup$ = createObsGetterSetter<ForexGroup>(
      'accountGroup',
      this.local,
    );
    this.accountIsDemo$ = createObsGetterSetter<boolean>(
      'accountIsDemo',
      this.local,
    );

    useStreams([this.streamModelToForm()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [this.streamFormChangesOut(), this.streamFormValidOut()],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamModelToForm() {
    return rx.pipe(
      () =>
        filterCompChange<AddContactFormModel>(
          'model',
          this.lifecycles.onChanges$,
        ),
      rx.map(({ currentValue }) => currentValue),
      rx.tap((model) => {
        this.isLead$.next(model.isLead);
        this.firstName$.next(model.firstName);
        this.lastName$.next(model.lastName);
        this.phone$.next(model.phone);
        this.countryPrefix$.next(model.countryPrefix);
        this.country$.next(model.country);
        this.brand$.next(model.brand);
        this.desk$.next(model.desk);
        this.user$.next(model.user);
        this.accountPlatform$.next(model.accountPlatform);
        this.accountCurrency$.next(model.accountCurrency);
        this.accountGroup$.next(model.accountGroup);
        this.accountStatus$.next(model.accountStatus);
        this.accountIsDemo$.next(model.accountIsDemo);
      }),
    )(null);
  }

  streamFormChangesOut() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.isLead$.pipe(rx.distinctUntilChanged(), wrapAsChange('isLead')),
          this.firstName$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('firstName'),
          ),
          this.lastName$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('lastName'),
          ),
          this.phone$.pipe(rx.distinctUntilChanged(), wrapAsChange('phone')),
          this.countryPrefix$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('countryPrefix'),
          ),
          this.email$.pipe(rx.distinctUntilChanged(), wrapAsChange('email')),
          this.password$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('password'),
          ),
          this.country$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('country'),
          ),
          this.brand$.pipe(rx.distinctUntilChanged(), wrapAsChange('brand')),
          this.campaign$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('campaign'),
          ),
          this.desk$.pipe(rx.distinctUntilChanged(), wrapAsChange('desk')),
          this.user$.pipe(rx.distinctUntilChanged(), wrapAsChange('user')),
          this.accountPlatform$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('accountPlatform'),
          ),
          this.accountCurrency$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('accountCurrency'),
          ),
          this.accountGroup$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('accountGroup'),
          ),
          this.accountStatus$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('accountStatus'),
          ),
          this.accountIsDemo$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('accountIsDemo'),
          ),
        ),
      rx.tap((change) => this.modelChangeOut$.next(change)),
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.modelChangeOut$,
      rx.debounceTime(150),
      rx.map(() => this.$validation.checkValid(this.addContactForm)),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }

  setUserAndDesk(user, desk) {
    this.local.user = user;
    this.local.desk = desk;

    return Promise.resolve();
  }
}

export const AddContactFormComponent = {
  template,
  controller: AddContactFormController,
  bindings: {
    model: '<',
    showCustomerFields: '<',
    showPlatformTypeForexFields: '<',
    showAssignedTo: '<',
    onChangeOfModel: '&',
    onChangeOfFormValidity: '&',
  },
};
