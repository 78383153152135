let CustomEvent;

(function (CustomEvent) {
  CustomEvent["TranslateChangeError"] = "$translateChangeError";
  CustomEvent["TranslateChangeSuccess"] = "$translateChangeSuccess";
  CustomEvent["TranslateChangeEnd"] = "$translateChangeEnd";
  CustomEvent["TranslateLoadingStart"] = "$translateLoadingStart";
  CustomEvent["TranslateLoadingSuccess"] = "$translateLoadingSuccess";
  CustomEvent["TranslateLoadingEnd"] = "$translateLoadingEnd";
  CustomEvent["TranslateLoadingError"] = "$translateLoadingError";
  CustomEvent["TranslateRefreshStart"] = "$translateRefreshStart";
  CustomEvent["TranslateRefreshEnd"] = "$translateRefreshEnd";
  CustomEvent["TranslateReady"] = "$translateReady";
})(CustomEvent || (CustomEvent = {}));

export { CustomEvent };
