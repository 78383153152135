import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './currency-exposure.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
const styles = require('./currency-exposure.component.scss');
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { generateUuid } from '@proftit/general-utilities';
import { ApiTrcExposureService } from '~/source/common/api-cfd-platform/api-trc-exposure.service';
import { TrcBrandExposureCurrenciesTotalSocketService } from '~/source/common/api-cfd-platform/trc-brand-exposure-currencies-total-socket.service';
import { observeChannel } from '~/source/common/utilities/observe-channel';

const GLOBAL_GROWL_ID = 'restService';

export class CurrencyExposureController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  blockUiKey = generateUuid();
  platformBrandId;
  socketServiceInstance;
  metrixBox$;

  /* @ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly trcBrandExposureCurrenciesTotalSocketService: () => TrcBrandExposureCurrenciesTotalSocketService,
    readonly prfApiTrcExposureService: ApiTrcExposureService,
  ) {}

  $onInit() {
    this.metrixBox$ = this.streamMetricBox();
    useStreams([this.metrixBox$], this.lifecycles.onDestroy$);
  }

  streamMetricBoxFromSocket() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
      rx.map((sessionInfo) => {
        const socketServiceInstance = this.trcBrandExposureCurrenciesTotalSocketService();
        socketServiceInstance.setToken(sessionInfo.session.token);
        socketServiceInstance.setStreamerUrl(sessionInfo.session.streamerUrl);

        return {
          socketServiceInstance,
          channelName: `{brand.${sessionInfo.trcBrand.id}}.exposure.currencies`,
        };
      }),
      rx.switchMap(({ socketServiceInstance, channelName }) =>
        observeChannel(socketServiceInstance, channelName),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamMetricBoxInit() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.switchMap((sessionInfo) => {
        return this.prfApiTrcExposureService.getCurrenciesTotals(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamMetricBox() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamMetricBoxInit(),
          this.streamMetricBoxFromSocket(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const CurrencyExposureComponent = {
  template,
  controller: CurrencyExposureController,
  require: {
    prfCurrentPlatformSession: '^',
  },
};
