import { PermissionNormalized } from '../models/permission-structure';

/**
 * Get permission syntax variation for create.
 *
 * @param permission
 * @return permission name for create
 */
export function permissionUpdateFor(permission: PermissionNormalized) {
  return `${permission}_U`;
}
