import template from './send-template-email-form.component.html';
const styles = require('./send-template-email-form.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { ModelChange } from '../../utilities/model-change';
import * as rx from '@proftit/rxjs';
import { Email } from '../../models/email';
import {
  ContentTemplate,
  DesignTemplate,
} from '@proftit/crm.api.models.entities';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import {
  isNotNilFormValidator,
  isEmailFormValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { Permissions } from '~/source/common/models/permission-structure';

export class SendTemplateEmailFormController {
  Permissions = Permissions;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  onChangeOfModel: (a: { change: any }) => void;
  onChangeOfValidity: (a: { isValid: boolean }) => {};

  model$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'model');

  languageForm = new FormControl({
    validators: [isNotNilFormValidator],
  });

  form$ = rx.pipe(
    () => this.model$,
    rx.map((model) => {
      return new FormGroup({
        from: new FormControl(model.from, {
          validators: [isEmailFormValidator],
        }),
        template: new FormControl(model.template, {
          validators: [isNotNilFormValidator],
        }),
      } as any);
    }),
    shareReplayRefOne(),
  )(null);

  /* @ngInject */
  constructor(readonly $validation) {
    useStreams(
      [
        this.form$.pipe(rx.map((form) => form.value$)),
        this.streamFormChangesOut(),
        this.streamFormValidOut(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamFormChangesOut() {
    return rx.pipe(
      () => this.form$.pipe(rx.switchMap((form) => form.value$)),
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.form$.pipe(rx.switchMap((form) => form.isValid$)),
      rx.tap((isValid: boolean) => this.onChangeOfValidity({ isValid })),
    )(null);
  }
}

export const SendTemplateEmailFormComponent = {
  template,
  controller: SendTemplateEmailFormController,
  bindings: {
    model: '<',
    customer: '<',
    onChangeOfModel: '&',
    onChangeOfValidity: '&',
  },
};
