import Promise from 'bluebird';
import Component from './base/component';
import { ReportsBooleanSelectOptions } from './reports-boolean-select-item';

class ReportsBooleanController extends Component.controller {
  query() {
    const values = ReportsBooleanSelectOptions;

    return Promise.resolve(values);
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'booleanSelect';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'name';
  }
}

export default Component.config({ controller: ReportsBooleanController });
