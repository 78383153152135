import SignupFormsService from '../signup-forms.service';

import ManageController from '../form.controller';
import template from '../form.html';

class SignupFormCreateController extends ManageController {
  signupFormsService: SignupFormsService;

  /**
   * called by constructor on init
   * @returns {Promise} returns a promise which resolves to the initial signup form model
   */
  initSignupFormModel() {
    return Promise.resolve({ isLead: false, formComponents: [] });
  }

  /**
   * submit form
   * @returns {Promise}
   */
  submitForm() {
    const normalized = this.modelNormalizer.normalize(this.model);
    normalized.formComponents = this.normalizeComponents(
      this.model.formComponents,
    );

    return this.signupFormsService
      .setConfig({ growlRef: 'signupForm', blockUiRef: 'signupForm' })
      .postWithQuery(normalized)
      .then(this.onSubmitSuccess.bind(this));
  }
}

export default {
  template,
  controller: SignupFormCreateController as any,
  controllerAs: 'vm',
};
