import Component from './base/component';

class WithdrawalTypesController extends Component.controller {
  static $inject = ['withdrawalTypesService', ...Component.controller.$inject];

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'withdrawal.withdrawalTypes';
  }
}

export default Component.config({ controller: WithdrawalTypesController });
