import Component from './base/component';
import { Brand } from '@proftit/crm.api.models.entities';

class DeskSelectController extends Component.controller {
  static $inject = ['brandsService', ...Component.controller.$inject];
  brand: Brand;

  /**
   *  Fetch states on selected brand change
   */
  init() {
    this.$scope.$watch('vm.brand', () => {
      this.output = []; // reset states when the country changes

      if (!this.brand) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst.rest.one(this.brand.id).getList('desks');
  }
  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'desk.SELECT_DESK';
  }
}

export default Component.config({
  controller: DeskSelectController,
  bindings: {
    brand: '=',
    nullable: '=',
  },
});
