import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './signup-form-select-field.component.html';

export class SignupFormSelectFieldController extends BaseController {
  /*@ngInject */
  constructor() {
    super();
  }
}

export const SignupFormSelectFieldComponent = {
  template,
  controller: SignupFormSelectFieldController,
  bindings: {
    field: '<model',
  },
};
