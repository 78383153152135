import template from './design-template-dialog.component.html';
const styles = require('./design-template-dialog.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { getResolveChange } from '~/source/common/utilities/rx-ng-one/operators/get-resolve-change';
import { subscribeForBeforeConstructorChanges } from '~/source/common/utilities/rx-ng-one/utils/subscribe-for-before-constructor-changes';

export interface Resolve {
  id: number;
  action: string;
}

export class DesignTemplateDialogController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  action$ = this.streamAction();
  subAction$ = this.streamSubAction();
  id$ = this.streamId();

  /*@ngInject */
  constructor() {
    subscribeForBeforeConstructorChanges(
      [this.action$, this.subAction$, this.id$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamAction() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<string>('action'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamSubAction() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<string>('subAction'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamId() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<number>('id'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

export const DesignTemplateDialogComponent = {
  template,
  controller: DesignTemplateDialogController,
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<',
  },
};
