import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './custom-email-template-general-form.component.html';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  isNotNilFormValidator,
  isNotEmptyArrayValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { CustomContentTemplate } from '@proftit/crm.api.models.entities';

const styles = require('./custom-email-template-general-form.component.scss');

export class CustomEmailTemplateGeneralFormController {
  onChange: (a: { change }) => void;
  onIsValid: (a: { isValid: boolean }) => void;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<CustomContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  form$ = this.streamForm();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.model$,
        this.form$.pipe(rx.switchMap((f) => f.value$)),
        this.streamNotifyChange(),
        this.streamNotifyIsValid(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => modelToForm(model)),
      shareReplayRefOne(),
    )(null);
  }

  streamNotifyChange() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) =>
        rx.obs.combineLatest(form.value$, form.isAtFirstValue$),
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.tap(([change, a]) => this.onChange({ change })),
    )(null);
  }

  streamNotifyIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onIsValid({ isValid })),
    )(null);
  }
}

function modelToForm(model: CustomContentTemplate) {
  return new FormGroup({
    brands: new FormControl(model.brands, {
      validators: [isNotNilFormValidator, isNotEmptyArrayValidator],
    }),
    departments: new FormControl(model.departments, {
      validators: [isNotNilFormValidator, isNotEmptyArrayValidator],
    }),
  } as any);
}

export const CustomEmailTemplateGeneralFormComponent = {
  template,
  controller: CustomEmailTemplateGeneralFormController,
  bindings: {
    model: '<',
    onChange: '&',
    onIsValid: '&',
  },
};
