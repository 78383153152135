import { getSymbolForCurrency } from '../utilities/get-symbol-for-currency';

/**
 * Filter factory.
 *
 * @returns {filter} filter instance function
 */
export function currencyCodeToSymbolFactory() {
  /**
   * Filter function.
   *
   * @param currencyCode
   * @returns currency symbol
   *
   * @example
   *
   *    {{ 'hello there' | prfSnakeCase }}
   */
  function filter(currencyCode: string) {
    return getSymbolForCurrency(currencyCode);
  }

  return filter;
}
