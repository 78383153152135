import daterangepicker from 'bootstrap-daterangepicker';
import _ from 'underscore';
import s from 'underscore.string';
import moment from 'moment';

function dateRangepickerDecorator($provide) {
  $provide.decorator('dateRangePickerDirective', ($delegate) => {
    const directive = $delegate[0];
    const { link } = directive;
    const optionsModifier = (function () {
      const modify = function (options) {
        const modifiedOptions = {};

        /**
         * Return relative to "now" moment object
         *
         * @param {object} date - {value: xx, unit: "days"/ "month" etc//}
         * unit - accepted by moment string that represents
         * value - can be negative
         * time unit of given value(days, month..)
         * @returns {Moment}
         */
        const parseRelativeTime = function (date) {
          return moment().add(date.value, date.unit);
        };

        /**
         * Calculates boundary values of date range. The start of the
         * range will indicate 00:00 AM and the end of the range
         * will indicate 11:59 PM
         *
         * @param {object} date - {value: xx, unit: "days"/ "month" etc//}
         * unit - accepted by moment string that represents
         * value - can be negative
         * @param {string} bound - start/end
         * @returns {moment}
         */
        const setRangeBound = function (date, bound) {
          const dateParsed = parseRelativeTime(date);
          const boundMap = {
            start: 'startOf',
            end: 'endOf',
          };

          return dateParsed[boundMap[bound]]('day');
        };

        const isValidRelativeDate = function (date) {
          const validKeys = ['value', 'unit'];
          /*
           * return true if date object has the same keys
           * the validKeys property does
           */
          return _.chain(date)
            .keys()
            .sortBy((x) => x)
            .isEqual(validKeys.sort((a, b) => (a < b ? -1 : 1)))
            .value();
        };

        // modifiers

        this.setMinDate = function (value) {
          return isValidRelativeDate(value)
            ? setRangeBound(value, 'start')
            : value;
        };

        this.setMaxDate = function (value) {
          return isValidRelativeDate(value)
            ? setRangeBound(value, 'end')
            : value;
        };

        this.setStartDate = function (...args) {
          return this.setMinDate(...args);
        };

        this.setEndDate = function (...args) {
          return this.setMaxDate(...args);
        };

        _.each(
          options,
          function (value, name: string) {
            const modifier = this[`set${s.capitalize(name)}`];
            if (_.isFunction(modifier)) {
              modifiedOptions[name] = modifier.call(this, value, name);
              return;
            }
            // default
            modifiedOptions[name] = value;
          },
          this,
        );

        return modifiedOptions;
      };

      return {
        modify,
      };
    })();

    directive.compile = function () {
      return function (scope, ...args) {
        /**
         * We want to also override some of the "daterangepicker" functions with our own functionality.
         * Basically, in the original implementation the daterangepicker plugin hides the calendars
         * when a range is selected and auto-applies the selected range.
         * We want to avoid that, and always keep the calendar open.
         *
         * This is not really a recommended thing to do, as it is "magic" and no one will understrand
         * why the calendar is not hiding. but it is the requirement :(
         * don't try this at home.
         *
         * Note that we must NOT use arrow functions here - as we need to preserve the dynamic this
         */
        const origHide = daterangepicker.prototype.hide;
        Object.assign(daterangepicker.prototype, {
          hide(e) {
            origHide.call(this, e);
            // The original hide uses $scope.apply to re-create the picker. we need to run after it.
            scope.$evalAsync(() => {
              // the 'hide' caused the picker to be re-created. so take the new picker:
              const picker = this.element.data('daterangepicker');
              // if alwayShowCalendars is true, re-show the calendar! don't close it
              if (picker && picker.alwaysShowCalendars) {
                picker.show();
              }
            });
          },
        });

        scope.opts = optionsModifier.modify(scope.opts);
        scope.model = optionsModifier.modify(scope.model);
        // call original link method
        link(scope, ...args);
      };
    };
    return $delegate;
  });
}

dateRangepickerDecorator.$inject = ['$provide'];

export default dateRangepickerDecorator;
