import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import {
  DESIGN_TEMPLATE_DELETED,
  DESIGN_TEMPLATE_UPDATED,
  DESIGN_TEMPLATE_CREATED,
} from '../../constants/general-pubsub-keys';
import { DesignTemplate } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '../../models/ielement-rest-ng';

export class DesignTemplatesService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'designTemplates';
  }

  /**
   *
   */
  deleteItem(id: number) {
    return this.removeElement(id).then((x) => {
      this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_DELETED, x);
      return x;
    });
  }

  activateItem(id: number) {
    return this.patchElement(id, {
      isActive: true,
    }).then((x) => {
      this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_UPDATED, x);
      return x;
    });
  }

  deactivateItem(id: number) {
    return this.patchElement(id, {
      isActive: false,
    }).then((x) => {
      this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_UPDATED, x);
      return x;
    });
  }

  createItem(designTemplate: Partial<DesignTemplate>) {
    return this.postWithQuery(designTemplate)
      .then((x) => x.plain())
      .then((x) => {
        this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_CREATED, x);
        return x;
      });
  }

  updateItem(id: number, designTemplate: Partial<DesignTemplate>) {
    return this.patchElement<IElementRestNg<DesignTemplate>>(id, designTemplate)
      .then((data) => data.plain())
      .then((x) => {
        this.prfClientGeneralPubsub.publish(DESIGN_TEMPLATE_UPDATED, x);
        return x;
      });
  }

  getItem(id: number) {
    return this.getOneWithQuery<IElementRestNg<DesignTemplate>>(
      id,
    ).then((data) => data.plain());
  }
}
