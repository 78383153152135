import { Permissions } from '~/source/common/models/permission-structure';

export const tradingAccountsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          createdAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'contactsImportTable.fields.CREATED_AT',
        field: 'createdAt',
        fieldName: 'createdAt',
        filterable: true,
        removable: true,
        show: true,
        sortable: 'createdAt',
        type: 'common',
      },
      {
        title: 'customerTradingAccountsAuditLogsTable.TRADING_ACCOUNT_ID',
        field: 'id',
        fieldName: 'id',
        reportFields: ['id'],
        filterable: false,
        removable: true,
        sortable: 'id',
        show: true,
        type: 'common',
        className: 'prf-nowrap',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        reportFields: ['customerName'],
        fieldFormat: '{{customerName}}',
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TRADING_PLATFORM',
        field: 'tradingPlatform',
        fieldName: 'tradingPlatform',
        fieldFormat: '{{ tradingPlatform }}',
        reportFields: ['tradingPlatform'],
        filterable: false,
        sortable: 'tradingPlatform',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{currency}}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{country}}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        reportFields: ['desk'],
        fieldFormat: '{{desk}}',
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'GROUP',
        field: 'forexGroup',
        fieldName: 'forexGroup',
        reportFields: ['forexGroup'],
        filterable: false,
        sortable: 'forexGroup',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{brand}}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'username',
        fieldName: 'username',
        reportFields: ['username'],
        fieldFormat: '{{username}}',
        filterable: true,
        sortable: 'username',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{campaign}}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'campaignOffer',
        fieldName: 'campaignOffer',
        reportFields: ['campaignOffer'],
        filterable: false,
        sortable: 'campaignOffer',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.BALANCE',
        field: 'balance',
        fieldName: 'balance',
        reportFields: ['balance'],
        fieldFormat: '{{ balance | number : 2}}',
        filterable: true,
        sortable: 'balance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.DEPOSIT_TOTAL',
        field: 'deposits',
        fieldFormat: '{{deposits | number : 2 }}',
        fieldName: 'deposits',
        reportFields: ['deposits'],
        filterable: true,
        sortable: 'deposits',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_DEPOSIT_BASIC',
        field: 'depositsBasic',
        fieldFormat: '{{depositsBasic | baseCurrencyFormat : 2 : true}}',
        fieldName: 'depositsBasic',
        reportFields: ['depositsBasic'],
        filterable: true,
        sortable: 'depositsBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.WITHDRAWAL_TOTAL',
        field: 'withdrawal',
        fieldName: 'withdrawal',
        reportFields: ['withdrawal'],
        fieldFormat: '{{withdrawal | number : 2 }}',
        filterable: true,
        sortable: 'withdrawal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_WITHDRAWAL_BASIC',
        field: 'withdrawalBasic',
        fieldName: 'withdrawalBasic',
        reportFields: ['withdrawalBasic'],
        fieldFormat: '{{withdrawalBasic | baseCurrencyFormat : 2 : true }}',
        filterable: true,
        sortable: 'withdrawalBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.BONUS_TOTAL',
        field: 'bonus',
        fieldName: 'bonus',
        fieldFormat: '{{ bonus | number : 2}}',
        reportFields: ['bonus'],
        filterable: true,
        sortable: 'bonus',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_BONUS_BASIC',
        field: 'bonusBasic',
        fieldName: 'bonusBasic',
        fieldFormat: '{{ bonusBasic | baseCurrencyFormat : 2 : true}}',
        reportFields: ['bonusBasic'],
        filterable: true,
        sortable: 'bonusBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.FTD_DATE',
        field: 'firstDepositDate',
        fieldName: 'firstDepositDate',
        filterable: true,
        removable: true,
        show: true,
        sortable: 'firstDepositDate',
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.WITHDRAWABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        reportFields: ['customerWithdrawableBalance'],
        fieldFormat: '{{ customerWithdrawableBalance | number : 2}}',
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EQUITY',
        field: 'equity',
        fieldName: 'equity',
        reportFields: ['equity'],
        fieldFormat: '{{equity | number : 2 }}',
        filterable: true,
        sortable: 'equity',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.tradingAccount.FREE_MARGIN',
        field: 'freeMargin',
        fieldName: 'freeMargin',
        reportFields: ['freeMargin'],
        fieldFormat: '{{ freeMargin | number : 2 }}',
        filterable: true,
        sortable: 'freeMargin',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.MARGIN',
        field: 'margin',
        fieldName: 'margin',
        reportFields: ['margin'],
        fieldFormat: '{{ margin | number : 2 }}',
        filterable: true,
        sortable: 'margin',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.MARGIN_LEVEL',
        field: 'marginLevel',
        fieldName: 'marginLevel',
        reportFields: ['marginLevel'],
        filterable: true,
        sortable: 'marginLevel',
        fieldFormat: '{{ marginLevel | number : 2 }}',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.positions.SWAP',
        field: 'swap',
        fieldName: 'swap',
        reportFields: ['swap'],
        filterable: true,
        sortable: 'swap',
        fieldFormat: '{{ swap | number : 2 }}',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.VOLUME',
        field: 'volume',
        fieldName: 'volume',
        reportFields: ['volume'],
        fieldFormat: '{{volume | number : 2 }}',
        filterable: true,
        sortable: 'volume',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.REAL',
        field: 'reportsReal',
        fieldName: 'real',
        reportFields: ['real'],
        fieldFormat: '{{real}}',
        filterable: true,
        sortable: 'real',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.CASH_PNL',
        field: 'customerCashPnl',
        fieldName: 'customerCashPnl',
        reportFields: ['customerCashPnl'],
        fieldFormat: '{{customerCashPnl |  number : 2 }}',
        filterable: true,
        sortable: 'customerCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'common.DEPARTMENT',
        field: 'reportsDepartment',
        fieldName: 'department',
        fieldFormat: '{{department}}',
        reportFields: ['department'],
        filterable: true,
        sortable: 'department',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
