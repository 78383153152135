import angular from 'angular';
import ContactsAssignComponent from './contacts-assign/contacts-assign.component';
import AssignSplitterComponent from './assign-splitter/assign-splitter.component';
import AssignStatusComponent from './assign-status/assign-status.component';
import AssignUsersListComponent from './assign-users-list/assign-users-list.component';
import AssignUsersListItemComponent from './assign-users-list-item/assign-users-list-item.component';

export const ContactsAssignModule = angular.module(
  'contact.contactsDashboard.assign',
  [],
);

ContactsAssignModule.component('prfContactsAssign', ContactsAssignComponent)
  .component('prfContactsAssignSplitter', AssignSplitterComponent)
  .component('prfContactsAssignStatus', AssignStatusComponent)
  .component('prfContactsAssignUsersList', AssignUsersListComponent)
  .component('prfContactsAssignUsersListItem', AssignUsersListItemComponent);
