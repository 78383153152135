import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import template from './table-columns-popup.component.html';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { UserSettingsService } from '~/source/common/services/user-settings';
import { IScope, blockUI } from 'angular';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { USER_SETTINGS_UPDATED } from '~/source/common/constants/general-pubsub-keys';

const styles = require('./table-columns-popup.component.scss');

interface EditColumnsPopupResolve {
  defaultTableColumns: any[];
  userSettingsKey: string;
  userSettingVersion: string;
}

enum ListType {
  active = 'active',
  inactive = 'inactive',
}

type MoveTo = {
  item: any;
  from: string;
  index: number;
};

export class TableColumnsPopupController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  close: () => void;

  title = 'contact.EDIT_COLUMNS';
  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  resolve: EditColumnsPopupResolve;
  resolve$ = observeShareCompChange<EditColumnsPopupResolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );
  ListType = ListType;

  defaultTableColumns$: rx.Observable<any[]> = this.resolve$.pipe(
    rx.map((resolve) => {
      return resolve.defaultTableColumns.filter(
        (item) => !['isOnline', 'selector'].includes(item.fieldName),
      );
    }),
    shareReplayRefOne(),
  );

  moveToActive$ = new rx.Subject<MoveTo>();
  moveToInactive$ = new rx.Subject<MoveTo>();
  saveTableColumns$ = new rx.Subject();
  userColumns$ = this.streamUserColumns();
  active$ = this.streamActive();
  inactive$ = this.streamInactive();

  /* @ngInject */

  constructor(
    readonly userSettingsService: UserSettingsService,
    readonly $scope: IScope,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly blockUI: blockUI.BlockUIService,
  ) {
    useStreams([this.resolve$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [this.streamSaveTableColumns(), this.userColumns$],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamUserColumns() {
    const blockUiInstance = this.blockUI.instances.get(this.blockUiId);
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((x) => x),
      rx.switchMap(() => {
        blockUiInstance.start();
        return this.userSettingsService
          .getSettingWithoutDefault(this.resolve.userSettingsKey)
          .then((data) => {
            if (
              _.isNil(data) ||
              data.value?.version !== this.resolve.userSettingVersion
            ) {
              blockUiInstance.stop();
              return null;
            }
            return data.plain();
          });
      }),
      rx.filter((columns) => !_.isNil(columns) && !_.isNil(columns.value.data)),
      rx.map((columns) =>
        columns.value.data.filter((x) => !['isOnline', 'selector'].includes(x)),
      ),
      rx.tap(() => blockUiInstance.stop()),
      rx.catchError(() => {
        blockUiInstance.stop();
        return rx.obs.NEVER;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUserActiveColumn() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([this.userColumns$, this.defaultTableColumns$]),
      rx.map(([userColumns, defaultTableColumns]) => {
        return userColumns.map((item) => {
          return defaultTableColumns.find(
            (column) => column.fieldName === item,
          );
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUserInactiveColumn() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([this.userColumns$, this.defaultTableColumns$]),
      rx.map(([userColumns, defaultTableColumns]) => {
        return defaultTableColumns.filter((item) => {
          return !userColumns.includes(item.fieldName);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamActive() {
    const state$ = new rx.BehaviorSubject([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamActiveFromInit(),
          this.streamActiveFromMoveToInactive(state$),
          this.streamActiveFromMoveToActiveAction(state$),
        ),
      rx.tap((columns) => {
        state$.next(columns);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveFromMoveToInactive(state$: rx.Observable<any[]>) {
    return rx.pipe(
      () => this.moveToInactive$,
      rx.withLatestFrom(state$),
      rx.map(([{ item, from, index }, state]) => {
        if (from === ListType.inactive) {
          return state;
        }
        return state.filter((element) => element.fieldName !== item.fieldName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveFromMoveToActiveAction(state$: rx.Observable<any[]>) {
    return rx.pipe(
      () => this.moveToActive$,
      rx.withLatestFrom(state$),
      rx.map(([{ item, from, index }, state]) => {
        if (from === ListType.active) {
          const currentIndex = state.findIndex(
            (element) => element.fieldName === item.fieldName,
          );
          return this.moveInArray(state, currentIndex, index, item);
        }

        return this.putInArrayAtIndex(state, index, item);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInactiveFromMoveToInactiveAction(state$: rx.Observable<any[]>) {
    return rx.pipe(
      () => this.moveToInactive$,
      rx.withLatestFrom(state$),
      rx.map(([{ item, from, index }, state]) => {
        if (from === ListType.inactive) {
          const currentIndex = state.findIndex(
            (element) => element.fieldName === item.fieldName,
          );
          return this.moveInArray(state, currentIndex, index, item);
        }
        return this.putInArrayAtIndex(state, index, item);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInactiveFromMoveToActive(state$: rx.Observable<any[]>) {
    return rx.pipe(
      () => this.moveToActive$,
      rx.withLatestFrom(state$),
      rx.map(([{ item, from, index }, state]) => {
        if (from === ListType.active) {
          return state;
        }
        return state.filter((element) => element.fieldName !== item.fieldName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveFromInit() {
    const nullish = null;
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.defaultTableColumns$,
          this.streamUserActiveColumn().pipe(rx.startWith(nullish)),
        ]),
      rx.map(([defaultColumns, userColumns]) => {
        if (_.isNil(userColumns)) {
          return defaultColumns.filter((column) => column.show);
        }
        return userColumns;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInactiveFromInit() {
    const nullish = null;

    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.defaultTableColumns$,
          this.streamUserInactiveColumn().pipe(rx.startWith(nullish)),
        ]),
      rx.map(([defaultColumns, userColumns]) => {
        if (_.isNil(userColumns)) {
          return defaultColumns.filter((column) => !column.show);
        }
        return userColumns;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInactive() {
    const state$ = new rx.BehaviorSubject([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamInactiveFromInit(),
          this.streamInactiveFromMoveToActive(state$),
          this.streamInactiveFromMoveToInactiveAction(state$),
        ),
      rx.tap((columns) => {
        state$.next(columns);
      }),
      shareReplayRefOne(),
    )(null);
  }

  moveToActive(item, from, index) {
    this.moveToActive$.next({ item, from, index });
    return true;
  }

  moveToInactive(item, from, index) {
    this.moveToInactive$.next({ item, from, index });
    return true;
  }

  streamSaveTableColumns() {
    const blockUiInstance = this.blockUI.instances.get(this.blockUiId);

    return rx.pipe(
      () => this.saveTableColumns$,
      rx.withLatestFrom(this.active$),
      rx.tap(([a, activeColumns]) => {
        blockUiInstance.start();
      }),
      rx.switchMap(([a, activeColumns]) => {
        const columnNames = [
          // always add isOnline and selector to the start of the list
          'isOnline',
          'selector',
          ...activeColumns.map((column) => column.fieldName),
        ];

        return this.userSettingsService
          .getSetting(this.resolve.userSettingsKey, columnNames)
          .then((userColumns) => ({ userColumns, columnNames }));
      }),
      rx.switchMap(({ userColumns, columnNames }) => {
        return this.userSettingsService.setSettingValue(userColumns.id, {
          version: this.resolve.userSettingVersion,
          data: columnNames,
        });
      }),
      rx.tap(() => {
        this.prfClientGeneralPubsub.publish(USER_SETTINGS_UPDATED, {
          userSettingsKey: this.resolve.userSettingsKey,
        });
        blockUiInstance.stop();
        this.close();
      }),
      rx.catchError(() => {
        blockUiInstance.stop();
        return rx.obs.NEVER;
      }),
    )(null);
  }

  moveInArray(state: any[], currentIndex: number, index: number, item: any) {
    const newState = [...state];
    newState.splice(currentIndex, 1);
    newState.splice(currentIndex > index ? index : index - 1, 0, item);
    return newState;
  }

  putInArrayAtIndex(state: any[], index: number, item: any): any[] {
    const newState = [...state];
    newState.splice(index, 0, item);
    return newState;
  }
}

export const TableColumnsPopup = {
  template,
  controller: TableColumnsPopupController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
