import template from './link-customer-form.component.html';
const styles = require('./link-customer-form.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { createNgModelSubject } from '~/source/common/utilities/create-ng-model-subject';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import {
  Customer,
  SearchCustomerResult,
} from '@proftit/crm.api.models.entities';

export class LinkCustomerFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  switchIsActiveOnCustomer: (a: { isActive: boolean }) => void;
  onRemoveLink: (a: { id: number }) => void;
  onToggleIsActiveLink: (a: { id: number; isActive: boolean }) => void;
  onAddCustomerToLinks: (a: { customer: SearchCustomerResult }) => void;

  customer$ = this.streamCustomer();
  linkedCustomers$ = this.streamLinkedCustomers();

  opToggleIsActiveLink$ = new rx.Subject<{ id: number; isActive: boolean }>();
  opToggleIsCustomerActive$ = new rx.Subject<boolean>();
  opAddCustomerToLinks$ = new rx.Subject<SearchCustomerResult>();
  onCustomerSearchPostSeach$ = new rx.Subject<SearchCustomerResult[]>();
  isCustomerActive$ = this.streamIsCustomerActive();
  isCustomerActive = createNgModelSubject(
    this.isCustomerActive$,
    this.opToggleIsCustomerActive$,
  );
  showResultsCustomerSearch$ = this.streamShowResultsCustomerSearch();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.customer$,
        this.linkedCustomers$,
        this.isCustomerActive.asObservable(),
        this.streamActionSwitchIsActiveOnCustomer(),
        this.streamActionToggleIsActiveLink(),
        this.streamActionAddCustomerToLinks(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCustomer() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<Customer>('customer'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamLinkedCustomers() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<any[]>('linkedCustomers'),
      rx.map((linkedCustomers) => _.defaultTo([], linkedCustomers)),
      rx.map((linkedCustomers) =>
        linkedCustomers.map((customer) => {
          const reactiveModel = Object.defineProperty({}, 'model', {
            get: () => {
              return customer.isActive;
            },
            set: (isActive: boolean) => {
              this.opToggleIsActiveLink$.next({ isActive, id: customer.id });
            },
          });

          return {
            ...customer,
            _isActive: reactiveModel,
          };
        }),
      ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamIsCustomerActive() {
    return rx.pipe(
      () => this.customer$,
      rx.map((customer) => customer.isActive),
    )(null);
  }

  streamActionSwitchIsActiveOnCustomer() {
    return rx.pipe(
      () => this.opToggleIsCustomerActive$,
      rx.tap((isActive) => this.switchIsActiveOnCustomer({ isActive })),
    )(null);
  }

  streamActionToggleIsActiveLink() {
    return rx.pipe(
      () => this.opToggleIsActiveLink$,
      rx.tap(({ id, isActive }) => this.onToggleIsActiveLink({ id, isActive })),
    )(null);
  }

  streamActionAddCustomerToLinks() {
    return rx.pipe(
      () => this.opAddCustomerToLinks$,
      rx.tap((customer) => this.onAddCustomerToLinks({ customer })),
    )(null);
  }

  streamShowResultsCustomerSearch() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.onCustomerSearchPostSeach$,
          rx.obs.from([false]),
          this.opAddCustomerToLinks$.pipe(rx.map(() => false)),
        ),
      rx.map((results) => {
        if (_.isEmpty(results)) {
          return false;
        }

        return true;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

export const LinkCustomerFormComponent = {
  template,
  controller: LinkCustomerFormController,
  bindings: {
    customer: '<',
    linkedCustomers: '<',
    switchIsActiveOnCustomer: '&',
    onRemoveLink: '&',
    onToggleIsActiveLink: '&',
    onAddCustomerToLinks: '&',
  },
};
