import commonModule from '../index';
import _ from 'underscore';

function underscore() {
  return function (value, fn, paramsP) {
    const params = paramsP || [];
    params.unshift(value);
    return fn ? _[fn].apply(this, params) : value;
  };
}
underscore.$inject = [];

commonModule.filter('underscore', underscore);
