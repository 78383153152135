import { IPromise, IScope } from 'angular';
import _ from 'underscore';
import * as _l from '@proftit/lodash';

import * as rx from '@proftit/rxjs';

import BaseController from '~/source/common/controllers/base';
import { Campaign } from '@proftit/crm.api.models.entities';
import MarketingTrackerParamsService from '~/source/marketing/services/tracker-params.service';
import CampaignsChangesClientPubsubService from '~/source/marketing/services/campaigns-changes-client-pubsub/campaigns-changes-client-pubsub.service';
import useStream from '~/source/common/utilities/use-stream';

import template from './third-party-tracking-form.html';
import { useTrackerState } from './use-tracker-state';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

interface UiParam {
  source: string;
  code: string;
  replaceOf?: string;
}

class ComponentController extends BaseController {
  // bindings
  campaign: Campaign;
  isEdit: boolean;

  settings;
  trackers;
  trackerParams = [];
  unsub$ = new rx.Subject<void>();
  onServerFireClicked: () => void;
  lifecycles = observeComponentLifecycles(this);

  trackerState = useTrackerState(
    this.lifecycles,
    this.marketingCampaignSettings.form.trackers.map((tracker) => tracker.type),
  );

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly marketingCampaignSettings,
    readonly marketingTrackerParamsService: MarketingTrackerParamsService,
    readonly campaignsChangesClientPubsub: CampaignsChangesClientPubsubService,
  ) {
    super();

    this.settings = this.marketingCampaignSettings;

    // get tracker params, set param visibility, arrange tracker display info
    if (this.campaign) {
      this.getTrackerParams().then((data) => {
        this.trackerParams = data;
      });
    }

    this.setWatchers();

    useStream(this.setStreamCampaignPubsubChanges(), this.unsub$);
  }

  $onInit() {}

  /**
   * this page is visible also on update page on load. so we need to make sure we arrange the data
   * only after we got the campaign data
   * @param changes
   */
  $onChanges(changes) {
    if (changes.campaign && changes.campaign.currentValue) {
      this.calcTrackersAndTrackerParams();
    }
  }

  /**
   * Component lifecycle - on destory.
   *
   * Clear existing rxjs subscription by notifying them of component end event.
   *
   * @return {void}
   */
  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * Generate stream to deal with campaign client pupsub changes notification.
   *
   * Currently, we want to recalculate the trackers params based on campaign params.
   * They could have been changed. Ex. when user add campaign params in the page.
   *
   * @return {Observable}
   */
  setStreamCampaignPubsubChanges() {
    return rx.pipe(
      () => this.campaignsChangesClientPubsub.getObs(),
      rx.filter(() => !_l.isNil(this.campaign)),
      rx.filter((campaign) => this.campaign.id === campaign.id),
      rx.tap(() => this.calcTrackersAndTrackerParams()),
    )(null);
  }

  /**
   * Calculate the tracker params and data.
   *
   * @return {Promise}
   */
  calcTrackersAndTrackerParams() {
    // make sure trackers promise resolved, then arrange data for template
    return this.getTrackerParams()
      .then((data) => {
        this.trackerParams = data;
      })
      .then(() => this.arrangeTrackerParamsData())
      .then(() => this.arrangeTrackersData());
  }

  /**
   */
  calcCustomerTrackerParamsFromUiParams(uiParams) {
    return uiParams
      .filter((trackerParam: any) => trackerParam.source === 'customerParam')
      .filter((trackerParam: any) => trackerParam.replaceOf)
      .map((trackerParam: any) => ({
        type: trackerParam.code,
        replaceOf: trackerParam.replaceOf,
      }));
  }

  /**
   */
  calcBrandTrackerParamsFromUiParams(uiParams) {
    return this.campaign.brandTrackerParams.map((brandParam) => {
      if (_l.isEmpty(brandParam.name)) {
        return brandParam;
      }

      const foundParam = uiParams
        .filter((uiParam) => uiParam.source === 'brandParam')
        .find((uiParam) => uiParam.code === brandParam.name);

      return {
        ...brandParam,
        replaceOf:
          _l.isNil(foundParam) || _l.isEmpty(foundParam.replaceOf)
            ? undefined
            : foundParam.replaceOf,
      };
    });
  }

  setApiParamsFromModel(uiParams) {
    this.campaign.trackerParams = this.calcCustomerTrackerParamsFromUiParams(
      uiParams,
    );

    this.campaign.brandTrackerParams = this.calcBrandTrackerParamsFromUiParams(
      uiParams,
    );
  }

  /**
   * watch for trackers & trackerParams changes and convert them to the right format ready for save
   */
  setWatchers() {
    this.$scope.$watch(
      'vm.trackerParams',
      (trackerParams: any) => {
        if (!trackerParams || !this.campaign) {
          return;
        }
        this.setApiParamsFromModel(trackerParams);
      },
      true,
    );

    this.$scope.$watch(
      'vm.trackers',
      (trackers) => {
        if (!trackers || !this.campaign) {
          return;
        }

        // convert tracker to api format
        this.campaign.trackers = _.chain(trackers)
          .filter((tracker: any) => tracker.isServerFire !== undefined)
          .map((tracker: any) => ({
            isServerFire: tracker.isServerFire,
            type: tracker.type,
            content: tracker.content,
          }))
          .value();
      },
      true,
    );
  }

  /**
   * Generate ui param from customer param.
   *
   * @param paramType - campagin tracker param type
   * @param campaignTrackerParams - current source params
   * @return newly ui param
   */
  createUiCustomerParam(paramType, campaignTrackerParams): UiParam {
    const uiCustomerParam = {
      source: 'customerParam',
      code: paramType.code,
    };

    const existingParam = campaignTrackerParams.find(
      (customerParam) => customerParam.type === paramType.code,
    );

    if (_l.isNil(existingParam)) {
      return uiCustomerParam;
    }

    return { ...uiCustomerParam, replaceOf: existingParam.replaceOf };
  }

  /**
   * Generate ui param from brand param.
   *
   * @param brandParam - brandParam
   * @return newly ui param
   */
  createUiBrandParam(brandParam): UiParam {
    let trackerParam: any = {
      source: 'brandParam',
      code: brandParam.name,
    };

    if (!_l.isNil(brandParam.replaceOf)) {
      trackerParam = {
        ...trackerParam,
        replaceOf: brandParam.replaceOf,
      };
    }

    return trackerParam;
  }

  /**
   * Get tracker params from api and from campaign params.
   *
   * @returns {Promise}
   */
  getTrackerParams() {
    return Promise.all([
      this.getCampaignTrackerParamsTypes()
        .then((paramsType) => paramsType.plain())
        .then((paramsTypes) => {
          return paramsTypes.map((paramType) =>
            this.createUiCustomerParam(paramType, this.campaign.trackerParams),
          );
        }),
      new Promise<any[]>((resolve) => {
        const brandParams = _l.defaultTo(
          [],
          _l.get(['brandTrackerParams'], this.campaign),
        );
        const uiParams = brandParams
          .filter((brandParam) => !_l.isNil(brandParam.name))
          .map((brandParam) => this.createUiBrandParam(brandParam));
        resolve(uiParams);
      }),
    ]).then(([customerUiParams, brandUiParams]) => {
      return [...customerUiParams, ...brandUiParams];
    });
  }

  /**
   * Get campaign tracker params types from api
   *
   * @returns {Promise}
   */
  getCampaignTrackerParamsTypes() {
    return this.marketingTrackerParamsService.getListWithQuery();
  }

  /**
   * sets trackerParams visibility
   */
  arrangeTrackerParamsData() {
    this.trackerParams = this.trackerParams.map((trackerParam) => {
      if (_l.isEmpty(trackerParam.replaceOf)) {
        return Object.assign(trackerParam, { visible: false });
      }

      return Object.assign(trackerParam, { visible: true });
    });
  }

  /**
   * arrange tracker data for template
   */
  arrangeTrackersData() {
    // get names of campaign trackers
    const campaignTrackerNames = _.pluck(this.campaign.trackers, 'type');

    this.trackers = this.settings.form.trackers.map((tracker) => {
      const index = _.indexOf(campaignTrackerNames, tracker.type);

      if (index !== -1) {
        Object.assign(tracker, this.campaign.trackers[index]);
      }

      return tracker;
    });
  }

  setUnusedState(type) {
    this.onServerFireClicked();
    this.trackerState.setValid(type);
  }
}

export default {
  template,
  bindings: {
    campaign: '<',
    isEdit: '<',
    onServerFireClicked: '&',
  },
  controller: ComponentController,
  controllerAs: 'vm',
};
