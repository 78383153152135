export const fieldsAndModulesItems = [
  {
    id: 1,
    code: 'CUSTOMER_STATUSES',
  },
  {
    id: 2,
    code: 'COMPLIANCE_STATUSES',
  },
  {
    id: 3,
    code: 'COMMUNICATION_SUBJECTS',
  },
  {
    id: 4,
    code: 'COMMUNICATION_STATUSES',
  },
  {
    id: 5,
    code: 'CLEARING_COMPANY',
  },
  {
    id: 6,
    code: 'PERSONAL_INFORMATION',
  },
  {
    id: 7,
    code: 'ATTACHMENTS',
  },
  {
    id: 8,
    code: 'EWALLETS',
  },
];
