import ng from 'angular';
import SettingsComponent from './notification-settings.component';
import { NotificationsUserRolesComponent } from './notifications-user-roles/notifications-user-roles.component';
import { NotificationsCustomEmailComponent } from './notifications-custom-email/notifications-custom-email.component';
import { NotificationsSingleCustomEmailComponent } from './notifications-single-custom-email/notifications-single-custom-email.component';
import { FieldSettingsItemComponent } from './field-settings-item/field-settings-item.component';

const ngModule = ng
  .module('crm.management.notificationSettings', [
    'crm.constants',
    'crm.common',
    'ui.router',
  ])
  .component('prfNotificationsSettings', SettingsComponent)
  .component('prfNotificationsUserRoles', NotificationsUserRolesComponent)
  .component('prfNotificationsCustomEmail', NotificationsCustomEmailComponent)
  .component(
    'prfNotificationsSingleCustomEmail',
    NotificationsSingleCustomEmailComponent,
  )
  .component('prfFieldSettingsItem', FieldSettingsItemComponent)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.management.notifications.settings', {
        url: '/settings',
        component: 'prfNotificationsSettings',
      });
    },
  ]);

export default ngModule.name;
