import { Customer } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { getPrefixedNumber } from './get-prefixed-number';

export type CustomerPhoneNumber = {
  phoneNumber: string;
  isPrimary: boolean;
};

export const getAllFullNumbersForCustomer = (
  customer: Customer,
): CustomerPhoneNumber[] => {
  const { countryPrefix, phone, countryPrefix2, phone2 } = customer;
  const primaryPhoneNumber = getPrefixedNumber(countryPrefix, phone);
  let result = [
    {
      isPrimary: true,
      phoneNumber: primaryPhoneNumber,
    },
  ];
  if (!_.isNil(phone2) && !_.isEmpty(phone2)) {
    const secondaryPhoneNumber = getPrefixedNumber(countryPrefix2, phone2);
    result = [
      ...result,
      {
        isPrimary: false,
        phoneNumber: secondaryPhoneNumber,
      },
    ];
  }
  return result;
};
