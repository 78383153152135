import {
  Brand,
  Desk,
  UserDepartmentCode,
} from '@proftit/crm.api.models.entities';
import type Moment from 'moment';
import {
  ItemProxy,
  generateValueBean,
  getBeanProxy,
  generateObjectBean,
  setWholeBean,
} from '@proftit/proxy-bean';
import { SimpleAllabeMenuItem } from './simple-allable-menu-item';
import { AssignmentFilter } from './assignment-filter';
import { DateRange } from './date-range';

export interface AutoAssignmentPanelForm {
  brandOption: SimpleAllabeMenuItem<Brand>;
  departmentOption: SimpleAllabeMenuItem<UserDepartmentCode>;
  dateRange: DateRange;
  deskOption: SimpleAllabeMenuItem<Desk>;
  searchTerm: string;
  assigmentFilter: SimpleAllabeMenuItem<AssignmentFilter>;
}

export function generateAutoAssignmentPanelForm(
  initialBrandOption: SimpleAllabeMenuItem<Brand>,
  initialDepartmentOption: SimpleAllabeMenuItem<UserDepartmentCode>,
  initialDeskOption: SimpleAllabeMenuItem<Desk>,
  initialAssignmentFilter: SimpleAllabeMenuItem<AssignmentFilter>,
  initialDateRange: DateRange,
  initialSearchTerm: string,
): ItemProxy<AutoAssignmentPanelForm> {
  const bean = generateObjectBean({
    children: {
      brandOption: generateValueBean(),
      departmentOption: generateValueBean(),
      deskOption: generateValueBean(),
      dateRange: generateDateRangeBean(),
      searchTerm: generateValueBean(),
      assigmentFilter: generateValueBean(),
    },
  });
  const proxy = getBeanProxy(bean);

  setWholeBean(bean, {
    brandOption: initialBrandOption,
    departmentOption: initialDepartmentOption,
    deskOption: initialDeskOption,
    dateRange: initialDateRange,
    assigmentFilter: initialAssignmentFilter,
    searchTerm: initialSearchTerm,
  });

  return proxy;
}

function generateDateRangeBean() {
  return generateObjectBean({
    children: {
      startDate: generateValueBean(),
      endDate: generateValueBean(),
    },
  });
}
