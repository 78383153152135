import TableController from '~/source/common/components/table/table.controller';
import template from './tasks-list.html';
import tasksSettings from './tasks-settings.json';
import { TasksService } from './tasks.service';
import { TokensService } from '../auth/services/tokens';

class TasksListTableController extends TableController {
  tasksService: TasksService;
  tokensService: TokensService;
  taskSearch: string;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      excludeNotifications: [],
      dataServiceInstance: this.tasksService,
      user: this.tokensService.getCachedUser(),
      settings: { ...tasksSettings },
      cols: [...tasksSettings.cols],
    });

    this.initTable();
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  get isInfiniteTable() {
    return true;
  }

  get tableKey() {
    return 'tasks';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiKey })
      .expand(['creator', 'employee', 'taskStatus'])
      .sort({ createdAt: 'desc' });
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    return {
      q: this.taskSearch,
    };
  }

  /**
   * called on task search changes in view
   */
  onTaskSearchChange() {
    this.tableParams.page(1);

    /*
     * set task search value to table filter reference object.
     * this change will trigger api call
     */
    this.tableParams.filter().q = this.taskSearch;
  }
}

TasksListTableController.$inject = [
  ...TableController.$inject,
  'tasksService',
  'tokensService',
  '$state',
  '$timeout',
];

export default {
  template,
  controller: TasksListTableController,
  controllerAs: 'vm',
  bindings: {
    onDone: '&',
    isPopup: '<',
  },
};
