import template from './auto-assignment-audit-log-table.component.html';
const styles = require('./auto-assignment-audit-log-table.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import TableController from '~/source/common/components/table/table.controller';
import RestService from '~/source/common/services/rest';
import { BrandsService } from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import { tableColumns } from './table-columns';
import { tableSettings } from './table-settings';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PopupService } from '~/source/common/components/modal/popup.service';

export class AutoAssignmentAuditLogTableController extends TableController {
  static $inject = [
    'popupService',
    'brandsService',
    ...TableController.$inject,
  ];

  brand: Brand;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  dataServiceInstance: BrandsService;
  popupService: PopupService;

  brandsService: () => BrandsService;

  cols = [...tableColumns];
  tableSettings = { ...tableSettings };
  blockUiId = generateBlockuiId();

  brandId$ = observeShareCompChange<Brand>(
    this.lifecycles.onChanges$,
    'brand',
  ).pipe(rx.map((brand) => brand.id));

  constructor(...args) {
    super(...args);
    useStreams([this.streamBrandChange()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.brandsService();
  }

  streamBrandChange() {
    return rx.pipe(
      () => this.brandId$.pipe(rx.distinctUntilChanged()),
      rx.withLatestFrom(this.isInitTable$),
      rx.tap(([brandId, isTableInit]) => {
        if (!isTableInit) {
          this.initTable();
        } else {
          this.reloadTable();
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.tableSettings;
  }

  $onDestroy() {}

  $onChanges() {}

  fetchFn(): RestService {
    return this.dataServiceInstance
      .getAutoAssignmentLogsResource(this.brand.id)
      .setConfig({ blockUiRef: this.blockUiId })
      .expand(['user', 'brand']);
  }

  openLogsPopupTable(brand: Brand) {
    this.popupService.open({
      component: 'prfAutoAssignmentAuditLogPopup',
      resolve: {
        brand,
      },
    });
  }
}

export const AutoAssignmentAuditLogTableComponent = {
  template,
  controller: AutoAssignmentAuditLogTableController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};
