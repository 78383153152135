import { PlatformCode } from '@proftit/crm.api.models.enums';

export const cfdPlatformGroupsTableCols = [
  {
    title: 'riskManager.GROUP_NAME',
    field: 'group',
    fieldFormat: '{{ name }}',
    sortable: 'name',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.LEVERAGE',
    field: 'leverage',
    fieldFormat: '{{ leverage }}',
    sortable: 'leverage',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    renderer: 'markupSpread',
    title: 'riskManager.MARKUP_SPREAD_X2',
    field: 'markupSpread',
    fieldFormat: '{{ spread }}',
    sortable: 'spread',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MINIMUM_INVESTMENT',
    field: 'minInvestment',
    fieldFormat: '{{ minInvestment.usd }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MAX_INVESTMENT',
    field: 'maxInvestment',
    fieldFormat: '{{ maxInvestment.usd }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    renderer: 'swapCommission',
    headerTooltipIcon: true,
    headerTooltipTextCode: 'riskManager.SWAP_HELPER_TEXT',
    title: 'riskManager.SWAP_COMMISSION',
    field: 'swapCommission',
    fieldName: 'swapCommission',
    fieldFormat:
      '{{ swapCommission }} {{ "cfdPlatformGroupsTable.SWAP_INNER_UNITS_OR" | translate }} {{ (swapRatio | prfZeroOneAsPercentage) || "0" }} %',
    sortable: 'swapCommission',
    show: true,
    filterable: false,
    removable: false,
    relevantPlatforms: [PlatformCode.Cfd],
  },
  {
    // This is empty col for control settings
    renderer: 'actions',
    title: '',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
