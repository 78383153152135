import {
  FormGroup,
  FormArray,
  AbstractControl,
} from '@proftit/ng1.reactive-forms';
import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';
import { switchOn } from '@proftit/general-utilities';
import * as _ from '@proftit/lodash';

const ALL_SEGMENTS_SCORES_SHOULD_RANGE_FROM_ZERO_TO_ONE_HUNDRED =
  'ALL_SEGMENTS_SCORES_SHOULD_RANGE_FROM_ZERO_TO_ONE_HUNDRED';
const EXPECTED_AGGREGATED_SCORE = 100;

export function allSegmentsScoresShouldRangeFromZeroToOneHundred(
  ctrl: FormArray,
) {
  return new Promise((resolve) => {
    const segments = ctrl.value || [];

    if (segments.length === 0) {
      return resolve({
        [ALL_SEGMENTS_SCORES_SHOULD_RANGE_FROM_ZERO_TO_ONE_HUNDRED]: {
          value: ctrl.value,
        },
      });
    }

    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    if (firstSegment.start !== 0) {
      return resolve({
        [ALL_SEGMENTS_SCORES_SHOULD_RANGE_FROM_ZERO_TO_ONE_HUNDRED]: {
          value: ctrl.value,
        },
      });
    }

    if (lastSegment.end !== 100) {
      return resolve({
        [ALL_SEGMENTS_SCORES_SHOULD_RANGE_FROM_ZERO_TO_ONE_HUNDRED]: {
          value: ctrl.value,
        },
      });
    }

    return resolve(null);
  });
}

allSegmentsScoresShouldRangeFromZeroToOneHundred.validatorName = ALL_SEGMENTS_SCORES_SHOULD_RANGE_FROM_ZERO_TO_ONE_HUNDRED;
