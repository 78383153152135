import template from './portfolio-positions-table.component.html';

const styles = require('./portfolio-positions-table.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import { PositionForexService } from '~/source/common/services/position-forex.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { Brand, Platform } from '@proftit/crm.api.models.entities';
import { Permissions } from '~/source/common/models/permission-structure';
import { portfolioPositionsTableSettings } from '~/source/portfolio/components/portfolio-positions-table/portfolio-positions-table.settings';
import { portfolioPositionsTableCols } from '~/source/portfolio/components/portfolio-positions-table/portfolio-positions-table.cols';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import TokensService from '~/source/auth/services/tokens';
import { CustomerPositionStatusesStoreService } from '~/source/common/store-services/customer-position-statuses-store.service';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import PositionsForexSocketService from '~/source/contact/contact-page/trading-account/forex/position/positions-forex-socket.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { streamSubscribeToPositionSocketsUpdates } from '~/source/contact/contact-page/trading-account/common/positions/utils/stream-subscribe-to-position-sockets-updates';
import BrandsService from '~/source/management/brand/services/brands';
import { Mt4OrderPnlSocketService } from '~/source/common/services/mt4-order-pnl-socket.service';
import { CfdPlatformPositionPayoutSocketService } from '~/source/common/api-cfd-platform/cfd-platform-position-payout-socket.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const OPEN_PNL_UPDATE_INTERVAL = 5000;

export class PortfolioPositionsTableController extends TableLiveController<
  PositionForexService
> {
  static $inject = [
    'prfPositionForexService',
    'tokensService',
    'prfCustomerPositionStatusesStoreService',
    'positionsForexSocketService',
    'brandsService',
    'mt4OrderPnlSocketService',
    'cfdPlatformPositionPayoutSocketService',
    ...TableLiveController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  positionsForexSocketService: PositionsForexSocketService;

  prfCustomerPositionStatusesStoreService: CustomerPositionStatusesStoreService;
  tokensService: TokensService;

  brandsService: () => BrandsService;
  mt4OrderPnlSocketService: () => Mt4OrderPnlSocketService;
  cfdPlatformPositionPayoutSocketService: () => CfdPlatformPositionPayoutSocketService;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = generateBlockuiId();
  prfPositionForexService: () => PositionForexService;

  brand: Brand;
  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');
  platform: Platform;
  platform$ = observeShareCompChange<Platform>(
    this.lifecycles.onChanges$,
    'platform',
  );

  user$ = new rx.BehaviorSubject<UserTokenModel>(null);

  Permissions = Permissions;

  settings = { ...portfolioPositionsTableSettings };
  cols = [...portfolioPositionsTableCols];
  tableKeyBinding: string;

  onTableColumnsChanged: any;
  openPositionStatusesIds: number[];
  tblNumOfRows: {
    id: number;
    count: number;
    name: number;
  };

  brandIdForFilters: number;
  platformIdForFilters: number;
  positions: IElementRestNg<any>;

  constructor(...args) {
    super(...args);

    this.dataServiceInstance = this.prfPositionForexService();
    this.user$.next(this.tokensService.getCachedUser());

    useStreams([this.brand$, this.platform$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamInitTable(),
        this.streamPlatformChange(),
        this.streamSetBrandIdForFilter(),
        this.streamSetPlatformIdForFilter(),
        streamSubscribeToPositionSocketsUpdates(
          this.collection$,
          OPEN_PNL_UPDATE_INTERVAL,
          this.mt4OrderPnlSocketService,
          this.cfdPlatformPositionPayoutSocketService,
          (props) => this.onEntityUpdate(props),
          this.prfCurrentPlatformSession,
          this.propertiesToUpdate$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  get tableKey() {
    if (!_.isNil(this.tableKeyBinding)) {
      return this.tableKeyBinding;
    }
    return 'portfolioPositions';
  }

  set searchTerm(searchVal) {
    if (_.isNil(this.tableParams)) {
      return;
    }
    this.tableParams.page(1);
    this.tableParams.filter().q = searchVal;
  }

  /**
   * sets tableParams to new number of rows
   * @param {number} numOfRows is the number of rows the table will have in every page
   * @returns {void}
   */
  setNumberOfRows(numOfRows): void {
    super.setNumberOfRows(numOfRows.count);
  }

  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiRef })
      .getPositionsByBrandAndPlatform(
        this.brand.id,
        this.platform.id,
        this.openPositionStatusesIds,
      );
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.lifecycles.onInit$,
          this.prfCustomerPositionStatusesStoreService
            .openCustomerPositionStatusesIds$,
        ),
      rx.filter(([a, ids]) => !_.isNil(ids)),
      rx.tap(([a, ids]) => {
        this.openPositionStatusesIds = ids;
        const tempCountRows = this.settings.ngTable.parameters.count;
        this.tblNumOfRows = {
          count: tempCountRows,
          id: numRowsOptions.indexOf(
            numRowsOptions.find((count) => tempCountRows === count),
          ),
          name: tempCountRows,
        };
        this.initTable(this.tblNumOfRows.count);
      }),
    )(null);
  }

  /**
   * Stream for platform change.
   */
  streamPlatformChange() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(this.platform$, this.brand$, this.isInitTable$),
      rx.filter(
        ([platform, brand, isInitTable]) => !_.isNil(platform) && isInitTable,
      ),
      rx.map(([platform, brand, isInitTable]) => platform),
      rx.map((platform) => {
        return this.calculateForexPositionTableCols(
          portfolioPositionsTableCols,
          platform as any,
        );
      }),
      rx.tap((newCols) => {
        this.cols = newCols;
        this.onTableColumnsChanged({ newCols });
      }),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  calculateForexPositionTableCols(tableColumns, platform: Platform) {
    const cols = tableColumns.reduce((acc, col) => {
      if (
        col.relevantPlatforms &&
        !col.relevantPlatforms.includes(platform.code)
      ) {
        return acc;
      }

      acc.push(col);
      return acc;
    }, []);

    return cols;
  }

  streamSetBrandIdForFilter() {
    return rx.pipe(
      () => this.brand$,
      rx.tap((brand) => (this.brandIdForFilters = brand.id)),
    )(null);
  }

  streamSetPlatformIdForFilter() {
    return rx.pipe(
      () => this.platform$,
      rx.tap((platform) => (this.platformIdForFilters = platform.id)),
    )(null);
  }

  parseLoadedData(positions) {
    super.parseLoadedData(positions);
    this.positions = positions;
    return positions;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Object} Socket service instance
   */
  get socketService() {
    return this.positionsForexSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.positions';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.positions;
  }
}

export const PortfolioPositionsTableComponent = {
  template,
  controller: PortfolioPositionsTableController,
  bindings: {
    brand: '<',
    platform: '<',
    onTableColumnsChanged: '&',
    skipDealFilters: '<',
    tableKeyBinding: '<',
    searchTerm: '<',
  },
  controllerAs: 'vm',
  require: {
    prfCurrentPlatformSession: '^',
  },
};
