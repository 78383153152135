import RestService from '~/source/common/services/rest';

export class DepartmentsService extends RestService {
  /**
   * Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'departments';
  }

  useCache() {
    return true;
  }
}

export default DepartmentsService;
