import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import template from './platforms-tab.html';
import { IScope } from 'angular';

class Controller extends BaseController {
  // bindings
  data;
  chartOptions;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $translate: angular.translate.ITranslateService,
  ) {
    super();
  }

  $onChanges(changes) {
    // changes in platform data
    if (changes.data && changes.data.currentValue) {
      this.extractData(changes.data.currentValue).then(
        (data) => (this.data = data),
      );
    }
  }

  /**
   * extract api data
   *
   * @param {array} data platform api data
   * @returns {Promise}
   */
  extractData(data) {
    // translate titles
    const titles = _.pluck(this.chartOptions.graphs, 'title');

    return this.$translate(titles).then((translations) => {
      _.each(this.chartOptions.graphs, (graph: any) => {
        graph.title = translations[graph.title];
      });

      // create a new object in order slicedChart directive will recognize the changes
      this.chartOptions = Object.assign({}, this.chartOptions);

      return data;
    });
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    chartOptions: '<',
  },
};
