import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './crm-smtp-configuration.component.html';
const styles = require('./crm-smtp-configuration.component.scss');
import { Permissions } from '~/source/common/models/permission-structure';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { SystemSmtpConfigurationStoreService } from '~/source/common/store-services/system-smtp-configuration-store.service';
import { SystemSmtpConfiguration } from '@proftit/crm.api.models.entities';
import { createSmtpConfigurationValidation } from './create-smtp-configuration-validation';

export class CrmSmtpConfigurationController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  editPermissions = [Permissions.ManagementSystemConfiguration.Update];

  cancelEditOp$ = new rx.Subject<void>();
  activateEditOp$ = new rx.Subject<void>();
  saveOp$ = new rx.Subject<void>();
  changeOp$ = new rx.Subject<void>();
  streamSave$ = this.streamSave();
  isInEdit$ = this.streamIsInEdit();

  validation$ = this.streamIsValidSmtpConfiguration();
  formSchema = createSmtpConfigurationValidation();
  mainForm: SystemSmtpConfiguration;

  /* @ngInject */
  constructor(
    readonly prfSystemSmtpConfigurationStoreService: SystemSmtpConfigurationStoreService,
  ) {}

  $onInit() {
    useStreams(
      [this.streamFormMutable(), this.streamSave$],
      this.lifecycles.onDestroy$,
    );
    this.prfSystemSmtpConfigurationStoreService.load();
  }

  $onDestroy() {}

  $onChanges() {}

  streamIsInEdit() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.cancelEditOp$.pipe(rx.map(() => false)),
          this.activateEditOp$.pipe(rx.map(() => true)),
          this.streamSave$.pipe(rx.map(() => false)),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamFormMutable() {
    return rx.pipe(() =>
      rx.obs.merge(
        this.streamFormMutableFromModel(),
        this.streamFormMutableFromCancelEdit(),
      ),
    )(null);
  }

  streamFormMutableFromCancelEdit() {
    return rx.pipe(
      () => this.activateEditOp$,
      rx.map(() => _.cloneDeep(this.mainForm)),
      rx.switchMap((prevValue) => {
        return this.cancelEditOp$.pipe(rx.map(() => prevValue));
      }),
      rx.tap((formValue) => (this.mainForm = formValue)),
      shareReplayRefOne(),
    )(null);
  }

  streamFormMutableFromModel() {
    return rx.pipe(
      () => this.prfSystemSmtpConfigurationStoreService.smtpConfiguration$,
      rx.tap((model) => {
        this.mainForm = _.isEmpty(model)
          ? {
              id: null,
              host: '',
              port: null,
              username: '',
              password: '',
            }
          : model;
      }),
    )(null);
  }

  streamSave() {
    return rx.pipe(
      () => this.saveOp$,
      rx.switchMap(() => {
        return this.formSchema.isValid(this.mainForm);
      }),
      rx.filter((isValidSmtpConfiguration) => isValidSmtpConfiguration),
      rx.switchMap(() => {
        return this.prfSystemSmtpConfigurationStoreService.save(this.mainForm);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsValidSmtpConfiguration() {
    return rx.pipe(
      () => rx.obs.merge(this.changeOp$.pipe(rx.debounceTime(300))),
      rx.switchMap(() =>
        this.formSchema
          .validate(this.mainForm, { abortEarly: false })
          .then(() => ({ isValid: true, errors: null }))
          .catch((err) => {
            return {
              isValid: false,
              errors: err.errors,
            };
          }),
      ),
      rx.startWith({
        isValid: false,
        errors: null,
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const CrmSmtpConfigurationComponent = {
  template,
  controller: CrmSmtpConfigurationController,
};
