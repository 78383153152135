import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { MarginCallInPercentOrMoneyCode } from '@proftit/tradingcore.api.models.entities';
import template from './brand-platform-mt4-group-form.component.html';
const styles = require('./brand-platform-mt4-group-form.component.scss');

export class BrandPlatformMt4GroupFormController {
  styles = styles;

  MarginCallInPercentOrMoneyCode = MarginCallInPercentOrMoneyCode;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandPlatformMt4GroupFormComponent = {
  template,
  controller: BrandPlatformMt4GroupFormController,
  bindings: {
    form: '<',
  },
};
