import template from './udarry-frame.component.html';
const styles = require('./udarry-frame.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class UdarryFrameController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const UdarryFrameComponent = {
  template,
  controller: UdarryFrameController,
  transclude: true,
  bindings: {
    header: '<',
    topActionIcon: '<',
    showTopActionLink: '<',
    topActionLink: '<',
    bottomActionIcon: '<',
    bottomActionLabel: '<',
    showActionLink: '<',
    showTopAction: '<',
    showFooter: '<',
    stretch: '<',
    onBottomActionClick: '&',
    onTopActionClick: '&',
  },
};
