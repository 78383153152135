import template from './positions-table-forex.html';
import positionsSettings from '../positions-forex-settings.json';
import PositionsTableBase from '../../../common/positions/positions-table-base';
import PositionsForexSocketService from '../positions-forex-socket.service';
import CustomersService from '~/source/contact/common/services/customers';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { calculateForexPositionTableCols } from '../calculate-forex-position-table-cols';
import { DisplayContext } from '../display-contenxt';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const styles = require('./positions-table.scss');

class Controller extends PositionsTableBase {
  styles = styles;

  static $inject = [
    'positionsForexSocketService',
    ...PositionsTableBase.$inject,
  ];

  /* */

  positionsForexSocketService: PositionsForexSocketService;
  dataServiceInstance: CustomersService;
  customer: IElementRestNg<Customer>;
  account: IElementRestNg<TradingAccount>;
  positions: IElementRestNg<any>;
  calculateCols = _.memoize(calculateForexPositionTableCols);
  isPortfolioPage: boolean;

  get cols() {
    if (this.isPortfolioPage) {
      return this.calculateCols(
        positionsSettings.tableColumns,
        this.account.platform,
        DisplayContext.PortfolioMain,
      );
    }
    return this.calculateCols(
      positionsSettings.tableColumns,
      this.account.platform,
      DisplayContext.ContactMain,
    );
  }

  get ngTableSettings() {
    return {
      ...positionsSettings.table.ngTable,
    };
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Object} Socket service instance
   */
  get socketService() {
    return this.positionsForexSocketService;
  }

  /**
   * Build a request to fetch forex positions.
   * Called by parent's "getData" ngTable method
   * @returns {RestService} restService instance, for chaining
   */
  getDataRequest() {
    return this.dataServiceInstance
      .getPositionsResource(this.customer.id, 'forex', this.account.id)
      .setConfig({ blockUiRef: 'positionsTable' })
      .expand([
        'currency',
        'positionStatus',
        'tradeAsset',
        'tradingAccount.currency',
        'tradingAccount.platform',
        'tradingAccount.customer',
        'tradingAccount.customer.brand',
      ])
      .sort({ entryDate: 'desc' });
  }

  parseLoadedData(positions) {
    super.parseLoadedData(positions);
    this.positions = positions;
    return positions;
  }

  get requiredApiFilters() {
    return this.isPortfolioPage ? { positionStatusCode: 'forex_open' } : {};
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
    isPortfolioPage: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};
