import * as _ from '@proftit/lodash';
import template from './brand-ewallet-list.html';
import BaseController from '~/source/common/controllers/base';
import BrandEwalletService from './../../services/brand-ewallet.service';
import * as rx from '@proftit/rxjs';
import useStream from '~/source/common/utilities/use-stream';

import { BrandEwallet } from '~/source/common/models/brand-ewallet';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class Controller extends BaseController {
  opGetList$ = new rx.Subject<any>();
  unsub$ = new rx.Subject<void>();
  brandEwalletsGrouped$ = new rx.BehaviorSubject<any>([]);

  /*@ngInject */
  constructor(readonly brandEwalletService: BrandEwalletService) {
    super();
  }

  /**
   * Lifecycle method - onInit
   *
   * @return {void}
   */
  $onInit() {
    useStream(this.streamEwalletList(), this.unsub$);
    this.opGetList$.next(null);
  }

  /**
   * Lifecycle method - onDestroy
   *
   * @return {void}
   */
  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * set ewallet list stream
   *
   * @return {Observable} observable of the list.
   */
  streamEwalletList() {
    return rx.pipe(
      () => this.opGetList$,
      rx.mergeMap(() => {
        return rx.obs.from(
          this.brandEwalletService
            .expand(['brand', 'ewallet'])
            .getListWithQuery<IElementRestNg<BrandEwallet>>(),
        );
      }),
      rx.tap((list) =>
        this.brandEwalletsGrouped$.next(this.groupByBrand(list)),
      ),
    )(null);
  }

  /**
   * transforms a collection into groups by brand name
   * each group has a key by brand name
   *
   * @param {collection} data
   * @returns {*}
   */
  groupByBrand(data) {
    return _.groupBy<BrandEwallet>((item) => item.brand.name, data);
  }

  deleteBrandEwallet(brandEwalletID) {
    this.brandEwalletService
      .setConfig({ blockUiRef: 'blockUI_brandEwalletList' })
      .deleteBrandEwallet(brandEwalletID)
      .then(() => {
        this.opGetList$.next(null);
      });
  }
}

export const BrandEwalletListComponent = {
  template,
  controller: Controller,
};

export default BrandEwalletListComponent;
