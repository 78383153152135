import template from './notice-popup-list.component.html';

const styles = require('./notice-popup-list.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { ContactsImport } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { ContactsImportStoreService } from '~/source/common/store-services/contacts-import-store.service';
import { NoticeType } from '~/source/common/components/notice-popup-list/notice-type';
import { NoticePopupService } from '~/source/common/services/notice-popup-service';

interface NoticeInfo {
  id: number;
  item: any;
  type: NoticeType;
}

function convertContactImportToNotice(
  contactsImport: ContactsImport,
): NoticeInfo {
  return {
    id: contactsImport.id,
    item: contactsImport,
    type: NoticeType.ContactsImport,
  };
}

export class NoticePopupListController {
  close: () => void;

  draggable: any;

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  dismissNoticeAction$ = new rx.Subject<number>();

  closePopupAction = new rx.Subject<void>();

  noticeList$ = this.streamNoticeList();

  draggableSettings$ = this.streamDraggableSettings();

  isShowCloseButton$ = this.streamIsShowCloseButton();

  /*@ngInject */
  constructor(
    readonly prfContactsImportStoreService: ContactsImportStoreService,
    readonly prfNoticePopupService: NoticePopupService,
  ) {
    useStreams(
      [this.draggableSettings$, this.streamClosePopup()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamNoticesHashFromActiveImports(
    noticesHash$: rx.Observable<Record<number, NoticeInfo>>,
  ) {
    return rx.pipe(
      () => this.prfContactsImportStoreService.activeContactsImports$,
      rx.map((processes) =>
        _.mapValues((p) => convertContactImportToNotice(p), processes),
      ),
      rx.withLatestFrom(noticesHash$),
      rx.map(([activeImports, noticesHash]) => {
        return {
          ...noticesHash,
          ...activeImports,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNoticesHashFromUserDismiss(
    noticesHash$: rx.Observable<Record<number, NoticeInfo>>,
  ) {
    return rx.pipe(
      () => this.dismissNoticeAction$,
      rx.withLatestFrom(noticesHash$),
      rx.map(([dismissNoticeId, noticesHash]) =>
        _.unset([dismissNoticeId], noticesHash),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamNoticesHash(): rx.Observable<Record<string, NoticeInfo>> {
    const noticesHash$ = new rx.BehaviorSubject<Record<number, NoticeInfo>>({});

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamNoticesHashFromActiveImports(noticesHash$),
          this.streamNoticesHashFromUserDismiss(noticesHash$),
        ),
      rx.tap((noticesHash) => noticesHash$.next(noticesHash)),
      shareReplayRefOne(),
    )(null);
  }

  streamNoticeList(): rx.Observable<NoticeInfo[]> {
    return rx.pipe(
      () => this.streamNoticesHash(),
      rx.map((noticesHash) => Object.values(noticesHash)),
      shareReplayRefOne(),
    )(null);
  }

  streamDraggableSettings() {
    return rx.pipe(
      () => this.noticeList$,
      rx.tap((list) => {
        if (_.isNil(this.draggable)) {
          return;
        }
        this.draggable.setIsOffsetType(true);
      }),
    )(null);
  }

  streamIsShowCloseButton() {
    return rx.pipe(
      () => this.prfContactsImportStoreService.hasNoActiveContactsImports$,
      shareReplayRefOne(),
    )(null);
  }

  streamClosePopup() {
    return rx.pipe(
      () => this.closePopupAction,
      rx.tap(() => this.prfNoticePopupService.closePopup()),
    )(null);
  }
}

export const NoticePopupListComponent = {
  template,
  controller: NoticePopupListController,
  controllerAs: 'vm',
  require: {
    draggable: '^prfDraggble',
  },
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
