import template from './withdrawal-tooltip-data.component.html';
const styles = require('./withdrawal-tooltip-data.component.scss');

class WithdrawalTooltipDataController {
  styles = styles;
}

export const WithdrawalTooltipDataComponent = {
  template,
  controller: WithdrawalTooltipDataController,
  bindings: {
    withdrawals: '<',
  },
};
