import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import UsersService from '~/source/management/user/services/users';
import { Brand, Customer, Desk, User } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import template from './user-assign-dropdown.html';

const styles = require('./user-assign-dropdown.scss');

class AssignUserController extends BaseController {
  styles = styles;

  localSelectedDesk;
  selectedDesk;
  disableUnassign;
  brandsService: () => BrandsService;
  usersService: () => UsersService;
  brandsServiceInstance: BrandsService;
  usersServiceInstance: UsersService;
  customer: Customer;
  user: User;
  brand: Brand;
  desks: ICollectionRestNg<Desk>;
  showLeftActionButton: boolean;
  onLeftActionButtonClick: Function;
  leftActionButtonIconClass: string;
  deskUsers: ICollectionRestNg<User>;
  onAssign: Function;
  isOpen: boolean;
  selectedOwnerDesk: Desk;

  lifecycles = observeComponentLifecycles(this);

  inputBrand$ = observeShareCompChange<Brand>(
    this.lifecycles.onChanges$,
    'brand',
  );

  inputCustomer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  selectedBrand$ = this.streamSelectedBrand();

  constructor(...args) {
    super(...args);

    useStreams(
      [this.inputBrand$, this.inputCustomer$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.brandsServiceInstance = this.brandsService();
    this.usersServiceInstance = this.usersService();
    this.disableUnassign = this.disableUnassign || false;
    this.showLeftActionButton = _.defaultTo(false, this.showLeftActionButton);
  }

  $onDestroy() {}

  streamSelectedBrand() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.inputBrand$, this.inputCustomer$),
      rx.map(([inputBrand, inputCustomer]) => {
        return _.isNil(inputCustomer) ? inputBrand : inputCustomer.brand;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * dispatched when popup is toggled
   *
   * @param {boolean} isOpen - Is open requested
   * @return {void}
   */
  togglePopup(isOpen: boolean, selectedBrand: Brand) {
    if (!isOpen) {
      return;
    }

    this.fetchBrandDesks(selectedBrand.id).then((desks) => {
      if (desks.length === 0) {
        return;
      }
      // desk is provided in bindings, select desk
      if (this.selectedDesk) {
        return this.selectDesk(selectedBrand, this.selectedDesk);
      }

      if (this.customer && this.customer.desk) {
        return this.selectDesk(selectedBrand, this.customer.desk);
      }

      // select default desk
      return this.selectDesk(selectedBrand, desks[0]);
    });
  }

  /**
   * fetch brand desks
   *
   * @param {number} brandId - Brand id
   * @return {Promise} Resource request promise
   *
   * return {Promise} - A promise which resolves to an array
   */
  fetchBrandDesks(brandId) {
    return this.brandsServiceInstance
      .setConfig({ blockUiRef: 'assignToPopup' })
      .getDesksResource(brandId)
      .getListWithQuery()
      .then((data) => {
        this.desks = data;

        return data;
      });
  }

  /**
   * fetch desk users by desk id
   *
   * @param {number} deskId
   * @return {Promise} - A promise which resolves to an array
   */
  fetchDeskUsers(brandId: number, deskId: number) {
    const filters = {
      deskId,
      brandId,
      isActive: true,
      'role.code': {
        exclude: 'extapi',
      },
    };

    return this.usersServiceInstance
      .setConfig({ blockUiRef: 'fetchUsers' })
      .expand('role')
      .filter(filters)
      .getListWithQuery<IElementRestNg<User>>()
      .then((data) => {
        // combine a full name for angular filter search
        data.forEach((user) => {
          user.fullName = `${user.firstName} ${user.lastName}`;
        });

        this.deskUsers = data;

        return data;
      });
  }

  /**
   * select a desk
   *
   * @param {object} desk desk {id, name}
   */
  selectDesk(brand: Brand, desk: Desk) {
    this.fetchDeskUsers(brand.id, desk.id);
    this.localSelectedDesk = desk;
  }

  /**
   * Called by the dropdown when a user is clicked.
   * Executes the "onAssign" callback with the changed params (user, desk) and then closes the dropdown.
   * @param user - selected user
   * @param desk - selected desk
   */
  assignUser(user: User, desk: Desk) {
    this.onAssign({ user, desk }).then(() => {
      this.isOpen = false;
    });
  }
}

AssignUserController.$inject = ['usersService', 'brandsService'];

export default {
  template,
  bindings: {
    brand: '<',
    customer: '<',
    user: '<',
    selectedDesk: '<',
    disableUnassign: '<',
    showLeftActionButton: '<',
    leftActionButtonIconClass: '<',
    onAssign: '&',
    onLeftActionButtonClick: '&',
  },
  controllerAs: 'vm',
  controller: AssignUserController,
};
