import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Brand, SystemEmailTemplate } from '@proftit/crm.api.models.entities';
import { SystemEmailTypeCode } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { SystemEmailTypesStoreService } from '~/source/common/store-services/system-email-types-store.service';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import template from './system-emails-trigger-chooser.component.html';

const styles = require('./system-emails-trigger-chooser.component.scss');

const TRIGGER_CHANGES_SUCCESS_MSG_TIMEOUT = 7000;

export class SystemEmailsTriggerChooserController {
  onTemplateSelect: (a: { id: number }) => void;

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  selectFirstIfNoneSelected$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'selectFirstIfNoneSelected',
  );

  setIsActiveAction = new rx.Subject<SystemEmailTemplate>();

  brandSystemEmailTemplatesFromLoad$ = this.streamBrandSystemEmailTemplatesFromLoad();

  brandSystemEmailTemplates$ = this.streamBrandSystemEmailTemplates();

  isDisabledForActivation$ = this.streamIsDisabledForActivation();

  /* @ngInject */
  constructor(
    readonly prfSystemEmailTemplatesService: () => SystemEmailTemplatesService,
    readonly growl: ng.growl.IGrowlService,
  ) {
    useStreams(
      [this.brand$, this.streamSelectFirstOnLoad()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBrandSystemEmailTemplatesFromLoad() {
    return rx.pipe(
      () => this.brand$,
      rx.switchMap((brand) =>
        this.prfSystemEmailTemplatesService().getAllForBrand(brand.id),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectFirstOnLoad() {
    return rx.pipe(
      () => this.brandSystemEmailTemplatesFromLoad$,
      rx.withLatestFrom(this.selectFirstIfNoneSelected$),
      rx.filter(([a, selectFirst]) => selectFirst),
      rx.tap(([templates]) => this.onTemplateSelect({ id: templates[0].id })),
    )(null);
  }

  streamBrandSystemEmailTemplatesFromToggleIsActive(
    templates$: rx.Observable<SystemEmailTemplate[]>,
  ) {
    return rx.pipe(
      () => this.setIsActiveAction,
      rx.switchMap((template) =>
        this.prfSystemEmailTemplatesService().setIsActive(
          template.id,
          !template.isActive,
        ),
      ),
      rx.tap(() =>
        this.growl.success(
          'systemEmailsTriggerChooser.TRIGGER_CHANGED_SUCCESS',
          {
            referenceId: 'globalMessages' as any,
            ttl: TRIGGER_CHANGES_SUCCESS_MSG_TIMEOUT,
          },
        ),
      ),
      rx.withLatestFrom(templates$),
      rx.map(([result, templates]) => {
        return templates.reduce((acc, item) => {
          if (item.id !== result.id) {
            acc.push(item);
            return acc;
          }

          acc.push(result);
          return acc;
        }, []);
      }),
    )(null);
  }

  streamBrandSystemEmailTemplates() {
    const templates$ = new rx.BehaviorSubject<SystemEmailTemplate[]>([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.brandSystemEmailTemplatesFromLoad$,
          this.streamBrandSystemEmailTemplatesFromToggleIsActive(templates$),
        ),
      rx.tap((templates) => templates$.next(templates)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsDisabledForActivation() {
    return rx.pipe(
      () => this.brandSystemEmailTemplates$,
      rx.map((systemTemplates) => {
        return _.flow([
          () => _.keyBy((s) => s.id, systemTemplates),
          (hash) =>
            _.mapValues(
              (s) => s.type.code === SystemEmailTypeCode.PasswordReset,
              hash,
            ),
        ])();
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const SystemEmailsTriggerChooserComponent = {
  template,
  controller: SystemEmailsTriggerChooserController,
  bindings: {
    brand: '<',
    selectedTemplateId: '<',
    selectFirstIfNoneSelected: '<',
    onTemplateSelect: '&',
  },
};
