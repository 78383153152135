import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import { ContactsImport } from '@proftit/crm.api.models.entities';
import { IRestConfig } from '~/source/common/services/irest-config';
import { IElementRestNg } from '../../models/ielement-rest-ng';

export class ContactsImportsService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'contactsImports';
  }

  /**
   *  Return all items list
   *
   * @returns items list.
   */
  resourceFullList(params: { config: IRestConfig; expand; embed }) {
    return this.setConfig(params.config)
      .expand(params.expand)
      .embed(params.embed);
  }

  /**
   *  Return one Item per id
   *
   * @returns one item.
   */
  getItem(id) {
    return this.expand(['resource']).getOneWithQuery<
      IElementRestNg<ContactsImport>
    >(id);
  }
}
