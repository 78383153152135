import { useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';
import { ItemLabelStrategy } from '~/source/common/components/dropdowns/base/item-label-strategy';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';
import { ContentTemplate } from '@proftit/crm.api.models.entities';

export class SystemEmailTemplateContentTemplateAsLanguageController extends DropdownController<
  SystemEmailTemplatesService
> {
  static $inject = [
    'prfSystemEmailTemplatesService',
    ...DropdownController.$inject,
  ];

  unsub$ = new rx.Subject<void>();

  systemEmailTemplateId$ = new rx.BehaviorSubject<number>(null);

  constructor(a, ...args) {
    super(a, ...args);

    useStreams(
      [this.streamReloadDataOnSystemEmailTemplateChange()],
      this.unsub$,
    );
  }

  streamReloadDataOnSystemEmailTemplateChange() {
    return rx.pipe(
      () => this.systemEmailTemplateId$,
      rx.tap((id) => {
        if (_.isNil(id)) {
          this.output = [];
          return;
        }

        this.fetchData();
      }),
    )(null);
  }

  onSystemEmailTemplateIdChange(systemEmailTemplateId: number) {
    this.systemEmailTemplateId$.next(systemEmailTemplateId);
  }

  /**
   * Disable fetch state on start and move it to on input.
   */
  init() {
    this._data = [];
  }

  /**
   * Preform request
   */
  query() {
    const id = this.systemEmailTemplateId$.getValue();

    return this.dataServiceInst
      .getOneWithAllContentTemplates(id)
      .then((systemEmailTemplate) => systemEmailTemplate.contentTemplates);
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item: ContentTemplate) {
    return item.language.name;
  }
}

export const SystemEmailTemplateContentTemplateAsLanguageComponent = DropdownConfig(
  {
    controller: SystemEmailTemplateContentTemplateAsLanguageController,
    bindings: {
      systemEmailTemplateId: '<',
    },
  },
);
