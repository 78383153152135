import Promise from 'bluebird';
import Component from './base/component';
import moment from 'moment';

class TimeZoneController extends Component.controller {
  static $inject = ['tzOffsets', ...Component.controller.$inject];

  tzOffsets: number[];

  query() {
    const timezones = [];

    this.tzOffsets.forEach((offset) => {
      const tzStr = moment().utc().utcOffset(offset).format('Z');

      timezones.push({
        id: offset,
        name: `GMT ${tzStr}`,
      });
    });

    return Promise.resolve(timezones);
  }

  get defaultPlaceholder() {
    return 'common.SELECT_TIME_ZONE';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({ controller: TimeZoneController });
