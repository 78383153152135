import TablePopupController from '~/source/common/components/table/table-popup';
import riskFreeSettings from './risk-free-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
class RiskFreeTablePopupController extends TablePopupController {
  static $inject = ['customersService', ...TablePopupController.$inject];

  dataServiceInstance: CustomersService;

  account: TradingAccount;
  customer: Customer;

  get cols() {
    return [...riskFreeSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...riskFreeSettings.tablePopup.ngTable };
  }

  get title() {
    return 'contact.tradingAccount.RISK_FREE';
  }

  get tableKey() {
    return 'riskFree';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getRiskFreePositionsResource(this.customer.id, this.account.id)
      .expand(['user']);
  }
}

export default RiskFreeTablePopupController;
