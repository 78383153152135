import { BonusAdditional } from './../bonus-additional.class';
import { calcMenuItemsForBonus } from './../calc-menu-items-for-bonus/calc-menu-items-for-bonus';
import { TransactionTransferBonus } from '@proftit/crm.api.models.entities';
/**
 *  Generate bonus additionals
 *  @param bonuses
 */
export function generateBonusAdditionals(bonuses: TransactionTransferBonus[]) {
  return bonuses.reduce((acc, bonus) => {
    const info = new BonusAdditional();
    info.menuItems = calcMenuItemsForBonus(bonus);

    return {
      ...acc,
      [bonus.id]: info,
    };
  }, {});
}
