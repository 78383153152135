import * as _ from '@proftit/lodash';
import ng from 'angular';

import BaseService from '~/source/common/services/baseService';

export default class BaseFormFormatter extends BaseService {
  model;

  /**
   *
   *
   * @param {Object} model
   * @returns {Object|*|{pos}|{pos, refreshFromBuffer, buffer}}
   */
  format(model) {
    const formattedData = {};
    this.model = ng.copy(model);

    this.model = this.preFormat(this.model);

    _.eachEs(this.map, (formatters, fieldName) => {
      /*
       * if property does not exist in the model -
       * continue the loop
       */
      if (this.model[fieldName] === undefined) {
        return;
      }

      formattedData[fieldName] = this.compose(formatters, fieldName);
    });

    return this.postFormat(_.extendEs(this.model, formattedData));
  }

  /**
   * Should be overridden on derived class
   * Returns map of the formatters for model
   * properties
   *
   * @returns {Object}
   */
  get map() {
    return {};
  }

  /**
   * Map keys of formatted data
   * Should be overridden in child class
   *
   * @returns {Object}
   */
  get keysMap() {
    return {};
  }

  /**
   * Called before preforming data format
   *
   * Should be overridden on child class
   *
   * @param {Object} formattedData
   * @return {Object} formattedData
   */
  preFormat(formattedData) {
    return formattedData;
  }

  /**
   * Called post preforming data format
   *
   * @param {Object} formattedData
   * @return {Object} formattedData
   */
  postFormat(formattedData) {
    if (_.isEmpty(this.keysMap)) {
      return formattedData;
    }

    _.eachEs(this.keysMap, (newKey: string, oldKey) => {
      if (formattedData[oldKey] === undefined) {
        // continue
        return;
      }

      formattedData[newKey] = formattedData[oldKey];

      delete formattedData[oldKey];
    });

    return formattedData;
  }

  /**
   *
   * @param {Object} formatters
   * @param {String} filedName
   */
  compose(formatters, filedName) {
    const methods = formatters.map((obj) =>
      _.bindEs(this[obj.method], this, ...obj.params),
    );

    return (<any>_.flowRightEs)(...methods)(this.model[filedName]);
  }

  /**
   * Name of property to fetch from object
   *
   * @param {String} propertyToExtract
   * @param {String} value
   */
  extract(propertyToExtract, value) {
    return value[propertyToExtract];
  }
}
