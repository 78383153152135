import RestService from '~/source/common/services/rest';

export default class GeographicStatsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'marketingStats/geographics';
  }
}
