import moment from 'moment';
import loglevel from 'loglevel';
import ng, { IModule } from 'angular';
import Inputmask from 'inputmask';
import appConfig from './appConfig';
import validationConf from './validation-conf';
import { validationMessagesConf } from './validation-messages-conf';
import idleConf from './idle-conf';
import timeConf from './time-conf';
import httpConf from './http-conf';
import { yupValidation } from './yup-validation';
import modalConf from '../common/components/modal/conf';
import dateRangepicker from '../common/decorators/daterangepicker-decorator';
import localStorageDecorator from '../common/decorators/local-storage-decorator';
import angularResizableDecorator from '../common/decorators/angular-resizable-decorator';
import cacheFactorySettings from './cache-factory-settings';
// @ts-ignore
import langEn from '../../lang/en.json';
import blockUiOverlayTemplate from './../common/templates/block-ui/block-ui-overlay.html';
import exceptionHandlerFactory from './exceptionHandler.factory';
import FeatureFlags from './features-flags';

function mainConf(
  blockUIConfig,
  $translateProvider,
  localStorageServiceProvider,
  $compileProvider,
  $locationProvider,
  angularMomentConfig,
  appConfig,
) {
  // disabling debug data to get better performance gain in production
  $compileProvider.debugInfoEnabled(appConfig.debugInfoEnabled);

  /*
   * Migrating from angular 1.5 to 1.6.
   * remove this if we will be able to convert all component constructors to $onInit
   */
  // $compileProvider.preAssignBindingsEnabled(true);

  // Remove the default bang ("!") prefix from the url hash
  $locationProvider.hashPrefix('');

  // --------block ui config-------------------------------------
  blockUIConfig.message = 'Loading ...';
  blockUIConfig.autoBlock = false;
  blockUIConfig.autoInjectBodyBlock = false;
  blockUIConfig.cssClass = 'block-ui bl-block-ui-container';
  blockUIConfig.blockBrowserNavigation = true;
  blockUIConfig.delay = 50;
  blockUIConfig.template = blockUiOverlayTemplate;

  // ----------Translate config-----------------------------------

  if (process.env.NODE_ENV === 'test') {
    $translateProvider.translations('en', langEn);
  } else {
    $translateProvider.useLoader('prfTranslateCustomLoader');
  }

  $translateProvider.preferredLanguage(appConfig.defaultLang);
  $translateProvider.useSanitizeValueStrategy('escaped');

  // bug in translate refresh. delete other langs tables.
  $translateProvider.forceAsyncReload(true);

  // ----------Storage config-----------------------------------
  localStorageServiceProvider.setPrefix('prf').setStorageType('localStorage');

  /**
   * Angular moment preprocess function.
   *
   * The default pre-process function wraps the value in a "moment" - even if it already is a "moment.
   * This causes some bugs - such as losing the timezone offset.
   * @param {*} time - Can be unix time, moment object, or anything the moment constructor can handle
   * @return {moment} - Moment object created from input
   */
  angularMomentConfig.preprocess = function preProcess(time) {
    if (!Number.isNaN(parseFloat(time)) && Number.isFinite(time)) {
      return moment(parseInt(time, 10));
    }

    if (moment.isMoment(time)) {
      return time;
    }

    return moment(time);
  };

  loglevel.setDefaultLevel(appConfig.defaultLogLevel as any);
}

mainConf.$inject = [
  'blockUIConfig',
  '$translateProvider',
  'localStorageServiceProvider',
  '$compileProvider',
  '$locationProvider',
  'angularMomentConfig',
  'appConfig',
];

export function configure(crm: IModule) {
  crm.factory('$exceptionHandler', exceptionHandlerFactory);
  crm.value('featuresFlagsData', FeatureFlags);
  crm.config(mainConf);
  crm.config(validationConf);
  crm.config(validationMessagesConf);
  crm.config(idleConf);
  crm.config(httpConf);
  crm.config(dateRangepicker);
  crm.config(localStorageDecorator);
  crm.config(angularResizableDecorator);
  crm.config(cacheFactorySettings);
  crm.config(modalConf);
  crm.config(timeConf);
  crm.config(yupValidation);
  Inputmask.extendDefinitions({
    '*': { validator: '[A-Za-z0-9 ]' },
    a: { validator: '[A-Za-z ]' },
  });
}
