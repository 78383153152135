import template from './import-file-form.component.html';

const styles = require('./import-file-form.component.scss');

import { IScope } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import log from 'loglevel';
import { CustomerComplianceFileType } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { isNotNilFormValidator } from '@proftit/ng1.reactive-forms.validators';
import ModalService from '~/source/common/components/modal/modal.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ContactsImportStoreService } from '~/source/common/store-services/contacts-import-store.service';
import { observeUploadFile } from '~/source/common/utilities/observe-upload-file';

export class ImportFileFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  form = new FormGroup({
    selectedBrand: new FormControl(null, {
      validators: [isNotNilFormValidator],
    }),
    file: new FormControl(null, {
      validators: [isNotNilFormValidator],
    }),
  } as any);

  complianceDocType: CustomerComplianceFileType;

  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  blockUiInstance;
  fileUploadProgress$ = new rx.BehaviorSubject<number>(0);

  uploadAction = new rx.Subject();
  showConfirmAction = new rx.Subject();

  /* @ngInject */
  constructor(
    readonly $scope: IScope,
    readonly brandsService: () => BrandsService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly modalService: ModalService,
    readonly blockUI: any,
    readonly growl,
    readonly prfContactsImportStoreService: ContactsImportStoreService,
  ) {
    useStreams(
      [
        this.form.value$,
        this.streamUploadAction(),
        this.streamShowConfirmDialog(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
  }

  $onDestroy() {}

  $onChanges() {}

  streamUploadAction() {
    return rx.pipe(
      () => this.uploadAction,
      rx.withLatestFrom(this.form.value$),
      rx.tap(() => {
        this.blockUiInstance.start();
      }),
      rx.switchMap(([a, formData]) => {
        return observeUploadFile(() => {
          return this.brandsService().contactsImportsUploadFile(
            (formData as any).selectedBrand.id,
            (formData as any).file,
          );
        });
      }),
      rx.tap(({ percentage }) => {
        this.fileUploadProgress$.next(percentage);
      }),
      rx.filter(({ percentage }) => percentage === 100),
      rx.tap(() => {
        this.blockUiInstance.stop();
      }),
      rx.catchError((err, caught) => {
        log.error(err);
        this.blockUiInstance.stop();
        this.growl.error('server_errors.SERVER_GENERAL', {
          referenceId: this.growlId,
        });
        return caught;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamShowConfirmDialog() {
    return rx.pipe(
      () => this.showConfirmAction,
      rx.switchMap(() => {
        const modalInstance = this.modalService.open({
          component: 'prfContactsConfirmDialog',
        });
        return (modalInstance.result as any) as Promise<any>;
      }),
      rx.tap((a) => {
        this.uploadAction.next(null);
      }),
      rx.catchError((err, caught) => {
        return caught;
      }),
    )(null);
  }
}

export const ImportFileFormComponent = {
  template,
  controller: ImportFileFormController,
};
