import * as _ from '@proftit/lodash';
import log from 'loglevel';
import 'angular';
import BaseService from '~/source/common/services/baseService';

class FilterKeyDuplicationResolverService extends BaseService {
  models;

  /*@ngInject */
  constructor(readonly dateFormat) {
    super();
  }

  /**
   * resolve filter duplication error caused by adding filters with the same property key to filter models object.
   *
   * there are two possible solutions for this issue:
   *
   * 1. if the values in both filters are equal, keep only the quick filter in models
   * @example
   *
   * this.models before transformation:
   * ---------------------------------
   * "isActiveStatus": {
   *   "state": {
   *     "type": "quick",
   *     "apiProperty": "isActive",
   *     "isActive": false,
   *     "valueField": "id"
   *   },
   *   "value": {"id": 1}
   * },
   * "isActive": {
   *   "state": {
   *     "type": "select",
   *     "isActive": true,
   *     "valueField": "id"
   *   },
   *   "value": {"id": 1}
   * }
   *
   * this.models after transformation:
   * ---------------------------------
   * "isActiveStatus": {
   *   "state": {
   *     "type": "quick",
   *     "apiProperty": "isActive",
   *     "isActive": true,
   *     "valueField": "id"
   *   },
   *   "value": {"id": 1}
   * }
   *
   * 2. if the values are not equal, keep both filters in models. but disable the quick filter.
   * @example
   *
   * this.models before transformation:
   * ----------------------------------
   * models = {
   * "isActiveStatus": {
   *   "state": {
   *     "type": "quick",
   *     "apiProperty": "isActive",
   *     "isActive": false,
   *     "valueField": "id"
   *   },
   *   "value": {"id": 1}
   * },
   * "isActive": {
   *   "state": {
   *     "type": "select",
   *     "isActive": true,
   *     "valueField": "id"
   *   },
   *   "value": {"id": 1}
   * }
   *
   * this.models after transformation:
   * ---------------------------------
   * models {
   * "isActiveStatus": {
   *   "state": {
   *     "type": "quick",
   *     "apiProperty": "isActive",
   *     "isActive": true,
   *     "valueField": "id",
   *     "isDisabled": true
   *   },
   *   "value": {"id": 1}
   * }
   * "isActive": {
   *   "state": {
   *     "type": "select",
   *     "isActive": true,
   *     "valueField": "id"
   *   },
   *   "value": {"id": 1}
   * }
   *
   * this script support quick filters with types select, monetaryRange & dateRange.
   * any other filter types should be added depends on design requirements.
   *
   * @param {Object} filterModels table filter models object (e.g. depositTotal: {state, value})
   */
  resolve(filterModels) {
    this.models = filterModels;

    _.eachEs(this.models, (filter) => {
      // this filter is not a quick filter, pass over this loop
      if (!this.isQuickFilter(filter)) {
        return;
      }

      /*
       * there are no duplicated filters with the same api property in models
       * pass over this loop
       */
      if (!this.areMultipleFiltersExists(filter)) {
        // still enable this filter just in-case with was disabled in prev state of the models
        this.enableFilter(filter);
        return;
      }

      let areValuesEquals;
      const apiProperty = filter.state.apiProperty;

      /*
       * if we got to this step it means:
       * 1. this filter is a quick filter
       * 2. there is other filter with the same api property key, a duplicated filter
       * 3. we need to handle this situation because we can't send to the server
       *    duplicated values (e.g. userId=1&userId=2)
       */
      switch (this.getFilterType(filter)) {
        case 'select':
          areValuesEquals = this.handleSelectFilter(filter);
          break;
        case 'dateRange':
          areValuesEquals = this.handleDateRangeFilter(filter);
          break;
        case 'numberRange':
        case 'monetaryRange':
          areValuesEquals = this.handleRangeFilter(filter);
          break;
      }

      // quick filter & duplicated filter values equals, activate quick filter & remove the duplicated filter.
      if (areValuesEquals) {
        // filter values equals. keep only quick filter activated & enabled.
        this.activateFilter(filter);
        this.enableFilter(filter);
        this.removeFilterFromModels(apiProperty);
      } else {
        // filter values not equals. keep both filters in models, disable & in-activate quick filter
        this.activateFilter(filter, false);
        this.enableFilter(filter, false);
      }
    });
  }

  /**
   * is this filter a quick filter or not (regular filter)
   * @param {Object} filter filter object including state & value properties (created in filter normalizer toModels())
   * @returns {boolean} - returns true when filter is a quick filter. otherwise, returns false
   */
  isQuickFilter(filter) {
    try {
      return filter.state.type === 'quick';
    } catch (e) {
      return false;
    }
  }

  /**
   * whether or not multiple filter with the same property exists in filter models
   * @param {Object} filter filter object including state & value properties (created in filter normalizer toModels())
   * @returns {boolean} returns true when there are multiple filter with the same property in filter models
   */
  areMultipleFiltersExists(filter) {
    // there are no other filter with the same api property key in the models
    if (!this.models[filter.state.apiProperty]) {
      /*
       * set this quick filter as not disabled
       * this action is required in case it was disabled in previous state of the models
       */

      return false;
    }

    return true;
  }

  /**
   * remove a filter from models
   * @param {String} filterKey - filter key name as specified in filter settings file
   */
  removeFilterFromModels(filterKey) {
    delete this.models[filterKey];
  }

  /**
   * set filter as enabled or disabled. filter state: {isDisabled: true|false}
   * quick filters are usually disabled in situation when there are 2 filters with the same api property in models
   * object. quick filter and regular filter with the same api property
   *
   * when a filter is disabled:
   * 1. the filter label is not clickable for the user, there are no actions available.
   * 2. the filter item is ignored in filter normalizer (toFilter())
   *
   * @param {Object} filter models filter object
   * @param {Boolean} bool set true to enable filter, false to disable filter
   */
  enableFilter(filter, bool = true) {
    filter.state.isDisabled = !bool;
  }

  /**
   * set filter as active or inactive. filter state: {isActive: true|false}
   *
   * when a filter is active:
   * 1. user can see active color on filter label.
   * 2. table results are filters by the filter values
   * 3. the user has actions available such as inactive or remove the filter
   *
   * when a filter is NOT active:
   * 1. filter values ignored by filter normalizer
   * 2. the user has actions available such as active or remove filter
   *
   * @param filter
   * @param bool
   */
  activateFilter(filter, bool = true) {
    filter.state.isActive = bool;
  }

  /**
   * get filter type name by quick filter object
   * @param {Object} quickFilter quick filter object from models
   * @returns {String} returns type name of the filter that is associated with the quick filter
   */
  getFilterType(quickFilter) {
    const apiProperty = quickFilter.state.apiProperty;
    return this.models[apiProperty].state.type;
  }

  /**
   * handle select type filters
   * @param {Object} quickFilter filter model object from filter models
   * @returns {Boolean} returns true when filters values equals. otherwise, returns false
   */
  handleSelectFilter(quickFilter) {
    const apiProperty = quickFilter.state.apiProperty;

    /*
     * filter equality comparison:
     * finding equality between values returned from selected filter & quick filter.
     * because select filter returns (mixed) object as value, it could be a difficult to perform equality comparison.
     * instead, a primitive value is taken from each object for comparison, the value is taken using the
     * 'valueField' property defined in filter state.
     */
    const modelApiComparisonValue = this.models[apiProperty].value[
      this.models[apiProperty].state.valueField
    ];
    const quickFilterApiComparisonValue =
      quickFilter.value[quickFilter.state.valueField];

    return quickFilterApiComparisonValue === modelApiComparisonValue;
  }

  /**
   * handle range type filters
   * @param {Object} quickFilter filter model object from filter models
   * @returns {Boolean} returns true when filters values equals. otherwise, returns false
   */
  handleRangeFilter(quickFilter) {
    const apiProperty = quickFilter.state.apiProperty;

    const quickFilterComparisonValue = quickFilter.value;
    const duplicatedFilterComparisonValue = this.models[apiProperty].value;

    return _.isEqualEs(
      quickFilterComparisonValue,
      duplicatedFilterComparisonValue,
    );
  }

  /**
   * handle dateRange type filters
   * @param {Object} quickFilter filter model object from filter models
   * @returns {Boolean} returns true when filters values equals. otherwise, returns false
   */
  handleDateRangeFilter(quickFilter) {
    const apiProperty = quickFilter.state.apiProperty;

    if (!quickFilter.value.startDate || !quickFilter.value.endDate) {
      log.warn(
        'you must provide startDate & endDate values in dateRange quick filter value settings',
      );
    }

    const quickFilterComparisonValue = {
      startDate: quickFilter.value.startDate
        .startOf('day')
        .format(this.dateFormat.MYSQL_DATETIME),
      endDate: quickFilter.value.endDate
        .endOf('day')
        .format(this.dateFormat.MYSQL_DATETIME),
    };

    const duplicatedFilterComparisonValue = {
      startDate: this.models[apiProperty].value.startDate
        .startOf('day')
        .format(this.dateFormat.MYSQL_DATETIME),
      endDate: this.models[apiProperty].value.endDate
        .endOf('day')
        .format(this.dateFormat.MYSQL_DATETIME),
    };

    return _.isEqualEs(
      quickFilterComparisonValue,
      duplicatedFilterComparisonValue,
    );
  }
}

export default FilterKeyDuplicationResolverService;
