import BaseStatusesController from './base-statuses.controller';
import CustomerComplianceStatusesService from '~/source/contact/common/services/customer-compliance-statuses.service';
import template from './statuses-management.html';

class CustomerStatusesController extends BaseStatusesController {
  static $inject = [
    'customerComplianceStatusesService',
    ...BaseStatusesController.$inject,
  ];
  shouldShowDefaultCheckbox = false;

  customerComplianceStatusesService: () => CustomerComplianceStatusesService;
  dataService: CustomerComplianceStatusesService;

  $onInit() {
    this.dataService = this.customerComplianceStatusesService();

    super.$onInit();
  }

  get sectionName() {
    return 'complianceStatuses';
  }
}

export default {
  template,
  controller: CustomerStatusesController,
};
