import angular from 'angular';
import { AddPromoCodePopupComponent } from './add-promo-code-popup/add-promo-code-popup';
import MarketingTabComponent from './marketing-tab/marketing-tab.component';
import { PromoCodePopupComponent } from './promo-code-popup/promo-code-popup.component';

export default angular
  .module('crm.contact.sidebar.marketing', [])
  .component('prfContactMarketingTab', MarketingTabComponent)
  .component('prfPromoCodePopup', PromoCodePopupComponent)
  .component('prfAddPromoCodePopup', AddPromoCodePopupComponent).name;
