import RestService from '~/source/common/services/rest';
import { IElementRestNg } from '../models/ielement-rest-ng';
import { UserSettings } from '@proftit/crm.api.models.entities';

export class UserSettingsService extends RestService {
  /**
   *  Return resource name
   */
  get resource(): string {
    return 'userSettings';
  }

  /**
   * Returns setting record for the given key.
   * If the record does not exist yet, created it using the default value
   *
   * @param key - Setting key
   * @param defaultValue - default setting value. used only if record does not exist
   */
  getSetting<T>(key: string, defaultValue: T) {
    return (
      this.filter({ key })
        .getListWithQuery<IElementRestNg<UserSettings<T>>>()
        // return the setting record if it exists, otherwise create it
        .then((resp) => {
          if (resp.length > 0) {
            return resp[0];
          }

          return this.postWithQuery<IElementRestNg<UserSettings<T>>>({
            key,
            value: defaultValue,
          });
        })
    );
  }

  /**
   * Returns setting record for the given key.
   * without creating it if not exist
   *
   * @param key - Setting key
   * @param defaultValue - default setting value. used only if record does not exist
   */
  getSettingWithoutDefault<T>(key: string) {
    return this.filter({ key })
      .getListWithQuery<IElementRestNg<UserSettings<T>>>()
      .then((resp) => {
        if (resp.length > 0) {
          return resp[0];
        }
        return null;
      });
  }

  /**
   * Update a setting value by id (not key!)
   *
   * We are using this method instead of the record's "patch" method because we want different block ui
   * settings for it.
   *
   * @param id - setting id
   * @param value - updated value
   */
  setSettingValue<T>(id: number, value: T) {
    return (
      this.resourceBuildStart()
        .getElement(id)
        .resourceBuildEnd()
        // background process, don't block the ui
        .setConfig({ suppressBlockUi: true })
        .patchWithQuery<IElementRestNg<UserSettings<T>>>({ value })
    );
  }

  useCache() {
    return true;
  }
}

export default UserSettingsService;
