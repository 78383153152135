import * as _ from '@proftit/lodash';
import Component from '~/source/common/components/dropdowns/base/component';
import { PlatfromAssetsService } from '../../services/platform-assets.service';
import { IHttpService, IPromise } from 'angular';
import * as rx from '@proftit/rxjs';
import {
  getAsList,
  jsonServerToCfdMongo,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CurrentTrcAssetsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-assets-of-platform-service.directive';
import { SymbolCategoryStoreServiceDirectiveController } from '~/source/common/service-directives/symbol-category-store-service.directive';
import template from './cfd-platform-asset-category-select.html';

export class CfdPlatformAssetCategorySelectController {
  static $inject = [
    ...Component.controller.$inject,
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
  ];

  /* require */

  symbolCategoryAsOptions = _.memoize((symboleCategories) =>
    symboleCategories
      ? symboleCategories.map((category) => ({
          value: category,
          label: category,
          name: category,
        }))
      : [],
  );

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  prfCurrentTrcAssetsOfPlatform: CurrentTrcAssetsOfPlatformServiceDirectiveController;

  prfSymbolCategory$ = new rx.BehaviorSubject<
    SymbolCategoryStoreServiceDirectiveController
  >(null);

  /* injected */

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfPlatformAssetsService: PlatfromAssetsService;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  $onInit() {
    useStreams([this.streamInitPopup()], this.lifecycles.onDestroy$);
  }

  $onChanges() {}

  $onDestroy() {}

  private streamInitPopup(): rx.Observable<any> {
    return rx.obs
      .combineLatest([
        this.prfSymbolCategory$.pipe(rx.filter((x) => !_.isNil(x))),
      ])
      .pipe(
        rx.tap(([symbolCategory]) => {
          symbolCategory.load();
        }),
      );
  }
}

export const CfdPlatformAssetCategorySelectComponent = {
  template,
  controller: CfdPlatformAssetCategorySelectController,
  bindings: {
    model: '=',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};
