import TablePopupController from '~/source/common/components/table/table-popup';
import PopupService from '~/source/common/components/modal/popup.service';
import template from './data-export-log-popup.html';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { dataExportTablePopupColumns } from './data-export-log-table-columns';
import angular from 'angular';
import ModalService from '~/source/common/components/modal/modal.service';
import { useStreams } from '@proftit/rxjs.adjunct';
import { AllExportsService } from '../../services/allExports';
import settings from './data-export-log-settings.json';
import { PrivateGoogleStorageFileService } from '~/source/common/services/private-google-storage-file.service';
import { downloadLocalFileUrl } from '@proftit/dom-utilities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export enum ReportIndexCode {
  CUSTOMERS = 'customers',
  BALANCE_LOGS = 'balance_logs',
}

const styles = require('./data-export-log-popup.scss');
const DATA_EXPORT_DONE_STATUS_CODE = 'done';
const FULL_PROGRESS_PERCENT = 100;

class DataExportLogComponent extends TablePopupController {
  static $inject = [
    '$scope',
    'popupService',
    'exportsService',
    'modalService',
    'PermPermissionStore',
    'privateGoogleStorageFileService',
    ...TablePopupController.$inject,
  ];

  styles = styles;
  exportsService: () => AllExportsService;
  exportsServiceInst: AllExportsService;
  exports$ = new rx.BehaviorSubject<any[]>([]);
  settings = _.cloneDeep(settings);
  privateGoogleStorageFileService: PrivateGoogleStorageFileService;

  // @ts-ignore - to prevent "property user before it's initalization".
  dataServiceInstance = this.exportsService();
  onDone: () => void;

  popupService: PopupService;
  modalService: ModalService;
  platformTypesMap: any;

  lifecycles = observeComponentLifecycles(this);
  infiniteTableInitSubject = new rx.Subject<number>();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    Object.assign(this, {
      exportsServiceInst: this.exportsService(),
    });

    useStreams([this.streamInitTable()], this.lifecycles.onDestroy$);
  }

  get ngTableDataParams() {
    return this.settings.popupTable.ngTable.parameters;
  }

  streamInitTable() {
    return rx.pipe(
      () => this.infiniteTableInitSubject,
      rx.withLatestFrom(this.infiniteTableInitSubject),
      rx.tap(([a, rows]) => this.initTable(rows)),
    )(null);
  }

  $onInit() {
    super.$onInit();
  }

  get cols() {
    return [...dataExportTablePopupColumns];
  }

  get ngTableSettings() {
    return { ...this.settings.popupTable.ngTable };
  }

  get title() {
    return 'DATA EXPORT LOG';
  }

  get tableKey() {
    return 'dataExportLog';
  }

  /**
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.exportsServiceInst
      .expand(['user', 'index'])
      .filter('progress', FULL_PROGRESS_PERCENT)
      .filter('status', DATA_EXPORT_DONE_STATUS_CODE);
  }

  downloadFile(id: number, reportCode: string): void {
    this.asyncFileDownload(id, reportCode);
  }

  asyncFileDownload(id: number, reportCode: string): Promise<any> {
    return this.getFileUrl(id, reportCode).then(
      ({ fileName, localFileUrl }) => {
        downloadLocalFileUrl(localFileUrl, fileName);
        URL.revokeObjectURL(localFileUrl);
      },
    );
  }

  getFileUrl(
    downloadId: number,
    reportCode: string,
  ): Promise<{ fileName: string; localFileUrl: string }> {
    let result;

    if (reportCode === ReportIndexCode.CUSTOMERS) {
      result = this.privateGoogleStorageFileService.getCrmExportFileUrl(
        downloadId,
      );
    } else if (reportCode === ReportIndexCode.BALANCE_LOGS) {
      result = this.privateGoogleStorageFileService.getBalanceLogFileUrl(
        downloadId,
      );
    } else {
      result = this.privateGoogleStorageFileService.getKibiFileUrl(downloadId);
    }

    return result;
  }

  $onDestroy() {}
}

export default {
  template,
  bindings: {
    close: '&',
    dismiss: '&',
  },
  controller: DataExportLogComponent,
  controllerAs: 'vm',
};
