import type { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import { TokensService } from './auth/services/tokens';

export const generalConfig = (
  $stateProvider: StateProvider,
  $urlRouterProvider: UrlRouterProvider,
  appConfig: any,
) => {
  // Define root url: redirect to dashboard if logged in, or login page otherwise.
  $urlRouterProvider.when('', [
    '$state',
    'tokensService',
    ($state: Record<string, any>, tokensService: TokensService) => {
      if (tokensService.isLoggedIn()) {
        $state.go(appConfig.defaultState);
      } else {
        $state.go('auth.login');
      }
    },
  ]);

  /**
   * All the "logged in" states should be under this state.
   * This way, we can have a custom layout for logged in state, with top menu
   * and no layout for "not logged in" states - such as the login page and the unauthorized page
   */
  $stateProvider.state('crm', {
    url: '/crm',
    component: 'prfLayout',
    redirectTo: appConfig.defaultState,
    data: {
      permissions: {
        only: ['loggedIn'],
        redirectTo: 'auth.login',
      },
    },
  });
};

generalConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'appConfig'];
