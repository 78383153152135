import * as _ from '@proftit/lodash';
import { isArray } from '@proftit/general-utilities';
import '~/vendor/amcharts/amcharts';
import 'ammap3/ammap/maps/js/worldLow';
import 'ammap3/ammap/ammap';
import BaseController from '~/source/common/controllers/base';
import module from '../../index';
import template from './world-map.html';
import { IScope } from 'angular';

import { Country } from '@proftit/crm.api.models.entities';

class Controller extends BaseController {
  mapId;
  mapHeight;
  countries: Country | Country[];

  /*@ngInject */
  constructor(readonly $element, readonly $scope: IScope) {
    super();
  }

  $postLink() {
    this.$scope.$watch('vm.countries', this.redraw.bind(this));
  }

  /**
   * Draw the map. called whenever the countries collection changes (or initialized)
   */
  redraw() {
    const options = {
      type: 'map',
      dataProvider: {
        map: 'worldLow',
      },
      /**
       * create areas settings
       * autoZoom set to true means that the map will zoom-in when clicked on the area
       * selectedColor indicates color of the clicked area.
       */
      areasSettings: {
        autoZoom: true,
        selectedColor: '#3abdd9',
      },
    };

    // countries array
    if (isArray(this.countries) && (this.countries as any[]).length) {
      // handle many areas provided as array of objects
      (options.dataProvider as any).areas = (this.countries as any[]).map(
        (country: Country) => ({
          id: country.alpha2Code,
        }),
      );
    } else if (_.isObject(this.countries)) {
      // countries object

      // handle single selected area provided as object
      (options.dataProvider as any).areas = [this.countries].map(
        (country: Country) => ({
          id: country.alpha2Code,
          color: '#3abdd9',
          autoZoom: true,
        }),
      );

      /*
       * linkToObject - set amcharts zoom map to selected country
       * the zoom will start right after the AmCharts.makeChart is called
       */
      (options.dataProvider as any).linkToObject = (this
        .countries as Country).alpha2Code;
    }

    const mapObj = (window as any).AmCharts.makeChart(this.mapId, options);

    /*
     * set how many seconds zoom to country takes. works only if we provide single area object.
     * please notice that this property behave differently if set in the options object. don't move it there
     * without proper testing
     */
    mapObj.zoomDuration = 5;
  }
}

module.component('prfWorldMap', {
  template,
  bindings: {
    mapId: '@',
    mapHeight: '@',
    countries: '<',
  },
  controller: Controller,
  controllerAs: 'vm',
});
