import angular from 'angular';

import AmChartsFunnelComponent from './amcharts-funnel.component';
import AmChartsPieComponent from './amcharts-pie.component';
import AmChartsSerialComponent from './amcharts-serial.component';
import AmChartsSlicedComponent from './amcharts-sliced.component';
import AmChartsStackedComponent from './amcharts-stacked.component';

const ngModule = angular
  .module('crm.common.amcharts', [])
  .component('prfAmChartsFunnel', AmChartsFunnelComponent)
  .component('prfAmChartsPie', AmChartsPieComponent)
  .component('prfAmChartsSerial', AmChartsSerialComponent)
  .component('prfAmChartsSliced', AmChartsSlicedComponent)
  .component('prfAmChartsStacked', AmChartsStackedComponent);

export default ngModule.name;
