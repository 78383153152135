import type { ui } from 'angular';
import brandListTemplate from './templates/brand-list.html';

export function brandRouting(
  $stateProvider: ui.IStateProvider,
  $urlRouterProvider: ui.IUrlRouterProvider,
) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/brands',
    '/crm/management/brands/list',
  );

  $stateProvider
    .state('crm.management.brands', {
      url: '/brands',
      template: '<ui-view/>',
      data: {
        pageTitle: 'MANAGEMENT_BRANDS',
      },
    })
    .state('crm.management.brands.list', {
      url: '/list',
      template: brandListTemplate,
      controller: 'BrandsListController as vm',
      data: {
        permissions: {
          only: ['management.brands'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.brands.create', {
      url: '/create',
      component: 'prfBrandCreate',
      data: {
        permissions: {
          only: ['management.brands_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.brands.update', {
      url: '/update/:id',
      template: `
        <prf-brand-update>
        </prf-brand-update>
      `,
      data: {
        permissions: {
          only: ['management.brands_U'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}

brandRouting.$inject = ['$stateProvider', '$urlRouterProvider'];
