import template from './notifications-user-roles.component.html';
const styles = require('./notifications-user-roles.component.scss');

import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import {
  UserRoleNotification,
  UserRolePosition,
} from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import * as rx from '@proftit/rxjs';

export class NotificationsUserRolesController {
  disabled;
  notifications;
  onStateChange;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  notifications$ = observeShareCompChange<UserRoleNotification[]>(
    this.lifecycles.onChanges$,
    'notifications',
  );
  roles$ = observeShareCompChange<UserRolePosition[]>(
    this.lifecycles.onChanges$,
    'roles',
  );
  roles: UserRolePosition[];
  roleTrackers$ = this.streamRoleTrackers();

  /*@ngInject */
  constructor() {
    useStreams([this.roles$, this.notifications$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams([this.roleTrackers$], this.lifecycles.onDestroy$);
  }

  $onDestroy() {}

  $onChanges() {}

  streamRoleTrackers() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.notifications$, this.roles$),
      rx.filter((dependenciesArray) =>
        dependenciesArray.every((dependency) => dependency),
      ),
      rx.map(([notifications, roles]) => {
        return roles.map((role) => {
          let shouldNotify = false;
          notifications.forEach((data) => {
            if (data.roleId === role.id) {
              shouldNotify = true;
            }
          });
          return {
            role,
            shouldNotify,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const NotificationsUserRolesComponent = {
  template,
  controller: NotificationsUserRolesController,
  bindings: {
    onStateChange: '&',
    roles: '<',
    disabled: '<',
    notifications: '<',
  },
};
