import Component from './base/component';

class PricingModelController extends Component.controller {
  static $inject = [
    'marketingPricingModelService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'common.SELECT_PRICING_MODEL';
  }

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'lang';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({ controller: PricingModelController });
