import ng, { IHttpService } from 'angular';
import * as rx from '@proftit/rxjs';

import { TableLiveController } from '~/source/common/components/table/table-live.controller';
import { SocketService } from '~/source/common/services/socket';

import template from './currency-exposure-table.component.html';
import { cols } from './currency-exposure-table-cols';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { settings } from './currency-exposure-table-settings';
import ModalService from '~/source/common/components/modal/modal.service';
import { CfdPlatformAsset } from '~/source/common/models/cfd-platform/cfd-platform-asset';
import { calcAssetFixedSpread } from '~/source/common/utilities/calc-asset-fixed-spread';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PLATFORM_ASSET_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import * as _ from '@proftit/lodash';
import { appendResource } from '@proftit/request-client';

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { Platform } from '@proftit/crm.api.models.entities';
import { PlatfromAssetsService } from '~/source/management/integrations/risk-manager/services/platform-assets.service';
import CfdPlatformSocketService from '~/source/management/integrations/risk-manager/services/cfd-platform-socket.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { ApiTrcExposureService } from '~/source/common/api-cfd-platform/api-trc-exposure.service';
import { TrcBrandExposureCurrenciesSocketService } from '~/source/common/api-cfd-platform/trc-brand-exposure-currencies-socket.service';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';

const styles = require('./currency-exposure-table.component.scss');

interface ICfdPlatformBrandAssetsTable {
  platformConnection: PlatformConnection;
}

const GLOBAL_GROWL_ID = 'restService';
const CURRENCIES_RESOURCE_PATH = 'currencies';

export class CurrencyExposureTableController extends TableLiveController
  implements ICfdPlatformBrandAssetsTable {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'modalService',
    'prfApiTrcExposureService',
    'prfCfdPlatformSocketService',
    'prfClientGeneralPubsub',
    'trcBrandExposureCurrenciesSocketService',
    ...TableLiveController.$inject,
  ];

  lifecycles = observeComponentLifecycles(this);
  /* bindings */
  platformConnection: PlatformConnection;
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  platformBrandId;
  /* injections */
  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  modalService: ModalService;
  prfPlatformAssetsService: PlatfromAssetsService;
  prfApiTrcExposureService: ApiTrcExposureService;
  trcBrandExposureCurrenciesSocketService: () => TrcBrandExposureCurrenciesSocketService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  socketServiceInstance: CfdPlatformSocketService;

  platformConnection$ = new rx.BehaviorSubject<PlatformConnection>(null);

  settings = _.cloneDeep(settings);
  cols = cols;
  opOpenEditPopup$ = new rx.Subject<CfdPlatformAsset>();
  currencies: Restangular.ICollection;
  platformConnectionForFilters: PlatformConnection;
  exportAsCsvOp$ = new rx.Subject<any>();
  tblNumOfRows: { count: number; id: number; name: string };
  styles = styles;

  constructor(...args) {
    super(...args);
  }

  $onInit() {
    super.$onInit();
    this.tblNumOfRows = {
      count: settings.table.ngTable.parameters.count,
      id: numRowsOptions.indexOf(settings.table.ngTable.parameters.count),
      name: `${settings.table.ngTable.parameters.count}`,
    };

    useStreams(
      [this.streamInitDataServiceInstanceAndTable(), this.streamExportAsCsv()],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {
    super.$onDestroy();
  }

  streamInitDataServiceInstanceAndTable() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((sessionInfo) => {
        this.platformBrandId = sessionInfo.trcBrand.id;
        this.socketServiceInstance = this.trcBrandExposureCurrenciesSocketService();
        this.socketServiceInstance.setToken(sessionInfo.session.token);
        this.socketServiceInstance.setStreamerUrl(
          sessionInfo.session.streamerUrl,
        );
      }),
      rx.map((sessionInfo) => {
        let req = this.prfApiTrcExposureService.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
        req = appendResource(CURRENCIES_RESOURCE_PATH, req);

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      rx.tap(() => this.initTable(this.tblNumOfRows.count)),
    )(null);
  }

  streamExportAsCsv() {
    const action$ = rx.pipe(
      () => this.exportAsCsvOp$,
      rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.switchMap(([_a, sessionInfo]) => {
        return this.prfApiTrcExposureService.exportCurrencyReport(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
    )(null);

    return action$;
  }

  /**
   * sets tableParams to new number of rows
   * @param {number} numOfRowid: numRowsOptions.indexOf(res.value),
                name:s is the number of rows the table will have in every page
   * @returns {void}
   */
  setNumberOfRows(numOfRows): void {
    super.setNumberOfRows(numOfRows.count);
  }

  get tableKey() {
    return 'cfdCurrencyExposure';
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return this.socketServiceInstance;
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId: number): string {
    return `{brand.${this.platformBrandId}}.exposure.currency.${elementId}`;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.currencies';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */

  get entitiesContainer() {
    return this.currencies;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  parseLoadedData(data) {
    data.forEach((currency) => {
      currency._uiFixedSpread = null;
      currency.id = currency.currencyId;
    });
    this.currencies = data;
    return data;
  }

  findEntityToUpdateFromStreamer(entities, newData) {
    return entities.find(({ id }) => id === newData.currencyId);
  }

  updateEntityFromStreamerNotification(entity, newData) {
    Object.assign(entity, newData);
  }
}

export const CurrencyExposureTableComponent = {
  template,
  controller: CurrencyExposureTableController,
  controllerAs: 'vm',
  require: {
    prfCurrentPlatformSession: '^',
  },
};

export default CurrencyExposureTableComponent;
