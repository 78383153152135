import { IScope } from 'angular';
import _ from 'underscore';
import * as _l from '@proftit/lodash';

import Controller from '~/source/common/controllers/base';
import { tzOffsets as TzOffsets } from '~/source/common/constants/tz-offsets';
import FilterService from '~/source/common/components/table-filters/services/filter.service';
import FilterCache from '~/source/common/components/table-filters/services/filter-cache';
import ModalService from '~/source/common/components/modal/modal.service';
import FilterKeyDuplicationResolver from '~/source/common/components/table-filters/services/filter-key-duplication-resolver';
import {
  FiltersConfigObject,
  FiltersModel,
  FilterType,
  CachedFilterObject,
  FilterConfig,
  NormalizedFilter,
  FiltersArray,
} from '~/source/common/components/table-filters/index';
import useStream from '~/source/common/utilities/use-stream';

import template from './filters-bar.html';
import modalTemplate from '../filter-group/filter-group-modal.html';
import savedFilterModalTemplate from '../filter-group/saved-filter-modal.html';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { TABLE_FILTER_UPDATE_DROPDOWN_MODEL } from '~/source/common/constants/general-pubsub-keys';
import * as rx from '@proftit/rxjs';

class TableFiltersBarController extends Controller {
  /*
   * Bindings
   */
  quickFilters: FiltersConfigObject;
  enableSavedFilters: any;
  category: any;
  onFilterRemovedByUi: (a: { filterName: string }) => {};
  onFilterAddedByEvent: (a: { filter: any }) => {};
  onFiltersAppliedFromSavedCache: (a: { filtersState }) => {};
  onClearAllFiltersFromBar: () => {};
  onNotifySavedFilterChange: (a: { filtersState }) => {};
  cacheId: any;
  models: FiltersConfigObject;
  filtersState: CachedFilterObject;
  savedFilter: any;
  removeFilter: (stateKey: string, value: any) => {};
  toggleIsActive: any;
  excludeFilter: Function;
  includeFilter: Function;
  isOpen: boolean;
  unsub$ = new rx.Subject<void>();
  addFilterObs: rx.Observable<{
    clearName: string | string[];
    newFilter: Object;
  }>;
  removeFilterObs: rx.Observable<string>;
  filterRemovedByUi$ = new rx.Subject<string>();
  filterAddedByEvent$ = new rx.Subject<any>();
  isFilterPopoverExcludable: Function;

  /*@ngInject */
  constructor(
    readonly tzOffsets: typeof TzOffsets,
    readonly $scope: IScope,
    readonly filterService: FilterService,
    readonly CacheFactory,
    readonly filterCache: FilterCache,
    readonly modalService: ModalService,
    readonly filterKeyDuplicationResolver: FilterKeyDuplicationResolver,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();

    this.isFilterPopoverExcludable = _l.memoize((stateKey) =>
      this.filterService.isFilterPopoverExcludable(stateKey),
    );
  }

  $onInit() {
    this.models = {};

    this.cacheId = this.cacheId || this.category;

    // normalize quick filters to models
    if (this.quickFilters) {
      Object.assign(
        this.models,
        this.filterService.quickFilterToModels(this.quickFilters),
      );
    }

    // get & merge saved filters into models
    this.filtersState = this.filterCache.get(this.cacheId);
    if (this.filtersState) {
      this.applyFiltersState(this.filtersState);
      if (this.onFiltersAppliedFromSavedCache) {
        this.onFiltersAppliedFromSavedCache({
          filtersState: this.filtersState,
        });
      }
    }

    this.savedFilter = {};

    this.$scope.$watch(
      () => this.savedFilter.filters,
      this.onSavedFilterChange.bind(this),
    );

    // trigger applyFilter() when 'add filter' event is called
    this.$scope.$on('table:filter:add', (event, filter) =>
      this.applyFilterFromEvent(filter),
    );

    // trigger _removeStateLabel() when 'remove filter' event is called
    this.$scope.$on('table:filter:remove', (scope, stateKey) =>
      this.removeStateLabelLocalFromEvent(stateKey),
    );

    useStream(this.streamAddFilterCommand(), this.unsub$);
    useStream(this.streamRemoveFilterCommand(), this.unsub$);
    useStream(this.streamNotifyRemovedFilterByUi(), this.unsub$);
    useStream(this.streamNotifyAddedFilterByEvent(), this.unsub$);

    // should disable quick filter that have a filter with the same key
    this.filterKeyDuplicationResolver.resolve(this.models);

    this.removeFilter = this.removeFilterFromUi.bind(this);
    this.toggleIsActive = this.toggleActiveStatusLocal.bind(this);
    this.excludeFilter = (stateKey, value) =>
      this.excludeIncludeFilterLocal(stateKey, value, true);
    this.includeFilter = (stateKey, value) =>
      this.excludeIncludeFilterLocal(stateKey, value, false);

    if (_l.isNil(this.filtersState)) {
      this.setFilterCache();
    }
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  /**
   * Generate stream - Reponsd to external add single filter command.
   *
   * @return Observable of the generated stream.
   */
  streamAddFilterCommand() {
    return rx.pipe(
      () => (this.addFilterObs ? this.addFilterObs : new rx.Subject()),
      rx.tap(({ clearName, newFilter }) => {
        this.clearAndAddFilterFromCommand(clearName, newFilter);
      }),
    )(null);
  }

  /**
   * Generate stream - Reponsd to external remove filter command.
   *
   * @return Observable of the generated stream.
   */
  streamRemoveFilterCommand() {
    return rx.pipe(
      () => (this.removeFilterObs ? this.removeFilterObs : new rx.Subject()),
      rx.tap((newFilter) => this.removeStateLabelLocalFromEvent(newFilter)),
    )(null);
  }

  /**
   * Generate stream - Output notification of removed filter by bar ui action.
   *
   * @return Observable of the generated stream.
   */
  streamNotifyRemovedFilterByUi() {
    return rx.pipe(
      () => this.filterRemovedByUi$,
      rx.filter(() => !_l.isNil(this.onFilterRemovedByUi)),
      rx.tap((filterName) => this.onFilterRemovedByUi({ filterName })),
    )(null);
  }

  /**
   * Generate stream - Output notification of added filter by bar ui action.
   *
   * @return Observable of the generated stream.
   */
  streamNotifyAddedFilterByEvent() {
    return rx.pipe(
      () => this.filterAddedByEvent$,
      rx.filter(() => !_l.isNil(this.onFilterAddedByEvent)),
      rx.tap((filter) => this.onFilterAddedByEvent({ filter })),
    )(null);
  }

  /**
   * Clear filter and add filter.
   *
   * @param clearName - filter to clear.
   * @param newFilter - filter to add.
   * @return none.
   */
  clearAndAddFilterFromCommand(clearName: string | string[], newFilter) {
    let clearNameList = [];
    if (_.isString(clearName)) {
      clearNameList = [...clearNameList, clearName];
    }

    if (_.isArray(clearName)) {
      clearNameList = [...clearNameList, ...(clearName as string[])];
    }

    clearNameList.forEach((name) => {
      this.removeStateLabelLocal(name);
    });

    this.applyFilter(newFilter);
  }

  /**
   * Returns the number of currently active filters
   * @return {number} active filters number
   */
  get activeFiltersNum() {
    return (this.models && Object.keys(this.models).length) || 0;
  }

  /**
   * Called when the "saved filter" changes.
   * Resets all currently selected filters, then apply the new filters state.
   *
   * @param {object} filtersState the new filter state
   * @return {void}
   */
  onSavedFilterChange(filtersState) {
    if (!filtersState || _.isEqual(filtersState, this.filtersState)) {
      return;
    }

    this.clearFilters(); // Reset current filters

    this.applyFiltersState(filtersState);

    // save it to localStorage as well
    this.updateFilterCache();

    this.onNotifySavedFilterChange({
      filtersState: this.filtersState,
    });
  }

  /**
   * Applies a given filters state by merging it to filter models and selected filters.
   *
   * Filter state should be an object representing the state of each of the filters.
   * E.g. { "newContacts": {"state": {"isActive": false},"value": true}},
   *        "isLead": {"state": {"isActive": true}, "value": false}}
   *      }
   *
   * @param {object} filtersState - state of all current filters
   * @return {void}
   */
  applyFiltersState(filtersState: CachedFilterObject) {
    // Set new models from the saved filter
    this.mergeCachedFiltersIntoModels(filtersState);

    this.$scope.$emit('table:filter:updated', this.models);
  }

  /**
   * Clear all currently selected filters
   * @return {void}
   */
  clearFilters() {
    // Clear all filter models
    Object.keys(this.models).forEach(
      (filterKey) => delete this.models[filterKey],
    );

    this.$scope.$emit('table:filter:updated', this.models);
  }

  clearAllFilters() {
    this.clearFilters();
    _.each(this.quickFilters, (filterObject) => {
      if (filterObject.state) {
        filterObject.state.isActive = false;
      }
    });
    Object.assign(
      this.models,
      this.filterService.quickFilterToModels(this.quickFilters),
    );
    this.filtersState = {};
    this.updateFilterCache();
    if (this.onClearAllFiltersFromBar) {
      this.onClearAllFiltersFromBar();
    }
  }

  /**
   * merge cached filters into models
   * this function is invoked once, on loading, after quick filters has been added to models
   */
  mergeCachedFiltersIntoModels(filtersState: CachedFilterObject) {
    _.each(filtersState, (cachedFilter, key) => {
      const cachedModel = this.filterService.fromCache({ [key]: cachedFilter });

      // cached deactivated filter is not exists in the models, add it.
      if (_.isUndefined(this.models[key])) {
        Object.assign(this.models, cachedModel);
      }

      // multiple filter
      if (_.isArray(cachedFilter)) {
        cachedFilter.forEach((modelFilter, index) => {
          /*
           * model have only active filters from selected filters in this stage.
           * add the missing cached filters.
           */
          if (!this.models[key][index]) {
            this.models[key][index] = cachedModel[key][index];
          }

          // set filter active status from cache, otherwise set false
          this.models[key][index].state.isActive = _.isUndefined(
            cachedFilter[index].state.isActive,
          )
            ? false
            : cachedFilter[index].state.isActive;

          // set filter status from state
          this.models[key][index].value = cachedFilter[index].value;
        });
      } else {
        // single filter, set filter active status & value from cache
        this.models[key].state.isActive = cachedFilter.state.isActive;
        this.models[key].value = cachedFilter.value;
      }
    });
  }

  /**
   * called on add filter event. updated local models and selected filters.
   * @param {object} filter
   */
  applyFilterFromEvent(filter) {
    this.applyFilter(filter);
    this.filterAddedByEvent$.next(filter);
  }

  /**
   * Updated local models and selected filters.
   * @param {object} filter
   */
  applyFilter(filter) {
    if (_.isEmpty(filter)) {
      return;
    }
    const newModels = this.filterService.toModels(filter);

    // Merge new filter models to currently applied models
    this.mergeFilter(this.models, newModels);

    this.filterKeyDuplicationResolver.resolve(this.models);
    this.updateFilterCache();
  }

  /**
   * merge new filter models to structured format supported by regular & multiple filters, changes applied
   * on currentFilters object
   *
   * If "newFilter" and "currentFilters" has different modes (one has exclude, the other include)
   * the "newFilter" mode wins.
   *
   * @example Array
   * newFilter: {"country":[{"value":{"id":1, ...},"exclude":false,"state":{...}}]}
   * dest:{"country":[{"value":{"id":2, ...},"exclude":false,"state":{...}]}
   * dest after merge: {"country": [{"value":{"id":1, ...},....}, {"value":{"id":2, ...},...}]}
   *
   * @example Exclude
   * newFilter: {"user":{"value":{"id":1, ...},"exclude":true,"state":{...}}}
   * dest:{"user":{"value":{"id":2, ...},"exclude":false,"state":{...}}
   * dest after merge: {"user": {"value":{"id":1, ...},....}}
   *
   * @example Simple
   * newFilter: {balanceTotal: {value: 1, state: {..}, exclude: false}
   * dest: {}
   * dest after merge: {balanceTotal: {value: 1, state: {..}, exclude: false}
   *
   * @param {object} currentFilters - current applied filter models
   * @param {object} newFilters - New filter models
   * @return {void}
   */
  mergeFilter(currentFilters: FiltersConfigObject, newFilters: FiltersModel) {
    _.each(newFilters, (newFilter, key) => {
      if (_.isArray(newFilter)) {
        currentFilters[key] = (currentFilters[key] || []) as any; // currentFilters must be an array

        // resolved exclude conflicts
        const preparedExistingFilters = this.prepareExistingFiltersForMerge(
          currentFilters[key],
          newFilter,
        );

        // remove duplicated filters
        const preparedNewFilters = this.prepareNewFiltersForMerge(
          preparedExistingFilters,
          newFilter,
        );

        // merge old filter with new filters
        currentFilters[key] = [
          ...preparedExistingFilters,
          ...preparedNewFilters,
        ] as any;
      } else {
        Object.assign(currentFilters, { [key]: newFilter });
      }
    });
  }

  /**
   * prepare exiting filters for merge by resolving (include vs exclude) conflicts
   *
   * @param {Object} activeFilterModel
   * @param {Object} activeFilterModel - new filter model
   * @returns {Array} returns empty array if a conflict is found. otherwise, return activeFilterModel as is
   */
  prepareExistingFiltersForMerge(activeFilterModel, newFilterModel) {
    // either all exclude are "on", or none
    const isExcludeActiveFilter = newFilterModel.every((el) => el.exclude);
    const isExcludeNewFilter = activeFilterModel.every((el) => el.exclude);

    return isExcludeActiveFilter === isExcludeNewFilter
      ? activeFilterModel
      : [];
  }

  /**
   * prevent adding duplicated filters to filter models (only in multiple filters)
   *
   * @param {Array} activeFilterModel - existing filter model
   * @param {Array} newFilterModel - new filters model
   * @return {Array} returns array contains only unique new filters (that don't exists in activeFilterModel)
   */
  prepareNewFiltersForMerge(
    activeFilterModel: FilterConfig[],
    newFilterModel: FilterConfig[],
  ) {
    const onlyNewFilters = [];

    _.each(newFilterModel, (filter) => {
      const valueField = filter.state.valueField || 'id';

      // use state valueField in order to extract a comparable value from the new filter
      const comparableValue = filter.value[valueField];

      // does comparableValue exists in current filters
      const filterExists = activeFilterModel.some(
        (el) => el.value[valueField] === comparableValue,
      );

      if (!filterExists) {
        onlyNewFilters.push(filter);
      }
    });

    return onlyNewFilters;
  }

  /**
   * remove a filter
   *
   * @param {string} stateKey
   * @param {*} value
   */
  removeFilterFromUi(stateKey, value) {
    this.removeFilterLocal(stateKey, value);
    this.filterRemovedByUi$.next(stateKey);
  }

  /**
   * remove a filter
   *
   * @param {string} stateKey
   * @param {*} value
   */
  removeFilterLocal(stateKey, value) {
    if (!this.models) {
      return;
    }

    const apiKey = this.filterService.getFilterApiKey(stateKey);

    // multiple filter
    if (_.isArray(this.models[stateKey])) {
      this.removeMultipleFilterLocal(stateKey, value, apiKey);
      if ((this.models[stateKey] as FilterConfig[]).length === 0) {
        // this was the last filter
        this.removeStateLabelLocal(stateKey);
      }
    } else {
      // single filter
      this.removeStateLabelLocal(stateKey);
    }

    this.filterKeyDuplicationResolver.resolve(this.models);
    this.updateFilterCache();
  }

  /**
   * remove a multiple filter
   * @param {string} stateKey
   * @param {*} value
   * @param {string} apiKey
   * @private
   */
  removeMultipleFilterLocal(stateKey: string, value, apiKey) {
    const index = _.findIndex(this.models[stateKey] as FilterConfig[], {
      value,
    });

    if (index !== -1) {
      this.models[stateKey] = (this.models[stateKey] as FilterConfig[]).filter(
        (model, key) => key !== index,
      ) as any;
    }
  }

  /**
   * remove a label from state only
   * @param {string} stateKey
   * @private
   */
  removeStateLabelLocalFromEvent(stateKey) {
    this.removeStateLabelLocal(stateKey);
  }

  /**
   * remove a label from state only
   * @param {string} stateKey
   * @private
   */
  removeStateLabelLocal(stateKey) {
    if (!this.models) {
      return;
    }

    delete this.models[stateKey];
    this.updateFilterCache();
  }

  /**
   * toggle filter active status
   * @param {string} stateKey
   * @param {*} value
   */
  toggleActiveStatusLocal(stateKey, value) {
    const apiKey = this.filterService.getFilterApiKey(stateKey);

    // multiple filter
    if (_.isArray(this.models[stateKey])) {
      this.toggleMultipleActiveStatusLocal(stateKey, value, apiKey);
    } else {
      // single filter
      this.models[stateKey].state.isActive = !this.models[stateKey].state
        .isActive;

      // should disabled quick filter that have a filter with the same key
      this.filterKeyDuplicationResolver.resolve(this.models);
      this.updateFilterCache();
    }
  }

  /**
   * toggle multiple filter active status
   * @param {string} stateKey
   * @param {*} value
   * @param {string} apiKey
   * @private
   */
  toggleMultipleActiveStatusLocal(stateKey, value, apiKey) {
    const index = _.findIndex(this.models[stateKey] as FilterConfig[], {
      value,
    });

    if (index === -1) {
      return;
    }

    // activate/deactivate models filter
    this.models[stateKey][index].state.isActive = !this.models[stateKey][index]
      .state.isActive;
    this.updateFilterCache();
  }

  /**
   * update filter cache
   */
  updateFilterCache() {
    this.setFilterCache();
    this.$scope.$emit('table:filter:updated', this.models);
  }

  setFilterCache() {
    this.filtersState = this.filterService.toCache(this.models);
    this.filterCache.put(this.cacheId, this.filtersState);
  }

  /**
   * Open modal that contains "filter-group-modal"
   * @returns {void}
   */
  openFilterGroup() {
    if (!this.savedFilter.id) {
      // open popup
      this.modalService.open({
        controller: 'FilterGroupPopupController',
        template: modalTemplate,
        scope: this.$scope,
        data: {
          currentSelectedFilters: this.filtersState,
          category: this.category,
          onUpdateMyFiltersDropdownModel: this.onUpdateMyFiltersDropdownModel.bind(
            this,
          ),
        },
      });
    }
  }

  /**
   * Called when the save/update group event triggered.
   * opens the 'Save successfully' modal
   *
   * @param {object} saved/updated filter object
   * @return {void}
   */
  openSavedFilterModal(filter) {
    this.isOpen = false;

    this.modalService.open({
      template: savedFilterModalTemplate,
      scope: this.$scope,
      data: {
        filter,
      },
    });
  }

  /**
   * Called when a filter is saved/updated.
   * Trigger event for my-filter-dropdown model + Opens 'savedFilterModel'
   *
   * @return {void}
   */
  onUpdateMyFiltersDropdownModel(filter, isUpdate) {
    this.prfClientGeneralPubsub.publish(TABLE_FILTER_UPDATE_DROPDOWN_MODEL, {
      filter,
      isUpdate,
    });
    this.openSavedFilterModal(filter);
  }

  /**
   * Returns true if the save filter button should be enabled
   * @return {boolean} true if button should be enabled
   */
  isSaveFilterEnabled() {
    if (this.savedFilter.id) {
      // already saved. don't enable
      return false;
    }

    // check if there are any active filters
    return (
      !!this.filtersState &&
      Object.values(this.filtersState)
        // flatten the array (as it might be nested)
        .reduce((acc: any, cur) => acc.concat(cur), [])
        .some(({ state }) => state.isActive)
    );
  }

  /**
   * Set exclude status on filter.
   *
   * @param stateKey
   * @param value
   * @param exclude
   */
  excludeIncludeFilterLocal(stateKey, value, exclude: boolean) {
    const apiKey = this.filterService.getFilterApiKey(stateKey);

    // multiple filter
    if (_.isArray(this.models[stateKey])) {
      this.toggleMultipleExcludeIncludeLocal(stateKey, value, apiKey, exclude);
    } else {
      // single filter
      this.models[stateKey].exclude = exclude;

      // should disabled quick filter that have a filter with the same key
      this.filterKeyDuplicationResolver.resolve(this.models);
      this.updateFilterCache();
    }
  }

  /**
   * Toggle multiple filter exclude property
   *
   * @param {string} stateKey
   * @param {*} value
   * @param {string} apiKey
   * @param exclude
   * @private
   */
  toggleMultipleExcludeIncludeLocal(stateKey, value, apiKey, exclude: boolean) {
    /*
    const index = _.findIndex(this.models[stateKey] as FilterConfig[], {
      value,
    });

    if (index === -1) {
      return;
    }
     */

    // For exclude, we want for now to effect all sub filters.

    // activate/deactivate models filter
    (this.models[stateKey] as FilterConfig[]).forEach(
      (filter) => (filter.exclude = exclude),
    );
    this.updateFilterCache();
  }
}

export default {
  template,
  transclude: true,
  controllerAs: 'vm',
  controller: TableFiltersBarController,
  bindings: {
    quickFilters: '<',
    category: '<',
    cacheId: '<',
    enableSavedFilters: '<',
    addFilterObs: '<',
    removeFilterObs: '<',
    onFilterRemovedByUi: '&',
    onFilterAddedByEvent: '&',
    onFiltersAppliedFromSavedCache: '&',
    onClearAllFiltersFromBar: '&',
    onNotifySavedFilterChange: '&',
    hideSavedFilters: '<',
    hideClearFilters: '<',
  },
};
