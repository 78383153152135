import WithdrawalRequest from '~/source/common/models/withdrawal-request';

const deletableStauses = ['approved', 'partial'];

/**
 * Check if withdrawal request is deletable.
 *
 * @param {WithdrawalRequest} withdrawalReq - withdrawal request
 * @return {boolean} true if deletable.
 */
export function isWithdrawalDeletable(withdrawalReq: WithdrawalRequest) {
  return deletableStauses.includes(withdrawalReq.transactionStatusCode);
}

export default isWithdrawalDeletable;
