import ng from 'angular';
import searchResultsModuleSettings from './search-results-settings.json';
import CustomersResultsTable from './search-results-table-customers.component';
import TradingAccountsResultsTable from './search-results-table-trading-accounts.component';
import SearchComponent from './search-popup.component';
import { SearchPopupCustomerResultComponent } from './search-popup-customer-result/search-popup-customer-result.component';
import { SearchPopupTradingAccountResultComponent } from './search-popup-trading-account-result/search-popup-trading-account-result.component';
import SearchService from './search.service';

export default ng
  .module('crm.search', [])
  .component('prfSearchPopup', SearchComponent)
  .component('prfSearchPopupCustomerResult', SearchPopupCustomerResultComponent)
  .component(
    'prfSearchPopupTradingAccountResult',
    SearchPopupTradingAccountResultComponent,
  )
  .component('prfSearchCustomersResultsTable', CustomersResultsTable)
  .component(
    'prfSearchTradingAccountsResultsTable',
    TradingAccountsResultsTable,
  )
  .constant('searchResultsModuleSettings', searchResultsModuleSettings)
  .factory('searchService', SearchService)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('crm.search', {
          url: '/search',
          abstract: true,
        })
        .state('crm.search.customers', {
          url: '/customers/:searchString',
          component: 'prfSearchCustomersResultsTable',
        })
        .state('crm.search.tradingAccounts', {
          url: '/tradingAccounts/:searchString',
          component: 'prfSearchTradingAccountsResultsTable',
        });
    },
  ]).name;
