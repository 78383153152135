import * as _ from '@proftit/lodash';
import { ContainerBean, BeanValidationResult } from '@proftit/proxy-bean';

export const FTD_AUTO_ASSIGNMENTS_UNIQUE_BY_SOURCE_DESK =
  'FTD_AUTO_ASSIGNMENTS_UNIQUE_BY_SOURCE_DESK';

export function ftdAutoAssignmentsUniquebySourceDeskProxyValidator(
  bean: ContainerBean,
): BeanValidationResult {
  const desksHash = bean.proxy.reduce((desksHash, ftdRule) => {
    if (_.isNil(ftdRule.sourceDesk)) {
      return desksHash;
    }

    if (!_.has([ftdRule.sourceDesk.id], desksHash)) {
      desksHash[ftdRule.sourceDesk.id] = {
        count: 0,
        desk: ftdRule.sourceDesk,
      };
    }

    desksHash[ftdRule.sourceDesk.id].count += 1;

    return desksHash;
  }, {});

  const multiDesk = _.flow([
    () => _.toPairs(desksHash),
    (pairs) => pairs.find(([_deskId, { count }]) => count > 1),
    (found) => (_.isNil(found) ? null : found[1].desk),
  ])();

  const isValid = _.isNil(multiDesk) ? true : false;

  return {
    isValid,
    code: FTD_AUTO_ASSIGNMENTS_UNIQUE_BY_SOURCE_DESK,
    payload: {
      desk: multiDesk,
    },
  };
}
