import template from './notifications-single-custom-email.component.html';
const styles = require('./notifications-single-custom-email.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class NotificationsSingleCustomEmailController {
  email: string;
  disabled: boolean;
  onChange: (a: { email: string }) => void;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  get emailField() {
    return this.email;
  }

  set emailField(email: string) {
    this.onChange({ email });
  }

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const NotificationsSingleCustomEmailComponent = {
  template,
  controller: NotificationsSingleCustomEmailController,
  bindings: {
    disabled: '<',
    email: '<',
    onChange: '&',
  },
};
