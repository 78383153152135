import { IWindowService } from 'angular';
import { Base64 } from 'js-base64';
import { ContentTemplate, Brand } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import BaseService from '~/source/common/services/baseService';
import { TemplatePlaceholdersService } from '~/source/common/api-crm-server/services/template-placeholders.service';

const CONTENT_TAG = '%template%';

const TAGS_DEMO_DATA = {
  account_id: '1234567891',
  agent_name: 'John Smith',
  amount_basic: '1234',
  balance_amount_basic: '1234',
  bonus_amount: '12',
  bonus_status: 'approved',
  brand_compliance_email: 'john.smith@example.com',
  brand_deposit_confirmation_url: 'https://deposit-confirm.exmaple.com',
  brand_email: 'https://brand.exmaple.com',
  brand_logo:
    '<img src="https://storage.googleapis.com/production-proftit-uploads/2018-11-28-5bfe93fc9b322.JPG" />',
  brand_logo_url:
    'https://storage.googleapis.com/production-proftit-uploads/2018-11-28-5bfe93fc9b322.JPG',
  brand_name: 'Example Brand',
  brand_website_address: 'https://brand.example.com',
  card_type: 'regular',
  cid: '1234',
  clearing_company: 'Example Clearing',
  date_sent: 'data sent',
  deposit_amount: '1234',
  deposit_file_missing: 'missing file',
  deposit_status: 'approved',
  document_name: 'document name',
  document_status: 'document status',
  email: 'email@example.com',
  exchange_rate: '',
  first_name: 'Hellen',
  ftd_date: '2019-01-02',
  last_deposit_amount: '123',
  last_deposit_date: '2019-01-02',
  last_four_digits_of_cc: '1234',
  last_name: 'Smith',
  last_withdrawal_amount: '1234',
  last_withdrawal_date: '2019-01-02',
  net_deposit_basic: '1234',
  password_reset_url: 'https://reset-password.example.com',
  payment_method: 'credit',
  phone_number: '123-1234-12345',
  registration_date: '2019-01-02',
  requested_amount: '1234',
  requested_withdrawal_amount: '1234',
  source_trading_account_name: 'account name',
  total_bonus_basic: '1234j',
  total_credit_basic: '1234j',
  total_deposit_basic: '1234',
  total_pnl_basic: '12t34',
  total_withdrawal_basic: '1234',
  transfer_amount: '1234',
  transfer_status: 'pending',
  transfer_trading_account_name: 'account name',
  used_margin_basic: '1234',
  withdrawal_amount: '1234',
  withdrawal_status: 'approved',
};

export class EmailTemplatePreviewService extends BaseService {
  static $inject = ['templatePlaceholdersService'];

  templatePlaceholdersService: () => TemplatePlaceholdersService;

  generateEmailPreview(
    contentTemplate: Partial<ContentTemplate>,
    brand?: Brand,
  ) {
    const content = Base64.decode(contentTemplate.content);
    const design = Base64.decode(contentTemplate.designTemplate.content);

    return this.templatePlaceholdersService()
      .getAll()
      .then((tags) => {
        const preview = _.flow([
          () =>
            tags.reduce((newContent, tag) => {
              const replaceValue = this.calcReplaceValue(tag, brand);

              return newContent.replace(
                new RegExp(`%${tag.code}%`, 'g'),
                replaceValue,
              );
            }, content),
          (newContent) => design.replace(CONTENT_TAG, newContent),
        ])();

        return preview;
      });
  }

  calcReplaceValue(tag, brand) {
    if (tag.code === 'brand_logo_url') {
      return _.get(['logo', 'url'], brand);
    }

    if (tag.code === 'brand_logo') {
      const url = _.get(['logo', 'url'], brand);
      return `<img src="${url}" />`;
    }

    return TAGS_DEMO_DATA[tag.code];
  }
}
