import * as _ from '@proftit/lodash';
import BaseService from '~/source/common/services/baseService';
import CacheService from '~/source/common/services/cache.service';
import ModalInfo from '~/source/common/models/modal-info';
import ModalOpenState from '~/source/common/models/modal-open-state';

/**
 * Cache service for modals data.
 */
export class ModalsCacheService extends BaseService {
  static cacheNamespace = 'modal-windows';

  static $inject = ['cacheService'];

  cacheService: CacheService;

  constructor(...args) {
    super(...args);

    this.cacheService.initializeSafe(ModalsCacheService.cacheNamespace, {
      storageMode: 'sessionStorage',
    });
  }

  /**
   * Save modal info into the cache.
   *
   * @param {string} cacheId - cache id to be used in the cache.
   * @param {ModalInfo} data - modal info to save in the cache.
   */
  put(cacheId: string, data: ModalInfo): void {
    return this.cacheService.put(
      ModalsCacheService.cacheNamespace,
      cacheId,
      data,
    );
  }

  /**
   * Get modal info from the cache.
   *
   * @param {string} cacheId - the id of the cache instance.
   * @return {(ModalInfo|null)} existing modal info in the cache, or null if not exists.
   */
  get(cacheId: string): ModalInfo | null {
    return this.cacheService.get(ModalsCacheService.cacheNamespace, cacheId);
  }

  /**
   * Set openState field on existing modal info in the cache.
   *
   * @param {string} cacheId - cache identifier.
   * @param {ModalOpenState} openState - openState value to set on the cache instance.
   * @returns {void}
   */
  setOpenState(cacheId: string, openState: ModalOpenState): void {
    const modal = this.getCreate(cacheId);
    modal.openState = openState;
    this.put(cacheId, modal);
  }

  /**
   * Get existing cache item or create it if not existing.
   *
   * @param {string} cacheId - cache identifier.
   * @param {object} args - additonal arguments for creating/getting the cache.
   *   - createDefaults: defaults to put on the newly created object when not exist.
   * @returns {ModalInfo} existing modal info or newly created one.
   */
  getCreate(
    cacheId: string,
    args: { createDefaults?: Partial<ModalInfo> } = {},
  ): ModalInfo {
    const modal: ModalInfo = this.get(cacheId);
    if (_.isNil(modal)) {
      return this.create(cacheId, args.createDefaults);
    }

    return modal;
  }

  /**
   * Create cache item.
   *
   * @param {string} cacheId - cache identifier.
   * @param {ModalInfo} defaults - values to put on the newly created object.
   * @returns {ModalInfo} existing modal info or newly created one.
   */
  create(cacheId: string, defaults: Partial<ModalInfo> = {}): ModalInfo {
    const modal = {
      ...ModalInfo.create(),
      ...defaults,
    };

    this.put(cacheId, modal);
    return modal;
  }
}

export default ModalsCacheService;
