import template from './timeline.component.html';

const styles = require('./timeline.component.scss');

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { PlayerState } from '../player-state';

function convertCurrentTimePositionToThumbStyleObject(
  currentTimePosition,
  duration,
) {
  const left = (currentTimePosition / duration) * 100;
  return { left: `${left}%` };
}

function convertSecondsToHHMMSS(secondsSum) {
  if (secondsSum === 0) {
    return 0;
  }
  const sec_num = parseInt(secondsSum, 10); // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;
  const hours_str = hours < 10 ? `0${hours}` : hours;
  const minutes_str = minutes < 10 ? `0${minutes}` : minutes;
  const seconds_str = seconds < 10 ? `0${seconds}` : seconds;
  if (hours_str > 0) {
    return `${hours_str}:${minutes_str}:${seconds_str}`;
  }

  return `${minutes_str}:${seconds_str}`;
}

export class TimelineController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  PlayerState = PlayerState;
  duration: number;
  onTimelineClick: (a: { desiredSeek: number }) => void;

  currentTimePosition$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'currentTimePosition',
  );

  duration$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'duration',
  );

  playStatus$ = observeShareCompChange<PlayerState>(
    this.lifecycles.onChanges$,
    'playStatus',
  );

  thumbPosition$ = this.streamThumbPosition(); // {left: 50%}
  currentTimePositionHHMMSS$ = this.streamCurrentTimePositionHHMMSS();
  durationHHMMSS$ = this.streamDurationHHMMSS();

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamThumbPosition() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.currentTimePosition$, this.duration$),
      rx.map(([currentTimePosition, duration]) =>
        convertCurrentTimePositionToThumbStyleObject(
          currentTimePosition,
          duration,
        ),
      ),
      rx.startWith({ left: `${0}%` }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCurrentTimePositionHHMMSS() {
    return rx.pipe(
      () => this.currentTimePosition$,
      rx.map((currentTimePosition) =>
        convertSecondsToHHMMSS(currentTimePosition),
      ),
      rx.startWith(0),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamDurationHHMMSS() {
    return rx.pipe(
      () => this.duration$,
      rx.map((duration) => {
        if (duration) {
          return convertSecondsToHHMMSS(duration);
        }
        return '--:--';
      }),
      rx.startWith('--:--'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  whenTimelineClick($event) {
    const desiredCurrentTimePosition =
      ($event.offsetX / $event.target.clientWidth) * this.duration;

    this.onTimelineClick({ desiredSeek: desiredCurrentTimePosition });
  }
}

export const TimelineComponent = {
  template,
  bindings: {
    onTimelineClick: '&',
    duration: '<',
    currentTimePosition: '<',
    playStatus: '<',
  },
  controller: TimelineController,
};
