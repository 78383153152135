import {
  QuestionnaireScore,
  QuestionnaireForm,
  QuestionnaireComponent,
  QuestionnaireComponentScore,
} from '@proftit/crm.api.models.entities';
import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';
import { switchOn } from '@proftit/general-utilities';
import * as _ from '@proftit/lodash';
import { ComponentScore } from '../../questionnaire-score-scorings-form/models/component-score';

export function createScoreStructureFromQuestion(
  question: QuestionnaireComponent,
): ComponentScore {
  const translationsMap = new Map<string, string>();
  const scoreData = switchOn(
    {
      [QuestionnaireComponentTypeCode.SingleSelect]: () => {
        return question.options.reduce((acc, option) => {
          const { translation_guid } = option;
          translationsMap.set(translation_guid, option.text);

          return {
            ...acc,
            [translation_guid]: {
              score: null,
              failQuestionnaire: false,
            },
          };
        }, {});
      },
      [QuestionnaireComponentTypeCode.MultiSelect]: () => {
        return question.options.reduce((acc, option) => {
          const { translation_guid } = option;
          translationsMap.set(translation_guid, option.text);

          return {
            ...acc,
            [translation_guid]: {
              score: null,
              failQuestionnaire: false,
            },
          };
        }, {});
      },
      [QuestionnaireComponentTypeCode.BooleanQuestion]: () => {
        return {
          yes: {
            score: null,
            failQuestionnaire: false,
          },
          no: {
            score: null,
            failQuestionnaire: false,
          },
        };
      },
      [QuestionnaireComponentTypeCode.Confirm]: () => {
        return question.options.reduce((acc, option) => {
          const { translation_guid } = option;
          translationsMap.set(translation_guid, option.text);

          return {
            ...acc,
            [translation_guid]: {
              score: null,
              failQuestionnaire: false,
            },
          };
        }, {});
      },
    },
    question.type,
    () => {
      throw new Error('unhandled question type');
    },
  );

  return {
    scoreData,
    maxQuestionScore: null,
    questionnaireComponentsId: question.id,
    _questionInfo: {
      translationsMap,
      id: question.id,
      type: question.type,
      content: _.get(['text'], question),
    },
  };
}
