import _ from 'underscore';

import SocketService from '~/source/common/services/socket';
import CustomerStatuses from '../services/customer-statuses.service';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import { Customer } from '@proftit/crm.api.models.entities';
import CustomerComplianceStatusesService from '~/source/contact/common/services/customer-compliance-statuses.service';
import CustomerComplianceStatus from '~/source/common/models/customer-compliance-status';
import DesksService from '~/source/management/brand/services/desks';

import * as _l from '@proftit/lodash';
import UsersService from '~/source/management/user/services/users';

export class CustomersSocket extends SocketService {
  static $inject = [
    'usersService',
    'desksService',
    'customerStatusesService',
    'customerComplianceStatusesService',
    ...SocketService.$inject,
  ];

  usersService: () => UsersService;
  desksService: () => DesksService;
  customerStatusesService: () => CustomerStatuses;
  customerComplianceStatusesService: () => CustomerComplianceStatusesService;

  get channelRoot() {
    return 'Customer';
  }

  get whiteList() {
    return [
      'countryPrefix',
      'countryPrefix2',
      'userId',
      'isOnline',
      'phone',
      'phone2',
      'firstName',
      'lastName',
      'customerComplianceStatusId',
      'customerStatusId',
      'lastCallDate',
      'lastFailedDepositDate',
      'lastCommunicationDetails',
      'equityTotal',
      'deskId',
      'isActive',
      'swapTotal',
    ];
  }

  /**
   * register streamer data parser on customerStatusId
   * transform customerStatusId field into customerStatus object
   * example: 1 => {id:1, code:"status code", name: "status name"}
   * @param {object} data
   * @returns {Promise}
   */
  parseCustomerStatusId(data) {
    if (data.customerStatusId !== undefined) {
      return (
        this.customerStatusesService()
          // this is a background operation. don't block or show errors
          .setConfig({ suppressGrowl: true, suppressBlockUi: true })
          .getListWithQuery()
          .then((customerData) => {
            const customerStatus = _.findWhere(customerData.plain(), {
              id: data.customerStatusId,
            });
            data.customerStatus = Object.assign({}, customerStatus);

            return data;
          })
      );
    }

    return Promise.resolve();
  }

  savedUser = [];

  /**
   * register streamer data parser on userId
   * transform null userId into an empty user object.
   * this will update customer table on un-assign operations.
   * example: userId: null => user: {}
   * @param {object} data
   * @returns {Promise}
   */
  parseUserId(data) {
    if (_l.isNil(data.userId)) {
      data.user = {};
      return Promise.resolve(data);
    }

    const savedUserKey = `user_${data.userId}`;
    if (this.savedUser[savedUserKey]) {
      data.user = this.savedUser[savedUserKey];
      return Promise.resolve(data);
    }

    return (
      this.usersService()
        // this is a background operation. don't block or show errors
        .setConfig({ suppressGrowl: true, suppressBlockUi: true })
        .getOneWithQuery(data.userId)
        .then((data) => data.plain())
        .then((userData) => {
          data.user = Object.assign({}, userData);
          this.savedUser[savedUserKey] = userData;
          return data;
        })
    );
  }

  /**
   * register streamer data parser on customerComplianceStatusId
   * transform customerComplianceStatusId field into customerComplianceStatus data object
   * example: 1 => {id:1, code:"status code", name: "status name"}
   * @param {object} data
   * @returns {Promise}
   */
  parseCustomerComplianceStatusId(data) {
    if (data.customerComplianceStatusId !== undefined) {
      return (
        this.customerComplianceStatusesService()
          // this is a background operation. don't block or show errors
          .setConfig({ suppressGrowl: true, suppressBlockUi: true })
          .getListWithQuery()
          .then(
            (verifiedStatuses: ICollectionRestNg<CustomerComplianceStatus>) => {
              const status = _.findWhere(verifiedStatuses.plain(), {
                id: data.customerComplianceStatusId,
              });
              data.customerComplianceStatus = Object.assign({}, status);
              return data;
            },
          )
      );
    }

    return Promise.resolve();
  }

  /**
   * register streamer data parser on customerDeskId
   * transform customerDeskId field into customerDesk data object
   * example: 1 => {id:1, name:"desk code"}
   * @param {object} data
   * @returns {Promise}
   */
  parseDeskId(data) {
    if (_l.isNil(data.deskId)) {
      data.desk = null;

      return Promise.resolve();
    }

    return (
      this.desksService()
        // this is a background operation. don't block or show errors
        .setConfig({ suppressGrowl: true, suppressBlockUi: true })
        .getOneWithQuery(data.deskId)
        .then((data) => data.plain())
        .then((deskData) => {
          data.desk = Object.assign({}, deskData);

          return data;
        })
    );
  }
}

export default CustomersSocket;
