import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import IElementRestNg from '../../models/ielement-rest-ng';
import { TemplatePlaceholder } from '@proftit/crm.api.models.entities';
import { IPromise } from 'angular';
import * as _ from '@proftit/lodash';

export class TemplatePlaceholdersService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'templatePlaceholders';
  }

  /**
   * Resource access type: user/customer/public.
   * Will be used to determine which api prefix to use.
   *
   * The default is defined here, but can be overriden by subclasses.
   * @returns {string}
   */
  get accessType(): string {
    return 'public';
  }

  /**
   * Get all template placeholders
   *
   * @return template placeholder list
   */
  getAll() {
    return this.expand(['type'])
      .getListWithQuery<IElementRestNg<TemplatePlaceholder>>()
      .then((data) => data.plain());
  }

  /**
   * Get by types.
   *
   * Get all placeholder meet the condition of provided types.
   *
   * @param types
   * @returns list of placeholders.
   */
  getByTypes(types: string[]): Promise<TemplatePlaceholder[]> {
    return this.getAll().then((placeholders) => {
      const typesWithoutNil = types.filter((t) => !_.isNil(t));
      const hasNil = types.includes(null);

      return _.flow([
        () => [],
        (list) =>
          hasNil
            ? [...list, ...placeholders.filter((p) => _.isNil(p.type))]
            : list,
        (list) => {
          const filteredPlaceholders = placeholders.filter((p) => {
            const typeCode = _.get(['type', 'code'], p);
            return typesWithoutNil.includes(typeCode);
          });
          return [...list, ...filteredPlaceholders];
        },
      ])();
    });
  }
}
