import ng from 'angular';
import { InternalTransfersSocketService } from './services/internal-transfers-socket.service';
import { InternalTransfersTableComponent } from './containers/internal-transfers-table/internal-transfers-table.component';
import { InternalTransfersTableHeaderComponent } from './components/internal-transfers-table-header/internal-transfers-table-header.component';
import { InternalTransferTablePopupComponent } from './containers/internal-transfer-table-popup/internal-transfer-table-popup.component';
import { AddInternalTransferPopupComponent } from './containers/add-internal-transfer-popup/add-internal-transfer-popup.component';
import { InternalTransferFormComponent } from './components/internal-transfer-form/internal-transfer-form.component';
import { AccountSearchSelectComponent } from './containers/account-search-select/account-search-select.component';
import { AccountSearchResultComponent } from './containers/account-search-result/account-search-result.component';
import { CurrencyToCurrencyBoxComponent } from './components/currency-to-currency-box/currency-to-currency-box.component';
import { InternalTransferDetailsRowComponent } from './components/internal-transfer-details-row/internal-transfer-details-row.component';

const dependencies = [];

export const InternalTransferModule = ng.module(
  'prf.internal_transfer',
  dependencies,
);

InternalTransferModule.service(
  'prfInternalTransfersSocketService',
  InternalTransfersSocketService,
)
  .component('prfInternalTransfersTable', InternalTransfersTableComponent)
  .component(
    'prfInternalTransfersTableHeader',
    InternalTransfersTableHeaderComponent,
  )
  .component(
    'prfInternalTransferTablePopup',
    InternalTransferTablePopupComponent,
  )
  .component('prfAddInternalTransferPopup', AddInternalTransferPopupComponent)
  .component('prfInternalTransferForm', InternalTransferFormComponent)
  .component('prfAccountSearchSelect', AccountSearchSelectComponent)
  .component('prfAccountSearchResult', AccountSearchResultComponent)
  .component('prfCurrencyToCurrencyBox', CurrencyToCurrencyBoxComponent)
  .component(
    'prfInternalTransferDetailsRow',
    InternalTransferDetailsRowComponent,
  );
