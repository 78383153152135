import ng, { ITimeoutService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './site-tracking-activity-manager.component.html';
import CustomersService from '~/source/contact/common/services/customers';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import TokensService from '~/source/auth/services/tokens';
import { TimeInterval } from '@proftit/constants.time';
import { CustomerSiteTrackingLocation } from '@proftit/crm.api.models.entities';
import {
  customerLocationsKeepaliveChannelName,
  customerLocationsNewChannelName,
} from '@proftit/crm.api.channels';
import CustomerSiteLocationTrackingSocket from '~/source/contact/common/services/customer-site-location-tracking-socket';
import CustomerSiteLocationKeepaliveSocket from '~/source/contact/common/services/customer-site-location-keepalive-socket';
const styles = require('./site-tracking-activity-manager.component.scss');

const MAX_AMOUNT_OF_VISIBLE_LOCATIONS = 6;
const KEEPALIVE_TIMEOUT = TimeInterval.Minute * 6;

export class SiteTrackingActivityManagerController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  customersServiceInst: CustomersService;
  customerId: number;

  customerId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'customerId',
  );

  isCustomerOnline$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'isCustomerOnline',
  );

  lastActivityLocationsFromCustomerChange$ = this.streamLastActivityLocationsFromCustomerChange();
  lastActivityLocations$ = this.streamLastActivityLocations();

  /* @ngInject */
  constructor(
    readonly $timeout: ITimeoutService,
    readonly customersService: () => CustomersService,
    readonly customerSiteLocationTrackingSocketService: CustomerSiteLocationTrackingSocket,
    readonly customerSiteLocationKeepaliveSocketService: CustomerSiteLocationKeepaliveSocket,
    readonly tokensService: TokensService,
  ) {
    this.customersServiceInst = this.customersService();

    useStreams(
      [this.customerId$, this.isCustomerOnline$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.lastActivityLocations$,
        this.lastActivityLocationsFromCustomerChange$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamLastActivityLocations() {
    const lastLocations = new rx.BehaviorSubject<
      CustomerSiteTrackingLocation[]
    >([]);
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.switchMap(() =>
        rx.obs.merge(
          this.lastActivityLocationsFromCustomerChange$,
          this.streamLastActivityLocationsFromNewStreamerLocation(
            lastLocations,
          ),
          observeChannel(
            this.customerSiteLocationKeepaliveSocketService,
            customerLocationsKeepaliveChannelName(
              Number(this.tokensService.getCachedUser().id),
              this.customerId,
            ),
          ).pipe(
            rx.startWith(0),
            rx.debounceTime(KEEPALIVE_TIMEOUT),
            rx.map(() => []),
          ),
        ),
      ),
      rx.map((locations) => _.orderBy(['createdAt'], ['desc'], locations)),
      rx.map((locations) => {
        if (locations.length > MAX_AMOUNT_OF_VISIBLE_LOCATIONS) {
          return locations.slice(0, MAX_AMOUNT_OF_VISIBLE_LOCATIONS);
        }
        return locations;
      }),
      rx.tap((newArray: CustomerSiteTrackingLocation[]) => {
        lastLocations.next(newArray);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLastActivityLocationsFromCustomerChange() {
    return rx.pipe(
      () => this.customerId$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((customerId: number) => {
        return rx.obs
          .from(
            this.customersServiceInst.getLastSiteTrackingLocations(customerId),
          )
          .pipe(rx.catchError((e) => rx.obs.from([])));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLastActivityLocationsFromNewStreamerLocation(
    lastLocations: rx.Observable<CustomerSiteTrackingLocation[]>,
  ) {
    return rx.pipe(
      () =>
        observeChannel(
          this.customerSiteLocationTrackingSocketService,
          customerLocationsNewChannelName(
            Number(this.tokensService.getCachedUser().id),
            this.customerId,
          ),
        ),
      rx.withLatestFrom(lastLocations),
      rx.map(([newLocation, previousLocations]) => [
        newLocation,
        ...previousLocations,
      ]),
      shareReplayRefOne(),
    )(null);
  }
}

export const SiteTrackingActivityManagerComponent = {
  template,
  controller: SiteTrackingActivityManagerController,
  bindings: {
    customerId: '<',
    isCustomerOnline: '<',
  },
};
