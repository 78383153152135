import { permissionsCrud } from '../permissions-crud';

function permissionRunConf(
  PermPermissionStore,
  authenticationService,
  tokensService,
) {
  PermPermissionStore.definePermission('loggedIn', () =>
    tokensService.isLoggedIn(),
  );

  authenticationService.addCrudPermissions(permissionsCrud);
}

permissionRunConf.$inject = [
  'PermPermissionStore',
  'authenticationService',
  'tokensService',
];

export default permissionRunConf;
