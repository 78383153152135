import * as _ from '@proftit/lodash';

/**
 * Filter factory. Return filter to do start case conversion.
 *
 * @returns {filter} filter instance function
 */
export function startCaseFilterFactory() {
  /**
   * Filter function. Convert string to start case.
   *
   * @param {string} str - string.
   * @returns {string} converted string.
   *
   * @example
   *
   *    {{ 'hello there' | prfStartCase }} === Hello There
   */
  function filter(str) {
    return _.startCase(str.toLowerCase());
  }

  return filter;
}

export default startCaseFilterFactory;
