import _ from 'underscore';
import template from './manual-deposit.html';
import TransactionController from '../../transaction-base.controller';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';
import { DoneFlow } from '../add-transaction-popup/done-flow';
import { ExcludeBy } from '~/source/common/components/dropdowns/base/component';

const DEPOSIT_SAFEGUARD_TIME = 300;

class ManualDepositController extends TransactionController {
  static $inject = [
    'modelNormalizer',
    'depositsSettings',
    ...TransactionController.$inject,
  ];
  ExcludeBy = ExcludeBy;
  lifecycles = observeComponentLifecycles(this);

  depositsSettings;
  modelNormalizer: ModelNormalizerService;
  manualDeposit;

  makeDepositAction = new rx.Subject<void>();

  constructor(...args) {
    super(...args);

    useStreams([this.streamMakeDeposit()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    Object.assign(this, {
      manualDeposit: {
        creditCard: {
          statusCode: 'external',
          number: null,
        },
      },
    });

    this.blockUiInstance = this.blockUI.instances.get('manualDepositBlock');
  }

  $onDestroy() {}

  /**
   * Normalize the deposit object and post it to server.
   */
  streamMakeDeposit() {
    return rx.pipe(
      () => this.makeDepositAction,
      rx.debounceTime(DEPOSIT_SAFEGUARD_TIME),
      rx.tap(() => {
        // normalize deposit object
        const normalizedDeposit = this.modelNormalizer.normalize(
          _.omit(this.manualDeposit, 'transactionStatus'),
        );
        normalizedDeposit.transactionStatusCode = this.manualDeposit.transactionStatus.code;
        normalizedDeposit.transferMethodTypeCode = this.depositType.code;
        normalizedDeposit.transferTransactionCC = {
          clearingCompanyId: normalizedDeposit.clearingCompanyId,
        };
        delete normalizedDeposit.clearingCompanyId;

        return this.customersService()
          .setConfig({ growlRef: 'transactionInfo' })
          .addDeposit(this.customer.id, this.account.id, normalizedDeposit)
          .then((deposit) => {
            /*
             * emit event that will eventually will cause to
             * deposits table to reload data from api
             */
            this.$scope.$emit(this.reloadTableEvent);
            // close the popup. this should be set to the bind of the directive
            this.onDone({ next: DoneFlow.ClosePopup });

            return deposit;
          });
      }),
    )(null);
  }

  filterDepositStatuses(statusList) {
    return statusList.filter(
      (status) => status.code !== TradingAccountTransactionStatusCode.Deleted,
    );
  }

  /**
   * Returns deposit type
   *
   * @returns {String}
   */
  get depositType() {
    return this.depositsSettings.types.manual;
  }
}

export default {
  template,
  controller: ManualDepositController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '=',
    onDone: '&',
  },
};
