import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class CreditNotificationsController extends NotificationsController {
  static $inject = ['creditsSocketService', ...NotificationsController.$inject];
}

export default {
  ...ComponentOptions,
  controller: CreditNotificationsController,
};
