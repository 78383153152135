import * as _ from '@proftit/lodash';

export function sortNestedGroupedNotificationSettingTypes(
  settingTypesGrouped,
  uiSortOrder,
) {
  return _.flow(
    () => Object.keys(settingTypesGrouped),
    (categoryKeys) =>
      categoryKeys.sort((a, b) => {
        const aIndex = uiSortOrder.findIndex(
          (uiCategory) => uiCategory.category === a,
        );
        const bIndex = uiSortOrder.findIndex(
          (uiCategory) => uiCategory.category === b,
        );

        return aIndex - bIndex;
      }),
    (sortedCategoryKeys) =>
      sortedCategoryKeys.reduce((acc, key) => {
        const categorySetting = settingTypesGrouped[key];
        const uiCategory = uiSortOrder.find(
          (uiCategory) => uiCategory.category === key,
        ).children;
        const sortedCategorySetting = categorySetting.sort((a, b) => {
          const aIndex = uiCategory.findIndex(
            (uiSettingCode) => uiSettingCode === a.code,
          );
          const bIndex = uiCategory.findIndex(
            (uiSettingCode) => uiSettingCode === b.code,
          );

          return aIndex - bIndex;
        });

        return {
          ...acc,
          [key]: sortedCategorySetting,
        };
      }, {}),
  )();
}
