export enum UserDepartmentCodeCode {
  SystemAndAdmins = 'sysadmin',
  GeneralPurpose = 'general',
  SalesConversion = 'salesconv',
  SalesRetention = 'salesrete',
  SalesConversionAndRetention = 'salesconvrete',
  BackOffice = 'backoffice',
  Marketing = 'marketing',
  Accounting = 'accounting',
  Management = 'management',
  Support = 'support',
  RnD = 'rnd',
  DemoAccounts = 'demo',
}
