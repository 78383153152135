import ng from 'angular';

import * as _ from '@proftit/lodash';

const directiveName = 'prfFocusOnClick';

/**
 *
 */
export const focusOnClickDirective = ($parse) => {
  return {
    restrict: 'A',
    link: (scope, element, attrs, ngModelCtrl) => {
      let targetClassId = null;

      attrs.$observe(directiveName, (val) => {
        targetClassId = $parse(attrs[directiveName])(scope);
      });

      element.bind('click', () => {
        if (_.isEmpty(targetClassId)) {
          return;
        }

        ng.element(document.querySelector(`.${targetClassId} input`)).focus();
      });
    },
  };
};

focusOnClickDirective.$inject = ['$parse'];
