import ng from 'angular';

import settings from './ip-list-settings.json';
import editGroupIpComponent from './edit-group-ip/edit-group-ip.component';
import ipListComponent from './ip-list.component';

const ngModule = ng
  .module('crm.management.ipList', ['crm.constants', 'crm.common', 'ui.router'])
  .component('prfEditGroupIp', editGroupIpComponent)
  .component('prfGroupIpList', ipListComponent)
  .constant('ipListSettings', settings)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('crm.management.permissions.ip-list', {
          url: '/ip-list',
          template: '<ui-view/>',
          redirectTo: 'crm.management.permissions.ip-list.manage',
        })
        .state('crm.management.permissions.ip-list.manage', {
          url: '/manage',
          component: 'prfGroupIpList',
          data: {
            permissions: {
              only: ['management.iplist'],
              redirectTo: 'error.unauthorized',
            },
          },
        });
    },
  ]);

export default ngModule.name;
