import Component from '~/source/common/components/dropdowns/base/component';
import { fetchAllWithPaging } from '~/source/common/utilities/fetch-all-with-paging';
import { ItemLabelStrategy } from '~/source/common/components/dropdowns/base/item-label-strategy';
const PAGE_FETCH_SIZE = 500;

class ContactsImportSelectController extends Component.controller {
  static $inject = [
    'prfContactsImportsService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'contactsImport.SELECT_PROCESS';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return fetchAllWithPaging(
      PAGE_FETCH_SIZE,
      (page, size) => this.queryPageStep(page, size),
      () => this.dataServiceInst.total,
    );
  }

  queryPageStep(page: number, size: number) {
    return this.dataServiceInst
      .setPage(page, size)
      .expand(['resource'])
      .getListWithQuery();
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return `${item.id} - ${item.resource.name}`;
  }
}

export const ContactsImportSelectComponent = Component.config({
  controller: ContactsImportSelectController,
});
