export enum SystemEmailTypeCode {
  DepositConfirmation = 'deposit_confirmation',
  RegistrationConfirmation = 'registration_confirmation',
  PasswordReset = 'password_reset',
  DepositDeclined = 'deposit_declined',
  BonusGiven = 'bonus_given',
  WithdrawalRequest = 'withdrawal_request',
  WithdrawalApproval = 'withdrawal_approval',
  WithdrawalDeclined = 'withdrawal_declined',
  DocumentsApproval = 'documents_approval',
  DocumentsDeclined = 'documents_declined',
  InternalTransfer = 'internal_transfer',
}
