import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './crypto-ewallet-list.component.html';
import { CryptoEwalletListTypes } from './crypto-ewallet-list-types';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import ModalService from '~/source/common/components/modal/modal.service';
import { reorderDropdownItems } from '~/source/management/crypto-ewallet/helper-functions/reorder-items';
import { Brand } from '@proftit/crm.api.models.entities';
import BrandsService from '~/source/management/brand/services/brands';

const styles = require('./crypto-ewallet-list.component.scss');

export class CryptoEwalletListController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  brand: Brand;
  brand$ = observeShareCompChange(this.lifecycles.onChanges$, 'brand');
  reloadEwalletsAction: rx.Subject<void>;

  CryptoEwalletListTypes = CryptoEwalletListTypes;

  onFormItemDroppedInActiveListAction = new rx.Subject<any>();
  onFormItemDroppedInDisabledListAction = new rx.Subject<any>();
  reorderActiveEwalletsAction = new rx.Subject<any>();
  disableEwalletAction = new rx.Subject<any>();

  ewalletsProp$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'ewallets',
  );

  ewallets$ = this.streamEwallets();

  activeEwallets$ = this.streamActiveEwallets();
  disabledEwallets$ = this.streamDisabledEwallets();

  /* @ngInject */
  constructor(
    readonly modalService: ModalService,
    readonly brandsService: () => BrandsService,
  ) {
    useStreams(
      [
        this.brand$,
        this.ewallets$,
        this.activeEwallets$,
        this.disabledEwallets$,
        this.streamOpenReorderApprovalDialog(),
        this.streamOnFormItemDroppedInDisabledList(),
        this.streamDisableEwallet(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamEwallets(): rx.Observable<any> {
    return rx.pipe(
      () => rx.obs.merge(this.ewalletsProp$, this.streamEwalletsFromReorder()),
      rx.map((ewallets) =>
        _.sortBy((ewallet) => ewallet.order, ewallets as any),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveEwallets() {
    return rx.pipe(
      () => this.ewallets$,
      rx.filter((ewallets) => !_.isNil(ewallets)),
      rx.map((ewallets) => ewallets.filter((e) => e.isActive === true)),
      rx.map((activeEwallets) =>
        _.sortBy((ewallet) => ewallet.order, activeEwallets),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamDisabledEwallets() {
    return rx.pipe(
      () => this.ewallets$,
      rx.filter((ewallets) => !_.isNil(ewallets)),
      rx.map((ewallets) => ewallets.filter((e) => e.isActive === false)),
      shareReplayRefOne(),
    )(null);
  }

  streamOpenReorderApprovalDialog() {
    return rx.pipe(
      () => this.onFormItemDroppedInActiveListAction,
      rx.withLatestFrom(this.ewallets$),
      rx.switchMap(([data, ewallets]) => {
        const { item } = data;
        const previousIndex = ewallets.findIndex(
          (ewallet) => ewallet.id === item.id,
        );
        const combinedData = {
          ...data,
          previousIndex,
        };
        return (this.openApprovalDialog(
          combinedData,
          'ewallet.CHANGE_EWALLET_ORDER_WARNING',
        )
          .result.then(() => {
            return {
              ewallets,
              data: combinedData,
              modalConfirmed: true,
            };
          })
          .catch(() => {
            return {
              ewallets,
              modalConfirmed: false,
              data: null,
            };
          }) as any) as Promise<{
          modalConfirmed: boolean;
          data: any;
          ewallets: any;
        }>;
      }),
      rx.tap(({ modalConfirmed, data, ewallets }) => {
        if (modalConfirmed) {
          this.reorderActiveEwalletsAction.next({ data, ewallets });
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnFormItemDroppedInDisabledList() {
    return rx.pipe(
      () => this.onFormItemDroppedInDisabledListAction,
      rx.switchMap((data) => {
        const combinedData = {
          ...data,
          isActive: false,
        };
        return (this.openApprovalDialog(
          combinedData,
          'ewallet.DISABLE_EWALLET_WARNING',
        )
          .result.then(() => {
            return {
              data: combinedData,
              modalConfirmed: true,
            };
          })
          .catch(() => {
            return {
              modalConfirmed: false,
              data: null,
            };
          }) as any) as Promise<{ modalConfirmed: boolean; data: any }>;
      }),
      rx.tap(({ modalConfirmed, data }) => {
        if (modalConfirmed) {
          this.disableEwalletAction.next({ data });
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDisableEwallet() {
    return rx.pipe(
      () => this.disableEwalletAction,
      rx.withLatestFrom(this.brand$),
      rx.switchMap(([{ data }, brand]: [any, Brand]) => {
        return rx.obs
          .from(
            this.brandsService()
              .getBrandEwalletCurrenciesResource(brand.id, data.item.id)
              .patchWithQuery({
                isActive: false,
              }) as any,
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap(() => {
        this.reloadEwalletsAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEwalletsFromReorder() {
    return rx.pipe(
      () => this.reorderActiveEwalletsAction,
      rx.withLatestFrom(this.brand$),
      rx.switchMap(([action, brand]: [any, Brand]) => {
        const { data, ewallets } = action;
        const isActive = data.type === CryptoEwalletListTypes.ActiveEwallet;
        if (isActive) {
          return Promise.resolve({ data, ewallets });
        }
        return rx.obs
          .from(
            this.brandsService()
              .getBrandEwalletCurrenciesResource(brand.id, data.item.id)
              .patchWithQuery({
                isActive: true,
              })
              .then(() => {
                return { data, ewallets };
              }),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map(({ data, ewallets }) => {
        const { index, item, previousIndex } = data;
        return reorderDropdownItems(index, item, previousIndex, ewallets);
      }),
      rx.switchMap((sortedEwallets) => {
        const payload = sortedEwallets.map((ewallet) => {
          return {
            id: ewallet.id,
            order: ewallet.order,
            currencyId: ewallet.currency.id,
          };
        });
        return rx.obs
          .from(
            this.brandsService().patchBrandEwalletCurrenciesOrder(
              this.brand.id,
              payload,
            ),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  openApprovalDialog(data, message) {
    return this.modalService.open({
      component: 'prfEwalletsReorderApprovalDialog',
      resolve: {
        data,
        message: () => message,
      },
    });
  }
}

export const CryptoEwalletListComponent = {
  template,
  controller: CryptoEwalletListController,
  bindings: {
    brand: '<',
    ewallets: '<',
    reloadEwalletsAction: '<',
  },
};
