export const internalTransferTablePopupColumns = [
  {
    title: 'common.DATE',
    field: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: 'createdAt',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'internalTransfer.SOURCE_TRADING_ACCOUNT_ID',
    field: '',
    fieldFormat: '{{ sourceTradingAccount.id }}',
    sortable: 'sourceTradingAccountId',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'internalTransfer.DESTINATION_TRADING_ACCOUNT_ID',
    field: '',
    fieldFormat: '{{ destinationTradingAccount.id }}',
    sortable: 'destinationTradingAccountId',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'internalTransfer.AMOUNT',
    field: 'amount',
    fieldFormat:
      '{{ amount | currency: sourceTradingAccount.currency.code : sourceTradingAccount.currency.decimals }}',
    sortable: 'amount',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'internalTransfer.EXCHANGE_RATE',
    field: 'rate',
    fieldFormat: '{{ _rateAsFixed }}',
    sortable: 'rate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'internalTransfer.TRANSFER_AMOUNT',
    field: 'transferAmount',
    /* tslint:disable-next-line:max-line-length */
    fieldFormat:
      '{{ transferAmount | currency: destinationTradingAccount.currency.code : destinationTradingAccount.currency.decimals }}',
    sortable: 'tansferAmount',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'internalTransfer.PROCESSED_BY',
    field: 'user',
    fieldFormat: '{{ user.firstName}} {{ user.lastName }}',
    sortable: 'userId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'internalTransfer.STATUS',
    field: '',
    fieldFormat:
      '{{ "internalTransfer.statuses." + statusCode.toUpperCase() | translate }}',
    sortable: 'statusCode',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    isActionsColumn: true,
    title: '',
    _comment: 'This is empty col for control settings',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
