import { CustomerCommunication } from '@proftit/crm.api.models.entities';
import { EntityCode } from '@proftit/crm.api.models.enums';
import moment from 'moment-timezone';
import { CrmCalEvent } from '../full-calendar/types/crm-cal-event';

export function followupToCalEvent(comm: CustomerCommunication): CrmCalEvent {
  const { fontColor, backgroundColor } = calcColorForFollowup();
  const id = genFollowupId(comm);

  return {
    id,
    extendedProps: {
      entityId: comm.id,
      entityCode: EntityCode.CustomerCommunication,
      customerId: comm.customer.id,
    },
    title: `followup ${comm.customer.firstName} ${comm.customer.lastName}`,
    start: moment(comm.followUpDate).toISOString(),
    end: moment(comm.followUpDate).toISOString(),
    color: backgroundColor,
    textColor: fontColor,
  };
}

function calcColorForFollowup() {
  return { backgroundColor: '#3f51b5', fontColor: 'white' };
}

function genFollowupId(comm: CustomerCommunication) {
  return `followup-${comm.id}`;
}
