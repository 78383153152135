import template from './deposit-tooltip-data.component.html';
const style = require('./deposit-tooltip-data.component.scss');

class DepositTooltipDataController {
  styles = style;

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const DepositTooltipDataComponent = {
  template,
  controller: DepositTooltipDataController,
  bindings: {
    deposit: '<',
  },
};
