import template from './security-data.component.html';
const styles = require('./security-data.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class SecurityDataController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SecurityDataComponent = {
  template,
  controller: SecurityDataController,
  bindings: {
    onEnterEdit: '&',
    brand: '<',
  },
};
