export type MarketingCampaignRewardsSource = {
  label: string;
  type: MarketingCampaignReward;
};

export enum MarketingCampaignReward {
  Registration = 'automaticRegistrationRewards',
  DepositFtd = 'automaticDepositRewardsFtd',
  DepositNonFtd = 'automaticDepositRewardsNonFtd',
}

export enum MarketingCampaignRewardType {
  Credit = 'credit',
  Bonus = 'bonus',
}

export const marketingCampaignRewards: MarketingCampaignRewardsSource[] = [
  {
    label: 'Registration',
    type: MarketingCampaignReward.Registration,
  },
  {
    label: 'Deposit - FTD',
    type: MarketingCampaignReward.DepositFtd,
  },
  {
    label: 'Deposit - Non FTD',
    type: MarketingCampaignReward.DepositNonFtd,
  },
];
