import * as rx from '@proftit/rxjs';

export class Editable<T = any> {
  isEdit$ = new rx.BehaviorSubject<boolean>(false);
  editAction = new rx.Subject<void>();
  cancelEditAction = new rx.Subject<void>();
  saveAction = new rx.Subject<void>();
  saveIsDoneAction = new rx.Subject<void>();
  afterSaveAction = new rx.Subject<void>();

  initiator$ = this.streamInitiator();

  constructor() {}

  /**
   * Merges all streams of the class. This is assigned into the initiator$ property,
   * which should be passed to the useStreams() function, which will initiate all these streams.
   */
  streamInitiator() {
    return rx.pipe(() =>
      rx.obs.merge(
        this.streamEdit(),
        this.streamCancelEdit(),
        this.streamSaveIsDone(),
      ),
    )(null);
  }

  streamEdit() {
    return rx.pipe(
      () => this.editAction,
      rx.tap(() => {
        this.isEdit$.next(true);
      }),
    )(null);
  }

  streamCancelEdit() {
    return rx.pipe(
      () => this.cancelEditAction,
      rx.tap(() => {
        this.isEdit$.next(false);
      }),
    )(null);
  }

  streamSaveIsDone() {
    return rx.pipe(
      () => this.saveIsDoneAction,
      rx.tap(() => {
        this.isEdit$.next(false);
        this.afterSaveAction.next();
      }),
    )(null);
  }
}
