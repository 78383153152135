import template from './add-transaction-popup.html';
import BaseController from '~/source/common/controllers/base';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import depositsSettings from '../deposits-settings.json';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { getResolveChange } from '~/source/common/utilities/rx-ng-one/operators/get-resolve-change';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { CashierCode } from '@proftit/crm.api.models.enums';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { MessageType } from '~/source/common/models/message-type';
import { DoneFlow } from './done-flow';

enum DepositForms {
  ExternalDeposit = 'external',
  ProftitDeposit = 'proftit',
  UnsupportedDeposit = 'unsupported',
  ShowOobitCompleate = 'showOobitCompleate',
}

interface IAddTransactionPopup {
  account: TradingAccount;
  customer: Customer;
}

const EXTERNAL_CASHIERS = [
  CashierCode.BridgerPay,
  CashierCode.Praxis,
  CashierCode.PnePaynetEasy,
];

const UNSUPPORTED_CASHIERS = [];

class Controller {
  lifecycles = observeComponentLifecycles(this);

  account: TradingAccount;
  customer: Customer;
  depositsSettings: any;
  settings = { ...depositsSettings.depositsModal };
  DepositForms = DepositForms;

  growlId = 'transactionInfo';

  opReloadForm$ = new rx.Subject<{ content: string; type: MessageType }>();

  setDepositForm$ = new rx.Subject<DepositForms>();

  customer$ = observeShareCompChange<{ customer: Customer }>(
    this.lifecycles.onChanges$,
    'resolve',
  ).pipe(rx.map(({ customer }) => customer));

  account$ = this.streamAccount();

  depositForm$ = this.streamDepositForm();

  showPageTrigger$ = this.streamShowPageTrigger();

  close: () => void;

  /* @ngInject */
  constructor(readonly growl) {
    useStreams(
      [this.customer$, this.account$, this.streamGrowlFormReloadForm()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamAccount() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<TradingAccount>('account'),
      shareReplayRefOne(),
    )(null);
  }

  streamDepositForm() {
    return rx.pipe(
      () =>
        rx.obs.merge(this.streamDepositFormFromCashier(), this.setDepositForm$),
      shareReplayRefOne(),
    )(null);
  }

  streamDepositFormFromCashier() {
    return rx.pipe(
      () => this.customer$,
      rx.map((customer) => customer.brand.cashier),
      rx.map((cashier) => calcDepositFormForPresentation(cashier)),
      shareReplayRefOne(),
    )(null);
  }

  streamShowPageTrigger() {
    return rx.pipe(
      () => this.opReloadForm$,
      rx.map(() => ({})),
      rx.startWith({}),
      shareReplayRefOne(),
    )(null);
  }

  streamGrowlFormReloadForm() {
    return rx.pipe(
      () => this.opReloadForm$,
      rx.filter((msg) => _.get(['type'], msg) === MessageType.Error),
      rx.tap((msg) => {
        this.growl.error(msg.content, {
          referenceId: this.growlId,
        });
      }),
    )(null);
  }

  depositDone(next: DoneFlow): void {
    if (next === DoneFlow.ShowOobit) {
      return this.setDepositForm$.next(DepositForms.ShowOobitCompleate);
    }
    return this.close();
  }
}

function calcDepositFormForPresentation(cashier) {
  if (UNSUPPORTED_CASHIERS.includes(cashier.code)) {
    return DepositForms.UnsupportedDeposit;
  }

  if (EXTERNAL_CASHIERS.includes(cashier.code)) {
    return DepositForms.ExternalDeposit;
  }

  return DepositForms.ProftitDeposit;
}

export const AddTransactionPopupComponent = {
  template,
  controller: Controller,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
