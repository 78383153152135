import UiRouter from '@uirouter/core';
import BaseController from '~/source/common/controllers/base';
import template from './cashier-edit.component.html';
import BrandsService from '../../brand/services/brands';
import cashierSettings from '../cashier-settings.json';
import { StateService } from '@uirouter/angularjs';

import * as _ from '@proftit/lodash';

type CashierCredentials = { key: string; value: any }[];
type CashierFields = { name: string; type: string; isRequired: boolean }[];

class CashierEditComponent extends BaseController {
  static $inject = [
    ...BaseController.$inject,
    '$stateParams',
    '$scope',
    '$state',
    'modelNormalizer',
    'brandsService',
  ];
  model: any;
  brand: any;
  modelNormalizer: any;
  $stateParams: UiRouter.StateParams;
  $state: StateService;
  brandsService: () => BrandsService;
  dataServiceInstance: BrandsService;
  cashierInputs: any;

  $onInit() {
    this.dataServiceInstance = this.brandsService();
    this.getBrand(this.$stateParams.id).then((data) => {
      this.brand = data.plain();
      this.initModel();
      this.onCashierSelect();
    });

    // listen to cashier change
    this.$scope.$watch(
      '$ctrl.model.cashier',
      (newCashier: {}, oldCashier: {}) =>
        this.onCashierChange(newCashier, oldCashier),
    );
  }

  /**
   * Initialize the model.
   * called on cashier change
   *
   */
  initModel() {
    this.model = {
      cashierCredentials:
        <CashierCredentials>this.brand.cashierCredentials || [],
      cashier: this.brand.cashier,
    };
  }

  /**
   * Called on cashier change from $scope.$watch
   *
   * @param {} newCashier
   * @param {} oldCashier
   */
  onCashierChange(newCashier, oldCashier) {
    // if onCashierChange called after $onInit oldValue cant be undefined
    if (oldCashier === undefined) {
      return;
    }

    this.model.cashierCredentials = [];
    this.onCashierSelect();
  }

  /**
   * Creating model for cashier credentials
   *
   * @example this.model.cashierCredentials =
   * this.buildCredentialsModel(this.model.cashierCredentials, fields, 'perCurrency');
   * @param {CashierCredentials} cashierCredentials from current model
   * @param {CashierFields} schema - cashier fields from server
   * @param {string} excludedType - field type to exclude from result
   * @returns {CashierCredentials} cashierCredentials with missing schema fields
   */
  buildCredentialsModel(
    cashierCredentials: CashierCredentials,
    schema: CashierFields,
    excludedType: string,
  ) {
    // create array of models
    // [{ key: 'url', value: 'https://praxispay.com/Login.asp' }, {key: 'siteId_USD', value: ...}, ...]
    return schema.reduce((accumulatorModel, schemaField) => {
      const foundField = cashierCredentials.find(
        ({ key }) => key === schemaField.name,
      );
      // if modal exists or Type is excluded, exclude from accumulator
      if (schemaField.type === excludedType || foundField) {
        return accumulatorModel;
      }
      // otherwise initiate the model
      const newField = { key: schemaField.name, value: '' };
      // and add to accumulator
      return [...accumulatorModel, newField];
    }, cashierCredentials);
  }

  /**
   * Creating inputs for cashier credential form
   *
   * @example this.cashierInputs = this.buildCredentialsForm(this.model.cashierCredentials, fields);
   * @param {CashierCredentials} cashierCredentials from current model
   * @param {CashierFields} schema - cashier fields from server
   * @returns {object} inputsAssociative
   */
  buildCredentialsForm(
    cashierCredentials: CashierCredentials,
    schema: CashierFields,
  ) {
    // create array of field details
    // [{ name: url, type: string, isRequired: true }, { name: siteId, type: perCurrency, ... }]
    const inputs = schema.map((schemaField) => {
      // if modal exists use it.
      let model = cashierCredentials.find(
        ({ key }) => key === schemaField.name,
      );
      // otherwise initiate the model
      if (_.isNil(model)) {
        model = { key: schemaField.name, value: '' };
      }
      // build input details
      // { model: {key: url, value: '(https://praxispay.com/Login.asp'}, validator: 'url,required', type: 'url' }
      return {
        model,
        validator: this.getValidations(schemaField),
        type: cashierSettings.inputsTypeMap[schemaField.type] || 'text',
      };
    });
    // convert inputs to associative object
    // [ url: { model: {key: url, value: '(https://praxispay.com/Login.asp'}, validator: 'url,required', type: 'url' },
    // siteId: { model: siteId, value: ''}, type: 'perCurrency', ... }]
    return _.keyBy('model.key', inputs);
  }

  /**
   * Creating new object combining 'cashier cashierCredentials' and 'cashier fields'
   *
   * @returns {void}
   */
  onCashierSelect() {
    const { cashierCredentials } = this.model;
    const fields = _.getEs(this, 'model.cashier.fields', []);

    this.model.cashierCredentials = this.buildCredentialsModel(
      this.model.cashierCredentials,
      fields,
      'perCurrency',
    );

    this.cashierInputs = this.buildCredentialsForm(
      this.model.cashierCredentials,
      fields,
    );
  }

  /**
   * Get validations string for the given field
   *
   * @param {object} field - field object to get validations for
   * @return {string} - Validations string. e.g. 'required, url'
   */
  getValidations(field) {
    const validations = [...cashierSettings.validations[field.type]];
    if (field.isRequired) {
      validations.push('required');
    }

    return validations.join(',');
  }

  /**
   * Retrieve brand instance from server
   *
   * @param id
   * @returns {Restangular.IPromise}
   */
  getBrand(id: number): Promise<any> {
    return this.brandsService()
      .setConfig({ growlRef: 'cashierForm', blockUiRef: 'cashierForm' })
      .expand(['cashier'])
      .getOneWithQuery(id);
  }

  /**
   * Save Cashier to brand in server
   *
   * @returns {Restangular.IPromise}
   */
  save() {
    const normalizedModel = this.modelNormalizer.normalize(this.model);
    // save the brand
    this.brandsService()
      .setConfig({
        growlRef: 'cashierForm',
        blockUiRef: 'cashierForm',
      })
      .patchElement(this.$stateParams.id, normalizedModel)
      .then(() => {
        this.$state.go('^.list');
      });
  }
}

export default {
  template,
  controller: CashierEditComponent,
};
