function cacheFactorySettings(CacheFactoryProvider) {
  Object.assign(CacheFactoryProvider.defaults, {
    storageMode: 'localStorage',
    storagePrefix: 'prf.',
    deleteOnExpire: 'passive',
  });
}

cacheFactorySettings.$inject = ['CacheFactoryProvider'];

export default cacheFactorySettings;
