export const inactivityFeeActivityLogTableSettings = {
  ngTable: {
    parameters: {
      count: 4,
      page: 1,
    },
    settings: {
      counts: [],
    },
  },
};
