import {
  Controller as SlicedChartController,
  config as SlicedChartConfig,
} from './amcharts-sliced.component';

export class Controller extends SlicedChartController {
  /**
   * Build the options object for a funnel chart, that will be passed to amcharts
   * Gets options object which will also be merged to the returned options object
   * and can be used to override settings.
   *
   * @param {object} options
   * @returns {object}
   */
  buildOptions(options) {
    return super.buildOptions(
      this.extend(
        {
          type: 'funnel',
          labelPosition: 'right',
          neckHeight: '50%',
          neckWidth: '70%',
          valueRepresents: 'area',
          colors: [
            '#f0435f',
            '#ff8c6f',
            '#bac3d2',
            '#60b1cc',
            '#344154',
            '#2f4074',
            '#448e4d',
            '#b7b83f',
            '#b9783f',
            '#b93e3d',
            '#913167',
          ],
          marginLeft: 15,
          marginRight: 160,
          showZeroSlices: true,
          titleField: 'title',
          valueField: 'value',
          addClassNames: true,
          classNamePrefix: 'prf-amcharts__funnel',
          theme: 'light',
        },
        options,
      ),
    );
  }
}

export default SlicedChartConfig({ controller: Controller });

export const config = (newConfig) =>
  SlicedChartConfig({
    controller: Controller,
    ...newConfig,
  });
