export const YesOption = {
  id: 1,
  name: 'yes',
};

export const NoOption = {
  id: 0,
  name: 'No',
};

export const BooleanSelectOptions = [YesOption, NoOption];
