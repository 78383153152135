import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

interface NgModelSubject<T> {
  value: T;
  asObservable: () => rx.Observable<T>;
}

/**
 * Create ng model subject.
 *
 * Use to create an object that expose a proxy model that can be binding to ng-model.
 * Then users of the object can listen on the reactive exposed observable.
 *
 * @param source$
 * @param opSet$
 * @param paramsP
 * @return utility object
 */
export function createNgModelSubject<T>(
  source$: rx.Observable<T>,
  opSet$: rx.Subject<T>,
  paramsP = {},
): NgModelSubject<T> {
  const params = _.defaults(paramsP, {
    initialValue: null,
  });

  let sourceValue = params.initialValue;

  const proxy: any = Object.defineProperties(
    {},
    {
      model: {
        set: (value: T) => {
          opSet$.next(value);
        },

        get: () => {
          return sourceValue;
        },
      },

      asObservable: {
        value: () => {
          return new rx.Observable((subscriber) => {
            const subscription = source$.subscribe((data) => {
              sourceValue = data;
            });

            const opSetSub = opSet$.subscribe((data) => {
              subscriber.next(data);
            });

            return () => {
              subscription.unsubscribe();
              opSetSub.unsubscribe();
            };
          });
        },
      },
    },
  );

  return proxy;
}
