import log from 'loglevel';
import { SocketService } from '~/source/common/services/socket';

export class Mt4AccountMarginLevelSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  streamerUrl = '';

  /**
   * Specify socket registry key.
   *
   * there are registry for crm socket, platform socket..
   * Sockets should be shared for all services targeting the same target.
   */
  get socketRegistryKey() {
    return 'mt4Socket';
  }

  getStreamerUrl() {
    // return this.appConfig.connections.mt4Streamer;
    return this.streamerUrl;
  }

  get channelRoot() {
    return 'marginLevel';
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }

  /**
   * Set streamer info
   *
   * @param host - host
   * @param port - port
   */
  setStreamerUrl(url: string) {
    this.streamerUrl = url;
  }
}

/**
 * Build channel name for account marginLevel subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildMt4AccountMarginLevelChannel(accountSyncId: number) {
  return `{customer.${accountSyncId}}.marginLevel`;
}
