import template from './content-template-page.component.html';
const styles = require('./content-template-page.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import type { StateService } from '@uirouter/angularjs';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { switchOn } from '~/source/common/utilities/switch-on';

export class ContentTemplatePageController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  action$ = this.streamAction();
  subAction$ = this.streamSubAction();
  id$ = this.streamId();
  parentId$ = this.streamParentId();
  templateType$ = this.streamTemplateType();

  /*@ngInject */
  constructor(readonly $state: StateService) {
    useStreams([], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamAction() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.map(() => this.$state.current.data.action),
      shareReplayRefOne(),
    )(null);
  }

  streamSubAction() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.map(() => this.$state.current.data.subAction),
      shareReplayRefOne(),
    )(null);
  }

  streamId() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.map(() => this.$state.params.id),
      shareReplayRefOne(),
    )(null);
  }

  streamParentId() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.map(() => this.$state.params.parentId),
      shareReplayRefOne(),
    )(null);
  }

  streamTemplateType() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.map(() => this.$state.current.data.templateType),
      shareReplayRefOne(),
    )(null);
  }
}

export const ContentTemplatePageComponent = {
  template,
  controller: ContentTemplatePageController,
};
