import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import template from './brand-platform-nt4-group-security-dialog.component.html';
const styles = require('./brand-platform-nt4-group-security-dialog.component.scss');

interface Resolve {
  brandPlatform: BrandPlatform;
  mtGroupId: number;
  securityId: number;
  operation: string;
}

export class BrandPlatformNt4GroupSecurityDialogController {
  /* bindings */

  close: (a: { $value: any }) => void;

  dismiss: (a: { $value: any }) => void;

  modalInstance;

  resolve: Resolve;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  /* inputs */

  resolveIn$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  securityIdIn$ = this.resolveIn$.pipe(
    rx.map(({ securityId }) => securityId),
    shareReplayRefOne(),
  );

  brandPlatformIn$ = this.resolveIn$.pipe(
    rx.map(({ brandPlatform }) => brandPlatform),
    shareReplayRefOne(),
  );

  mtGroupIdIn$ = this.resolveIn$.pipe(
    rx.map(({ mtGroupId }) => mtGroupId),
    shareReplayRefOne(),
  );

  operationIn$ = this.resolveIn$.pipe(
    rx.map(({ operation }) => operation),
    shareReplayRefOne(),
  );

  /* @ngInject */
  constructor() {
    useStreams([this.resolveIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandPlatformNt4GroupSecurityDialogComponent = {
  template,
  controller: BrandPlatformNt4GroupSecurityDialogController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
