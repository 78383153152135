import ControllerBase from '~/source/common/controllers/base';

import template from './list.html';

class Controller extends ControllerBase {}

const PriorityListComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default PriorityListComponent;
