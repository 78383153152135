import template from './communication-actions.component.html';
const styles = require('./communication-actions.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Customer, User } from '@proftit/crm.api.models.entities';
import { ModalService } from '../modal/modal.service';
import * as rx from '@proftit/rxjs';
import { getCompChange } from '../../utilities/rx-ng-one/operators/get-comp-change';
import { UsersService } from '~/source/management/user/services/users';
import { useStreams } from '@proftit/rxjs.adjunct';

export class CommunicationActionsController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customer: Customer;
  user: User;

  customer$ = this.streamCustomer();
  user$ = this.streamUser();
  isVoipEnabledForUser$ = this.streamIsVoipEnabledForUser();

  /*@ngInject */
  constructor(
    readonly modalService: ModalService,
    readonly usersService: () => UsersService,
  ) {
    // for now, register for local observables until we remove preAssignBindingsEnabled.
    useStreams(
      [this.customer$, this.user$, this.isVoipEnabledForUser$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamUser() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<User>('user'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamCustomer() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<Customer>('customer'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamIsVoipEnabledForUser() {
    return rx.pipe(
      () => this.user$,
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([user, customer]) => {
        return this.usersService().isVoipEnabledForUser(
          user.id as number,
          customer.brand.id,
        );
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  openSendSmsDialog(customer: Customer) {
    this.modalService.open({
      component: 'prfClickToSendDialog',
      resolve: {
        customer,
        brand: customer.brand,
      },
    });
  }

  openSendEmailDialog(customer: Customer) {
    this.modalService.open({
      component: 'prfSendEmailDialog',
      size: 'lg',
      resolve: {
        customer,
        brand: customer.brand,
      },
    });
  }
}

export const CommunicationActionsComponent = {
  template,
  controller: CommunicationActionsController,
  bindings: {
    customer: '<',
    user: '<',
    disableLastCallTooltip: '<',
  },
};
