export const bundlePositionsTableCols = [
  {
    title: 'contact.positions.ORDER_ID',
    field: 'syncRemoteId',
    sortable: 'id',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contact.positions.SYMBOL',
    field: 'tradeAsset',
    fieldFormat: '{{tradeAsset.name | uppercase}}',
    sortable: false,
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.TYPE',
    field: 'side',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'contact.positions.SIZE',
    field: 'size',
    fieldFormat:
      '{{ size | currency: tradingAccount.currency.code : tradingAccount.currency.decimals }}',
    sortable: 'size',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.LEVERAGE',
    field: 'leverage',
    fieldFormat: '{{ leverage }}',
    sortable: 'leverage',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.OPEN_TIME',
    field: 'entryDate',
    fieldFormat: '{{entryDate | amDateFormat:"L LTS"}}',
    sortable: 'entryDate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.CLOSE_TIME',
    field: 'expiryDate',
    fieldFormat: '{{expiryDate | amDateFormat:"L LTS"}}',
    sortable: 'expiryDate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.OPEN_PRICE',
    field: 'entryRate',
    fieldFormat: '{{ entryRate | prfToFixed:tradeAsset.tailDigits }}',
    sortable: 'entryRate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.CLOSE_PRICE',
    field: 'expiryRate',
    fieldFormat: '{{ expiryRate | prfToFixed:tradeAsset.tailDigits }}',
    sortable: 'expiryRate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.STOP_LOSS',
    field: 'stopLoss',
    fieldFormat: '{{ stopLoss | prfToFixed:tradeAsset.tailDigits }}',
    sortable: 'stopLoss',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.TAKE_PROFIT',
    field: 'takeProfit',
    fieldFormat: '{{ takeProfit | prfToFixed:tradeAsset.tailDigits }}',
    sortable: 'takeProfit',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.bundles.PAYOUT',
    field: 'payout',
    fieldFormat:
      '{{ payout | currency: tradingAccount.currency.code : tradingAccount.currency.decimals }}',
    sortable: 'payout',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.PROFIT',
    field: 'profit',
    fieldFormat:
      '{{profit |  currency: tradingAccount.currency.code : tradingAccount.currency.decimals }}',
    sortable: 'profit',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'contact.positions.STATUS',
    field: 'positionStatusForex',
    fieldName: 'status',
    fieldFormat:
      '{{positionStatusCode ? "position.statuses." + positionStatusCode.toUpperCase() : "N/A" | translate}}',
    sortable: 'positionStatusCode',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    field: 'closeReason',
    fieldFormat:
      "{{ closeReason ? 'position.closeReasons.'  + closeReason.toUpperCase() : 'N/A' | translate }}",
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: 'contact.positions.CLOSE_REASON',
  },
];
