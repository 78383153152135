/**
 * reduces currency fields
 *
 * @param {Array} currencies
 * @param {Object} currency
 */
export function createCurrenciesCredentialsGenesis(currencies) {
  return currencies.reduce((acc, curr) => {
    return { ...acc, [curr]: { CVVON: '', CVVOFF: '', '3D': '', MOTO: '' } };
  }, {});
}

export default createCurrenciesCredentialsGenesis;
