import type { IAngularStatic } from 'angular';
import { generateDirectiveForStore } from '~/source/common/utilities/generate-directive-for-store';
import { HandlerValidationMessageComponent } from './handler-validation-message/handler-validation-message.component';
import { ProxyFormValidationMessageGeneratorService } from './proxy-form-validation-message-generator.service';

export function proxyFormNg1Module(ng: IAngularStatic) {
  const moduleNg = ng.module('prfProxyFormNg1', []);

  moduleNg
    .directive(
      'prfProxyFormValidationMessageGenerator',
      generateDirectiveForStore(
        ProxyFormValidationMessageGeneratorService,
        'prfProxyFormValidationMessageGenerator',
      ),
    )
    .component(
      'prfHandlerValidationMessage',
      HandlerValidationMessageComponent,
    );

  return moduleNg;
}
