/// <reference types="intercom-web" />
import { IAppConfig } from '~/source/conf/appConfig';
import * as rx from '@proftit/rxjs';
import UserTokenModel from '~/source/common/models/user-token-model';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import TokensService from '~/source/auth/services/tokens';
import { IWindowService } from 'angular';

export interface IntercomData {
  hide_default_launcher: boolean;
  name?: string;
  user_id?: string;
  role?: string;
  crmName?: string;
  logged_in: boolean;
  session_duration?: number;
  company: {
    company_id: string;
    name: string;
    website: string;
    role?: string;
    currentUrl: string;
  };
}
const FIVE_MINUTES: number = 5 * 60 * 1000;

export class IntercomService {
  appId: string;
  subdomain: string;
  onDestroy$ = new rx.Subject<void>();
  showIntercom$ = new rx.Subject<any>();
  user$ = this.streamUser();

  /* @ngInject */
  constructor(
    readonly appConfig: IAppConfig,
    readonly $location,
    readonly prfCurrentUserStore: CurrentUserStoreService,
    readonly $window: IWindowService,
    readonly tokensService: TokensService,
  ) {
    this.appId = this.appConfig.intercomConfig.app_id;
    this.loadIntercomFunction();
    this.subdomain = this.getSumdomainName();

    // Intercom chat is used also for non-logged in users, in the "login screen".
    // If user not logged in - we will call him "guest".
    // If he's logged in - we will fill his details.
    // In addition, to start clean, we will clean the exists instant of Intercom -by calling "Shutdown".
    useStreams(
      [
        this.user$,
        this.streamLoggedInUser(),
        this.streamLoggedOutUser(),
        this.streamShowIntercom(),
      ],
      this.onDestroy$,
    );
  }

  showIntercom(isGuest: boolean) {
    this.showIntercom$.next(isGuest);
  }

  private streamShowIntercom() {
    return rx.pipe(
      () => this.showIntercom$,
      rx.tap((isGuest) => {
        if (isGuest) {
          this.showIntercomButtonAsGuest();
        } else {
          this.showIntercomChatAsLoggedInUser();
        }
      }),
    )(null);
  }

  private showIntercomChatAsLoggedInUser() {
    this.shutdown();
    this.boot(this.getUserForIntercom(this.tokensService.getCachedUser()));
    this.show();
  }

  private streamLoggedOutUser() {
    return rx.pipe(
      () => this.prfCurrentUserStore.onUserLogout$,
      rx.withLatestFrom(this.user$),
      rx.tap(([loggedOut, user]) => this.showIntercomButtonAsGuest()),
    )(null);
  }

  private streamLoggedInUser() {
    return rx.pipe(
      () => this.prfCurrentUserStore.onUserLogin$,
      rx.withLatestFrom(this.user$),
      rx.tap(([loggedIn, user]) => {
        this.shutdown();
        this.boot(user);
      }),
    )(null);
  }

  /*** Boot the Intercom chat object.*/
  boot(userP) {
    let user = userP;
    if (user === null) {
      user = this.getGuestData();
    }
    const bootParams = {
      app_id: this.appId,
      created_at: Date.now().toString(),
      ...user,
    };

    this.$window.Intercom('boot', bootParams);
  }

  private show() {
    this.$window.Intercom('show');
  }

  private hide() {
    this.$window.Intercom('hide');
  }

  private shutdown() {
    this.$window.Intercom('shutdown'); // this method will effectively clear out any user data from "Intercom" cookie. For details: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomshutdown
  }

  showIntercomButtonAsGuest() {
    this.shutdown();
    this.boot(this.getGuestData());
    this.hide();
  }

  private getGuestData(): IntercomData {
    const id = `guest-${Math.random().toString(36).slice(2)}`;

    return {
      hide_default_launcher: false,
      name: 'Guest',
      role: 'guest',
      crmName: this.subdomain,
      logged_in: false,
      session_duration: FIVE_MINUTES,
      company: {
        company_id: this.subdomain,
        name: this.subdomain,
        role: 'unknown',
        currentUrl: this.$location.path(),
        website: this.subdomain,
      },
    };
  }

  /** Load Intercom script into "window" object.
   *  Based on : https://developers.intercom.com/installing-intercom/docs/intercom-javascript
   */
  private loadIntercomFunction() {
    const appId = this.appId; // Our  Intercom's app ID is included in the widget URL
    const w = this.$window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        // @ts-ignore
        i.c(arguments);
      };
      // @ts-ignore
      i.q = [];
      // @ts-ignore
      i.c = function (args) {
        // @ts-ignore
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${appId}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  }

  private streamUser() {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,

      rx.map<UserTokenModel, IntercomData>((user) => {
        if (_.isNil(user)) {
          return null;
        }

        return this.getUserForIntercom(user);
      }),

      shareReplayRefOne(),
    )(null);
  }

  private getSumdomainName() {
    return this.$location.host().replace('proftit.com', '');
  }

  private getUserForIntercom(user: UserTokenModel): IntercomData {
    return {
      hide_default_launcher: true,
      name: `${user.firstName} ${user.lastName} - ${this.subdomain}`,
      user_id: `${user.username} - ${this.subdomain}`,
      role: user.role.name,
      crmName: this.subdomain,
      logged_in: true,
      company: {
        company_id: this.subdomain,
        name: this.subdomain,
        website: this.$location.host(),
        currentUrl: this.$location.path(),
      },
    };
  }
}
