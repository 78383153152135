import template from './brand-account-status-automation-audit-log-table.component.html';
const styles = require('./brand-account-status-automation-audit-log-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { BrandsService } from '~/source/management/brand/services/brands';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { Brand } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { tableColumns } from './table-columns';
import { tableSettings } from './table-settings';
import { calcInfoForBrandAccountStatusAutomationAuditLog } from '../../shared/calc-info-for-brand-account-status-automation-audit-log';

class Controller extends TableController {
  static $inject = [
    'popupService',
    'brandsService',
    ...TableController.$inject,
  ];

  brand: Brand;

  brandsService: () => BrandsService;
  popupService: PopupService;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  blockUid = generateBlockuiId();
  dataServiceInstance: BrandsService;
  cols = [...tableColumns];
  tableSettings = { ...tableSettings };

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.brandsService();
    this.initTable();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.tableSettings;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getAccountStatusAutomationLogsResource(this.brand.id)
      .setConfig({ blockUiRef: this.blockUid })
      .expand(['user'])
      .sort({ requestedAt: 'desc' });
  }

  openLogsPopupTable(brand: Brand) {
    this.popupService.open({
      component: 'prfBrandAccountStatusAutomationAuditLogPopup',
      resolve: {
        brand,
      },
    });
  }

  /**
   * parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[]): any[] {
    data.forEach((record) => {
      record._dataAsPrintable = calcInfoForBrandAccountStatusAutomationAuditLog(
        record.data,
      );
    });
    return data;
  }
}

export const BrandAccountStatusAutomationAuditLogTableComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};
