export enum YupValidationCode {
  notTypeNumber = 'NOT_TYPE_NUMBER',
  notTypeString = 'NOT_TYPE_STRING',
  notTypeArray = 'NOT_TYPE_ARRAY',
  required = 'REQUIRED',
  integer = 'INTEGER',
  positive = 'POSITIVE',
  min = 'MIN',
  max = 'MAX',
  maxDecimalPoints = 'MAX_DECIMAL_POINTS',
  minArray = 'MIN_ARRAY',
  chooseOne = 'CHOOSE_ONE',
}
