function idleConf(IdleProvider, appConfig) {
  const idleTimeout = appConfig.idleTimeout || 60;
  // events which reset idling
  IdleProvider.interrupt(
    'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll',
  );
  IdleProvider.autoResume('notIdle'); // only interrupt when user is not idle
  IdleProvider.idle(60 * idleTimeout); // time in seconds until the "idle" message appears
  IdleProvider.timeout(50); // time in seconds the "idle" message will be displayed, until it will logout
}

idleConf.$inject = ['IdleProvider', 'appConfig'];

export default idleConf;
