import { IHttpService } from 'angular';
import Url from 'url-parse';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import { BrandsService } from '~/source/management/brand/services/brands';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  addHeader,
  getAsOne,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  create as genReqCreate,
  setTokenHeader,
  performRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { removeColonAtEnd } from '~/source/common/utilities/remove-colon-at-end';
import { CfdPlatformBrandConfig } from '~/source/common/models/cfd-platform/cfd-platform-brand-config';
import { Platform } from '@proftit/crm.api.models.entities';
import { PlatformCode } from '@proftit/crm.api.models.enums';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';

const CFD_RESOURCE_PATH = 'Config/Cfd';
const BUNDLE_RESOURCE_PATH = 'Config/Bundle';

export function getPlatformResourcePathForPlatform(platform: Platform) {
  switch (platform.code) {
    case PlatformCode.Cfd:
      return CFD_RESOURCE_PATH;
    case PlatformCode.Bundle:
      return BUNDLE_RESOURCE_PATH;
    default:
      throw new Error('not implemented');
  }
}

export class CfdPlatformBrandConfigsService {
  /*@ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  initReq(reqParam, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
    ])(reqParam);
  }

  initPrivateReq(apiUrl: string, token: string, platform: Platform) {
    const resourcePath = getPlatformResourcePathForPlatform(platform);

    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setResource(resourcePath, req),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReqWithSession(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  getAsOne(id: number, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  update(id: number, asset: Partial<CfdPlatformBrandConfig>, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => genReqUpdate(req),
        (req) => setPayload(asset, req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }
}
