export const emailTemplateDesignsLogsTableCols = [
  {
    title: 'designTemplatesLogs.fields.UPDATED_AT',
    field: '',
    id: 'updatedAt',
    fieldFormat: '{{ modifiedAt | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'designTemplatesLogs.fields.TEMPLATE_NAME',
    field: '',
    id: 'templateName',
    fieldFormat: '{{ templateName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'designTemplatesLogs.fields.FIELD',
    field: '',
    id: 'field',
    fieldFormat: '{{ field }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'designTemplatesLogs.fields.USER',
    field: '',
    id: 'user',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'designTemplatesLogs.fields.IP',
    field: 'ip',
    fieldFormat: '{{ ip }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
