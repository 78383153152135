// This file was generated automatically. Tread lightly.

export enum TransactionWithdrawalReasonTypeCode {
  Bonus = 'bonus',
  Chargeback = 'chargeback',
  Fees = 'fees',
  Fraud = 'fraud',
  Inactivityfee = 'inactivityfee',
  Other = 'other',
  Unset = 'unset',
}
