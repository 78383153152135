import angular from 'angular';
import RiskFreeCreateComponent from './risk-free-create.component';
import RiskFreeDeductComponent from './risk-free-deduct.component';
import RiskFreeTableComponent from './risk-free-table.component';
import RiskFreeTablePopupController from './risk-free-table-popup.controller';

export default angular
  .module('crm.contact.tradingAccount.binary.riskFree', [])
  .component('prfRiskFreeCreate', RiskFreeCreateComponent)
  .component('prfRiskFreeDeduct', RiskFreeDeductComponent)
  .component('prfRiskFreeTable', RiskFreeTableComponent)
  .controller('riskFreeTablePopupController', RiskFreeTablePopupController)
  .name;
