import Promise from 'bluebird';
import Component from './base/component';

class Controller extends Component.controller {
  query() {
    const values = [
      {
        id: 0,
        name: 'RESTRICTED',
      },
      {
        id: 1,
        name: 'NOT_RESTRICTED',
      },
    ];

    return Promise.resolve(values);
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'restrictionSelect';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'name';
  }

  /**
   * Default placeholder for the select.
   * Used when no placeholder-term attribute was set.
   *
   * @returns {string}
   */
  get defaultPlaceholder(): string {
    return 'restrictionSelect.PLACEHOLDER';
  }
}

export default Component.config({ controller: Controller });
