import { TransitionService, HookMatchCriteria } from '@uirouter/core';
import { observeTransitionSuccess } from '../utilities/rxjs/observables/observe-transition-success';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export class RouterStoreService {
  _routeChangeSuccess$ = rx.pipe(
    () => observeTransitionSuccess(this.$transitions, { to: 'crm.**' }),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  _routeChangeSuccessAfterInitial$ = rx.pipe(
    () => this._routeChangeSuccess$,
    rx.filter((transition) => !_.isEmpty(transition.from().name)),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  _routeChangeSuccessAfterInitWithoutSubstateChanges$ = rx.pipe(
    () => this._routeChangeSuccessAfterInitial$,
    rx.filter(
      (transition) => !transition.to().name.startsWith(transition.from().name),
    ),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  /* @ngInject */
  constructor(readonly $transitions: TransitionService) {}

  get routeChangeSuccess$() {
    return this._routeChangeSuccess$;
  }

  get routeChangeSuccessAfterInitial$() {
    return this._routeChangeSuccessAfterInitial$;
  }

  get routeChangeSuccessAfterInitWithoutSubstateChanges$() {
    return this._routeChangeSuccessAfterInitWithoutSubstateChanges$;
  }
}
