import template from './svg-symbols.component.html';
const styles = require('./svg-symbols.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class SvgSymbolsController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor(readonly $sce) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SvgSymbolsComponent = {
  template,
  controller: SvgSymbolsController,
};
