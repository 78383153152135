import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class PortfolioNotificationsController extends NotificationsController {
  buildChannel() {
    return [
      `user.${this.tokensService.getCachedUser().id}`,
      `.${this.streamingService.channelRoot}`,
      '.new',
    ].join('');
  }

  /**
   * Filters elements that were excluded
   *
   * @param {object} data
   */
  filter(data) {
    // prevent callback invoke on exclude elements update
    if (data.accountId && this.exclude.includes(data.accountId)) {
      return;
    }
    this.onUpdate(data);
  }
}

PortfolioNotificationsController.$inject = [
  // socket sevice needs to be first in the injection. (see base notification controller)
  'portfolioForexPositionSocketService',
  ...NotificationsController.$inject,
];

export const PortfolioNotificationsComponent = {
  ...ComponentOptions,
  controller: PortfolioNotificationsController,
};
