import { IScope } from 'angular';
import type { StateService } from '@uirouter/angularjs';
import _ from 'underscore';

import TableController from '~/source/common/components/table/table.controller';
import BrandsService from '~/source/management/brand/services/brands';

import template from './manage.html';

class ClearingCompaniesManageController extends TableController {
  static $inject = [
    'brandsService',
    'clearing3dModuleSettings',
    '$scope',
    '$state',
    ...TableController.$inject,
  ];

  brandsService: () => BrandsService;
  $state: StateService;
  clearing3dModuleSettings: any;
  $scope: IScope;

  selectedBrandId: number;
  brands: any[];
  dataServiceInstance: BrandsService;

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      dataServiceInstance: this.brandsService(),
      selectedBrandId: this.$state.params.brandId
        ? parseInt(this.$state.params.brandId, 10)
        : null,
      settings: Object.assign({}, this.clearing3dModuleSettings),
      cols: [...this.clearing3dModuleSettings.cols],
    });
  }

  get isInfiniteTable() {
    return true;
  }

  /**
   * fetch brands then init table
   * this function will be passed to & invoked by prf-calculate-num-of-rows on-table-ready
   * @param rows
   */
  initTableFn(rows) {
    this.fetchBrands().then(() => {
      // set default brand id only if no id is provided in url
      if (!this.selectedBrandId) {
        const firstBrand = _.first(this.brands);
        this.selectedBrandId = firstBrand ? firstBrand.id : 0;
      }

      this.initTable(rows);

      // watch selected brand & fetch 3D's data accordingly
      this.watchSelectedBrandId();
    });
  }

  get tableKey() {
    return '3ds-settings';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getThreeDResource(this.selectedBrandId)
      .expand(['clearingCompany', 'minimumDeposits.currency'])
      .embed(['countries', 'minimumDeposits'])
      .setConfig({ blockUiRef: this.blockUiKey });
  }

  /**
   * Getter for ngTableParams
   * necessary for parent reload method
   *
   * @returns {NgTableParams}
   */

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.clearing3dModuleSettings.ngTable;
  }

  /**
   * load list of brands
   * @returns {Promise}
   */
  fetchBrands() {
    return this.dataServiceInstance
      .embed('platformConnections')
      .expand(['file:logo'])
      .getListWithQuery()
      .then((data) => {
        this.brands = data;
      });
  }

  /**
   * watch selected brand and load 3ds settings table data on change
   */
  watchSelectedBrandId() {
    this.$scope.$watch('vm.selectedBrandId', (newVal, oldVal) => {
      if (newVal === oldVal) {
        return;
      }

      // load brand 3D's settings
      this.tableParams.reload();
    });
  }

  /**
   * select brand
   * @param {number} brandId
   */
  selectBrand(brandId) {
    // update state with brand id.
    this.$state.go(
      'crm.management.integration.clearing.3d-settings.list.table',
      { brandId },
    );

    // set selected brand id
    this.selectedBrandId = brandId;
  }

  /**
   * is this brand selected
   * @param {number} brandId
   * @returns {boolean}
   */
  isSelected(brandId) {
    return this.selectedBrandId === brandId;
  }

  /**
   * delete all 3D's setting records. one 3D's can have multiple currencies.
   * here we use filter to find all the 3D's currency records we want to delete.
   *
   * @param {object} threeD
   */
  deleteThreeD(threeDId) {
    this.dataServiceInstance
      .resourceBuildStart()
      .setConfig({ blockUiRef: this.blockUiKey })
      .getElement(this.selectedBrandId)
      .getNestedElement('threeDConnections', threeDId)
      .resourceBuildEnd()
      .removeWithQuery()
      .then(() => {
        this.tableParams.reload();
      });
  }
}

const Clearing3dSettingsManageComponent = {
  template,
  controller: ClearingCompaniesManageController,
  controllerAs: 'vm',
};

export default Clearing3dSettingsManageComponent;
