import BaseDropdown from '~/source/common/components/dropdowns/base/component';

class PositionsStatusesController extends BaseDropdown.controller {
  static $inject = [
    'positionsStatusesForexService',
    ...BaseDropdown.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'position.SELECT_POSITION_STATUS';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'position.statuses';
  }

  get nullableElement() {
    return {
      id: null,
      code: 'null',
      nullable: true,
    };
  }
}

export default BaseDropdown.config({ controller: PositionsStatusesController });
