import { Brand, Customer, PromoCode } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { CustomerStoreProviderController } from '~/source/contact/common/components/customer-store-provider/customer-store-provider.component';
import { useResolve } from '../../../trading-account/forex/bundles-table-popup/use-resolve';
import template from './add-promo-code-popup.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PROMO_CODE_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { PromoCodeScreenAction } from './promo-code-screen-action';

class AddPromoCodePopupController {
  prfCustomerStoreProvider: CustomerStoreProviderController;

  close: () => void;

  brandIn$: rx.Observable<Brand>;

  customerIn$: rx.Observable<Customer>;

  actionIn$: rx.Observable<PromoCodeScreenAction>;

  promoCodeIn$: rx.Observable<PromoCode>;

  onScreenEnd = () => {
    this.prfClientGeneralPubsub.publish(PROMO_CODE_ADDED, null);
    this.close();
  };

  /* @ngInject */
  constructor(readonly prfClientGeneralPubsub: ClientGeneralPubsub) {
    const lifecycles = observeComponentLifecycles(this);

    const { resolve$ } = useResolve<Resolve>(lifecycles);

    const brandIn$ = resolve$.pipe(
      rx.map(({ brand }) => brand),
      shareReplayRefOne(),
    );

    const customerIn$ = resolve$.pipe(
      rx.map(({ customer }) => customer),
      shareReplayRefOne(),
    );

    const actionIn$ = resolve$.pipe(
      rx.map(({ action }) => action),
      shareReplayRefOne(),
    );

    const promoCodeIn$ = resolve$.pipe(
      rx.map(({ promoCode }) => promoCode),
      shareReplayRefOne(),
    );

    useStreams(
      [brandIn$, customerIn$, actionIn$, promoCodeIn$],
      lifecycles.onDestroy$,
    );

    this.brandIn$ = brandIn$;
    this.customerIn$ = customerIn$;
    this.actionIn$ = actionIn$;
    this.promoCodeIn$ = promoCodeIn$;
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

interface Resolve {
  brand: Brand;
  customer: Customer;
  action: PromoCodeScreenAction;
  promoCode: PromoCode;
}

export const AddPromoCodePopupComponent = {
  template,
  controller: AddPromoCodePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
    prfCrmAppRouteProvider: '^',
  },
};
