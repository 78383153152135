import SocketService from '~/source/common/services/socket';

export class CommunicationSubjectsChangeSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  SOCKET_TIMEOUT_INTERVAL = 500;

  get entityName() {
    return 'CustomerCommunicationSubject';
  }

  get channelRoot() {
    return `crm.${this.entityName}.change`;
  }
}
