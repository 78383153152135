import { PlatformCode } from '@proftit/crm.api.models.enums';

export const cols = [
  {
    title: 'riskManager.SYMBOL_NAME',
    field: 'cfdPlatformAssetName',
    fieldFormat: '{{ name }}',
    sortable: 'name',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'riskManager.SYMBOL_CATEGORY',
    field: 'cfdPlatformAssetCategory',
    fieldFormat: '{{ group }}',
    sortable: 'group',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'riskManager.PRECISION',
    field: 'precision',
    fieldFormat: '{{ precision }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MINIMUM_MOVEMENT',
    field: 'minimumMovement',
    fieldFormat: '{{ minMove }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.FIXED_SPREAD',
    field: 'fixedSpread',
    fieldFormat: '{{ _uiFixedSpread.toString() }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    renderer: 'markupSpread',
    title: 'riskManager.MARKUP_SPREAD_X2',
    field: 'markupSpread',
    fieldFormat: '{{ spread }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.LEVERAGE_RATIO',
    field: 'leverageRatio',
    fieldFormat: '{{ leverage }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MINIMUM_INVESTMENT',
    field: 'minInvestment',
    fieldFormat: '{{ minInvestment.usd }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'riskManager.MAX_INVESTMENT',
    field: 'maxInvestment',
    fieldFormat: '{{ maxInvestment.usd }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    renderer: 'swapCommission',
    headerTooltipIcon: true,
    headerTooltipTextCode: 'riskManager.SWAP_HELPER_TEXT',
    title: 'riskManager.SWAP_COMMISSION',
    field: 'swapCommission',
    fieldName: 'swapCommission',
    fieldFormat:
      '{{ swapCommission }} {{ "cfdPlatformGroupsTable.SWAP_INNER_UNITS_OR" | translate }} {{ (swapRatio | prfZeroOneAsPercentage) || "0" }} %',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
    relevantPlatforms: [PlatformCode.Cfd],
  },
  {
    // This is empty col for control settings
    renderer: 'actions',
    title: '',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
