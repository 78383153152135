import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import template from './sidebar.html';

import BaseController from '~/source/common/controllers/base';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';
import { useStream } from '~/source/common/utilities/use-stream';
import { BrandsService } from '~/source/management/brand/services/brands';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { SideMenuItem } from '~/source/common/components/side-menu-item/side-menu-item';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { sideMenuData } from './side-menu-data';
import { CfdPlatforms, MtPlatforms } from '@proftit/crm.api.models.enums';
import { Brand } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

function setInMenuTree(
  path: string[],
  itemChangeFn: (item: SideMenuItem) => SideMenuItem,
  menu: SideMenuItem[],
) {
  const preTip = _.head(path);
  const postTips = _.tail(path);

  if (_.isNil(preTip)) {
    return menu;
  }

  return menu.map((item) => {
    if (item.id !== preTip) {
      return item;
    }

    if (_.isNil(postTips) || postTips.length === 0) {
      return itemChangeFn(item);
    }

    return {
      ...item,
      menuItems: setInMenuTree(postTips, itemChangeFn, item.menuItems),
    };
  }, []);
}

class Controller extends BaseController {
  collapseEntries: any;

  lifecycles = observeComponentLifecycles(this);

  menu$ = new rx.BehaviorSubject<SideMenuItem[]>(sideMenuData);

  opRecalcMenuFromCollapseAction$ = new rx.Subject<string[]>();

  brandsWithPlatforms$ = this.streamBrandsWithPlatforms();

  isSystemHasCfd$ = this.streamIsSystemHasCfd();

  isSystemHasMt$ = this.streamIsSystemHasMt();

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly featuresFlags: FeaturesFlagsService,
    readonly brandsService: () => BrandsService,
  ) {
    super();
  }

  /**
   * Component lifecycle
   */
  $onInit() {
    useStreams(
      [
        this.streamCalcMenuFromCollapseAction(),
        this.streamCalcMenuFromFlagHasCfdOrMtPlatformBrand(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  /**
   * Component lifecycle
   *
   * Notifiy all subscription to close.
   */
  $onDestroy() {}

  streamCalcMenuFromCollapseAction() {
    return rx.pipe(
      () => this.opRecalcMenuFromCollapseAction$,
      rx.withLatestFrom(this.menu$),
      rx.map(([path, menu]) => {
        return setInMenuTree(
          path,
          (targetItem) => {
            return {
              ...targetItem,
              isCollapsed: !targetItem.isCollapsed,
            };
          },
          menu,
        );
      }),
      rx.tap((menu) => this.menu$.next(menu)),
    )(null);
  }

  streamBrandsWithPlatforms() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((t) => t)),
      rx.switchMap(() => rx.obs.from(this.getBrandsAndPlatforms())),
      rx.map((data) => data.plain()),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Generate stream calculation of isSystemHasCfd.
   *
   * @return - The calculation stream observable.
   */
  streamIsSystemHasCfd() {
    return rx.pipe(
      () => this.brandsWithPlatforms$,
      rx.map((brands) =>
        brands.find((brand) => {
          return _.flow([
            () => _.get('platformConnections', brand),
            (platformConns) => _.defaultTo([], platformConns),
            (platformConns) =>
              platformConns.find((conn) =>
                CfdPlatforms.includes(conn.platform.code),
              ),
          ])();
        }),
      ),
      rx.map((brandCfd) => !_.isNil(brandCfd)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsSystemHasMt() {
    return rx.pipe(
      () => this.brandsWithPlatforms$,
      rx.map((brands) =>
        brands.find((brand) => {
          return _.flow([
            () => _.get('platformConnections', brand),
            (platformConns) => _.defaultTo([], platformConns),
            (platformConns) =>
              platformConns.find((conn) =>
                MtPlatforms.includes(conn.platform.code),
              ),
          ])();
        }),
      ),
      rx.map((brand) => !_.isNil(brand)),
      shareReplayRefOne(),
    )(null);
  }

  streamCalcMenuFromFlagHasCfdOrMtPlatformBrand() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.isSystemHasCfd$, this.isSystemHasMt$),
      rx.withLatestFrom(this.menu$),
      rx.map(([[isSystemHasCfd, isSystemHasMt], menu]) => {
        const path = ['integration', 'platforms', 'riskManager'];
        return setInMenuTree(
          path,
          (targetItem) => {
            return {
              ...targetItem,
              hide: !(isSystemHasCfd || isSystemHasMt),
            };
          },
          menu,
        );
      }),
      rx.tap((menu) => this.menu$.next(menu)),
    )(null);
  }

  /**
   * Get list of the system brands and connected platform from the server.
   *
   * @return - Promise of the server query.
   * @private
   */
  getBrandsAndPlatforms() {
    return this.brandsService()
      .embed(['platformConnections'])
      .expand(['platformType', 'platformConnections.platform'])
      .getListWithQuery<IElementRestNg<Brand>>();
  }
}

const SidebarComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    collapseEntries: '<?',
  },
};

export default SidebarComponent;
