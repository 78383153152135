import template from './audit-logs-tab.html';
import { BaseController } from '~/source/common/controllers/base';

class Controller extends BaseController {}

export const AuditLogsTab = {
  template,
  controller: Controller,
  bindings: {
    customer: '<',
  },
};
