/*
 * User settings is used as semi free table to store json data relevant for client for a user.
 * Those are client side codes are not stored currently in fk on the DB, only meaningful for client.
 */
export enum UserSettingsKeyCode {
  PortfolioPlatform = 'portfolioPlatformUserSettings',
  PortfolioBrandUserSettings = 'portfolioBrandUserSettings',
  SettingsCfdPlatformBrandsManagemer = 'cfdPlatformBrandsManagemer',
  SettingsCfdPlatformBrandGroupsManagemer = 'cfdPlatformBrandGroupsManager',
  SettingsCfdPlatformBrandAssetManagemer = 'cfdPlatformBrandAssetManagemer',
}
