import RestService from '~/source/common/services/rest';
import { IFieldsAndModulesService } from '~/source/management/fields-and-modules/i-fields-and-modules-service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { IPromise } from 'angular';
import { FieldsAndModulesModel } from '@proftit/crm.api.models.entities/src';
import { TimeInterval } from '@proftit/constants.time';

export class ClearingCompaniesService extends RestService
  implements IFieldsAndModulesService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'clearingCompanies';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }

  /**
   * Override default cache options
   */
  get cacheOptions() {
    return {
      maxAge: TimeInterval.Hour,
    };
  }

  getClearingCompaniesResource(id: number) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }

  updateIsDefault(
    id: number,
    isDefault: boolean,
  ): Promise<FieldsAndModulesModel> {
    return this.getClearingCompaniesResource(id)
      .patchWithQuery<IElementRestNg<FieldsAndModulesModel>>({ id, isDefault })
      .then((patchedModel) => patchedModel);
  }
}

export default ClearingCompaniesService;
