import * as _ from '@proftit/lodash';
import stringLength from 'string-length';

/**
 * Caluclate validation for max code point points of string model.
 *
 * @param value
 * @param scope
 * @param element
 * @param attrs
 * @param param
 */
export function maxCodepoints(value, scope, element, attrs, param) {
  const maxLength = parseInt(param, 10);

  if (!_.isFinite(maxLength)) {
    throw new Error('maxCodepoints param is not a number');
  }

  /*
   * This techniqe is marked out because for some reason
   * in the browser console it works, but in the browser execution, it does not.
   * Maybe the native support for spread vs the transpile code strategy do not do
   * the same thing for the length. Need to check further. For now, using regex test.
   */
  // const size = [...value].length;
  const size = stringLength(value);

  return size <= maxLength;
}
