import { useStreams } from '@proftit/rxjs.adjunct';
import {
  observeShareCompChange,
  ComponentLifecyclesObservables,
} from '@proftit/rxjs.adjunct.ng1';

export function useResolve<T>(lifecycles: ComponentLifecyclesObservables) {
  const resolve$ = observeShareCompChange<T>(lifecycles.onChanges$, 'resolve');

  useStreams([resolve$], lifecycles.onDestroy$);

  return {
    resolve$,
  };
}
