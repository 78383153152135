import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './crypto-ewallet.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { Brand } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PreselectType } from '~/source/common/components/dropdowns/base/component';
import BrandsService from '~/source/management/brand/services/brands';
const styles = require('./crypto-ewallet.component.scss');

const LAST_SELECTED_BRAND_ID_KEY =
  'prf.custom.management.integration.ewallet.cryptoEwallet.lastSelectedBrandId';

export class CryptoEwalletController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  PreselectType = PreselectType;

  reloadEwalletsAction = new rx.Subject<void>();

  brandFormControl = new FormControl<Brand>(null);
  ewalletsForBrand$ = this.streamEwalletsForBrand();

  /* @ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    useStreams(
      [
        this.brandFormControl.value$,
        this.streamSaveSelectedBrandIdToLocalStorage(),
        this.ewalletsForBrand$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamEwalletsForBrand() {
    return rx.pipe(
      () =>
        rx.obs.merge(this.brandFormControl.value$, this.reloadEwalletsAction),
      rx.withLatestFrom(this.brandFormControl.value$),
      rx.filter(([a, brand]) => !_.isNil(brand)),
      rx.switchMap(([a, brand]) => {
        return rx.obs
          .from(
            this.brandsService()
              .expand(['currency'])
              .getBrandEwalletCurrencies(brand.id),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  preselectLastSelectedBrand(brands: Brand[]) {
    const storedValue = localStorage.getItem(LAST_SELECTED_BRAND_ID_KEY);
    if (_.isNil(storedValue)) {
      return null;
    }

    const lastSelectedBrandId = Number(storedValue);
    return brands.find((brand) => brand.id === lastSelectedBrandId);
  }

  streamSaveSelectedBrandIdToLocalStorage() {
    return rx.pipe(
      () => this.brandFormControl.value$,
      rx.filter((value) => !_.isNil(value)),
      rx.tap((value) => {
        localStorage.setItem(LAST_SELECTED_BRAND_ID_KEY, String(value.id));
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const CryptoEwalletComponent = {
  template,
  controller: CryptoEwalletController,
};
