import { IHttpService, IHttpPromise } from 'angular';
// import Url from 'url-parse';
// import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  // init,
  // setProtocol,
  // setHost,
  // setResource,
  // addHeader,
  // getAsList,
  // create as genReqCreate,
  initRequestDomainResource,
  getAsList,
  setTokenHeader,
  cfdMongo,
} from '@proftit/request-client';

import { Mt4Asset } from '@proftit/tradingcore.api.models.entities';
// import { removeColonAtEnd } from '@proftit/general-utilities';
// import { PlatformConnection } from '~/source/common/models/platform-connection';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ngPerformMongo } from '../utilities/ng-perform-mongo';

export const RESOURCE_PATH = 'MTAssets';

export class ApiMt4AssetsService {
  /* @ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  getAssets(apiUrl: string, token: string): Promise<Mt4Asset[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => ngPerformMongo<Mt4Asset[]>(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  getAssetsSlice(
    apiUrl: string,
    token: string,
    take: number,
    skip: number,
    query: Record<string, any>,
  ): IHttpPromise<Mt4Asset[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => cfdMongo.setTake(take, req),
      (req) => cfdMongo.setSkip(skip, req),
      (req) => (query ? cfdMongo.setQuery(query, req) : req),
      (req) => getAsList(req),
      (req) => ngPerformMongo<Mt4Asset[]>(this.$http, req),
    ])();
  }
}
