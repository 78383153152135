import * as _ from '@proftit/lodash';

import RestService from '~/source/common/services/rest';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { getCfdPlatformPrivateAccessInfoCredentials } from '~/source/common/models/platform-connection/get-cfd-platform-private-access-info-credentials';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import {
  InactivityFeeSetting,
  Brand,
  CommunicationProviderCredentials,
  Desk,
  AssignmentCounts,
  AccountStatusAutomationRule,
  FtdAutoAssignment,
  BrandPlatform,
  AccountAllowedActions,
  BrandDepositWireAccount,
} from '@proftit/crm.api.models.entities';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { INACTIVITY_FEE_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { SimpleCacheService } from '~/source/common/services/simple-cache.service';
import { CacheOptions } from 'cachefactory';
import moment from 'moment';
import { IPromise } from 'angular';
import { CfdPlatforms, PlatformCode } from '@proftit/crm.api.models.enums';
import { TimeInterval } from '@proftit/constants.time';
import { UserBrandPlatformSession } from '~/source/common/models/user-brand-platform-session';

export interface FtdAutoAssigmentData {
  isNotAssigneeFtdAutoAssignmentEnabled: boolean;
  isAssigneeFtdAutoAssignmentEnabled: boolean;
  isAssigneeFtdAutoAssignmentWithDelayEnabled: boolean;
  isAssigneeFtdAutoAssignmentByCustomerStatusEnabled: boolean;
  ftdAutoAssignmentDelay: number;
  ftdAutoAssignmentCustomerStatusId: number;
  ftdAutoAssignments: FtdAutoAssignment[];
}

const SERVICE_RESOURCE = 'brands';
const CLEARING_LIST = 'clearingCompanyLists';
const PLATFORMS_RESOURCE = 'platforms';
const PLATFORMS_CONNECTION_RESOURCE = 'platformConnections';
const SESSIONS_RESOURCE = 'sessions';
const VOIP_PROVIDER_RESOURCE = 'voipProviders';
const CASHIER_RESOURCE = 'cashiers';
const CURRENCIES_RESOURCE = 'currencyConnections';
const BLACKLISTED_COUNTRIES_RESOURCE = 'blacklistedCountries';
const DESKS_RESOURCE = 'desks';
const GROUPS_RESOURCE = 'groups';
const BANK_ACCOUNT_RESOURCE = 'bankAccounts';
const BONUS_PACKAGES_RESOURCE = 'bonusPackages';
const THREE_D_RESOURCE = 'threeDConnections';
const NOTIFICATIONS_RESOURCE = 'notificationSettings';
const BATCH_ACTION_INDICATION = '~';
export const PLATFROM_CREDENTIAL_PUBLIC_API_TOKEN = 'publicApiToken';
const PLATFROM_CREDENTIAL_API_URL = 'url';
const PLATFORM_CREDENTIAL_USERNAME = 'username';
const PLATFORM_CREDENTIAL_PASSWORD = 'password';
export const PLATFROM_CREDENTIAL_STREAMER_URL = 'streamerUrl';
const INACTIVITY_FEE_SETTINGS_RESOURCE = 'inactivityFeeSettings';
const INACTIVITY_FEE_SETTING_SINGLE_RESOURCE = 'inactivityFeeSetting';
const LOGS = 'logs';
const PUBLIC_CREDENTIALS = 'publicCredentials';
const CLEARING_COMPANIES = 'clearingCompanies';
const PUBLIC_KEY = 'publicKey';
const DIDS_MAPPED_RESOURCE = 'didsMapped'; // means: dids numbers that are mapped to this brand/department.
const SMS_MAPPED_NUMBERS = 'smsNumbersMapped'; // means: dids numbers that are mapped to this brand/department.
const BRANDS_DIDS_CACHE_KEY = 'didsBrands';
const ACCOUNT_STATUS_AUTOMATION = 'accountStatusAutomation';
const DEMO_DEPOSIT_LOGS_RESOURCE = 'demoDepositLogs';
const ACCOUNT_STATUS_AUTOMATION_LOGS_RESOURCE = 'accountStatusAutomationLogs';
const AUTO_ASSIGNMENT_LOGS = 'autoAssignmentLogs';
const FTD_AUTO_ASSIGNMENT_LOGS = 'ftdAutoAssignmentLogs';
const CONTACTS_IMPORTS = 'contactsImports';
const ASSIGNMENT_COUNTS = 'assignmentCounts';
const VOIP_CREDENTIALS = 'voipCredentials';
const SMS_CREDENTIALS = 'smsCredentials';
const SMS_PROVIDERS = 'smsProviders';
const EMAIL_CREDENTIALS = 'emailCredentials';
const ALLOWED_ACTIONS = 'allowedActions';
const EWALLETS_RESOURCE = 'ewallets';
const EWALLET_CURRENCY_VIEWS_RESOURCE = 'ewalletCurrencyViews';
const EWALLET_PROVIDERS_RESOURCE = 'ewalletProviders';

export function calcGetDidsCacheName(brandId: number, voipProviderId: number) {
  return `${BRANDS_DIDS_CACHE_KEY}_${brandId}_${VOIP_PROVIDER_RESOURCE}_${voipProviderId}`;
}

export class BrandsService extends RestService {
  static $inject = [
    ...RestService.$inject,
    'prfClientGeneralPubsub',
    'prfSimpleCacheService',
    'Upload',
  ];

  prfClientGeneralPubsub: ClientGeneralPubsub;
  prfSimpleCacheService: SimpleCacheService;
  Upload: any;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  get name() {
    return 'brands';
  }

  getBrandResource(brandId) {
    return this.resourceBuildStart().getElement(brandId).resourceBuildEnd();
  }

  /**
   * Build url to list of currencies related to given brand
   *
   * @param {int} brandId
   * @return {RestService}
   */
  getCurrenciesResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(CURRENCIES_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * normalize currencies for custom put collection update
   * @param {Array} selectedCurrencies array of objects
   * @returns {Array}
   */
  normalizeCurrencies(selectedCurrencies) {
    return selectedCurrencies.map((currency) => ({
      currencyId: currency.currency.id,
      minimumDeposit: currency.minimumDeposit || 0,
      minimumDepositCustomer: currency.minimumDepositCustomer || 0,
      isDefault:
        typeof currency.isDefault === 'boolean' ? currency.isDefault : false,
    }));
  }

  /**
   * Update user group ips collection. we use restangular customPUT method in order to batch update the ip collection
   *
   * @param {Number} brandId
   * @param {Array} currencies
   * @returns {Promise}
   */
  updateCurrencies(brandId, currenciesObj) {
    const normalizedCurrencies = this.normalizeCurrencies(currenciesObj);

    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(CURRENCIES_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(normalizedCurrencies);
  }

  /**
   * get country restrictions resource
   * @param {number} brandId
   * @returns {RestService}
   */
  getBacklistedCountriesResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(BLACKLISTED_COUNTRIES_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Update user group ips collection. we use restangular customPUT method in order to batch update the ip collection
   *
   * @param {Number} brandId
   * @param {Array} countries
   * @returns {Promise}
   */
  updateBacklistedCountries(brandId, countries) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(BLACKLISTED_COUNTRIES_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(countries);
  }

  /**
   * 3D's resource.
   * @param brandId
   * @returns {RestService}
   */
  getThreeDResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(THREE_D_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * 3D's resource.
   * @param brandId
   * @param threeDSettingsId
   * @returns {RestService}
   */
  getThreeDSpecificResource(brandId, threeDSettingsId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(THREE_D_RESOURCE, threeDSettingsId)
      .resourceBuildEnd();
  }

  /**
   * Post 3D settings object.
   *
   * @param {Number} brandId
   * @param {Array} threeDSettings
   * @returns {Promise}
   */
  postThreeD(brandId, threeDSettings) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(THREE_D_RESOURCE)
      .resourceBuildEnd()
      .postWithQuery(threeDSettings);
  }

  /**
   * normalize 3D's connection currencies for custom collection
   * @param {array} threeDConnections array of objects
   * @returns {array} - minimumDeposits
   */
  normalizeThreeDMinimumDeposit(minimumDeposits) {
    return minimumDeposits.map((currencyConnections) => ({
      id: currencyConnections.id,
      value: currencyConnections.value,
      currencyId: currencyConnections.currency.id,
    }));
  }

  /**
   * notifications resource.
   * @param brandId
   * @returns {RestService}
   */
  getNotificationsResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(NOTIFICATIONS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Update notification collection. we use restangular customPUT method in order to batch update notifications
   *
   * @param {Number} brandId
   * @param {Array} notifications
   * @returns {Promise}
   */
  updateNotifications(brandId, notifications) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(NOTIFICATIONS_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(notifications);
  }

  /**
   * Build url to list of platforms related to given brand
   *
   * @param {int} brandId
   * @return {RestService}
   */
  getPlatformsResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(PLATFORMS_CONNECTION_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url of a specific brand's platform
   *
   * @param {int} brandId
   * @param {int} platformId
   * @return {RestService}
   */
  getPlatformResource(brandId, platformId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(PLATFORMS_CONNECTION_RESOURCE, platformId)
      .resourceBuildEnd();
  }

  getBrandPlatformConnectionsAllowedActionsResource(
    brandId: number,
    platformId: number,
  ): RestService {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(PLATFORMS_CONNECTION_RESOURCE, platformId)
      .getNestedCollection(ALLOWED_ACTIONS)
      .resourceBuildEnd();
  }

  getBrandPlatformConnectionsAllowedActionsResourceForUpdate(
    brandId: number,
    platformId: number,
  ): RestService {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(PLATFORMS_CONNECTION_RESOURCE, platformId)
      .getNestedElement(ALLOWED_ACTIONS, BATCH_ACTION_INDICATION)
      .resourceBuildEnd();
  }

  getBrandPlatformConnectionsAllowedActions(
    brandId: number,
    platformId: number,
  ): Promise<any> {
    return this.getBrandPlatformConnectionsAllowedActionsResource(
      brandId,
      platformId,
    )
      .expand(['accountStatus', 'action'])
      .getListWithQuery<IElementRestNg<AccountAllowedActions>>()
      .then((data) => data.plain());
  }

  updateBrandPlatformConnectionsAllowedActions(
    brandId: number,
    platformId: number,
    accountAllowedActions: AccountAllowedActions[],
  ): Promise<any> {
    return this.getBrandPlatformConnectionsAllowedActionsResourceForUpdate(
      brandId,
      platformId,
    )
      .expand(['accountStatus', 'action'])
      .customPutWithQuery(accountAllowedActions)
      .then((data) => data.plain());
  }

  /**
   * Build url of a specific brand's platform session
   *
   * @param brandId
   * @param platformId
   * @return
   */
  getBrandPlatformSessionResource(brandId: number, platformId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(PLATFORMS_CONNECTION_RESOURCE, platformId)
      .getNestedCollection(SESSIONS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url of a brand's Cashier
   *
   * @param {int} brandId
   * @return {RestService}
   */
  getCashiersResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(CASHIER_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url to list of desks related to given brand
   *
   * @param {int} brandId
   * @return {RestService}
   */
  getDesksResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(DESKS_RESOURCE)
      .resourceBuildEnd();
  }

  getDesks(brandId: number): Promise<Desk[]> {
    return this.getDesksResource(brandId)
      .getListWithQuery<IElementRestNg<Desk>>()
      .then((data) => data.plain());
  }

  /**
   * Build url of a specific brand's desk
   *
   * @param {int} brandId
   * @param {int} deskId
   * @return {RestService}
   */
  getDeskResource(brandId, deskId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(DESKS_RESOURCE, deskId)
      .resourceBuildEnd();
  }

  /**
   * Build url to list of sms numbers related to given brand
   *
   * @param brandId
   * @return resource builder
   */
  getMappedDidsSmsNumbersResource(brandId: number, smsProviderId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(SMS_PROVIDERS, smsProviderId)
      .getNestedCollection(SMS_MAPPED_NUMBERS)
      .resourceBuildEnd();
  }

  getInactivityFeeSettingsResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(INACTIVITY_FEE_SETTINGS_RESOURCE)
      .resourceBuildEnd();
  }

  getInactivityFeeSettingResource(brandId, feeId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(INACTIVITY_FEE_SETTINGS_RESOURCE, feeId)
      .resourceBuildEnd();
  }

  getInactivityFeeSettingLogsResource(brandId, feeId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(INACTIVITY_FEE_SETTING_SINGLE_RESOURCE, feeId)
      .getNestedCollection(LOGS)
      .resourceBuildEnd();
  }

  getDemoDepositLogsResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(DEMO_DEPOSIT_LOGS_RESOURCE)
      .resourceBuildEnd();
  }

  getAccountStatusAutomationLogsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(ACCOUNT_STATUS_AUTOMATION_LOGS_RESOURCE)
      .resourceBuildEnd();
  }

  getAutoAssignmentLogsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(AUTO_ASSIGNMENT_LOGS)
      .resourceBuildEnd();
  }

  getFtdAutoAssignmentLogsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(FTD_AUTO_ASSIGNMENT_LOGS)
      .resourceBuildEnd();
  }

  /**
   * get account status automation list resource
   * @param brandId
   * @return configured rest service for the resource.
   */
  getAccountStatusAutomationListResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(ACCOUNT_STATUS_AUTOMATION)
      .resourceBuildEnd();
  }

  /**
   * get account status automation item resource
   * @param brandId
   * @param feeId
   * @return configured rest service for the resource.
   */
  getAccountStatusAutomationItemResource(brandId, feeId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(ACCOUNT_STATUS_AUTOMATION, feeId)
      .resourceBuildEnd();
  }

  /**
   * get account status automation list
   * @param brandId
   * @return account status automation list request
   */
  getAccountStatusAutomationList(brandId) {
    return this.getAccountStatusAutomationListResource(brandId)
      .getListWithQuery()
      .then((data) => data.plain());
  }

  /**
   * Build url to list of bank accounts related to given brand
   *
   * @param {int} brandId
   * @returns {RestService}
   */
  getBankAccountResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(BANK_ACCOUNT_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url to list of bank accounts related to given brand
   *
   * @param brandId
   * @param bankAccountId
   * @returns {RestService}
   */
  getBankAccountItemResource(brandId: number, bankAccountId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(BANK_ACCOUNT_RESOURCE, bankAccountId)
      .resourceBuildEnd();
  }

  /**
   * Build url for bonus packages of specific brand
   *
   * @param {int} brandId
   * @returns {RestService}
   */
  getBonusPackagesResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(BONUS_PACKAGES_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url for clearing priorities list of specific brand
   *
   * @param {int} brandId
   * @returns {RestService}
   */
  getClearingPrioritiesListResource(brandId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(CLEARING_LIST)
      .resourceBuildEnd();
  }

  /**
   * Detect the platform type that is common to all the brands
   * If all the brands have the same platform type, it will be returned.
   * Otherwise, "common" will be returned.
   * @param {array} brands - all brands array
   * @return {string} platform type: binary/forex/common.
   */
  detectCommonPlatformType(brands) {
    const firstItemType = brands[0].platformType.code;

    // If all the types match the first type, it means there is only one platform type
    const allTypeSimilar = brands.every(
      (brand) => brand.platformType.code === firstItemType,
    );

    return allTypeSimilar ? firstItemType : 'common';
  }

  /**
   * Build url to list the groups related to specific brand and platform.
   * @param {number} brandId - brand id
   * @param {number} platformId - platform id
   * @return {RestService} - self instance, for chaining
   */
  getGroupsResource(brandId, platformId) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(PLATFORMS_RESOURCE, platformId)
      .getNestedCollection(GROUPS_RESOURCE)
      .resourceBuildEnd();
  }

  getBrandsCfdPlatformConnections() {
    return this.embed(['platformConnections'])
      .expand(['platformType', 'platformConnections.platform'])
      .getListWithQuery<IElementRestNg<Brand>>()
      .then((data) => data.plain())
      .then((brands) => {
        const brandGroupedCfdPlatformConns = brands.map((brand) =>
          brand.platformConnections.filter((conn) =>
            CfdPlatforms.includes(conn.platform.code),
          ),
        );
        return _.flatten(brandGroupedCfdPlatformConns);
      });
  }

  /**
   * Get dids phone list query.
   *
   * The raw query with no special cache behind.
   *
   * @param brandId
   * @return promise of the work.
   */
  getMappedDidsPhonesListQuery(brandId: number, voipProviderId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(VOIP_PROVIDER_RESOURCE, voipProviderId)
      .getNestedCollection(DIDS_MAPPED_RESOURCE)
      .resourceBuildEnd()
      .getListWithQuery()
      .then((results) => results.plain())
      .then((results) => {
        return results;
      })
      .catch(() => {
        return [];
      });
  }

  /**
   * Get dids phone list with result caching.
   *
   * @param brandId
   * @return promise of the work or promise of the imidiate results.
   */
  getDidsPhonesListWithResultCache(brandId: number, voipProviderId: number) {
    const queryPromise = this.getMappedDidsPhonesListQuery(
      brandId,
      voipProviderId,
    );

    return queryPromise;
  }

  /**
   * get dids phones list with cache of the promised work also.
   *
   * @param brandId - brand id
   * @return promise of either the existing results or existing work promise.
   */
  getDidsPhonesList(brandId: number, voipProviderId: number) {
    const cacheKey = calcGetDidsCacheName(brandId, voipProviderId);
    const cachedQueryPromise = this.prfSimpleCacheService.get(cacheKey);
    if (!_.isNil(cachedQueryPromise)) {
      return cachedQueryPromise;
    }

    const queryPromise = this.getDidsPhonesListWithResultCache(
      brandId,
      voipProviderId,
    );
    const cachingPromise = (queryPromise as any).finally(() =>
      this.prfSimpleCacheService.remove(cacheKey),
    );

    this.prfSimpleCacheService.put(cacheKey, cachingPromise);

    return queryPromise;
  }

  /**
   * Clear caches for brand dids.
   *
   * @param brandId
   **/
  clearBrandDidsCash(brandId: number, voipProviderId: number) {
    const cacheKey = calcGetDidsCacheName(brandId, voipProviderId);
    this.cacheRemove(cacheKey);
  }

  /**
   * get public credentials
   * @param brandId - brand id
   * @param clearingCompanyId - clearing company id
   * @return {RestService}
   */
  getPublicCredentials(brandId: number, clearingCompanyId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(CLEARING_COMPANIES, clearingCompanyId)
      .getNestedCollection(PUBLIC_CREDENTIALS)
      .resourceBuildEnd()
      .getListWithQuery()
      .then((credentials) =>
        credentials.find((cred) => (cred as any).key === PUBLIC_KEY),
      );
  }

  getBrandPlatformCredentials(brandParam, platformCode: string) {
    return this.embed(['platformConnections'])
      .expand(['platformConnections.platform'])
      .getOneWithQuery<IElementRestNg<Brand>>(brandParam.id)
      .then((data) => data.plain())
      .then((brand) => _.defaultTo([], brand.platformConnections))
      .then((platformConns) =>
        platformConns.find((conns) => conns.platform.code === platformCode),
      )
      .then((cfdConn) => _.get('credentials', cfdConn))
      .then((creds) => _.defaultTo([], creds));
  }

  /**
   * Get cfd streamer url.
   *
   * @param brand - brand
   * @return streamer url.
   */
  getCfdPlatformApiStreamerUrl(brand: Brand) {
    return this.getBrandPlatformCredentials(brand, PlatformCode.Cfd).then(
      (credentials) => {
        const streamerUrl = _.get(
          'value',
          credentials.find(
            (cred) => cred.key === PLATFROM_CREDENTIAL_STREAMER_URL,
          ),
        );

        return streamerUrl;
      },
    );
  }

  /**
   * Get Bundle streamer url.
   *
   * @param brand - brand
   */
  getBundlePlatformApiStreamerUrl(brand: Brand) {
    return this.getBrandPlatformCredentials(brand, PlatformCode.Bundle).then(
      (credentials) => {
        const streamerUrl = _.get(
          'value',
          credentials.find(
            (cred) => cred.key === PLATFROM_CREDENTIAL_STREAMER_URL,
          ),
        );

        return streamerUrl;
      },
    );
  }

  /**
   * Add inactivit fee.
   *
   * Notify pubsub.
   *
   * @param brandId: brand id.
   * @param fee: fee.
   */
  addInactivityFee(brandId: number, fee: InactivityFeeSetting) {
    return this.getInactivityFeeSettingsResource(brandId)
      .postWithQuery<IElementRestNg<InactivityFeeSetting>>(fee)
      .then((x) => {
        this.prfClientGeneralPubsub.publish(INACTIVITY_FEE_ADDED, x);
        return x;
      });
  }

  /**
   * Get item
   *
   * Retrive brand info from server.
   *
   * @return - work promise of then brand retrival.
   */
  getItem(id: number) {
    return this.getOneWithQuery<IElementRestNg<Brand>>(id).then((data) =>
      data.plain(),
    );
  }

  getPlatformConnectionList(brandId: number): Promise<PlatformConnection[]> {
    return this.getPlatformsResource(brandId)
      .expand(['platform'])
      .getListWithQuery<IElementRestNg<PlatformConnection>>()
      .then((data) => data.plain());
  }

  /*
   * Get Brand platform for brand by platform code.
   */
  getBrandPlatformPerPlatform(
    brandId: number,
    platformCode: PlatformCode,
  ): Promise<BrandPlatform> {
    return this.getPlatformConnectionList(brandId).then((brandPlatforms) =>
      brandPlatforms.find((bp) => bp.platform.code === platformCode),
    );
  }

  /**
   * Get a brand platform connection item.
   *
   * @param brandId.
   * @param platformConnectionId.
   * @return - work promise of the platform connection retrival.
   */
  getPlatformConnectionItem(
    brandId: number,
    platformConnectionId: number,
  ): Promise<PlatformConnection> {
    return this.getPlatformsResource(brandId)
      .expand(['platform'])
      .getListWithQuery<IElementRestNg<PlatformConnection>>()
      .then((data) => data.plain())
      .then(
        (data) =>
          data.find(
            (platformConn) => platformConn.id === platformConnectionId,
          ) as PlatformConnection,
      );
  }

  getBrandDemoDepositInfo(brandId: number, config: any) {
    return this.embed(['currencyConnections'])
      .expand(['currencyConnections.currency'])
      .setConfig(config)
      .getOneWithQuery<IElementRestNg<Brand>>(brandId)
      .then((data) => data.plain());
  }

  updateDemoDeposit(
    brandId: number,
    isDemoDepositActive: boolean,
    currencyConnections,
  ) {
    return this.getBrandResource(brandId).patchWithQuery({
      isDemoDepositActive,
      currencyConnections,
    });
  }

  createSmsCredentials(
    brandId: number,
    smsProviderId: number,
    credentials: CommunicationProviderCredentials[],
  ) {
    return this.getSmsCredentialsCollectionResource(brandId)
      .expand('smsProvider')
      .postWithQuery<IElementRestNg<Brand>>({
        smsProviderId,
        credentials,
      });
  }

  updateSmsProvider(
    brandId: number,
    smsProviderId: number,
    smsCredentialsId: number,
    credentials: CommunicationProviderCredentials[],
    mappedDids: any,
  ) {
    return this.getSmsCredentialsSingleResource(brandId, smsCredentialsId)
      .expand('smsProvider')
      .patchWithQuery<IElementRestNg<Brand>>({
        smsProviderId,
        credentials,
        mappedDids,
      });
  }

  updateBrandFtdAutoAssigment(
    brandId: number,
    ftdAutoAssgnmentsData: FtdAutoAssigmentData,
  ): Promise<Brand> {
    return this.getBrandResource(brandId)
      .patchWithQuery<IElementRestNg<Brand>>(ftdAutoAssgnmentsData)
      .then((data) => data.plain());
  }

  getVoipCredentialsCollectionResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(VOIP_CREDENTIALS)
      .resourceBuildEnd();
  }

  getVoipCredentialsSingleResource(brandId: number, voipCredentialsId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(VOIP_CREDENTIALS, voipCredentialsId)
      .resourceBuildEnd();
  }

  getSmsCredentialsCollectionResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(SMS_CREDENTIALS)
      .resourceBuildEnd();
  }

  getSmsCredentialsSingleResource(brandId: number, smsCredentialsId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(SMS_CREDENTIALS, smsCredentialsId)
      .resourceBuildEnd();
  }

  getEmailCredentialsCollectionResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(EMAIL_CREDENTIALS)
      .resourceBuildEnd();
  }

  getEmailCredentialsSingleResource(
    brandId: number,
    emailCredentialsId: number,
  ) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(EMAIL_CREDENTIALS, emailCredentialsId)
      .resourceBuildEnd();
  }

  updateVoipProvider(
    brandId: number,
    voipProviderId: number,
    voipCredentialsId: number,
    credentials: CommunicationProviderCredentials[],
    shouldShowVoip: boolean,
    isDefault: boolean,
    mappedDids: any,
  ) {
    return this.getVoipCredentialsSingleResource(
      brandId,
      voipCredentialsId,
    ).patchWithQuery<IElementRestNg<Brand>>({
      voipProviderId,
      credentials,
      shouldShowVoip,
      isDefault,
      mappedDids,
    });
  }

  addVoipProvider(
    brandId: number,
    voipProviderId: number,
    credentials: CommunicationProviderCredentials[],
    shouldShowVoip: boolean,
    isDefault: boolean,
  ) {
    return this.getVoipCredentialsCollectionResource(brandId).postWithQuery({
      voipProviderId,
      credentials,
      shouldShowVoip,
      isDefault,
    });
  }

  deleteVoipProviderConfiguration(
    brandId: number,
    voipProviderConfigId: number,
  ) {
    return this.getVoipCredentialsSingleResource(
      brandId,
      voipProviderConfigId,
    ).removeWithQuery();
  }

  updateSecuritySettings(
    brandId: number,
    isCaptchaEnabled: boolean,
    captchaSiteKey: string,
    captchaSecretKey: string,
    is2faSignupEnabled: boolean,
    is2faLoginEnabled: boolean,
    is2faPswdResetEnabled: boolean,
  ) {
    return this.getBrandResource(brandId).patchWithQuery<IElementRestNg<Brand>>(
      {
        isCaptchaEnabled,
        captchaSiteKey,
        captchaSecretKey,
        is2faSignupEnabled,
        is2faLoginEnabled,
        is2faPswdResetEnabled,
      },
    );
  }

  updateBrandAccountStatusAutomations(
    brandId: number,
    isAccountStatusAutomationActive: boolean,
    accountStatusAutomations: any[],
  ) {
    return this.getBrandResource(brandId)
      .patchWithQuery({
        isAccountStatusAutomationActive,
        accountStatusAutomations,
      })
      .then((brand) => brand.plain());
  }

  getContactsImportsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(CONTACTS_IMPORTS)
      .resourceBuildEnd();
  }

  getAssignmentCountsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(ASSIGNMENT_COUNTS)
      .resourceBuildEnd();
  }

  getAssignmentCounts(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(ASSIGNMENT_COUNTS)
      .resourceBuildEnd()
      .getListWithQuery<IElementRestNg<AssignmentCounts>>()
      .then((data) => data.plain());
  }

  getBrandDeskAssignmentCountsResource(
    brandId: number,
    deskId: number,
  ): BrandsService {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(DESKS_RESOURCE, deskId)
      .getNestedCollection(ASSIGNMENT_COUNTS)
      .resourceBuildEnd();
  }

  getBrandDeskAssignmentCounts(
    brandId: number,
    deskId: number,
  ): Promise<AssignmentCounts[]> {
    return this.getBrandDeskAssignmentCountsResource(brandId, deskId)
      .getListWithQuery<IElementRestNg<AssignmentCounts>>()
      .then((data) => data.plain());
  }

  contactsImportsUploadFile(brandId: number, file, options: any = {}) {
    /**
     * Restangular unforutnely does not easily support file uploading
     * @see {@link https://github.com/mgonto/restangular/issues/420}
     *
     * so we use this ng-file-upload service instead, which is much better for uploading.
     * @see {@link https://github.com/danialfarid/ng-file-upload}
     */
    return this.Upload.upload({
      url: `${this.baseUrl}/${this.resource}/${brandId}/${CONTACTS_IMPORTS}`,
      data: { file },
      ...options,
    });
  }

  updateIsMultipleWithdrawalsEnabled(
    brandId: number,
    isMultipleWithdrawalsEnabled: boolean,
  ) {
    return this.getBrandResource(brandId)
      .patchWithQuery<IElementRestNg<Brand>>({
        isMultipleWithdrawalsEnabled,
        id: brandId,
      })
      .then((res) => {
        return res.plain();
      });
  }

  getDownloadContactsImportErrorReportPath(brandId: number, fileId: number) {
    return `${brandId}/contactsImports/${fileId}/downloads/errors`;
  }

  downloadContactsImportErrorReport(brandId: number, fileId: number) {
    const customPath = this.getDownloadContactsImportErrorReportPath(
      brandId,
      fileId,
    );
    return this.getWithCustomPath(customPath, { responseType: 'blob' });
  }

  getAccountStatusAutomationRulesResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection('accountStatusAutomationRules')
      .resourceBuildEnd();
  }

  getAccountStatusAutomationRuleResource(brandId: number, ruleId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement('accountStatusAutomationRules', ruleId)
      .resourceBuildEnd();
  }

  getAccountStatusAutomationRules(brandId: number) {
    return this.getAccountStatusAutomationRulesResource(brandId)
      .embed(['accountStatusAutomationMaps'])
      .expand([
        'type',
        'accountStatusAutomationMaps.customerVerifiedStatus',
        'accountStatusAutomationMaps.accountStatus',
      ])
      .getListWithQuery()
      .then((res) => res.plain());
  }

  postAccountStatusAutomationRules(
    brandId: number,
    data: AccountStatusAutomationRule,
  ) {
    return this.getAccountStatusAutomationRulesResource(brandId)
      .embed(['accountStatusAutomationMaps'])
      .expand([
        'type',
        'accountStatusAutomationMaps.customerVerifiedStatus',
        'accountStatusAutomationMaps.accountStatus',
      ])
      .postWithQuery(data)
      .then((res) => res.plain());
  }

  patchAccountStatusAutomationRule(
    brandId: number,
    ruleId: number,
    data: AccountStatusAutomationRule,
  ) {
    return this.getAccountStatusAutomationRuleResource(brandId, ruleId)
      .embed(['accountStatusAutomationMaps'])
      .expand([
        'type',
        'accountStatusAutomationMaps.customerVerifiedStatus',
        'accountStatusAutomationMaps.accountStatus',
      ])
      .patchWithQuery(data)
      .then((res) => res.plain());
  }

  /*
   * Create platform session through the CRM.
   *
   * @param brandId
   * @param brandPlatformId
   * @return session including auth token to be used in platform connections.
   */
  createPlatformSession(
    brandId: number,
    brandPlatformId: number,
  ): Promise<UserBrandPlatformSession> {
    return this.getBrandPlatformSessionResource(brandId, brandPlatformId)
      .postWithQuery<IElementRestNg<UserBrandPlatformSession>>({})
      .then((data) => data.plain());
  }

  updateBrandDepositWireAccount(
    brandId: number,
    brandWireId: number,
    brandWire: BrandDepositWireAccount,
  ): Promise<BrandDepositWireAccount> {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement('bankAccounts', brandWireId)
      .resourceBuildEnd()
      .expand(['currency'])
      .patchWithQuery(brandWire) as any;
  }

  getBrandEwalletCurrencies(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(EWALLET_CURRENCY_VIEWS_RESOURCE)
      .resourceBuildEnd()
      .getListWithQuery();
  }

  getBrandEwalletsResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(EWALLETS_RESOURCE)
      .resourceBuildEnd();
  }

  getBrandEwalletProviders(brandId: number, expand = []) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(EWALLET_PROVIDERS_RESOURCE)
      .resourceBuildEnd()
      .embed(['currencies'])
      .expand(expand)
      .getListWithQuery();
  }

  patchBrandEwalletProviders(
    brandId: number,
    ewalletProviderId: number,
    data: any,
  ) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(EWALLET_PROVIDERS_RESOURCE, ewalletProviderId)
      .resourceBuildEnd()
      .patchWithQuery(data);
  }

  createBrandEwalletProvider(brandId: number, data: any) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedCollection(EWALLET_PROVIDERS_RESOURCE)
      .resourceBuildEnd()
      .postWithQuery(data);
  }

  getBrandEwalletsMassUpdateResource(brandId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(EWALLETS_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd();
  }

  getBrandEwalletCurrenciesResource(brandId: number, ewalletId: number) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(EWALLET_CURRENCY_VIEWS_RESOURCE, ewalletId)
      .resourceBuildEnd();
  }

  createPrivateEwalletAddress(
    brandId: number,
    ewalletProviderID: number,
    data: {
      tradingAccountId: number;
      currencyId: string;
    },
  ) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(EWALLET_PROVIDERS_RESOURCE, ewalletProviderID)
      .getNestedCollection('generateAddress')
      .resourceBuildEnd()
      .postWithQuery(data);
  }

  patchBrandEwalletCurrenciesOrder(
    brandId: number,
    payload: { id: number; currencyId: number; order: number }[],
  ) {
    return this.resourceBuildStart()
      .getElement(brandId)
      .getNestedElement(
        EWALLET_CURRENCY_VIEWS_RESOURCE,
        BATCH_ACTION_INDICATION,
      )
      .resourceBuildEnd()
      .expand('currency')
      .patchWithQuery(payload);
  }
}
export default BrandsService;
