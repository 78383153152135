import Component from './base/component';
import { Customer, Brand } from '@proftit/crm.api.models.entities';
import { CustomersService } from '~/source/contact/common/services/customers';
import { ItemLabelStrategy } from './base/item-label-strategy';
import {
  observeShareCompChange,
  observeComponentLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ContentTemplatesService } from '~/source/common/api-crm-server/services/content-templates.service';

class Controller extends Component.controller<ContentTemplatesService> {
  static $inject = ['contentTemplatesService', ...Component.controller.$inject];

  lifecycles = observeComponentLifecycles(this);

  brand: Brand;

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  constructor(d, ...args) {
    super(d, ...args);

    useStreams([this.streamFetchData()], this.lifecycles.onDestroy$);
  }

  streamFetchData() {
    return rx.pipe(
      () => this.brand$,
      rx.tap((brand) => {
        this.fetchData();
      }),
    )(null);
  }

  /*
   * Override default imediate fetch.
   */
  init() {}

  get defaultPlaceholder() {
    return 'availableLanguagesForSendForBrandSelectComponent.PLACEHOLDER';
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return this.dataServiceInst
      .getAllCustomForBrand(this.brand)
      .then((templates) => templates.map((t) => t.language))
      .then((languages) => _.uniqBy((l) => l.id, languages));
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.name;
  }
}

export const AvailableLanguagesForSendForBrandSelectComponent = Component.config(
  {
    controller: Controller,
    bindings: {
      brand: '<',
    },
  },
);
