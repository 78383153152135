import ng from 'angular';
import { Brand } from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { CustomerLoginTypeCode } from '@proftit/crm.api.models.enums';
import template from './brand-login-method-data.component.html';
const styles = require('./brand-login-method-data.component.scss');

export class BrandLoginMethodDataController {
  brand: Brand;

  styles = styles;

  CustomerLoginTypeCode = CustomerLoginTypeCode;

  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const BrandLoginMethodDataComponent = {
  template,
  controller: BrandLoginMethodDataController,
  bindings: {
    brand: '<',
  },
};
