export enum SimpleAllabeMenuItemType {
  All,
  Specific,
}

export interface SimpleAllabeMenuItem<T> {
  value: T;
  label: string;
  type: SimpleAllabeMenuItemType;
}
