import $ from 'jquery';
import angular from 'angular';

import GroupCreateAbstractController from './createAbstract';
import { PermissionStructuresService } from '../services/permission-structures';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { UserGroup } from '~/source/common/models/user-group';

export class GroupCopyController extends GroupCreateAbstractController {
  $translate: angular.translate.ITranslateService;
  permissionStructuresService: PermissionStructuresService;

  /**
   * Return user group that will contain the permissions of group that it being copied from.
   * The name and description of new group would be modified and will contain the name and
   * description of the origin group with translated attachment
   *
   * @returns {Promise}
   */
  getUserGroup() {
    // get origin group and translations
    const translatePromise = this.$translate([
      'group.COPIED_GROUP_NAME_ATTACH',
      'group.COPIED_GROUP_DESCRIPTION_ATTACH',
      // get the group we copy from
    ]);

    const userGroupPromise = this.userGroupsService().getOneWithQuery<
      IElementRestNg<UserGroup>
    >(this.$state.params.groupId);

    // embed doesn't work for permissions. we have to get it in another request :(
    const groupPermissionsPromise = this.userGroupsServiceInst
      .resourceBuildStart()
      .getElement(this.$state.params.groupId)
      .getNestedCollection('permissions')
      .resourceBuildEnd()
      .getListWithQuery();

    // get the permissions structure
    const permissionsStructurePromise = this.permissionStructuresService.getListWithQuery();

    return Promise.all([
      translatePromise,
      permissionsStructurePromise,
      userGroupPromise,
      groupPermissionsPromise,
    ]).then((data) => {
      const [
        translation,
        permissionsStructure,
        userGroup,
        groupPermissions,
      ] = data;

      /**
       * Convert permissions structure to the tree structure needed by the directive
       * and assign the copied group permissions to it
       */
      const permissions = this.managementPermissionsModifierService.modify(
        permissionsStructure.plain(),
        groupPermissions.plain(),
      );

      // when the promises resolved, modify the user group
      return Object.assign(userGroup.plain(), {
        permissions,
        id: null,
        name: `${translation['group.COPIED_GROUP_NAME_ATTACH']} ${userGroup.name}`,
        description: `${translation['group.COPIED_GROUP_DESCRIPTION_ATTACH']} ${userGroup.description}`,
      });
    });
  }
}

GroupCopyController.$inject = [
  '$state',
  '$translate',
  'userGroupsService',
  'permissionStructuresService',
  'managementPermissionsModifierService',
  ...GroupCopyController.$inject,
];
