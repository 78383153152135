import * as _ from '@proftit/lodash';
import { IOnChangesObject, IChangesObject } from 'angular';
import * as rx from '@proftit/rxjs';

export function filterCompChange<T>(propName: string) {
  return function operator(
    obs$: rx.Observable<IOnChangesObject>,
  ): rx.Observable<IChangesObject<T>> {
    return rx.pipe(
      () => obs$,
      rx.map((changes) => changes[propName]),
      rx.filter((propChange) => !_.isNil(propChange)),
    )(null);
  };
}
