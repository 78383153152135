import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class BonusNotificationsController extends NotificationsController {
  static $inject = ['bonusesSocketService', ...NotificationsController.$inject];
}

export default {
  ...ComponentOptions,
  controller: BonusNotificationsController,
};
