import * as _ from '@proftit/lodash';
import template from './assignment-audit-log-popup.html';
import TablePopupController from '~/source/common/components/table/table-popup';
import infoAuditSettings from './assignment-audit-log-popup-settings.json';

import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';

class Controller extends TablePopupController {
  static $inject = [
    'customersService',
    'blockUI',
    ...TablePopupController.$inject,
  ];

  settings = _.cloneDeep(infoAuditSettings);
  cols = [...this.settings.tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings.popupTable.ngTable;
  }

  get tableKey() {
    return 'AssignmentAuditLogPopup';
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      return;
    }
    this.customer = resolve.customer;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getAssignmentAuditLogResource(this.customer.id)
      .sort({ requestedAt: 'desc' });
  }
}

export const AssignmentAuditLogPopupTable = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
