import * as _ from '@proftit/lodash';

import addCreditPopupTemplate from '../add-credit-popup.html';
import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import creditSettings from '../credit-settings.json';
import TableLiveController from '~/source/common/components/table/table-live.controller';
import TableSettings from '~/source/common/models/table-settings';
import CustomersService from '~/source/contact/common/services/customers';
import CreditsSocket from '~/source/contact/common/services/credits-socket.service';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';

import template from './credits-table.html';

enum ColumnRenderers {
  Regular = 'regular',
  Note = 'note',
}

class Controller extends TableLiveController {
  static $inject = [
    ...TableLiveController.$inject,
    'customersService',
    'popupService',
    'creditsSocketService',
    'tokensService',
  ];

  blockUiId = 'creditsTable';
  config: TableSettings;
  creditsSocketService: CreditsSocket;
  customersService: () => CustomersService;
  credits: IElementRestNg<any>;
  dataServiceInstance: CustomersService;
  customer: IElementRestNg<Customer>;
  account: IElementRestNg<TradingAccount>;
  popupService: PopupService;

  $onInit() {
    super.$onInit();
    if (_.isEmpty(this.config)) {
      this.config = <any>{};
    }

    Object.assign(this, {
      settings: Object.assign({}, creditSettings),
      dataServiceInstance: this.customersService(),
      cols: [...creditSettings.tableColumns],
    });

    this.initTable();
    this.$scope.$on('creditsTableReload', this.reloadTable.bind(this));
  }

  colRendererToShow(col) {
    if (col.field === 'note') {
      return ColumnRenderers.Note;
    }

    return ColumnRenderers.Regular;
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService() {
    return this.creditsSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.credits';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.credits;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  fetchFn() {
    return this.dataServiceInstance
      .getCreditsResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: this.blockUiId })
      .expand(['user', 'promoCode'])
      .sort({ requestedAt: 'desc' });
  }

  /**
   * add currency object into query results. because this data is required for 'amount' field value formatting
   * defined in table-popup.json.
   *
   * @param {Array} data query results
   * @return {Array} returns formatted query results
   */
  parseLoadedData(data) {
    data.forEach((item) => {
      item.currency = this.account.currency;
    });

    this.credits = data;
    return data;
  }

  /**
   * Opens the "add credit" modal
   * @returns {void}
   */
  openAddCreditPopup() {
    this.popupService.open({
      template: addCreditPopupTemplate,
      scope: this.$scope,
      data: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * Open the "credit log" popup
   *
   * @return {void}
   */
  openCreditTablePopup() {
    this.popupService.open({
      controller: 'creditTablePopupController',
      template: tablePopupTemplate,
      scope: this.$scope,
      data: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  patchNoteAction({ note, creditId }: { note: string; creditId: number }) {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiId })
      .patchCredit(this.customer.id, this.account.id, creditId, { note })
      .then((newCredit) => {
        this.onCreditNoteChange({
          creditId: newCredit.id,
          note: String(newCredit.note),
        });
      })
      .catch((e) => {});
  }

  onCreditNoteChange({ creditId, note }: { creditId: number; note: string }) {
    const changedCredit = this.credits.find((credit) => credit.id === creditId);
    if (_.isNil(changedCredit)) {
      return;
    }
    changedCredit.note = note;
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
  },
  controllerAs: 'vm',
};
