import template from './generic-progress-box.html';

class Controller {
  header: string;
  subHeader: string;
  progress: string;
}

const GenericProgressBoxComponent = {
  template,
  controller: Controller,
  bindings: {
    header: '<',
    subHeader: '<',
    progress: '<',
    progressLabel: '<',
  },
};

export default GenericProgressBoxComponent;
