import { IScope } from 'angular';
import ModalService from '~/source/common/components/modal/modal.service';
import type { StateService } from '@uirouter/angularjs';
import idleWarningTemplate from '../templates/idle/idle-warning-dialog.html';
import BaseController from './base';
import TokensService from '~/source/auth/services/tokens';
import log from 'loglevel';

export default class IdleController extends BaseController {
  /*@ngInject*/
  constructor(
    readonly $scope: IScope & { [key: string]: any },
    readonly Idle: any, // todoOld: notworking.  angular.idle.IIdleService,
    readonly tokensService: TokensService,
    readonly $state: StateService,
    readonly modalService: ModalService,
  ) {
    super();

    $scope.$on('IdleStart', () => this.idleStart($scope));
    $scope.$on('IdleWarn', (e, countdown) =>
      this.idleWarn(e, countdown, $scope),
    );
    $scope.$on('IdleEnd', () => this.idleEnd($scope));
    $scope.$on('IdleTimeout', () => this.idleTimeout($scope));
    $scope.$on(
      'Keepalive',
      () => this.tokensService.isLoggedIn() && this.keepAlive(),
    );
    $scope.countdown = this.Idle.getTimeout();
    // Clicking the stay button will restart the idle watch
    $scope.stay = this.Idle.watch;
  }

  // the user appears to have gone idle
  idleStart($scope) {
    this.closeModals($scope);
    $scope.warning = this.modalService.open({
      template: idleWarningTemplate,
      backdrop: 'static', // prevent modal close on backdrop click
      keyboard: 'false', // prevent modal close on esc click
      scope: this.$scope,
    });
  }

  /*
   * follows after the IdleStart event, but includes a countdown until the user is considered timed out
   * the countdown arg is the number of seconds remaining until then.
   * you can change the title or display a warning dialog from here.
   * you can let them resume their session by calling Idle.watch()
   */
  idleWarn(e, countdown, $scope) {}

  /*
   * the user has timed out (meaning idleDuration + timeout has passed without any activity)
   * this is where you'd log them
   */
  idleTimeout($scope) {
    this.closeModals($scope);
    // Logout, then redirect to session expired page
    return this.tokensService.logout().finally(() => {
      this.Idle.unwatch();
      this.$state.go('error.session-expired');
    });
  }

  /**
   * the user has come back from AFK and is doing stuff. if you are warning them
   * you can use this to hide the dialog
   */
  idleEnd($scope) {
    this.closeModals($scope);
  }

  /**
   * Called when Keepalive ping is fired, if a user is logged in.
   * Request a keep alive from the server.
   */
  keepAlive() {
    this.tokensService.keepAlive();
  }

  closeModals($scope) {
    if ($scope.warning) {
      $scope.warning.close();
      $scope.warning = null;
    }
  }
}
