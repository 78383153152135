import PendingPositionsTableBasePopupController from '../../common/pending-positions/pending-positions-table-popup-base-controller';
import pendingPositionsSettings from '../pending-positions-forex-settings';
import { TradingAccount } from '@proftit/crm.api.models.entities';
class PendingPositionTableForexPopupController extends PendingPositionsTableBasePopupController {
  // injected by the modal service from the parent activator.
  account: TradingAccount;

  get cols() {
    return [...pendingPositionsSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...pendingPositionsSettings.tablePopup.ngTable };
  }

  get tableKey() {
    return 'pendingPositionsForex';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getPendingOrdersResource(
        this.customer.id,
        this.account.type,
        this.account.id,
      )
      .expand(['status', 'tradeAsset']);
  }
}

export default PendingPositionTableForexPopupController;
