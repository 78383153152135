import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './exposure-metric-box.component.html';
const styles = require('./exposure-metric-box.component.scss');

export class ExposureMetricBoxController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  boxTitle: string;
  content: string;

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ExposureMetricBoxComponent = {
  template,
  controller: ExposureMetricBoxController,
  bindings: {
    boxTitle: '<',
    content: '<',
    shouldHideCurrency: '<',
  },
};
