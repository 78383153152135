// import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './number-checkbox-choice-input.component.html';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import Big from 'big.js';
import { generateObjectProxy } from '~/source/common/proxy-form/generate-object-proxy';
import { FieldProxyHandler } from '~/source/common/proxy-form/field-proxy-handler';
import { isNumericStringProxyValidator } from '~/source/common/proxy-form/validators/is-numeric-string-proxy-validator';
const styles = require('./number-checkbox-choice-input.component.scss');

export class NumberCheckboxChoiceInputController {
  /* bindings */

  onModelChange: (a: { value: any }) => void;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  modelIn$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'model');

  checkboxLabelIn$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'checkboxLabel',
  );

  form = generateForm();

  isNumberInputDisabled$ = this.streamIsNumberInputDisabled();

  /* @ngInject */
  constructor() {
    useStreams([this.checkboxLabelIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamNumberInput(),
        this.streamCheckbox(),
        this.streamModelNotifyChange(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamIsNumberInputDisabled() {
    return rx.pipe(
      () => this.form.value$,
      rx.filter((a) => a),
      rx.map((form) => form.checkbox),
      shareReplayRefOne(),
    )(null);
  }

  streamNumberInput() {
    return rx.pipe(
      () => this.modelIn$,
      rx.filter((value) => !_.isNil(value)),
      rx.filter((value) => isNumber(value)),
      rx.withLatestFrom(this.form.value$.pipe(rx.filter((a) => a))),
      rx.filter(([newVal, { numberInput }]) => newVal !== numberInput),
      rx.map(([newVal]) => newVal),
      rx.tap((newVal) => (this.form.proxy.numberInput = newVal)),
    )(null);
  }

  streamCheckbox() {
    return rx.pipe(
      () => this.modelIn$,
      rx.filter((value) => !_.isNil(value)),
      rx.withLatestFrom(this.form.value$.pipe(rx.filter((a) => a))),
      rx.filter(([newVal, { checkbox }]) => {
        if (newVal === 'default' && checkbox) {
          return false;
        }

        if (newVal !== 'default' && !checkbox) {
          return false;
        }

        return true;
      }),
      rx.map(([newVal]) => {
        if (newVal === 'default') {
          return true;
        }

        return false;
      }),
      rx.tap((newValBool) => (this.form.proxy.checkbox = newValBool)),
    )(null);
  }

  streamModelNotifyChange() {
    return rx.pipe(
      () => this.form.value$,
      rx.map(({ numberInput, checkbox }) => {
        if (checkbox) {
          return 'default';
        }

        return numberInput;
      }),
      rx.tap((value) => this.onModelChange({ value })),
    )(null);
  }
}

function generateForm() {
  const proxy: any = generateObjectProxy<any>({});

  proxy.handler.addFieldHandler(
    'numberInput',
    new FieldProxyHandler({
      validators: [isNumericStringProxyValidator],
    }),
  );

  proxy.handler.addFieldHandler(
    'checkbox',
    new FieldProxyHandler({
      validators: [],
    }),
  );

  const value$ = new rx.BehaviorSubject<any>(null);
  proxy.handler.getLeaf().addValueListener(({ value }) => value$.next(value));

  proxy.numberInput = null;
  proxy.checkbox = false;

  return {
    proxy,
    value$,
  };
}

function isNumber(value: any) {
  try {
    Big(value);
    return true;
  } catch {
    return false;
  }
}

export const NumberCheckboxChoiceInputComponent = {
  template,
  controller: NumberCheckboxChoiceInputController,
  bindings: {
    model: '<',
    checkboxLabel: '<',
    onModelChange: '&',
  },
};
