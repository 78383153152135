import RestService from '~/source/common/services/rest';
import { TimeInterval } from '@proftit/constants.time';

export class CustomerPropertyTypesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'customerPropertyTypes';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }

  /**
   * Override default cache options
   */
  get cacheOptions() {
    return {
      maxAge: TimeInterval.Hour,
    };
  }
}

export default CustomerPropertyTypesService;
