if (process.env.NODE_ENV !== 'test') {
  require('tinymce/skins/ui/oxide/skin.css');
}

import 'tinymce/tinymce.min';
// Import a theme is required and avoids extra requests!
import 'tinymce/themes/silver/theme.min';
// Any plugins you want to use has to be imported and also avoids extra requests!
import 'tinymce/plugins/paste/plugin.min';
import 'tinymce/plugins/link/plugin.min';
import 'tinymce/plugins/lists/plugin.min';
import 'tinymce/plugins/autoresize/plugin.min';
import 'tinymce/plugins/imagetools/plugin.min';
import 'tinymce/plugins/table/plugin.min';
import 'tinymce/plugins/wordcount/plugin.min';
import 'tinymce/plugins/textcolor/plugin.min';
import 'tinymce/plugins/colorpicker/plugin.min';
import 'tinymce/plugins/print/plugin.min';
import 'tinymce/plugins/preview/plugin.min';
import 'tinymce/plugins/charmap/plugin.min';
import 'tinymce/plugins/insertdatetime/plugin.min';
import 'tinymce/plugins/searchreplace/plugin.min';
import 'tinymce/plugins/advlist/plugin.min';
import 'tinymce/plugins/contextmenu/plugin.min';
import 'tinymce/plugins/image/plugin.min';
import 'tinymce/plugins/hr/plugin.min';
import 'tinymce/plugins/anchor/plugin.min';
import 'tinymce/plugins/pagebreak/plugin.min';
import 'tinymce/plugins/fullscreen/plugin.min';
import 'tinymce/plugins/template/plugin.min';
import 'tinymce/plugins/save/plugin.min';
import 'tinymce/plugins/fullpage/plugin.min';
import 'tinymce/plugins/code/plugin.min';
import 'tinymce/plugins/media/plugin.min';
// without this import, the icons of tinymce toolbar disappear, and turn to 'not found' text.
import 'tinymce/icons/default';
