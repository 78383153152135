import module from '../../index';

/**
 * Preventing event propagation
 *
 * Use case:
 *  <div ng-controller="OverlayCtrl" class="overlay" ng-click="hideOverlay()">
 *  <img src="http://some_src" ng-click="nextImage()" prf-stop-event="click"/>
 * </div>
 *
 *  Preventing from click on image to propagate to hideOverlay
 */
module.directive('prfStopEvent', () => ({
  restrict: 'A',
  link(scope, element, attr) {
    if (attr && attr.prfStopEvent) {
      // bind to event that it propagation should be stopped
      element.bind(attr.prfStopEvent, (e) => e.stopPropagation());
    }
  },
}));
