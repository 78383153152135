import ng from 'angular';
import _ from 'underscore';

import BaseController from '~/source/common/controllers/base';
import { ManagementPermissionsModifierService } from '../../../permissions/permissions-modifier.service';

import template from './group-edit.html';

class Controller extends BaseController {
  userGroup: any;
  groupBackup: any;
  enterToEditPermissionsModeInManagerComponent: () => void;
  // Initial mode
  isEdit = false;

  /*@ngInject */
  constructor(
    readonly managementPermissionsModifierService: ManagementPermissionsModifierService,
    ...args
  ) {
    super(managementPermissionsModifierService, ...args);
  }

  /**
   * Saves a group copy and enters edit mode
   */
  onEdit() {
    this.groupBackup = ng.copy(this.userGroup);
    this.isEdit = true;
  }

  /**
   * Restores group from copy and exits edit mode
   */
  cancelEdit() {
    this.userGroup = this.groupBackup;
    this.isEdit = false;
  }

  updateGroup() {
    // Normalize the user group format according to format expected by server
    const normalizedUserGroup = _.pick(this.userGroup, 'name', 'description');

    return this.userGroup.patch(normalizedUserGroup).then(() => {
      this.isEdit = false;
    });
  }
}

export const GroupEditComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    userGroup: '<',
    enterToEditPermissionsModeInManagerComponent: '&',
  },
};
