import _ from 'underscore'; // this could be replaced with lodash once tree shaking will work for it
import * as _l from '@proftit/lodash';
import ng from 'angular';
import log from 'loglevel';
import BaseService from '~/source/common/services/baseService';
import {
  FiltersConfigObject,
  FiltersModel,
  FilterType,
  CachedFilterObject,
  FilterConfig,
  NormalizedFilter,
  FiltersArray,
  FilterStateTemplate,
} from '../index';
import { serializeDateRange } from '../utilities/serialize-date-range';

class FilterService extends BaseService {
  dateFormat: { [key: string]: string };
  filtersSettings: FiltersConfigObject;

  /**
   * transform filter models ({state, value}) to valid normalized api filter values
   *
   * @example filter model to a valid api filter values
   *
   * Input:
   *   country: [
   *     {
   *       state: {isActive: true},
   *       value: {id: 1}
   *     },
   *     {
   *       state: {isActive: true},
   *       value: {id: 2}
   *     },
   *     {
   *       state: {isActive: false},
   *       value: {id: 3}
   *     }
   *   ]
   *   balanceTotal: {minNumber: 100, maxNumber: 5000}
   * }
   *
   * Output: => {
   *   countryId: [1, 2],
   *   balanceTotal_gte: 100,
   *   balanceTotal_lte: 5000
   * }
   *
   * the transformation is affected by the following settings (default values defined in filter settings json file)
   *
   * filter 'state' settings (affect handling & behavior of the filters):
   *
   *  @example a simple default filter state settings formation
   *  customerComplianceStatus:
   *  "state": {
   *     "type": "select",
   *     "name": "filters.COMPLIANCE_STATUS",
   *     "isActive": true,
   *     "apiProperty": "customerComplianceStatusId"
   *   },
   *   popover: {...}
   *  }
   *
   * - isActive {boolean} required
   * not active filters are ignored by this normalizations process
   *
   * - isDisabled {boolean}
   * disabled filters are ignored by this normalizations process
   *
   * - type {String}
   * the type of the filter.
   * uses: filter labels use 'type' to decide which format is most suitable.
   *
   * - name {String}
   * the name that will appear on filter label.
   *
   * - apiProperty {String}
   * the api property key (param name) that will be sent to the server
   *
   * - digits {Number} this property exists in only monetary range filters.
   * defined how many digits to display on values.
   *
   * @param {Object} models filter models object (e.g. {state:{...}, value: {...}} )
   * @returns {Object} normalized filter object ready for restangular api calls
   */
  toFilter(models: FiltersModel): NormalizedFilter {
    const normalizedFilter = {};
    const handlers = {
      simple: ({ value }: FilterConfig) => value,
      array: (model: FilterConfig[], valueField?: string) => {
        // keep only active filters
        const activeFilters = model
          .filter(
            ({ value, state }) =>
              state.isActive && value[valueField] !== undefined,
          )
          .map(({ value }) => value[valueField]);

        // array model has no active filter inside
        if (activeFilters.length === 0) {
          return;
        }

        // // If at least one of the array elements has "exclude", use "exclude" for all!
        const exclude = model.some((el) => el.exclude);
        if (activeFilters.length > 0) {
          // In "exclude" mode, the structure is { fieldName: { exclude: [1, 2,...] } }
          return exclude ? { exclude: activeFilters } : activeFilters;
        }
      },
      select: ({ value, state }: FilterConfig) => {
        // select filters return object, decide which property will be sent to the server as value
        const valueField = state.valueField ? state.valueField : 'id';

        return value[valueField];
      },
      singleNumberInput: ({ value, state }: FilterConfig) => {
        return value.selectedNumber;
      },
      quick: ({ value, state }: FilterConfig) =>
        state.valueField ? value[state.valueField] : value,
      dateRange: ({ value }: FilterConfig) => {
        return serializeDateRange(value, this.dateFormat.MYSQL_DATETIME);
      },
      monetaryRange: ({ value }: FilterConfig) => {
        if (
          _.isNumber(value.minNumber) &&
          _.isNumber(value.maxNumber) &&
          value.minNumber > value.maxNumber
        ) {
          return;
        }

        return {
          /*
           * we need to send null values to the normalizer if filter is empty in order to remove existing
           * values from the request
           * set min number
           */
          gte: _.isNumber(value.minNumber) ? value.minNumber : null,

          // set max number filter, the max number can't be 0
          lte: _.isNumber(value.maxNumber) ? value.maxNumber : null,
        };
      },
      numberRange: undefined, // STUB, will be overridden below to equal to the monetaryRange
    };

    // numbers handler is the same as monetary handler
    handlers.numberRange = handlers.monetaryRange;

    Object.keys(models).forEach((filterKey: string) => {
      const model: FilterConfig | FilterConfig[] = models[filterKey];
      const defaultFilterModelState = this.filtersSettings[filterKey].state;

      // to property key that sends to the server
      const apiProperty = defaultFilterModelState.apiProperty
        ? defaultFilterModelState.apiProperty
        : filterKey;

      // handle multiple filters
      if (Array.isArray(model)) {
        // value field
        const valueField = defaultFilterModelState.valueField
          ? defaultFilterModelState.valueField
          : 'id';

        const value = handlers.array(model, valueField);

        if (value !== undefined) {
          normalizedFilter[apiProperty] = value;
        }

        return;
      }

      // ignore this filter if its in-active or there is not state
      if (!defaultFilterModelState || !model.state.isActive) {
        return;
      }

      const mergedModel = {
        ...model,
        /*
         * Merge the model state with the default model state, to fill in missing attributes
         * (the model might come from cache and then have no "state.type" attribute, for example)
         */
        state: { ...defaultFilterModelState, ...model.state },
      };

      const filterType = mergedModel.state.type;

      if (handlers[filterType]) {
        let value = handlers[filterType](mergedModel, apiProperty);
        const { template } = mergedModel.state;
        if (!_l.isNil(template)) {
          value = this.buildValueFromTemplate(template, mergedModel.value);
        }
        if (value !== undefined) {
          normalizedFilter[apiProperty] = model.exclude
            ? { exclude: ng.copy(value) }
            : ng.copy(value);
        }
      } else {
        log.warn(
          'there is no handler defined for this filter type,',
          filterType,
        );
      }
    });

    return normalizedFilter;
  }

  private buildValueFromTemplate(
    template: FilterStateTemplate,
    filterSelectedValue: any,
  ) {
    const { pattern, variableFields } = template;
    const objForTemplateCompile = {};
    const variableFieldsEntries = _l.toPairs(variableFields);
    variableFieldsEntries.forEach(([key, fieldValue]) => {
      objForTemplateCompile[key] = filterSelectedValue[fieldValue];
    });
    return _l.templateEs(pattern)(objForTemplateCompile);
  }

  /**
   * normalize cached filter key name.
   * @param filterKey
   * @returns {*}
   * @private
   */
  private normalizeFilterKey(filterKey: string): string {
    let normalizedKey: string = filterKey;
    if (filterKey.substr(-2) === 'Id') {
      // remove id from filter key if exists
      normalizedKey = filterKey.replace('Id', '');
    } else if (filterKey.indexOf('.') !== -1) {
      /*
       * in some cases filters send to the server (and load from cache) in nesting format like {tradeProduct.code}
       * extract the real filterKey name as its appear in our settings file
       */
      normalizedKey = _.first(filterKey.split('.'));
    }

    return normalizedKey;
  }

  /**
   *  transform quick filters to filter models.
   *  fill model state & value from filter settings json file
   *
   *  @example {deposited: {}} => {deposited: {state:{...}, value:{...}, exclude: false}
   *
   * @param filters
   * @returns {Object} returns filter object models
   */
  quickFilterToModels(filters: FiltersConfigObject): FiltersConfigObject {
    const models = {};

    _.each(filters, (filter: FilterConfig, filterKey: string) => {
      /*
       * merge filter state with settings state allow the developer to override default state value
       * when assigning quick filter to table
       */
      const state =
        filter && filter.state
          ? { ...this.filtersSettings[filterKey].state, ...filter.state }
          : { ...this.filtersSettings[filterKey].state };

      // get quick
      const value = filter.value
        ? filter.value
        : this.filtersSettings[filterKey].value;

      models[filterKey] = { value, state, exclude: !!filter.exclude };
    });

    return models;
  }

  /**
   *  convert array filter to model format. add value, state (from settings) properties
   *
   * @example: filters array
   *  [{id:1, name: "Israel"}] =>  [{value: {id: 1, name: "Israel"}, state: {isVisible: true...} }]
   * @example: filters array wrapped by 'exclude'
   * { exclude: [{id:1, name: "Israel"}] } =>
   *  [{value: {id: 1, name: "Israel"}, exclude: true, state: {isVisible: true...} }]
   *
   * @param {Object} filters filter array as received from table filters. possibly wrapped by 'exclude'
   * @param {String} filterKey filter key as defined in filter settings json filter
   */
  filterArrayToModels(
    filters: FiltersArray,
    filterKey: string,
  ): FilterConfig[] {
    if (!this.filtersSettings[filterKey].state.multiple) {
      return;
    }

    // if filter is not an array, it's probably an array wrapped by 'exclude'. e.g { exclude [1, 2] }
    const values = !Array.isArray(filters) ? filters.exclude : filters;

    if (values.length === 0) {
      return;
    }

    return values.map((value) => ({
      value,
      state: { ...this.filtersSettings[filterKey].state },
      // if filter is NOT an array, it must be an object wrapped by "exclude" - means it's an exclude filter.
      exclude: !Array.isArray(filters),
    }));
  }

  /**
   * Convert server filters & filter models to models
   *
   * @example
   *  toModels({balanceTotal: {minNumber:1}) -> {balanceTotal: {value: {minNumber: 1}, state: {isActive:true, ...}}
   *  toModels({country: [{id:1, name:"Israel"}]}) ->
   *    {country: [{value: {id:1, name:"Israel"}]}, state: {isActive:true, ...}]}
   *
   * @param {object} filters
   * @returns {object}
   */
  toModels(filters): FiltersModel {
    const models: FiltersModel = {};
    _.each(filters, (filterObj: any, filterKey: string) => {
      const normalizedFilterKey = this.normalizeFilterKey(filterKey);

      // handle array filter
      if (this.filtersSettings[normalizedFilterKey].state.multiple) {
        const arrayFilterModel = this.filterArrayToModels(
          filterObj,
          normalizedFilterKey,
        );
        if (arrayFilterModel) {
          models[normalizedFilterKey] = arrayFilterModel;
        }
        return;
      }

      models[normalizedFilterKey] = {
        // 'filterObject' is null on 'dateRange' filter whenever 'no-range' was checked
        value:
          !_.isNull(filterObj) && filterObj.exclude
            ? filterObj.exclude
            : filterObj,
        state: { ...this.filtersSettings[normalizedFilterKey].state },
        exclude: !_.isNull(filterObj) && !!filterObj.exclude,
      };
    });

    return models;
  }

  /**
   * prepare raw filters for cache
   * raw filters will be converted to filter models than converted to cache format
   *
   * @example
   * prepareItemForCache(
   * [
   *  {
   *    balanceTotal: { id: 15, name: "regular filter name" },
   *    someQuickFilter: {}
   *  }
   * ])
   *  -> [{
   *      balanceTotal: {
   *        value: 15,
   *        state: { isActive: true },
   *        value: { id: 15, name: "regular filter name" }
   *      },
   *      {
   *      someQuickFilter: {
   *        value:true,
   *        state: { isActive: true }
   *      }
   *    }]
   *
   * @param {Object} filters raw
   * @returns {Object} filters object ready for cache
   */
  prepareItemForCache(filters: any): CachedFilterObject {
    const cacheModels: CachedFilterObject = {};

    _.each(filters, (filterObj, filterName: string) => {
      const filterType = this.getFilterType(filterName);
      let item: FiltersModel;

      if (!filterType) {
        return;
      }

      switch (filterType) {
        case 'quick':
          item = this.quickFilterToModels({
            [filterName]: { state: { isActive: true } },
          });
          break;
        default:
          item = this.toModels({ [filterName]: filterObj });
          break;
      }

      Object.assign(cacheModels, this.toCache(item));
    });

    return cacheModels;
  }

  /**
   * prepare object for cache based on filter model. keep only values we'll use later.
   *
   * @example
   * toCache(
   *  {balanceTotal: {value: 15, state:{isActive: true, apiProperty:"lala", "type": "monetaryRange","digits": 0}}}
   * )
   *  -> {balanceTotal: {value: 15, state:{isActive: true}})
   *
   * @param {object} models
   * @returns {Object} filters object ready for cache
   */
  toCache(models: FiltersModel): CachedFilterObject {
    const cacheData: CachedFilterObject = {};
    const normalizeFilterItem = (item: FilterConfig) => ({
      state: {
        isActive: item.state.isActive,
      },
      exclude: !!item.exclude,
      value: item.value,
    });

    Object.keys(models).forEach((key: string) => {
      const filter: FilterConfig | FilterConfig[] = models[key];
      // multiple filter
      if (Array.isArray(filter)) {
        // Insert arrays ordered by their string representation. makes it much easier to compare filters.
        cacheData[key] = _.sortBy(filter, (item) =>
          JSON.stringify(item.value),
        ).map((filterItem) => normalizeFilterItem(filterItem));
      } else {
        // single filter
        cacheData[key] = normalizeFilterItem(filter);
      }
    });
    return cacheData;
  }

  /**
   * parse over cache filter models and complete missing state values from filter settings json file
   * @param {Object} cachedModels raw filter model data from local storage
   * @returns {Object} returns an ready filter models object
   */
  fromCache(cachedModels: CachedFilterObject): FiltersModel {
    const models = {};

    const normalizeFilterItem = (item, itemKey) => ({
      value: item.value,
      state: { ...this.filtersSettings[itemKey].state, ...item.state },
      exclude: item.exclude,
    });

    _.each(cachedModels, (filter, filterKey) => {
      if (_.isArray(filter)) {
        models[filterKey] = filter.map((filterModel) =>
          normalizeFilterItem(filterModel, filterKey),
        );
      } else {
        models[filterKey] = normalizeFilterItem(filter, filterKey);
      }
    });

    return models;
  }

  /**
   * disable all filter models by setting state's isActive=false
   * @param {Object} filterModels
   * @returns {Object} returns filter list with disabled filters
   */
  disableAllFilters(filterModels: FiltersModel): FiltersModel {
    _.each(filterModels, (filterModel) => {
      const models = _.isArray(filterModel) ? filterModel : [filterModel];

      models
        .filter((filterObj) => _l.getEs(filterObj, 'state.isActive')) // only get active filters
        .forEach((filterObj) => {
          filterObj.state.isActive = false;
        });
    });

    return filterModels;
  }

  /**
   * get filter type
   *
   * @param {String} filterKey filter key as defined in filter settings json file
   * @returns {string} return type of filter. (select, quick, dateRange...)
   */
  getFilterType(filterKey: string): FilterType {
    return _l.getEs(this.filtersSettings[filterKey], 'state.type');
  }

  /**
   * map a state property key to its property api property key
   * @param {string} stateKey
   * @returns {*}
   */
  getFilterApiKey(stateKey: string): string {
    if (
      this.filtersSettings[stateKey] &&
      this.filtersSettings[stateKey].state.apiProperty
    ) {
      return this.filtersSettings[stateKey].state.apiProperty;
    }

    return stateKey;
  }

  /**
   * Show if filter is excludable.
   *
   * @param stateKey - filker identification.
   * @return is fitler excludable.
   */
  isFilterPopoverExcludable(stateKey: string): boolean {
    const excludable = _l.get(
      [stateKey, 'popover', 'excludable'],
      this.filtersSettings,
    );
    return excludable === true;
  }
}

FilterService.$inject = ['dateFormat', 'filtersSettings'];

export default FilterService;
