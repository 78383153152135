import template from './is-demo-select.html';
const styles = require('./is-demo-select.component.scss');

export class IsDemoSelectController {
  styles = styles;
  isOpen = false;
  onChangeIsDemo: (a: { isDemo: boolean }) => void;

  selectIsDemo(isDemo) {
    this.isOpen = false;
    this.onChangeIsDemo({ isDemo });
  }
}

export const IsDemoSelectComponent = {
  template,
  controller: IsDemoSelectController,
  bindings: {
    isDemo: '<',
    onChangeIsDemo: '&',
  },
};
