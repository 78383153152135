import * as _ from '@proftit/lodash';
import { blockUI } from 'angular';
import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import TableController from '~/source/common/components/table/table.controller';
import ReportsService from '~/source/contact/common/services/reports.service';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import ModalService from '~/source/common/components/modal/modal.service';
import Url from 'url-parse';
import UserSettingsService from '~/source/common/services/user-settings';
const styles = require('./common-report-table.component.scss');
const USER_SETTING_REPORTS_COLUMNS_VERSION = 3;
import * as rx from '@proftit/rxjs';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { USER_SETTINGS_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { CrmAppStoreProviderController } from '~/source/app/cfm-app-store-provider.component';
import { ReportsExportService } from '~/source/common/services/report-export.service';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import TokensService from '~/source/auth/services/tokens';
import UserTokenModel from '~/source/common/models/user-token-model';
import { generateUuid } from '@proftit/general-utilities/';
import moment from 'moment-timezone';

export abstract class CommonReportsTableController extends TableController {
  // init all injection
  static $inject = [
    '$scope',
    'dateFormat',
    'appConfig',
    'reportsService',
    'prfReportsExportService',
    'authenticationService',
    'tradeReputationTypesService',
    'stats',
    'customersSocketService',
    'tokensService',
    'highlightEntityService',
    'usersService',
    'contactDashboardCacheService',
    'userSettingsService',
    'popupService',
    'modalService',
    'prfClientGeneralPubsub',
    '$translate',
    'PermPermissionStore',
    'prfContactsImportsService',
    'communicationTypesService',
    'popupService',
    '$state',
    'prfAppTag',
    'blockUI',
    ...TableController.$inject,
  ];

  stats;
  dataServiceInstance: ReportsService;
  prfReportsExportService: () => ReportsExportService;
  exportTableCsvAction = new rx.Subject<void>();
  reportsService: () => ReportsService;
  brand: any;
  tokensService: TokensService;
  tblNumOfRows: any;
  styles = styles;
  modalService: ModalService;
  userSettingsService: UserSettingsService;
  userSettingsInstance: any;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  $translate: ng.translate.ITranslateService;
  search: string;
  lifecycles = observeComponentLifecycles(this);
  tableColumns: any[];
  reportSettings: any;
  userSettingsCoulumnKey: string;
  user: UserTokenModel;
  tableKeyName: string;
  prfCrmAppStoreProvider: CrmAppStoreProviderController;
  resource: string;
  exportResource: string;
  outgoingRequestUrl: string = 'api/user/v3/reports/';
  PermPermissionStore: ng.permission.PermissionStore;
  isAllowedToSeeAndExportCsv$ = this.streamIsAllowedToSeeAndExportCsv();
  prfAppTag: any;

  /* @ngInject */
  constructor(reportSettings, userSettingsCoulumnKey, tableKeyName, args) {
    super(...args);
    this.reportSettings = reportSettings;
    this.userSettingsCoulumnKey = userSettingsCoulumnKey;
    this.tableKeyName = tableKeyName;
    Object.assign(this, {
      dataServiceInstance: this.reportsService(),
      filterModels: {},
      quickFilters: Object.assign(
        {},
        (<any>this.reportSettings).table.quickFilters,
      ),
      tableColumns: [],
      user: this.tokensService.getCachedUser(),
    });

    this.dataServiceInstance.setResourceEntity(this.resource);
  }

  $onDestroy() {}

  blockUI: blockUI.BlockUIService;

  $onInit() {
    super.$onInit();

    useStreams(
      [this.streamUserCoulomnChanged(), this.streamExportTableCsv()],
      this.lifecycles.onDestroy$,
    );

    const count = this.reportSettings.table.ngTable.parameters.count;
    this.tblNumOfRows = {
      count,
      id: numRowsOptions.indexOf(count),
      name: count,
    };

    const userSettingsPromise = this.userSettingsService
      .getSetting('customersReportPageSize', 50)
      .then((res) => {
        this.userSettingsInstance = res;
        this.tblNumOfRows = Object.assign(
          {},
          {
            count: res.value,
            id: numRowsOptions.indexOf(res.value),
            name: res.value,
          },
        );
        this.initTable(this.tblNumOfRows.count);
        return res;
      });

    this.$scope.$on('table:column:removed', (scope, field) => {
      this.hideColumn(field);
      this.removeFieldFromUserSettings(field);
    });

    this.prepareTableColumnLists();
  }

  setNumberOfRows(numOfRows): void {
    this.tblNumOfRows.count = numOfRows.count;
    super.setNumberOfRows(numOfRows.count);

    // save to server
    this.userSettingsService.setSettingValue(
      this.userSettingsInstance.id,
      numOfRows.count,
    );
  }

  editColumnsAction() {
    this.modalService.open({
      component: 'prfTableColumnsPopup',
      size: 'xlg',
      resolve: {
        defaultTableColumns: () => this.reportSettings.table.colsList,
        userSettingsKey: () => this.userSettingsCoulumnKey,
        userSettingVersion: () => USER_SETTING_REPORTS_COLUMNS_VERSION,
      },
    });
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.reportSettings.table.ngTable;
  }
  filteredSettings;
  /**
   * on toggle checkbox on the edit columns option (enable / disable column),
   * then update the NgTable on the first place with checkbox column
   * needed for assign customers to user.
   */
  onToggleColumn() {
    this.prepareTableColumnLists();
  }

  /**
   * prepare table column list for table & edit columns dropdown
   */
  prepareTableColumnLists() {
    // get only allowed columns for selected brand platform type (common & binary or common & forex)
    this.tableColumns = [...this.reportSettings.table.colsList];

    this.filteredSettings = [...this.tableColumns];

    this.prepareTableColumnListsFromUserSetting();
  }

  prepareTableColumnListsFromUserSetting() {
    const blockUiInstance = this.blockUI.instances.get(
      'customerReportTableBlock',
    );
    blockUiInstance.start();

    this.userSettingsService
      .getSettingWithoutDefault(this.userSettingsCoulumnKey)
      .then((data) => {
        if (_.isNil(data) || _.isNil(data.value.data)) {
          return;
        }

        const tableColumns = data.value.data
          .map((item) => {
            const column = this.filteredSettings.find(
              (c) => c.fieldName === item,
            );
            if (!_.isNil(column)) {
              column.show = true;
            }

            return column;
          })
          .filter((x) => !_.isNil(x));

        this.tableColumns = tableColumns;

        blockUiInstance.stop();
      })
      .finally(() => blockUiInstance.stop());
  }

  /**
   * hide column by field name
   * @param {String} field
   */
  hideColumn(field) {
    this.tableColumns.find((col) => col.field === field).show = false;
  }

  removeFieldFromUserSettings(field) {
    const columnNames = this.tableColumns
      .map((column) => column.fieldName)
      .filter((name) => name !== field);

    this.userSettingsService
      .getSetting(this.userSettingsCoulumnKey, columnNames)
      .then((data) =>
        this.userSettingsService
          .setSettingValue(data.id, {
            version: USER_SETTING_REPORTS_COLUMNS_VERSION,
            data: columnNames,
          })
          .then((d) => this.prepareTableColumnListsFromUserSetting()),
      );
  }

  streamUserCoulomnChanged() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === USER_SETTINGS_UPDATED),
      rx.tap(() => {
        this.prepareTableColumnListsFromUserSetting();
      }),
      shareReplayRefOne(),
    )(null);
  }

  get requiredApiFilters() {
    return this.search ? { q: this.search } : {};
  }

  onSearchChange() {
    this.tableParams.page(1);

    /*
     * set desk search value to table filter reference object.
     * this change will trigger api call
     */
    this.tableParams.filter().q = this.search;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    // todo: get service by name (getcustomer)
    const serviceQuery = this.dataServiceInstance.setConfig({
      blockUiRef: 'customerRportTableBlock',
    });

    return serviceQuery;
  }

  /* parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[], total?: number): any[] {
    this.outgoingRequestUrl = data['getRequestedUrl']
      ? data['getRequestedUrl']()
      : null;

    data['records'].forEach((customer, index) => {
      customer._customerLinkData = this.getCustomerLinkData(
        customer.id,
        index,
        total,
      );
    });

    this.stats = Object.keys(data['aggs']).map((key) => ({
      label: key,
      value: data['aggs'][key],
    }));
    return data['records'];
  }
  /**
   *
   * @param routeId
   * @param index
   * @param total
   * @returns
   */
  getCustomerLinkData(routeId, index, total) {
    return {
      total,
      id: routeId,
      userId: this.user.id,
      currentOffset: this.getCurrentOffsetByIndex(index),
      guid: this.prfAppTag.murmur,
      navigationId: generateUuid(),
      sort: Object.keys(this.tableParams.sorting()),
      order: Object.values(this.tableParams.sorting()),
      timestamp: moment().valueOf(),
    };
  }

  /**
   *
   * @param index
   * @returns
   */
  getCurrentOffsetByIndex(index) {
    const page = this.tableParams.page();

    if (page === 1) {
      return index;
    }

    return this.tableParams.count() * (page - 1) + index;
  }

  /*
   *
   * @param start
   * @param limit
   * @param filter
   * @param params
   * @returns Promise<any>
   */
  getRequest(start, limit, filter, params): Promise<any> {
    filter = {
      ...filter,
      ...this.ngTableSettings.parameters.filter,
    };

    return this.fetchFn()
      .slice(start, undefined, limit)
      .filter(filter)
      .sort(params.sorting())
      .getOneWithQuery<Restangular.IElement>(this.resource);
  }

  /**
   * Export Table as CSV
   */
  streamExportTableCsv() {
    return rx.pipe(
      () => this.exportTableCsvAction,
      rx.switchMap(() => {
        const displayingColumns = this.tableColumns.filter(
          (col) => col.show && col.reportFields,
        );
        const columnsTitles = displayingColumns.map(
          (col) => col.title || col.reportTitle,
        );
        return rx.obs
          .from((this.$translate(columnsTitles) as any) as Promise<string>)
          .pipe(
            rx.map((translations) => ({ translations, displayingColumns })),
          );
      }),
      rx.map(({ translations, displayingColumns }) =>
        displayingColumns.reduce(
          (acc, col) => [
            ...acc,
            {
              title: translations[col.title || col.reportTitle],
              fields: col.reportFields,
              template: col.reportFieldsTemplate || null,
            },
          ],
          [],
        ),
      ),
      rx.tap((columns) => {
        const { pathname, query } = new Url(this.outgoingRequestUrl);
        this.prfReportsExportService()
          .setConfig({
            growlRef: `export${this.resource}`,
            blockUiRef: `export${this.resource}`,
          })
          .generateExportCustomerTableCsv(
            this.exportResource,
            columns,
            `${pathname}${this.resource}${query}`,
          );
      }),
    )(null);
  }

  streamIsAllowedToSeeAndExportCsv() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ReportingExport,
          this.PermPermissionStore,
        ),
      rx.map((perms) => perms.isView && perms.isCreate),
      shareReplayRefOne(),
    )(null);
  }
}
