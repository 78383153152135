import Component from './base/component';

class SmsProviderController extends Component.controller {
  static $inject = ['smsProvidersService', ...Component.controller.$inject];

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'smsProvider.SELECT_SMS_PROVIDER';
  }
}

export const SmsProviderSelectComponent = Component.config({
  controller: SmsProviderController,
});
