import { QuestionnaireScore } from '@proftit/crm.api.models.entities';

export function createNewQuestionnaireScore(): Partial<QuestionnaireScore> {
  return {
    id: null,
    name: null,
    questionnaireForm: null,
    segments: [],
    componentScores: [],
  };
}
