import Component from './base/component';

class EWalletTransactionStatusesController extends Component.controller {
  static $inject = [
    'ewalletTransactionStatusesService',
    ...Component.controller.$inject,
  ];

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'transaction.statuses';
  }
}

export default Component.config({
  controller: EWalletTransactionStatusesController,
});
