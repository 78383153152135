import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ProxyFormValidationMessageGeneratorService } from '~/source/common/proxy-form.ng1/proxy-form-validation-message-generator.service';
import { generatePrfProxyFormValidtionMessage } from '~/source/common/utilities/generate-prf-proxy-form-validation-message';
import { LastLoginNoticePopupService } from '~/source/common/services/last-login-notice-popup-service';
import template from './app.component.html';
import { PageTitleService } from '../common/services/page-title.service';
import { UserSettings } from '@proftit/crm.api.models.entities';
import { UserSettingsService } from '~/source/common/services/user-settings';
import TokensService from '~/source/auth/services/tokens';
import { IntercomService } from '~/source/common/services/intercom.service';
const styles = require('./app.component.scss');

export class AppController {
  currentPreferredLanguageUserSettings$ = rx.pipe(
    () => this.tokensService.user$,
    rx.switchMap((user) => {
      if (!_.isNil(user)) {
        return this.userSettingsService
          .getSetting('preferredLanguage', {
            preferredLanguageCode: 'EN',
          })
          .then((data) => data.plain());
      }
      return rx.obs.of(null);
    }),
    shareReplayRefOne(),
  )(null);

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  prfProxyFormValidationMessageGenerator$ = new rx.BehaviorSubject<
    ProxyFormValidationMessageGeneratorService
  >(null);

  /* @ngInject */
  constructor(
    readonly $translate: ng.translate.ITranslateService,
    readonly prfLastLoginNoticePopupService: LastLoginNoticePopupService,
    readonly userSettingsService: UserSettingsService,
    readonly prfPageTitleService: PageTitleService,
    readonly tokensService: TokensService,
    readonly intercomService: IntercomService,
  ) {}

  $onInit() {
    useStreams(
      [
        this.streamConfigProxyFormValidatonMessageGenerator(),
        this.streamSetUILanguage(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamConfigProxyFormValidatonMessageGenerator() {
    return rx.pipe(
      () => this.prfProxyFormValidationMessageGenerator$,
      rx.filter((srv) => !_.isNil(srv)),
      rx.tap((messageGenerator) => {
        messageGenerator.setGenerator((msgInfo) =>
          generatePrfProxyFormValidtionMessage(msgInfo, this.$translate),
        );
      }),
    )(null);
  }

  streamSetUILanguage() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.currentPreferredLanguageUserSettings$,
          this.tokensService.user$,
        ),
      rx.tap(([userSettings, user]) => {
        const isLoggedInAndHasPreferredLang = [userSettings, user].every(
          (item) => !_.isNil(item),
        );
        if (isLoggedInAndHasPreferredLang) {
          this.$translate.use(
            userSettings.value.preferredLanguageCode.toLowerCase(),
          );
        } else {
          this.$translate.use('en');
        }
      }),
    )(null);
  }
}

export const AppComponent = {
  template,
  controller: AppController,
};
