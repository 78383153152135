import Component from '~/source/common/components/dropdowns/base/component';
import { PublicBrandsService } from '~/source/common/services/brands-public';
import { mapTranslationsArray } from '~/source/common/utilities/map-translations-array';

class TradingAccountTypesController extends Component.controller {
  static $inject = ['publicBrandsService', ...Component.controller.$inject];

  // bindings
  currencyId: number;
  brandId: number;
  platformId: number;

  // Override default functionally with a no-op
  init() {}

  $onChanges(changesObj) {
    if ((changesObj.platformId || changesObj.currencyId) && this.platformId) {
      this.fetchData();
    }
  }

  query() {
    const groupLists = (<PublicBrandsService>(
      this.dataServiceInst
    )).getGroupsResource(this.brandId, this.platformId);

    // If currencyId hasn't defined yet and the platform is MT4, fetch all groups
    if (undefined === this.currencyId && this.platformId === 4) {
      return groupLists.getListWithQuery();
    }

    // get only groups of this currency, or any currency (null=any)
    return groupLists
      .filter('currencyId', [this.currencyId, 'null'])
      .getListWithQuery();
  }

  get defaultPlaceholder() {
    return 'tradingAccountGroup.PLEASE_SELECT';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: TradingAccountTypesController,
  bindings: {
    brandId: '<',
    currencyId: '<',
    platformId: '<',
  },
});
