import BaseController from '~/source/common/controllers/base';

import template from './withdrawal-request-details.html';

class WithdrawalRequestDetailsController extends BaseController {
  withdrawalRequest: any;
}

export default {
  template,
  controller: WithdrawalRequestDetailsController,
  bindings: {
    withdrawalRequest: '<',
    account: '<',
    customer: '<',
  },
};
