import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import {
  isNotNilFormValidator,
  optionalMaxNumberFormValidatorFactory,
  optionalNumberMustBeNonNegativeFormValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { Scoring } from '../models/scoring';

export function createScoringFormGroup(scoring: Scoring): FormGroup {
  return new FormGroup({
    score: new FormControl<number>(scoring.score as any, {
      validators: [
        isNotNilFormValidator,
        optionalNumberMustBeNonNegativeFormValidator,
        optionalMaxNumberFormValidatorFactory(100),
      ],
    }),
    failQuestionnaire: new FormControl<boolean>(scoring.failQuestionnaire, {
      validators: [isNotNilFormValidator],
    }),
  } as any);
}
