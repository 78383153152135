import { generateUuid } from '@proftit/general-utilities';
import * as rx from '@proftit/rxjs';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles, observeShareCompChange } from '@proftit/rxjs.adjunct.ng1';

var template$d = "<div class=\"prf-menubar\">\n  <div class=\"prf-start-section\" cr-transclude=\"start\"></div>\n\n  <div class=\"prf-menu-items-section\"></div>\n\n  <div class=\"prf-end-section\" cr-transclude=\"end\"></div>\n</div>\n";

function styleInject(css, ref) {
  if ( ref === void 0 ) ref = {};
  var insertAt = ref.insertAt;

  if (!css || typeof document === 'undefined') { return; }

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css_248z$d = ".prf-menubar {\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n  align-items: center; }\n";
styleInject(css_248z$d);

class PrfMenubarController {}
const PrfMenubarComponent = {
  template: template$d,
  transclude: {
    start: '?trnStart',
    end: '?trnEnd'
  },
  controller: PrfMenubarController
};

function definePrfMenubarModule(ng) {
  const md = ng.module('prfUiKit.prfMenubar', []);
  md.component('prfMenubar', PrfMenubarComponent);
  return md;
}

var template$c = "<div\n  class=\"prf-section-card-header {{ $ctrl.theme }}\"\n  data-prf-id=\"prf-section-card-header\"\n>\n  <div cr-transclude=\"start\"></div>\n\n  <div cr-transclude=\"middle\"></div>\n\n  <div cr-transclude=\"end\"></div>\n</div>\n";

var css_248z$c = ".prf-section-card-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n";
styleInject(css_248z$c);

class PrfSectionCardHeaderControlelr {}
const PrfSectionCardHeaderComponent = {
  template: template$c,
  transclude: {
    start: '?trnStart',
    middle: '?trnMiddle',
    end: '?trnEnd'
  },
  bindings: {
    theme: '<'
  },
  controller: PrfSectionCardHeaderControlelr
};

function definePrfSectionCardHeaderModule(ng) {
  const md = ng.module('prfUiKit.prfSectionCardHeader', []);
  md.component('prfSectionCardHeader', PrfSectionCardHeaderComponent);
  return md;
}

var template$b = "<ng-transclude\n  class=\"prf-section-cards-layout__transclusion prf-section-cards-layout\"\n  data-prf-id=\"com-section-cards-layout\"\n>\n</ng-transclude>\n";

var css_248z$b = ".prf-section-cards-layout {\n  display: grid;\n  grid-column-gap: 10px;\n  grid-row-gap: 10px; }\n";
styleInject(css_248z$b);

class Controller$6 {}
const SectionCardsLayoutComponent = {
  template: template$b,
  controller: Controller$6,
  transclude: true
};

function definePrfSectionCardsLayoutModule(angular) {
  const ngModule = angular.module('prfUiKit.prfSectionCardsLayout', []);
  ngModule.component('prfSectionCardsLayout', SectionCardsLayoutComponent);
  return ngModule;
}

var template$a = "<button\n  class=\"prf-button\"\n  ng-click=\"$ctrl.doClick()\"\n  ng-disabled=\"$ctrl.disabled\"\n>\n  <span class=\"prf-button-text\">{{$ctrl.buttonText}}</span>\n</button>\n";

var css_248z$a = ".prf-button {\n  margin-right: 20px;\n  padding: 2px 15px;\n  color: #ffffff;\n  background-color: #3abdd9;\n  border: 1px solid transparent; }\n  .prf-button .prf-button-text {\n    text-transform: capitalize; }\n  .prf-button[disabled] {\n    background-color: #7D7D7E; }\n";
styleInject(css_248z$a);

class Controller$5 {}
const ButtonComponent = {
  template: template$a,
  controller: Controller$5,
  bindings: {
    buttonText: '<',
    disabled: '<',
    doClick: '&'
  }
};

function definePrfButtonModule(angular) {
  const ngModule = angular.module('prfUiKit.prfButton', []);
  ngModule.component('prfButton', ButtonComponent);
  return ngModule;
}

var template$9 = "<div class=\"prf-radio-group {{$ctrl.styles.main}}\">\n  <cr-transclude></cr-transclude>\n</div>\n";

var css_248z$9 = "";
var styles$5 = {};
styleInject(css_248z$9);

class PrfRadioGroupController {
  constructor() {
    this.styles = styles$5;
    this.onSelectedChange = this.onSelectedChange ? this.onSelectedChange : null;
  }

}
const PrfRadioGroupComponent = {
  template: template$9,
  controller: PrfRadioGroupController,
  bindings: {
    onSelectedChange: '&',
    selectedValue: '<'
  },
  transclude: true
};

var template$8 = "<div class=\"prf-radio-group-radio {{$ctrl.styles.main}}\">\n  <label ng-click=\"$ctrl.doClick()\" class=\"prf-radio {{$ctrl.styles.label}}\">\n    <input type=\"radio\" ng-checked=\"$ctrl.checked()\" />\n    <i></i>\n    <span class=\"prf-data-label\"> {{ $ctrl.label }}</span>\n  </label>\n  <cr-transclude class=\"{{$ctrl.styles.more}}\"></cr-transclude>\n</div>\n";

var css_248z$8 = ".prf-radio-group-radio-component-module_main__2x3yA {\n  margin-bottom: 20px;\n  align-content: center;\n  display: flex;\n  align-items: center; }\n  .prf-radio-group-radio-component-module_main__2x3yA .prf-radio-group-radio-component-module_label__3TETA {\n    flex: 1; }\n  .prf-radio-group-radio-component-module_main__2x3yA .prf-radio-group-radio-component-module_more__37Vqc {\n    flex: 3; }\n";
var styles$4 = {"main":"prf-radio-group-radio-component-module_main__2x3yA","label":"prf-radio-group-radio-component-module_label__3TETA","more":"prf-radio-group-radio-component-module_more__37Vqc"};
styleInject(css_248z$8);

class Controller$4 {
  constructor() {
    this.styles = styles$4;
    this.value = this.value ? this.value : null;
  }

  checked() {
    return this.prfRadioGroup.selectedValue === this.value;
  }

  doClick() {
    this.prfRadioGroup.onSelectedChange({
      value: this.value
    });
  }

}
const PrfRadioGroupRadioComponent = {
  template: template$8,
  controller: Controller$4,
  bindings: {
    value: '<',
    label: '<'
  },
  require: {
    prfRadioGroup: '^^'
  },
  transclude: true
};

function definePrfRadioGroupModule(angular) {
  const ngModule = angular.module('prfUiKit.prfRadioGroup', []);
  ngModule.component('prfRadioGroup', PrfRadioGroupComponent);
  ngModule.component('prfRadioGroupRadio', PrfRadioGroupRadioComponent);
  return ngModule;
}

var template$7 = "<div class=\"{{ $ctrl.styles.main }}\" data-prf-id=\"com-section-card\">\n  <div class=\"{{ $ctrl.styles.headerBar }}\" ng-if=\"!$ctrl.hideHeader\">\n    <span>{{ $ctrl.header }}</span>\n\n    <span\n      class=\"{{ $ctrl.styles.topActionSegment }}\"\n      ng-if=\"$ctrl.showTopAction\"\n      ng-click=\"$ctrl.onTopActionClick()\"\n    >\n      <a\n        class=\"{{ $ctrl.styles.topActionLink }}\"\n        ng-if=\"$ctrl.showTopActionLink\"\n      >\n        {{ $ctrl.topActionLink }}\n      </a>\n\n      <i class=\"pf {{ $ctrl.topActionIcon }}\"></i>\n    </span>\n  </div>\n\n  <div class=\"{{ $ctrl.styles.contentSection }}\">\n    <ng-transclude></ng-transclude>\n  </div>\n</div>\n";

var css_248z$7 = ":root {\n  --color-white: #fff; }\n\n.prf-ui-kit-section-card-component-module_main__CHsBz {\n  background-color: var(--comp--section-card--main--background-color, var(--color-white));\n  border: var(--comp--section-card--main--border, 1px solid #dcdada);\n  font-family: Lato, sans-serif; }\n\n.prf-ui-kit-section-card-component-module_headerBar__1Sf5z {\n  color: #0d131e;\n  background-color: #fafafa;\n  border-bottom: 1px solid #dcdada;\n  line-height: 30px;\n  height: 30px;\n  overflow: hidden;\n  padding: 0 10px;\n  text-transform: uppercase;\n  font-weight: 700;\n  display: flex;\n  justify-content: space-between; }\n\n.prf-ui-kit-section-card-component-module_topActionLink__2LLW0 {\n  text-decoration: underline; }\n";
var styles$3 = {"main":"prf-ui-kit-section-card-component-module_main__CHsBz","headerBar":"prf-ui-kit-section-card-component-module_headerBar__1Sf5z","topActionLink":"prf-ui-kit-section-card-component-module_topActionLink__2LLW0"};
styleInject(css_248z$7);

/// <reference lib="dom" />
class Controller$3 {
  constructor() {
    this.styles = styles$3;
  }

}
const SectionCardComponent = {
  template: template$7,
  controller: Controller$3,
  transclude: true,
  bindings: {
    header: '<',
    hideHeader: '<',
    showTopAction: '<',
    topActionIcon: '<',
    showTopActionLink: '<',
    topActionLink: '<',
    onTopActionClick: '&'
  }
};

function defineNg1UiKitCrmSectionCardModule(angular) {
  const ngModule = angular.module('prfUiKit.prfNg1UiKitCrmSectionCard', []);
  ngModule.component('prfSectionCard', SectionCardComponent);
  return ngModule;
}

/* eslint import/prefer-default-export: 0 */

const crTranscludeModuleName = 'crTransclude';

var template$6 = "<div class=\"prf-block-ui-component\" block-ui=\"{{ :: $ctrl.blockUiId }}\">\n  <cr-transclude></cr-transclude>\n</div>\n";

var css_248z$6 = "";
styleInject(css_248z$6);

/// <reference lib="dom" />
class PrfBlockUiController {
  constructor(blockUI) {
    this.blockUiId = generateUuid();
    this.lifecycles = observeComponentLifecycles(this);
    this.blockOnIn$ = observeShareCompChange(this.lifecycles.onChanges$, 'blockOn');
    this.blockUI = blockUI;
    useStreams([this.blockOnIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
    useStreams([this.streamBlocking()], this.lifecycles.onDestroy$);
  }
  /* eslint-disable-next-line class-methods-use-this */


  $onChanges() {}
  /* eslint-disable-next-line class-methods-use-this */


  $onDestroy() {}

  streamBlocking() {
    return rx.pipe(() => rx.obs.merge(this.blockOnIn$.pipe(rx.filter(doBlock => doBlock), rx.tap(() => {
      this.blockUiInstance.start();
    })), this.blockOnIn$.pipe(rx.filter(doBlock => !doBlock), rx.tap(() => {
      this.blockUiInstance.stop();
    }))))(null);
  }

}
PrfBlockUiController.$inject = ['blockUI'];
const PrfBlockUiComponent = {
  template: template$6,
  transclude: true,
  controller: PrfBlockUiController,
  bindings: {
    blockOn: '<'
  }
};

function definePrfBlockUiModule(ng) {
  const md = ng.module('prfUiKit.prfBlockUi', [crTranscludeModuleName, 'blockUI']);
  md.component('prfBlockUi', PrfBlockUiComponent);
  return md;
}

var template$5 = "<div class=\"prf-pager\" data-prf-id=\"prf-pager\">\n  <button\n    class=\"prf-t-previous-button\"\n    ng-click=\"$ctrl.onPreviousSelect()\"\n    ng-disabled=\"($ctrl.selectedIn$ | async:this) <= 0\"\n  ></button>\n\n  <div class=\"prf-t-pages-selection\">\n    <div\n      class=\"prf-t-page-selection-item\"\n      ng-repeat=\"pageNum in ($ctrl.window$ | async:this).viewWindow\"\n      ng-click=\"$ctrl.onPageSelect({ page: pageNum })\"\n      ng-class=\"{ \n        'prf-t-selected': (($ctrl.selectedIn$ | async:this) === pageNum) \n      }\"\n    >\n      <div class=\"prf-t-page-selection-item-number\">{{ pageNum }}</div>\n    </div>\n\n    <div\n      class=\"prf-t-additional-pages-indication\"\n      ng-if=\"$ctrl.showAdditionalIndicator$ | async:this\"\n    ></div>\n  </div>\n\n  <button\n    class=\"prf-t-next-button\"\n    ng-click=\"$ctrl.onNextSelect()\"\n    ng-disabled=\"($ctrl.selectedIn$ | async:this) >= (($ctrl.amountIn$ | async:this) - 1)\"\n  ></button>\n</div>\n";

var css_248z$5 = "";
styleInject(css_248z$5);

/* eslint-disable class-methods-use-this */

function calcViewWindow(start, end) {
  const arr = [];

  for (let i = start; i < end; i += 1) {
    arr.push(i);
  }

  return arr;
}

function calcNewWindow(page, amount, windowSize) {
  let start = page;
  let end = start + windowSize;
  let viewWindow = calcViewWindow(start, end);

  if (end < amount) {
    return {
      amount,
      start,
      end,
      viewWindow
    };
  }

  end = amount;
  start = end - windowSize;
  viewWindow = calcViewWindow(start, end);
  return {
    amount,
    start,
    end,
    viewWindow
  };
}

function calcWindowWithPrev(page, amount, windowSize, prevWindow) {
  if (page >= prevWindow.start && page < prevWindow.end) {
    return prevWindow;
  }

  if (page < prevWindow.start) {
    return calcNewWindow(page, amount, windowSize);
  }

  return calcNewWindow(page - (windowSize - 1), amount, windowSize);
}

class PrfPagerController {
  constructor() {
    this.lifecycles = observeComponentLifecycles(this);
    this.selectedIn$ = observeShareCompChange(this.lifecycles.onChanges$, 'selected');
    this.amountIn$ = observeShareCompChange(this.lifecycles.onChanges$, 'amount');
    this.maxWindowSizeIn$ = observeShareCompChange(this.lifecycles.onChanges$, 'maxWindowSize');
    this.windowSize$ = this.streamWindowSize();
    this.window$ = this.streamWindow();
    this.showAdditionalIndicator$ = this.streamShowAdditionalIndicator();
    useStreams([this.selectedIn$, this.amountIn$, this.maxWindowSizeIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamWindowSize() {
    return rx.pipe(() => rx.obs.combineLatest(this.amountIn$, this.maxWindowSizeIn$), rx.map(([amount, maxWindowSize]) => {
      return Math.min(amount, maxWindowSize);
    }), shareReplayRefOne())(null);
  }

  streamWindow() {
    const window$ = new rx.BehaviorSubject({
      amount: 0,
      start: 0,
      end: 0,
      viewWindow: []
    });
    return rx.pipe(() => rx.obs.combineLatest(this.selectedIn$, this.amountIn$, this.windowSize$), rx.withLatestFrom(window$), rx.map(([[selected, amount, windowSize], prevWindow]) => {
      if (windowSize !== prevWindow.viewWindow.length) {
        return calcNewWindow(selected, amount, windowSize);
      }

      if (amount !== prevWindow.amount) {
        return calcNewWindow(selected, amount, windowSize);
      }

      return calcWindowWithPrev(selected, amount, windowSize, prevWindow);
    }), rx.tap(newWindow => window$.next(newWindow)), shareReplayRefOne())(null);
  }

  streamShowAdditionalIndicator() {
    return rx.pipe(() => rx.obs.combineLatest(this.window$, this.amountIn$), rx.map(([{
      end
    }, amount]) => {
      return end - 1 < amount - 1;
    }), shareReplayRefOne())(null);
  }

}
const PrfPagerComponent = {
  template: template$5,
  controller: PrfPagerController,
  bindings: {
    selected: '<',
    amount: '<',
    maxWindowSize: '<',
    onPreviousSelect: '&',
    onNextSelect: '&',
    onPageSelect: '&'
  }
};

function definePrfPagerModule(ng) {
  const md = ng.module('prfUiKit.prfPager', []);
  md.component('prfPager', PrfPagerComponent);
  return md;
}

var template$4 = "<div class=\"prf-sidebar-main-layout\">\n  <div class=\"prf-t-sidebar\">\n    <div cr-transclude=\"sidebar\"></div>\n  </div>\n\n  <div class=\"prf-t-section-divider\"></div>\n\n  <div class=\"prf-t-main\">\n    <div cr-transclude=\"main\"></div>\n  </div>\n</div>\n";

var css_248z$4 = ".prf-sidebar-main-layout {\n  display: grid;\n  grid-template-areas: \"sidebar divider main\"; }\n  .prf-sidebar-main-layout > .prf-t-sidebar {\n    grid-area: sidebar; }\n  .prf-sidebar-main-layout > .prf-t-section-divider {\n    grid-area: divider; }\n  .prf-sidebar-main-layout > .prf-t-main {\n    grid-area: main; }\n";
styleInject(css_248z$4);

class PrfSidebarMainLayoutControlelr {}
const PrfSidebarMainLayoutComponent = {
  template: template$4,
  transclude: {
    sidebar: 'trnSidebar',
    main: 'trnMain'
  },
  controller: PrfSidebarMainLayoutControlelr
};

function definePrfSidebarMainLayoutModule(ng) {
  const md = ng.module('prfUiKit.prfSidebarMainLayout', ['crTransclude']);
  md.component('prfSidebarMainLayout', PrfSidebarMainLayoutComponent);
  return md;
}

var template$3 = "<ul\n  class=\"prf-spreaded-select {{ $ctrl.theme }}\"\n  prf-selector=\"$ctrl.selector = selectorApi\"\n  prf-selector-items=\"$ctrl.items\"\n  prf-selector-selected-item=\"$ctrl.selectedItem\"\n  prf-selector-on-item-select=\"$ctrl.selectedItem = item\"\n>\n  <li\n    ng-class=\"{\n      'prf-spreaded-select-item': true,\n      'prf-t-selected': (($ctrl.selector.selectedItems$ | async:this).includes(item))\n    }\"\n    ng-repeat=\"item in ($ctrl.selector.items$ | async:this) track by $ctrl.trackFn(item)\"\n    ng-click=\"$ctrl.selector.selectItem(item)\"\n  >\n    <span\n      cr-transclude\n      cr-transclude-slot=\"item\"\n      ng-if=\"!($ctrl.selector.selectedItems$ | async:this).includes(item)\"\n      context=\"item\"\n    ></span>\n\n    <span\n      cr-transclude\n      cr-transclude-slot=\"selectedItem\"\n      ng-if=\"($ctrl.selector.selectedItems$ | async:this).includes(item)\"\n      context=\"item\"\n    ></span>\n  </li>\n</ul>\n";

var css_248z$3 = "";
styleInject(css_248z$3);

/// <reference lib="dom" />
class PrfSpreadedSelectController {
  constructor() {
    this.trackFn = item => item.label;
  }

}
const PrfSpreadedSelectComponent = {
  template: template$3,
  transclude: {
    item: 'trnItem',
    selectedItem: 'trnSelectedItem'
  },
  controller: PrfSpreadedSelectController,
  bindings: {
    items: '=',
    selectedItem: '=',
    theme: '<'
  }
};

function definePrfSpreadedSelectModule(ng) {
  const md = ng.module('prfUiKit.prfSpreadedSelect', ['crTransclude']);
  md.component('prfSpreadedSelect', PrfSpreadedSelectComponent);
  return md;
}

/* eslint-disable class-methods-use-this */
class PrfSelectorDirectiveController {
  constructor($scope, $attrs, $parse) {
    this.lifecycles = observeComponentLifecycles(this);
    this.itemsInOp$ = new rx.Subject();
    this.selectedItemsInOp$ = new rx.Subject();
    this.selectItemOp$ = new rx.Subject();
    this.items$ = this.streamItems();
    this.selectedItems$ = this.streamSelectedItems();
    this.$scope = $scope;
    this.$attrs = $attrs;
    this.$parse = $parse;
    const selectorApiAttachExpFn = $parse($attrs.prfSelector);
    this.onItemSelectExpFn = $parse($attrs.prfSelectorOnItemSelect);
    const newScope = $scope.$new(false, $scope);
    newScope.selectorApi = this;
    selectorApiAttachExpFn(newScope);
    newScope.$destroy();
    $scope.$watch($attrs.prfSelectorItems, newVal => {
      this.itemsInOp$.next(newVal);
    });
    $scope.$watch($attrs.prfSelectorSelectedItem, newVal => {
      this.selectedItemsInOp$.next([newVal]);
    });
  }

  $onInit() {
    useStreams([this.items$, this.selectedItems$], this.lifecycles.onDestroy$);
  }

  $onChanges() {}

  $onDestroy() {}

  streamItems() {
    return rx.pipe(() => rx.obs.merge(this.itemsInOp$), shareReplayRefOne())(null);
  }

  streamSelectedItemsFromSelectItemIn() {
    return rx.pipe(() => this.selectedItemsInOp$, shareReplayRefOne())(null);
  }

  streamSelectedItemsFromSelectOp() {
    return rx.pipe(() => this.selectItemOp$, rx.map(item => [item]), rx.tap(items => {
      const scope = this.$scope.$new(false, this.$scope);
      Object.assign(scope, {
        item: items[0]
      });
      this.onItemSelectExpFn(scope);
    }), shareReplayRefOne())(null);
  }

  streamSelectedItems() {
    return rx.pipe(() => rx.obs.merge(this.streamSelectedItemsFromSelectOp(), this.streamSelectedItemsFromSelectItemIn()), shareReplayRefOne())(null);
  }

  selectItem(item) {
    this.selectItemOp$.next(item);
  }

}
PrfSelectorDirectiveController.$inject = ['$scope', '$attrs', '$parse'];
const prfSelectorDirective = () => {
  return {
    restrict: 'A',
    bindToController: true,
    controller: PrfSelectorDirectiveController
  };
};
prfSelectorDirective.$inject = [];

function definePrfSelectorModule(ng) {
  const md = ng.module('prfUiKit.prfSelector', []);
  md.directive('prfSelector', prfSelectorDirective);
  return md;
}

var template$2 = "<div class=\"{{ $ctrl.styles.main }}\" data-prf-id=\"com-section-card-action-line\">\n  <div class=\"{{ $ctrl.styles.actionItem }}\" ng-click=\"$ctrl.onClick()\">\n    <div class=\"{{ $ctrl.styles.icon }}\" ng-transclude=\"icon\"></div>\n    <div class=\"{{ $ctrl.styles.content }}\">{{ $ctrl.content }}</div>\n  </div>\n</div>\n";

var css_248z$2 = ".prf-ui-kit-section-card-action-line-component-module_main__1kHi6 {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-end;\n  align-items: center;\n  padding: 5px 15px;\n  font-weight: 700;\n  text-transform: capitalize;\n  cursor: pointer;\n  background-color: #fafafa; }\n\n.prf-ui-kit-section-card-action-line-component-module_actionItem__2Q54C {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-end;\n  align-items: center; }\n\n.prf-ui-kit-section-card-action-line-component-module_icon__1KENB {\n  padding: 0 8px; }\n";
var styles$2 = {"main":"prf-ui-kit-section-card-action-line-component-module_main__1kHi6","actionItem":"prf-ui-kit-section-card-action-line-component-module_actionItem__2Q54C","icon":"prf-ui-kit-section-card-action-line-component-module_icon__1KENB"};
styleInject(css_248z$2);

/// <reference lib="dom" />
class Controller$2 {
  constructor() {
    this.styles = styles$2;
  }

}
const SectionCardActionLineComponent = {
  template: template$2,
  controller: Controller$2,
  transclude: {
    icon: 'prftrIcon'
  },
  bindings: {
    content: '<',
    onClick: '&'
  }
};

function defineNg1UiKitCrmSectionCardActionLineModule(angular) {
  const ngModule = angular.module('prfUiKit.prfNg1UiKitCrmSectionCardActionLine', []);
  ngModule.component('prfSectionCardActionLine', SectionCardActionLineComponent);
  return ngModule;
}

var template$1 = "<div\n  class=\"prf-section-card-content {{ ::$ctrl.theme }}\"\n  data-prf-id=\"com-section-card-content\"\n>\n  <ng-transclude class=\"prf-section-card-content__transclusion\">\n  </ng-transclude>\n</div>\n";

var css_248z$1 = ".prf-ui-kit-section-card-content-component-module_withPadding__3jI8x {\n  padding: 30px 10px; }\n";
var styles$1 = {"withPadding":"prf-ui-kit-section-card-content-component-module_withPadding__3jI8x"};
styleInject(css_248z$1);

/// <reference lib="dom" />
class Controller$1 {
  constructor() {
    this.styles = styles$1;
  }

}
const SectionCardContentComponent = {
  template: template$1,
  controller: Controller$1,
  transclude: true,
  bindings: {
    theme: '<?'
  }
};

function defineNg1UiKitCrmSectionCardContentModule(angular) {
  const ngModule = angular.module('prfUiKit.prfNg1UiKitCrmSectionCardContent', []);
  ngModule.component('prfSectionCardContent', SectionCardContentComponent);
  return ngModule;
}

var template = "<div\n  class=\"{{ $ctrl.styles.main }}\"\n  data-prf-id=\"com-section-card-divider\"\n></div>\n";

var css_248z = ".prf-ui-kit-section-card-divider-component-module_main__1fUuN {\n  border-top: var(--comp--section-card-action-line--main--border, 1px solid #dcdada); }\n";
var styles = {"main":"prf-ui-kit-section-card-divider-component-module_main__1fUuN"};
styleInject(css_248z);

/// <reference lib="dom" />
class Controller {
  constructor() {
    this.styles = styles;
  }

}
const SectionCardDividerComponent = {
  template,
  controller: Controller,
  transclude: true
};

function defineNg1UiKitCrmSectionCardDividerModule(angular) {
  const ngModule = angular.module('prfUiKit.prfNg1UiKitCrmSectionCardDivider', []);
  ngModule.component('prfSectionCardDivider', SectionCardDividerComponent);
  return ngModule;
}

function definePrfUiKitModule(ng) {
  const prfBlockUiModule = definePrfBlockUiModule(ng);
  const prfSelectorModule = definePrfSelectorModule(ng);
  const prfMenubarModule = definePrfMenubarModule(ng);
  const prfPagerModule = definePrfPagerModule(ng);
  const prfSectionCardHeaderModule = definePrfSectionCardHeaderModule(ng);
  const prfSidebarMainLayoutModule = definePrfSidebarMainLayoutModule(ng);
  const prfSpreadedSelectModule = definePrfSpreadedSelectModule(ng);
  const prfSectionCardsLayoutModule = definePrfSectionCardsLayoutModule(ng);
  const prfButtonModule = definePrfButtonModule(ng);
  const rfRadioGroupModule = definePrfRadioGroupModule(ng);
  const prfUiKitSectionCard = defineNg1UiKitCrmSectionCardModule(ng);
  const prfUiKitSectionCardDivider = defineNg1UiKitCrmSectionCardDividerModule(ng);
  const prfUiKitSectionCardContent = defineNg1UiKitCrmSectionCardContentModule(ng);
  const prfUiKitSectionCardActionLine = defineNg1UiKitCrmSectionCardActionLineModule(ng);
  const md = ng.module('prfUiKit', [crTranscludeModuleName, prfBlockUiModule.name, prfMenubarModule.name, prfPagerModule.name, prfSectionCardHeaderModule.name, prfSelectorModule.name, prfSidebarMainLayoutModule.name, prfSpreadedSelectModule.name, prfSectionCardsLayoutModule.name, prfButtonModule.name, rfRadioGroupModule.name, prfUiKitSectionCard.name, prfUiKitSectionCardDivider.name, prfUiKitSectionCardContent.name, prfUiKitSectionCardActionLine.name]);
  return md;
}

export { defineNg1UiKitCrmSectionCardModule, definePrfButtonModule, definePrfMenubarModule, definePrfRadioGroupModule, definePrfSectionCardHeaderModule, definePrfSectionCardsLayoutModule, definePrfUiKitModule };
