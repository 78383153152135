import Component from './base/component';
import ItemLabelStrategy from '~/source/common/components/dropdowns/base/item-label-strategy';

class CampaignIdSelectController extends Component.controller {
  static $inject = [
    'marketingCampaignsService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'marketing.SELECT_CAMPAIGN';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return `${item.id} - ${item.name}`;
  }
}

export default Component.config({ controller: CampaignIdSelectController });
