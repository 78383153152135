import angular from 'angular';
import BonusNotificationsComponent from './bonus-notifications.component';
import CallsNotificationsComponent from './calls-notifications.component';
import CreditsNotificationsComponent from './credits-notifications.component';
import DepositsNotificationsComponent from './deposits-notifications.component';
import FeesNotificationsComponent from './fees-notifications.component';
import PositionsBinaryNotificationsComponent from './positions-binary-notifications.component';
import PositionsForexNotificationsComponent from './positions-forex-notifications.component';
import RiskFreeNotificationsComponent from './risk-free-notifications.component';
import WithdrawalsNotificationsComponent from './withdrawals-notifications.component';
import PendingPositionsForexNotificationsComponent from './pending-positions-forex-notifications.component';
import { InternalTransfersNotificationsComponent } from './internal-transfers-notifications.component';
import { BundlesNotificationsComponent } from './bundles-notifications.component';
import { PortfolioNotificationsComponent } from './portfolio-notifications.component';
import { PortfolioPositionsNotificationsComponent } from '~/source/common/components/collection-notifications/portfolio-positions-notifications.component';

export default angular
  .module('crm.collectionNotifications', [])
  .component('prfBonusesNotifications', BonusNotificationsComponent)
  .component('prfCallsNotifications', CallsNotificationsComponent)
  .component('prfCreditsNotifications', CreditsNotificationsComponent)
  .component('prfDepositsNotifications', DepositsNotificationsComponent)
  .component('prfFeesNotifications', FeesNotificationsComponent)
  .component('prfPortfolioNotifications', PortfolioNotificationsComponent)
  .component(
    'prfPortfolioPositionsNotifications',
    PortfolioPositionsNotificationsComponent,
  )
  .component(
    'prfPositionsBinaryNotifications',
    PositionsBinaryNotificationsComponent,
  )
  .component(
    'prfPositionsForexNotifications',
    PositionsForexNotificationsComponent,
  )
  .component('prfRiskFreesNotifications', RiskFreeNotificationsComponent)
  .component('prfWithdrawalsNotifications', WithdrawalsNotificationsComponent)
  .component(
    'prfPendingPositionsForexNotifications',
    PendingPositionsForexNotificationsComponent,
  )
  .component(
    'prfInternalTransfersNotifications',
    InternalTransfersNotificationsComponent,
  )
  .component('prfBundlesNotifications', BundlesNotificationsComponent).name;
