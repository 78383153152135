import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';
import {
  initRequestDomainResource,
  getAsList,
  setTokenHeader,
  jsonServerToCfdMongo,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { CfdGroup } from '@proftit/tradingcore.api.models.entities';

const RESOURCE_PATH = 'Groups';

export class ApiCfdGroupsService {
  /* @ngInject */
  constructor(readonly $http: IHttpService) {}

  getGroups(apiUrl: string, token: string): Promise<CfdGroup[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => jsonServerToCfdMongo('', req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest<CfdGroup[]>(this.$http, req),
      (req) => req.then((resp: { data: CfdGroup[] }) => resp.data),
    ])();
  }
}
