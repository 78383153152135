import template from './contacts-notifications-view.component.html';
const styles = require('./contacts-notifications-view.component.scss');

import ng, { ITimeoutService } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import BaseController from '~/source/common/controllers/base';
import * as rx from '@proftit/rxjs';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import { useStreams } from '@proftit/rxjs.adjunct';

export class ContactsNotificationsViewController extends BaseController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  newNotificationBlinkAnim = false;

  /*@ngInject */
  constructor(readonly $timeout: ITimeoutService) {
    super();
    useStreams([this.streamCustomersAmount()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCustomersAmount() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange(NEW_CUSTOMERS_AMOUNT_PROP),
      rx.tap((newCustomersAmount) => {
        this.newNotificationBlinkAnim = true;
        this.$timeout(() => (this.newNotificationBlinkAnim = false), 3000);
      }),
    )(null);
  }
}

const NEW_CUSTOMERS_AMOUNT_PROP = 'newCustomersAmount';

export const ContactsNotificationsViewComponent = {
  template,
  controller: ContactsNotificationsViewController,
  bindings: {
    [NEW_CUSTOMERS_AMOUNT_PROP]: '<',
    onClick: '&',
  },
};
