import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';

import template from './brand-data.html';
import { IFormController } from 'angular';

class ComponentController extends BaseController {
  brand: Brand;

  isEdit: boolean;
  prevAttributes: any;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $stateParams: any,
  ) {
    super();
  }

  $onInit() {
    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = {
      name: this.brand.name,
      logo: { ...this.brand.logo },
      platformType: { ...this.brand.platformType },
      status: this.brand.status,
    };

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.brand, this.prevAttributes);
    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    const normalizedModel = {
      isActive: this.brand.isActive,
      name: this.brand.name,
      logoId: this.brand.logoId,
    };

    // save the brand
    this.brandsService()
      .getBrandResource(this.brand.id)
      .setConfig({ blockUiRef: 'brandData' })
      .patchWithQuery(normalizedModel)
      .then(() => {
        // change to display mode
        this.isEdit = false;
      });
  }

  /**
   * Set form validatity to flase when uploading logo.
   * @param form
   */
  whenBrandLogoUpload(form: IFormController) {
    form.logoId.$setValidity('notUploading', false);
  }

  /**
   * Set form validatity to true when done uploading logo.
   * @param form
   */
  whenBrandLogoUploadDone(form: IFormController) {
    form.logoId.$setValidity('notUploading', true);
  }
}

const BrandDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};

export default BrandDataComponent;
