import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import ng from 'angular';
import { wrapNgPermissionValidatePromise } from '../utilities/wrap-ng-permission-validate-promise';
import { CurrentUserStoreService } from '../store-services/current-user-store.service';

/**
 * Filter factory.
 */
export function hasPermissionsFilterFactory(
  PermPermissionStore: ng.permission.PermissionStore,
  prfCurrentUserStore: CurrentUserStoreService,
) {
  function hasPermissionsFilter(permissionsList: string[]) {
    const promises = permissionsList.map((perm) =>
      wrapNgPermissionValidatePromise(
        PermPermissionStore.getPermissionDefinition(perm),
      ),
    );

    return Promise.all(promises).then((permsResults) =>
      permsResults.every((isAllowed) => isAllowed),
    );
  }

  const memFn = _.memoizeEs(hasPermissionsFilter, (a) => a);

  (memFn as any).$stateful = true;

  prfCurrentUserStore.loginEvent$
    .pipe(rx.tap(() => memFn.cache.clear()))
    .subscribe();

  return memFn;
}

hasPermissionsFilterFactory.$inject = [
  'PermPermissionStore',
  'prfCurrentUserStore',
];
