import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { Mt4Asset } from '@proftit/tradingcore.api.models.entities';
import { objShallowDifference } from '@proftit/general-utilities';
import { generateObjectProxy } from '~/source/common/proxy-form/generate-object-proxy';
import { FieldProxyHandler } from '~/source/common/proxy-form/field-proxy-handler';
// import { isNumberProxyValidator } from '~/source/common/proxy-form/validators/is-number-proxy-validator';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { isStringValueProxyValidatorFactory } from '~/source/common/proxy-form/validators/is-string-value-proxy-validator-factory';
import { isNumericStringProxyValidator } from '~/source/common/proxy-form/validators/is-numeric-string-proxy-validator';
import { orCombineProxyValidatorFactory } from '~/source/common/proxy-form/validators/or-combine_proxy-validator-factory';

export function generateAssetForm() {
  const proxy: any = generateObjectProxy<Mt4Asset>({});

  const readonlyFields = [
    'assetType',
    'baseCurrency',
    'contractSize',
    'id',
    'marginMode',
    'mtSecuritiesName',
    'name',
    'otherCurrency',
    'precision',
    'source',
    'status',
    'symbol',
    'tradeState',
    'mtCustomerGroup',
    'mtGroupSecurities',
  ];

  readonlyFields.forEach((fieldName) => {
    proxy.handler.addFieldHandler(
      fieldName,
      new FieldProxyHandler({
        isReadonly: true,
      }),
    );
  });

  proxy.handler.addFieldHandler(
    'percentage',
    new FieldProxyHandler({
      validators: [
        orCombineProxyValidatorFactory([
          isNumericStringProxyValidator,
          isStringValueProxyValidatorFactory(['default']),
        ]),
      ],
    }),
  );

  proxy.handler.addFieldHandler(
    'swapLong',
    new FieldProxyHandler({
      validators: [
        orCombineProxyValidatorFactory([
          isNumericStringProxyValidator,
          isStringValueProxyValidatorFactory(['default']),
        ]),
      ],
    }),
  );

  proxy.handler.addFieldHandler(
    'swapShort',
    new FieldProxyHandler({
      validators: [
        orCombineProxyValidatorFactory([
          isNumericStringProxyValidator,
          isStringValueProxyValidatorFactory(['default']),
        ]),
      ],
    }),
  );

  proxy.handler.addFieldHandler(
    'isDefaultPercentage',
    new FieldProxyHandler({}),
  );

  proxy.handler.addFieldHandler('isDefaultSwapLong', new FieldProxyHandler({}));

  proxy.handler.addFieldHandler(
    'isDefaultSwapShort',
    new FieldProxyHandler({}),
  );

  const isValid$ = new rx.BehaviorSubject<boolean>(true);
  const validationResults$ = new rx.BehaviorSubject<any[]>([]);
  const value$ = new rx.BehaviorSubject<Mt4Asset>(null);
  const initial$ = new rx.BehaviorSubject<Mt4Asset>(null);
  const changes$ = rx.pipe(
    () => rx.obs.combineLatest(value$, initial$),
    rx.map(([value, initial]) => objShallowDifference(value, initial)),
    shareReplayRefOne(),
  )(null);

  proxy.handler
    .getLeaf()
    .addIsValidListener(({ isValid }) => isValid$.next(isValid));

  proxy.handler
    .getLeaf()
    .addValidationResultsListener(({ validationResults }) =>
      validationResults$.next(validationResults),
    );

  proxy.handler.getLeaf().addValueListener(({ value }) => value$.next(value));

  proxy.handler
    .getLeaf()
    .addInitialListener(({ initial }) => initial$.next(initial));

  return {
    proxy,
    isValid$,
    validationResults$,
    value$,
    initial$,
    changes$,
  };
}
