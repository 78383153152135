import { QuestionnaireScoreSegment } from '@proftit/crm.api.models.entities';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import {
  isNotEmptyStringFormValidator,
  isNotNilFormValidator,
  isStringNotEmptyOrOtherNotNilFormValidator,
} from '@proftit/ng1.reactive-forms.validators';
import { generateSegment } from '../../utilities/generate-segment';

export function genereateNewSegmentControl(
  segment = generateSegment(),
  segmentIndex,
) {
  /*
   * The failed semgent (the first one) should be readonly.
   * The second segment - first success) sould be not deletable. only editable.
   */
  const isReadonlyProps = segmentIndex === 0;
  const isReadonlyMain = segmentIndex < 2;

  return new FormGroup(
    {
      id: new FormControl<string>(segment.id as any, {
        isReadonly: isReadonlyProps,
      }),
      name: new FormControl<string>(segment.name, {
        isReadonly: isReadonlyProps,
        validators: [isStringNotEmptyOrOtherNotNilFormValidator],
      }),
      start: new FormControl<number>(segment.start, {
        isReadonly: isReadonlyProps,
        validators: [isNotNilFormValidator],
      }),
      end: new FormControl<number>(segment.end, {
        isReadonly: isReadonlyProps,
        validators: [isNotNilFormValidator],
      }),
    } as any,
    {
      isReadonly: isReadonlyMain,
    },
  );
}
