import log from 'loglevel';
import { SocketService } from '~/source/common/services/socket';
import { hashCode } from '../utilities/hash-code';

export class Mt4AccountPnlSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  token: string;

  streamerUrl = '';

  /**
   * Specify socket registry key.
   *
   * there are registry for crm socket, platform socket..
   * Sockets should be shared for all services targeting the same target.
   */
  get socketRegistryKey() {
    const urlHash = hashCode(this.streamerUrl);
    return `mt4Socket&url=${urlHash}`;
  }

  getStreamerUrl() {
    // return this.appConfig.connections.mt4Streamer;
    return this.streamerUrl;
  }

  get channelRoot() {
    return 'accountpnl';
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }

  /**
   * Set streamer info
   *
   * @param host - host
   * @param port - port
   */
  setStreamerUrl(url: string) {
    this.streamerUrl = url;
  }

  setToken(token: string) {
    this.token = token;
  }

  buildSocketQuery() {
    return `token=${this.token}`;
  }
}

/**
 * Build channel name for account openpnl subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildMt4AccountPnlChannel(accountSyncId: number) {
  return `accountpnl.${accountSyncId}`;
}
