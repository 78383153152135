import { IPromise } from 'angular';
import { Currency, RateBaseSymbol } from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import IElementRestNg from '../models/ielement-rest-ng';

const SERVICE_RESOURCE = 'rates/bases';
const SYMBOLS_RESOURCE = 'symbols';

export class RatesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  getRateResource(
    baseCurrencyCode: string,
    targetCurrencyCode: string,
  ): RatesService {
    return this.resourceBuildStart()
      .getElement(baseCurrencyCode)
      .getNestedElement(SYMBOLS_RESOURCE, targetCurrencyCode)
      .resourceBuildEnd();
  }

  getRate(
    baseCurrencyCode: string,
    targetCurrencyCode: string,
  ): Promise<RateBaseSymbol> {
    return this.getRateResource(baseCurrencyCode, targetCurrencyCode)
      .getOneWithQuery<IElementRestNg<RateBaseSymbol>>()
      .then((data) => data.plain());
  }
}
