import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';

export class TestEmailConnectionService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'testConnection';
  }

  getEmailProviderResource(emailProviderCode) {
    return this.resourceBuildStart()
      .getElement(emailProviderCode)
      .resourceBuildEnd();
  }
}
