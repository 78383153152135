import BaseService from '~/source/common/services/baseService';
import CacheService from '~/source/common/services/cache.service';

const cacheNamespace = 'contactDashboardCacheData';

export class ContactDashboardCacheService extends BaseService {
  static cacheNamespace = 'contactDashboardCacheData';

  static $inject = ['cacheService'];

  cacheService: CacheService;

  constructor(...args) {
    super(...args);

    this.cacheService.initializeSafe(
      ContactDashboardCacheService.cacheNamespace,
      { storageMode: 'sessionStorage' },
    );
  }

  /**
   * Save modal info into the cache.
   *
   * @param {string} cacheId - cache id to be used in the cache.
   * @param {*} data the data to store in local storage
   */
  put(cacheId: string, data: any): void {
    return this.cacheService.put(
      ContactDashboardCacheService.cacheNamespace,
      cacheId,
      data,
    );
  }

  /**
   * Get modal info from the cache.
   *
   * @param {string} cacheId - the id of the cache instance.
   * @return {(*|null)} existing modal info in the cache, or null if not exists.
   */
  get(cacheId: string): any | null {
    return this.cacheService.get(
      ContactDashboardCacheService.cacheNamespace,
      cacheId,
    );
  }
}

export default ContactDashboardCacheService;
