import ng from 'angular';
import dashboardComponent from './accounting-dashboard.component';
import accountingStatsComponent from './accounting-stats.component';
import depositChartsComponent from './transaction-charts/deposit-charts.component';
import transactionChartsComponent from './transaction-charts/transactions-charts.component';
import withdrawalChartsComponent from './transaction-charts/withdrawal-charts.component';
import marketingStatsComponent from './marketing-stats.component';
import accountingStatsService from './accounting-stats.service';
import ColorLuminanceService from './color-luminance.service';
import { RelativeStatsComponent } from './relative-stats/relative-stats.component';

export default ng
  .module('crm.accounting', [
    'crm.constants',
    'crm.common',
    'crm.brand',
    'ui.router',
  ])
  .component('prfAccountingDashboard', dashboardComponent)
  .component('prfDepositCharts', depositChartsComponent)
  .component('prfAccountingStats', accountingStatsComponent)
  .component('prfAccountingMarketingStats', marketingStatsComponent)
  .component('prfTransactionsCharts', transactionChartsComponent)
  .component('prfWithdrawalCharts', withdrawalChartsComponent)
  .component('prfRelativeStats', RelativeStatsComponent)

  .service('statsService', accountingStatsService.$factorize())
  .service('colorLuminanceService', ColorLuminanceService)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.accounting', {
        url: '/accounting',
        component: 'prfAccountingDashboard',
        data: {
          pageTitle: 'ACCOUNTING',
        },
      });
    },
  ]).name;
