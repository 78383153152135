import template from './risk-free-deduct.html';
import RiskFreeCommonController from './risk-free-base.controller';

class RiskFreeDeductController extends RiskFreeCommonController {
  quantityToDeduct = undefined;

  /**
   * Deduct risk free positions and call the 'onDone' callback on success
   *
   * @return {Promise} resolves on success
   */
  deductRiskFree() {
    //  make sure to always pass either a value or an 'undefined' (not null)
    return this.updateRiskFreePositions(
      this.customer.id,
      this.account.id,
      0 - (this.quantityToDeduct || 0),
    ).then(() => {
      this.account.riskFreeAvailable -= this.quantityToDeduct;
      this.onDone();
    });
  }
}

export default {
  template,
  controller: RiskFreeDeductController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
  },
};
