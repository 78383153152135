import * as _ from '@proftit/lodash';

import { Changeset } from './changeset';

export function setFieldUpdate(
  fieldName: string,
  value: any,
  changeset: Changeset<any>,
) {
  const newChanges = _.reject(
    (x) => x.fieldName === fieldName,
    changeset.changes,
  );

  return {
    ...changeset,
    changes: [...newChanges, { fieldName, nextValue: value }],
  };
}
