import ng from 'angular';
import * as rx from '@proftit/rxjs';
import log from 'loglevel';
import { ContactsImportsService } from '~/source/common/api-crm-server/services/contacts-imports.service';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import {
  userContactsImportNewChannelName,
  userContactsImportUpdateChannelName,
} from '@proftit/crm.api.channels';
import { ContactsImport } from '@proftit/crm.api.models.entities';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import { ContactsImportSocketService } from '~/source/common/services/contacts-import-socket-service';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

export class ContactsImportStoreService {
  newContactsImportByChannelNew$ = this.streamNewContactsImportByChannelNew();

  activeContactsImports$ = this.streamActiveContactsImports();

  hasActiveContactsImports$ = this.streamHasActiveContactsImports();

  hasNoActiveContactsImports$ = this.streamHasNoActiveContactsImports();

  whenHasActiveContactsImports$ = this.streamWhenHasActiveContactsImports();

  /* @ngInject */
  constructor(
    readonly prfContactsImportsService: () => ContactsImportsService,
    readonly prfCurrentUserStore: CurrentUserStoreService,
    readonly prfContactsImportSocketService: ContactsImportSocketService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {}

  streamActiveContactsImportsByReq() {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.from([
            {
              isView: false,
            },
          ]);
        }

        return checkCrudPermission(
          PermissionNormalized.ContactsContactImport,
          this.PermPermissionStore,
        );
      }),
      rx.switchMap((perms) => {
        if (!perms.isView) {
          return rx.obs.from([[]]);
        }

        return rx.obs
          .from(
            this.prfContactsImportsService()
              .filter({ isDone: false })
              .expand(['resource'])
              .getListWithQuery()
              .then((data) => data.plain()),
          )
          .pipe(
            rx.catchError((err) => {
              log.error('error fetching contacts import processes', err);
              return rx.obs.from([[]]);
            }),
          );
      }),
    )(null);
  }

  streamNewContactsImportByChannelNew(): rx.Observable<ContactsImport> {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userContactsImportNewChannelName(user.id);
        return observeChannel<ContactsImport>(
          this.prfContactsImportSocketService,
          channelName,
        );
      }),
      rx.switchMap((contactsImport) => {
        return rx.obs
          .from(
            this.prfContactsImportsService()
              .getItem((contactsImport as ContactsImport).id)
              .then((data) => data.plain()),
          )
          .pipe(
            rx.catchError((err) => {
              log.error('error fetching contacts import processes', err);
              return rx.obs.from([contactsImport]);
            }),
          );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveContactsImportsByChannelNew() {
    return rx.pipe(
      () => this.newContactsImportByChannelNew$,
      rx.filter((channelUpdate) => !channelUpdate.isDone),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveContactsImports(): rx.Observable<Record<number, ContactsImport>> {
    const activeContactsImports$ = new rx.BehaviorSubject<
      Record<number, ContactsImport>
    >({});

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamActiveContactsImportsByReq(),
          this.streamActiveContactsImportsByChannelNew().pipe(
            rx.map((a) => [a]),
          ),
        ),
      rx.mergeMap((processes) => {
        const processesUpdates$list: rx.Observable<
          ContactsImport
        >[] = processes.map((p) =>
          this.observeContactImportForDoneUpdateUntilDone(p.id).pipe(
            rx.startWith(p),
          ),
        );

        return rx.obs.merge(...processesUpdates$list);
      }),
      rx.withLatestFrom(activeContactsImports$),
      rx.map(([contactsImport, activeContactsImports]) => {
        if (contactsImport.isDone) {
          return _.omit([contactsImport.id], activeContactsImports);
        }

        return {
          ...activeContactsImports,
          [contactsImport.id]: contactsImport,
        };
      }),
      rx.tap((imports) => activeContactsImports$.next(imports)),
      shareReplayRefOne(),
    )(null);
  }

  streamHasActiveContactsImports() {
    return rx.pipe(
      () => this.activeContactsImports$,
      rx.map((imports) => Object.keys(imports).length > 0),
      shareReplayRefOne(),
    )(null);
  }

  streamHasNoActiveContactsImports() {
    return rx.pipe(
      () => this.hasActiveContactsImports$,
      rx.map((has) => !has),
      shareReplayRefOne(),
    )(null);
  }

  streamWhenHasActiveContactsImports() {
    return rx.pipe(
      () => this.hasActiveContactsImports$,
      rx.filter((has) => has),
      shareReplayRefOne(),
    )(null);
  }

  observeContactImportForDoneUpdateUntilDone(
    id: number,
  ): rx.Observable<ContactsImport> {
    return rx.pipe(
      () => this.observeContactsImportUpdateFromSocket(id),
      rx.filter((channelUpdate) => channelUpdate.isDone),
      rx.first(),
    )(null);
  }

  observeContactsImportUpdateFromSocket(
    contactsImportId: number,
  ): rx.Observable<ContactsImport> {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }
        const channelName = userContactsImportUpdateChannelName(
          user.id,
          contactsImportId,
        );
        return observeChannel(this.prfContactsImportSocketService, channelName);
      }),
    )(null);
  }
}
