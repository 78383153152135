export const FocusIfDirective = () => ({
  restrict: 'A',
  link($scope, element, attrs) {
    $scope.$watch(attrs.prfFocusIf, (newValue) => {
      if (newValue) {
        element.focus();
      }
    });
  },
});
