import { growl, blockUI } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, syncTwoWay } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { BrandsService } from '~/source/management/brand/services/brands';
import { UserSettingsService } from '~/source/common/services/user-settings';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import {
  Brand,
  BrandPlatform,
  ForexGroup,
} from '@proftit/crm.api.models.entities';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { BrandsStoreService } from '~/source/common/store-services/brands-store.service';
import template from './cfd-platform-brands-manager.component.html';
import { CurrentBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-brand-store-service.directive';
import { CurrentPlatformOfBrandStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-of-brand-store-service.directive';
import { CurrentUserSettingsRiskBrandsManagerServiceDirectiveController } from '~/source/common/service-directives/current-user-settings-risk-brands-manager-service.directive';
import { applyUserSettingsBrandPlatformAndGroup } from '../../utilities/apply-user-settings-brand-platform-and-group';
const styles = require('./cfd-platform-brands-manager.component.scss');

export class CfdPlatformBrandsManagerController {
  /* require */

  prfCurrentBrand: CurrentBrandStoreServiceDirectiveController;

  prfCurrentPlatformOfBrand: CurrentPlatformOfBrandStoreServiceDirectiveController;

  prfCurrentUserSettingsRiskBrandsManager: CurrentUserSettingsRiskBrandsManagerServiceDirectiveController;

  /* bindings */

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  selectedBrandBlockUi = generateBlockuiId();

  selectedBrandPlatformBlockUi = generateBlockuiId();

  selectedGroupBlockUi = generateBlockuiId();

  selectedBrandF = new FormControl<Brand>(null);

  selectedBrandPlatformF = new FormControl<BrandPlatform>(null);

  selectedGroupF = new FormControl<ForexGroup>(null);

  /* @ngInject */
  constructor(
    readonly blockUI: blockUI.BlockUIService,
    readonly growl: growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly brandsService: () => BrandsService,
    readonly userSettingsService: UserSettingsService,
    readonly popupService: PopupService,
    readonly prfBrandsStore: BrandsStoreService,
  ) {
    useStreams(
      [
        this.selectedBrandF.value$,
        this.selectedBrandPlatformF.value$,
        this.selectedGroupF.value$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        syncTwoWay(
          this.prfCurrentBrand.brand$,
          (brand: Brand) =>
            this.prfCurrentBrand.loadBrand(_.get(['id'], brand)),
          this.selectedBrandF.value$,
          (val: Brand) => this.selectedBrandF.setValue(val),
          (brandA: Brand, brandB: Brand) =>
            _.get(['id'], brandA) === _.get(['id'], brandB),
        ),
        syncTwoWay(
          this.prfCurrentPlatformOfBrand.brandPlatformS.stream$,
          (val: BrandPlatform) =>
            this.prfCurrentPlatformOfBrand.setBrandPlatform(val),
          this.selectedBrandPlatformF.value$,
          (val: BrandPlatform) => this.selectedBrandPlatformF.setValue(val),
          (brandPlatformA: BrandPlatform, brandPlatformB: BrandPlatform) =>
            _.get(['id'], brandPlatformA) === _.get(['id'], brandPlatformB),
        ),
        rx.pipe(
          () => rx.obs.of(true),
          applyUserSettingsBrandPlatformAndGroup(
            this.blockUI,
            this.blockUiId,
            this.growl,
            this.growlMessages,
            this.growlId,
            this.selectedBrandF,
            this.selectedBrandPlatformF,
            new FormControl(null),
            this.prfCurrentUserSettingsRiskBrandsManager,
            this.prfBrandsStore.brandsWithCfdMt4S.stream$,
            this.prfCurrentBrand.cfdMt4brandPlatforms$,
            rx.obs.EMPTY,
            false,
          ),
          rx.switchMap(() =>
            this.prfCurrentUserSettingsRiskBrandsManager.syncOneWayBy(
              this.selectedBrandF.value$,
              this.selectedBrandPlatformF.value$,
            ),
          ),
        )(null),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  openAuditLogTablePopup(brandPlatform: BrandPlatform) {
    this.popupService.open({
      component: 'prfTradingCoreBrandsAuditLogTablePopup',
      resolve: {
        brandPlatform: () => brandPlatform,
      },
    });
  }
}

export const CfdPlatformBrandsManagerComponent = {
  template,
  controller: CfdPlatformBrandsManagerController,
  require: {
    prfCurrentBrand: '^',
    prfCurrentPlatformOfBrand: '^',
    prfCurrentUserSettingsRiskBrandsManager: '^',
  },
};

export default CfdPlatformBrandsManagerComponent;
