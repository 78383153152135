import Component from './base/component';
import { User } from '@proftit/crm.api.models.entities';

class UsernameSelectController extends Component.controller {
  static $inject = ['usersService', ...Component.controller.$inject];

  isActive: boolean;

  query() {
    const filters = {
      isActive: this.isActive !== undefined ? this.isActive : true,
      'role.code': {
        exclude: 'extapi',
      },
    };

    return this.dataServiceInst
      .expand('role')
      .filter(filters)
      .getListWithQuery()
      .then((users) => {
        users.forEach((user: User & { name: string }) => {
          user.name = user.username;
        });
        return users;
      });
  }

  get defaultPlaceholder() {
    return 'user.SELECT_USER';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: UsernameSelectController,
  bindings: {
    isActive: '<',
  },
});
