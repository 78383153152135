/**
 * List of existed time zone offsets from UTC
 *
 * @type {number[]}
 */
export const tzOffsets = [
  -12,
  -11,
  -10,
  -9.5,
  -9,
  -8,
  -7,
  -6,
  -5,
  -4,
  -3.5,
  -3,
  -2,
  -1,
  0,
  1,
  2,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  5.75,
  6,
  6.5,
  7,
  8,
  8.5,
  8.75,
  9,
  9.5,
  10,
  10.5,
  11,
  12,
  12.75,
  13,
  14,
];

export default tzOffsets;
