import ng, { IHttpService } from 'angular';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { Mt4Group, MtSecurity } from '@proftit/tradingcore.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  pipeAsyncWorkInUi,
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import {
  ApiMt4GroupsService,
  RESOURCE_PATH,
} from '~/source/common/api-cfd-platform/api-mt4-groups.service';
import { TableLiveController } from '~/source/common/components/table/table-live.controller';
import { SocketService } from '~/source/common/services/socket';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { initPrivateReqMt4 } from '~/source/common/api-cfd-platform/trading-core-request-utilities/init-private-req-mt4';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { settings } from './brand-platform-mt4-group-securities-table-settings';
import { brandPlatformMt4GroupSecuritiesTableColumns } from './brand-platform-mt4-group-securities-table-columns';
import template from './brand-platform-mt4-group-securities-table.component.html';
import { CurrentTrcStreamerOfBrandPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-steamer-of-brand-platform-service.directive';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
const styles = require('./brand-platform-mt4-group-securities-table.component.scss');

const GLOBAL_GROWL_ID = 'restService';

export class BrandPlatformMt4GroupSecuritiesTableController extends TableLiveController {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'modalService',
    'prfApiMt4GroupsService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  /* require */

  prfCurrentTrcStreamerOfBrandPlatform: CurrentTrcStreamerOfBrandPlatformServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* injections */

  $http: IHttpService;

  blockUI: ng.blockUI.BlockUIService;

  growl: ng.growl.IGrowlService;

  growlMessages: ng.growl.IGrowlMessagesService;

  modalService: ModalService;

  prfApiMt4GroupsService: ApiMt4GroupsService;

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /* bindings */

  brandPlatform: BrandPlatform;

  mgGroup: Mt4Group;

  /* state */

  styles = styles;

  settings = _.cloneDeep(settings);

  cols = [...brandPlatformMt4GroupSecuritiesTableColumns];

  lifecycles = observeComponentLifecycles(this);

  brandPlatformIn$ = observeShareCompChange<BrandPlatform>(
    this.lifecycles.onChanges$,
    'brandPlatform',
  );

  mtGroupIn$ = observeShareCompChange<Mt4Group>(
    this.lifecycles.onChanges$,
    'mtGroup',
  );

  opOpenEditPopup$ = new rx.Subject<Mt4Group>();

  securities: Restangular.ICollection;

  items$ = new rx.BehaviorSubject<MtSecurity[]>([]);

  constructor(...args) {
    super(...args);

    useStreams(
      [this.brandPlatformIn$, this.mtGroupIn$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamInitTable(),
        this.streamOpenEditPopup(),
        this.streamItemsStreamerUpdates(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  get tableKey() {
    return 'mt4BrandPlatformGroupSecurities';
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return null;
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId: number): string {
    return null;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.securities';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.securities;
  }

  isStreamingOn() {
    return false;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  parseLoadedData(data) {
    this.securities = data;
    this.items$.next(data);
    return data;
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfCurrentPlatformSession.sessionS.stream$.pipe(
            rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
          ),
          this.mtGroupIn$,
        ),
      rx.filter((deps) => deps.every((d) => !_.isNil(d))),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.map(([sessionInfo, mtGroup]) => {
        let req = initPrivateReqMt4(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
          RESOURCE_PATH,
        );
        req = this.prfApiMt4GroupsService.reqGroupSecurities(
          req,
          (mtGroup as any).id,
        );

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamOpenEditPopup() {
    return rx.pipe(
      () => this.opOpenEditPopup$,
      rx.withLatestFrom(this.brandPlatformIn$, this.mtGroupIn$),
      rx.tap(([security, brandPlatform, group]) =>
        this.openEditPopup(brandPlatform, group, (security as any).id),
      ),
    )(null);
  }

  streamItemsStreamerUpdates() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.items$, this.mtGroupIn$),
      rx.switchMap(([items, group]) => {
        const updates = items.map((item: any) => {
          return this.prfCurrentTrcStreamerOfBrandPlatform.observeMtGroupMtSecurity(
            (group as any).id,
            item.id,
          );
        });

        return rx.obs.merge(rx.obs.NEVER, ...updates);
      }),
      rx.withLatestFrom(this.items$),
      rx.tap(([update, items]) => {
        const orig = items.find((a: any) => a.id === (update as any).id);
        if (_.isNil(orig)) {
          return;
        }

        Object.assign(
          orig,
          _.pick(['maxVolume', 'minVolume', 'spread', 'volumeStep'], update),
        );
      }),
    )(null);
  }

  openEditPopup(
    brandPlatform: BrandPlatform,
    group: Mt4Group,
    securityId: number,
  ) {
    this.modalService.open({
      component: 'prfBrandPlatformNt4GroupSecurityDialog',
      resolve: {
        brandPlatform: () => brandPlatform,
        mtGroupId: () => (group as any).id,
        securityId: () => securityId,
        operation: () => 'update',
      },
    });
  }
}

export const BrandPlatformMt4GroupSecuritiesTableComponent = {
  template,
  controller: BrandPlatformMt4GroupSecuritiesTableController,
  controllerAs: 'vm',
  bindings: {
    brandPlatform: '<',
    mtGroup: '<',
  },
  require: {
    prfCurrentTrcStreamerOfBrandPlatform: '^',
    prfCurrentPlatformSession: '^',
  },
};
