import template from './questionnaire-score-scorings-form.component.html';
const styles = require('./questionnaire-score-scorings-form.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { createComponentsScoresFormArray } from './utilities/create-components-scores-form-array';
import { QuestionnaireComponentTypeCode } from '@proftit/crm.api.models.enums';

export class QuestionnaireScoreScoringsFormController {
  onChangeIsPristine: (x: { isPristine: any }) => void;
  onChangeIsValid: (x: { isValid: any }) => void;
  onChangeValue: (x: { value: any }) => void;

  QuestionnaireComponentTypeCode = QuestionnaireComponentTypeCode;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  model$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'model');
  form$ = this.streamForm();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.form$,
        this.streamActionNotifyChangeValue(),
        this.streamActionNotifyChangeIsValid(),
        this.streamActionNotifyChangeIsPristine(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) => createComponentsScoresFormArray(model)),
      shareReplayRefOne(),
    )(null);
  }

  streamActionNotifyChangeValue() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.value$),
      rx.tap((value) => this.onChangeValue({ value })),
    )(null);
  }

  streamActionNotifyChangeIsValid() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isValid$),
      rx.tap((isValid) => this.onChangeIsValid({ isValid })),
    )(null);
  }

  streamActionNotifyChangeIsPristine() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.isPristine$),
      rx.tap((isPristine) => this.onChangeIsPristine({ isPristine })),
    )(null);
  }
}

export const QuestionnaireScoreScoringsFormComponent = {
  template,
  controller: QuestionnaireScoreScoringsFormController,
  bindings: {
    model: '<',
    onChangeValue: '&',
    onChangeIsValid: '&',
    onChangeIsPristine: '&',
  },
};
