import template from './side-menu-item.component.html';
const styles = require('./side-menu-item.component.scss');

import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import { SideMenuItem } from './side-menu-item';

export class SideMenuItemController extends BaseController {
  styles = styles;
  menuItem: SideMenuItem;

  /*@ngInject */
  constructor() {
    super();
  }
}

export const SideMenuItemComponent = {
  template,
  controller: SideMenuItemController,
  bindings: {
    menuItem: '<',
    level: '<',
    onCollapseClick: '&',
  },
};
