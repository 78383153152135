import * as rx from '@proftit/rxjs';

/**
 *
 */
export function wrapAsChange(fieldName: string) {
  return function operator(obs$: rx.Observable<any>): rx.Observable<any> {
    return rx.pipe(
      () => obs$,
      rx.map((nextValue) => ({
        fieldName,
        nextValue,
      })),
    )(null);
  };
}
