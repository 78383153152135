import Promise from 'bluebird';
import Component from '~/source/common/components/dropdowns/base/component';
import { fieldsAndModulesItems } from './fields-and-modules-items';

class FieldsAndModulesListSelectController extends Component.controller {
  query() {
    return Promise.resolve(fieldsAndModulesItems);
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'fieldsAndModulesListSelect';
  }
}

export const FieldsAndModulesListSelectComponent = Component.config({
  controller: FieldsAndModulesListSelectController,
});
