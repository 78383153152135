import * as _ from '@proftit/lodash';
import template from './info-audit-log-popup.html';
import TablePopupController from '~/source/common/components/table/table-popup';
import infoAuditSettings from './info-audit-log-popup-settings.json';

import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';

class Controller extends TablePopupController {
  static $inject = [
    'customersService',
    'blockUI',
    ...TablePopupController.$inject,
  ];

  settings = _.cloneDeep(infoAuditSettings);
  cols = [...this.settings.tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;
  auditSearch: string;

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings.popupTable.ngTable;
  }

  get tableKey() {
    return 'infoAuditLogPopup';
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      return;
    }
    this.customer = resolve.customer;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getInfoAuditLogResource(this.customer.id)
      .exclude('field', 'userId')
      .sort({ requestedAt: 'desc' });
  }

  /**
   * called on audit info search changes in view
   * @return {void}
   */
  onAuditSearchChange() {
    this.tableParams.page(1);

    /*
     * set audit search value to table filter reference object.
     * this change will trigger api call
     */
    this.tableParams.filter().q = this.auditSearch;
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @override
   * @returns {Object}
   */
  get requiredApiFilters() {
    return {
      q: this.auditSearch,
    };
  }
}

export const InfoAuditLogPopupTable = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
