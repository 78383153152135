import * as _ from '@proftit/lodash';
/**
 * text filter, to use when concatenating strings
 */
export function trimTextFactory() {
  return function (inputStr: string) {
    const filteredArr = inputStr.split(' ').filter((word) => word !== 'null');

    if (filteredArr.length === 0) {
      return '';
    }

    if (filteredArr.length === 1) {
      return filteredArr[0];
    }

    return inputStr;
  };
}
trimTextFactory.$inject = [];
