import type { StateProvider } from '@uirouter/angularjs';

export const automationRouting = [
  '$stateProvider',
  ($stateProvider: StateProvider) => {
    $stateProvider
      .state('crm.management.tools.automation', {
        redirectTo: 'crm.management.tools.automation.inactivityFee',
        url: '/automation',
        component: 'prfAutomationDashboard',
      })
      .state('crm.management.tools.automation.inactivityFee', {
        url: '/inactivity-fee',
        component: 'prfInactivityFeeDashboard',
      })
      .state('crm.management.tools.automation.accountStatus', {
        url: '/account-status',
        component: 'prfAccountStatusDashboard',
      })
      .state('crm.management.tools.automation.depositDemoAccount', {
        url: '/deposit-demo-account',
        component: 'prfBrandDemoDepositAutomationDashboard',
      })
      .state('crm.management.tools.automation.autoAssignments', {
        url: '/auto-assignments',
        component: 'prfAutoAssignmentDashboard',
      })
      .state('crm.management.tools.automation.accountStatusPermissions', {
        url: '/account-status-permissions',
        component: 'prfAccountStatusPermissionsDashboard',
      })
      .state('crm.management.tools.automation.referAFriend', {
        url: '/refer-a-friend',
        component: 'prfReferAFriend',
      })
      .state('crm.management.tools.automation.promoCode', {
        url: '/promo-code',
        redirectTo: 'crm.management.tools.automation.promoCode.list',
      })
      .state('crm.management.tools.automation.promoCode.list', {
        url: '/list/?{brandId:int}{platformId:int}',
        component: 'prfPromoCodeList',
        reloadOnSearch: false,
      })
      .state('crm.management.tools.automation.promoCode.create', {
        url: '/create/?{brandId:int}{platformId:int}',
        component: 'prfPromoCode',
        data: {
          action: 'create',
        },
      })
      .state('crm.management.tools.automation.promoCode.edit', {
        url: '/edit/:id?{brandId:int}{platformId:int}',
        component: 'prfPromoCode',
        data: {
          action: 'update',
        },
      });
  },
];
