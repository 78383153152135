export const portfolioPositionsTableSettings = {
  quickFilters_comment:
    'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
  quickFilters: {
    positionForexRealAccount: {
      state: {
        isActive: true,
      },
    },
  },
  ngTable: {
    parameters: {
      count: 10,
      page: 1,
      filter: {
        ['tradingAccount.isDemo']: false,
      },
    },
    settings: {
      counts: [],
    },
  },
};
