const styles = require('./campaign-settings-form.scss');
import template from './campaign-settings-form.html';
import BaseController from '~/source/common/controllers/base';
import moment, { Moment } from 'moment';
import { IScope } from 'angular';
import * as _ from '@proftit/lodash';
import { PricingModelCodesWithValue } from './../pricing-model-codes-with-value';
import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  User,
  Campaign,
  CampaignPricingModelType,
  Desk,
} from '@proftit/crm.api.models.entities';
import { CampaignPricingModelTypeCode } from '@proftit/crm.api.models.enums';
import { FormControl } from '@proftit/ng1.reactive-forms';

type DateRange = { startDate: Moment; endDate: Moment };

class ComponentController extends BaseController {
  styles = styles;
  // bindings
  campaign: Campaign;

  lifecycles = observeComponentLifecycles(this);
  useDefaultPlatform: boolean;
  dateRange: any; // DateRange | Moment;
  settings;

  PricingModelCodesWithValue = PricingModelCodesWithValue;

  pricingModel$ = new rx.BehaviorSubject<CampaignPricingModelType>(null);
  shouldAssignToRegistrationDeskFormControl = new FormControl<boolean>(null);

  campaign$ = observeShareCompChange<Campaign>(
    this.lifecycles.onChanges$,
    'campaign',
  );

  set pricingModel(val: CampaignPricingModelType) {
    this.campaign.pricingModel = val;
    if (
      !PricingModelCodesWithValue.includes(
        this.campaign.pricingModel.code as CampaignPricingModelTypeCode,
      )
    ) {
      this.campaign.pricingModelValue = null;
    }
  }
  get pricingModel() {
    return this.campaign.pricingModel;
  }

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly marketingCampaignSettings,
    readonly dateFormat,
  ) {
    super();
    useStreams(
      [
        this.shouldAssignToRegistrationDeskFormControl.value$,
        this.streamClearDeskOnAutoAssignRegistrationDesk(),
        this.streamUpdateCampaignOnAutoAssignRegistrationDeskChange(),
        this.streamAutoAssignRegistrationDeskFromCampaign(),
      ],
      this.lifecycles.onDestroy$,
    );

    Object.assign(this, {
      settings: this.marketingCampaignSettings,

      // date-range picker & date picker settings
      dateRangeSettings: Object.assign(
        {},
        this.marketingCampaignSettings.form.datepicker.range,
      ),
      endlessDateSettings: Object.assign(
        {},
        this.marketingCampaignSettings.form.datepicker.range,
        { singleDatePicker: true },
      ),

      // create date range var based on campaign fromDate & tillDate
      dateRange: {
        startDate:
          this.campaign && this.campaign.fromDate
            ? moment(this.campaign.fromDate).format(
                this.dateFormat.MYSQL_DATETIME,
              )
            : null,
        endDate:
          this.campaign && this.campaign.tillDate
            ? moment(this.campaign.tillDate).format(
                this.dateFormat.MYSQL_DATETIME,
              )
            : null,
      },
    });

    // set watchers
    this.$scope.$watch(
      'vm.campaign.isEndless',
      this.toggleCampaignEndDate.bind(this),
    );
    this.$scope.$watchCollection(
      'vm.dateRange',
      this.dateRangeChange.bind(this),
    );
  }

  streamAutoAssignRegistrationDeskFromCampaign() {
    return rx.pipe(
      () => this.campaign$,
      rx.tap((campaign) => {
        this.shouldAssignToRegistrationDeskFormControl.setValue(
          campaign.shouldAssignToRegistrationDesk,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUpdateCampaignOnAutoAssignRegistrationDeskChange() {
    return rx.pipe(
      () =>
        this.shouldAssignToRegistrationDeskFormControl.value$.pipe(
          rx.distinctUntilChanged(),
        ),
      rx.filter((x) => !_.isNil(x)),
      rx.tap((shouldAssignToRegistrationDesk) => {
        this.campaign.shouldAssignToRegistrationDesk = shouldAssignToRegistrationDesk;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamClearDeskOnAutoAssignRegistrationDesk() {
    return rx.pipe(
      () => this.shouldAssignToRegistrationDeskFormControl.value$,
      rx.filter((x) => x),
      rx.tap(() => {
        const newDesk = { id: null } as Desk;
        this.campaign.desk = newDesk;
        this.onDeskChange(newDesk);
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  /**
   * listen to chnage in dateRange and update campaign obj with the right api format
   */
  dateRangeChange() {
    if (this.campaign.isEndless) {
      if (this.dateRange.isValid && this.dateRange.isValid()) {
        this.campaign.fromDate = this.dateRange.format(
          this.dateFormat.MYSQL_DATETIME,
        );
      }
      this.campaign.tillDate = null;
    } else {
      if (this.dateRange.startDate) {
        this.campaign.fromDate = moment(this.dateRange.startDate).format(
          this.dateFormat.MYSQL_DATETIME,
        );
      }
      if (this.dateRange.endDate) {
        this.campaign.tillDate = moment(this.dateRange.endDate).format(
          this.dateFormat.MYSQL_DATETIME,
        );
      }
    }
  }

  /**
   * Called on toggle of campaign end date
   *
   * @param {boolean} isEndless - true if endless
   */
  toggleCampaignEndDate(isEndless) {
    const settings = this.settings.form.datepicker.range;
    const initialData = settings.initialData;
    let endDate;

    /*
     * toggle date range picker settings
     * in order to show single picker / double picker
     */
    if (isEndless) {
      if (this.dateRange.endDate) {
        this.$scope.$evalAsync(() => {
          this.dateRange.endDate = null;
        });
      }
    } else if (!isEndless && this.dateRange) {
      /*
       * calculate end date of campaign range according
       * to startDate and with difference that defined in initial
       * calculate only id
       */
      let startDate;

      /*
       * we use the date range picker in two modes.
       * - when its in DATE RANGE mode it will return an object value contains {startDate, endDate}.
       * - when its in DATE mode it will return date object
       *
       * get the right start date. and make sure its a moment object
       */
      if (
        this.dateRange.startDate &&
        this.dateRange.startDate.isValid &&
        this.dateRange.startDate.isValid()
      ) {
        // date range picker mode. dateRange.startDate a valid moment object
        startDate = this.dateRange.startDate;
      } else if (this.dateRange.startDate) {
        // date range picker mode. yet dateRange.startDate is not valid moment object.
        startDate = moment(this.dateRange.startDate);
      } else {
        // date mode. the date picker is a date object
        startDate = moment(this.dateRange);
      }

      endDate = startDate
        .clone()
        .add(
          initialData.endDate.value - initialData.startDate.value,
          initialData.endDate.unit,
        );

      this.dateRange = {
        startDate,
        endDate,
      };
    }
  }

  /**
   * set the default brand platform as the campaign platform
   */
  toggleDefaultPlatform() {
    if (this.useDefaultPlatform) {
      this.campaign.platform = this.campaign.brand.platform;
    }
  }

  /**
   * hock when user change desk
   *
   * @param newDesk
   */
  onDeskChange(newDesk) {
    // reset assignee after change of desk
    this.campaign.user = {
      id: null,
    } as User;
  }
}

export default {
  template,
  bindings: {
    campaign: '<',
  },
  controller: ComponentController,
  controllerAs: 'vm',
};
