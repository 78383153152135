import template from './fee-general-details-row.component.html';
const styles = require('./fee-general-details-row.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class FeeGeneralDetailsRowController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const FeeGeneralDetailsRowComponent = {
  template,
  controller: FeeGeneralDetailsRowController,
  bindings: {
    fee: '<',
  },
};
