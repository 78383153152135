import ng from 'angular';

import DesksListComponent from './desks-list/desks-list.component';
import DeskCreateComponent from './desk-create/desk-create.component';
import DeskEditComponent from './desk-edit/desk-edit.component';
import DeskFormComponent from './desk-form/desk-form.component';
import settings from './desk-settings.json';

export default ng
  .module('crm.management.desks', [])

  // todoOld: <any> is a hack. getting typing error
  // "Property 'find' is missing in type 'typeof DesksListComponent'"
  .component('prfDesksList', <any>DesksListComponent)
  .component('prfDeskManagementForm', <any>DeskFormComponent)
  .component('prfDeskEdit', <any>DeskEditComponent)
  .component('prfDeskCreate', <any>DeskCreateComponent)

  .constant('deskModuleSettings', settings)
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    ($stateProvider, $urlRouterProvider) => {
      $stateProvider
        .state('crm.management.desks', {
          url: '/desks',
          template: '<ui-view/>',
          redirectTo: 'crm.management.desks.list',
          data: {
            pageTitle: 'MANAGEMENT_DESKS',
          },
        })
        .state('crm.management.desks.list', {
          url: '/list',
          component: 'prfDesksList',
          // todoOld: permissions: ex. contacts-dashboard
        })
        .state('crm.management.desks.edit', {
          url: '/edit/:id',
          component: 'prfDeskEdit',
        })
        .state('crm.management.desks.create', {
          url: '/create',
          component: 'prfDeskCreate',
        });
    },
  ]).name;
