import { observeComponentLifecycles, observeShareCompChange } from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

var template = "";

function styleInject(css, ref) {
  if ( ref === void 0 ) ref = {};
  var insertAt = ref.insertAt;

  if (!css || typeof document === 'undefined') { return; }

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css_248z = "";
var styles = {};
styleInject(css_248z);

/// <reference lib="dom" />
class DynamicTemplateController {
  // input
  // state
  constructor($element, $rootScope, $templateCache, $compile) {
    this.lifecycles = observeComponentLifecycles(this);
    this.linkFnInput$ = observeShareCompChange(this.lifecycles.onChanges$, 'linkFn');
    this.customDataInput$ = observeShareCompChange(this.lifecycles.onChanges$, 'customData');
    this.styles = styles;
    this.generatedItem$ = this.streamGeneratedItem();
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.$templateCache = $templateCache;
    this.$compile = $compile;
    useStreams([this.linkFnInput$, this.customDataInput$, this.streamLinkTemplateToElement(), this.streamSyncDataToElement()], this.lifecycles.onDestroy$);
  }
  /* eslint-disable-next-line */


  $onInit() {}
  /* eslint-disable-next-line */


  $onDestroy() {}
  /* eslint-disable-next-line */


  $onChanges() {}

  streamGeneratedItem() {
    return rx.pipe(() => this.linkFnInput$, rx.switchMap(templateLinkFn => {
      return new rx.Observable(s => {
        let element;
        let scope;
        templateLinkFn((clonedElement, selfScope) => {
          element = clonedElement;
          scope = selfScope;
          s.next({
            element,
            scope
          });
        });
        return () => {
          if (element) {
            try {
              element.remove();
            } catch (err) {// handle error here
            }
          }

          if (scope) {
            scope.$destroy();
          }
        };
      });
    }), shareReplayRefOne())(null);
  }

  streamLinkTemplateToElement() {
    const lastItem$ = new rx.BehaviorSubject({});
    return rx.pipe(() => this.generatedItem$, rx.withLatestFrom(lastItem$), rx.tap(([newItem, prevItem]) => {
      if (prevItem.element) {
        prevItem.element.remove();
      }

      this.$element.append(newItem.element);
      lastItem$.next(newItem);
    }), rx.finalize(() => {
      const lastItem = lastItem$.getValue();

      if (lastItem.element) {
        try {
          lastItem.element.remove();
        } catch (err) {// handle error here
        }
      }
    }))(null);
  }

  streamSyncDataToElement() {
    // TODO: remove previous assigned keys.
    return rx.pipe(() => rx.obs.combineLatest(this.generatedItem$, this.customDataInput$), rx.tap(([item, data]) => {
      Object.assign(item.scope, data);
    }))(null);
  }

}
DynamicTemplateController.$inject = ['$element', '$rootScope', '$templateCache', '$compile'];
const DynamicTemplateComponent = {
  template,
  controller: DynamicTemplateController,
  bindings: {
    linkFn: '<',
    customData: '<'
  }
};

function defineNg1DynamicTemplateModule(angular) {
  const ngModule = angular.module('prfNg1DynamicTemplate', []);
  ngModule.component('prfDynamicTemplate', DynamicTemplateComponent);
  return ngModule;
}

export { defineNg1DynamicTemplateModule };
