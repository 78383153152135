import template from './security-form.component.html';
const styles = require('./security-form.component.scss');
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { buildBrandSecurityForm } from './build-brand-security-form';
import { Brand } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';

export class SecurityFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  onChange: (x: {
    isCaptchaEnabled: boolean;
    captchaSiteKey: string;
    captchaSecretKey: string;
    is2faLoginEnabled: boolean;
    is2faSignupEnabled: boolean;
    is2faPswdResetEnabled: boolean;
  }) => void;

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  form = buildBrandSecurityForm();

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.brand$,
        this.form.value$,
        this.streamFillFormFromBrand(),
        this.streamNotifyOnChanges(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamFillFormFromBrand() {
    return rx.pipe(
      () => this.brand$,
      rx.tap((brand) => {
        (this.form.controls as any).isCaptchaEnabled.setValueAsFirst(
          brand.isCaptchaEnabled,
        );
        (this.form.controls as any).captchaSiteKey.setValueAsFirst(
          brand.captchaSiteKey,
        );
        (this.form.controls as any).captchaSecretKey.setValueAsFirst(
          brand.captchaSecretKey,
        );
        (this.form.controls as any).is2faSignupEnabled.setValueAsFirst(
          brand.is2faSignupEnabled,
        );
        (this.form.controls as any).is2faLoginEnabled.setValueAsFirst(
          brand.is2faLoginEnabled,
        );
        (this.form.controls as any).is2faPswdResetEnabled.setValueAsFirst(
          brand.is2faPswdResetEnabled,
        );
      }),
    )(null);
  }

  streamNotifyOnChanges() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.form.isAtFirstValue$, this.form.value$),
      rx.filter(([first, value]) => !first),
      rx.debounceTime(500),
      rx.map(([first, value]) => value as any),
      rx.tap(
        ({
          isCaptchaEnabled,
          captchaSiteKey,
          captchaSecretKey,
          is2faSignupEnabled,
          is2faLoginEnabled,
          is2faPswdResetEnabled,
        }) => {
          this.onChange({
            isCaptchaEnabled,
            captchaSiteKey,
            captchaSecretKey,
            is2faSignupEnabled,
            is2faLoginEnabled,
            is2faPswdResetEnabled,
          });
        },
      ),
    )(null);
  }
}

export const SecurityFormComponent = {
  template,
  controller: SecurityFormController,
  bindings: {
    brand: '<',
    isTwoFactorPasswordResetDisabled: '<',
    onChange: '&',
  },
};
