import template from './click-to-send-dialog.component.html';
const styles = require('./click-to-send-dialog.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { filterCompChange } from '~/source/common/utilities/rxjs/observables/filter-comp-change';
import * as rx from '@proftit/rxjs';
import { Customer, Brand } from '@proftit/crm.api.models.entities';
import { CustomersService } from '../services/customers';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { SourceDisplayTypeCode } from '@proftit/crm.api.models.enums';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { CustomerPhoneNumber } from '~/source/common/utilities/customer-phone-number/get-all-full-numbers-for-customer';

export class ClickToSendDialogController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  close: () => void;
  dismiss: () => void;

  title = 'common.CLICK_TO_SMS';
  blockUiId = 'clickToSendDialogBlockUi';
  growlId = 'clickToSendDialogGrowlId';

  brand$ = new rx.BehaviorSubject<Brand>(null);
  customer$ = new rx.BehaviorSubject<Customer>(null);
  senderNumber$ = new rx.BehaviorSubject<string>('');
  message$ = new rx.BehaviorSubject<string>('');
  opUpdateCustomerPhoneNumber$ = new rx.Subject<{
    phone: string;
    countryPrefix: string;
  }>();
  onUpdateSenderNumber$ = new rx.Subject<string>();
  isEditPhone$ = new rx.BehaviorSubject<boolean>(false);
  isEditSenderNumber$ = new rx.BehaviorSubject<boolean>(false);
  sendSms$ = new rx.Subject<void>();
  isFormValid$ = new rx.BehaviorSubject<boolean>(true);
  selectedCustomerPhoneNumberFormControl = new FormControl<
    {
      id: number;
      label: string;
    } & CustomerPhoneNumber
  >(null);

  /*@ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly customersService: () => CustomersService,
  ) {
    useStreams([this.streamResolveChange()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.streamUpdateCustomerPhone(),
        this.streamUpdateSenderNumber(),
        this.streamSendSms(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamResolveChange() {
    return rx.pipe(
      () => filterCompChange('resolve', this.lifecycles.onChanges$),
      rx.map(({ currentValue }) => currentValue),
      rx.tap(({ customer, brand }) => {
        this.customer$.next(customer);
        this.brand$.next(brand);
      }),
    )(null);
  }

  streamUpdateCustomerPhone() {
    return rx.pipe(
      () => this.opUpdateCustomerPhoneNumber$,
      rx.withLatestFrom(this.customer$),
      this.tapStartAsyncWorkInUi(),
      rx.switchMap(([{ phone, countryPrefix }, customer]) =>
        rx.obs.from(
          this.customersService()
            .setConfig({ blockUiRef: this.blockUiId, growlRef: this.growlId })
            .updateCustomer(customer.id, {
              phone,
              countryPrefix,
            }),
        ),
      ),
      rx.withLatestFrom(this.customer$),
      rx.tap(([customerFromUpdate, customer]) => {
        this.customer$.next({
          ...customer,
          phone: customerFromUpdate.phone,
        });

        this.isEditPhone$.next(false);
      }),
      this.tapStopAsyncWorkInUi(),
    )(null);
  }

  streamUpdateSenderNumber() {
    return rx.pipe(
      () => this.onUpdateSenderNumber$,
      rx.tap((value) => {
        this.isEditSenderNumber$.next(false);
        this.senderNumber$.next(value);
      }),
    )(null);
  }

  /**
   * Component stream - send sms logic
   * @return stream
   */
  streamSendSms() {
    return rx.pipe(
      () => this.sendSms$,
      rx.withLatestFrom(
        this.customer$,
        this.message$,
        this.senderNumber$,
        this.selectedCustomerPhoneNumberFormControl.value$,
      ),
      this.tapStartAsyncWorkInUi(),
      rx.switchMap(
        ([a, customer, message, senderNumber, selectedCustomerPhoneNumber]: [
          any,
          Customer,
          string,
          any,
          CustomerPhoneNumber,
        ]) => {
          let userPhoneNum;
          const {
            sourceDisplayType,
          } = customer.brand.smsCredentials[0].smsProvider;
          if (sourceDisplayType === SourceDisplayTypeCode.FreeText) {
            userPhoneNum = senderNumber;
          } else if (sourceDisplayType === SourceDisplayTypeCode.List) {
            userPhoneNum = senderNumber.smsNumber.number;
          }

          return rx.obs.from(
            this.customersService()
              .setConfig({
                blockUiRef: this.blockUiId,
                growlRef: this.growlId,
                defaultErrorMessage: 'contact.call.errors.CALL_FAILED',
              })
              .sendSms(
                customer.id,
                customer.brand.smsCredentials[0].smsProvider.id,
                message,
                userPhoneNum,
                selectedCustomerPhoneNumber.isPrimary,
              ),
          );
        },
      ),
      rx.tap(() => this.close()),
      this.tapStopAsyncWorkInUi(),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}

  tapStartAsyncWorkInUi() {
    return tapStartAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  tapStopAsyncWorkInUi() {
    return tapStopAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }
}

export const ClickToSendDialogComponent = {
  template,
  controller: ClickToSendDialogController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
