import Component from '~/source/common/components/dropdowns/base/component';

class MarketingCampaignStatusesController extends Component.controller {
  static $inject = [
    'marketingCampaignStatusesService',
    ...Component.controller.$inject,
  ];
  /**
   * Path to translations on lang.json file
   * Must be overridden
   *
   * @returns {String}
   */
  get translationPath() {
    return 'marketing.campaign.statuses';
  }
}

export default Component.config({
  controller: MarketingCampaignStatusesController,
});
