import * as _ from '@proftit/lodash';

import Component from '~/source/common/components/dropdowns/base/component';
import BrandsService from '~/source/management/brand/services/brands';
import IElementRestNg from 'source/common/models/ielement-rest-ng';
import { CfdPlatforms } from '@proftit/crm.api.models.enums';
import { Brand } from '@proftit/crm.api.models.entities';

export class CfdPlatformBrandsSelectController extends Component.controller {
  static $inject = ['brandsService', ...Component.controller.$inject];

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return this.dataServiceInst
      .embed(['platformConnections'])
      .expand(['platformType', 'platformConnections.platform'])
      .getListWithQuery<IElementRestNg<Brand>>()
      .then((data) => data.plain())
      .then((brands) =>
        brands.filter((brand) => {
          return _.flow([
            () => _.get('platformConnections', brand),
            (platformConns) => _.defaultTo([], platformConns),
            (platformConns) =>
              platformConns.find((conn) =>
                CfdPlatforms.includes(conn.platform.code),
              ),
          ])();
        }),
      );
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Brands',
    };
  }

  get defaultPlaceholder() {
    return 'SELECT_BRAND';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const CfdPlatformBrandsSelectComponent = Component.config({
  controller: CfdPlatformBrandsSelectController,
});

export default CfdPlatformBrandsSelectComponent;
