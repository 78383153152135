import angular, { IHttpService, translate } from 'angular';
import * as _ from '@proftit/lodash';
import log from 'loglevel';
import enJson from './../../../lang/en.json';
import { IAppConfig } from '~/source/conf/appConfig';
import { calcTranslationApiUrl } from './calcTranslationApiUrl';

export const translateCustomLoader = (
  $http: IHttpService,
  appConfig: IAppConfig,
  $translate: translate.ITranslateService,
) => {
  return createTranslateFunction($http, appConfig, $translate);
};

translateCustomLoader.$inject = ['$http', 'appConfig', '$translate'];

function createTranslateFunction(
  $http: IHttpService,
  appConfig: IAppConfig,
  $translate: translate.ITranslateService,
): (options: TranslationOptions) => Promise<any> {
  let translationRepo: TranslationRepo = {
    en: {
      localValue: enJson,
      remoteValue: null,
    },
  };

  return (options: TranslationOptions) => {
    if (!(options.key in translationRepo)) {
      translationRepo = _.set(
        [options.key],
        {
          localValue: {},
          remoteValue: null,
        },
        translationRepo,
      );
    }

    const langInfo = translationRepo[options.key];

    if (!_.isNil(langInfo.remoteValue)) {
      return Promise.resolve(langInfo.remoteValue);
    }

    const restResource = calcTranslationApiUrl(appConfig, options.key);
    fetchTranslation(restResource, options, $http)
      .catch(() => {
        return enJson;
      })
      .then((translationData) => {
        translationRepo = _.set(
          [options.key, 'remoteValue'],
          translationData,
          translationRepo,
        );

        // refresh has a bug that it delete the other langs translations
        // from the translationTable
        // $translate.refresh(options.key);
        $translate.use(options.key);
      });

    // use local translation in the meantime;
    return Promise.resolve(langInfo.localValue);
  };
}

function fetchTranslation(
  fileUrl: string,
  options: TranslationOptions,
  $http: IHttpService,
): Promise<any> {
  const httpOptions = {
    url: fileUrl,
    method: 'GET',
    ...options.$http,
  };

  return $http(httpOptions)
    .then((result) => result.data)
    .catch((error: Error) => {
      log.error(
        `error loading translation file: ${options.key}, ${error.toString()}`,
      );

      Promise.reject(options.key);
    }) as Promise<any>;
}

interface TranslationOptions {
  key: string;
  $http: {
    cache: any;
  };
}

interface TranslationRepo {
  [key: string]: TranslationRepoEntry;
}

interface TranslationRepoEntry {
  localValue: TranslationStructure;
  remoteValue: TranslationStructure;
}

type TranslationStructure = Record<string, any>;
