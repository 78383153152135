import type { Default as FullCalendar } from 'fullcalendar/Calendar';
/*
 * Load the module declaration types, while still using typescript
 * 'import type' that does not support loading without
 * a target ex. 'import type {somethis} from ..
 */
import type {} from 'fullcalendar/src/types/jquery-hooks';

export function getFullCalendar(element: JQuery): FullCalendar {
  return element.fullCalendar('getCalendar') as FullCalendar;
}
