import moment from 'moment';

import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { Campaign } from '@proftit/crm.api.models.entities';
import MarketingCampaignController from '../../campaign.base.controller';

import template from './campaign-update.html';
import { PlatformCode } from '@proftit/crm.api.models.enums';

class MarketingUpdateCampaignController extends MarketingCampaignController {
  PlatformCode = PlatformCode;
  campaignId: number;
  campaign: Campaign;

  constructor(...args) {
    super(...args);

    this.campaignId = this.$state.params.campaignId;

    this.initModel().then((data) => {
      this.campaign = data;
    });
  }

  fetchFn(): any {
    throw new Error('Should not fetch data on create controller');
  }

  initModel() {
    return this.marketingCampaignsServiceInst
      .expand([
        'brand',
        'brand.platformConnections.platform',
        'currencyConnections.currency',
        'desk',
        'pricingModel',
        'platform',
        'language',
        'campaignStatus',
        'user',
      ])
      .embed([
        'brand.platformConnections',
        'trackerParams',
        'brandTrackerParams',
        'trackers',
        'blacklistedCountries',
        'currencyConnections',
        'automaticDepositRewardsNonFtd',
        'automaticDepositRewardsFtd',
        'automaticRegistrationRewards',
      ])
      .getOneWithQuery<IElementRestNg<Campaign>>(this.campaignId)
      .then((campaignData) => {
        campaignData.isEndless = !moment(campaignData.tillDate).isValid();

        return campaignData.plain();
      });
  }
}

export default {
  template,
  controller: MarketingUpdateCampaignController,
  controllerAs: 'vm',
};
