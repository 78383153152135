import template from './questionnaire-score-page.component.html';
const styles = require('./questionnaire-score-page.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import type { StateService } from '@uirouter/angularjs';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';

export class QuestionnaireScorePageController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  action$ = this.streamAction();
  id$ = this.streamId();
  questionnaireFormId$ = this.streamQuestionnaireFormId();

  /*@ngInject */
  constructor(readonly $state: StateService) {
    useStreams(
      [this.action$, this.id$, this.questionnaireFormId$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamAction() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => this.$state.current.data.action),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamId() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => this.$state.params.id),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamQuestionnaireFormId() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => this.$state.params.questionnaireFormId),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

export const QuestionnaireScorePageComponent = {
  template,
  controller: QuestionnaireScorePageController,
};
