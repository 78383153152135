import SocketService from '~/source/common/services/socket';

class BonusesSocket extends SocketService {
  get channelRoot() {
    return 'TransactionTransferBonus';
  }

  get whiteList() {
    return [];
  }
}

export default BonusesSocket;
