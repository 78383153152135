import Promise from 'bluebird';
import Component from '../base/component';
import numRowsOptions from '../rows-options';

class NumOfRowsController extends Component.controller {
  values: any[];

  query() {
    this.values = numRowsOptions.map((number, index) => {
      return {
        id: index,
        name: number,
        count: number,
      };
    });
    return Promise.resolve(this.values);
  }

  compareItemsForSelected(itemA: any, itemB: any) {
    return itemA.count === itemB.count;
  }

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'common.NUMBER_OF_ROWS';
  }
}

export default Component.config({ controller: NumOfRowsController });
