import * as _ from '@proftit/lodash';
import { FieldProxyHandler } from '../field-proxy-handler';
import { isBigJsNumber } from '../../utilities/is-bigjs-number';

export const IS_NUMERIC_STRING = 'IS_NUMERIC_STRING';

export function isNumericStringProxyValidator(handler: FieldProxyHandler<any>) {
  if (_.isNil(handler.value)) {
    return {
      code: IS_NUMERIC_STRING,
      isValid: true,
    };
  }

  const result = isBigJsNumber(handler.value);

  if (result) {
    return {
      code: IS_NUMERIC_STRING,
      isValid: true,
    };
  }

  return {
    code: IS_NUMERIC_STRING,
    isValid: false,
    payload: {
      value: handler.value,
    },
  };
}
