import template from './task-form.html';
import BaseController from '~/source/common/controllers/base';
import * as _ from '@proftit/lodash';
import { TokensService } from '~/source/auth/services/tokens';
import { DateTzAdjustService } from '~/source/common/services/date-tz-adjust';
import { UserTokenModel } from '~/source/common/models/user-token-model';
import type { Task } from '@proftit/crm.api.models.entities';
class TasksFormController extends BaseController {
  task: Task | { id: number; dueDate: Date };
  hasEditPermission: boolean;

  isDatePickerOpen = false;
  minDate = new Date();
  user: UserTokenModel;

  /*@ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly dateTzAdjustService: DateTzAdjustService,
  ) {
    super();

    this.user = this.tokensService.getCachedUser();
  }

  $onInit() {
    /*
     * needed on task-update mode - when this.task is a full object coming from binding
     * (comes as "2018-05-24 09:00:25"))
     */
    if (this.isUpdateMode) {
      this.task.dueDate = this.dateTzAdjustService.getUTCAdjustedTime(
        _.get('dueDate', this.task as any),
      );
    }
  }

  get isUpdateMode() {
    return !!this.task?.id;
  }

  /**
   * Sets the "is date picker open" flag to true.
   */
  openDatePicker() {
    this.isDatePickerOpen = true;
  }
}

export default {
  template,
  controller: TasksFormController,
  controllerAs: 'vm',
  bindings: {
    task: '<',
    hasEditPermission: '<',
    isNewTask: '<',
  },
};
