import BaseController from '~/source/common/controllers/base';

import template from './desk-form.component.html';

class DeskFormComponent extends BaseController {
  disabled = true;
}

export default {
  template,
  controller: DeskFormComponent,
  bindings: {
    model: '<',
    disabled: '<',
  },
};
