import ng from 'angular';

import ParamsToTrackData from './parameters-to-track-data/parameters-to-track-data.component';
import ParamsToTrackForm from './parameters-to-track-form/parameters-to-track-form.component';

const ngModule = ng
  .module('marketing.paramsToTrack', [])
  .component('prfParametersToTrackData', ParamsToTrackData)
  .component('prfParametersToTrackForm', ParamsToTrackForm);

export default ngModule.name;
