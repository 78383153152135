import * as _ from '@proftit/lodash';
import template from './info-audit-log-table.html';
import TableController from '~/source/common/components/table/table.controller';
import infoAuditSettings from './info-audit-log-settings.json';

import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import PopupService from '~/source/common/components/modal/popup.service';

class Controller extends TableController {
  static $inject = [
    'popupService',
    'customersService',
    'blockUI',
    ...TableController.$inject,
  ];

  settings = _.cloneDeep(infoAuditSettings);
  cols = [...this.settings.tableColumns];
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;
  popupService: PopupService;

  $onInit() {
    super.$onInit();
    Object.assign(this, {
      dataServiceInstance: this.customersService(),
    });
    this.initTable();
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getInfoAuditLogResource(this.customer.id)
      .setConfig({ blockUiRef: 'infoAuditLogTable' })
      .exclude('field', 'userId')
      .sort({ requestedAt: 'desc' });
  }

  openInfoAuditPopup() {
    this.popupService.open({
      component: 'prfInfoAuditTablePopup',
      resolve: {
        customer: this.customer,
      },
    });
  }
}

export const InfoAuditLogTable = {
  template,
  controller: Controller,
  bindings: {
    customer: '<',
  },
};
