import BaseController from '~/source/common/controllers/base';
import _ from 'underscore';
import Promise from 'bluebird';
import { IScope, ITimeoutService } from 'angular';
import CustomersService from '~/source/contact/common/services/customers';
import DepositsSocket from '~/source/contact/common/services/deposits-socket.service';
import { SocketListener } from '~/source/common/services/socket';
import {
  TradingAccount,
  Customer,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import BrandsService from '~/source/management/brand/services/brands';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { prepareTxrefForServer } from '~/source/common/utilities/prepare-txref';
import { DEPOSIT_TABLE_RELOAD } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { TimeInterval } from '@proftit/constants.time';
import { ClearingCompanyCode } from '@proftit/crm.api.models.enums';
import * as _l from '@proftit/lodash';
import { DoneFlow } from './deposit/add-transaction-popup/done-flow';

const MAX_DEPOSIT_TIME = 120 * 1000; // 2 Minutes to complete regular deposit
const COTI_MAX_DEPOSIT_TIME = TimeInterval.Minute * 15;
const MANUAL_EWALLET_DEPOSIT = 'MANUAL_E-WALLET_DEPOSIT';
enum ShowWidget {
  Payfast = 'payfast',
}

enum WorkflowState {
  ShowRegularForm,
  ShowPayfastWidget,
}

abstract class TransactionController extends BaseController {
  WorkflowState = WorkflowState;

  abstract get depositType(): any;
  blockUiInstance: any;
  account: TradingAccount;
  customer: Customer;
  onDone: (a: { next: DoneFlow }) => void;

  // needed for subclasses
  static $inject = [
    '$scope',
    'blockUI',
    'customersService',
    'depositsSocketService',
    'growl',
    'growlMessages',
    '$timeout',
    'brandsService',
    'providerClearingCompaniesService',
    '$document',
    'prfClientGeneralPubsub',
    '$sce',
    '$window',
  ];

  $scope: IScope & { $parent: any };
  blockUI;
  $sce: any;
  customersService: () => CustomersService;
  depositsSocketService: DepositsSocket;
  growl: angular.growl.IGrowlService;
  growlMessages: angular.growl.IGrowlMessagesService;
  $timeout: ITimeoutService;
  brandsService: () => BrandsService;
  providerClearingCompaniesService;
  $document;
  $window;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  workflowState$ = new rx.BehaviorSubject<WorkflowState>(
    WorkflowState.ShowRegularForm,
  );
  iframeContent$ = new rx.BehaviorSubject<string>('');

  constructor(...args) {
    super(...args);

    const blockUiReference = this.calcBlockUiReference();

    this.blockUiInstance = this.blockUI.instances.get(blockUiReference);
  }

  calcBlockUiReference() {
    if (this.blockUiId) {
      return this.blockUiId;
    }

    // get auto generated reference id from parent modal. use it to create block ui reference
    return this.$scope.$parent && this.$scope.$parent.referenceId
      ? `blockui-modal-${this.$scope.$parent.referenceId}`
      : 'main'; // main is the default block ui reference
  }

  /**
   * Name of the method in customerService that should be in use when creating deposit
   *
   * @returns {string}
   */
  get depositMethod() {
    return 'addDeposit';
  }

  /**
   * Name of event that cause to related table to reload
   *
   * @returns {string}
   */
  get reloadTableEvent() {
    return 'depositsTableReload';
  }

  /**
   * Specify local block ui for the component.
   *
   * Allow children to override to use block ui in their on templates.
   */
  get blockUiId() {
    return null;
  }

  /**
   * Channel for updates on created deposit
   *
   * @param {id} depositId
   * @returns {string}
   */
  buildChannel(depositId: number) {
    return `customer.${this.customer.id}.${this.depositsSocketService.channelRoot}.${depositId}`;
  }

  /**
   * Intended to be overriden by child components
   */
  updateDeposit3dStatus(
    is3dSale: boolean,
    html3d: string,
    redirectUrl: string,
  ) {}

  /**
   * Posts deposit to server and handles its response.
   * Most errors are caught internally.
   *
   * @param {object} deposit - the deposit object to post to server
   * @return {Promise}
   */
  makeDeposit(deposit, clearingCompany = null) {
    // add deposit type to deposit object
    _.extend(deposit, {
      transferMethodTypeCode: this.depositType.code,
    });

    // Start blocking the ui
    this.blockUiInstance.start();

    // remove previous errors
    this.growlMessages.destroyAllMessages('transactionInfo' as any);

    return (
      this.customersService()
        // suppress the automatic block ui/growl, we want to manage it ourselves here.
        .setConfig({ suppressBlockUi: true, suppressGrowl: true })
        [this.depositMethod](this.customer.id, this.account.id, deposit)
        .then((deposit) => {
          if (
            deposit.is3dSale &&
            clearingCompany.code === ClearingCompanyCode.OoBit
          ) {
            const redirectUrl = deposit.redirectUrl
              ? this.$sce.trustAsResourceUrl(deposit.redirectUrl)
              : null;

            if (_l.isNil(redirectUrl)) {
              return Promise.reject(
                new Error(`Transaction rejected. Please try again later`),
              );
            }

            this.$window.open(redirectUrl);
            return Promise.resolve({ deposit, next: DoneFlow.ShowOobit });
          }

          if (deposit.is3dSale) {
            this.blockUiInstance.stop();

            const redirectUrl = deposit.redirectUrl
              ? this.$sce.trustAsResourceUrl(deposit.redirectUrl)
              : null;

            // set 3d vars to this.deposit, to access them from template. this will show the 3d form.
            Object.assign(deposit, {
              redirectUrl,
              is3dSale: deposit.is3dSale,
              html3d: deposit.html3d,
            });
            this.updateDeposit3dStatus(
              true,
              deposit.html3d,
              deposit.redirectUrl,
            );
          }
          if (deposit.showWidget === ShowWidget.Payfast) {
            // Stop blocking and show payfast form
            this.blockUiInstance.stop();
            this.iframeContent$.next(deposit.formHtml);
            this.workflowState$.next(WorkflowState.ShowPayfastWidget);
          }

          /*
           * Deposit is pending approval. Subscribe for streamer updates.
           * this is used for most credit card deposits, including 3d.
           */
          if (this.shouldWaitApproval(deposit)) {
            return this.waitApproval(deposit, clearingCompany);
          }

          // check for failure status codes
          if (this.hasFailed(deposit)) {
            const rejectionErr = deposit.rejectionReason || 'Unknown';
            return Promise.reject(
              new Error(`Transaction rejected. Reason: ${rejectionErr}`),
            );
          }

          /**
           * at this point the deposit *should* be at one of the following states:
           * - wire deposit in status "requested"
           * - credit card deposit in status "approved"
           *
           * both cases are success, which is why we resolve the promise.
           */
          return Promise.resolve({ deposit, next: DoneFlow.ClosePopup });
        })
        .then(({ deposit, next }) => {
          /*
           * emit event that will eventually will cause to
           * deposits table to reload data from api
           */
          this.$scope.$emit(this.reloadTableEvent);
          // close the popup. this should be set to the bind of the directive
          this.onDone({ next });
        })
        .catch((err) => {
          const message =
            err instanceof Error ? err.message : 'errors.GENERAL_SERVER_ERROR';
          this.growl.error(message, { referenceId: 'transactionInfo' as any });
        })
        .finally(() => {
          this.blockUiInstance.stop();
          this.updateDeposit3dStatus(false, null, null);
        })
    );
  }

  /**
   * Check if the deposit status code is a failing one.
   * @param {object} deposit - deposit object from server
   * @return {boolean} - true if failure
   */
  hasFailed(deposit) {
    const failStatuses = ['rejected', 'canceled', 'declined'];

    // In case of ewallet, canceled is allowed.
    if (deposit.transferMethodTypeCode === MANUAL_EWALLET_DEPOSIT) {
      return (
        ['rejected', 'declined'].indexOf(deposit.transactionStatusCode) !== -1
      );
    }
    return failStatuses.indexOf(deposit.transactionStatusCode) !== -1;
  }

  /**
   * Returns true if we should wait for streamer approval for given deposit.
   * @param {object} depositResult - deposit result from server
   * @return {boolean} - if true, we should subscribe to streamer for approval
   */
  shouldWaitApproval(depositResult) {
    /*
     * returns true if the deposit method is an approvable one (such as card) and the deposit
     * status is not final
     */
    return (
      this.depositType.isApprovable &&
      (depositResult.transactionStatusCode === 'pending' ||
        depositResult.transactionStatusCode === 'requested')
    );
  }

  /**
   * Wait for deposit status update from streamer
   *
   * @param {object} deposit - the deposit request object
   * @returns {Promise} resolved/rejected once the streamer sets the deposit status.
   */
  waitApproval(deposit, clearingCompany) {
    let callback;
    let timeout;
    return new Promise((resolve, reject) => {
      let maxTimeToWait;
      if (_l.isNil(clearingCompany)) {
        maxTimeToWait = MAX_DEPOSIT_TIME;
      } else {
        maxTimeToWait =
          clearingCompany.code === ClearingCompanyCode.COTI
            ? COTI_MAX_DEPOSIT_TIME
            : MAX_DEPOSIT_TIME;
      }
      // Set a timer so we don't wait forever
      timeout = this.$timeout(
        () => reject(new Error('deposits.TIMEOUT')),
        maxTimeToWait,
      );

      /**
       * Subscribe a callback to element.
       * The callback created is composed of JSON parse first, as the result is a json string,
       * and the actual callback.
       * the created function is saved to a var, so we could unsubscribe it.
       */
      this.depositsSocketService.subscribe(
        this.buildChannel(deposit.id),
        (callback = <SocketListener>_.compose((result) => {
          // Update the deposit object with the status received from streamer
          deposit.transactionStatusCode = result.transactionStatusCode;
          switch (result.transactionStatusCode) {
            case 'approved':
              resolve({ deposit, next: DoneFlow.ClosePopup });
              break;
            case 'rejected':
            case 'canceled':
            case 'declined': {
              // If rejection reason is set, use it. otherwise, use general rejection error.
              const rejectionErr = result.rejectionReason || 'Unknown';
              reject(
                new Error(`Transaction rejected. Reason: ${rejectionErr}`),
              );
              break;
            }
            default:
              // Ignore pending status
              break;
          }
        }, JSON.parse)),
      );
    }).finally(() => {
      // when promise is fulfilled, unsubscribe callback
      this.depositsSocketService.unsubscribe(
        this.buildChannel(deposit.id),
        callback,
      );
      // Reset timer
      this.$timeout.cancel(timeout);
    });
  }
}

export default TransactionController;
