import * as _ from '@proftit/lodash';
import { BrandsService } from '~/source/management/brand/services/brands';
import { User } from '@proftit/crm.api.models.entities';
import template from './user-data.html';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PROFILE_PICTURE_UPLOADED } from '~/source/common/constants/general-pubsub-keys';
import { PublicSystemLogin2faStoreService } from '~/source/common/store-services/public-system-login-2fa-store.service';

class ComponentController {
  user: User;
  onUserValid: () => Promise<void>;

  isEdit: boolean;
  prevAttributes: any;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $stateParams: any,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly prfPublicSystemLogin2faStoreService: PublicSystemLogin2faStoreService,
  ) {
    this.isEdit = false;
  }

  save() {
    // save the user
    this.onUserValid().then(() => {
      // change to display mode
      this.isEdit = false;
      this.prfClientGeneralPubsub.publish(PROFILE_PICTURE_UPLOADED, '');
    });
  }

  /**
   * Enter edit mode:
   * Save current user model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = _.pick(
      [
        'firstName',
        'lastName',
        'userGroup',
        'role',
        'department',
        'email',
        'phone',
        'username',
        'isActive',
        'profileImage',
        'is2faLoginEnabled',
      ],
      this.user,
    );

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.user, this.prevAttributes);
    // Exit edit mode
    this.isEdit = false;
  }
}

const UserDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    user: '=',
    form: '=',
    onUserValid: '&onUserValidFn',
  },
};

export default UserDataComponent;
