import log from 'loglevel';
import { SocketService } from '~/source/common/services/socket';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { getCfdPlatformPublicTokenCredential } from '~/source/common/models/platform-connection/get-cfd-platform-public-token-credential';

/**
 * Build channel name for cfd account openpnl subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildCfdPlatformPositionPayoutChannel(
  accountId: number,
  positionId: number,
) {
  return `{customer.${accountId}}.cfdposition.${positionId}.payout`;
}

export class CfdPlatformPositionPayoutSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  token: string;
  platformStreamerUrl: string;

  get socketRegistryKey() {
    return 'cfdPlatformSocket';
  }

  get channelRoot() {
    return null;
  }

  getStreamerUrl() {
    return this.platformStreamerUrl;
  }

  buildSocketQuery() {
    return `token=${this.token}`;
  }

  setToken(token: string) {
    this.token = token;
  }

  setStreamerUrl(url: string) {
    this.platformStreamerUrl = `${url}/`;
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }
}
