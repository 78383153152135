import * as _ from '@proftit/lodash';
import Promise from 'bluebird';

import TableLiveController from '~/source/common/components/table/table-live.controller';
import CustomersService from '~/source/contact/common/services/customers';
import PopupService from '~/source/common/components/modal/popup.service';
import TableSettings from '~/source/common/models/table-settings';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import {
  TradingAccount,
  Customer,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import DepositSocket from '~/source/contact/common/services/deposits-socket.service';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';
import UsersService from '~/source/management/user/services/users';
import isDepositDeletable from '~/source/common/models/deposit/is-deposit-deletable';
import template from './deposits-table.html';
import confirmDepositPopupTemplate from '../confirm-deposit-popup/confirm-deposit-popup.html';
import {
  DEPOSIT_TABLE_RELOAD,
  ATTACHMENT_ADDED_TO_DEPOSIT,
  ATTACHMENT_REMOVED_FROM_DEPOSIT,
} from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import * as rx from '@proftit/rxjs';
import { pagingDepositTableCols } from './paging-deposit-table-columns';
import angular from 'angular';
import { generateUuid } from '@proftit/general-utilities';
import ModalService from '~/source/common/components/modal/modal.service';
import { useStreams } from '@proftit/rxjs.adjunct';
import {
  checkCrudPermission,
  CrudPermissionInfo,
} from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import {
  Permissions,
  PermissionNormalized,
} from '~/source/common/models/permission-structure';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';
const styles = require('./deposits-table.component.scss');

interface MenuItem {
  labelCode: string;
  actionCode: string;
}

class DepositAdditional {
  showActionWarning: boolean = false;
  isMenuOpen: boolean = false;
  menuItems: MenuItem[] = [];
  attachDocumentButtonId = generateUuid();
}

const preApprovedStatuses = ['requested', 'pending'];
const cancebleStatuses = ['requested', 'pending'];
const docLinkableStatuses = ['requested', 'approved'];

class DepositTableController extends TableLiveController {
  static $inject = [
    'customersService',
    'popupService',
    'modalService',
    'depositsSocketService',
    'highlightEntityService',
    'tokensService',
    'depositsSettings',
    'featuresFlags',
    'usersService',
    'platformTypesMap',
    'prfClientGeneralPubsub',
    'PermPermissionStore',
    ...TableLiveController.$inject,
  ];

  styles = styles;

  blockUiId = 'depositsTable';
  depositsSettings;
  config: TableSettings;
  customersService: () => CustomersService;
  depositsSocketService: DepositSocket;
  deposits: IElementRestNg<any>;
  customer: IElementRestNg<Customer>;
  dataServiceInstance: CustomersService;
  popupService: PopupService;
  modalService: ModalService;
  account: IElementRestNg<TradingAccount>;
  featuresFlags: FeaturesFlagsService;
  depositAdditional: { [key: number]: DepositAdditional } = {};
  usersService: () => UsersService;
  usersServiceInstance: UsersService;
  platformTypesMap: any;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  unsub$: rx.Subject<any> = new rx.Subject<null>();
  cols: any[];
  PermPermissionStore: ng.permission.PermissionStore;
  Permissions = Permissions;
  attachmentsPermissions: CrudPermissionInfo;
  filesPermissions: CrudPermissionInfo;

  $onInit() {
    super.$onInit();

    Object.assign(this, {
      settings: { ...this.depositsSettings, ...(this.config || {}) },
      dataServiceInstance: this.customersService(),
      cols: [...pagingDepositTableCols],
    });
    useStreams(
      [
        this.streamInitTable(),
        this.streamTableReload(),
        this.streamTableReloadForAddAttachment(),
        this.streamTableReloadForRemoveAttachment(),
      ],
      this.unsub$,
    );
    this.usersServiceInstance = this.usersService();

    this.$scope.$on('depositsTableReload', this.reloadTable.bind(this));
  }

  streamTableReload() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === DEPOSIT_TABLE_RELOAD),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          checkCrudPermission(
            PermissionNormalized.ContactsAttachments,
            this.PermPermissionStore,
          ),
          checkCrudPermission(
            PermissionNormalized.ContactsFileUpload,
            this.PermPermissionStore,
          ),
        ),

      rx.tap(([attachmentsPermissions, filesPermissions]) => {
        const documentsColumn = _.find(
          (col) => col.fieldName === 'documents',
          this.cols,
        );

        documentsColumn.show = attachmentsPermissions.isView;
      }),
      rx.tap(([attachmentsPermissions, filesPermissions]) => {
        this.attachmentsPermissions = attachmentsPermissions;
        this.filesPermissions = filesPermissions;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamTableReloadForAddAttachment() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === ATTACHMENT_ADDED_TO_DEPOSIT),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamTableReloadForRemoveAttachment() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === ATTACHMENT_REMOVED_FROM_DEPOSIT),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService() {
    return this.depositsSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.deposits';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.deposits;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getDepositsResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: this.blockUiId })
      .embed(['attachments'])
      .expand([
        'currency',
        'user',
        'owner',
        'ownerDesk',
        'ownerDesk',
        'clearingCompany',
        'transactionTransferWire',
        'transactionTransferCreditCard',
        'transactionTransferCreditCard.clearingCompany',
        'transactionTransferApm',
        'transactionTransferApm.clearingCompany',
        'ewalletTransaction',
        'ewalletTransaction.ewallet',
        'mobileTransaction',
        'mobileTransaction.clearingCompany',
      ])
      .sort({ requestedAt: 'desc' });
  }

  parseLoadedData(data) {
    this.deposits = data;
    this.depositAdditional = this.generateDepositsAdditionals();

    return data;
  }

  /**
   * Calculate relevant menu items for deposit row.
   *
   * @param {object} deposit - deposit
   * @returns {[MenuItem]} menu items relevant for the deposit
   * @private
   */
  calcMenuItemsForDeposit(deposit) {
    let menuItems = [];

    if (this.isDepositCancelable(deposit)) {
      menuItems = [
        ...menuItems,
        { labelCode: 'common.CANCEL', actionCode: 'cancel' },
      ];
    }

    if (
      isDepositDeletable(deposit) &&
      this.featuresFlags.isEnabled(['DELETE_DEPOSITS'])
    ) {
      menuItems = [
        ...menuItems,
        { labelCode: 'common.DELETE', actionCode: 'startDelete' },
      ];
    }

    if (this.isDepositConfirmable(deposit)) {
      menuItems = [
        ...menuItems,
        { labelCode: 'common.CONFIRM', actionCode: 'startConfirm' },
      ];
    }

    if (this.isDepositDocLinkable(deposit)) {
      if (
        this.attachmentsPermissions.isView &&
        this.attachmentsPermissions.isUpdate
      ) {
        menuItems = [
          ...menuItems,
          {
            labelCode: 'contact.DEPOSIT_LINK_DOCUMENT',
            actionCode: 'linkDocument',
          },
        ];
      }
      if (
        this.attachmentsPermissions.isCreate &&
        this.filesPermissions.isCreate
      ) {
        menuItems = [
          ...menuItems,
          {
            labelCode: 'contact.DEPOSIT_ATTACH_DOCUMENT',
            actionCode: 'attachDocument',
          },
        ];
      }
    }

    return menuItems;
  }

  /**
   * When data is reloaded, clear and referesh the accompaning data additional
   * info array. Ex. For each deposit the system saves a ui state of the action
   * warning line open status.
   *
   * on user Paging Event
   *
   * can be override by a different logic
   *
   * @returns {void}
   */
  onUserPaging(): void {
    this.depositAdditional = this.generateDepositsAdditionals();
  }

  generateDepositsAdditionals() {
    return this.deposits.reduce((acc, deposit) => {
      const info = new DepositAdditional();
      info.menuItems = this.calcMenuItemsForDeposit(deposit);

      return {
        ...acc,
        [deposit.id]: info,
      };
    }, {});
  }

  /**
   * Fires event that will eventually cause to deposit popup to open
   */
  onAddDeposit() {
    this.$scope.$emit('deposit.onAdd');
  }

  /**
   * Callback for push notification for deposit update
   *
   * @param {Object} deposit
   */
  onDepositPushUpdate(_deposit) {}

  isEwalletDeposit(deposit) {
    return [
      this.depositsSettings.types.manualEwallet.code,
      this.depositsSettings.types.ewallet.code,
    ].includes(deposit.transferMethodTypeCode);
  }

  /**
   *  Opens deposit confirm dialog
   */
  openDepositConfirmDialog(deposit) {
    this.depositAdditional[deposit.id].isMenuOpen = false;

    if (this.isEwalletDeposit(deposit)) {
      this.popupService.open({
        component: 'prfConfirmEwalletDepositPopup',
        resolve: {
          deposit: () => deposit,
          account: () => this.account,
          customer: () => this.customer,
          platformType: () =>
            this.platformTypesMap[this.customer.brand.platformTypeId],
        },
      });
    } else {
      this.popupService.open({
        controller: 'ConfirmDepositPopupController',
        template: confirmDepositPopupTemplate,
        scope: this.$scope,
        data: {
          deposit,
          accountId: this.account.id,
          contactId: this.customer.id,
          platformType: this.platformTypesMap[
            this.customer.brand.platformTypeId
          ],
        },
      });
    }
  }

  startDeleteDepositProcess(deposit) {
    this.depositAdditional[deposit.id].isMenuOpen = false;
    this.depositAdditional[deposit.id].showActionWarning = true;
  }

  /**
   * Cancel deposit
   *
   * @param {Object} deposit
   */
  cancelDeposit(deposit) {
    // cancel request
    deposit
      .patch({
        transactionStatusCode: TradingAccountTransactionStatusCode.Canceled,
      })
      .then(() => {
        // update table data on success
        deposit.transactionStatusCode = 'canceled';
        this.depositAdditional = this.generateDepositsAdditionals();
      });
  }

  /**
   * Delete deposit
   *
   * @param {Object} deposit
   */
  deleteDeposit(deposit) {
    // delete request
    deposit
      .patch({
        transactionStatusCode: TradingAccountTransactionStatusCode.Deleted,
      })
      .then(() => {
        // update table data on success
        deposit.transactionStatusCode = 'deleted';
        this.depositAdditional = this.generateDepositsAdditionals();
      });
  }

  /**
   * Open the "add deposit" popup
   *
   * @return {void}
   */
  openAddDepositPopup() {
    this.popupService.open({
      component: 'prfAddTransactionPopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * Open the "deposit log" popup
   *
   * @return {void}
   */
  openDepositTablePopup() {
    this.popupService.open({
      component: 'prfDepositTablePopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  // calculate the owner desk ID - depends on the existence of owner
  calcOwnerDeskId(owner, desk) {
    if (owner && desk) {
      return desk.id;
    }

    return null;
  }

  /**
     sign owner to Deposit
     *
     * @return {promise}
     */
  assignOwnerToDeposit(owner, desk, deposit) {
    return this.customersService()
      .getDepositResource(this.customer.id, this.account.id, deposit.id)
      .setConfig({ blockUiRef: 'assignToPopup' })
      .patchWithQuery({
        ownerId: owner ? owner.id : null,
        ownerDeskId: this.calcOwnerDeskId(owner, desk),
      })
      .then(() => {
        deposit.owner = owner;
        this.$scope.$emit('depositsTableReload');
      });
  }

  /**
   * Check is deposit is cancelable
   *
   * @return {boolean} is deposit cancelable.
   */
  isDepositCancelable(deposit) {
    return cancebleStatuses.includes(deposit.transactionStatusCode);
  }

  isDepositConfirmable(deposit) {
    return preApprovedStatuses.includes(deposit.transactionStatusCode);
  }

  isDepositDocLinkable(deposit) {
    return docLinkableStatuses.includes(deposit.transactionStatusCode);
  }

  onDropDownAction(actionCode, deposit, file) {
    const actionsDispatch = {
      cancel: () => this.cancelDeposit(deposit),
      startDelete: () => this.startDeleteDepositProcess(deposit),
      startConfirm: () => this.openDepositConfirmDialog(deposit),
      linkDocument: () => this.openDepositLinkDocumentDialog(deposit),
      attachDocument: () => this.attachDocumentDeposit(deposit.id),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  /**
   * Method to allow doing work after entity was updated from socket.
   *
   * @param {object} entity - updated entity
   * @return {void}
   */
  onAfterEntityUpdate(entity) {
    // reset and regenrated data infos;
    this.depositAdditional = this.generateDepositsAdditionals();
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }

  openDepositLinkDocumentDialog(deposit) {
    this.modalService.open({
      component: 'prfDepositLinkDocumentPopup',
      size: 'lg',
      resolve: {
        depositId: deposit.id,
        accountId: this.account.id,
        customerId: this.customer.id,
      },
    });
  }

  openDepositLinkedDocumentsDialog(depositId) {
    this.modalService.open({
      component: 'prfDepositLinkedDocumentsPopup',
      size: 'lg',
      resolve: {
        depositId,
        accountId: this.account.id,
        customerId: this.customer.id,
      },
    });
  }

  attachDocumentDeposit(depositId) {
    const id = this.depositAdditional[depositId].attachDocumentButtonId;

    const selector = angular.element(`[data-prf-js-id="${id}"]`);

    !_.isNil(selector) ? selector.click() : null;
  }

  patchNoteAction({
    note,
    tradingAccount,
    deposit,
  }: {
    note: string;
    tradingAccount: TradingAccount;
    deposit: TradingAccountDeposit;
  }) {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiId })
      .patchDeposit(this.customer.id, tradingAccount.id, deposit.id, { note })
      .then((newDeposit) => {
        this.onDepositNoteChange({
          depositId: newDeposit.id,
          note: String(newDeposit.note),
        });
      })
      .catch((e) => {});
  }

  onDepositNoteChange({
    depositId,
    note,
  }: {
    depositId: number;
    note: string;
  }) {
    const changedDeposit = this.deposits.find(
      (deposit) => deposit.id === depositId,
    );
    if (_.isNil(changedDeposit)) {
      return;
    }
    changedDeposit.note = note;
  }
}

export default {
  template,
  bindings: {
    account: '<',
    customer: '<',
    config: '<',
  },
  controller: DepositTableController,
  controllerAs: 'vm',
};
