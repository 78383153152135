import Component from './base/component';

class Controller extends Component.controller {
  static $inject = ['designTemplatesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'emailTemplates.SELECT_DESIGN_TEMPLATE';
  }

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'lang';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  query() {
    return this.dataServiceInst
      .filter({ isActive: true })
      .getListWithQuery()
      .then((data) => data.plain());
  }
}

export const ActiveDesignTemplateSelectComponent = Component.config({
  controller: Controller,
});
