import * as rx from '@proftit/rxjs';

/**
 * Pattern to use stream inside a component.
 *
 * Subscribe to stream. Unsubscribe from it automaticly using `takeUntil`
 * the unsub$ observable emit that the component is ending it's life cycle.
 *
 * @param stream - The stream source
 * @param usub$ - Observable returning when the component is ending.
 * @return - subscription.
 */
export function useStream(
  stream: rx.Observable<any>,
  unsub$: rx.Observable<any>,
) {
  return rx
    .pipe(
      () => stream,
      rx.takeUntil(unsub$),
    )(null)
    .subscribe();
}

export default useStream;
