import template from './send-email-dialog.component.html';
const styles = require('./send-email-dialog.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { subscribeForBeforeConstructorChanges } from '../../utilities/rx-ng-one/utils/subscribe-for-before-constructor-changes';
import { getResolveChange } from '../../utilities/rx-ng-one/operators/get-resolve-change';
import { Customer } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export class SendEmailDialogController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customer$ = this.streamCustomer();
  brand$ = rx.pipe(
    () => this.lifecycles.onChanges$,
    getResolveChange<Customer>('brand'),
    shareReplayRefOne(),
  )(null);

  /*@ngInject */
  constructor() {
    subscribeForBeforeConstructorChanges(
      [this.customer$, this.brand$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCustomer() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getResolveChange<Customer>('customer'),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }
}

export const SendEmailDialogComponent = {
  template,
  controller: SendEmailDialogController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
