import * as _ from '@proftit/lodash';

import ModalsCacheService from '~/source/common/services/modals-cache.service';
import CustomerComplianceRemovedClientPubsubService from '~/source/common/services/customer-compliance-removed-client-pubsub.service';

import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';

import { CommunicationTypesService } from '~/source/common/services/communication-types.service';
import { CommunicationsSubjectsService } from './communications-subjects.service';

import { AccountType } from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
type AllAccountType = AccountType | 'all';

const SERVICE_RESOURCE = 'reports/';

export class ReportsService extends RestService {
  static $inject = [
    ...RestService.$inject,
    'modalsCacheService',
    'prfCustomerComplianceRemovedClientPubsub',
    'prfClientGeneralPubsub',
    'communicationTypesService',
    'communicationsSubjectsService',
  ];

  modalsCacheService: ModalsCacheService;
  prfCustomerComplianceRemovedClientPubsub: CustomerComplianceRemovedClientPubsubService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  communicationTypesService: CommunicationTypesService;
  communicationsSubjectsService: CommunicationsSubjectsService;
  resourceEntity: string;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource(): string {
    return SERVICE_RESOURCE;
  }

  setResourceEntity(entity: string) {
    this.resourceEntity = entity;
  }

  /**
   * Build url
   *
   * @returns {RestService}
   */
  getCustomersResource() {
    return this.resourceBuildStart()
      .getElement(this.resourceEntity)
      .resourceBuildEnd();
  }
}

export default ReportsService;
