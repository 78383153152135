export enum CustomerCommunicationStatusCode {
  EmailSent = 'email_sent',
  EmailReceived = 'email_received',
  InProgress = 'in_progress',
  Busy = 'busy',
  Calling = 'calling',
  CallBack = 'call_back',
  Invalid = 'invalid',
  Voicemail = 'voicemail',
  Na = 'na',
  Answered = 'answered',
  EmailFailed = 'email_failed',
}
