import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import { StateService } from '@uirouter/core';
import template from './create.html';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';

class Controller extends BaseController {
  static $inject = [
    '$scope',
    'clearingCompanyConnectionsService',
    'modelNormalizer',
    '$state',
  ];

  clearingCompanyConnection: ClearingCompanyConnection;
  modelNormalizer: ModelNormalizerService;
  clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService;
  $state: StateService;

  constructor(...args) {
    super(...args);
    this.clearingCompanyConnection = {
      id: 0,
      clearingCompany: null,
      credentials: [],
      brand: null,
      url: '',
      order: 1,
      isActive: true,
    };
  }

  /**
   *  add new clearing company connection
   */
  save() {
    const normalizedModel = this.modelNormalizer.normalize(
      this.clearingCompanyConnection,
    );

    this.clearingCompanyConnectionsService()
      .postWithQuery(normalizedModel)
      .then((data) => {
        this.$state.go('^.list');
      });
  }
}

const ClearingCompaniesCreateComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default ClearingCompaniesCreateComponent;
