import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class InternalTransfersNotificationsController extends NotificationsController {}

InternalTransfersNotificationsController.$inject = [
  'prfInternalTransfersSocketService',
  ...NotificationsController.$inject,
];

export const InternalTransfersNotificationsComponent = {
  ...ComponentOptions,
  controller: InternalTransfersNotificationsController,
};
