import ng from 'angular';
import type { StateService } from '@uirouter/angularjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import log from 'loglevel';
import { TableController } from '~/source/common/components/table/table.controller';
import template from './custom-email-templates-table.component.html';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { CustomContentTemplate, Brand } from '@proftit/crm.api.models.entities';
import { CustomEmailTemplatesTableAction } from './custom-email-templates-table-actions';
import { shareReplayRefOne, useStreams, pipeLog } from '@proftit/rxjs.adjunct';
import { switchOn } from '@proftit/general-utilities';
import {
  CONTENT_TEMPLATE_UPDATED,
  CONTENT_TEMPLATE_DELETED,
} from '~/source/common/constants/general-pubsub-keys';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { customEmailTemplatesTableSettings } from './custom-email-templates-table.settings';
import { customEmailTemplatesTableCols } from './custom-email-templates-table.cols';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { calcMenuItem } from './calc-menu-item';
import { ContentTemplatesService } from '~/source/common/api-crm-server/services/content-templates.service';
import { FormControl } from '@proftit/ng1.reactive-forms';
import numRowsOptions from '~/source/common/components/dropdowns/rows-options';
import { ContentTemplateTypeCode } from '@proftit/crm.api.models.enums';
const styles = require('./custom-email-templates-table.component.scss');

interface NumberOfRowsElement {
  count: number;
}

export class CustomEmailTemplatesTableController extends TableController<
  ContentTemplatesService
> {
  static $inject = [
    'contentTemplatesService',
    'prfClientGeneralPubsub',
    'PermPermissionStore',
    '$state',
    ...TableController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = generateBlockuiId();
  contentTemplatesService: () => ContentTemplatesService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  PermPermissionStore: ng.permission.PermissionStore;
  $state: StateService;

  settings = { ...customEmailTemplatesTableSettings };
  cols = [...customEmailTemplatesTableCols];

  brand$ = new rx.BehaviorSubject<Brand>(null);

  opMenuAction$ = new rx.Subject<{
    actionCode: CustomEmailTemplatesTableAction;
    item: CustomContentTemplate;
  }>();

  collectionMenuItems$ = this.streamCollectionMenuItems();

  isTableInitialized$ = new rx.BehaviorSubject<boolean>(false);

  triggerShowSelectedNumberOfRows$ = new rx.BehaviorSubject<object | boolean>(
    false,
  );

  selectedNumberOfRows = new FormControl<NumberOfRowsElement>(null);

  constructor(...args) {
    super(...args);

    this.dataServiceInstance = this.contentTemplatesService();

    useStreams(
      [
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
        this.streamInitTable(),
        this.streamMenuActionPerform(),
        this.streamRefreshTableOnExternalNotice(),
        this.streamInitSelectedNumberOfRows(),
        this.selectedNumberOfRows.value$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  fetchFn() {
    // todoOld: brand filter
    const sort = { createdAt: 'desc' };
    const expand = ['createdBy', 'designTemplate', 'language'];
    const embed = ['brands', 'departments'];
    const config = { blockUiRef: this.blockUiRef };

    return this.dataServiceInstance
      .setConfig(config)
      .resourceFullListByBrandAndType(
        this.brand$.getValue(),
        ContentTemplateTypeCode.Custom,
        sort,
        expand,
        embed,
      );
  }

  streamInitTable() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.tap(() => this.initTable()),
      rx.tap(() => this.isTableInitialized$.next(true)),
    )(null);
  }

  streamCollectionMenuItems() {
    return rx.pipe(
      () => this.collection$,
      rx.switchMap(() =>
        checkCrudPermission(
          PermissionNormalized.ManagementEmailTemplate,
          this.PermPermissionStore,
        ),
      ),
      rx.withLatestFrom(this.collection$),
      rx.map(([{ isView, isUpdate, isDelete, isCreate }, items]) =>
        items.map((item) => ({
          id: item.id,
          menu: calcMenuItem(
            item as CustomContentTemplate,
            isUpdate,
            isDelete,
            isCreate,
          ),
        })),
      ),
      rx.map((itemsMenus) =>
        itemsMenus.reduce((acc, itemMenu) => {
          acc[itemMenu.id] = itemMenu.menu;
          return acc;
        }, {}),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamMenuActionPerform() {
    return rx.pipe(
      () => this.opMenuAction$,
      rx.tap((action) => {
        const actions = {
          [CustomEmailTemplatesTableAction.Delete]: () =>
            this.dataServiceInstance.deleteItem(action.item.id),
          [CustomEmailTemplatesTableAction.Activate]: () =>
            this.dataServiceInstance.activateItem(action.item.id),
          [CustomEmailTemplatesTableAction.Deactivate]: () =>
            this.dataServiceInstance.deactivateItem(action.item.id),
          [CustomEmailTemplatesTableAction.Edit]: () =>
            this.$state.go(
              'crm.management.tools.emails.contenttemplate.update.custom',
              { id: action.item.id },
            ),
          [CustomEmailTemplatesTableAction.Copy]: () =>
            this.$state.go(
              'crm.management.tools.emails.contenttemplate.copy.custom',
              {
                id: action.item.id,
              },
            ),
        };
        switchOn(actions, action.actionCode, () => {
          log.error('unhandled action', action);
          throw new Error('unhandled action');
        });
      }),
      rx.catchError((err, obs) => {
        log.error('error on action', err);
        return obs;
      }),
    )(null);
  }

  streamRefreshTableOnExternalNotice() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          rx.pipe(
            () => this.prfClientGeneralPubsub.getObservable(),
            rx.filter(({ key }) =>
              [CONTENT_TEMPLATE_UPDATED, CONTENT_TEMPLATE_DELETED].includes(
                key,
              ),
            ),
          )(null),
          this.brand$,
        ),
      rx.withLatestFrom(this.isTableInitialized$),
      rx.filter(([a, isInit]) => isInit),
      rx.tap(() => {
        this.resetNgTablePage();
        this.reloadTable();
      }),
    )(null);
  }

  streamInitSelectedNumberOfRows() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.lifecycles.onInit$,
          rx.obs.from([this.settings.ngTable.parameters.count]),
        ),
      rx.map(([a, num]) => {
        const id = numRowsOptions.indexOf(num);

        return {
          id,
          count: num,
          name: num,
        };
      }),
      rx.tap((numOfRowRecord) => {
        this.selectedNumberOfRows.setValueAsFirst(numOfRowRecord);
      }),
      rx.tap(() => {
        this.triggerShowSelectedNumberOfRows$.next({});
      }),
    )(null);
  }

  onRowClick(item: CustomContentTemplate) {
    this.opMenuAction$.next({
      item,
      actionCode: CustomEmailTemplatesTableAction.Edit,
    });
  }

  get tableKey() {
    return 'customEmailTemplatesTable';
  }
}

export const CustomEmailTemplatesTableComponent = {
  template,
  controller: CustomEmailTemplatesTableController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};
