import template from './user-brand-email-data.component.html';
const styles = require('./user-brand-email-data.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Brand, User } from '@proftit/crm.api.models.entities';
import { UsersService } from '../../../services/users';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import { CommunicationProviderCode } from '@proftit/crm.api.models.enums';

interface UserBrandEmail {
  id?: number;
  email: string;
}

interface UserBrandConnection extends Brand {
  email: Partial<UserBrandEmail>;
  emails: UserBrandEmail[];
  emailProviderCode: string;
}

export class UserBrandEmailDataController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  brand: UserBrandConnection;
  user: User;

  prevAttributes: any;
  isEdit: boolean;

  /*@ngInject */
  constructor(readonly usersService: () => UsersService) {
    useStreams(
      [this.streamInitializeEmailSettings()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamInitializeEmailSettings() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<Brand>('brand'),
      rx.map((brand) => {
        if (_.isNil(brand)) {
          return newEmailSettings();
        }

        return _.head(this.brand.emails) || newEmailSettings();
      }),
      rx.tap((email) => (this.brand.email = email)),
    )(null);
  }

  /**
   * Enter edit mode:
   * Save current configurationsFromServer state so it can be restored if the user chooses to cancel
   *
   * @returns {void}
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = { ...this.brand.email };

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous configurationsFromServer state, or remove if it didn't exist before
   *
   * @returns {void}
   */
  cancelEdit() {
    // Restore pre-edit state
    this.brand.email = this.prevAttributes;
    // Exit edit mode
    this.isEdit = false;
  }

  /**
   * is voip configurations fields filled
   *
   * @returns {boolean}
   */
  isEmptySettings() {
    return _.isEmpty(this.brand.email.email);
  }

  /**
   * Delete userBrandEmail from existing brand
   *
   * @returns {Promise}
   */
  deleteUserBrandEmail() {
    this.usersService()
      .deleteEmails(this.user.id, this.brand.id, this.brand.email.id)
      .then(() => {
        this.isEdit = false;
        this.brand.email = {};
      });
  }

  getEmailPayloadForSave(brand: Brand) {
    const emailProviderId =
      brand.emailCredentials.length > 0
        ? // we are taking the 1st email credentials because the UI does not support multiple email credentials. At most, only one can exist.
          brand.emailCredentials[0].emailProvider.id
        : null;
    const email = _.omit(['id'], (brand as any).email);
    return {
      ...email,
      providerId: emailProviderId,
    };
  }

  /**
   * Update brand with voipConfigurations
   *
   * @returns {Promise}
   */
  update() {
    const payload = this.getEmailPayloadForSave(this.brand);

    this.usersService()
      .updateEmails(this.user.id, this.brand.id, this.brand.email.id, payload)
      .then(() => {
        this.isEdit = false;
      });
  }

  /**
   * Save new voipConfigurations on an existing brand
   * (create new voipConfigurations on new brand is saved straight from /create.js)
   *
   * @returns {*}
   */
  save() {
    if (this.isEmptySettings()) {
      this.isEdit = false;
      return;
    }

    const payload = this.getEmailPayloadForSave(this.brand);
    this.usersService()
      .createEmails(this.user.id, this.brand.id, payload)
      .then((email: any) => {
        this.isEdit = false;
        this.brand.email.id = email.id;
      });
  }

  /**
   * Called when 'save' is clicked - calles save/edit/delete methods
   *
   * @returns {*}
   */
  saveClicked() {
    // save new voipConfigurations on existing brand
    if (_.isEmpty(this.prevAttributes)) {
      return this.save();
    }

    // delete existing voip configurations
    if (this.isEmptySettings()) {
      return this.deleteUserBrandEmail();
    }
    // edit voipConfigurations
    return this.update();
  }

  isGmailEmailProvider(): boolean {
    if (this.brand.emailProviderCode === CommunicationProviderCode.Gmail) {
      return true;
    }
    const { emailProvider } = this.brand;
    if (_.isNil(emailProvider)) {
      return false;
    }
    const { code } = emailProvider;
    return code === CommunicationProviderCode.Gmail;
  }
}

function newEmailSettings() {
  return {};
}

export const UserBrandEmailDataComponent = {
  template,
  controller: UserBrandEmailDataController,
  bindings: {
    brand: '<',
    user: '<',
  },
};
