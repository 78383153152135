import Component from './base/component';

class EmailProviderController extends Component.controller {
  static $inject = ['emailProvidersService', ...Component.controller.$inject];

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'emailProvider.SELECT_EMAIL_PROVIDER';
  }
}

export const EmailProviderSelectComponent = Component.config({
  controller: EmailProviderController,
});
