const styles = require('./search-popup-customer-result.component.scss');
import template from './search-popup-customer-result.html';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

class Controller {
  styles = styles;
  PermissionNormalized = PermissionNormalized;
}

export const SearchPopupCustomerResultComponent = {
  template,
  controller: Controller,
  bindings: {
    info: '<',
  },
};
