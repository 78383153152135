import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './form-field.component.html';
const styles = require('./form-field.component.scss');

export class FormFieldController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const FormFieldComponent = {
  template,
  controller: FormFieldController,
  transclude: {
    label: 'trnLabel',
    field: 'trnField',
  },
};
