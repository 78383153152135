import ng from 'angular';
import _ from 'underscore';
import RestService from '~/source/common/services/rest';

import { ManagementPermissionsModifierService } from '../../permissions/permissions-modifier.service';

const SERVICE_RESOURCE = 'userGroups';
const GROUP_IPS_RESOURCE = 'whitelistips';
const BATCH_ACTION_INDICATION = '~';

export class UserGroupsService extends RestService {
  managementPermissionsModifierService: ManagementPermissionsModifierService;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  /**
   * Return only the needed fields of the user groups, after removing parent references
   * from all the permissions tree nodes.
   *
   * @param {object} userGroup
   * @returns {object}
   */
  prepareUserGroup(userGroup) {
    // make copy of the object because we still need to use the original one in the app
    const parsedUserGroup = ng.copy(userGroup);
    // remove parent's ref to avoid circular structure, before submitting to server.
    parsedUserGroup.permissions = this.managementPermissionsModifierService.resetParent(
      parsedUserGroup.permissions,
    ).children;

    return _.pick(parsedUserGroup, 'id', 'name', 'description', 'permissions');
  }

  /**
   * Get all user groups with ips (whitelist) collection embedded
   *
   * @returns {RestService}
   */
  getUserGroupIpsResource() {
    return this.resourceBuildStart()
      .embed(GROUP_IPS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Update user group ips collection. we use restangular customPUT method in order to batch update the ip collection
   *
   * @param {Number} groupId
   * @param {Array} ips
   * @returns {Promise}
   */
  updateUserGroupIps(groupId, ips) {
    // Create ip array with objects as required by the server
    const ipObj = ips.map((ip) => ({ ipAddress: ip }));

    return this.resourceBuildStart()
      .getElement(groupId)
      .getNestedElement(GROUP_IPS_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(ipObj);
  }
}

UserGroupsService.$inject = [
  'managementPermissionsModifierService',
  ...RestService.$inject,
];
