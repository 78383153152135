import SocketService from '~/source/common/services/socket';

export class KibiDownloadSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  get channelRoot() {
    return 'kibi/downloads';
  }
}

export default KibiDownloadSocketService;
