import angular from 'angular';
import { ContactsAssignModule } from './contacts-assign/module';
import ContactStatusPopupComponent from './contact-status-popup/contact-status-popup.component';
import ContactTableSelectionBarComponent from './contact-table-selection-bar/contact-table-selection-bar.component';
import ContactsStatsComponent from './contacts-stats/contacts-stats.component';
import ContactUnassignComponent from './contact-unassign/contact-unassign.component';
import { ContactsDashboardComponent } from './contacts-dashboard/contacts-dashboard.component';
import { ContactsNotificationsViewComponent } from '~/source/contact/contacts-dashboard/contacts-notifications-view/contacts-notifications-view.component';
import { ContactsNotificationsManagerComponent } from '~/source/contact/contacts-dashboard/contacts-notifications-manager/contacts-notifications-manager.component';
import { ContactsDashboardCellComponent } from '~/source/contact/contacts-dashboard/contacts-dashboard-cell/contacts-dashboard-cell.component';

export default angular
  .module('contact.contactsDashboard', [ContactsAssignModule.name])
  .component('prfContactStatusPopup', ContactStatusPopupComponent)
  .component('prfContactTableSelectionBar', ContactTableSelectionBarComponent)
  .component('prfContactUnassign', ContactUnassignComponent)
  .component('prfContactsStats', ContactsStatsComponent)
  .component('prfContactsDashboard', ContactsDashboardComponent)
  .component('prfContactsNotificationsView', ContactsNotificationsViewComponent)
  .component('prfContactsDashboardCell', ContactsDashboardCellComponent)
  .component(
    'prfContactsNotificationsManager',
    ContactsNotificationsManagerComponent,
  )
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.contacts.list', {
        url: '/list?filter',
        component: 'prfContactsDashboard',
        data: {
          permissions: {
            only: ['contacts'],
            redirectTo: 'error.unauthorized',
          },
        },

        params: {
          contactsImportId: null,
        },
      });
    },
  ]).name;
