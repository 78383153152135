export enum VoipProviderCode {
  Coperato = 'coperato',
  CoperatoSms = 'coperato-sms',
  Commpeak = 'commpeak',
  CommpeakSms = 'commpeak-sms',
  Tradeo = 'tradeo',
  Didlogic = 'didlogic',
  Euphoria = 'euphoria',
  Voicespin = 'voicespin',
  VoicespinSms = 'voicespin-sms',
  Squaretalk = 'squaretalk',
  Stub = 'stub',
}
