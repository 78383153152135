class ColorLuminanceService {
  /**
   * lighten or darken hex color
   * @param {string} hex - a hex color value such as “#abc” or “#123456” (the hash is optional)
   * @param {string} lum - the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc.
   * @returns {string}
   *
   * @example
   * this.colorLuminance("#69c", 0);  // returns "#6699cc"
   * this.colorLuminance("6699CC", 0.2); // "#7ab8f5" - 20% lighter
   * this.colorLuminance("69C", -0.5); // "#334d66" - 50% darker
   * this.colorLuminance("000", 1); // "#000000" - true black cannot be made lighter!
   *
   */
  convert(hexParam, lumParam) {
    // validate hex string
    let hex = String(hexParam).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    const lum = lumParam || 0;

    // convert to decimal and change luminosity
    let rgb = '#';
    let c;
    let i;

    for (i = 0; i < 3; i += 1) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += `00${c}`.substr(c.length);
    }

    return rgb;
  }
}

export default ColorLuminanceService;
