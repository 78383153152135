import * as _ from '@proftit/lodash';

export function calcKeepAliveInterval(
  sessionTime: number,
  localStorageService: Storage,
): number {
  const lsInterval = localStorageService.get('prfKeepAliveInterval');

  if (!_.isNil(lsInterval)) {
    return lsInterval;
  }

  return calcKeepAliveIntervalBySessionTime(sessionTime);
}

function calcKeepAliveIntervalBySessionTime(sessionTime: number): number {
  const baseTime = Math.floor((sessionTime * 0.9) / 2);
  const randomAddition = Math.floor(Math.random() * baseTime);

  const keepAliveInterval = Math.floor(baseTime + randomAddition);

  return keepAliveInterval;
}
