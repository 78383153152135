import BaseStatusesController from '../base-statuses.controller';
import ClearingCompaniesService from '~/source/common/services/clearing-companies';

import template from '../statuses-management.html';

class ConfigurableClearingCompanyManagementController extends BaseStatusesController {
  static $inject = [
    'clearingCompaniesService',
    ...BaseStatusesController.$inject,
  ];

  shouldHideTrashIcon = true;

  clearingCompaniesService: ClearingCompaniesService;
  dataService: ClearingCompaniesService;

  /**
   * Component life cycle - on init.
   *
   * @return {void}
   */
  $onInit() {
    this.dataService = this.clearingCompaniesService;

    super.$onInit();
  }

  /**
   * Section name. Must be overriden by subclasses
   * Used for growl and blockUi
   *
   * @returns {string} section name
   */
  get sectionName() {
    return 'configurableClearingCompanies';
  }

  /**
   * Allow inheriting components to define their own required filter by
   * overriding this method.
   *
   * @returns {object} required filters
   */
  requiredApiFilters() {
    return {};
  }

  /**
   * Signal for the view that the model is not to be deleted.
   *
   * @param {object} model - the model.
   * @return {boolean} true to enable delete, false otherwise.
   */
  isDeleteEnabled(model) {
    return false;
  }

  /**
   * Calc the update confirm dialog header.
   *
   * @return {Promise} Promise that will be resolved to the header value;
   */
  getUpdateConfirmHeader() {
    return this.$translate(
      'statuses.CONFIRM_CONFIGURABLE_COMPANIES_MODAL_TITLE',
    );
  }

  /**
   * Calc the update confirm dialog confirm text.
   *
   * @return {Promise} Promise that will be resolved to the confirm text value;
   */
  getUpdateConfirmConfirmText(model) {
    return this.$translate('statuses.CONFIRM_CONFIGURABLE_COMPANIES_TEXT', {
      from: this.prevValues[model.id],
      to: model.name,
    });
  }
}

export default {
  template,
  controller: ConfigurableClearingCompanyManagementController,
};
