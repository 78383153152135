import ng from 'angular';
import modalService from './modal.service';
import modalToggleDirective from './modalToggle.directives';
import popupService from './popup.service';
import modalTemplate from './modal.html';
import popupTemplate from './popup.html';
import popupWindowTemplate from './popupWindow.html';

export default ng
  .module('crm.common.modal', [])
  .service('modalService', modalService)
  .service('popupService', popupService)
  .directive('prfModalToggle', modalToggleDirective)
  .run([
    '$templateCache',
    ($templateCache) => {
      $templateCache.put('base-modal.html', modalTemplate);
      $templateCache.put('base-popup.html', popupTemplate);
      $templateCache.put('base-popup-window.html', popupWindowTemplate);
    },
  ]).name;
