import { Permissions } from '~/source/common/models/permission-structure';

export const communicationsRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.integration.communications', {
        redirectTo: 'crm.management.integration.communications.list',
        url: '/communications',
        component: 'prfCommunicationsDashboard',
        data: {
          permissions: {
            only: [Permissions.ManagementBrandCommunicationSettings.Read],
            redirectTo: 'error.unauthorized',
          },
        },
      })
      .state('crm.management.integration.communications.list', {
        url: '/list',
        component: 'prfCommunicationsList',
      })
      .state(`crm.management.integration.communications.update`, {
        component: 'prfCommunicationsUpdate',
        url: '/update/:brandId',
      });
  },
];
