import ng from 'angular';

import { BrandEwalletService } from './services/brand-ewallet.service';
import { EwalletsService } from './services/ewallets.service';
import { BrandEwalletEditManagerComponent } from './containers/brand-ewallet-edit-manager/brand-ewallet-edit-manager.component';
import { BrandEwalletEditorComponent } from './components/brand-ewallet-editor/brand-ewallet-editor.component';
import { EwalletSelectComponent } from './components/ewallet-select/ewallet-select.component';
import { BrandEwalletListComponent } from './components/brand-ewallet-list/brand-ewallet-list';
import { BrandEwalletSelectComponent } from './components/brand-ewallet-select/brand-ewallet-select.component';

const BrandEwalletModule = ng.module('crm.brandEwallet', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when(
    '/crm/management/integration/brand-ewallet/companies',
    '/crm/management/integration/brand-ewallet/companies/list',
  );

  $stateProvider
    .state('crm.management.integration.brand-ewallet', {
      url: '/brand-ewallet',
      template: '<ui-view/>',
      redirectTo: 'crm.management.integration.brand-ewallet.list',
    })
    .state('crm.management.integration.brand-ewallet.list', {
      component: 'prfBrandEwalletList',
      url: '/list',
      data: {
        permissions: {
          only: ['management.brands.ewalletscredentials'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.integration.brand-ewallet.create', {
      component: 'prfBrandEwalletEditManager',
      url: '/create',
      data: {
        permissions: {
          only: ['management.brands.ewalletscredentials_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.integration.brand-ewallet.edit', {
      component: 'prfBrandEwalletEditManager',
      url: '/edit/:id',
      data: {
        permissions: {
          only: ['management.brands.ewalletscredentials_U'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

BrandEwalletModule.config(config)
  .service('brandEwalletService', BrandEwalletService)
  .service('ewalletsService', EwalletsService)
  .component('prfEwalletSelect', EwalletSelectComponent)
  .component('prfBrandEwalletSelect', BrandEwalletSelectComponent)
  .component('prfBrandEwalletEditManager', BrandEwalletEditManagerComponent)
  .component('prfBrandEwalletEditor', BrandEwalletEditorComponent)
  .component('prfBrandEwalletList', BrandEwalletListComponent);

export default BrandEwalletModule.name;
