import log from 'loglevel';

/**
 * The "directiveIf" directive allows other directives
 * to be dynamically removed from this element.
 *
 * Any number of directives can be controlled with the object
 * passed in the "directive-if" attribute on this element:
 *
 *    {'attributeName': 'expression'[, 'attribute': 'expression']}
 *
 * If `expression` evaluates to `false` then `attributeName`
 * will be removed from this element.
 *
 * Usage:
 *
 *         <any directive-if="{'myDirective': 'expression'}"
 *                    my-directive>
 *         </any>
 *
 */
import ng from 'angular';

export const DirectiveIf = [
  '$compile',
  ($compile) => {
    // Error handling.
    let compileGuard = 0;
    // End of error handling.

    return {
      // Set a high priority so we run before other directives.
      priority: 100,
      // Set terminal to true to stop other directives from running.
      terminal: true,

      compile() {
        return {
          pre(scope, element, attr) {
            /*
             * Error handling.
             *
             * Make sure we don't go into an infinite
             * compile loop if something goes wrong.
             */
            compileGuard += 1;
            if (compileGuard >= 10) {
              log.error('directiveIf: infinite compile loop!');
              return;
            }
            // End of error handling.

            // Get the set of directives to apply.
            const directives = scope.$eval(attr.prfDirectiveIf);
            ng.forEach(directives, (expr, directive) => {
              /*
               * Evaluate each directive expression and remove
               * the directive attribute if the expression evaluates
               * to `false`.
               */
              const result = scope.$eval(expr);
              if (!result) {
                /*
                 * Set the attribute to `null` to remove the attribute.
                 *
                 * See: https://docs.angularjs.org/api/ng/type/$compile.directive.Attributes#$set
                 */
                attr.$set(directive, null);
              }
            });

            /*
             * Remove our own directive before compiling
             * to avoid infinite compile loops.
             */
            attr.$set('prfDirectiveIf', null);

            /*
             * Recompile the element so the remaining directives
             * can be invoked.
             */
            $compile(element)(scope);

            /*
             * Error handling.
             *
             * Reset the compileGuard after compilation
             * (otherwise we can't use this directive multiple times).
             *
             * It should be safe to reset here because we will
             * only reach this code *after* the `$compile()`
             * call above has returned.
             */
            compileGuard = 0;
          },
        };
      },
    };
  },
];
