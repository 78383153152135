import * as _ from '@proftit/lodash';
import template from './platform-data.html';
import BaseController from '~/source/common/controllers/base';
import { Brand } from '@proftit/crm.api.models.entities';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';
import { BrandsService } from '~/source/management/brand/services/brands';

class PlatformDataController extends BaseController {
  isEdit: boolean;
  platformConnection;
  prevAttributes;
  brandsInstance: BrandsService;
  removeValidate: () => boolean;
  removeElement: () => void;
  brand: Brand;
  keysToShowOnlyInCreateMode = ['isCrypto', 'templateId'];

  /*@ngInject */
  constructor(
    readonly modelNormalizer: ModelNormalizerService,
    readonly brandsService: () => BrandsService,
  ) {
    super();
  }

  $onInit() {
    /**
     *  initial state is determined according to the id: if the model has no id,
     *  it must be a new connection, so enter edit mode
     */
    this.isEdit = !this.platformConnection.id;

    this.brandsInstance = this.brandsService();
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state. must deep clone, as the connection object is nested.
    this.prevAttributes = _.cloneDeep(this.platformConnection);
    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state, or remove the model if it didn't exist before
   */
  cancelEdit() {
    if (!this.platformConnection.id) {
      // edit was canceled before the model was created. Remove model
      this.remove();
      return;
    }

    // Restore pre-edit state
    Object.assign(this.platformConnection, this.prevAttributes);
    // Exit edit mode
    this.isEdit = false;
  }

  /**
   * Removes the platform.
   * First removes from server, then removes the entire element.
   *
   * Calls the validation function first, to check if we are allowed to remove.
   */
  remove() {
    // Validate if we are allowed to remove the platform, using the passed validation function
    if (!this.removeValidate()) {
      return;
    }

    if (!this.platformConnection.id) {
      // new connection. no need to remove from server
      this.removeElement();
      return;
    }

    // Existing desk: remove from server, then remove the element.
    this.brandsInstance
      .getPlatformResource(this.brand.id, this.platformConnection.id)
      .removeWithQuery()
      .then(() => {
        this.removeElement();
      });
  }

  /**
   * Update (patch) platform data to server.
   * isEdit changes to 'false' on success.
   * @return {Promise} resolved on success or rejected on failure
   */
  update() {
    // When updating, we have to remove the ID from the updated object
    const normalizedModel = this.modelNormalizer.normalize(
      _.omit('id', this.platformConnection),
    );

    /**
     * The server handles the isDefault flag as a special case.
     * Therefore, when the isDefault flag is false, it must not be sent at all.
     * it should only be sent to the server when it's true, which will make the server
     * change all the other platforms to non default.
     */
    if (normalizedModel.isDefault === false) {
      delete normalizedModel.isDefault;
    }

    // Save the platform
    return this.brandsInstance
      .getPlatformResource(this.brand.id, this.platformConnection.id)
      .setConfig({
        errorsTranslationPath: 'platform.server_errors',
        growlRef: 'platformConnections',
        blockUiRef: 'platformConnections',
      })
      .patchWithQuery(normalizedModel)
      .then(() => {
        // change to display mode
        this.isEdit = false;
      });
  }

  getCredObjFromPlatformConnection(
    platformConnection: any,
    credKeyName: string,
  ) {
    return platformConnection.credentials.find(
      (cred) => cred.key === credKeyName,
    );
  }

  /**
   * Save the platform data to the server.
   *
   * If an id exists - update. otherwise - create new.
   */
  save() {
    if (this.platformConnection.id) {
      // Existing platform. update
      return this.update();
    }

    const platformConnectionCopy = _.cloneDeep(this.platformConnection);
    const isCryptoCred = this.getCredObjFromPlatformConnection(
      platformConnectionCopy,
      'isCrypto',
    );

    const templateIdKey = 'templateId';
    if (!_.isNil(isCryptoCred) && isCryptoCred.value) {
      platformConnectionCopy.credentials = platformConnectionCopy.credentials.filter(
        (cred) => cred.key !== templateIdKey,
      );
    }

    const templateIdCred = this.getCredObjFromPlatformConnection(
      platformConnectionCopy,
      templateIdKey,
    );
    if (!_.isNil(templateIdCred) && !isCryptoCred.value) {
      const newCredObject = {
        key: templateIdKey,
        value: templateIdCred?.value?.id
          ? templateIdCred.value.platformConnections[0].syncRemoteId
          : null,
      };
      platformConnectionCopy.credentials = [
        ...platformConnectionCopy.credentials.filter(
          (cred) => cred.key !== templateIdKey,
        ),
        newCredObject,
      ];
    }
    const normalizedModel = this.modelNormalizer.normalize(
      platformConnectionCopy,
    );

    return this.brandsInstance
      .getPlatformsResource(this.brand.id)
      .setConfig({
        errorsTranslationPath: 'platform.server_errors',
        growlRef: 'platformConnections',
        blockUiRef: 'platformConnections',
      })
      .expand(['platform'])
      .postWithQuery<any>(normalizedModel)
      .then((createdPlatform) => {
        // change to display mode
        this.isEdit = false;
        this.platformConnection.id = createdPlatform.id;
        this.platformConnection.brandId = createdPlatform.brandId;
      });
  }
}

export default {
  template,
  bindings: {
    platformConnection: '=',
    brand: '<',
    removeElement: '&removeElementFn',
    removeValidate: '&removeValidateFn',
  },
  controller: PlatformDataController,
  controllerAs: 'vm',
};
