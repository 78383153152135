import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class BundlesNotificationsController extends NotificationsController {}

BundlesNotificationsController.$inject = [
  'bundlesSocketService',
  ...NotificationsController.$inject,
];

export const BundlesNotificationsComponent = {
  ...ComponentOptions,
  controller: BundlesNotificationsController,
};
