import ng from 'angular';

import AddBonusPopupController from './add-bonus-popup.controller';
import BonusCreateComponent from './bonus-create/bonus-create.component';
import BonusDeductComponent from './bonus-deduct/bonus-deduct.component';
import BonusTableComponent from './bonus-table/bonus-table.component';
import BonusTablePopupComponent from './bonus-table-popup/bonus-table-popup.component';
import { CanceledBonusDetailsComponent } from './canceled-bonus-details/canceled-bonus-details';

export default ng
  .module('crm.contact.tradingAccount.bonus', [])
  .controller('AddBonusPopupController', AddBonusPopupController)
  .component('prfBonusTablePopup', BonusTablePopupComponent)
  .component('prfBonusTable', BonusTableComponent)
  .component('prfBonusCreate', BonusCreateComponent)
  .component('prfCanceledBonusDetails', CanceledBonusDetailsComponent)
  .component('prfBonusDeduct', BonusDeductComponent).name;
