import * as _ from '@proftit/lodash';
import ng from 'angular';

import BaseService from '~/source/common/services/baseService';
import TokensService from '~/source/auth/services/tokens';

class AuthenticationService extends BaseService {
  static $inject = ['tokensService', 'PermPermissionStore'];

  tokensService: TokensService;
  PermPermissionStore: ng.permission.PermissionStore;

  /**
   * Defines all the permissions for crud-based permissions.
   * For each permission `Perm` defined in the "crudPermissions" array, it will define the following permissions:
   * "Perm" (read)
   * "Perm_C" (create)
   * "Perm_U" (update)
   * "Perm_D" (delete).
   *
   * The same function is used to validate each permission: isValidPermission
   * @param {object} crudPermissions - crud permissions map
   * @return {void}
   */
  addCrudPermissions(crudPermissions) {
    const actionMap = {
      '': 'actionR',
      _C: 'actionC',
      _U: 'actionU',
      _D: 'actionD',
    };

    _.eachEs(actionMap, (action, suffix) => {
      // Add suffix to each permission
      const permissionList = crudPermissions.map(
        (permission) => `${permission}${suffix}`,
      );

      // Now define all the permissions
      this.PermPermissionStore.defineManyPermissions(
        permissionList,
        _.partialEs(
          (act, suff, permission) => {
            const permissionKey = permission.replace(suff, ''); // remove suffix from permission
            return this.isValidPermission(permissionKey, act);
          },
          action,
          suffix,
        ),
      );
    });
  }

  /**
   * Checks if the user has permission for permissionKey and action
   * @param {string} permissionKey - Permission to check (e.g. contacts)
   * @param {string} [action] - action to check (e.g. actionR - read). Default: actionR
   * @return {boolean} returns true if the user has permission
   */
  isValidPermission(permissionKey, action = 'actionR') {
    if (!this.tokensService.isLoggedIn()) {
      // user is not logged in - no permission
      return false;
    }
    const currentUser = this.tokensService.getCachedUser();
    const userPermissions = currentUser.permissions[permissionKey];

    if (_.isEmpty(userPermissions)) {
      // user permissions are empty - no permission
      return false;
    }

    return userPermissions[action] === 1;
  }
}

export default AuthenticationService;
