import template from './fernis-frame.component.html';
const styles = require('./fernis-frame.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class FernisFrameController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const FernisFrameComponent = {
  template,
  controller: FernisFrameController,
  transclude: true,
  bindings: {
    headerText: '<',
    headerSideActionIcon: '<',
    onHeaderSideActionClick: '&',
  },
};
