import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import {
  Brand,
  ContentTemplate,
  Language,
} from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';
import template from './system-emails.component.html';
import {
  ContentTemplateTypeCode,
  LanguagesCode3,
} from '@proftit/crm.api.models.enums';
import { PreselectType } from '~/source/common/components/dropdowns/base/component';

const styles = require('./system-emails.component.scss');

export class SystemEmailsController {
  styles = styles;

  ContentTemplateTypeCode = ContentTemplateTypeCode;

  PreselectType = PreselectType;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  selectSystemEmailAction = new rx.Subject<number>();

  form$ = this.streamForm();

  selectedSystemEmailTemplateId$ = this.streamSelectedSystemEmailTemplateId();

  lastSelectedLanguage: Language;

  lastSelectedLanguage$ = this.streamLastSelectedLanguage();

  preselectContentTemplateBinded: (data: ContentTemplate[]) => ContentTemplate;

  /*@ngInject */
  constructor(
    readonly prfSystemEmailTemplatesService: () => SystemEmailTemplatesService,
  ) {
    this.preselectContentTemplateBinded = (templates: ContentTemplate[]) => {
      return this.preselectContentTemplate(templates);
    };

    useStreams(
      [
        this.form$.pipe(rx.switchMap((f) => f.value$)),
        this.lastSelectedLanguage$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm() {
    return rx.pipe(() =>
      rx.obs.from([
        new FormGroup({
          brand: new FormControl(null),
          contentTemplate: new FormControl(null),
        } as any),
      ]),
    )(null);
  }

  streamSelectedSystemEmailTemplateId() {
    return rx.pipe(
      () => this.selectSystemEmailAction,
      rx.withLatestFrom(
        this.form$.pipe(rx.switchMap((form) => form.controls$)),
      ),
      rx.tap(([systemId, controls]) => {
        (controls.contentTemplate as FormControl<any>).model = null;
      }),
      rx.map(([systemId, controls]) => systemId),
      rx.startWith(null),
      shareReplayRefOne(),
    )(null);
  }

  streamLastSelectedLanguage() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.controls.contentTemplate.value$),
      rx.filter((template) => !_.isNil(template)),
      rx.map((template) => _.get(['language'], template)),
      rx.tap((language) => {
        this.lastSelectedLanguage = language;
      }),
      shareReplayRefOne(),
    )(null);
  }

  preselectContentTemplate(templates: ContentTemplate[]) {
    if (_.isNil(this.lastSelectedLanguage)) {
      return templates.find((t) => t.language.code3 === LanguagesCode3.English);
    }

    return templates.find(
      (t) => t.language.code3 === this.lastSelectedLanguage.code3,
    );
  }
}

export const SystemEmailsComponent = {
  template,
  controller: SystemEmailsController,
};
