import * as _ from '@proftit/lodash';
import CacheFactory from 'cachefactory';

import BaseService from '~/source/common/services/baseService';
import FilterService from '~/source/common/components/table-filters/services/filter.service';
import { CachedFilterObject } from '~/source/common/components/table-filters';

const cacheNamespace = 'filtersCacheData';
class FilterCacheService extends BaseService {
  CacheFactory: CacheFactory.ICacheFactory;
  filterService: FilterService;

  /**
   * store values in local store
   * @param {String} cacheId  the cache identifier of local storage data
   * @param {Object} data the data to store in local storage
   */
  put(cacheId: string, data: any, options = {}) {
    this.getCacheFactory().put(cacheId, data, options);
  }

  /**
   * returns cached data for the given cache id.
   *
   * It first gets the cache factory for this resource,
   * then tries to get the requested cache id.
   * If the cache id does not exist, it returns undefined.
   *
   * @param {String} cacheId  the cache identifier of local storage data
   * @returns {Object} returns a filter collection from local storage
   */
  get(cacheId: string): CachedFilterObject {
    return this.getCacheFactory().get(cacheId);
  }

  duplicateFilter(originalCacheId: string, newCacheId: string, options = {}) {
    const data = this.getCacheFactory().get(originalCacheId);
    this.put(newCacheId, data, options);
  }

  keys() {
    return this.getCacheFactory().keys();
  }

  /**
   * Return cache object if already exists otherwise creates a new Cache object
   * @returns {CacheFactory} - returns cache factory initialized object
   */
  getCacheFactory(): CacheFactory.ICache {
    let cache = this.CacheFactory.get(cacheNamespace);

    if (_.isUndefined(cache)) {
      // create cache with the default options from cache config file
      cache = this.CacheFactory(cacheNamespace, {
        storageMode: 'localStorage',
      });
    }

    return cache;
  }

  /**
   * disable all filters belongs to specific cacheId
   * @param {String} cacheId the cache identifier of local storage data
   */
  disableAll(cacheId: string): void {
    const cachedFilters = this.get(cacheId) || {};
    const disabledFilters = this.filterService.disableAllFilters(cachedFilters);
    this.put(cacheId, disabledFilters);
  }

  /**
   * add filter items to local storage cached filters, override filter if exist.
   * note, filter must br prepared
   *
   * @param cacheId
   * @param filters
   */
  addItems(cacheId: string, filters: CachedFilterObject) {
    const cachedItems = this.get(cacheId) || {};
    this.put(cacheId, { ...cachedItems, ...filters });
  }
}

FilterCacheService.$inject = [
  'filterService',
  'filtersSettings',
  'CacheFactory',
];

export default FilterCacheService;
