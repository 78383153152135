import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { Mt4Group } from '@proftit/tradingcore.api.models.entities';
import { generateObjectProxy } from '~/source/common/proxy-form/generate-object-proxy';
import { FieldProxyHandler } from '~/source/common/proxy-form/field-proxy-handler';
import { isNumberProxyValidator } from '~/source/common/proxy-form/validators/is-number-proxy-validator';
import { objShallowDifference } from '@proftit/general-utilities';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { isNumberBelowProxyValidatorFactory } from '~/source/common/proxy-form/validators/is-number-below-proxy-validator-factory';
import { isNumberNoneNegativeProxyValidator } from '~/source/common/proxy-form/validators/is-number-none-negative-proxy-validator';
import { isNumberInRangeProxyValidatorFactory } from '~/source/common/proxy-form/validators/is-number-in-range-proxy-validator-factory';

interface ProxiedObject {
  handler: any;
}

function observeValueOnProxy<T extends object>(proxy: ProxiedObject) {
  const value$ = new rx.BehaviorSubject<T>(null);
  proxy.handler.getLeaf().addValueListener(({ value }) => value$.next(value));

  return value$;
}

function observeInitialOnProxy<T>(proxy: ProxiedObject) {
  const initial$ = new rx.BehaviorSubject<T>(null);

  proxy.handler
    .getLeaf()
    .addInitialListener(({ initial }) => initial$.next(initial));

  return initial$;
}

function observeIsValidOnProxy(proxy: ProxiedObject) {
  const isValid$ = new rx.BehaviorSubject<boolean>(true);

  proxy.handler
    .getLeaf()
    .addIsValidListener(({ isValid }) => isValid$.next(isValid));

  return isValid$;
}

function observeValidationResultsOnProxy(proxy: ProxiedObject) {
  const validationResults$ = new rx.BehaviorSubject<any[]>([]);

  proxy.handler
    .getLeaf()
    .addValidationResultsListener(({ validationResults }) =>
      validationResults$.next(validationResults),
    );
}

function observeChangesOnProxy<T>(
  value$: rx.Observable<T>,
  initial$: rx.Observable<T>,
) {
  const changes$ = rx.pipe(
    () => rx.obs.combineLatest(value$, initial$),
    rx.map(([value, initial]) => objShallowDifference(value, initial)),
    shareReplayRefOne(),
  )(null);

  return changes$;
}

export function generateGroupSecurityForm() {
  const proxy: any = generateObjectProxy<Mt4Group>({});

  const readonlyFields = [
    'enable',
    'id',
    'mtCustomerGroup',
    'mtCustomerGroupID',
    'mtGroupSecuritiesId',
    'name',
    'trade',
  ];

  readonlyFields.forEach((fieldName) => {
    proxy.handler.addFieldHandler(
      fieldName,
      new FieldProxyHandler({
        isReadonly: true,
      }),
    );
  });

  proxy.handler.addFieldHandler(
    'minVolume',
    new FieldProxyHandler({
      validators: [isNumberProxyValidator],
    }),
  );

  proxy.handler.addFieldHandler(
    'maxVolume',
    new FieldProxyHandler({
      validators: [isNumberProxyValidator],
    }),
  );

  proxy.handler.addFieldHandler(
    'volumeStep',
    new FieldProxyHandler({
      validators: [isNumberProxyValidator],
    }),
  );

  proxy.handler.addFieldHandler(
    'spread',
    new FieldProxyHandler({
      validators: [
        isNumberProxyValidator,
        isNumberInRangeProxyValidatorFactory(-999_999, 999_999),
      ],
    }),
  );

  const isValid$ = observeIsValidOnProxy(proxy as any);
  const validationResults$ = observeValidationResultsOnProxy(proxy as any);
  const initial$ = observeInitialOnProxy(proxy as any);
  const value$ = observeValueOnProxy(proxy as any);
  const changes$ = observeChangesOnProxy(value$, initial$);

  return {
    proxy,
    isValid$,
    validationResults$,
    value$,
    changes$,
  };
}
