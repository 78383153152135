import Attachment from '~/source/common/models/attachment';
import * as rx from '@proftit/rxjs';

export class CustomerComplianceRemovedClientPubsubService {
  itemRemoved$ = new rx.Subject<Attachment>();

  /**
   * Get the service changes observable. Clients then could subscribe
   * for changes notifcations on it.
   *
   * @return {Observable}
   */
  getObs() {
    return this.itemRemoved$.asObservable();
  }

  /**
   * Publish a change notificatin.
   *
   * @param campaign - The updated campaign
   * @return {void}
   */
  publish(item: Attachment) {
    this.itemRemoved$.next(item);
  }
}

export default CustomerComplianceRemovedClientPubsubService;
