import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { Mt4Group } from '@proftit/tradingcore.api.models.entities';
import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import { ApiMt4GroupsService } from '../api-cfd-platform/api-mt4-groups.service';
import { ClientGeneralPubsub } from '../services/client-general-pubsub';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import { directivesPriorities } from '../constants/directives-priorities';
import { PlatformSessionInfo } from './platform-session-info';

interface UpdateAction {}

export class CurrentGroupOfBrandPlatformStoreServiceDirectiveController {
  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  getOneActionObs$ = new rx.Subject<rx.Observable<any>>();

  updateActionObs$ = new rx.Subject<rx.Observable<UpdateAction>>();

  groupS = this.streamGroup();

  /* @ngInject */
  constructor(
    readonly prfApiMt4GroupsService: ApiMt4GroupsService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams([this.groupS.stream$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamGroupFromGet() {
    return generateInProcessStream(
      this.getOneActionObs$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(
              rx.catchError((e) => {
                inProcess$.next({ inProcess: false, error: e });
                log.error('error fetching group', e);
                return rx.obs.of(null);
              }),
            ),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamGroupFromUpdate() {
    return generateInProcessStream(
      this.updateActionObs$,
      (inProcess$) =>
        rx.pipe(
          rx.switchMap((action$) =>
            action$.pipe(
              rx.catchError((e) => {
                inProcess$.next({ inProcess: false, error: e });
                log.error('error updating group', e);
                return rx.obs.NEVER;
              }),
            ),
          ),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamGroup() {
    const groupFromGetS = this.streamGroupFromGet();
    const groupFromUpdateS = this.streamGroupFromUpdate();

    return generateInProcessStream(
      rx.obs.merge(groupFromGetS.stream$, groupFromUpdateS.stream$),
      () => rx.pipe(shareReplayRefOne()),
      [groupFromGetS.inProcess$, groupFromUpdateS.inProcess$],
    );
  }

  load(sessonInfo: PlatformSessionInfo, mtGroupId: number) {
    const action$ = rx.pipe(
      () =>
        rx.obs.from(
          this.prfApiMt4GroupsService.getGroup(
            sessonInfo.session.apiUrl,
            sessonInfo.session.token,
            mtGroupId,
          ),
        ),
      shareReplayRefOne(),
    )(null);

    this.getOneActionObs$.next(action$);
    return action$;
  }

  update(changes: Partial<Mt4Group>) {
    const action$ = rx.pipe(
      () => rx.obs.of(changes),
      rx.withLatestFrom(
        this.groupS.stream$,
        this.prfCurrentPlatformSession.sessionS.stream$,
      ),
      rx.switchMap(([changes, group, sessionInfo]) => {
        if (!sessionInfo.isLoggedIn) {
          throw new Error('update action can not be performed. Not logged in');
        }

        return this.prfApiMt4GroupsService.updateGroup(
          group.id,
          changes,
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      shareReplayRefOne(),
    )(null);

    this.updateActionObs$.next(action$);
    return action$;
  }
}

export const currentGroupOfBrandPlatformStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentGroupOfBrandPlatformStoreServiceDirectiveController,
  };
};

currentGroupOfBrandPlatformStoreServiceDirective.$inject = [] as string[];
