import * as _ from '@proftit/lodash';
import { tradingAccountsReportsSettings } from './trading-accounts-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'tradingAccountsReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class TradingAccountsReportTableController extends CommonReportsTableController {
  resource: string = 'customerTradingAccounts';
  exportResource: string = 'customer_trading_accounts';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>tradingAccountsReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'tradingAccountsReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'trading_accounts_report_table';
  }
}

export const TradingAccountsReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: TradingAccountsReportTableController,
};
