export function createEmptyBrand() {
  return {
    isActive: true,
    desks: [],
    currencyConnections: [],
    blacklistedCountries: [],
    attachmentsVerifiedMethod: {},
    voipProvider: {},
    voipCredentials: [],
    smsProvider: null,
    smsCredentials: [],
    cashier: null,
    cashierCredentials: [],
    isMultipleWithdrawalsEnabled: true,
  };
}
