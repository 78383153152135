import template from './profile-icons-select-bar.html';
import BaseController from '~/source/common/controllers/base';
const styles = require('./profile-icons-select-bar.scss');

export class ProfileIconsSelectBarController extends BaseController {
  images: number[];

  styles = styles;

  /*@ngInject */
  constructor() {
    super();
  }

  $onInit() {}

  $onDestroy() {}
}

export const profileIconsSelectBarComponent = {
  template,
  controller: ProfileIconsSelectBarController,
  bindings: {
    cancelEdit: '&',
    applyEdit: '&',
    images: '<',
  },
};
