import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'kibi/downloadReports';
const DOWNLOADS = 'downloads';

export class KibiDownloadsService extends RestService {
  static $inject = [...RestService.$inject];

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  getDownloadReportPath(reportId: number) {
    return `${reportId}/${DOWNLOADS}`;
  }

  downloadReport(reportId: number) {
    const customPath = this.getDownloadReportPath(reportId);
    return this.getWithCustomPath(customPath, { responseType: 'blob' });
  }
}

export default KibiDownloadsService;
