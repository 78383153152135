import RestService from '~/source/common/services/rest';
import { BrandEwalletProvider } from '~/source/management/integrations/ewallet-crypto-providers/ewallets-providers.model';

const SERVICE_RESOURCE = 'ewalletProviders';

export class EwalletsProvidersService extends RestService {
  static $inject = [...RestService.$inject];

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  /**
   * Builds the resource for eWallet providers.
   *
   * @returns {Object} The built resource.
   */
  getEwalletProvidersResource() {
    return this.resourceBuildStart().resourceBuildEnd().getListWithQuery();
  }

  /**
   * Builds the resource for eWallet providers with embedded currencies.
   *
   * @returns {Object} The built resource with embedded currencies.
   */
  getEwalletProvidersResourceWithCurrencies() {
    return this.embed('currencies')
      .expand(['currencies.currency'])
      .getListWithQuery();
  }

  get name() {
    return this.resource;
  }
}

export default EwalletsProvidersService;
