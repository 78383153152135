import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';

export const reorderCompanies = (
  insertionIndex: number,
  newItem: ClearingCompanyConnection,
  previousIndex: number,
  originalArray: ClearingCompanyConnection[],
) => {
  const newData = [...originalArray];
  newData.splice(insertionIndex, 0, newItem);
  const indexToRemoveAt =
    previousIndex > insertionIndex ? previousIndex + 1 : previousIndex;
  newData.splice(indexToRemoveAt, 1);
  return newData.map((dataItem, index) => {
    return {
      ...dataItem,
      order: index + 1,
    };
  });
};
