import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import {
  isNotEmptyStringFormValidator,
  isNotNilFormValidator,
} from '@proftit/ng1.reactive-forms.validators';

export function buildEditCommentForm() {
  return new FormGroup({
    communicationSubject: new FormControl('', {
      validators: [isNotNilFormValidator],
    }),
    details: new FormControl('', {
      validators: [isNotEmptyStringFormValidator],
    }),
    communicationStatus: new FormControl('', {
      validators: [isNotNilFormValidator],
    }),
    noFollowUpCheck: new FormControl(true, {
      validators: [],
    }),
    followUpDateAsDate: new FormControl(null, {
      validators: [],
    }),
  } as any);
}
