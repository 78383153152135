import * as _ from '@proftit/lodash';

import RestService from '~/source/common/services/rest';
import CustomerProperty from '~/source/common/models/customer-property';

export class CustomerPropertiesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'customerProperties';
  }

  upsert(id: number, data: Partial<CustomerProperty>) {
    if (!_.isNil(id)) {
      throw new Error('update not implemented');
      // return this.update(data.id, data);
    }

    return this.insert(data);
  }

  insert(data: Partial<CustomerProperty>) {
    return this.postWithQuery(data);
  }
}

export default CustomerPropertiesService;
