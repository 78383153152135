export const tableColumns = [
  {
    title: 'scoringTable.fields.NAME',
    field: '',
    id: 'name',
    fieldFormat: '{{ name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'scoringTable.fields.BRAND',
    field: '',
    id: 'brand',
    fieldFormat: '{{ questionnaireForm.brand.name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'scoringTable.fields.FORM_NAME',
    field: '',
    id: 'form',
    fieldFormat: '{{ questionnaireForm.name }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'scoringTable.fields.IS_DIRTY',
    field: '',
    id: 'isDirty',
    fieldFormat:
      '{{ isDirty | booleanToWord:"booleanToWord.scoringIsDirty" | translate }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    headerTooltipIcon: true,
    headerTooltipTextCode: 'scoringTable.isDirtyTooltip',
  },
  {
    title: 'scoringTable.fields.STATUS',
    field: '',
    id: 'status',
    fieldFormat:
      '{{ isActive | booleanToWord:"booleanToWord.active" | translate }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: '',
    field: '',
    id: 'actions',
    fieldFormat: '',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
];
