import * as _ from '@proftit/lodash';

import isValidFourPartDecimalIp from './is-valid-four-part-decimal-ip';
import isValidRangeIpv4 from './is-valid-range-ipv4';
import isValidIpv6 from './is-valid-ipv6';
import isValidRangeIpv6 from './is-valid-range-ipv6';

const validators: ((string) => boolean)[] = [
  isValidFourPartDecimalIp,
  isValidRangeIpv4,
  isValidIpv6,
  isValidRangeIpv6,
];

/**
 * Check if ip is valid as whitelist proftit ip for customer/group.
 *
 * @param {string} ip - ip
 * @return {boolean} whether the ip is valid
 */
export function isValidCustomerSystemIp(ip: string) {
  return _.some((validator) => validator(ip), validators);
}

export default isValidCustomerSystemIp;
