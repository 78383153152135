import template from './contacts-import-dashboard.component.html';
const styles = require('./contacts-import-dashboard.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { Permissions } from '~/source/common/models/permission-structure';

export class ContactsImportDashboardController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  Permissions = Permissions;

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ContactsImportDashboardComponent = {
  template,
  controller: ContactsImportDashboardController,
};
