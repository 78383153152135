import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class PositionsBinaryNotificationsController extends NotificationsController {}

PositionsBinaryNotificationsController.$inject = [
  'positionsBinarySocketService',
  ...NotificationsController.$inject,
];

export default {
  ...ComponentOptions,
  controller: PositionsBinaryNotificationsController,
};
