import { IHttpService } from 'angular';
import Url from 'url-parse';

import { Brand } from '@proftit/crm.api.models.entities';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  addHeader,
  getAsOne,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  create as genReqCreate,
  setTokenHeader,
  setApiUserCredentialsHeaders,
  performRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import { BrandsService } from '~/source/management/brand/services/brands';
import { CfdPlatformGroup } from '~/source/common/models/cfd-platform/cfd-platform-group';
import { removeColonAtEnd } from '~/source/common/utilities/remove-colon-at-end';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { getCfdPlatformPrivateAccessInfoCredentials } from '~/source/common/models/platform-connection/get-cfd-platform-private-access-info-credentials';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';

const RESOURCE_PATH = 'Groups';

export class PlatfromGroupsService {
  updateGroup$ = new rx.Subject<any>();
  createGroup$ = new rx.Subject<any>();

  /*@ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  getUpdateGroupObs() {
    return this.updateGroup$.asObservable();
  }

  getCreateGroupObs() {
    return this.createGroup$.asObservable();
  }

  initReq(reqParam, urlStr) {
    const url = new Url(urlStr);
    return _.flow([
      (req) => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(RESOURCE_PATH, req),
    ])(reqParam);
  }

  initPublicReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReqWithSession(sessionInfo: PlatformSessionInfo) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, sessionInfo.session.apiUrl),
      (req) => setTokenHeader(sessionInfo.session.token, req),
    ])();
  }

  getAsOne(id: number, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  update(id: number, group, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => genReqUpdate(req),
        (req) => setPayload(group, req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        _.tap((req) => req.then((resp) => this.updateGroup$.next(null))),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  create(group: CfdPlatformGroup, req) {
    return rx.obs.from(
      _.flow([
        (req) => genReqCreate(req),
        (req) => setPayload(group, req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        _.tap((req) => req.then((resp) => this.createGroup$.next(null))),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }
}
