import ng from 'angular';

import { EwalletCryptoProvidersListComponent } from './components/ewallet-crypto-providers-list/ewallet-crypto-providers-list.component';
import { EwalletCryptoProvidersDashboardComponent } from './components/ewallet-crypto-providers-dashboard/ewallet-crypto-providers-dashboard.component';
import { ewalletCryptoProvidersRouting } from './ewallet-crypto-providers.routing';
import { EwalletCryptoProvidersUpdateComponent } from './components/ewallet-crypto-providers-update/ewallet-crypto-providers-update.component';
import { EwalletProvidersFieldsRendererComponent } from './components/credentials-fields-renderer/ewallet-providers-fields-renderer.component';
import { EwalletProvidersCurrenciesRendererComponent } from './components/ewallet-provider-currency-renderer/ewallet-providers-currency-renderer.component';
import { EwalletsProvidersService } from './servises/ewalletProviders.service';

export const EwalletCryptoProvidersModule = ng.module(
  'crm.integrations.ewalletCryptoProviders',
  [],
);

EwalletCryptoProvidersModule.config(ewalletCryptoProvidersRouting);

EwalletCryptoProvidersModule.component(
  'prfEwalletCryptoProvidersDashboard',
  EwalletCryptoProvidersDashboardComponent,
)
  .service('ewalletProvidersService', EwalletsProvidersService)
  .component(
    'prfEwalletCryptoProvidersList',
    EwalletCryptoProvidersListComponent,
  )
  .component(
    'prfEwalletCryptoProvidersUpdate',
    EwalletCryptoProvidersUpdateComponent,
  )

  .component(
    'prfEwalletProvidersFieldsRenderer',
    EwalletProvidersFieldsRendererComponent,
  )
  .component(
    'prfEwalletProvidersCurrenciesRenderer',
    EwalletProvidersCurrenciesRendererComponent,
  );
