import * as _ from '@proftit/lodash';

import FormFormatter from '../../../common/services/base-form-formatter';

class ManagementBrandDeskFormFormatter extends FormFormatter {
  constructor(...args) {
    super(...args);
  }

  get map() {
    return {
      brands: [
        {
          method: 'normalizeBrandParams',
          params: [],
        },
      ],
    };
  }

  /**
   * Modify brands, desks and voipConfigurations in order to match API requets
   *
   * Output example - brands [{
   *   brandId: 3
   *   desks: [3, ,4 , 5]
   *   voipConfigurations: [{sip: "123456", voipExtestion: 555666555}]
   * }]
   */
  normalizeBrandParams() {
    return _.map((brand) => {
      brand.desks = _.map((desk) => desk.id, brand.desks);

      return _.flow([
        () => ({
          desks: brand.desks,
          brandId: brand.id,
        }),
        (conf) => {
          // if there is no voipConfigurations - dont send it to server
          if (_.isEmpty(brand.voipConfigurations)) {
            return conf;
          }

          const filteredVoipConfigurations = brand.voipConfigurations.filter(
            (config) =>
              !_.isNil(config) &&
              !_.isNil(config.extension) &&
              String(config.extension).length > 0,
          );
          return {
            ...conf,
            voipConfigurations: filteredVoipConfigurations,
          };
        },
        (conf) => {
          if (_.isEmpty(brand.email) || brand?.email?.email === '') {
            return conf;
          }

          const emailProviderId =
            brand.emailCredentials.length > 0
              ? brand.emailCredentials[0].emailProviderId
              : null;
          return {
            ...conf,
            emails: [{ ...brand.email, providerId: emailProviderId }],
          };
        },
      ])();
    }, this.model.brands);
  }
}

export default ManagementBrandDeskFormFormatter;
