import template from './email-template-contents-logs-table.component.html';
const styles = require('./email-template-contents-logs-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import * as rx from '@proftit/rxjs';
import { Brand } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { ContentTemplatesLogsService } from '~/source/common/api-crm-server/services/content-templates-logs.service';
import { emailTemplatesContentsLogsTableCols } from './email-template-contents-logs-table.cols';
import { emailTemplatesContentsLogsTableSettings } from './email-template-contents-logs-table.settings';

export class EmailTemplateContentsLogsTableController extends TableController {
  static $inject = [
    'contentTemplatesLogsService',
    'prfClientGeneralPubsub',
    ...TableController.$inject,
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  blockUiRef = 'emailTemplateContentsTable';
  contentTemplatesLogsService: () => ContentTemplatesLogsService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  dataServiceInstance: ContentTemplatesLogsService;
  settings = { ...emailTemplatesContentsLogsTableSettings };
  cols = [...emailTemplatesContentsLogsTableCols];
  brand$ = new rx.BehaviorSubject<Brand>(null);
  isTableInitialized$ = new rx.BehaviorSubject<boolean>(false);

  constructor(...args) {
    super(...args);

    this.dataServiceInstance = this.contentTemplatesLogsService();

    useStreams(
      [
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
        this.streamInitTable(),
        this.streamRefreshTableOnExternalNotice(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  fetchFn() {
    // todoOld: brand filter
    const sort = { modifiedAt: 'desc' };
    const expand = ['user'];
    const embed = [];
    const config = { blockUiRef: this.blockUiRef };

    return this.dataServiceInstance.resourceFullListByBrand(
      this.brand$.getValue(),
      config,
      sort,
      expand,
      embed,
    );
  }

  streamInitTable() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.tap(() => this.initTable()),
      rx.tap(() => this.isTableInitialized$.next(true)),
    )(null);
  }

  streamRefreshTableOnExternalNotice() {
    return rx.pipe(
      () => rx.obs.merge(this.brand$),
      rx.withLatestFrom(this.isTableInitialized$),
      rx.filter(([a, isInit]) => isInit),
      rx.tap(() => this.reloadTable()),
    )(null);
  }
}

export const EmailTemplateContentsLogsTableComponent = {
  template,
  controller: EmailTemplateContentsLogsTableController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
  },
};
