import template from './email-templates-dashboard.component.html';

const styles = require('./email-templates-dashboard.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { Brand } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { switchOn } from '~/source/common/utilities/switch-on';

const viewChangeLogActionTranslateCode =
  'emailTemplates.dashboard.actions.VIEW_CHANGE_LOG';
const viewEmailTemplatesActionTranslateCode =
  'emailTemplates.dashboard.actions.VIEW_EMAIL_TEMPLATES';

enum Section {
  Templates = 'templates',
  Logs = 'logs',
}

export class EmailTemplatesDashboardController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  Section = Section;

  selectedBrand$ = new rx.BehaviorSubject<Brand>(null);
  sectionToShow$ = new rx.BehaviorSubject<Section>(Section.Templates);
  toggleViewButtonLabelCode$ = new rx.BehaviorSubject<string>(
    viewChangeLogActionTranslateCode,
  );
  opToggleMainView$ = new rx.Subject<void>();
  toggleButtonLabelId$: rx.Observable<
    string
  > = this.streamToggleButtonLabelId();

  /*@ngInject */
  constructor() {}

  $onInit() {
    useStreams(
      [
        this.streamToggleMainViews(),
        this.streamCalcToggleButtonText(),
        this.toggleButtonLabelId$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  get selectedBrand() {
    return this.selectedBrand$.getValue();
  }

  set selectedBrand(val: Brand) {
    this.selectedBrand$.next(val);
  }

  streamToggleMainViews() {
    return rx.pipe(
      () => this.opToggleMainView$,
      rx.withLatestFrom(this.sectionToShow$),
      rx.map(([a, sectionToShow]) => {
        if (sectionToShow === Section.Templates) {
          return Section.Logs;
        }

        return Section.Templates;
      }),
      rx.tap((sectionToShow) => this.sectionToShow$.next(sectionToShow)),
    )(null);
  }

  streamToggleButtonLabelId(): rx.Observable<Section> {
    return rx.pipe(
      () => this.sectionToShow$,
      rx.map((sectionToShow) => {
        if (sectionToShow === Section.Templates) {
          return Section.Logs;
        }

        return Section.Templates;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCalcToggleButtonText() {
    return rx.pipe(
      () => this.sectionToShow$,
      rx.map((section) =>
        switchOn(
          {
            [Section.Templates]: () => viewChangeLogActionTranslateCode,
            [Section.Logs]: () => viewEmailTemplatesActionTranslateCode,
          },
          section,
          () => {
            throw new Error('not implemented');
          },
        ),
      ),
      rx.tap((labelCode) => this.toggleViewButtonLabelCode$.next(labelCode)),
    )(null);
  }
}

export const EmailTemplatesDashboardComponent = {
  template,
  controller: EmailTemplatesDashboardController,
};
