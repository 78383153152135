import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import { CustomerComplianceFileType } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '../../models/ielement-rest-ng';
import * as _ from '@proftit/lodash';
import {
  CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED,
  CUSTOMER_COMPLIANCE_DOC_TYPE_CREATED,
} from '../../constants/general-pubsub-keys';

export class ComplianceDockTypesManagementService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'complianceDocTypesManagement';
  }

  /**
   * Get all items.
   *
   * Get all annotate with typing.
   *
   * @returns all items
   */
  getAll() {
    return this.getListWithQuery<
      IElementRestNg<CustomerComplianceFileType>
    >().then((data) => data.plain());
  }

  getBySearch(searchTerm: string) {
    const filter = _.flow([
      (a) => (_.isEmpty(searchTerm) ? a : { ...a, q: searchTerm }),
    ])({});

    return this.filter(filter)
      .getListWithQuery<IElementRestNg<CustomerComplianceFileType>>()
      .then((data) => data.plain());
  }

  /**
   * Update is-visible on item
   *
   * @param id
   * @param isVisible
   * @returns work promise
   */
  updateIsVisible(id: number, isVisible: boolean) {
    return this.patchElement<IElementRestNg<CustomerComplianceFileType>>(id, {
      isVisible,
    })
      .then((result) => result.plain())
      .then((result) => {
        this.prfClientGeneralPubsub.publish(
          CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED,
          result,
        );
        return result;
      });
  }

  /**
   * Update item
   *
   * @param {} id
   * @param {} updates
   * @returns work promise
   */
  updateItem(id: number, updates: Partial<CustomerComplianceFileType>) {
    return this.patchElement<IElementRestNg<CustomerComplianceFileType>>(
      id,
      updates,
    )
      .then((result) => result.plain())
      .then((result) => {
        this.prfClientGeneralPubsub.publish(
          CUSTOMER_COMPLIANCE_DOC_TYPE_UPDATED,
          result,
        );
        return result;
      });
  }

  /**
   * Create item.
   *
   * send selective fields and publish in client the results.
   *
   * @param item
   * @returns work promise
   */
  createItem(item: Partial<CustomerComplianceFileType>) {
    const fields = {
      name: item.name,
      isSystem: item.isSystem,
      isVisible: item.isVisible,
    };

    return this.postWithQuery(fields)
      .then((x) => x.plain())
      .then((x) => {
        this.prfClientGeneralPubsub.publish(
          CUSTOMER_COMPLIANCE_DOC_TYPE_CREATED,
          {
            original: item,
            created: x,
          },
        );
        return x;
      });
  }
}
