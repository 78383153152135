import ng from 'angular';
import settings from './settings.json';
import CountriesFormComponent from './components/countries-form';
import Clearing3dSettingsCreateComponent from './components/create';
import Clearing3dSettingsEditComponent from './components/edit';
import Clearing3dSettingsManageComponent from './components/manage';

const ngModule = ng.module('crm.clearing3dSettings', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

function config($stateProvider, $urlRouterProvider) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/integration/clearing/3d-settings',
    '/crm/management/integration/clearing/3d-settings/list',
  );

  $stateProvider
    .state('crm.management.integration.clearing.3d-settings', {
      url: '/3d-settings',
      template: '<ui-view/>',
    })
    .state('crm.management.integration.clearing.3d-settings.list', {
      url: '/list',
      component: 'prfClearing3dSettingsManage',
      data: {
        permissions: {
          only: ['management.brands.settings_3d'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.integration.clearing.3d-settings.list.table', {
      url: '/:brandId',
    })
    .state('crm.management.integration.clearing.3d-settings.create', {
      url: '/create/:brandId',
      component: 'prfClearing3dSettingsCreate',
      data: {
        permissions: {
          only: ['management.brands.settings_3d_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.integration.clearing.3d-settings.edit', {
      url: '/edit/:threeDSettingsId/:brandId',
      component: 'prfClearing3dSettingsEdit',
      data: {
        permissions: {
          only: ['management.brands.settings_3d_U'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

ngModule
  .config(config)
  .constant('clearing3dModuleSettings', settings)
  .component('prfCountriesForm', CountriesFormComponent)
  .component('prfClearing3dSettingsCreate', Clearing3dSettingsCreateComponent)
  .component('prfClearing3dSettingsEdit', Clearing3dSettingsEditComponent)
  .component('prfClearing3dSettingsManage', Clearing3dSettingsManageComponent);

export default ngModule.name;
