import BaseController from '~/source/common/controllers/base';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
class TradingAccountActionsBarController extends BaseController {
  account: TradingAccount & { statusCode: string; currencyId: number };
  customer: Customer;

  customersServiceInst: CustomersService;

  /*@ngInject*/
  constructor(
    readonly growl: angular.growl.IGrowlService,
    readonly customersService: () => CustomersService,
  ) {
    super();
  }

  $onInit() {
    this.customersServiceInst = this.customersService();
  }

  /**
   * Get the trading account resource for current trading account id and type
   * @return {RestService} RestService instance for trading account resource
   */
  get tradingAccountResource() {
    return this.customersServiceInst
      .getAccountResourceByType(
        this.customer.id,
        this.account.type,
        this.account.id,
      )
      .setConfig({
        blockUiRef: 'tradingAccounts',
        growlRef: 'tradingAccounts',
        errorsTranslationPath: 'contact.errors',
      });
  }

  /**
   * Change account currency to the new selected currency
   * @param {object} newCurrency - new account currency
   * @return {Promise} resolves when the server PATCH is successful
   */
  changeAccountCurrency(newCurrency) {
    return this.tradingAccountResource
      .patchWithQuery({
        currencyId: newCurrency.id,
      })
      .then(() => {
        this.account.currencyId = newCurrency;
        this.account.currency = newCurrency;
      });
  }

  /**
   * Change account status to the new selected status
   * @param {object} newStatus - new account status
   * @return {Promise} resolves when the server PATCH is successful
   */
  changeAccountStatus(newStatus) {
    return this.tradingAccountResource
      .patchWithQuery({
        statusCode: newStatus.code,
      })
      .then(() => {
        this.account.statusCode = newStatus;
      });
  }

  changeAccountIsStatusLocked(isStatusLocked: boolean) {
    return this.tradingAccountResource
      .patchWithQuery({
        isStatusLocked,
      })
      .then(() => {
        this.account.isStatusLocked = isStatusLocked;
      });
  }

  /**
   * Toggles account "demo" status
   *
   * @return {Promise} resolves when the server PATCH is successful
   */
  toggleDemoStatus() {
    if (this.account.isAccountActive) {
      throw new Error('Cannot change status of active account');
    }
    const isDemo = !this.account.isDemo;
    // send patch to server
    return this.tradingAccountResource
      .patchWithQuery({
        isDemo,
      })
      .then(() => {
        this.account.isDemo = isDemo;
        // notification on success
        const msg = this.account.isDemo
          ? 'contact.ACCOUNT_CHANGED_TO_DEMO'
          : 'contact.ACCOUNT_CHANGED_TO_REAL';

        this.growl.success(msg, { referenceId: 'tradingAccounts' as any });
      });
  }

  /**
   * Change account type to the new account type
   * @param {object} tradingAccountType - new account type
   * @return {Promise} resolves when the server PATCH is successful
   */
  changeTradingAccountType(tradingAccountType) {
    return this.tradingAccountResource
      .patchWithQuery({
        tradingAccountTypeId: tradingAccountType.id,
      })
      .then(() => {
        this.account.tradingAccountType = tradingAccountType;
      });
  }
}

export default TradingAccountActionsBarController;
