import template from './system-template-general-form.component.html';
const styles = require('./system-template-general-form.component.scss');

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
// import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { SystemContentTemplate } from '@proftit/crm.api.models.entities';
import { useStreams } from '@proftit/rxjs.adjunct';

export class SystemTemplateGeneralFormController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  model$ = observeShareCompChange<SystemContentTemplate>(
    this.lifecycles.onChanges$,
    'model',
  );

  /* @ngInject */
  constructor() {
    useStreams([this.model$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const SystemTemplateGeneralFormComponent = {
  template,
  controller: SystemTemplateGeneralFormController,
  bindings: {
    model: '<',
  },
};
