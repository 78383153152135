import Component from '~/source/common/components/dropdowns/base/component';

class CommunicationsStatusesController extends Component.controller {
  static $inject = [
    'communicationsStatusesService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'common.SELECT_STATUS';
  }

  /**
   * Disable translations
   *
   * @returns {string} empty
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: CommunicationsStatusesController,
});
