import { SharedGroupFiltersPivot } from '@proftit/crm.api.models.entities';
import { RestService } from '~/source/common/services/rest';

export class SharedGroupFiltersPivotService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'sharedGroupFiltersPivot';
  }

  getFilterData(filterId: number): Promise<SharedGroupFiltersPivot[]> {
    return this.resourceBuildStart()
      .getElement(filterId)
      .resourceBuildEnd()
      .customGetWithConfig({});
  }
}
