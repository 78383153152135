import { PlatformCode } from '@proftit/crm.api.models.enums';

export const brandPlatformMt4GroupsTableColumns = [
  {
    title: 'riskManager.GROUP_NAME',
    field: 'group',
    fieldFormat: '{{ name }}',
    sortable: 'name',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.MARGIN_CALL_LEVEL',
    field: '',
    fieldFormat: '{{ marginCallLevel }}',
    sortable: 'marginCallLevel',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.STOP_OUT_LEVEL',
    field: '',
    fieldFormat: '{{ stopOutLevel }}',
    sortable: 'stopOutLevel',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    // This is empty col for control settings
    renderer: 'actions',
    title: '',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
