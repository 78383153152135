import ng, { IHttpService } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './symbols-exposure.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { CfdPlatformSocketService } from '~/source/management/integrations/risk-manager/services/cfd-platform-socket.service';
import { generateUuid } from '@proftit/general-utilities';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { PlatformExposureSymbolsService } from '~/source/exposure/services/platform-exposure-symbols.service';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';
const styles = require('./symbols-exposure.component.scss');

export class SymbolsExposureController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateUuid();

  shouldShowTable$ = new rx.BehaviorSubject<boolean>(false);

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  socketServiceInstance: CfdPlatformSocketService;

  sessionInfo$: rx.Observable<PlatformSessionInfo>;
  metrics$;

  /* @ngInject */
  constructor(
    readonly prfCfdPlatformSocketService: () => CfdPlatformSocketService,
    readonly $http: IHttpService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly prfPlatformExposureSymbolsService: PlatformExposureSymbolsService,
  ) {
    this.socketServiceInstance = this.prfCfdPlatformSocketService();
  }

  $onInit() {
    this.sessionInfo$ = this.streamSessionInfo();
    this.metrics$ = this.streamMetrics();

    useStreams(
      [
        this.sessionInfo$,
        this.streamInitSocketService(),
        this.metrics$,
        this.shouldShowTable$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamSessionInfo() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((x) => !_.isNil(x)),
      shareReplayRefOne(),
    )(null);
  }

  streamInitSocketService() {
    return rx.pipe(
      () => this.sessionInfo$,
      rx.tap((sessionInfo) => {
        this.socketServiceInstance.setToken(sessionInfo.session.token);
        this.socketServiceInstance.setStreamerUrl(
          sessionInfo.session.streamerUrl,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamMetrics() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamMetricsFromGetRequest(),
          this.streamMetricsFromStreamer(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamMetricsFromGetRequest() {
    return rx.pipe(
      () => this.sessionInfo$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((sessionInfo) => {
        this.shouldShowTable$.next(false);
        const req = this.prfPlatformExposureSymbolsService.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
        return this.prfPlatformExposureSymbolsService.getTotals(req);
      }),
      rx.tap(() => {
        this.shouldShowTable$.next(true);
      }),
      shareReplayRefOne(),
    )(null);
  }

  buildMetricsChannel(platformConnectionId: number): string {
    return `{brand.${platformConnectionId}}.exposure.symbols`;
  }

  streamMetricsFromStreamer() {
    return rx.pipe(
      () => this.sessionInfo$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((sessionInfo) => {
        return observeChannel(
          this.socketServiceInstance,
          this.buildMetricsChannel(sessionInfo.trcBrand.id),
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const SymbolsExposureComponent = {
  template,
  controller: SymbolsExposureController,
  require: {
    prfCurrentPlatformSession: '^',
  },
};
