function calcCombinations(items) {
  if (items.length <= 1) {
    return [items];
  }

  return items.reduce((combinations, item, idx) => {
    const rest = [...items];
    rest.splice(idx, 1);
    const restCombs = calcCombinations(rest);
    const itemCombs = [[item], ...restCombs.map(c => [item, ...c])];
    const itemCombsWithRest = [...itemCombs, ...restCombs];
    return [...combinations, ...itemCombsWithRest];
  }, []);
}

/**
 *
 */
function decimalPlaces(n) {
  // Make sure it is a number and use the builtin number -> string.
  // eslint-disable-next-line
  const s = '' + +n; // Pull out the fraction and the exponent.

  const match = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/.exec(s); // NaN or Infinity or integer.
  // We arbitrarily decide that Infinity is integral.

  if (!match) {
    return 0;
  } // Count the number of digits in the fraction and subtract the
  // exponent to simulate moving the decimal point left by exponent places.
  // 1.234e+2 has 1 fraction digit and '234'.length -  2 == 1
  // 1.234e-2 has 5 fraction digit and '234'.length - -2 == 5


  const fractionLength = match[1] === '0' ? 0 : (match[1] || '').length;
  const exponent = match[2] || 0;
  const calc = fractionLength - exponent;
  return Math.max(0, // lower limit.
  calc);
}

function sumArray(numbers) {
  return numbers.reduce((acc, n) => acc + n, 0);
}

export { calcCombinations, decimalPlaces, sumArray };
