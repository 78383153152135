export const ScrollTopDirective = [
  () => ({
    restrict: 'A',
    link(scope, element, attrs) {
      let scrollTopActionId;
      attrs.$observe('scrollTopActionId', (newValue) => {
        if (newValue !== scrollTopActionId) {
          element.scrollTop(0);
          scrollTopActionId = newValue;
        }
      });
    },
  }),
];
