import { IHttpService } from 'angular';
import Url from 'url-parse';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  addHeader,
  getAsOne,
  getAsList,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  create as genReqCreate,
  appendResource,
  setResponseType,
  setTokenHeader,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { removeColonAtEnd } from '@proftit/general-utilities';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';
import { BrandsService } from '~/source/management/brand/services/brands';
import download from 'downloadjs';

const RESOURCE_PATH = 'exposure';
export const SYMBOLS_RESOURCE_PATH = 'symbols';
export const POSITIONS_RESOURCE_PATH = 'positions';
export const TOTALS_RESOURCE_PATH = 'totals';
export const FILE_PATH = 'file';

export class PlatformExposureSymbolsService {
  /*@ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  initReq(reqParam, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      () => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(RESOURCE_PATH, req),
    ])(reqParam);
  }

  initPublicReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReqWithSession(session: PlatformSessionInfo) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, session.session.apiUrl),
      (req) => setTokenHeader(session.session.token, req),
    ])();
  }

  getAsOne(id: number, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  getSymbolsData(req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResource(SYMBOLS_RESOURCE_PATH, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  getTotals(req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResource(SYMBOLS_RESOURCE_PATH, req),
        (req) => appendResource(TOTALS_RESOURCE_PATH, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  exportSymbolsReport(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => setResponseType('blob', req),
      (req) => appendResource(SYMBOLS_RESOURCE_PATH, req),
      (req) => appendResource(FILE_PATH, req),
      (req) => addHeader('Accept', '*/*', req),
      (req) => genReqCreate(req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest(this.$http, req),
      (req) =>
        req.then((fileData) => {
          const fileName = 'symbols.xlsx';
          return download(
            fileData.data,
            fileName,
            fileData.headers('content-type'),
          );
        }),
    ])();
  }
}
