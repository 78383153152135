import { generateUuid } from '@proftit/general-utilities';
import BaseController from '~/source/common/controllers/base';

import template from './user-brand-voip-form.html';

class ComponentController extends BaseController {
  compId = generateUuid();
}

const UserBrandVoipFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    voipConfiguration: '<',
    voipProvider: '<',
    onVoipConfigurationChange: '&',
  },
};

export default UserBrandVoipFormComponent;
