import BaseController from '~/source/common/controllers/base';
import template from './trading-account-heading.html';
import { TradingAccount } from '@proftit/crm.api.models.entities';

const styles = require('./trading-account-heading.component.scss');

class TradingAccountHeadingController extends BaseController {
  styles = styles;
  account: TradingAccount;
  saveNameRequested: Function;

  prevName: string;
  isEdit = false;

  enterEditMode() {
    this.prevName = this.account.name;
    this.isEdit = true;
  }

  cancelEditMode() {
    this.account.name = this.prevName;
    this.isEdit = false;
  }

  updateRequested() {
    if (this.account.name !== this.prevName) {
      this.saveNameRequested({ name: this.account.name });
    }

    this.isEdit = false;
  }
}

const Component = {
  template,
  controller: TradingAccountHeadingController,
  bindings: {
    account: '<',
    saveNameRequested: '&',
  },
};

export default Component;
