import template from './attachments-management-dashboard.component.html';
const styles = require('./attachments-management-dashboard.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class AttachmentsManagementDashboardController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const AttachmentsManagementDashboardComponent = {
  template,
  controller: AttachmentsManagementDashboardController,
};
