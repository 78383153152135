import RestService from '~/source/common/services/rest';
import TokensService from '~/source/auth/services/tokens';
import { CommunicationTypesService } from '../../services/communication-types.service';
import { CommunicationMethodCode } from '@proftit/crm.api.models.enums';
import * as _ from '@proftit/lodash';

export class CommunicationsService extends RestService {
  static $inject = [
    'tokensService',
    'communicationTypesService',
    ...RestService.$inject,
  ];

  tokensService: TokensService;
  communicationTypesService: CommunicationTypesService;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'communications';
  }

  /**
   * Get the current user's active call (if any)
   * If for some reason the server will return more than one, the first one will be used.
   *
   * @return {Promise} resolves to active call's object or undefined if no call is active
   */
  getActiveCall() {
    const currentUser = this.tokensService.getCachedUser();

    return this.communicationTypesService.getCommTypeCall().then((typeCall) => {
      return (
        this.filter({
          isActive: true,
          userId: currentUser.id,
          typeId: typeCall.id,
          method: CommunicationMethodCode.Voip,
        })
          .slice(0, 1, 1)
          .sort('date', 'asc')
          .expand([
            'customer',
            'customer.country',
            'communicationStatus',
            'communicationSubject',
          ])
          .getListWithQuery()
          // there can only be one active call at a time. get it (or undefined)
          .then((calls) => calls[0])
      );
    });
  }

  getLastCallForBrand(brandId: number, userId: number) {
    return this.communicationTypesService.getCommTypeCall().then((typeCall) => {
      return this.expand(['customer'])
        .sort('date', 'desc')
        .slice(0, 1, 1)
        .filter({
          userId,
          typeId: typeCall.id,
          method: CommunicationMethodCode.Voip,
          'customer.brandId': brandId,
        })
        .getListWithQuery()
        .then((calls) => {
          if (calls.length > 0) {
            return calls[0];
          }
          return null;
        });
    });
  }
}

export default CommunicationsService;
