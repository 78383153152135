import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';

import Url from 'url-parse';

import download from 'downloadjs';
import { downloadLocalFileUrl } from '@proftit/dom-utilities';

import { CfdGroup } from '@proftit/tradingcore.api.models.entities';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  addHeader,
  getAsOne,
  getAsList,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  create as genReqCreate,
  appendResource,
  setResponseType,
  setTokenHeader,
  jsonServerToCfdMongo,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

import { removeColonAtEnd } from '@proftit/general-utilities';

const RESOURCE_PATH = 'Currencies';

export class ApiTrcCurrenciesService {
  /* @ngInject */
  constructor(readonly $http: IHttpService) {}

  initReq(reqParam, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      () => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(RESOURCE_PATH, req),
    ])(reqParam);
  }

  getAllCurrencies(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => jsonServerToCfdMongo('', req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest(this.$http, req),
      (req) => req.then((resp: { data }) => resp.data),
    ])();
  }
}
