import template from './deposit-attach-document-button.component.html';

const styles = require('./deposit-attach-document-button.component.scss');

import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { useStreams } from '@proftit/rxjs.adjunct';
import {
  observeUploadFile,
  ProgressReportType,
} from '~/source/common/utilities/observe-upload-file';
import { FilesUploadService } from '~/source/common/services/files-upload';
import CustomersService from '~/source/contact/common/services/customers';
import log from 'loglevel';

interface File {}

export class DepositAttachDocumentButtonController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customerId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'customerId',
  );
  accountId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'accountId',
  );
  depositId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'depositId',
  );

  attachDocumentAction = new rx.Subject<File>();

  /*@ngInject */
  constructor(
    readonly filesUploadService: FilesUploadService,
    readonly customersService: () => CustomersService,
  ) {
    useStreams(
      [
        this.customerId$,
        this.accountId$,
        this.depositId$,
        this.streamAttachDocumentToDeposit(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamAttachDocumentToDeposit() {
    return rx.pipe(
      () => this.attachDocumentAction,
      rx.switchMap((file) =>
        observeUploadFile(() =>
          this.filesUploadService.uploadPrivateFile(file),
        ).pipe(
          rx.catchError((err, caught) => {
            log.error('error uploading file:', err);

            return rx.obs.EMPTY;
          }),
        ),
      ),
      rx.filter(
        ({ reportType }) => reportType === ProgressReportType.Completed,
      ),
      rx.map(({ file }) => file.id),
      rx.withLatestFrom(this.customerId$, this.accountId$, this.depositId$),
      rx.switchMap(([fileId, customerId, accountId, depositId]) => {
        return rx.obs
          .from(
            this.customersService().attachFileToDeposit(
              customerId,
              accountId,
              depositId,
              fileId,
            ),
          )
          .pipe(
            rx.catchError((err, caught) => {
              log.error('error attaching file to deposit:', err);

              return rx.obs.EMPTY;
            }),
          );
      }),
    )(null);
  }
}

export const DepositAttachDocumentButtonComponent = {
  template,
  controller: DepositAttachDocumentButtonController,
  bindings: {
    customerId: '<',
    accountId: '<',
    depositId: '<',
    buttonId: '<',
  },
};
