import Component from './base/component';
import { User } from '@proftit/crm.api.models.entities';
import { fetchAllWithPaging } from '~/source/common/utilities/fetch-all-with-paging';

const PAGE_FETCH_SIZE = 500;

class BonusProcessByUserSelectController extends Component.controller {
  static $inject = ['usersService', ...Component.controller.$inject];

  isActive: boolean;

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return fetchAllWithPaging(
      PAGE_FETCH_SIZE,
      (page, size) => this.queryPageStep(page, size),
      () => this.dataServiceInst.total,
    );
  }

  queryPageStep(page: number, size: number) {
    const filters = {
      isActive: this.isActive === undefined ? true : this.isActive,
      'role.code': {
        exclude: 'extapi',
      },
    };

    return this.dataServiceInst
      .expand('role')
      .setConfig({ blockUiRef: `dropdown_${this.dropdownId}` })
      .setPage(page, size)
      .filter(filters)
      .getListWithQuery()
      .then((users) => {
        users.forEach((user: User & { name: string }) => {
          user.name = `${user.firstName} ${user.lastName}`;
        });
        return users;
      });
  }

  get defaultPlaceholder() {
    return 'user.SELECT_USER';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Add nullable element, by selecting it, we will be able to
   * filter unassigned customers
   *
   * @returns {{id: null, name: string, firstName: string}}
   */
  get nullableElement() {
    return {
      id: null,
      name: 'System',
      firstName: 'System',
    };
  }
}

export const BonusProcessByUserSelectComponent = Component.config({
  controller: BonusProcessByUserSelectController,
  bindings: {
    isActive: '<',
  },
});
