import template from './email-provider-form.component.html';
const styles = require('./email-provider-form.component.scss');

import ng, { IScope } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { FilesUploadService } from '~/source/common/services/files-upload';
import { CommunicationProviderTypeCode } from '@proftit/crm.api.models.enums';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams } from '@proftit/rxjs.adjunct';

export class EmailProviderFormController {
  onUploadFile: (a: { file; fieldCode }) => void;
  onFailedFileSelection: () => void;
  fileValidationErrors = new FormControl(null);

  styles = styles;
  blockUiId = generateBlockuiId();
  lifecycles = observeComponentLifecycles(this);
  CommunicationProviderTypeCode = CommunicationProviderTypeCode;

  /*@ngInject */
  constructor(
    readonly blockUI: any,
    readonly filesUploadService: FilesUploadService,
  ) {}

  $onInit() {
    useStreams([this.streamFileValidationErrors()], this.lifecycles.onDestroy$);
  }

  streamFileValidationErrors() {
    return rx.pipe(
      () => this.fileValidationErrors.value$ as rx.Observable<any[]>,
      rx.filter((value) => !_.isNil(value)),
      rx.filter((array) => array.length > 0),
      rx.tap((array) => {
        const erroredOnPattern = array.some(
          (file) => (file.$error = 'pattern'),
        );
        if (erroredOnPattern) {
          this.onFailedFileSelection();
        }
      }),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}

  upload(file, fieldCode) {
    if (!file) {
      return;
    }
    this.onUploadFile({ file, fieldCode });
  }
}

export const EmailProviderFormComponent = {
  template,
  controller: EmailProviderFormController,
  bindings: {
    isEdit: '<',
    brand: '<',
    formFieldCredentials: '<',
    testConnectionEmail: '<',
    gmailConfigurationFileUpdatedAt: '<',
    onTestConnectionAction: '&',
    onUploadFile: '&',
    onFailedFileSelection: '&',
  },
};
