import { PlatformTypeCode } from '@proftit/crm.api.models.enums';
import { NotificationSettingCategoryCode, NotificationSettingTypeCode } from '@proftit/notification-setting.api.enums';

const NotificationSettingTypeTable = [{
  DESCRIPTION: 'NEW_CONTACT',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_ASSIGNED_USER',
  category: NotificationSettingCategoryCode.NewContact,
  code: NotificationSettingTypeCode.Signup,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'ASSIGNMENTS_TEXT',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_ASSIGNED_USER',
  category: NotificationSettingCategoryCode.Assignments,
  code: NotificationSettingTypeCode.Assignments,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'ALERT',
  DESCRIPTION_POST: 'MINUTES_BEFORE',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.FollowUps,
  code: NotificationSettingTypeCode.FollowUps,
  emailNotifications: [],
  inputType: 'minutesSelect',
  isActive: false,
  isEmailNotificationSupported: false,
  isSystemNotificationSupportedForNotificationSetting: false,
  platformType: null,
  roleNotifications: [],
  value: '0'
}, {
  DESCRIPTION: 'CONTACT_IS_ONLINE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.ContactIsOnline,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'INVESTMENT_ABOVE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.InvestmentAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Binary,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: '0'
}, {
  DESCRIPTION: 'MARGIN_LEVEL_ABOVE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.MarginLevelAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: '0'
}, {
  DESCRIPTION: 'MARGIN_LEVEL_REACHED',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.MarginLevelReached,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: '0'
}, {
  DESCRIPTION: 'MARGIN_STOP_OUT',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.MarginStopOut,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: '0'
}, {
  DESCRIPTION: 'TOTAL_OPEN_VOLUME_ABOVE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.TotalOpenVolumeAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: '0'
}, {
  DESCRIPTION: 'TOTAL_TRADING_ACCOUNT_PNL_ABOVE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.TotalTradingAccountPnLAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'TOTAL_TRADING_ACCOUNT_PNL_BELOW',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.TotalTradingAccountPnLBelow,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: [],
  validator: 'nonePositiveDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'TOTAL_TURNOVER_ABOVE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.TotalTurnoverAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Binary,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'TRADE_SIZE_ABOVE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.TradingActivity,
  code: NotificationSettingTypeCode.TradeSizeAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'APPROVED_BONUSES',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.ApprovedBonuses,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Binary,
  roleNotifications: []
}, {
  DESCRIPTION: 'APPROVED_CREDIT',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.ApprovedCredit,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: []
}, {
  DESCRIPTION: 'APPROVED_WITHDRAWALS',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.ApprovedWithdrawals,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'CANCELED_WITHDRAWALS',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.CanceledWithdrawals,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'DEPOSIT_APPROVED_AMOUNT_RANGE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.DepositApprovedAmountAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Binary,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'REQUESTED_DEPOSIT',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.RequestedDeposit,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'FAILED_DEPOSIT_AMOUNT_RANGE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.FailedDepositAmountAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'DEPOSIT_APPROVED_AMOUNT_RANGE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.TransactionsApprovedAmountAbove,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: [],
  validator: 'noneNegativeDecimalNumber',
  value: 0
}, {
  DESCRIPTION: 'TURNOVER_GOAL_REACHED',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.TurnoverGoalReached,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Binary,
  roleNotifications: []
}, {
  DESCRIPTION: 'VOLUME_GOAL_REACHED',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.VolumeGoalReached,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: PlatformTypeCode.Forex,
  roleNotifications: []
}, {
  DESCRIPTION: 'WITHDRAWAL_REQUESTS',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Transactions,
  code: NotificationSettingTypeCode.WithdrawalRequests,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'ATTACHMENT_UPLOADED_ALERT',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Verification,
  code: NotificationSettingTypeCode.RegulationFiles,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'VERIFICATION_STATUS_CHANGE',
  DESCRIPTION_POST: '',
  SEND_EMAIL: 'SEND_EMAIL_TO_DESK_MANAGERS',
  category: NotificationSettingCategoryCode.Verification,
  code: NotificationSettingTypeCode.Verification,
  emailNotifications: [],
  inputType: '',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: []
}, {
  DESCRIPTION: 'DOCUMENT_EXPIRY',
  DESCRIPTION_POST: 'DAYS_BEFORE',
  SEND_EMAIL: 'SEND_EMAIL_TO_ASSIGNED_USER',
  category: NotificationSettingCategoryCode.Verification,
  code: NotificationSettingTypeCode.FileExpiry,
  customClass: 'prf-notification-settings__label--wide',
  emailNotifications: [],
  inputType: 'input',
  isActive: false,
  isEmailNotificationSupported: true,
  isSystemNotificationSupportedForNotificationSetting: true,
  platformType: null,
  roleNotifications: [],
  value: 10
}];

export { NotificationSettingTypeTable };
