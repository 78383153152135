export const tableColumns = [
  {
    title: 'brandAccountStatusAutomationAuditLogTable.MODIFIED_DATE',
    field: '',
    id: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandAccountStatusAutomationAuditLogTable.CHANGED_BY',
    field: '',
    id: 'user',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandAccountStatusAutomationAuditLogTable.PREVIOUS_VALUES',
    field: '',
    id: 'data',
    fieldFormat: '{{ _dataAsPrintable }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandAccountStatusAutomationAuditLogTable.IP',
    field: '',
    id: 'ip',
    fieldFormat: '{{ userIp }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
