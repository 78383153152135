import Component from '~/source/common/components/dropdowns/base/component';

class CommunicationsSubjectSelect extends Component.controller {
  static $inject = [
    'communicationsSubjectsService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'common.SELECT_SUBJECT';
  }

  /**
   * Path to translation
   *
   * @returns {string} translation path
   */
  get translationPath() {
    return 'contact.communicationSubjects';
  }

  /**
   * Name of the property that should be translated
   *
   * Override default translate source 'code' because call subject
   * is now a dynamic list, that can be changed by the user. Therefore
   * some of its values will not have translations in the contants language
   * file. Therefore, translation is not applicable for this kind of list.
   * We will show 'name' value instead.
   *
   * @returns {string}
   */
  get translateSource(): string {
    return null;
  }
}

export default Component.config({ controller: CommunicationsSubjectSelect });
