import template from './email-templates-section-templates.component.html';
const styles = require('./email-templates-section-templates.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { Brand } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import { permissionCreateFor } from '~/source/common/utilities/permission-create-for';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

export class EmailTemplatesSectionTemplatesController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  brand: Brand;
  PermissionNormalized = PermissionNormalized;
  permissionCreateFor = _.memoize(permissionCreateFor);

  /*@ngInject */
  constructor(readonly modalService: ModalService) {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  openCreateEmailTemplateDesignDialog() {
    this.modalService.open({
      component: 'prfDesignTemplateDialog',
      resolve: {
        action: () => 'create',
      },
    });
  }
}

export const EmailTemplatesSectionTemplatesComponent = {
  template,
  controller: EmailTemplatesSectionTemplatesController,
  bindings: {
    brand: '<',
  },
};
