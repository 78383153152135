import log from 'loglevel';
import BaseController from '~/source/common/controllers/base';
import UsersService from '~/source/management/user/services/users';
import TokensService from '~/source/auth/services/tokens';
import { Customer, User, VoipProvider } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import template from './click-to-call.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { calcLastCallTooltip } from './calc-last-call-tooltip';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { CommunicationsService } from '~/source/common/components/call-manager/communications.service';
import { ArrowDirection } from '~/source/contact/common/dropdown-arrow/dropdown-arrow.component';
import { moveToFirst } from '~/source/common/utilities/strucutre-transform/move-to-first';
import { VoipProvidersService } from '~/source/common/services/voip-providers';
import {
  CustomerPhoneNumber,
  getAllFullNumbersForCustomer,
} from '~/source/common/utilities/customer-phone-number/get-all-full-numbers-for-customer';
import BrandsService from '~/source/management/brand/services/brands';
import { CommunicationTypeCode } from '@proftit/crm.api.models.enums';
import { Communication } from '~/source/common/models/communication';
import { CommunicationTypesService } from '~/source/common/services/communication-types.service';
import { CustomerStoreProviderController } from '~/source/contact/common/components/customer-store-provider/customer-store-provider.component';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';

const styles = require('./click-to-call.scss');

export interface DidNumber {
  id: string;
  name: string;
  country?: {
    code: string;
    name: string;
  };
}

const CLICK_CALL_DEBOUNCE_TIME = 1000;

export class Controller extends BaseController {
  static $inject = [
    'customersService',
    'tokensService',
    'usersService',
    'blockUI',
    'communicationsService',
    'voipProvidersService',
    'brandsService',
    'communicationTypesService',
    'prfCurrentUserStore',
  ];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  ArrowDirection = ArrowDirection;

  prfCustomerStoreProvider: CustomerStoreProviderController;
  usersService: () => UsersService;
  tokensService: TokensService;
  customersService: () => CustomersService;
  voipProvidersService: VoipProvidersService;
  communicationTypesService: CommunicationTypesService;
  brandsService: () => BrandsService;
  prfCurrentUserStore: CurrentUserStoreService;
  blockUI;

  customerInstance: CustomersService;
  communicationsService: CommunicationsService;
  enableCallButton: boolean;
  customer: Customer;

  blockUiId = generateBlockuiId();
  blockUiInstance;

  voipProviderSelectAction = new rx.Subject<VoipProvider>();
  numberToCallToSelectAction = new rx.Subject<{ id: number; number: string }>();
  onNewDidsAction = new rx.Subject<{ dids: any[] }>();
  startCallFromPhoneClickAction$ = new rx.Subject<number>();

  lastCallNumber$ = new rx.BehaviorSubject<string>('');
  lastCallTooltip$ = new rx.BehaviorSubject<string>('');
  lastVoipCommunication$ = new rx.BehaviorSubject<any>({});
  selectedNumber$ = new rx.BehaviorSubject<DidNumber>(null);
  disableLastCallTooltip$ = new rx.BehaviorSubject<boolean>(true);
  opStartCall$ = new rx.Subject<number>();
  opCallWasMade$ = new rx.Subject<void>();
  isClickDisabled$ = new rx.BehaviorSubject<boolean>(false);
  didsBS$ = new rx.BehaviorSubject<any[]>([]);

  toggleIsDidsListOpenAction$ = new rx.Subject<void>();
  closeDidsListAction$ = new rx.Subject<void>();
  isDidsListOpen$: rx.Observable<boolean>;
  dropdownArrowDirection$;

  communicationTypes$;
  voipProvidersForBrand$;
  lastUsedVoipProviderForCustomer$;
  voipProvidersToDisplay$;
  selectedVoipProvider$;
  customerPhones$;
  selectedNumberToCallTo$;

  constructor(...args) {
    super(...args);

    useStreams(
      [
        observeCompChange<boolean>(
          this.disableLastCallTooltip$,
          'disableLastCallTooltip',
          this.lifecycles.onChanges$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.isDidsListOpen$ = this.streamIsDidsListOpen();
    this.dropdownArrowDirection$ = this.streamDropdownArrowDirection();
    this.communicationTypes$ = this.streamCommunicationTypes();
    this.voipProvidersForBrand$ = this.streamVoipProvidersForBrand();
    this.lastUsedVoipProviderForCustomer$ = this.streamLastVoipProviderUsedForCustomer();
    this.voipProvidersToDisplay$ = this.streamVoipProvidersToDisplay();
    this.selectedVoipProvider$ = this.streamSelectedVoipProvider();
    this.customerPhones$ = this.streamCustomerPhones();
    this.selectedNumberToCallTo$ = this.streamSelectedNumberToCallTo();

    useStreams(
      [
        this.streamCalcSelectedNumber(),
        this.streamCalcSelectTooltip(),
        this.streamStartCall(),
        this.streamStartCallFromPhoneClick(),
        this.streamDidsFromChild(),
        this.communicationTypes$,
        this.toggleIsDidsListOpenAction$,
        this.isDidsListOpen$,
        this.dropdownArrowDirection$,
        this.voipProvidersForBrand$,
        this.lastUsedVoipProviderForCustomer$,
        this.voipProvidersToDisplay$,
        this.voipProviderSelectAction,
        this.selectedVoipProvider$,
        this.customerPhones$,
        this.selectedNumberToCallTo$,
        this.didsBS$,
        this.lastVoipCommunication$,
      ],
      this.lifecycles.onDestroy$,
    );

    this.customerInstance = this.customersService();
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);

    this.usersService()
      .isVoipEnabledForUser(
        this.tokensService.getCachedUser().id as number,
        this.customer.brand.id,
      )
      .then((isVoipEnabled) => (this.enableCallButton = isVoipEnabled));
  }

  $onDestroy() {}

  streamCommunicationTypes() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(this.communicationTypesService.getListWithQuery())
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.map((val) => val.plain()),
      shareReplayRefOne(),
    )(null);
  }

  streamDidsFromChild() {
    return rx.pipe(
      () => this.onNewDidsAction,
      rx.tap(({ dids }) => {
        this.didsBS$.next(dids);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamVoipProvidersForBrand() {
    return rx.pipe(
      () => this.prfCustomerStoreProvider.customerStoreService.customer$,
      rx.filter((customer) => !_.isNil(customer.brand.voipCredentials)),
      rx.map((customer) => {
        return customer.brand.voipCredentials.map((c) => {
          return {
            ...c.voipProvider,
            isDefault: c.isDefault,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLastVoipProviderUsedForCustomer() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.prfCustomerStoreProvider.customerStoreService.customer$,
          this.isDidsListOpen$,
          this.communicationTypes$,
        ),
      rx.filter((x) => x.every((item) => !_.isNil(item))),
      rx.filter(([x, isDidsListOpen, communicationTypes]) => isDidsListOpen),
      rx.switchMap(([customer, x, communicationTypes]) => {
        // get the customer's last call.
        return rx.obs
          .from(
            this.customersService()
              .getCommunicationsResource(customer.id)
              .expand(['provider'])
              .filter({
                typeId: (communicationTypes as any[]).find(
                  (cType) => cType.code === CommunicationTypeCode.Call,
                ).id,
              })
              .slice(0, 1, 1)
              .sort({ date: 'desc' })
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.filter((callData) => callData.length > 0),
      rx.map(
        ([lastCustomerCommunication]) => lastCustomerCommunication.provider,
      ),
      rx.startWith(null),
      shareReplayRefOne(),
    )(null);
  }

  streamVoipProvidersToDisplay() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.voipProvidersForBrand$,
          this.lastUsedVoipProviderForCustomer$,
          this.prfCustomerStoreProvider.customerStoreService.customer$,
          this.prfCurrentUserStore.userWithVoipConfigurations$,
        ),
      rx.filter(
        ([voipProvidersForBrand, b, customer, user]) =>
          !_.isNil(voipProvidersForBrand) &&
          !_.isNil(customer) &&
          !_.isNil(user),
      ),
      rx.map(
        ([
          voipProvidersForBrand,
          lastUserVoipProviderForCustomer,
          customer,
          user,
        ]: [any[], VoipProvider, Customer, User]) => {
          let filteredVoips;
          // show isDefault first
          if (_.isNil(lastUserVoipProviderForCustomer)) {
            filteredVoips = moveToFirst(
              (item) => item.isDefault,
              voipProvidersForBrand,
            );
            return {
              customer,
              user,
              voips: filteredVoips,
            };
          }

          // show lastUserVoipProviderForCustomer first
          const voipProvidersWithoutLastUsed = voipProvidersForBrand.filter(
            (x) => x.id !== lastUserVoipProviderForCustomer.id,
          );
          filteredVoips = [
            lastUserVoipProviderForCustomer,
            ...voipProvidersWithoutLastUsed,
          ];
          return {
            customer,
            user,
            voips: filteredVoips,
          };
        },
      ),
      rx.map(({ customer, voips, user }) => {
        const { brand } = customer;
        const relevantBrandConfigForUser = user.brands.find(
          (b) => b.id === brand.id,
        );
        if (_.isNil(relevantBrandConfigForUser)) {
          return [];
        }
        const voipsWithUserExtension = (relevantBrandConfigForUser as any).voipConfigurations.filter(
          (voipConfig) =>
            !_.isNil(voipConfig.extension) &&
            String(voipConfig.extension).length > 0,
        );
        const voipsWithUserExtensionProviderIds = voipsWithUserExtension.map(
          (v) => v.providerId,
        );
        return voips.filter((voip) => {
          return voipsWithUserExtensionProviderIds.includes(voip.id);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedVoipProvider() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSelectedVoipProviderFromInit(),
          this.streamSelectedVoipProviderFromLastCommunication(),
          this.streamSelectedVoipProviderFromUserSelect(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedVoipProviderFromLastCommunication() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.voipProvidersToDisplay$,
          this.lastVoipCommunication$.pipe(rx.distinctUntilKeyChanged('id')),
        ),
      rx.filter(
        ([voipProviders, lastVoipComm]) =>
          !_.isNil(voipProviders) && !_.isNil(lastVoipComm),
      ),
      rx.map(([voipProviders, lastVoipComm]) => {
        return (voipProviders as any[]).find((vp) => {
          return vp.id === lastVoipComm.providerId;
        });
      }),
      rx.filter((x) => !_.isNil(x)),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedVoipProviderFromInit() {
    return rx.pipe(
      () => this.voipProvidersToDisplay$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((voipProviders) => voipProviders[0]),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomerPhones() {
    return rx.pipe(
      () => this.prfCustomerStoreProvider.customerStoreService.customer$,
      rx.map((customer: Customer) => getAllFullNumbersForCustomer(customer)),
      rx.map((phones: CustomerPhoneNumber[]) =>
        phones.map((p, i) => {
          return {
            ...p,
            id: i,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedNumberToCallTo() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamPreselectFirstNumberToCallToOnInit(),
          this.streamSelectedNumberToCallToFromUserSelect(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamPreselectFirstNumberToCallToOnInit() {
    return rx.pipe(
      () => this.customerPhones$,
      rx.map((phones) => phones[0]),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedNumberToCallToFromUserSelect() {
    return rx.pipe(
      () => this.numberToCallToSelectAction,
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedVoipProviderFromUserSelect() {
    return rx.pipe(
      () => this.voipProviderSelectAction,
      rx.filter((x) => !_.isNil(x)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsDidsListOpen() {
    const lastState = new rx.BehaviorSubject<boolean>(false);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamIsDidsListOpenFromToggle(lastState),
          this.streamIsDidsListOpenFromClose(),
        ),
      rx.startWith(false),
      rx.tap((newValue: boolean) => lastState.next(newValue)),
      shareReplayRefOne(),
    )(null);
  }

  streamIsDidsListOpenFromToggle(lastState: rx.BehaviorSubject<boolean>) {
    return rx.pipe(
      () => this.toggleIsDidsListOpenAction$,
      rx.withLatestFrom(lastState),
      rx.map(([a, lastState]) => !lastState),
      shareReplayRefOne(),
    )(null);
  }

  streamIsDidsListOpenFromClose() {
    return rx.pipe(
      () => this.closeDidsListAction$,
      rx.map(() => false),
      shareReplayRefOne(),
    )(null);
  }

  streamDropdownArrowDirection() {
    return rx.pipe(
      () => this.isDidsListOpen$,
      rx.map((isListOpen) =>
        isListOpen ? this.ArrowDirection.UP : this.ArrowDirection.DOWN,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamGetLastVoipComm() {
    return rx.pipe(
      () => this.isDidsListOpen$,
      rx.filter((isDidsListOpen) => isDidsListOpen),
      rx.switchMap(() =>
        rx.obs.from(
          this.customerInstance
            .setConfig({ blockUiRef: this.blockUiId })
            .getLastCall(this.customer.id),
        ),
      ),
      rx.switchMap((lastVoipCommunication) => {
        if (!_.isNil(lastVoipCommunication)) {
          return Promise.resolve(lastVoipCommunication);
        }
        return this.communicationsService
          .getLastCallForBrand(
            this.customer.brand.id,
            this.tokensService.getCachedUser().id as number,
          )
          .catch((e) => null);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamGetLastVoipCommForInstantCall() {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.switchMap(() =>
        rx.obs.from(
          this.customerInstance
            .setConfig({ blockUiRef: this.blockUiId })
            .getLastCall(this.customer.id),
        ),
      ),
      rx.switchMap((lastVoipCommunication) => {
        if (!_.isNil(lastVoipCommunication)) {
          return Promise.resolve(lastVoipCommunication);
        }
        return this.communicationsService
          .getLastCallForBrand(
            this.customer.brand.id,
            this.tokensService.getCachedUser().id as number,
          )
          .catch((e) => null);
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * start last call stream : get list of calls and filter the last call made
   * @returns {void}
   */
  streamCalcSelectedNumber() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.obs.merge(this.lifecycles.onInit$, this.opCallWasMade$),
          this.didsBS$,
        ),
      rx.withLatestFrom(this.disableLastCallTooltip$),
      rx.switchMap(() => this.streamGetLastVoipComm()),
      rx.tap((lastVoipCommunication) => {
        if (!_.isNil(lastVoipCommunication)) {
          this.lastVoipCommunication$.next(lastVoipCommunication);
          this.lastCallNumber$.next(lastVoipCommunication.userPhoneNum);
        }
      }),
      rx.map((callNumber) => {
        if (!callNumber) {
          return null;
        }

        const didNumberValues = this.didsBS$.value.map((didObj) => {
          const { did } = didObj;
          return did.value;
        });

        if (!didNumberValues.includes(callNumber)) {
          return null;
        }

        return {
          name: callNumber,
          id: callNumber,
        };
      }),
      rx.tap((callNumber) => this.selectedNumber$.next(callNumber)),
    )(null);
  }

  /**
   * Stream - calc select tooltip
   * @return stream observable
   */
  streamCalcSelectTooltip() {
    return rx.pipe(
      () => this.lastCallNumber$,
      rx.map((lastCallNumber) => calcLastCallTooltip(lastCallNumber)),
      rx.tap((tooltipText) => this.lastCallTooltip$.next(tooltipText)),
    )(null);
  }

  streamStartCallFromPhoneClick() {
    return rx.pipe(
      () => this.startCallFromPhoneClickAction$,
      rx.withLatestFrom(this.isClickDisabled$),
      rx.filter(([customerId, isClickDisabled]) => !isClickDisabled),
      rx.tap(() => {
        this.isClickDisabled$.next(true);
        if (!this.blockUiInstance.isBlocking()) {
          this.blockUiInstance.start();
        }
      }),
      rx.debounceTime(CLICK_CALL_DEBOUNCE_TIME),
      rx.switchMap(() => this.streamGetLastVoipCommForInstantCall()),
      rx.withLatestFrom(
        this.selectedNumberToCallTo$,
        this.voipProvidersToDisplay$,
        this.prfCustomerStoreProvider.customerStoreService.customer$,
      ),
      rx.tap(() => {
        this.closeDidsListAction$.next();
      }),
      rx.switchMap(
        ([
          lastVoipComm,
          selectedNumberToCallTo,
          voipProvidersToDisplay,
          customer,
        ]: [Communication, any, VoipProvider[], Customer]) => {
          const { userPhoneNum, providerId } = lastVoipComm;
          const providerIdToUse = !_.isNil(providerId)
            ? providerId
            : voipProvidersToDisplay[0].id;
          return rx.obs
            .from(
              this.customerInstance
                .setConfig({
                  blockUiRef: this.blockUiId,
                  growlRef: 'globalMessages',
                })
                .makeVoipCall(
                  customer.id,
                  providerIdToUse,
                  userPhoneNum,
                  selectedNumberToCallTo.isPrimary,
                ),
            )
            .pipe(
              rx.catchError((err) => {
                log.error('error performing call', err);
                return rx.obs.from([null]);
              }),
            );
        },
      ),
      rx.tap(() => this.opCallWasMade$.next()),
      rx.tap(() => {
        this.isClickDisabled$.next(false);
        if (this.blockUiInstance.isBlocking()) {
          this.blockUiInstance.stop();
        }
      }),
      shareReplayRefOne(),
    )(null) as rx.Observable<any>;
  }

  /**
   * Stream - start a call
   * @return stream observable
   */
  streamStartCall() {
    return rx.pipe(
      () => this.opStartCall$,
      rx.withLatestFrom(this.isClickDisabled$),
      rx.filter(([customerId, isClickDisabled]) => !isClickDisabled),
      rx.map(([customerId, isClickDisabled]) => customerId),
      rx.tap(() => {
        this.isClickDisabled$.next(true);
        if (!this.blockUiInstance.isBlocking()) {
          this.blockUiInstance.start();
        }
      }),
      rx.debounceTime(CLICK_CALL_DEBOUNCE_TIME),
      rx.withLatestFrom(
        this.selectedNumber$.pipe(
          rx.map((didNumber) => _.get(['id'], didNumber)),
        ),
        this.selectedVoipProvider$,
        this.selectedNumberToCallTo$,
      ),
      rx.tap(() => {
        this.closeDidsListAction$.next();
      }),
      rx.switchMap(
        ([
          customerId,
          selectedNumber,
          selectedVoipProvider,
          selectedCustomerPhoneNumberObj,
        ]: [number, string, VoipProvider, any]) => {
          return rx.obs
            .from(
              this.customerInstance
                .setConfig({
                  blockUiRef: this.blockUiId,
                  growlRef: 'globalMessages',
                })
                .makeVoipCall(
                  customerId,
                  selectedVoipProvider.id,
                  selectedNumber,
                  selectedCustomerPhoneNumberObj.isPrimary,
                ),
            )
            .pipe(
              rx.catchError((err) => {
                log.error('error performing call', err);
                return rx.obs.from([null]);
              }),
            );
        },
      ),
      rx.tap(() => this.opCallWasMade$.next()),
      rx.tap(() => {
        this.isClickDisabled$.next(false);
        if (this.blockUiInstance.isBlocking()) {
          this.blockUiInstance.stop();
        }
      }),
    )(null) as rx.Observable<void>;
  }

  get selectedNumber() {
    return this.selectedNumber$.getValue();
  }

  set selectedNumber(value: DidNumber) {
    this.selectedNumber$.next(value);
  }
}

export const ClickToCallComponent = {
  template,
  controller: Controller,
  require: { prfCustomerStoreProvider: '^' },
  bindings: {
    customer: '<',
    disableLastCallTooltip: '<',
  },
};

export default ClickToCallComponent;
