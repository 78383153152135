const styles = require('./default-trading-account-groups.component.scss');
import * as _ from '@proftit/lodash';
import template from './default-trading-account-groups.html';
import BaseController from '~/source/common/controllers/base';
import { BrandsService } from '~/source/management/brand/services/brands';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { filterCompChange } from '~/source/common/utilities/rxjs/observables/filter-comp-change';
import { Brand, Platform } from '@proftit/crm.api.models.entities';
import { MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import * as rx from '@proftit/rxjs';

class ComponentController extends BaseController {
  lifecycles = observeComponentLifecycles(this);
  styles = styles;
  brand: Brand;
  platform: Platform;
  prevAttributes: any;

  // collect groups selection from ui
  groups = [];
  isEdit = false;
  groupsByCurrency;

  isShowStatusSection$ = new rx.BehaviorSubject<boolean>(false);

  /*@ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    super();

    useStreams([this.streamIsShowStatusSection()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.getGroups().then((groups) => {
      this.groupsByCurrency = groups;
    });
  }

  $onDestroy() {}

  streamIsShowStatusSection() {
    return rx.pipe(
      () => filterCompChange<Platform>('platform', this.lifecycles.onChanges$),
      rx.map(({ currentValue }) => currentValue),
      rx.map((platform) => MT4_PLATFORMS.includes(platform.code)),
      rx.tap((isShow) => this.isShowStatusSection$.next(isShow)),
    )(null);
  }

  /**
   * Gets all the account groups and groups them by their currency code (or 'all' if currency is null)
   * @example (groups per currency, mt4 platform)
   * { EUR: [{id: 4}], USD: [{id: 5}, {id: 6}] }
   *
   * @example (groups with no specific currency, CFD platform)
   * { all: [{id: 5}, {id: 6}] }
   * @return {angular.IPromise} a promise which is resolved to the grouped groups
   */
  getGroups() {
    return this.brandsService()
      .getGroupsResource(this.brand.id, this.platform.id)
      .setConfig({ blockUiRef: 'default-trading-account-groups' })
      .expand(['currency'])
      .getListWithQuery()
      .then((allGroups) =>
        _.groupBy(
          (group) => _.getEs(group, 'currency.code', 'all'),
          allGroups.plain(),
        ),
      );
  }

  /**
   * enter edit mode:
   * save current model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = this.groups.map((group) => ({ ...group }));
    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // restore pre-edit state
    this.groups = [...this.prevAttributes];

    // exit edit mode
    this.isEdit = false;
  }

  /**
   * normalize groups object for save
   * @returns {{id, isDefault: boolean}[]}
   */
  normalizeGroups() {
    return this.groups
      .filter((group) => group.id)
      .map((group) => ({
        id: group.id,
        isDefault: true,
        status: group.status,
      }));
  }

  /**
   * save group settings
   * @returns {Object} returns Restangular object
   */
  save() {
    const normalizedGroups = this.normalizeGroups();

    return this.brandsService()
      .getGroupsResource(this.brand.id, this.platform.id)
      .setConfig({ blockUiRef: 'default-trading-account-groups' })
      .patchCollection('~', normalizedGroups)
      .then(() => {
        this.isEdit = false;
      });
  }
}

export default {
  template,
  bindings: {
    platform: '<',
    brand: '<',
  },
  controllerAs: 'vm',
  controller: ComponentController,
};
