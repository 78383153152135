import { TradingAccountDeposit } from '@proftit/crm.api.models.entities';

/**
 * Check is deposit is deletable.
 *
 * @param deposit - deposit.
 * @return is deposit deletable.
 */
export function isDepositDeletable(deposit: TradingAccountDeposit) {
  return deposit.isDeletable;
}

export default isDepositDeletable;
