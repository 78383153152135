import Promise from 'bluebird';
import Component from './base/component';

class Controller extends Component.controller {
  static $inject = [
    'prfContentTemplateTypesService',
    ...Component.controller.$inject,
  ];

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  compareItemsForSelected(itemA: any, itemB: any) {
    return itemA.code === itemB.code;
  }
}

export const ContentTemplateTypesSelectComponent = Component.config({
  controller: Controller,
});
