import log from 'loglevel';
import type { growl } from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import {
  useStreams,
  shareReplayRefOne,
  inProcessFilter,
} from '@proftit/rxjs.adjunct';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { generateGroupSecurityForm } from './generate-group-security-form';
import template from './brand-platform-mt4-group-security-dialog-item.component.html';
import { CurrentMt4SecurityOfGroupStoreServiceDirectiveController } from '~/source/common/service-directives/current-mt4-security-of-group-store-service.directive';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
const styles = require('./brand-platform-mt4-group-security-dialog-item.component.scss');

export class BrandPlatformMt4GroupSecurityDialogItemController {
  /* require */

  prfCurrentMt4SecurityOfGroup: CurrentMt4SecurityOfGroupStoreServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* bindings */

  onClose: () => void;

  /* state */

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  growlId = generateGrowlId();

  securityFormP = generateGroupSecurityForm();

  /* inputs */

  mtGroupIdIn$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'mtGroupId',
  );

  securityIdIn$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'securityId',
  );

  brandPlatformIn$ = observeShareCompChange<BrandPlatform>(
    this.lifecycles.onChanges$,
    'brandPlatform',
  );

  operationIn$ = observeShareCompChange<string>(
    this.lifecycles.onChanges$,
    'operation',
  );

  /* streams */

  doOperation$ = new rx.Subject<void>();

  isConfirmButtonDisabledS;

  /* @ngInject */
  constructor(
    readonly growl: growl.IGrowlService,
    readonly growlMessages: growl.IGrowlMessagesService,
  ) {
    useStreams(
      [
        this.securityIdIn$,
        this.brandPlatformIn$,
        this.mtGroupIdIn$,
        this.operationIn$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    this.isConfirmButtonDisabledS = this.streamIsConfirmButtonDisabled();
    const initSecurityS = this.streamInitSecurity();
    const initSecurityFormS = this.streamInitSecurityForm();
    const updateSecurityS = this.streamUpdateSecurity();
    const loginToSelectedPlatformS = this.streamLoginToSelectedPlatform();

    useStreams(
      [
        initSecurityS.stream$,
        initSecurityFormS.stream$,
        updateSecurityS.stream$,
        loginToSelectedPlatformS.stream$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitSecurity() {
    return generateInProcessStream(
      rx.obs.combineLatest(
        this.brandPlatformIn$,
        this.mtGroupIdIn$,
        this.securityIdIn$,
        this.operationIn$,
        this.prfCurrentPlatformSession.sessionS.stream$.pipe(
          rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
        ),
      ),
      (inProcess$) =>
        rx.pipe(
          inProcessFilter(inProcess$, (params) =>
            params.every((p) => !_.isNil(p)),
          ),
          rx.switchMap(
            ([
              brandPlatform,
              mtGroupId,
              securityId,
              operation,
              sessionInfo,
            ]) => {
              if (operation !== 'update') {
                throw new Error('unimplemented');
              }

              return this.prfCurrentMt4SecurityOfGroup.load(
                sessionInfo,
                mtGroupId,
                securityId,
              );
            },
          ),
        ),
    );
  }

  streamInitSecurityForm() {
    return generateInProcessStream(
      this.prfCurrentMt4SecurityOfGroup.securityS.stream$,
      () =>
        rx.pipe(
          rx.tap((security) => {
            this.securityFormP.proxy.handler
              .getLeaf()
              .setValueAsInitial(security);
          }),
        ),
      [this.prfCurrentMt4SecurityOfGroup.securityS.inProcess$],
    );
  }

  streamIsConfirmButtonDisabled() {
    return generateInProcessStream(
      rx.obs.combineLatest(
        this.securityFormP.isValid$,
        this.securityFormP.changes$,
      ),
      () =>
        rx.pipe(
          rx.map(([isValid, changes]) => {
            if (_.isEmpty(changes)) {
              return true;
            }

            if (!isValid) {
              return true;
            }

            return false;
          }),
          shareReplayRefOne(),
        ),
    );
  }

  streamUpdateSecurity() {
    return generateInProcessStream(this.doOperation$, (inProcess$) =>
      rx.pipe(
        rx.withLatestFrom(this.securityFormP.changes$),
        rx.tap(() =>
          this.growlMessages.destroyAllMessages(this.growlId as any),
        ),
        inProcessFilter(inProcess$, ([_op, changes]) => !_.isEmpty(changes)),
        rx.switchMap(([_op, changes]) => {
          return this.prfCurrentMt4SecurityOfGroup.update(changes).pipe(
            rx.catchError((e) => {
              log.error('error saving group', e);
              this.growl.error(_.get(['data', 'message'], e), {
                referenceId: this.growlId as any,
              });
              return rx.obs.NEVER;
            }),
          );
        }),
        rx.tap(() => this.onClose()),
      ),
    );
  }

  streamLoginToSelectedPlatform() {
    return generateInProcessStream(this.brandPlatformIn$, () =>
      rx.pipe(
        rx.switchMap((brandPlatform) => {
          if (_.isNil(brandPlatform)) {
            return this.prfCurrentPlatformSession.logout();
          }

          return this.prfCurrentPlatformSession.login(brandPlatform);
        }),
      ),
    );
  }
}

export const BrandPlatformMt4GroupSecurityDialogItemComponent = {
  template,
  controller: BrandPlatformMt4GroupSecurityDialogItemController,
  require: {
    prfCurrentMt4SecurityOfGroup: '^',
    prfCurrentPlatformSession: '^',
  },
  bindings: {
    securityId: '<',
    mtGroupId: '<',
    brandPlatform: '<',
    operation: '<',
    onClose: '&',
    onDismiss: '&',
  },
};
