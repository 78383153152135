import * as _ from '@proftit/lodash';
import type { StateService } from '@uirouter/angularjs';

import BaseController from '~/source/common/controllers/base';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import BrandToClearingCompany from '~/source/common/models/brand-to-clearing-company';

import template from './create.html';

class Clearing3dSettingsCreateController extends BaseController {
  brandId: number;
  brand: Brand;
  threeDConnections: any;
  clearingCompanyConnectionsServiceInst: ClearingCompanyConnectionsService;
  clearingCompanyConnection: any;
  brandsServiceInstance: BrandsService;

  /*@ngInject */
  constructor(
    readonly clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService,
    readonly $state: StateService,
    readonly $stateParams: any,
    readonly modelNormalizer: ModelNormalizerService,
    readonly brandsService: () => BrandsService,
  ) {
    super();

    Object.assign(this, {
      clearingCompanyConnectionsServiceInst: this.clearingCompanyConnectionsService(),
      brandsServiceInstance: this.brandsService(),

      threeDConnections: {
        minimumDeposits: null,
        countries: null,
        clearingCompany: {},
      },
      showNoBrandCurrenciesMessage: false,
      brandId: this.$stateParams.brandId,
    });

    // load brand & clearing company connections after
    this.loadBrand(this.brandId).then((data) => {
      this.threeDConnections.minimumDeposits = data.currencyConnections.map(
        (currency) => ({
          currency: currency.currency,
          value: 0,
        }),
      );
      this.loadClearingCompanyConnection(this.brandId);
    });
  }

  /**
   * user select a clearing company
   * @param {object} clearingCompany
   */
  selectClearingCompany(clearingCompany) {
    this.threeDConnections.clearingCompany = clearingCompany;
  }

  /**
   * is clearing company selected
   * @param {object} clearingCompany
   * @returns {boolean}
   */
  isSelected(clearingCompany) {
    return this.threeDConnections.clearingCompany.id === clearingCompany.id;
  }

  /**
   *  fetch clearingCompany connection resource by id
   *  also include brand and clearingCompany resources
   *
   * @returns {Promise}
   */
  loadClearingCompanyConnection(brandId) {
    return this.clearingCompanyConnectionsServiceInst
      .filter('brandId', brandId)
      .expand(['brand', 'clearingCompany'])
      .getListWithQuery<IElementRestNg<BrandToClearingCompany>>()
      .then((data) => {
        // set first record as default clearing company
        const clearingCompany: BrandToClearingCompany = _.head(data);
        this.threeDConnections.clearingCompany = clearingCompany
          ? clearingCompany.clearingCompany
          : {};

        this.clearingCompanyConnection = this.groupByBrand(data);
      });
  }

  /**
   * load brand data
   * @param {number} brandId
   * @returns {Promise}
   */
  loadBrand(brandId) {
    return this.brandsServiceInstance
      .embed([
        'currencyConnections',
        'platformConnections',
        'currencyConnections',
      ])
      .expand([
        'file:logo',
        'platformConnections.platform',
        'currencyConnections.currency',
      ])
      .getOneWithQuery<IElementRestNg<Brand>>(brandId)
      .then((data) => {
        this.brand = data;
        return data;
      });
  }

  /**
   * transforms a collection into groups by brand name
   * each group has a key by brand name
   *
   * @param {collection} data
   * @returns {*}
   */
  groupByBrand(data: BrandToClearingCompany[]) {
    return _.groupBy((item) => item.brand.name, data);
  }

  /**
   *  add new 3D's settings
   */
  save() {
    const normalized3dSettings = this.modelNormalizer.normalize(
      _.omit(['minimumDeposits'], this.threeDConnections),
    );
    normalized3dSettings.minimumDeposits = this.brandsServiceInstance.normalizeThreeDMinimumDeposit(
      this.threeDConnections.minimumDeposits,
    );

    this.brandsServiceInstance
      .postThreeD(this.brandId, normalized3dSettings)
      .then((data) => {
        this.$state.go('^.list.table', { brandId: this.brandId });
      });
  }
}

const Clearing3dSettingsCreateComponent = {
  template,
  controller: Clearing3dSettingsCreateController,
  controllerAs: 'vm',
};

export default Clearing3dSettingsCreateComponent;
