import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { RolesService } from '~/source/management/user/services/roles';
import { UserRolePositionCode } from '@proftit/crm.api.models.enums';

export class UserRolePositionStoreService {
  userRolePositions$ = rx.obs
    .from(this.rolesService().getAll())
    .pipe(shareReplayRefOne());

  adminRole$ = this.userRolePositions$.pipe(
    rx.map((roles) => roles.find((r) => r.code === UserRolePositionCode.Admin)),
    shareReplayRefOne(),
  );

  /* @ngInject */
  constructor(readonly rolesService: () => RolesService) {}
}
