import ng from 'angular';
import Controller from '~/source/common/controllers/base';
import UsersService from '~/source/management/user/services/users';
import BrandsService from '~/source/management/brand/services/brands';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

abstract class ApiUserAbstractManagerController extends Controller {
  usersService: () => UsersService;
  brandsService: () => BrandsService;
  $state: ng.ui.IStateService;
  brandsServiceInst: BrandsService;
  usersServiceInst: UsersService;
  apiUserModel: IElementRestNg<{
    id: number;
    brands: [any];
    roleId: number;
    isAction: boolean;
  }>;

  constructor(...args) {
    super(...args);

    Object.assign(this, {
      usersServiceInst: this.usersService(),
      brandsServiceInst: this.brandsService(),
      apiUserModel: {
        brands: [undefined],
        roleId: 5,
        isActive: false,
        isBypassIpWhitelist: true,
      },
    });
  }

  abstract queryUser();

  /**
   *  create / update user
   *  call this function on child view
   *
   * @returns {Promise.<T>}
   */
  onUserFormValid() {
    return this.queryUser()
      .then(() => {
        // Called when user was successfully created/updated
        this.$state.go('crm.management.permissions.api-users.list');
      })
      .catch(() => {
        // Called when user creation/update failed
      });
  }

  /**
   * Gets brand logo from the server
   *
   * @param {Object} brand
   * @returns {Restangular}
   */
  getBrandLogo(brand) {
    return this.brandsServiceInst.expand('file:logo').getOneWithQuery(brand.id);
  }
}

export default ApiUserAbstractManagerController;
