import RestService from '../../../common/services/rest';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { CommunicationSubject } from '~/source/common/models/communication-subject';
import { IFieldsAndModulesService } from '~/source/management/fields-and-modules/i-fields-and-modules-service';
import { FieldsAndModulesModel } from '@proftit/crm.api.models.entities';
import { IPromise } from 'angular';

const COMM_SUBJECT_OUTBOUND_SMS = 'outbound_sms';

export class CommunicationsSubjectsService extends RestService
  implements IFieldsAndModulesService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'communicationSubjects';
  }

  useCache(): boolean {
    return false;
  }

  /**
   */
  getCommSubjectOutboundSms() {
    return this.getListWithQuery<
      IElementRestNg<CommunicationSubject>
    >().then((items) =>
      items.find((item) => item.code === COMM_SUBJECT_OUTBOUND_SMS),
    );
  }

  getCommunicationsSubjectsResource(id: number) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }

  updateIsDefault(
    id: number,
    isDefault: boolean,
  ): Promise<FieldsAndModulesModel> {
    return this.getCommunicationsSubjectsResource(id)
      .patchWithQuery<IElementRestNg<FieldsAndModulesModel>>({
        id,
        isDefault,
      })
      .then((patchedModel) => patchedModel);
  }
}
