import * as _ from '@proftit/lodash';

/**
 * Filter factory. Return asTextList Filter.
 *
 * @return {Function} asTextList filter
 */
export function asTextListFactory() {
  /**
   * Convert number to fixed string representation.
   *
   * @param input - string to truncate.
   * @param limit - length to show.
   * @reutrn {string} string representation of the source.
   */
  function asTextList(
    input: Object[],
    field: string,
    delimiter: string = ', ',
  ): string {
    if (_.isNil(input)) {
      return '';
    }

    if (!_.isArray(input)) {
      return '';
    }

    if (input.length === 0) {
      return '';
    }

    const newContent = _.tail(input).reduce(
      (acc, item) => `${acc}${delimiter}${item[field]}`,
      input[0][field],
    ) as string;

    return newContent;
  }

  return asTextList;
}

asTextListFactory.$inject = [];
