/**
 * Makes any element draggable.
 * Taken from angular developer guide:
 * @see {@link https://docs.angularjs.org/guide/directive}
 *
 * Usage: use "prf-draggable" attribute.
 */

export const DraggableDirective = [
  '$document',
  '$window',
  ($document, $window) => ({
    restrict: 'AE',
    controller: [
      '$scope',
      function ($scope) {
        this.setIsOffsetType = (val: boolean) => {
          $scope.isOffsetType = val;
        };
      },
    ],
    link(scope, elemParam, attrs) {
      let startX;
      let startY;
      let startYBottom;
      let initialMouseX;
      let initialMouseY;
      let elHeight;
      let elWidth;

      let elem = elemParam;

      const $jqWindow = $($window);

      if (attrs.prfDraggble === 'parent') {
        elem = elem.parent();
      }

      /**
       * Called on mouse move. Makes the element follow the mouse.
       * This method is bounnd mousedown and unbound on mouse up.
       * @param {jQuery.Event} $event
       * @returns {boolean}
       */
      function mousemove($event) {
        const dx = $event.clientX - initialMouseX;
        const dy = $event.clientY - initialMouseY;
        const minTop = 0;
        const minBottom = 0;
        const minLeft = 0;
        const maxTop = $jqWindow.height() - elHeight;
        const maxBottom = $jqWindow.height() - elHeight;
        const maxLeft = $jqWindow.width() - elWidth;
        const top = Math.min(Math.max(startY + dy, minTop), maxTop);
        const bottom = Math.min(
          Math.max(startYBottom - dy, minBottom),
          maxBottom,
        );
        const left = Math.min(Math.max(startX + dx, minLeft), maxLeft);

        if (scope.isOffsetType) {
          elem.css({
            left: `${left}px`,
            bottom: `${bottom}px`,
          });
        } else {
          elem.css({
            top: `${top}px`,
            left: `${left}px`,
            bottom: 'auto',
          });
        }
        return false;
      }

      /**
       *
       * Called on mouse up. Unbinds mousemove and mouseup events
       * This method is bound on mousedown
       */
      function mouseup() {
        $document.unbind('mousemove', mousemove);
        $document.unbind('mouseup', mouseup);
      }
      /**
       * In order to make the element movable, it must have absolute position.
       * It is preferable to set it by css, but we do it here anyway to make sure.
       */
      elem.css({
        position: 'absolute',
      });

      /**
       * When the left mouse button is clicked, start following the mouse
       * (by listening to mousemove event)
       */
      elem.bind('mousedown', ($event) => {
        // Get el dimension only the first time (when it is fully shown and not hidden)
        elHeight = elHeight || elem.height();
        elWidth = elWidth || elem.width();

        if ($event.which !== 1) {
          return; // ignore non-left click
        }

        // If the user tried dragging from a place different then the handle, block it.
        if (!$($event.target).closest('[draggable-handle]').length) {
          return;
        }

        startX = elem.prop('offsetLeft');
        startY = elem.prop('offsetTop');
        startYBottom = $jqWindow.height() - (startY + elHeight);
        initialMouseX = $event.clientX;
        initialMouseY = $event.clientY;
        $document.bind('mousemove', mousemove);
        $document.bind('mouseup', mouseup);
      });
    },
  }),
];
