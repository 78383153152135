import angular from 'angular';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import AppConfig from '~/source/conf/appConfig';
import TokensService, { UserLoginData } from '../services/tokens';
import { calcKeepAliveInterval } from '~/source/common/utilities/calc-keep-alive-interval';
import template from './login.html';
import log from 'loglevel';
import { UsersService } from '~/source/management/user/services/users';
import { BrandsService } from '~/source/management/brand/services/brands';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { PublicSystemPhoneVerificationService } from '~/source/common/services/public-system-phone-verification';
import { crmErrors } from '~/source/common/api-crm-server/crm-errors';
import { GrowlSuppressMethod } from '~/source/common/services/growl-suppress-method';
import { IntercomService } from '~/source/common/services/intercom.service';

enum LoginStep {
  CredentialsStep,
  AuthInputStep,
  LoggedIn,
  End,
}

export class LoginController {
  LoginStep = LoginStep;

  lifecycles = observeComponentLifecycles(this);

  loginOp$ = new rx.Subject<void>();

  authOp$ = new rx.Subject<{ code: string[] }>();

  resendCodeOp$ = new rx.Subject<void>();

  session$ = new rx.BehaviorSubject<any>(null);

  loginStep$ = this.streamLoginStep();

  credentials: Partial<UserLoginData> = {
    username: '',
    password: '',
  };

  /* @ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly $state: any,
    readonly appConfig: typeof AppConfig,
    readonly Keepalive: any,
    readonly Idle: any,
    readonly Title: any,
    readonly growl: angular.growl.IGrowlService,
    readonly usersService: () => UsersService,
    readonly brandsService: () => BrandsService,
    readonly prfPublicSystemPhoneVerificationService: PublicSystemPhoneVerificationService,
    readonly intercomService: IntercomService,
    readonly localStorageService: Storage,
  ) {
    // The title might has changed to "Your session has timed out". Try to restore original title.
    this.Title.restore();
    this.intercomService.showIntercom(true);
  }

  $onInit() {
    useStreams(
      [this.loginStep$, this.streamReSendCode()],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  streamLoginWithCredentials(credentials: Partial<UserLoginData>) {
    return rx.obs
      .from(
        this.tokensService
          .setConfig({
            errorsTranslationPath: 'login.errors',
            blockUiRef: 'loginBlockUI',
            growlRef: 'loginGrowl',
            suppressGrowl: GrowlSuppressMethod.ByDataCode,
            dataCodesForGrowlSuppress: [
              crmErrors.errorAuth2faMissingCode.dataCode,
            ],
          })
          .expand(['role'])
          .login(credentials),
      )
      .pipe(
        rx.tap((session) => this.session$.next(session)),
        rx.map(() => LoginStep.LoggedIn),
        rx.catchError((err) => {
          if (
            _.get(['response', 'data', 'code'], err) ===
            crmErrors.errorAuth2faMissingCode.dataCode
          ) {
            return rx.obs
              .from(
                this.prfPublicSystemPhoneVerificationService
                  .sendPhoneVerification(
                    this.credentials.username,
                    this.credentials.password,
                  )
                  .catch(() => null),
              )
              .pipe(rx.map(() => LoginStep.AuthInputStep));
          }

          if (err.message === 'API User') {
            this.growl.error('login.API_USERS_CANNOT_LOGIN', {
              referenceId: <any>'loginGrowl',
            });
          }

          throw err;
        }),
      );
  }

  streamLoginFromCredentialInput(
    loginStep$: rx.Observable<LoginStep>,
  ): rx.Observable<LoginStep> {
    const loginAction$ = rx.pipe(
      () => loginStep$,
      rx.switchMap((step) => {
        if (step === LoginStep.CredentialsStep) {
          return rx.obs.of(true);
        }

        return rx.obs.NEVER;
      }),
      rx.switchMap(() => this.loginOp$),
      rx.switchMap(() => this.streamLoginWithCredentials(this.credentials)),
      rx.catchError(() => loginAction$),
    )(null);

    return loginAction$;
  }

  streamLoginFromLoginAsToken(loginStep$: rx.Observable<LoginStep>) {
    const loginAction$ = rx.pipe(
      () => loginStep$,
      rx.switchMap((step) => {
        if (step === LoginStep.CredentialsStep) {
          return rx.obs.of(true);
        }

        return rx.obs.NEVER;
      }),
      rx.switchMap(() => this.lifecycles.onInitShared$),
      rx.filter((onInit) => onInit),
      rx.switchMap(() => {
        if (!this.$state.params.token) {
          return rx.obs.NEVER;
        }
        return rx.obs.of(this.$state.params.token.token as string);
      }),
      rx.switchMap((token) => this.streamLoginWithCredentials({ token })),
      rx.catchError(() => rx.obs.NEVER),
    )(null);

    return loginAction$;
  }

  streamLoginFromAuthInput(
    loginStep$: rx.Observable<LoginStep>,
  ): rx.Observable<LoginStep> {
    const action$ = rx.pipe(
      () => loginStep$,
      rx.switchMap((step) => {
        if (step === LoginStep.AuthInputStep) {
          return rx.obs.of(true);
        }

        return rx.obs.NEVER;
      }),
      rx.switchMap(() => this.authOp$),
      rx.switchMap(({ code }) => {
        const smsCode = code.join('');

        return this.tokensService
          .setConfig({
            errorsTranslationPath: 'login.errors',
            blockUiRef: 'loginBlockUI',
            growlRef: 'loginGrowl',
            suppressGrowl: false,
          })
          .expand(['role'])
          .login({
            smsCode,
            username: this.credentials.username,
            password: this.credentials.password,
          });
      }),
      rx.tap((session) => this.session$.next(session)),
      rx.map(() => LoginStep.LoggedIn),
      rx.catchError((err) => {
        if (err.message === 'API User') {
          this.growl.error('login.API_USERS_CANNOT_LOGIN', {
            referenceId: <any>'loginGrowl',
          });
        }

        return action$;
      }),
    )(null);

    return action$;
  }

  streamLoginFromAfterLoggedIn(
    loginStep$: rx.Observable<LoginStep>,
  ): rx.Observable<LoginStep> {
    return rx.pipe(
      () => loginStep$,
      rx.switchMap((step) => {
        if (step === LoginStep.LoggedIn) {
          return rx.obs.of(true);
        }

        return rx.obs.NEVER;
      }),
      rx.withLatestFrom(this.session$),
      rx.tap(([a, session]) => {
        // Set keepalive settings: "ping" when the session is 90% done
        const keepAliveInterval = calcKeepAliveInterval(
          session.sessionTime,
          this.localStorageService,
        );
        this.Keepalive.setInterval(keepAliveInterval);

        const now = Date.now();
        const nextKeepAlive = now + keepAliveInterval * 1000;
        log.info(
          'keepalive interval set to',
          keepAliveInterval,
          'Next will be at ',
          new Date(nextKeepAlive),
        );

        // Start watching for idle user
        this.Idle.watch();
      }),
      rx.tap(() => {
        this.$state.go(this.appConfig.defaultState);
      }),
      rx.map(() => LoginStep.End),
    )(null);
  }

  streamLoginStep() {
    const loginStep$ = new rx.BehaviorSubject<LoginStep>(
      LoginStep.CredentialsStep,
    );

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamLoginFromCredentialInput(loginStep$),
          this.streamLoginFromLoginAsToken(loginStep$),
          this.streamLoginFromAuthInput(loginStep$),
          this.streamLoginFromAfterLoggedIn(loginStep$),
        ),
      rx.startWith(LoginStep.CredentialsStep),
      rx.tap((step) => loginStep$.next(step)),
      shareReplayRefOne(),
    )(null);
  }

  streamReSendCode() {
    return rx.pipe(
      () => this.resendCodeOp$,
      rx.switchMap(() => {
        return this.prfPublicSystemPhoneVerificationService
          .sendPhoneVerification(
            this.credentials.username,
            this.credentials.password,
          )
          .catch(() => null);
      }),
    )(null);
  }
}

export default {
  template,
  controller: LoginController,
};
