import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useBrandStore } from './use-brand-store';
import { BrandStore } from './brand-store';
import { BrandsService } from '~/source/management/brand/services/brands';
import { CountriesService } from '../services/countries';
import { CountriesProviderInner } from './countries-provider.component';

export class BrandProviderInner {
  prfCountriesProviderInner: CountriesProviderInner;

  store: BrandStore;

  /* @ngInject */
  constructor(readonly brandsService: () => BrandsService) {}

  $onInit() {
    const lifecycles = observeComponentLifecycles(this);
    const [brandStore] = useBrandStore(
      this.brandsService,
      this.prfCountriesProviderInner.countriesStore,
      lifecycles,
    );

    this.store = brandStore;
  }

  $onChanges() {}

  $onDestroy() {}
}

export const BrandProviderComponent = {
  template: `
    <prf-countries-provider>
      <prf-brand-provider-inner>
        <ng-transclude></ng-transclude>
      </prf-brand-provider-inner>
    </prf-countries-provider>
  `,
  transclude: true,
};

export const BrandProviderInnerComponent = {
  template: `
    <ng-transclude></ng-transclude>
  `,
  controller: BrandProviderInner,
  transclude: true,
  require: {
    prfCountriesProviderInner: '^',
  },
};
