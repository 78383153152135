import template from './auto-assignment-list-desk-item.component.html';

const styles = require('./auto-assignment-list-desk-item.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { BrandDesk, Desk } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams, tapLog } from '@proftit/rxjs.adjunct';
import { AutoAssignmentPatch } from '~/source/management/automation/containers/auto-assignment/auto-assignment-patch';

export class AutoAssignmentListDeskItemController {
  isEdit: boolean;
  desk: Desk & BrandDesk;

  onDeskAutoAssignmentChange: (objToPass: AutoAssignmentPatch) => void;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  desk$ = observeShareCompChange<Desk & BrandDesk>(
    this.lifecycles.onChanges$,
    'desk',
  );
  isDeskAutoAssignmentEnabledFormControl = new FormControl<boolean>(false);

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.desk$,
        this.isDeskAutoAssignmentEnabledFormControl.value$,
        this.isDeskAutoAssignmentEnabledFormControl.isAtFirstValue$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamInitIsDeskAutoAssignmentEnabledFormControl(),
        this.streamEmitIsDeskAutoAssignmentEnabledChange(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamInitIsDeskAutoAssignmentEnabledFormControl() {
    return rx.pipe(
      () => this.desk$,
      rx.filter((desk) => !_.isNil(desk)),
      rx.tap((desk) => {
        this.isDeskAutoAssignmentEnabledFormControl.setValueAsFirst(
          desk.isAutoAssignmentEnabled,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEmitIsDeskAutoAssignmentEnabledChange() {
    return rx.pipe(
      () =>
        this.isDeskAutoAssignmentEnabledFormControl.value$.pipe(
          rx.distinctUntilChanged(),
        ),
      // isAtFirstValue$ sync aysynchronosly to value$ so it need time to update
      rx.delay(0),
      rx.withLatestFrom(
        this.isDeskAutoAssignmentEnabledFormControl.isAtFirstValue$,
      ),
      rx.filter(([value, isAtFirstValue]) => !isAtFirstValue),
      rx.tap(([isAutoAssignmentEnabled]) => {
        const objToSend = {
          isAutoAssignmentEnabled,
          id: this.desk.id,
        };
        this.onDeskAutoAssignmentChange(objToSend as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  $onChanges() {}
}

export const AutoAssignmentListDeskItemComponent = {
  template,
  controller: AutoAssignmentListDeskItemController,
  bindings: {
    desk: '<',
    isEdit: '<',
    onDeskAutoAssignmentChange: '&',
  },
};
