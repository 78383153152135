import { IHttpService, IRootScopeService } from 'angular';
import log from 'loglevel';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';
import { Level } from '@proftit/types-loglevel';
import { initI18Next } from './initI18Next';
import { IAppConfig } from '~/source/conf/appConfig';
import { calcTranslationApiUrl } from '../services/calcTranslationApiUrl';

export function useI18NextWithAngularTranslate(
  currentLang$: rx.Observable<string>,
  translationsTable$: rx.Observable<{ [key: string]: any }>,
  translationLoadingSuccess$: rx.Observable<any>,
  appConfig: IAppConfig,
  $rootScope: IRootScopeService,
  $http: IHttpService,
  lifecycles: ComponentLifecyclesObservables,
) {
  const translationNeededOp$ = new rx.Subject<{
    language: string;
    namespace: string;
    callback: Function;
  }>();

  const i18nInst = initI18Next(
    translationNeededOp$,
    'en',
    log.getLevel() === Level.Debug,
  );

  const tapChangeLanguage$ = currentLang$.pipe(
    rx.distinctUntilChanged(),
    rx.tap((language) => {
      i18nInst.changeLanguage(language);
    }),
    shareReplayRefOne(),
  );

  const tapAddTranslations$ = translationNeededOp$.pipe(
    /*
    rx.withLatestFrom(translationsTable$, currentLang$),
    rx.tap(([{ language, namespace, callback }, table, lang]) => {
      const langTable = table[language];
      callback(null, langTable);
    }),
    */
    rx.mergeMap(({ language, namespace, callback }) => {
      const restResource = calcTranslationApiUrl(appConfig, language);
      const httpOptions = {
        url: restResource,
        method: 'GET',
      };

      return ($http(httpOptions).then((result) => ({
        callback,
        data: result.data,
      })) as any) as Promise<any>;
    }),
    rx.tap(({ data, callback }) => {
      callback(null, data);
    }),
    shareReplayRefOne(),
  );

  useStreams([tapChangeLanguage$, tapAddTranslations$], lifecycles.onDestroy$);

  return { i18nInst };
}
