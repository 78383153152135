import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';

const DOWNLOAD_REPORTS = 'downloadReports';
const DOWNLOADS = 'downloads';

export class BalanceLogsExportService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'BalanceLogsExport';
  }

  startBalanceLogsExport(tradingAccountId: number) {
    return this.resourceBuildStart()
      .getElement(DOWNLOAD_REPORTS)
      .resourceBuildEnd()
      .customPostWithQuery({
        customerTradingAccountId: tradingAccountId,
      });
  }

  getDownloadReportPath(reportId: number) {
    return `${DOWNLOAD_REPORTS}/${reportId}/${DOWNLOADS}`;
  }

  downloadReport(reportId: number) {
    const customPath = this.getDownloadReportPath(reportId);
    return this.getWithCustomPath(customPath, { responseType: 'blob' });
  }
}
