import angular from 'angular';
import * as _ from '@proftit/lodash';
import { InactivityFeeSettingLog } from '@proftit/crm.api.models.entities';
import { switchOn } from '~/source/common/utilities/switch-on';

function translateKey(key: string) {
  return switchOn(
    {
      start_date: () => 'Start Date',
      inactivity_period_length: () => 'Inactivity Period Length',
      cycle_length: () => 'Cycle Length',
      units_charge_size: () => 'USD Charge Size',
      percent_charge_size: () => 'Percent Charge Size',
      is_active: () => 'Is Active',
    },
    key,
    () => key,
  );
}

/**
 * Calcuttate meta info for inactivity fee log record.
 *
 * @param rec - record
 */
export function calcInfoForLogRecord(
  rec: InactivityFeeSettingLog,
  $translate: angular.translate.ITranslateService,
) {
  const criterias = _.flow([
    () =>
      rec.data.criteria.map((x) =>
        $translate.instant(`inactivityFee.criterias.${x}`),
      ),
    (criterias) => criterias.join(', '),
  ])();

  const fields = _.toPairs(rec.data.fields).map(([key, val]) => ({
    val,
    key: translateKey(key),
  }));
  const fieldsAsString = fields
    .map(({ key, val }) => `${key}: ${val}`)
    .join(', ');

  const fieldsLiHtml = fields
    .map(({ key, val }) => `${key}: ${val}`)
    .map((x) => `<li>${x}</li>`)
    .join('');
  const fieldsAsTooltipHtml = `<ul>${fieldsLiHtml}</ul`;

  return {
    criterias,
    fields,
    fieldsAsString,
    fieldsAsTooltipHtml,
  };
}
