import template from './withdrawal-transfer-details.component.html';

const styles = require('./withdrawal-transfer-details.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { pipeLog, shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { switchOn } from '@proftit/general-utilities';
import { TradingAccountTransferMethodTypeCode } from '@proftit/crm.api.models.enums';
import { mapTranslationsArray } from '~/source/common/utilities/map-translations-array';
import CurrenciesService from '~/source/common/services/currencies';

interface TableRow {
  label: string;
  value: string;
}

export class WithdrawalTransferDetailsController {
  TransferMethods = TradingAccountTransferMethodTypeCode;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  isPinTooltipDisplayed = false;

  method$ = observeShareCompChange<TradingAccountTransferMethodTypeCode>(
    this.lifecycles.onChanges$,
    'method',
  );
  wire$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'wire');
  card$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'card');
  manual$ = observeShareCompChange<any>(
    this.lifecycles.onChanges$,
    'manualCard',
  );
  mobileMoney$ = observeShareCompChange<any>(
    this.lifecycles.onChanges$,
    'mobileMoney',
  );
  ewallet$ = observeShareCompChange<any>(
    this.lifecycles.onChanges$,
    'ewalletTransaction',
  );
  infoTitle$ = this.calcInfoTitle();
  infoTable$ = this.calcInfoTable();
  infoTableToCopy$ = this.calcInfoTableToCopy();

  infoForTooltip$ = this.streamInfoForTooltip();

  /*@ngInject */
  constructor(
    readonly $translate: angular.translate.ITranslateService,
    readonly currenciesService: CurrenciesService,
  ) {
    useStreams(
      [this.infoTitle$, this.infoTable$, this.infoTableToCopy$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamInfoForTooltip() {
    return rx.obs.combineLatest(this.infoTitle$, this.infoTable$).pipe(
      rx.map(([infoTitle, infoTable]) => {
        return {
          infoTable,
          infoTitle,
        };
      }),
      shareReplayRefOne(),
    );
  }

  calcInfoTitle() {
    return rx.pipe(
      () => this.method$,
      rx.switchMap((method) => {
        return switchOn(
          {
            [this.TransferMethods.WireWithdrawal]: () => {
              return this.$translate(
                'contact.tradingAccount.withdrawals.WIRE_TRANSFER_DETAILS',
              );
            },
            [this.TransferMethods.CardWithdrawal]: () => {
              return this.$translate(
                'contact.tradingAccount.withdrawals.CARD_TRANSFER_DETAILS',
              );
            },
            [this.TransferMethods.EWallet]: () => {
              return this.$translate(
                'contact.tradingAccount.withdrawals.EWALLET_TRANSFER_DETAILS',
              );
            },
            [this.TransferMethods.MobileMoney]: () => {
              return this.$translate(
                'contact.tradingAccount.withdrawals.MOBILE_MONEY_TRANSFER_DETAILS',
              );
            },
            [this.TransferMethods.Manual]: () => {
              return this.$translate(
                'contact.tradingAccount.withdrawals.CARD_TRANSFER_DETAILS',
              );
            },
          },
          method as string,
          () => {
            throw new Error('unhandled method for withdrawal details');
          },
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamWireInfoTableObj() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.wire$, this.method$),
      rx.filter(
        ([wire, method]) => method === this.TransferMethods.WireWithdrawal,
      ),
      rx.switchMap(([wire, method]) =>
        mapTranslationsArray(
          {
            ACCOUNT_NUMBER: 'wire.ACCOUNT_NUMBER',
            FIRST_NAME: 'common.FIRST_NAME',
            LAST_NAME: 'common.LAST_NAME',
            BANK_NAME: 'wire.BANK_NAME',
            BANK_ADDRESS: 'wire.BANK_ADDRESS',
            BANK_COUNTRY: 'wire.BANK_COUNTRY',
            BANK_CITY: 'wire.BANK_CITY',
            IBAN: 'wire.IBAN',
            SWIFT_CODE: 'wire.SWIFT_CODE',
            COUNTRY: `country.${wire.bankCountry.alpha2Code}`,
          },
          this.$translate,
        ),
      ),
      rx.withLatestFrom(this.wire$, this.method$),
      rx.map(([translations, wire, method]) => {
        if (_.isNil(wire)) {
          return [];
        }
        return [
          { label: translations.ACCOUNT_NUMBER, value: wire.accountNumber },
          { label: translations.FIRST_NAME, value: wire.firstName },
          { label: translations.LAST_NAME, value: wire.lastName },
          { label: translations.BANK_NAME, value: wire.bankName },
          { label: translations.BANK_ADDRESS, value: wire.bankAddress },
          { label: translations.BANK_COUNTRY, value: translations.COUNTRY },
          { label: translations.BANK_CITY, value: wire.bankCity },
          { label: translations.IBAN, value: wire.iban },
          { label: translations.SWIFT_CODE, value: wire.swiftCode },
        ];
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCardInfoTableObj() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.card$,
          this.method$,
          mapTranslationsArray(
            {
              LAST_4_DIGITS: 'card.LAST_4_DIGITS',
              CLEARING_COMPANY: 'CLEARING_COMPANY',
            },
            this.$translate,
          ),
        ),
      rx.filter(
        ([card, method, translations]) =>
          method === this.TransferMethods.CardWithdrawal,
      ),
      rx.map(([card, method, translations]) => {
        if (_.isNil(card)) {
          return [];
        }
        return [
          {
            label: translations.LAST_4_DIGITS,
            value: card.cardNumber,
          },
          {
            label: translations.CLEARING_COMPANY,
            value: card.clearingCompany.name,
          },
        ];
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEWalletInfoTableObj() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.ewallet$,
          this.method$,
          mapTranslationsArray(
            {
              EWALLET: 'brandEwallet.EWALLET',
              FROM_ADDRESS: 'contact.FROM_ADDRESS',
              TO_ADDRESS: 'contact.TO_ADDRESS',
              REQUESTED_CURRENCY: 'withdrawal.REQUESTED_CURRENCY',
            },
            this.$translate,
          ),
        ),
      rx.filter(
        ([ewallet, method, translations]) =>
          method === this.TransferMethods.EWallet,
      ),
      rx.map(([ewallet, method, translations]) => {
        if (_.isNil(ewallet)) {
          return [];
        }

        let table: TableRow[] = [
          {
            label: translations.EWALLET,
            value: ewallet.ewallet == null ? '' : ewallet.ewallet.name,
          },
          {
            label: translations.FROM_ADDRESS,
            value: ewallet.fromEwalletAddress,
          },
          {
            label: translations.TO_ADDRESS,
            value: ewallet.toEwalletAddress,
          },
        ];
        return [translations, table, ewallet.currencyCode];
      }),
      rx.switchMap(([translations, table, currencyCode]) => {
        if (!currencyCode) {
          return new Promise((resolve) => resolve(table));
        }
        return this.fetchCurrencyByCode(currencyCode).then((currency) => {
          return [
            ...table,
            {
              label: translations.REQUESTED_CURRENCY,
              value: currency[0].name,
            },
          ];
        });
      }),
      rx.map((table: TableRow[]) => table),

      shareReplayRefOne(),
    )(null);
  }

  streamManualInfoTableObj() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.manual$,
          this.method$,
          mapTranslationsArray(
            {
              LAST_4_DIGITS: 'card.LAST_4_DIGITS',
              CLEARING_COMPANY: 'CLEARING_COMPANY',
            },
            this.$translate,
          ),
        ),
      rx.filter(
        ([manual, method, translations]) =>
          method === this.TransferMethods.Manual,
      ),
      rx.map(([manual, method, translations]) => {
        if (_.isNil(manual)) {
          return [];
        }
        return [
          {
            label: translations.LAST_4_DIGITS,
            value: manual.cardNumber,
          },
          {
            label: translations.CLEARING_COMPANY,
            value: manual.clearingCompany.name,
          },
        ];
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamMobileMoneyInfoTableObj() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.mobileMoney$,
          this.method$,
          mapTranslationsArray(
            {
              CLEARING_COMPANY: 'CLEARING_COMPANY',
            },
            this.$translate,
          ),
        ),
      rx.filter(
        ([mobileMoney, method, translations]) =>
          method === this.TransferMethods.MobileMoney,
      ),
      rx.map(([mobileMoney, method, translations]) => {
        if (_.isNil(mobileMoney)) {
          return [];
        }
        return [
          {
            label: translations.CLEARING_COMPANY,
            value: mobileMoney.clearingCompany.name,
          },
        ];
      }),
      shareReplayRefOne(),
    )(null);
  }

  calcInfoTable(): rx.Observable<TableRow[]> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamWireInfoTableObj(),
          this.streamCardInfoTableObj(),
          this.streamEWalletInfoTableObj(),
          this.streamManualInfoTableObj(),
          this.streamMobileMoneyInfoTableObj(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  calcInfoTableToCopy() {
    return rx.pipe(
      () => this.infoTable$,
      rx.filter((table) => !_.isNil(table)),
      rx.map((table) =>
        table.map(({ label, value }) => `${label}: \t${value}`).join('\n'),
      ),
      shareReplayRefOne(),
    )(null);
  }

  fetchCurrencyByCode(code: string) {
    return this.currenciesService
      .filter({ code: code })
      .getListWithQuery()
      .then((currency) => currency.plain());
  }
}

export const WithdrawalTransferDetailsComponent = {
  template,
  controller: WithdrawalTransferDetailsController,
  bindings: {
    wire: '<',
    method: '<',
    card: '<',
    mobileMoney: '<',
    manualCard: '<',
    ewalletTransaction: '<',
  },
};
