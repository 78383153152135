import ng from 'angular';
import BaseController from '~/source/common/controllers/base';
import DesksService from '~/source/management/brand/services/desks';
import * as _ from '@proftit/lodash';

import { Desk } from '@proftit/crm.api.models.entities';

import template from './desk-edit.component.html';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

class DeskEditComponent extends BaseController {
  lifecycles = observeComponentLifecycles(this);
  serverModel: IElementRestNg<Desk>;
  model: Desk;
  desksService: () => DesksService;
  dataServiceInstance: DesksService;
  $state: any;
  modelNormalizer: any;
  isFormDisabled = true;

  /**
   * Component life cycle method - onInit
   *
   * @returns {void}
   */
  $onInit(): void {
    this.dataServiceInstance = this.desksService();
    this.getDesk(this.$state.params.id).then((data) => {
      this.serverModel = data;
      this.model = data.plain();
    });
  }

  $onChanges(changes: angular.IOnChangesObject) {
    super.$onChanges(changes);
  }

  $onDestroy() {}

  /**
   * Retrieve desk instance from server
   *
   * @param id
   * @returns {Restangular.IPromise}
   */
  getDesk(id: number): Promise<any> {
    this.setDataServiceGrowlAndBlock();

    return this.dataServiceInstance
      .embed(['countries', 'languages'])
      .getOneWithQuery(id);
  }

  /**
   * Update desk instance in the server
   *
   * @return {Promise<any>}
   */
  update(): Promise<any> {
    this.setDataServiceGrowlAndBlock();
    const data = this.prepDataForUpdate();

    return (this.serverModel.patch(data).then(() => {
      this.$state.go('^.list');
    }) as any) as Promise<string>;
  }

  /**
   * Set the data service error pane and blocking pane before use.
   *
   * The blocking page is reset to `service.name` after each rest operation there
   * for we need to set it before each operation.
   *
   * @return {void}
   */
  setDataServiceGrowlAndBlock() {
    this.dataServiceInstance.setConfig({
      growlRef: 'deskForm',
      blockUiRef: 'deskEditBlockUi',
    });
  }

  /**
   * Prepare model data for update to server. ex. Normalize data.
   *
   * @returns {Object}
   */
  prepDataForUpdate(): Partial<any> {
    const data = _.omit(['id'], this.model);
    return this.modelNormalizer.normalize(data);
  }

  onFormAuthorized() {
    this.isFormDisabled = false;
  }

  onFormUnAuthorized() {
    this.isFormDisabled = true;
  }
}

DeskEditComponent.$inject = [
  ...BaseController.$inject,
  '$state',
  'desksService',
  'modelNormalizer',
];

export default {
  template,
  controller: DeskEditComponent,
};
