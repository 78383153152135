import type { translate } from 'angular';
import { switchOn } from '@proftit/general-utilities';
import { IS_NUMBER } from '~/source/common/proxy-form/validators/is-number-proxy-validator';
import { IS_STRING_CONSTANT_VALUE } from '../proxy-form/validators/is-string-value-proxy-validator-factory';
import { ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT } from '~/source/management/integrations/risk-manager/containers/brand-platform-mt4-group-asset-item-dialog/asset-margin-and-swaps-can-not-all-be-default.proxy-validator';
import { ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR } from '@proftit/proxy-bean';
import {
  IS_NOT_EMPTY_PROXY_VALIDATOR,
  IS_NOT_NIL_PROXY_VALIDATOR,
  IS_URL_PROXY_VALIDATOR,
} from '@proftit/proxy-bean-validators-general';
import { FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED } from '~/source/management/automation/containers/auto-assignment/ftd-assigment-manager/ftd-auto-assignment-dest-is-not-empty-if-source-is-filled.proxy-validator';
import { FTD_AUTO_ASSIGNMENT_SOURCE_IS_NOT_EMPTY_IF_DEST_IS_FILLED } from '~/source/management/automation/containers/auto-assignment/ftd-assigment-manager/ftd-auto-assignment-source-is-not-empty-if-dest-is-filled.proxy-validator';
import { FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED } from '~/source/management/automation/containers/auto-assignment/ftd-assigment-manager/ftd-auto-assignment-when-other-filed-user-must-is-filled.proxy-validator';
import { FTD_AUTO_ASSIGNMENTS_UNIQUE_BY_SOURCE_DESK } from '~/source/management/automation/containers/auto-assignment/ftd-assigment-manager/ftd-auto-assignments-uniuqe-by-source-desk.proxy-validator';
import { IS_NUMBER_BELOW } from '../proxy-form/validators/is-number-below-proxy-validator-factory';
import { IS_NUMBER_NONE_NEGATIVE } from '../proxy-form/validators/is-number-none-negative-proxy-validator';
import { IS_NUMBER_IN_RANGE } from '../proxy-form/validators/is-number-in-range-proxy-validator-factory';

export function generatePrfProxyFormValidtionMessage(
  messageInfo,
  $translate: translate.ITranslateService,
) {
  return switchOn(
    {
      [ALL_CHILDREN_ARE_VALID_PROXY_VALIDATOR]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [FTD_AUTO_ASSIGNMENT_SOURCE_IS_NOT_EMPTY_IF_DEST_IS_FILLED]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [FTD_AUTO_ASSIGNMENTS_UNIQUE_BY_SOURCE_DESK]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [FTD_AUTO_ASSIGNMENT_WHEN_OTHER_FILLED_USER_MUST_BE_FILLED]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [IS_NOT_NIL_PROXY_VALIDATOR]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [IS_NOT_EMPTY_PROXY_VALIDATOR]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [IS_NUMBER]: () => simpleCodeTranslate(messageInfo.code, $translate),
      [IS_NUMBER_BELOW]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [IS_NUMBER_IN_RANGE]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [IS_NUMBER_NONE_NEGATIVE]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
      [IS_STRING_CONSTANT_VALUE]: () =>
        Promise.resolve(
          `Text value must be of those values: [${messageInfo.payload.validValues.toString()}]`,
        ),
      [IS_URL_PROXY_VALIDATOR]: () =>
        simpleCodeTranslate(messageInfo.code, $translate),
    },
    messageInfo.code,
    () => Promise.resolve('general error'),
  );
}

function simpleCodeTranslate(
  code: string,
  $translate: translate.ITranslateService,
) {
  const translatePath = `proxyFormValidationMessages.${code}`;
  return $translate(translatePath).catch(() => null);
}
