export const customEmailTemplatesTableCols = [
  {
    title: 'customEmailTemplatesTable.fields.NAME',
    field: '',
    fieldFormat: '{{ name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.DESIGN_TEMPLATE',
    field: '',
    fieldFormat: '{{ designTemplate.name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.BRANDS',
    field: '',
    fieldFormat: '{{ brands | prfAsTextList:"name":"," | prfLimitToDots:30 }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.DEPARTMENTS',
    field: '',
    fieldFormat:
      '{{ departments | prfAsTextList:"name":"," | prfLimitToDots:30 }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.IS_PUBLISHED',
    field: '',
    fieldFormat:
      '{{ isActive | booleanToWord:"booleanToWord.active" | translate }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.CREATED_BY',
    field: '',
    fieldFormat: '{{ createdBy.firstName }} {{ createdBy.lastName }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.CREATED_AT',
    field: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'customEmailTemplatesTable.fields.LANGUAGE',
    field: 'language',
    fieldFormat: '{{ ("lang." + language.code2) | translate }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'languageId',
  },
  {
    title: '',
    field: '',
    fieldName: 'actions',
    fieldFormat: '',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
];
