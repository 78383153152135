import template from './stats.html';
import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';

class StatsController extends BaseController {
  stats;

  /*@ngInject*/
  constructor(readonly $scope: IScope) {
    super();
  }
}

export default {
  bindings: {
    stats: '<',
  },
  template,
  controller: StatsController,
};
