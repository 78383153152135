import ng from 'angular';
// import { ILightbox } from 'angular-bootstrap-lightbox';
import lightBoxTemplate from 'angular-bootstrap-lightbox/src/lightbox.html';
import * as _ from '@proftit/lodash';

import TableController from '~/source/common/components/table/table.controller';
import {
  Customer,
  CustomerComplianceFileType,
  CustomerRegulationFile,
} from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import contactCompliancePreviewTableSettings from './contact-compliance-preview-table-settings';
import TableSettings from '~/source/common/models/table-settings';
import TableColumnSettings from '~/source/common/models/table-column-settings';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';

import template from './contact-compliance-preview-dialog.component.html';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { Entity } from '@proftit/crm.api.models.general';
import { CustomerComplianceFileTypeCode } from '@proftit/crm.api.models.enums';
import { Attachment } from '~/source/common/models/attachment';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  ATTACHMENT_REMOVED_FROM_DEPOSIT,
  CUSTOMER_REGULATION_FILE_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';
import type { ILightbox } from '~/source/types/ilightbox';
import { PrivateGoogleStorageFileService } from '~/source/common/services/private-google-storage-file.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';

interface ComponentBindings {
  customer: Customer;
  complianceDocTypeId: number;
  complianceDocType: CustomerComplianceFileType;
  config: TableSettings;
}

interface DataInfo {
  isEdit: boolean;
  fileType: string;
  viewItem: any;
  editItem?: any;
}

const defaultViewRenderer = {
  type: 'general',
};

const complianceTypesWithScoringAbility = [
  CustomerComplianceFileTypeCode.Qestionnaire as string,
];

class Controller extends TableController implements ComponentBindings {
  static $inject = [
    'customersService',
    'Lightbox',
    '$window',
    'prfClientGeneralPubsub',
    'privateGoogleStorageFileService',
    ...TableController.$inject,
  ];

  /*
   * bindings
   */

  close: ($value: any) => void;
  dismiss: ($value: any) => void;

  toggleDetailRowAction = new rx.Subject<number>();

  /**
   * modal dialog component gets passed data by the 'resolve' binding parameter.
   * We use a setter to automatically extract the incoming value and destructur
   * it to the different values passed.
   */
  set resolve(val: ComponentBindings) {
    if (_.isNil(val)) {
      return;
    }
    this.customer = val.customer;
    this.complianceDocTypeId = val.complianceDocTypeId;
    this.complianceDocType = val.complianceDocType;
    this.config = val.config;
  }

  customer: Customer;
  complianceDocTypeId: number;
  complianceDocType: CustomerComplianceFileType;
  config: TableSettings;

  /*
   * DI
   */

  customersService: () => CustomersService;
  Lightbox: ILightbox;
  $window: ng.IWindowService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  privateGoogleStorageFileService: PrivateGoogleStorageFileService;

  /*
   * Locals
   */

  dataServiceInstance: CustomersService;
  cols: TableColumnSettings[];
  attachments: ICollectionRestNg<CustomerRegulationFile>;
  dataInfos: {
    [key: string]: DataInfo;
  } = {};

  lifecycles: {
    onInit$: rx.Observable<void>;
    onInitShared$: rx.Observable<boolean>;
    onChanges$: rx.Observable<any>;
    onDestroy$: rx.Observable<any>;
  } = observeComponentLifecycles(this);

  blockUiId = generateBlockuiId();

  viewAttachmentAction = new rx.Subject<CustomerRegulationFile>();
  viewPDFAttachmentAction = new rx.Subject<CustomerRegulationFile>();
  viewImageAttachmentAction = new rx.Subject<CustomerRegulationFile>();
  opCalcGlobalEditMode$ = new rx.Subject<void>();
  globalEditMode$ = this.streamGlobalEditMode();
  detailsRowsInfo$ = this.streamDetailsRowsInfo();

  constructor(...args) {
    super(...args);
    useStreams(
      [
        this.streamViewAttachment(),
        this.streamViewPDFAttachment(),
        this.streamViewImageAttachment(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.settings = {
      ...contactCompliancePreviewTableSettings,
      ...(this.config || {}),
    };
    this.dataServiceInstance = this.customersService();
    this.cols = [...this.settings.tableColumns];

    this.initTable();
  }

  $onDestroy() {}

  $onChanges() {}

  streamGlobalEditMode() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.opCalcGlobalEditMode$,
          this.lifecycles.onInitShared$.pipe(
            rx.filter((hasHappened) => hasHappened),
          ),
        ),
      rx.map(() =>
        Object.keys(this.dataInfos).some(
          (keyName) => this.dataInfos[keyName].isEdit,
        ),
      ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamDetailsRowsFromCollection() {
    return rx.pipe(
      () => this.collection$ as rx.Observable<any[]>,
      rx.map((items) => items.map((item) => generateRowInfo(item))),
      rx.map((rowsInfos) => _.keyBy((r) => r.itemId, rowsInfos)),
      shareReplayRefOne(),
    )(null);
  }

  streamDetailsRowsFromToggleRowAction(
    detailsRowsInfo$: rx.Observable<Record<number, RowInfo>>,
  ) {
    return rx.pipe(
      () => this.toggleDetailRowAction,
      rx.withLatestFrom(detailsRowsInfo$),
      rx.map(([rowId, detailsRowsInfo]) => {
        const rowInfo = detailsRowsInfo[rowId];

        return {
          ...detailsRowsInfo,
          [rowId]: {
            ...rowInfo,
            isOpen: !rowInfo.isOpen,
          },
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDetailsRowsInfo() {
    const detailsRowsInfoSubject = new rx.BehaviorSubject<
      Record<number, RowInfo>
    >({});

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamDetailsRowsFromCollection(),
          this.streamDetailsRowsFromToggleRowAction(detailsRowsInfoSubject),
        ),
      rx.tap((info) => detailsRowsInfoSubject.next(info)),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return false;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getAttachmentsResource(this.customer.id)
      .setConfig({
        growlRef: 'compliancePreviewDialogTable',
        blockUiRef: 'compliancePreviewDialogTable',
      })
      .expand(['complianceDocType', 'file'])
      .embed(['questionnaireCustomerScores'])
      .filter({
        complianceDocTypeId: this.complianceDocTypeId,
      });
  }

  parseLoadedData(data) {
    this.attachments = data;
    this.dataInfos = this.calcDataInfos(data.plain()) as any;

    return data;
  }

  calcDataInfos(data: CustomerRegulationFile[]) {
    return _.flow(
      () => _.keyBy((item) => item.id, data),
      (hash) => _.mapValues((item) => this.calcDataInfo(item), hash),
    )();
  }

  calcDataInfosForOneChange(
    dataInfos: Record<number, DataInfo>,
    change: CustomerRegulationFile,
  ) {
    return _.flow([
      () => _.toPairs(dataInfos),
      (pairs) =>
        pairs.reduce((acc, [id, dataInfo]) => {
          const idAsNum = parseInt(id, 10);

          if (idAsNum !== change.id) {
            return [...acc, [idAsNum, dataInfo]];
          }

          const newDataInfo = this.calcDataInfo(change);
          return [...acc, [idAsNum, newDataInfo]];
        }, []),
      (pairs) => _.fromPairs(pairs),
    ])();
  }

  calcDataInfo(item: CustomerRegulationFile): DataInfo {
    const tempViewCols = this.cols.map((col) => {
      const modelInit = _.get('renderers.view.modelInit', col);
      let value;

      if (_.isNil(modelInit)) {
        value = item[col.fieldName];
      } else {
        value = modelInit(item[col.fieldName]);
      }

      return { value, name: col.fieldName };
    });
    const viewItem = _.flow(
      () => _.keyBy((x) => x.name, tempViewCols),
      (x) => _.mapValues((val) => val.value, x),
    )();

    return {
      viewItem,
      fileType: item.file.type,
      isEdit: false,
    };
  }

  getRowRenderer(row, col) {
    const editRenderer = this.dataInfos[row.id].isEdit
      ? _.get(['renderers', 'edit'], col)
      : null;
    let viewRenderer = _.get(['renderers', 'view'], col);
    if (_.isNil(viewRenderer)) {
      viewRenderer = defaultViewRenderer;
    }

    return editRenderer ? editRenderer : viewRenderer;
  }

  startRowEdit(row: IElementRestNg<CustomerRegulationFile>) {
    this.dataInfos = _.set([row.id, 'isEdit'], true, this.dataInfos);
    this.initModelEditFields(row);
    this.opCalcGlobalEditMode$.next();
  }

  cancelRowEdit(row: IElementRestNg<CustomerRegulationFile>) {
    this.dataInfos = _.set([row.id, 'isEdit'], false, this.dataInfos);
    this.dataInfos = _.set([row.id, 'editItem'], null, this.dataInfos) as any;
    this.opCalcGlobalEditMode$.next();
  }

  saveRowEdit(original: IElementRestNg<CustomerRegulationFile>, canvas) {
    this.dataInfos = _.set([original.id, 'isEdit'], false, this.dataInfos);

    this.setModelFromEditFields(original, canvas);
    this.dataInfos = this.calcDataInfosForOneChange(
      this.dataInfos,
      original.plain(),
    );
    this.opCalcGlobalEditMode$.next();
    original.patch().then((res) => {
      this.prfClientGeneralPubsub.publish(
        CUSTOMER_REGULATION_FILE_UPDATED,
        res,
      );
    });
  }

  initModelEditFields(item: CustomerRegulationFile) {
    const tempEditCols = this.cols.map((col) => {
      let modelInit = _.get('renderers.edit.modelInit', col);
      if (_.isNil(modelInit)) {
        modelInit = _.get('renderers.view.modelInit', col);
      }

      let value;

      if (_.isNil(modelInit)) {
        value = item[col.fieldName];
      } else {
        value = modelInit(item[col.fieldName]);
      }

      return { value, name: col.fieldName };
    });
    const editItem = _.flow(
      () => _.keyBy((x) => x.name, tempEditCols),
      (x) => _.mapValues((val) => val.value, x),
    )();

    this.dataInfos = _.set([item.id, 'editItem'], editItem, this.dataInfos);
  }

  setModelFromEditFields(original: CustomerRegulationFile, canvas) {
    this.cols.forEach((col) => {
      const editRenderer = _.get('renderers.edit', col);
      if (_.isNil(editRenderer)) {
        return;
      }

      const modelSave = _.get('renderers.edit.modelSave', col);
      let value;

      if (_.isNil(modelSave)) {
        value = canvas[col.fieldName];
      } else {
        value = modelSave(canvas[col.fieldName]);
      }

      original[col.fieldName] = value;
    });
  }

  deleteAttachment(attachment: IElementRestNg<CustomerRegulationFile>) {
    return this.dataServiceInstance
      .removeEntityCompliance(attachment)
      .then((data) => {
        const inx = _.findIndex(
          (item: CustomerRegulationFile) => item.id === attachment.id,
          this.attachments.plain(),
        );

        if (inx < 0) {
          return;
        }

        this.reloadTable();

        if (
          (attachment.complianceDocTypeCode =
            CustomerComplianceFileTypeCode.DepositDocument)
        ) {
          this.prfClientGeneralPubsub.publish(
            ATTACHMENT_REMOVED_FROM_DEPOSIT,
            data,
          );
        }
      });
  }

  streamViewAttachment() {
    return rx.pipe(
      () => this.viewAttachmentAction,
      rx.tap((attachment: CustomerRegulationFile) => {
        if (attachment.file.type === 'application/pdf') {
          this.viewPDFAttachmentAction.next(attachment);
        } else {
          this.viewImageAttachmentAction.next(attachment);
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamViewPDFAttachment() {
    return rx.pipe(
      () => this.viewPDFAttachmentAction,
      rx.switchMap((attachment) => {
        return this.privateGoogleStorageFileService.getAttachmentFileUrl(
          attachment.customerId,
          attachment.id,
          this.blockUiId,
        );
      }),
      rx.tap((attachmentFileUrl: string) => {
        this.$window.open(attachmentFileUrl, '_blank');
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamViewImageAttachment() {
    return rx.pipe(
      () => this.viewImageAttachmentAction,
      rx.map((attachment) => {
        const displayableAttachments = _.filter(
          (item: CustomerRegulationFile) =>
            item.file.type !== 'application/pdf',
          this.attachments,
        );
        const index = _.findIndex(
          (item: CustomerRegulationFile) => item.id === attachment.id,
          displayableAttachments,
        );
        return {
          displayableAttachments,
          index,
        };
      }),
      rx.filter(({ displayableAttachments, index }) => index >= 0),
      rx.switchMap(({ displayableAttachments, index }) => {
        const attachmentUrlObservables = displayableAttachments.map(
          (localAttachment) => {
            return this.privateGoogleStorageFileService.getAttachmentFileUrl(
              localAttachment.customerId,
              localAttachment.id,
              this.blockUiId,
            );
          },
        );
        return rx.obs.combineLatest(attachmentUrlObservables).pipe(
          rx.map((fileUrls) => {
            return {
              fileUrls,
              index,
            };
          }),
        );
      }),
      rx.tap(({ index, fileUrls }: { index: number; fileUrls: any[] }) => {
        this.Lightbox.openModal(fileUrls, index, {
          templateurl: null,
          template: lightBoxTemplate,
        });
      }),
      shareReplayRefOne(),
    )(null);
  }
}

interface RowInfo {
  itemId: number;
  isOpen: boolean;
  hasScoringAbility: boolean;
  hasDetailsRow: boolean;
}

function generateRowInfo(item: Attachment): RowInfo {
  const hasScoringAbility = complianceTypesWithScoringAbility.includes(
    item.complianceDocType.code,
  );
  const hasDetailsRow = hasScoringAbility;

  return {
    hasScoringAbility,
    hasDetailsRow,
    itemId: item.id,
    isOpen: false,
  };
}

export const ContactCompliancePreviewDialogComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};

export default ContactCompliancePreviewDialogComponent;
