import * as _ from '@proftit/lodash';
import { CustomerStoreService } from '~/source/contact/common/services/customer-store.service';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { CrmAppStoreProviderController } from '~/source/app/cfm-app-store-provider.component';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';

export class CustomerStoreProviderController {
  prfCrmAppStoreProvider: CrmAppStoreProviderController;

  customerStoreService: CustomerStoreService;

  customer: Customer;

  lifecycles$ = observeComponentLifecycles(this);

  customerIn$ = observeShareCompChange(this.lifecycles$.onChanges$, 'customer');

  // @ngInject
  constructor(
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    useStreams([this.customerIn$], this.lifecycles$.onDestroy$);
  }

  streamSetCustomer() {
    return rx.pipe(
      () => this.customerIn$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((customer) => {
        this.customerStoreService.setCustomer(customer);
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {
    this.customerStoreService = new CustomerStoreService(
      this.customersService,
      this.prfCrmAppStoreProvider,
      this.modelNormalizer,
    );

    useStreams(
      [this.customerStoreService.customer$, this.streamSetCustomer()],
      this.lifecycles$.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}
}

export const CustomerStoreProviderComponent = {
  controller: CustomerStoreProviderController,
  bindings: {
    customer: '<',
  },
  template: '<ng-transclude></ng-transclude>',
  transclude: true,
  require: {
    prfCrmAppStoreProvider: '^',
  },
};
