import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { Brand } from '@proftit/crm.api.models.entities';
import { streamReorderActiveCompanyConnections } from './clearing-company-connections-from-reorder';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import * as _ from '@proftit/lodash';

export const streamClearingCompanyConnectionsForBrand = (
  clearingCompanyConnectionsForBrandFromData$,
  reorderActiveCompanyConnectionsAction: rx.Subject<any>,
  clearingCompanyConnectionsService: ClearingCompanyConnectionsService,
  blockUiId: string,
  brandFormControl: FormControl<Brand>,
) => {
  const clearingCompanies$ = new rx.BehaviorSubject<any>([]);
  return rx.pipe(
    () =>
      rx.obs.merge(
        clearingCompanyConnectionsForBrandFromData$,
        streamReorderActiveCompanyConnections(
          reorderActiveCompanyConnectionsAction,
          clearingCompanies$,
          clearingCompanyConnectionsService,
          blockUiId,
          brandFormControl.value ? brandFormControl.value.id : null,
        ),
      ),
    rx.tap((newValue: any) => {
      const activeConnections = newValue.filter((conn) => conn.isActive);
      const sortedActiveConnections = _.sortBy(
        (connection) => connection.order,
        activeConnections,
      );
      const disabledConnections = newValue.filter((conn) => !conn.isActive);
      const allConnections = [
        ...sortedActiveConnections,
        ...disabledConnections,
      ];
      clearingCompanies$.next(allConnections);
    }),
    shareReplayRefOne(),
  )(null) as rx.Observable<any[]>;
};
