export const YesOption = {
  id: 1,
  name: 'Yes',
};

export const NoOption = {
  id: 0,
  name: 'No',
};

export const ReportsBooleanSelectOptions = [YesOption, NoOption];
