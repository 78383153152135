import * as _ from '@proftit/lodash';

const IBAN_LENGTH = 35;

/**
 */
export function ibanValidator(value, scope, element, attrs, param) {
  if (_.isEmpty(value)) {
    return true;
  }

  return value.length <= IBAN_LENGTH;
}

export default ibanValidator;
