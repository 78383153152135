import template from './question-field.html';

export default {
  template,
  bindings: {
    model: '<',
    onChange: '&',
  },
  controllerAs: 'vm',
};
