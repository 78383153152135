/**
 * Formats a currency using the app config's 'base currency'
 *
 * @example
 * {{1050.57 | baseCurrencyFormat }} -> "USD 1050"
 * {{1050.57 | baseCurrencyFormat : 2 }} -> "USD 1050"
 * {{1050.57 | baseCurrencyFormat : 2 : true }} -> "$1050"
 *
 * @param {object} appConfig - appCurrency object (injected)
 * @param {function} $filter - Angular filter service
 * @return {function(string, number, boolean): string} angular filter function.
 * @constructor
 */
export function baseCurrencyFormatFactory(appConfig, $filter) {
  return (amount, digits = 2, useSign = false) => {
    const currencySign = useSign
      ? appConfig.baseCurrency.sign
      : `${appConfig.baseCurrency.code} `;

    return $filter('currency')(amount, currencySign, digits);
  };
}

baseCurrencyFormatFactory.$inject = ['appConfig', '$filter'];

export default baseCurrencyFormatFactory;
