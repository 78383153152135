import * as _ from '@proftit/lodash';

/**
 * Filter factory. Return filter to do snake case conversion.
 *
 * @returns {filter} filter instance function
 */
export function snakeCaseFilterFactory() {
  /**
   * Filter function. Convert string to snake case.
   *
   * @param {string} str - string.
   * @returns {string} converted string.
   *
   * @example
   *
   *    {{ 'hello there' | prfSnakeCase }}
   */
  function filter(str) {
    return _.snakeCase(str);
  }

  return filter;
}

export default snakeCaseFilterFactory;
