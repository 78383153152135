import BonusCommonController from '../bonus-common.controller';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

import template from './bonus-deduct.html';

const DEPOSIT_SAFEGUARD_TIME = 300;

class BonusDeductController extends BonusCommonController {
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  lifecycles = observeComponentLifecycles(this);

  deductBonusAction = new rx.Subject<void>();

  constructor(...args) {
    super(...args);

    useStreams([this.streamDeductBonus()], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  /**
   * Called before posting funds bonus
   */
  streamDeductBonus() {
    return rx.pipe(
      () => this.deductBonusAction,
      rx.debounceTime(DEPOSIT_SAFEGUARD_TIME),
      rx.tap(() => {
        /*
         * bonus amount is entered as positive value,
         * we should convert it to negative
         */
        this.makeDeposit({
          amount: this.bonus.amount * -1,
          statusCode: 'negative',
          note: this.bonus.note,
        });
      }),
    )(null);
  }
}

export default {
  template,
  controller: BonusDeductController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
  },
};
