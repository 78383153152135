import PendingPositionsTableBase from '../../common/pending-positions/pending-positions-table-base';
import PendingPositionsForexSocketService from '~/source/common/services/pending-positions-forex-socket.service';
import pendingPositionsSettings from '../pending-positions-forex-settings';

import template from './pending-positions-table-forex.component.html';

class PendingPositionsTableForexController extends PendingPositionsTableBase {
  static $inject = [
    'pendingPositionsForexSocketService',
    ...PendingPositionsTableBase.$inject,
  ];

  pendingPositionsForexSocketService: PendingPositionsForexSocketService;

  get cols() {
    return [...pendingPositionsSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...pendingPositionsSettings.table.ngTable };
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Object} Socket service instance
   */
  get socketService() {
    return this.pendingPositionsForexSocketService;
  }

  /**
   * Build a request to fetch forex pending positions.
   * Called by parent's "getData" ngTable method
   * @returns {RestService} restService instance, for chaining
   */
  getDataRequest() {
    return this.dataServiceInstance
      .getPendingOrdersResource(this.customer.id, 'all', this.account.id)
      .setConfig({ blockUiRef: 'positionsTable' })
      .expand(['status', 'tradeAsset', 'tradingAccount.currency'])
      .sort({ openTime: 'desc' });
  }

  parseLoadedData(pendingPositions) {
    this.pendingPositions = pendingPositions;
    return pendingPositions;
  }
}

const PendingPositionsTableForexComponent = {
  template,
  controller: PendingPositionsTableForexController,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
  },
};

export default PendingPositionsTableForexComponent;
