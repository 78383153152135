import ng from 'angular';
import Big from 'big.js';
import { isString } from '@proftit/general-utilities';

import BaseController from '~/source/common/controllers/base';

import template from './add-internal-transfer-popup.component.html';
import {
  TradingAccount,
  Customer,
  InternalTransfer,
} from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { CustomersService } from '~/source/contact/common/services/customers';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';
import { useStream } from '~/source/common/utilities/use-stream';
import { NgModelChange } from '~/source/common/utilities/create-ng-model-obs-mediator';
import { Changeset } from '~/source/common/utilities/changeset/changeset';
import * as _ from '@proftit/lodash';
import { CrudOperation } from '@proftit/request-client';

export interface IAddInternalTransferPopup {
  account: TradingAccount;
  customer: Customer;
}

export class AddInternalTransferPopupController extends BaseController
  implements IAddInternalTransferPopup {
  close: () => void;

  /*
   * Dialog bindings
   */
  customer: Customer;
  account: TradingAccount;

  customer$ = new rx.BehaviorSubject<Customer>(null);
  account$ = new rx.BehaviorSubject<TradingAccount>(null);

  unsub$ = new rx.Subject<void>();
  model$ = new rx.BehaviorSubject<Partial<InternalTransfer>>(null);
  operation$ = new rx.BehaviorSubject<CrudOperation>(CrudOperation.Create);
  opUpdateUiModel$ = new rx.Subject<NgModelChange>();
  changeset$ = new rx.BehaviorSubject<Changeset<InternalTransfer>>(null);
  doSaveOperation$ = new rx.Subject<void>();
  isFormValid$ = new rx.BehaviorSubject<boolean>(false);

  title = 'contact.tradingAccount.INTERNAL_TRANSFER';
  blockUiId = `blockUi-AddInternalTransferPopup-${_.uniqueId('')}`;
  growlId = `growlId-AddInternalTransferPopup-${_.uniqueId('')}`;

  /*@ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    super();
  }

  $onInit() {
    useStream(this.streamInitModel(), this.unsub$);
    useStream(this.streamModelUiUpdating(), this.unsub$);
    useStream(this.streamSaving(), this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onResolveChange(resolve: IAddInternalTransferPopup) {
    this.customer$.next(resolve.customer);
    this.account$.next(resolve.account);
  }

  streamInitModel() {
    return rx.pipe(
      () => this.account$,
      rx.map((account) => this.initializeInternalTransfer(account)),
      rx.tap((internalTransfer) => this.model$.next(internalTransfer)),
    )(null);
  }

  streamModelUiUpdating() {
    return rx.pipe(
      () => this.opUpdateUiModel$,
      rx.withLatestFrom(this.changeset$, this.model$),
      rx.map(([change, changeset, currentModel]) => {
        const newModel = {
          ...currentModel,
          [change.fieldName]: change.nextValue,
        };

        return newModel;
      }),
      rx.tap((newModel) => this.model$.next(newModel)),
    )(null);
  }

  streamSaving() {
    return rx.pipe(
      () => this.doSaveOperation$,
      rx.withLatestFrom(this.model$, this.account$, this.customer$),
      rx.switchMap(([action, model, account, customer]) => {
        const rate = isString(model.rate)
          ? parseFloat((model.rate as any) as string)
          : model.rate;

        const normModel = {
          ...model,
          rate,
        };

        return rx.pipe(
          () =>
            rx.obs.from(
              this.createInternalTransfer(normModel, account, customer),
            ),
          rx.tap(() => this.close()),
          rx.catchError(() => rx.obs.EMPTY),
        )(null);
      }),
    )(null);
  }

  initializeInternalTransfer(
    account: TradingAccount,
  ): Partial<InternalTransfer> {
    return {
      id: null,
      sourceTradingAccount: account,
      destinationTradingAccount: null,
      amount: 0,
      rate: 1,
      reason: null,
    };
  }

  createInternalTransfer(
    model: Partial<InternalTransfer>,
    account: TradingAccount,
    customer: Customer,
  ) {
    const normalizedModel = this.modelNormalizer.normalize(model);

    return this.customersService()
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
        errorsTranslationPath: 'internalTransfer.errors',
      })
      .createInternalTransfer(customer.id, account.id, normalizedModel);
  }
}

export const AddInternalTransferPopupComponent = {
  template,
  controller: AddInternalTransferPopupController,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};
