import BaseController from '~/source/common/controllers/base';

class MarketingViewPixelCodeModalController extends BaseController {
  pluginUrl: string;

  /*@ngInject*/
  constructor(readonly appConfig) {
    super();

    this.pluginUrl = this.appConfig.pluginUrls.bundle;
  }
}

export default MarketingViewPixelCodeModalController;
