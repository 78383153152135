import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
// import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './simple-dropdown.component.html';
import angular from 'angular';
const styles = require('./simple-dropdown.component.scss');

export class SimpleDropdownController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  placeholder: string;

  /* @ngInject */
  constructor(readonly $translate: angular.translate.ITranslateService) {}

  $onInit() {
    this.placeholder = _.defaultTo(
      this.$translate.instant('common.PLEASE_SELECT_OPTION'),
      this.placeholder,
    );
  }

  $onDestroy() {}

  $onChanges() {}
}

export const SimpleDropdownComponent = {
  template,
  controller: SimpleDropdownController,
  bindings: {
    items: '<',
    selectedItem: '=',
    selectionCompare: '<',
    placeholder: '<',
  },
};
