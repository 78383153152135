import { IScope } from 'angular';

import BaseController from '~/source/common/controllers/base';
import TokensService from '~/source/auth/services/tokens';

export class DeleteGroupModalController extends BaseController {
  $scope: IScope;
  moveToGroup;
  deactivateUsers;
  usersService;
  tokensService: TokensService;
  group;
  $close: () => void;

  hideSystemGroups: boolean;

  $onInit() {
    this.hideSystemGroups = !this.tokensService.getCachedUser().isSystem;
  }

  /**
   * Move the deleted group's user to another group and deactivate the users if needed.
   *
   * @return {Promise} - resolved when patch is completed
   */
  moveUsers() {
    const patch: { userGroupId: number; isActive?: boolean } = {
      userGroupId: this.moveToGroup.id,
    };

    if (this.deactivateUsers) {
      patch.isActive = false;
    }

    return (
      this.usersService()
        .setConfig({ blockUiRef: 'groupDelete', growlRef: 'groupDelete' })
        // select all the users of deleted groups and apply the patch on them
        .filter('userGroupId', this.group.id)
        .patchCollection('filter', patch)
    );
  }

  /**
   * Called when approving the operation in the delete group dialog.
   * Moves the user, then deletes the group.
   */
  ok() {
    // first, move the users to another group
    this.moveUsers()
      // then remove the group
      .then(() => this.group.remove())
      .then(() => {
        this.$scope.$emit('group.deleted');
        this.$close();
      });
  }
}

DeleteGroupModalController.$inject = [
  '$scope',
  'userGroupsService',
  'usersService',
  'tokensService',
  ...BaseController.$inject,
];

export default DeleteGroupModalController;
