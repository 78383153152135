import template from './security-manager.component.html';

const styles = require('./security-manager.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { Brand } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
import BrandsService from '~/source/management/brand/services/brands';
import { CustomerLoginTypeCode } from '@proftit/crm.api.models.enums';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

export class SecurityManagerController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  isEdit = false;

  brand: Brand;

  formName = 'securityForm';
  blockUiRef = generateBlockuiId();
  growlRef = generateGrowlId();

  securityDataChangeSubject = new rx.BehaviorSubject<{
    isCaptchaEnabled: boolean;
    captchaSiteKey: string;
    captchaSecretKey: string;
    is2faSignupEnabled: boolean;
    is2faLoginEnabled: boolean;
  }>({
    isCaptchaEnabled: false,
    captchaSiteKey: '',
    captchaSecretKey: '',
    is2faSignupEnabled: false,
    is2faLoginEnabled: false,
  });

  brandIn$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  isTwoFactorPasswordResetDisabled$ = this.streamIsTwoFactorPasswordResetDisabled();

  /*@ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    useStreams([this.brandIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  enterEdit() {
    this.isEdit = true;
  }

  cancelEdit() {
    this.isEdit = false;
  }

  /**
   * Save brand with new smsProvider + smsCredentials (just for 'edit' mode)
   * (create is saved straight from /create.js)
   *
   * @returns {Promise} resolves to the updated brand record on success
   */
  save({
    isCaptchaEnabled,
    captchaSiteKey,
    captchaSecretKey,
    is2faSignupEnabled,
    is2faLoginEnabled,
    is2faPswdResetEnabled,
  }) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.blockUiRef, growlRef: this.growlRef })
      .updateSecuritySettings(
        this.brand.id,
        isCaptchaEnabled,
        captchaSiteKey,
        captchaSecretKey,
        is2faSignupEnabled,
        is2faLoginEnabled,
        is2faPswdResetEnabled,
      )
      .then((updatedBrand) => {
        // change to display mode
        this.isEdit = false;
        this.brand.isCaptchaEnabled = updatedBrand.isCaptchaEnabled;
        this.brand.captchaSiteKey = updatedBrand.captchaSiteKey;
        this.brand.captchaSecretKey = updatedBrand.captchaSecretKey;
        this.brand.is2faSignupEnabled = updatedBrand.is2faSignupEnabled;
        this.brand.is2faLoginEnabled = updatedBrand.is2faLoginEnabled;
        this.brand.is2faPswdResetEnabled = updatedBrand.is2faPswdResetEnabled;

        return updatedBrand;
      });
  }

  update({
    isCaptchaEnabled,
    captchaSiteKey,
    captchaSecretKey,
    is2faSignupEnabled,
    is2faLoginEnabled,
    is2faPswdResetEnabled,
  }) {
    this.brand.isCaptchaEnabled = isCaptchaEnabled;
    this.brand.captchaSiteKey = captchaSiteKey;
    this.brand.captchaSecretKey = captchaSecretKey;
    this.brand.is2faSignupEnabled = is2faSignupEnabled;
    this.brand.is2faLoginEnabled = is2faLoginEnabled;
    this.brand.is2faPswdResetEnabled = is2faPswdResetEnabled;
  }

  streamIsTwoFactorPasswordResetDisabled() {
    return rx.pipe(
      () => this.brandIn$,
      rx.map(
        (brand) => brand.customerLoginType === CustomerLoginTypeCode.Phone,
      ),
      shareReplayRefOne(),
    )(null);
  }
}

export const SecurityManagerComponent = {
  template,
  controller: SecurityManagerController,
  bindings: {
    brand: '<',
    isCreate: '<',
  },
};
