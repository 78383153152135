import Component from '~/source/common/components/dropdowns/base/component';

class PositionsProductsController extends Component.controller {
  static $inject = [
    'positionsProductsService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'position.SELECT_PRODUCT';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'position.products';
  }

  get nullableElement() {
    return {
      id: null,
      code: 'null',
      nullable: true,
    };
  }
}

export default Component.config({ controller: PositionsProductsController });
