import template from './bundles-table-popup.component.html';

import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import TablePopupController from '~/source/common/components/table/table-popup';
import { bundlesTablePopupSettings } from './bundles-table-popup-settings';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { bundlesTableCols } from '../bundles-table-cols';
import { ICollectionRestNg } from '~/source/common/models/icollection-rest-ng';
import { Bundle } from '~/source/common/models/bundle';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { useResolve } from './use-resolve';
import { BrandsService } from '~/source/management/brand/services/brands';

interface RowInfo {
  bundle: Bundle;
  isSubTableActionOpen: boolean;
}

class BundlesTablePopupController extends TablePopupController {
  static $inject = [
    'customersService',
    'brandsService',
    ...TablePopupController.$inject,
  ];

  customersService: () => CustomersService;

  brandsService: () => BrandsService;

  lifecycles = observeComponentLifecycles(this);

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  resolveCt = useResolve<{ customer: Customer; account: TradingAccount }>(
    this.lifecycles,
  );

  customer$ = this.resolveCt.resolve$.pipe(
    rx.map((resolve) => (resolve ? resolve.customer : null)),
    shareReplayRefOne(),
  );

  account$ = this.resolveCt.resolve$.pipe(
    rx.map((resolve) => (resolve ? resolve.account : null)),
    shareReplayRefOne(),
  );

  dataServiceInstance: CustomersService;

  customer: Customer;
  account: TradingAccount;
  incomingRows$ = new rx.Subject<Bundle[]>();
  rowsInfos$ = new rx.BehaviorSubject<{ [id: number]: RowInfo }>({});
  opOpenCloseSubTable$ = new rx.Subject<number>();

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamCalcRowsInfo(),
        this.streamSubTableOpening(),
        this.streamSyncResolveToState(),
        this.streamLoginToPlatformSession(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  streamCalcRowsInfo() {
    return rx.pipe(
      () => this.incomingRows$,
      rx.map((bundles) => bundles.map((b) => this.createRowInfoForBundle(b))),
      rx.map((rowsInfosArray) => _.keyBy((o) => o.bundle.id, rowsInfosArray)),
      rx.withLatestFrom(this.rowsInfos$),
      rx.map(([nextRowsInfos, rowsInfos]) => ({
        ...rowsInfos,
        ...nextRowsInfos,
      })),
      rx.tap((rowsInfos) => this.rowsInfos$.next(rowsInfos)),
    )(null);
  }

  streamSubTableOpening() {
    return rx.pipe(
      () => this.opOpenCloseSubTable$,
      rx.withLatestFrom(this.rowsInfos$),
      rx.map(([id, rowsInfos]) => rowsInfos[id]),
      rx.withLatestFrom(this.rowsInfos$),
      rx.map(([selectedRowInfo, rowsInfos]) => {
        return {
          ...rowsInfos,
          [selectedRowInfo.bundle.id]: {
            ...selectedRowInfo,
            isSubTableActionOpen: !selectedRowInfo.isSubTableActionOpen,
          },
        };
      }),
      rx.tap((rowsInfos) => this.rowsInfos$.next(rowsInfos)),
    )(null);
  }

  createRowInfoForBundle(bundle: Bundle) {
    return {
      bundle,
      isSubTableActionOpen: true,
    };
  }

  get cols() {
    return [...bundlesTableCols];
  }

  get ngTableSettings() {
    return { ...bundlesTablePopupSettings };
  }

  get title() {
    return 'contact.BUNDLES';
  }

  get tableKey() {
    return 'bundle';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getBundlesResource(this.customer.id, this.account.id)
      .embed(['positionsForex'])
      .expand([
        'tradingAccount',
        'tradingAccount.currency',
        'positionsForex.currency',
        'positionsForex.positionStatus',
        'positionsForex.tradeAsset',
        'positionsForex.tradingAccount.currency',
      ])
      .sort({ entryDate: 'desc' });
  }

  parseLoadedData(data: ICollectionRestNg<Bundle>) {
    this.incomingRows$.next(data);

    return data;
  }

  streamSyncResolveToState() {
    const streamFn = rx.pipe(
      () => this.resolveCt.resolve$,
      rx.tap((resolve) => {
        if (_.isNil(resolve)) {
          this.customer = null;
          this.account = null;
          return;
        }

        this.customer = resolve.customer;
        this.account = resolve.account;
      }),
      rx.catchError(() => {
        return streamFn(null);
      }),
    );

    return streamFn(null);
  }

  streamLoginToPlatformSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.account$,
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((service) => !_.isNil(service)),
          ),
          this.customer$,
        ),
      rx.switchMap(([account, _sessionService, customer]) => {
        return this.brandsService().getBrandPlatformPerPlatform(
          customer.brand.id,
          account.platform.code,
        );
      }),
      rx.withLatestFrom(this.prfCurrentPlatformSession$),
      rx.switchMap(([brandPlatform, sessionService]) => {
        return sessionService.login(brandPlatform);
      }),
    )(null);
  }
}

export const BundlesTablePopupComponent = {
  template,
  controller: BundlesTablePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
