export const riskManagerRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.management.integration.platforms.risk-manager', {
        redirectTo: 'crm.management.integration.platforms.risk-manager.symbols',
        template: `
          <prf-risk-manager-dashboard>
          </prf-risk-manager-dashboard>
        `,
        data: {
          permissions: {
            only: ['management.riskmanager'],
            redirectTo: 'error.unauthorized',
          },
        },
      })
      .state('crm.management.integration.platforms.risk-manager.symbols', {
        url: '/symbols',
        component: 'prfCfdPlatformBrandAssetsManager',
      })
      .state('crm.management.integration.platforms.risk-manager.securities', {
        url: '/securities',
        component: 'prfBrandPlatformMt4GroupSecuritiesManager',
      })
      .state('crm.management.integration.platforms.risk-manager.groups', {
        url: '/groups',
        component: 'prfCfdPlatformGroupsManager',
      })
      .state('crm.management.integration.platforms.risk-manager.brands', {
        url: '/brands',
        component: 'prfCfdPlatformBrandsManager',
      });
  },
];
