import RestService from '~/source/common/services/rest';

export class AllExportsService extends RestService {
  /**
   * Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'allExports';
  }

  useCache() {
    return false;
  }

  getExportsListPromise() {
    return this.getListWithQuery();
  }
}

export default AllExportsService;
