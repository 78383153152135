import * as _ from '@proftit/lodash';
import { Mt4Asset } from '@proftit/tradingcore.api.models.entities';
import { ObjectProxyHandler } from '~/source/common/proxy-form/object-proxy-handler';
import { FieldProxyHandler } from '~/source/common/proxy-form/field-proxy-handler';

export const ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT =
  'ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT';

export function assetMarginAndSwapsCanNotAllBeDefaultProxyValidator(
  context: ObjectProxyHandler<Mt4Asset>,
) {
  const relaventFields: FieldProxyHandler<any>[] = [
    context.fields.percentage as any,
    context.fields.swapLong as any,
    context.fields.swapShort as any,
  ];

  const result = relaventFields.every((f) => f.value === 'default');

  if (result) {
    return {
      code: ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT,
      isValid: false,
      payload: {
        code: ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT,
      },
    };
  }

  return {
    code: ASSET_MARGIN_AND_SWAPS_CAN_NOT_ALL_BE_DEFAULT,
    isValid: true,
  };
}
