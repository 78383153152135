import _ from 'underscore';
import template from './transactions-charts.html';
import BaseController from '~/source/common/controllers/base';
import chartTypeSettings from '../settings/chart-types.json';
import chartSettings from '../settings/charts.json';
import accountingStatsService from '~/source/accounting/accounting-stats.service';

class TransactionChartsController extends BaseController {
  chartSettings: typeof chartSettings.transactions;
  data: any[];
  filters: any[];
  options: any;
  baseId: string;

  static $inject = ['statsService', '$translate', '$scope', '$timeout'];

  constructor(
    readonly statsService: () => accountingStatsService,
    readonly $translate: angular.translate.ITranslateService,
    readonly $scope: angular.IScope,
    readonly $timeout: angular.ITimeoutService,
  ) {
    super();

    this.chartSettings = chartSettings.transactions;
    this.data = [];
    this.filters = [];
    this.options = {};
    this.baseId = _.uniqueId();

    // translate chart labels. you can find them in language file with accounting.charts.* prefix
    this.translateChartLabels().then(this.initFilters.bind(this));
  }

  /**
   * angular component changes listener
   * @param {object} changes
   */
  $onChanges(changes) {
    if (changes.defaultData && changes.defaultData.currentValue) {
      this.arrangeChartsData(changes.defaultData.currentValue);

      // update charts data
      this.data = changes.defaultData.currentValue;
    }
  }

  /**
   * |
   * create dynamic charts options
   * @private
   */
  private createChartsOptions() {
    // create charts options for amcharts
    _.each(this.chartSettings, (chart, chartName) => {
      // create object for chart
      this.options[chartName] = {};

      // merge default chart options and chart options
      Object.assign(
        this.options[chartName],
        chartTypeSettings[chart.type],
        chart,
      );

      // remove type definition. its breaks amcharts and not needed anymore
      delete this.options[chartName].type;
    });
  }

  /**
   * translate chart labels in settings
   *
   * @returns {*}
   * @private
   */
  private translateChartLabels() {
    const translationPrefix = 'accounting.charts.';
    const chartLabels = [];

    // parse charts
    _.each(this.chartSettings, (chart) => {
      chartLabels.push(
        translationPrefix + chart.allLabels[0].text.toUpperCase(),
      );
    });

    return this.$translate(chartLabels).then((translations) => {
      _.each(this.chartSettings, (chart) => {
        const label = chart.allLabels[0];
        label.text = translations[translationPrefix + label.text.toUpperCase()];
      });
    });
  }

  /**
   * add filter label
   * @param {object} filter - should have a name & filterParamName properties defined
   */
  addFilter(filter) {
    filter.originalName = filter.name;

    // don't add filter twice from the same chart
    if (
      _.where(this.filters, {
        id: filter.id,
        filterParamName: filter.filterParamName,
      }).length === 0
    ) {
      this.filters.push(filter);
    }
  }

  /**
   * remove filter label
   * @param {object} sliceData
   */
  removeFilter(sliceData) {
    this.filters = this.filters.filter(
      (f) => !(f.id === sliceData.id && f.originalName === sliceData.name),
    );
  }

  /**
   * remove all filters
   */
  removeAllFilters() {
    this.filters = [];
  }

  /**
   *
   * arrange chart data
   *
   * @param {object} data - chart raw data as received form stats server
   * @private
   */
  arrangeChartsData(data) {
    this.options.graphs = {};

    _.each(this.chartSettings, (chartSettings, chartName) => {
      _.each(data[chartName], (chartData: any) => {
        /*
         * filterParamName property, we add it to each chart slice data and pass it along to
         * filter labels directive. its the param we send to the server in api calls.
         */
        chartData.filterParamName = chartSettings.filterParamName;

        // we will use it to identify to which chart this label belongs to.
        chartData.chartName = chartName;

        /*
         * the amcharts will validate chart data on every change, this will re-draw the chart to
         * it's initial state. this is the way to tell amcharts to keep the slice pulled out.
         */
        if (
          _.findIndex(this.filters, {
            filterParamName: chartData.filterParamName,
            id: chartData.id,
          }) > -1
        ) {
          chartData.pulled = true;
        }
      });
    });
  }

  /**
   * we init deposit and withdrawal filter objects and set all needed events
   * @private
   */
  private initFilters() {
    // listen to events fired in sliced charts directives (pie & funnel)
    this.$scope.$on('slicedChart:label:added', (scope, slice) => {
      // external event fired by amcharts, $evalSync is not working well here
      this.$timeout(() => {
        this.addFilter(slice.sliceData);
      });
    });

    this.$scope.$on('slicedChart:label:removed', (scope, sliceData) => {
      // external event fired by amcharts, $evalSync is not working well here
      this.$timeout(() => {
        this.removeFilter(sliceData);
      });
    });

    this.$scope.$on('slicedChart:label:removeAll', () => {
      this.removeAllFilters();
    });

    // listen to events fired in labels directive
    this.$scope.$on('labels:label:removed', (scope, label) => {
      this.$scope.$broadcast('slicedChart:label:remove', label);
      this.removeFilter({ id: label.id, name: label.name });
    });

    // watch for filter changes then emit an event.
    this.$scope.$watchCollection('vm.filters', (nVal, oVal) => {
      if (nVal === oVal) {
        return;
      }

      // filters-changed
      this.$scope.$emit('charts:label:changed', this.filters);
    });

    // create charts options
    this.createChartsOptions();
  }
}

export default {
  template,
  controller: TransactionChartsController,
  controllerAs: 'vm',
  bindings: {
    defaultData: '<data',
  },
};
