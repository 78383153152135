import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';
import { CustomerCommunicationStatusCode } from '@proftit/crm.api.models.enums';
import * as _ from '@proftit/lodash';

const NULL_CHARACTER = '\0';
const INCOMING_LABEL_FILTER = 'Incoming';
const OUTGOING_LABEL_FILTER = 'Outgoing';

export class EmailSearchService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'emailSearch';
  }

  getSearchEmails(
    commStatusCode: CustomerCommunicationStatusCode,
    searchString: string,
    userEmail: string,
    customerId: number,
    pageToken: string,
  ) {
    return this.expand(['customer']).filter({
      customerId: !_.isNil(customerId) ? customerId : NULL_CHARACTER,
      email: userEmail,
      keyword: searchString,
      label:
        commStatusCode === CustomerCommunicationStatusCode.EmailReceived
          ? INCOMING_LABEL_FILTER
          : OUTGOING_LABEL_FILTER,
      pageToken: !_.isNil(pageToken) ? pageToken : NULL_CHARACTER,
    });
  }
}
