import template from '~/source/common/components/collection-notifications/base/collection-notifications.html';
import BaseController from '~/source/common/controllers/base';
import TokensService from '~/source/auth/services/tokens';
import SocketService, { SocketListener } from '~/source/common/services/socket';
import { UserTokenModel } from '~/source/common/models/user-token-model';

class CollectionNotificationsController extends BaseController {
  user: UserTokenModel;
  tokensService: TokensService;
  exclude: number[];
  callback: SocketListener;
  streamingService: SocketService;
  channel: string;
  updatedElements: Object[];
  accountId: number;
  translateData: { numNotifications: number };
  reloadMethod: () => void;

  constructor(streamingService, ...args) {
    super(streamingService, ...args);

    Object.assign(this, {
      streamingService,
      updatedElements: [],
      translateData: { numNotifications: 0 },
    });
  }

  $onInit() {
    this.user = this.tokensService.getCachedUser();
    /*
     * array if id`s that any update of related elements won`t cause
     * invoke of callback
     */
    this.exclude = Array.isArray(this.exclude) ? this.exclude : [];

    this.callback = this.streamingService.wrapListener(this.filter.bind(this));
    this.channel = this.buildChannel();

    this.subscribe();
  }

  /**
   * Returns channel for updates. By default only data that related
   * to selected account will be sent. Can be overridden by child classes
   *
   * @returns {string}
   */
  buildChannel() {
    return [
      `user.${this.tokensService.getCachedUser().id}`,
      '.account',
      `.${this.accountId}`,
      `.${this.streamingService.channelRoot}`,
      '.new',
    ].join('');
  }

  /**
   * Subscribe for updates to collection
   */
  subscribe() {
    this.streamingService.subscribe(this.channel, this.callback);
  }

  /**
   * Filters elements that were excluded
   *
   * @param {object} data
   */
  filter(data) {
    // prevent callback invoke on exclude elements update
    if (data.id && this.exclude.includes(data.id)) {
      return;
    }

    this.onUpdate(data);
  }

  /**
   * Unsubscribe from updates on collection
   */
  unsubscribe() {
    this.streamingService.unsubscribe(this.channel, this.callback);
  }

  /**
   * Called after data that received form streamer has been received.
   * This method should be overridden by child classes
   *
   * @param {Object} data
   */
  onUpdate(data) {
    this.updatedElements.push(data);
    this.translateData = { numNotifications: this.updatedElements.length };
  }

  /**
   * Called when refresh button has been pressed by user
   */
  reload() {
    // reset the updated elements, because they will be received from server
    this.updatedElements = [];
    this.translateData = { numNotifications: 0 };
    // call method that passed to directive
    this.reloadMethod();
  }

  /**
   * Called when containing is destroyed, unsubscribe from channel
   */
  $onDestroy() {
    this.unsubscribe();
  }
}

CollectionNotificationsController.$inject = ['$scope', 'tokensService'];

export default CollectionNotificationsController;

export const ComponentOptions = {
  template,
  controllerAs: 'vm',
  bindings: {
    exclude: '=?', // array of elements to exclude from listening
    reloadMethod: '&',
    accountId: '<',
  },
};
