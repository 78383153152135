import ng from 'angular';

import PriorityCreateComponent from './components/create';
import PriorityEditComponent from './components/edit';
import PriorityListComponent from './components/list';

const ngModule = ng.module('crm.priority', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

function config($stateProvider, $urlRouterProvider) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/automation/priority',
    '/crm/management/automation/priority/list',
  );

  $stateProvider
    .state('crm.management.automation.priority', {
      url: '/priority',
      template: '<ui-view/>',
    })
    .state('crm.management.automation.priority.list', {
      url: '/list',
      components: 'prfPriorityListComponent',
    })
    .state('crm.management.automation.priority.create', {
      url: '/create',
      components: 'prfPriorityCreateComponent',
    })
    .state('crm.management.automation.priority.edit', {
      url: '/edit-priority/:id',
      components: 'prfPriorityEditComponent',
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

ngModule
  .config(config)
  .component('prfPriorityListComponent', PriorityListComponent)
  .component('prfPriorityEditComponent', PriorityEditComponent)
  .component('prfPriorityCreateComponent', PriorityCreateComponent);

export default ngModule.name;
