import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './crud-task-popup.component.html';
import { IPromise, translate } from 'angular';
import { switchOnEx } from '@proftit/general-utilities';
import { TasksService } from '../tasks.service';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import type { Task } from '@proftit/crm.api.models.entities';

const styles = require('./crud-task-popup.component.scss');

interface CrudTaskPopupResolve {
  action: string;
  id?: number;
  dueDate?: Date;
}

class CrudTaskPopupController {
  styles = styles;

  close: () => void;

  resolve: CrudTaskPopupResolve;

  lifecycles = observeComponentLifecycles(this);

  resolve$ = observeShareCompChange<CrudTaskPopupResolve>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  id$ = this.resolve$.pipe(
    rx.map(({ id }) => id),
    shareReplayRefOne(),
  );

  action$ = this.resolve$.pipe(
    rx.map(({ action }) => action),
    shareReplayRefOne(),
  );

  dueDate$ = this.resolve$.pipe(
    rx.map(({ dueDate }) => dueDate),
    shareReplayRefOne(),
  );

  title$ = this.action$.pipe(
    rx.switchMap((action) =>
      switchOnEx(
        {
          create: () => this.$translate('common.NEW_TASK'),
          update: () => this.$translate('common.EDIT_TASK'),
        },
        action,
      ),
    ),
    shareReplayRefOne(),
  );

  task$ = rx.obs.combineLatest([this.id$, this.action$]).pipe(
    rx.withLatestFrom(this.dueDate$),
    rx.switchMap(([[id, action], dueDate]) =>
      switchOnEx(
        {
          create: () => Promise.resolve(dueDate),
          update: () => this.fetchTask(id),
        },
        action,
      ),
    ),
    shareReplayRefOne(),
  );

  /* @ngInject */
  constructor(
    readonly $translate: translate.ITranslateService,
    readonly tasksService: TasksService,
  ) {
    useStreams(
      [this.resolve$, this.id$, this.action$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  fetchTask(id: number): Promise<Task> {
    return this.tasksService
      .expand(['employee', 'creator', 'taskStatus'])
      .getOneWithQuery<IElementRestNg<Task>>(id);
  }
}

export const CrudTaskPopupComponent = {
  template,
  controller: CrudTaskPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
