import Component from '~/source/common/components/dropdowns/base/component';

class Controller extends Component.controller {
  static $inject = ['feeTypesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.PLEASE_SELECT';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'fees.types';
  }
}

export default Component.config({ controller: Controller });
