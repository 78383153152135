import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';
import TokensService from '~/source/auth/services/tokens';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';

class WithdrawalStatusUpdatePopupController extends BaseController {
  title: string;
  $close: () => void;
  cancellationReason: string;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope & {
      withdrawalRequestStatus: string;
      withdrawalRequest: WithdrawalRequest;
    },
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly tokensService: TokensService,
  ) {
    super();
  }

  $onInit() {
    this.title =
      this.$scope.withdrawalRequestStatus === 'canceled'
        ? 'contact.WITHDRAWAL_CANCELLATION'
        : 'contact.WITHDRAWAL_CLOSE';
  }

  /**
   * @param {object} withdrawalRequest
   * @param {string} status
   * @param {string} comment
   * @private
   */
  updateStatus(withdrawalRequest, status, comment) {
    const currentUser = this.tokensService.getCachedUser();
    // cancel request
    withdrawalRequest
      .patch({
        // data to patch
        transactionStatusCode: status,
        cancellationReason: comment,
      })
      .then(() => {
        // update table data on success
        withdrawalRequest.transactionStatusCode = status;
        withdrawalRequest.cancellationReason = comment;
        withdrawalRequest.handler = currentUser;
        this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, '');
      });
  }

  /**
   * Update new withdrawal request status
   *
   */
  statusUpdate() {
    this.updateStatus(
      this.$scope.withdrawalRequest,
      this.$scope.withdrawalRequestStatus === 'canceled'
        ? 'canceled'
        : 'closed',
      this.cancellationReason,
    );
    this.$close();
  }
}

export default WithdrawalStatusUpdatePopupController;
