import { SocketService } from '~/source/common/services/socket';
import { hashCode } from '../utilities/hash-code';

/**
 * Build channel name for bundle account openpnl subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildBundleAccountPnlChannel(accountSyncId: number) {
  return `{customer.${accountSyncId}}.openpnl`;
}

export class BundlePlatformAccountOpenPnlSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  token: string;
  platformStreamerUrl: string;

  get socketRegistryKey() {
    const tokenHash = hashCode(this.token);
    const urlHash = hashCode(this.platformStreamerUrl);
    return `bundlePlatformSocket-token=${tokenHash}&url=${urlHash}`;
  }

  get channelRoot() {
    return null;
  }

  getStreamerUrl() {
    return this.platformStreamerUrl;
  }

  buildSocketQuery() {
    return `token=${this.token}`;
  }

  setToken(token: string) {
    this.token = token;
  }

  setStreamerUrl(url: string) {
    this.platformStreamerUrl = `${url}`;
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }
}
