/**
 * New features, such as features that are not yet supported by server, will be enabled, disabled by a flag.
 * Place these flags here.
 *
 */
export default Object.freeze({
  AUDIT_LOGS_ATTACHMENTS: false,
  AUDIT_LOGS_WITHDRAWAL_OWNER: false,
  AUDIT_LOGS_DEPOSITS_OWNER: false,
  AUDIT_LOGS_CUSTOMER_ASSIGNMENT: false,
  AUDIT_LOGS_CUSTOMER_INFO: false,
  DESKS: true,
  STATUSES_CONFIGURABLE_CLEARING_COMPANIES: true,
  DELETE_DEPOSITS: true,
  DELETE_WITHDRAWALS: true,
  FLAG_MT4_OPEN_PNL_STREAM: true,
  ACCOUNT_STATUS_AUTOMATION: true,
});

export const FLAG_MT4_OPEN_PNL_STREAM = 'FLAG_MT4_OPEN_PNL_STREAM';
