import SocketService from '~/source/common/services/socket';

/**
 * This socket service listens to the same channel as the "customers-socket".
 * However, it "whitelists" a different set of attributes - only ones that are related to the
 * customer accounts summary.
 * It also applies no parsing (unlike the customers-socket) so it's faster, when only the summary is wanted.
 * It shouldn't burden the streamer load, as "subscribe" happens only one time anyway, regardless of the number
 * of listeners.
 */
export default class AccountSummarySocketService extends SocketService {
  get channelRoot() {
    return 'Customer';
  }

  get whiteList() {
    return [
      'balanceTotal',
      'creditTotal',
      'depositTotal',
      'pnlTotal',
      'turnoverTotal',
      'withdrawalTotal',
      'internalTransferTotal',
      'bonusTotal',
      'winRateTotal',
      'marginTotal',
      'equityTotal',
    ];
  }
}
