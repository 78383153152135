// This file was generated automatically. Tread lightly.

export enum QuestionnaireComponentTypeCode {
  BooleanQuestion = 'boolean_question',
  Confirm = 'confirm',
  Header = 'header',
  MultiSelect = 'multi_select',
  Question = 'question',
  SingleSelect = 'single_select',
}
