import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import { TradingAccount } from '@proftit/crm.api.models.entities';
import BaseController from '~/source/common/controllers/base';

import template from './reset-password-form.component.html';
import * as rx from '@proftit/rxjs';

class Controller extends BaseController {
  /*
   * bindings
   */

  account: TradingAccount;
  validationTrigger: rx.Subject<boolean>;
  onFormValidChange: (x: { value: boolean }) => null;
  onSetPassword: (x: { value: string }) => null;

  /*
   * locals
   */

  /**
   * Local state - used for notifying observables chains that the component is
   * destroyed and all subscriptions should be disposed of. Each subscription chain
   * that starts with `takeUntil($this.unsub$)` will be shortcuted and exited.
   */
  unsub$ = new rx.Subject();

  formName = _.uniqueId('resetPasswordForm');
  password$: rx.BehaviorSubject<string> = new rx.BehaviorSubject('');
  confirmPassword$: rx.BehaviorSubject<string> = new rx.BehaviorSubject('');
  inputValidations$: rx.Subject<{
    key: string;
    isValid: boolean;
  }> = new rx.Subject();
  formValidationState$ = new rx.BehaviorSubject({
    password: false,
    confirmPassword: false,
  });

  /*
   * Lifecycles
   */

  /*@ngInject */
  constructor(readonly $scope: IScope, readonly $validation: any) {
    super();
  }

  /**
   * Component life cycle - on init
   *
   * @return {void}
   */
  $onInit() {
    this.setChainUpdateFormValidationsStauts();
    this.setChainNotifyOnFormValidStatusChange();
    this.setChainNotifyPasswordChange();

    this.validateWhenRequestedObs(
      this.unsub$,
      this.validationTrigger,
    ).subscribe();
  }

  /**
   * Component life cycle - on destory
   *
   * Clear of observable subscribtions.
   *
   * @return {void}
   */
  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }

  /**
   * Generate validate-when-requested observable chain.
   *
   * When triggered, activate the form validation.
   *
   * @param {Observable} unsub$ - unsub observable.
   * @param {Observable} validationTrigger$ - validation triggers observable.
   * @return {Observable} the custom composed observable.
   */
  validateWhenRequestedObs(unsub$, validationTrigger$) {
    return rx.pipe(
      () => validationTrigger$,
      rx.takeUntil(unsub$),
      rx.tap(() => this.validate()),
    )(null);
  }

  /**
   * Activate the form validation.
   *
   * @return {void}
   * @private
   */
  validate() {
    this.$validation.validate(this[this.formName]);
  }

  /**
   * Model proxy. Setter-Getter is used so changes to two way model can
   * be captures and propagetad as one way into a reactive variable.
   */
  set password(val: string) {
    this.password$.next(val);
  }
  get password() {
    return this.password$.value;
  }

  /**
   * Model proxy. Setter-Getter is used so changes to two way model can
   * be captures and propagetad as one way into a reactive variable.
   */
  set confirmPassword(val: string) {
    this.confirmPassword$.next(val);
  }
  get confirmPassword() {
    return this.confirmPassword$.value;
  }

  /**
   * Setup and subscribe to update-form-validation-status chain.
   *
   * Recalclulate the form validation status each validation change
   * done by angular-validation directive on each form input field.
   * The directive calls and push changes to the formValidationState$
   * reactive subject variable.
   *
   * @return {void}
   */
  setChainUpdateFormValidationsStauts() {
    rx.pipe(
      () => this.inputValidations$,
      rx.takeUntil(this.unsub$),
      rx.withLatestFrom(this.formValidationState$),
      rx.map(([{ isValid, key: modelName }, models]) => ({
        ...models,
        [modelName]: isValid,
      })),
      rx.tap((models) => this.formValidationState$.next(models)),
    )(null).subscribe();
  }

  /**
   * Setup and subscribe to notify-form-validation-status-change.
   *
   * Calculate overall form validation status based on its inputs
   * validation statuses and notify outside component user about
   * each change to the form validation status.
   *
   * @return {void}
   */
  setChainNotifyOnFormValidStatusChange() {
    rx.pipe(
      () => this.formValidationState$,
      rx.takeUntil(this.unsub$),
      rx.map((models) => _.every((isValid) => isValid, Object.values(models))),
      rx.tap((isAllValid) => this.onFormValidChange({ value: isAllValid })),
    )(null).subscribe();
  }

  /**
   * Setup and subscribe to notify-password-change.
   *
   * Notify the user of the component on each password model change.
   *
   * @return {void}
   */
  setChainNotifyPasswordChange() {
    rx.pipe(
      () => this.password$,
      rx.takeUntil(this.unsub$),
      rx.tap((password) => this.onSetPassword({ value: password })),
    )(null).subscribe();
  }
}

export const ResetPasswordFormComponent = {
  template,
  controller: Controller,
  bindings: {
    account: '<',
    validationTrigger: '<',
    onSetPassword: '&',
    onFormValidChange: '&',
  },
};

export default ResetPasswordFormComponent;
