import ng from 'angular';

import { BrandPlatformBrandSecuritiesAuditLogTableComponent } from './containers/brand-platform-brand-securities-audit-log-table/brand-platform-brand-securities-activity-log-table.component';
import { BrandPlatformMt4GroupAssetItemDialogComponent } from './containers/brand-platform-mt4-group-asset-item-dialog/brand-platform-mt4-group-asset-item-dialog.component';
import { BrandPlatformMt4GroupAssetsTableComponent } from './containers/brand-platform-mt4-group-assets-table/brand-platform-mt4-group-assets-table.component';
import { BrandPlatformMt4GroupDialogComponent } from './containers/brand-platform-mt4-group-dialog/brand-platform-mt4-group-dialog.component';
import { BrandPlatformMt4GroupDialogItemComponent } from './containers/brand-platform-mt4-group-dialog-item/brand-platform-mt4-group-dialog-item.component';
import { BrandPlatformMt4GroupFormComponent } from './containers/brand-platform-mt4-group-form/brand-platform-mt4-group-form.component';
import { BrandPlatformMt4GroupsTableComponent } from './containers/brand-platform-mt4-groups-table/brand-platform-mt4-groups-table.component';
import { BrandPlatformMt4GroupSecurityDialogItemComponent } from './containers/brand-platform-mt4-group-security-dialog-item/brand-platform-mt4-group-security-dialog-item.component';
import { BrandPlatformMt4GroupSecurityItemFormComponent } from './containers/brand-platform-mt4-group-security-item-form/brand-platform-mt4-group-security-item-form.component';
import { BrandPlatformMt4GroupSecuritiesTableComponent } from './containers/brand-platform-mt4-group-securities-table/brand-platform-mt4-group-securities-table.component';
import { BrandPlatformMt4GroupSecuritiesManagerComponent } from './containers/brand-platform-mt4-group-securities-manager/brand-platform-mt4-group-securities-manager.component';
import { BrandPlatformNt4GroupSecurityDialogComponent } from './containers/brand-platform-nt4-group-security-dialog/brand-platform-nt4-group-security-dialog.component';
import { RiskManagerDashboardComponent } from './containers/risk-manager-dashboard/risk-manager-dashboard.component';
import { CfdPlatformBrandAssetsManagerComponent } from './containers/cfd-platform-brand-assets-manager/cfd-platform-brand-assets-manager.component';
import { CfdPlatformBrandsSelectComponent } from './containers/cfd-platform-brands-select/cfd-platform-brands-select.component';
import { CfdPlatformBrandAssetsTableComponent } from './containers/cfd-platform-brand-assets-table/cfd-platform-brand-assets-table.component';
import { CfdPlatformBrandAssetEditDialogComponent } from './containers/cfd-platform-brand-asset-edit-dialog/cfd-platform-brand-asset-edit-dialog.component';
import { CfdPlatformBrandGroupEditDialogComponent } from './containers/cfd-platform-group-edit-dialog/cfd-platform-group-edit-dialog.component';
import { PlatfromAssetsService } from './services/platform-assets.service';
import { PlatfromGroupsService } from './services/platform-groups.service';
import { BrandPlatformNt4GroupAssetItemFormComponent } from './components/brand-platform-nt4-group-asset-item-form/brand-platform-nt4-group-asset-item-form.component';
import { CfdPlatformAssetFormComponent } from './components/cfd-platform-asset-form/cfd-platform-asset-form.component';
import { CfdPlatformSocketService } from './services/cfd-platform-socket.service';
import { CfdPlatformBrandsManagerComponent } from './containers/cfd-platform-brands-manager/cfd-platform-brands-manager.component';
import { CfdPlatformBrandsTableComponent } from './containers/cfd-platform-brands-table/cfd-platform-brands-table.component';
import { CfdPlatformBrandConfigFormComponent } from './components/cfd-platform-brand-config-form/cfd-platform-brand-config-form.component';
import { CfdPlatformBrandConfigEditDialogComponent } from './containers/cfd-platform-brand-config-edit-dialog/cfd-platform-brand-config-edit-dialog.component';
import { CfdPlatformBrandConfigsService } from './services/cfd-platform-brand-configs.service';
import { CfdPlatformBrandsService } from './services/cfd-platform-brands.service';
import { CfdPlatformAssetNameSelectComponent } from './containers/cfd-platform-asset-name-select/cfd-platform-asset-name-select';
import { CfdPlatformAssetCategorySelectComponent } from './containers/cfd-platform-asset-category-select/cfd-platform-asset-category-select';
import { CfdPlatformGroupNameSelectComponent } from './containers/cfd-platform-group-name-select/cfd-platform-group-name-select';
import { CfdPlatformGroupsManagerComponent } from './containers/cfd-platform-groups-manager/cfd-platform-groups-manager.component';
import { CfdPlatformGroupsTableComponent } from './containers/cfd-platform-groups-table/cfd-platform-groups-table.component';
import { CfdPlatformGroupFormComponent } from './components/cfd-platform-group-form/cfd-platform-group-form.component';
import { BrandPlatformConnectionSelectComponent } from './containers/brand-platform-connection-select/brand-platform-connection-select.component';
import { CfdPlatformBrandAssetsActivityLogTableComponent } from './containers/cfd-platform-brand-assets-activity-log-table/cfd-platform-brand-assets-activity-log-table.component';
import { CfdPlatformBrandGroupsAuditLogTableComponent } from './containers/cfd-platform-brand-groups-audit-log-table/cfd-platform-brand-groups-audit-log-table.component';
import { TradingCoreWebRequestLogRecordService } from './services/trading-core-web-request-log-record.service';
import { TradingCoreAuditLogsApiService } from './services/trading-core-audit-logs.api.service';
import { TradingCoreAssetsAuditLogTablePopupComponent } from './containers/trading-core-assets-audit-log-table-popup/trading-core-assets-audit-log-table-popup.component';
import { TradingCoreGroupsAuditLogTablePopupComponent } from './containers/trading-core-groups-audit-log-table-popup/trading-core-groups-audit-log-table-popup.component';
import { CfdPlatformBrandsAuditLogTableComponent } from './containers/cfd-platform-brands-audit-log-table/cfd-platform-brands-audit-log-table.component';
import { TradingCoreBrandsAuditLogTablePopupComponent } from './containers/trading-core-brands-audit-log-table-popup/trading-core-brands-audit-log-table-popup.component';
import { riskManagerRouting } from '~/source/management/integrations/risk-manager/risk-manager.routing';
import { riskManagerContextProviderDirective } from './containers/risk-manager-dashboard/risk-manager-context.provider.directive';
import { BrandPlatformBrandSecuritiesAuditLogTablePopupComponent } from './containers/brand-platform-brand-securities-audit-log-table-popup/brand-platform-brand-securities-audit-log-table-popup.component';
import { NumberCheckboxChoiceInputComponent } from './components/number-checkbox-choice-input/number-checkbox-choice-input.component';
import { NumberCheckboxLockInputComponent } from './components/number-checkbox-lock-input/number-checkbox-lock-input.component';
import { CfdPlatformBrandAssetsBulkUpdateComponent } from './containers/cfd-platform-brand-assets-bulk-update/cfd-platform-brand-assets-bulk-update.component';

export const RiskManagerModule = ng.module('crm.integrations.riskmanager', []);

RiskManagerModule.config(riskManagerRouting);

RiskManagerModule.service('prfPlatformAssetsService', PlatfromAssetsService)
  .service('prfPlatformGroupsService', PlatfromGroupsService)
  .service('prfCfdPlatformSocketService', CfdPlatformSocketService.$factorize())
  .service('prfCfdPlatformBrandConfigsService', CfdPlatformBrandConfigsService)
  .service('cfdPlatformBrandsService', CfdPlatformBrandsService)
  .service(
    'prfTradingCoreWebRequestLogService',
    TradingCoreWebRequestLogRecordService,
  )
  .service('prfTradingCoreAuditLogsApi', TradingCoreAuditLogsApiService)
  .directive(
    'prfRiskManagerContextProvider',
    riskManagerContextProviderDirective,
  )
  .component(
    'prfBrandPlatformMt4GroupAssetItemForm',
    BrandPlatformNt4GroupAssetItemFormComponent,
  )
  .component(
    'prfBrandPlatformMt4GroupAssetItemDialogBase',
    BrandPlatformMt4GroupAssetItemDialogComponent,
  )
  .component('prfBrandPlatformMt4GroupAssetItemDialog', {
    ...BrandPlatformMt4GroupAssetItemDialogComponent,
    require: {},
    controller() {},
    template: `
      <prf-brand-platform-mt4-group-asset-item-dialog-base
        prf-current-mt4-asset-of-group
        prf-current-platform-session
        prf-current-trc-streamer-of-brand-platform

        close="$ctrl.close({ $value })"
        dismiss="$ctrl.dismiss({ $value })"
        modal-instance="$ctrl.modalInstance"
        resolve="$ctrl.resolve"
      />`,
  })
  .component(
    'prfBrandPlatformMt4GroupAssetsTable',
    BrandPlatformMt4GroupAssetsTableComponent,
  )
  .component(
    'prfBrandPlatformMt4GroupDialog',
    BrandPlatformMt4GroupDialogComponent,
  )
  .component(
    'prfBrandPlatformMt4GroupDialogItemBase',
    BrandPlatformMt4GroupDialogItemComponent,
  )
  .component('prfBrandPlatformMt4GroupDialogItem', {
    ...BrandPlatformMt4GroupDialogItemComponent,
    require: {},
    controller() {},
    template: `
      <prf-brand-platform-mt4-group-dialog-item-base
        prf-current-group-of-brand-platform
        prf-current-platform-session

        brand-platform="$ctrl.brandPlatform"
        mt-group-id="$ctrl.mtGroupId"
        operation="$ctrl.operation"
        on-close="$ctrl.onClose()"
        on-dismiss="$ctrl.onDismiss()"
      />
      `,
  })
  .component('prfBrandPlatformMt4GroupForm', BrandPlatformMt4GroupFormComponent)
  .component(
    'prfBrandPlatformMt4GroupsTable',
    BrandPlatformMt4GroupsTableComponent,
  )
  .component(
    'prfBrandPlatformMt4GroupSecurityDialogItemBase',
    BrandPlatformMt4GroupSecurityDialogItemComponent,
  )
  .component('prfBrandPlatformMt4GroupSecurityDialogItem', {
    ...BrandPlatformMt4GroupSecurityDialogItemComponent,
    require: {},
    controller() {},
    template: `
      <prf-brand-platform-mt4-group-security-dialog-item-base
        prf-current-mt4-security-of-group
        prf-current-platform-session

        security-id="$ctrl.securityId"
        brand-platform="$ctrl.brandPlatform"
        mt-group-id="$ctrl.mtGroupId"
        operation="$ctrl.operation"
        on-close="$ctrl.onClose({ $value })"
        on-dismiss="$ctrl.onDismiss({ $value })"
      />
      `,
  })
  .component(
    'prfBrandPlatformMt4GroupSecurityItemForm',
    BrandPlatformMt4GroupSecurityItemFormComponent,
  )
  .component(
    'prfBrandPlatformMt4GroupSecuritiesTable',
    BrandPlatformMt4GroupSecuritiesTableComponent,
  )
  .component(
    'prfBrandPlatformMt4GroupSecuritiesManagerBase',
    BrandPlatformMt4GroupSecuritiesManagerComponent,
  )
  .component('prfBrandPlatformMt4GroupSecuritiesManager', {
    ...BrandPlatformMt4GroupSecuritiesManagerComponent,
    require: {},
    controller() {},
    template: `
      <prf-brand-platform-mt4-group-securities-manager-base
        prf-current-brand
        prf-current-user-settings-risk-securities-manager
      />
      `,
  })
  .component(
    'prfBrandPlatformNt4GroupSecurityDialog',
    BrandPlatformNt4GroupSecurityDialogComponent,
  )
  .component(
    'prfCfdPlatformBrandAssetsBulkUpdate',
    CfdPlatformBrandAssetsBulkUpdateComponent,
  )
  .component('prfCfdPlatformBrandsSelect', CfdPlatformBrandsSelectComponent)
  .component(
    'prfCfdPlatformAssetNameSelect',
    CfdPlatformAssetNameSelectComponent,
  )
  .component(
    'prfCfdPlatformAssetCategorySelect',
    CfdPlatformAssetCategorySelectComponent,
  )
  .component(
    'prfCfdPlatformGroupNameSelect',
    CfdPlatformGroupNameSelectComponent,
  )
  .component('prfRiskManagerDashboardBase', RiskManagerDashboardComponent)
  .component('prfRiskManagerDashboard', {
    ...RiskManagerDashboardComponent,
    require: {},
    controller() {},
    template: `
    <prf-risk-manager-dashboard-base
      prf-current-platform-session
      prf-current-platform-of-brand
      prf-current-trc-assets-of-platform
      prf-current-trc-groups-of-platform
      prf-current-trc-mt-securities-of-platform
      prf-current-trc-streamer-of-brand-platform
    />
    `,
  })
  .component(
    'prfCfdPlatformBrandAssetsManagerBase',
    CfdPlatformBrandAssetsManagerComponent,
  )
  .component('prfCfdPlatformBrandAssetsManager', {
    ...CfdPlatformBrandAssetsManagerComponent,
    require: {},
    controller() {},
    template: `
      <prf-cfd-platform-brand-assets-manager-base
        prf-current-brand
        prf-current-group-of-brand-platform
        prf-current-user-settings-risk-asset-manager
      />`,
  })
  .component(
    'prfCfdPlatformBrandAssetsTable',
    CfdPlatformBrandAssetsTableComponent,
  )
  .component(
    'prfCfdPlatformBrandAssetEditDialog',
    CfdPlatformBrandAssetEditDialogComponent,
  )
  .component(
    'prfCfdPlatformGroupEditDialog',
    CfdPlatformBrandGroupEditDialogComponent,
  )
  .component('prfCfdPlatformAssetForm', CfdPlatformAssetFormComponent)
  .component(
    'prfCfdPlatformBrandsManagerBase',
    CfdPlatformBrandsManagerComponent,
  )
  .component('prfCfdPlatformBrandsManager', {
    ...CfdPlatformBrandsManagerComponent,
    require: {},
    controller() {},
    template: `
      <prf-cfd-platform-brands-manager-base
        prf-current-brand
        prf-current-user-settings-risk-brands-manager
      />
      `,
  })
  .component('prfCfdPlatformBrandsTable', CfdPlatformBrandsTableComponent)
  .component(
    'prfCfdPlatformBrandConfigForm',
    CfdPlatformBrandConfigFormComponent,
  )
  .component(
    'prfCfdPlatformBrandConfigEditDialog',
    CfdPlatformBrandConfigEditDialogComponent,
  )
  .component('prfCfdPlatformGroupForm', CfdPlatformGroupFormComponent)
  .component(
    'prfCfdPlatformGroupsManagerBase',
    CfdPlatformGroupsManagerComponent,
  )
  .component('prfCfdPlatformGroupsManager', {
    ...CfdPlatformGroupsManagerComponent,
    require: {},
    controller() {},
    template: `
    <prf-cfd-platform-groups-manager-base
      prf-current-brand
      prf-current-group-of-brand-platform
      prf-current-user-settings-risk-groups-manager
    />
    `,
  })
  .component('prfCfdPlatformGroupsTable', CfdPlatformGroupsTableComponent)
  .component(
    'prfBrandPlatformBrandSecuritiesAuditLogTable',
    BrandPlatformBrandSecuritiesAuditLogTableComponent,
  )
  .component(
    'prfCfdPlatformBrandAssetsActivityLogTable',
    CfdPlatformBrandAssetsActivityLogTableComponent,
  )
  .component(
    'prfTradingCoreAssetsAuditLogTablePopupBase',
    TradingCoreAssetsAuditLogTablePopupComponent,
  )
  .component('prfTradingCoreAssetsAuditLogTablePopup', {
    ...TradingCoreAssetsAuditLogTablePopupComponent,
    require: {},
    controller() {},
    template: `
        <prf-trading-core-assets-audit-log-table-popup-base
          prf-current-platform-session
          prf-current-platform-of-brand
          prf-current-trc-groups-of-platform
          prf-current-trc-assets-of-platform

          close="vm.close({ $value })"
          dismiss="vm.dismiss({ $value })"
          modal-instance="vm.modalInstance"
          resolve="vm.resolve"
        />
      `,
  })
  .component(
    'prfBrandPlatformBrandSecuritiesAuditLogTablePopupBase',
    BrandPlatformBrandSecuritiesAuditLogTablePopupComponent,
  )
  .component('prfBrandPlatformBrandSecuritiesAuditLogTablePopup', {
    ...BrandPlatformBrandSecuritiesAuditLogTablePopupComponent,
    require: {},
    controller() {},
    template: `
        <prf-brand-platform-brand-securities-audit-log-table-popup-base
          prf-current-platform-session
          prf-current-platform-of-brand
          prf-current-trc-groups-of-platform
          prf-current-trc-mt-securities-of-platform
          prf-current-trc-assets-of-platform

          close="vm.close({ $value })"
          dismiss="vm.dismiss({ $value })"
          modal-instance="vm.modalInstance"
          resolve="vm.resolve"
        />
      `,
  })
  .component(
    'prfCfdPlatformBrandGroupsAuditLogTable',
    CfdPlatformBrandGroupsAuditLogTableComponent,
  )
  .component(
    'prfTradingCoreGroupsAuditLogTablePopupBase',
    TradingCoreGroupsAuditLogTablePopupComponent,
  )
  .component('prfTradingCoreGroupsAuditLogTablePopup', {
    ...TradingCoreGroupsAuditLogTablePopupComponent,
    require: {},
    controller() {},
    template: `
      <prf-trading-core-groups-audit-log-table-popup-base 
          prf-current-platform-session
          prf-current-platform-of-brand
          prf-current-trc-groups-of-platform

          close="vm.close({ $value })"
          dismiss="vm.dismiss({ $value })"
          modal-instance="vm.modalInstance"
          resolve="vm.resolve"
      />`,
  })
  .component(
    'prfCfdPlatformBrandsAuditLogTable',
    CfdPlatformBrandsAuditLogTableComponent,
  )
  .component(
    'prfTradingCoreBrandsAuditLogTablePopupBase',
    TradingCoreBrandsAuditLogTablePopupComponent,
  )
  .component('prfTradingCoreBrandsAuditLogTablePopup', {
    ...TradingCoreBrandsAuditLogTablePopupComponent,
    require: {},
    controller() {},
    template: `
        <prf-trading-core-brands-audit-log-table-popup-base
          prf-current-platform-session
          prf-current-platform-of-brand

          close="vm.close({ $value })"
          dismiss="vm.dismiss({ $value })"
          modal-instance="vm.modalInstance"
          resolve="vm.resolve"
        />
      `,
  })
  .component(
    'prfBrandPlatformConnectionSelect',
    BrandPlatformConnectionSelectComponent,
  )
  .component('prfNumberCheckboxChoiceInput', NumberCheckboxChoiceInputComponent)
  .component('prfNumberCheckboxLockInput', NumberCheckboxLockInputComponent);
export default RiskManagerModule;
