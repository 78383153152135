class BaseService {
  constructor(...args) {
    this.constructor.$inject.forEach((fieldName, index) => {
      this[fieldName] = args[index];
    });
  }

  /**
   * Returns a function for use by module.factory, which itself returns a function that instantiates the class
   * it operates on.
   *
   * This is useful when you want to create a non-singleton factory that will be instantiated every time
   * it is invoked.
   */
  static $factorize() {
    // tslint:disable-next-line
    const constructorFn = this; // 'this' is the service class itself. save its ref.
    return [
      '$injector',
      ($injector) => () => $injector.instantiate(constructorFn),
    ];
  }
}

export default BaseService;
