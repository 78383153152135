import { generateUuid } from '@proftit/general-utilities';

const FAILED_SEGMENT_NAME = 'Failed';

export function generateDefaultFailedSegment() {
  return {
    id: `clientid-${generateUuid()}` as any,
    name: FAILED_SEGMENT_NAME,
    start: 0,
    end: 55,
  };
}
