import angular from 'angular';

import PositionsStatusesService from './positions-statuses-forex.service';
import PositionsStatusesForexSelectComponent from './positions-statuses-forex-select.component';
import PositionsTableForexComponent from './positions-table-forex/positions-table-forex';
import PositionTableForexPopupController from './positions-table-forex-popup-controller';
import { BundleSocketService } from './bundles-socket.service';
import { PositionsForexSocketService } from './positions-forex-socket.service';

export default angular
  .module('crm.contact.tradingAccount.forex.position', [])
  .service('positionsForexSocketService', PositionsForexSocketService)
  .service('positionsStatusesForexService', PositionsStatusesService)
  .service('bundlesSocketService', BundleSocketService)
  .component(
    'prfPositionsStatusesForexSelect',
    PositionsStatusesForexSelectComponent,
  )
  .component('prfPositionsTableForex', <any>PositionsTableForexComponent)
  .controller(
    'positionsTableForexPopupController',
    PositionTableForexPopupController,
  ).name;
