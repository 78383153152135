import template from './campaign-blacklisted-countries-form.html';
import BaseController from '~/source/common/controllers/base';

class CampaignBlacklistedCountriesFormController extends BaseController {}

export default {
  template,
  bindings: {
    required: '<',
    selectedCountries: '=',
    isEdit: '<',
    hideDefault: '<',
  },
  controller: CampaignBlacklistedCountriesFormController,
  controllerAs: 'vm',
};
