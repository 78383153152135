import {
  AccountAllowedActions,
  TradingAccountStatus,
} from '@proftit/crm.api.models.entities';

export function normalizedTradingAccountAllowedActions(
  state,
  tradingAccountStatusList,
) {
  const statuses: string[] = Object.keys(state);

  const fn = (status: TradingAccountStatus, action: AccountAllowedActions) => ({
    accountStatusId: status.id,
    actionId: action.id,
  });

  return statuses.reduce((acc, currentStatusStr) => {
    const foundStatus: TradingAccountStatus = tradingAccountStatusList.find(
      (status) => status.code === currentStatusStr,
    );
    const statusActions = state[currentStatusStr];
    const normalizedStatusActions = statusActions.map((action) =>
      fn(foundStatus, action),
    );
    return [...acc, ...normalizedStatusActions];
  }, []);
}
