import template from './account-status-permissions-dashboard.component.html';
import * as rx from '@proftit/rxjs';
import {
  AccountAction,
  Brand,
  BrandPlatform,
} from '@proftit/crm.api.models.entities';
import { AccountActionsService } from '~/source/common/services/account-actions.service';
import BrandsService from '~/source/management/brand/services/brands';
const styles = require('./account-status-permissions-dashboard.component.scss');
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, tapLog } from '@proftit/rxjs.adjunct';
import { TradingAccountStatusCode } from '@proftit/crm.api.models.enums';
import { TradingAccountStatuses } from '~/source/common/services/trading-account-statuses';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { normalizedTradingAccountAllowedActions } from './normalized-trading-account-allowed-actions';
import log from 'loglevel';

interface CrmAction {
  index: number;
  item: AccountAction;
  external: any;
  type: string & { category: string; status: string };
  status?: string;
}

interface AllowedActionsPerStatus {
  [status: string]: AccountAction[];
}

export class AccountStatusPermissionsDashboardController {
  styles = styles;
  selectedBrand$ = new rx.BehaviorSubject<Brand>(null);
  selectedBrandPlatform$ = new rx.BehaviorSubject<BrandPlatform>(null);
  allowedActions$: rx.Observable<AccountAction>;
  tradingAccountStatusList$ = this.streamTradingAccountStatusList();
  blockUiId = generateBlockuiId();
  dropFromStatusBox$ = new rx.Subject<CrmAction>(); // from status to another status
  dropFromToolbox$ = new rx.Subject<CrmAction>(); // from toolbox to status
  removeActionFromStatusBox = new rx.Subject<any>();
  tradingAccountStatusCode = TradingAccountStatusCode;

  generateType = _.memoize((accountStatus) => {
    return JSON.stringify({ category: 'dataitem', status: accountStatus });
  });

  onCancelEdit$ = new rx.Subject<void>();
  onConfirm$ = new rx.Subject<void>();
  isEdit$ = new rx.BehaviorSubject<boolean>(false);

  constructor(
    readonly accountActionsService: AccountActionsService,
    readonly brandsService: () => BrandsService,
    readonly tradingAccountStatusesService: () => TradingAccountStatuses,
  ) {
    this.allowedActions$ = rx.obs.from(
      this.accountActionsService
        .getList()
        .then((accountActions) => accountActions),
    );
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  brandPlatformAllowedActionsGroupedByStatus$ = this.streamStatusStructure();

  streamTradingAccountStatusList() {
    const obs$ = new rx.Observable((subscriber) => {
      this.tradingAccountStatusesService()
        .getListWithQuery()
        .then((data) => data.plain())
        .then((data) => subscriber.next(data));
    });

    return obs$;
  }

  streamStatusStructureFromSelect() {
    const initialAllowedActionsPerStatus: AllowedActionsPerStatus = {
      [TradingAccountStatusCode.Active]: [],
      [TradingAccountStatusCode.NoTrade]: [],
    };

    const stream = rx.obs
      .combineLatest([this.selectedBrand$, this.selectedBrandPlatform$])
      .pipe(
        rx.switchMap(([selectedBrand, selectedBrandPlatform]) => {
          if (
            _.isNil(this.selectedBrand) ||
            _.isNil(this.selectedBrandPlatform)
          ) {
            return rx.obs.of(initialAllowedActionsPerStatus);
          }
          return this.brandsService()
            .setConfig({ blockUiRef: this.blockUiId })
            .getBrandPlatformConnectionsAllowedActions(
              selectedBrand.id,
              selectedBrandPlatform.id,
            )
            .catch((err) => {
              log.error(`error in streamStatusStructureFromSelect ${err}`);
              return [];
            });
        }),
        rx.map((brandPlatformAllowedActions) => {
          const allowedActionsPerStatus = _.cloneDeep(
            initialAllowedActionsPerStatus,
          );
          for (const item of brandPlatformAllowedActions) {
            allowedActionsPerStatus[item.accountStatus.code].push(item.action);
          }
          return allowedActionsPerStatus;
        }),
      );

    return stream;
  }

  streamStatusStructureFromToolboxDrop(
    state$: rx.Observable<AllowedActionsPerStatus>,
  ): rx.Observable<AllowedActionsPerStatus> {
    const stream = this.dropFromToolbox$.pipe(
      rx.withLatestFrom(state$),
      rx.map(([action, state]) => {
        const currentStatusActionsList = state[action.status];
        const crmActionToDrop = action.item;
        const isCrmActionExist = currentStatusActionsList.some(
          (accountAction) => accountAction.code === crmActionToDrop.code,
        );
        if (isCrmActionExist) {
          return state;
        }
        const newStatusActionList = [
          ...currentStatusActionsList,
          crmActionToDrop,
        ];
        return { ...state, [action.status]: newStatusActionList };
      }),
    );
    return stream;
  }

  streamStatusStructureFromStatusBoxDrop(
    state$: rx.Observable<AllowedActionsPerStatus>,
  ): rx.Observable<AllowedActionsPerStatus> {
    const stream = this.dropFromStatusBox$.pipe(
      rx.withLatestFrom(state$),
      rx.map(([action, state]) => {
        const targetStatusActionList = state[action.status];
        const sourceStateStatusList = [...state[action.type.status]];
        const crmActionToDrop = action.item;
        const isCrmActionExist = targetStatusActionList.some(
          (accountAction) => accountAction.code === crmActionToDrop.code,
        );
        if (isCrmActionExist) {
          return state;
        }

        const newStatusActionList = [
          ...targetStatusActionList,
          crmActionToDrop,
        ];
        const crmActionToDrag = sourceStateStatusList.findIndex(
          (item) => item.code === crmActionToDrop.code,
        );
        sourceStateStatusList.splice(crmActionToDrag, 1);
        const newState = {
          ...state,
          [action.status]: newStatusActionList,
          [action.type.status]: sourceStateStatusList,
        };

        return newState;
      }),
    );
    return stream;
  }

  streamStatusStructureFromRemoveAction(
    state$: rx.Observable<AllowedActionsPerStatus>,
  ): rx.Observable<AllowedActionsPerStatus> {
    const stream = this.removeActionFromStatusBox.pipe(
      rx.withLatestFrom(state$),
      rx.map(([action, state]) => {
        const currentStatusActionsList = [...state[action.status]];
        const crmActionToDrop = action.actionToDelete;
        const crmActionToRemove = currentStatusActionsList.findIndex(
          (item) => item.code === crmActionToDrop.code,
        );
        currentStatusActionsList.splice(crmActionToRemove, 1);
        const newState = {
          ...state,
          [action.status]: currentStatusActionsList,
        };
        return newState;
      }),
    );
    return stream;
  }

  streamStatusStructureFromIsEdit(state$) {
    return this.isEdit$.pipe(
      rx.switchMap((isEdit) => {
        if (!isEdit) {
          return rx.obs.NEVER;
        }
        return rx.obs.merge(
          this.streamStatusStructureFromToolboxDrop(state$),
          this.streamStatusStructureFromStatusBoxDrop(state$),
          this.streamStatusStructureFromRemoveAction(state$),
        );
      }),
    );
  }

  streamStatusStructureFromCancel(
    state$: rx.Observable<AllowedActionsPerStatus>,
  ): rx.Observable<AllowedActionsPerStatus> {
    return rx.pipe(
      () => this.isEdit$,
      rx.filter((isEdit: boolean) => isEdit),
      rx.withLatestFrom(state$),
      rx.switchMap(([isEdit, state]) => {
        return this.onCancelEdit$.pipe(
          rx.map((action) => {
            return { ...state };
          }),
          rx.tap(() => this.isEdit$.next(false)),
        );
      }),
    )(null);
  }

  streamStatusStructureFromConfirm(
    state$: rx.Observable<AllowedActionsPerStatus>,
  ): rx.Observable<AllowedActionsPerStatus> {
    return this.onConfirm$.pipe(
      rx.withLatestFrom(
        this.selectedBrand$,
        this.selectedBrandPlatform$,
        state$,
        this.tradingAccountStatusList$,
      ),
      rx.map(
        ([
          a,
          selectedBrand,
          selectedPlatform,
          state,
          tradingAccountStatusList,
        ]) => {
          const normalizedDate = normalizedTradingAccountAllowedActions(
            state,
            tradingAccountStatusList,
          );
          return this.brandsService().updateBrandPlatformConnectionsAllowedActions(
            selectedBrand.id,
            selectedPlatform.id,
            normalizedDate,
          );
        },
      ),
      rx.tap((value) => this.isEdit$.next(false)),
      rx.switchMap(() => rx.obs.NEVER),
    );
  }

  streamStatusStructure() {
    const state$ = new rx.BehaviorSubject({});
    const streamFn = () =>
      rx.obs
        .merge(
          this.streamStatusStructureFromSelect(),
          this.streamStatusStructureFromIsEdit(state$),
          this.streamStatusStructureFromConfirm(state$),
          this.streamStatusStructureFromCancel(state$),
        )
        .pipe(
          tapLog('from primary'),
          rx.tap((newState) => state$.next(newState)),
          shareReplayRefOne(),
          rx.catchError((err) => {
            log.error(`Error in primary stream ${err.message} `);
            return streamFn();
          }),
        );
    return streamFn();
  }

  dropCallback(index, item, external, type, status) {
    if (type === 'toolbox') {
      this.dropFromToolbox$.next({ index, item, external, type, status });
    } else {
      const parsedData = JSON.parse(type);
      this.dropFromStatusBox$.next({
        index,
        item,
        external,
        status,
        type: parsedData,
      });
    }
    return true;
  }

  removeAction(allowedAction, status) {
    this.removeActionFromStatusBox.next({
      status,
      actionToDelete: allowedAction,
    });
  }

  set selectedBrand(val: Brand) {
    this.selectedBrand$.next(val);
  }

  get selectedBrand() {
    return this.selectedBrand$.getValue();
  }

  set selectedBrandPlatform(val: BrandPlatform) {
    this.selectedBrandPlatform$.next(val);
  }

  get selectedBrandPlatform() {
    return this.selectedBrandPlatform$.getValue();
  }
}

export const AccountStatusPermissionsDashboardComponent = {
  template,
  controller: AccountStatusPermissionsDashboardController,
  bindings: {},
};
