import ng from 'angular';

import settings from './settings.json';
import { PermissionsTreeComponent } from './components/permissions-tree/permissions-tree';
import { GroupEditComponent } from './components/group-edit/group-edit';
import { GroupFormComponent } from './components/group-form/group-form';
import { PermissiosEditComponent } from './components/permissions-edit/permissions-edit';
import { GroupAddController } from './controllers/add';
import { GroupCopyController } from './controllers/copy';
import { UserGroupsService } from './services/user-groups';
import { PermissionStructuresService } from './services/permission-structures';
import { DeleteGroupModalController } from './controllers/delete-group-modal';
import { GroupEditController } from './controllers/edit';
import { GroupListComponent } from './components/group-list/group-list';

export const GroupModule = ng.module('crm.group', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

const modulePath = 'management/group';

config.$inject = ['$stateProvider', '$urlRouterProvider'];

function config($stateProvider, $urlRouterProvider) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/permissions/groups',
    '/crm/management/permissions/groups/list',
  );

  $stateProvider
    .state('crm.management.permissions.groups', {
      url: '/groups',
      template: '<ui-view/>',
    })
    .state('crm.management.permissions.groups.list', {
      url: '/list',
      component: 'prfGroupListComponent',
      data: {
        permissions: {
          only: ['management.usergroups'],
          redirectTo: 'error.unauthorized',
        },
      },
    })

    .state('crm.management.permissions.groups.add', {
      url: '/add',
      template: require(`~/source/${modulePath}/templates/group-add.html`),
      controller: 'GroupAddController as vm',
      data: {
        permissions: {
          only: ['management.usergroups_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })

    .state('crm.management.permissions.groups.edit', {
      url: '/edit/:id',
      template: require(`~/source/${modulePath}/templates/group-edit.html`),
      controller: 'GroupEditController as vm',
      data: {
        permissions: {
          only: ['management.usergroups_U'],
          redirectTo: 'error.unauthorized',
        },
      },
    })

    .state('crm.management.permissions.groups.copy', {
      url: '/copy/:groupId',
      template: require(`~/source/${modulePath}/templates/group-add.html`),
      controller: 'GroupCopyController as vm',
      data: {
        permissions: {
          only: ['management.usergroups_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}

GroupModule.config(config)
  .constant('groupsModuleSettings', settings)
  .factory('userGroupsService', UserGroupsService.$factorize())
  .service('permissionStructuresService', PermissionStructuresService)
  .controller('GroupAddController', GroupAddController)
  .controller('GroupCopyController', GroupCopyController)
  .controller('DeleteGroupModalController', DeleteGroupModalController)
  .controller('GroupEditController', GroupEditController)
  .component('prfGroupListComponent', GroupListComponent)
  .component('prfGroupEdit', GroupEditComponent)
  .component('prfPermissionsTree', PermissionsTreeComponent)
  .component('prfUserGroupForm', GroupFormComponent)
  .component('prfPermissionsEdit', PermissiosEditComponent);
