import RestService from '~/source/common/services/rest';

export class CurrenciesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'currencies';
  }

  /**
   * This resource is public
   *
   * @returns {string}
   */
  get accessType() {
    return 'public';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }
}

export default CurrenciesService;
