import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './promo-code-list.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import BrandsService from '../../brand/services/brands';
import { shareReplayRefOne, useStreams, tapLog } from '@proftit/rxjs.adjunct';
import { Brand, BrandPlatform } from '@proftit/crm.api.models.entities';
const styles = require('./promo-code-list.component.scss');

export class PromoCodeListController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  selectedBrandFormControl = new FormControl(null);
  selectedBrandPlatformFormControl = new FormControl(null);

  selectByUiParams$ = new rx.Subject<{ brandId: number; platformId: number }>();

  brand$ = this.streamBrand();

  brands$ = new rx.BehaviorSubject<Brand[]>([]);

  brandPlatforms$ = new rx.BehaviorSubject<BrandPlatform[]>([]);

  preselectWasDone$ = this.streamPreselectDropdownsOnUrlParams();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $state: ng.ui.IStateService,
    readonly $stateParams: { brandId: number; platformId: number },
  ) {
    useStreams(
      [
        this.brand$,
        this.selectedBrandFormControl.value$,
        this.selectedBrandPlatformFormControl.value$,
        this.preselectWasDone$,
        this.streamUpdateUiState(),
      ],
      this.lifecycles.onDestroy$,
    );

    this.selectByUiParams$.next({
      brandId: this.$stateParams.brandId,
      platformId: this.$stateParams.platformId,
    });
  }

  streamBrand() {
    return rx.pipe(
      () =>
        this.selectedBrandFormControl.value$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.distinctUntilKeyChanged('id'),
        ),
      rx.switchMap((brand) => {
        return rx.obs
          .from(
            this.brandsService()
              .embed(['currencyConnections'])
              .expand('currencyConnections.currency')
              .getOneWithQuery(brand.id),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPreselectDropdownsOnUrlParams() {
    return rx.pipe(
      () => this.selectByUiParams$,
      rx.switchMap(() => {
        const preselectList: rx.Observable<boolean>[] = [rx.obs.of(true)];

        if (!_.isNil(this.$stateParams.brandId)) {
          preselectList.push(this.streamPreselectBrandOnUrlParams());
        }

        if (!_.isNil(this.$stateParams.platformId)) {
          preselectList.push(this.streamPreselectPlatformOnUrlParams());
        }

        return rx.obs.combineLatest(preselectList);
      }),
      rx.map((selectedList) => selectedList.every((x) => x)),
      shareReplayRefOne(),
    )(null);
  }

  streamUpdateUiState() {
    return rx.pipe(
      () => this.preselectWasDone$,
      rx.filter((wasDone) => wasDone),
      rx.switchMap(() =>
        rx.obs.combineLatest({
          brand: this.selectedBrandFormControl.value$,
          brandPlatform: this.selectedBrandPlatformFormControl.value$,
        }),
      ),
      rx.tap(({ brand, brandPlatform }) => {
        const currentBrandId = this.$stateParams.brandId;
        const cuurentPlatformId = this.$stateParams.platformId;

        if (
          brand?.id === currentBrandId &&
          brandPlatform?.platform?.id === cuurentPlatformId
        ) {
          return;
        }

        this.$state.transitionTo(
          '.' as any,
          {
            brandId: brand?.id,
            platformId: brandPlatform?.platform?.id,
          },
          {
            notify: false,
            reload: false,
            inherit: true,
            relative: this.$state.$current as any,
            location: true,
          },
        );
      }),
    )(null);
  }

  streamPreselectBrandOnUrlParams(): rx.Observable<boolean> {
    const wasSelected$ = new rx.BehaviorSubject<boolean>(false);

    return rx.pipe(
      () => this.brands$,
      rx.withLatestFrom(wasSelected$),
      rx.filter(([brands, wasSelected]) => !wasSelected),
      rx.filter(([brands, a]) => brands.length > 0),
      rx.filter(() => !_.isNil(this.$stateParams.brandId)),
      rx.map(([brands, a]) =>
        brands.find((brand) => brand.id === this.$stateParams.brandId),
      ),
      rx.filter((brand) => !_.isNil(brand)),
      rx.tap((brand) => {
        wasSelected$.next(true);
        this.selectedBrandFormControl.setValue(brand);
      }),
      rx.withLatestFrom(wasSelected$),
      rx.map(([a, wasSelected]) => wasSelected),
    )(null) as rx.Observable<boolean>;
  }

  streamPreselectPlatformOnUrlParams(): rx.Observable<boolean> {
    const wasSelected$ = new rx.BehaviorSubject(false);

    return rx.pipe(
      () => this.brandPlatforms$,
      rx.withLatestFrom(wasSelected$),
      rx.filter(([brandPlatforms, wasSelected]) => !wasSelected),
      rx.filter(([brandPlatforms, a]) => brandPlatforms.length > 0),
      rx.filter(() => !_.isNil(this.$stateParams.platformId)),
      rx.map(([brandPlatforms, a]) =>
        brandPlatforms.find(
          (brandPlatform) =>
            brandPlatform.platform.id === this.$stateParams.platformId,
        ),
      ),
      rx.filter((brand) => !_.isNil(brand)),
      rx.tap((brandPlatform) => {
        wasSelected$.next(true);
        this.selectedBrandPlatformFormControl.setValue(brandPlatform);
      }),
      rx.withLatestFrom(wasSelected$),
      rx.map(([a, wasSelected]) => wasSelected),
    )(null) as rx.Observable<boolean>;
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const PromoCodeListComponent = {
  template,
  controller: PromoCodeListController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
    prfCrmAppRouteProvider: '^',
  },
};
