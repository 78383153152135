import TablePopupController from '~/source/common/components/table/table-popup';
import CustomersService from '~/source/contact/common/services/customers';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import isDepositDeletable from '~/source/common/models/deposit/is-deposit-deletable';

import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';
import PopupService from '~/source/common/components/modal/popup.service';
import confirmDepositPopupTemplate from '../confirm-deposit-popup/confirm-deposit-popup.html';
import template from './deposit-table-popup.html';

import * as _ from '@proftit/lodash';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  ATTACHMENT_ADDED_TO_DEPOSIT,
  ATTACHMENT_REMOVED_FROM_DEPOSIT,
  DEPOSIT_STATUS_UPDATE,
  DEPOSIT_TABLE_RELOAD,
} from '~/source/common/constants/general-pubsub-keys';
import * as rx from '@proftit/rxjs';
import { depositTablePopupColumns } from './popup-deposit-table-columns';
import { calcCardHodlerForDeposit } from '~/source/common/models/deposit/calc-card-holder-for-deposit';
import angular from 'angular';
import { generateUuid } from '@proftit/general-utilities';
import ModalService from '~/source/common/components/modal/modal.service';
import { useStreams } from '@proftit/rxjs.adjunct';
import {
  Permissions,
  PermissionNormalized,
} from '~/source/common/models/permission-structure';
import {
  checkCrudPermission,
  CrudPermissionInfo,
} from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';

const styles = require('./deposits-table-popup.component.scss');

interface MenuItem {
  labelCode: string;
  actionCode: string;
}

class DepositAdditional {
  showActionWarning: boolean = false;
  isMenuOpen: boolean = false;
  menuItems: MenuItem[] = [];
  attachDocumentButtonId = generateUuid();
}

const preApprovedStatuses = ['requested', 'pending'];
const cancebleStatuses = ['requested', 'pending'];
const docLinkableStatuses = ['requested', 'approved'];

class DepositTablePopupComponent extends TablePopupController {
  static $inject = [
    'customersService',
    'depositsSettings',
    'popupService',
    'modalService',
    'platformTypesMap',
    'featuresFlags',
    'prfClientGeneralPubsub',
    'PermPermissionStore',
    ...TablePopupController.$inject,
  ];

  styles = styles;

  depositsSettings;
  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  deposits: IElementRestNg<any>;
  account: TradingAccount;
  customer: Customer;
  onDone: () => void;
  depositAdditional: { [key: number]: DepositAdditional } = {};
  featuresFlags: FeaturesFlagsService;
  popupService: PopupService;
  modalService: ModalService;
  platformTypesMap: any;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  unsub$: rx.Subject<any> = new rx.Subject<null>();
  Permissions = Permissions;

  PermPermissionStore: ng.permission.PermissionStore;

  attachmentsPermissions: CrudPermissionInfo;
  filesPermissions: CrudPermissionInfo;

  infiniteTableInitSubject = new rx.Subject<number>();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams(
      [
        this.streamInitTable(),
        this.streamDepositUpdateStatus(),
        this.streamTableReload(),
        this.streamTableReloadForAddAttachment(),
        this.streamTableReloadForRemoveAttachment(),
      ],
      this.unsub$,
    );
  }

  streamInitTable() {
    return rx.pipe(
      () => this.infiniteTableInitSubject,
      rx.switchMap(() =>
        rx.obs.combineLatest(
          checkCrudPermission(
            PermissionNormalized.ContactsAttachments,
            this.PermPermissionStore,
          ),
          checkCrudPermission(
            PermissionNormalized.ContactsFileUpload,
            this.PermPermissionStore,
          ),
        ),
      ),
      rx.tap(([attachmentsPermissions, filesPermissions]) => {
        const documentsColumn = _.find(
          (col) => col.fieldName === 'documents',
          this.cols,
        );

        documentsColumn.show = attachmentsPermissions.isView;
      }),
      rx.tap(([attachmentsPermissions, filesPermissions]) => {
        this.attachmentsPermissions = attachmentsPermissions;
        this.filesPermissions = filesPermissions;
      }),
      rx.withLatestFrom(this.infiniteTableInitSubject),
      rx.tap(([a, rows]) => this.initTable(rows)),
    )(null);
  }

  $onInit() {
    super.$onInit();
  }

  get cols() {
    return [...depositTablePopupColumns];
  }

  get ngTableSettings() {
    return { ...this.depositsSettings.popupTable.ngTable };
  }

  get title() {
    return 'DEPOSITS';
  }

  get tableKey() {
    return 'deposit';
  }

  streamDepositUpdateStatus() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === DEPOSIT_STATUS_UPDATE),
      rx.tap(() => {
        this.reloadTable();
      }),
    )(null);
  }

  streamTableReload() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === DEPOSIT_TABLE_RELOAD),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamTableReloadForAddAttachment() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === ATTACHMENT_ADDED_TO_DEPOSIT),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamTableReloadForRemoveAttachment() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === ATTACHMENT_REMOVED_FROM_DEPOSIT),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      this.account = null;
      return;
    }
    this.customer = resolve.customer;
    this.account = resolve.account;
  }

  parseLoadedData(data) {
    data.forEach((deposit) => {
      const cardHolder = calcCardHodlerForDeposit(deposit, this.customer);
      if (cardHolder) {
        Object.assign(deposit, {
          _calcCardHolder: cardHolder,
        });
      }
    });

    if (!this.deposits) {
      this.deposits = data;
    } else {
      this.deposits = _.unionWith(
        (a: any, b: any) => a.id === b.id,
        data,
        this.deposits,
      );
    }
    this.depositAdditional = this.generateDepositsAdditionals();
    return data;
  }

  generateDepositsAdditionals() {
    return this.deposits.reduce((acc, deposit) => {
      const info = new DepositAdditional();
      info.menuItems = this.calcMenuItemsForDeposit(deposit);

      return {
        ...acc,
        [deposit.id]: info,
      };
    }, {});
  }

  onDropDownAction(actionCode, deposit) {
    const actionsDispatch = {
      cancel: () => this.cancelDeposit(deposit),
      startDelete: () => this.startDeleteDepositProcess(deposit),
      startConfirm: () => this.openDepositConfirmDialog(deposit),
      linkDocument: () => this.openDepositLinkDocumentDialog(deposit),
      attachDocument: () => this.attachDocumentDeposit(deposit.id),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  /**
   * Calculate relevant menu items for deposit row.
   *
   * @param {object} deposit - deposit
   * @returns {[MenuItem]} menu items relevant for the deposit
   * @private
   */
  calcMenuItemsForDeposit(deposit) {
    let menuItems = [];

    if (this.isDepositCancelable(deposit)) {
      menuItems = [
        ...menuItems,
        { labelCode: 'common.CANCEL', actionCode: 'cancel' },
      ];
    }

    if (
      isDepositDeletable(deposit) &&
      this.featuresFlags.isEnabled(['DELETE_DEPOSITS'])
    ) {
      menuItems = [
        ...menuItems,
        { labelCode: 'common.DELETE', actionCode: 'startDelete' },
      ];
    }

    if (this.isDepositConfirmable(deposit)) {
      menuItems = [
        ...menuItems,
        { labelCode: 'common.CONFIRM', actionCode: 'startConfirm' },
      ];
    }

    if (this.isDepositDocLinkable(deposit)) {
      if (
        this.attachmentsPermissions.isView &&
        this.attachmentsPermissions.isUpdate
      ) {
        menuItems = [
          ...menuItems,
          {
            labelCode: 'contact.DEPOSIT_LINK_DOCUMENT',
            actionCode: 'linkDocument',
          },
        ];
      }
      if (
        this.attachmentsPermissions.isCreate &&
        this.filesPermissions.isCreate
      ) {
        menuItems = [
          ...menuItems,
          {
            labelCode: 'contact.DEPOSIT_ATTACH_DOCUMENT',
            actionCode: 'attachDocument',
          },
        ];
      }
    }

    return menuItems;
  }

  isDepositCancelable(deposit) {
    return cancebleStatuses.includes(deposit.transactionStatusCode);
  }

  isDepositConfirmable(deposit) {
    return preApprovedStatuses.includes(deposit.transactionStatusCode);
  }

  isDepositDocLinkable(deposit) {
    return docLinkableStatuses.includes(deposit.transactionStatusCode);
  }

  isEwalletDeposit(deposit) {
    return [
      this.depositsSettings.types.manualEwallet.code,
      this.depositsSettings.types.ewallet.code,
    ].includes(deposit.transferMethodTypeCode);
  }

  openDepositConfirmDialog(deposit) {
    this.depositAdditional[deposit.id].isMenuOpen = false;

    if (this.isEwalletDeposit(deposit)) {
      this.popupService.open({
        component: 'prfConfirmEwalletDepositPopup',
        resolve: {
          deposit,
          accountId: this.account.id,
          contactId: this.customer.id,
          platformType: this.platformTypesMap[
            this.customer.brand.platformTypeId
          ],
        },
      });
    } else {
      this.popupService.open({
        controller: 'ConfirmDepositPopupController',
        template: confirmDepositPopupTemplate,
        scope: this.$scope,
        data: {
          deposit,
          accountId: this.account.id,
          contactId: this.customer.id,
          platformType: this.platformTypesMap[
            this.customer.brand.platformTypeId
          ],
        },
      });
    }
  }

  startDeleteDepositProcess(deposit) {
    this.depositAdditional[deposit.id].isMenuOpen = false;
    this.depositAdditional[deposit.id].showActionWarning = true;
  }

  /**
   * Cancel deposit
   *
   * @param {Object} deposit
   */
  cancelDeposit(deposit) {
    // cancel request
    deposit
      .patch({
        transactionStatusCode: TradingAccountTransactionStatusCode.Canceled,
      })
      .then(() => {
        // update table data on success
        deposit.transactionStatusCode = 'canceled';
        this.prfClientGeneralPubsub.publish(DEPOSIT_STATUS_UPDATE, '');
        this.depositAdditional = this.generateDepositsAdditionals();
      });
  }

  /**
   * Delete deposit
   *
   * @param {Object} deposit
   */
  deleteDeposit(deposit) {
    // delete request
    deposit
      .patch({
        transactionStatusCode: TradingAccountTransactionStatusCode.Deleted,
      })
      .then(() => {
        // update table data on success
        deposit.transactionStatusCode = 'deleted';
        this.prfClientGeneralPubsub.publish(DEPOSIT_STATUS_UPDATE, '');
        this.depositAdditional = this.generateDepositsAdditionals();
      });
  }

  /**
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getDepositsResource(this.customer.id, this.account.id)
      .embed(['attachments'])
      .expand([
        'currency',
        'clearingCompany',
        'user',
        'owner',
        'transactionTransferWire',
        'transactionTransferCreditCard',
        'transactionTransferCreditCard.clearingCompany',
        'transactionTransferCreditCard.customerCreditcard',
        'ewalletTransaction',
        'ewalletTransaction.ewallet',
        'mobileTransaction',
        'mobileTransaction.clearingCompany',
      ]);
  }

  // calculate the owner desk ID - depends on the existence of owner
  calcOwnerDeskId(owner, desk) {
    if (owner && desk) {
      return desk.id;
    }

    return null;
  }

  assignOwnerToDeposit(owner, desk, deposit) {
    return this.customersService()
      .getDepositResource(this.customer.id, this.account.id, deposit.id)
      .setConfig({ blockUiRef: 'assignToPopup' })
      .patchWithQuery({
        ownerId: owner ? owner.id : null,
        ownerDeskId: this.calcOwnerDeskId(owner, desk),
      })
      .then(() => {
        deposit.owner = owner;
        this.reloadTable();
        this.$scope.$emit('depositsTableReload');
      });
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }

  openDepositLinkDocumentDialog(deposit) {
    this.modalService.open({
      component: 'prfDepositLinkDocumentPopup',
      size: 'lg',
      resolve: {
        depositId: deposit.id,
        accountId: this.account.id,
        customerId: this.customer.id,
      },
    });
  }

  openDepositLinkedDocumentsDialog(depositId) {
    this.modalService.open({
      component: 'prfDepositLinkedDocumentsPopup',
      size: 'lg',
      resolve: {
        depositId,
        accountId: this.account.id,
        customerId: this.customer.id,
      },
    });
  }

  attachDocumentDeposit(depositId) {
    const id = this.depositAdditional[depositId].attachDocumentButtonId;

    const selector = angular.element(`[data-prf-js-id="${id}"]`);

    !_.isNil(selector) ? selector.click() : null;
  }
}

export default {
  template,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  controller: DepositTablePopupComponent,
  controllerAs: 'vm',
};
