import moment, { Moment } from 'moment';
import * as _ from '@proftit/lodash';

export interface DateRange {
  startDate: Moment;
  endDate: Moment;
}

export function serializeDateRange(dateRange: DateRange, format: string) {
  /*
   * for 'Empty' dateRange case -
   * have to parse to string for rest service (rest service ignores all filters with null Object)
   */
  if (_.isNull(dateRange)) {
    return 'null';
  }

  const gte = moment.isMoment(dateRange.startDate)
    ? dateRange.startDate
    : moment(dateRange.startDate);
  const lte = moment.isMoment(dateRange.endDate)
    ? dateRange.endDate
    : moment(dateRange.endDate);

  return {
    // use date since the start of the day (00:00) of "startDate"
    gte: gte.startOf('day').format(format),
    // till the end of the day (23:59) of "endDate"
    lte: lte.endOf('day').format(format),
  };
}
