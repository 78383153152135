import ng from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import { TradingAccount } from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import BaseController from '~/source/common/controllers/base';

import template from './reset-password-dialog.component.html';

interface RadioGroupOption {
  label: string;
  value: any;
}

enum GenerationMethod {
  ByMail,
  Manual,
}

class Controller extends BaseController {
  /*
   * bindings
   */

  close: () => {};

  /**
   * modal dialog component gets passed data by the 'resolve' binding parameter.
   * We use a setter to automatically extract the incoming value and destructur
   * it to the different values passed.
   */
  set resolve(val: { account: TradingAccount }) {
    if (_.isNil(val)) {
      return;
    }
    this.account = val.account;
  }
  account: TradingAccount;

  /*
   * services
   */
  customersServiceInst: CustomersService;

  /*
   * locals
   */

  title = 'RESET_PASSWORD';
  blockUiId = _.uniqueId('blockui-modal-reset-password-');
  growlId = _.uniqueId('');

  /**
   * Local state - used for notifying observables chains that the component is
   * destroyed and all subscriptions should be disposed of. Each subscription chain
   * that starts with `takeUntil($this.unsub$)` will be shortcuted and exited.
   */
  unsub$ = new rx.Subject<null>();

  generationMethodOptions$: rx.Observable<RadioGroupOption[]>;
  selectedMethod$ = new rx.BehaviorSubject<GenerationMethod>(
    GenerationMethod.Manual,
  );
  password$ = new rx.BehaviorSubject<string>('');
  doOperation$ = new rx.Subject<null>();
  resetPasswordFormValid$ = new rx.BehaviorSubject<boolean>(false);
  GenerationMethod = GenerationMethod;
  formValidationTrigger$ = new rx.Subject<boolean>();

  /*@ngInject */
  constructor(
    readonly $translate: ng.translate.ITranslateService,
    readonly customersService: () => CustomersService,
  ) {
    super();

    this.customersServiceInst = this.customersService();
  }

  $onInit() {
    this.generationMethodOptions$ = this.genObsMethodOptions();

    this.updatePasswordManuallyObs(
      this.unsub$,
      this.doOperation$,
      this.selectedMethod$,
      this.password$,
      this.resetPasswordFormValid$,
      this.formValidationTrigger$,
    ).subscribe();
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }

  /**
   * Setup and subscribe to 'update-password-maually' chain.
   *
   * When user press ok button and the method selected is then
   * 'update-password-manually' option, we send the server
   * a manual update password request.
   *
   * @return {void}
   * @private
   */
  updatePasswordManuallyObs(
    unsub$,
    doOperation$,
    selectedMethod$,
    password$,
    resetPasswordFormValid$: rx.Observable<boolean>,
    formValidationTrigger$,
  ) {
    return rx.pipe(
      () => doOperation$,
      rx.takeUntil(unsub$),
      rx.withLatestFrom(selectedMethod$, password$, resetPasswordFormValid$),
      rx.filter(
        ([a, selectedMethod, b, d]) =>
          selectedMethod === GenerationMethod.Manual,
      ),
      rx.tap(() => formValidationTrigger$.next()),
      rx.filter(([a, b, c, isFormValid]) => isFormValid),
      rx.switchMap(([a, b, password, d]) => {
        return rx.pipe(
          () => rx.obs.from(this.updatePasswordManualy(password)),
          rx.map((resp) => ({ resp, success: true })),
          rx.catchError((e) => rx.obs.of({ success: false, resp: e })),
        )(null);
      }),
      rx.filter((result) => result.success),
      rx.tap(() => this.close()),
    )(null);
  }

  /**
   * Cosntruct and send the server an update password request.
   *
   * @return {Promise}
   * @private
   */
  updatePasswordManualy(password) {
    return this.getTradingAccountResource().patchWithQuery({ password });
  }

  /**
   * Get the trading account resource.
   *
   * @return {CustomersService}
   * @private
   */
  getTradingAccountResource() {
    return this.customersServiceInst
      .getAccountResourceByType(
        this.account.customerId,
        this.account.type,
        this.account.id,
      )
      .setConfig({
        blockUiRef: this.blockUiId,
        growlRef: this.growlId,
        errorsTranslationPath: 'contact.errors',
      });
  }

  /**
   * Construct an observable - method types avaialbe options.
   *
   * Here we construct the options from a translation service that
   * return promises which we wait on and then continue to build the
   * options.
   *
   * @return {Observable}
   * @private
   */
  genObsMethodOptions() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          rx.obs.from(
            (this.$translate('SEND_EMAIL') as any) as Promise<string>,
          ),
          rx.obs.from(this.$translate('SET_NEW') as Promise<string>),
        ]),
      rx.map(([byMailTrans, byManualTrans]) => {
        return [
          /*
           * Will be enabled next version
           */
          // { label: byMailTrans, value: GenerationMethod.ByMail },
          { label: byManualTrans, value: GenerationMethod.Manual },
        ];
      }),
    )(null);
  }
}

export const ResetPasswordDialogComponent = {
  template,
  controller: Controller,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};

export default ResetPasswordDialogComponent;
