import template from './tooltip-info-copy-bar.component.html';
const styles = require('./tooltip-info-copy-bar.component.scss');

class TooltipInfoCopyBarController {
  styles = styles;

  info: Record<string, any>;
  templateId: string;
  isPinTooltipDisplayed = false;
  infoTableToCopy: string;

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const TooltipInfoCopyBarComponent = {
  template,
  controller: TooltipInfoCopyBarController,
  bindings: {
    info: '<',
    infoTableToCopy: '<',
    templateId: '<',
  },
};
