import * as _ from '@proftit/lodash';

import Promise from 'bluebird';

import { JSONObject } from '~/source/types/JsonObject';
import SocketService from '~/source/common/services/socket';
import CustomersService from '~/source/contact/common/services/customers';
import { TradingAccount } from '@proftit/crm.api.models.entities';
import { UserTokenModel } from '~/source/common/models/user-token-model';

const ACCOUNT_DEPOSIT_CHANNEL_ROOT = 'TradingAccountDeposit';

export default class DepositsSocket extends SocketService {
  static $inject = ['customersService', ...SocketService.$inject];

  customersService: () => CustomersService;

  get channelRoot() {
    return ACCOUNT_DEPOSIT_CHANNEL_ROOT;
  }

  /**
   * General parse of returned record from streamer.
   *
   * This is user to do overal parsing before any 'parseField...' methods.
   * Here we use it to retrieve the deposit with the required expanded fields
   * instead of doing that for each field via it's seperated endpoint.
   * Now we don't need to access 'users' endpoint.
   *
   * @param {object} data - the streamer provided record
   * @return {Promise} promise that resolve after the record has been general parsed.
   */
  generalParse(data): Promise<any> {
    const expandFieldsNames = ['owner'];
    const fieldsToExapnds = _.isEmpty(this.whiteList)
      ? expandFieldsNames
      : _.intersection(expandFieldsNames, this.whiteList);

    return (
      this.customersService()
        .getDepositResource(data.customerId, data.tradingAccountId, data.id)
        // this is a background operation. don't block or show errors
        .setConfig({
          suppressGrowl: true,
          suppressBlockUi: true,
        })
        .expand(fieldsToExapnds)
        .getOneWithQuery()
        .then((deposit) => {
          const expendedFields = _.pick(fieldsToExapnds, deposit);
          Object.assign(data, expendedFields);

          return data;
        }) as Promise<any>
    );
  }
}

export function buildChannelAccountDepositNew(
  user: UserTokenModel,
  account: TradingAccount,
) {
  return [
    `user.${user.id}`,
    '.account',
    `.${account.id}`,
    `.${ACCOUNT_DEPOSIT_CHANNEL_ROOT}`,
    '.new',
  ].join('');
}
