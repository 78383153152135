import * as _ from '@proftit/lodash';

export function convertFormAccountFieldsToAccountFields(form) {
  return _.flow([
    (acc) =>
      form.accountPlatform ? { ...acc, platform: form.accountPlatform } : acc,
    (acc) =>
      form.accountCurrency ? { ...acc, currency: form.accountCurrency } : acc,
    (acc) =>
      form.accountGroup
        ? { ...acc, tradingAccountGroup: form.accountGroup }
        : acc,
    (acc) =>
      form.accountStatus
        ? { ...acc, statusCode: form.accountStatus.code }
        : acc,
    (acc) =>
      !_.isUndefined(form.accountIsDemo)
        ? { ...acc, isDemo: form.accountIsDemo }
        : acc,
  ])({});
}
