import template from './brand-inactivity-fee.component.html';
const styles = require('./brand-inactivity-fee.component.scss');

import ng from 'angular';
import log from 'loglevel';

import BaseController from '~/source/common/controllers/base';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import { switchOn } from '~/source/common/utilities/switch-on';
import * as _ from '@proftit/lodash';
import {
  Brand,
  InactivityFeeSetting,
  InactivityCriteria,
} from '@proftit/crm.api.models.entities';
import { BrandsService } from '~/source/management/brand/services/brands';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { NgModelChange } from '~/source/common/utilities/create-ng-model-obs-mediator';
import { Changeset } from '~/source/common/utilities/changeset/changeset';
import { resetFieldUpdate } from '~/source/common/utilities/changeset/reset-field-update';
import { setFieldUpdate } from '~/source/common/utilities/changeset/set-field-update';
import { init as initChangeset } from '~/source/common/utilities/changeset/init';
import { InactivityCriteriasService } from '~/source/common/services/inactivity-criterias.service';
import { generateInactivityCriteria } from '~/source/common/data-generation/generate-inactivity-criteria';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';
import { InactivityFeeChargeTypesService } from '~/source/common/services/inactivity-fee-charge-types.service';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { wrapNgPermissionValidatePromise } from '~/source/common/utilities/wrap-ng-permission-validate-promise';
import * as rx from '@proftit/rxjs';

function generateNewFee(): InactivityFeeSetting {
  return {
    id: null,
    isActive: false,
    startDate: null,
    unitsChargeSize: 0,
    percentChargeSize: 0,
    inactivityPeriodLength: 0,
    inactivityCriteria: [],
  };
}

export class BrandInactivityFeeController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  brand$ = new rx.BehaviorSubject<Brand>(null);
  feeId$ = new rx.BehaviorSubject<number>(null);
  action$ = new rx.BehaviorSubject<string>('read');
  fee$ = new rx.BehaviorSubject<InactivityFeeSetting>(null);
  originalFee$ = new rx.BehaviorSubject<InactivityFeeSetting>(null);
  actionLabel$ = new rx.BehaviorSubject<string>(null);
  actionIcon$ = new rx.BehaviorSubject<string>(null);
  changeset$ = new rx.BehaviorSubject<Changeset<InactivityFeeSetting>>(null);
  criteriasSet$ = new rx.BehaviorSubject<InactivityCriteria[]>([]);
  partialCriteriasSet$ = new rx.BehaviorSubject<InactivityCriteria[]>([]);
  isEdit$ = new rx.BehaviorSubject<boolean>(false);
  isFormValid$ = new rx.BehaviorSubject<boolean>(false);
  isShowEditActionLink$ = new rx.BehaviorSubject<boolean>(false);

  opUpdateUiModel$ = new rx.Subject<NgModelChange>();
  opActivateEditMode$ = new rx.Subject<void>();
  opSaveModel$ = new rx.Subject<void>();
  opCancelEditMode$ = new rx.Subject<void>();
  opReloadActivityLogTable$ = new rx.Subject<void>();

  blockUiId = `${generateUuid()}-BlockUi`;
  growlId = `${generateUuid()}-Growl`;

  /*@ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly brandsService: () => BrandsService,
    readonly inactivityCriteriasService: InactivityCriteriasService,
    readonly inactivityFeeChargeTypesService: InactivityFeeChargeTypesService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly popupService: PopupService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [
        this.streamInitModel(),
        this.streamActionLabel(),
        this.streamActionIcon(),
        this.streamUiModelUpdating(),
        this.streamCalcInactivityCriteriasSet(),
        this.streamPartialCriteriasSet(),
        this.streamActivateEditMode(),
        this.streamCancelEditMode(),
        this.streamSave(),
        this.streamCalcIsShowEditActionLink(),
        observeCompChange<number>(
          this.feeId$,
          'feeId',
          this.lifecycles.onChanges$,
        ),
        observeCompChange<string>(
          this.action$,
          'action',
          this.lifecycles.onChanges$,
        ),
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamInitModel() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.brand$, this.feeId$, this.action$),
      rx.withLatestFrom(this.fee$),
      rx.switchMap(([[brand, feeId, action], currFee]) => {
        if (!['read', 'update', 'create'].includes(action)) {
          throw new Error('unimplemented action');
        }

        if (_.isNil(brand)) {
          return rx.obs.from([null]);
        }

        if (action === 'create') {
          return rx.obs.from([generateNewFee()]);
        }

        if (['read', 'update'].includes(action)) {
          if (_.get(['id'], currFee) === feeId) {
            return rx.obs.from([currFee]);
          }

          return rx.obs.from(this.getFee(brand.id, feeId));
        }

        throw new Error('not implemented');
      }),
      rx.tap((fee) => {
        this.fee$.next(fee);
        this.changeset$.next(initChangeset(fee));
      }),
    )(null);
  }

  streamActionLabel() {
    return rx.pipe(
      () => this.action$,
      rx.map((action) => {
        return switchOn(
          {
            read: () => 'EDIT',
            create: () => 'EDIT',
          },
          action,
          () => '',
        );
      }),
      rx.tap((label) => this.actionLabel$.next(label)),
    )(null);
  }

  streamActionIcon() {
    return rx.pipe(
      () => this.action$,
      rx.map((action) => {
        return switchOn(
          {
            read: () => 'pf-pencil',
            create: () => 'pf-pencil',
          },
          action,
          () => '',
        );
      }),
      rx.tap((icon) => this.actionIcon$.next(icon)),
    )(null);
  }

  getFee(brandId: number, feeId: number) {
    return this.brandsService()
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
      })
      .getInactivityFeeSettingResource(brandId, feeId)
      .embed(['inactivityCriteria'])
      .getOneWithQuery<IElementRestNg<InactivityFeeSetting>>()
      .then((x) => x.plain());
  }

  updateFee(brandId: number, feeId: number, fee: InactivityFeeSetting) {
    return this.brandsService()
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
      })
      .getInactivityFeeSettingResource(brandId, feeId)
      .patchWithQuery<IElementRestNg<InactivityFeeSetting>>(fee);
  }

  createFee(brandId: number, fee: InactivityFeeSetting) {
    return this.brandsService()
      .setConfig({
        growlRef: this.growlId,
        blockUiRef: this.blockUiId,
      })
      .addInactivityFee(brandId, fee);
  }

  streamUiModelUpdating() {
    return rx.pipe(
      () => this.opUpdateUiModel$,
      rx.withLatestFrom(this.changeset$, this.fee$),
      rx.map(([change, changeset, currentFee]) => {
        const newChangeset = (function () {
          if (changeset.original[change.fieldName] === change.nextValue) {
            return resetFieldUpdate(change.fieldName, changeset);
          }

          return setFieldUpdate(change.fieldName, change.nextValue, changeset);
        })();

        const newFee = {
          ...currentFee,
          [change.fieldName]: change.nextValue,
        };

        return [newFee, newChangeset];
      }),
      rx.tap(([asset, changeset]) => {
        this.fee$.next(asset as InactivityFeeSetting);
        this.changeset$.next(changeset as Changeset<InactivityFeeSetting>);
      }),
    )(null);
  }

  streamCalcInactivityCriteriasSet() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.switchMap(() => rx.obs.from(this.getInacitvityCriterias())),
      rx.tap((inactivityCriterias) =>
        this.criteriasSet$.next(inactivityCriterias),
      ),
    )(null);
  }

  streamPartialCriteriasSet() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.criteriasSet$, this.fee$),
      rx.map(([criteriasSet, fee]) => {
        if (_.isNil(fee)) {
          return criteriasSet;
        }

        return _.differenceWith(
          (a, b) => a.code === b.code,
          criteriasSet,
          fee.inactivityCriteria,
        );
      }),
      rx.tap((partialSet) => this.partialCriteriasSet$.next(partialSet)),
    )(null);
  }

  streamActivateEditMode() {
    return rx.pipe(
      () => this.opActivateEditMode$,
      rx.withLatestFrom(this.fee$),
      rx.tap(([a, fee]) => {
        this.originalFee$.next(fee);
        this.isEdit$.next(true);
      }),
    )(null);
  }

  streamCancelEditMode() {
    return rx.pipe(
      () => this.opCancelEditMode$,
      rx.withLatestFrom(this.originalFee$),
      rx.tap(([a, originalFee]) => {
        this.fee$.next(originalFee),
          this.changeset$.next(initChangeset(originalFee));
        this.isEdit$.next(false);
      }),
    )(null);
  }

  streamSave() {
    return rx.pipe(
      () => this.opSaveModel$,
      rx.withLatestFrom(this.fee$),
      rx.map(([a, fee]) => _.omit(['id'], fee)),
      rx.map((fee) => this.modelNormalizer.normalize(fee)),
      rx.withLatestFrom(this.brand$, this.feeId$, this.action$),
      rx.switchMap(([fee, brand, feeId, action]) => {
        if (action === 'create') {
          return rx.pipe(
            () => rx.obs.from(this.createFee(brand.id, fee)),
            rx.tap(() => this.isEdit$.next(false)),
            rx.catchError((err, caught$) => {
              log.error('error in saving', err);
              return rx.obs.EMPTY;
            }),
          )(null);
        }

        if (['read', 'update'].includes(action)) {
          return rx.pipe(
            () => rx.obs.from(this.updateFee(brand.id, feeId, fee)),
            rx.tap(() => this.isEdit$.next(false)),
            rx.catchError((err, caught$) => {
              log.error('error in saving', err);
              return rx.obs.EMPTY;
            }),
          )(null);
        }

        throw new Error('not implemented');
      }),
      rx.tap(() => this.opReloadActivityLogTable$.next()),
    )(null);
  }

  streamCalcIsShowEditActionLink() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.isEdit$,
          rx.pipe(() =>
            rx.obs.from(
              wrapNgPermissionValidatePromise(
                this.PermPermissionStore.getPermissionDefinition(
                  'automation.inactivity_U',
                ),
              ),
            ),
          )(null),
        ),
      rx.map(([isEdit, permInactivityUpdate]) => {
        if (!permInactivityUpdate) {
          return false;
        }

        return !isEdit;
      }),
      rx.tap((isShow) => this.isShowEditActionLink$.next(isShow)),
    )(null);
  }

  getInacitvityCriterias() {
    return this.inactivityCriteriasService
      .getListWithQuery<IElementRestNg<InactivityCriteria>>()
      .then((d) => d.plain());
  }

  tapStartAsyncWorkInUiMain() {
    return tapStartAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  tapStopAsyncWorkInUiMain() {
    return tapStopAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  openLogsPopupTable(brand, fee) {
    this.popupService.open({
      component: 'prfInactivityFeeSettingLogsPopupTable',
      resolve: {
        brand,
        fee,
      },
    });
  }
}

export const BrandInactivityFeeComponent = {
  template,
  controller: BrandInactivityFeeController,
  bindings: {
    brand: '<',
    feeId: '<',
    action: '<',
  },
};
