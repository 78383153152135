import template from './inactivity-fee-dashboard.component.html';
const styles = require('./inactivity-fee-dashboard.component.scss');

import ng from 'angular';

import BaseController from '~/source/common/controllers/base';
import { Brand } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';

export class InactivityFeeDashboardController {
  styles = styles;
  selectedBrand$ = new rx.BehaviorSubject<Brand>(null);

  /*@ngInject */
  constructor() {}

  set selectedBrand(val: Brand) {
    this.selectedBrand$.next(val);
  }
  get selectedBrand() {
    return this.selectedBrand$.getValue();
  }
}

export const InactivityFeeDashboardComponent = {
  template,
  controller: InactivityFeeDashboardController,
};
