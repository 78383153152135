import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { patchConnectionIsActiveAsTrue } from './patch-connection-is-active-as-true';
import { reorderCompanies } from './../helper-functions/reorder-companies';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import { ClearingCompanyConnectionsListType } from '~/source/management/clearing-companies/components/manage/clearing-company-connections-list-type';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';

type ReorderClearingCompanyConnectionAction = {
  index: number;
  item: ClearingCompanyConnection;
  external: boolean;
  type: ClearingCompanyConnectionsListType;
  previousIndex: number;
};

export const streamReorderActiveCompanyConnections = (
  reorderActiveCompanyConnectionsAction: rx.Subject<{
    data: ReorderClearingCompanyConnectionAction;
  }>,
  clearingCompanies$: rx.Observable<ClearingCompanyConnection[]>,
  clearingCompanyConnectionsService: ClearingCompanyConnectionsService,
  blockUiId: string,
  brandId: number,
) => {
  return rx.pipe(
    () => reorderActiveCompanyConnectionsAction,
    rx.withLatestFrom(clearingCompanies$),
    rx.switchMap(([formAction, dataForBrand]) => {
      return rx.obs
        .from(
          patchConnectionIsActiveAsTrue(
            formAction,
            dataForBrand,
            clearingCompanyConnectionsService,
          ),
        )
        .pipe(rx.catchError(() => rx.obs.NEVER));
    }),
    rx.map(([formAction, connections]) => {
      const { data } = formAction;
      const { index, item, previousIndex } = data;
      return reorderCompanies(index, item, previousIndex, connections);
    }),
    rx.switchMap((data) => {
      const payload = data.map((dataItem) => {
        return {
          id: dataItem.id,
          order: dataItem.order,
        };
      });
      return rx.obs
        .from(
          clearingCompanyConnectionsService
            .setConfig({ blockUiRef: blockUiId })
            .patchForBrand(brandId, payload),
        )
        .pipe(rx.catchError(() => rx.obs.NEVER));
    }),
    shareReplayRefOne(),
  )(null);
};
