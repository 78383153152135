import Component from '~/source/common/components/dropdowns/base/component';
import { Brand } from '@proftit/crm.api.models.entities';
import { fetchAllWithPaging } from '~/source/common/utilities/fetch-all-with-paging';

const PAGE_FETCH_SIZE = 500;

class BrandCampaignSelectController extends Component.controller {
  static $inject = [
    'marketingCampaignsService',
    ...Component.controller.$inject,
  ];

  // bindings
  brand: Brand;

  /**
   *  Fetch states on selected brand change
   */
  init() {
    this.$scope.$watch('vm.brand', () => {
      this.output = []; // reset states when the country changes

      if (!this.brand) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    return fetchAllWithPaging(
      PAGE_FETCH_SIZE,
      (page, size) => this.queryPageStep(page, size),
      () => this.dataServiceInst.total,
    );
  }

  queryPageStep(page: number, size: number) {
    return this.dataServiceInst
      .setConfig({ blockUiRef: `dropdown_${this.dropdownId}` })
      .setPage(page, size)
      .filter('brandId', this.brand.id)
      .getListWithQuery();
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'marketing.SELECT_CAMPAIGN';
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Campaigns',
    };
  }
}

const BrandCampaignSelectComponent = Component.config({
  controller: BrandCampaignSelectController,
  bindings: {
    brand: '<',
  },
});

export default BrandCampaignSelectComponent;
