import * as rx from '@proftit/rxjs';

import { Campaign } from '@proftit/crm.api.models.entities';

export class CampaignsChangesClientPubsubService {
  campaignChanged$ = new rx.Subject<Campaign>();

  /**
   * Get the service changes observable. Clients then could subscribe
   * for changes notifcations on it.
   *
   * @return {Observable}
   */
  getObs() {
    return this.campaignChanged$.asObservable();
  }

  /**
   * Publish a change notificatin.
   *
   * @param campaign - The updated campaign
   * @return {void}
   */
  publish(campaign: Campaign) {
    this.campaignChanged$.next(campaign);
  }
}

export default CampaignsChangesClientPubsubService;
