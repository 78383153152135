import { IHttpService } from 'angular';
// import Url from 'url-parse';
// import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  // init,
  // setProtocol,
  // setHost,
  // setResource,
  // addHeader,
  // getAsOne,
  // getAsList,
  // appendResourceId,
  // setPayload,
  // update as genReqUpdate,
  // create as genReqCreate,
  // appendResource,
  initRequestDomainResource,
  getAsList,
  setTokenHeader,
} from '@proftit/request-client';

import { MtSecurity } from '@proftit/tradingcore.api.models.entities';
// import { removeColonAtEnd } from '@proftit/general-utilities';
// import { PlatformConnection } from '~/source/common/models/platform-connection';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ngPerformMongo } from '../utilities/ng-perform-mongo';

export const RESOURCE_PATH = 'MTSecurities';

export class ApiMtSecuritiesService {
  /* @ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  getSecurities(apiUrl: string, token: string): Promise<MtSecurity[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => ngPerformMongo<MtSecurity[]>(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }
}
