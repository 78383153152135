import * as _ from '@proftit/lodash';
import { getWholeValueOfBean, logicalAndProxyValidatorFactory } from '@proftit/proxy-bean';
import urlParse from 'url-parse';

const IS_NOT_EMPTY_PROXY_VALIDATOR = 'IS_NOT_EMPTY_PROXY_VALIDATOR';
function isNotEmptyProxyValidator(bean) {
  const value = getWholeValueOfBean(bean);

  if (!_.isEmpty(value)) {
    return {
      isValid: true,
      code: IS_NOT_EMPTY_PROXY_VALIDATOR
    };
  }

  return {
    isValid: false,
    code: IS_NOT_EMPTY_PROXY_VALIDATOR,
    payload: {
      value
    }
  };
}

const IS_NOT_NIL_PROXY_VALIDATOR = 'IS_NOT_NIL_PROXY_VALIDATOR';
function isNotNilProxyValidator(bean) {
  const value = getWholeValueOfBean(bean);

  if (!_.isNil(value)) {
    return {
      isValid: true,
      code: IS_NOT_NIL_PROXY_VALIDATOR
    };
  }

  return {
    isValid: false,
    code: IS_NOT_NIL_PROXY_VALIDATOR,
    payload: {
      value
    }
  };
}

const IS_URL_PROXY_VALIDATOR = 'IS_URL_PROXY_VALIDATOR';
function isUrlProxyValidator(bean) {
  const value = getWholeValueOfBean(bean);
  const url = urlParse(value, {});

  if (_.isEmpty(url.protocol)) {
    return {
      isValid: false,
      code: IS_URL_PROXY_VALIDATOR,
      payload: {
        value,
        url
      }
    };
  }

  if (_.isEmpty(url.host)) {
    return {
      isValid: false,
      code: IS_URL_PROXY_VALIDATOR,
      payload: {
        value,
        url
      }
    };
  }

  return {
    isValid: true,
    code: IS_URL_PROXY_VALIDATOR
  };
}

function isNotNilAndNotEmptyProxyValidator(bean) {
  return logicalAndProxyValidatorFactory([isNotNilProxyValidator, isNotEmptyProxyValidator])(bean);
}

export { IS_NOT_EMPTY_PROXY_VALIDATOR, IS_NOT_NIL_PROXY_VALIDATOR, IS_URL_PROXY_VALIDATOR, isNotEmptyProxyValidator, isNotNilAndNotEmptyProxyValidator, isNotNilProxyValidator, isUrlProxyValidator };
