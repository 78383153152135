import TableLiveController from '~/source/common/components/table/table-live.controller';
import bonusSettings from '../bonus-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import {
  TradingAccount,
  Customer,
  TransactionTransferBonus,
} from '@proftit/crm.api.models.entities';
import BonusesSocket from '~/source/contact/common/services/bonuses-socket.service';
import PopupService from '~/source/common/components/modal/popup.service';
import template from './bonus-table.html';
import popupTemplate from '../add-bonus-popup.html';
import * as rx from '@proftit/rxjs';
import { BONUS_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { useStream } from '~/source/common/utilities/use-stream';
import * as _ from '@proftit/lodash';
import { generateBonusAdditionals } from './../utilities/generate-bonus-additionals/generate-bonus-additionals';
import { BonusAdditional } from './../utilities/bonus-additional.class';

enum ColumnRenderers {
  Regular = 'regular',
  Actions = 'actions',
  Note = 'note',
}

class Controller extends TableLiveController {
  static $inject = [
    '$scope',
    'customersService',
    'popupService',
    'bonusesSocketService',
    'dateFormat',
    'highlightEntityService',
    'tokensService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  blockUiId = 'bonusesTable';
  customersService: () => CustomersService;
  account: IElementRestNg<TradingAccount>;
  bonusesSocketService: BonusesSocket;
  bonuses: IElementRestNg<any>;
  dataServiceInstance: CustomersService;
  customer: IElementRestNg<Customer>;
  popupService: PopupService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  unsub$: rx.Subject<any> = new rx.Subject<null>();
  bonusAdditional: { [key: number]: BonusAdditional } = {};

  $onInit() {
    super.$onInit();

    Object.assign(this, {
      excludeNotifications: [],
      settings: Object.assign({}, bonusSettings),
      dataServiceInstance: this.customersService(),
      cols: [...bonusSettings.tableColumns].filter((column) =>
        ['common', this.account.type].includes(column.type),
      ),
    });

    this.initTable();
    this.$scope.$on('bonusesTableReload', this.reloadTable.bind(this));
    useStream(this.streamBonusUpdateStatus(), this.unsub$);
  }

  streamBonusUpdateStatus() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === BONUS_STATUS_UPDATE),
      rx.tap(() => {
        this.reloadTable();
      }),
    )(null);
  }

  get pageKey() {
    return 'bonus';
  }

  colRendererToShow(col) {
    if (col.field === 'note') {
      return ColumnRenderers.Note;
    }
    if (col.field === 'actions') {
      return ColumnRenderers.Actions;
    }

    return ColumnRenderers.Regular;
  }

  patchNoteAction({ note, bonusId }: { note: string; bonusId: number }) {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiId })
      .patchBonus(this.customer.id, this.account.id, bonusId, { note })
      .then((newBonus) => {
        this.onBonusNoteChange({
          bonusId: newBonus.id,
          note: String(newBonus.note),
        });
      })
      .catch((e) => {});
  }

  onBonusNoteChange({ bonusId, note }: { bonusId: number; note: string }) {
    const changedBonus = this.bonuses.find((bonus) => bonus.id === bonusId);
    if (_.isNil(changedBonus)) {
      return;
    }
    changedBonus.note = note;
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService() {
    return this.bonusesSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName() {
    return 'vm.bonuses';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.bonuses;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getBonusesResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: this.blockUiId })
      .expand(['currency', 'user', 'canceler', 'promoCode'])
      .sort({ createdAt: 'desc' });
  }

  parseLoadedData(data) {
    this.bonuses = data;
    this.bonusAdditional = generateBonusAdditionals(this.bonuses);
    return data;
  }

  /**
   * perform action from dropdown
   * @param  actionCode - cancel / confirm bonus
   * @param  bonus - bonus
   */
  onDropDownAction(actionCode: string, bonus: TransactionTransferBonus) {
    const actionsDispatch = {
      cancel: () => (this.bonusAdditional[bonus.id].showActionWarning = true),
      startConfirm: () => this.onApproveBonus(bonus),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  /**
   * Opens the "add bonus" modal
   * @returns {void}
   */
  openAddBonusPopup() {
    this.popupService.open({
      template: popupTemplate,
      scope: this.$scope,
      data: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * Called on bonus cancelation by user
   *
   * @param {object} bonus
   */
  onCancelBonus(bonus) {
    this.dataServiceInstance
      .cancelBonus(this.customer.id, this.account.id, bonus.id)
      .then(() => this.reloadTable());
  }

  /**
   * Called on bonus approvel by user
   *
   * @param {object} bonus
   */
  onApproveBonus(bonus: TransactionTransferBonus) {
    this.dataServiceInstance
      .approveBonus(this.customer.id, this.account.id, bonus.id)
      .then(() => {
        this.reloadTable();
      });
  }

  /**
   * Open the "bonus log" popup
   *
   * @return {void}
   */
  openBonusTablePopup() {
    this.popupService.open({
      component: 'prfBonusTablePopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    account: '<',
    customer: '<',
  },
  controllerAs: 'vm',
};
