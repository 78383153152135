import { isApprovable } from '~/source/contact/contact-page/trading-account/common/bonus/utilities/is-approveable/is-approvable';
import { MenuItem } from './../menu-item.interface';
import { isCancelable } from './../is-cancelable/is-cancelable';

/**
 * Calculate relevant menu items for Bonus row.
 *
 * @param  bonus - bonus
 */
export function calcMenuItemsForBonus(bonus): MenuItem[] {
  let menuItems = [];

  if (isCancelable(bonus)) {
    menuItems = [
      ...menuItems,
      { labelCode: 'common.CANCEL', actionCode: 'cancel' },
    ];
  }

  if (isApprovable(bonus)) {
    menuItems = [
      ...menuItems,
      { labelCode: 'common.CONFIRM', actionCode: 'startConfirm' },
    ];
  }

  return menuItems;
}
