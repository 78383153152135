import * as _ from '@proftit/lodash';

import { Currency } from '@proftit/crm.api.models.entities';

/**
 * Calculate the minimum input step for currency.
 *
 * @param {Currency} currency - the currency.
 * @returns {string} string represtings the step amount.
 *
 * @example
 *
 *   calcCurrencyMinDecimalStep({ decimals: 3 })
 *   // '0.001'
 */
export function calcCurrencyMinDecimalStep(currency: Currency) {
  if (_.isNil(currency)) {
    return '1';
  }

  if (currency.decimals === 0) {
    return '1';
  }

  return `0.${'0'.repeat(currency.decimals - 1)}1`;
}

export default calcCurrencyMinDecimalStep;
