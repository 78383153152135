import template from './campaign-blacklisted-countries-data.html';
import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { IScope } from 'angular';
import type { StateService } from '@uirouter/angularjs';
import { Country } from '@proftit/crm.api.models.entities';
import MarketingCampaignsService from '~/source/marketing/services/campaigns.service';

class CampaignBlacklistedCountriesDataController extends BaseController {
  marketingCampaignsServiceInstance: MarketingCampaignsService;

  // bindings
  selectedCountries: Country[];

  prevAttributes: Country[];
  isEdit: boolean = false;

  /*@ngInject*/
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $scope: IScope,
    readonly $state: StateService,
    readonly marketingCampaignsService: () => MarketingCampaignsService,
  ) {
    super();

    this.marketingCampaignsServiceInstance = this.marketingCampaignsService();
  }

  /**
   * Enter edit mode:
   * Save current model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = [...this.selectedCountries];

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.selectedCountries, this.prevAttributes);

    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    this.marketingCampaignsServiceInstance
      .setConfig({ blockUiRef: 'campaignBlacklistedCountriesForm' })
      .updateBlacklistedCountries(
        this.$state.params.campaignId,
        this.selectedCountries,
      )
      .then((res) => {
        this.isEdit = false;
      });
  }
}

export default {
  template,
  controller: CampaignBlacklistedCountriesDataController,
  controllerAs: 'vm',
  bindings: {
    selectedCountries: '<',
  },
};
