import template from './add-transaction-external-cashier.component.html';
const styles = require('./add-transaction-external-cashier.component.scss');

import log from 'loglevel';
import ng, { ISCEService } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import {
  Customer,
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import { getProcessGuidForTransaction } from '@proftit/crm.logic.general';
import { CustomersService } from '~/source/contact/common/services/customers';
import { useStreams } from '@proftit/rxjs.adjunct';
import DepositsSocket, {
  buildChannelAccountDepositNew,
} from '~/source/contact/common/services/deposits-socket.service';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { TokensService } from '~/source/auth/services/tokens';
import { TradingAccountTransactionStatusCode } from '~/source/common/models/trading-account-transaction-status';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { MessageType } from '~/source/common/models/message-type';

enum IframeType {
  Url = 'url',
  InlineSource = 'inlineSource',
  None = 'none',
}

export class AddTransactionExternalCashierController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customer: Customer;

  account: TradingAccount;

  onDone: () => void;

  onDepositDeclined: (a: { content: string; type: MessageType }) => void;

  IframeType = IframeType;
  currentUser$ = this.streamCurrentUser();
  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );
  account$ = observeShareCompChange<TradingAccount>(
    this.lifecycles.onChanges$,
    'account',
  );
  cashierInfo$ = this.streamCashierInfo();
  iframeType$ = this.streamIframeType();
  cashierUrl$ = this.streamCashierUrl();
  cashierContent$ = this.streamCashierContent();
  processedDeposit$ = this.streamProcessedDeposit();

  /*@ngInject */
  constructor(
    readonly $sce: ISCEService,
    readonly tokensService: TokensService,
    readonly customersService: () => CustomersService,
    readonly depositsSocketService: DepositsSocket,
    readonly $translate: ng.translate.ITranslateService,
  ) {
    useStreams(
      [
        this.currentUser$,
        this.customer$,
        this.account$,
        this.streamActionClosePopup(),
        this.streamNotifyOnDeclined(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /**
   * Stream - current user
   * @return stream
   */
  streamCurrentUser() {
    return rx.pipe(
      () => this.lifecycles.onInit$,
      rx.map(() => this.tokensService.getCachedUser()),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - cashier info
   * @return stream
   */
  streamCashierInfo() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.customer$, this.account$),
      rx.switchMap(([customer, account]) =>
        rx.obs.from(
          this.customersService().getCashierDetails(customer.id, account.id),
        ),
      ),
      rx.catchError((err, caught) => {
        log.error('error fetching cacshier details', err);
        return [];
      }),
      rx.map((cashiersData) => {
        if (cashiersData.legnth === 0) {
          return null;
        }

        const firstCashier = cashiersData[0];
        return firstCashier;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - iframe type
   * @return stream
   */
  streamIframeType() {
    return rx.pipe(
      () => this.cashierInfo$,
      rx.map((cashierInfo) => {
        if (_.isNil(cashierInfo)) {
          return IframeType.None;
        }

        if (cashierInfo.cashierData.iframeUrl) {
          return IframeType.Url;
        }

        if (cashierInfo.cashierData.iframeContent) {
          return IframeType.InlineSource;
        }

        log.error('unhandled external cashier deposit method');
        return IframeType.None;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - cashier url
   * @return stream
   */
  streamCashierUrl() {
    return rx.pipe(
      () => this.cashierInfo$,
      rx.map((cashierInfo) => _.get(['cashierData', 'iframeUrl'], cashierInfo)),
      rx.map((url) => {
        if (_.isEmpty(url)) {
          return url;
        }

        return this.$sce.trustAsResourceUrl(url);
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - cashier content
   * @return stream
   */
  streamCashierContent() {
    return rx.pipe(
      () => this.cashierInfo$,
      rx.map((cashierInfo) =>
        _.get(['cashierData', 'iframeContent'], cashierInfo),
      ),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - processed deposit
   * @return stream
   */
  streamProcessedDeposit() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.currentUser$,
          this.account$,
          this.cashierInfo$.pipe(
            rx.map((cashierInfo) =>
              _.get(['cashierData', 'referenceId'], cashierInfo),
            ),
          ),
        ),
      rx.switchMap(([user, account, referenceId]) => {
        if (_.isNil(referenceId)) {
          return rx.obs.from([]);
        }

        const channel = buildChannelAccountDepositNew(user, account);
        return observeChannel(this.depositsSocketService, channel);
      }),
      rx.withLatestFrom(this.customer$, this.account$),
      rx.switchMap(([incomingDeposit, customer, account]) => {
        return rx.obs.from(
          this.customersService()
            .expand(['transactionTransferCreditCard'])
            .getDeposit(customer.id, account.id, (incomingDeposit as any).id),
        );
      }),
      rx.withLatestFrom(this.cashierInfo$),
      rx.filter(([deposit, cashierInfo]) => {
        const incomingReferenceId = getProcessGuidForTransaction(
          deposit,
          cashierInfo,
        );

        const referenceId = _.get(['cashierData', 'referenceId'], cashierInfo);

        return `${incomingReferenceId}` === `${referenceId}`;
      }),
      rx.map(([deposit, cashierInfo]) => deposit),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  /**
   * Stream - action close popup
   * @return stream
   */
  streamActionClosePopup() {
    return rx.pipe(
      () => this.processedDeposit$,
      rx.filter(
        (deposit) =>
          deposit.transactionStatusCode ===
          TradingAccountTransactionStatusCode.Approved,
      ),
      rx.tap(() => this.onDone()),
    )(null);
  }

  streamNotifyOnDeclined() {
    return rx.pipe(
      () => this.processedDeposit$,
      rx.filter(
        (deposit) =>
          deposit.transactionStatusCode ===
          TradingAccountTransactionStatusCode.Declined,
      ),
      rx.switchMap(() => {
        return this.$translate('deposits.errors.GENERAL').then(
          (trans: string) => ({
            content: trans,
            type: MessageType.Error,
          }),
        ) as Promise<{ content: string; type: MessageType }>;
      }),
      rx.tap((msg) => this.onDepositDeclined(msg)),
    )(null);
  }
}

export const AddTransactionExternalCashierComponent = {
  template,
  controller: AddTransactionExternalCashierController,
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
    onDepositDeclined: '&',
  },
};
