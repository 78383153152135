import ng from 'angular';
import _ from 'underscore';
import { ICacheFactory, ICache } from 'cachefactory';
import { TransitionService } from '@uirouter/core';
import BaseService from '~/source/common/services/baseService';
import ModalsCacheService from '~/source/common/services/modals-cache.service';
import ModalOpenState from '~/source/common/models/modal-open-state';
import log from 'loglevel';
import template from './modal.html';
import * as _l from '@proftit/lodash';

export class ModalService extends BaseService {
  static $inject = [
    '$uibModal',
    '$rootScope',
    'blockUI',
    'CacheFactory',
    '$uibModalStack',
    'modalsCacheService',
    '$transitions',
  ];

  options: Object;
  CacheFactory: ICacheFactory;
  $rootScope: ng.IRootScopeService;
  $uibModal: ng.ui.bootstrap.IModalService;
  $uibModalStack: ng.ui.bootstrap.IModalStackService;
  modalsCacheService: ModalsCacheService;
  $transitions: TransitionService;

  constructor(...args) {
    super(...args);
    this.options = {};
  }

  /**
   * Use options' scope or create a new scope if not defined, then add initial values to it.
   * @param {object} options
   * @returns {Scope}
   * @private
   */
  initScope(options) {
    let id = _.uniqueId();
    const modalScope = options.scope
      ? options.scope.$new()
      : this.$rootScope.$new(true);

    if (options.data) {
      // if data is available, add it to the scope
      _(modalScope).extend(options.data);
      id = options.data.referenceId || id;
    }
    modalScope.referenceId = id;

    const getCreateArgs = _l.isNil(options.openStateOnNew)
      ? {}
      : {
          createDefaults: {
            openState: options.openStateOnNew,
          },
        };

    const modalCacheInfo = this.modalsCacheService.getCreate(
      modalScope.referenceId,
      getCreateArgs,
    );

    // let outside component user be able to override modal saved state.
    modalScope.minimized =
      options.openState === ModalOpenState.Unset
        ? modalCacheInfo.openState === ModalOpenState.Close
        : options.openState === ModalOpenState.Close;

    return modalScope;
  }

  /**
   * Build the options that will be passed to uibModal.open
   *
   * use "defaults" for undefined values
   *
   * @param {object} options
   * @param {object} defaults
   * @returns {Object}
   * @private
   */
  buildOptions(options, defaults) {
    // Wrap appendTo, if needed
    if (options.appendTo && !(options.appendTo instanceof ng.element)) {
      options.appendTo = ng.element(options.appendTo);
    }

    if (_l.isNil(options.openState)) {
      options.openState = ModalOpenState.Unset;
    }

    // initialize modal scope
    options.scope = this.initScope(options);

    // Add defaults
    return _.defaults(options, defaults, {
      shouldCloseOnRouteChange: true,
      appendTo: ng.element(
        options.scope.minimized
          ? '[prf-modal-container-minimized]'
          : '[prf-modal-container]',
      ),
    });
  }

  open(options, defaults = {}) {
    let deregister;
    const modal = this.$uibModal.open(
      this.buildOptions(options, {
        template,
        backdrop: false,
        ...defaults,
      }),
    );

    modal.result
      .then(
        () => {},
        (reason) => {
          // swallow. these are not really exceptions
          if (
            ['cancel', 'backdrop click', 'escape key press'].includes(reason)
          ) {
          } else {
            log.error('error in modal', reason);
            throw reason;
          }
        },
      )
      .finally(() => {
        if (deregister) {
          deregister();
        }
      });

    modal.opened.then(() => {
      if (options.shouldCloseOnRouteChange) {
        deregister = this.$transitions.onStart({}, () => {
          modal.dismiss('cancel');
        });
      }
      const { modalScope } = this.$uibModalStack.getTop().value;
      modalScope.$watch('vm.minimized', (isMinimized) => {
        if (isMinimized !== undefined) {
          const openState = isMinimized
            ? ModalOpenState.Close
            : ModalOpenState.Open;
          this.modalsCacheService.setOpenState(
            modalScope.referenceId,
            openState,
          );
        }
      });
    });
    return modal;
  }
}

export default ModalService;
