import template from './user-profile-summary-bar.html';
const styles = require('./user-profile-summary-bar.scss');
import BaseController from '~/source/common/controllers/base';
import { UserSettingsService } from '~/source/common/services/user-settings';
import { Language, UserSettings } from '@proftit/crm.api.models.entities';
import { AvailableLanguage } from '~/source/management/user/services/available-language';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import angular from 'angular';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateUuid } from '@proftit/general-utilities';

export class UserProfileSummaryBarController extends BaseController {
  shouldShowAutoAssignment: boolean;
  isAutoAssignmentEnabled: boolean;
  availableLanguages$ = this.streamAvailableLanguages();
  selectedPreferredLanguage = new FormControl<Language>(null);
  currentPreferredLanguageUserSettings$: rx.Observable<
    UserSettings<{ preferredLanguageCode: string }>
  > = rx.obs
    .from(
      this.userSettingsService.getSetting('preferredLanguage', {
        preferredLanguageCode: 'EN',
      }),
    )
    .pipe(
      rx.map((userSettings) => userSettings.plain()),
      shareReplayRefOne(),
    );

  blockUiId = generateUuid();
  blockUiInstance: angular.blockUI.BlockUIService;
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor(
    readonly availableLanguagesService: () => AvailableLanguage,
    readonly userSettingsService: UserSettingsService,
    readonly $translate: angular.translate.ITranslateService,
    readonly blockUI: angular.blockUI.BlockUIService,
  ) {
    super();
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
  }

  $onInit() {
    useStreams(
      [
        this.selectedPreferredLanguage.value$,
        this.streamSyncSelectedPreferredLanguageWithDB(),
        this.streamSetDBSelectedPreferredLang(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  streamAvailableLanguages() {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.tap(() => this.blockUiInstance.start()),
      rx.switchMap(() =>
        this.availableLanguagesService().getUserAvailableLanguages(),
      ),
      rx.tap(() => this.blockUiInstance.stop()),
      rx.catchError((err) => {
        this.blockUiInstance.stop();
        return rx.obs.of([]);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSyncSelectedPreferredLanguageWithDB() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.currentPreferredLanguageUserSettings$,
          this.availableLanguages$,
        ),
      rx.map(([userSettings, availableLanguages]) => {
        return availableLanguages.find(
          (lang) => lang.code2 === userSettings.value.preferredLanguageCode,
        );
      }),
      rx.tap((preferredLang: Language) =>
        this.selectedPreferredLanguage.setValueAsFirst(preferredLang),
      ),
    )(null);
  }

  streamSetDBSelectedPreferredLang() {
    return rx.pipe(
      () => this.selectedPreferredLanguage.value$ as rx.Observable<Language>,
      rx.delay(10),
      rx.withLatestFrom(
        this.currentPreferredLanguageUserSettings$,
        this.selectedPreferredLanguage.isAtFirstValue$,
      ),
      rx.filter(([selectedLang, currentLang, isAtFirstLang]) => !isAtFirstLang),
      rx.switchMap(
        ([newPreferredLang, currentPrefLang, a]: [any, any, any]) => {
          return this.userSettingsService.setSettingValue(currentPrefLang.id, {
            preferredLanguageCode: newPreferredLang.code2,
          }) as any;
        },
      ),
      rx.tap((userSettings: any) =>
        this.$translate.use(
          userSettings.value.preferredLanguageCode.toLowerCase(),
        ),
      ),
    )(null);
  }
}

export const userProfileSummaryBarComponent = {
  template,
  controller: UserProfileSummaryBarController,
  bindings: {
    user: '<',
    editPic: '&',
    brands: '<',
    shouldShowAutoAssignment: '<',
    isAutoAssignmentEnabled: '<',
    isAutoAssignmentEnabledFormControl: '<',
  },
};
