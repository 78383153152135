export const depositLinkedDocumentsPopupColumns = [
  {
    title: 'contact.compliance.IMAGE',
    field: 'file',
    fieldName: 'file',
    fieldFormat: '{{ file.name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
    renderers: {
      view: {
        type: 'image',
        modelInit: (cell) => {
          if (cell.type === 'application/pdf') {
            return '/assets/img/attachments/pdf.png';
          }
          return cell.resizeUrl;
        },
      },
    },
  },
  {
    title: 'contact.compliance.NAME',
    field: 'name',
    fieldName: 'name',
    fieldFormat: '{{ name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
    renderers: {
      edit: {
        type: 'inputEditor',
        placeholder: 'Insert name',
      },
    },
  },
  {
    title: 'contact.compliance.EXPIRY_DATE',
    field: 'expiryDate',
    fieldName: 'expiryDate',
    fieldFormat: '{{ expiryDate | amDateFormat:"L" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
    renderers: {
      edit: {
        type: 'dateEditor',
      },
    },
  },
  {
    title: 'common.STATUS',
    field: 'statusCode',
    fieldName: 'statusCode',
    fieldFormat:
      '{{ ("contact.compliance.statuses." + statusCode.toUpperCase()) | translate }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
    renderers: {
      edit: {
        type: 'prf-customer-regulation-file-statuses-select',
        modelInit: (cell) => ({ code: cell }),
        modelSave: (editModel) => editModel.code,
        exclude: ['expired'],
        excludeBy: 'byCode',
      },
    },
  },
  {
    title: 'contact.compliance.LAST_MODIFIED',
    field: 'lastModified',
    fieldName: 'lastModified',
    fieldFormat: '{{ lastModified | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
