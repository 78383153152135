import * as _ from '@proftit/lodash';

import switchOn from '~/source/common/utilities/switch-on';

/**
 * Convert customer property value to presentation text.
 *
 * @param {string} typecode
 * @param {*} value
 * @return {*} presentation form of the property value.
 */
export function convertCustomerPropValueToPresentationText(typeCode, value) {
  if (_.isNil(value)) {
    return value;
  }

  return switchOn(
    {
      bool: () => (value === true ? 'Yes' : 'No'),
      text: () => value,
      select: () => value,
      multi_select: () => value.map((x) => `* ${x}`).join('\n'),
      date: () => value,
    },
    typeCode,
    () => {
      throw new Error('unimplemented');
    },
  );
}

export default convertCustomerPropValueToPresentationText;
