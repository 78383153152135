import { IScope } from 'angular';
import log from 'loglevel';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import { FilesUploadService } from '~/source/common/services/files-upload';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import ModalService from '~/source/common/components/modal/modal.service';
import template from './upload-clearing-companies-file.html';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
const styles = require('./upload-clearing-companies-file.component.scss');

class UploadClearingCompaniesFileController extends BaseController {
  styles = styles;
  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();

  blockUiInstance;
  fileUploadProgress: number;
  onUpload: (a: { file }) => void;
  file: any;
  uploadFile: any;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly filesUploadService: FilesUploadService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly modalService: ModalService,
    readonly blockUI: any,
    readonly growl,
  ) {
    super();
  }

  upload(file) {
    if (!file) {
      return;
    }

    // Reset current file (if exists)
    this.file = null;

    /*
     * Start blocking the ui
     * Blocking stops will occur within 'finally' on 'save' method
     */
    this.blockUiInstance = this.blockUI.instances.get(this.blockUiId);
    this.blockUiInstance.start();

    this.filesUploadService.uploadPublicFile(file).then(
      (resp) => {
        this.onUpload({ file: resp.data });
        this.blockUiInstance.stop();
      },
      (resp) => {
        log.warn(`Error uploading file: ${resp.status}`);
        this.blockUiInstance.stop();
        this.growl.error('server_errors.SERVER_GENERAL', {
          referenceId: this.growlId,
        });
      },
    );
  }
}

export const uploadClearingCompaniesFileComponent = {
  template,
  controller: UploadClearingCompaniesFileController,
  bindings: {
    uploadFile: '<',
    onUpload: '&',
  },
};
