import { cfdPlatformGroupsTableCols } from './cfd-platform-groups-table-cols';
import * as _ from '@proftit/lodash';
import { Platform } from '@proftit/crm.api.models.entities';

/**
 * Calcualte columns for cfd platform group table.
 * @param platform
 * @return columns
 */
export function calcCfdPlatformGroupTableCols(platform: Platform) {
  return _.flow([
    () => cfdPlatformGroupsTableCols,
    (cols) =>
      cols.filter((col) => {
        if (!_.isArray(col.relevantPlatforms)) {
          return true;
        }

        if (col.relevantPlatforms.includes(platform.code)) {
          return true;
        }

        return false;
      }),
  ])();
}
