import * as _ from '@proftit/lodash';

/**
 * Checks if the parsed document contains at least one "parsererror" tag, if so, the document is not valid.
 * <br/>
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/DOMParser
 * @param html - parsed html document to validate.
 * @returns True if the parsed html document is valid, false otherwise.
 */
function isParsedHtmlValid(html) {
  return html.getElementsByTagName('parsererror').length === 0;
}

function sanitizeHTML(html) {
  return html.replace(/<iframe>(.*?)<\/iframe>/gms, '').replace(/<script>(.*?)<\/script>/gms, '').replace(/<style>(.*?)<\/style>/gms, '');
}

function spaceClosingTags(htmlString) {
  const string = htmlString.replace(/<\//gms, ' </');
  return string;
}

/**
 * Returns a string, that contains all human-readable text inside the htmlElement. This string will not contain extraneous whitespace.
 * The only whitespace that is allowed is a single space.
 * <b>Optional: </b> Snippet length can be capped, using the maxLength parameter.
 * @param htmlElement The HTMLElement to generate the text from.
 * @param maxLength The maximum amount of characters allowed in the snippet string.
 */

function generateSnippet(html, maxLength) {
  const filteredHTML = sanitizeHTML(html);
  const spacedHTML = spaceClosingTags(filteredHTML); // SECURITY NOTE: Under no circumstances should you append the parsed document or any of it children to a live DOM.
  // This would cause any malicious scripts to run.
  // parseFromString does not run JavaScript when ONLY parsing, so it is safe to use in this specific context.
  // for further reading, please see: https://stackoverflow.com/a/37554728/10148053

  const htmlDocument = new DOMParser().parseFromString(spacedHTML, 'text/html');

  if (!isParsedHtmlValid(htmlDocument)) {
    return '';
  }

  const {
    body
  } = htmlDocument;
  const wholeSnippet = body.innerText.replace(/(\r\n|\n|\r)/gm, ' ') // replace all new lines with a single space.
  .replace(/(\s){2,}/gm, ' ') // replace all extraneous whitespace (2 or more) with a single space.
  .trim();

  if (!_.isNil(maxLength)) {
    return wholeSnippet.substring(0, maxLength);
  }

  return wholeSnippet;
}

function getAttachmentsContainer(attachmentsCount) {
  if (attachmentsCount < 1) {
    return '';
  }

  const attachmentText = attachmentsCount > 1 ? 'Attachments' : 'Attachment';
  const attachmentsCountHTMLString = `<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABI0lEQVQ4y+2QMUoDURRFz5sxkFpSiCJYxSYI2k8x/4dEsE4vuAMrQYsBFyDiAqxszALCQCakVWwshHSipYUoaGOSuTbaxMSMvbe8HA7vPmNGvPdVSWulUukmTdPnWVwwrXTOHUvKzGx/OBzeee+jwgLvfRXYDYKglmXZtqQ9SaeFBXme14Drbrf7ClCpVFJgo7DAzEJg7JxrOecu2+32GAi/pqnQD/6Sf8EvAknvwEocx+vAW2GBpDEQlsvlDHgxsytJh61WKwTySX7hhzEIBpJqnU7nA9j57uv1+ibwOPeCKIoGkp7iOD4CDKDZbC7meX4m6WSSt2m7Go3G6mg0ugCWgHtgy8zOoyg6SJIknysASJIk6Pf7W2a2bGa3vV7vYRr3CRDradUHhtU+AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEwLTExVDEwOjI3OjEyKzAwOjAwPL9VHwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMC0xMVQxMDoyNzoxMiswMDowME3i7aMAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"/>
 <span style="margin-left: 4px;">${attachmentsCount} ${attachmentText}</span>`;
  return `<div style="display: flex; position: absolute; padding: 12px; bottom: 0; left: 0;">
  ${attachmentsCountHTMLString}
</div>`;
}

/**
 * Returns an iframe string, that contains the passed html string. The iframe is stripped from its privileges because it is sandboxed.
 * @param htmlString - the html that will be contained in the iframe
 * @returns an iframe with no privileges.
 */
function getSandboxedIframe(htmlString, windowTitle, attachmentsCount) {
  const escapedHtmlString = _.escape(htmlString);

  const attachmentsContainer = getAttachmentsContainer(attachmentsCount);
  return `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <title>${windowTitle}</title>
  </head>
  <body>
   <iframe style="width: 100%; height: 100%;" 
    frameborder="0"
    sandbox=""
    srcdoc="${escapedHtmlString}"
  >
  </iframe>
  ${attachmentsContainer}
  </body>
</html>`;
}

/**
 * <p><h2>Requires a DOM to be mounted.</h2></p>
 * <p>A utility function to download large files, using the browsers built-in download manager.</p>
 * <p>Appends an invisible 'a' tag to the DOM, loads it with the url and fileName, and clicks it to start a download using the browser.</p>
 * @param localFileUrl A URL generated using <code>URL.createObjectURL()</code>, which holds a reference to a blob or a file.
 * @param fileName The name of the file to be downloaded.
 */
function downloadLocalFileUrl(localFileUrl, fileName) {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = localFileUrl;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
}

export { downloadLocalFileUrl, generateSnippet, getSandboxedIframe, isParsedHtmlValid, sanitizeHTML };
