import BaseController from '~/source/common/controllers/base';

import template from './confirmation-line.component.html';

export class ConfirmationLineController extends BaseController {}

export default {
  template,
  controller: <Function>ConfirmationLineController,
  bindings: {
    messageCode: '@',
    okCode: '@',
    cancelCode: '@',
    onConfirmation: '&',
    onCancelation: '&',
  },
};
