import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import template from './funnel-tab.html';
import { IScope } from 'angular';

const CHART_ID = 'funnelGraph';

class Controller extends BaseController {
  // bindings
  data;
  chartOptions;

  chart;
  tableData;
  chartData;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly $translate: angular.translate.ITranslateService,
  ) {
    super();

    // get chart object after render is completed
    this.$scope.$on('slicedChart:chart:rendered', (scope, chart) => {
      if (chart.div && chart.div.id === CHART_ID) {
        this.chart = chart;
      }
    });
  }

  $onChanges(changes) {
    // changes in data
    if (changes.data && changes.data.currentValue) {
      this.extractData(changes.data.currentValue).then(
        (data) => (this.data = data),
      );
    }
  }

  /**
   * extract api data
   *
   * @param {array} data funnel api data
   * @returns {Promise}
   */
  extractData(data) {
    const fields = this.chartOptions.fields;
    const fieldTitles = fields.map((obj) => obj.title);
    const graph = [];

    return this.$translate(fieldTitles).then((translations) => {
      // translate fields
      _.each(fields, (field: any, index) => {
        graph.push({
          title: translations[field.title],
          value: data[field.fieldName],
        });
      });

      // sort the data by value in order to render correct funnel graph
      const sortedData = _.sortBy(graph, 'value').reverse();

      // add settings colors
      sortedData.forEach((field, index) => {
        field.color = this.chartOptions.colors[index];
      });

      return sortedData;
    });
  }

  /**
   * trigger chart rollOverSlice
   * @param {number} index
   */
  hoverSlice(index) {
    this.chart.rollOverSlice(index);
  }

  /**
   * trigger chart rollOutSlice
   * @param {number} index
   */
  blurSlice(index) {
    this.chart.rollOutSlice(index);
  }
}

export default {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    chartOptions: '<',
  },
};
