let NotificationSettingCategoryCode;

(function (NotificationSettingCategoryCode) {
  NotificationSettingCategoryCode["NewContact"] = "newContact";
  NotificationSettingCategoryCode["Assignments"] = "assignments";
  NotificationSettingCategoryCode["FollowUps"] = "followUps";
  NotificationSettingCategoryCode["TradingActivity"] = "tradingActivity";
  NotificationSettingCategoryCode["Transactions"] = "transactions";
  NotificationSettingCategoryCode["Verification"] = "verification";
})(NotificationSettingCategoryCode || (NotificationSettingCategoryCode = {}));

let NotificationSettingTypeCode;

(function (NotificationSettingTypeCode) {
  NotificationSettingTypeCode["Signup"] = "signup";
  NotificationSettingTypeCode["Assignments"] = "assignments";
  NotificationSettingTypeCode["FollowUps"] = "followUps";
  NotificationSettingTypeCode["ContactIsOnline"] = "contactIsOnline";
  NotificationSettingTypeCode["InvestmentAbove"] = "investmentAbove";
  NotificationSettingTypeCode["MarginLevelAbove"] = "marginLevelAbove";
  NotificationSettingTypeCode["MarginLevelReached"] = "marginLevelReached";
  NotificationSettingTypeCode["MarginStopOut"] = "marginStopOut";
  NotificationSettingTypeCode["TotalOpenVolumeAbove"] = "totalOpenVolumeAbove";
  NotificationSettingTypeCode["TotalTradingAccountPnLAbove"] = "totalTradingAccountPnLAbove";
  NotificationSettingTypeCode["TotalTradingAccountPnLBelow"] = "totalTradingAccountPnLBelow";
  NotificationSettingTypeCode["TotalTurnoverAbove"] = "totalTurnoverAbove";
  NotificationSettingTypeCode["TradeSizeAbove"] = "tradeSizeAbove";
  NotificationSettingTypeCode["ApprovedBonuses"] = "approvedBonuses";
  NotificationSettingTypeCode["ApprovedCredit"] = "approvedCredit";
  NotificationSettingTypeCode["ApprovedWithdrawals"] = "approvedWithdrawals";
  NotificationSettingTypeCode["CanceledWithdrawals"] = "canceledWithdrawals";
  NotificationSettingTypeCode["DepositApprovedAmountAbove"] = "depositApprovedAmountAbove";
  NotificationSettingTypeCode["RequestedDeposit"] = "requestedDeposit";
  NotificationSettingTypeCode["FailedDepositAmountAbove"] = "failedDepositAmountAbove";
  NotificationSettingTypeCode["TransactionsApprovedAmountAbove"] = "transactionsApprovedAmountAbove";
  NotificationSettingTypeCode["TurnoverGoalReached"] = "turnoverGoalReached";
  NotificationSettingTypeCode["VolumeGoalReached"] = "volumeGoalReached";
  NotificationSettingTypeCode["WithdrawalRequests"] = "withdrawalRequests";
  NotificationSettingTypeCode["RegulationFiles"] = "regulationFiles";
  NotificationSettingTypeCode["Verification"] = "verification";
  NotificationSettingTypeCode["FileExpiry"] = "fileExpiry";
})(NotificationSettingTypeCode || (NotificationSettingTypeCode = {}));

export { NotificationSettingCategoryCode, NotificationSettingTypeCode };
