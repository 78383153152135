export const internalTransferTablePopupSettings = {
  ngTable: {
    parameters: {
      count: 20,
      page: 1,
    },
    settings: {
      counts: [],
    },
  },
};
