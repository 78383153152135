import RestService from '~/source/common/services/rest';

export class PositionForexService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'positionsForex';
  }

  /**
   *  Get Positions By brand
   *
   * @returns { Promise }
   */
  getPositionsByBrandAndPlatform(
    brandId: number,
    platformId: number,
    positionStatusIds: number[],
  ): PositionForexService {
    return this.filter({
      'tradingAccount.customer.brandId': brandId,
      'tradingAccount.platformId': platformId,
      positionStatusId: positionStatusIds,
    })
      .embed([
        'tradingAccount.customer.brand.smsCredentials',
        'tradingAccount.customer.brand.voipCredentials',
      ])
      .expand([
        'currency',
        'tradeAsset',
        'user',
        'tradingAccount',
        'tradingAccount.customer',
        'tradingAccount.customer.country',
        'tradingAccount.customer.user',
        'tradingAccount.customer.brand',
        'tradingAccount.customer.brand.voipCredentials.voipProvider',
        'tradingAccount.customer.brand.smsCredentials.smsProvider',
        'tradingAccount.platform',
        'tradingAccount.tradingAccountGroup',
        'positionStatus',
      ]);
  }
}
