import _ from 'underscore';
import type { StateService } from '@uirouter/angularjs';
import { Campaign } from '@proftit/crm.api.models.entities';
import template from './campaign-settings-data.html';
import BaseController from '~/source/common/controllers/base';
import MarketingCampaignsService from '../../services/campaigns.service';
import ModelNormalizerService from '../../../common/services/model-normalizer';
import { PricingModelCodesWithValue } from './../pricing-model-codes-with-value';

const FIELDS = [
  'name',
  'brand',
  'desk',
  'user',
  'platform',
  'language',
  'fromDate',
  'tillDate',
  'isEndless',
  'budget',
  'cookieExpire',
  'pricingModel',
  'pricingModelValue',
  'campaignStatus',
  'shouldAssignToRegistrationDesk',
];

class CampaignSettingsDataController extends BaseController {
  isEdit: boolean = false;
  marketingCampaignsServiceInstance: MarketingCampaignsService;

  campaign: Campaign;
  prevAttributes: Partial<Campaign>;

  PricingModelCodesWithValue = PricingModelCodesWithValue;

  /*@ngInject*/
  constructor(
    readonly $state: StateService,
    readonly marketingCampaignsService: () => MarketingCampaignsService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly marketingCampaignSettings,
  ) {
    super();
  }

  $onInit() {
    Object.assign(this, {
      settings: this.marketingCampaignSettings,
      marketingCampaignsServiceInstance: this.marketingCampaignsService(),
      isEdit: false,
    });
  }

  /**
   * Enter edit mode:
   * Save current platform model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    this.prevAttributes = {};
    Object.values(FIELDS).forEach((item) => {
      const value = this.campaign[item];
      this.prevAttributes[item] = _.clone(value);
    });

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state, or remove the model if it didn't exist before
   */
  cancelEdit() {
    // Restore pre-edit state
    Object.assign(this.campaign, this.prevAttributes);
    // Exit edit mode
    this.isEdit = false;
  }

  /**
   * Save the platform data to the server.
   *
   */
  save() {
    // normalize the form data and make ready for submission
    const normalizedCampaignData = this.modelNormalizer.normalize(
      _.pick(this.campaign, FIELDS),
    );

    this.marketingCampaignsServiceInstance
      .setConfig({ blockUiRef: 'campaignSettingsForm' })
      .patchCollection(this.$state.params.campaignId, normalizedCampaignData)
      .then(() => {
        this.isEdit = false;
      });
  }
}

export default {
  template,
  bindings: {
    campaign: '<',
  },
  controller: CampaignSettingsDataController,
  controllerAs: 'vm',
};
