import * as rx from '@proftit/rxjs';

export function observeChannel<T = any>(socketService, channel) {
  return rx.Observable.create((observer) => {
    const onDataFn = socketService.wrapListener((data: T) => {
      observer.next(data);
    });

    socketService.subscribe(channel, onDataFn);

    return () => {
      socketService.unsubscribe(channel, onDataFn);
    };
  });
}
