const styles = require('./search-popup-trading-account-results.component.scss');
import template from './search-popup-trading-account-result.html';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

class Controller {
  styles = styles;
  PermissionNormalized = PermissionNormalized;
}

export const SearchPopupTradingAccountResultComponent = {
  template,
  controller: Controller,
  bindings: {
    info: '<',
  },
};
