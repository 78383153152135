import template from './emails-dashboard.component.html';
const styles = require('./emails-dashboard.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { switchOn } from '@proftit/general-utilities';

const menuEntries = [
  {
    permissionOnly: null,
    svgIcon: 'closed-envelop',
    title: 'emailsDashboard.tabs.SYSTEM_EMAIL',
    uiState: '.systememails',
    menuId: 'system-email',
  },
  {
    permissionOnly: null,
    svgIcon: 'opened-envelop',
    title: 'emailsDashboard.tabs.EMAIL_TEMPLATES',
    uiState: '.customemailtemplates',
    menuId: 'email-templates',
  },
];

export class EmailsDashboardController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  tabs: any = [...menuEntries];

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const EmailsDashboardComponent = {
  template,
  controller: EmailsDashboardController,
};
