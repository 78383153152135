import Component from './base/component';

class VoipProviderController extends Component.controller {
  static $inject = ['voipProvidersService', ...Component.controller.$inject];

  get translateSource() {
    return '';
  }

  get defaultPlaceholder() {
    return 'voip.SELECT_VOIP_PROVIDER';
  }
}

export default Component.config({
  controller: VoipProviderController,
});
