import RestService from '~/source/common/services/rest';

export default class FeeTypesService extends RestService {
  get resource() {
    return 'feeTypes';
  }

  useCache() {
    return true;
  }
}
