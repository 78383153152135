import RestService from '~/source/common/services/rest';

export default class MarketingTrackerParamsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'campaignTrackerParamTypes';
  }

  /**
   * For now  - use mock
   *
   * @returns {boolean}
   */
  useMock() {
    return false;
  }
}
