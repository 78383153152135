import BaseController from '~/source/common/controllers/base';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import BrandsService from '~/source/management/brand/services/brands';
import DesksService from '~/source/management/brand/services/desks';
import { Desk, Brand } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

import template from './desk-data.html';

class ComponentController extends BaseController {
  brand: Brand;
  desk: Desk;
  removeValidate: () => boolean;
  removeElement: () => void;

  isEdit: boolean;

  brandsInstance: BrandsService;
  desksInstance: DesksService;

  /*@ngInject */
  constructor(
    readonly modelNormalizer: ModelNormalizerService,
    readonly brandsService: () => BrandsService,
    readonly desksService: () => DesksService,
  ) {
    super();

    this.brandsInstance = this.brandsService();
    this.desksInstance = this.desksService();
  }

  $onInit() {
    /**
     *  initial state is determined according to the id: if the desk has no id,
     *  it must be a new desk, so enter edit mode
     */
    this.isEdit = !this.desk.id;
  }

  /**
   * Removes the desk.
   * First removes from server, then removes the entire element.
   *
   * Calls the validation function first, to check if we are allowed to remove.
   */
  remove() {
    // Validate if we are allowed to remove the desk, using the passed validation function
    if (!this.removeValidate()) {
      return;
    }

    if (!this.desk.id) {
      // new desk. no need to remove from server
      this.removeElement();
      return;
    }

    const desksResource = this.brandsInstance.getDeskResource(
      this.brand.id,
      this.desk.id,
    );

    // Remove from server, then remove the element.
    desksResource.rest.remove().then(() => {
      this.removeElement();
    });
    // resetting the query manually is required to avoid restangular error if this function will run again
    desksResource.resetQuery();
  }

  /**
   * Save the desk data to the server.
   *
   * Works for both existing and new desks.
   * For existing desks, only the id will be sent.
   * For new desks, the entire desk object will be sent in order to create it.
   *
   */
  save() {
    const normalizedModel = this.modelNormalizer.normalize(this.desk);

    this.brandsInstance
      .getDesksResource(this.brand.id)
      .postWithQuery<IElementRestNg<Desk>>(normalizedModel)
      .then((desk) => {
        // change to display mode
        this.isEdit = false;
        // add created id to the desk object
        this.desk.id = desk.id;
        // this change also affects the 'desks' api cache. clear it too (brands are already cleared on POST)
        this.desksInstance.cacheEmpty();
      });
  }
}

const DeskDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    desk: '=',
    brand: '<',
    removeElement: '&removeElementFn',
    removeValidate: '&removeValidateFn',
  },
};

export default DeskDataComponent;
