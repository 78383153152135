import template from './password-data.html';
import BaseController from '~/source/common/controllers/base';

class Controller extends BaseController {
  static $inject = ['$scope'];

  mandatoryModification: boolean;
  model: any;
  enableResetByMail: boolean;
  passwordValidation: string;

  $onInit() {
    Object.assign(this, {
      changePassword: this.mandatoryModification || false,
    });

    this.$scope.$watch('vm.changePassword', this.onChangePassword());
  }

  /**
   * Called when "enable password change checkbox has been checked/unchecked
   */
  onChangePassword() {
    return (newValue) => {
      if (newValue === false) {
        delete this.model.sendPasswordResetEmail;
        return;
      }
      // Set default: if reset-by-mail is enabled, default is to select the "by mail" option
      this.model.sendPasswordResetEmail = !!this.enableResetByMail;
    };
  }
}

const PasswordDataComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    model: '<',
    enableResetByMail: '<',
    // boolean, if set - password would by modified (manually or automatically)
    mandatoryModification: '<',
    passwordValidation: '<',
  },
};

export default PasswordDataComponent;
