import BaseController from '~/source/common/controllers/base';
import BrandsService from '../../../management/brand/services/brands';
import template from './brand-currencies-fields.component.html';
import { Brand } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class BrandCurrenciesFieldsComponent extends BaseController {
  static $inject = ['brandsService', ...BaseController.$inject];

  brandsService: () => BrandsService;
  currencies: any[];
  currenciesModels: any = {};
  fieldPrefix: string;
  brandId: number;
  form: any[];

  $onInit() {
    this.getCurrencies(this.brandId);
  }

  /**
   * get brand currencies
   * @param {number} brandId brand id
   * @returns {Promise} restangular promise
   */
  getCurrencies(brandId) {
    return this.brandsService()
      .getCurrenciesResource(brandId)
      .expand('currency')
      .setConfig({ blockUiRef: 'brandCurrenciesFieldsBlockUi' })
      .getListWithQuery<IElementRestNg<Brand>>()
      .then((currencies) => {
        this.currencies = currencies.map(({ currency }) => ({
          fieldName: `${this.fieldPrefix}_${currency.code}`,
          code: currency.code,
        }));
        this.currencies.forEach((currency) => {
          let model = this.form.find(({ key }) => key === currency.fieldName);
          if (!model) {
            // if model does not exist in the credentials array, create it and push it
            model = { key: currency.fieldName, value: '' };
            this.form.push(model);
          }
          // necessary object for easy model access in the template
          this.currenciesModels[currency.fieldName] = model;
        });
        return currencies;
      });
  }
}

export default {
  template,
  bindings: {
    brandId: '<',
    fieldPrefix: '<',
    form: '<',
    validations: '<',
  },
  controller: BrandCurrenciesFieldsComponent,
};
