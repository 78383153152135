import Component from './base/component';
import { PublicBrandsService } from '~/source/common/services/brands-public';
import { Brand } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class CurrencySelectController extends Component.controller {
  static $inject = ['publicBrandsService', ...Component.controller.$inject];

  dataServiceInst: PublicBrandsService;
  brand: Brand;

  get defaultPlaceholder() {
    return 'common.PLEASE_SELECT';
  }

  init() {
    this._data = [];

    this.$scope.$watch('vm.brand', () => {
      this.output = []; // reset states when the brand changes

      if (!this.brand) {
        return;
      }

      this.fetchData();
    });
  }

  /**
   * Fetches the brand's currencies and returns a promise
   * which resolves to an array of currency objects.
   *
   * @returns {Promise}
   */
  query() {
    return (
      this.dataServiceInst
        .expand('currency')
        .getCurrenciesResource(this.brand.id)
        .getListWithQuery<IElementRestNg<any>>()
        // all we care about is the 'currency' property (which is an object)
        .then((currencies) => currencies.map(({ currency }) => currency))
    );
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: CurrencySelectController,
  bindings: {
    brand: '<',
  },
});
