import _ from 'underscore';
import template from './wire-request.html';
import TransactionController from '../../transaction-base.controller';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import BrandsService from '~/source/management/brand/services/brands';
import { TradingAccountDeposit } from '@proftit/crm.api.models.entities';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

const DEPOSIT_SAFEGUARD_TIME = 300;

class WireRequestController extends TransactionController {
  static $inject = [
    'depositsSettings',
    'modelNormalizer',
    ...TransactionController.$inject,
  ];

  lifecycles = observeComponentLifecycles(this);
  makeDepositAction = new rx.Subject<void>();

  depositsSettings;
  modelNormalizer: ModelNormalizerService;

  bankAccount;
  deposit: TradingAccountDeposit;

  constructor(...args) {
    super(...args);

    useStreams([this.streamMakeDeposit()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    this.fetchBankAccounts();

    this.blockUiInstance = this.blockUI.instances.get('wireDepositBlock');
  }

  $onDestroy() {}

  /**
   * Normalize the deposit object and post it to server.
   */
  streamMakeDeposit() {
    return rx.pipe(
      () => this.makeDepositAction,
      rx.debounceTime(DEPOSIT_SAFEGUARD_TIME),
      rx.tap(() => {
        // normalize deposit object
        const normalizedDeposit = this.modelNormalizer.normalize(this.deposit);
        super.makeDeposit(normalizedDeposit);
      }),
    )(null);
  }

  /**
   * Fetch brand's bank account details
   */
  fetchBankAccounts() {
    // get brand bank account for account currency
    this.brandsService()
      .expand(['country'])
      /*
       * only bank account related to the same currency as
       * trading account
       */
      .filter('currencyId', this.account.currency.id)
      .getBankAccountResource(this.customer.brand.id)
      .getListWithQuery()
      // if there is multiple bank account that meet the criteria - use the first one
      .then((bankAccount) => {
        this.bankAccount = _.first(bankAccount);
      });
  }
  /**
   * Returns deposit type
   *
   * @returns {String}
   */
  get depositType() {
    return this.depositsSettings.types.wire;
  }
}

export default {
  template,
  controller: WireRequestController,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
    onDone: '&',
  },
};
