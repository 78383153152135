import { FormGroup } from '@proftit/ng1.reactive-forms';
import * as _ from '@proftit/lodash';
import { createScoringFormGroup } from './create-scoring-form-group';
import { ScoreData } from '../models/score-data';

export function createScoreDataFormGroup(scoreData: ScoreData): FormGroup {
  const answersObjCtrls = _.mapValues(
    (scoring, scoringKey) => createScoringFormGroup(scoring),
    scoreData,
  );

  return new FormGroup(answersObjCtrls as any);
}
