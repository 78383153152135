import template from './contacts-import-create.component.html';

const styles = require('./contacts-import-create.component.scss');

import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
export class ContactsImportCreateController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ContactsImportCreateComponent = {
  template,
  controller: ContactsImportCreateController,
};
