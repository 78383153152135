import { IHttpService } from 'angular';
import Url from 'url-parse';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import {
  init,
  setProtocol,
  setHost,
  setResource,
  getAsOne,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  setTokenHeader,
  performRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { BrandsService } from '~/source/management/brand/services/brands';
import { CfdPlatformAsset } from '~/source/common/models/cfd-platform/cfd-platform-asset';
import { removeColonAtEnd } from '~/source/common/utilities/remove-colon-at-end';
import { Brand } from '@proftit/crm.api.models.entities';

const RESOURCE_PATH = 'WebRequestLogRecord';

export class TradingCoreWebRequestLogRecordService {
  /*@ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  initReq(reqParam, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      (req) => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(RESOURCE_PATH, req),
    ])(reqParam);
  }

  initPublicReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  getAsOne(id: number, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  update(id: number, asset: Partial<CfdPlatformAsset>, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => genReqUpdate(req),
        (req) => setPayload(asset, req),
        (req) => cfdMongoToNgHttp(req),
        (req) => performRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }
}
