import template from './communications-update.component.html';

const styles = require('./communications-update.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import UiRouter from '@uirouter/core';
import { BrandsService } from '~/source/management/brand/services/brands';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';

import { Permissions } from '~/source/common/models/permission-structure';
import { Brand } from '@proftit/crm.api.models.entities';
import { VoipProvidersService } from '~/source/common/services/voip-providers';

export class CommunicationsUpdateController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  BlockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  Permissions = Permissions;

  addVoipRegionAction$ = new rx.Subject<void>();
  reloadBrandAction = new rx.Subject<void>();

  brand$ = this.streamBrand();
  voipProviders$ = this.streamVoipProviders();
  usedVoipProviders$ = this.streamUsedVoipProviders();
  usedVoipProvidersCredentials$ = this.streamUsedVoipProvidersCredentials();
  isAddVoipRegionDisabled$ = this.streamIsAddVoipRegionDisabled();

  /*@ngInject */
  constructor(
    readonly $stateParams: UiRouter.StateParams,
    readonly voipProvidersService: VoipProvidersService,
    readonly brandsService: () => BrandsService,
  ) {
    useStreams(
      [
        this.brand$,
        this.voipProviders$,
        this.usedVoipProviders$,
        this.usedVoipProvidersCredentials$,
        this.isAddVoipRegionDisabled$,
        this.addVoipRegionAction$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamVoipProviders() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.voipProvidersService
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((voipProviders) =>
        voipProviders.map((provider) => {
          return {
            ...provider,
            label: provider.name,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamUsedVoipProviders() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((brand) => {
        const { voipCredentials } = brand;
        return voipCredentials.map(({ voipProvider }) => voipProvider);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUsedVoipProvidersCredentials() {
    return rx.pipe(
      () => this.brand$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((brand) => brand.voipCredentials),
      shareReplayRefOne(),
    )(null);
  }

  streamIsAddVoipRegionDisabled() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.voipProviders$, this.usedVoipProviders$),
      rx.filter((array) => array.every((x) => !_.isNil(x))),
      rx.map(
        ([allVoipProviders, usedVoipProviders]) =>
          usedVoipProviders.length >= allVoipProviders.length,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamBrand() {
    return rx.pipe(
      () => rx.obs.merge(this.lifecycles.onInit$, this.reloadBrandAction),
      rx.map(() => this.$stateParams.brandId),
      rx.switchMap((brandId) => this.fetchBrand(brandId)),
      shareReplayRefOne(),
    )(null);
  }

  fetchBrand(brandId) {
    return this.brandsService()
      .setConfig({ blockUiRef: this.BlockUiId, growlRef: this.growlId })
      .embed([
        'voipCredentials',
        'smsCredentials',
        'emailCredentials',
        'voipCredentials.mappedDids',
        'smsCredentials.mappedDids',
      ])
      .expand([
        'voipCredentials.voipProvider',
        'smsCredentials.smsProvider',
        'emailCredentials.emailProvider',
      ])
      .getOneWithQuery(brandId)
      .then((brand) => brand.plain());
  }
}

export const CommunicationsUpdateComponent = {
  template,
  controller: CommunicationsUpdateController,
};
