import * as _ from '@proftit/lodash';
import Big from 'big.js';

export function definePropSwapRatioAsPercentage(
  model,
  objSubjectIn$,
  objSubjectOut$,
) {
  const propName = '_swapRatioAsPercentage';
  const realPropName = 'swapRatio';

  return Object.defineProperty(model, propName, {
    get() {
      const currObj = _.defaultTo({}, objSubjectIn$.getValue());
      const realValue = currObj[realPropName];

      if (_.isNil(realValue)) {
        return null;
      }
      return Number(Big(realValue).times(10000).div(100).toString());
    },

    set(val) {
      const realValue = _.isNil(val)
        ? null
        : Number(Big(val).times(100).div(10000).toString());

      const resp = {
        fieldName: realPropName,
        nextValue: realValue,
      };

      objSubjectOut$.next(resp);
    },
  });
}
