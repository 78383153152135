import SocketService from '~/source/common/services/socket';

class CustomerSiteLocationKeepaliveSocket extends SocketService {
  get channelRoot() {
    return '';
  }

  get whiteList() {
    return [];
  }
}

export default CustomerSiteLocationKeepaliveSocket;
