import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './ewallets-reorder-approval-dialog.component.html';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { generateGrowlId } from '~/source/common/utilities/generate-growl-id';
const styles = require('./ewallets-reorder-approval-dialog.component.scss');

export class EwalletsReorderApprovalDialogController {
  styles = styles;
  resolve;
  dismiss;
  blockUiId = generateBlockuiId();
  growlId = generateGrowlId();
  close: () => void;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const EwalletsReorderApprovalDialogComponent = {
  template,
  controller: EwalletsReorderApprovalDialogController,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
