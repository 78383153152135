import * as _ from '@proftit/lodash';
import { UserNotificationCategoryCode } from '@proftit/crm.api.models.enums';
import Component from '../dropdowns/base/component';

class NotificationsCategorySelectController extends Component.controller {
  query() {
    // There is no API to get the categories, so they are hard-coded here
    const categories = _.flow(
      () => _.toPairs(UserNotificationCategoryCode),
      (pairs) => pairs.map(([_key, val], index) => ({ code: val, id: index })),
    )();

    return Promise.resolve(categories) as any;
  }

  get defaultPlaceholder() {
    return 'search.PLACEHOLDER';
  }

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'notifications.categories';
  }
}

export default Component.config({
  controller: NotificationsCategorySelectController,
});
