import BaseController from '~/source/common/controllers/base';
import wireInfoFormTemplate from './brand-deposit-wire-form.component.html';
import fieldValidations from '../wire-info-validations.json';
const styles = require('./brand-deposit-wire-form.component.scss');
import * as _ from '@proftit/lodash';

class ComponentController extends BaseController {
  styles = styles;
  validations = fieldValidations;
  regularFields: { key: string; value: string | number }[];
  customFields: { key: string; value: string | number }[];

  $onInit() {
    // for the case when we have currency with 0 bank accounts, i.e. we have just added a currency so we dont
    // have bank account yet
    this.customFields = _.defaultTo([], this.customFields);
  }

  addCustomField() {
    this.customFields = [...this.customFields, { key: '', value: '' }];
  }
}

export const BrandDepositWireFormComponent = {
  controller: ComponentController,
  template: wireInfoFormTemplate,
  controllerAs: 'vm',
  bindings: {
    regularFields: '=',
    customFields: '=',
  },
};
