import * as _ from '@proftit/lodash';
import { failedDepositsReportsSettings } from './failed-deposits-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'failed-depositsReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class failedDepositsReportTableController extends CommonReportsTableController {
  resource: string = 'depositsFailed';
  exportResource: string = 'deposits_failed';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>failedDepositsReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'failedDepositsReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'failed_deposits_report_table';
  }
}

export const FailedDepositsReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: failedDepositsReportTableController,
};
