import { IHttpService } from 'angular';
import Url from 'url-parse';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  addHeader,
  getAsOne,
  getAsList,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  create as genReqCreate,
  appendResource,
  setTokenHeader,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

import { BrandsService } from '~/source/management/brand/services/brands';
import { CfdPlatformAsset } from '~/source/common/models/cfd-platform/cfd-platform-asset';
import { removeColonAtEnd } from '@proftit/general-utilities';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { getCfdPlatformPrivateAccessInfoCredentials } from '~/source/common/models/platform-connection/get-cfd-platform-private-access-info-credentials';
import { Brand } from '@proftit/crm.api.models.entities';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';

const RESOURCE_PATH = 'Assets';
export const GROUP_RESOURCE_PATH = 'Group';

export class PlatfromAssetsService {
  /*@ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  initReq(reqParam, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      () => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(RESOURCE_PATH, req),
    ])(reqParam);
  }

  initPublicReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }

  initPrivateReqWithSession(session: PlatformSessionInfo) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, session.session.apiUrl),
      (req) => setTokenHeader(session.session.token, req),
    ])();
  }

  getAsOne(id: number, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  update(id: number, asset: Partial<CfdPlatformAsset>, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(id, req),
        (req) => genReqUpdate(req),
        (req) => setPayload(asset, req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  getAsOneGroupAsset(assetId: number, groupId: number, req) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(assetId, req),
        (req) => appendResource(GROUP_RESOURCE_PATH, req),
        (req) => appendResourceId(groupId, req),
        (req) => getAsOne(req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }

  updateGroupAsset(
    assetId: number,
    asset: Partial<CfdPlatformAsset>,
    groupId: number,
    req,
  ) {
    return rx.obs.from(
      _.flow([
        (req) => appendResourceId(assetId, req),
        (req) => appendResource(GROUP_RESOURCE_PATH, req),
        (req) => appendResourceId(groupId, req),
        (req) => genReqUpdate(req),
        (req) => setPayload(asset, req),
        (req) => cfdMongoToNgHttp(req),
        (req) => ngHttpPerformRequest(this.$http, req),
        (req) => req.then((resp) => resp.data),
      ])(req),
    );
  }
}
