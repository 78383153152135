import * as _ from '@proftit/lodash';
import { AuditLog } from '@proftit/tradingcore.api.models.entities';
import { formatJsonToDlimitedItemsString } from './format-json-to-delimited-items-string';

/*
 * Format return asset audit log item for presenattion in the table and popup.
 *
 * @item
 * @rreturn
 */
export function formatGeneralAssetAuditLogBodyToPresentation(
  item: AuditLog,
): string {
  return formatJsonToDlimitedItemsString(item.newValues, (data) =>
    _.omit(['assetId', 'brandId', 'groupId', 'createdAt', 'updatedAt'], data),
  );
}
