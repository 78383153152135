import * as rx from '@proftit/rxjs';
import { CountriesService } from '~/source/common/services/countries';
import { Country } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export class CountriesStoreService {
  onDestroy$ = new rx.Subject<void>();

  countries$ = this.streamCountries();

  countriesCodeMap$ = this.streamCountriesCodeMap();

  /* @ngInject */
  constructor(readonly countriesService: () => CountriesService) {}

  streamCountries() {
    return rx.pipe(
      () =>
        rx.obs.from(
          this.countriesService().getListWithQuery<IElementRestNg<Country>>(),
        ),
      rx.map((res) => res.plain()),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Returns a stream that emits a Map where the key is the alpha2Code of the country, and the value is the country object.
   */
  streamCountriesCodeMap() {
    return rx.pipe(
      () => this.countries$,
      rx.map((countries) => {
        const countryCodeMap = new Map<string, Country>();
        countries.forEach((country) => {
          countryCodeMap.set(country.alpha2Code, country);
        });
        return countryCodeMap;
      }),
      shareReplayRefOne(),
    )(null);
  }
}
