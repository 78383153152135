import * as _ from '@proftit/lodash';

/**
 * Filter factory. Return toFixed Filter.
 *
 * @return {Function} toFixed filter
 */
export function toFixedFilterFactory() {
  /**
   * Convert number to fixed string representation.
   *
   * @param {number} source - source number.
   * @param {number} length - length of fix.
   * @reutrn {string} string representation of the source.
   */
  function toFixedFilter(source: number, length: number): string {
    if (_.isNil(source)) {
      return '';
    }

    if (source === 0) {
      return '0';
    }

    return source.toFixed(length);
  }

  return toFixedFilter;
}

toFixedFilterFactory.$inject = [];

export default toFixedFilterFactory;
