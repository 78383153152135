import type { IHttpService, IRootScopeService, translate } from 'angular';
import i18next from 'i18next';
import { shareReplayRefOne, tapLog } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  observeComponentLifecycles,
  fromNgScopeEvent,
} from '@proftit/rxjs.adjunct.ng1';
import {
  ITranslateService,
  CustomEvent as AngularTranslateEvents,
} from '@proftit/types-angular-translations';
import { IAppConfig } from '~/source/conf/appConfig';
import { useAngularTranslate } from './useAngularTranslate';
import { useI18NextWithAngularTranslate } from './useI18NextWithAngularTranslate';

export class TranslationsProvider {
  info$: rx.Observable<{
    language: string;
    table: Object;
  }>;

  transaltionsTable$: rx.Observable<Object>;

  i18nInst: typeof i18next;

  /* @ngInject */
  constructor(
    readonly $translate: ITranslateService,
    readonly $rootScope: IRootScopeService,
    readonly appConfig: IAppConfig,
    readonly $http: IHttpService,
  ) {
    const lifecycles = observeComponentLifecycles(this);

    const {
      currentLang$,
      translationsTable$,
      translationLoadingSuccess$,
    } = useAngularTranslate($translate, $rootScope, lifecycles);

    const { i18nInst } = useI18NextWithAngularTranslate(
      currentLang$,
      translationsTable$,
      translationLoadingSuccess$,
      appConfig,
      $rootScope,
      $http,
      lifecycles,
    );

    this.transaltionsTable$ = translationsTable$;
    this.i18nInst = i18nInst;
  }

  $onInit() {
    this.i18nInst.changeLanguage(this.$translate.use());
  }

  $onChanges() {}

  $onDestroy() {}
}

interface LanguageInfo {
  language: string;
  table: Record<string, any>;
}

export const TranslationsProviderComponent = {
  template: `
    <ng-transclude></ng-transclude>
  `,
  controller: TranslationsProvider,
  transclude: true,
};
