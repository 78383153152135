import ng from 'angular';
import { CryptoEwalletComponent } from '~/source/management/crypto-ewallet/components/crypto-ewallet/crypto-ewallet.component';
import { CryptoEwalletEditorComponent } from '~/source/management/crypto-ewallet/components/crypto-ewallet-editor/crypto-ewallet-editor.component';
import { CryptoEwalletListComponent } from '~/source/management/crypto-ewallet/components/crypto-ewallet-list/crypto-ewallet-list.component';
import { EwalletsReorderApprovalDialogComponent } from '~/source/management/crypto-ewallet/components/ewallets-reorder-approval-dialog/ewallets-reorder-approval-dialog.component';

const CryptoEwalletModule = ng.module('crm.cryptoEwallet', ['ui.router']);

function config($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when(
    '/crm/management/integration/ewallet/crypto-ewallet',
    '/crm/management/integration/ewallet/crypto-ewallet/list',
  );

  $stateProvider
    .state('crm.management.integration.ewallet.crypto-ewallet', {
      url: '/crypto-ewallet',
      template: '<ui-view/>',
      redirectTo: 'crm.management.integration.ewallet.crypto-ewallet.list',
    })
    .state('crm.management.integration.ewallet.crypto-ewallet.list', {
      component: 'prfCryptoEwallet',
      url: '/list',
      data: {
        permissions: {
          only: ['management.brands.ewalletscredentials'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.integration.ewallet.crypto-ewallet.create', {
      component: 'prfCryptoEwalletEditor',
      url: '/create',
      data: {
        permissions: {
          only: ['management.brands.ewalletscredentials_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    })
    .state('crm.management.integration.ewallet.crypto-ewallet.update', {
      url: '/update/:currencyId?brandId',
      component: 'prfCryptoEwalletEditor',
      data: {
        permissions: {
          only: ['management.brands.ewalletscredentials_C'],
          redirectTo: 'error.unauthorized',
        },
      },
    });
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];

CryptoEwalletModule.config(config)
  .component('prfCryptoEwallet', CryptoEwalletComponent)
  .component('prfCryptoEwalletEditor', CryptoEwalletEditorComponent)
  .component('prfCryptoEwalletList', CryptoEwalletListComponent)
  .component(
    'prfEwalletsReorderApprovalDialog',
    EwalletsReorderApprovalDialogComponent,
  );

export default CryptoEwalletModule.name;
