import { IScope } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';

import BaseController from '~/source/common/controllers/base';
import TransferMethodTypes from '~/source/contact/common/services/transfer-method-types.service';
import CustomersService from '~/source/contact/common/services/customers';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import {
  WithdrawalValidationError,
  default as WithdrawalValidationService,
} from '../common/validation.service';
import { TradingAccount, Customer } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { useStream } from '~/source/common/utilities/use-stream';
import template from './withdrawal-mobile-money.component.html';
import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
const styles = require('./withdrawal-mobile-money.scss');

export class WithdrawalMobileMoneyController extends BaseController {
  styles = styles;
  // bindings
  $close: () => void;
  contactId: number;
  accountId: number;
  withdrawalRequest: WithdrawalRequest;

  account: TradingAccount;

  withdrawalMobileMoney;
  customerServiceInst: CustomersService;
  invalidFormErrors: WithdrawalValidationError[] = [];
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  unsub$ = new rx.Subject<void>();
  contactId$ = new rx.BehaviorSubject<number>(null);
  accountId$ = new rx.BehaviorSubject<number>(null);
  withdrawalRequest$ = new rx.BehaviorSubject<WithdrawalRequest>(null);
  account$ = new rx.BehaviorSubject<TradingAccount>(null);
  customer$ = new rx.BehaviorSubject<Customer>(null);
  opSubmitClick$ = new rx.Subject<void>();
  withdrawalModel$ = new rx.BehaviorSubject<any>(null);
  selectedBrandMobileMoney;
  opBrandMobileMoneyChange$ = new rx.Subject<ClearingCompanyConnection>();
  opAmountChange$ = new rx.Subject<number>();

  shouldDisplayNegativeFreeMarginWarning$ = this.streamShouldDisplayNegativeFreeMarginWarning();

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly transferMethodTypesService: TransferMethodTypes,
    readonly withdrawalValidationService: WithdrawalValidationService,
    readonly withdrawalSettings,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();
  }

  $onInit() {
    this.customerServiceInst = this.customersService();

    useStream(this.streamModelInit(), this.unsub$);
    useStream(this.streamAccountCalc(), this.unsub$);
    useStream(this.streamCustomerCalc(), this.unsub$);
    useStream(this.streamSubmit(), this.unsub$);
    useStream(this.streamModelBrandSelection(), this.unsub$);
    useStream(this.streamModelAmountInput(), this.unsub$);
    useStream(this.streamAmountInputErrorsCalc(), this.unsub$);
    useStream(this.shouldDisplayNegativeFreeMarginWarning$, this.unsub$);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onContactIdChange(newValue) {
    this.contactId$.next(newValue);
  }

  onAccountIdChange(newValue) {
    this.accountId$.next(newValue);
  }

  onWithdrawalRequestChange(newValue) {
    this.withdrawalRequest$.next(newValue);
  }

  streamModelInit() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.withdrawalRequest$,
          this.getTransferMethodTypes().pipe(
            rx.map((transferMethodTypes) =>
              _.keyBy('code', transferMethodTypes),
            ),
          ),
        ),
      rx.map(([withdrawalRequest, transferMethodTypes]) => {
        return {
          clearingCompany: null,
          amount: null,
          methodType: transferMethodTypes['MOBILE_MONEY_WITHDRAWAL'],
          withdrawalType: withdrawalRequest
            ? withdrawalRequest.withdrawalType
            : null,
        };
      }),
      rx.tap((model) => this.withdrawalModel$.next(model)),
    )(null);
  }

  streamAccountCalc() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.accountId$, this.contactId$),
      rx.switchMap(([accountId, contactId]) => {
        if ([accountId, contactId].some((x) => _.isNil(x))) {
          return rx.obs.from([null]);
        }
        return this.getAccount(accountId, contactId);
      }),
      rx.tap((account) => this.account$.next(account)),
    )(null);
  }

  streamCustomerCalc() {
    return rx.pipe(
      () => this.contactId$,
      rx.switchMap((contactId) => this.getCustomer(contactId)),
      rx.tap((customer) => this.customer$.next(customer)),
    )(null);
  }

  streamModelBrandSelection() {
    return rx.pipe(
      () => this.opBrandMobileMoneyChange$,
      rx.tap(
        (brandMobileMoney) =>
          (this.selectedBrandMobileMoney = brandMobileMoney),
      ),
      rx.withLatestFrom(this.withdrawalModel$),
      rx.map(([brandMobileMoney, model]) => ({
        ...model,
        clearingCompany: brandMobileMoney,
      })),
      rx.tap((model) => this.withdrawalModel$.next(model)),
    )(null);
  }

  streamModelAmountInput() {
    return rx.pipe(
      () => this.opAmountChange$,
      rx.withLatestFrom(this.withdrawalModel$),
      rx.map(([amount, model]) => ({ ...model, amount })),
      rx.tap((model) => this.withdrawalModel$.next(model)),
    )(null);
  }

  streamAmountInputErrorsCalc() {
    return rx.pipe(
      () => this.opAmountChange$,
      rx.withLatestFrom(this.account$),
      rx.map(([amount, account]) => {
        return this.withdrawalValidationService.getErrors(
          amount,
          account,
          this.withdrawalRequest,
        );
      }),
      rx.tap((errors) => (this.invalidFormErrors = errors)),
    )(null);
  }

  streamShouldDisplayNegativeFreeMarginWarning() {
    return rx.pipe(
      () => this.opAmountChange$,
      rx.withLatestFrom(this.account$),
      rx.filter(([amount, account]) => !_.isNil(account)),
      rx.map(([amount, account]) => {
        const nonNullAmount = !_.isNil(amount) ? amount : 0;
        return nonNullAmount > account.freeMargin;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSubmit() {
    return rx.pipe(
      () => this.opSubmitClick$,
      rx.withLatestFrom(this.withdrawalModel$),
      rx.map(([a, model]) => model),
      rx.map((withdrawalMobileMoney) =>
        this.modelNormalizer.normalize(withdrawalMobileMoney),
      ),
      rx.switchMap((normalizedWithdrawals) =>
        this.submitWithdrawal(normalizedWithdrawals),
      ),
      rx.tap(() => this.$close()),
    )(null);
  }

  /**
   * get customer trading account info, including balance, withdrawable amount etc...
   * @returns {Promise} returns a promise which resolved on success
   */
  getAccount(
    accountId: number,
    contactId: number,
  ): rx.Observable<TradingAccount> {
    return rx.obs.from(
      this.customerServiceInst
        .getAccountResource(contactId, accountId)
        .expand(['currency'])
        .getOneWithQuery<IElementRestNg<TradingAccount>>()
        .then((account) => account.plain()),
    );
  }

  getCustomer(customerId: number): rx.Observable<Customer> {
    return rx.obs.from(
      this.customerServiceInst
        .expand(['brand'])
        .getOneWithQuery<IElementRestNg<Customer>>(customerId)
        .then((customer) => customer.plain()),
    );
  }

  /**
   * get transaction method types for submitting with the right methodTypeId
   * @returns {Promise} returns a promise which resolved on success
   */
  getTransferMethodTypes() {
    // get types of credit cards from server
    return rx.obs.from(
      this.transferMethodTypesService
        .getListWithQuery()
        .then((data) => data.plain()),
    );
  }

  /**
   * is the form valid or not
   * @override
   * @returns {boolean} is this form valid or not
   */
  isAmountFieldValid() {
    return (
      this.invalidFormErrors.filter((error) => error.errorType === 'ERROR')
        .length === 0
    );
  }

  /**
   *  called upon withdrawals by credit cards form is valid
   * @returns {Promise|void}
   */
  submitWithdrawal(withdrawals) {
    // post bulk request of withdrawals
    return rx.obs.from(
      this.customerServiceInst
        .setConfig({
          growlRef: 'withdrawalForm',
          blockUiRef: 'withdrawalForm',
          errorsTranslationPath: 'withdrawal.errors',
        })
        .getLinkedWithdrawalsResource(
          this.contactId,
          this.accountId,
          this.withdrawalRequest.id,
        )
        .postWithQuery(withdrawals)
        .then(() =>
          this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, ''),
        ),
    );
  }
}

export const WithdrawalMobileMoneyComponent = {
  template,
  controller: WithdrawalMobileMoneyController,
  bindings: {
    $close: '<',
    contactId: '<',
    accountId: '<',
    withdrawalRequest: '<',
    shouldDisplayFreeMargin: '<',
  },
};

export default WithdrawalMobileMoneyComponent;
