import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import accountingStatsService from '~/source/accounting/accounting-stats.service';
import type { IScope, blockUI } from 'angular';

abstract class AbstractChartsController extends BaseController {
  static $inject = ['statsService', '$scope', 'blockUI', 'appConfig'];

  data: any[];
  labelFilters;
  brandId: number;
  startDate: string;
  endDate: string;
  relativeStartDate: string;
  relativeEndDate: string;
  abstract get blockUiInstanceName(): string;
  abstract get transactionType(): string;

  handleFilterChangesDebounced: (filters: any) => void;

  constructor(
    readonly statsService: () => accountingStatsService,
    readonly $scope: IScope,
    readonly blockUI: blockUI.BlockUIService,
    readonly appConfig: any,
  ) {
    super();

    this.data = [];

    /*
     * pullOut a slice could end up pullIn other slice. because only one slice could be selected
     * at a time. these functionality create two api calls.
     * using debounce prevent this unwanted behavior
     */
    this.handleFilterChangesDebounced = _.debounceEs(
      this.handleFilterChanges.bind(this),
      30,
    );

    this.$scope.$on('charts:label:changed', (scope, filters) => {
      // keep filters and use them when user change brand id or dates
      this.labelFilters = filters;

      this.handleFilterChangesDebounced(this.labelFilters);
    });
  }

  $onChanges(changes) {
    // changes in brand id
    if (
      _.hasEs(changes, 'brandId.currentValue') &&
      changes.brandId.currentValue !== changes.brandId.previousValue
    ) {
      this.$scope.$broadcast('slicedChart:label:removeAll');

      this.brandId = changes.brandId.currentValue;

      this.labelFilters = [];
      this.handleFilterChangesDebounced(this.labelFilters);
    }

    // changes in date picker
    if (changes.startDate || changes.endDate) {
      this.$scope.$broadcast('slicedChart:label:removeAll');

      if (changes.startDate && changes.startDate.currentValue) {
        this.startDate = changes.startDate.currentValue;
      }

      if (changes.endDate && changes.endDate.currentValue) {
        this.endDate = changes.endDate.currentValue;
      }

      if (changes.relativeStartDate?.currentValue) {
        this.relativeStartDate = changes.relativeStartDate.currentValue;
      }

      if (changes.relativeEndDate?.currentValue) {
        this.relativeEndDate = changes.relativeEndDate.currentValue;
      }

      this.handleFilterChangesDebounced(this.labelFilters);
    }
  }

  /**
   * handle filter change
   *
   * basically we send one api call to get all charts data. but this way we don't see all possible filtering
   * options on the screen (charts). because filtered charts have only one results (the filtered result).
   * the solution is to send additional unique api call for every filtered
   * chart. each call should no include its own chart filter.
   *
   * @param {array} filters
   * @private
   */
  private handleFilterChanges(filters) {
    const chartsPromises = [];
    const blockUiInstance = this.blockUI.instances.get(
      this.blockUiInstanceName,
    );

    blockUiInstance.start();

    // fetch all charts data including summary values
    chartsPromises.push(this.fetchChartData(filters));

    // parse chart filters & create promise api calls for charts
    filters.forEach((filter) => {
      // all filters, not including current chart filter
      const restFilters = filters.filter((f) => f !== filter);

      // fetch single chart data
      chartsPromises.push(
        this.fetchSingleChartData(restFilters, filter.chartName),
      );
    });

    Promise.all(chartsPromises).then((values) => {
      // the first value (values[0]) is the main call contains all charts & summary data.
      const isMainChartResultsEmpty = this.isMainChartResultsEmpty(values[0]);

      /*
       * there could be a situation when we have selected filters but got no data in the charts. this can
       * happen when a user select filters and then change a date range or a brand. if this happens we
       * want to show empty charts because there is no data.
       */
      if (isMainChartResultsEmpty) {
        /*
         * set only the main data call
         * don't set unique calls may have data in them because
         * don't include all filters
         */
        this.data = values[0];
      } else {
        // set data of both main call and unique chart calls
        this.data = Object.assign({}, ...values);
      }

      blockUiInstance.stop();
    });
  }

  /**
   * check if the main api call for charts is empty.
   *
   *
   * @param {array} data
   * @returns {boolean} return true if all arrays in the call are empty. return false otherwise
   */
  isMainChartResultsEmpty(data) {
    return _.everyEs(data, (item) => {
      if (Array.isArray(item) && item.length === 0) {
        return true;
      }

      return !(Array.isArray(item) && item.length > 0);
    });
  }

  /**
   * get filters required to make chart api call
   *
   * @param {array} labelFilters
   * @returns {object}
   */
  getFilters(labelFilters) {
    if (!this.startDate || this.brandId === undefined) {
      return;
    }

    const filter = <any>{
      date: {
        gte: this.startDate,
        lte: this.endDate,
      },
      relativeDate: {
        gte: this.relativeStartDate,
        lte: this.relativeEndDate,
      },
    };

    if (this.brandId) {
      // if brand model is set, add its id to the query.
      filter.brandId = this.brandId;
    }

    labelFilters.forEach((labelFilter) => {
      filter[labelFilter.filterParamName] = labelFilter.id;
    });

    return filter;
  }

  /**
   * fetch chart data.
   *
   * @param {array} labelFilters
   * @returns {Promise}
   * @private
   */
  private fetchChartData(labelFilters) {
    const filters = this.getFilters(labelFilters);

    // filters are required for this action
    if (!filters) {
      return;
    }

    return this.statsService()
      .setConfig({ suppressBlockUi: true })
      .filter(filters)
      .getOneWithQuery(this.transactionType)
      .then((data) => data.plain());
  }

  /**
   * fetch single chart data
   *
   * @param {object} labelFilters
   * @param {string} chartName
   * @returns {Promise}
   * @private
   */
  private fetchSingleChartData(labelFilters, chartName) {
    const filters = this.getFilters(labelFilters);

    return this.statsService()
      .setConfig({ suppressBlockUi: true })
      .resourceBuildStart()
      .filter(filters)
      .getElement(this.transactionType)
      .resourceBuildEnd()
      .getOneWithQuery(chartName)
      .then((data) => ({ [chartName]: data.plain() }));
  }
}

export default AbstractChartsController;
