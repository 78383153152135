import moment from 'moment';
import template from './date-picker-bar.html';
import { DatePickerController } from './date-picker.component';

class DatePickerBarController extends DatePickerController {
  settings;
  model;

  /**
   * Set range relative to now by passing start
   * boundary
   *
   * @param {int} value - can be negative
   * @param {string} unit
   */
  onPredefinedRangeSelect(value, unit) {
    this.model.startDate = moment().add(value, unit).startOf('day');
    this.model.endDate = moment().endOf('day');
  }
}

export default {
  template,
  controller: DatePickerBarController,
  controllerAs: 'vm',
  bindings: {
    settings: '<',
    model: '=',
    tooltipContent: '<',
    isTooltipDisabled: '<',
    tooltipClass: '<',
  },
};

export { DatePickerBarController };
