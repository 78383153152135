import Component from '~/source/common/components/dropdowns/base/component';

class PendingPositionsStatusesForexSelect extends Component.controller {
  get defaultPlaceholder() {
    return 'pendingPositionForex.ui.SELECT_PENDING_POSITION_STATUS';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'pendingPositionForex.fields.status';
  }

  get nullableElement() {
    return {
      id: null,
      code: 'null',
      nullable: true,
    };
  }
}

PendingPositionsStatusesForexSelect.$inject = [
  // data service needs to be the first service injected (base select controller).
  'pendingPositionsStatusesForexService',
  ...Component.controller.$inject,
];

const PendingPositionsStatusesForexSelectComponent = Component.config({
  controller: PendingPositionsStatusesForexSelect,
});

export default PendingPositionsStatusesForexSelectComponent;
