import template from './modal.html';

function modalConf($uibModalProvider) {
  /**
   * Default configuration for modals
   */
  $uibModalProvider.options = {
    template,
    controller() {},
    controllerAs: 'vm',
    bindToController: true,
    backdrop: true,
    windowClass: 'prf-dialog',
    backdropClass: 'prf-dialog-backdrop',
  };
}

modalConf.$inject = ['$uibModalProvider'];

export default modalConf;
