import BaseController from '~/source/common/controllers/base';
import FeaturesFlagsService from '~/source/common/services/features-flags.service';

import template from './fields-and-modules-list.html';
import { fieldsAndModulesItems } from '../fields-and-modules-items';

class StatusesListController extends BaseController {
  static $inject = ['featuresFlags'];

  featuresFlags: FeaturesFlagsService;
  statusToEditActive = fieldsAndModulesItems[0];
}

export const FieldsAndModulesListComponent = {
  template,
  controller: StatusesListController,
};

export default FieldsAndModulesListComponent;
