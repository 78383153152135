import Component from '~/source/common/components/dropdowns/base/component';

class CommunicationTypesController extends Component.controller {
  static $inject = [
    'communicationTypesService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'common.SELECT_COMM_TYPE';
  }

  /**
   * Disable translations
   *
   * @returns {string} empty
   */
  get translateSource() {
    return '';
  }
}

export const CommunicatonTypesSelectComponent = Component.config({
  controller: CommunicationTypesController,
});
