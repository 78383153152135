// This file was generated automatically. Tread lightly.

export enum CustomerCreditCardStatusCode {
  Approved = 'approved',
  Canceled = 'canceled',
  Expired = 'expired',
  External = 'external',
  Pending = 'pending',
  Rejected = 'rejected',
}
