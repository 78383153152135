import angular from 'angular';
import moment from 'moment';
import log from 'loglevel';

import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import RestService from '~/source/common/services/rest';
import { UserRolePosition } from '@proftit/crm.api.models.entities';

import UserTokenModel from '~/source/common/models/user-token-model';
import { GeneralSharedWorkerService } from '~/source/common/services/general-shared-worker.service';
import UsersService from '~/source/management/user/services/users';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATE,
} from '~/source/common/constants/general-pubsub-keys';

const CACHE_KEY = 'userData';

export interface UserLoginData {
  username: string;
  password: string;
  smsCode?: string;
  token: string;
}

export interface UserTokenModelRestangularized
  extends UserTokenModel,
    Restangular.IElement {
  id?: any; // to comply with IElement
}

export class TokensService extends RestService {
  Keepalive: any; // todoOld: notworking. angular.idle.IKeepAliveService;

  static $inject = [
    ...RestService.$inject,
    'Keepalive',
    'prfGeneralSharedWorkerService',
    'usersService',
    'prfClientGeneralPubsub',
  ];

  prfGeneralSharedWorkerService: GeneralSharedWorkerService;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  usersService: () => UsersService;
  userFromLogin$ = new rx.Subject<UserTokenModel>();
  userFromLogout$ = new rx.Subject<void>();
  user$ = this.streamUser();
  onDestroy$ = new rx.Subject<void>();

  constructor(...args: any[]) {
    super(...args);
    /**
     * Create an event bus for the service to make it easier for service users to listen
     * to specific events, without having to user angular's scope.
     */
    useStreams([this.user$], this.onDestroy$);
  }

  get resource(): string {
    return 'tokens';
  }

  /**
   * Sets the passed userData to the cache.
   * Sets the cache expiration time from the as the session time.
   * @param {object} userData
   */
  setUserDataCache(userData: UserTokenModel): void {
    const tokenTtl = moment().add(userData.sessionTime, 'seconds');
    this.cachePut(CACHE_KEY, userData, tokenTtl.valueOf());
    this.prfClientGeneralPubsub.publish(USER_UPDATE, userData);
  }

  /**
   * Cached user attributes, while preserving the original cache expiry
   * @param {object} attributes - Attributes which should be updated in the cache
   */
  updateUserCache(attributes: Partial<UserTokenModel>): void {
    // merge the updated user data into the cached user data. only pick certain attributes that are relevant
    const tokenData = Object.assign({}, this.getCachedUser(), attributes);
    const { expires } = this.getCacheInfo(); // preserve the original expiry
    this.cachePut(CACHE_KEY, tokenData, expires);
  }

  /**
   * Get the last logged in user
   * @returns {*}
   */
  getCachedUser(): UserTokenModel {
    return this.getCached(CACHE_KEY);
  }

  /**
   * Return info about the cached user
   * @returns {object} - object with info about the cache, such as "created", "expires", etc
   */
  getCacheInfo(): CacheFactory.CacheItemInfo {
    return this.getCacheFactory().info(CACHE_KEY);
  }

  /**
   * Returns true if the user is logged in
   * @returns {boolean}
   */
  isLoggedIn(): boolean {
    return this.getCachedUser() !== undefined;
  }

  /**
   * Remove cached user data
   */
  removeCached(): void {
    this.cacheRemove(CACHE_KEY);
  }

  streamUser() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.userFromLogin$,
          this.userFromLogout$.pipe(rx.map(() => null)),
          rx.obs.of(this.getCachedUser()),
        ),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * POST the login data to tokens api, to generate token for this user.
   */
  login(
    loginData: Partial<UserLoginData>,
  ): Promise<UserTokenModelRestangularized> {
    return this.postWithQuery<UserTokenModelRestangularized>(loginData)
      .then((userData) => {
        if (userData.role.code === 'extapi') {
          throw new Error('API User');
        }
        return userData;
      })
      .then((userData) => {
        this.setUserDataCache(userData);
        this.prfGeneralSharedWorkerService.setCurrentLoggedUser(
          userData.plain ? userData.plain() : userData,
        );
        this.prfClientGeneralPubsub.publish(USER_LOGIN, null);
        return userData;
      })
      .then((userData) => {
        this.usersService().removeVoipCacheForUser(userData.id as number);
        this.userFromLogin$.next(userData);
        return userData;
      });
  }

  /**
   * Override the default cache clear interceptor
   * When running operations on the tokens api, avoid clearing the cache because
   * we need the token for the operation itself.
   *
   * We will manually manage the cache instead.
   */
  cacheClearRequestInterceptor() {
    // NOOP
  }

  /**
   * send a DELETE request to the tokens api, to delete the user's token.
   *
   * @returns {Promise}
   */
  logout(): Promise<any> {
    /**
     * If user is not logged in, just empty the cache and resolve immediately
     */
    if (!this.isLoggedIn()) {
      this.removeCached();
      return Promise.resolve();
    }

    return (this.removeElement('~') as Promise<any>).finally(() => {
      this.removeCached();
      this.prfGeneralSharedWorkerService.setCurrentLoggedUser(null);
      this.prfClientGeneralPubsub.publish(USER_LOGOUT, null);
      this.userFromLogout$.next();
    });
  }

  /**
   * Sends a keepAlive request (an empty patch to tokens api)
   * returns Promise with new session data (with new token and session time)
   * @returns {Promise}
   */
  keepAlive() {
    log.debug('Starting keepalive....');
    return this.setConfig({
      /**
       * Since keep alive is usually called automatically,
       * and not user interactive - suppress growl and block ui.
       */
      suppressBlockUi: true,
      suppressGrowl: true,
    })
      .patchElement<UserTokenModelRestangularized>('~', {})
      .then((newSession) => {
        log.info('Keepalive. New token: \n%s', newSession.jwt);
        // Update cached user data with the new session info (new token + session time)
        const userInfo = Object.assign(
          {},
          this.getCachedUser(),
          newSession.plain(),
        );
        this.setUserDataCache(userInfo);
        this.prfGeneralSharedWorkerService.updateCurrentLoggedUser(
          userInfo.plain ? userInfo.plain() : userInfo,
        );
      })
      .then(() => {
        const now = Date.now();
        const nextKeepAlive = now + this.Keepalive._options().interval * 1000;
        log.info('Next keepalive will be at ', new Date(nextKeepAlive));
      });
  }
}

export default TokensService;
