import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';

import template from './confirm-dialog.component.html';

interface DialogInput {
  headerText: string;
  confirmText: string;
  okText: string;
  cancelText: string;
  dialogTheme: Object;
}

class Controller extends BaseController {
  /*
   * bindings
   */

  close: ({ $value: any }) => {};
  dismiss: ({ $value: any }) => {};

  /**
   * modal dialog component gets passed data by the 'resolve' binding parameter.
   * We use a setter to automatically extract the incoming value and destructur
   * it to the different values passed.
   */
  set resolve(val: DialogInput) {
    if (_.isNil(val)) {
      return;
    }
    this.headerText = val.headerText;
    this.confirmText = val.confirmText;
    this.okText = val.okText;
    this.cancelText = val.cancelText;
    this.dialogTheme = val.dialogTheme;
  }
  headerText: string;
  confirmText: string;
  okText: string;
  cancelText: string;
  dialogTheme;
}

export const ConfirmDialogComponent = {
  template,
  controller: Controller,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
};

export default ConfirmDialogComponent;
