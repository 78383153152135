import * as _ from '@proftit/lodash';

import platformsData from '../platform.json';
import { TradingAccount } from '@proftit/crm.api.models.entities';

/**
 * Check if account has reset password functionality.
 *
 * We encapsulate the logic here in order to be able to refactor more
 * easily when server will support sending 'hasResetPassword.. data on account.platform.
 * Then we have the option to just change here the code in one place.
 * Instead of more overrall refactoring and remove the call to this
 * function and change it with direct property access.
 *
 * @return {boolean} indication if account has reset password functionality
 */
export function hasResetPasswordFunctionality(account: TradingAccount) {
  const platformCode = _.get(['platform', 'code'], account);
  if (_.isNil(platformCode)) {
    return false;
  }

  const platformData = platformsData.find((d) => d.code === platformCode);
  if (_.isNil(platformData)) {
    return false;
  }

  return _.defaultTo(false, platformData.hasResetPasswordFunctionality);
}

export default hasResetPasswordFunctionality;
