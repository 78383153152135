import '~/vendor/amcharts/amcharts';
import '~/vendor/amcharts/plugins/animate/animate';
import '~/vendor/amcharts/serial';
import '~/vendor/amcharts/pie';
import '~/vendor/amcharts/funnel';

import $ from 'jquery';
import _ from 'underscore';
import s from 'underscore.string';
import moment from 'moment';

import BaseController from '~/source/common/controllers/base';

export class Controller extends BaseController {
  chartId: string;
  data: any;
  options: any;

  /**
   * Merges two or more objects together to a new object.
   * This method delegates to jQuery's deep merge method.
   * @param {...object} objects
   * @return {object} the merged object
   */
  extend(...objects) {
    return $.extend(true, {}, ...objects);
  }

  /**
   * Build the options object that will be passed to amcharts
   * Gets options object which should also be merged to the returned object.
   *
   * This method should be overridden by subclasses which will add their own options
   * @param {object} options
   * @returns {object}
   */
  buildOptions(options) {
    return options;
  }

  /**
   * Creates additions amcharts options according custom settings
   *
   * @param {Object} options
   * @returns {Object} return additional native options derived from custom options
   */
  processCustomOptions(options: { custom: Object }) {
    let processMethodName;
    let additionalOptions = {};

    if (_.isEmpty(options) || _.isEmpty(options.custom)) {
      return additionalOptions;
    }
    /*
     * iterate over custom options and convert them
     * to native to amcharts options by using process methods
     */
    _.each(
      <any>options.custom,
      function (value, name: string) {
        processMethodName = `processCustom${s.capitalize(name)}`;

        if (_.isFunction(this[processMethodName])) {
          // execute process method
          additionalOptions = this.extend(
            additionalOptions,
            this[processMethodName](value),
          );
        }
      },
      this,
    );

    return additionalOptions;
  }

  /**
   * Modify chart options in a way that it will handle category values in unix time
   *
   * @param isSet
   * @returns {{categoryAxis: {categoryFunction: categoryAxis."categoryFunction", parseDates: boolean}}}
   */
  processCustomDateAsUnix(isSet) {
    if (isSet === false) {
      return;
    }
    return {
      categoryAxis: {
        /**
         * Convert category axis values from unix
         * timestamp to js Date object
         *
         * @param {unix timestamp} date
         * @returns {Date}
         */
        categoryFunction(date) {
          return moment.unix(date).toDate();
        },
        parseDates: true,
      },
    };
  }

  onDataChange() {
    this.makeChart();
  }

  onOptionsChange() {
    this.makeChart();
  }

  makeChart() {
    let options: { data?: any; dataProvider?: any } = {};

    if (_.isUndefined(this.data)) {
      return;
    }
    // build graph options
    options = this.buildOptions(this.options || {});
    // extend with custom options
    const customOptions = this.processCustomOptions(this.options || {});
    options = this.extend(options, customOptions);

    // Data might be deferred: wait for resolution.
    Promise.resolve(this.data).then((data) => {
      options.dataProvider = data;
      delete options.data;
      (window as any).AmCharts.makeChart(this.chartId, options);
    });
  }
}

export const config = (newConf) =>
  Object.assign(
    {},
    {
      template: `<div class="amchart"
               id="{{$ctrl.chartId}}"
               ng-style="{height: $ctrl.height, width: $ctrl.width}">
             </div>`,
    },
    newConf,
    {
      // merge bindings seperately, as they are a nested object
      bindings: Object.assign(
        {
          options: '<',
          data: '<',
          height: '<',
          width: '<',
          chartId: '@',
        },
        newConf.bindings,
      ),
    },
  );
