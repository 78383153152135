/**
 * This directive switches between minimized/maximized mode of the modal,
 * simply by moving the modal window between the two possible containers (regular/minimized container)
 */
export function modalToggleDirective() {
  return {
    scope: {
      minimized: '=',
    },
    link(scope, elem) {
      elem.click(() => {
        // find modal window in the parents of this button
        const modalWindow = elem.parents('[uib-modal-window]');
        const modalContainer = modalWindow.parent();
        const otherContainers = modalContainer.siblings();

        if (otherContainers.length !== 1) {
          throw new Error(
            'Modal error: Modal container should have exactly one sibling',
          );
        }
        // move modal window to the other sibling container (there is only one)
        otherContainers.append(modalWindow);

        // switch status
        scope.minimized = !scope.minimized;

        scope.$apply();
      });
    },
  };
}

export default modalToggleDirective;
