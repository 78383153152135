import ng, { IHttpService } from 'angular';
import { TableController } from '~/source/common/components/table/table.controller';
import {
  observeShareCompChange,
  observeComponentLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { Entity } from '@proftit/crm.api.models.general';
import { Mt4Group } from '@proftit/tradingcore.api.models.entities';
import { appendResource, appendResourceId } from '@proftit/request-client';
import { ModalService } from '~/source/common/components/modal/modal.service';
import { brandPlatformMt4GroupsTableSettings } from './brand-platform-mt4-groups-table-settings';
import template from './brand-platform-mt4-groups-table.component.html';
import { initPrivateReqMt4 } from '~/source/common/api-cfd-platform/trading-core-request-utilities/init-private-req-mt4';
import {
  ApiMt4PlatformService,
  MT_GROUPS_RESOURCE_PATH,
} from '~/source/common/api-cfd-platform/api-mt4-platform.service';
import { SocketService } from '~/source/common/services/socket';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import {
  pipeAsyncWorkInUi,
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { brandPlatformMt4GroupsTableColumns } from './brand-platform-mt4-groups-table-columns';
import { CurrentTrcStreamerOfBrandPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-steamer-of-brand-platform-service.directive';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
const styles = require('./brand-platform-mt4-groups-table.component.scss');

const GLOBAL_GROWL_ID = 'restService';

export class BrandPlatformMt4GroupsTableController extends TableController {
  static $inject = [
    'blockUI',
    '$http',
    'growl',
    'growlMessages',
    'modalService',
    'prfApiMt4PlatformService',
    ...TableController.$inject,
  ];

  /* require */

  prfCurrentTrcStreamerOfBrandPlatform: CurrentTrcStreamerOfBrandPlatformServiceDirectiveController;

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* inject */

  blockUI: ng.blockUI.BlockUIService;

  $http: IHttpService;

  growl: ng.growl.IGrowlService;

  growlMessages: ng.growl.IGrowlMessagesService;

  modalService: ModalService;

  prfApiMt4PlatformService: ApiMt4PlatformService;

  /* state */

  styles = styles;

  cols = [...brandPlatformMt4GroupsTableColumns];

  lifecycles = observeComponentLifecycles(this);

  settings = _.cloneDeep(brandPlatformMt4GroupsTableSettings);

  brandPlatformIn$ = observeShareCompChange<BrandPlatform>(
    this.lifecycles.onChanges$,
    'brandPlatform',
  );

  opOpenEditPopup$ = new rx.Subject<Mt4Group>();

  groups: Mt4Group[];

  groups$ = new rx.BehaviorSubject<Mt4Group[]>([]);

  /* methods */

  constructor(...args) {
    super(...args);

    useStreams([this.brandPlatformIn$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamInitDataServiceInstanceAndTable(),
        this.streamOpenEditPopup(),
        this.streamItemsStreamerUpdates(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  streamInitDataServiceInstanceAndTable() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.map((sessionInfo) => {
        const req = initPrivateReqMt4(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
          MT_GROUPS_RESOURCE_PATH,
        );

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  get tableKey() {
    return 'tradingcorePlatformBrandMtGroups';
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return null;
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId: number): string {
    return null;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.groups';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.groups;
  }

  isStreamingOn() {
    return false;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  parseLoadedData(data) {
    this.groups = data;
    this.groups$.next(data);
    return data;
  }

  streamOpenEditPopup() {
    return rx.pipe(
      () => this.opOpenEditPopup$,
      rx.withLatestFrom(this.brandPlatformIn$),
      rx.tap(([group, brandPlatform]) =>
        this.openEditPopup(brandPlatform, group as any),
      ),
    )(null);
  }

  streamItemsStreamerUpdates() {
    return rx.pipe(
      () => this.groups$,
      rx.switchMap((groups) => {
        const groupsUpdates = groups.map((group: any) => {
          return this.prfCurrentTrcStreamerOfBrandPlatform.observeMtGroup(
            group.id,
          );
        });

        return rx.obs.merge(rx.obs.NEVER, ...groupsUpdates);
      }),
      rx.withLatestFrom(this.groups$),
      rx.tap(([update, groups]) => {
        const orig = groups.find((a: any) => a.id === (update as any).id);
        if (_.isNil(orig)) {
          return;
        }

        Object.assign(
          orig,
          _.pick(['marginCallLevel', 'stopOutLevel'], update),
        );
      }),
    )(null);
  }

  openEditPopup(brandPlatform: BrandPlatform, mtGroup: Mt4Group & Entity) {
    this.modalService.open({
      component: 'prfBrandPlatformMt4GroupDialog',
      resolve: {
        brandPlatform: () => brandPlatform,
        mtGroupId: () => mtGroup.id,
        operation: () => 'update',
      },
    });
  }
}

export const BrandPlatformMt4GroupsTableComponent = {
  template,
  controller: BrandPlatformMt4GroupsTableController,
  controllerAs: 'vm',
  bindings: {
    brandPlatform: '<',
  },
  require: {
    prfCurrentTrcStreamerOfBrandPlatform: '^',
    prfCurrentPlatformSession: '^',
  },
};
