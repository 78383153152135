import { SideMenuItem } from '~/source/common/components/side-menu-item/side-menu-item';
import { Permissions } from '~/source/common/models/permission-structure';

export const sideMenuData: SideMenuItem[] = [
  {
    id: 'brands',
    stateId: 'management.brands',
    labelCode: 'BRANDS',
    iconClass: 'svg-i-s-nav-tag',
    uiState: '.brands',
    isMainItem: true,
  },
  {
    id: 'desks',
    stateId: 'desks',
    labelCode: 'DESKS',
    iconClass: 'svg-i-s-nav-headphones',
    uiState: '.desks',
    isMainItem: true,
    permissionsOnly: ['management.desks'],
  },
  {
    id: 'permissions',
    stateId: 'permissions',
    labelCode: 'PERMISSIONS',
    iconClass: 'svg-i-s-nav-crown',
    isCollapsed: false,
    isMainItem: true,
    menuItems: [
      {
        id: 'groups',
        stateId: 'permissions.groups',
        labelCode: 'GROUPS',
        uiState: '.permissions.groups',
      },
      {
        id: 'users',
        stateId: 'users',
        labelCode: 'USERS',
        uiState: '.permissions.users',
      },
      {
        id: 'apiUsers',
        stateId: 'api-users',
        labelCode: 'API Users',
        uiState: '.permissions.api-users',
      },
      {
        id: 'ipWhitelist',
        stateId: 'ip-list',
        labelCode: 'IP_WHITELIST',
        uiState: '.permissions.ip-list',
        permissionsOnly: ['management.iplist'],
      },
    ],
  },
  {
    id: 'tools',
    stateId: 'tools',
    labelCode: 'TOOLS',
    iconClass: 'svg-i-s-nav-bag',
    isCollapsed: false,
    isMainItem: true,
    menuItems: [
      {
        id: 'automation',
        stateId: 'automation',
        labelCode: 'AUTOMATION',
        uiState: '.tools.automation',
        permissionsOnly: ['automation'],

        hide: false,
      },
      {
        id: 'fieldsAndModules',
        stateId: 'fields-and-modules',
        labelCode: 'FIELDS_AND_MODULES',
        uiState: '.tools.fields-and-modules.list',
        permissionsOnly: ['management.fields_and_modules'],
      },
      {
        id: 'signup',
        stateId: 'signup-form',
        labelCode: 'SIGN_UP_FORM',
        uiState: '.tools.signup-form',
      },
      {
        id: 'questionnaire',
        stateId: 'questionnaire',
        labelCode: 'QUESTIONNAIRE',
        uiState: '.tools.questionnaire',
      },
      {
        id: 'emails',
        stateId: 'emails',
        labelCode: 'EMAILS',
        uiState: '.tools.emails',

        permissionsOnly: [Permissions.ManagementEmailTemplate.Read],
      },
      {
        id: 'contactsImport',
        stateId: 'contacts-import',
        labelCode: 'common.CONTACTS_IMPORT',
        uiState: '.tools.contacts-import',

        permissionsOnly: [Permissions.ContactsContactImport.Read],
      },
    ],
  },
  {
    id: 'notifications',
    stateId: 'notifications',
    labelCode: 'NOTIFICATIONS',
    iconClass: 'svg-i-s-nav-bell',
    isMainItem: true,
    permissionsOnly: ['management.brands.notification_settings'],
    menuItems: [
      {
        id: 'notificationsSettings',
        stateId: 'settings',
        labelCode: 'NOTIFICATION_SETTINGS',
        uiState: '.notifications.settings',
      },
    ],
  },
  {
    id: 'integration',
    stateId: 'integration',
    labelCode: 'common.INTEGRATION',
    iconClass: 'svg-i-s-nav-integration',
    isCollapsed: false,
    isMainItem: true,
    menuItems: [
      {
        id: 'communications',
        stateId: 'communications',
        labelCode: 'management.integration.COMMUNICATIONS',
        uiState: '.integration.communications',

        permissionsOnly: [
          Permissions.ManagementBrandCommunicationSettings.Read,
        ],
      },

      {
        id: 'ewalletCryptoProviders',
        stateId: 'ewallet-crypto-providers',
        labelCode: 'brandEwallet.CRYPTO_EWALLET_PROVIDERS',
        uiState: '.integration.ewallet-crypto-providers',
        permissionsOnly: ['management.brands.ewalletscredentials'],
      },
      {
        id: 'system_configurations',
        stateId: 'system_configurations',
        labelCode: 'management.integration.SYSTEM_CONFIGURATIONS',
        uiState: '.integration.system_configurations',

        permissionsOnly: [Permissions.ManagementSystemConfiguration.Read],
      },
      {
        id: 'platforms',
        stateId: 'platforms',
        labelCode: 'common.PLATFORMS',
        isCollapsed: false,

        menuItems: [
          {
            id: 'riskManager',
            stateId: 'risk-manager',
            labelCode: 'riskManager.RISK_MANAGER',
            uiState: '.integration.platforms.risk-manager',
            permissionsOnly: ['management.riskmanager'],
            hide: true,
          },
          {
            id: 'brandConnectivity',
            stateId: 'connectivity',
            labelCode: 'platform.BRAND_CONNECTIVITY',
            uiState: '.integration.platforms.connectivity',
          },
        ],
      },
      {
        id: 'clearing',
        stateId: 'clearing',
        labelCode: 'clearing.CLEARING',
        isCollapsed: false,

        menuItems: [
          {
            id: 'cashier',
            stateId: 'cashier',
            labelCode: 'clearing.CASHIER',
            uiState: '.integration.clearing.cashier',
            permissionsOnly: ['management.brands.cashier'],
          },
          {
            id: 'clearingCompaniesList',
            stateId: 'companies',
            labelCode: 'clearing.COMPANIES',
            uiState: '.integration.clearing.companies',
            permissionsOnly: ['management.brands.clearingcredentials'],
          },
          {
            id: '3dSettings',
            stateId: '3d-settings',
            labelCode: 'clearing.3DS_SETTINGS',
            uiState: '.integration.clearing.3d-settings',
            permissionsOnly: ['management.brands.settings_3d'],
          },
        ],
      },
      {
        id: 'ewallet',
        stateId: 'ewallet',
        labelCode: 'brandEwallet.EWALLET',
        isCollapsed: false,
        menuItems: [
          {
            id: 'cryptoEwallet',
            stateId: 'crypto-ewallet',
            labelCode: 'brandEwallet.CRYPTO_EWALLET',
            uiState: '.integration.ewallet.crypto-ewallet',
            permissionsOnly: ['management.brands.ewalletscredentials'],
          },
        ],
      },
    ],
  },
  {
    id: 'about',
    stateId: 'about',
    labelCode: 'management.ABOUT',
    iconClass: 'svg-i-s-nav-info',
    uiState: '.about',
    isMainItem: true,
  },
];
