import * as rx from '@proftit/rxjs';

import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';

export function observeShareCompChange<T>(onChanges$, propName: string) {
  return rx.pipe(
    () => onChanges$,
    getCompChange<T>(propName),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);
}
