import { Permissions } from '~/source/common/models/permission-structure';

export const bonusesReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          requesteAt: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'reports.titles.REQUESTED_DATE',
        field: 'requestedAt',
        fieldName: 'requestedAt',
        fieldFormat: '{{ requestedAt }}',
        reportFields: ['requestedAt'],
        filterable: true,
        sortable: 'requestedAt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.AMOUNT',
        field: 'requestedAmount',
        fieldName: 'requestedAmount',
        fieldFormat: '{{ requestedAmount | number : 2 }}',
        reportFields: ['requestedAmount'],
        filterable: true,
        sortable: 'requestedAmount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.AMOUNT_BASIC',
        field: 'requestedAmountBasic',
        fieldName: 'requestedAmountBasic',
        fieldFormat:
          '{{ requestedAmountBasic | baseCurrencyFormat : 2 : true }}',
        reportFields: ['requestedAmountBasic'],
        filterable: true,
        sortable: 'requestedAmountBasic',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.VOLUME_GOAL',
        field: 'bonusVolumeGoal',
        fieldName: 'bonusVolumeGoal',
        fieldFormat: '{{ bonusVolumeGoal | number : 2 }}',
        reportFields: ['bonusVolumeGoal'],
        filterable: true,
        sortable: 'bonusVolumeGoal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.VOLUME',
        field: 'volume',
        fieldName: 'volume',
        fieldFormat: '{{ volume | number : 2 }}',
        reportFields: ['volume'],
        filterable: true,
        sortable: 'volume',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'accounting.TURNOVER',
        field: 'bonusTurnoverGoal',
        fieldName: 'bonusTurnoverGoal',
        fieldFormat: '{{ bonusTurnoverGoal | number : 2 }}',
        reportFields: ['turnover'],
        filterable: true,
        sortable: 'bonusTurnoverGoal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.WITHDRAWABLE',
        field: 'withdrawable',
        fieldName: 'withdrawable',
        fieldFormat: '{{ withdrawable }}',
        reportFields: ['withdrawable'],
        filterable: true,
        sortable: 'withdrawable',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.NOTE',
        field: 'note',
        fieldName: 'note',
        fieldFormat: '{{ note }}',
        reportFields: ['note'],
        filterable: false,
        sortable: 'note',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMPLOYEE',
        field: 'accountManager',
        fieldName: 'accountManager',
        fieldFormat: '{{ accountManager }}',
        reportFields: ['accountManager'],
        filterable: true,
        sortable: 'accountManager',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_NAME',
        field: 'tradingAccount',
        fieldName: 'tradingAccount',
        fieldFormat: '{{ tradingAccount }}',
        reportFields: ['tradingAccount'],
        filterable: false,
        sortable: 'tradingAccount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TRADING_ACCOUNT_ID',
        field: 'tradingAccountId',
        fieldName: 'tradingAccountId',
        fieldFormat: '{{ tradingAccountId }}',
        reportFields: ['tradingAccountId'],
        filterable: false,
        sortable: 'tradingAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'externalAccountId',
        fieldName: 'externalAccountId',
        fieldFormat: '{{ externalAccountId }}',
        reportFields: ['externalAccountId'],
        filterable: false,
        sortable: 'externalAccountId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CURRENCY',
        field: 'currency',
        fieldName: 'currency',
        fieldFormat: '{{ currency }}',
        reportFields: ['currency'],
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.BALANCE',
        field: 'balance',
        fieldName: 'balance',
        fieldFormat: '{{ balance | number : 2 }}',
        reportFields: ['balance'],
        filterable: true,
        sortable: 'balance',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reporrts.titles.PROCESSING_EMPLOYEE',
        field: 'reportsByEmployee',
        fieldName: 'employee',
        fieldFormat: '{{ employee }}',
        reportFields: ['employee'],
        filterable: true,
        sortable: 'employee',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.DEPOSIT_TOTAL',
        field: 'depositTotal',
        fieldName: 'depositTotal',
        fieldFormat: '{{ depositTotal | number : 2 }}',
        reportFields: ['depositTotal'],
        filterable: true,
        sortable: 'depositTotal',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.STATUS',
        field: 'transactionStatusCode',
        fieldName: 'status',
        fieldFormat: '{{ status }}',
        reportFields: ['status'],
        filterable: true,
        sortable: 'status',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.TOTAL_DEPOSIT_COUNT',
        field: 'totalDepositsCnt',
        fieldName: 'totalDepositsCnt',
        fieldFormat: '{{ totalDepositsCnt }}',
        reportFields: ['totalDepositsCnt'],
        filterable: true,
        sortable: 'totalDepositsCnt',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_CASH_PNL',
        field: 'accountCashPnl',
        fieldName: 'accountCashPnl',
        fieldFormat: '{{ accountCashPnl | number : 2 }}',
        reportFields: ['accountCashPnl'],
        filterable: true,
        sortable: 'accountCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.ACCOUNT_WITHDRAWABLE_BALANCE',
        field: 'accountWithdrawableBalance',
        fieldName: 'accountWithdrawableBalance',
        fieldFormat: '{{ accountWithdrawableBalance | number : 2 }}',
        reportFields: ['accountWithdrawableBalance'],
        filterable: true,
        sortable: 'accountWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.CUSTOMER_CASH_PNL',
        field: 'customerCashPnl',
        fieldName: 'customerCashPnl',
        fieldFormat: '{{ customerCashPnl | number : 2 }}',
        reportFields: ['customerCashPnl'],
        filterable: true,
        sortable: 'customerCashPnl',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.WITHDRAWABLE_BALANCE',
        field: 'customerWithdrawableBalance',
        fieldName: 'customerWithdrawableBalance',
        fieldFormat: '{{ customerWithdrawableBalance | number : 2 }}',
        reportFields: ['customerWithdrawableBalance'],
        filterable: true,
        sortable: 'customerWithdrawableBalance',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
