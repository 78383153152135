import template from './notice-item.component.html';

const styles = require('./notice-item.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { NoticeType } from '../notice-popup-list/notice-type';
import { useStreams } from '@proftit/rxjs.adjunct';

export class NoticeItemController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  NoticeType = NoticeType;

  notice$ = observeShareCompChange(this.lifecycles.onChanges$, 'notice');

  /*@ngInject */
  constructor() {
    useStreams([this.notice$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const NoticeItemComponent = {
  template,
  controller: NoticeItemController,
  bindings: {
    notice: '<',
  },
};
