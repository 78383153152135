import ipaddr from 'ipaddr.js';

/**
 * Check if an ip is valid four part decimal ip.
 *
 * @param {string} ip - ip
 * @return {boolean} Whether the ip is valid
 */
export function isValidFourPartDecimalIp(ip: string) {
  return ipaddr.IPv4.isValidFourPartDecimal(ip);
}

export default isValidFourPartDecimalIp;
