import ng from 'angular';
import { TasksModule } from '~/source/tasks/tasks.module';
import service from './notifications.service';
import notificationsCenterComponent from './notifications-center.component';
import notificationsCenterPopupComponent from './notifications-center-popup/notifications-center-popup.component';
import notificationsCenterLogPopupComponent from './notifications-center-log-popup.controller';
import NotificationsSocketService from './notifications-socket.service';
import NotificationCategorySelect from './notifications-category-select';
import { SingleNotificationComponent } from '~/source/common/components/notifications/single-notification/single-notification.component';

export default ng
  .module('crm.notifications', [TasksModule.name])
  .component('prfNotificationsCenter', notificationsCenterComponent)
  .component('prfNotificationsCategorySelect', NotificationCategorySelect)
  .component(
    'prfNotificationsCenterPopup',
    <any>notificationsCenterPopupComponent,
  )
  .component('prfSingleNotification', SingleNotificationComponent)
  .controller(
    'notificationsCenterLogPopupController',
    notificationsCenterLogPopupComponent,
  )
  .service('notificationsSocketService', NotificationsSocketService)
  .service('notificationsService', service).name;
