import * as _ from '@proftit/lodash';

/**
 */
export function decimalNumber(value) {
  if (_.isNil(value)) {
    return true;
  }

  const valueStr = value.toString();
  const pattern = new RegExp('^\\d+(.\\d+)?$');
  return valueStr.match(pattern) !== null;
}

export default decimalNumber;
