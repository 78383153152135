import { complianceStatusSvg } from '~/source/contact/contact-page/sidebar-tabs/compliance/compliance/compliance-status-svg';
import { CustomerRegulationFileStatuses } from '@proftit/crm.api.models.enums';

export function getIconForFileStatus(status, defaultIcon) {
  const {
    Approved,
    Pending,
    Declined,
    Expired,
  } = CustomerRegulationFileStatuses;
  switch (status) {
    case Approved:
      return complianceStatusSvg.approved;
    case Pending:
      return complianceStatusSvg.pending;
    case Declined:
      return complianceStatusSvg.declined;
    case Expired:
      return complianceStatusSvg.expired;
    default:
      return defaultIcon;
  }
}
