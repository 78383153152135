import log from 'loglevel';
import { SocketService } from '~/source/common/services/socket';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { getCfdPlatformPublicTokenCredential } from '~/source/common/models/platform-connection/get-cfd-platform-public-token-credential';
import { hashCode } from '../utilities/hash-code';

/**
 * Build channel name for cfd account openpnl subscription.
 *
 * @param accountId - target account id.
 * @return channel name.
 */
export function buildCfdAccountPnlChannel(accountSyncId: number) {
  return `{customer.${accountSyncId}}.openpnl`;
}

export class CfdPlatformAccountOpenPnlSocketService extends SocketService {
  static $inject = [...SocketService.$inject];

  token: string;
  platformStreamerUrl: string;

  get socketRegistryKey() {
    const tokenHash = hashCode(this.token);
    const urlHash = hashCode(this.platformStreamerUrl);
    return `cfdPlatformSocket&token=${tokenHash}&url=${urlHash}`;
  }

  getStreamerUrl() {
    return this.platformStreamerUrl;
  }

  buildSocketQuery() {
    return `token=${this.token}`;
  }

  setToken(token: string) {
    this.token = token;
  }

  setStreamerUrl(url: string) {
    this.platformStreamerUrl = `${url}`;
  }

  getSocketOptions() {
    return {
      transports: ['websocket'],
    };
  }

  get channelRoot() {
    return null;
  }
}
