import Promise from 'bluebird';
import ManageController from '../manage.controller';
import template from '../form.html';

class QuestionnaireCreateController extends ManageController {
  static $inject = ['$state', ...ManageController.$inject];

  $state: ng.ui.IStateService;

  /**
   * called by constructor on init
   * @returns {Promise} returns a promise which resolves as an initial model object for questionnaire
   */
  initQuestionnaireModel(langCode) {
    return Promise.resolve({ isActive: true, components: [] });
  }

  /**
   * submit form
   * @returns {Promise}
   */
  submitForm() {
    // normalize form components
    this.model.components = this.normalizeComponents(this.model.components);

    const langToUse = this.model.language;
    const normalized = this.modelNormalizer.normalize(this.model, false);
    normalized.languages = [
      {
        languageId: langToUse.id,
      },
    ];

    return this.questionnaireService()
      .setConfig({
        growlRef: 'questionnaireForm',
        blockUiRef: 'questionnaireForm',
      })
      .customPostWithHeaders(normalized, '', null, {
        'Accept-Language': langToUse.code2,
      })
      .then((res) => {
        // the user will be redirected to the edit page of the same questionnaire that was just created, so he can edit it, and so that add new translation language button will be available
        this.$state.go(`^.edit`, {
          id: res.id,
          langId: this.model.language.id,
          action: this.QuestionnaireUserAction.Edit,
        });
      });
  }
}

export default {
  template,
  controller: QuestionnaireCreateController as any,
  controllerAs: 'vm',
};
