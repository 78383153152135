import angular from 'angular';

import FilterLabelComponent from './filter-label/filter-label.component';
import TableFilterComponent from './filters-bar/filters-bar.component';
import { FilterPopoverModule } from './filter-popover/module';
import filterSettings from './filters.json';
import attachmentsFilterSettings from './reports-filters/attachments-filters.json';
import bonusesFilterSettings from './reports-filters/bonuses-filters.json';
import bundlesFilterSettings from './reports-filters/bundles-filters.json';
import communicationsFilterSettings from './reports-filters/communications-filters.json';
import creditsFilterSettings from './reports-filters/credits-init-filters.json';
import depositsFilterSettings from './reports-filters/deposits-filters.json';
import depositsWithdrawalsFilterSettings from './reports-filters/deposits-withdrawals-filters.json';
import failedDepositsFilterSettings from './reports-filters/failed-deposits-filters.json';
import feesFilterSettings from './reports-filters/fees-filters.json';
import ftdsFilterSettings from './reports-filters/ftds-filters.json';
import openWithdrawalsFilterSettings from './reports-filters/open-withdrawals-filters.json';
import positionsFilterSettings from './reports-filters/positions-filters.json';
import tradingAccountsFilterSettings from './reports-filters/trading-accounts-filters.json';
import withdrawalsFilterSettings from './reports-filters/withdrawals-filters.json';
import promoCodesFilterSettings from './reports-filters/promo-codes-filters.json';
import FilterGroupModule from './filter-group/module';
import FilterService from './services/filter.service';
import FilterCacheService from './services/filter-cache';
import filterKeyDuplicationResolverService from './services/filter-key-duplication-resolver';

export const TableFiltersModule = angular.module('crm.common.tableFilters', [
  FilterGroupModule,
  FilterPopoverModule.name,
]);

TableFiltersModule.component('prfFilterLabel', FilterLabelComponent)
  .component('prfTableFiltersBar', TableFilterComponent)
  .constant('filtersSettings', {
    ...filterSettings,
    ...tradingAccountsFilterSettings,
    ...attachmentsFilterSettings,
    ...bonusesFilterSettings,
    ...bundlesFilterSettings,
    ...communicationsFilterSettings,
    ...creditsFilterSettings,
    ...depositsFilterSettings,
    ...depositsWithdrawalsFilterSettings,
    ...failedDepositsFilterSettings,
    ...feesFilterSettings,
    ...ftdsFilterSettings,
    ...openWithdrawalsFilterSettings,
    ...positionsFilterSettings,
    ...withdrawalsFilterSettings,
    ...promoCodesFilterSettings,
  })
  // .constant('tradingAccountFilterSettings', tradingAccountfilterSettings)
  .service('filterService', FilterService)
  .service('filterCache', FilterCacheService)
  .service('filterKeyDuplicationResolver', filterKeyDuplicationResolverService);
