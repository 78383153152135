import { PlatformTypeCode } from '@proftit/crm.api.models.enums';
import { SignupFormFieldCode } from '@proftit/signup-form.api.enums';

const signupFormFieldTable = [{
  data: {
    isAutoFill: false
  },
  field: SignupFormFieldCode.Email,
  hasAutoFillOption: true,
  requiredBySystem: ['customer', 'lead'],
  type: 'input',
  isAutoFillOptionHidden: false,
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.ConfirmEmail,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: false,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Password,
  requiredBySystem: ['customer'],
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.ConfirmPassword,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: false,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: {
    isAutoFill: false
  },
  field: SignupFormFieldCode.FirstName,
  hasAutoFillOption: true,
  requiredBySystem: ['customer', 'lead'],
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: {
    isAutoFill: false
  },
  field: SignupFormFieldCode.LastName,
  hasAutoFillOption: true,
  requiredBySystem: ['customer', 'lead'],
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Name,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: {
    isAutoFill: false
  },
  field: SignupFormFieldCode.Currency,
  hasAutoFillOption: true,
  requiredBySystem: ['customer'],
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: {
    isAutoFill: true
  },
  field: SignupFormFieldCode.IsDemoOnly,
  hasAutoFillOption: true,
  isAutoFillOptionHidden: true,
  isOptionsHidden: true,
  requiredBySystem: [],
  options: [{
    label: 'True',
    value: true
  }, {
    label: 'False',
    value: false
  }],
  type: 'radio',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: false,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: {
    isAutoFill: false
  },
  field: SignupFormFieldCode.Phone,
  isAutoFillOptionHidden: false,
  requiredBySystem: ['customer', 'lead'],
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: {
    isAutoFill: false
  },
  field: SignupFormFieldCode.Country,
  hasAutoFillOption: true,
  requiredBySystem: ['customer'],
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.State,
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: false,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Gender,
  options: [{
    label: 'male',
    value: true
  }, {
    label: 'female',
    value: false
  }],
  type: 'radio',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Language,
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Birthday,
  type: 'date',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Age,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Address,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.City,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: 'http://',
  field: SignupFormFieldCode.ConfirmTerms,
  type: 'terms',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  data: '',
  field: SignupFormFieldCode.GeneralConfirmTerms,
  isMultiple: true,
  type: 'generalTerms',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.IdType,
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.IdNumber,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.Nationality,
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.TaxResidency,
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.TaxIdNumber,
  type: 'input',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.IsAllowMarketingCommunication,
  options: [{
    label: 'Yes',
    value: true
  }, {
    label: 'No',
    value: false
  }],
  type: 'radio',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: null
}, {
  field: SignupFormFieldCode.IsAccountReal,
  options: [{
    label: 'Real',
    value: true
  }, {
    label: 'Demo',
    value: false
  }],
  type: 'radio',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: true,
  hasDefaultValueForAdmin: true,
  defaultValueForAdmin: true,
  platformType: null
}, {
  field: SignupFormFieldCode.Platform,
  type: 'select',
  isRequiredForEndUser: false,
  hasIsRequiredForEndUserAbility: true,
  isEnabledForSelectingValueInAdmin: false,
  hasDefaultValueForAdmin: false,
  platformType: PlatformTypeCode.Forex
}];

export { signupFormFieldTable };
