import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './clearing-company-image-selector.component.html';
const styles = require('./clearing-company-image-selector.component.scss');

export class ClearingCompanyImageSelectorController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ClearingCompanyImageSelectorComponent = {
  template,
  controller: ClearingCompanyImageSelectorController,
  bindings: {
    companies: '<',
    selectedCompany: '<',
    onCompanySelect: '&',
  },
};
