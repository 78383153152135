/**
 * Set iframe content.
 */
function setIframeContent(iframe, content) {
  iframe.contentWindow.document.open('text/html', 'replace');
  iframe.contentWindow.document.write(content);
  iframe.contentWindow.document.close();
}

/**
 * Set iframe content - alternative way.
 *
 * Currently this is not used because the iframe body onload event wast not
 * get called. Current reason is not known. Could be related to the iframe
 * onload getting set here.
 *
 * Instead, a combination of iframe container and this directive
 * was used to removed the iframe element and create it again for 'hard' iframe
 * refresh.
 */
function setIframeContentAlternative(iframe, content) {
  // remove previous content
  iframe.src = '';
  // wait for the frame source to change
  iframe.onload = () => {
    // remove the onload binding, to avoid it being called again by the real iframe load
    iframe.onload = null;
    // write new content
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(content);
    iframe.contentWindow.document.close();
  };
}

/**
 * This directive is used to use an arbitrary content for an iframe,
 * much like the 'srcdoc' does, but with IE supported.
 *
 * It should be used as an attribute to an 'iframe' tag.
 *
 * Usage:
 *
 * <iframe prf-iframe-content="<html><body>test</body></html>"></iframe>
 *
 * Output:
 * <iframe>
 *   <html><body>test</body></html>
 * </iframe>
 *
 * @return {object} directive object
 */
export function iframeContentDirective() {
  return {
    restrict: 'A',
    scope: {
      prfIframeContent: '<',
    },
    link: function link(scope, $element) {
      const iframe = $element[0];
      if (iframe.tagName !== 'IFRAME') {
        throw new Error(
          'prfIframeContent directive can only work on iframe elements',
        );
      }

      // Whenever the bound content changes, set it as the iframe's content
      scope.$watch('prfIframeContent', () => {
        setIframeContent(iframe, scope.prfIframeContent);
      });
    },
  };
}
