import Component from '~/source/common/components/dropdowns/base/component';

class BonusStatusesController extends Component.controller {
  static $inject = ['bonusStatusesService', ...Component.controller.$inject];
  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'bonus.statuses';
  }
}

export default Component.config({ controller: BonusStatusesController });
