import ng from 'angular';
import {
  observeComponentLifecycles,
  getCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import template from './signup-form-radio-field.component.html';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { generateUuid } from '@proftit/general-utilities';
import { SignupFormField } from '@proftit/signup-form.api.entities';
import * as _ from '@proftit/lodash';
const styles = require('./signup-form-radio-field.component.scss');

function calcInitialValue(model) {
  if (!_.isUndefined(model.defaultValueForEndUser)) {
    return model.defaultValueForEndUser;
  }

  if (model.hasDefaultValueForAdmin) {
    return model.defaultValueForAdmin;
  }

  return null;
}

export class SignupFormRadioFieldController {
  field: SignupFormField;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  form$ = this.streamForm();
  customRadioName = generateUuid().replace(/-/g, '');

  /*@ngInject */
  constructor() {
    useStreams(
      [
        this.form$.pipe(rx.switchMap((form) => form.value$)),
        this.streamSetDefaultValueForUser(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamForm(): rx.Observable<FormGroup> {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<SignupFormField>('field'),
      rx.map((model) => {
        const value = calcInitialValue(model);

        return new FormGroup({
          radio: new FormControl(value, {
            isReadonly: !model.isEnabledForSelectingValueInAdmin,
          }),
        } as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSetDefaultValueForUser() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.controls$),
      rx.switchMap((controls) => controls.radio.value$),
      rx.map((value) => (this.field.defaultValueForEndUser = value)),
    )(null);
  }
}

export const SignupFormRadioFieldComponent = {
  template,
  controller: SignupFormRadioFieldController,
  bindings: {
    field: '<model',
  },
};
