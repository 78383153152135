import ng from 'angular';

import template from './internal-transfer-form.component.html';
import {
  InternalTransfer,
  TradingAccount,
} from '@proftit/crm.api.models.entities';
import {
  NgModelChange,
  createNgModelObsMediator,
} from '~/source/common/utilities/create-ng-model-obs-mediator';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';

const internalTransferFields = [
  'sourceTradingAccount',
  'destinationTradingAccount',
  'amount',
  'rate',
  'reason',
];

export class InternalTransferFormController {
  lifecycles = observeComponentLifecycles(this);
  model: InternalTransfer;
  onChangeOfModel: (a: { change: NgModelChange }) => {};
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;
  internalTransferForm;
  unsub$ = new rx.Subject<void>();
  model$ = new rx.BehaviorSubject<InternalTransfer>(null);
  modelUpdatesOut$ = new rx.Subject<NgModelChange>();
  modelLocal = {};
  destDesc$ = new rx.BehaviorSubject<string>(' ');
  searchFocusId = _.uniqueId('prf-internal-transfer-form-search-focus');

  /*@ngInject */
  constructor(readonly $validation) {
    useStreams(
      [
        this.streamModelInput(),
        this.streamModelOut(),
        this.streamFormValidOut(),
        this.streamCalcDestDesc(),
        observeCompChange<InternalTransfer>(
          this.model$,
          'model',
          this.lifecycles.onChanges$,
        ),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamModelInput() {
    return rx.pipe(
      () => this.model$,
      rx.map((model) =>
        createNgModelObsMediator(
          internalTransferFields,
          this.model$,
          this.modelUpdatesOut$,
        ),
      ),
      rx.tap((modelMediator) => (this.modelLocal = modelMediator)),
    )(null);
  }

  streamModelOut() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.debounceTime(150),
      rx.map(() => {
        return this.$validation.checkValid(this.internalTransferForm);
      }),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }

  streamCalcDestDesc() {
    return rx.pipe(
      () => this.modelUpdatesOut$,
      rx.filter((change) => change.fieldName === 'destinationTradingAccount'),
      rx.map((change) => calcDestDesc(change.nextValue)),
      rx.tap((destDesc) => this.destDesc$.next(destDesc)),
    )(null);
  }
}

function calcDestDesc(account) {
  if (_.isNil(account)) {
    return ' ';
  }

  const extID = account.syncRemoteId
    ? `External ID ${account.syncRemoteId}`
    : 'No External ID';

  return `${account.firstName} ${account.lastName} - ${extID}`;
}

export const InternalTransferFormComponent = {
  template,
  controller: InternalTransferFormController,
  bindings: {
    model: '<',
    onChangeOfModel: '&',
    onChangeOfFormValidity: '&',
  },
};
