import { IScope } from 'angular';

import BaseController from '~/source/common/controllers/base';
import CurrenciesService from '~/source/common/services/currencies';

import template from './currency-form.html';
import { RatesService } from '~/source/common/services/rates.service';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { Currency } from '@proftit/crm.api.models.entities';
import Big from 'big.js';

const MIN_DEPOSIT_VALUE_SYMBOL = 'USD';

class ComponentController {
  currencies: any;
  selectedCurrencies: any;

  selectCurrencyAction = new rx.Subject<Currency>();

  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly currenciesService: CurrenciesService,
    readonly prfRatesService: RatesService,
  ) {
    useStreams([this.streamSelectCurrency()], this.lifecycles.onDestroy$);
    this.loadCurrencies();
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  loadCurrencies() {
    this.currenciesService.getListWithQuery().then((currencies) => {
      this.currencies = currencies;
    });
  }

  selectCurrency(currency) {
    this.selectCurrencyAction.next(currency.plain());
  }

  isSelected(currency) {
    return (
      this.selectedCurrencies.filter(
        (selectedCurrency) => selectedCurrency.currency.code === currency.code,
      ).length === 1
    );
  }

  removeCurrencyFromSelectedCurrencies(selectedCurrency) {
    return rx.pipe(
      () => rx.obs.of(selectedCurrency),
      rx.tap((selectedCurrency) => {
        this.selectedCurrencies.splice(
          this.selectedCurrencies.indexOf(selectedCurrency),
          1,
        );
      }),
    )(null);
  }

  addCurrencyToSelectedCurrencies(currency) {
    return rx.pipe(
      () => rx.obs.of(currency),
      rx.switchMap((currency) => {
        return this.prfRatesService
          .getRate(MIN_DEPOSIT_VALUE_SYMBOL, currency.code)
          .catch(() => ({ rate: 0 }));
      }),
      rx.map((rateResponse) => {
        return Number(
          Big(rateResponse.rate).round(currency.decimals).toString(),
        );
      }),
      rx.tap((rate) => {
        const isDefault = this.selectedCurrencies.length === 0;

        this.selectedCurrencies.push({
          isDefault,
          currency,
          minimumDeposit: rate,
          minimumDepositCustomer: rate,
        });
      }),
    )(null);
  }

  streamSelectCurrency() {
    return rx.pipe(
      () => this.selectCurrencyAction,
      rx.map((currency) => {
        const selectedCurrency = this.selectedCurrencies.find(
          (selectedCurrency) =>
            selectedCurrency.currency.code === currency.code,
        );

        return { currency, selectedCurrency };
      }),
      rx.switchMap(({ currency, selectedCurrency }) => {
        if (_.isNil(selectedCurrency)) {
          return this.addCurrencyToSelectedCurrencies(currency);
        }

        return this.removeCurrencyFromSelectedCurrencies(selectedCurrency);
      }),
    )(null);
  }
}

const CurrencyFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    selectedCurrencies: '=',
    isEdit: '<',
    remove: '&removeFn',
    removeValidate: '&removeValidateFn',
  },
};

export default CurrencyFormComponent;
