import template from './notice-item-contacts-import.component.html';

const styles = require('./notice-item-contacts-import.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { ContactsImport } from '@proftit/crm.api.models.entities';
import { NoticeType } from '~/source/common/components/notice-popup-list/notice-type';
import { ContactsImportStoreService } from '~/source/common/store-services/contacts-import-store.service';

function calcProgressFromContactsImport(contactsImport) {
  if (contactsImport.totalRecords === 0) {
    return 0;
  }

  const progress =
    ((contactsImport.errorsCount + contactsImport.newRecords) * 100) /
    contactsImport.totalRecords;

  return Math.floor(progress);
}

export class NoticeItemContactsImportController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  contactsImportFromInput$ = observeShareCompChange<ContactsImport>(
    this.lifecycles.onChanges$,
    'contactsImport',
  );

  contactsImport$ = this.streamContactsImport();
  progress$ = this.streamProgress();

  /*@ngInject */
  constructor(
    readonly prfContactsImportStoreService: ContactsImportStoreService,
  ) {
    useStreams([this.contactsImportFromInput$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamProgress() {
    return rx.pipe(
      () => this.contactsImport$,
      rx.map((contactsImport) =>
        calcProgressFromContactsImport(contactsImport),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamContactsImportFromChannel(
    contactsImport$: rx.Observable<ContactsImport>,
  ) {
    return rx.pipe(
      () => contactsImport$,
      rx.switchMap((contactsImport) =>
        this.prfContactsImportStoreService.observeContactsImportUpdateFromSocket(
          contactsImport.id,
        ),
      ),
      rx.withLatestFrom(contactsImport$),
      rx.map(([channelUpdates, original]) => ({
        ...original,
        ...(channelUpdates as any),
      })),
      shareReplayRefOne(),
    )(null);
  }

  streamContactsImport() {
    const contactsImportSubject = new rx.BehaviorSubject<ContactsImport>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.contactsImportFromInput$,
          this.streamContactsImportFromChannel(contactsImportSubject),
        ),
      rx.tap((contactsImport) => contactsImportSubject.next(contactsImport)),
      shareReplayRefOne(),
    )(null);
  }
}

export const NoticeItemContactsImportComponent = {
  template,
  controller: NoticeItemContactsImportController,
  bindings: {
    contactsImport: '<',
  },
};
