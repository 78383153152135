import template from './brand-deposit-wire-account-view.component.html';
import { BrandDepositWireAccount } from '@proftit/crm.api.models.entities/src/brand-deposit-wire-account';
import * as _ from '@proftit/lodash';
const styles = require('./brand-deposit-wire-account-view.component.scss');

export class BrandDepositWireAccountViewController {
  styles = styles;
  wireAccountRegularFields: BrandDepositWireAccount;
  wireAccountCustomFields: BrandDepositWireAccount;
  displayFields: Partial<BrandDepositWireAccount>;
  displayCustomFields: any;

  // @ngInject
  constructor() {}

  $onInit() {
    this.displayFields = _.pick(
      [
        'holderName',
        'holderAddress',
        'bankName',
        'bankAddress',
        'country',
        'iban',
        'swiftCode',
        'noteOnTransfer',
      ],
      this.wireAccountRegularFields,
    );

    this.displayCustomFields = this.wireAccountCustomFields;
  }

  $onChanges() {}

  $onDestroy() {}
}

export const BrandDepositWireAccountViewComponent = {
  template,
  controller: BrandDepositWireAccountViewController,
  bindings: {
    wireAccountRegularFields: '<',
    wireAccountCustomFields: '<',
  },
};
