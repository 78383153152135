import RestService from '~/source/common/services/rest';
import { CacheOptions } from 'cachefactory';
import { TimeInterval } from '@proftit/constants.time';

export class CustomerRegulationFileStatusesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'customerRegulationFileStatuses';
  }

  useCache(): boolean {
    return true;
  }

  get cacheOptions(): CacheOptions {
    return {
      maxAge: TimeInterval.Hour,
    };
  }
}

export default CustomerRegulationFileStatusesService;
