import * as yup from 'yup';
import * as _ from '@proftit/lodash';
import { YupValidationCode } from '~/source/common/yup-validtion/yup-validation-code';
import { decimalPlaces } from '@proftit/math.adjunct';

export function yupValidation() {
  yup.addMethod(yup.number, 'maxDecimalPoints', maxDecimalPoints);

  yup.setLocale({
    mixed: {
      required: ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: path,
        code: YupValidationCode.required,
      }),
      notType: ({ path, type, value, originalValue }) => {
        switch (type) {
          case 'number':
            return {
              type,
              value,
              originalValue,
              key: path,
              code: YupValidationCode.notTypeNumber,
            };
          case 'array':
            return {
              type,
              value,
              originalValue,
              key: path,
              code: YupValidationCode.notTypeArray,
            };
          case 'string':
            return {
              type,
              value,
              originalValue,
              key: path,
              code: YupValidationCode.notTypeString,
            };
          default:
            return {
              path,
              type,
              value,
              originalValue,
              key: path,
              code: YupValidationCode.notTypeString,
            };
        }
      },
    },
    number: {
      integer: ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: path,
        code: YupValidationCode.integer,
      }),
      positive: ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: path,
        code: YupValidationCode.positive,
      }),
      min: ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: path,
        code: YupValidationCode.min,
      }),
      max: ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: path,
        code: YupValidationCode.max,
      }),
      maxDecimalPoints: maxDecimalPointsLocale,
    },
    array: {
      min: ({ path, type, value, originalValue }) => ({
        type,
        value,
        originalValue,
        key: path,
        code: YupValidationCode.minArray,
      }),
    },
  });
}

function maxDecimalPointsLocale({ path, type, value, originalValue }) {
  return {
    type,
    value,
    originalValue,
    key: path,
    code: YupValidationCode.maxDecimalPoints,
  };
}

function maxDecimalPoints(
  maxDecimalPoints: number,
  message = maxDecimalPointsLocale,
) {
  return this.test({
    message,
    name: 'maxDecimalPoints',
    exclusive: true,
    params: { maxDecimalPoints },
    test(value) {
      if (!_.isFinite(value)) {
        return true;
      }

      const decimalLength = decimalPlaces(value);
      return decimalLength <= maxDecimalPoints;
    },
  });
}

yupValidation.$inject = ['$validationProvider'];
