import { observeComponentLifecycles, observeShareCompChange } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

var template = "<div\n  class=\"prfw-sms-code\"\n  data-prf-id=\"number-code-input-component\"\n  prf-input-jumper-context\n>\n  <input\n    type=\"text\"\n    class=\"prfw-sms-code-control\"\n    name=\"number{{ $index }}\"\n    ng-model=\"numberCtrl.model\"\n    ng-model-options=\"{ debounce: 150 }\"\n    maxlength=\"1\"\n    prf-input-jumper=\"$index\"\n    prf-input-jumper-prop-length-triger=\"1\"\n    ng-repeat=\"numberCtrl in ($ctrl.form.controls$ | async:this)\"\n    ng-focus=\"$ctrl.onFocus($event)\"\n  />\n</div>\n";

function styleInject(css, ref) {
  if ( ref === void 0 ) ref = {};
  var insertAt = ref.insertAt;

  if (!css || typeof document === 'undefined') { return; }

  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');
  style.type = 'text/css';

  if (insertAt === 'top') {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild);
    } else {
      head.appendChild(style);
    }
  } else {
    head.appendChild(style);
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
}

var css_248z = ".prfw-sms-code {\n  display: flex;\n  justify-content: space-between; }\n  .prfw-sms-code-control {\n    height: 1.5em;\n    width: 1.5em;\n    text-align: center;\n    padding: 0;\n    line-height: 1.5em; }\n    .prfw-sms-code-control:not(:first-child) {\n      margin-left: 0.4em; }\n";
var styles = {};
styleInject(css_248z);

/// <reference lib="dom" />
class NumberCodeInputController {
  constructor() {
    this.styles = styles;
    this.lifecycles = observeComponentLifecycles(this);
    this.form$ = observeShareCompChange(this.lifecycles.onChanges$, 'form');
    useStreams([this.form$.pipe(rx.switchMap(form => form.value$))], this.lifecycles.onDestroy$);
  }
  /* eslint-disable-next-line */


  $onInit() {}
  /* eslint-disable-next-line */


  $onDestroy() {}
  /* eslint-disable-next-line */


  $onChanges() {}

  onFocus(e) {
    e.currentTarget.select();
  }

}
NumberCodeInputController.$inject = [];
const NumberCodeInputComponent = {
  template,
  controller: NumberCodeInputController,
  bindings: {
    form: '<'
  }
};

function inputJumperDirective($parse) {
  return {
    restrict: 'A',
    require: {
      prfInputJumperContext: '^'
    },

    link(scope, element, attrs, {
      prfInputJumperContext
    }) {
      let jumperId;
      attrs.$observe('prfInputJumper', newVal => {
        jumperId = $parse(newVal)(scope);
        prfInputJumperContext.registerAsChild(jumperId, () => {
          element[0].focus();
        });
      });

      const inputHandler = e => {
        if (_.isNil(e.target.value) || e.target.value.length < 1) {
          return;
        }

        prfInputJumperContext.jumpToNext(jumperId);
      };

      const keydownHandler = e => {
        // backspace on empty
        if (e.keyCode === 8 && (_.isNil(e.target.value) || _.isEmpty(e.target.value))) {
          prfInputJumperContext.jumpToPrev(jumperId);
        }
      };

      element.on('input', inputHandler);
      element.on('keydown', keydownHandler);
      scope.$on('$destroy', () => {
        element.off('input', inputHandler);
        element.off('keydown', keydownHandler);
      });
    }

  };
}
inputJumperDirective.$inject = ['$parse'];

class InputJumperContext {
  constructor() {
    this.childs = {};
  }

  registerAsChild(jumperId, focusFn) {
    this.childs[jumperId] = {
      focusFn
    };
  }

  jumpToNext(jumperId) {
    const nextInput = this.childs[jumperId + 1];

    if (_.isNil(nextInput)) {
      return;
    }

    nextInput.focusFn();
  }

  jumpToPrev(jumperId) {
    const input = this.childs[jumperId - 1];

    if (_.isNil(input)) {
      return;
    }

    input.focusFn();
  }

}

function inputJumperContextDirective() {
  return {
    restrict: 'A',
    controller: InputJumperContext
  };
}
inputJumperContextDirective.$inject = [];

function defineNg1InputJumperModule(angular) {
  const ngModule = angular.module('prfInputJumper', []);
  ngModule.directive('prfInputJumperContext', inputJumperContextDirective).directive('prfInputJumper', inputJumperDirective);
  return ngModule;
}

function defineNg1NumberCodeInputModule(angular) {
  const inputJumperModule = defineNg1InputJumperModule(angular);
  const ngModule = angular.module('prfNg1NumberCodeInput', ['asyncFilter', inputJumperModule.name]);
  ngModule.component('prfNumberCodeInput', NumberCodeInputComponent);
  return ngModule;
}

export { defineNg1NumberCodeInputModule };
