import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';
import {
  initRequestDomainResource,
  getAsList,
  appendResource,
  update,
  setPayload,
  setTokenHeader,
  performRequest as ngHttpPerformRequest,
  cfdMongoToNgHttp,
} from '@proftit/request-client';
import { CfdGroup } from '@proftit/tradingcore.api.models.entities';

const RESOURCE_PATH = 'Assets';

const ASSETS_GROUPS = 'AssetsGroups';

const PIP_DIGITS = 'AssetsPrecisions';

const GROUP = 'group';

const BULK = 'bulk';
export class ApiCfdAssetsService {
  /* @ngInject */
  constructor(readonly $http: IHttpService) {}

  getAssets(apiUrl: string, token: string): Promise<CfdGroup[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest<CfdGroup[]>(this.$http, req),
      (req) => req.then((resp: { data: CfdGroup[] }) => resp.data),
    ])();
  }

  getAssetsCategories(apiUrl: string, token: string): Promise<CfdGroup[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => appendResource(ASSETS_GROUPS, req),
      (req) => getAsList(req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest<CfdGroup[]>(this.$http, req),
      (req) => req.then((resp: { data: CfdGroup[] }) => resp.data),
    ])();
  }

  getAssetsPipDigits(
    groupFilters,
    apiUrl: string,
    token: string,
  ): Promise<CfdGroup[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => appendResource(PIP_DIGITS, req),
      (req) => getAsList(req),
      (req) => cfdMongoToNgHttp(req),
      (req) => {
        if (_.isEmpty(groupFilters)) {
          return req;
        }

        const url = `${
          req.url
        }?query={"query":{"status":"active","group": {"$in": [${groupFilters
          .map((item) => `'${item}'`)
          .join(',')}]}}}`;
        return {
          ...req,
          url,
        };
      },
      (req) => ngHttpPerformRequest<CfdGroup[]>(this.$http, req),
      (req) => req.then((resp: { data: CfdGroup[] }) => resp.data),
    ])();
  }

  getGroupAssets(
    groupId,
    groupFilters,
    apiUrl: string,
    token: string,
  ): Promise<CfdGroup[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => appendResource(GROUP, req),
      (req) => appendResource(groupId, req),
      (req) => getAsList(req),
      (req) => cfdMongoToNgHttp(req),
      (req) => {
        if (_.isEmpty(groupFilters)) {
          return req;
        }

        // this is category
        const url = _.flow([
          (url) => `${url}?query={"query":{"status":"active"`,
          (url) =>
            groupFilters.categories
              ? `${url},"group": {"$in": [${groupFilters.categories
                  .map((item) => `'${item}'`)
                  .join(',')}]}`
              : url,
          (url) =>
            groupFilters.pipDigits
              ? `${url},"precision": {"$in": [${groupFilters.pipDigits
                  .map((item) => item)
                  .join(',')}]}`
              : url,
          (url) => `${url}}}`,
        ])(req.url);

        return {
          ...req,
          url,
        };
      },
      (req) => ngHttpPerformRequest<CfdGroup[]>(this.$http, req),
      (req) => req.then((resp: { data: CfdGroup[] }) => resp.data),
    ])();
  }

  updateBulkGroupAssets(payload, apiUrl: string, token: string) {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => appendResource(BULK, req),
      (req) => update(req),
      (req) => setPayload(payload, req),
      (req) => cfdMongoToNgHttp(req),
      (req) => ngHttpPerformRequest<CfdGroup[]>(this.$http, req),
      (req) => req.then((resp: { data: CfdGroup[] }) => resp.data),
    ])();
  }
}
