import ng from 'angular';
import log from 'loglevel';
import BaseService from '~/source/common/services/baseService';
import PopupService from '~/source/common/components/modal/popup.service';
import DownloadStatus from '~/source/common/models/download-status';
import { KibiDownloadsService } from './kibi-downloads.service';
import { KibiDownloadSocketService } from './kibi-download-socket.service';
import { CrmDownloadsSocketService } from './crm-downloads-socket.service';
import TokensService from '~/source/auth/services/tokens';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import * as rx from '@proftit/rxjs';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import {
  userCrmExportNewChannelName,
  userKibiExportNewChannelName,
  userBalanceLogExportNewChannelName,
} from '@proftit/crm.api.channels';
import { CrmDownloadsService } from './crm-downloads.service';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import { BalanceLogDownloadsService } from '~/source/reports/balance-log-downloads.service';

export class DownloadsPopupService extends BaseService {
  static $inject = [
    'popupService',
    'kibiDownloadsService',
    'crmDownloadsService',
    'crmDownloadsSocketService',
    'tokensService',
    'kibiDownloadSocketService',
    'PermPermissionStore',
    'prfCurrentUserStore',
    'balanceLogDownloadsService',
  ];

  popupService: PopupService;
  tokensService: TokensService;
  popupInstance: ng.ui.bootstrap.IModalInstanceService;
  kibiDownloadsService: KibiDownloadsService;
  kibiDownloadSocketService: KibiDownloadSocketService;
  crmDownloadsService: CrmDownloadsService;
  crmDownloadsSocketService: CrmDownloadsSocketService;
  balanceLogDownloadsService: () => BalanceLogDownloadsService;
  PermPermissionStore: ng.permission.PermissionStore;
  prfCurrentUserStore: CurrentUserStoreService;

  unsub$ = new rx.Subject<void>();
  isPopupOpenS$ = new rx.BehaviorSubject<boolean>(false);
  startListeningOp$ = new rx.Subject<void>();
  stopListeningOp$ = new rx.Subject<void>();
  openDownloadPopupOp$ = new rx.Subject<void>();
  closeDownloadPopupOp$ = new rx.Subject<void>();
  popupInstanceS$ = new rx.BehaviorSubject<any>(null);
  kibiExportChannelName$ = this.streamKibiExportChannelName();
  newDownloadsExists$ = this.streamNewDownloadsExists();
  openDownloadPopup$ = this.streamOpenDownloadPopup();
  closeDownloadPopup$ = this.streamCloseDownloadPopup();

  constructor(...args) {
    super(...args);

    useStreams(
      [this.openDownloadPopup$, this.closeDownloadPopup$],
      this.unsub$,
    );
  }

  streamKibiExportChannelName() {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.map((user) => {
        if (_.isNil(user)) {
          return null;
        }

        return userKibiExportNewChannelName(user.id);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamActiveKibiDownloadsFromReq() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ReportingKibiExport,
          this.PermPermissionStore,
        ),
      rx.switchMap((perms) => {
        if (!perms.isView) {
          return rx.obs.from([[]]);
        }

        return this.kibiDownloadsService
          .filter({ status: DownloadStatus.Active })
          .getListWithQuery()
          .then((data) => data.plain());
      }),
      rx.catchError((err) => {
        log.error('error fetching kib downloads', err);
        return rx.obs.from([[]]);
      }),
    )(null);
  }

  streamActiveCrmDownloadsFromReq() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ContactsCrmexport,
          this.PermPermissionStore,
        ),
      rx.switchMap((perms) => {
        if (!perms.isView) {
          return rx.obs.from([[]]);
        }

        return this.crmDownloadsService
          .filter({ status: DownloadStatus.Active })
          .getListWithQuery()
          .then((data) => data.plain());
      }),
      rx.catchError((err) => {
        log.error('error fetching crm downloads', err);
        return rx.obs.from([[]]);
      }),
    )(null);
  }

  streamActiveBalanceLogDownloadsFromReq() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.BalanceLogExport,
          this.PermPermissionStore,
        ),
      rx.switchMap((perms) => {
        if (!perms.isView) {
          return rx.obs.from([[]]);
        }

        return this.balanceLogDownloadsService()
          .filter({ status: DownloadStatus.Active })
          .getListWithQuery()
          .then((data) => data.plain());
      }),
      rx.catchError((err) => {
        log.error('error fetching crm downloads', err);
        return rx.obs.from([[]]);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewKibiDownloadsFromSocket() {
    return rx.pipe(
      () => this.startListeningOp$,
      rx.switchMap(() =>
        checkCrudPermission(
          PermissionNormalized.ReportingKibiExport,
          this.PermPermissionStore,
        ),
      ),
      rx.filter((permissionCrud) => permissionCrud.isView),
      rx.switchMap(() => this.kibiExportChannelName$),
      rx.switchMap((kibiChannelName) => {
        if (_.isNil(kibiChannelName)) {
          return rx.obs.NEVER;
        }

        return observeChannel(this.kibiDownloadSocketService, kibiChannelName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewCrmDownloadsFromSocket() {
    return rx.pipe(
      () => this.startListeningOp$,
      rx.switchMap(() => this.prfCurrentUserStore.currentLoggedUser$),
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }

        const channelName = userCrmExportNewChannelName(user.id);
        return observeChannel(this.crmDownloadsSocketService, channelName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewBalanceLogDownloadsFromSocket() {
    return rx.pipe(
      () => this.startListeningOp$,
      rx.switchMap(() =>
        checkCrudPermission(
          PermissionNormalized.BalanceLogExport,
          this.PermPermissionStore,
        ),
      ),
      rx.filter((permissionCrud) => permissionCrud.isView),
      rx.switchMap(() => this.prfCurrentUserStore.currentLoggedUser$),
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.NEVER;
        }
        const channelName = userBalanceLogExportNewChannelName(user.id);
        return observeChannel(this.crmDownloadsSocketService, channelName);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamNewKibiDownloadsExitsFromReq() {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.from([[]]);
        }

        return this.streamActiveKibiDownloadsFromReq();
      }),
      rx.filter((d) => d.length > 0),
      rx.map(() => true),
      shareReplayRefOne(),
    )(null);
  }

  streamNewCrmDownloadsExitsFromReq() {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.from([[]]);
        }

        return this.streamActiveCrmDownloadsFromReq();
      }),
      rx.filter((d) => d.length > 0),
      rx.map(() => true),
      shareReplayRefOne(),
    )(null);
  }

  streamNewBalanceLogDownloadsExistsFromReq() {
    return rx.pipe(
      () => this.prfCurrentUserStore.currentLoggedUser$,
      rx.switchMap((user) => {
        if (_.isNil(user)) {
          return rx.obs.from([[]]);
        }

        return this.streamActiveBalanceLogDownloadsFromReq();
      }),
      rx.filter((d) => d.length > 0),
      rx.map(() => true),
      shareReplayRefOne(),
    )(null);
  }

  streamNewDownloadsExists() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamNewKibiDownloadsFromSocket().pipe(rx.map(() => true)),
          this.streamNewCrmDownloadsFromSocket().pipe(rx.map(() => true)),
          this.streamNewBalanceLogDownloadsFromSocket().pipe(
            rx.map(() => true),
          ),
          this.streamNewKibiDownloadsExitsFromReq(),
          this.streamNewCrmDownloadsExitsFromReq(),
          this.streamNewBalanceLogDownloadsExistsFromReq(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamOpenDownloadPopup() {
    return rx.pipe(
      () => rx.obs.merge(this.newDownloadsExists$, this.openDownloadPopupOp$),
      rx.withLatestFrom(this.isPopupOpenS$),
      rx.tap(([op, isPopupOpen]) => {
        if (isPopupOpen) {
          return;
        }

        this.popupInstanceS$.next(this.openPopupComponent());
        this.isPopupOpenS$.next(true);
      }),
    )(null);
  }

  streamCloseDownloadPopup() {
    return rx.pipe(
      () => rx.obs.merge(this.stopListeningOp$, this.closeDownloadPopupOp$),
      rx.withLatestFrom(this.popupInstanceS$, this.isPopupOpenS$),
      rx.filter(([op, popupInstance, isPopupOpen]) => {
        return popupInstance && isPopupOpen;
      }),
      rx.tap(([op, popupInstance, isPopupOpen]) => {
        popupInstance.close();
        this.popupInstanceS$.next(null);
        this.isPopupOpenS$.next(false);
      }),
    )(null);
  }

  /**
   * Init service, start listen to downloads channel
   */
  startListening() {
    this.startListeningOp$.next();
  }

  /**
   * stop listen to downloads channel
   */
  stopListening() {
    this.stopListeningOp$.next();
  }

  openPopup() {
    this.openDownloadPopupOp$.next();
  }

  closePopup() {
    this.closeDownloadPopupOp$.next();
  }

  openPopupComponent() {
    return this.popupService.open({
      component: 'prfUserReportGenerationDownloadList',
      openedClass: 'downloads-wrapper',
      shouldCloseOnRouteChange: false,
    });
  }
}

export default DownloadsPopupService;
