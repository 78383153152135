import ng, { IScope } from 'angular';

import BaseController from '~/source/common/controllers/base';
import { ModalService } from '../../modal/modal.service';
import { UserFilters } from '~/source/common/services/user-filters';

export class FilterGroupPopupController extends BaseController {
  group;
  isUpdate: boolean;
  currentSelectedFilters;
  $close: () => void;
  category;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly modalService: ModalService,
    readonly userFiltersService: UserFilters,
  ) {
    super();

    this.group = {};
    this.isUpdate = false;

    this.$scope.$on('filterGroupForm:groupSelected', (event, selectedItem) => {
      this.isUpdate = selectedItem.existing;
    });
  }

  /**
   * Updates filters on filterGroup object
   *
   * @return {void}
   */
  updateFiltersState() {
    this.userFiltersService
      .setConfig({
        growlRef: 'filterGroupModal',
        blockUiRef: 'filterGroupModal',
      })
      .resourceBuildStart()
      .getElement(this.group.id)
      .resourceBuildEnd()
      .patchWithQuery({
        // must copy so that filter state changes won't affect saved filter
        filters: ng.copy(this.currentSelectedFilters),
      })
      .then((data) => {
        /*
         * update table data on success
         * This will add the new filter to the "my filters" dropdown and select it
         */
        (this.$scope as any).onUpdateMyFiltersDropdownModel(data, true);
        this.$close();
      });
  }

  /**
   * Called when "FilterGroupPopupController:save" event was triggered
   * Sends request to server that will add new filter group
   *
   * @return {void}
   */
  saveFiltersState() {
    const filter = {
      name: this.group.name,
      filterTypeCode: this.category,
      isSystem: false,
      // must copy so that filter state changes won't affect saved filter
      filters: ng.copy(this.currentSelectedFilters),
    };
    this.userFiltersService
      .setConfig({
        growlRef: 'filterGroupModal',
        blockUiRef: 'filterGroupModal',
      })
      .postWithQuery(filter)
      .then((data) => {
        (this.$scope as any).onUpdateMyFiltersDropdownModel(data, false);
        this.$close();
      });
  }

  /**
   * Save/Overwrite new group
   *
   * @return {void}
   */
  saveUpdateFilterGroup() {
    if (this.isUpdate) {
      // update
      this.updateFiltersState();
    } else {
      // save
      this.saveFiltersState();
    }
  }
}

export default FilterGroupPopupController;
