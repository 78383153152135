export const tableColumns = [
  {
    title: 'brandDemoDepositAutomationAuditLogTable.MODIFIED_DATE',
    field: '',
    id: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandDemoDepositAutomationAuditLogTable.ACTION',
    field: '',
    id: 'action',
    fieldFormat: '{{ action }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandDemoDepositAutomationAuditLogTable.PREVIOUS_CRITERIA',
    field: '',
    id: 'previousAmount',
    fieldFormat: '{{ _dataAsPrintable.oldValue }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandDemoDepositAutomationAuditLogTable.NEW_CRITERIA',
    field: '',
    id: 'newAmount',
    fieldFormat: '{{ _dataAsPrintable.newValue }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandDemoDepositAutomationAuditLogTable.CHANGED_BY',
    field: '',
    id: 'user',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'brandDemoDepositAutomationAuditLogTable.IP',
    field: '',
    id: 'ip',
    fieldFormat: '{{ userIp }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
