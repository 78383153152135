import * as _ from '@proftit/lodash';
import {
  CommunicationProvider,
  // SystemLogin2fa,
  CommunicationProviderFormsField,
  CommunicationProviderCredentials,
} from '@proftit/crm.api.models.entities';
import {
  ItemProxy,
  allChildrenAreValidProxyValidator,
  ValueBean,
  generateValueBean,
  getBeanProxy,
  generateObjectBean,
  logicalAndProxyValidatorFactory,
  generateArrayBean,
  getWholeValueOfBean,
} from '@proftit/proxy-bean';
import {
  isNotNilAndNotEmptyProxyValidator,
  isUrlProxyValidator,
} from '@proftit/proxy-bean-validators-general';
import { CommunicationProviderTypeCode } from '@proftit/crm.api.models.enums';

interface SystemLogin2faForm {
  isEnabled: boolean;
  provider: CommunicationProvider;
  smsCredentialsSpreaded: {
    schema: CommunicationProviderFormsField;
    value: CommunicationProviderCredentials;
  }[];
}

export function generateSystemLogin2faForm(): ItemProxy<SystemLogin2faForm> {
  const systemLogin2faFormBean = generateSystemLogin2faFormBean();
  return getBeanProxy(systemLogin2faFormBean);
}

function generateSystemLogin2faFormBean() {
  return generateObjectBean({
    children: {
      isEnabled: generateValueBean(),
      provider: generateValueBean(),
      smsCredentialsSpreaded: generateSmsCredentialsSpreadedBean(),
    },
  });
}

function generateSmsCredentialsSpreadedBean() {
  return generateArrayBean({
    newItemFactory: () => generateCommProviderCredsSpreadedBean(),
    validators: [allChildrenAreValidProxyValidator],
  });
}

function generateCommProviderCredsSpreadedBean() {
  const schema = generateValueBean();

  return generateObjectBean({
    children: {
      schema,
      value: generateObjectBean({
        children: {
          key: generateValueBean(),
          value: generateValueBean({
            validators: [commProviderCredProxyValidatorFactory(schema)],
          }),
        },
      }),
    },
  });
}

function commProviderCredProxyValidatorFactory(schema: ValueBean) {
  return function validator<T>(bean: ValueBean) {
    const validatorsList = _.flow([
      (ls) =>
        getWholeValueOfBean(schema).isRequired
          ? [...ls, isNotNilAndNotEmptyProxyValidator]
          : ls,
      (ls) =>
        getWholeValueOfBean(schema).type === CommunicationProviderTypeCode.Url
          ? [...ls, isUrlProxyValidator]
          : ls,
    ])([]);

    return logicalAndProxyValidatorFactory(validatorsList)(bean);
  };
}
