import RestService from '~/source/common/services/rest';

export class WarmRedisCacheService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'streamer/warmCache';
  }

  getResourceRoute(resourceName: string) {
    return this.resourceBuildStart()
      .getElement(resourceName)
      .resourceBuildEnd();
  }
}
