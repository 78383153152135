import template from './generic-dialog.component.html';
const styles = require('./generic-dialog.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';

export class GenericDialogController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const GenericDialogComponent = {
  template,
  controller: GenericDialogController,
  bindings: {
    blockUiId: '<',
    titleText: '<',
    growlId: '<',
    disableMinimizeOption: '<',
    onCancelClick: '&',
  },
  transclude: true,
};
