import { CommunicationTypeCode } from '@proftit/crm.api.models.enums';
import RestService from '~/source/common/services/rest';
import { IElementRestNg } from '../models/ielement-rest-ng';
import { CommunicationType } from '../models/communication-type';
import { TimeInterval } from '@proftit/constants.time';

const COMM_TYPE_CALL = 'call';
const COMM_TYPE_SMS = 'sms';

export class CommunicationTypesService extends RestService {
  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'communicationTypes';
  }

  /**
   * Cache the result
   *
   * @returns {boolean}
   */
  useCache() {
    return true;
  }

  /**
   * Override default cache options
   */
  get cacheOptions() {
    return {
      maxAge: TimeInterval.Day,
    };
  }

  /**
   * Get communication type by type code.
   *
   * This gets the cached list and filter in client for the correct code.
   *
   * @param typeCode
   * @return communication type
   */
  getItemByCode(typeCode: CommunicationTypeCode | string) {
    return this.getListWithQuery<
      IElementRestNg<CommunicationType>
    >().then((items) => items.find((item) => item.code === typeCode));
  }

  /**
   * Get communication type for type call.
   * @return call communication type.
   */
  getCommTypeCall() {
    return this.getListWithQuery<
      IElementRestNg<CommunicationType>
    >().then((items) => items.find((item) => item.code === COMM_TYPE_CALL));
  }

  /**
   * Get communication type for type comment.
   * @return call communication type.
   */
  getCommTypeComment() {
    return this.getListWithQuery<
      IElementRestNg<CommunicationType>
    >().then((items) =>
      items.find((item) => item.code === CommunicationTypeCode.Comment),
    );
  }

  /**
   * Get communication type for type sms.
   * @return sms communication type.
   */
  getCommTypeSms() {
    return this.getListWithQuery<
      IElementRestNg<CommunicationType>
    >().then((items) => items.find((item) => item.code === COMM_TYPE_SMS));
  }
}
