// This file was generated automatically. Tread lightly.

export enum UserAccessApiSubjectCode {
  Root = '&root',
  ApiUsers = 'api_users',
  AutomationAccountStatuses = 'automation.account_statuses',
  AutomationInactivity = 'automation.inactivity',
  Bonuses = 'bonuses',
  Brands = 'brands',
  BrandsCashiers = 'brands.cashiers',
  BrandsClearings = 'brands.clearings',
  BrandsCommunicationSettings = 'brands.communication_settings',
  BrandsEwallets = 'brands.ewallets',
  BrandsSettings3D = 'brands.settings_3d',
  Campaigns = 'campaigns',
  Clearings = 'clearings',
  ContactsContactimport = 'contacts.contactimport',
  ContactsCrmexport = 'contacts.crmexport',
  ContactsSend2Facode = 'contacts.send2facode',
  Customers = 'customers',
  CustomersAttachments = 'customers.attachments',
  CustomersAuditlog = 'customers.auditlog',
  CustomersCommunications = 'customers.communications',
  CustomersCreditcards = 'customers.creditcards',
  CustomersLinkcustomers = 'customers.linkcustomers',
  CustomersLoginas = 'customers.loginas',
  CustomersSharedgroupfilters = 'customers.sharedgroupfilters',
  CustomersTradingaccount = 'customers.tradingaccount',
  CustomersTradingaccountBalancelogsexport = 'customers.tradingaccount.balancelogsexport',
  CustomersTradingaccountCredits = 'customers.tradingaccount.credits',
  CustomersTradingaccountDeposits = 'customers.tradingaccount.deposits',
  CustomersTradingaccountFees = 'customers.tradingaccount.fees',
  CustomersTradingaccountInternaltransfers = 'customers.tradingaccount.internaltransfers',
  CustomersTradingaccountPositions = 'customers.tradingaccount.positions',
  CustomersTradingaccountRiskfreepositions = 'customers.tradingaccount.riskfreepositions',
  CustomersTradingaccountWithdrawalrequests = 'customers.tradingaccount.withdrawalrequests',
  CustomersTradingaccountWithdrawalrequestsWithdrawals = 'customers.tradingaccount.withdrawalrequests.withdrawals',
  Desks = 'desks',
  Ewallets = 'ewallets',
  FieldsAndModules = 'fields_and_modules',
  Files = 'files',
  IpWhitelist = 'ip_whitelist',
  Mail = 'mail',
  ManagementAssignmentpanel = 'management.assignmentpanel',
  ManagementEmailTemplates = 'management.email_templates',
  ManagementRiskmanager = 'management.riskmanager',
  ManagementUser2Fa = 'management.user2fa',
  Marketing = 'marketing',
  NotificationSettings = 'notification_settings',
  Portfolio = 'portfolio',
  Pricingmodel = 'pricingmodel',
  ReportingKibiexport = 'reporting.kibiexport',
  Usergroups = 'usergroups',
  Users = 'users',
  UsersActivity = 'users.activity',
}
