import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import type { IParseService, IScope } from 'angular';
import { PlatformCode, MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import { ApiCfdGroupsService } from '../api-cfd-platform/api-cfd-groups.service';
import { ApiMt4GroupsService } from '../api-cfd-platform/api-mt4-groups.service';
import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import { Mt4Group, CfdGroup } from '@proftit/tradingcore.api.models.entities';
import { directivesPriorities } from '../constants/directives-priorities';

interface InProcessStream<T> {
  inProcess$: rx.Observable<any>;
  stream$: rx.Observable<T>;
}

interface NgSelfAttrs {
  normalize(name: string): string;
  $addClass(classVal: string): void;
  $removeClass(classVal: string): void;
  $updateClass(newClasses: string, oldClasses: string): void;
  $observe(key: string, fn: Function): void;
  $set(name: string, value: string): void;
  $attr: Record<string, string>;
}

interface TrcGroupsOfPlatformNgAttrs extends NgSelfAttrs {
  prfCurrentTrcGroupsOfPlatform: string;
}

export class CurrentTrcGroupsOfPlatformServiceDirectiveController {
  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  cfdGroupsS: InProcessStream<CfdGroup[]>;

  bundleGroupsS: InProcessStream<CfdGroup[]>;

  mtGroupsS: InProcessStream<Mt4Group[]>;

  generalGroupsS: InProcessStream<(CfdGroup | Mt4Group)[]>;

  /* @ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $parse: IParseService,
    readonly $attrs: TrcGroupsOfPlatformNgAttrs,
    readonly prfApiCfdGroupsService: ApiCfdGroupsService,
    readonly prfApiMt4GroupsService: ApiMt4GroupsService,
  ) {}

  $onInit() {
    this.cfdGroupsS = this.streamCfdGroups();
    this.mtGroupsS = this.streamMtGroups();
    this.bundleGroupsS = this.streamBundleGroups();
    this.generalGroupsS = this.streamGeneralGroups();

    const mainAttrExpFn = this.$parse(
      this.$attrs.prfCurrentTrcGroupsOfPlatform,
    );
    const newScope: any = this.$scope.$new(false, this.$scope);
    newScope.service = this;
    mainAttrExpFn(newScope);
    newScope.$destroy();
  }

  streamCfdGroups() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((sessionInfo) => {
            if (!sessionInfo.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (sessionInfo.platform.code !== PlatformCode.Cfd) {
              return rx.obs.of([]);
            }

            return this.prfApiCfdGroupsService
              .getGroups(sessionInfo.session.apiUrl, sessionInfo.session.token)
              .catch((e: Error) => {
                log.error('error fetching cfd platform groups', e);
                return [];
              });
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamBundleGroups() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((sessionInfo) => {
            if (!sessionInfo.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (sessionInfo.platform.code !== PlatformCode.Bundle) {
              return rx.obs.of([]);
            }

            return this.prfApiCfdGroupsService
              .getGroups(sessionInfo.session.apiUrl, sessionInfo.session.token)
              .catch((e: Error) => {
                log.error('error fetching bundle platform groups', e);
                return [];
              });
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamMtGroups() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((sessionInfo) => {
            if (!sessionInfo.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (!MT4_PLATFORMS.includes(sessionInfo.platform.code)) {
              return rx.obs.of([]);
            }

            return this.prfApiMt4GroupsService
              .getGroups(sessionInfo.session.apiUrl, sessionInfo.session.token)
              .catch((e: Error) => {
                log.error('error fetching mt4 platform groups', e);
                return [];
              });
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamGeneralGroups() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((session) => {
            if (!session.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (MT4_PLATFORMS.includes(session.platform.code)) {
              return this.mtGroupsS.stream$;
            }

            if (session.platform.code === PlatformCode.Cfd) {
              return this.cfdGroupsS.stream$;
            }

            if (session.platform.code === PlatformCode.Bundle) {
              return this.bundleGroupsS.stream$;
            }
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }
}

export const currentTrcGroupsOfPlatformServiceDirective = () => {
  return {
    priority: directivesPriorities.serviceDirective,
    restrict: 'A',
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentTrcGroupsOfPlatformServiceDirectiveController,
  };
};

currentTrcGroupsOfPlatformServiceDirective.$inject = [] as string[];
