import ng from 'angular';
import PlatformsApiComponent from './platforms-manage/platforms-manage.component';
import PlatformDataComponent from './platform-data/platform-data.component';
import PlatformFormComponent from './platform-form/platform-form.component';
import DefaultTradingAccountGroups from './default-trading-account-groups/default-trading-account-groups.component';
import { brandConnectivityRouting } from './brand-connectivity.routing';

export const brandConnectivityModule = ng
  .module('crm.integrations.platforms', [])
  .config(brandConnectivityRouting)
  .component('prfPlatformsManage', PlatformsApiComponent)
  .component('prfPlatformData', PlatformDataComponent)
  .component('prfPlatformForm', PlatformFormComponent)
  .component(
    'prfPlatformDefaultTradingAccountGroups',
    DefaultTradingAccountGroups,
  );
