import * as _ from '@proftit/lodash';
import { IOnChangesObject, IChangesObject } from 'angular';
import * as rx from '@proftit/rxjs';

export function filterCompChange<T>(
  propertyName: string,
  onChange$: rx.Observable<IOnChangesObject>,
): rx.Observable<IChangesObject<T>> {
  return rx.pipe(
    () => onChange$,
    rx.map((changes) => changes[propertyName]),
    rx.filter((propChange) => !_.isNil(propChange)),
  )(null);
}
