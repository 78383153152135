import ng, { IComponentOptions } from 'angular';
import {
  BrandProviderComponent,
  BrandProviderInnerComponent,
} from './brand-provider.component';
import {
  CountriesProviderInnerComponent,
  CountriesProviderComponent,
} from './countries-provider.component';
import { TranslationsProviderComponent } from './translations-provider.component';

export const ProvidersModule = ng.module('crm.common.providers', [
  'asyncFilter',
]);

ProvidersModule.component('prfBrandProvider', BrandProviderComponent);
ProvidersModule.component('prfBrandProviderInner', BrandProviderInnerComponent);
ProvidersModule.component('prfCountriesProvider', CountriesProviderComponent);
ProvidersModule.component(
  'prfCountriesProviderInner',
  CountriesProviderInnerComponent,
);
ProvidersModule.component(
  'prfTranslationsProvider',
  TranslationsProviderComponent,
);
