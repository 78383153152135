import { IHttpService } from 'angular';
// import Url from 'url-parse';
// import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import {
  // init,
  // setProtocol,
  // setHost,
  // setResource,
  // addHeader,
  getAsOne,
  // getAsList,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  // create as genReqCreate,
  appendResource,
  initRequestDomainResource,
  getAsList,
  setTokenHeader,
  CfdMongoRequest,
} from '@proftit/request-client';

import {
  Mt4Group,
  MtGroupAsset,
} from '@proftit/tradingcore.api.models.entities';
// import { removeColonAtEnd } from '@proftit/general-utilities';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
// import { PlatformConnection } from '~/source/common/models/platform-connection';
import { BrandsService } from '~/source/management/brand/services/brands';
import { initPrivateReqMt4 } from '~/source/common/api-cfd-platform/trading-core-request-utilities/init-private-req-mt4';
import { initReq } from './trading-core-request-utilities/init-req';
import { ngPerformMongo } from '../utilities/ng-perform-mongo';

export const RESOURCE_PATH = 'MTCustomerGroups';
export const MT_ASSETS_PATH = 'MTAssets';
export const MT_GROUP_SECURITIES_PATH = 'MTSecurities';

export class ApiMt4GroupsService {
  /* @ngInject */
  constructor(
    readonly $http: IHttpService,
    readonly appConfig: any,
    readonly brandsService: () => BrandsService,
  ) {}

  reqGroupAssets(reqP: CfdMongoRequest, groupdId: number) {
    return _.flow([
      (req) => appendResourceId(groupdId, req),
      (req) => appendResource(MT_ASSETS_PATH, req),
    ])(reqP);
  }

  reqGroupSecurities(reqP: CfdMongoRequest, groupdId: number) {
    return _.flow([
      (req) => appendResourceId(groupdId, req),
      (req) => appendResource(MT_GROUP_SECURITIES_PATH, req),
    ])(reqP);
  }

  getGroup(apiUrl: string, token: string, groupId: number) {
    return _.flow([
      () => initPrivateReqMt4(apiUrl, token, RESOURCE_PATH),
      (req) => appendResourceId(groupId, req),
      (req) => getAsOne(req),
      (req) => ngPerformMongo(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  getGroups(apiUrl: string, token: string): Promise<Mt4Group[]> {
    return _.flow([
      () => initRequestDomainResource(apiUrl, RESOURCE_PATH),
      (req) => setTokenHeader(token, req),
      (req) => getAsList(req),
      (req) => ngPerformMongo<Mt4Group[]>(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  getGroupAsset(
    apiUrl: string,
    token: string,
    groupId: number,
    assetId: number,
  ) {
    return _.flow([
      () => initPrivateReqMt4(apiUrl, token, RESOURCE_PATH),
      (req) => appendResourceId(groupId, req),
      (req) => appendResource(MT_ASSETS_PATH, req),
      (req) => appendResourceId(assetId, req),
      (req) => getAsOne(req),
      (req) => ngPerformMongo(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  getGroupSecurity(
    apiUrl: string,
    token: string,
    groupId: number,
    securityId: number,
  ) {
    return _.flow([
      () => initPrivateReqMt4(apiUrl, token, RESOURCE_PATH),
      (req) => appendResourceId(groupId, req),
      (req) => appendResource(MT_GROUP_SECURITIES_PATH, req),
      (req) => appendResourceId(securityId, req),
      (req) => getAsOne(req),
      (req) => ngPerformMongo(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  updateGroup(
    groupId: number,
    updates: Partial<Mt4Group>,
    apiUrl: string,
    token: string,
  ) {
    return _.flow([
      () => initReq(RESOURCE_PATH, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => appendResourceId(groupId, req),
      (req) => setPayload(updates, req),
      (req) => genReqUpdate(req),
      (req) => ngPerformMongo(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  updateGroupAsset(
    groupId: number,
    assetId: number,
    updates: Partial<MtGroupAsset>,
    apiUrl: string,
    token: string,
  ) {
    return _.flow([
      () => initReq(RESOURCE_PATH, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => appendResourceId(groupId, req),
      (req) => appendResource(MT_ASSETS_PATH, req),
      (req) => appendResourceId(assetId, req),
      (req) => setPayload(updates, req),
      (req) => genReqUpdate(req),
      (req) => ngPerformMongo(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }

  updateGroupSecurity(
    groupId: number,
    securityId: number,
    updates: Partial<any>,
    apiUrl: string,
    token: string,
  ) {
    return _.flow([
      () => initReq(RESOURCE_PATH, apiUrl),
      (req) => setTokenHeader(token, req),
      (req) => appendResourceId(groupId, req),
      (req) => appendResource(MT_GROUP_SECURITIES_PATH, req),
      (req) => appendResourceId(securityId, req),
      (req) => setPayload(updates, req),
      (req) => genReqUpdate(req),
      (req) => ngPerformMongo(this.$http, req),
      (req) => req.then((resp) => resp.data),
    ])();
  }
}
