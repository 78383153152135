import ng from 'angular';

import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';

import BaseController from '~/source/common/controllers/base';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import Questionnaire from '~/source/common/models/questionnaire';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import QuestionnaireService from './questionnaire.service';
import { LanguagesService } from '~/source/common/services/languages';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { Language } from '@proftit/crm.api.models.entities';

export enum QuestionnaireUserAction {
  CopyForm = 'copyForm',
  AddLang = 'addLang',
  Edit = 'edit',
}

abstract class QuestionnaireAbstractController extends BaseController {
  static $inject = [
    '$scope',
    'questionnaireModuleSettings',
    'questionnaireService',
    'modelNormalizer',
    '$state',
    '$location',
    'languagesService',
  ];

  QuestionnaireUserAction = QuestionnaireUserAction;
  lifecycles = observeComponentLifecycles(this);

  questionnaireModuleSettings: any;
  $state: ng.ui.IStateService;
  $location: ng.ILocationService;
  modelNormalizer: ModelNormalizerService;
  questionnaireService: () => QuestionnaireService;
  languagesService: () => LanguagesService;

  settings: any;
  formFields: any;
  fieldTypeMap: any;
  model: IElementRestNg<Questionnaire> | Partial<Questionnaire>;
  originalModelFromServer:
    | IElementRestNg<Questionnaire>
    | Partial<Questionnaire>;

  onQuestionnaireModelInitAction = new rx.Subject<void>();
  onQuestionnaireModelLanguagePreselectAction = new rx.Subject<void>();
  modelChangeAction = new rx.Subject<void>();
  hasModelChangedFromManualUpdateAction = new rx.Subject<boolean>();

  questionnaireLanguageFormControl = new FormControl<any>(null);
  isQuestionnaireActiveFormControl = new FormControl<boolean>(null);
  userActionFromQueryStringBS$ = new rx.BehaviorSubject<
    QuestionnaireUserAction
  >(null);

  languages$ = this.streamLanguages();
  hasModelChanged$ = this.streamModelChanged();
  langCode2ToUse$ = this.streamLangCode2ToUse();
  userActionFromQueryString$ = this.streamUserActionFromQueryString();
  isBaseQuestionnaire$ = this.streamIsBaseQuestionnaire();

  $onInit() {
    useStreams(
      [
        this.questionnaireLanguageFormControl.value$,
        this.isQuestionnaireActiveFormControl.value$,
        this.languages$,
        this.streamCopyModel(),
        this.streamPreselectedLanguage(),
        this.streamInitQuestionnaireModel(),
        this.streamUpdateModelIsActive(),
        this.streamLanguageModelChange(),
        this.langCode2ToUse$,
        this.isBaseQuestionnaire$,
        this.userActionFromQueryString$,
        this.streamAlterModelFromUserAction(),
        this.userActionFromQueryStringBS$,
      ],
      this.lifecycles.onDestroy$,
    );

    Object.assign(this, {
      settings: Object.assign({}, this.questionnaireModuleSettings),
      formFields: this.questionnaireModuleSettings.formFields,
      fieldTypeMap: this.questionnaireModuleSettings.fieldTypeMap,
    });
  }

  $onDestroy() {}

  streamUserActionFromQueryString(): rx.Observable<QuestionnaireUserAction> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => this.$location.search().action as QuestionnaireUserAction),
      rx.tap((val: QuestionnaireUserAction) => {
        this.userActionFromQueryStringBS$.next(val);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAlterModelFromUserAction() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.onQuestionnaireModelInitAction,
          this.userActionFromQueryString$,
        ),
      rx.filter(([a, action]) => !_.isNil(action)),
      rx.tap(([a, action]) => {
        let newData = null;
        if (action === QuestionnaireUserAction.CopyForm) {
          newData = {
            ...this.model,
            brand: null,
            name: null,
            isActive: false,
          };
        } else if (action === QuestionnaireUserAction.AddLang) {
          newData = {
            ...this.model,
            isActive: false,
          };
        }
        if (!_.isNil(newData)) {
          this.initQuestionnaire(newData);
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsBaseQuestionnaire() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.onQuestionnaireModelInitAction,
          this.langCode2ToUse$,
          this.userActionFromQueryString$,
        ),
      rx.map(
        ([a, langCode2, userAction]: [
          any,
          string,
          QuestionnaireUserAction,
        ]) => {
          if (userAction === QuestionnaireUserAction.CopyForm) {
            return true;
          }
          if (userAction === QuestionnaireUserAction.AddLang) {
            return false;
          }
          // if the model does not have any languages, this means we are in create mode, in that case, its always considered a base questionnaire.
          if (_.isNil(this.model.languages)) {
            return true;
          }
          const baseLanguageEntity = this.model.languages.find(
            (l) => l.isDefault,
          );
          if (_.isNil(baseLanguageEntity)) {
            return false;
          }
          return baseLanguageEntity.language.code2 === langCode2;
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamLangCode2ToUse() {
    return rx.pipe(
      () => this.languages$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((langs: Language[]) => {
        const { langId } = this.$location.search();
        const langToUse = langs.find((l) => l.id === Number(langId));
        return !_.isNil(langToUse) ? langToUse.code2 : null;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamInitQuestionnaireModel() {
    return rx.pipe(
      () => this.langCode2ToUse$,
      rx.switchMap((langCode2: string) => {
        return this.initQuestionnaireModel(langCode2).then((data) => {
          this.initQuestionnaire(data);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  initQuestionnaire(data) {
    if (_.isEqual(data, this.model)) {
      return;
    }
    this.model = _.cloneDeep(data);
    this.isQuestionnaireActiveFormControl.setValue(this.model.isActive);
    this.onQuestionnaireModelInitAction.next();
  }

  streamUpdateModelIsActive() {
    return rx.pipe(
      () => this.isQuestionnaireActiveFormControl.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((newVal: boolean) => {
        this.model.isActive = newVal;
        this.modelChangeAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamModelChanged() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamModelChangedFromModelChange(),
          this.streamModelChangedFromManualUpdate(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamModelChangedFromManualUpdate() {
    return rx.pipe(
      () => this.hasModelChangedFromManualUpdateAction,
      shareReplayRefOne(),
    )(null);
  }

  streamModelChangedFromModelChange() {
    return rx.pipe(
      () => this.modelChangeAction,
      rx.map(() => !_.isEqual(this.model, this.originalModelFromServer)),
      shareReplayRefOne(),
    )(null);
  }

  streamLanguages() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(this.languagesService().getListWithQuery())
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.map((langs: any[]) => {
        return langs.map((l) => {
          return {
            ...l.plain(),
            label: l.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCopyModel() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.onQuestionnaireModelInitAction,
          this.onQuestionnaireModelLanguagePreselectAction,
        ),
      rx.tap(() => {
        this.originalModelFromServer = _.cloneDeep(this.model);
        this.modelChangeAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamPreselectedLanguage() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.onQuestionnaireModelInitAction,
          this.languages$,
        ),
      rx.map(([a, langs]) => {
        const { langId } = this.$location.search();
        if (_.isNil(langId)) {
          return null;
        }
        const languageToUse = langs.find((l) => l.id === Number(langId));
        if (_.isNil(languageToUse)) {
          return null;
        }
        return languageToUse;
      }),
      rx.filter((x) => !_.isNil(x)),
      rx.tap((lang) => {
        this.questionnaireLanguageFormControl.setValue(lang);
        this.model.language = lang;
        this.onQuestionnaireModelLanguagePreselectAction.next();
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamLanguageModelChange() {
    return rx.pipe(
      () => this.questionnaireLanguageFormControl.value$,
      rx.filter((x) => !_.isNil(x)),
      rx.tap((lang) => {
        this.modelChangeAction.next();
        this.model.language = lang;
      }),
      shareReplayRefOne(),
    )(null);
  }

  abstract initQuestionnaireModel(
    langCode: string,
  ): Promise<IElementRestNg<Questionnaire> | Partial<Questionnaire>>;

  /**
   * called when a new component is dropped inside the list, a new element will be added to the form.
   *
   * the callback is called by the the angular-drag-and-drop-lists library
   * for more information look for dnd-drop on https://github.com/marceljuenemann/angular-drag-and-drop-lists
   *
   * @param index
   * @param item
   * @param external
   * @param type
   * @return {Object} returns modified dragged element object
   */
  onFormDrop(index, item, external, type) {
    this.modelChangeAction.next();

    // return item. the dnd plugin will add it the the list
    return item;
  }

  onItemMoved(index, item) {
    this.model.components.splice(index, 1);
    this.modelChangeAction.next();
  }

  /**
   * called when a form element is dropped outside the list, the item will be removed
   *
   * the callback is called by the the angular-drag-and-drop-lists library
   * for more information look for dnd-drop on https://github.com/marceljuenemann/angular-drag-and-drop-lists
   *
   * @param index
   * @param item
   * @param external
   * @param type
   * @return {boolean} return true means that drop is allowed to this element.
   */
  onOutsideDrop(index, item, external, type) {
    /*
     * drop is allowed on this element
     * if element source dnd-effect-allowed="move" the element will be
     * removed from source list by the plugin
     */
    this.modelChangeAction.next();
    return true;
  }

  /**
   * normalize form components for save/update action
   * @param {Array} components returns form components array with order property for each element
   */
  normalizeComponents(components) {
    return components.map((component, index) => {
      const componentClone = _.cloneDeep(component);
      if (!_.isNil(componentClone.options)) {
        const optionsWithOrder = componentClone.options.map((option, index) => {
          return {
            ...option,
            order: index,
          };
        });
        componentClone.options = optionsWithOrder;
      }
      return {
        ...componentClone,
        order: index,
      };
    });
  }

  /**
   * remove form element by clicking on remove icon
   * @param {Number} index
   */
  remove(index) {
    this.model.components.splice(index, 1);
    this.modelChangeAction.next();
  }

  /**
   * calculate pagination values for components page number separation
   * @return {{showPage: function(*): boolean, pageNumber: function(*): number, totalPages: function(): number}}
   */
  get pagination() {
    return {
      showPage: (index) => index % 4 === 0,
      pageNumber: (index) => Math.ceil((index + 4) / 4),
      totalPages: () => Math.ceil(this.model.components.length / 4),
    };
  }
}

export default QuestionnaireAbstractController;
