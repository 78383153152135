import BaseService from '~/source/common/services/baseService';
import calcAsCurrencyUnitAmount from '~/source/common/utilities/calc-as-currency-unit-amount';

export interface WithdrawalValidationError {
  errorMessage: string;
  errorType: string;
  args: {
    withdrawalAmount: number;
    limitValue: number;
    limitType: string;
  };
}

export class WithdrawalValidationService extends BaseService {
  /**
   * return error message if exists
   * @returns {Array.<Object>} returns validation error information
   */
  getErrors(
    withdrawalAmount,
    account,
    withdrawalRequest,
  ): WithdrawalValidationError[] {
    const errors = [];
    const errorMessage =
      'withdrawal.errors.WITHDRAWAL_AMOUNT_HIGHER_THAN_WITHDRAWALBLE_ERROR';

    const { balance, withdrawable } = account;
    const { amountRequested, amountApproved } = withdrawalRequest;
    const decimals = account.currency.decimals;

    const unitAmountRequested = calcAsCurrencyUnitAmount(
      amountRequested,
      decimals,
    );
    const unitAmountApproved = calcAsCurrencyUnitAmount(
      amountApproved,
      decimals,
    );
    const unitWithdrawalAmount = calcAsCurrencyUnitAmount(
      withdrawalAmount,
      decimals,
    );
    const unitBalance = calcAsCurrencyUnitAmount(balance, decimals);
    const unitWithdrawable = calcAsCurrencyUnitAmount(withdrawable, decimals);

    // total withdrawal can't be greater than account balance
    if (unitWithdrawalAmount > unitBalance) {
      errors.push({
        errorMessage,
        errorType: 'ERROR',
        args: {
          withdrawalAmount,
          limitValue: balance,
          limitType: 'withdrawal.errorTypes.BALANCE',
        },
      });
    }

    // total withdrawal can't be greater than requested amount left
    if (unitWithdrawalAmount > unitAmountRequested - unitAmountApproved) {
      errors.push({
        errorMessage,
        errorType: 'ERROR',
        args: {
          withdrawalAmount,
          limitValue: amountRequested - amountApproved,
          limitType: 'withdrawal.errorTypes.AMOUNT_REQUESTED_LEFT',
        },
      });
    }

    // total withdrawal can't be greater than amount requested
    if (unitWithdrawalAmount > unitAmountRequested) {
      errors.push({
        errorMessage,
        errorType: 'ERROR',
        args: {
          withdrawalAmount,
          limitValue: amountRequested,
          limitType: 'withdrawal.errorTypes.AMOUNT_REQUESTED',
        },
      });
    }

    // show a warning message when total withdrawal is greater than withdrawable amount
    if (unitWithdrawalAmount > unitWithdrawable) {
      errors.push({
        errorMessage,
        errorType: 'WARNING',
        args: {
          withdrawalAmount,
          limitValue: withdrawable,
          limitType: 'withdrawal.errorTypes.WITHDRAWABLE',
        },
      });
    }

    return errors;
  }
}

export default WithdrawalValidationService;
