import * as _ from '@proftit/lodash';
import ng, { IHttpService } from 'angular';

import { IRestServiceInstance } from '../services/rest';

import {
  performRequest as ngHttpPerformRequest,
  JsonServerRequest,
  slice as jsonSrvSlice,
  setFilters as jsSetFilter,
  setSorts as jsSetSorts,
  getAsList,
  jsonServerToCfdMongo,
  cfdMongoToNgHttp,
} from '@proftit/request-client';

export class CfdMongoRestAdapter implements IRestServiceInstance {
  blockUiRef: string;
  growlRef: string = 'restService';

  total = 0;

  constructor(
    readonly $http: IHttpService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    private request: JsonServerRequest,
    readonly searchFieldName: string,
  ) {}

  setConfig({ blockUiRef, growlRef }) {
    if (!_.isUndefined(blockUiRef)) {
      this.blockUiRef = blockUiRef;
    }

    if (!_.isUndefined(growlRef)) {
      this.growlRef = growlRef;
    }

    return this;
  }

  embed(...args) {
    return this;
  }

  cacheEmpty() {}

  filter(filters) {
    this.request = jsSetFilter(filters, this.request) as any;
    return this;
  }

  sort(sorts) {
    this.request = jsSetSorts(sorts, this.request);
    return this;
  }

  slice(start: number, end: number, limit: number) {
    this.request = jsonSrvSlice(start, end, limit, this.request);
    return this;
  }

  getListWithQuery() {
    return _.flow([
      () => this.startBlockUi(),
      () => this.request,
      (reqModel) => getAsList(reqModel),
      (reqModel) => jsonServerToCfdMongo(this.searchFieldName, reqModel),
      (reqModel) => cfdMongoToNgHttp(reqModel),
      _.tap(() => {
        this.growlMessages.destroyAllMessages(<any>this.growlRef);
      }),
      (ngHttpRequestModel) =>
        ngHttpPerformRequest(this.$http, ngHttpRequestModel),
      _.tap((httpReq) =>
        httpReq.then((resp) => this.setTotalFromResponse(resp)),
      ),
      _.tap((httpReq) =>
        httpReq.then(
          () => {
            this.stopBlockUi();
          },
          () => {
            this.stopBlockUi();
            const errorMessage = 'error in query';
            this.growl.error(errorMessage, { referenceId: <any>this.growlRef });
          },
        ),
      ),
      (httpReq) => httpReq.then((resp) => resp.data),
    ])();
  }

  setTotalFromResponse(resp) {
    this.total = parseInt(resp.headers('x-total-count'), 10);
  }

  startBlockUi() {
    if (_.isNil(this.blockUiRef)) {
      return null;
    }

    const blockUiInstance = this.blockUI.instances.get(this.blockUiRef);

    if (_.isNil(blockUiInstance)) {
      return null;
    }

    blockUiInstance.start();
    return null;
  }

  stopBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.blockUiRef);
    if (_.isNil(blockUiInstance)) {
      return;
    }

    blockUiInstance.stop();
    return null;
  }
}
