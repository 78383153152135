export const customerTradingAccountsAuditLogsTableColumns = [
  {
    title: 'customerTradingAccountsAuditLogsTable.TRADING_ACCOUNT_ID',
    field: '',
    fieldFormat: '{{ modelId }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.DATE',
    field: '',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.FIELD',
    field: '',
    fieldFormat: '{{ presentationFieldName }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
