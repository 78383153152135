import ng from 'angular';

const styles = require('./cfd-platform-asset-form.component.scss');
import BaseController from '~/source/common/controllers/base';
import { CfdPlatformAsset } from '~/source/common/models/cfd-platform/cfd-platform-asset';
import * as rx from '@proftit/rxjs';
import template from './cfd-platform-asset-form.component.html';
import { useStream } from '~/source/common/utilities/use-stream';
import {
  createNgModelObsMediator,
  NgModelChange,
} from '~/source/common/utilities/create-ng-model-obs-mediator';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import { definePropSwapRatioAsPercentage } from '~/source/management/integrations/risk-manager/utilities/define-prop-swap-ratio-as-percentage';

export interface CfdPlatformAssetFormControllerBindings {
  asset: CfdPlatformAsset;
  fixedSpread: number;
  onChangeOfAsset: (a: { change: NgModelChange }) => {};
}

export class CfdPlatformAssetFormController extends BaseController
  implements CfdPlatformAssetFormControllerBindings {
  styles = styles;
  /* Bindings */
  asset: CfdPlatformAsset;
  fixedSpread: number;
  onChangeOfAsset: (a: { change: NgModelChange }) => {};
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  unsub$ = new rx.Subject<void>();
  assetIn$ = new rx.BehaviorSubject<CfdPlatformAsset>(null);
  assetUpdatesOut$ = new rx.Subject<NgModelChange>();
  assetModel = {};
  platformAssetForm;
  minInvestmentFormControl = new FormControl<number>(null);
  maxInvestmentFormControl = new FormControl<number>(null);
  minInvestmentUpdate$ = this.streamMinInvestmentForUpdate();
  maxInvestmentUpdate$ = this.streamMaxInvestmentForUpdate();

  /*@ngInject */
  constructor(readonly $validation) {
    super();
  }

  $onInit() {
    this.assetModel = createNgModelObsMediator(
      ['name', 'precision', 'minMove', 'spread', 'leverage', 'swapCommission'],
      this.assetIn$,
      this.assetUpdatesOut$,
    );

    definePropSwapRatioAsPercentage(
      this.assetModel,
      this.assetIn$,
      this.assetUpdatesOut$,
    );

    useStream(this.streamModelOut(), this.unsub$);
    useStream(this.streamFormValidOut(), this.unsub$);
    useStreams(
      [
        this.streamMinInvestment(),
        this.streamMaxInvestment(),
        this.minInvestmentFormControl.value$,
        this.maxInvestmentFormControl.value$,
      ],
      this.unsub$,
    );
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onAssetChange(newAsset: CfdPlatformAsset) {
    this.assetIn$.next(newAsset);
  }

  streamMinInvestment() {
    return rx.pipe(
      () => this.assetIn$,
      rx.filter((asset) => !_.isNil(asset)),
      rx.tap((asset) => {
        this.minInvestmentFormControl.setValueAsFirst(asset.minInvestment);
      }),
    )(null);
  }

  streamMaxInvestment() {
    return rx.pipe(
      () => this.assetIn$,
      rx.filter((asset) => !_.isNil(asset)),
      rx.tap((asset) => {
        this.maxInvestmentFormControl.setValueAsFirst(asset.maxInvestment);
      }),
    )(null);
  }

  streamMinInvestmentForUpdate() {
    return rx.pipe(
      () =>
        this.minInvestmentFormControl.value$.pipe(rx.distinctUntilChanged()),
      rx.withLatestFrom(this.minInvestmentFormControl.isAtFirstValue$),
      rx.filter(([minInvestment, isAtFirstValue]) => !isAtFirstValue),
      rx.map(([minInvestment]) => {
        return {
          fieldName: 'minInvestment',
          nextValue: minInvestment,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamMaxInvestmentForUpdate() {
    return rx.pipe(
      () =>
        this.maxInvestmentFormControl.value$.pipe(rx.distinctUntilChanged()),
      rx.withLatestFrom(this.maxInvestmentFormControl.isAtFirstValue$),
      rx.filter(([maxInvestment, isAtFirstValue]) => !isAtFirstValue),
      rx.map(([maxInvestment]) => {
        return {
          fieldName: 'maxInvestment',
          nextValue: maxInvestment,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamModelOut() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.assetUpdatesOut$,
          this.minInvestmentUpdate$,
          this.maxInvestmentUpdate$,
        ),
      rx.tap((change) => this.onChangeOfAsset({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.assetUpdatesOut$,
      rx.debounceTime(150),
      rx.map(() => this.$validation.checkValid(this.platformAssetForm)),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }
}

export const CfdPlatformAssetFormComponent = {
  template,
  controller: CfdPlatformAssetFormController,
  bindings: {
    asset: '<',
    fixedSpread: '<',
    shouldShowSwapCommission: '<',
    onChangeOfAsset: '&',
    onChangeOfFormValidity: '&',
  },
};

export default CfdPlatformAssetFormComponent;
