import template from './design-template-form.component.html';
const styles = require('./design-template-form.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { createObsGetterSetter } from '~/source/common/utilities/create-obs-getter-setter';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import { getCompChange } from '~/source/common/utilities/rx-ng-one/operators/get-comp-change';
import { DesignTemplate } from '@proftit/crm.api.models.entities';
import { wrapAsChange } from '~/source/common/utilities/wrap-as-change';

interface ModelChange {
  fieldName: string;
  nextValue: any;
}

export class DesignTemplateFormController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  model;
  onChangeOfModel: (a: { change: ModelChange }) => void;
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  designTemplateForm;
  local = {};
  name$ = createObsGetterSetter<string>('name', this.local);
  content$ = createObsGetterSetter<string>('content', this.local);
  modelChangeOut$ = new rx.Subject<ModelChange>();

  /*@ngInject */
  constructor(readonly $validation) {}

  $onInit() {
    useStreams(
      [
        this.streamModelToForm(),
        this.streamFormChangesOut(),
        this.streamFormValidOut(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {}

  $onChanges() {}

  streamModelToForm() {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      getCompChange<DesignTemplate>('model'),
      rx.tap((model) => {
        this.name$.next(model.name);
        this.content$.next(model.content);
      }),
    )(null);
  }

  streamFormChangesOut() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.name$.pipe(rx.distinctUntilChanged(), wrapAsChange('name')),
          this.content$.pipe(
            rx.distinctUntilChanged(),
            wrapAsChange('content'),
          ),
        ),
      rx.tap((change) => this.modelChangeOut$.next(change)),
      rx.tap((change) => this.onChangeOfModel({ change })),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.modelChangeOut$,
      rx.debounceTime(150),
      rx.map(() => this.$validation.checkValid(this.designTemplateForm)),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }
}

export const DesignTemplateFormComponent = {
  template,
  controller: DesignTemplateFormController,
  bindings: {
    model: '<',
    onChangeOfModel: '&',
    onChangeOfFormValidity: '&',
  },
};
