import ng from 'angular';
import log from 'loglevel';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';

import template from './cfd-platform-brand-config-edit-dialog.component.html';
import { CfdPlatformBrandConfig } from '~/source/common/models/cfd-platform/cfd-platform-brand-config';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { CfdPlatformBrandConfigsService } from '../../services/cfd-platform-brand-configs.service';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { init as initChangeset } from '~/source/common/utilities/changeset/init';
import { Changeset } from '~/source/common/utilities/changeset/changeset';
import { NgModelChange } from '~/source/common/utilities/create-ng-model-obs-mediator';
import { setFieldUpdate } from '~/source/common/utilities/changeset/set-field-update';
import { resetFieldUpdate } from '~/source/common/utilities/changeset/reset-field-update';
import { PLATFORM_BRAND_CONFIG_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { PlatformBrand } from '~/source/common/models/cfd-platform/platform-brand';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  PlatformsWithSwapAbility,
  PlatformCode,
} from '@proftit/crm.api.models.enums';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';
import { CurrentTrcGroupsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-groups-of-platform-service.directive';
import { GroupAssetsStoreServiceDirectiveController } from '~/source/common/service-directives/group-assets-store-service.directive';

export interface ICfdPlatformBrandConfigEditComponent {
  operation: string;
  platformBrand: PlatformBrand;
}

export class CfdPlatformBrandConfigEditDialogController {
  close: () => void;

  resolve: ICfdPlatformBrandConfigEditComponent;

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  prfCurrentTrcGroupsOfPlatform$ = new rx.BehaviorSubject<
    CurrentTrcGroupsOfPlatformServiceDirectiveController
  >(null);

  prfGroupAssets$ = new rx.BehaviorSubject<
    GroupAssetsStoreServiceDirectiveController
  >(null);

  lifecycles = observeComponentLifecycles(this);
  changeAssetsFilter$ = this.streamChangeAssetsFilter();

  title = 'riskManager.BRAND_CONFIG_SETTINGS';
  blockUiId = 'cfdPlatformBrandConfigEditDialogBlockUi';
  growlId = 'cfdPlatformBrandConfigEditDialogGrowl';

  resolve$ = observeShareCompChange<ICfdPlatformBrandConfigEditComponent>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  operation$ = this.resolve$.pipe(
    rx.map(({ operation }) => operation),
    shareReplayRefOne(),
  );

  platformBrand$ = this.resolve$.pipe(
    rx.map(({ platformBrand }) => platformBrand),
    shareReplayRefOne(),
  );

  brandConfig$ = new rx.BehaviorSubject<CfdPlatformBrandConfig>(null);
  changeset$ = new rx.BehaviorSubject<Changeset<CfdPlatformBrandConfig>>(null);
  opUpdateUiBrandConfig$ = new rx.Subject<NgModelChange>();
  doOperation$ = new rx.Subject<void>();
  isFormValid$ = new rx.BehaviorSubject<boolean>(true);
  showSwapCommission$ = new rx.BehaviorSubject<boolean>(false);
  isConfirmButtonDisabled$ = this.streamIsConfirmButtomDisabled();

  initScrean$ = this.streamInitScreen();

  isLoading$ = this.streamIsLoading();

  /*@ngInject */
  constructor(
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly prfCfdPlatformBrandConfigsService: CfdPlatformBrandConfigsService,
  ) {
    useStreams(
      [this.resolve$, this.operation$, this.platformBrand$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamSession(),
        this.initScrean$,
        this.streamUiBrandConfigUpdating(),
        this.streamDoOperation(),
        this.streamShowSwapCommission(),
        this.changeAssetsFilter$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  streamSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.platformBrand$,
          this.prfCurrentPlatformSession$,
        ),
      rx.filter((params) => params.every((p) => !_.isNil(p))),
      rx.switchMap(([platformBrand, sessionStore]) => {
        return sessionStore.login(platformBrand._crmPlatformConnection);
      }),
    )(null);
  }

  streamInitScreen() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.operation$,
          this.platformBrand$,
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((store) => !_.isNil(store)),
            rx.switchMap((store) => store.sessionS.stream$),
          ),
        ),
      this.tapStartAsyncWorkInUiMain(),
      rx.switchMap(([operation, platformBrand, sessionInfo]) =>
        this.initObject(operation, sessionInfo, platformBrand.id),
      ),
      this.tapStopAsyncWorkInUiMain(),
      rx.catchError((err, caught$) => {
        log.error('error in object init', err);
        return rx.obs.EMPTY;
      }),
      rx.tap((brandConfig: CfdPlatformBrandConfig) => {
        this.brandConfig$.next(brandConfig);
        this.changeset$.next(initChangeset(brandConfig));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUiBrandConfigUpdating() {
    return rx.pipe(
      () => this.opUpdateUiBrandConfig$,
      rx.withLatestFrom(this.changeset$, this.brandConfig$),
      rx.map(([change, changeset, brandConfig]) =>
        this.calcChangeset(change, changeset, brandConfig),
      ),
      rx.tap(([asset, changeset]) => {
        this.brandConfig$.next(asset as CfdPlatformBrandConfig);
        this.changeset$.next(changeset as Changeset<CfdPlatformBrandConfig>);
      }),
    )(null);
  }

  streamDoOperation() {
    return rx.pipe(
      () => this.doOperation$,
      rx.withLatestFrom(
        this.operation$,
        this.brandConfig$,
        this.changeset$,
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((service) => !_.isNil(service)),
          rx.switchMap((service) => service.sessionS.stream$),
        ),
      ),
      this.tapStartAsyncWorkInUiMain(),
      rx.mergeMap(([_doOp, operation, _asset, changeset, sessionInfo]) => {
        if (operation === 'update') {
          return this.updateBrandConfig(changeset, sessionInfo);
        }

        throw new Error('not implemented');
      }),
      this.tapStopAsyncWorkInUiMain(),
      rx.tap(() =>
        this.prfClientGeneralPubsub.publish(
          PLATFORM_BRAND_CONFIG_UPDATED,
          null,
        ),
      ),
      rx.tap(() => this.close()),
      rx.catchError((err) => rx.obs.EMPTY),
    )(null);
  }

  /**
   * sTream show swap commission.
   * @return stream
   */
  streamShowSwapCommission() {
    return rx.pipe(
      () =>
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((store) => !_.isNil(store)),
          rx.switchMap((store) => store.sessionS.stream$),
        ),
      rx.map((sessionInfo) =>
        PlatformsWithSwapAbility.includes(
          sessionInfo.platform.code as PlatformCode,
        ),
      ),
      rx.tap((show) => this.showSwapCommission$.next(show)),
    )(null);
  }

  streamIsConfirmButtomDisabled() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.isFormValid$, this.changeset$),
      rx.map(([isFormValid, changeset]) => {
        if (!isFormValid) {
          return true;
        }

        if (_.isNil(changeset)) {
          return true;
        }

        return changeset.changes.length === 0;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamChangeAssetsFilter() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.prfCurrentTrcGroupsOfPlatform$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((service) => service.generalGroupsS.stream$),
            rx.filter((groups) => groups.length > 0),
            rx.take(1),
          ),
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((x) => !_.isNil(x)),
            rx.switchMap((service) => service.sessionS.stream$),
            rx.filter((session) => session.isLoggedIn),
          ),
          this.prfGroupAssets$.pipe(rx.filter((x) => !_.isNil(x))),
        ]),
      rx.delay(0),
      rx.tap(([groups, platformSession, groupAssets]) => {
        groupAssets.filter(groups[0].id, {});
      }),
    )(null);
  }

  streamIsLoading() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.initScrean$.pipe(
            rx.map(() => false),
            rx.startWith(true),
          ),
        ),
      rx.map((conds) => conds.some((c) => c)),
      shareReplayRefOne(),
    )(null);
  }

  tapStartAsyncWorkInUiMain() {
    return tapStartAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  tapStopAsyncWorkInUiMain() {
    return tapStopAsyncWorkInUi(
      this.blockUI,
      this.growl,
      this.growlMessages,
      this.blockUiId,
      this.growlId,
    );
  }

  updateBrandConfig(
    changeset: Changeset<CfdPlatformBrandConfig>,
    sessionInfo: PlatformSessionInfo,
  ) {
    const updates: any = changeset.changes.reduce((acc, change) => {
      return {
        ...acc,
        [change.fieldName]: change.nextValue,
      };
    }, {});

    if (!_.isNil(updates.asset?.value)) {
      updates.defaultAssetId = updates.asset.value;
      delete updates.asset;
    }

    const req = this.prfCfdPlatformBrandConfigsService.initPrivateReq(
      sessionInfo.session.apiUrl,
      sessionInfo.session.token,
      sessionInfo.platform,
    );

    return this.prfCfdPlatformBrandConfigsService.update(
      changeset.original.brand.id,
      updates,
      req,
    );
  }

  calcChangeset(change, changeset, brandConfig) {
    const newChangeset = (function () {
      const origValue = _.defaultTo(null, changeset.original[change.fieldName]);

      if (origValue === change.nextValue) {
        return resetFieldUpdate(change.fieldName, changeset);
      }

      return setFieldUpdate(change.fieldName, change.nextValue, changeset);
    })();

    const newBrandConfig = {
      ...brandConfig,
      [change.fieldName]: change.nextValue,
    };

    return [newBrandConfig, newChangeset];
  }

  initObject(
    operation: string,
    sessionInfo: PlatformSessionInfo,
    platformBrandId: number,
  ) {
    if ([operation, sessionInfo].some((x) => _.isNil(x))) {
      return rx.obs.EMPTY;
    }

    if (operation === 'update') {
      const req = this.prfCfdPlatformBrandConfigsService.initPrivateReq(
        sessionInfo.session.apiUrl,
        sessionInfo.session.token,
        sessionInfo.platform,
      );

      return rx.obs
        .from(
          this.prfCfdPlatformBrandConfigsService.getAsOne(platformBrandId, req),
        )
        .pipe(
          rx.map((config: CfdPlatformBrandConfig) => ({
            ...config,
            asset: config.defaultAssetId
              ? { value: config.defaultAssetId, label: config.defaultAssetName }
              : null,
          })),
        );
    }

    throw new Error('not implemented');
  }

  setCurrentPlatformSession(
    currentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController,
  ) {
    this.prfCurrentPlatformSession$.next(currentPlatformSession);
  }
}

export const CfdPlatformBrandConfigEditDialogComponent = {
  template,
  controller: CfdPlatformBrandConfigEditDialogController,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};

export default CfdPlatformBrandConfigEditDialogComponent;
