import log from 'loglevel';
import type { IParseService, IScope } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { PlatformSessionInfo } from './platform-session-info';
import { directivesPriorities } from '../constants/directives-priorities';
import { ApiTrcBrandsService } from '../api-cfd-platform/api-trc-brands.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ApiCfdAssetsService } from '../api-cfd-platform/api-cfd-assets.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';

interface NgSelfAttrs {
  normalize(name: string): string;
  $addClass(classVal: string): void;
  $removeClass(classVal: string): void;
  $updateClass(newClasses: string, oldClasses: string): void;
  $observe(key: string, fn: Function): void;
  $set(name: string, value: string): void;
  $attr: Record<string, string>;
}

interface PipDigitsNgAttrs extends NgSelfAttrs {
  prfPipDigits: string;
}

export class PipDigitsStoreServiceDirectiveController {
  lifecycles = observeComponentLifecycles(this);

  load$ = new rx.Subject<void>();
  filterChanged$ = new rx.Subject();
  pipDigits$;
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  inProgress$ = new rx.BehaviorSubject<boolean>(false);

  /* @ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $parse: IParseService,
    readonly $attrs: PipDigitsNgAttrs,
    readonly prfApiCfdAssetsService: ApiCfdAssetsService,
  ) {}

  $onInit() {
    this.pipDigits$ = this.streamPipDigits();

    useStreams(
      [this.pipDigits$, this.prfCurrentPlatformSession.sessionS.stream$],
      this.lifecycles.onDestroy$,
    );

    const mainAttrExpFn = this.$parse(this.$attrs.prfPipDigits);
    const newScope: any = this.$scope.$new(false, this.$scope);
    newScope.service = this;
    mainAttrExpFn(newScope);
    newScope.$destroy();
  }

  $onChanges() {}

  $onDestroy() {}

  streamPipDigits() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.load$,
          this.filterChanged$.pipe(rx.startWith([])),
        ]),
      rx.tap(() => this.inProgress$.next(true)),
      rx.delay(0),
      rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),
      rx.switchMap(([[a, filters], sessionInfo]) =>
        this.prfApiCfdAssetsService.getAssetsPipDigits(
          filters,
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        ),
      ),
      rx.tap(() => this.inProgress$.next(false)),
      rx.catchError((error) => {
        this.inProgress$.next(false);
        throw error;
      }),
      rx.startWith([]),
      shareReplayRefOne(),
    )(null);
  }

  load() {
    this.load$.next();
  }

  filter(filters) {
    this.filterChanged$.next(filters);
  }
}

export const pipDigitsStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: PipDigitsStoreServiceDirectiveController,
  };
};

pipDigitsStoreServiceDirective.$inject = [] as string[];
