import ng from 'angular';
import { react2angular } from 'react2angular';
import { automationRouting } from './automation.routing';
import { AutomationDashboardComponent } from './containers/automation-dashboard/automation-dashboard.component';
import { InactivityFeeDashboardComponent } from './containers/inactivity-fee-dashboard/inactivity-fee-dashboard.component';
import { BrandInactivityFeeFormComponent } from './components/brand-inactivity-fee-form/brand-inactivity-fee-form.component';
import { BrandInactivityFeeListComponent } from './containers/brand-inactivity-fee-list/brand-inactivity-fee-list.component';
import { BrandInactivityFeeComponent } from './containers/brand-inactivity-fee/brand-inactivity-fee.component';
import { InactivityFeeActivityLogTableComponent } from './containers/inactivity-fee-activity-log-table/inactivity-fee-activity-log-table.component';
import { InactivityFeeSettingLogsPopupTableComponent } from './containers/inactivity-fee-setting-logs-popup-table/inactivity-fee-setting-logs-popup-table.component';
import { AccountStatusDashboardComponent } from './containers/account-status-dashboard/account-status-dashboard.component';
import { BrandAccountStatusAutomationItemComponent } from './containers/brand-account-status-automation-item/brand-account-status-automation-item.component';
import { BrandDemoDepositAutomationDashboardComponent } from './containers/brand-demo-deposit-automation-dashboard/brand-demo-deposit-automation-dashboard.component';
import { BrandDemoDepositAutomationItemComponent } from './containers/brand-demo-deposit-automation-item/brand-demo-deposit-automation-item.component';
import { BrandDemoDepositAutomationAuditLogTableComponent } from './containers/brand-demo-deposit-automation-audit-log-table/brand-demo-deposit-automation-audit-log-table.component';
import { BrandIsDemoDepositActiveFormComponent } from './components/brand-is-demo-deposit-active-form/brand-is-demo-deposit-active-form.component';
import { BrandCurrenciesDemoDepositAmountFormComponent } from './components/brand-currencies-demo-deposit-amount-form/brand-currencies-demo-deposit-amount-form.component';
import { BrandDemoDepositAutomationAuditLogPopupComponent } from './containers/brand-demo-deposit-automation-audit-log-popup/brand-demo-deposit-automation-audit-log-popup.component';
import { BrandAccountStatusAutomationGeneralFormComponent } from './components/brand-account-status-automation-general-form/brand-account-status-automation-general-form.component';
import { BrandAccountStatusAutomationStatusesFormComponent } from './components/brand-account-status-automation-statuses-form/brand-account-status-automation-statuses-form.component';
import { BrandAccountStatusAutomationAuditLogTableComponent } from './containers/brand-account-status-automation-audit-log-table/brand-account-status-automation-audit-log-table.component';
import { BrandAccountStatusAutomationAuditLogPopupComponent } from './containers/brand-account-status-automation-audit-log-popup/brand-account-status-automation-audit-log-popup.component';
import { AutoAssignmentListComponent } from './containers/auto-assignment/auto-assignment-list/auto-assignment-list.component';
import { AutoAssignmentListDeskItemComponent } from './containers/auto-assignment/auto-assignment-list-desk-item/auto-assignment-list-desk-item.component';
import { AutoAssignmentAuditLogTableComponent } from './containers/auto-assignment/auto-assignment-audit-log-table/auto-assignment-audit-log-table.component';
import { AutoAssignmentAuditLogPopupComponent } from './containers/auto-assignment/auto-assignment-audit-log-popup/auto-assignment-audit-log-popup.component';
import { NewContactAssigmentManagerComponent } from './containers/auto-assignment/new-contact-assignment-manager/new-contact-assignment-manager.component';
import { AutoAssigmentDashboardComponent } from './containers/auto-assignment/auto-assigment-dashboard/auto-assigment-dashboard.component';
import { FtdAssigmentManagerComponent } from './containers/auto-assignment/ftd-assigment-manager/ftd-assigment-manager.component';
import { BrandFtdAutoAssignmentLineComponent } from './containers/auto-assignment/brand-ftd-auto-assignment-line/brand-ftd-auto-assignment-line.component';
import { FtdAssignmentAuditLogPopupComponent } from './containers/auto-assignment/ftd-assignment-audit-log-popup/ftd-assignment-audit-log-popup.component';
import { AccountStatusPermissionsDashboardComponent } from '~/source/management/automation/containers/account-status-permissions-dashboard/account-status-permissions-dashboard.compoent';
import { AccountActionsService } from '~/source/common/services/account-actions.service';
import { ReferAFriendComponent } from '~/source/management/automation/refer-a-friend/refer-a-friend.component';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { PromoCodeListComponent } from './promo-code-list/promo-code-list.component';

import { ReferAFriendComposite } from '@proftit/crm-app-rewards-configuration';
import {
  PromoCodeListComposite,
  PromoCodeComposite,
} from '@proftit/crm-app-management';

const dependencies = [];

export const AutomationModule = ng.module(
  'crm.management.automation',
  dependencies,
);

const md = AutomationModule;

AutomationModule.config(automationRouting);

AutomationModule.component(
  'prfAutomationDashboard',
  AutomationDashboardComponent,
)
  .component('prfInactivityFeeDashboard', InactivityFeeDashboardComponent)
  .component('prfBrandInactivityFeeForm', BrandInactivityFeeFormComponent)
  .component('prfBrandInactivityFeeList', BrandInactivityFeeListComponent)
  .component('prfBrandInactivityFee', BrandInactivityFeeComponent)
  .component(
    'prfInactivityFeeActivityLogTable',
    InactivityFeeActivityLogTableComponent,
  )
  .component(
    'prfInactivityFeeSettingLogsPopupTable',
    InactivityFeeSettingLogsPopupTableComponent,
  )
  .component(
    'prfAccountStatusPermissionsDashboard',
    AccountStatusPermissionsDashboardComponent,
  )
  .service('accountActionsService', AccountActionsService)
  .component('prfAccountStatusDashboard', AccountStatusDashboardComponent)
  .component(
    'prfBrandAccountStatusAutomationItem',
    BrandAccountStatusAutomationItemComponent,
  )
  .component(
    'prfBrandDemoDepositAutomationDashboard',
    BrandDemoDepositAutomationDashboardComponent,
  )
  .component(
    'prfBrandDemoDepositAutomationItem',
    BrandDemoDepositAutomationItemComponent,
  )
  .component(
    'prfBrandDemoDepositAutomationAuditLogTable',
    BrandDemoDepositAutomationAuditLogTableComponent,
  )
  .component(
    'prfBrandIsDemoDepositActiveForm',
    BrandIsDemoDepositActiveFormComponent,
  )
  .component(
    'prfBrandCurrenciesDemoDepositAmountForm',
    BrandCurrenciesDemoDepositAmountFormComponent,
  )
  .component(
    'prfBrandDemoDepositAutomationAuditLogPopup',
    BrandDemoDepositAutomationAuditLogPopupComponent,
  )
  .component(
    'prfBrandAccountStatusAutomationGeneralForm',
    BrandAccountStatusAutomationGeneralFormComponent,
  )
  .component(
    'prfBrandAccountStatusAutomationStatusesForm',
    BrandAccountStatusAutomationStatusesFormComponent,
  )
  .component(
    'prfBrandAccountStatusAutomationAuditLogTable',
    BrandAccountStatusAutomationAuditLogTableComponent,
  )

  .component(
    'prfBrandAccountStatusAutomationAuditLogPopup',
    BrandAccountStatusAutomationAuditLogPopupComponent,
  )
  .component('prfAutoAssignmentList', AutoAssignmentListComponent)
  .component(
    'prfAutoAssignmentListDeskItem',
    AutoAssignmentListDeskItemComponent,
  )
  .component(
    'prfAutoAssignmentAuditLogTable',
    AutoAssignmentAuditLogTableComponent,
  )
  .component(
    'prfAutoAssignmentAuditLogPopup',
    AutoAssignmentAuditLogPopupComponent,
  )
  .component('prfReferAFriend', ReferAFriendComponent)
  .component('prfPromoCode', PromoCodeComponent)
  .component('prfPromoCodeList', PromoCodeListComponent);

md.component('prfAutoAssignmentDashboard', AutoAssigmentDashboardComponent);
md.component(
  'prfBrandFtdAutoAssignmentLine',
  BrandFtdAutoAssignmentLineComponent,
);
md.component(
  'prfFtdAssignmentAuditLogPopup',
  FtdAssignmentAuditLogPopupComponent,
);
md.component('prfFtdAssignmentManagerBase', FtdAssigmentManagerComponent);
md.component('prfFtdAssignmentManager', {
  ...FtdAssigmentManagerComponent,
  require: {},
  controller() {},
  template: `
    <prf-ftd-assignment-manager-base
      prf-current-brand
      brand="$ctrl.brand"
    />
    `,
});
md.component(
  'prfNewContactAssignmentManager',
  NewContactAssigmentManagerComponent,
);

md.component(
  'prfReferAFriendReact',
  react2angular(ReferAFriendComposite, [
    'crmApp',
    'translationsProvider',
    'brand',
    'platform',
  ]),
);
md.component(
  'prfPromoCodeListReact',
  react2angular(PromoCodeListComposite, [
    'crmApp',
    'routeProvider',
    'translationsProvider',
    'brand',
    'platform',
  ]),
);
md.component(
  'prfPromoCodeReact',
  react2angular(PromoCodeComposite, [
    'crmApp',
    'routeProvider',
    'translationsProvider',
    'action',
    'brandId',
    'platformId',
    'promoCodeId',
    'mode',
    'customerId',
    'promoCodeId',
    'onScreenEnd',
  ]),
);
