import ng from 'angular';
import { emailsRouting } from './emails.routing';
import { EmailTemplatesDashboardComponent } from './containers/email-templates-dashboard/email-templates-dashboard.component';
import { EmailTemplatesSectionChangelogComponent } from './containers/email-templates-section-changelog/email-templates-section-changelog.component';
import { EmailTemplatesSectionTemplatesComponent } from './containers/email-templates-section-templates/email-templates-section-templates.component';
import { EmailTemplateDesignsTableComponent } from './containers/email-template-designs-table/email-template-designs-table.component';
import { EmailTemplateContentsLogsTableComponent } from './containers/email-template-contents-logs-table/email-template-contents-logs-table.component';
import { EmailTemplateDesignsLogsTableComponent } from './containers/email-template-designs-logs-table/email-template-designs-logs-table.component';
import { DesignTemplateFormComponent } from './components/design-template-form/design-template-form.component';
import { DesignTemplateItemComponent } from './containers/design-template-item/design-template-item.component';
import { DesignTemplateDialogComponent } from './containers/design-template-dialog/design-template-dialog.component';
import { ContentTemplatePageComponent } from './containers/content-template-page/content-template-page.component';
import { ContentTemplateItemComponent } from './containers/content-template-item/content-template-item.component';
import { ContentTemplateDesignFormComponent } from './components/content-template-design-form/content-template-design-form.component';
import { ContentTemplateGeneralFormComponent } from './components/content-template-general-form/content-template-general-form.component';
import { ContentTemplateEditorFormComponent } from './components/content-template-editor-form/content-template-editor-form.component';
import { TemplatePlaceholderSimpleListComponent } from './containers/template-placeholder-simple-list/template-placeholder-simple-list.component';
import { EmailsDashboardComponent } from './containers/emails-dashboard/emails-dashboard.component';
import { CustomEmailTemplatesTableComponent } from './containers/custom-email-templates-table/custom-email-templates-table.component';
import { CustomEmailTemplateGeneralFormComponent } from './components/custom-email-template-general-form/custom-email-template-general-form.component';
import { SystemEmailsComponent } from './containers/system-emails/system-emails.component';
import { SystemEmailsTriggerChooserComponent } from './containers/system-emails-trigger-chooser/system-emails-trigger-chooser.component';
import { InlineContentTemplatePreviewComponent } from './containers/inline-content-template-preview/inline-content-template-preview.component';
import { SystemTemplateGeneralFormComponent } from './components/system-template-general-form/system-template-general-form.component';

const dependencies = [];

export const EmailsModule = ng.module(
  'crm.management.emailtemplates',
  dependencies,
);

EmailsModule.config(emailsRouting);

EmailsModule.component(
  'prfEmailTemplatesSectionChangelog',
  EmailTemplatesSectionChangelogComponent,
)
  .component(
    'prfEmailTemplatesSectionTemplates',
    EmailTemplatesSectionTemplatesComponent,
  )
  .component(
    'prfEmailTemplateContentsLogsTable',
    EmailTemplateContentsLogsTableComponent,
  )
  .component(
    'prfEmailTemplateDesignsLogsTable',
    EmailTemplateDesignsLogsTableComponent,
  )
  .component('prfEmailTemplateDesignsTable', EmailTemplateDesignsTableComponent)
  .component('prfDesignTemplateForm', DesignTemplateFormComponent)
  .component('prfDesignTemplateItem', DesignTemplateItemComponent)
  .component('prfDesignTemplateDialog', DesignTemplateDialogComponent)
  .component('prfContentTemplatePage', ContentTemplatePageComponent)
  .component('prfContentTemplateItem', ContentTemplateItemComponent)
  .component(
    'prfContentTemplateGeneralForm',
    ContentTemplateGeneralFormComponent,
  )
  .component('prfContentTemplateEditorForm', ContentTemplateEditorFormComponent)
  .component('prfContentTemplateDesignForm', ContentTemplateDesignFormComponent)
  .component('prfEmailTemplatesDashboard', EmailTemplatesDashboardComponent)
  .component(
    'prfTemplatePlaceholderSimpleList',
    TemplatePlaceholderSimpleListComponent,
  )
  .component('prfEmailsDashboard', EmailsDashboardComponent)
  .component('prfCustomEmailTemplatesTable', CustomEmailTemplatesTableComponent)
  .component(
    'prfCustomEmailTemplateGeneralForm',
    CustomEmailTemplateGeneralFormComponent,
  )
  .component('prfSystemEmails', SystemEmailsComponent)
  .component(
    'prfSystemEmailsTriggerChooser',
    SystemEmailsTriggerChooserComponent,
  )
  .component(
    'prfInlineContentTemplatePreview',
    InlineContentTemplatePreviewComponent,
  )
  .component(
    'prfSystemTemplateGeneralForm',
    SystemTemplateGeneralFormComponent,
  );
