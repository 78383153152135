import template from './customer-page-view-audit-log-table.component.html';

const styles = require('./customer-page-view-audit-log-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { CustomersService } from '~/source/contact/common/services/customers';
import { PopupService } from '~/source/common/components/modal/popup.service';
import { Customer } from '@proftit/crm.api.models.entities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { customerPageViewAuditLogsTableColumns } from '~/source/contact/contact-page/sidebar-tabs/audit-logs-tab/customer-page-view-audit-log-table/customer-page-view-audit-log-table-columns';
import { customerPageViewAuditLogsTableSettings } from '~/source/contact/contact-page/sidebar-tabs/audit-logs-tab/customer-page-view-audit-log-table/customer-page-view-audit-log-table-settings';

export class CustomerPageViewAuditLogTableController extends TableController {
  static $inject = [
    'popupService',
    'customersService',
    ...TableController.$inject,
  ];
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customersService: () => CustomersService;
  popupService: PopupService;

  customer: Customer;

  blockUid = generateBlockuiId();
  cols = [...customerPageViewAuditLogsTableColumns];
  tableSettings = { ...customerPageViewAuditLogsTableSettings };
  dataServiceInstance: CustomersService;

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.customersService();
    this.initTable();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.tableSettings;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getCustomerPageViewsAuditLogResource(this.customer.id)
      .setConfig({ blockUiRef: this.blockUid })
      .sort({ requestedAt: 'desc' });
  }

  openTablePopup() {
    this.popupService.open({
      component: 'prfCustomerPageViewAuditLogPopup',
      resolve: {
        customer: this.customer,
      },
    });
  }
}

export const CustomerPageViewAuditLogTableComponent = {
  template,
  controller: CustomerPageViewAuditLogTableController,
  bindings: {
    customer: '<',
  },
};
