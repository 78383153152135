import BonusCommonController from '../bonus-common.controller';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';

import template from './bonus-create.html';

const DEPOSIT_SAFEGUARD_TIME = 300;

class BonusCreateController extends BonusCommonController {
  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  lifecycles = observeComponentLifecycles(this);

  makeDepositAction = new rx.Subject<void>();

  constructor(...args) {
    super(...args);

    useStreams([this.streamMakeDeposit()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    // this will be the model to fill in by the template
    this.bonus = {
      statusCode: 'approved',
    };
  }

  $onDestroy() {}

  /**
   * Called before posting funds bonus
   */
  streamMakeDeposit() {
    return rx.pipe(
      () => this.makeDepositAction,
      rx.debounceTime(DEPOSIT_SAFEGUARD_TIME),
      rx.tap(() => {
        // create new bonus (as a kind of "deposit")
        this.makeDeposit({
          amount: this.bonus.amount,
          multiplier: this.bonus.multiplier,
          statusCode: this.bonus.statusCode,
          note: this.bonus.note,
        });
      }),
    )(null);
  }
}

export default {
  template,
  controller: BonusCreateController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '<',
    onDone: '&',
  },
};
