import ng from 'angular';
import * as _ from '@proftit/lodash';

export function prfBlockUiOnDirective(blockUI: ng.blockUI.BlockUIService) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const blockUiId = attrs.blockUi;
      const blockUiInstance = blockUI.instances.get(blockUiId);

      if (_.isNil(blockUiInstance)) {
        return;
      }

      scope.$watch(attrs.prfBlockUiOn, (newVal) => {
        if (_.isNil(blockUiInstance)) {
          return;
        }

        if (_.isNil(newVal)) {
          return;
        }

        if (newVal) {
          blockUiInstance.start();
        } else {
          blockUiInstance.stop();
        }
      });
    },
    controller() {},
  };
}

prfBlockUiOnDirective.$inject = ['blockUI'];
