import ng from 'angular';
import { BrandDropdownComponent } from './brand-dropdown/brand-dropdown.component';
import { BrandPlatformsDropdownComponent } from './brand-platforms-dropdown/brand-platforms-dropdown.component';
import { Mt4GroupDropdownComponent } from './mt4-group-dropdown/mt4-group-dropdown.component';
import { DeskDropdownComponent } from './desk-dropdown/desk-dropdown.component';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { SimpleDropdownComponent } from './simple-dropdown/simple-dropdown.component';

export const CustomPrfDropdownsModule = ng.module('crm.customPrfDropdowns', []);

const md = CustomPrfDropdownsModule;

md.component('prfBrandDropdown', BrandDropdownComponent);
md.component('prfBrandPlatformsDropdown', BrandPlatformsDropdownComponent);
md.component('prfDeskDropdown', DeskDropdownComponent);
md.component('prfMt4GroupDropdown', Mt4GroupDropdownComponent);
md.component('prfUserDropdown', UserDropdownComponent);
md.component('prfSimpleDropdown', SimpleDropdownComponent);
