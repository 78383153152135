import ng from 'angular';

import Controller from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

class BrandsListController extends Controller {
  brandServiceInst: BrandsService;
  settings: any;
  tableParams: any;
  totalRecords: number;

  /*@ngInject */
  constructor(
    readonly $scope: ng.IScope,
    readonly brandsService: () => BrandsService,
    readonly brandModuleSettings: any,
    readonly NgTableParams: any,
  ) {
    super();

    this.settings = this.brandModuleSettings;
    this.brandServiceInst = this.brandsService();

    this.initTableParams();
  }

  initTableParams() {
    const ngTableSettings = $.extend(
      true,
      this.settings.brandsTable.ngTable.settings,
      {
        getData: this.getData.bind(this),
      },
    );

    this.tableParams = new this.NgTableParams(
      this.settings.brandsTable.ngTable.parameters,
      ngTableSettings,
    );
  }

  /**
   * Gets the data from API.
   * Called automatically by NgTable on any page/filter change.
   *
   * @param {Deferred} defer - this can be resolved with the fetch results (instead of returning a promise)
   * @param {object} params - NgTableParams
   * @returns {Promise} response from server
   */
  getData(params) {
    const page = params.page();
    const count = params.count();
    const filters = params.filter();
    const sort = params.sorting();

    // make the request
    return this.brandServiceInst
      .setPage(page, count)
      .filter(filters)
      .sort(sort)
      .expand(['file:logo'])
      .getListWithQuery<IElementRestNg<Brand>>()
      .then((data) => {
        // set the totals to ng-params
        params.total(this.brandServiceInst.total);
        // this will decide if the "no records" message will be shown. must not be 0 before this line.
        this.totalRecords = this.brandServiceInst.total;

        return data;
      });
  }

  /**
   * Toggles a brand "isActive" flag.
   * @param {object} brand - restangularized brand object
   */
  toggleStatus(brand) {
    brand
      .patch({
        isActive: !brand.isActive,
      })
      .then((response) => {
        brand.isActive = response.isActive;
      });
  }
}

export default BrandsListController;
