import * as _ from '@proftit/lodash';

/**
 */
export function minNotRequired(value, scope, element, attrs, param) {
  if (!_.isFinite(parseInt(value, 10))) {
    return true;
  }

  return parseInt(value, 10) >= parseInt(param, 10);
}

export default minNotRequired;
