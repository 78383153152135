import ng from 'angular';
import BonusModule from './bonus/module';
import DepositModule from './deposit/module';
import { FeeModule } from './fee/module';
import WithdrawalModule from './withdrawal/module';
import ComponentsModule from './components/module';
import AccountTypesDropdownComponent from './account-types-dropdown.component';
import { InternalTransferModule } from './internal-transfer/internal-transfer.module';
import { AccountTotalsComponent } from './account-totals/account-totals.component';
import { WithdrawalTooltipDataComponent } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-tooltip-data/withdrawal-tooltip-data.component';

export const ContactPageTradingAccountCommonModule = ng
  .module('crm.contact.tradingAccount.common', [
    BonusModule,
    DepositModule,
    FeeModule.name,
    WithdrawalModule,
    ComponentsModule,
    InternalTransferModule.name,
  ])
  .component('prfAccountTypesDropdown', AccountTypesDropdownComponent)
  .component('prfWithdrawalTooltipData', WithdrawalTooltipDataComponent)
  .component('prfAccountTotals', AccountTotalsComponent);

export default ContactPageTradingAccountCommonModule.name;
