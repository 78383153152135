import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

export function tapStartAsyncWorkInUi<T>(
  blockUI: ng.blockUI.BlockUIService,
  growl: ng.growl.IGrowlService,
  growlMessages: ng.growl.IGrowlMessagesService,
  blockUiId: string,
  growlId?: string,
): rx.MonoTypeOperatorFunction<T> {
  return rx.tap(() => {
    const blockUiInstance = blockUI.instances.get(blockUiId);
    blockUiInstance.start();

    if (growlId) {
      growlMessages.destroyAllMessages(<any>growlId);
    }
  });
}

export function tapStopAsyncWorkInUi(
  blockUI: ng.blockUI.BlockUIService,
  growl: ng.growl.IGrowlService,
  growlMessages: ng.growl.IGrowlMessagesService,
  blockUiId: string,
  growlId?: string,
) {
  return rx.pipe(
    rx.tap(() => {
      const blockUiInstance = blockUI.instances.get(blockUiId);
      blockUiInstance.stop();
    }),
    rx.catchError((err, caught) => {
      const message = calcErrorMessage(err);
      if (growlId) {
        growl.error(message, { referenceId: <any>growlId });
      }

      throw err;
    }),
    rx.finalize(() => {
      const blockUiInstance = blockUI.instances.get(blockUiId);
      blockUiInstance.stop();
    }),
  );
}

export function pipeAsyncWorkInUi(
  blockUI: ng.blockUI.BlockUIService,
  growl: ng.growl.IGrowlService,
  growlMessages: ng.growl.IGrowlMessagesService,
  blockUiId: string,
  growlId: string,
  action,
) {
  return rx.pipe(
    tapStartAsyncWorkInUi(blockUI, growl, growlMessages, blockUiId, growlId),
    rx.switchMap((...args) =>
      action(...args).pipe(
        tapStopAsyncWorkInUi(blockUI, growl, growlMessages, blockUiId, growlId),
      ),
    ),
  );
}

function isNillOrEmpty(val) {
  if (_.isNil(val)) {
    return true;
  }

  if (_.isEmpty(val)) {
    return true;
  }

  return false;
}

function calcErrorMessage(error: any) {
  let msg = _.get(['message'], error);
  if (!isNillOrEmpty(msg)) {
    return msg;
  }

  msg = _.get(['data'], error);

  if (!isNillOrEmpty(msg) && _.isString(msg)) {
    return msg;
  }

  msg = _.get(['data', 'message'], error);

  if (!isNillOrEmpty(msg) && _.isString(msg)) {
    return msg;
  }

  return `${error.status} - Error. Please try again later`;
}
