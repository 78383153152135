import $ from 'jquery';
import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';
import template from './kibi-container.html';
import { IIntervalService, IScope, IWindowService } from 'angular';
import { StateService } from '@uirouter/core';
import { wrapNgPermissionValidatePromise } from '~/source/common/utilities/wrap-ng-permission-validate-promise';
import { calcKibiCustomerRowId } from './utilities/calc-kibi-customer-row-id';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';

const DASHBOARD_SAMPLE_TIME = 500;

class KibiContainerController extends BaseController {
  static $inject = [
    'appConfig',
    '$scope',
    '$interval',
    'reportsSettings',
    '$state',
    'PermPermissionStore',
  ];

  iframeContent;

  lifecycles = observeComponentLifecycles(this);

  // refactor this
  constructor(
    readonly appConfig,
    readonly $scope: IScope,
    readonly $interval: IIntervalService,
    readonly reportsSettings,
    readonly $state: StateService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
    readonly $window: IWindowService,
  ) {
    super();
    // listen to $scope destroyed event
    this.$scope.$on('$destroy', () => this.onDestroy());
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /**
   * Called by iframe-loader when iframe is ready
   */
  onIframeReady() {}

  /**
   * Disable visualze and export button when user does not have reporting.kibiexport permission.
   */
  disableExportAndVisualizeForNonAuthUser() {
    wrapNgPermissionValidatePromise(
      this.PermPermissionStore.getPermissionDefinition('reporting.kibiexport'),
    ).then((hasPermission) => {
      if (hasPermission) {
        return;
      }

      const exportCsvButton = this.iframeContent.$(
        '[ng-click="exportAsCsvAsync()"]',
      );
      if (exportCsvButton) {
        exportCsvButton.hide();
      }

      const visualizeButton = this.iframeContent.$(
        '[ng-href*="kibana#/visualize?"]',
      );
      if (visualizeButton) {
        visualizeButton.hide();
      }
    });
  }

  /**
   * Called on scope destroy
   */
  onDestroy() {}
}

export default {
  template,
  controller: KibiContainerController,
  controllerAs: 'vm',
};
