import template from './permissions-tree.html';
import BaseController from '~/source/common/controllers/base';
import * as _ from '@proftit/lodash';

const permissionTypes = {
  R: 'VIEW',
  C: 'CREATE',
  U: 'UPDATE',
  D: 'DELETE',
};

/*
 * returns true if all the enabled permissions under current row are checked
 * this is used to determine the selectAll property of the row
 *
 * @param {Object} permission
 * @returns {Boolean}
 */
export function isCurrentRowAllSelected(permission) {
  if (_.isNil(permission)) {
    return false;
  }
  return Object.keys(permissionTypes).every((permType) =>
    permission[`enabled${permType}`] ? permission[`action${permType}`] : true,
  );
}

export class Controller extends BaseController {
  permission: any;
  permissionTypes = permissionTypes;
  private isRowSelectAllFlag: boolean = false;

  set isRowSelectAll(val) {
    this.isRowSelectAllFlag = val;

    // @todoOld: needs to remove 4 next lines after server remove selectedAll model field.
    if (_.isNil(this.permission)) {
      return;
    }
    this.permission.selectedAll = val;
  }

  get isRowSelectAll() {
    return this.isRowSelectAllFlag;
  }

  /*
   * reCalculate isRowSelectAll if input permission has change
   *
   * @param {Object} permissionCurrent
   * @returns {Boolean}
   */
  onPermissionChange(permissionCurrent) {
    this.isRowSelectAll = isCurrentRowAllSelected(permissionCurrent);
  }

  /*
   * Called when one CRUD permission type is clicked
   * ReCalculate value of permission.selectAll
   *
   * @param {Object} permission
   */
  onPermissionToggle(permission) {
    this.isRowSelectAll = isCurrentRowAllSelected(permission);
  }

  /*
   * Called when Toggle Checkbox is clicked
   * Set selected row values of all CRUD equal to permission.selectedAll
   * permission.selectedAll is model of Toggle Checkbox
   *
   * @param {Object} permission
   */
  onRowToggle(permission, isRowSelectAll) {
    Object.keys(permissionTypes)
      .filter((permType) => permission[`enabled${permType}`])
      .forEach((permType) => {
        permission[`action${permType}`] = isRowSelectAll;
      });
  }
}

Controller.$inject = [...BaseController.$inject];

export const PermissionsTreeComponent = {
  template,
  controller: Controller,
  bindings: {
    permission: '<',
    isEdit: '<',
  },
};
