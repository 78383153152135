import ng from 'angular';
import GeneralTermsComponent from './general-terms/general-terms.component';
import { SignupFormSelectFieldComponent } from './signup-form-select-field/signup-form-select-field.component';
import { SignupFormDateFieldComponent } from './signup-form-date-field/signup-form-date-field.component';
import { SignupFormInputFieldComponent } from './signup-form-input-field/signup-form-input-field.component';
import { SignupFormRadioFieldComponent } from './signup-form-radio-field/signup-form-radio-field.component';
import { SignupFormConfirmTermsFieldComponent } from './signup-form-confirm-terms-field/signup-form-confirm-terms-field.component';
import { SignupFormFieldComponent } from './signup-form-field/signup-form-field.component';

const ngModule = ng
  .module('crm.management.signupForm.fields', [])
  .component('prfSignupFormDateField', SignupFormDateFieldComponent)
  .component('prfSignupFormInputField', SignupFormInputFieldComponent)
  .component('prfSignupFormRadioField', SignupFormRadioFieldComponent)
  .component('prfSignupFormGeneralConfirmTermsField', GeneralTermsComponent)
  .component('prfSignupFormSelectField', SignupFormSelectFieldComponent)
  .component(
    'prfSignupFormConfirmTermsField',
    SignupFormConfirmTermsFieldComponent,
  )
  .component('prfSignupFormField', SignupFormFieldComponent);

export default ngModule.name;
