import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';

class TasksStatusesController extends DropdownController {
  static $inject = ['tasksStatusesService', ...DropdownController.$inject];

  get defaultPlaceholder() {
    return 'common.SELECT_STATUS';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'tasks.statuses';
  }
}

export default DropdownConfig({
  controller: TasksStatusesController,
});
