import angular from 'angular';
import { AddCallPopupComponent } from './add-communication-popup/add-communication-popup.component';
import { CommunicationHistoryTablePopupComponent } from './communication-history-popup/communication-history-popup.component';
import CommunicationHistoryTable from './communication-history-table/communication-history-table.component';
import CustomerStatusUpdatePopupController from './customer-status-update-popup.controller';
import { EditCommentPopupComponent } from './edit-comment-popup/edit-comment-popup.component';
import { EditCommentFormComponent } from './edit-comment-form/edit-comment-form.component';

export default angular
  .module('contact.contactPage.calls', [])
  .component('addCallPopup', AddCallPopupComponent)
  .component(
    'prfCommunicationHistoryTablePopup',
    CommunicationHistoryTablePopupComponent,
  )
  .controller(
    'CustomerStatusUpdatePopupController',
    CustomerStatusUpdatePopupController,
  )
  .component('prfEditCommentPopup', EditCommentPopupComponent)
  .component('prfEditCommentForm', EditCommentFormComponent)
  .component('prfContactCallHistoryTable', CommunicationHistoryTable).name;
