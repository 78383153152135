import {
  ComponentLifecyclesObservables,
  observeComponentLifecycles as useLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import * as requ from '@proftit/requ';
import type { IHttpService } from 'angular';
import { TokensService } from '../auth/services/tokens';
import { CrmAppStore } from './crm-app-store';
import { UserTokenModel } from '../common/models/user-token-model';
import { IAppConfig } from '../conf/appConfig';

interface AppTag {
  fingerprints: any;
  murmur: string;
}

export class CrmAppStoreProviderController {
  lifecycles = useLifecycles(this);

  store = new CrmAppStore(
    this.lifecycles,
    this.tokensService,
    this.appConfig,
    this.prfAppTag,
    this.$http,
  );

  // @ngInject
  constructor(
    readonly tokensService: TokensService,
    readonly appConfig: IAppConfig,
    readonly prfAppTag: AppTag,
    readonly $http: IHttpService,
  ) {}

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const CrmAppStoreProviderComponent = {
  controller: CrmAppStoreProviderController,
  template: '<ng-transclude></ng-transclude>',
  transclude: true,
};
