function userCrmExportNewChannelName(userId) {
  return `user.${userId}.own.CRMExport.new`;
}

function userKibiExportNewChannelName(userId) {
  return `user.${userId}.own.KibiExport.new`;
}

function userKibiExportUpdateChannelName(userId, exportId) {
  return `user.${userId}.KibiExport.${exportId}`;
}

function userCrmExportUpdateChannelName(userId, exportId) {
  return `user.${userId}.CRMExport.${exportId}`;
}

function userCustomerCommunicationUpdateChannelName(userId, commId) {
  return `user.${userId}.CustomerCommunication.${commId}`;
}

function userOwnCustomerCommunicationNewChannelName(userId) {
  return `user.${userId}.own.CustomerCommunication.new`;
}

function userContactsImportNewChannelName(userId) {
  return `user.${userId}.own.ContactsImport.new`;
}

function userContactsImportUpdateChannelName(userId, entityId) {
  return `user.${userId}.ContactsImport.${entityId}`;
}

function userUpdateChannelName(userId) {
  return `user.${userId}.User`;
}

function userBalanceLogExportNewChannelName(userId) {
  return `user.${userId}.own.BalanceLogsExport.new`;
}

function userBalanceLogExportUpdateChannelName(userId, exportId) {
  return `user.${userId}.BalanceLogsExport.${exportId}`;
}

function customerLocationsNewChannelName(userId, customerId) {
  return `user.${userId}.customer.${customerId}.locations.new`;
}

function customerLocationsKeepaliveChannelName(userId, customerId) {
  return `user.${userId}.customer.${customerId}.locations.keepalive`;
}

export { customerLocationsKeepaliveChannelName, customerLocationsNewChannelName, userBalanceLogExportNewChannelName, userBalanceLogExportUpdateChannelName, userContactsImportNewChannelName, userContactsImportUpdateChannelName, userCrmExportNewChannelName, userCrmExportUpdateChannelName, userCustomerCommunicationUpdateChannelName, userKibiExportNewChannelName, userKibiExportUpdateChannelName, userOwnCustomerCommunicationNewChannelName, userUpdateChannelName };
