import * as rx from '@proftit/rxjs';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export const streamDisabledClearingCompanyConnections = (
  clearingCompanyConnectionsForBrand$: rx.Observable<any[]>,
) => {
  return rx.pipe(
    () => clearingCompanyConnectionsForBrand$,
    rx.map((connections) =>
      connections.filter((connection) => !connection.isActive),
    ),
    shareReplayRefOne(),
  )(null);
};
