import * as _ from '@proftit/lodash';
import { FieldProxyHandler } from '../field-proxy-handler';

export const IS_NUMBER_BELOW = 'IS_NUMBER_BELOW';

export function isNumberBelowProxyValidatorFactory(max: number) {
  return function isNumberBelowProxyValidator(handler: FieldProxyHandler<any>) {
    if (handler.value > max) {
      return {
        code: IS_NUMBER_BELOW,
        isValid: false,
      };
    }

    return {
      code: IS_NUMBER_BELOW,
      isValid: true,
    };
  };
}
