import ng from 'angular';
import FormFieldsModule from './fields/module';
import settings from './form-settings.json';
import ListComponent from './signup-form-list/signup-form-list.component';
import CreateComponent from './signup-form-create/signup-form-create.component';
import EditComponent from './signup-form-edit/signup-form-edit.component';
import SignupFormsService from './signup-forms.service';
import FormBuilderViewCodeModalController from './view-form-code-modal.controller';

const ngModule = ng
  .module('crm.management.signupForm', [FormFieldsModule])
  .component('prfSignupFormList', ListComponent)
  .component('prfSignupFormCreate', CreateComponent)
  .component('prfSignupFormEdit', EditComponent)
  .controller(
    'FormBuilderViewCodeModalController',
    FormBuilderViewCodeModalController,
  )
  .service('signupFormsService', SignupFormsService)
  .constant('signupFormModuleSettings', settings)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('crm.management.tools.signup-form', {
          redirectTo: 'crm.management.tools.signup-form.list',
          url: '/signup-form',
          template: '<ui-view/>',
        })
        .state('crm.management.tools.signup-form.list', {
          url: '/list',
          component: 'prfSignupFormList',
        })
        .state('crm.management.tools.signup-form.create', {
          url: '/create',
          component: 'prfSignupFormCreate',
        })
        .state('crm.management.tools.signup-form.edit', {
          url: '/edit/:id',
          component: 'prfSignupFormEdit',
        });
    },
  ]);

export default ngModule.name;
