import * as _ from '@proftit/lodash';

import BaseService from '~/source/common/services/baseService';

export class FeaturesFlagsService extends BaseService {
  featuresFlagsData: any;

  /**
   * Check if a feature is turned off/on.
   *
   * @return {boolean}
   */
  isEnabled(targetPathParam: string[]): boolean {
    const targetPath = _.joinEs(targetPathParam, '.');
    const result = _.getEs(this.featuresFlagsData, targetPath);
    if (_.isNil(result)) {
      throw new Error(
        `feature definition does not exist: ${_.toStringEs(targetPath)}`,
      );
    }

    return result;
  }
}

FeaturesFlagsService.$inject = ['featuresFlagsData'];

export default FeaturesFlagsService;
