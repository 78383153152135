import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import BaseController from '~/source/common/controllers/base';
import { Brand } from '@proftit/crm.api.models.entities';
import { brandVoipValidations } from '~/source/management/brand/validations.settings';

import template from './voip-form.html';
import { calcValidationsStrForVoipField } from './calc-validations-str-for-voip-field';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import BrandsService from '~/source/management/brand/services/brands';
import { Entity } from '@proftit/crm.api.models.general';
const styles = require('./voip-form.scss');

class ComponentController extends BaseController {
  styles = styles;
  brand: Brand;

  mergedVoipObject: any;
  credentialsObject: any;
  reloadProviders: () => void;

  lifecycles = observeComponentLifecycles(this);

  region: any;
  unusedVoipProviders: any[];
  voipProvidersToDisplay: any[];
  onProviderChangeEvent: () => void;
  isCurrentlyDefault: boolean;
  unusedVoipProviders$ = observeShareCompChange<any[]>(
    this.lifecycles.onChanges$,
    'unusedVoipProviders',
  );

  /*@ngInject */
  constructor(readonly brandsService: () => BrandsService) {
    super();
    useStreams(
      [this.unusedVoipProviders$, this.streamUpdateVoipProvidersToDisplay()],
      this.lifecycles.onDestroy$,
    );
  }

  /**
   * Called on init of component
   */

  $onInit() {
    this.setMergedVoipObject();
    this.isCurrentlyDefault = this.region.isDefault;
  }

  streamUpdateVoipProvidersToDisplay() {
    return rx.pipe(
      () => this.unusedVoipProviders$,
      rx.tap((unusedVoipProviders) => {
        this.updateVoipProvidersToDisplay(unusedVoipProviders);
      }),
      shareReplayRefOne(),
    )(null);
  }

  updateVoipProvidersToDisplay(unusedVoipProviders) {
    this.voipProvidersToDisplay = this.getVoipProvidersToDisplay(
      this.region.voipProvider,
      unusedVoipProviders,
    );
  }

  idSelectionCompare = (a: Entity, b: Entity) => {
    return _.get(['id'], a) === _.get(['id'], b);
  };

  getVoipProvidersToDisplay(
    currentVoipProvider: any,
    unusedVoipProviders: any[],
  ) {
    if (!_.isNil(currentVoipProvider)) {
      return [currentVoipProvider, ...unusedVoipProviders];
    }
    return [...unusedVoipProviders];
  }

  $onDestroy() {}

  /**
   * Creating new object combining 'voipProvider credentials' and 'voipProvider.provider fields'
   *
   * @returns {void}
   */
  setMergedVoipObject() {
    if (_.isNil(this.region.voipProvider)) {
      return;
    }
    this.mergedVoipObject = {};
    const arrayCredentials = this.region.voipCredentials;

    if (!this.region.voipProvider) {
      return;
    }

    const fields = this.region.voipProvider.fields;
    if (!fields) {
      return;
    }

    fields.forEach((fieldItem) => {
      // if credentialsObject is full - its an edit mode, otherwise - create mode
      const credItemToMerge = this.credentialsObject
        ? arrayCredentials.find((credItem) => credItem.key === fieldItem.code)
        : null;

      const validationsStr = calcValidationsStrForVoipField(
        brandVoipValidations[fieldItem.type],
        fieldItem.isRequired,
      );

      this.mergedVoipObject[fieldItem.code] = {
        name: fieldItem.code,
        value: credItemToMerge ? credItemToMerge.value.toString() : '',
        validator: validationsStr,
        type: fieldItem.type,
      };
    });
  }

  onCredentialsChange(newCredentialObject) {
    // initialize credentialsObject if its undefined/null
    if (_.isEmpty(this.credentialsObject)) {
      this.credentialsObject = {};
    }
    this.credentialsObject[newCredentialObject.name] =
      newCredentialObject.value;
  }

  /**
   * Called on select voip-provider change
   *
   * @returns {void}
   */

  onChangeVoipProvider(voipProvider) {
    this.onProviderChangeEvent();
    this.createNewVoipFields(voipProvider);
  }

  createNewVoipFields(voipProvider) {
    if (_.isEmpty(voipProvider) || _.isEmpty(voipProvider.fields)) {
      this.mergedVoipObject = {};
      return;
    }
    this.region.voipCredentials = [];
    this.setMergedVoipObject();
  }

  deleteVoipRegion() {
    this.brandsService()
      .deleteVoipProviderConfiguration(
        this.brand.id,
        this.region.voipCredentialsId,
      )
      .then(() => {
        this.reloadProviders();
      });
  }
}

const BrandVoipFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    credentialsObject: '=',
    unusedVoipProviders: '<',
    region: '=',
    onProviderChangeEvent: '&',
    reloadProviders: '&',
  },
};

export default BrandVoipFormComponent;
