import Component from '~/source/common/components/dropdowns/base/component';

class Controller extends Component.controller {
  static $inject = ['countriesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.SELECT_COUNTRY';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'alpha2Code';
  }

  /**
   * Path to translations on lang.json file
   * Must be overridden
   *
   * @returns {String}
   */
  get translationPath(): string {
    return 'nationalities';
  }

  get nullableElement() {
    return {
      id: null,
      code: 'null',
      alpha2Code: 'null',
      nullable: true,
    };
  }
}

const NationalitySelectComponent = Component.config({ controller: Controller });

export default NationalitySelectComponent;
