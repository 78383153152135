const styles = require('./customer-links-select.component.scss');

import {
  DropdownController,
  DropdownConfig,
} from '~/source/common/components/dropdowns/base/component';
import { useStream } from '~/source/common/utilities/use-stream';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ItemLabelStrategy } from '~/source/common/components/dropdowns/base/item-label-strategy';
import { CustomersService } from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';

class Controller extends DropdownController {
  static $inject = ['customersService', ...DropdownController.$inject];

  dataServiceInst: CustomersService;

  unsub$ = new rx.Subject<void>();
  customer$ = new rx.BehaviorSubject<Customer>(null);

  constructor(a, ...args) {
    super(a, ...args);

    useStream(this.streamCustomerChange(), this.unsub$);
  }

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];
  }

  onCustomerChange(customer: Customer) {
    this.customer$.next(customer);
  }

  streamCustomerChange() {
    return rx.pipe(
      () => this.customer$,
      rx.tap(() => {
        this.output = [];
      }),
      rx.filter((customer) => !_.isNil(customer)),
      rx.tap(() => this.fetchData()),
    )(null);
  }

  /**
   * Preform request
   *
   * @returns {*|{method, params, headers}}
   */
  query() {
    const customer = this.customer$.getValue();

    return this.dataServiceInst.getCustomerLinks(customer.id).then((links) => {
      const currentCustomer = this.customer$.getValue();

      return [{ id: currentCustomer.id }, ...links];
    });
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }

  /**
   * Component option - Item label strategy.
   *
   * By default, sets to field translation.
   *
   * @return {ItemLabelStrategy} item label strategy
   */
  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  /**
   * Calc label for data item.
   *
   * By default, return the item element name untranstlated.
   *
   * @param {object} item - item
   * @return {string} item label.
   */
  calcLabelForItem(item) {
    return item.id;
  }

  /**
   * Calculate classes for option item.
   *
   * Allow override in child component.
   *
   * @param item
   * @return classes as list of string;
   */
  calcClassesForOption(item): string[] {
    if (item.isActive) {
      return ['prf-active-customer-link'];
    }

    return [];
  }
}

export const CustomerLinksSelectComponent = DropdownConfig({
  controller: Controller,
  bindings: {
    customer: '<',
  },
});
