import { ClearingCompanyCode } from '@proftit/crm.api.models.enums';
import _ from 'underscore';
import * as _l from '@proftit/lodash';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import template from './creditcard-deposit-form.html';
import TransactionController from '../../transaction-base.controller';
import {
  Customer,
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import { CreditCard } from '~/source/common/models/credit-card';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import * as rx from '@proftit/rxjs';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import { ClearingCompanyType } from '@proftit/crm.api.models.enums/src';
import CreditCardTypesService from '~/source/contact/common/services/credit-card-types.service';

const styles = require('./creditcard-deposit-form.component.scss');

class CreditCardDepositFormController extends TransactionController {
  styles = styles;
  account: TradingAccount;
  customer: Customer;
  ClearingCompanyCode = ClearingCompanyCode;
  onDone: () => void;

  lifecycles = observeComponentLifecycles(this);

  modelNormalizer: ModelNormalizerService;
  depositsSettings;
  clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService;
  creditCardTypesService: () => CreditCardTypesService;
  creditcards: CreditCard[];
  displayAddCardFrom: boolean;
  deposit: TradingAccountDeposit;

  selectClearingCompanyAction = new rx.Subject<any>();

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  activeClearingCompanyConnectionsForBrand$ = this.streamActiveClearingCompanyConnectionsForBrand();
  selectedClearingCompany$ = this.streamSelectedClearingCompany();
  creditCardType$ = this.streamCreditCardType();
  shouldShowOnlyAmount$ = this.streamShouldShowOnlyAmount();

  static $inject = [
    'modelNormalizer',
    'depositsSettings',
    'clearingCompanyConnectionsService',
    'creditCardTypesService',
    ...TransactionController.$inject,
  ];

  makeDepositAction = new rx.Subject<void>();

  constructor(...args) {
    super(...args);
    useStreams(
      [
        this.streamMakeDeposit(),
        this.activeClearingCompanyConnectionsForBrand$,
        this.shouldShowOnlyAmount$,
        this.creditCardType$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    Object.assign(this, {
      deposit: {},
    });

    this.showNewCardForm();
  }

  streamShouldShowOnlyAmount() {
    return rx.pipe(
      () => this.selectedClearingCompany$,
      rx.map(
        (clearingCompanyConnection) =>
          clearingCompanyConnection.clearingCompany.showAmountOnly,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedClearingCompany() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSelectedClearingCompanyFromSelection(),
          this.streamSelectedClearingCompanyFromDefault(),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedClearingCompanyFromDefault() {
    return rx.pipe(
      () => this.activeClearingCompanyConnectionsForBrand$,
      rx.filter((data) => !_l.isNil(data) && data.length > 0),
      rx.map((data) => data[0]),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedClearingCompanyFromSelection() {
    return rx.pipe(
      () => this.selectClearingCompanyAction,
      rx.map(({ company }) => company),
      shareReplayRefOne(),
    )(null);
  }

  streamCreditCardType() {
    return rx.pipe(
      () => this.selectedClearingCompany$,
      rx.filter(
        (clearingCompanyConnection) =>
          clearingCompanyConnection.clearingCompany.code ===
          ClearingCompanyCode.Gateway,
      ),
      rx.switchMap(() => {
        return this.creditCardTypesService()
          .filter({ code: ['visa', 'mastercard'] })
          .getListWithQuery()
          .catch((e) => null);
      }),
      rx.map((types) => types.plain()),
      shareReplayRefOne(),
    )(null);
  }

  updateCreditCardTypeId({ creditcardTypeId }) {
    this.deposit.creditcardTypeId = creditcardTypeId;
  }

  streamActiveClearingCompanyConnectionsForBrand() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.withLatestFrom(this.customer$),
      rx.filter(([a, customer]) => !_l.isNil(customer)),
      rx.switchMap(([a, customer]) => {
        return rx.obs
          .from(
            this.clearingCompanyConnectionsService()
              .expand(['brand', 'clearingCompany', 'displayFile'])
              .filter({
                brandId: customer.brand.id,
                'clearingCompany.type': ClearingCompanyType.CreditCard,
              })
              .getListWithQuery<IElementRestNg<ClearingCompanyConnection>>(),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((allConnections) =>
        allConnections.filter((connection) => connection.isActive),
      ),
      rx.map((filteredConnections) =>
        _l.sortBy((connection) => connection.order, filteredConnections),
      ),
      shareReplayRefOne(),
    )(null);
  }

  $onDestroy() {}

  /**
   * Normalize the deposit object and post it to server.
   */
  streamMakeDeposit() {
    return rx.pipe(
      () => this.makeDepositAction,
      rx.withLatestFrom(
        this.selectedClearingCompany$,
        this.shouldShowOnlyAmount$,
      ),
      rx.debounceTime(this.depositsSettings.depositSafeguardTime),
      rx.tap(([a, clearingCompanyConnection, shouldShowOnlyAmount]) => {
        // normalize deposit object
        const normalizedDeposit = this.modelNormalizer.normalize(this.deposit);
        const data = {
          ...normalizedDeposit,
          transferTransactionCC: {
            clearingCompanyId: clearingCompanyConnection.clearingCompany.id,
          },
        };
        if (shouldShowOnlyAmount) {
          delete data.creditCard;
        }
        super.makeDeposit(data, clearingCompanyConnection.clearingCompany);
      }),
    )(null);
  }

  /**
   * Specify local block ui for the component.
   *
   * Allow children to override to use block ui in their on templates.
   */
  get blockUiId() {
    return 'creditCardBlock';
  }

  /**
   * Returns deposit type
   *
   * @returns {String}
   */
  get depositType() {
    return this.depositsSettings.types.creditCard;
  }

  updateDeposit3dStatus(
    is3dSale: boolean,
    html3d: string,
    redirectUrl: string,
  ) {
    if (is3dSale) {
      this.deposit = {
        ...this.deposit,
        html3d,
        redirectUrl,
        is3dSale,
      };
    } else {
      delete this.deposit.html3d;
      delete this.deposit.redirectUrl;
      delete this.deposit.is3dSale;
    }
  }

  /**
   * Show the credit card form and initialize it with customer details
   */
  showNewCardForm() {
    this.displayAddCardFrom = true;

    // remove creditCardId property if exists
    delete this.deposit.creditCardId;

    Object.assign(this.deposit, {
      creditCard: _.pick(this.customer, [
        'firstName',
        'lastName',
        'city',
        'zipCode',
        'address',
        'country',
      ]),
    });
  }
}

export default {
  template,
  controller: CreditCardDepositFormController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    account: '=',
    onDone: '&',
  },
};
