export enum MarginLevelType {
  Normal = 'normal',
  MarginCall = 'margin-call',
  StopOut = 'stop-out',
}

export const reachedMarginLevel = [
  MarginLevelType.MarginCall,
  MarginLevelType.StopOut,
];
