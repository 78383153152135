import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class WithdrawalNotificationsController extends NotificationsController {}

WithdrawalNotificationsController.$inject = [
  'withdrawalsSocketService',
  ...NotificationsController.$inject,
];

export default {
  ...ComponentOptions,
  controller: WithdrawalNotificationsController,
};
