import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class PortfolioPositionsNotificationsController extends NotificationsController {
  buildChannel() {
    return [
      `user.${this.tokensService.getCachedUser().id}`,
      `.${this.streamingService.channelRoot}`,
      '.new',
    ].join('');
  }
}

PortfolioPositionsNotificationsController.$inject = [
  // socket sevice needs to be first in the injection. (see base notification controller)
  'portfolioForexPositionSocketService',
  ...NotificationsController.$inject,
];

export const PortfolioPositionsNotificationsComponent = {
  ...ComponentOptions,
  controller: PortfolioPositionsNotificationsController,
};
