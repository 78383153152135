import ng from 'angular';

import type { Brand, Country } from '@proftit/crm.api.models.entities';
import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import ModelNormalizerService from '~/source/common/services/model-normalizer';

import template from './blacklisted-countries-data.html';
import { BrandProviderInner } from '~/source/common/providers/brand-provider.component';

class ComponentController extends BaseController {
  brandsInstance: BrandsService;

  prfBrandProviderInner: BrandProviderInner;

  selectedCountries: any;
  brand: Brand;

  isEdit: boolean;
  prevAttributes: any;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly modelNormalizer: ModelNormalizerService,
  ) {
    super();

    this.brandsInstance = this.brandsService();
    this.isEdit = false;
  }

  /**
   * Enter edit mode:
   * Save current model state so we could restore them if the user chooses to cancel
   */
  enterEdit() {
    // Save pre-edit state
    this.prevAttributes = ng.copy(this.selectedCountries);

    // Enter edit mode
    this.isEdit = true;
  }

  /**
   * Cancel edit mode:
   * restore previous model state
   */
  cancelEdit() {
    // Restore pre-edit state
    this.selectedCountries = ng.copy(this.prevAttributes);

    // Exit edit mode
    this.isEdit = false;
  }

  save() {
    this.prfBrandProviderInner.store
      .updateBlacklistedCountries(this.selectedCountries)
      .then(() => {
        this.isEdit = false;
      });
  }

  isCountryDisabledFn(country: Country) {
    return country.isBlacklisted;
  }
}

const BlacklistedCountriesDataComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    selectedCountries: '<',
  },
  require: {
    prfBrandProviderInner: '^',
  },
};

export default BlacklistedCountriesDataComponent;
