import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { generateUuid } from '@proftit/general-utilities';

interface StoreServiceClass {
  $require;
  $inject;
  new (...args): any;
}

export function generateDirectiveForStore(
  storeClass: StoreServiceClass,
  directiveName: string,
) {
  const directiveFactory = ($parse) => {
    const controllerTypeId = generateUuid();

    return {
      restrict: 'EA',
      require: {
        ...storeClass.$require,
      },
      link(scope, element, attrs, requiredCtrls) {
        const self = scope[controllerTypeId];

        const ctrlInjects = self.ctrlInjects;

        const item = new storeClass(...ctrlInjects, requiredCtrls);

        const actionCall = $parse(attrs[directiveName]);

        actionCall(scope, { item });
        self.item$.next(item);

        scope.$on('$destroy', () => {
          item.onDestroy$.next();
        });
      },
      controllerAs: directiveName,
      controller: [
        '$scope',
        ...storeClass.$inject,
        function ($scope, ...ctrlInjects) {
          this.ctrlInjects = ctrlInjects;
          this.item$ = new rx.BehaviorSubject<any>(null);
          this.controllerTypeId = controllerTypeId;
          this.controllerId = generateUuid();

          $scope[controllerTypeId] = this;
        },
      ],
    };
  };

  directiveFactory.$inject = ['$parse'];

  return directiveFactory;
}
