import { IScope } from 'angular';

import ControllerBase from '~/source/common/controllers/base';

import template from './edit.html';

class Controller extends ControllerBase {
  /*@ngInject */
  constructor(readonly $scope: IScope, readonly $stateParams: any) {
    super();
  }
}

const PriorityEditComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default PriorityEditComponent;
