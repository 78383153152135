import Component from './base/component';

class LanguageController extends Component.controller {
  static $inject = ['languagesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.SELECT_LANGUAGE';
  }

  /**
   * Path to translations on lang.json file
   *
   * @returns {string}
   */
  get translationPath() {
    return 'lang';
  }

  /**
   * Used if select is nullable, returns nullable entity
   *
   * @returns {Object}
   */
  get nullableElement() {
    return {
      id: null,
      code2: 'NULL',
    };
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'code2';
  }
}

export default Component.config({ controller: LanguageController });
