import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'system/phoneVerification';

export class PublicSystemPhoneVerificationService extends RestService {
  get resource() {
    return SERVICE_RESOURCE;
  }

  get accessType() {
    return 'public';
  }

  sendPhoneVerification(username: string, password: string) {
    return this.resourceBuildStart().resourceBuildEnd().postWithQuery({
      username,
      password,
    });
  }
}
