import commonModule from '../index';

commonModule.filter('slashToDash', slashToDash);

function slashToDash() {
  return function (value) {
    return !value ? '' : value.replace(/\//g, '-');
  };
}
slashToDash.$inject = [];
