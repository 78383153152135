/**
 * Base Controller
 *
 * common logic for all Controllers can be added here.
 */
import _ from 'underscore';
import s from 'underscore.string';
import log from 'loglevel';

export abstract class BaseController implements ng.IController {
  $scope: angular.IScope;

  static $inject = ['$scope'];

  constructor(...injectedProviders) {
    /**
     * injectable classes are called with injected providers.
     * We want those providers to easily be accessible, so we set them as class members.
     * this should only be done if the number of $inject vars matches the arguments number.
     * otherwise, it means a base class has overridden the constructor and has tempered with the arguments already.
     */
    if (this.constructor.$inject.length === injectedProviders.length) {
      this.setInjectedProvidersAsMembers(injectedProviders);
    } else if (injectedProviders.length > 0) {
      let msg = 'injected arguments length not equal to expected amount\n';
      msg += `- consturctor: ${this.constructor.name}\n`;
      msg += `- injectedProviders: ${injectedProviders.length}, inject array: ${this.constructor.$inject.length}`;

      log.debug('injected arguments amount does not match expected amount');
    }
  }

  /**
   * Set all the injected providers (as specified by the static $inject) as class members.
   * @param {object[]} providers - Injected providers, as passed by the injector to the constructor.
   */
  protected setInjectedProvidersAsMembers(providers) {
    this.constructor.$inject.forEach((fieldName, index) => {
      this[fieldName] = providers[index];
    });
  }

  /**
   * Called whenever one-way bindings are updated. The changesObj is a hash whose keys are the names of
   * the bound properties that have changed, and the values are an object of
   * the form { currentValue, previousValue, isFirstChange() }
   *
   * Iterate the changes list and call an instance method by the name "onFieldChange"
   * for each changed field.
   * @param {angular.IOnChangesObject} changes - Angular changes object
   * @return void
   */
  $onChanges(changes: angular.IOnChangesObject) {
    if (changes === undefined) {
      return;
    }

    Object.keys(changes).forEach((field) => {
      const change = changes[field];
      const changeMethod: Function = this[`on${s.capitalize(field)}Change`];
      if (
        change.previousValue !== change.currentValue &&
        _.isFunction(changeMethod)
      ) {
        changeMethod.call(this, change.currentValue, change.previousValue);
      }
    });
  }

  /**
   * Broadcasts the passed event down to child scopes.
   * Ignores own event to prevent infinite recursion.
   *
   * This is useful for catching events emitted by the children and bubbling them down,
   * so they could be read by all the siblings of the scope which emitted this event.
   *
   * @param {angular.IAngularEvent} event - Angular event
   * @param {any} args - Any additional payload to the event
   */
  protected bubbleDown(event: angular.IAngularEvent, ...args) {
    if (event.currentScope === event.targetScope) {
      return; // Ignore own event to prevent recursion
    }

    event.stopPropagation(); // no need to keep propagating up

    // Broadcast the event down with the same arguments
    this.$scope.$broadcast(event.name, ...args);
  }
}

export default BaseController;
