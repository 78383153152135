import * as rx from '@proftit/rxjs';
import { IOnChangesObject } from 'angular';
import { filterCompChange } from '../../rxjs/operators/filter-comp-change';

export function getCompChange<T>(propName: string) {
  return function operator(obs$: rx.Observable<IOnChangesObject>) {
    return rx.pipe(
      () => obs$,
      filterCompChange<T>(propName),
      rx.map((a) => a.currentValue),
    )(null);
  };
}
