// tslint:disable-next-line:max-line-length
const ipv4RangeRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/(3[0-2]|[1-2][0-9]|[0-9]))$/;

/**
 * Check if ip is a valid ipv4 range form.
 *
 * @param {string} ip - ip.
 * @return {boolean} whether is the ip a valid ipv4 range.
 */
export function isIpv4RangeRegex(ip: string) {
  return ipv4RangeRegex.test(ip);
}

export default isIpv4RangeRegex;
