import { IScope } from 'angular';
import _ from 'underscore';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import CurrenciesService from '~/source/common/services/currencies';
import calcCurrencyMinDecimalStep from '~/source/common/models/currency/calc-currency-min-decimal-step';

import template from './minimum-deposit-form.html';

class ComponentController extends BaseController {
  brandServiceInst: BrandsService;
  selectedCurrencies: any;
  selectedCurrency: any;
  selectedDefault: any;

  calcCurrencyMinDecimalStep = calcCurrencyMinDecimalStep;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly brandsService: () => BrandsService,
    readonly currenciesService: CurrenciesService,
  ) {
    super();

    this.brandServiceInst = this.brandsService();

    const selectedCurrency = _.filter(
      this.selectedCurrencies,
      (currency: any) => currency.isDefault === true,
    );
    this.selectedDefault = _.first(selectedCurrency);

    // watch outside brand change.
    this.$scope.$watch('vm.brand.id', (nVal, oVal) => {
      if (nVal === oVal) {
        return;
      }

      // get currencies (with min deposut) attach to selected brand
      this.getCurrencies(nVal).then((data) => {
        /*
         * ignore the id field
         * because normalizer normalize it which produce unwanted results in post & put
         */
        this.selectedCurrencies = data.map((currencyObj) =>
          _.omit(currencyObj, 'id'),
        );
      });
    });
  }

  /**
   * get brand currencies
   * @param {number} brandId brand id
   * @returns {Promise} restangular promise
   */
  getCurrencies(brandId) {
    return this.brandServiceInst
      .getCurrenciesResource(brandId)
      .expand('currency')
      .getListWithQuery();
  }

  /**
   * Ses isDefault=true on default currency and "false" on all the other currencies.
   *
   * @param {object} selectedCurrency - new default currency
   * @return {void}
   */
  onDefaultCurrencyChange(selectedCurrency) {
    this.selectedCurrencies.forEach((currency) => {
      currency.isDefault =
        currency.currency.code === selectedCurrency.currency.code;
    });
  }
}

const MinimumDepositFormComponent = {
  template,
  controller: ComponentController,
  controllerAs: 'vm',
  bindings: {
    separatedMinimumDeposits: '<',
    selectedCurrencies: '=',
    isEdit: '<',
    hideDefault: '<',
    brand: '<',
  },
};

export default MinimumDepositFormComponent;
