import * as yup from 'yup';

export function createSmtpConfigurationValidation() {
  const schema = yup.object().shape({
    host: yup.string().required(),
    port: yup.number().required().positive().integer(),
    username: yup.string().required(),
    password: yup.string().required(),
  });

  return schema;
}
