import module from '../../index';
import template from './world-map-form.html';
import BaseController from '~/source/common/controllers/base';
import type { ITimeoutService } from 'angular';
import * as rx from '@proftit/rxjs';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { generateUuid } from '@proftit/general-utilities';

class Controller extends BaseController {
  lifecycles = observeComponentLifecycles(this);

  countriesIn$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'countriesIn',
  );

  countriesSelectId = generateUuid();

  /* @ngInject */
  constructor(readonly $element: JQuery, readonly $timeout: ITimeoutService) {
    super();

    useStreams(
      [this.countriesIn$, this.streamHardRefreshCountriesSelect()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /*
   * Bug in chosen does not show disabled items if model include only
   * disabled items on start. Need to hard refresh chosen.
   */
  streamHardRefreshCountriesSelect() {
    return this.countriesIn$.pipe(
      rx.tap(() => {
        const chosenEl = this.$element.find(
          `#${this.countriesSelectId} select`,
        );
        chosenEl.trigger('chosen:updated');
      }),
    );
  }
}

module.component('prfWorldMapForm', {
  template,
  bindings: {
    countriesIn: '<',
    countries: '=',
    multiple: '<',
    disabled: '<',
    required: '<',
    isCountryDisabledFn: '<',
  },
  controller: Controller,
  controllerAs: 'vm',
});
