import { IOnChangesObject } from 'angular';
import { filterCompChange } from './rxjs/observables/filter-comp-change';
import * as rx from '@proftit/rxjs';

export function observeCompChange<T>(
  value$: rx.Subject<T>,
  inputName: string,
  onChanges$: rx.Observable<IOnChangesObject>,
): rx.Observable<T> {
  return rx.pipe(
    () => filterCompChange<T>(inputName, onChanges$),
    rx.map(({ currentValue }) => currentValue),
    rx.tap((val) => value$.next(val)),
  )(null);
}
