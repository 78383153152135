import Component from '~/source/common/components/dropdowns/base/component';

class CustomerComplianceStatusesController extends Component.controller {
  static $inject = [
    'customerComplianceStatusesService',
    ...Component.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'common.SELECT_STATUS';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({
  controller: CustomerComplianceStatusesController,
});
