let SignupFormFieldCode;

(function (SignupFormFieldCode) {
  SignupFormFieldCode["Address"] = "address";
  SignupFormFieldCode["Age"] = "age";
  SignupFormFieldCode["Birthday"] = "birthday";
  SignupFormFieldCode["City"] = "city";
  SignupFormFieldCode["ConfirmEmail"] = "confirmEmail";
  SignupFormFieldCode["ConfirmPassword"] = "confirmPassword";
  SignupFormFieldCode["ConfirmTerms"] = "confirmTerms";
  SignupFormFieldCode["Country"] = "country";
  SignupFormFieldCode["Currency"] = "currency";
  SignupFormFieldCode["Email"] = "email";
  SignupFormFieldCode["FirstName"] = "firstName";
  SignupFormFieldCode["Gender"] = "gender";
  SignupFormFieldCode["GeneralConfirmTerms"] = "generalConfirmTerms";
  SignupFormFieldCode["IdNumber"] = "idNumber";
  SignupFormFieldCode["IdType"] = "idType";
  SignupFormFieldCode["IsAccountReal"] = "isAccountReal";
  SignupFormFieldCode["IsAllowMarketingCommunication"] = "isAllowMarketingCommunication";
  SignupFormFieldCode["IsDemoOnly"] = "isDemoOnly";
  SignupFormFieldCode["Language"] = "language";
  SignupFormFieldCode["LastName"] = "lastName";
  SignupFormFieldCode["Name"] = "name";
  SignupFormFieldCode["Nationality"] = "nationality";
  SignupFormFieldCode["Password"] = "password";
  SignupFormFieldCode["Phone"] = "phone";
  SignupFormFieldCode["Platform"] = "platform";
  SignupFormFieldCode["State"] = "state";
  SignupFormFieldCode["TaxIdNumber"] = "taxIdNumber";
  SignupFormFieldCode["TaxResidency"] = "taxResidency";
})(SignupFormFieldCode || (SignupFormFieldCode = {}));

export { SignupFormFieldCode };
