import ng from 'angular';

import ClearingCompanyConnectionsService from './services/clearing-company-connections';
import ClearingCompaniesFormComponent from './components/clearing-companies-form/clearing-companies-form';
import ClearingCompaniesCreateComponent from '~/source/management/clearing-companies/components/create/create.component';
import ClearingCompaniesEditComponent from '~/source/management/clearing-companies/components/edit/edit.component';
import ClearingCompaniesManageComponent from '~/source/management/clearing-companies/components/manage/manage.component';
import ClearingCompanyCredentialsEditorComponent from './components/clearing-company-credentials-editor/clearing-company-credentials-editor.component';
import { uploadClearingCompaniesFileComponent } from './components/upload-clearing-companies-file/upload-clearing-companies-file.component';
const clearingCompanies = ng.module('crm.clearingCompanies', [
  'crm.constants',
  'crm.common',
  'ui.router',
]);

function config($stateProvider, $urlRouterProvider) {
  // Alias: / -> /list. This acts as a default child state
  $urlRouterProvider.when(
    '/crm/management/integration/clearing/companies',
    '/crm/management/integration/clearing/companies/list',
  );

  $stateProvider
    .state('crm.management.integration.clearing', {
      url: '/clearing',
      template: '<ui-view/>',
    })
    .state('crm.management.integration.clearing.companies', {
      url: '/companies',
      template: '<ui-view/>',
    })
    .state('crm.management.integration.clearing.companies.list', {
      component: 'prfClearingCompaniesManageComponent',
      url: '/list',
    })
    .state('crm.management.integration.clearing.companies.create', {
      component: 'prfClearingCompaniesCreateComponent',
      url: '/create',
    })
    .state('crm.management.integration.clearing.companies.edit', {
      component: 'prfClearingCompaniesEditComponent',
      url: '/edit/:id',
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

clearingCompanies
  .config(config)
  .factory(
    'clearingCompanyConnectionsService',
    ClearingCompanyConnectionsService.$factorize(),
  )
  .component('prfClearingCompaniesForm', ClearingCompaniesFormComponent)
  .component(
    'prfClearingCompaniesCreateComponent',
    ClearingCompaniesCreateComponent,
  )
  .component(
    'prfClearingCompaniesEditComponent',
    ClearingCompaniesEditComponent,
  )
  .component(
    'prfClearingCompaniesManageComponent',
    ClearingCompaniesManageComponent,
  )
  .component(
    'prfClearingCompanyCredentialsEditorComponent',
    ClearingCompanyCredentialsEditorComponent,
  )
  .component(
    'prfUploadClearingCompaniesFile',
    uploadClearingCompaniesFileComponent,
  );

export default clearingCompanies.name;
