import * as _ from '@proftit/lodash';
import BaseService from '~/source/common/services/baseService';

import moment from 'moment';

export default class BaseFormParser extends BaseService {
  model;

  parse(model) {
    const parsedData = {};

    this.model = model;

    this.preParse();

    _.eachEs(this.map, (parsers, fieldName) => {
      if (this.model[fieldName] === undefined) {
        return;
      }

      parsedData[fieldName] = this.compose(parsers, fieldName);
    });

    return this.postParse(_.extendEs(this.model, parsedData));
  }

  get keysMap() {
    return {};
  }

  get map() {
    return {};
  }

  /**
   * Receives value of date in described format and returns
   * moment object
   *
   * @param {String} format
   * @param {Mixed} value
   * @returns {Moment}
   */
  getMoment(format, value) {
    return moment(value, format);
  }

  /**
   *
   * @param {Object} parsers
   * @param {String} filedName
   */
  compose(parsers, filedName) {
    const methods = parsers.map((obj) =>
      // @ts-ignore
      _.partialEs(this[obj.method], ...(<any[]>obj.params)),
    );

    return (<any>_.flowRightEs)(...methods)(this.model[filedName]);
  }

  preParse() {}

  postParse(parsedData) {
    _.eachEs(this.keysMap, (newKey: string, oldKey) => {
      if (parsedData[oldKey] === undefined) {
        // continue
        return;
      }

      parsedData[newKey] = parsedData[oldKey];

      delete parsedData[oldKey];
    });

    return parsedData;
  }
}
