import TableController from '~/source/common/components/table/table.controller';
import template from './platforms-manage.html';
import { Brand } from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { BrandsService } from '~/source/management/brand/services/brands';

class PlatformsApiManageController extends TableController {
  brandsService: () => BrandsService;
  brand: Brand;

  $onInit() {
    super.$onInit();
    this.fetchBrand(this.$stateParams.brandId);

    this.$scope.$watch('$ctrl.brand.platformConnections.length', (len) =>
      this.onPlatformsNumberChange(len),
    );
    this.$scope.$on('platform:setDefault', (e, platform) =>
      this.onDefaultPlatformSet(platform),
    );
  }

  /*
   * todoOld: It seems the controller is not used as table controller. There is no table in the tempalte.
   * We need to remove the inheritance.
   */
  fetchFn(): RestService {
    throw new Error('not implemented');
  }

  /**
   * Fetch brand and set it to 'this.brand'
   * @param {number} brandId - Brand id to fetch
   */
  fetchBrand(brandId) {
    this.brandsService()
      .setConfig({ blockUiRef: 'platformsForm' })
      .embed(['platformConnections', 'currencyConnections'])
      .expand([
        'platformType',
        'platformConnections.platform',
        'platformConnections.platform:targetPlatform',
        'currencyConnections.currency',
      ])
      .getOneWithQuery(brandId)
      .then((brand) => {
        this.brand = brand.plain();
        // make sure platformConnections is an array
        this.brand.platformConnections = this.brand.platformConnections || [];
      });
  }

  /**
   * Called when the number of brand platforms changes.
   *
   * If the brand's platform number drops to 1 - set the remaining platform as default
   * @param {number} newLen
   */
  onPlatformsNumberChange(newLen) {
    if (newLen === 1) {
      /*
       * brand now has only one platform. set as default
       * Note: since we are not saving here, it would only affect the view
       * but it doesn't matter, since the server also does the same
       */
      this.brand.platformConnections[0].isDefault = true;
    }
  }

  /**
   * Called on a default platform set event.
   *
   * Changes all other platforms 'isDefault' to false.
   * @param {object} newDefaultPlatform - the new default platform object
   */
  onDefaultPlatformSet(newDefaultPlatform) {
    const otherPlatforms = this.brand.platformConnections.filter(
      (plat) => plat !== newDefaultPlatform,
    );

    // change all other platforms 'is default' flag to false.
    otherPlatforms.forEach((el) => {
      el.isDefault = false;
    });
  }
}

PlatformsApiManageController.$inject = [
  '$stateParams',
  'brandsService',
  ...TableController.$inject,
];

export default {
  template,
  controller: PlatformsApiManageController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
