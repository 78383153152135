import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';

const styles = require('./cfd-platform-group-form.component.scss');
import ng from 'angular';
import BaseController from '~/source/common/controllers/base';
import { CfdPlatformGroup } from '~/source/common/models/cfd-platform/cfd-platform-group';
import template from './cfd-platform-group-form.html';

import { useStream } from '~/source/common/utilities/use-stream';
import * as rx from '@proftit/rxjs';
import {
  createNgModelObsMediator,
  NgModelChange,
} from '~/source/common/utilities/create-ng-model-obs-mediator';
import { definePropSwapRatioAsPercentage } from '../../utilities/define-prop-swap-ratio-as-percentage';
import * as _ from '@proftit/lodash';
import { FormControl } from '@proftit/ng1.reactive-forms';

export class CfdPlatformGroupFormController extends BaseController {
  styles = styles;

  /* Bindings */
  group: CfdPlatformGroup;
  onChangeOfGroup: (a: { change: NgModelChange }) => {};
  onChangeOfFormValidity: (a: { isValid: boolean }) => {};

  unsub$ = new rx.Subject<void>();
  groupIn$ = new rx.BehaviorSubject<CfdPlatformGroup>(null);
  groupOut$ = new rx.Subject<NgModelChange>();
  groupModel = {};
  platformGroupForm;
  minInvestmentFormControl = new FormControl<number>(null);
  maxInvestmentFormControl = new FormControl<number>(null);
  minInvestmentUpdate$ = this.streamMinInvestmentForUpdate();
  maxInvestmentUpdate$ = this.streamMaxInvestmentForUpdate();

  /*@ngInject */
  constructor(readonly $validation) {
    super();
  }

  $onInit() {
    this.groupModel = createNgModelObsMediator(
      ['name', 'spread', 'leverage', 'swapCommission'],
      this.groupIn$,
      this.groupOut$,
    );
    definePropSwapRatioAsPercentage(
      this.groupModel,
      this.groupIn$,
      this.groupOut$,
    );
    useStream(this.streamModelOut(), this.unsub$);
    useStream(this.streamFormValidOut(), this.unsub$);
    useStreams(
      [
        this.streamMinInvestment(),
        this.streamMaxInvestment(),
        this.minInvestmentFormControl.value$,
        this.maxInvestmentFormControl.value$,
      ],
      this.unsub$,
    );
  }

  streamMinInvestment() {
    return rx.pipe(
      () => this.groupIn$,
      rx.filter((group) => !_.isNil(group)),
      rx.tap((group) => {
        this.minInvestmentFormControl.setValueAsFirst(group.minInvestment);
      }),
    )(null);
  }

  streamMaxInvestment() {
    return rx.pipe(
      () => this.groupIn$,
      rx.filter((group) => !_.isNil(group)),
      rx.tap((group) => {
        this.maxInvestmentFormControl.setValueAsFirst(group.maxInvestment);
      }),
    )(null);
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  onGroupChange(newGroup) {
    this.groupIn$.next(newGroup);
  }

  streamMinInvestmentForUpdate() {
    return rx.pipe(
      () =>
        this.minInvestmentFormControl.value$.pipe(rx.distinctUntilChanged()),
      rx.withLatestFrom(this.minInvestmentFormControl.isAtFirstValue$),
      rx.filter(([minInvestment, isAtFirstValue]) => !isAtFirstValue),
      rx.map(([minInvestment]) => {
        return {
          fieldName: 'minInvestment',
          nextValue: minInvestment,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamMaxInvestmentForUpdate() {
    return rx.pipe(
      () =>
        this.maxInvestmentFormControl.value$.pipe(rx.distinctUntilChanged()),
      rx.withLatestFrom(this.maxInvestmentFormControl.isAtFirstValue$),
      rx.filter(([maxInvestment, isAtFirstValue]) => !isAtFirstValue),
      rx.map(([maxInvestment]) => {
        return {
          fieldName: 'maxInvestment',
          nextValue: maxInvestment,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamModelOut() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.groupOut$,
          this.minInvestmentUpdate$,
          this.maxInvestmentUpdate$,
        ),
      rx.tap((change) => this.onChangeOfGroup({ change })),
      shareReplayRefOne(),
    )(null);
  }

  streamFormValidOut() {
    return rx.pipe(
      () => this.groupOut$,
      rx.debounceTime(150),
      rx.map(() => this.$validation.checkValid(this.platformGroupForm)),
      rx.tap((isValid) => this.onChangeOfFormValidity({ isValid })),
    )(null);
  }
}

export const CfdPlatformGroupFormComponent = {
  template,
  controller: CfdPlatformGroupFormController,
  bindings: {
    group: '<',
    onChangeOfGroup: '&',
    operation: '<',
    showSwapCommission: '<',
    onChangeOfFormValidity: '&',
  },
};

export default CfdPlatformGroupFormComponent;
