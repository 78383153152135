import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class FeesNotificationsController extends NotificationsController {}

FeesNotificationsController.$inject = [
  'feesSocketService',
  ...NotificationsController.$inject,
];

export default {
  ...ComponentOptions,
  controller: FeesNotificationsController,
};
