import log from 'loglevel';

export function httpRunConfig($httpProvider, appConfig) {
  /*
   * configure $http service to combine processing of multiple http responses received at
   * around the same time via $rootScope.$applyAsync to get better performance gain in production
   */
  $httpProvider.useApplyAsync(true);

  /**
   * Configure "global" $http interceptors.
   * These interceptors will work for $http and all other services which use $http (such as restangular)
   * If you need something only for restangular and not globally - do it as a restangular interceptor instead.
   */
  $httpProvider.interceptors.push([
    '$injector',
    ($injector) => ({
      // Request interceptor to add token data
      request: (config) => {
        const tokensService = $injector.get('tokensService');
        const appTag = $injector.get('prfAppTag');
        const user = tokensService.getCachedUser();

        // send browser info fingerprint on each crm api server request.
        if (config.url.startsWith(appConfig.connections.api)) {
          config.headers['X-Api-Key'] = appTag.murmur;
        }

        if (user && config.url.indexOf(appConfig.connections.api) !== -1) {
          /*
           * if logged in, set authorization header.
           * This is done on the basic $http service and not restangular
           * so it would affect non restangular requests too.
           */
          config.headers.Authorization = user.jwt;
          log.debug('[%s] %s Token: \n%s', config.method, config.url, user.jwt);
        }

        return config;
      },
    }),
  ]);
}
httpRunConfig.$inject = ['$httpProvider', 'appConfig'];
export default httpRunConfig;
