import ng, { IScope } from 'angular';
import type { StateService } from '@uirouter/angularjs';
import _ from 'underscore';

import BaseController from '~/source/common/controllers/base';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import ModelNormalizerService from '~/source/common/services/model-normalizer';

import template from './edit.html';

interface BrandCurrency {
  id: number;
  currency: any;
  value: number;
}

class Clearing3dSettingsEditController extends BaseController {
  threeDConnections: {
    minimumDeposits: BrandCurrency[];
    countries: any;
    clearingCompany: any;
    brand: any;
  };
  model: any;
  brandId: number;
  brandsServiceInstance: BrandsService;
  id: number;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $state: StateService,
    readonly $stateParams: any,
    readonly brandsService: () => BrandsService,
    readonly modelNormalizer: ModelNormalizerService,
    ...args
  ) {
    super(
      $scope,
      $state,
      $stateParams,
      brandsService,
      modelNormalizer,
      ...args,
    );

    Object.assign(this, {
      brandsServiceInstance: this.brandsService(),

      // these values don't change
      brandId: this.$stateParams.brandId,
      id: this.$stateParams.threeDSettingsId,

      threeDConnections: {
        minimumDeposits: [],
        countries: [],
        clearingCompany: {},
        brand: {},
      },
    });

    // get brand & 3d settings
    Promise.all([this.loadBrand(), this.loadThreeDObject()]).then((values) => {
      const brand = values[0].plain();
      const threeD = values[1].plain();
      this.model = values[1];
      let defaultCurrencies;

      /*
       * merge brand currencies with 3d currencies
       * we want to get to latest list of currencies from the brand, and the minimumDeposit values
       * from the 3D's
       */
      defaultCurrencies = brand.currencyConnections.map((brandCurrency) => {
        /*
         * 3D's currencies are based on the currencies already defined for the current brand.
         * so we need to check if brand currency have record in 3ds settings
         */
        const threeDConnection = _.find(
          threeD.minimumDeposits,
          (d: BrandCurrency) => brandCurrency.currency.id === d.currency.id,
        );

        const currencyObject = threeDConnection || brandCurrency;

        const brandCurrencyTransform: BrandCurrency = {
          id: currencyObject.id,
          currency: currencyObject.currency,
          value: currencyObject.value || 0,
        };

        return brandCurrencyTransform;
      });

      // create our 3D's connection object
      this.threeDConnections = {
        brand,
        minimumDeposits: defaultCurrencies,

        /*
         * get first record from countries. its the same in all the records. because these results
         * are filtered by countries & brand
         */
        countries: threeD ? threeD.countries : {},

        /*
         * get first record from clearingCompany. its the same in all the records. because these results
         * are filtered by countries & brand
         */
        clearingCompany: threeD ? threeD.clearingCompany : {},
      };
    });
  }

  /**
   * load brand data*
   * @returns {Promise}
   */
  loadBrand() {
    return this.brandsServiceInstance
      .embed(['currencyConnections', 'platformConnections'])
      .expand(['file:logo', 'currencyConnections.currency'])
      .getOneWithQuery(this.brandId)
      .then((data) => data);
  }

  /**
   * Load brand 3D's settings
   * @returns {Promise}
   */
  loadThreeDObject() {
    return this.brandsServiceInstance
      .getThreeDSpecificResource(this.brandId, this.id)
      .embed(['countries', 'minimumDeposits'])
      .expand(['minimumDeposits.currency', 'clearingCompany'])
      .getOneWithQuery();
  }

  /**
   * Update data for 3D's settings model.
   *
   * @returns {void}
   */
  update() {
    const normalized3dSettings = this.modelNormalizer.normalize(
      _.omit(this.threeDConnections, ['brand', 'minimumDeposits']),
    );
    const normalizedMinimumDeposits = this.brandsServiceInstance.normalizeThreeDMinimumDeposit(
      this.threeDConnections.minimumDeposits,
    );
    normalized3dSettings.minimumDeposits = normalizedMinimumDeposits;

    this.model.patch(normalized3dSettings).then((data) => {
      this.$state.go('^.list.table', { brandId: this.brandId });
    });
  }
}

const Clearing3dSettingsEditComponent = {
  template,
  controller: Clearing3dSettingsEditController,
  controllerAs: 'vm',
};

export default Clearing3dSettingsEditComponent;
