import { IScope } from 'angular';

import commonModule from '../../index';
import template from './labels.html';
import BaseController from '~/source/common/controllers/base';

class Controller extends BaseController {
  model;

  showHidden: boolean;

  /*@ngInject */
  constructor(readonly $scope: IScope) {
    super();
  }

  $onInit() {
    this.showHidden = false;
  }

  removeLabel(label) {
    this.model.splice(this.model.indexOf(label), 1);
    this.$scope.$emit('labels:label:removed', label);
  }
}

commonModule.component('prfLabels', {
  template,
  bindings: {
    model: '=',
  },
  controller: Controller,
  controllerAs: 'vm',
});
