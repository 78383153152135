import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { isNotEmptyStringFormValidator } from '@proftit/ng1.reactive-forms.validators';

export function buildBrandSecurityForm() {
  return new FormGroup({
    isCaptchaEnabled: new FormControl(false),
    captchaSiteKey: new FormControl('', {
      validators: [isNotEmptyStringFormValidator],
    }),
    captchaSecretKey: new FormControl('', {
      validators: [isNotEmptyStringFormValidator],
    }),
    is2faSignupEnabled: new FormControl(false),
    is2faLoginEnabled: new FormControl(false),
    is2faPswdResetEnabled: new FormControl(false),
  } as any);
}
