import ng from 'angular';
import selectTemplate from './brand-deposit-wire-list.component.html';
import BaseController from '~/source/common/controllers/base';
import BrandService from '~/source/management/brand/services/brands';
import * as _ from '@proftit/lodash';
import { generateUuid } from '@proftit/general-utilities';
import { BrandCurrency } from '~/source/common/models/brand-currency';
import { BrandDepositWireAccount } from '@proftit/crm.api.models.entities/src/brand-deposit-wire-account';

const styles = require('./brand-deposit-wire-list.component.scss');

const LOCAL_GENERATED_ID = 'LOCALID';

class WireListTableController extends BaseController {
  static $inject = ['$scope', 'brandsService'];

  styles = styles;
  brand: any;
  brandCurrencies: BrandCurrency[];
  selectedBrandCurrency: BrandCurrency;
  accountsForCurrency: BrandDepositWireAccount[] = [];
  isEditList: {
    [key: number]: boolean;
  };
  isCurrencyInEdit: boolean;
  bankAccounts: BrandDepositWireAccount[]; // all the bank accounts for the brand
  brandsService: () => BrandService;

  $onInit() {}

  onBrandChange() {
    if (_.isEmpty(this.brand)) {
      return;
    }

    this.isEditList = {};

    if (_.getEs(this.brand, 'id')) {
      this.getWireInfoData(null);
    }
  }

  currencyChanged(brandCurrency: BrandCurrency): void {
    const isEditModeOnArr = Object.values(this.isEditList);
    const isEditModeOn = isEditModeOnArr.some((item) => item);

    if (isEditModeOn) {
      return;
    }

    this.selectedBrandCurrency = brandCurrency;
    if (_.isEmpty(this.bankAccounts)) {
      return;
    }

    const bankAccountsForSelectedCurrency = this.bankAccounts.filter(
      (account) => {
        return account.currencyId === brandCurrency.currency.id;
      },
    );

    if (bankAccountsForSelectedCurrency.length > 0) {
      this.accountsForCurrency = bankAccountsForSelectedCurrency;
    } else {
      this.accountsForCurrency = [this.generateInitialBankAccount()];
    }
  }

  addBankAccount() {
    this.accountsForCurrency = [
      ...this.accountsForCurrency,
      this.generateInitialBankAccount(),
    ];
  }

  generateInitialBankAccount() {
    return {
      _isNew: true,
      id: `${LOCAL_GENERATED_ID}-${generateUuid()}` as any,
      holderName: '',
      holderAddress: '',
      bankName: '',
      bankAddress: '',
      country: null,
      iban: '',
      swiftCode: '',
      noteOnTransfer: '',
      customFields: [],
    };
  }

  whenBankAccountUpdate(
    oldValue: BrandDepositWireAccount,
    newValue: BrandDepositWireAccount,
  ) {
    this.isEditList = _.omit([oldValue.id], this.isEditList);
    this.isEditList = { ...this.isEditList, [newValue.id]: false };
    this.getWireInfoData(newValue.currency.id);
  }

  setEdit(account: BrandDepositWireAccount, isEdit: boolean) {
    this.isEditList[account.id] = isEdit;
    this.isCurrencyInEdit = this.calcCurrencyInEdit();
  }

  calcCurrencyInEdit(): boolean {
    return this.accountsForCurrency.some(
      (account) => this.isEditList[account.id],
    );
  }

  removeBankAccount(
    accountData: BrandDepositWireAccount & { _isNew: boolean },
  ) {
    if (this.accountsForCurrency.length <= 1) {
      return;
    }
    this.deleteWireInfoDataAndUpdateModel(accountData.id, accountData._isNew);
  }

  /**
   * get wire info data for all currencies & select currency(select first as default)
   * @param {number | string} currencyId - currency id
   * @returns {Promise} - restangular promise
   */
  getWireInfoData(currencyId?: number | string): Promise<any> {
    return this.brandsService()
      .getBankAccountResource(this.brand.id)
      .expand(['country'])
      .getListWithQuery()
      .then((accounts) => {
        this.bankAccounts = accounts;
        if (!_.isEmpty(this.brandCurrencies)) {
          let selectedNewCurrency; // select the currency (after adding a new currency + new wire info for it)
          if (currencyId) {
            selectedNewCurrency = this.brandCurrencies.findIndex(
              (item: any) => item.currency.id === currencyId,
            );
          }
          this.currencyChanged(
            this.brandCurrencies[selectedNewCurrency ? selectedNewCurrency : 0],
          );
        }
      });
  }

  deleteWireInfoDataAndUpdateModel(accountId: number | string, isNew: boolean) {
    if (isNew) {
      this.findAccountAndDelete(accountId);
      return;
    }
    this.brandsService()
      .getBankAccountItemResource(this.brand.id, accountId as number)
      .removeWithQuery()
      .then(() => {
        this.findAccountAndDelete(accountId);
      });
  }

  findAccountAndDelete(accountId: number | string) {
    const accountToDelete = this.accountsForCurrency.findIndex((account) => {
      return accountId === account.id;
    });
    this.accountsForCurrency.splice(accountToDelete, 1);
  }
}

export const BrandDepositWireListComponent = {
  controller: WireListTableController,
  template: selectTemplate,
  controllerAs: 'vm',
  bindings: {
    brandCurrencies: '<',
    brand: '<',
  },
};
