import RestService from '~/source/common/services/rest';

const SERVICE_RESOURCE = 'search';
const CUSTOMERS_SEARCH_RESOURCE = 'customers';
const TRADING_ACCOUNTS_SEARCH_RESOURCE = 'tradingAccounts';

export class SearchService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  /**
   * Search for customers
   * @returns {Promise} Restangularized promise which resolves to the search result array
   */
  findCustomers() {
    return this.getOneWithQuery(CUSTOMERS_SEARCH_RESOURCE);
  }

  /**
   * Search for customers, by trading account external id
   * @returns {Promise} Restangularized promise which resolves to the search result array
   */
  findTradingAccounts() {
    return this.getOneWithQuery(TRADING_ACCOUNTS_SEARCH_RESOURCE);
  }
}

export default SearchService.$factorize();
