import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { Brand } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ContentTemplatesService } from '~/source/common/api-crm-server/services/content-templates.service';
import { EmailTemplatePreviewService } from '~/source/common/services/email-template-preview.service';
import template from './inline-content-template-preview.component.html';
import { ContentTemplateTypeCode } from '@proftit/crm.api.models.enums';
import { SystemEmailTemplatesService } from '~/source/common/api-crm-server/services/system-email-templates.service';

const styles = require('./inline-content-template-preview.component.scss');

export class InlineContentTemplatePreviewController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  parentId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'parentId',
  );

  contentTemplateId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'contentTemplateId',
  );

  type$ = observeShareCompChange<string>(this.lifecycles.onChanges$, 'type');

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  contentTemplate$ = this.streamContentTemplate();

  htmlForPreview$ = this.streamHtmlForPreview();

  /*@ngInject */
  constructor(
    readonly contentTemplatesService: () => ContentTemplatesService,
    readonly emailTemplatePreviewService: EmailTemplatePreviewService,
    readonly prfSystemEmailTemplatesService: () => SystemEmailTemplatesService,
  ) {
    useStreams(
      [this.parentId$, this.contentTemplateId$, this.type$, this.brand$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamContentTemplate() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.parentId$.pipe(rx.startWith(null)),
          this.contentTemplateId$,
          this.type$,
        ),
      rx.switchMap(([parentId, id, type]) => {
        if (type === ContentTemplateTypeCode.System) {
          if (_.isNil(parentId)) {
            return rx.obs.EMPTY;
          }

          return this.prfSystemEmailTemplatesService().getSystemEmailTemplateContentTemplateFull(
            parentId,
            id,
          );
        }

        return this.contentTemplatesService().getFullItem(id);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamHtmlForPreview() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.contentTemplate$,
          this.brand$.pipe(rx.startWith(null)),
        ),
      rx.switchMap(([template, brand]) => {
        return this.emailTemplatePreviewService.generateEmailPreview(
          template,
          brand,
        );
      }),
      rx.startWith(''),
      shareReplayRefOne(),
    )(null);
  }
}

export const InlineContentTemplatePreviewComponent = {
  template,
  controller: InlineContentTemplatePreviewController,
  bindings: {
    parentId: '<',
    contentTemplateId: '<',
    brand: '<',
    type: '<',
  },
};
