import * as _ from '@proftit/lodash';
import { switchOn } from '@proftit/general-utilities';
import type { IResponse } from 'restangular';
import { IRestConfig } from '../irest-config';
import { GrowlSuppressMethod } from '../growl-suppress-method';

/**
 * Returns true if growl error should be suppressed.
 *
 * This happens in the following cases:
 * 1. config.suppressGrowl is true ("suppress always")
 * 2. config.suppressGrowl is an array of status codes to suppress growl for, and the current
 *   status code is in this array.
 * @param statusCode - http status code of the server response
 * @returns true if growl error should be suppressed
 */
export function isGrowlSuppressed(response: IResponse, config: IRestConfig) {
  const growlSuppressMethod = calcGrowlSuppressMethod(config.suppressGrowl);

  return switchOn(
    {
      [GrowlSuppressMethod.ByBoolean]: () => {
        return config.suppressGrowl;
      },

      [GrowlSuppressMethod.ByDataCode]: () => {
        const dataCode = _.get(['data', 'code'], response);

        return config.dataCodesForGrowlSuppress.includes(dataCode);
      },

      [GrowlSuppressMethod.ByStatusCode]: () => {
        return (config.suppressGrowl as number[]).includes(response.status);
      },
    },
    growlSuppressMethod,
    () => false,
  );
}

function calcGrowlSuppressMethod(
  suppressGrowl: GrowlSuppressMethod | Number[] | boolean,
): GrowlSuppressMethod {
  if (typeof suppressGrowl === 'boolean') {
    return GrowlSuppressMethod.ByBoolean;
  }

  if (suppressGrowl instanceof Array) {
    return GrowlSuppressMethod.ByStatusCode;
  }

  return suppressGrowl as GrowlSuppressMethod;
}
