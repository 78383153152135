import { Country } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import type { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';
import IElementRestNg from '../models/ielement-rest-ng';
import { CountriesService } from '../services/countries';

export class CountriesStore {
  setCountriesFromLoadOp$ = new rx.Subject<Country[]>();

  countries$ = this.streamCountries();

  systemBlacklisted$ = this.streamSystemBlacklisted();

  constructor(
    readonly countriesService: () => CountriesService,
    readonly lifecycles: ComponentLifecyclesObservables,
  ) {
    useStreams([this.countries$], lifecycles.onDestroy$);
  }

  streamCountries() {
    return rx.pipe(
      () => rx.obs.merge(this.setCountriesFromLoadOp$),
      shareReplayRefOne(),
    )(null);
  }

  streamSystemBlacklisted() {
    return rx.pipe(
      () => this.countries$,
      rx.map((countries) => countries.filter((c) => c.isBlacklisted)),
      shareReplayRefOne(),
    )(null);
  }

  async load() {
    const countries = await this.countriesService()
      .getListWithQuery<IElementRestNg<Country>>()
      .then((data) => data.plain());

    this.setCountriesFromLoadOp$.next(countries);

    return countries;
  }
}
