import BaseController from '~/source/common/controllers/base';
import PasswordResetEmail from '../services/password-reset-email';
import template from './forgot-password.html';
import { StateService } from '@uirouter/core';

export class ForgotPasswordController extends BaseController {
  static $inject = ['passwordResetEmail', '$state'];

  passwordResetEmail: PasswordResetEmail;
  $state: StateService;
  email: string;

  onFormValid() {
    this.passwordResetEmail
      .postWithQuery({ email: this.email })
      .then(() => {
        this.$state.go('.email-sent');
      })
      .catch(() => {
        this.$state.go('.user-not-found');
      });
  }
}

export default {
  template,
  controller: ForgotPasswordController,
  controllerAs: 'vm',
};
