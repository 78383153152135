/* global SHARED_WORKER_FILENAME */

import log from 'loglevel';
import {
  OutputMessageCode,
  MessageCode,
} from '@proftit/crm.client.worker.shared.general.messages';
import * as rx from '@proftit/rxjs';
import { generateUuid } from '../utilities/generate-uuid';
import { useStreams } from '@proftit/rxjs.adjunct';
import {
  registerForWorkerEmission,
  EmissionType,
} from './register-for-worker-emission';
import { UserTokenModel } from '@proftit/crm.api.models.entities';

declare const SHARED_WORKER_FILENAME: { value: string };

const sharedWorkerFilename = SHARED_WORKER_FILENAME;

export class GeneralSharedWorkerService {
  worker: any;

  unsub$ = new rx.Subject<void>();
  setAppFingerprintAction = new rx.Subject<string>();
  setAppConfigAction = new rx.Subject<any>();
  setCurrentLoggedUserAction = new rx.Subject<any>();
  updateCurrentLoggedUserAction = new rx.Subject<UserTokenModel>();
  setIsVoipEnalbedAction = new rx.Subject<boolean>();
  setIsCurrentUserLoggedInAction = new rx.Subject<boolean>();

  worker$ = rx.pipe(
    () =>
      rx.obs.from([
        new SharedWorker(sharedWorkerFilename.value, 'general_shared_worker'),
      ]),
    rx.tap((worker) => worker.port.start()),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  workerMessage$ = rx.pipe(
    () => this.worker$,
    rx.switchMap(
      (worker) =>
        new rx.Observable<MessageEvent>((s) => {
          worker.onerror = (e) => {
            log.error('shared worker general error');
            s.error(e);
          };

          worker.port.onmessageerror = (e) => {
            log.error('shared worker message error');
            s.error(e);
          };

          worker.port.onmessage = (m) => {
            log.debug('shared worker message', m);
            s.next(m);
          };
        }),
    ),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  iNewCall$ = rx.pipe(
    () =>
      registerForWorkerEmission(
        MessageCode.RegisterToNewCall,
        OutputMessageCode.NewCallEmission,
        MessageCode.NotifyHeartbeatNewCall,
        this.workerMessage$,
        this.worker$,
      ),
    rx.filter((p) => p.emissionType === EmissionType.OUTPUT),
    rx.map((p) => p.msg),
    rx.map((m) => m.data.payload.emission),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  iActiveCall$ = rx.pipe(
    () =>
      registerForWorkerEmission(
        MessageCode.RegisterToActiveCall,
        OutputMessageCode.ActiveCallEmission,
        MessageCode.NotifyHeartbeatActiveCall,
        this.workerMessage$,
        this.worker$,
      ),
    rx.filter((p) => p.emissionType === EmissionType.OUTPUT),
    rx.map((p) => p.msg),
    rx.map((m) => m.data.payload.emission),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  setAppFingerprint$ = rx.pipe(
    () => this.setAppFingerprintAction,
    rx.withLatestFrom(this.worker$),
    rx.tap(([appFingerprint, worker]) => {
      worker.port.postMessage({
        type: MessageCode.SetAppFingerprint,
        payload: {
          appFingerprint,
        },
      });
    }),
  )(null);

  setAppConfig$ = rx.pipe(
    () => this.setAppConfigAction,
    rx.withLatestFrom(this.worker$),
    rx.tap(([appConfig, worker]) => {
      worker.port.postMessage({
        type: MessageCode.SetAppConfig,
        payload: {
          appConfig,
        },
      });
    }),
  )(null);

  setCurrentLoggedUser$ = rx.pipe(
    () => this.setCurrentLoggedUserAction,
    rx.withLatestFrom(this.worker$),
    rx.tap(([user, worker]) => {
      worker.port.postMessage({
        type: MessageCode.SetCurrentLoggedUser,
        payload: {
          user,
        },
      });
    }),
  )(null);

  updateCurrentLoggedUser$ = rx.pipe(
    () => this.updateCurrentLoggedUserAction,
    rx.withLatestFrom(this.worker$),
    rx.tap(([user, worker]) => {
      worker.port.postMessage({
        type: MessageCode.UpdateCurrentLoggedUser,
        payload: {
          user,
        },
      });
    }),
  )(null);

  setIsVoipEnalbed$ = rx.pipe(
    () => this.setIsVoipEnalbedAction,
    rx.withLatestFrom(this.worker$),
    rx.tap(([isVoipEnabled, worker]) => {
      worker.port.postMessage({
        type: MessageCode.SetIsVoipEnabled,
        payload: {
          isVoipEnabled,
        },
      });
    }),
  )(null);

  setIsCurrentUserLoggedIn$ = rx.pipe(
    () => this.setIsCurrentUserLoggedInAction,
    rx.withLatestFrom(this.worker$),
    rx.tap(([isCurrentUserLoggedIn, worker]) => {
      worker.port.postMessage({
        type: MessageCode.SetIsCurrentUserLoggedIn,
        payload: {
          isCurrentUserLoggedIn,
        },
      });
    }),
  )(null);

  /* @ngInject */
  constructor(readonly prfAppTag, readonly appConfig) {
    useStreams(
      [
        this.worker$,
        this.setAppFingerprint$,
        this.setAppConfig$,
        this.setCurrentLoggedUser$,
        this.updateCurrentLoggedUser$,
        this.setIsVoipEnalbed$,
        this.setIsCurrentUserLoggedIn$,
      ],
      this.unsub$,
    );

    this.setAppFingerprint(this.prfAppTag.murmur);
    this.setAppConfig(this.appConfig);
  }

  setAppFingerprint(appFingerprint: string) {
    this.setAppFingerprintAction.next(appFingerprint);
  }

  setAppConfig(appConfig) {
    this.setAppConfigAction.next(appConfig);
  }

  setCurrentLoggedUser(user) {
    this.setCurrentLoggedUserAction.next(user);
  }

  updateCurrentLoggedUser(user: UserTokenModel) {
    this.updateCurrentLoggedUserAction.next(user);
  }

  setIsVoipEnalbed(isVoipEnabled: boolean) {
    this.setIsVoipEnalbedAction.next(isVoipEnabled);
  }

  setIsCurrentUserLoggedIn(val: boolean) {
    this.setIsCurrentUserLoggedInAction.next(val);
  }

  get newCall$() {
    return this.iNewCall$;
  }

  get activeCall$() {
    return this.iActiveCall$;
  }
}
