// This file was generated automatically. Tread lightly.

export enum TradingAccountTransactionTypeCode {
  Bonus = 'bonus',
  Creditcard = 'creditcard',
  Ewallet = 'ewallet',
  Manual = 'manual',
  Unset = 'unset',
  Wire = 'wire',
}
