import { Permissions } from '~/source/common/models/permission-structure';

export const attachmentsReportsSettings = {
  table: {
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        sorting: {
          uploadDate: 'desc',
        },
      },
      settings: {
        counts: [],
      },
    },
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      reportsRealContacts: {
        state: {
          isActive: true,
        },
      },
    },
    colsList: [
      {
        title: 'reports.titles.UPLOAD_DATE',
        field: 'uploadDate',
        fieldName: 'uploadDate',
        fieldFormat: '{{ uploadDate }}',
        reportFields: ['uploadDate'],
        filterable: true,
        sortable: 'uploadDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customerId',
        fieldName: 'customerId',
        fieldFormat: '{{ customerId }}',
        reportFields: ['customerId'],
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'CUSTOMER_NAME',
        field: 'customerName',
        fieldName: 'customerName',
        fieldFormat: '{{ customerName }}',
        reportFields: ['customerName'],
        filterable: false,
        sortable: 'customerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.EMAIL',
        field: 'email',
        fieldName: 'email',
        fieldFormat: '{{ email }}',
        reportFields: ['email'],
        filterable: false,
        sortable: 'email',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'PHONE',
        field: 'phone',
        fieldName: 'phone',
        fieldFormat: '{{ phone }}',
        reportFields: ['phone'],
        filterable: false,
        sortable: 'phone',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.CATEGORY',
        field: 'category',
        fieldName: 'category',
        fieldFormat: '{{ category }}',
        reportFields: ['category'],
        filterable: true,
        sortable: 'category',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.DOCUMENT_NAME',
        field: 'documentName',
        fieldName: 'documentName',
        fieldFormat: '{{ documentName }}',
        reportFields: ['documentName'],
        filterable: false,
        sortable: 'documentName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.DOCUMENT_STATUS',
        field: 'documentStatus',
        fieldName: 'documentStatus',
        fieldFormat: '{{ documentStatus }}',
        reportFields: ['documentStatus'],
        filterable: true,
        sortable: 'documentStatus',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.DOCUMENT_EXPIRY_DATE',
        field: 'documentExpiryDate',
        fieldName: 'documentExpiryDate',
        fieldFormat: '{{ documentExpiryDate }}',
        reportFields: ['documentExpiryDate'],
        filterable: true,
        sortable: 'documentExpiryDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'reports.titles.LAST_MODIFIED_DATE',
        field: 'lastModifiedDate',
        fieldName: 'lastModifiedDate',
        fieldFormat: '{{ lastModifiedDate }}',
        reportFields: ['lastModifiedDate'],
        filterable: true,
        sortable: 'lastModifiedDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.VERIFIED_STATUS',
        field: 'reportsCustomerComplianceName',
        fieldName: 'customerComplianceName',
        fieldFormat: '{{ customerComplianceName }}',
        reportFields: ['customerComplianceName'],
        filterable: true,
        sortable: 'customerComplianceName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.OFFER_NAME',
        field: 'offerName',
        fieldName: 'offerName',
        fieldFormat: '{{ offerName }}',
        reportFields: ['offerName'],
        filterable: false,
        sortable: 'offerName',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'auditLogs.MARKETING_INFO',
        field: 'marketingInfo',
        fieldName: 'marketingInfo',
        fieldFormat: '{{ marketingInfo }}',
        reportFields: ['marketingInfo'],
        filterable: false,
        sortable: 'marketingInfo',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.CAMPAIGN',
        field: 'reportsCampaign',
        fieldName: 'campaign',
        fieldFormat: '{{ campaign }}',
        reportFields: ['campaign'],
        filterable: true,
        sortable: 'campaign',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.DESK',
        field: 'reportsDesk',
        fieldName: 'reportsDesk',
        fieldFormat: '{{ desk }}',
        reportFields: ['desk'],
        filterable: true,
        sortable: 'desk',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'BRAND',
        field: 'reportsBrand',
        fieldName: 'reportsBrand',
        fieldFormat: '{{ brand }}',
        reportFields: ['brand'],
        filterable: true,
        sortable: 'brand',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_CALL_DATE',
        field: 'lastCallDate',
        fieldName: 'lastCallDate',
        fieldFormat: '{{ lastCallDate }}',
        reportFields: ['lastCallDate'],
        filterable: true,
        sortable: 'lastCallDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'contact.dashboard.titles.LAST_LOGIN_DATE',
        field: 'lastLoginDate',
        fieldName: 'lastLoginDate',
        fieldFormat: '{{ lastLoginDate }}',
        reportFields: ['lastLoginDate'],
        filterable: true,
        sortable: 'lastLoginDate',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'common.COUNTRY',
        field: 'reportsCountry',
        fieldName: 'country',
        fieldFormat: '{{ country }}',
        reportFields: ['country'],
        filterable: true,
        sortable: 'country',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.FTD_DAYS_COUNT',
        field: 'ftdDaysCount',
        fieldName: 'ftdDaysCount',
        fieldFormat: '{{ ftdDaysCount }}',
        reportFields: ['ftdDaysCount'],
        filterable: true,
        sortable: 'ftdDaysCount',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'contact.LAST_CALL_STATUS',
        field: 'lastCallStatus',
        fieldName: 'lastCallStatus',
        fieldFormat: '{{ lastCallStatus }}',
        reportFields: ['lastCallStatus'],
        filterable: true,
        sortable: 'lastCallStatus',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'PHONE2',
        field: 'phone2',
        fieldName: 'phone2',
        fieldFormat: '{{ phone2 }}',
        reportFields: ['phone2'],
        filterable: false,
        sortable: 'phone2',
        removable: true,
        show: false,
        type: 'common',
      },
      {
        title: 'reports.titles.REGISTRATION_DATE_COUNT',
        field: 'registrationDateCount',
        fieldName: 'registrationDateCount',
        fieldFormat: '{{ registrationDateCount }}',
        reportFields: ['registrationDateCount'],
        filterable: true,
        sortable: 'registrationDateCount',
        removable: true,
        show: false,
        type: 'common',
      },
    ],
  },
  assignUsersLimit: 10000,
  userEvents: {
    toggleItem: 'toggleItem',
    paging: 'paging',
  },
};
