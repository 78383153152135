import Url from 'url-parse';
import * as _ from '@proftit/lodash';
import {
  init,
  setProtocol,
  setHost,
  setResource,
} from '@proftit/request-client';
import { removeColonAtEnd } from '@proftit/general-utilities';

export function initReq(resourcePath: string, urlStr: string) {
  const url = new Url(urlStr);

  return _.flow([
    () => init(),
    (req) => setProtocol(removeColonAtEnd(url.protocol), req),
    (req) => setHost(url.host, req),
    (req) => setResource(resourcePath, req),
  ])();
}
