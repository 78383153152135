import TableController from '~/source/common/components/table/table.controller';
import SignupFormsService from '../signup-forms.service';
import ModalService from '~/source/common/components/modal/modal.service';
import AppConfig from '~/source/conf/appConfig';

import formCodeTemplate from '../view-form-code-modal.html';
import template from './signup-form-list.component.html';

class SignupFormListController extends TableController {
  static $inject = [
    ...TableController.$inject,
    '$scope',
    'signupFormsService',
    'appConfig',
    'signupFormModuleSettings',
    'modalService',
  ];

  signupFormsService: SignupFormsService;
  signupFormModuleSettings: any;
  modalService: ModalService;
  appConfig: typeof AppConfig;
  dataServiceInstance: SignupFormsService;
  settings: any;
  cols: any;

  // Disable params caching: unlike the contacts list, we don't want it to remember current page/sort etc
  filterCacheKey = null;
  tableCacheKey = null;

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.signupFormsService;
    this.settings = Object.assign({}, this.signupFormModuleSettings);
    this.cols = [...this.signupFormModuleSettings.cols];

    this.initTable();
  }

  get tableKey() {
    return 'signupForm';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .setConfig({ blockUiRef: this.blockUiKey })
      .expand(['brand']);
  }

  /**
   * Open a modal showing the form code
   * @param {object} form - Form model
   */
  showFormCode(form) {
    this.modalService.open({
      controller: 'FormBuilderViewCodeModalController',
      template: formCodeTemplate,
      scope: this.$scope,
      data: {
        lang: this.appConfig.defaultLang,
        formKey: form.formKey,
        brandId: form.brand.id,
        pluginUrl: this.appConfig.pluginUrls.bundle,
      },
    });
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * deletes list item & reload table
   * @param {Object} item - Restangular model
   * @returns {Promise}
   */
  remove(item) {
    return item.remove().then(() => this.reloadTable());
  }
}

export default {
  template,
  controller: SignupFormListController as any,
  controllerAs: 'vm',
};
