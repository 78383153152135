import * as _ from '@proftit/lodash';
import { FieldProxyHandler } from '../field-proxy-handler';

export const IS_NUMBER_IN_RANGE = 'IS_NUMBER_IN_RANGE';

export function isNumberInRangeProxyValidatorFactory(min: number, max: number) {
  return function isNumberBelowProxyValidator(handler: FieldProxyHandler<any>) {
    if (handler.value > max || handler.value < min) {
      return {
        code: IS_NUMBER_IN_RANGE,
        isValid: false,
      };
    }

    return {
      code: IS_NUMBER_IN_RANGE,
      isValid: true,
    };
  };
}
