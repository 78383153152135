import s from 'underscore.string';
import commonModule from '../index';

/**
 * Run an arbitrary Underscore.string on the input.
 * @see {@link https://github.com/epeli/underscore.string}
 * @example:
 * {{'2.556' | underscoreString: 'toNumber'}}
 * --> 3
 *
 * This will convert the product code (e.g. LongTerm) to dash format (long-term)
 *
 * It is also possible to pass additional arguments
 * @example
 * {{'2.556' | underscoreString: 'toNumber': 1}}
 * --> 2.6
 *
 * @return {function(*=, *, *=)}
 */
function underscoreString() {
  /**
   * If function was specified - run it, along with any params passed as array
   * Otherwise - return input as-is.
   */
  return (input, fn, ...params) => (fn ? s[fn](input, ...params) : input);
}

commonModule.filter('underscoreString', underscoreString);
