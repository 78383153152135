import ng from 'angular';
import generalTemplate from './templates/general.html';
import offlineTemplate from './templates/offline.html';
import sessionExpiredTemplate from './templates/session-expired.html';
import unauthorizedTemplate from './templates/unauthorized.html';
import notFoundTemplate from './templates/not-found.html';
import { LoggedInFromAnotherLocationErrorComponent } from './components/logged-in-from-another-location-error/logged-in-from-another-location-error.component';

export const ErrorModule = ng
  .module('crm.error', ['crm.common', 'ui.router'])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    ($stateProvider, $urlRouterProvider) => {
      // Define what to do in invalid routes: redirect to not-found page
      $urlRouterProvider.otherwise('/error/not-found');
      $stateProvider
        .state('error', {
          abstract: true,
          url: '/error',
          template: '<ui-view/>',
        })
        .state('error.general', {
          url: '/general',
          template: generalTemplate,
        })
        .state('error.offline', {
          url: '/offline',
          template: offlineTemplate,
        })
        .state('error.session-expired', {
          url: '/session-expired',
          template: sessionExpiredTemplate,
        })
        .state('error.unauthorized', {
          url: '/unauthorized',
          template: unauthorizedTemplate,
        })
        .state('error.loggedInFromAnotherLocation', {
          url: '/logged-in-from-another-location?username&ip&country&browser',
          component: 'prfLoggedInFromAnotherLocationError',
        })
        .state('error.not-found', {
          url: '/not-found',
          template: notFoundTemplate,
        });
    },
  ]);

ErrorModule.component(
  'prfLoggedInFromAnotherLocationError',
  LoggedInFromAnotherLocationErrorComponent,
);
