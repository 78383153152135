import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './edit-confirm-line.component.html';
const styles = require('./edit-confirm-line.component.scss');

export class EditConfirmLineController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const EditConfirmLineComponent = {
  template,
  controller: EditConfirmLineController,
  bindings: {
    isInEdit: '<',
    isEditEnabled: '<',
    hideEditButton: '<',
    onCancel: '&',
    onConfirm: '&',
    onEdit: '&',
  },
};
