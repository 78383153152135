import Big from 'big.js';

export function isBigJsNumber(value: any) {
  try {
    Big(value);
    return true;
  } catch {
    return false;
  }
}
