import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { StateService, StateParams } from '@uirouter/core';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './crypto-ewallet-editor.component.html';
import { FormArray, FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import { Brand, Currency } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { BrandsService } from '~/source/management/brand/services/brands';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';
import { Editable } from '~/source/common/utilities/editable';
import Ewallet from '~/source/common/models/ewallet';
import { isNotNilFormValidator } from '@proftit/ng1.reactive-forms.validators';
import { UserCurrenciesService } from '~/source/common/services/user-currencies.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { BrandCurrency } from '~/source/common/models/brand-currency';
import { generateUuid } from '@proftit/general-utilities';

const styles = require('./crypto-ewallet-editor.component.scss');

export class CryptoEwalletEditorController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  blockUiRef = generateBlockuiId();

  editManager = new Editable<any>();
  addEwalletAddressAction = new rx.Subject<void>();
  deleteAddressAction$ = new rx.Subject<void>();

  isCreateAction$ = this.streamIsCreateAction();
  brands$ = this.streamBrands();
  formGroup$ = this.streamFormGroup();
  ewallets$ = this.streamEwallets();
  ewalletDataArray$ = this.streamEwalletDataArrayFromFormGroup();
  isFormGroupValid$ = this.streamIsFormGroupValid();
  selectedBrandFromFormGroup$ = this.streamSelectedBrandInFormGroup();
  cryptoCurrencies$ = this.streamCryptoCurrencies();
  ewalletsForBrand$ = this.streamEwalletsForBrand();
  cryptoCurrenciesFromIsCreate$ = this.streamCryptoCurrenciesFromIsCreate();

  /* @ngInject */
  constructor(
    readonly userCurrenciesService: UserCurrenciesService,
    readonly brandsService: () => BrandsService,
    readonly ewalletsService: EwalletsService,
    readonly $state: StateService,
    readonly $stateParams: StateParams,
  ) {
    useStreams(
      [
        this.editManager.initiator$,
        this.isCreateAction$,
        this.brands$,
        this.cryptoCurrencies$,
        this.ewallets$,
        this.formGroup$.pipe(rx.switchMap((x) => x.value$)),
        this.ewalletDataArray$,
        this.isFormGroupValid$,
        this.streamOnCancel(),
        this.streamOnSave(),
        this.selectedBrandFromFormGroup$,
        this.ewalletsForBrand$,
        this.cryptoCurrenciesFromIsCreate$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamEwalletsForBrand() {
    return rx.pipe(
      () => this.selectedBrandFromFormGroup$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((brand) => {
        return rx.obs
          .from(
            this.brandsService()
              .expand(['currency'])
              .getBrandEwalletCurrencies(brand.id)
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEwalletDataArrayFromFormGroup() {
    return rx.pipe(
      () => this.formGroup$.pipe(rx.switchMap((x) => x.controls$)),
      rx.map((controls) => {
        return controls.ewalletData;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormGroup() {
    const currentFormGroup = new rx.BehaviorSubject<FormGroup>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamFormGroupFromInit(),
          this.streamFormGroupFromServerModel(),
          this.streamFormGroupFromEwalletAddressAdd(currentFormGroup),
          this.streamFormGroupFromEwalletAddressDelete(currentFormGroup),
        ),
      rx.tap((formGroup) => {
        currentFormGroup.next(formGroup);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamIsFormGroupValid() {
    return rx.pipe(
      () => this.formGroup$.pipe(rx.switchMap((x) => x.isValid$)),
      shareReplayRefOne(),
    )(null);
  }

  streamFormGroupFromInit() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.withLatestFrom(this.isCreateAction$),
      rx.map(([a, isCreate]) => this.generateFormGroup(isCreate)),
      shareReplayRefOne(),
    )(null);
  }

  streamFormGroupFromServerModel() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.isCreateAction$, this.brands$),
      rx.filter(([a, brands]) => !a),

      rx.switchMap(() => {
        const { currencyId, brandId } = this.$stateParams;
        return rx.obs
          .from(
            this.brandsService()
              .getBrandEwalletsResource(brandId)
              .filter({ currencyId })
              .expand(['currency', 'ewallet', 'brand'])
              .setConfig({ blockUiRef: this.blockUiRef })
              .getListWithQuery(),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((serverModel) => {
        return this.serverToClientModelMorph(serverModel.plain());
      }),
      rx.map((serverModel) => this.generateFormGroup(false, serverModel)),
      shareReplayRefOne(),
    )(null);
  }

  serverToClientModelMorph(serverModel) {
    return serverModel.reduce(
      (acc, ewalletItem: any) => {
        if (_.isNil(acc.brand)) {
          acc.brand = { ...ewalletItem.brand, label: ewalletItem.brand.name };
        }

        if (_.isNil(acc.currency)) {
          acc.currency = {
            ...ewalletItem.currency,
            label: ewalletItem.currency.name,
          };
        }

        if (_.isNil(acc.depositRemarks)) {
          acc.depositRemarks = ewalletItem.depositRemarks;
        }
        const { addresses } = ewalletItem.credentials;
        const ewalletData = addresses.map((address) => {
          return {
            ewalletAddress: address,
            ewallet: {
              ...ewalletItem.ewallet,
              label: ewalletItem.ewallet.name,
            },
          };
        });
        acc.ewalletData = [...acc.ewalletData, ...ewalletData];
        return acc;
      },
      { ewalletData: [] },
    );
  }

  currencyCompare(a: BrandCurrency, b: Currency) {
    return _.get(['currency', 'id'], a) === _.get(['id'], b);
  }

  generateFormGroup(isCreate: boolean, model: any = {}, fromAction = null) {
    return new FormGroup({
      brand: new FormControl<Brand>(!_.isNil(model) ? model.brand : null, {
        validators: [isNotNilFormValidator],
      }),
      currency: new FormControl<Currency>(
        !_.isNil(model) ? model.currency : null,
        { validators: [isNotNilFormValidator] },
      ),
      depositRemarks: new FormControl<string>(
        !_.isNil(model) ? model.depositRemarks : null,
      ),
      ewalletData: this.generateEwalletDataFormArray(
        isCreate,
        !_.isNil(model) ? model.ewalletData : [],
        fromAction,
      ),
    } as any);
  }

  generateEwalletDataFormArray(
    isCreate: boolean,
    data = [],
    fromAction = null,
  ) {
    const emptyObject = {
      ewallet: null,
      ewalletAddress: null,
    };
    const dataCopy = _.cloneDeep(data);
    if (
      _.isNil(fromAction) &&
      isCreate &&
      (data.length === 0 ||
        !_.isEqual(dataCopy[dataCopy.length - 1], emptyObject))
    ) {
      dataCopy.push(emptyObject);
    }
    const formGroupsToAdd = dataCopy.map((item) => {
      return new FormGroup({
        ewallet: new FormControl<Ewallet>(
          !_.isNil(item) ? item.ewallet : null,
          { validators: [isNotNilFormValidator] },
        ),
        ewalletAddress: new FormControl<string>(
          !_.isNil(item) ? item.ewalletAddress : null,
          { validators: [isNotNilFormValidator] },
        ),
        ewalletLocalGuid: new FormControl<string>(generateUuid()),
      } as any);
    });
    const formArray = new FormArray(formGroupsToAdd);
    return formArray;
  }

  streamFormGroupFromEwalletAddressAdd(
    currentFormGroup: rx.BehaviorSubject<FormGroup>,
  ) {
    return rx.pipe(
      () => this.addEwalletAddressAction,
      rx.withLatestFrom(currentFormGroup, this.isCreateAction$),
      rx.map(([a, formGroup, isCreate]) => {
        return this.generateFormGroup(isCreate, {
          ...formGroup.value,
          ewalletData: [
            ...formGroup.value.ewalletData,
            {
              ewallet: null,
              ewalletAddress: null,
            },
          ],
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormGroupFromEwalletAddressDelete(
    currentFormGroup: rx.BehaviorSubject<FormGroup>,
  ) {
    return rx.pipe(
      () => this.deleteAddressAction$,
      rx.withLatestFrom(currentFormGroup, this.isCreateAction$),
      rx.map(([addressRowGuidToDelete, formGroup, isCreate]) => {
        const newEwalletData = formGroup.value.ewalletData.filter(
          (addressData) =>
            addressData.ewalletLocalGuid !== addressRowGuidToDelete,
        );
        return this.generateFormGroup(
          isCreate,
          {
            ...formGroup.value,
            ewalletData: [...newEwalletData],
          },
          'delete',
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSelectedBrandInFormGroup() {
    return rx.pipe(
      () =>
        this.formGroup$.pipe(
          rx.switchMap((x) => x.controls$),
          rx.switchMap((controls) => controls.brand.value$),
          rx.filter((x) => !_.isNil(x)),
          rx.distinctUntilKeyChanged('id' as any),
        ),
      shareReplayRefOne(),
    )(null);
  }

  streamCryptoCurrencies() {
    return rx.pipe(
      () => this.selectedBrandFromFormGroup$,
      rx.switchMap((brand) => {
        return rx.obs
          .from(
            this.brandsService()
              .getCurrenciesResource(brand.id)
              .setConfig({ blockUiRef: this.blockUiRef })
              .expand('currency')
              .filter({ 'currency.isCrypto': true })
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((currencies) => {
        return currencies.map((c) => {
          return {
            ...c,
            label: c.currency.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCryptoCurrenciesFromIsCreate() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.cryptoCurrencies$,
          this.isCreateAction$,
          this.ewalletsForBrand$,
        ),
      rx.map(
        ([
          allCryptoCurrenciesForBrand,
          isCreate,
          alreadyConfiguredEwallets,
        ]) => {
          if (!isCreate) {
            const { currencyId } = this.$stateParams;
            return allCryptoCurrenciesForBrand.filter(
              (currencyConnection) =>
                currencyConnection.currency.id === Number(currencyId),
            );
          }
          return allCryptoCurrenciesForBrand.filter((currencyConnection) => {
            return _.isNil(
              alreadyConfiguredEwallets.find(
                (ewallet) =>
                  ewallet.currency.id === currencyConnection.currency.id,
              ),
            );
          });
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamBrands() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.brandsService()
              .setConfig({ blockUiRef: this.blockUiRef })
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((brands) => {
        return brands.map((b) => {
          return {
            ...b,
            label: b.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamEwallets() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(
            this.ewalletsService
              .setConfig({ blockUiRef: this.blockUiRef })
              .getListWithQuery()
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.map((ewallets) => {
        return ewallets.map((e) => {
          return {
            ...e,
            label: e.name,
          };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnCancel() {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.tap(() => {
        this.$state.go('^.list');
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamOnSave() {
    return rx.pipe(
      () => this.editManager.saveAction,
      rx.withLatestFrom(this.formGroup$),
      rx.switchMap(([a, formGroup]) => {
        const valueToSend = this.normalize(formGroup.value);
        return rx.obs
          .from(
            this.brandsService()
              .getBrandEwalletsMassUpdateResource(formGroup.value.brand.id)
              .setConfig({ blockUiRef: this.blockUiRef })
              .customPutWithQuery(valueToSend) as any,
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap(() => {
        this.$state.go('^.list');
      }),
      shareReplayRefOne(),
    )(null);
  }

  normalize(formGroupValue) {
    const {
      currency: brandCurrencyConnection,
      ewalletData,
      depositRemarks,
    } = formGroupValue;
    const ewalletIdToAddressesMapping = ewalletData.reduce((acc, currData) => {
      const { ewallet, ewalletAddress } = currData;
      const addressesForEwallet = !_.isNil(acc[ewallet.id])
        ? acc[ewallet.id]
        : [];
      acc[ewallet.id] = [...addressesForEwallet, ewalletAddress];
      return acc;
    }, {});

    const normalizedEwalletData = Object.entries(
      ewalletIdToAddressesMapping,
    ).map(([ewalletId, addressesArray]) => {
      return {
        depositRemarks,
        currencyId: brandCurrencyConnection.currency.id,
        ewalletId: Number(ewalletId),
        credentials: { addresses: addressesArray },
      };
    });

    return normalizedEwalletData;
  }

  streamIsCreateAction() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        if (_.isNil(this.$stateParams.currencyId)) {
          return true;
        }
        return false;
      }),
      shareReplayRefOne(),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const CryptoEwalletEditorComponent = {
  template,
  controller: CryptoEwalletEditorController,
};
