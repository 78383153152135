import template from './customer-page-view-audit-log-popup.component.html';

const styles = require('./customer-page-view-audit-log-popup.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { CustomersService } from '~/source/contact/common/services/customers';
import { Customer } from '@proftit/crm.api.models.entities';
import { customerPageViewAuditLogsPopupSettings } from './customer-page-view-audit-log-popup-settings';
import { customerPageViewAuditLogsPopupColumns } from './customer-page-view-audit-log-popup-columns';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { useStreams } from '@proftit/rxjs.adjunct';
import TablePopupController from '~/source/common/components/table/table-popup';

interface Resolve {
  customer: Customer;
}

export class CustomerPageViewAuditLogPopupController extends TablePopupController {
  static $inject = ['customersService', ...TableController.$inject];

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  customersService: () => CustomersService;

  cols = [...customerPageViewAuditLogsPopupColumns];
  tableSettings = { ...customerPageViewAuditLogsPopupSettings };
  dataServiceInstance: CustomersService;

  customer: Customer;
  customer$ = observeShareCompChange<Resolve>(
    this.lifecycles.onChanges$,
    'resolve',
  ).pipe(
    rx.map((r) => _.get(['customer'], r)),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((customer) => (this.customer = customer)),
  );
  opInitTable$ = new rx.Subject<number>();

  constructor(dataService, ...args) {
    super(dataService, ...args);

    useStreams([this.streamInitTable()], this.lifecycles.onDestroy$);
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {}

  $onChanges() {}

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.opInitTable$,
          this.customer$.pipe(rx.filter((c) => !_.isNil(c))),
        ),
      rx.tap(([rows, customer]) => this.initTable(rows)),
    )(null);
  }

  /**
   * Getter for ngTableParams
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  /**
   * Getter for ngTableSettings
   * @returns {NgTableSettings}
   */
  get ngTableSettings() {
    return this.tableSettings;
  }

  get tableKey() {
    return 'customerPageViewAuditLogPopup';
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  query() {
    return this.dataServiceInstance
      .getCustomerPageViewsAuditLogResource(this.customer.id)
      .sort({ requestedAt: 'desc' });
  }
}

export const CustomerPageViewAuditLogPopupComponent = {
  template,
  controller: CustomerPageViewAuditLogPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
