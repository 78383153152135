import BaseController from '~/source/common/controllers/base';
import template from './canceled-bonus-details.html';
import CustomersService from '~/source/contact/common/services/customers';
import { BONUS_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import {
  TradingAccount,
  Customer,
  TransactionTransferBonus,
} from '@proftit/crm.api.models.entities';
const styles = require('./canceled-bonus-details.scss');

export class Controller extends BaseController {
  customerServiceInst: CustomersService;
  bonus: TransactionTransferBonus;
  account: TradingAccount;
  customer: Customer;
  styles = styles;

  /*@ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();
    this.customerServiceInst = this.customersService();
  }

  updateReason(newReason) {
    // send request to the server
    this.customerServiceInst
      .getBonusResource(this.customer.id, this.account.id, this.bonus.id)
      .patchWithQuery<IElementRestNg<TransactionTransferBonus>>({
        reason: newReason,
      })
      .then(
        (updatedBonusRequest) => {
          this.bonus.reason = updatedBonusRequest.reason;
          this.prfClientGeneralPubsub.publish(BONUS_STATUS_UPDATE, '');
        },
        (error) => {},
      );
  }
}

export const CanceledBonusDetailsComponent = {
  template,
  controller: Controller,
  bindings: {
    bonus: '<',
    account: '<',
    customer: '<',
  },
};

export default CanceledBonusDetailsComponent;
