import * as _ from '@proftit/lodash';
import ng from 'angular';
import Restangular from 'restangular';
import TablePopupController from '~/source/common/components/table/table-popup';
import withdrawalSettings from '../withdrawal-settings.json';
import CustomersService from '~/source/contact/common/services/customers';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import isWithdrawalDeletable from '~/source/common/models/withdrawal-request/is-withdrawal-deletable';

import { PopupService } from '~/source/common/components/modal/popup.service';
import withdrawalStatusUpdatePopupTemplate from '../withdrawal-status-update-popup.html';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import template from './withdrawal-table-popup.html';

import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { useStream } from '~/source/common/utilities/use-stream';
import * as rx from '@proftit/rxjs';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';

export interface MenuItem {
  labelCode: string;
  actionCode: string;
}

class DataAdditional {
  menuItems: MenuItem[] = [];
  showActionWarning: boolean = false;
}

class WithdrawalTablePopupComponent extends TablePopupController {
  static $inject = [
    'customersService',
    'PermPermissionStore',
    'popupService',
    'prfClientGeneralPubsub',
    ...TablePopupController.$inject,
  ];

  customersService: () => CustomersService;
  dataServiceInstance: CustomersService;
  customer: Customer;
  account: TradingAccount;
  dataAdditionals: { [id: number]: DataAdditional } = {};
  PermPermissionStore: ng.permission.PermissionStore;
  popupService: PopupService;
  withdrawals: IElementRestNg<any>;
  prfClientGeneralPubsub: ClientGeneralPubsub;
  unsub$: rx.Subject<any> = new rx.Subject<null>();

  $onInit() {
    super.$onInit();
    useStream(this.streamWithdrawalUpdateStatus(), this.unsub$);
  }

  get cols() {
    return [...withdrawalSettings.tableColumns];
  }

  get ngTableSettings() {
    return { ...withdrawalSettings.tablePopup.ngTable };
  }

  get title() {
    return 'WITHDRAWAL';
  }

  get tableKey() {
    return 'withdrawal';
  }

  streamWithdrawalUpdateStatus() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === WITHDRAWAL_STATUS_UPDATE),
      rx.tap(() => {
        this.reloadTable();
      }),
    )(null);
  }

  onResolveChange(resolve) {
    if (_.isNil(resolve)) {
      this.customer = null;
      this.account = null;
      return;
    }
    this.customer = resolve.customer;
    this.account = resolve.account;
  }

  parseLoadedData(data) {
    if (!this.withdrawals) {
      this.withdrawals = data;
    } else {
      this.withdrawals = _.unionWith(
        (a: any, b: any) => a.id === b.id,
        data,
        this.withdrawals,
      );
    }
    this.dataAdditionals = this.generateDataAdditionals();

    return data;
  }

  /**
   * Generate data infos accompaning the data. Ui state...
   *
   * @return {object[]} the generated data infos
   */
  generateDataAdditionals() {
    return this.withdrawals.reduce((acc, withdrawal) => {
      const info = new DataAdditional();
      this.calcMenuItemsForWithdrawalRow(withdrawal).then((menuItems) => {
        info.menuItems = menuItems;
      });

      return {
        ...acc,
        [withdrawal.id]: info,
      };
    }, {});
  }

  /**
   * Calculate menu items for specific withdrawal.
   *
   * Stateless function.
   *
   * @param {WithdrawalRequest} withdrawalReq - withdrawal
   * @return {Promise} promise of menu items
   */
  calcMenuItemsForWithdrawalRow(
    withdrawalReq: WithdrawalRequest,
  ): Promise<MenuItem[]> {
    const updatePromise = this.PermPermissionStore.getPermissionDefinition(
      'contacts.transactions.withdrawalsrequest_U',
    )
      .validatePermission()
      .then(
        () => {
          return true;
        },
        () => {
          return false;
        },
      );

    const createPromise = this.PermPermissionStore.getPermissionDefinition(
      'contacts.transactions.withdraw_C',
    )
      .validatePermission()
      .then(
        () => {
          return true;
        },
        () => {
          return false;
        },
      );

    return Promise.all([updatePromise, createPromise]).then(
      ([hasReqUpdate, hasWithdrawalCreate]) => {
        let menuItems = [];

        if (hasReqUpdate && this.isCancelable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.CANCEL',
              actionCode: 'cancel',
            },
          ];
        }

        if (hasReqUpdate && this.isCloseable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.CLOSE',
              actionCode: 'close',
            },
          ];
        }

        if (hasWithdrawalCreate && this.isConfirmable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.CONFIRM',
              actionCode: 'confirm',
            },
          ];
        }

        if (isWithdrawalDeletable(withdrawalReq)) {
          menuItems = [
            ...menuItems,
            {
              labelCode: 'common.DELETE',
              actionCode: 'delete',
            },
          ];
        }

        return menuItems;
      },
    );
  }

  /**
   * Determines whether request is cancelable. Only request in status
   * "requested" can be canceled
   *
   * @param {object} withdrawalRequest
   * @returns {boolean}
   */
  isCancelable(withdrawalRequest: WithdrawalRequest): boolean {
    return withdrawalRequest.transactionStatusCode === 'requested';
  }

  /**
   * Determines whether request is cancelable. Only request in status
   * "partial" can be closed
   *
   * @param {object} withdrawalRequest
   * @returns {boolean}
   */
  isCloseable(withdrawalRequest: WithdrawalRequest): boolean {
    return withdrawalRequest.transactionStatusCode === 'partial';
  }

  /**
   * Determines whether request is confirmable. Only request in status
   * "partial" and "requested" can be confirmed
   *
   * @param {object} withdrawalRequest
   * @returns {boolean}
   */
  isConfirmable(withdrawalRequest: WithdrawalRequest): boolean {
    // on those statuses request is confirmable
    const statuses = ['partial', 'requested'];

    return statuses.includes(withdrawalRequest.transactionStatusCode);
  }

  onDropDownAction(actionCode, withdrawalReq) {
    const actionsDispatch = {
      cancel: () =>
        this.openWithdrawalStatusUpdatePopup(withdrawalReq, 'canceled'),
      close: () =>
        this.openWithdrawalStatusUpdatePopup(withdrawalReq, 'closed'),
      confirm: () => this.openRequestConfirmDialog(withdrawalReq),
      delete: () =>
        (this.dataAdditionals[withdrawalReq.id].showActionWarning = true),
    };

    const actionFn = _.defaultTo(() => {}, actionsDispatch[actionCode]);
    actionFn();
  }

  /**
   * Open "withdrawal confirm" popup
   *
   * @param {object} withdrawalRequest request data
   * @returns {void}
   */
  openRequestConfirmDialog(withdrawalRequest: WithdrawalRequest) {
    this.popupService.open({
      component: 'prfConfirmWithdrawalPopup',
      resolve: {
        withdrawalRequest,
        accountId: this.account.id,
        account: this.account,
        contactId: this.customer.id,
        customer: this.customer,
      },
    });
  }

  deleteWithdrawal(withdrawalReq: IElementRestNg<WithdrawalRequest>) {
    // delete request
    withdrawalReq
      .patch({
        transactionStatusCode: TradingAccountTransactionStatusCode.Deleted,
      })
      .then(() => {
        // update table data on success
        withdrawalReq.transactionStatusCode = 'deleted';
        // reset data infos
        this.dataAdditionals = this.generateDataAdditionals();
      });
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getWithdrawalRequestsResource(this.customer.id, this.account.id)
      .embed(['withdrawals'])
      .expand([
        'currency',
        'withdrawalType',
        'user',
        'transactionTransferWire',
        'transactionTransferWire.country:bankCountry', // expanded-expand with mapping. who knew that was possible
        'handler',
        'withdrawals.handler',
        'withdrawals.deposit',
        'withdrawals.transactionTransferCreditCard',
        'withdrawals.transactionTransferCreditCard.clearingCompany',
        'withdrawals.transactionTransferWire',
        'withdrawals.ewalletTransaction',
        'withdrawals.ewalletTransaction.ewallet',
        'withdrawals.deposit.transactionTransferCreditCard',
        'withdrawals.deposit.transactionTransferCreditCard.clearingCompany',
        'withdrawals.mobileTransaction',
        'withdrawals.mobileTransaction.clearingCompany',
      ]);
  }

  openWithdrawalStatusUpdatePopup(
    withdrawalRequest: WithdrawalRequest,
    newStatus: string,
  ) {
    // open popup
    this.popupService.open({
      controller: 'WithdrawalStatusUpdatePopupController',
      template: withdrawalStatusUpdatePopupTemplate,
      scope: this.$scope,
      data: {
        withdrawalRequest,
        withdrawalRequestStatus: newStatus,
      },
    });
  }

  // calculate the owner desk ID - depends on the existence of owner
  calcOwnerDeskId(owner, desk) {
    if (owner && desk) {
      return desk.id;
    }

    return null;
  }

  /**
   * Assign owner to withdrawal
   *
   * @return {promise}
   */
  assignOwnerToWithdrawal(owner, desk, withdrawal) {
    return this.customersService()
      .getWithdrawalRequestResource(
        this.customer.id,
        this.account.id,
        withdrawal.id,
      )
      .setConfig({ blockUiRef: 'assignToPopup' })
      .patchWithQuery({
        userId: owner ? owner.id : null,
        ownerDeskId: this.calcOwnerDeskId(owner, desk),
      })
      .then(() => {
        withdrawal.user = owner;
        this.reloadTable();
        this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, '');
      });
  }

  $onDestroy() {
    this.unsub$.next(null);
    this.unsub$.complete();
  }
}

export default {
  template,
  bindings: {
    close: '&',
    dismiss: '&',
    modalInstance: '<',
    resolve: '<',
  },
  controller: WithdrawalTablePopupComponent,
  controllerAs: 'vm',
};
