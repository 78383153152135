import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './click-to-call-list.component.html';
import { DidNumber } from '~/source/contact/common/click-to-call/click-to-call.component';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import BrandsService from '~/source/management/brand/services/brands';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import { VoipProviderCode } from '@proftit/crm.api.models.enums';
import { CountriesStoreService } from '~/source/common/store-services/countries-store.service';
import { moveToFirst } from '~/source/common/utilities/strucutre-transform/move-to-first';
import { Customer, VoipProvider } from '@proftit/crm.api.models.entities';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { VoipProvidersService } from '~/source/common/services/voip-providers';
const styles = require('./click-to-call-list.component.scss');

export type RawDidNumber = {
  did: {
    countryCode?: string;
    label: string;
    type: string;
    value: string;
  };
};

export class ClickToCallListController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateBlockuiId();

  model;
  onChange: () => void;
  onNewDids: ({ dids }) => void;

  brandId$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'brandId',
  );

  voipProvider$ = observeShareCompChange<VoipProvider>(
    this.lifecycles.onChanges$,
    'voipProvider',
  );

  numberToShowFirst$ = observeShareCompChange<number>(
    this.lifecycles.onChanges$,
    'numberToShowFirst',
  );

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  selectedVoipProvider$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'selectedVoipProvider',
  );

  searchStringFormControl = new FormControl<string>(null);

  countriesCodeMap$ = this.streamCountriesCodeMap();
  dids$ = this.streamDids();
  didsAfterNumberChange$ = this.streamDidsFromFirstNumberChange();
  didsToDisplay$ = this.streamDidsToDisplay();
  shouldShowCountryFlags$ = this.streamShouldShowCountryFlags();
  voipProviderCodesWithIcons$ = this.streamVoipProviderCodesWithIcons();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly prfCountriesStoreService: CountriesStoreService,
    readonly voipProvidersService: VoipProvidersService,
  ) {
    useStreams(
      [
        this.dids$,
        this.voipProvider$,
        this.shouldShowCountryFlags$,
        this.countriesCodeMap$,
        this.didsToDisplay$,
        this.searchStringFormControl.value$,
        this.streamDidsFromSearch(),
        this.customer$,
        this.voipProviderCodesWithIcons$,
        this.selectedVoipProvider$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamVoipProviderCodesWithIcons() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        return [
          VoipProviderCode.Coperato,
          VoipProviderCode.Didlogic,
          VoipProviderCode.Commpeak,
          VoipProviderCode.Voicespin,
        ];
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDidsFromSearch() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.didsAfterNumberChange$,
          this.searchStringFormControl.value$,
        ),
      rx.filter((x) => x.every((item) => !_.isNil(item))),
      rx.map(([dids, searchValue]) => {
        return dids.filter((didObject) =>
          didObject.did.label
            .toLowerCase()
            .startsWith(searchValue.toLowerCase()),
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDids(): rx.Observable<RawDidNumber[]> {
    return rx.pipe(
      // we need countriesCodeMap to emit at least once, before streaming the dids.
      // Otherwise, streamDids emits before the countriesCodeMap and the child stream "streamDidsToDisplay" gets stuck.
      () =>
        rx.obs.combineLatest(
          this.brandId$,
          this.countriesCodeMap$,
          this.selectedVoipProvider$,
        ),
      rx.filter(
        ([brandId, b, voipProvider]) =>
          !_.isNil(brandId) && !_.isNil(voipProvider),
      ),
      rx.switchMap(([brandId, b, voipProvider]) => {
        return this.brandsService()
          .setConfig({ blockUiRef: this.blockUiId })
          .getDidsPhonesList(brandId, voipProvider.id) as Promise<
          RawDidNumber[]
        >;
      }),
      rx.tap((dids) => {
        this.onNewDids({ dids });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDidsFromFirstNumberChange() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.dids$, this.numberToShowFirst$),
      rx.map(([currentDids, numberToShowFirst]) => {
        return moveToFirst(
          (x) => x.did.value === numberToShowFirst,
          currentDids,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDidsToDisplay(): rx.Observable<DidNumber[]> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.dids$,
          this.streamDidsFromFirstNumberChange(),
          this.streamDidsFromSearch(),
        ),
      rx.withLatestFrom(this.countriesCodeMap$, this.numberToShowFirst$),
      rx.map(
        ([dids, countriesCodeMap, numberToShowFirst]: [
          RawDidNumber[],
          Map<string, any>,
          number,
        ]) => {
          return dids.map((didObject) => {
            const { did } = didObject;
            let defaultDidValue: DidNumber & { _isHighlighted: boolean } = {
              id: did.value,
              name: did.label,
              _isHighlighted: false,
            };
            if (!_.isNil(did.countryCode) && !_.isEmpty(did.countryCode)) {
              const mapValue = countriesCodeMap.get(did.countryCode);
              if (!_.isNil(mapValue)) {
                const country = {
                  code: mapValue.alpha2Code.toLowerCase(),
                  name: mapValue.name,
                };
                defaultDidValue = {
                  ...defaultDidValue,
                  country,
                };
              }
            }

            if (defaultDidValue.id === String(numberToShowFirst)) {
              defaultDidValue._isHighlighted = true;
            }

            return defaultDidValue;
          });
        },
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamShouldShowCountryFlags() {
    return rx.pipe(
      () => this.voipProvider$,
      rx.filter((x) => !_.isNil(x)),
      rx.map((voipProvider: VoipProvider) => {
        const voipProvidersWithFlags = [
          VoipProviderCode.Coperato,
          VoipProviderCode.Squaretalk,
        ].map((e) => e.toString());
        return voipProvidersWithFlags.includes(voipProvider.code);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamCountriesCodeMap() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return this.prfCountriesStoreService.countriesCodeMap$;
      }),
    )(null);
  }

  updateModel(did) {
    this.model = { ...did };
    this.onChange();
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ClickToCallListComponent = {
  template,
  controller: ClickToCallListController,
  bindings: {
    brandId: '<',
    voipProvider: '<',
    numberToShowFirst: '<',
    customer: '<',
    voipProviderSelectAction: '<',
    voipProvidersToDisplay: '<',
    selectedVoipProvider: '<',
    customerNumbersToCallTo: '<',
    selectedNumberToCallTo: '<',
    numberToCallToSelectAction: '<',
    model: '=',
    onChange: '&',
    onCallButtonClick: '&',
    onNewDids: '&',
  },
};
