import * as _ from '@proftit/lodash';
import { ContainerBean, BeanValidationResult } from '@proftit/proxy-bean';

export const FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED =
  'FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED';

export function ftdAutoAssignmentDestIsNotEmptyIfSourceIsFilledProxyValidator(
  bean: ContainerBean,
): BeanValidationResult {
  if (_.isNil(bean.proxy['sourceDesk'])) {
    return {
      isValid: true,
      code: FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED,
    };
  }

  if (!_.isNil(bean.proxy['destinationDesk'])) {
    return {
      isValid: true,
      code: FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED,
    };
  }

  return {
    isValid: false,
    code: FTD_AUTO_ASSIGNMENT_DEST_IS_NOT_EMPTY_IF_SOURCE_IS_FILLED,
  };
}
