export const cols = [
  {
    title: 'common.ID',
    field: 'currencyId',
    fieldFormat: '{{ currencyId }}',
    sortable: false,
    show: false,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.CURRENCY',
    field: 'cfdPlatformCurrencyName',
    fieldFormat: '{{ symbol }}',
    sortable: 'symbol',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.NET_TOTAL',
    field: 'netTotal',
    fieldFormat: '{{ netTotal }}',
    sortable: 'netTotal',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.RATE',
    field: 'rate',
    fieldFormat: '{{ rate }}',
    sortable: 'rate',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.NET_TOTAL_USD',
    field: 'netTotalUSD',
    fieldFormat: '{{ netTotalUSD }}',
    sortable: 'netTotalUSD',
    show: true,
    filterable: false,
    removable: false,
  },
];
