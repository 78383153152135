import NotificationsController, {
  ComponentOptions,
} from './base/base-collection-notifications.controller';

class PositionsForexNotificationsController extends NotificationsController {}

PositionsForexNotificationsController.$inject = [
  'positionsForexSocketService',
  ...NotificationsController.$inject,
];

export default {
  ...ComponentOptions,
  controller: PositionsForexNotificationsController,
};
