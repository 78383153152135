import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './promo-code.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import BrandsService from '../../brand/services/brands';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { StateService } from '@uirouter/angularjs';
const styles = require('./promo-code.component.scss');

export class PromoCodeController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $state: StateService,
  ) {
    useStreams([], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const PromoCodeComponent = {
  template,
  controller: PromoCodeController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
    prfCrmAppRouteProvider: '^',
  },
};
