import {
  CommunicationProvider,
  CommunicationProviderCredentials,
} from '@proftit/crm.api.models.entities';
import { FormArray, FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import * as _ from '@proftit/lodash';
import emailProviderSettings from '~/source/management/brand/components/email-provider/email-provider-settings.unsorted.json';
import { arrayToHashMap } from '@proftit/general-utilities';
import { isStringNotEmptyOrOtherNotNilFormValidator } from '@proftit/ng1.reactive-forms.validators';

export function getFormArray(
  emailProvider: CommunicationProvider,
  emailCredentials: CommunicationProviderCredentials[],
): FormArray {
  if (_.isNil(emailProvider)) {
    return new FormArray([]);
  }
  const { fields } = emailProvider;
  if (_.isNil(fields)) {
    return new FormArray([]);
  }

  const fieldsMergedWithClientSettings = mergeFieldsWithClientSettings(
    emailProvider.code,
    fields,
  );

  const mergedFields = mergeFieldsWithCredentials(
    fieldsMergedWithClientSettings,
    emailCredentials,
  );

  const sortedMergedFields = sortFieldsWithClientSettings(
    emailProvider.code,
    mergedFields,
  );

  return createFormArray(sortedMergedFields);
}

export function mergeFieldsWithClientSettings(emailProviderCode, fields) {
  const settingsForEmailProvider = emailProviderSettings[emailProviderCode];
  if (_.isNil(settingsForEmailProvider)) {
    return fields;
  }
  if (_.isNil(settingsForEmailProvider.fields)) {
    return fields;
  }

  const settingsFieldsHashMap = arrayToHashMap<string, any>(
    settingsForEmailProvider.fields,
    'code',
  );

  return fields.map((field) => {
    const settingsField = settingsFieldsHashMap.get(field.code);
    if (_.isNil(settingsField)) {
      return field;
    }
    return {
      ...field,
      ...settingsField,
    };
  });
}

export function mergeFieldsWithCredentials(fields, emailCredentials) {
  if (_.isNil(emailCredentials)) {
    return fields;
  }

  const credentialsHashMap = arrayToHashMap<string, any>(
    emailCredentials,
    'key',
  );

  return fields.map((field) => {
    const credentialForField = credentialsHashMap.get(field.code);
    return {
      ...field,
      value: credentialForField ? credentialForField.value : null,
      updatedAt: credentialForField ? credentialForField.updatedAt : null,
    };
  });
}

export function sortFieldsWithClientSettings(emailProviderCode, fields) {
  const settingsForEmailProvider = emailProviderSettings[emailProviderCode];
  if (_.isNil(settingsForEmailProvider)) {
    return fields;
  }
  if (_.isNil(settingsForEmailProvider.fields)) {
    return fields;
  }

  const fieldHashMap = arrayToHashMap<string, any>(fields, 'code');

  return settingsForEmailProvider.fields.map((settingsField) => {
    return fieldHashMap.get(settingsField.code);
  });
}

function createFormArray(fields) {
  return new FormArray(fields.map((field) => createFormGroup(field)));
}

function createFormGroup(field) {
  const valueValidators = field.isRequired
    ? [isStringNotEmptyOrOtherNotNilFormValidator]
    : [];
  return new FormGroup(
    {
      code: new FormControl<string>(field.code),
      type: new FormControl<string>(field.type),
      value: new FormControl<string>(field.value, {
        validators: valueValidators,
      }),
      isReadonly: new FormControl<boolean>(field.isReadonly),
      isRequired: new FormControl<boolean>(field.isRequired),
      updatedAt: new FormControl<string>(field.updatedAt),
    } as any,
    {
      metaInfo: {
        hasTooltip: _.defaultTo(false, field.hasTooltip),
        tooltipText: _.defaultTo('', field.tooltipText),
      },
    },
  );
}
