import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { DateRangeType } from '@proftit/utils-datetime';
import { EnumId, UtilsService } from '~/source/common/utils/utils.service';
import template from './prf-tasks-navbar.component.html';
import { TasksNavbarViewOption } from './tasks-navbar-view-option';
import { SelectOption } from '~/source/common/custom-prf-dropdowns/simple-dropdown/select-option';
const styles = require('./prf-tasks-navbar.component.scss');

export class PrfTasksNavbarController {
  onDaterangeTypeSelect: (x: { daterangeType: DateRangeType }) => void;

  onViewOptionSelect: (x: { viewOption: TasksNavbarViewOption }) => void;

  TasksNavbarViewOption = TasksNavbarViewOption;

  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  dateRangeTypes = this.prfUtils
    .enumToSelectOptions<DateRangeType>(DateRangeType, EnumId.DaterangeType)
    .map((opt) => ({ ...opt, label: _.startCase(opt.label) }));

  viewOption: TasksNavbarViewOption;

  daterangeType: DateRangeType;

  viewOption$ = observeShareCompChange<TasksNavbarViewOption>(
    this.lifecycles.onChanges$,
    'viewOption',
  );

  daterangeType$ = observeShareCompChange<DateRangeType>(
    this.lifecycles.onChanges$,
    'daterangeType',
  );

  isCalendarSelected$ = this.viewOption$.pipe(
    rx.map((viewOption) => viewOption === TasksNavbarViewOption.Calendar),
    shareReplayRefOne(),
  );

  isListSelected$ = this.viewOption$.pipe(
    rx.map((viewOption) => viewOption === TasksNavbarViewOption.List),
    shareReplayRefOne(),
  );

  get selectedDateRangeType(): SelectOption<DateRangeType> {
    return this.dateRangeTypes.find((i) => i.value === this.daterangeType);
  }

  set selectedDateRangeType(selectOption: SelectOption<DateRangeType>) {
    this.onDaterangeTypeSelect({ daterangeType: selectOption.value });
  }

  /* @ngInject */
  constructor(readonly prfUtils: UtilsService) {
    useStreams(
      [this.viewOption$, this.daterangeType$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const PrfTasksNavbarComponent = {
  template,
  controller: PrfTasksNavbarController,
  bindings: {
    viewOption: '<',
    daterangeType: '<',
    dateText: '<',
    onViewOptionSelect: '&',
    onDaterangeTypeSelect: '&',
    onClickGotoToday: '&',
    onClickPrevRange: '&',
    onClickNextRange: '&',
    onClickNewTask: '&',
  },
};
