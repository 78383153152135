import BaseDropdown from '~/source/common/components/dropdowns/base/component';

class Controller extends BaseDropdown.controller {
  static $inject = [
    'prfTradeBundlesService',
    ...BaseDropdown.controller.$inject,
  ];

  get defaultPlaceholder() {
    return 'contact.bundles.SELECT_BUNDLE';
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'position.statuses';
  }

  /**
   * Name of the propy that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const TradeBundleSelectComponent = BaseDropdown.config({
  controller: Controller,
});
