import { IScope } from 'angular';
import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import template from './contact-info-update.html';
import { Country, Customer } from '@proftit/crm.api.models.entities';
import CustomerPropertiesService from '~/source/common/services/customer-properties.service';
import CustomerProperty from '~/source/common/models/customer-property';
import CustomerPropertyValue from '~/source/common/models/customer-property-value';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import switchOn from '~/source/common/utilities/switch-on';
import useStream from '~/source/common/utilities/use-stream';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import convertStringToCustomerPropertyValue from '~/source/common/utilities/convert-string-to-customer-property-value';
import convertCustomerPropertyValueToString from '~/source/common/utilities/convert-customer-property-value-to-string';
import { isCustomerPropertyValueEmpty } from '~/source/common/utilities/is-customer-property-value-empty';
import { generateUuid } from '~/source/common/utilities/generate-uuid';
import {
  PermissionNormalized,
  Permissions,
} from '~/source/common/models/permission-structure';
import * as rx from '@proftit/rxjs';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';

export class ContactInfoUpdateController {
  // Bindings
  customer: Customer;
  onUpdateCustomer: (changes: any) => void;
  onCancelEdit: () => void;

  Permissions = Permissions;

  customerPropertyValues$ = new rx.BehaviorSubject<CustomerPropertyValue[]>([]);

  genUid: Function;

  contactPersonalInfoPhonePerm$ = this.streamContactPersonalInfoPhonePerm();

  /*@ngInject */
  constructor(
    readonly customerPropertiesService: CustomerPropertiesService,
    readonly $scope: IScope & { CustomerForm: any },
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    const lifecycles = observeComponentLifecycles(this);
    this.genUid = _.memoize((name) => generateUuid());

    useStreams([this.streamCustomerProperties()], lifecycles.onDestroy$);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  onCountryChange(newValue: Country) {
    this.customer.state = null;
  }

  streamCustomerProperties() {
    return rx.pipe(
      () => rx.obs.from(this.getCustomerProperties()),
      rx.map((props) => props.plain()),
      rx.map((props) => props.filter((prop) => !prop.isDisabled)),
      rx.map((props) => props.sort((a, b) => a.order - b.order)),
      rx.map((props) =>
        this.convergePropsAndValues(
          props,
          this.customer.customerPropertyValues,
        ),
      ),
      rx.map((propVals) =>
        propVals.map((propVal) => ({
          ...propVal,
          value: convertStringToCustomerPropertyValue(
            propVal.value,
            propVal.property.typeCode,
          ),
        })),
      ),
      rx.tap((propVals) => this.customerPropertyValues$.next(propVals)),
    )(null);
  }

  streamContactPersonalInfoPhonePerm() {
    return rx.pipe(
      () =>
        checkCrudPermission(
          PermissionNormalized.ContactPersonalInfoPhone,
          this.PermPermissionStore,
        ),
      shareReplayRefOne(),
    )(null);
  }

  convergePropsAndValues(props, propVals) {
    let numerator = -1;

    return props.map((prop) => {
      const existingPropVal = this.customer.customerPropertyValues.find(
        (val) => val.property.id === prop.id,
      );

      if (_.isNil(existingPropVal)) {
        numerator -= 1;

        return {
          id: numerator,
          value: null,
          property: prop,
        };
      }

      return {
        ...existingPropVal,
        property: prop,
      };
    });
  }

  /**
   *
   */
  getCustomerProperties() {
    return this.customerPropertiesService.getListWithQuery<
      IElementRestNg<CustomerProperty>
    >();
  }

  /**
   * Get object of changed (dirty) fields and pass it to onUpdateCustomer method.
   *
   * Returns the changed object.
   *
   * @return {void}
   */
  save() {
    // Get only dirty form fields (exclude angular special fields)
    const changes = _.filterEs(
      this.$scope.CustomerForm,
      (el, key) => key[0] !== '$' && el.$dirty,
    )
      // create result object containing only the dirty fields key and value
      .reduce((result, field) => {
        if (field.$name.startsWith('customerProperty')) {
          return result;
        }

        Object.assign(result, {
          // Get value from the real model, not the form field!
          [field.$name]: this.customer[field.$name],
        });

        return result;
      }, {});

    const customerPropertyValues = this.customerPropertyValues$
      .getValue()
      .filter((propVal) => !isCustomerPropertyValueEmpty(propVal))
      .map((propVal) => ({
        ...propVal,
        value: convertCustomerPropertyValueToString(
          propVal.value,
          propVal.property.typeCode,
        ),
      }))
      .map((propVal) => {
        const { id, ...newPropVal } = propVal;
        return newPropVal;
      });

    this.onUpdateCustomer({
      changes: {
        ...changes,
        customerPropertyValues,
      },
    });
  }
}

export default {
  template,
  controller: ContactInfoUpdateController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    onCancelEdit: '&',
    onUpdateCustomer: '&',
  },
  require: {
    prfCustomerStoreProvider: '^',
  },
};
