import * as rx from '@proftit/rxjs';
import { CommunicationProvider } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { SmsProvidersService } from '../services/sms-providers';

export class SmsProvidersStoreService {
  smsProviders$ = this.streamSmsProviders();

  /* @ngInject */
  constructor(readonly smsProvidersService: SmsProvidersService) {}

  streamSmsProviders(): rx.Observable<CommunicationProvider[]> {
    return rx.pipe(
      () =>
        rx.obs.from(
          this.smsProvidersService.getListWithQuery<
            IElementRestNg<CommunicationProvider>
          >(),
        ),
      rx.map((res) => res.plain()),
      rx.catchError(() => rx.obs.of([])),
      shareReplayRefOne(),
    )(null);
  }
}
