import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { Brand, BrandPlatform } from '@proftit/crm.api.models.entities';
import {
  CFD_MT4_PLATFORMS,
  CFD_PLATFORMS,
  RISK_MANAGEMENT_PLATFORMS,
  PlatformCode,
  RISK_MANAGEMENT_MT4_PLATFORMS,
} from '@proftit/crm.api.models.enums';
import {
  combineInProcesses,
  generateInProcessSubject,
  tapInProcessOn,
  catchErrorInProcessOff,
  tapFinalizeInProcessOff,
  shareReplayRefOne,
} from '@proftit/rxjs.adjunct';
import { getWithDefault } from '@proftit/general-utilities';
import { BrandsService } from '~/source/management/brand/services/brands';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { generateInProcessStream } from '~/source/common/utilities/generate-in-process-stream';
import { CurrentUserStoreService } from './current-user-store.service';

export class BrandsStoreService {
  brandsS = this.streamBrands();

  brandsWithPlatformsS = this.streamBrandsWithPlatforms();

  brandsWithCfdMt4S = this.streamBrandsWithCfdMt4();

  brandsWithCfdS = this.streamBrandsWithCfd();

  brandsWithCfdLikeS = this.streamBrandsWithCfdLike();

  brandsWithMtS = this.streamBrandsWithMt();

  brandsWithRiskManagementPlatformsS = this.streamBrandsWithRiskManagementPlatforms();

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly prfCurrentUserStore: CurrentUserStoreService,
  ) {}

  streamBrands() {
    const inProcess$ = generateInProcessSubject();

    const streamFn: () => rx.Observable<Brand[]> = () =>
      rx.pipe(
        () => this.prfCurrentUserStore.currentLoggedUser$,
        tapInProcessOn(inProcess$),
        rx.switchMap(() =>
          this.brandsService()
            .embed(['platformConnections'])
            .expand(['platformType', 'platformConnections.platform'])
            .getListWithQuery<IElementRestNg<Brand>>(),
        ),
        rx.map((data) => data.plain()),
        catchErrorInProcessOff(inProcess$, (error) => {
          log.error(
            'BrandsStoreService -> streamBrands -> error fetching brands',
            error,
          );
          return streamFn();
        }),
        tapFinalizeInProcessOff(inProcess$),
        shareReplayRefOne(),
      )(null);

    return {
      inProcess$,
      stream$: streamFn(),
    };
  }

  streamBrandsWithPlatforms() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([
      inProcessSelf$,
      this.brandsS.inProcess$,
    ]);

    const stream$ = rx.pipe(
      () => this.brandsS.stream$,
      tapInProcessOn(inProcessSelf$),
      rx.map((brands) => {
        return brands.filter((brand) => {
          const brandPlatforms = getWithDefault<BrandPlatform[]>(
            ['platformConnections'],
            [],
            brand,
          );
          return brandPlatforms.length > 0;
        });
      }),
      tapFinalizeInProcessOff(inProcessSelf$),
      shareReplayRefOne(),
    )(null);

    return {
      inProcess$,
      stream$,
    };
  }

  streamBrandsWithCfdMt4() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([
      inProcessSelf$,
      this.brandsWithPlatformsS.inProcess$,
    ]);

    const stream$ = rx.pipe(
      () => this.brandsWithPlatformsS.stream$,
      tapInProcessOn(inProcessSelf$),
      rx.map((brands) => {
        return brands.filter((brand) => {
          const brandPlatforms = brand.platformConnections;
          return brandPlatforms.some((bp) =>
            CFD_MT4_PLATFORMS.includes(bp.platform.code),
          );
        });
      }),
      tapFinalizeInProcessOff(inProcessSelf$),
      shareReplayRefOne(),
    )(null);

    return {
      inProcess$,
      stream$,
    };
  }

  streamBrandsWithRiskManagementPlatforms() {
    const inProcessSelf$ = generateInProcessSubject();
    const inProcess$ = combineInProcesses([
      inProcessSelf$,
      this.brandsWithPlatformsS.inProcess$,
    ]);

    const stream$ = rx.pipe(
      () => this.brandsWithPlatformsS.stream$,
      tapInProcessOn(inProcessSelf$),
      rx.map((brands) => {
        return brands.filter((brand) => {
          const brandPlatforms = brand.platformConnections;
          return brandPlatforms.some((bp) =>
            RISK_MANAGEMENT_PLATFORMS.includes(bp.platform.code),
          );
        });
      }),
      tapFinalizeInProcessOff(inProcessSelf$),
      shareReplayRefOne(),
    )(null);

    return {
      inProcess$,
      stream$,
    };
  }

  streamBrandsWithCfd() {
    return generateInProcessStream(
      this.brandsWithPlatformsS.stream$,
      (inProcess$) =>
        rx.pipe(
          rx.map((brands) => {
            return brands.filter((brand) => {
              const brandPlatforms = brand.platformConnections;
              return brandPlatforms.some((bp) =>
                CFD_PLATFORMS.includes(bp.platform.code),
              );
            });
          }),
          shareReplayRefOne(),
        ),
      [this.brandsWithPlatformsS.inProcess$],
    );
  }

  streamBrandsWithCfdLike() {
    return generateInProcessStream(
      this.brandsWithPlatformsS.stream$,
      (inProcess$) =>
        rx.pipe(
          rx.map((brands) => {
            return brands.filter((brand) => {
              const brandPlatforms = brand.platformConnections;
              return brandPlatforms.some((bp) =>
                [PlatformCode.Cfd, PlatformCode.Bundle].includes(
                  bp.platform.code,
                ),
              );
            });
          }),
          shareReplayRefOne(),
        ),
      [this.brandsWithPlatformsS.inProcess$],
    );
  }

  streamBrandsWithMt() {
    return generateInProcessStream(
      this.brandsWithPlatformsS.stream$,
      (inProcess$) =>
        rx.pipe(
          rx.map((brands) => {
            return brands.filter((brand) => {
              const brandPlatforms = brand.platformConnections;
              return brandPlatforms.some((bp) =>
                RISK_MANAGEMENT_MT4_PLATFORMS.includes(bp.platform.code),
              );
            });
          }),
          shareReplayRefOne(),
        ),
      [this.brandsWithPlatformsS.inProcess$],
    );
  }
}
