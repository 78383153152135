// import TableSettings from '~/source/common/models/table-settings';

const settings: any = {
  table: {
    ngTable: {
      parameters: {
        count: 4,
        page: 1,
      },
      settings: {
        counts: [],
      },
    },
  },
  tablePopup: {
    ngTable: {
      parameters: {
        count: 20,
        page: 1,
      },
      settings: {
        counts: [],
      },
    },
  },
  tableColumns: [
    {
      title: 'pendingPositionForex.fields.SYNC_REMOTE_ID',
      field: 'syncRemoteId',
      sortable: 'syncRemoteId',
      show: true,
      filterable: false,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.OPEN_TIME',
      field: 'openTime',
      fieldFormat: '{{ openTime | amDateFormat:"L LTS" }}',
      sortable: 'openTime',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.CLOSE_TIME',
      field: 'closeTime',
      fieldFormat: '{{ closeTime | amDateFormat:"L LTS" }}',
      filterName: 'timestamp',
      sortable: 'closeTime',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.TYPE',
      field: 'type',
      sortable: false,
      show: true,
      filterable: false,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.SIZE',
      field: 'investment',
      fieldFormat:
        '{{ investment | number:(tradingAccount.currency.decimals) }}',
      sortable: 'investment',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.LEVERAGE',
      field: 'leverage',
      fieldFormat: '{{ leverage | number}}',
      sortable: 'leverage',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.SYMBOL',
      field: 'tradeAsset',
      fieldFormat: '{{ tradeAsset.name | uppercase }}',
      sortable: false,
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.ENTRY_RATE',
      field: 'entryRate',
      fieldFormat: '{{ entryRate | prfToFixed:tradeAsset.tailDigits }}',
      sortable: 'entryRate',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.STOP_LOSS',
      field: 'stopLoss',
      fieldFormat: '{{ stopLoss | prfToFixed:tradeAsset.tailDigits }}',
      sortable: 'stopLoss',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.TAKE_PROFIT',
      field: 'takeProfit',
      fieldFormat: '{{ takeProfit | prfToFixed:tradeAsset.tailDigits }}',
      sortable: 'takeProfit',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.STATUS',
      field: 'pendingPositionStatusForex',
      fieldFormat:
        '{{ status.code | codeTranslate:"pendingPositionForexStatus" }}',
      sortable: 'statusId',
      show: true,
      filterable: true,
      removable: false,
    },
    {
      title: 'pendingPositionForex.fields.ORDER_ID',
      fieldFormat: '{{ positionId }}',
      sortable: 'positionId',
      show: true,
      filterable: false,
      removable: false,
    },
    {
      // This is empty col for control settings
      title: '',
      sortable: false,
      show: true,
      filterable: false,
      removable: false,
    },
  ],
};

export default settings;
