import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import { Mt4Group } from '@proftit/tradingcore.api.models.entities';
import { objShallowDifference } from '@proftit/general-utilities';
import { generateObjectProxy } from '~/source/common/proxy-form/generate-object-proxy';
import { FieldProxyHandler } from '~/source/common/proxy-form/field-proxy-handler';
import { isNumberProxyValidator } from '~/source/common/proxy-form/validators/is-number-proxy-validator';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';

export function generateGroupForm() {
  const proxy: any = generateObjectProxy<Mt4Group>({});

  const readonlyFields = [
    'brandId',
    'currency',
    'defaultLeverage',
    'id',
    'maxInvestment',
    'minInvestment',
    'mtCustomerGroupSecurities',
    'mtGroupAssets',
    'mtServerId',
    'name',
  ];

  readonlyFields.forEach((fieldName) => {
    proxy.handler.addFieldHandler(
      fieldName,
      new FieldProxyHandler({
        isReadonly: true,
      }),
    );
  });

  proxy.handler.addFieldHandler(
    'marginCallInPercentOrMoney',
    new FieldProxyHandler({
      validators: [],
    }),
  );

  proxy.handler.addFieldHandler(
    'marginCallLevel',
    new FieldProxyHandler({
      validators: [isNumberProxyValidator],
    }),
  );

  proxy.handler.addFieldHandler(
    'stopOutLevel',
    new FieldProxyHandler({
      validators: [isNumberProxyValidator],
    }),
  );

  const isValid$ = new rx.BehaviorSubject<boolean>(true);
  const validationResults$ = new rx.BehaviorSubject<any[]>([]);
  const value$ = new rx.BehaviorSubject<Mt4Group>(null);
  const initial$ = new rx.BehaviorSubject<Mt4Group>(null);
  const changes$ = rx.pipe(
    () => rx.obs.combineLatest(value$, initial$),
    rx.map(([value, initial]) => objShallowDifference(value, initial)),
    shareReplayRefOne(),
  )(null);

  proxy.handler
    .getLeaf()
    .addIsValidListener(({ isValid }) => isValid$.next(isValid));

  proxy.handler
    .getLeaf()
    .addValidationResultsListener(({ validationResults }) =>
      validationResults$.next(validationResults),
    );

  proxy.handler.getLeaf().addValueListener(({ value }) => value$.next(value));

  proxy.handler
    .getLeaf()
    .addInitialListener(({ initial }) => initial$.next(initial));

  return {
    proxy,
    isValid$,
    validationResults$,
    value$,
    initial$,
    changes$,
  };
}
