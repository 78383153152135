import BaseController from '~/source/common/controllers/base';
import template from './portfolio-stats-bar.html';

class Controller extends BaseController {}

export const PortfolioStatsBarComponent = {
  template,
  controller: Controller,
  bindings: {
    stats: '<',
    selectedPlatform: '<',
    showTotalBalance: '<',
    showFreeMargin: '<',
    showTotalMargin: '<',
  },
};

export default PortfolioStatsBarComponent;
