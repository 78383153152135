import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import template from './brand-create.component.html';

const styles = require('./brand-create.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import { StateService } from '@uirouter/core';

import BaseController from '~/source/common/controllers/base';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import BrandsService from '~/source/management/brand/services/brands';
import { Brand } from '@proftit/crm.api.models.entities';
import { Permissions } from '~/source/common/models/permission-structure';
import { createEmptyBrand } from './create-empty-brand';
import { createLoginMethodForm } from './create-login-method-form';
import { CustomerLoginTypeCode } from '@proftit/crm.api.models.enums';

export class BrandCreateController extends BaseController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  brand: Brand;
  Permissions = Permissions;

  loginMethodForm = createLoginMethodForm();

  brand$ = this.streamBrand();

  /*@ngInject */
  constructor(
    readonly $scope: ng.IScope,
    readonly modelNormalizer: ModelNormalizerService,
    readonly brandsService: () => BrandsService,
    readonly $state: StateService,
  ) {
    super();

    useStreams(
      [this.loginMethodForm.value$, this.brand$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamBrandFromLoginMethodForm(brand$: rx.Observable<Brand>) {
    return rx.pipe(
      () => this.loginMethodForm.value$ as rx.Observable<Brand>,
      rx.withLatestFrom(brand$),
      rx.map(([formVal, brand]) => {
        return _.flow([
          (b) => ({ ...b, ...formVal }),
          (b) => {
            if (b.customerLoginType === CustomerLoginTypeCode.Phone) {
              return {
                ...b,
                is2faPswdResetEnabled: true,
              };
            }

            return b;
          },
        ])(brand);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamBrandFromInit() {
    return rx.pipe(
      () => rx.obs.of(createEmptyBrand()),
      shareReplayRefOne(),
    )(null);
  }

  streamBrand() {
    const brand$ = new rx.BehaviorSubject<Brand>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamBrandFromInit(),
          this.streamBrandFromLoginMethodForm(brand$),
        ),
      rx.tap((brand) => {
        this.brand = brand;
        brand$.next(brand);
      }),
    )(null);
  }

  /**
   * This method will transofmr brand.voipCredentials to key-value pairs array
   * @example: {url: "www.voicenter.com", key: "123456789"} =>
   * [{key: "url", value: "www.voicenter.com"}, {key: "key", value: "123456789"}]
   *
   * @return {array} voipCredentials as key-value pairs array
   */
  voipCredentialsToArray() {
    return Object.keys(this.brand.voipCredentials).map((key) => ({
      key,
      value: this.brand.voipCredentials[key],
    }));
  }

  /**
   * Persists the new brand (with all the platforms and desks) to the server.
   */
  save() {
    // to avoid modifying the original this.brand, copy it. this will be useful if the "post" will fail
    const brand = { ...this.brand };
    if (brand.voipCredentials) {
      brand.voipCredentials = this.voipCredentialsToArray() as any;
    }
    const normalizedModel = this.modelNormalizer.normalize(brand);

    // Save the brand, then redirect back to the brands list
    this.brandsService()
      .postWithQuery(normalizedModel)
      .then(() => {
        this.$state.go('^.list');
      });
  }
}

export const BrandCreateComponent = {
  template,
  controller: BrandCreateController,
  controllerAs: 'vm',
};
