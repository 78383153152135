import template from './download-template-info.component.html';

const styles = require('./download-template-info.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { contactsImportConfig } from '~/source/management/contacts-import/contacts-import.config';

export class DownloadTemplateInfoController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  countriesTemplateUrl = contactsImportConfig.countriesTemplate;
  contactExampleTemplateUrl = contactsImportConfig.contactExampleTemplate;

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const DownloadTemplateInfoComponent = {
  template,
  controller: DownloadTemplateInfoController,
};
