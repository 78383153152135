import * as _ from '@proftit/lodash';
import { promoCodesReportsSettings } from './promo-codes-report-settings';
const USER_SETTING_REPORTS_COLUMNS_KEY = 'promo-codesReportsTableColumnsV2';
import template from '../../common-reports-table.component.html';
import { CommonReportsTableController } from '../../common-reports-table.component';

export class PromoCodesReportTableController extends CommonReportsTableController {
  resource: string = 'promoCodes';
  exportResource: string = 'promo_codes';

  /* @ngInject */
  constructor(...args) {
    super(
      <any>promoCodesReportsSettings,
      USER_SETTING_REPORTS_COLUMNS_KEY,
      'promoCodesReportTable',
      args,
    );
  }

  get tableKey() {
    // for save the user setting we need to add it to the server const,
    // this will error now...
    return 'promo_codes_report_table';
  }
}

export const PromoCodesReportTableComponent = {
  bindings: {
    brand: '<',
  },
  template,
  controllerAs: 'vm',
  controller: PromoCodesReportTableController,
};
