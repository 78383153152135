import * as _ from '@proftit/lodash';
import CacheFactory from 'cachefactory';

import BaseService from '~/source/common/services/baseService';

export interface CacheParams {
  storageMode: string;
}

export class CacheService extends BaseService {
  static $inject = ['CacheFactory'];

  CacheFactory: CacheFactory.ICacheFactory;

  /**
   * store values in local store
   * @param {String} cacheId  the cache identifier of local storage data
   * @param {Object} data the data to store in local storage
   */
  put(cacheNamespace: string, cacheId: string, data: any) {
    this.getCreateCache(cacheNamespace).put(cacheId, data);
  }

  /**
   * returns cached data for the given cache id.
   *
   * It first gets the cache factory for this resource,
   * then tries to get the requested cache id.
   * If the cache id does not exist, it returns undefined.
   *
   * @param {String} cacheId  the cache identifier of local storage data
   * @returns {Object} returns a filter collection from local storage
   */
  get(cacheNamespace: string, cacheId: string): any {
    return this.getCreateCache(cacheNamespace).get(cacheId);
  }

  /**
   * Initialize the cache to a new cache. If one exist already with the same params, then do nothing.
   *
   * @param {string} cacheNamepsace - cache main identifier.
   * @param {CacheParams} cacheParams - parameters for setting the cache engine.
   * @returns {void}
   */
  initializeSafe(
    cacheNamespace: string,
    cacheParams: Partial<CacheParams> = {},
  ): void {
    const currentCache = this.getCreateCache(cacheNamespace, cacheParams);

    const cacheInfo = currentCache.info();
    if (cacheInfo.storageMode !== cacheParams.storageMode) {
      this.CacheFactory.destroy(cacheNamespace);
      this.createCachFactory(cacheNamespace, cacheParams);
    }
  }

  /**
   * @private
   */
  createCachFactory(
    cacheNamespace: string,
    cacheParamsP: Partial<CacheParams> = {},
  ) {
    const cacheParams = _.defaultsFp({
      storageMode: 'localStorage',
    })(cacheParamsP);

    return this.CacheFactory(cacheNamespace, cacheParams);
  }

  /**
   * @private
   */
  getCreateCache(
    cacheNamespace: string,
    cacheParams: Partial<CacheParams> = {},
  ) {
    let cache = this.CacheFactory.get(cacheNamespace, cacheParams);

    if (_.isNil(cache)) {
      cache = this.createCachFactory(cacheNamespace, cacheParams);
    }

    return cache;
  }
}

export default CacheService;
