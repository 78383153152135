import angular from 'angular';

import RestService from '~/source/common/services/rest';
import CampaignsChangesClientPubsubService from './campaigns-changes-client-pubsub/campaigns-changes-client-pubsub.service';
import { Campaign } from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { MarketingCampaignReward } from '~/source/common/data-sources/marketing-campaign-rewards-source';

const CURRENCIES_RESOURCE = 'currencyConnections';
const BLACKLISTED_COUNTRIES_RESOURCE = 'blacklistedCountries';
const BATCH_ACTION_INDICATION = '~';
const AUTOMATIC_REGISTRATION_REWARDS = 'automaticRegistrationRewards';
const AUTOMATIC_DEPOSIT_REWARDS_FTD = 'automaticDepositRewardsFtd';
const AUTOMATIC_DEPOSIT_REWARDS_NON_FTD = 'automaticDepositRewardsNonFtd';

export default class MarketingCampaignsService extends RestService {
  static $inject = [...RestService.$inject, 'campaignsChangesClientPubsub'];

  campaignsChangesClientPubsub: CampaignsChangesClientPubsubService;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'campaigns';
  }

  /**
   * Update user group ips collection. we use restangular customPUT method in order to batch update the ip collection
   *
   * @param {Number} campaignId
   * @param {Array} currencies
   * @returns {Promise}
   */
  updateCurrencies(campaignId, currencies) {
    return this.resourceBuildStart()
      .getElement(campaignId)
      .getNestedElement(CURRENCIES_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(currencies);
  }

  /**
   * get country restrictions resource
   * @param {number} campaignId
   * @returns {RestService}
   */
  getBlacklistedCountriesResource(campaignId) {
    return this.resourceBuildStart()
      .getElement(campaignId)
      .getNestedCollection(BLACKLISTED_COUNTRIES_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Update user group ips collection. we use restangular customPUT method in order to batch update the ip collection
   *
   * @param {Number} campaignId
   * @param {Array} countries
   * @returns {Promise}
   */
  updateBlacklistedCountries(campaignId, countries) {
    return this.resourceBuildStart()
      .getElement(campaignId)
      .getNestedElement(BLACKLISTED_COUNTRIES_RESOURCE, BATCH_ACTION_INDICATION)
      .resourceBuildEnd()
      .customPutWithQuery(countries);
  }

  /**
   * Update an existing campaign. Encapsulate update of campaign.
   *
   * @param id - id
   * @param data - campaign data to update
   * @return rest promise of the update request
   */
  updateCampaign(id: string, data: any): Promise<Campaign> {
    /*
     * todoOld: why `patchCollection` was chosen instead of patchElement.
     * They are the same implementation.
     */
    return this.patchCollection<IElementRestNg<Campaign>>(id, data).then(
      (resp) => {
        this.campaignsChangesClientPubsub.publish(resp.plain());
        return resp;
      },
    );
  }

  massReplaceAutomaticRewards(
    campaignId: number,
    bonusType: MarketingCampaignReward,
    bonusesData: any[],
  ) {
    return this.resourceBuildStart()
      .getElement(campaignId)
      .getNestedElement(
        this.getRewardSubResourceForType(bonusType),
        BATCH_ACTION_INDICATION,
      )
      .resourceBuildEnd()
      .customPutWithQuery(bonusesData)
      .then((res) => res.plain());
  }

  getRewardSubResourceForType(bonusType: MarketingCampaignReward) {
    switch (bonusType) {
      case MarketingCampaignReward.Registration:
        return AUTOMATIC_REGISTRATION_REWARDS;
      case MarketingCampaignReward.DepositFtd:
        return AUTOMATIC_DEPOSIT_REWARDS_FTD;
      case MarketingCampaignReward.DepositNonFtd:
        return AUTOMATIC_DEPOSIT_REWARDS_NON_FTD;
      default:
        throw new Error('invalid bonus type selected for mass replace');
    }
  }

  /**
   * For now  - use mock
   *
   * @returns {boolean}
   */
  useMock() {
    return false;
  }

  useCache() {
    return false;
  }
}
