import ng from 'angular';

import marketingDashboardSettings from './settings/marketing-dashboard.json';
import marketingCampaignSettings from './settings/campaign.json';
import ViewPixelCodeModalController from './components/pixel-code-popup/view-pixel-code-modal.controller';
// pages
import CampaignCreatePage from './components/campaign-create/campaign-create.component';
import CampaignUpdatePage from './components/campaign-update/campaign-update.component';
import MarketingDashboardComponent from './components/marketing-dashboard/marketing-dashboard.component';
// modules
import CampaignBlacklistModule from './components/campaign-blacklisted-countries/module';
import MarketingTabsModule from './components/dashboard-tabs/module';
import ParamsToTrackModule from './components/parameters-to-track/module';
import ThirdPartyTrackingModule from './components/third-party-tracking/module';
// other components
import CampaignMinDepositData from './components/campaign-minimum-deposit/campaign-minimum-deposit-data.component';
import CampaignStatusSelect from './components/campaign-statuses-select.component';
import CampaignsTrendGraph from './components/trend/campaign-trend-graph.component';
// services
import CampaignStatusesService from './services/campaign-statuses.service';
import MarketingCampaignsService from './services/campaigns.service';
import MarketingDashboardService from './services/marketing-stats.service';
import MarketingPricingModelService from './services/pricing-models.service';
import MarketingTrackerParamsService from './services/tracker-params.service';
import GeographicStatsService from './services/geographic-stats.service';
import CampaignsChangesClientPubsubService from './services/campaigns-changes-client-pubsub/campaigns-changes-client-pubsub.service';
import CampaignSettingsData from './components/campaign-settings-data/campaign-settings-data.component';
import CampaignSettingsFrom from './components/campaign-settings-form/campaign-settings-form.component';
import { AutomaticBonusManagerComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-manager/automatic-bonus-manager.component';
import { AutomaticBonusDataComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-data/automatic-bonus-data.component';
import { AutomaticBonusFormComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-form/automatic-bonus-form.component';
import { AutomaticBonusRegionComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-region/automatic-bonus-region.component';
import { AutomaticBonusRegistrationRowComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-registration-row/automatic-bonus-registration-row.component';
import { AutomaticBonusDepositManagerComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-deposit-manager/automatic-bonus-deposit-manager.component';
import { AutomaticBonusDepositRowComponent } from '~/source/marketing/components/automatic-bonus/automatic-bonus-deposit-row/automatic-bonus-deposit-row.component';

export default ng
  .module('crm.marketing', [
    'ui.router',
    'crm.brand',
    'crm.common',
    'validation',
    CampaignBlacklistModule,
    MarketingTabsModule,
    ParamsToTrackModule,
    ThirdPartyTrackingModule,
  ])
  .constant('marketingDashboardSettings', marketingDashboardSettings)
  .constant('marketingCampaignSettings', marketingCampaignSettings)
  .controller(
    'MarketingViewPixelCodeModalController',
    ViewPixelCodeModalController,
  )
  .component('prfCampaignCreatePage', CampaignCreatePage)
  .component('prfCampaignUpdatePage', CampaignUpdatePage)
  .component('prfMarketingDashboard', MarketingDashboardComponent)
  .component('prfCampaignMinimumDepositData', CampaignMinDepositData)
  .component('prfMarketingCampaignStatusesSelect', CampaignStatusSelect)
  .component('prfCampaignTrendGraph', CampaignsTrendGraph)
  .component('prfCampaignSettingsData', CampaignSettingsData)
  .component('prfCampaignSettingsForm', CampaignSettingsFrom)
  .component('prfAutomaticBonusManager', AutomaticBonusManagerComponent)
  .component('prfAutomaticBonusRegion', AutomaticBonusRegionComponent)
  .component('prfAutomaticBonusData', AutomaticBonusDataComponent)
  .component('prfAutomaticBonusForm', AutomaticBonusFormComponent)
  .component(
    'prfAutomaticBonusDepositManager',
    AutomaticBonusDepositManagerComponent,
  )
  .component(
    'prfAutomaticBonusRegistrationRow',
    AutomaticBonusRegistrationRowComponent,
  )
  .component('prfAutomaticBonusDepositRow', AutomaticBonusDepositRowComponent)
  .factory(
    'marketingCampaignStatusesService',
    CampaignStatusesService.$factorize(),
  )
  .factory('marketingCampaignsService', MarketingCampaignsService.$factorize())
  .factory('marketingDashboardService', MarketingDashboardService.$factorize())
  .factory('geographicStatsService', GeographicStatsService.$factorize())
  .service('marketingPricingModelService', MarketingPricingModelService)
  .service('marketingTrackerParamsService', MarketingTrackerParamsService)
  .service('campaignsChangesClientPubsub', CampaignsChangesClientPubsubService)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider
        .state('crm.marketing-dashboard', {
          // statistics and list of campaigns
          url: '/marketing-dashboard',
          component: 'prfMarketingDashboard',
          data: {
            permissions: {
              only: ['marketing'],
              redirectTo: 'error.unauthorized',
            },
            pageTitle: 'MARKETING',
          },
        })
        .state('crm.create-campaign', {
          url: '/create',
          component: 'prfCampaignCreatePage',
          data: {
            permissions: {
              only: ['marketing.campaigns_C'],
              redirectTo: 'error.unauthorized',
            },
          },
        })
        .state('crm.copy-campaign', {
          url: '/copy/:campaignId',
          component: 'prfCampaignCreatePage',
          data: {
            permissions: {
              only: ['marketing.campaigns_C'],
              redirectTo: 'error.unauthorized',
            },
          },
        })
        .state('crm.update-campaign', {
          // campaign update page
          url: '/update/:campaignId',
          component: 'prfCampaignUpdatePage',
          data: {
            permissions: {
              only: ['marketing.campaigns'],
              redirectTo: 'error.unauthorized',
            },
          },
        });
    },
  ]).name;
