import { isCurrencyACryptoCurrency } from './is-currency-a-crypto-currency';
import { getSymbolForCryptoCurrency } from './get-symbol-for-crypto-currency';

/**
 * Get symbol for currency.
 *
 * Under the hood, it statuses Intl NumberFormat.
 *
 * @param currency
 * @returns currency symbol
 */
export function getSymbolForCurrency(currency: string) {
  if (isCurrencyACryptoCurrency(currency)) {
    return getSymbolForCryptoCurrency(currency);
  }

  return new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
  })
    .format(1)
    .substring(0, 1);
}
