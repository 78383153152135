import template from './inactivity-fee-activity-log-table.component.html';
const styles = require('./inactivity-fee-activity-log-table.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { TableController } from '~/source/common/components/table/table.controller';
import { inactivityFeeActivityLogTableSettings } from './inactivity-fee-activity-log-table.settings';
import { inactivityFeeActivityLogTableCols } from '../inactivity-fee-activity-log-table.cols';
import { CustomersService } from '~/source/contact/common/services/customers';
import {
  Brand,
  InactivityFeeSettingLog,
  InactivityFeeSetting,
} from '@proftit/crm.api.models.entities';
import { BrandsService } from '~/source/management/brand/services/brands';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeCompChange } from '~/source/common/utilities/observe-comp-change';
import * as _ from '@proftit/lodash';
import { calcInfoForLogRecord } from '../../shared/calc-info-for-log-record';
import { filterCompChange } from '~/source/common/utilities/rxjs/observables/filter-comp-change';
import * as rx from '@proftit/rxjs';

export class InactivityFeeActivityLogTableController extends TableController {
  static $inject = ['brandsService', '$translate', ...TableController.$inject];

  customersService: () => CustomersService;
  $translate: angular.translate.ITranslateService;

  styles = styles;
  lifecycles = observeComponentLifecycles(this);

  settings = { ...inactivityFeeActivityLogTableSettings };
  cols = [...inactivityFeeActivityLogTableCols];

  brandsService: () => BrandsService;
  dataServiceInstance: BrandsService;
  brand: Brand;
  fee: InactivityFeeSetting;
  logs: InactivityFeeSettingLog[];

  brand$ = new rx.BehaviorSubject<Brand>(null);
  fee$ = new rx.BehaviorSubject<InactivityFeeSetting>(null);

  constructor(...args) {
    super(...args);

    useStreams(
      [
        this.streamInitTable(),
        observeCompChange<InactivityFeeSetting>(
          this.fee$,
          'fee',
          this.lifecycles.onChanges$,
        ),
        observeCompChange<Brand>(
          this.brand$,
          'brand',
          this.lifecycles.onChanges$,
        ),
        this.streamOpReload(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    this.dataServiceInstance = this.brandsService();
  }

  $onDestroy() {}

  $onChanges() {}

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean}
   */
  showColumnActions() {
    return false;
  }

  fetchFn() {
    return this.dataServiceInstance
      .getInactivityFeeSettingLogsResource(this.brand.id, this.fee.id)
      .setConfig({ blockUiRef: 'inactivityFeeActivityLogTable' })
      .expand(['user'])
      .sort({ createdAt: 'desc' });
  }

  parseLoadedData(data) {
    data.forEach(
      (rec) => (rec._info = calcInfoForLogRecord(rec, this.$translate)),
    );

    this.logs = data;

    return data;
  }

  streamInitTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          rx.pipe(
            rx.distinctUntilChanged(
              (a: Brand, b: Brand) => _.get('id', a) === _.get('id', b),
            ),
          )(this.brand$),
          rx.pipe(
            rx.distinctUntilChanged(
              (a: InactivityFeeSetting, b: InactivityFeeSetting) =>
                _.get('id', a) === _.get('id', b),
            ),
          )(this.fee$),
        ),
      rx.filter(([...args]) => args.every((x) => !_.isNil(x))),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamOpReload() {
    return rx.pipe(
      () =>
        filterCompChange<rx.Observable<void>>(
          'opReload',
          this.lifecycles.onChanges$,
        ),
      rx.map(({ currentValue }) => currentValue),
      rx.map((opReload$) => _.defaultTo(rx.obs.EMPTY, opReload$)),
      rx.switchMap((opReload$) => opReload$),
      rx.tap(() => this.reloadTable()),
    )(null);
  }
}

export const InactivityFeeActivityLogTableComponent = {
  template,
  controller: InactivityFeeActivityLogTableController,
  controllerAs: 'vm',
  bindings: {
    brand: '<',
    fee: '<',
    config: '<',
    opReload: '<',
  },
};
