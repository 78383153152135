import * as _ from '@proftit/lodash';

export function calcInfoForBrandDemoDepositAutomationAuditLog(data) {
  return _.flow([
    (d) => {
      if (data.action !== 'activation') {
        return {
          oldValue: data.oldValue,
          newValue: data.newValue,
        };
      }

      return {
        ...d,
        oldValue: data.oldValue === 0 ? false : true,
        newValue: data.newValue === 0 ? false : true,
      };
    },
  ])({});
}
