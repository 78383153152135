import { CfdPlatformAsset } from '../models/cfd-platform/cfd-platform-asset';

export function calcAssetFixedSpread(
  asset: CfdPlatformAsset,
  marketData,
): number {
  const ask = marketData.ask * Math.pow(10, asset.precision);
  const bid = marketData.bid * Math.pow(10, asset.precision);
  const diff = ask - bid;
  const diffRound = Math.round(diff);
  const fixedSpread = diffRound / asset.minMove;

  return fixedSpread;
}
