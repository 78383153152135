/* global APP_VERSION */
import log from 'loglevel';

declare const APP_VERSION: string;

function config(localStorageService) {
  const cachedAppVersion = localStorageService.get('appVersion');

  if (!cachedAppVersion) {
    localStorageService.set('appVersion', APP_VERSION);

    return;
  }

  if (APP_VERSION !== cachedAppVersion) {
    log.warn(
      'App version changed! Current: %s Cached: %s. Emptying localStorage...',
      APP_VERSION,
      cachedAppVersion,
    );

    /**
     * This will remove all cache entities starting with the configured prefix.
     * Since the prefix is the same for localStorageService and angular-cache, both will be erased.
     */
    localStorageService.clearAll();
  }
}

config.$inject = ['localStorageService'];

export default config;
