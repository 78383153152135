import * as _ from '@proftit/lodash';

/**
 *  Converts a date acceptable by the Date function, and converts it to ISO-8601 formatted date
 */
export function booleanToWordFactory() {
  return function (
    input: boolean,
    translateBooleanPath: string = 'booleanToWord.yesNo',
  ) {
    if (_.isNil(input)) {
      return '';
    }

    return `${translateBooleanPath}.${input.toString().toUpperCase()}`;
  };
}
booleanToWordFactory.$inject = [];
