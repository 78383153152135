import ng, { ITemplateCacheService } from 'angular';

import searchSettings from './settings/search.json';
import ngTableHeaderTemplate from './templates/ng-table/custom-filter-header.html';
import AmChartsModule from './components/amcharts/module';
import CallManagerModule from './components/call-manager/module';
import ClockModule from './components/clock/module';
import { DatePickerModule } from './components/date-picker/module';
import { CustomPrfDropdownsModule } from './custom-prf-dropdowns/custom-prf-dropdowns.module';
import CollectionNotificationsModule from './components/collection-notifications/module';
import { DropdownsModule } from './components/dropdowns/module';
import NotificationsModule from './components/notifications/module';
import TableModule from './components/table/module';
import { TableFiltersModule } from './components/table-filters/module';
import ModalModule from './components/modal/module';
import UserAssignModule from './components/user-assign-dropdown/module';
import { FilterModule } from './filter/filter.module';

import FeaturesFlagsService from './services/features-flags.service';
import PendingPositionsForexSocketService from './services/pending-positions-forex-socket.service';
import PendingPositionsStatusesForexService from './services/pending-positions-statuses-forex.service';
import codeTranslationsSettings from './filter/code-translations.settings';
import CodeTranslationFilterFactory from './filter/code-translate.filter';
import TrustAsHtmlFilter from './filter/trust-as-html.filter';
import TableCacheService from './services/table-cache.service';
import CacheService from './services/cache.service';
import ModalsCacheService from './services/modals-cache.service';
import ClearingCompaniesService from './services/clearing-companies';
import BrandCurrenciesFields from './components/brand-currencies-fields/brand-currencies-fields.component';
import baseCurrencyFormatFactory from './filter/base-currency-format';
import ModelNormalizerService from './services/model-normalizer';
import removeTrailingZerosFilter from './filter/remove-trailing-zeros';
import getFieldFactory from './filter/get-field';
import snakeCaseFilterFactory from './filter/snake-case.filter';
import toFixedFilterFactory from './filter/to-fixed.filter';
import CollapsibleListComponent from './components/collapsible-list/collapsible-list.component';
import ConfirmationLineComponent from './components/confirmation-line/confirmation-line.component';
import ActionsMenuDropdownComponent from './components/actions-menu-dropdown/actions-menu-dropdown.component';
import IdleController from './controllers/idle.controller';
import PasswordFormComponent from './components/password/password-form';
import PasswordDataComponent from './components/password/password-data';
import NumberRangeComponent from './components/number-range/number-range';
import ConfirmDialogComponent from './components/confirm-dialog/confirm-dialog.component';
import DateButtonComponent from './components/date-button/date-button.component';
import CustomerPropertyValueRendererComponent from './components/customer-property-value-renderer/customer-property-value-renderer.component';
import InputMaskDirective from './components/inputmask/input-mask.directive';
import CurrenciesService from './services/currencies';
import UserSettingsService from '~/source/common/services/user-settings';
import AddWithdrawalReqNotifierService from './services/add-withdrawal-request-notifier.service';
import CustomerComplianceRemovedClientPubsubService from './services/customer-compliance-removed-client-pubsub.service';
import CustomerPropertyTypesService from './services/customer-property-types.service';
import CustomerPropertiesService from './services/customer-properties.service';
import VerificationStatusMethodsService from './services/verification-status-methods';
import KibiReportCsvService from './services/kibi-report-csv.service';
import ComponentCreatorDirective from './components/component-creator/component-creator.directive';
import { SocketRegistryService } from './services/socket-registry.service';
import { MainMenuComponent } from './components/menu/main-menu';
import { UserFilters } from './services/user-filters';
import { PublicBrandsService } from './services/brands-public';
import { VoipProvidersService } from './services/voip-providers';
import { UserSocketService } from './services/user-socket';
import { TradingAccountStatuses } from './services/trading-account-statuses';
import { PlatformsService } from './services/platforms';
import { PlatformTypesService } from './services/platforms-types';
import { LanguagesService } from './services/languages';
import { HighlightEntityService } from './services/highlight-entity';
import { FilesUploadService } from './services/files-upload';
import { DateTzAdjustService } from './services/date-tz-adjust';
import { CountryStatesService } from './services/countryStates';
import { CountriesService } from './services/countries';
import { DirectiveIf } from './components/directive-if/directive-if';
import { ClientGeneralPubsub } from './services/client-general-pubsub';
import { GenericConfirmDialogComponent } from './components/generic-confirm-dialog/generic-confirm-dialog.component';
import { DraggableDirective } from './components/draggable/draggable';
import { ElasticFieldsCollectionDirective } from './components/elastic-fields/elastic-fields-collection';
import { ElasticFieldsElementDirective } from './components/elastic-fields/elastic-fields-element';
import { FocusIfDirective } from './components/focus-if/focus-if.directive';
import { HideElementDirective } from './components/hide-element/hide-element';
import { IframeLoaderComponent } from './components/iframe-loader/iframe-loader';
import InlineEditFieldComponent from '~/source/common/components/inline-edit-field/inline-edit-field.component';
import LayoutComponent from '~/source/common/layout/layout';
import { ngModelInitialValidityDirective } from './directives/ng-model-initial-validity.directive';
import { IfContainerComponent } from './components/if-container/if-container.component';
import { focusOnClickDirective } from './directives/focus-on-click/focus-on-click.directive';
import { SharedGroupFiltersService } from './services/shared-group-filters.service';
import { SharedGroupFiltersPivotService } from './services/shared-group-filters-pivot.service';
import { auditLogFieldDisplay } from './filter/audit-log-field-display.filter';
import { SideMenuItemComponent } from './components/side-menu-item/side-menu-item.component';
import { SimpleDropdownSelectedItemComponent } from './components/simple-dropdown-selected-item/simple-dropdown-selected-item.component';
import { SimpleDropdownItemComponent } from './components/simple-dropdown-item/simple-dropdown-item.component';
import { InactivityCriteriasService } from './services/inactivity-criterias.service';
import { InactivityFeeChargeTypesService } from './services/inactivity-fee-charge-types.service';
import { limitToDotsFilterFactory } from './filter/limit-to-dots.filter';
import { asTextListFactory } from './filter/as-text-list.filter';
import { userMenuButtonComponent } from './components/user-menu-button/user-menu-button';
import { CommunicationTypesService } from './services/communication-types.service';
import { DefaultFilesService } from './services/default-files';
import { Mt4AccountPnlSocketService } from './services/mt4-account-pnl-socket.service';
import { Mt4BrandStatsSocketService } from './services/mt4-brand-stats-socket.service';
import { Mt4AccountFreeMarginSocketService } from './services/mt4-account-free-margin-socket.service';
import { Mt4AccountMarginLevelSocketService } from './services/mt4-account-margin-level-socket.service';
import { Mt4OrderPnlSocketService } from './services/mt4-order-pnl-socket.service';
import { ScriptsService } from './services/scripts.service';
import { phoneEditingDirective } from './directives/phone-editing/phone-editing.directive';
import { AddContactDialogComponent } from './components/add-contact-dialog/add-contact-dialog.component';
import { AddContactFormComponent } from './components/add-contact-form/add-contact-form.component';
import { SimpleCacheService } from './services/simple-cache.service';
import { CfdPlatformAccountOpenPnlSocketService } from './api-cfd-platform/cfd-platform-account-open-pnl-socket.service';
import { CfdPlatformAccountMarginLevelSocketService } from './api-cfd-platform/cfd-platform-account-margin-level-socket.service';
import { CfdPlatformAccountFreeMarginSocketService } from './api-cfd-platform/cfd-platform-account-free-margin-socket.service';
import { CfdPlatformPositionPayoutSocketService } from './api-cfd-platform/cfd-platform-position-payout-socket.service';
import { BundlePlatformAccountOpenPnlSocketService } from './api-bundle-platform/bundle-platform-account-open-pnl-socket.service';

import { currencyCodeToSymbolFactory } from './filter/currency-code-to-symbol.filter';
import { trimTextFactory } from './filter/trim-text.filter';
import { ApiCrmServerModule } from './api-crm-server/api-crm-server.module';
import { booleanToWordFactory } from './filter/boolean-to-word.filter';
import { DesignTemplateSelectComponent } from './components/dropdowns/design-template-select.component';
import { CommunicationActionsComponent } from './components/communication-actions/communication-actions.component';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';
import { SendEmailItemComponent } from './components/send-email-item/send-email-item.component';
import { SendCustomEmailFormComponent } from './components/send-custom-email-form/send-custom-email-form.component';
import { SendTemplateEmailFormComponent } from './components/send-template-email-form/send-template-email-form.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { ClearingCompanyReorderApprovalDialogComponent } from './components/clearing-company-reorder-approval-dialog/clearing-company-reorder-approval-dialog.component';
import { ExternalWindowService } from './services/external-window.service';
import { BrandsPnlStatsSocketService } from './services/brands-pnl-stats-socket.service';
import { CurrencyRatesService } from './services/currency-rates.service';
import { iframeContentDirective } from './directives/iframe-content/iframe-content.directive';
import { EmailTemplatePreviewService } from './services/email-template-preview.service';
import { BundleStatsSocketService } from '~/source/common/services/bundle-stats-socket.service';
import { CustomerSearchResultComponent } from './components/customer-search-result/customer-search-result.component';
import { CustomerSearchSelectComponent } from './components/customer-search-select/customer-search-select.component';
import { classesForNgOptionsDirective } from './directives/classes-for-ng-options/classes-for-ng-options.directive';
import { ComplianceDockTypesManagementService } from './api-crm-server/services/compliance-dock-types-management.service';
import { AudioPlayerModule } from './components/audio-player/module';
import { filterOnlyBrandsWithAudoUpdateCampaignsFilterFactory } from './filter/filter-only-brands-with-audo-update-campaigns.filter';
import { QuestionnaireScoresService } from './services/questionnaire-scores.service';
import { FormControlValidationMessageComponent } from './components/form-control-validation-message/form-control-validation-message.component';
import { ValidationMessageTranslateGeneralComponent } from './components/validation-message-translate-general/validation-message-translate-general.component';
import { StoreServicesModule } from './store-services/store-services.module';
import { GeneralSharedWorkerService } from './services/general-shared-worker.service';
import { NoticePopupService } from './services/notice-popup-service';
import { ContactsImportSocketService } from './services/contacts-import-socket-service';
import { NoticePopupListComponent } from './components/notice-popup-list/notice-popup-list.component';
import { NoticeItemComponent } from './components/notice-item/notice-item.component';
import { NoticeItemContactsImportComponent } from './components/notice-item-contacts-import/notice-item-contacts-import.component';
import { NoticeItemContactsImportCompleteComponent } from './components/notice-item-contacts-import-complete/notice-item-contacts-import-complete.component';
import { SmsProvidersService } from '~/source/common/services/sms-providers';
import { PositionForexService } from './services/position-forex.service';
import { PortfolioService } from './services/portfolio.service';
import { prfBlockUiOnDirective } from './directives/prf-block-ui-on/prf-block-ui-on.directive';
import { ApiCfdPlatformModule } from './api-cfd-platform/api-cfd-platform.module';
import { proxyFormNg1Module } from './proxy-form.ng1/proxy-form-ng1.module';
import { ServiceDirectivesModule } from './service-directives/service-directives.module';
import { SystemService } from './services/system.service';

const ProxyFormNg1Module = proxyFormNg1Module(ng);
import { EmailProvidersService } from '~/source/common/services/email-providers.service';
import { TestEmailConnectionService } from '~/source/common/services/test-email-connection.service';
import { GmailNotificationCenterComponent } from './components/gmail/gmail-notification-center/gmail-notification-center.component';
import { GmailNotificationCenterPopupComponent } from './components/gmail/gmail-notification-center-popup/gmail-notification-center-popup.component';
import { GmailNotificationComponent } from '~/source/common/components/gmail/notification-list/gmail-notification/gmail-notification.component';
import { GmailNotificationListComponent } from '~/source/common/components/gmail/notification-list/gmail-notification-list/gmail-notification-list.component';
import { ScrollTopDirective } from './directives/scroll-top/scroll-top.directive';
import { LastLoginNoticeComponent } from '~/source/common/components/last-login-notice/last-login-notice.component';
import { LastLoginNoticePopupService } from '~/source/common/services/last-login-notice-popup-service';
import { PageTitleService } from '~/source/common/services/page-title.service';
import { RatesService } from '~/source/common/services/rates.service';
import { PublicSystemPhoneVerificationService } from './services/public-system-phone-verification';
import { PublicSystemLogin2faService } from './services/public-system-login-2fa.service';
import { BalanceLogsExportService } from '~/source/common/services/balance-log-export.sevice';
import { CommunicationSubjectsStoreService } from './store-services/communication-subjects-store.service';
import { CommunicationSubjectsChangeSocketService } from '~/source/common/services/communication-subjects-change-socket-service';
import { WarmRedisCacheService } from './services/warm-redis-cache-service';
import { PrfAssigmentCountsService } from './services/prf-assigment-counts.service';
import { CustomerAssignmentLogsService } from './services/customer-assignment-logs.service';
import { createFactoryForService } from '@proftit/ng1.adjunct';
import { AccountStatusAutomationTypesService } from './services/account-status-automation-types.service';
import { PrivateGoogleStorageImageComponent } from './components/private-google-storage-image/private-google-storage-image.component';
import { PrivateGoogleStorageFileService } from './services/private-google-storage-file.service';
import { EmailCenterPopupComponent } from './components/gmail/email-center-popup/email-center-popup.component';
import { UserEmailsService } from './services/user-emails.service';
import { ClearEmailNotificationsService } from './services/clear-email-notifications.service';
import { YupValidationErrorComponent } from '~/source/common/components/yup-validation-error/yup-validation-error.component';
import { ChosenMultipleDropdownComponent } from '~/source/common/components/chosen-multiple-dropdown/chosen-multiple-dropdown.component';
import { ChosenSingleDropdownComponent } from '~/source/common/components/chosen-single-dropdown/chosen-single-dropdown.component';
import { PrfRxService } from './services/prf-rx.service';
import { EmailSearchService } from './services/email-search.service';
import { EmailCenterService } from './services/email-center.service';
import { SingleNumberInputComponent } from './components/single-number-input/single-number-input.component';
import { PrfInviewContainerComponent } from '~/source/common/components/prf-inview-container/prf-inview-container.component';
import { UserCurrenciesService } from './services/user-currencies.service';
import { CustomerNavigationService } from './services/customer-navigation.service';
import { TooltipInfoCopyBarComponent } from '~/source/common/components/tooltip-info-copy-bar/tooltip-info-copy-bar.component';
import startCaseFilterFactory from '~/source/common/filter/start-case';
import { PublicVersionsService } from '~/source/common/services/public-versions.service';
import { BundleAccountPnlSocketService } from '~/source/common/services/bundle-account-pnl-socket.service';
import { UtilsService } from './utils/utils.service';
import { PfTasksListPopupService } from './services/pf-tasks-list-popup.service';
import { translateCustomLoader } from '~/source/common/services/translate-custom-loader';
import { ProvidersModule } from './providers/providers.module';
import { IntercomService } from './services/intercom.service';
import { IntercomComponent } from './components/intercom/intercom.component';

export const CommonModule = ng.module('crm.common', [
  'crm.constants',
  AmChartsModule,
  CallManagerModule,
  ClockModule,
  CustomPrfDropdownsModule.name,
  DropdownsModule.name,
  ModalModule,
  DatePickerModule.name,
  CollectionNotificationsModule,
  NotificationsModule,
  TableModule,
  TableFiltersModule.name,
  UserAssignModule,
  ApiCrmServerModule.name,
  AudioPlayerModule.name,
  StoreServicesModule.name,
  ApiCfdPlatformModule.name,
  ProxyFormNg1Module.name,
  ServiceDirectivesModule.name,
  FilterModule.name,
  ProvidersModule.name,
]);

CommonModule.constant('searchSettings', searchSettings)
  .constant('codeTranslationsSettings', codeTranslationsSettings)
  .factory('publicBrandsService', PublicBrandsService.$factorize())
  .factory('countriesService', CountriesService.$factorize())
  .factory('balanceLogsExportService', BalanceLogsExportService.$factorize())
  .service('featuresFlags', FeaturesFlagsService)
  .service('currencyRatesService', CurrencyRatesService)
  .service('addWithdrawalReqNotifierService', AddWithdrawalReqNotifierService)
  .service(
    'pendingPositionsForexSocketService',
    PendingPositionsForexSocketService,
  )
  .service(
    'pendingPositionsStatusesForexService',
    PendingPositionsStatusesForexService,
  )
  .service('privateGoogleStorageFileService', PrivateGoogleStorageFileService)
  .service('cacheService', CacheService)
  .service('modalsCacheService', ModalsCacheService)
  .service('tableCacheService', TableCacheService)
  .service('modelNormalizer', ModelNormalizerService)
  .service('clearingCompaniesService', ClearingCompaniesService)
  .service('currenciesService', CurrenciesService)
  .service('userCurrenciesService', UserCurrenciesService)
  .service('userSettingsService', UserSettingsService)
  .service('defaultFilesService', DefaultFilesService)
  .service('brandsPnlStatsSocketService', BrandsPnlStatsSocketService)
  .service(
    'prfCustomerComplianceRemovedClientPubsub',
    CustomerComplianceRemovedClientPubsubService,
  )
  .service('customerPropertyTypesService', CustomerPropertyTypesService)
  .service('customerPropertiesService', CustomerPropertiesService)
  .service('verifiedMethodsService', VerificationStatusMethodsService)
  .service('kibiReportCsvService', KibiReportCsvService)
  .service('prfSocketRegistryService', SocketRegistryService)
  .service('userFiltersService', UserFilters)
  .service('voipProvidersService', VoipProvidersService)
  .service('smsProvidersService', SmsProvidersService)
  .service('emailProvidersService', EmailProvidersService)
  .service('testEmailConnectionService', TestEmailConnectionService)
  .service('userSocketService', UserSocketService)
  .service('platformsService', PlatformsService)
  .service('platformTypesService', PlatformTypesService)
  .service('languagesService', LanguagesService.$factorize())
  .service('highlightEntityService', HighlightEntityService)
  .service('filesUploadService', FilesUploadService)
  .service('dateTzAdjustService', DateTzAdjustService)
  .service('countryStatesService', CountryStatesService)
  .service('prfClientGeneralPubsub', ClientGeneralPubsub)
  .service('sharedGroupFiltersService', SharedGroupFiltersService)
  .service('sharedGroupFiltersPivotService', SharedGroupFiltersPivotService)
  .service('inactivityCriteriasService', InactivityCriteriasService)
  .service('inactivityFeeChargeTypesService', InactivityFeeChargeTypesService)
  .service('prfScriptsService', ScriptsService)
  .service('communicationTypesService', CommunicationTypesService)
  .service(
    'mt4AccountPnlSocketService',
    Mt4AccountPnlSocketService.$factorize(),
  )
  .service(
    'bundleAccountPnlSocketService',
    BundleAccountPnlSocketService.$factorize(),
  )
  .service('mt4BrandStatsSocketService', Mt4BrandStatsSocketService)
  .service('bundleStatsSocketService', BundleStatsSocketService)
  .service(
    'mt4AccountMarginLevelSocketService',
    Mt4AccountMarginLevelSocketService.$factorize(),
  )
  .service(
    'mt4AccountFreeMarginSocketService',
    Mt4AccountFreeMarginSocketService.$factorize(),
  )

  .service('mt4OrderPnlSocketService', Mt4OrderPnlSocketService.$factorize())
  .service('prfScriptsService', ScriptsService)
  .service('prfSimpleCacheService', SimpleCacheService)
  .service(
    'cfdPlatformAccountOpenPnlSocketService',
    CfdPlatformAccountOpenPnlSocketService.$factorize(),
  )
  .service(
    'cfdPlatformAccountMarginLevelSocketService',
    CfdPlatformAccountMarginLevelSocketService.$factorize(),
  )
  .service(
    'cfdPlatformAccountFreeMarginSocketService',
    CfdPlatformAccountFreeMarginSocketService.$factorize(),
  )
  .service(
    'cfdPlatformPositionPayoutSocketService',
    CfdPlatformPositionPayoutSocketService.$factorize(),
  )
  .service(
    'bundlePlatformAccountOpenPnlSocketService',
    BundlePlatformAccountOpenPnlSocketService.$factorize(),
  )
  .service('externalWindowService', ExternalWindowService)
  .service('emailTemplatePreviewService', EmailTemplatePreviewService)
  .service('prfGeneralSharedWorkerService', GeneralSharedWorkerService)
  .service('prfNoticePopupService', NoticePopupService)
  .service('prfContactsImportSocketService', ContactsImportSocketService)
  .service('prfRatesService', RatesService)
  .service('prfLastLoginNoticePopupService', LastLoginNoticePopupService)
  .service('prfPageTitleService', PageTitleService)
  .service(
    'communicationSubjectsStoreService',
    CommunicationSubjectsStoreService,
  )
  .service(
    'communicationSubjectsChangeSocketService',
    CommunicationSubjectsChangeSocketService,
  )
  .service('publicVersionsService', PublicVersionsService)
  .service('warmRedisCacheService', WarmRedisCacheService)
  .service('userEmailsService', UserEmailsService)
  .service('clearEmailNotificationsService', ClearEmailNotificationsService)
  .service('prfEmailSearchService', EmailSearchService)
  .service('prfEmailCenterService', EmailCenterService)
  .service('prfCustomerNavigationService', CustomerNavigationService)
  .service('pfTasksListPopupService', PfTasksListPopupService)
  .service('intercomService', IntercomService)
  .factory('prfTranslateCustomLoader', translateCustomLoader)
  .factory(
    'prfComplianceDockTypesManagementService',
    ComplianceDockTypesManagementService.$factorize(),
  )
  .factory(
    'questionnaireScoresService',
    QuestionnaireScoresService.$factorize(),
  )
  .factory('prfPositionForexService', PositionForexService.$factorize())
  .factory('portfolioService', PortfolioService.$factorize())
  .factory('tradingAccountStatusesService', TradingAccountStatuses.$factorize())
  .factory(
    'accountStatusAutomationTypesService',
    AccountStatusAutomationTypesService.$factorize(),
  )
  .filter('codeTranslate', CodeTranslationFilterFactory)
  .filter('trustAsHtml', TrustAsHtmlFilter)
  .filter('baseCurrencyFormat', baseCurrencyFormatFactory)
  .filter('removeTrailingZeros', removeTrailingZerosFilter)
  .filter('prfGetField', getFieldFactory)
  .filter('prfSnakeCase', snakeCaseFilterFactory)
  .filter('prfToFixed', toFixedFilterFactory)
  .filter('prfAuditLogFieldDisplay', auditLogFieldDisplay)
  .filter('prfLimitToDots', limitToDotsFilterFactory)
  .filter('prfStartCase', startCaseFilterFactory)
  .filter('prfAsTextList', asTextListFactory)
  .filter('booleanToWord', booleanToWordFactory)
  .filter('prfCurrencyCodeToSymbol', currencyCodeToSymbolFactory)
  .filter('trimText', trimTextFactory)
  .filter(
    'filterOnlyBrandsWithAudoUpdateCampaigns',
    filterOnlyBrandsWithAudoUpdateCampaignsFilterFactory,
  )
  .controller('IdleController', IdleController)
  .directive('prfInputMask', InputMaskDirective)
  .directive('prfComponentCreator', ComponentCreatorDirective)
  .directive('prfDirectiveIf', DirectiveIf)
  .directive('prfDraggble', DraggableDirective)
  .directive('prfElasticFieldsCollection', ElasticFieldsCollectionDirective)
  .directive('prfElasticFieldsElement', ElasticFieldsElementDirective)
  .directive('prfFocusIf', FocusIfDirective)
  .directive('prfHideElement', HideElementDirective)
  .directive('prfScrollTop', ScrollTopDirective)
  .directive('prfNgModelInitialValidity', ngModelInitialValidityDirective)
  .directive('prfFocusOnClick', focusOnClickDirective)
  .directive('prfPhoneEditing', phoneEditingDirective)
  .directive('prfIframeContent', iframeContentDirective)
  .directive('prfClassesForNgOptions', classesForNgOptionsDirective)
  .directive('prfBlockUiOn', prfBlockUiOnDirective)
  .component('prfBrandCurrenciesFields', <any>BrandCurrenciesFields)
  .component('prfConfirmationLine', <any>ConfirmationLineComponent)
  .component('prfActionsMenuDropdown', <any>ActionsMenuDropdownComponent)
  .component('prfCollapsibleList', CollapsibleListComponent)
  .component('prfPasswordForm', PasswordFormComponent)
  .component('prfPasswordData', PasswordDataComponent)
  .component('prfNumberRange', NumberRangeComponent)
  .component('prfSingleNumberInput', SingleNumberInputComponent)
  .component('prfConfirmDialog', ConfirmDialogComponent)
  .component('prfDateButton', DateButtonComponent)
  .component(
    'prfCustomerPropertyValueRenderer',
    CustomerPropertyValueRendererComponent,
  )
  .component('prfMainMenu', MainMenuComponent)
  .component('prfGenericConfirmDialog', GenericConfirmDialogComponent)
  .component('prfIframeLoader', IframeLoaderComponent)
  .component('prfInlineEditField', InlineEditFieldComponent)
  .component('prfLayout', LayoutComponent)
  .component('prfIfContainer', IfContainerComponent)
  .component('prfInviewContainer', PrfInviewContainerComponent)
  .component('prfSideMenuItem', SideMenuItemComponent)
  .component(
    'prfSimpleDropdownSelectedItem',
    SimpleDropdownSelectedItemComponent,
  )
  .component('prfTooltipInfoCopyBar', TooltipInfoCopyBarComponent)
  .component('prfSimpleDropdownItem', SimpleDropdownItemComponent)
  .component('prfUserMenuButton', userMenuButtonComponent)
  .component('prfAddContactDialog', AddContactDialogComponent)
  .component('prfAddContactForm', AddContactFormComponent)
  .component('prfDesignTemplateSelect', DesignTemplateSelectComponent)
  .component('prfCommunicationActions', CommunicationActionsComponent)
  .component('prfSendEmailDialog', SendEmailDialogComponent)
  .component('prfSendEmailItem', SendEmailItemComponent)
  .component('prfSendCustomEmailForm', SendCustomEmailFormComponent)
  .component('prfSendTemplateEmailForm', SendTemplateEmailFormComponent)
  .component('prfGenericDialog', GenericDialogComponent)
  .component('prfCustomerSearchResult', CustomerSearchResultComponent)
  .component('prfCustomerSearchSelect', CustomerSearchSelectComponent)
  .component(
    'prfFormControlValidationMessage',
    FormControlValidationMessageComponent,
  )
  .component(
    'prfValidationMessageTranslateGeneral',
    ValidationMessageTranslateGeneralComponent,
  )
  .component('prfNoticePopupList', NoticePopupListComponent)
  .component('prfNoticeItem', NoticeItemComponent)
  .component('prfNoticeItemContactsImport', NoticeItemContactsImportComponent)
  .component(
    'prfNoticeItemContactsImportComplete',
    NoticeItemContactsImportCompleteComponent,
  )
  .component(`prfGmailNotificationCenter`, GmailNotificationCenterComponent)
  .component(
    `prfGmailNotificationCenterPopup`,
    GmailNotificationCenterPopupComponent,
  )
  .component(`prfGmailNotification`, GmailNotificationComponent)
  .component(`prfGmailNotificationList`, GmailNotificationListComponent)
  .component(`prfLastLoginNotice`, LastLoginNoticeComponent)
  .component(
    `prfClearingCompanyReorderApprovalDialog`,
    ClearingCompanyReorderApprovalDialogComponent,
  )
  .component(`prfPrivateGoogleStorageImage`, PrivateGoogleStorageImageComponent)
  .component(`prfEmailCenterPopup`, EmailCenterPopupComponent)
  .component(`prfYupValidationError`, YupValidationErrorComponent)
  .component(`prfChosenMultipleDropdown`, ChosenMultipleDropdownComponent)
  .component(`prfChosenSingleDropdown`, ChosenSingleDropdownComponent)
  .component('prfIntercom', IntercomComponent)

  .run([
    '$templateCache',
    ($templateCache: ITemplateCacheService) => {
      $templateCache.put(
        'ng-table-custom-filter-header.html',
        ngTableHeaderTemplate,
      );
    },
  ]);

CommonModule.factory(
  'prfAssigmentCountsService',
  PrfAssigmentCountsService.$factorize(),
);
CommonModule.factory(
  'prfCustomerAssignmentLogsService',
  createFactoryForService(CustomerAssignmentLogsService),
);
CommonModule.service('prfRx', PrfRxService);
CommonModule.service('prfSystemService', SystemService);
CommonModule.service(
  'prfPublicSystemPhoneVerificationService',
  PublicSystemPhoneVerificationService,
);
CommonModule.service(
  'prfPublicSystemLogin2faService',
  PublicSystemLogin2faService,
);
CommonModule.service('prfUtils', UtilsService);

export default CommonModule;
