import { IPromise } from 'restangular';
import {
  SystemEmailTemplate,
  ContentTemplate,
  SystemContentTemplate,
} from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from '../../services/client-general-pubsub';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';

const CONTENT_TEMPLATES_RESOURCE = 'contentTemplates';

export class SystemEmailTemplatesService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'systemEmails';
  }

  getSystemEmailTemplateResource(id) {
    return this.resourceBuildStart().getElement(id).resourceBuildEnd();
  }

  getSystemEmailTemplateContentTemplatesResource(systemId: number) {
    return this.resourceBuildStart()
      .getElement(systemId)
      .getNestedCollection(CONTENT_TEMPLATES_RESOURCE)
      .resourceBuildEnd();
  }

  getSystemEmailTemplateContentTemplateResource(
    systemId: number,
    contentId: number,
  ) {
    return this.resourceBuildStart()
      .getElement(systemId)
      .getNestedElement(CONTENT_TEMPLATES_RESOURCE, contentId)
      .resourceBuildEnd();
  }

  getSystemEmailTemplateContentTemplateFull(
    systemId: number,
    contentId: number,
  ) {
    return this.getSystemEmailTemplateResource(systemId)
      .embed(['contentTemplates'])
      .expand([
        'brand',
        'brand.file:logo',
        'type',
        'contentTemplates.designTemplate',
        'contentTemplates.language',
      ])
      .getOneWithQuery<IElementRestNg<ContentTemplate>>()
      .then((data) => data.plain())
      .then((data) => {
        const contentTemplate = data.contentTemplates.find(
          (c) => c.id === contentId,
        );

        if (_.isNil(contentTemplate)) {
          throw new Error(
            `content template ${contentId} for system template ${systemId} was not found`,
          );
        }

        contentTemplate.system = _.pick(
          ['id', 'brand', 'isActive', 'type'],
          data,
        );

        return contentTemplate;
      });
  }

  getItem(id: number) {
    return this.getSystemEmailTemplateResource(id)
      .expand(['type', 'contentTemplate', 'brand'])
      .getOneWithQuery<IElementRestNg<SystemEmailTemplate>>();
  }

  getOneWithAllContentTemplates(id: number): Promise<SystemEmailTemplate> {
    return this.getSystemEmailTemplateResource(id)
      .expand(['type', 'brand', 'contentTemplates.language'])
      .embed(['contentTemplates'])
      .getOneWithQuery<IElementRestNg<SystemEmailTemplate>>();
  }

  getAllForBrand(brandId: number): Promise<SystemEmailTemplate[]> {
    return this.filter({ brandId })
      .expand(['type', 'brand'])
      .getListWithQuery<IElementRestNg<SystemEmailTemplate>>()
      .then((data) => data.plain()) as any;
  }

  createTemplate(
    systemId: number,
    model: SystemContentTemplate,
  ): Promise<SystemContentTemplate> {
    return this.getSystemEmailTemplateContentTemplatesResource(systemId)
      .postWithQuery<IElementRestNg<ContentTemplate>>(model)
      .then((data) => data.plain()) as any;
  }

  updateContentTemplate(
    systemId: number,
    contentId: number,
    model: SystemContentTemplate,
  ): Promise<SystemContentTemplate> {
    return this.getSystemEmailTemplateContentTemplateResource(
      systemId,
      contentId,
    )
      .patchWithQuery<IElementRestNg<ContentTemplate>>(model)
      .then((data) => data.plain()) as any;
  }

  setIsActive(id: number, isActive: boolean): Promise<SystemEmailTemplate> {
    return this.getSystemEmailTemplateResource(id)
      .expand(['type', 'brand'])
      .embed(['contentTemplates'])
      .patchWithQuery<IElementRestNg<SystemEmailTemplate>>({
        id,
        isActive,
      })
      .then((data) => data.plain()) as any;
  }
}
