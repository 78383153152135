import { convertFormAccountFieldsToAccountFields } from './convert-form-account-fields-to-account-fields';
import { switchOn } from '../../utilities/switch-on';
import { PlatformTypeCode } from '@proftit/crm.api.models.enums';

export function addAccountDataToCustomer(customerData, accountData) {
  const accountFieldName = switchOn(
    {
      [PlatformTypeCode.Forex]: () => 'tradingAccountsForex',
      [PlatformTypeCode.Binary]: () => 'tradingAccountsBinary',
    },
    accountData.accountPlatform.platformType.code,
    () => {
      throw new Error('unexpected');
    },
  );

  const accountNewData = convertFormAccountFieldsToAccountFields(accountData);

  return {
    ...customerData,
    [accountFieldName]: [accountNewData],
  };
}
