import { IScope } from 'angular';

import Controller from '~/source/common/controllers/base';

import template from './ip-list.html';
import { UserGroupsService } from '../group/services/user-groups';

class IpListController extends Controller {
  userGroupsServiceInstance: UserGroupsService;
  tableParams: any;
  globalIpList: any;

  /*@ngInject */
  constructor(
    readonly $scope: IScope,
    readonly ipListSettings,
    readonly userGroupsService: () => UserGroupsService,
    readonly NgTableParams,
  ) {
    super();
  }

  $onInit() {
    this.userGroupsServiceInstance = this.userGroupsService();

    this.initTableParams();
  }

  /**
   * Initialize the groups ips table
   */
  initTableParams() {
    const ngTableSettings = Object.assign(
      {},
      this.ipListSettings.ngTable.settings,
      {
        getData: (params) => this.getData(params),
      },
    );

    this.tableParams = new this.NgTableParams(
      this.ipListSettings.ngTable.parameters,
      ngTableSettings,
    );
  }

  /**
   * Gets the data from API.
   * Called automatically by NgTable on any page/filter change.
   *
   * @param {object} params - NgTableParams
   * @returns {Promise} response from server
   */
  getData(params) {
    return this.userGroupsServiceInstance
      .getUserGroupIpsResource()
      .getListWithQuery()
      .then((groups) => {
        params.total(groups.length);

        // Create global ip array to use in ip auto-complete list
        this.globalIpList = this.createGlobalIpList(groups);

        // Create group.ip array from embedded whitelistips field
        return this.prepareUserGroupsObj(groups);
      });
  }

  /**
   * Enter edit mode by changing the "isEdit" property of the group to true.
   * @param {object} group
   */
  editGroup(group) {
    group.isEdit = true;
  }

  /**
   * Create global ip list array from user groups for autocomplete list
   * @param {object} groupsObj
   * @returns {Array}
   */
  createGlobalIpList(groupsObj) {
    const ipList = [];

    // Iterate over user group
    groupsObj.forEach((group) => {
      if (group.whitelistips) {
        // Iterate over user group ips
        group.whitelistips.forEach(({ ipAddress }) => {
          // Add non existing ips to global ip list
          if (!ipList.includes(ipAddress)) {
            ipList.push(ipAddress);
          }
        });
      }
    });

    return ipList;
  }

  /**
   * Create group.ip array from embedded whitelistips field
   * @param {object} groupsObj
   * @returns {object} groupsObj with ip array
   */
  prepareUserGroupsObj(groupsObj) {
    return groupsObj.map((group) => {
      // Map whitelist objects's array provided by the resource to simple ips array
      group.ip = group.whitelistips.map(({ ipAddress }) => ipAddress);

      return group;
    });
  }
}

export default {
  template,
  controller: IpListController,
};
