import ng from 'angular';
import { ReportsComponent } from './reports/reports.component';
import { CustomersComponent } from './all-reports-components/customers/customers-report-table/customers.component';
import { CustomersReportTableComponent } from './all-reports-components/customers/customers-report-table/customers-report-table.component';
import { TradingAccountsReportTableComponent } from './all-reports-components/customers/trading-accounts-report-table/trading-accounts-report-table.component';
import { TradingAccountsComponent } from './all-reports-components/customers/trading-accounts-report-table/trading-accounts.component';
import { CommunicationsReportTableComponent } from './all-reports-components/customers/communications-report-table/communications-report-table.component';
import { AttachmentsComponent } from './all-reports-components/customers/attachments-report-table/attachments.component';
import { AttachmentsReportTableComponent } from './all-reports-components/customers/attachments-report-table/attachments-report-table.component';
import { DepositsComponent } from './all-reports-components/transactions/deposits-report-table/deposits.component';
import { DepositsReportTableComponent } from './all-reports-components/transactions/deposits-report-table/deposits-report-table.component';
import { FtdsComponent } from './all-reports-components/transactions/ftds-report-table/ftds.component';
import { FtdsReportTableComponent } from './all-reports-components/transactions/ftds-report-table/ftds-report-table.component';
import { OpenWithdrawalsComponent } from './all-reports-components/transactions/open-withdrawals-report-table/open-withdrawals.component';
import { OpenWithdrawalsReportTableComponent } from './all-reports-components/transactions/open-withdrawals-report-table/open-withdrawals-report-table.component';
import { BonusesComponent } from './all-reports-components/transactions/bonuses-report-table/bonuses.component';
import { BonusesReportTableComponent } from './all-reports-components/transactions/bonuses-report-table/bonuses-report-table.component';
import { FeesComponent } from './all-reports-components/transactions/fees-report-table/fees.component';
import { FeesReportTableComponent } from './all-reports-components/transactions/fees-report-table/fees-report-table.component';
import { CreditsComponent } from './all-reports-components/transactions/credits-report-table/credits.component';
import { CreditsReportTableComponent } from './all-reports-components/transactions/credits-report-table/credits-report-table.component';
import { PromoCodesComponent } from './all-reports-components/transactions/promo-codes-report-table/promo-codes.component';
import { PromoCodesReportTableComponent } from './all-reports-components/transactions/promo-codes-report-table/promo-codes-report-table.component';
import { PositionsComponent } from './all-reports-components/trades/positions-report-table/positions.component';
import { PositionsReportTableComponent } from './all-reports-components/trades/positions-report-table/positions-report-table.component';
import { BundlesComponent } from './all-reports-components/trades/bundles-report-table/bundles.component';
import { BundlesReportTableComponent } from './all-reports-components/trades/bundles-report-table/bundles-report-table.component';
import StatsComponent from './stats/stats.component';
import { CommunicationsComponent } from './all-reports-components/customers/communications-report-table/communications-component';
import { FailedDepositsComponent } from './all-reports-components/transactions/failed-deposits-report-table/failed-deposits-component';
import { FailedDepositsReportTableComponent } from './all-reports-components/transactions/failed-deposits-report-table/failed-deposits-report-table.component';
import { DepositsWithdrawalsComponent } from './all-reports-components/transactions/deposits-withdrawals-report-table/deposits-withdrawals-component';
import { WithdrawalsReportTableComponent } from './all-reports-components/transactions/withdrawals-report-table/withdrawals-report-table.component';
import { WithdrawalsComponent } from './all-reports-components/transactions/withdrawals-report-table/withdrawals.component';
import { DepositsWithdrawalsReportTableComponent } from './all-reports-components/transactions/deposits-withdrawals-report-table/deposits-withdrawals-report-table.component';
import ReportCacheService from './reports/services/report-cache.service';

export default ng
  .module('crm.crmReports', ['crm.constants', 'crm.common', 'ui.router'])
  .component('prfReports', ReportsComponent)
  .component('prfCustomersReport', CustomersComponent)
  .component('prfCustomersReportTable', CustomersReportTableComponent)
  .component('prfTradingAccountsReport', TradingAccountsComponent)
  .component(
    'prfTradingAccountsReportTable',
    TradingAccountsReportTableComponent,
  )
  .component('prfCommunicationsReport', CommunicationsComponent)
  .component('prfCommunicationsReportTable', CommunicationsReportTableComponent)
  .component('prfAttachmentsReport', AttachmentsComponent)
  .component('prfAttachmentsReportTable', AttachmentsReportTableComponent)
  .component('prfDepositsReport', DepositsComponent)
  .component('prfDepositsReportTable', DepositsReportTableComponent)
  .component('prfFtdsReport', FtdsComponent)
  .component('prfFtdsReportTable', FtdsReportTableComponent)
  .component('prfWithdrawalsReport', WithdrawalsComponent)
  .component('prfWithdrawalsReportTable', WithdrawalsReportTableComponent)
  .component('prfOpenWithdrawalsReport', OpenWithdrawalsComponent)
  .component(
    'prfOpenWithdrawalsReportTable',
    OpenWithdrawalsReportTableComponent,
  )
  .component('prfBonusesReport', BonusesComponent)
  .component('prfBonusesReportTable', BonusesReportTableComponent)
  .component('prfFeesReport', FeesComponent)
  .component('prfFeesReportTable', FeesReportTableComponent)
  .component('prfCreditsReport', CreditsComponent)
  .component('prfCreditsReportTable', CreditsReportTableComponent)
  .component('prfPositionsReport', PositionsComponent)
  .component('prfPositionsReportTable', PositionsReportTableComponent)
  .component('prfBundlesReport', BundlesComponent)
  .component('prfBundlesReportTable', BundlesReportTableComponent)
  .component('prfPromoCodesReport', PromoCodesComponent)
  .component('prfPromoCodesReportTable', PromoCodesReportTableComponent)
  .component('prfFailedDepositsReport', FailedDepositsComponent)
  .component('prfFailedDepositsReportTable', FailedDepositsReportTableComponent)
  .component('prfDepositsWithdrawalsReport', DepositsWithdrawalsComponent)
  .service('reportCacheService', ReportCacheService)
  .component(
    'prfDepositsWithdrawalsReportTable',
    DepositsWithdrawalsReportTableComponent,
  )
  .component('prfStatisticsReport', StatsComponent)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.crmReports', {
        url: '/reports',
        component: 'prfReports',
        data: {
          permissions: {
            only: ['reporting'],
            redirectTo: 'error.unauthorized',
          },
          pageTitle: 'REPORTS',
        },
      });
    },
  ]).name;
