import type { translate } from 'angular';
import { SelectOption } from '../custom-prf-dropdowns/simple-dropdown/select-option';

export enum EnumId {
  DaterangeType = 'daterange_type',
}

export class UtilsService {
  /* @ngInject */
  constructor(readonly $translate: translate.ITranslateService) {}

  enumToSelectOptions<T>(
    subject: Record<string, T>,
    subjectName: EnumId,
  ): SelectOption<T>[] {
    return Object.entries(subject).map(([key, val]) => {
      const newKey = this.$translate.instant(
        generateTranslationEnumKey(key, subjectName),
      );

      return {
        label: newKey,
        value: val,
      };
    });
  }
}

function generateTranslationEnumKey(key: string, subjectName: string) {
  return `enums.${subjectName}.${key.toUpperCase()}`;
}
