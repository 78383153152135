import ng from 'angular';
import Big from 'big.js';

import BaseController from '~/source/common/controllers/base';

import template from './internal-transfer-table-popup.component.html';
import { TablePopupController } from '~/source/common/components/table/table-popup';
import { CustomersService } from '~/source/contact/common/services/customers';
import { Customer, TradingAccount } from '@proftit/crm.api.models.entities';
import { internalTransferTablePopupColumns } from './internal-transfer-table-popup-columns';
import { internalTransferTablePopupSettings } from './internal-transfer-table-popup-settings';

export interface IInternalTransferTablePopup {
  customer: Customer;
  account: TradingAccount;
}

export class InternalTransferTablePopupController extends TablePopupController {
  static $inject = ['customersService', ...TablePopupController.$inject];

  /*
   * Injections
   */
  customersService: () => CustomersService;

  /*
   * Dialog bindings
   */
  customer: Customer;
  account: TradingAccount;

  dataServiceInstance: CustomersService;

  onResolveChange(value: IInternalTransferTablePopup) {
    this.customer = value.customer;
    this.account = value.account;
  }

  get cols() {
    return [...internalTransferTablePopupColumns];
  }

  get ngTableSettings() {
    return { ...internalTransferTablePopupSettings.ngTable };
  }

  get title() {
    return 'contact.tradingAccount.INTERNAL_TRANSFER';
  }

  get tableKey() {
    return 'internalTransfer';
  }

  /**
   * this function is called by parent
   * @returns {*}
   */
  query() {
    return this.dataServiceInstance
      .getInternalTransfersResource(this.customer.id, this.account.id)
      .expand([
        'user',
        'sourceTradingAccount.currency',
        'destinationTradingAccount.currency',
      ]);
  }

  parseLoadedData(data) {
    data.forEach((tr) => {
      tr._rateAsFixed = Big(tr.rate).toFixed();
    });

    return data;
  }
}

export const InternalTransferTablePopupComponent = {
  template,
  controller: InternalTransferTablePopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
