export const customerPageViewAuditLogsPopupColumns = [
  {
    title: 'common.DATE',
    field: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: 'createdAt',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'auditLogs.fields.VIEWED_BY',
    field: 'user',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: 'userFirstName',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    field: 'createdByEnum',
    fieldFormat:
      '{{ ("auditLogs.fields.createdBy." + createdBy) | translate  }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'createdBy',
    title: 'auditLogs.fields.USER_TYPE',
  },
  {
    title: 'auditLogs.fields.IP',
    field: 'ip',
    sortable: 'ip',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.COUNTRY',
    field: 'country',
    fieldFormat: '{{ country }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'auditLogs.fields.BROWSER',
    field: 'browser',
    sortable: 'browser',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'auditLogs.fields.DEVICE',
    field: 'device',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'auditLogs.fields.MODEL',
    field: 'model',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
];
