import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './private-google-storage-image.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import CustomersService from '~/source/contact/common/services/customers';
import {
  GoogleStorageDomain,
  PrivateGoogleStorageFileService,
} from '~/source/common/services/private-google-storage-file.service';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
const styles = require('./private-google-storage-image.component.scss');

export class PrivateGoogleStorageImageController {
  styles = styles;

  apiParams;
  domain;

  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateBlockuiId();

  apiParams$ = observeShareCompChange(this.lifecycles.onChanges$, 'apiParams');
  domain$ = observeShareCompChange(this.lifecycles.onChanges$, 'domain');

  shouldShowImage$ = new rx.BehaviorSubject<boolean>(false);
  privateImage$ = this.streamPrivateImage();

  /* @ngInject */
  constructor(
    readonly customersService: () => CustomersService,
    readonly privateGoogleStorageFileService: PrivateGoogleStorageFileService,
  ) {
    useStreams([this.privateImage$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamPrivateImage() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.apiParams$, this.domain$),
      rx.filter(
        ([apiParams, domain]) => !_.isNil(apiParams) && !_.isNil(domain),
      ),
      rx.switchMap(([apiParams, domain]) => {
        this.shouldShowImage$.next(false);
        return rx.obs
          .from(this.getDomainRequest(domain, apiParams))
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap(() => {
        this.shouldShowImage$.next(true);
      }),
      shareReplayRefOne(),
    )(null);
  }

  getDomainRequest(domain, apiParams) {
    switch (domain) {
      case GoogleStorageDomain.Attachments:
        return this.privateGoogleStorageFileService.getAttachmentFileUrl(
          apiParams.customerId,
          apiParams.attachmentId,
          this.blockUiId,
        );
      default:
        return;
    }
  }
}

export const PrivateGoogleStorageImageComponent = {
  template,
  controller: PrivateGoogleStorageImageController,
  bindings: {
    apiParams: '<',
    domain: '<',
  },
};
