import angular from 'angular';
import CreditCreateComponent from './create-credit/credit-create.component';
import CreditDeductComponent from './credit-deduct/credit-deduct.component';
import CreditTableComponent from './credits-table/credits-table.component';
import CreditTablePopupController from './credits-table-popup.controller';

export default angular
  .module('crm.contact.tradingAccount.forex.credit', [])
  .component('prfCreditCreate', CreditCreateComponent)
  .component('prfCreditDeduct', CreditDeductComponent)
  .component('prfCreditsTable', CreditTableComponent)
  .controller('creditTablePopupController', CreditTablePopupController).name;
