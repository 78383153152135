import BaseController from '~/source/common/controllers/base';
import template from './accounting-dashboard.html';
import { CrmAppStoreProviderController } from '../app/cfm-app-store-provider.component';

class AccountingDashboardController extends BaseController {
  prfCrmAppStoreProvider: CrmAppStoreProviderController;

  /*@ngInject*/
  constructor() {
    super();
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export default {
  template,
  controller: AccountingDashboardController,
  require: {
    prfCrmAppStoreProvider: '^',
    prfTranslationsProvider: '^',
  },
};
