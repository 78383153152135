import {
  shareReplayRefOne,
  generateReactiveGetterSetter,
  useStreams,
} from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './prf-tasks-calendar.component.html';
import { generateUuid, switchOn } from '@proftit/general-utilities';
import TasksService from '../tasks.service';
import moment, { Moment } from 'moment-timezone';
import addFns from 'date-fns/add';
import startOfDay from 'date-fns/startOfDay';
import setFns from 'date-fns/set';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

import type {
  CustomerCommunication,
  Task,
  TaskStatus,
  User,
} from '@proftit/crm.api.models.entities';
import {
  DateRangeType,
  getDateRangeByTypeAndStart,
  startOf,
  getMonthStartEndRange,
  addUnitByRange,
  subUnitByRange,
} from '@proftit/utils-datetime';
// tslint:disable-next-line:no-duplicate-imports
import type { DateRange } from '@proftit/utils-datetime';
import type { Default as FullCalendar } from 'fullcalendar/Calendar';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { serializeDateRange } from '~/source/common/components/table-filters/utilities/serialize-date-range';
import { DateFormatConstants } from '~/source/common/constants/date-format';
import { CommunicationsService } from '~/source/common/components/call-manager/communications.service';
import {
  EntityCode,
  UserRolePositionCode,
} from '@proftit/crm.api.models.enums';
import type { IPromise, translate } from 'angular';
import { CurrentUserStoreService } from '~/source/common/store-services/current-user-store.service';
import { followupToCalEvent } from './followup-to-cal-event';
import { taskToCalEvent } from './task-to-cal-event';
import { CrmCalEvent } from '../full-calendar/types/crm-cal-event';
import { OptionsInputBase } from 'fullcalendar';
import tasksStatusesService from '~/source/tasks/tasks-statuses.service';
import { CheckboxGroupItem } from '../checkbox-group/checkbox-group-item';
import { DateTzAdjustService } from '~/source/common/services/date-tz-adjust';
import {
  TASK_CREATED,
  TASK_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';

const styles = require('./prf-tasks-calendar.component.scss');

function isItBeforeToday(MomentDate: Moment) {
  return MomentDate.diff(moment(0, 'HH')) < 0;
}

function isItToday(MomentDate: Moment) {
  return MomentDate.isSame(moment(), 'day');
}

export class PrfTasksCalendarController {
  /* out bindings */

  onInstance: (a: { instance: PrfTasksCalendarController }) => void;

  onClickTask: (a: { taskId: number }) => void;

  onClickDay: (a: { dueDate: Date }) => void;

  onClickFollowUp: (a: { customerId: number }) => void;

  onSelectDateRange: (a: { dateRange: DateRange }) => void;

  /* constants */

  daterangeType: DateRangeType;

  styles = styles;

  /* inputs */

  lifecycles = observeComponentLifecycles(this);

  daterangeType$ = observeShareCompChange<DateRangeType>(
    this.lifecycles.onChanges$,
    'daterangeType',
  );

  /* actions */

  gotoTodayOp$ = new rx.Subject<void>();

  gotoPrevRangeOp$ = new rx.Subject<void>();

  gotoNextRangeOp$ = new rx.Subject<void>();

  onCalendarViewRander$ = new rx.Subject<any>();

  /* state */

  calendarId = generateUuid();

  uiCalendarInst$ = new rx.BehaviorSubject<FullCalendar>(null);

  calendarOptions$ = this.streamCalendarOptions();

  chooseUsers$ = new rx.Subject<User[]>();

  chosenUsers$ = this.streamChosenUsers();

  chosenUsersCs = generateReactiveGetterSetter<User[]>(
    this.chooseUsers$,
    this.chosenUsers$,
    this.lifecycles.onDestroy$,
  );

  setChosenTaskStatuses$ = new rx.Subject<TaskStatus[]>();

  chosenTaskStatuses$ = this.streamChosenTaskStatuses();

  chosenTaskStatusesCs = generateReactiveGetterSetter<TaskStatus[]>(
    this.setChosenTaskStatuses$,
    this.chosenTaskStatuses$,
    this.lifecycles.onDestroy$,
  );

  chosenDateRangeBs$ = new rx.BehaviorSubject<DateRange>(null);

  choosePointerDate$ = new rx.Subject<Date>();

  chosenPointerDate$ = this.streamChosenPointerDate();

  chosenDateRange$ = this.streamChosenDateRange();

  chosenPointerDateCs = generateReactiveGetterSetter<Date>(
    this.choosePointerDate$,
    this.chosenPointerDate$,
    this.lifecycles.onDestroy$,
  );

  tasksStatuses$ = this.streamTasksStatuses();
  tasks$ = this.streamTasks();

  showFollowUpsSubject$ = new rx.BehaviorSubject<boolean>(true);

  followUps$ = this.streamFollowUps();

  calendarEventsSourcesCs = this.constructCalendarEventsSources(
    this.tasks$,
    this.followUps$,
    this.lifecycles.onDestroy$,
  );

  isUserAllowedToSelectUsers$ = this.prfCurrentUserStore.currentLoggedUser$.pipe(
    rx.map((user) => {
      if (_.isNil(user)) {
        return false;
      }

      return user.role.code !== UserRolePositionCode.Regular;
    }),
    shareReplayRefOne(),
  );

  chosenPointerDateOptions = {};

  set showFollowUps(value: boolean) {
    this.showFollowUpsSubject$.next(value);
  }

  get showFollowUps() {
    return this.showFollowUpsSubject$.getValue();
  }

  /* @ngInject */
  constructor(
    readonly tasksService: TasksService,
    readonly communicationsService: CommunicationsService,
    readonly dateFormat: DateFormatConstants,
    readonly prfCurrentUserStore: CurrentUserStoreService,
    readonly tasksStatusesService: tasksStatusesService,
    readonly $translate: translate.ITranslateService,
    readonly dateTzAdjustService: DateTzAdjustService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams([this.daterangeType$], this.lifecycles.onDestroy$);
  }

  $onInit() {
    useStreams(
      [
        this.chosenDateRange$.pipe(
          rx.tap((dateRange) => this.onSelectDateRange({ dateRange })),
        ),
        this.streamSetCalendarView(),
      ],
      this.lifecycles.onDestroy$,
    );

    /*
     * The controller instance is shared with parent so
     * that parent can activate child methods. Through binding
     * its more difficult.
     */
    this.onInstance({ instance: this });
  }

  $onChanges() {}

  $onDestroy() {
    /*
     * The parent needs to be notified to clean its reference
     * so there will be no memory leaks.
     */
    this.onInstance({ instance: null });
  }

  streamCalendarOptions(): rx.Observable<OptionsInputBase> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.filter((isInit) => isInit),
      rx.map(() => {
        return {
          // typescript problem for height prop
          height: 'parent' as 'parent',
          dayClick: (date: Moment) => {
            if (isItBeforeToday(date)) {
              return;
            }

            // set not today time to the start of the work day
            let dueDate = setFns(date.toDate(), { hours: 8, minutes: 0 });

            if (isItToday(date)) {
              // set today time to 10 min from now
              dueDate = this.dateTzAdjustService.getUTCAdjustedTime(
                addFns(new Date(), { minutes: 10 }),
              );
            }

            this.onClickDay({ dueDate });
          },
          eventClick: (calEvent: CrmCalEvent) => {
            if (calEvent.extendedProps.entityCode === EntityCode.Task) {
              const taskId = calEvent.extendedProps.entityId;
              this.onClickTask({ taskId });
            }

            if (
              calEvent.extendedProps.entityCode ===
              EntityCode.CustomerCommunication
            ) {
              const customerId = calEvent.extendedProps.customerId;
              this.onClickFollowUp({ customerId });
            }
          },
          editable: false,
          defaultView: 'month',
          header: false,
          viewRender: (view, element) => {
            this.onCalendarViewRander$.next({ view, element });
          },
        } as any;
      }),
    )(null);
  }

  streamChosenUsers(): rx.Observable<User[]> {
    return rx.pipe(
      () => this.chooseUsers$,
      rx.startWith([]),
      shareReplayRefOne(),
    )(null);
  }

  streamChosenTaskStatuses(): rx.Observable<TaskStatus[]> {
    return rx.pipe(
      () => this.setChosenTaskStatuses$,
      rx.startWith([]),
      shareReplayRefOne(),
    )(null);
  }

  streamChosenPointerDateFromChosenDateRange(
    currDate$: rx.Observable<Date>,
  ): rx.Observable<Date> {
    return rx.pipe(
      () => this.chosenDateRangeBs$,
      rx.filter((x) => !_.isNil(x)),
      rx.withLatestFrom(currDate$),
      rx.filter(([newDateRange, currDate]) => {
        // stoping condition for infinite loop betwee pointer date and chosen date range.
        const mCurr = moment(currDate).add(1, 'minute');
        const mStart = moment(newDateRange.start);
        const mEnd = moment(newDateRange.end);
        const result = mCurr.isBetween(mStart, mEnd, null, '[]');
        return !result;
      }),
      rx.map(([newDateRange, _currDate]) => newDateRange.start),
    )(null);
  }

  streamChosenPointerDate(): rx.Observable<Date> {
    const pointerDate$ = new rx.BehaviorSubject<Date>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.choosePointerDate$.pipe(rx.map((date) => startOfDay(date))),
          this.streamChosenPointerDateFromChosenDateRange(pointerDate$),
        ),
      rx.tap((date) => pointerDate$.next(date)),
      shareReplayRefOne(),
    )(null);
  }

  streamChosenDateRangeFromGotoToday(): rx.Observable<DateRange> {
    return rx.pipe(
      () => this.gotoTodayOp$,
      rx.withLatestFrom(this.daterangeType$),
      rx.map(([_a, daterangeType]) => {
        const newStart = startOfDay(new Date());
        return getDateRangeByTypeAndStart(newStart, daterangeType);
      }),
    )(null);
  }

  streamChosenDateRangeFromGotoNextRange(
    currRange$: rx.Observable<DateRange>,
  ): rx.Observable<DateRange> {
    return rx.pipe(
      () => this.gotoNextRangeOp$,
      rx.withLatestFrom(this.daterangeType$, currRange$),
      rx.map(([_a, daterangeType, currRange]) => {
        const newStart = addUnitByRange(currRange.start, daterangeType, 1);
        return getDateRangeByTypeAndStart(newStart, daterangeType);
      }),
    )(null);
  }

  streamChosenDateRangeFromGotoPrevRange(
    currRange$: rx.Observable<DateRange>,
  ): rx.Observable<DateRange> {
    return rx.pipe(
      () => this.gotoPrevRangeOp$,
      rx.withLatestFrom(this.daterangeType$, currRange$),
      rx.map(([_a, daterangeType, currRange]) => {
        const newStart = subUnitByRange(currRange.start, daterangeType, 1);
        return getDateRangeByTypeAndStart(newStart, daterangeType);
      }),
    )(null);
  }

  streamChosenDateRangeFromChosenPointer(
    currRange$: rx.Observable<DateRange>,
  ): rx.Observable<DateRange> {
    return rx.pipe(
      () => this.chosenPointerDate$,
      rx.withLatestFrom(this.daterangeType$, currRange$),
      rx.filter(([pointerDate, _daterangeType, currRange]) => {
        // stoping condition for infinite loop betwee pointer date and chosen date range.
        const mPointer = moment(pointerDate).add(1, 'minute');
        const mStart = moment(currRange.start);
        const mEnd = moment(currRange.end);
        const result = mPointer.isBetween(mStart, mEnd, null, '[]');
        return !result;
      }),
      rx.map(([pointerDate, daterangeType]) => {
        const newStart = startOf(pointerDate, daterangeType);
        return getDateRangeByTypeAndStart(newStart, daterangeType);
      }),
    )(null);
  }

  streamChosenDateRangeFromDaterangeType(
    currRange$: rx.Observable<DateRange>,
  ): rx.Observable<DateRange> {
    return rx.pipe(
      () => this.daterangeType$,
      rx.withLatestFrom(currRange$),
      rx.map(([daterangeType, currRange]) => {
        const currentDate = new Date();
        if (
          isAfter(currentDate, currRange.start) &&
          isBefore(currentDate, currRange.end)
        ) {
          return getDateRangeByTypeAndStart(currentDate, daterangeType);
        }
        return getDateRangeByTypeAndStart(currRange.start, daterangeType);
      }),
    )(null);
  }

  streamChosenDateRange(): rx.Observable<DateRange> {
    const initialDateRange = getMonthStartEndRange(moment().toDate());

    const chosenDateRange$ = new rx.BehaviorSubject<DateRange>(
      initialDateRange,
    );

    const wasDateRangeInited$ = new rx.BehaviorSubject<boolean>(false);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamChosenDateRangeFromGotoToday(),
          this.streamChosenDateRangeFromGotoNextRange(chosenDateRange$),
          this.streamChosenDateRangeFromGotoPrevRange(chosenDateRange$),
          this.streamChosenDateRangeFromChosenPointer(chosenDateRange$),
          this.streamChosenDateRangeFromDaterangeType(chosenDateRange$),
        ),
      rx.tap((value) => {
        if (!_.isNil(value) && !wasDateRangeInited$.getValue()) {
          wasDateRangeInited$.next(true);
        }
      }),
      rx.map((value) => {
        const wasDateRangeInited = wasDateRangeInited$.getValue();
        if (wasDateRangeInited) {
          return value;
        }
        return initialDateRange;
      }),
      rx.tap((dateRange) => chosenDateRange$.next(dateRange)),
      rx.tap((dateRange) => this.chosenDateRangeBs$.next(dateRange)),
      shareReplayRefOne(),
    )(null);
  }

  streamTasksStatuses(): rx.Observable<CheckboxGroupItem<TaskStatus>[]> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$,
      rx.switchMap(() => this.fetchTasksStatuses()),
      rx.switchMap((TaskStatus) => {
        const statusesPromiseList = TaskStatus.map((status) => {
          const translation = `tasks.statuses.${status?.code.toUpperCase()}`;

          const newStatusP = this.$translate(translation).then((content) => {
            return {
              content,
              value: status,
              className: status.code,
            };
          });

          return newStatusP;
        });

        return Promise.all(statusesPromiseList);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamTaskCreatedOrUpdated() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === TASK_UPDATED || key === TASK_CREATED),
      rx.startWith(null),
    )(null);
  }

  streamTasks(): rx.Observable<Task[]> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.chosenDateRange$,
          this.chosenUsers$,
          this.chosenTaskStatuses$,
          this.streamTaskCreatedOrUpdated(),
        ]),
      rx.switchMap(([dateRange, users, statuses]) => {
        const usersNorm = users.map((u) => u.id);
        const statusesNorm = statuses.map((t) => t.id);

        return this.fetchTasks(
          usersNorm,
          statusesNorm,
          dateRange.start,
          dateRange.end,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFollowUps(): rx.Observable<CustomerCommunication[]> {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.chosenDateRange$,
          this.chosenUsers$,
          this.showFollowUpsSubject$,
        ]),
      rx.switchMap(([dateRange, users, showFollowUps]) => {
        if (!showFollowUps) {
          return rx.obs.of([]);
        }

        const usersNorm = users.map((u) => u.id);

        return this.fetchFollowUps(usersNorm, dateRange.start, dateRange.end);
      }),
      shareReplayRefOne(),
    )(null);
  }

  fetchTasksStatuses(): Promise<TaskStatus[]> {
    return this.tasksStatusesService
      .getListWithQuery<IElementRestNg<TaskStatus>>()
      .then((data) => data.plain());
  }

  fetchTasks(
    normalizedUsers: number[],
    normalizedTaskStatuses: number[],
    start: Date,
    end: Date,
  ): Promise<Task[]> {
    return this.tasksService
      .expand(['creator', 'employee', 'taskStatus'])
      .sort({ createdAt: 'desc' })
      .filter({
        dueDate: serializeDateRange(
          {
            startDate: moment(start) as any,
            endDate: moment(end) as any,
          },
          this.dateFormat.MYSQL_DATETIME,
        ),
        employeeId: normalizedUsers,
        taskStatusId: normalizedTaskStatuses,
      })
      .getListWithQuery<IElementRestNg<Task>>()
      .then((data) => data.plain());
  }

  fetchFollowUps(
    normalizedUsers: number[],
    start: Date,
    end: Date,
  ): Promise<CustomerCommunication[]> {
    return this.communicationsService
      .sort({ creatdAt: 'desc' })
      .filter({
        followUpDate: serializeDateRange(
          {
            startDate: moment(start) as any,
            endDate: moment(end) as any,
          },
          this.dateFormat.UNIX_MILI_TIMESTAMP,
        ),
        userId: normalizedUsers,
      })
      .expand(['customer'])
      .getListWithQuery<IElementRestNg<Task>>()
      .then((data) => data.plain());
  }

  constructCalendarEventsSources(
    tasks$: rx.Observable<Task[]>,
    followUps$: rx.Observable<CustomerCommunication[]>,
    until$: rx.Observable<void>,
  ): { value: EventSource[] } {
    /*
     * FullCalendar ng that we use need to keep the same references for the sources.
     * Therefore we need to give it the same refrence and change
     * values beneath it.
     */
    const tasksEvents: CrmCalEvent[] = [];
    const followUpsEvents: CrmCalEvent[] = [];

    tasks$
      .pipe(
        rx.map((list) => list.map((i) => taskToCalEvent(i))),
        rx.tap((events) => {
          tasksEvents.splice(0, tasksEvents.length);
          tasksEvents.push(...events);
        }),
        rx.takeUntil(until$),
      )
      .subscribe();

    followUps$
      .pipe(
        rx.map((list) => list.map((i) => followupToCalEvent(i))),
        rx.tap((events) => {
          followUpsEvents.splice(0, followUpsEvents.length);
          followUpsEvents.push(...events);
        }),
        rx.takeUntil(until$),
      )
      .subscribe();

    const inst = [
      {
        events: tasksEvents,
      },
      {
        events: followUpsEvents,
      },
    ];

    const interfaceInst = {};

    Object.defineProperty(interfaceInst, 'value', {
      get() {
        return inst;
      },

      set(_eventSources: any) {
        throw new Error('not supported');
      },
    });

    return interfaceInst as any;
  }

  streamSetCalendarView() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.daterangeType$,
          this.chosenDateRange$,
          this.uiCalendarInst$.pipe(rx.filter((c) => !_.isNil(c))),
        ]),
      rx.map(([daterangeType, dateRange, calendar]) => {
        const viewOptions = switchOn(
          {
            [DateRangeType.Day]: () => ({ viewType: 'basicDay' }),
            [DateRangeType.Week]: () => ({ viewType: 'basicWeek' }),
            [DateRangeType.Month]: () => ({ viewType: 'month' }),
          },
          daterangeType,
          () => ({ viewType: 'month' }),
        );

        return { viewOptions, dateRange, calendar };
      }),
      rx.tap(({ viewOptions, dateRange, calendar }) => {
        calendar.changeView(viewOptions.viewType, dateRange.start);
        // change view for some reason does not change the date.
        calendar.gotoDate(addFns(dateRange.start, { hours: 12 }));
      }),
    )(null);
  }

  /*
   * Component api method
   */
  gotoToday() {
    this.gotoTodayOp$.next();
  }

  /*
   * Component api method
   */
  prevRange() {
    this.gotoPrevRangeOp$.next();
  }

  /*
   * Component api method
   */
  nextRange() {
    this.gotoNextRangeOp$.next();
  }
}

export const PrfTasksCalendarComponent = {
  template,
  controller: PrfTasksCalendarController,
  bindings: {
    daterangeType: '<',
    onDone: '&',
    onInstance: '&',
    onClickTask: '&',
    onClickFollowUp: '&',
    onClickDay: '&',
    onSelectDateRange: '&',
  },
};
