import * as _ from '@proftit/lodash';

/**
 * Filter factory. Return limitToDots Filter.
 *
 * @return {Function} limitToDots filter
 */
export function limitToDotsFilterFactory($filter) {
  /**
   * Convert number to fixed string representation.
   *
   * @param input - string to truncate.
   * @param limit - length to show.
   * @reutrn {string} string representation of the source.
   */
  function limitToDots(input: string, limit: number): string {
    if (_.isNil(input)) {
      return input;
    }

    let newContent = $filter('limitTo')(input, limit);

    if (newContent.length < input.length) {
      newContent += '...';
    }

    return newContent;
  }

  return limitToDots;
}

limitToDotsFilterFactory.$inject = ['$filter'];
