import template from './brand-account-status-automation-statuses-form.component.html';
const styles = require('./brand-account-status-automation-statuses-form.component.scss');

import ng from 'angular';
import {
  AccountStatusAutomationMap,
  AccountStatusAutomationType,
  CustomerComplianceStatus,
  TradingAccountStatus,
} from '@proftit/crm.api.models.entities';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { CustomerComplianceStatusesService } from '~/source/contact/common/services/customer-compliance-statuses.service';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { requestWithComponent } from '~/source/common/utilities/request-with-component';
import { IPromise } from 'restangular';
import * as _ from '@proftit/lodash';
import { TradingAccountStatuses } from '~/source/common/services/trading-account-statuses';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { FormGroup, FormControl, FormArray } from '@proftit/ng1.reactive-forms';
import { Editable } from '~/source/common/utilities/editable';
import { getAccountStatusAutomationMapFormGroups } from '~/source/management/automation/shared/get-account-status-automation-map-form-groups';
import { AccountStatusAutomationTypeCode } from '@proftit/crm.api.models.enums';
import { Permissions } from '~/source/common/models/permission-structure';

enum ItemType {
  SourceItemType = 'source_item_type',
  TargetItemType = 'target_item_type',
}

interface DragNDropDropInfo {
  index: number;
  item: any;
  external: any; // todoOld
  type: ItemType;
  automationStatusKey?: string;
}

function addItemToAutomationStatuses(
  targetKey: string,
  item,
  automationStatuses,
) {
  const targetGroup = automationStatuses[targetKey];

  // if exists already in target group -> do nothing.
  if (targetGroup.find((x) => x.id === item.id)) {
    return automationStatuses;
  }

  const newTargetGroup = [...targetGroup, item];

  // remove from rest groups if exists
  const restGroups = _.omit([targetKey], automationStatuses);

  const newRestGroups = _.mapValues((restGroup) => {
    if (restGroup.find((x) => x.id === item.id)) {
      return restGroup.filter((x) => x.id !== item.id);
    }

    return restGroup;
  }, restGroups);

  return {
    [targetKey]: newTargetGroup,
    ...newRestGroups,
  };
}

export class BrandAccountStatusAutomationStatusesFormController {
  AccountStatusAutomationTypeCode = AccountStatusAutomationTypeCode;
  ItemType = ItemType;
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  opOnSourceDrop$ = new rx.Subject<DragNDropDropInfo>();
  opOnPanelDrop$ = new rx.Subject<DragNDropDropInfo>();
  opOutsideDrop$ = new rx.Subject<DragNDropDropInfo>();

  Permissions = Permissions;

  editManager: Editable;

  accountStatusAutomationTypes$ = observeShareCompChange<
    AccountStatusAutomationType[]
  >(this.lifecycles.onChanges$, 'accountStatusAutomationTypes');

  automationRuleFormGroup$ = observeShareCompChange<FormGroup>(
    this.lifecycles.onChanges$,
    'automationRuleFormGroup',
  );
  accountStatusAutomationMaps$ = this.streamAccountStatusAutomationMaps();

  shouldShowTypesDropdown$ = this.streamShouldShowTypesDropdown();

  customerComplianceStatuses$ = this.streamCustomerComplianceStatuses();
  tradingAccountStatuses$ = this.streamTradingAccountStatuses();
  sourceCustomerKYCStatuses$ = this.streamSourceCustomerKYCStatuses();
  automationStatuses$ = this.streamAutomationStatuses();

  /*@ngInject */
  constructor(
    readonly customerComplianceStatusesService: () => CustomerComplianceStatusesService,
    readonly tradingAccountStatusesService: () => TradingAccountStatuses,
    readonly $translate: angular.translate.ITranslateService,
  ) {
    useStreams(
      [
        this.shouldShowTypesDropdown$,
        this.accountStatusAutomationMaps$,
        this.streamUpdateFormGroup(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamShouldShowTypesDropdown() {
    return rx.pipe(
      () => this.accountStatusAutomationTypes$,
      rx.filter(
        (types) =>
          !_.isNil(types) && !_.isNil(types.length) && types.length > 0,
      ),
      rx.map((types) => types.length > 1),
      shareReplayRefOne(),
    )(null);
  }

  streamCustomerComplianceStatuses() {
    return rx.pipe(
      () =>
        requestWithComponent(
          (s) =>
            s.getListWithQuery() as Promise<
              IElementRestNg<CustomerComplianceStatus[]>
            >,
          () => rx.obs.NEVER,
          this.customerComplianceStatusesService(),
          this,
        ) as rx.Observable<IElementRestNg<CustomerComplianceStatus[]>>,
      rx.map((d) => d.plain()),
      shareReplayRefOne(),
    )(null);
  }

  streamAccountStatusAutomationMaps(): rx.Observable<
    AccountStatusAutomationMap[]
  > {
    return rx.pipe(
      () =>
        this.automationRuleFormGroup$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.switchMap((x) => x.value$),
        ),
      rx.filter(
        (x: any) => !_.isNil(x) && !_.isNil(x.accountStatusAutomationMaps),
      ),
      rx.map((x) => x.accountStatusAutomationMaps),
      shareReplayRefOne(),
    )(null);
  }

  streamSourceCustomerKYCStatusesFromModel() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.customerComplianceStatuses$.pipe(rx.startWith([])),
          this.accountStatusAutomationMaps$,
        ),
      rx.filter(
        ([complianceStatuses, accountStatusAutomationMaps]) =>
          !_.isNil(complianceStatuses) && !_.isNil(accountStatusAutomationMaps),
      ),
      rx.map(([complianceStatuses, accountStatusAutomationMaps]) =>
        complianceStatuses.filter(
          (c) =>
            !accountStatusAutomationMaps.find(
              (t) => t.customerVerifiedStatus.id === c.id,
            ),
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamSourceCustomerKYCStatusesFromTargetDropToSource(
    sourceCustomerKYCStatuses$: rx.Observable<any[]>,
  ) {
    return rx.pipe(
      () => this.opOnSourceDrop$,
      rx.filter((op) => op.type === ItemType.TargetItemType),
      rx.withLatestFrom(sourceCustomerKYCStatuses$),
      rx.map(([op, sourceCustomerKYCStatuses]) => {
        return [...sourceCustomerKYCStatuses, op.item];
      }),
    )(null);
  }

  streamSourceCustomerKYCStatusesFromSourceDropToTarget(
    sourceCustomerKYCStatuses$: rx.Observable<any[]>,
  ) {
    return rx.pipe(
      () => this.opOnPanelDrop$,
      rx.filter((op) => op.type === ItemType.SourceItemType),
      rx.withLatestFrom(sourceCustomerKYCStatuses$),
      rx.map(([op, sourceCustomerKYCStatuses]) => {
        return sourceCustomerKYCStatuses.filter((x) => x.id !== op.item.id);
      }),
    )(null);
  }

  streamSourceCustomerKYCStatuses() {
    const sourceCustomerKYCStatuses$ = new rx.BehaviorSubject<any[]>([]);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamSourceCustomerKYCStatusesFromModel(),
          this.streamSourceCustomerKYCStatusesFromTargetDropToSource(
            sourceCustomerKYCStatuses$,
          ),
          this.streamSourceCustomerKYCStatusesFromSourceDropToTarget(
            sourceCustomerKYCStatuses$,
          ),
        ),
      rx.map((stats) => stats.sort((a, b) => (a.code > b.code ? 1 : -1))),
      rx.switchMap((stats) => {
        const arr = stats.map((s) => {
          const translateCode = `brandAccountStatusAutomationStatusesForm.complianceStatuses.${s.code.toUpperCase()}`;

          return this.$translate(translateCode)
            .catch((e) => s.name)
            .then((label) => {
              return {
                ...s,
                label,
              };
            });
        });

        if (arr.length === 0) {
          return rx.obs.from([[]]);
        }

        return rx.obs.forkJoin(arr);
      }),
      rx.tap((stats: any) => sourceCustomerKYCStatuses$.next(stats)),
      shareReplayRefOne(),
    )(null);
  }

  streamTradingAccountStatuses() {
    return rx.pipe(
      () =>
        requestWithComponent(
          (s) =>
            s.getListWithQuery() as Promise<
              IElementRestNg<TradingAccountStatus[]>
            >,
          () => rx.obs.NEVER,
          this.tradingAccountStatusesService(),
          this,
        ) as rx.Observable<IElementRestNg<TradingAccountStatus[]>>,
      rx.map((d) => d.plain()),
      shareReplayRefOne(),
    )(null);
  }

  streamModelGroupedAutomationStatuses() {
    const automationMaps$ = new rx.BehaviorSubject<
      AccountStatusAutomationMap[]
    >(null);
    return rx.pipe(
      () => this.accountStatusAutomationMaps$,
      rx.withLatestFrom(automationMaps$),
      rx.filter(
        ([receivedValue, lastValue]) => !_.isEqual(receivedValue, lastValue),
      ),
      rx.tap(([receivedValue]) => {
        automationMaps$.next(receivedValue);
      }),
      rx.map(([accountStatusAutomationMaps]) => {
        return _.groupBy(
          (x) => x.accountStatus.code,
          accountStatusAutomationMaps,
        );
      }),
      rx.map((groupedAutoStats) =>
        _.mapValues(
          (values) => values.map((v) => v.customerVerifiedStatus),
          groupedAutoStats,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomationStatusesFromModel() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.tradingAccountStatuses$,
          this.streamModelGroupedAutomationStatuses(),
        ),
      rx.map(([tradingAccountStatuses, groupedModelAutomationStatuses]) => {
        return tradingAccountStatuses.reduce((acc, tradingAccountStatus) => {
          acc[tradingAccountStatus.code] = [];
          if (groupedModelAutomationStatuses[tradingAccountStatus.code]) {
            acc[tradingAccountStatus.code] = [
              ...groupedModelAutomationStatuses[tradingAccountStatus.code],
            ];
          }

          return acc;
        }, {});
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomationStatusesFromSourceDropToTarget(
    automationStatuses$: rx.Observable<any>,
  ) {
    return rx.pipe(
      () => this.opOnPanelDrop$,
      rx.filter((op) => op.type === ItemType.SourceItemType),
      rx.withLatestFrom(automationStatuses$),
      rx.map(([op, automationStatuses]) =>
        addItemToAutomationStatuses(
          op.automationStatusKey,
          op.item,
          automationStatuses,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomationStatusesFromTargetDropToTarget(
    automationStatuses$: rx.Observable<any>,
  ) {
    return rx.pipe(
      () => this.opOnPanelDrop$,
      rx.filter((op) => op.type === ItemType.TargetItemType),
      rx.withLatestFrom(automationStatuses$),
      rx.map(([op, automationStatuses]) =>
        addItemToAutomationStatuses(
          op.automationStatusKey,
          op.item,
          automationStatuses,
        ),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamAutomationStatusesFromTargetDropToSource(
    automationStatuses$: rx.Observable<any>,
  ) {
    return rx.pipe(
      () => this.opOnSourceDrop$,
      rx.filter((op) => op.type === ItemType.TargetItemType),
      rx.withLatestFrom(automationStatuses$),
      rx.map(([op, automationStatuses]) => {
        const newGroups = _.mapValues((group) => {
          if (group.find((x) => x.id === op.item.id)) {
            return group.filter((x) => x.id !== op.item.id);
          }

          return group;
        }, automationStatuses);

        return newGroups;
      }),
    )(null);
  }

  streamAutomationStatuses() {
    const automationStatuses$ = new rx.BehaviorSubject<any>(null);

    return rx.pipe(
      () =>
        rx.obs.merge(
          this.streamAutomationStatusesFromModel(),
          this.streamAutomationStatusesFromSourceDropToTarget(
            automationStatuses$,
          ),
          this.streamAutomationStatusesFromTargetDropToTarget(
            automationStatuses$,
          ),
          this.streamAutomationStatusesFromTargetDropToSource(
            automationStatuses$,
          ),
        ),
      rx.map((automationStatuses) =>
        _.flow(
          () => Object.keys(automationStatuses),
          (keys) => keys.sort(),
          (keys) =>
            keys.reduce(
              (acc, k) => ({ ...acc, [k]: automationStatuses[k] }),
              {},
            ),
        )(),
      ),
      rx.switchMap((groupsStats: any[]) => {
        const groupsArray: any = _.mapValues((group) => {
          const statusArray = group.map((s) => {
            const translateCode = `brandAccountStatusAutomationStatusesForm.complianceStatuses.${s.code.toUpperCase()}`;

            return this.$translate(translateCode)
              .catch((e) => s.name)
              .then((label) => {
                return {
                  ...s,
                  label,
                };
              });
          });

          return Promise.all(statusArray);
        }, groupsStats);

        if (groupsArray.length === 0) {
          return rx.obs.from([[]]);
        }

        return rx.obs.forkJoin(groupsArray);
      }),
      rx.tap((automationStatuses) =>
        automationStatuses$.next(automationStatuses),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamUpdateFormGroup() {
    return rx.pipe(
      () => this.automationStatuses$,
      rx.withLatestFrom(
        this.tradingAccountStatuses$,
        this.automationRuleFormGroup$.pipe(
          rx.filter((x) => !_.isNil(x)),
          rx.switchMap((x) => x.controls$),
        ),
      ),
      rx.tap(([automationStatuses, tradingAccountStatuses, controls]) => {
        const newFormArrayValues = this.automationMapsViewModelToServerModel(
          automationStatuses,
          tradingAccountStatuses,
        );
        const newFormArrayControls = getAccountStatusAutomationMapFormGroups(
          newFormArrayValues,
        );
        (controls.accountStatusAutomationMaps as FormArray).setNewValues(
          newFormArrayControls,
        );
      }),
      shareReplayRefOne(),
    )(null);
  }

  automationMapsViewModelToServerModel(
    automationStatuses,
    tradingAccountStatuses,
  ) {
    const viewModelEntries = _.toPairs(automationStatuses).filter(
      ([key, value]: [string, any[]]) => value.length > 0,
    );

    return viewModelEntries.reduce(
      (
        acc,
        [tradingAccountStatusCode, automationStatusesForAccountStatus]: [
          string,
          any[],
        ],
      ) => {
        const tradingAccountStatus = tradingAccountStatuses.find(
          (status) => status.code === tradingAccountStatusCode,
        );
        const tradingAndAutomationPairs = automationStatusesForAccountStatus.map(
          (automationStatus) => {
            return {
              accountStatus: {
                ...tradingAccountStatus,
              },
              customerVerifiedStatus: {
                ...automationStatus,
              },
            };
          },
        );
        return [...acc, ...tradingAndAutomationPairs];
      },
      [],
    );
  }
}

export const BrandAccountStatusAutomationStatusesFormComponent = {
  template,
  controller: BrandAccountStatusAutomationStatusesFormController,
  bindings: {
    editManager: '<',
    accountStatusAutomationTypes: '<',
    automationRuleFormGroup: '<',
    hideDaysInput: '<',
  },
};
