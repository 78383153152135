import log from 'loglevel';
import * as rx from '@proftit/rxjs';
import { PlatformCode, MT4_PLATFORMS } from '@proftit/crm.api.models.enums';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import { CfdAsset, Mt4Asset } from '@proftit/tradingcore.api.models.entities';
import { ApiCfdAssetsService } from '../api-cfd-platform/api-cfd-assets.service';
import { ApiMt4AssetsService } from '../api-cfd-platform/api-mt4-assets.service';
import { directivesPriorities } from '../constants/directives-priorities';
import { PlatformSessionInfo } from './platform-session-info';

interface InProcessStream<T> {
  inProcess$: rx.Observable<any>;
  stream$: rx.Observable<T>;
}

export class CurrentTrcAssetsOfPlatformServiceDirectiveController {
  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* state */

  cfdAssetsS: InProcessStream<CfdAsset[]>;

  bundleAssetsS: InProcessStream<CfdAsset[]>;

  mtAssetsS: InProcessStream<Mt4Asset[]>;

  generalAssetsS: InProcessStream<(CfdAsset | Mt4Asset)[]>;

  /* @ngInject */
  constructor(
    readonly prfApiCfdAssetsService: ApiCfdAssetsService,
    readonly prfApiMt4AssetsService: ApiMt4AssetsService,
  ) {}

  $onInit() {
    this.cfdAssetsS = this.streamCfdAssets();
    this.bundleAssetsS = this.streamBundleAssets();
    this.mtAssetsS = this.streamMtAssets();
    this.generalAssetsS = this.streamGeneralAssets();
  }

  streamCfdAssets() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((sessionInfo) => {
            if (!sessionInfo.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (sessionInfo.platform.code !== PlatformCode.Cfd) {
              return rx.obs.of([]);
            }

            return this.prfApiCfdAssetsService
              .getAssets(sessionInfo.session.apiUrl, sessionInfo.session.token)
              .catch((e: Error) => {
                log.error('error fetching cfd platform assets', e);
                return [];
              });
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamBundleAssets() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((sessionInfo) => {
            if (!sessionInfo.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (sessionInfo.platform.code !== PlatformCode.Bundle) {
              return rx.obs.of([]);
            }

            return this.prfApiCfdAssetsService
              .getAssets(sessionInfo.session.apiUrl, sessionInfo.session.token)
              .catch((e: Error) => {
                log.error('error fetching bundle platform assets', e);
                return [];
              });
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  streamMtAssets() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((sessionInfo) => {
            if (!sessionInfo.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (!MT4_PLATFORMS.includes(sessionInfo.platform.code)) {
              return rx.obs.of([]);
            }

            return this.getAllAssetsSlices(sessionInfo, 0).catch((e: Error) => {
              log.error('error fetching mt5 platform assets', e);
              return [];
            });
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }

  getAllAssetsSlices(
    sessionInfo: PlatformSessionInfo,
    skip: number,
  ): Promise<Mt4Asset[]> {
    const maxSliceSize = 500;

    return (this.prfApiMt4AssetsService.getAssetsSlice(
      sessionInfo.session.apiUrl,
      sessionInfo.session.token,
      maxSliceSize,
      skip,
      { tradeState: ['Full access', 'Close only'] },
    ) as Promise<any>).then((resp) => {
      const xTotalCount = parseInt(resp.headers()['x-total-count'], 10);
      const items = resp.data;
      if (xTotalCount <= skip + resp.data.length) {
        return Promise.resolve(resp.data);
      }

      return this.getAllAssetsSlices(
        sessionInfo,
        skip + resp.data.length,
      ).then((nextSlice) => Promise.resolve([...items, ...nextSlice]));
    }) as Promise<Mt4Asset[]>;
  }

  streamGeneralAssets() {
    return generateInProcessStream(
      this.prfCurrentPlatformSession.sessionS.stream$,
      () =>
        rx.pipe(
          rx.switchMap((session) => {
            if (!session.isLoggedIn) {
              return rx.obs.of([]);
            }

            if (MT4_PLATFORMS.includes(session.platform.code)) {
              return this.mtAssetsS.stream$;
            }

            if (session.platform.code === PlatformCode.Cfd) {
              return this.cfdAssetsS.stream$;
            }

            if (session.platform.code === PlatformCode.Bundle) {
              return this.bundleAssetsS.stream$;
            }

            throw new Error('not implemented -> stream General Assets');
          }),
          shareReplayRefOne(),
        ),
      [],
    );
  }
}

export const currentTrcAssetsOfPlatformServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: CurrentTrcAssetsOfPlatformServiceDirectiveController,
  };
};

currentTrcAssetsOfPlatformServiceDirective.$inject = [] as string[];
