import moment from 'moment';
import momentTz from 'moment-timezone';

const config = (appConfig) => {
  /*
   * set tz extension to moment plugin
   */
  (moment as any).tz = momentTz.tz;
  /*
   * set default zone to moment global object
   * this will cause to all vendor plugins
   * that use moment work with pre-defined
   * timezone
   */
  (moment as any).tz.setDefault(appConfig.defaultTimezone);
  moment.locale('en-gb');
};

config.$inject = ['appConfig'];

export default config;
