import ng from 'angular';

import ThirdPartyTrackingData from './third-party-tracking-data/third-party-tracking-data.component';
import ThirdPartyTrackingForm from './third-party-tracking-form/third-party-tracking-form.component';

const ngModule = ng
  .module('marketing.thirdPartyTracking', [])
  .component('prfThirdPartyTrackingData', ThirdPartyTrackingData)
  .component('prfThirdPartyTrackingForm', ThirdPartyTrackingForm);

export default ngModule.name;
