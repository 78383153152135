import angular from 'angular';
import { ManagerComponent } from './manager/manager.component';
import { RendererComponent } from './renderer/renderer.component';
import { PlayComponent } from './play/play.component';
import { PauseComponent } from './pause/pause.component';
import { StopComponent } from './stop/stop.component';
import { TimelineComponent } from './timeline/timeline.component';
import { VolumeComponent } from './volume/volume.component';

export const AudioPlayerModule = angular.module('prfAudioPlayerModule', []);

AudioPlayerModule.component('prfAudioPlayer', ManagerComponent)
  .component('prfAudioPlayerRenderer', RendererComponent)
  .component('prfAudioPlayerPlay', PlayComponent)
  .component('prfAudioPlayerPause', PauseComponent)
  .component('prfAudioPlayerStop', StopComponent)
  .component('prfAudioPlayerTimeline', TimelineComponent)
  .component('prfAudioPlayerVolume', VolumeComponent);
