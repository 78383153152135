import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';
import template from './reports.component.html';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import BaseController from '~/source/common/controllers/base';
import ReportCacheService from './services/report-cache.service';

const styles = require('./reports.component.scss');

export class ReportsController extends BaseController {
  static $inject = ['reportCacheService'];
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  selectedReport: string;

  selectedCategory: string;

  categories = [
    { label: 'Customers' },
    { label: 'Transactions' },
    { label: 'Trades' },
  ];

  reports = {
    Customers: [
      { label: 'Customers' },
      { label: 'Trading Accounts' },
      { label: 'Communications' },
      { label: 'Attachments' },
    ],
    Transactions: [
      { label: 'Deposits' },
      { label: 'Ftds' },
      { label: 'Failed Deposits' },
      { label: 'Withdrawals' },
      { label: 'Open Withdrawals' },
      { label: 'Deposits Withdrawals' },
      { label: 'Bonuses' },
      { label: 'Fees' },
      { label: 'Credits' },
      { label: 'Promo Codes' },
    ],
    Trades: [{ label: 'Positions' }, { label: 'Bundles' }],
  };

  /* @ngInject */
  constructor(readonly reportCahcheService: ReportCacheService) {
    super();
    useStreams([], this.lifecycles.onDestroy$);
  }

  $onInit() {
    if (this.reportCahcheService.get('category')) {
      this.selectedCategory = this.reportCahcheService.get('category');
    }

    if (this.reportCahcheService.get('report')) {
      this.selectedReport = this.reportCahcheService.get('report');
    }
  }

  $onDestroy() {}

  $onChanges() {}

  onChange() {
    this.reportCahcheService.put('category', this.selectedCategory);
    this.reportCahcheService.put('report', this.selectedReport);
  }
}

export const ReportsComponent = {
  template,
  controller: ReportsController,
  bindings: {
    selectedCategory: '<',
    selectedReport: '<',
  },
};
