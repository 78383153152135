import * as _ from '@proftit/lodash';
import template from './parameters-to-track-form.html';
import BaseController from '~/source/common/controllers/base';
import { IScope } from 'angular';

class ParametersToTrackFormController extends BaseController {
  index: string;

  /*@ngInject*/
  constructor(readonly $scope: IScope) {
    super();

    // create unique value for row input fields
    this.index = _.uniqueIdEs();
  }
}

export default {
  template,
  bindings: {
    param: '=',
    isEdit: '=',
    removeElement: '&removeFn',
    removeValidate: '&removeValidateFn',
  },
  controller: ParametersToTrackFormController,
  controllerAs: 'vm',
};
