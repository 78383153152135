import ng from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './generic-confirm-dialog.component.html';
import * as _ from '@proftit/lodash';

const styles = require('./generic-confirm-dialog.component.scss');

enum IconType {
  Font = 'font',
  Svg = 'svg',
}

export class GenericConfirmDialogController extends BaseController {
  onConfirmClick;

  styles = styles;
  IconType = IconType;

  confirmIcon;
  confirmIconType: IconType;
  isConfirmButtonDisabled;

  $onInit() {
    this.confirmIconType = _.defaultTo(IconType.Font, this.confirmIconType);
    this.confirmIcon = _.defaultTo('pf-refresh', this.confirmIcon);
    this.isConfirmButtonDisabled = _.isNil(this.isConfirmButtonDisabled)
      ? false
      : this.isConfirmButtonDisabled;
  }
}

export const GenericConfirmDialogComponent = {
  template,
  controller: GenericConfirmDialogController,
  bindings: {
    blockUiId: '<',
    blockUiOn: '<',
    titleText: '<',
    growlId: '<',
    confirmButtonText: '<',
    isConfirmButtonDisabled: '<',
    disableMinimizeOption: '<',
    confirmIconType: '<',
    confirmIcon: '<',
    onCancelClick: '&',
    onConfirmClick: '&',
  },
  transclude: true,
};

export default GenericConfirmDialogComponent;
