import RestService from '~/source/common/services/rest';

export class UserFilters extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'filters';
  }

  overwriteSharedGroupFilters(filterId: number, departmentsConns) {
    return this.resourceBuildStart()
      .getElement(filterId)
      .getNestedElement('sharedGroupFilters', '~')
      .resourceBuildEnd()
      .customPutWithQuery(departmentsConns);
  }
}
