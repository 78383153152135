import Promise from 'bluebird';
import Component from './base/component';

class CreatedByEnumController extends Component.controller {
  query() {
    const values = [
      {
        id: 'user',
        name: 'user',
      },
      {
        id: 'customer',
        name: 'customer',
      },
      {
        id: 'system',
        name: 'system',
      },
    ];

    return Promise.resolve(values);
  }

  /**
   * Path to translation
   *
   * @returns {string}
   */
  get translationPath() {
    return 'createdByEnumSelect';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return 'name';
  }
}

export const CreatedByEnumSelectComponent = Component.config({
  controller: CreatedByEnumController,
});
