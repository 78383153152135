import { Customer, CustomerWeather } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import CustomersService from '~/source/contact/common/services/customers';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import type { IPromise } from 'angular';
import { CrmAppStoreProviderController } from '~/source/app/cfm-app-store-provider.component';
import { ModelNormalizerService } from '~/source/common/services/model-normalizer';

export class CustomerStoreService {
  loadSuccessOp$ = new rx.Subject();

  swapPhonesSuccessOp$ = new rx.Subject();

  updateCustomerFromSocketSuccessOp$ = new rx.Subject<Partial<Customer>>();

  updateCustomerInfoSuccessOp$ = new rx.Subject<Partial<Customer>>();

  updateCustomerAllChangesSuccessOp$ = new rx.Subject<Partial<Customer>>();

  setCustomer$ = new rx.Subject<Partial<Customer>>();

  customer$ = this.streamCustomer();

  weather$ = this.streamWeather();

  constructor(
    readonly customersService: () => CustomersService,
    readonly crmApp: CrmAppStoreProviderController,
    readonly modelNormalizer: ModelNormalizerService,
  ) {}

  streamCustomer(): rx.Observable<Customer> {
    const customer$ = new rx.BehaviorSubject<Customer>(null);
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.loadSuccessOp$,
          this.swapPhonesSuccessOp$,
          this.updateCustomerFromSocketSuccessOp$,
          this.updateCustomerInfoSuccessOp$,
          this.updateCustomerAllChangesSuccessOp$,
          this.setCustomer$,
        ),
      rx.tap((customer: Customer) => customer$.next(customer)),
      shareReplayRefOne<Customer>(),
    )(null);
  }

  streamWeather(): rx.Observable<CustomerWeather> {
    return rx.obs
      .combineLatest(this.customer$, this.crmApp.store.loggedCrmApiS.crmApi$)
      .pipe(
        rx.switchMap(([customer, api]) => {
          if (_.isNil(api)) {
            return rx.obs.of(null);
          }

          return api.userApi().customers().one(customer.id).weather().getOne();
        }),
        shareReplayRefOne(),
      );
  }

  setCustomer(customer: Partial<Customer>) {
    this.setCustomer$.next(customer);
  }

  load(id: number): rx.Observable<Customer> {
    return rx.pipe(
      () => rx.obs.defer(() => getCustomer(id, this.customersService)),
      rx.take(1),
      rx.tap<Customer>((customer) => this.loadSuccessOp$.next(customer)),
    )(null);
  }

  swapPhones() {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([a, customer]) =>
        this.customersService().swapPrimaryAndSecondaryPhoneNumbers(
          customer.id,
        ),
      ),
      rx.withLatestFrom(this.customer$),
      rx.map(([resCustomer, customer]) => {
        return {
          ...customer,
          phone: resCustomer.phone,
          phone2: resCustomer.phone2,
          countryPrefix: resCustomer.countryPrefix,
          countryPrefix2: resCustomer.countryPrefix2,
        };
      }),
      rx.take(1),
      rx.tap((customer) => this.swapPhonesSuccessOp$.next(customer)),
    )(null);
  }

  updateCustomerFromSocket(properties: Partial<Customer>) {
    return rx.obs.lastValueFrom(
      rx.pipe(
        () => rx.obs.of(true),
        rx.withLatestFrom(this.customer$),
        rx.map(([a, customer]) => {
          return {
            ...customer,
            ...properties,
          };
        }),
        rx.take(1),
        rx.tap((customer) => {
          this.updateCustomerFromSocketSuccessOp$.next(customer);
        }),
      )(null),
    );
  }

  updateCustomerInfo(fieldsName: string[]) {
    return rx.pipe(
      () => rx.obs.of(true),
      rx.withLatestFrom(this.customer$),
      rx.switchMap(([a, customer]) => {
        const data = _.pick(fieldsName, customer);
        return this.customersService().updateCustomer(customer.id, data);
      }),
      rx.take(1),
      rx.withLatestFrom(this.customer$),
      rx.tap(([a, customer]) =>
        this.updateCustomerInfoSuccessOp$.next(customer),
      ),
    )(null);
  }

  updateAllChanges(changes: Partial<Customer>) {
    // normalize data

    return rx
      .pipe(
        () => rx.obs.of(true),
        rx.withLatestFrom(this.customer$),
        rx.switchMap(([a, customer]) => {
          const changesNorm = this.modelNormalizer.normalize(changes);
          return this.customersService().updateCustomer(
            customer.id,
            changesNorm,
          );
        }),
        rx.withLatestFrom(this.customer$),
        rx.switchMap(([r, origCustomer]) =>
          getCustomer(origCustomer.id, this.customersService),
        ),
        rx.tap((newCustomer) =>
          this.updateCustomerAllChangesSuccessOp$.next(newCustomer),
        ),
        rx.take(1),
      )(null)
      .toPromise();
  }
}

function getCustomer(
  id: number,
  customersService: () => CustomersService,
): Promise<Customer> {
  return customersService()
    .expand([
      'brand.cashier',
      'country',
      'desk',
      'user',
      'campaign',
      'language',
      'experienceType',
      'state',
      'customerComplianceStatus',
      'idType',
      'taxResidency',
      'nationality',
      'customerPropertyValues.property',
      'customerStatus',
      'brand.voipCredentials.voipProvider',
      'brand.smsCredentials.smsProvider',
    ])
    .embed([
      'customerPropertyValues',
      'brand.voipCredentials',
      'brand.smsCredentials',
    ])
    .getOneWithQuery(id) as any;
}
