import * as _ from '@proftit/lodash';
import Component from '~/source/common/components/dropdowns/base/component';
import { ApiTrcCurrenciesService } from '~/source/common/api-cfd-platform/api-trc-currencies.service';
import { IHttpService, IPromise } from 'angular';
import * as rx from '@proftit/rxjs';
import { getAsList, jsonServerToCfdMongo } from '@proftit/request-client';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { CurrentTrcAssetsOfPlatformServiceDirectiveController } from '~/source/common/service-directives/current-trc-assets-of-platform-service.directive';
import ItemLabelStrategy from './base/item-label-strategy';

export class CfdPlatformCurrencyNameSelectController extends Component.controller {
  static $inject = [
    ...Component.controller.$inject,
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'prfApiTrcCurrenciesService',
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  /* injected */

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  prfApiTrcCurrenciesService: ApiTrcCurrenciesService;

  /* state */

  lifecycles = observeComponentLifecycles(this);

  /**
   *  Fetch states on selected brand
   */
  init() {
    this._data = [];

    useStreams([this.streamData()], this.lifecycles.onDestroy$);
  }

  streamData() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((session) => session.isLoggedIn),
      rx.tap((session) => {
        this._data = [];

        this.fetchData();
      }),
    )(null);
  }

  /**
   * Rewrite default behavior
   * Expand brand entity with default platform
   *
   * @returns {Promise}
   */
  query() {
    return rx
      .pipe(
        () => rx.obs.of(true),
        rx.tap(() => this.startBlockUi()),
        rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),
        rx.switchMap(([a, sessioninfo]) =>
          this.prfApiTrcCurrenciesService.getAllCurrencies(
            sessioninfo.session.apiUrl,
            sessioninfo.session.token,
          ),
        ),
        rx.finalize(() => {
          this.stopBlockUi();
        }),
        rx.take(1),
      )(null)
      .toPromise() as any;
  }

  startBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.getBlockUiRef());

    if (_.isNil(blockUiInstance)) {
      return;
    }

    blockUiInstance.start();
  }

  stopBlockUi() {
    const blockUiInstance = this.blockUI.instances.get(this.getBlockUiRef());
    if (_.isNil(blockUiInstance)) {
      return;
    }

    blockUiInstance.stop();
  }

  calcLabelForItem(item) {
    return item.symbol;
  }

  get nullableElement() {
    return {
      id: null,
      name: 'All Assets',
    };
  }

  get itemLabelStrategy() {
    return ItemLabelStrategy.FieldValue;
  }

  get defaultPlaceholder() {
    return 'SELECT_CURRENCY';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export const CfdPlatformCurrencyNameSelectComponent = Component.config({
  controller: CfdPlatformCurrencyNameSelectController,
  require: {
    prfCurrentPlatformSession: '^',
  },
});

export default CfdPlatformCurrencyNameSelectComponent;
