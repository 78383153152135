import ng from 'angular';
import { BrandsStoreService } from './brands-store.service';
import { CommunicationsStoreService } from './communications-store.service';
import { CurrentUserStoreService } from './current-user-store.service';
import { RouterStoreService } from './router-store.service';
import { SystemEmailTypesStoreService } from './system-email-types-store.service';
import { ContactsImportStatusesStoreService } from './contacts-import-statuses-store.service';
import { ContactsImportStoreService } from './contacts-import-store.service';
import { ContentTemplateStore } from './content-template-store.service';
import { createFactoryForService } from '@proftit/ng1.adjunct';
import { CustomerPositionStatusesStoreService } from './customer-position-statuses-store.service';
import { CustomerComplianceFileTypesStoreService } from './customer-compliance-file-types-store.service';
import { NotificationSettingTypeStore } from './notification-setting-types-store.service';
import { CountriesStoreService } from '~/source/common/store-services/countries-store.service';
import { SmsProvidersStoreService } from './sms-providers-store.service';
import { SystemLogin2faStoreService } from './system-login-2fa-store.service';
import { PublicSystemLogin2faStoreService } from './public-system-login-2fa-store.service';
import { UserRolePositionStoreService } from './user-role-positions-store.service';
import { SystemSmtpConfigurationStoreService } from './system-smtp-configuration-store.service';

export const StoreServicesModule = ng.module('crm.storeServices', []);

const md = StoreServicesModule;

StoreServicesModule.service('prfBrandsStore', BrandsStoreService)
  .service('prfCommunicationsStore', CommunicationsStoreService)
  .service('prfCurrentUserStore', CurrentUserStoreService)
  .service('prfRouterStore', RouterStoreService)
  .service('prfSystemEmailTypesStore', SystemEmailTypesStoreService)
  .service(
    'prfContactsImportStatusesStoreService',
    ContactsImportStatusesStoreService,
  )
  .service('prfContactsImportStoreService', ContactsImportStoreService)
  .service(
    'prfCustomerPositionStatusesStoreService',
    CustomerPositionStatusesStoreService,
  )
  .service('prfContactsImportStoreService', ContactsImportStoreService)
  .factory(
    'prfContentTemplateStore',
    createFactoryForService(ContentTemplateStore),
  )
  .service(
    'prfCustomerComplianceFileTypesStoreService',
    CustomerComplianceFileTypesStoreService,
  )
  .service('prfNotificationSettingTypeStore', NotificationSettingTypeStore)
  .service('prfSmsProvidersStore', SmsProvidersStoreService)
  .service('prfSystemLogin2faStore', SystemLogin2faStoreService)
  .service('prfCountriesStoreService', CountriesStoreService)
  .service(
    'prfPublicSystemLogin2faStoreService',
    PublicSystemLogin2faStoreService,
  );

md.service('prfUserRolePositionsStore', UserRolePositionStoreService).service(
  'prfSystemSmtpConfigurationStoreService',
  SystemSmtpConfigurationStoreService,
);
