import * as _ from '@proftit/lodash';
import type { FieldProxyHandler } from '../field-proxy-handler';

export const IS_NUMBER_NONE_NEGATIVE = 'IS_NUMBER_NONE_NEGATIVE';

export function isNumberNoneNegativeProxyValidator(
  handler: FieldProxyHandler<any>,
) {
  if (handler.value < 0) {
    return {
      code: IS_NUMBER_NONE_NEGATIVE,
      isValid: false,
    };
  }

  return {
    code: IS_NUMBER_NONE_NEGATIVE,
    isValid: true,
  };
}
