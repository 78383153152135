import { IPromise } from 'angular';

import RestService from '~/source/common/services/rest';
import { User, VoipProvider } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import * as _ from '@proftit/lodash';

const BRAND_DESK_CONNECTION_RESOURCE = 'brands';
const SERVICE_RESOURCE = 'users';
const ACTIVITY_LOGS_RESOURCE = 'activityLogs';
const VOIP_BRANDS_CACHE_KEY = 'voipBrands';
const VOIP_CONFIGURATIONS = 'voipConfigurations';
const TOKEN_RESOURCE = 'token';
const EMAIL_RESOURCE = 'emails';

function getCacheNameForUserBrandVoip(userId) {
  return `${VOIP_BRANDS_CACHE_KEY}_${userId}`;
}

export interface BrandVoipConfiguration {
  id: number;
  extension: number;
  sip: string;
  voipProviderId?: number;
  voipProvider?: VoipProvider;
  provider?: VoipProvider;
  providerId?: number;
}

export class UsersService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return SERVICE_RESOURCE;
  }

  /**
   * Set a new password for the user identified by the passed token.
   *
   * @param {string} newPassword - New customer password
   * @param {string} token - Authorization token to use for the request
   * @return {Promise} - Restangular request promise
   */
  resetPassword(newPassword: string, token: string) {
    return this.rest.one('~').patch(
      {
        password: newPassword,
        // set authorization header for this request only
      },
      undefined,
      { Authorization: `Bearer ${token}` },
    );
  }

  /**
   * Adds brand connection to user
   *
   * @param {int} userId
   * @param {object} brandConnection
   * @returns {RestService}
   */
  addBrandConnection(userId: number, brandConnection) {
    return this.getBrandDeskConnectionsResource(userId)
      .postWithQuery<any>(brandConnection)
      .then((data) => {
        // invalidate the voip brands cache
        this.removeCachedVoipBrandsSet(userId);
        return data;
      });
  }

  /**
   * Removes brand desk connection from user entity
   *
   * @param {int} userId
   * @param {int} brandConnectionId
   * @returns {*}
   */
  removeBrandConnection(userId: number, brandConnectionId: number) {
    return this.getBrandDeskConnectionResource(userId, brandConnectionId)
      .removeWithQuery()
      .then((data) => {
        // invalidate the voip brands cache
        this.removeCachedVoipBrandsSet(userId);
        return data;
      });
  }

  /**
   * Build url to list brand-desks connected to user
   *
   * @param {int} userId
   * @return {RestService}
   */
  getBrandDeskConnectionsResource(userId: number) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedCollection(BRAND_DESK_CONNECTION_RESOURCE)
      .resourceBuildEnd();
  }

  getUserResource(userId: number) {
    return this.resourceBuildStart().getElement(userId).resourceBuildEnd();
  }

  /**
   * Build url to specific brand-desk connection
   *
   * @param {int} userId
   * @param {int} brandConnectionId
   * @returns {RestService}
   */
  getBrandDeskConnectionResource(userId: number, brandConnectionId: number) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedElement(BRAND_DESK_CONNECTION_RESOURCE, brandConnectionId)
      .resourceBuildEnd();
  }

  /**
   * Build url to post brand.voipConfigurations (new voipConfigurations for existing brand)
   *
   * @param {int} userId
   * @param {int} brandConnectionId
   * @returns {RestService}
   */
  getVoipConfigurationsPostResource(userId: number, brandConnectionId: number) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedElement(BRAND_DESK_CONNECTION_RESOURCE, brandConnectionId)
      .getNestedCollection(VOIP_CONFIGURATIONS)
      .resourceBuildEnd();
  }

  /**
   * Build url to patch/delete brand.voipConfigurations
   *
   * @param {int} userId
   * @param {int} brandConnectionId
   * @param {int} voipConfigurationsId
   * @returns {RestService}
   */
  getVoipConfigurationsResource(
    userId: number,
    brandConnectionId: number,
    voipConfigurationsId: number,
  ) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedElement(BRAND_DESK_CONNECTION_RESOURCE, brandConnectionId)
      .getNestedElement(VOIP_CONFIGURATIONS, voipConfigurationsId)
      .resourceBuildEnd();
  }

  /**
   * Build url to post brand.voipConfigurations (new voipConfigurations for existing brand)
   *
   * @param {int} userId
   * @param {int} brandConnectionId
   * @returns {RestService}
   */
  getEmailsPostResource(userId: number, brandConnectionId: number) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedElement(BRAND_DESK_CONNECTION_RESOURCE, brandConnectionId)
      .getNestedCollection(EMAIL_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Build url to patch/delete brand.email
   *
   * @param {int} userId
   * @param {int} brandConnectionId
   * @param {int} emailConnectionId
   * @returns {RestService}
   */
  getEmailsResource(
    userId: number,
    brandConnectionId: number,
    emailConnId: number,
  ) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedElement(BRAND_DESK_CONNECTION_RESOURCE, brandConnectionId)
      .getNestedElement(EMAIL_RESOURCE, emailConnId)
      .resourceBuildEnd();
  }

  /**
   * Create email connection for user brand.
   *
   * @param userId
   * @param brandConnectionId
   * @returns work promise
   */
  createEmails(userId: number, brandConnectionId: number, email: any) {
    return this.getEmailsPostResource(userId, brandConnectionId).postWithQuery(
      email,
    );
  }

  /**
   * Update email connection for user brand.
   *
   * @param userId
   * @param brandConnectionId
   * @param emailConnectionId
   * @returns work promise
   */
  updateEmails(
    userId: number,
    brandConnectionId: number,
    emailConnId: number,
    emailData: any,
  ) {
    return this.getEmailsResource(
      userId,
      brandConnectionId,
      emailConnId,
    ).patchWithQuery(emailData);
  }

  /**
   * Delete email connection for user brand.
   *
   * @param userId
   * @param brandConnectionId
   * @param emailConnectionId
   * @returns work promise
   */
  deleteEmails(userId: number, brandConnectionId: number, emailConnId: number) {
    return this.getEmailsResource(
      userId,
      brandConnectionId,
      emailConnId,
    ).removeWithQuery();
  }

  /**
   * Build url to list of activity logs by user id
   *
   * @param {int} userId
   * @returns {RestService}
   */
  getActivityLogsResource(userId: number) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getElement(ACTIVITY_LOGS_RESOURCE)
      .resourceBuildEnd();
  }

  /**
   * Gets a Set of brands which have voip configured for the user.
   *
   * @example
   * Check if user "2" has voip settings for brand "4":
   * voipEnabledPromise = usersService.getVoipBrandsSet(2)
   *  .then(brandsSet => brandsSet.has(4));
   * @param {number} userId - use id to get brand settings for
   * @return {Promise} - resolves to a new Set of the brand ids with voip configured
   */
  getVoipBrandsSet(userId: number): Promise<Set<number>> {
    const cacheKey = getCacheNameForUserBrandVoip(userId);
    const cachedBrands = this.getCached(cacheKey);
    if (cachedBrands) {
      return Promise.resolve(new Set(cachedBrands));
    }

    return (
      this.getBrandDeskConnectionsResource(userId)
        .embed(['voipConfigurations'])
        .getListWithQuery<any>()
        /*
         * get only brands with voip configuration (non empty array). Example:
         * [{ brandId: 1, voipConfiguration: [{id: 1}] }, [{ brandId: 1, oipConfiguration: [] }]
         * -> [{ brandId: 1, voipConfiguration: [{id: 1}] }]
         */
        .then((userBrands) =>
          userBrands.filter(
            ({ voipConfigurations }) =>
              voipConfigurations && voipConfigurations.length > 0,
          ),
        )
        // Just keep the brand id. that's all we need
        .then((userBrands) => userBrands.map(({ id }) => id))
        // Set it to cache and return a set of brand ids
        .then((userBrands) => {
          this.cachePut(cacheKey, userBrands);
          // finally, convert it to a Set, to allow easy "has" queries;
          return new Set(userBrands);
        })
    );
  }

  removeVoipCacheForUser(userId: number) {
    const cacheKey = getCacheNameForUserBrandVoip(userId);
    this.cacheRemove(cacheKey);
  }

  updateUserVoip(
    userId: number,
    brandId: number,
    voipConfiguration: BrandVoipConfiguration,
  ) {
    return this.getVoipConfigurationsResource(
      userId,
      brandId,
      voipConfiguration.id,
    )
      .patchWithQuery(_.omit(['id'], voipConfiguration))
      .then((user) => {
        this.removeVoipCacheForUser(userId);
        return user;
      });
  }

  /**
   * Get is voip enabled for user ber brand.
   *
   * @param userId - user id.
   * @param brandid - brand id.
   * @return is voip enable for user for brand.
   */
  isVoipEnabledForUser(userId: number, brandId: number) {
    return this.getVoipBrandsSet(userId).then((brandsSet) =>
      brandsSet.has(brandId),
    );
  }

  /**
   * Invalidate the "voip brands" set cache
   * Must call this function on any edit/remove/add action on the user_brands connections array!
   * @return {void}
   */
  removeCachedVoipBrandsSet(userId: number) {
    this.cacheRemove(getCacheNameForUserBrandVoip(userId));
  }

  /**
   * generates token
   * @param user - user
   */
  generateToken(user: User) {
    return this.resourceBuildStart()
      .getElement(user.id)
      .getNestedCollection(TOKEN_RESOURCE)
      .resourceBuildEnd()
      .postWithQuery<IElementRestNg<{ token: string }>>({});
  }

  getUserGmailSignatureResource(userId: number, brandId: number) {
    return this.resourceBuildStart()
      .getElement(userId)
      .getNestedElement(BRAND_DESK_CONNECTION_RESOURCE, brandId)
      .getNestedElement('emailSignatures', 'me')
      .resourceBuildEnd();
  }

  getUserGmailSignature(userId: number, brandId: number) {
    return this.getUserGmailSignatureResource(
      userId,
      brandId,
    ).getOneWithQuery();
  }

  /*
   * Get users for specific brand
   */
  getUsersForBrand(brandId: number): Promise<User[]> {
    return this.filter({ brandId })
      .embed(['brands', 'brands.desks'])
      .getListWithQuery<IElementRestNg<User>>()
      .then((d) => d.plain());
  }

  disableNotifications(userId: number): Promise<{}> {
    return this.getUserResource(userId)
      .patchWithQuery<IElementRestNg<User>>({ disableNotifications: true })
      .then((resp) => resp.plain());
  }

  activateNotifications(userId: number): Promise<{}> {
    return this.getUserResource(userId)
      .patchWithQuery<IElementRestNg<User>>({ disableNotifications: false })
      .then((resp) => resp.plain());
  }
}

export default UsersService;
