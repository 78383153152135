import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { patchIsActiveConnection } from './../helper-functions/patch-is-active-connection';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import { ClearingCompanyConnectionsListType } from '~/source/management/clearing-companies/components/manage/clearing-company-connections-list-type';

type ToggleIsActiveClearingCompanyConnectionAction = {
  index: number;
  item: ClearingCompanyConnection;
  external: boolean;
  type: ClearingCompanyConnectionsListType;
  isActive: boolean;
};

export const streamToggleIsActiveCompanyConnection: (
  toggleIsActiveCompanyConnectionAction,
  clearingCompanyConnectionsService,
) => rx.Observable<ClearingCompanyConnection> = (
  toggleIsActiveCompanyConnectionAction: rx.Subject<{
    data: ToggleIsActiveClearingCompanyConnectionAction;
  }>,
  clearingCompanyConnectionsService: ClearingCompanyConnectionsService,
) => {
  return rx.pipe(
    () => toggleIsActiveCompanyConnectionAction,
    rx.switchMap(({ data }) => {
      return patchIsActiveConnection(
        data.item.id,
        data.isActive,
        clearingCompanyConnectionsService,
      );
    }),
    rx.filter((x) => !_.isNil(x)),
    shareReplayRefOne(),
  )(null);
};
