const styles = require('./cfd-platform-groups-table.component.scss');
import ng, { IHttpService } from 'angular';
import { TableController } from '~/source/common/components/table/table.controller';
import template from './cfd-platform-groups-table.html';
import { settings } from './cfd-platform-groups-table-settings';
import { PlatfromGroupsService } from '../../services/platform-groups.service';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import * as rx from '@proftit/rxjs';
import { CfdPlatformGroup } from '~/source/common/models/cfd-platform/cfd-platform-group';
import ModalService from '~/source/common/components/modal/modal.service';
import { CrudOpeartion } from '~/source/common/models/crud-operation';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import { useStreams } from '@proftit/rxjs.adjunct';
import * as _ from '@proftit/lodash';
import { calcCfdPlatformGroupTableCols } from './calc-cfd-platform-group-table-cols';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { UserBrandPlatformSession } from '~/source/common/models/user-brand-platform-session';

export class CfdPlatformGroupsTableController extends TableController {
  static $inject = [
    'blockUI',
    '$http',
    'growl',
    'growlMessages',
    'prfPlatformGroupsService',
    'modalService',
    ...TableController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  styles = styles;

  platformConnection: PlatformConnection;

  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  cols;
  settings = _.cloneDeep(settings);
  prfPlatformGroupsService: PlatfromGroupsService;
  platformConnection$ = new rx.BehaviorSubject<PlatformConnection>(null);
  unsub$ = new rx.Subject<void>();
  searchTerm$ = new rx.BehaviorSubject<string>('');
  opOpenEditPopup$ = new rx.Subject<CfdPlatformGroup>();
  opCloseEditPopup$ = new rx.Subject<CfdPlatformGroup>();
  opOpenAddPopup$ = new rx.Subject<CfdPlatformGroup>();
  modalService: ModalService;

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamTable(),
        this.streamSearchByTerm(),
        this.streamOpenEditPopup(),
        this.streamCloseEditPopup(),
        this.streamOpenAddPopup(),
        this.streamTableChange(),
      ],
      this.unsub$,
    );
  }

  streamTableChange() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.prfPlatformGroupsService.getCreateGroupObs(),
          this.prfPlatformGroupsService.getUpdateGroupObs(),
        ),
      rx.tap((res) => this.reloadTable()),
    )(null);
  }

  createDataServiceInstance(session: UserBrandPlatformSession) {
    const req = this.prfPlatformGroupsService.initPrivateReq(
      session.apiUrl,
      session.token,
    );

    return new CfdMongoRestAdapter(
      this.$http,
      this.blockUI,
      this.growl,
      this.growlMessages,
      req,
      'name',
    );
  }

  /**
   * Stream table initialization
   * @return stream
   */
  streamTable() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((sessionInfo) => sessionInfo.isLoggedIn),
      rx.map((sessionInfo) => {
        const adapterInstance = this.createDataServiceInstance(
          sessionInfo.session,
        );
        const cols = calcCfdPlatformGroupTableCols(sessionInfo.platform);

        return { adapterInstance, cols };
      }),
      rx.tap(({ adapterInstance, cols }) => {
        this.dataServiceInstance = adapterInstance;
        this.cols = cols;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  onPlatformConnectionChange(newPlatformConn: PlatformConnection) {
    this.platformConnection$.next(newPlatformConn);
  }

  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  /*
   * stream search by term.
   * @returns {void}
   */
  streamSearchByTerm() {
    return rx.pipe(
      () => this.searchTerm$,
      rx.debounceTime(300),
      rx.distinctUntilChanged(),
      rx.pairwise(),
      rx.tap(([a, searchTerm]) => {
        this.tableParams.page(1);
        this.tableParams.filter().q = searchTerm;
      }),
    )(null);
  }

  streamOpenEditPopup() {
    return rx.pipe(
      () => this.opOpenEditPopup$,
      rx.withLatestFrom(this.platformConnection$),
      rx.tap(([group, platformConnection]) =>
        this.openEditPopup(platformConnection, group),
      ),
    )(null);
  }

  streamCloseEditPopup() {
    return rx.pipe(
      () => this.opCloseEditPopup$,
      rx.tap((val) => this.reloadTable()),
    )(null);
  }

  streamOpenAddPopup() {
    return rx.pipe(
      () => this.opOpenAddPopup$,
      rx.withLatestFrom(this.platformConnection$),
      rx.tap(([group, platformConnection]) =>
        this.openAddPopup(platformConnection),
      ),
    )(null);
  }

  set searchTerm(val: string) {
    this.searchTerm$.next(val);
  }
  get searchTerm() {
    return this.searchTerm$.getValue();
  }

  get tableKey() {
    return 'cfdPlatformBrandGroups';
  }

  openEditPopup(
    platformConnection: PlatformConnection,
    group: CfdPlatformGroup,
  ) {
    this.modalService.open({
      component: 'prfCfdPlatformGroupEditDialog',
      resolve: {
        operation: () => CrudOpeartion.Update,
        platformConnection: () => platformConnection,
        groupId: () => group.id,
        opCloseEditPopup$: this.opCloseEditPopup$,
      },
    });
  }

  openAddPopup(platformConnection: PlatformConnection) {
    this.modalService.open({
      component: 'prfCfdPlatformGroupEditDialog',
      resolve: {
        operation: () => CrudOpeartion.Create,
        platformConnection: () => platformConnection,
        opCloseEditPopup$: this.opCloseEditPopup$,
      },
    });
  }
}

export const CfdPlatformGroupsTableComponent = {
  template,
  controller: CfdPlatformGroupsTableController,
  controllerAs: 'vm',
  bindings: {
    platformConnection: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};

export default CfdPlatformGroupsTableComponent;
