import * as _ from '@proftit/lodash';
import { MtGroupAsset } from '@proftit/tradingcore.api.models.entities';

export function normializeMt4AssetForUpdate(
  changes: MtGroupAsset,
): Partial<MtGroupAsset> {
  const fields = _.pick(['id', 'swapLong', 'swapShort', 'percentage'], changes);

  if (changes.isDefaultSwapLong) {
    fields.swapLong = 'default';
  }

  if (changes.isDefaultSwapShort) {
    fields.swapShort = 'default';
  }

  if (changes.isDefaultPercentage) {
    fields.percentage = 'default';
  }

  return fields;
}
