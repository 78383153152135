import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';

export class ClearEmailNotificationsService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'clearEmailNotifications';
  }

  getEmptyResource() {
    return this.resourceBuildStart().resourceBuildEnd();
  }
}
