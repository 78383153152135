export enum ForexGroupNameCode {
  Standard = 'standard',
  Silver = 'silver',
  Gold = 'gold',
  vip = 'vip',
  SeedForexUsd = 'SEED-Forex-USD',
  SeedForexEur = 'SEED-Forex-EUR',
  SeedBundleUsd = 'SEED-Bundle-USD',
  SeedBundleEur = 'SEED-Bundle-EUR',
  SeedMtbundleUsd = 'SEED-MTBundle-USD',
  SeedMtbundleEur = 'SEED-MTBundle-EUR',
  SeedBrand3VxMt4Usd = 'SEED-Brand-3_VX-MT4-USD',
  SeedBrand3VxMt4Eur = 'SEED-Brand-3_VX-MT4-EUR',
}
