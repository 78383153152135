// This file was generated automatically. Tread lightly.

export enum TradeProductCode {
  Forex = 'forex',
  Ladder = 'ladder',
  LongTerm = 'longTerm',
  Onetouch = 'onetouch',
  Regular = 'regular',
  Trend = 'trend',
}
