import moment, { Moment } from 'moment';

import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import dateFormat from '~/source/common/constants/date-format';

import template from './date-button.component.html';
const styles = require('./date-button.component.scss');

type InnerModel = { startDate: string; endDate: null } | Moment;

interface AngularDateRangeSettings {
  singleDatePicker?: boolean;
  locale: {
    format: string;
  };
  showDropdowns: boolean;
  minDate?: Moment;
  maxDate?: Moment;
}

class Controller extends BaseController {
  styles = styles;
  model: string;
  minDate: Moment;
  maxDate: Moment;
  onChangeModel: ({ value: string }) => {};

  innerModelVal: InnerModel;
  dateRangeSettings: AngularDateRangeSettings;

  get innerModel(): InnerModel {
    return this.innerModelVal;
  }

  set innerModel(val: InnerModel) {
    let date;
    if (_.has('startDate', val)) {
      date = moment((<any>val).startDate);
    } else {
      date = <Moment>val;
    }

    this.onChangeModel({ value: this.convertMomentToString(date) });
  }

  $onInit() {
    this.innerModelVal = this.convertModelToInnerModel(this.model);

    this.dateRangeSettings = _.flow([
      (st) => ({
        autoApply: true,
        singleDatePicker: true,
        locale: {
          format: 'LL',
        },
        showDropdowns: true,
      }),
      (st) => (_.isNil(this.minDate) ? st : { ...st, minDate: this.minDate }),
      (st) => (_.isNil(this.maxDate) ? st : { ...st, maxDate: this.maxDate }),
    ])({});
  }

  convertModelToInnerModel(model: string): InnerModel {
    let startDate;
    if (model) {
      startDate = moment(model).format(dateFormat.MYSQL_DATE);
    } else {
      startDate = null;
    }

    const endDate = null;
    const innerModel = { startDate, endDate };

    return innerModel;
  }

  convertMomentToString(time: Moment): string {
    if (_.isNil(time)) {
      return null;
    }

    if (!time.isValid) {
      return null;
    }

    if (!time.isValid()) {
      return null;
    }

    const model = moment(time).format(dateFormat.MYSQL_DATE);
    return model;
  }

  onModelChange(curr, prev) {
    this.innerModelVal = this.convertModelToInnerModel(curr);
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    model: '<',
    isReadonly: '<',
    minDate: '<',
    maxDate: '<',
    onChangeModel: '&',
  },
};
