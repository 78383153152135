export const symbolsExposureCols = [
  {
    title: 'exposure.symbolsExposure.SYMBOL',
    field: 'platformSymbol',
    fieldFormat: '{{ symbol }}',
    sortable: 'symbol',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.NET_POSITION_SIZE',
    field: '',
    fieldFormat: '{{ netPositionSize }}',
    sortable: 'netPositionSize',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.MET_EXPOSURE',
    field: '',
    fieldFormat: '{{ netExposure }} {{ netExposureCurrency | uppercase }}',
    sortable: '',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.NET_EXPOSURE_USD',
    field: '',
    fieldFormat: '{{ netExposureUsd | number:2 }}',
    sortable: 'netExposureUsd',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.OPEN_PNL_USD',
    field: '',
    fieldFormat: '{{ openPnl }}',
    sortable: 'openPnl',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.AVERAGE_PRICE_NET_POSITION',
    field: '',
    fieldFormat: '{{ averagePrice | number:precision}}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.MARKET_PRICE',
    field: '',
    fieldFormat: '{{ marketPrice | number:precision}}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.LONG_POSITIONS_OPEN_VOLUME_USD',
    field: '',
    fieldFormat: '{{ longPositionsOpenVolumeInUsd | number:2 }}',
    sortable: 'longPositionsOpenVolumeInUsd',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.LONG_POSITIONS_LEVERAGED_OPEN_VOLUME_USD',
    field: '',
    fieldFormat: '{{ longPositionsLeveragedOpenVolumeInUsd | number:2 }}',
    sortable: 'longPositionsLeveragedOpenVolumeInUsd',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.SHORT_POSITIONS_OPEN_VOLUME_USD',
    field: '',
    fieldFormat: '{{ shortPositionsOpenVolumeInUsd | number:2 }}',
    sortable: 'shortPositionsOpenVolumeInUsd',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.SHORT_POSITIONS_LEVERAGED_OPEN_VOLUME_USD',
    field: '',
    fieldFormat: '{{ shortPositionsLeveragedOpenVolumeInUsd | number:2 }}',
    sortable: 'shortPositionsLeveragedOpenVolumeInUsd',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.OPEN_POSITIONS_COUNT',
    field: '',
    fieldFormat: '{{ openPositionsCount }}',
    sortable: 'openPositionsCount',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.TRADERS_COUNT',
    field: '',
    fieldFormat: '{{ tradersCount }}',
    sortable: 'tradersCount',
    show: true,
    filterable: false,
    removable: false,
  },
];
