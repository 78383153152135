import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './number-checkbox-lock-input.component.html';
import { shareReplayRefOne, useStreams, pipeLog } from '@proftit/rxjs.adjunct';
const styles = require('./number-checkbox-lock-input.component.scss');

export class NumberCheckboxLockInputController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  /* @ngInject */
  constructor() {
    useStreams([], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const NumberCheckboxLockInputComponent = {
  template,
  controller: NumberCheckboxLockInputController,
  bindings: {
    numberModel: '=',
    checkboxModel: '=',
    checkboxLabel: '<',
  },
};
