export const exposureSymbolPositionsTableCols = [
  {
    title: 'exposure.symbolsExposure.positions.ID',
    field: '',
    fieldFormat: '{{ id }}',
    sortable: 'id',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.PLATFORM_ID',
    field: 'customer',
    fieldFormat: '{{ customerId }}',
    sortable: 'customerId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.SYMBOL',
    field: '',
    fieldFormat: '{{ symbol }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.TYPE',
    field: 'buySellTradeDirection',
    fieldFormat: '{{ direction | prfStartCase }}',
    sortable: 'direction',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.POSITION_SIZE',
    field: '',
    fieldFormat: '{{ sizeInUnits }}',
    sortable: 'sizeInUnits',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.VOLUME',
    field: '',
    fieldFormat: '{{ investment }} {{ customerCurrencyName }}',
    sortable: 'investment',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.OPEN_LEVERAGED_VOLUME',
    field: '',
    fieldFormat: '{{ leveragedInvestment }} {{ customerCurrencyName }}',
    sortable: 'leveragedInvestment',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.EXPOSURE',
    field: '',
    fieldFormat:
      '{{ exposureInQuotedCurrency }} {{ baseCurrencySymbolName | uppercase }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.OPEN_TIME',
    field: 'exposureEntryDate',
    fieldFormat: "{{entryDate | amDateFormat:'L LTS'}}",
    sortable: 'entryDate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.OPEN_PRICE',
    field: '',
    fieldFormat: '{{ entryRate | number:precision}}',
    sortable: 'entryRate',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.CURRENT_PRICE',
    field: '',
    fieldFormat: '{{ _currentPriceToDisplay }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.OPEN_PNL',
    field: '',
    fieldFormat: '{{ _openPNL }} {{ exposureCurrency }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.SWAP',
    code: 'swap',
    field: '',
    fieldFormat: '{{ swapCommission }}',
    sortable: 'swapCommission',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.STOP_LOSS',
    field: '',
    fieldFormat: '{{ stopLoss }} {{ customerCurrencyName }}',
    sortable: 'stopLoss',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'exposure.symbolsExposure.positions.TAKE_PROFIT',
    field: '',
    fieldFormat: '{{ takeProfit }} {{ customerCurrencyName }}',
    sortable: 'takeProfit',
    show: true,
    filterable: false,
    removable: false,
  },
];
