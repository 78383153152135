import ng from 'angular';
import { Brand } from '@proftit/crm.api.models.entities';
import { CustomerLoginTypeCode } from '@proftit/crm.api.models.enums';
import { useStreams, shareReplayRefOne } from '@proftit/rxjs.adjunct';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import { SignupFormField } from '@proftit/signup-form.api.entities';
import { FormFieldCode } from '../../form-field-code';
import template from './signup-form-field.component.html';

const styles = require('./signup-form-field.component.scss');

export class SignupFormFieldController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  brand$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  model$ = observeShareCompChange<SignupFormField>(
    this.lifecycles.onChanges$,
    'model',
  );

  fieldInfoText$ = this.streamFieldInfoText();

  /* @ngInject */
  constructor(readonly $translate: ng.translate.ITranslateService) {
    useStreams([this.brand$, this.model$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamFieldInfoText() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.brand$, this.model$),
      rx.switchMap(([brand, model]) => {
        if (
          model.field === FormFieldCode.Email &&
          brand.customerLoginType === CustomerLoginTypeCode.Email
        ) {
          return (this.$translate(
            'signupFormField.LOGIN_BY_EMAIL_IS_ENABLED',
          ) as any) as Promise<string>;
        }

        if (
          model.field === FormFieldCode.Phone &&
          brand.customerLoginType === CustomerLoginTypeCode.Phone
        ) {
          return (this.$translate(
            'signupFormField.LOGIN_BY_PHONE_IS_ENABLED',
          ) as any) as Promise<string>;
        }

        return rx.obs.of('');
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const SignupFormFieldComponent = {
  template,
  controller: SignupFormFieldController,
  bindings: {
    fieldType: '<',
    model: '<',
    brand: '<',
  },
};
