export const inactivityFeeActivityLogTablePopupCols = [
  {
    title: 'inactivityFee.activityLogTable.MODIFIED_ON',
    field: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS"}}',
    sortable: 'createdAt',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'inactivityFee.activityLogTable.PREVIOUS_VALUES',
    field: '',
    fieldName: 'infoFields',
    fieldFormat: '{{ _info.fields }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'inactivityFee.activityLogTable.PREVIOUS_CRITERIAS',
    field: 'field',
    fieldName: 'infoCriterias',
    fieldFormat: '{{ _info.criterias }}',
    sortable: 'field',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'inactivityFee.activityLogTable.CHANGED_BY',
    field: 'inactivityFeeChangedBy',
    fieldFormat: '{{ user.firstName }} {{ user.lastName }}',
    sortable: 'userId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'inactivityFee.activityLogTable.IP',
    field: 'ip',
    fieldFormat: '{{ userIp }}',
    sortable: 'userIp',
    show: true,
    filterable: false,
    removable: false,
  },
];
