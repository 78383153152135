import * as rx from '@proftit/rxjs';

class AddWithdrawalReqNotifierService {
  private subject = new rx.Subject<any>();

  getObservable(): rx.Observable<any> {
    return this.subject.asObservable();
  }

  next() {
    this.subject.next(null);
  }
}

export default AddWithdrawalReqNotifierService;
