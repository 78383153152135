import ng from 'angular';

import template from './cfd-platform-brand-asset-edit-dialog.component.html';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PlatfromAssetsService } from '../../services/platform-assets.service';
import { CfdPlatformAsset } from '~/source/common/models/cfd-platform/cfd-platform-asset';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { CfdPlatformSocketService } from '../../services/cfd-platform-socket.service';
import { calcAssetFixedSpread } from '~/source/common/utilities/calc-asset-fixed-spread';
import { observeChannel } from '~/source/common/utilities/observe-channel';
import { Changeset } from '~/source/common/utilities/changeset/changeset';
import { resetFieldUpdate } from '~/source/common/utilities/changeset/reset-field-update';
import { setFieldUpdate } from '~/source/common/utilities/changeset/set-field-update';
import { NgModelChange } from '~/source/common/utilities/create-ng-model-obs-mediator';
import { init as initChangeset } from '~/source/common/utilities/changeset/init';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PLATFORM_ASSET_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { CfdPlatformGroup } from '~/source/common/models/cfd-platform/cfd-platform-group';
import {
  PlatformCode,
  PlatformsWithSwapAbility,
} from '@proftit/crm.api.models.enums';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';
import { PlatformSessionInfo } from '~/source/common/service-directives/platform-session-info';

export interface ICfdPlatformBrandAssetEditComponent {
  assetId: number;
  operation: string;
  platformConnection: PlatformConnection;
  platformGroup: CfdPlatformGroup;
}

export class CfdPlatformBrandAssetEditDialogController {
  close: () => void;
  platformGroup: CfdPlatformGroup;

  prfCurrentPlatformSession$ = new rx.BehaviorSubject<
    CurrentPlatformSessionStoreServiceDirectiveController
  >(null);

  cfdPlatformSocketServiceInstance$ = new rx.BehaviorSubject<
    CfdPlatformSocketService
  >(null);

  lifecycles = observeComponentLifecycles(this);

  resolve$ = observeShareCompChange<ICfdPlatformBrandAssetEditComponent>(
    this.lifecycles.onChanges$,
    'resolve',
  );

  showSwapCommission$ = new rx.BehaviorSubject<boolean>(false);

  assetId$ = this.resolve$.pipe(
    rx.map(({ assetId }) => assetId),
    shareReplayRefOne(),
  );

  operation$ = this.resolve$.pipe(
    rx.map(({ operation }) => operation),
    shareReplayRefOne(),
  );

  platformConnection$ = this.resolve$.pipe(
    rx.map(({ platformConnection }) => platformConnection),
    shareReplayRefOne(),
  );

  platformGroup$ = this.resolve$.pipe(
    rx.map(({ platformGroup }) => platformGroup),
    shareReplayRefOne(),
  );

  asset$ = new rx.BehaviorSubject<CfdPlatformAsset>(null);
  fixedSpread$ = new rx.BehaviorSubject<number>(null);
  opUpdateUiAsset$ = new rx.Subject<NgModelChange>();
  doOperation$ = new rx.Subject<void>();
  changeset$ = new rx.BehaviorSubject<Changeset<CfdPlatformAsset>>(null);
  isFormValid$ = new rx.BehaviorSubject<boolean>(true);
  isConfirmButtonDisabled$ = this.streamIsConfirmButtomDisabled();

  initScrean$ = this.streamInitScreen();

  isLoading$ = this.streamIsLoading();

  title = 'riskManager.SYMBOL_SETTINGS';
  blockUiId = 'cfdPlatformBrandAssetEditDialogBlockUi';
  growlId = 'cfdPlatformBrandAssetEditDialogGrowl';

  /*@ngInject */
  constructor(
    readonly prfPlatformAssetsService: PlatfromAssetsService,
    readonly blockUI: ng.blockUI.BlockUIService,
    readonly growl: ng.growl.IGrowlService,
    readonly growlMessages: ng.growl.IGrowlMessagesService,
    readonly prfCfdPlatformSocketService: () => CfdPlatformSocketService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams(
      [
        this.resolve$,
        this.assetId$,
        this.operation$,
        this.platformConnection$,
        this.platformGroup$,
        this.lifecycles.onInitShared$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    useStreams(
      [
        this.streamSession(),
        this.initScrean$,
        this.streamInitCfdSocketInstance(),
        this.streamFixedSpreadFetching(),
        this.streamUiAssetUpdating(),
        this.streamDoOperation(),
        this.streamCalcShowSwapCommission(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onChanges() {}

  $onDestroy() {}

  streamSession() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.platformConnection$,
          this.prfCurrentPlatformSession$,
        ),
      rx.filter((params) => params.every((p) => !_.isNil(p))),
      rx.switchMap(([conn, sessionStore]) => {
        return sessionStore.login(conn);
      }),
    )(null);
  }

  streamCalcShowSwapCommission() {
    return rx.pipe(
      () => this.platformConnection$,
      rx.map((platformConnection) => platformConnection.platform),
      rx.map((platform) =>
        PlatformsWithSwapAbility.includes(platform.code as PlatformCode),
      ),
      rx.tap((show) => this.showSwapCommission$.next(show)),
    )(null);
  }

  streamInitCfdSocketInstance() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession$,
      rx.filter((service) => !_.isNil(service)),
      rx.switchMap((service) => service.sessionS.stream$),
      rx.switchMap((sessionInfo) => {
        if (!sessionInfo.isLoggedIn) {
          return rx.obs.EMPTY;
        }

        const instance = this.prfCfdPlatformSocketService();
        instance.setToken(sessionInfo.session.token);
        instance.setStreamerUrl(sessionInfo.session.streamerUrl);
        return rx.obs.of(instance);
      }),
      rx.tap((serviceInstance) =>
        this.cfdPlatformSocketServiceInstance$.next(serviceInstance),
      ),
    )(null);
  }

  streamInitScreen() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.operation$,
          this.prfCurrentPlatformSession$.pipe(
            rx.filter((store) => !_.isNil(store)),
            rx.switchMap((store) => store.sessionS.stream$),
          ),
          this.assetId$,
          this.lifecycles.onInitShared$.pipe(rx.filter((isInit) => isInit)),
        ),
      rx.filter(
        ([_operation, sessionInfo, _assetId, _a]) => sessionInfo.isLoggedIn,
      ),
      rx.filter(([operation, sessionInfo, assetId, _a]) =>
        [operation, sessionInfo, assetId].every((x) => !_.isNil(x)),
      ),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiId,
        this.growlId,
      ),
      rx.withLatestFrom(this.platformGroup$),
      rx.mergeMap(([[operation, sessionInfo, assetId, _a], platformGroup]) => {
        if ([operation, sessionInfo, assetId].some((x) => _.isNil(x))) {
          return rx.obs.from([null]);
        }

        if (operation === 'update') {
          const req = this.prfPlatformAssetsService.initPrivateReq(
            sessionInfo.session.apiUrl,
            sessionInfo.session.token,
          );
          return rx.pipe(
            () =>
              rx.obs.from(
                this.prfPlatformAssetsService.getAsOneGroupAsset(
                  assetId,
                  platformGroup.id,
                  req,
                ),
              ),
            rx.catchError((err, caught$) => rx.obs.from([null])),
          )(null);
        }

        throw new Error('not implemented');
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiId,
        this.growlId,
      ),
      rx.tap((asset: CfdPlatformAsset) => {
        const normalizedAsset = {
          ...asset,
          maxInvestment: (asset.maxInvestment as any).usd,
          minInvestment: (asset.minInvestment as any).usd,
        };
        this.asset$.next(normalizedAsset);
        this.changeset$.next(initChangeset(normalizedAsset));
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFixedSpreadFetching() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.asset$,
          this.cfdPlatformSocketServiceInstance$,
        ),
      rx.switchMap(([asset, socketService]) => {
        if ([asset, socketService].some((x) => _.isNil(x))) {
          return rx.obs.EMPTY;
        }

        return observeChannel(
          socketService,
          `${socketService.channelRoot}.${asset.id}`,
        ).pipe(
          rx.map((marketData) => ({ asset, marketData })),
          rx.map(({ asset, marketData }) =>
            calcAssetFixedSpread(asset, marketData),
          ),
          rx.tap((fixedSpread: number) => this.fixedSpread$.next(fixedSpread)),
        );
      }),
    )(null);
  }

  streamUiAssetUpdating() {
    return rx.pipe(
      () => this.opUpdateUiAsset$,
      rx.withLatestFrom(this.changeset$, this.asset$),
      rx.map(([change, changeset, currentAsset]) => {
        const newChangeset = (function () {
          if (changeset.original[change.fieldName] === change.nextValue) {
            return resetFieldUpdate(change.fieldName, changeset);
          }

          return setFieldUpdate(change.fieldName, change.nextValue, changeset);
        })();

        const newAsset = {
          ...currentAsset,
          [change.fieldName]: change.nextValue,
        };

        return [newAsset, newChangeset];
      }),
      rx.tap(([asset, changeset]) => {
        this.asset$.next(asset as CfdPlatformAsset);
        this.changeset$.next(changeset as Changeset<CfdPlatformAsset>);
      }),
    )(null);
  }

  streamDoOperation() {
    return rx.pipe(
      () => this.doOperation$,
      rx.withLatestFrom(
        this.operation$,
        this.asset$,
        this.platformGroup$,
        this.changeset$,
        this.prfCurrentPlatformSession$.pipe(
          rx.filter((service) => !_.isNil(service)),
          rx.switchMap((service) => service.sessionS.stream$),
        ),
      ),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiId,
        this.growlId,
      ),
      rx.mergeMap(
        ([_doOp, operation, _asset, platformGroup, changeset, sessionInfo]) => {
          if (operation === 'update') {
            return rx.obs.from(
              this.updateAsset(changeset, platformGroup, sessionInfo),
            );
          }

          throw new Error('not implemented');
        },
      ),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiId,
        this.growlId,
      ),
      rx.tap(() =>
        this.prfClientGeneralPubsub.publish(PLATFORM_ASSET_UPDATED, null),
      ),
      rx.tap(() => this.close()),
      rx.catchError((err) => rx.obs.EMPTY),
    )(null);
  }

  streamIsConfirmButtomDisabled() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.isFormValid$, this.changeset$),
      rx.map(([isFormValid, changeset]) => {
        if (!isFormValid) {
          return true;
        }

        if (_.isNil(changeset)) {
          return true;
        }

        return changeset.changes.length === 0;
      }),
      rx.shareReplay({ bufferSize: 1, refCount: true }),
    )(null);
  }

  streamIsLoading() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.initScrean$.pipe(
            rx.map(() => false),
            rx.startWith(true),
          ),
        ),
      rx.map((conds) => conds.some((c) => c)),
      shareReplayRefOne(),
    )(null);
  }

  updateAsset(
    changeset: Changeset<CfdPlatformAsset>,
    platformGroup: CfdPlatformGroup,
    sessionInfo: PlatformSessionInfo,
  ) {
    const assetUpdates = changeset.changes.reduce((acc, change) => {
      return {
        ...acc,
        [change.fieldName]: change.nextValue,
      };
    }, {});

    const req = this.prfPlatformAssetsService.initPrivateReq(
      sessionInfo.session.apiUrl,
      sessionInfo.session.token,
    );

    return this.prfPlatformAssetsService.updateGroupAsset(
      changeset.original.id,
      assetUpdates,
      platformGroup.id,
      req,
    );
  }

  setCurrentPlatformSession(
    currentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController,
  ) {
    this.prfCurrentPlatformSession$.next(currentPlatformSession);
  }
}

export const CfdPlatformBrandAssetEditDialogComponent = {
  template,
  controller: CfdPlatformBrandAssetEditDialogController,
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};

export default CfdPlatformBrandAssetEditDialogComponent;
