import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './brand-ftd-auto-assignment-line.component.html';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import {
  FtdAutoAssignment,
  Brand,
  Desk,
  User,
} from '@proftit/crm.api.models.entities';
import { observeObjectProxyFieldValue } from '@proftit/proxy-bean-rxjs';
import { emptyUserOption } from '../empty-user-option';
import { emptyDeskOption } from '../empty-desk-option';
const styles = require('./brand-ftd-auto-assignment-line.component.scss');

export class BrandFtdAutoAssignmentLineController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  assignment$ = observeShareCompChange<FtdAutoAssignment>(
    this.lifecycles.onChanges$,
    'assignment',
  );

  brandIn$ = observeShareCompChange<Brand>(this.lifecycles.onChanges$, 'brand');

  brandDesks$ = observeShareCompChange<Desk[]>(
    this.lifecycles.onChanges$,
    'brandDesks',
  );

  brandUsers$ = observeShareCompChange<User[]>(
    this.lifecycles.onChanges$,
    'brandUsers',
  );

  isInEditIn$ = observeShareCompChange<boolean>(
    this.lifecycles.onChanges$,
    'isInEdit',
  );

  sourceDesksOptions$ = this.streamSourceDesksOptions();

  destinationDesksOptions$ = this.streamDestinationDesksOptions();

  usersOptions$ = this.streamUsersOptions();

  /* @ngInject */
  constructor() {
    useStreams(
      [
        this.assignment$,
        this.brandIn$,
        this.brandDesks$,
        this.brandUsers$,
        this.isInEditIn$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamUsersPerBrandAndDesk() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.brandIn$,
          this.brandUsers$.pipe(
            rx.filter((users) => _.defaultTo([], users).length > 0),
          ),
          this.assignment$.pipe(
            rx.filter((a) => !_.isNil(a)),
            rx.switchMap((assignment) =>
              observeObjectProxyFieldValue<Desk>(
                assignment,
                'destinationDesk',
              ).pipe(rx.startWith(assignment.destinationDesk)),
            ),
          ),
        ),
      rx.map(([brand, users, destDesk]) => {
        if ([brand, users, destDesk].some((a) => _.isNil(a))) {
          return [];
        }

        return users.filter((u) => {
          const userBrand = u.brands.find((b) => b.id === brand.id);
          if (_.isNil(userBrand)) {
            return false;
          }

          const foundDesk = (userBrand.desks as Desk[]).find(
            (d: any) => d.id === destDesk.id,
          );
          return !_.isNil(foundDesk);
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUsersOptions() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest(
          this.assignment$,
          this.streamUsersPerBrandAndDesk(),
        ),
      rx.map(([assigment, brandUsers]) => {
        if ([assigment, brandUsers].some(_.isNil)) {
          return [emptyUserOption];
        }

        return [emptyUserOption, ...brandUsers];
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamDestinationDesksOptions() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.assignment$, this.brandDesks$),
      rx.map(([assigment, brandDesks]) => {
        if ([assigment, brandDesks].some(_.isNil)) {
          return [];
        }

        return brandDesks;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamSourceDesksOptions() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.assignment$, this.brandDesks$),
      rx.map(([assigment, brandDesks]) => {
        if ([assigment, brandDesks].some(_.isNil)) {
          return [emptyDeskOption];
        }

        return [emptyDeskOption, ...brandDesks];
      }),
      shareReplayRefOne(),
    )(null);
  }

  deskCompare(deskA: Desk, deskB: Desk) {
    if ([deskA, deskB].some(_.isNil)) {
      return false;
    }

    return deskA.id === deskB.id;
  }

  userCompare(userA: User, userB: User) {
    if ([userA, userB].some(_.isNil)) {
      return false;
    }

    return userA.id === userB.id;
  }
}

export const BrandFtdAutoAssignmentLineComponent = {
  template,
  controller: BrandFtdAutoAssignmentLineController,
  bindings: {
    assignment: '<',
    brand: '<',
    brandDesks: '<',
    brandUsers: '<',
    isInEdit: '<',
    onDelete: '&',
    onAdd: '&',
  },
};
