import RestService from '~/source/common/services/rest';

class AccountingStatsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'stats';
  }
}

export default AccountingStatsService;
