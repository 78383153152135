import * as _ from '@proftit/lodash';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import { Customer } from '@proftit/crm.api.models.entities';

export const contactPageRouting = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider
      .state('crm.contacts.view', {
        url:
          '/view/:id?accountId&tab&navigationId&sort&order&userId&currentOffset&guid&timestamp&total',
        template: `<prf-customer-store-provider>
                        <prf-contact-page></prf-contact-page>
                    </prf-customer-store-provider>`,
        data: {
          permissions: {
            only: ['contacts.personalinfo'],
            redirectTo: 'error.unauthorized',
          },
        },
      })
      // find customer page by external user id (syncRemoteId)
      .state('crm.contacts.syncRemoteId', {
        url: '/syncRemoteId/:syncRemoteId',
        resolve: {
          goToCustomerPageBySyncRemoteId(
            $stateParams,
            $state,
            customersService,
          ) {
            return customersService()
              .filter('tradingAccounts.syncRemoteId', $stateParams.syncRemoteId)
              .embed('tradingAccounts')
              .getListWithQuery()
              .then((data: ICollectionRestNg<Customer>) => {
                const customer = _.head(data);
                if (customer.id) {
                  $state.go('crm.contacts.view', { id: customer.id });
                }
              });
          },
        },
        data: {
          permissions: {
            only: ['contacts.personalinfo'],
            redirectTo: 'error.unauthorized',
          },
        },
      });
  },
];
