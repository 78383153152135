import template from './login-method-form.component.html';
const styles = require('./login-method-form.component.scss');

import ng from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { CustomerLoginTypeCode } from '@proftit/crm.api.models.enums';

export class LoginMethodFormController {
  hideEditingTip: boolean;

  styles = styles;

  CustomerLoginTypeCode = CustomerLoginTypeCode;

  lifecycles = observeComponentLifecycles(this);

  /*@ngInject */
  constructor() {}

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const LoginMethodFormComponent = {
  template,
  controller: LoginMethodFormController,
  bindings: {
    form: '<',
    hideEditingTip: '<',
  },
};
