import * as _ from '@proftit/lodash';
import { IScope } from 'angular';

import BaseController from '~/source/common/controllers/base';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';
import BrandsService from '~/source/management/brand/services/brands';

import template from './countries-form.html';

class Controller extends BaseController {
  countries: any;
  clearingCompanyExistingCountries: any;
  clearingCompany: any;
  brandsServiceInstance: BrandsService;
  clearingCompanyConnectionsServiceInst: ClearingCompanyConnectionsService;
  brandId: number;

  /*@ngInject*/
  constructor(
    readonly clearingCompanyConnectionsService: () => ClearingCompanyConnectionsService,
    readonly brandsService: () => BrandsService,
    readonly $scope: IScope,
  ) {
    super();
  }

  $onInit() {
    Object.assign(this, {
      clearingCompanyConnectionsServiceInst: this.clearingCompanyConnectionsService(),
      brandsServiceInstance: this.brandsService(),
    });

    this.$scope.$watch('vm.countries', this.onCountriesChange.bind(this));

    this.initCountriesPerClearingCompany();
  }

  /**
   * Remove current selected countries from 'clearingCompanyExistingCountries on 'Edit' mode
   * @returns {void}
   */
  onCountriesChange() {
    if (!_.isEmpty(this.countries) && this.clearingCompanyExistingCountries) {
      const selectedCountriesArr = this.countries.map(({ id }) => id);

      /*
       * removing selected countries ids from the list of excluded countries for the current company
       * for them to appear in the dropdown countries list whenever in 'Edit' mode
       */
      this.clearingCompanyExistingCountries[
        this.clearingCompany.code
      ] = _.difference(
        this.clearingCompanyExistingCountries[this.clearingCompany.code],
        selectedCountriesArr,
      );
    }
  }

  /**
   * Create map object: Clearing company code as key and countries array as value ("cash4": [30])
   *
   * @param {array} data array of 3D-settings-Objects  -
   * [
   * {
   * "id": 1,
   * "countries": [
   * {
   * "id": 226,
   * "name": "United States"
   * },
   * {
   * "id": 225,
   * "name": "United Kingdom"
   * }
   * ],
   * "minimumDeposits": [],
   * "clearingCompanyName": "Fibonatix (Insight)",
   * "clearingCompany": {
   * "id": 2,
   * "code": "fibonatix",
   * "name": "Fibonatix (Insight)",
   * "fields": null
   * }
   * },
   * {
   * "id": 5,
   * "countries": [
   * {
   * "id": 30,
   * "name": "Brazil"
   * }
   * ],
   * "minimumDeposits": [],
   * "clearingCompanyName": "4Cash",
   * "clearingCompany": {
   * "id": 12,
   * "code": "4cash",
   * "name": "4Cash",
   * "fields": null
   * }
   * }
   * ]
   *
   * @returns {object} - {"cash4": [226, 225], "fibonatix": [1, 44]}
   */
  groupCountriesByCompany(data) {
    const clearingCompanies = {};
    data.forEach((item) => {
      clearingCompanies[item.clearingCompany.code] = [
        ...(clearingCompanies[item.clearingCompany.code] || []),
        ...item.countries.map(({ id }) => id),
      ];
    });
    return clearingCompanies;
  }

  /**
   * load clearing company data
   * @returns {void}
   */
  initCountriesPerClearingCompany() {
    this.brandsServiceInstance
      .getThreeDResource(this.brandId)
      .embed(['countries', 'minimumDeposits'])
      .expand(['minimumDeposits.currency', 'clearingCompany'])
      .setConfig({ blockUiRef: 'threeDCountries' })
      .expand(['clearingCompany'])
      .getListWithQuery()
      .then((data) => {
        this.clearingCompanyExistingCountries = this.groupCountriesByCompany(
          data,
        );
        this.onCountriesChange();
      });
  }
}

const CountriesFormComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
  bindings: {
    brandId: '<',
    clearingCompany: '<',
    countries: '=',
    multiple: '<',
    required: '<',
  },
};

export default CountriesFormComponent;
