import ng from 'angular';

import settings from './settings.json';
import ListComponent from './questionnaire-list/questionnaire-list.component';
import CreateComponent from './questionnaire-create/questionnaire-create.component';
import EditComponent from './questionnaire-edit/questionnaire-edit.component';
import QuestionnaireService from './questionnaire.service';
import FormComponentsModule from './form-components/module';
import { questionnaireRouting } from './questionnaire.routing';
import { QuestionnaireDashboardComponent } from './questionnaire-dashboard/questionnaire-dashboard.component';
import { ScoringTableComponent } from './scoring-table/scoring-table.component';
import { QuestionnaireScoreItemComponent } from './questionnaire-score-item/questionnaire-score-item.component';
import { QuestionnaireScoreGeneralFormComponent } from './questionnaire-score-general-form/questionnaire-score-general-form.component';
import { QuestionnaireScoreSegmentsFormComponent } from './questionnaire-score-segments-form/questionnaire-score-segments-form.component';
import { QuestionnaireScoreScoringsFormComponent } from './questionnaire-score-scorings-form/questionnaire-score-scorings-form.component';
import { QuestionnaireScorePageComponent } from './questionnaire-score-page/questionnaire-score-page.component';
import { TranslatedQuestionnaireTableComponent } from '~/source/management/questionnaire/translated-questionnaire-table/translated-questionnaire-table.component';

export const QuestionnaireModule = ng.module('crm.management.questionnaire', [
  'crm.constants',
  'crm.common',
  'ui.router',
  FormComponentsModule,
]);

QuestionnaireModule.component('prfQuestionnaireList', ListComponent)
  .component('prfQuestionnaireCreate', CreateComponent)
  .component('prfQuestionnaireEdit', EditComponent)
  .component('prfQuestionnaireDashboard', QuestionnaireDashboardComponent)
  .component('prfScoringTable', ScoringTableComponent)
  .component('prfQuestionnaireScoreItem', QuestionnaireScoreItemComponent)
  .component('prfQuestionnaireScorePage', QuestionnaireScorePageComponent)
  .component(
    'prfTranslatedQuestionnaireTable',
    TranslatedQuestionnaireTableComponent,
  )
  .component(
    'prfQuestionnaireScoreGeneralForm',
    QuestionnaireScoreGeneralFormComponent,
  )
  .component(
    'prfQuestionnaireScoreSegmentsForm',
    QuestionnaireScoreSegmentsFormComponent,
  )
  .component(
    'prfQuestionnaireScoreScoringsForm',
    QuestionnaireScoreScoringsFormComponent,
  )
  .factory('questionnaireService', QuestionnaireService.$factorize())
  .constant('questionnaireModuleSettings', settings)
  .config(questionnaireRouting);
