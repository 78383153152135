import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne } from '@proftit/rxjs.adjunct';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { Brand } from '@proftit/crm.api.models.entities';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import ClearingCompanyConnection from '~/source/common/models/clearing-company-connection';
import ClearingCompanyConnectionsService from '~/source/management/clearing-companies/services/clearing-company-connections';

export const streamClearingCompanyConnectionsForBrandFromData = (
  toggleIsActiveCompanyConnection$,
  brandFormControl: FormControl<Brand>,
  clearingCompanyConnectionsService: ClearingCompanyConnectionsService,
) => {
  return rx.pipe(
    () =>
      rx.obs.merge(
        toggleIsActiveCompanyConnection$,
        brandFormControl.value$.pipe(
          rx.filter((val) => !_.isNil(val)),
          rx.distinctUntilKeyChanged('id' as any),
        ),
      ),
    rx.withLatestFrom(brandFormControl.value$),
    rx.filter(([a, brand]) => !_.isNil(brand)),
    rx.switchMap(([a, brand]) => {
      return clearingCompanyConnectionsService
        .expand(['brand', 'clearingCompany'])
        .filter({ brandId: brand.id })
        .getListWithQuery<IElementRestNg<ClearingCompanyConnection>>()
        .then((res) => _.sortBy((connection) => connection.order, res))
        .catch((e) => rx.obs.NEVER);
    }),
    shareReplayRefOne(),
  )(null);
};
