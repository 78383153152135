import ng from 'angular';
import Big from 'big.js';

import BaseController from '~/source/common/controllers/base';

import template from './internal-transfers-table.component.html';
import { TableLiveController } from '~/source/common/components/table/table-live.controller';
import { SocketService } from '~/source/common/services/socket';
import { internalTransfersSettings } from './internal-transfers-settings';
import { internalTransfersColumns } from './internal-transfers-columns';
import { CustomersService } from '~/source/contact/common/services/customers';
import { InternalTransfersSocketService } from '../../services/internal-transfers-socket.service';
import { PopupService } from '~/source/common/components/modal/popup.service';
import {
  TradingAccount,
  Customer,
  InternalTransfer,
} from '@proftit/crm.api.models.entities';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { useStream } from '~/source/common/utilities/use-stream';
import {
  INTERNAL_TRANSFER_ADDED,
  INTERNAL_TRANSFER_UPDATED,
} from '~/source/common/constants/general-pubsub-keys';
import * as _ from '@proftit/lodash';
import * as rx from '@proftit/rxjs';

export interface IInternalTransfersTableControllerBindings {
  account: TradingAccount;
  customer: Customer;
  config: any;
}

interface ModelRow {
  model: InternalTransfer;
  showDetailsRow: boolean;
}

function genModelRow(model: InternalTransfer) {
  return {
    model,
    showDetailsRow: false,
  };
}

export class InternalTransfersTableController extends TableLiveController
  implements IInternalTransfersTableControllerBindings {
  static $inject = [
    'prfInternalTransfersSocketService',
    'customersService',
    'popupService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  /**
   * Bindings
   */
  account: TradingAccount;
  customer: Customer;

  /**
   * Injections
   */
  prfInternalTransfersSocketService: InternalTransfersSocketService;
  customersService: () => CustomersService;
  popupService: PopupService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  config;
  dataServiceInstance: CustomersService;
  internalTransfers: Restangular.ICollection;
  unsub$: rx.Subject<any> = new rx.Subject<null>();
  settings;
  cols;
  internalTransfers$ = new rx.BehaviorSubject<InternalTransfer[]>([]);
  modelRows$ = new rx.BehaviorSubject<{ [key: number]: ModelRow }>({});
  opOpenCloseRowDetails$ = new rx.Subject<InternalTransfer>();

  $onInit() {
    super.$onInit();
    this.settings = { ...internalTransfersSettings, ...(this.config || {}) };
    this.cols = [...internalTransfersColumns];

    this.dataServiceInstance = this.customersService();
    this.initTable();

    useStream(this.streamRefreshTableOnCreated(), this.unsub$);
    useStream(this.streamCalcModelRows(), this.unsub$);
    useStream(this.streamOpenCloseRowDetails(), this.unsub$);
    useStream(this.streamRefreshTableOnRecordUpdated(), this.unsub$);
  }

  $onDestroy() {
    super.$onDestroy();

    this.unsub$.next(null);
    this.unsub$.complete();
  }

  streamRefreshTableOnCreated() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key }) => key === INTERNAL_TRANSFER_ADDED),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamCalcModelRows() {
    return rx.pipe(
      () => this.internalTransfers$,
      rx.map((models) => models.map((model) => genModelRow(model))),
      rx.map((modelRowsArray) => _.keyBy((x) => x.model.id, modelRowsArray)),
      rx.tap((modelRows) => this.modelRows$.next(modelRows as any)),
    )(null);
  }

  streamOpenCloseRowDetails() {
    return rx.pipe(
      () => this.opOpenCloseRowDetails$,
      rx.withLatestFrom(this.modelRows$),
      rx.map(([row, modelRows]) => modelRows[row.id]),
      rx.map((modelRow) =>
        _.set(['showDetailsRow'], !modelRow.showDetailsRow, modelRow),
      ),
      rx.withLatestFrom(this.modelRows$),
      rx.map(([modelRow, modelRows]) =>
        _.set([modelRow.model.id], modelRow, modelRows),
      ),
      rx.tap((modelRows) => this.modelRows$.next(modelRows)),
    )(null);
  }

  streamRefreshTableOnRecordUpdated() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((msg) => msg.key === INTERNAL_TRANSFER_UPDATED),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  get pageKey(): string {
    return 'internalTransfer';
  }

  /**
   * Returns true in notification directive is in use for this table
   *
   * @returns {boolean}
   */
  isUpdateNotification() {
    return true;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return this.prfInternalTransfersSocketService;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.internalTransfers';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.internalTransfers;
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.ngTable;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance
      .getInternalTransfersResource(this.customer.id, this.account.id)
      .setConfig({ blockUiRef: 'internalTransfersTable' })
      .expand([
        'user',
        'sourceTradingAccount.currency',
        'destinationTradingAccount.currency',
      ])
      .sort({ createdAt: 'desc' });
  }

  parseLoadedData(data) {
    data.forEach((tr) => {
      tr._rateAsFixed = Big(tr.rate).toFixed();
    });
    this.internalTransfers = data;
    this.internalTransfers$.next(data);

    return data;
  }

  /**
   * don't show actions popup for sort and filter when mouse-over table columns
   * @override
   * @return {boolean} - inidication whether to show colum actions.
   */
  showColumnActions() {
    return false;
  }

  openAddInternalTransferPopup() {
    this.popupService.open({
      component: 'prfAddInternalTransferPopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }

  openInternalTransferTablePopup() {
    this.popupService.open({
      component: 'prfInternalTransferTablePopup',
      resolve: {
        account: this.account,
        customer: this.customer,
      },
    });
  }
}

export const InternalTransfersTableComponent = {
  template,
  controller: InternalTransfersTableController,
  controllerAs: 'vm',
  bindings: {
    account: '<',
    customer: '<',
    config: '<?',
  },
};
