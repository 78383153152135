import UserManageController from './user-manage-abstract';
import * as _ from '@proftit/lodash';

import template from './user-update.html';
import { Brand, VoipCredential } from '@proftit/crm.api.models.entities';
import { BrandVoipConfiguration } from '~/source/management/user/services/users';

class Controller extends UserManageController {
  // will host newly added brand-desk connections
  newBrands = [];
  voipConfigurationToVoipProviderMemo = {};

  $onInit() {
    super.$onInit();
    this.$scope.$watch('vm.user.isActive', this.toggleUserActivity.bind(this));
  }

  /**
   * Toggle user activity flag
   *
   * @param {boolean} newValue
   * @param {boolean} prevValue
   */
  toggleUserActivity(newValue: boolean, prevValue: boolean) {
    if (_.isUndefined(prevValue)) {
      return;
    }

    this.user.patch({
      isActive: newValue,
    });
  }

  /**
   * Get user that want to edit from server
   *
   * @returns {Restangular}
   */
  initUserModel() {
    return this.usersServiceInst
      .setConfig({ growlRef: 'userForm' })
      .expand([
        'department',
        'userGroup',
        'role',
        'profileImage',
        'brands.voipConfigurations.provider',
        'brands.voipCredentials.voipProvider',
        'brands.emailCredentials.emailProvider',
      ])
      .embed([
        'brands',
        'brands.desks',
        'brands.voipConfigurations',
        'brands.emails',
        'brands.voipCredentials',
        'brands.emailCredentials',
      ])
      .getOneWithQuery(this.$state.params.userId)
      .then((user: any) => {
        user.brands = user.brands.map((userBrand) => {
          const data = this.expandUserVoipConfigurations(
            userBrand.voipCredentials,
            userBrand.voipConfigurations,
          );
          return {
            ...userBrand,
            voipConfigurations: data,
          };
        });
        return user;
      });
  }

  expandUserVoipConfigurations(
    voipCredentials: VoipCredential[],
    voipConfigurations: BrandVoipConfiguration[],
  ) {
    return voipCredentials.map((creds, index) => {
      const foundConfiguration = voipConfigurations.find(
        (config) =>
          creds.voipProvider.id === (config.providerId || config.provider.id),
      );
      if (!_.isNil(foundConfiguration)) {
        return foundConfiguration;
      }
      return {
        id: `temp${index}`,
        _isNew: true,
        extension: '',
        sip: '',
        provider: { ...creds.voipProvider },
      };
    });
  }

  getVoipConfigurationForVoipCredentials(
    brand: Brand,
    singleVoipCredentials: VoipCredential,
  ) {
    return brand.voipConfigurations.find((vc) => {
      return (
        (vc.providerId || vc.provider.id) ===
        singleVoipCredentials.voipProvider.id
      );
    });
  }

  /**
   * Patch user to server
   *
   * @returns {$q}
   * @private
   */
  queryUser() {
    /*
     * id is omitted because of normalizer and role should be change because different
     * roles cause different relations between user and brand-desks and we don`t want to
     * mess with this shit
     */
    const normalizedUser = this.modelNormalizer.normalize(
      _.omit(['id', 'role'], this.user),
    );
    /*
     * this method is used only for updating user properties therefore
     * the brand-desk connection should not be send to server
     */
    delete normalizedUser.brands;
    // update user
    return this.user.patch(normalizedUser);
  }

  /**
   * Add brand-desk connection to user
   *
   * @param {object} brandModel - brand desk model
   */
  addBrandDesk(brandModel) {
    const formattedBrandDeskConnection = _.head(
      this.brandDeskFormFormatterInst.format({
        // modify the structure in order to use formatter
        brands: [brandModel],
      }).brands,
    );

    this.usersServiceInst
      .addBrandConnection(this.user.id, formattedBrandDeskConnection)
      .then((response) => {
        brandModel.voipConfigurations = response.voipConfigurations || [];
        brandModel.emails = response.emails ? response.emails : [];
        brandModel.emailProviderCode = response.emailProviderCode
          ? response.emailProviderCode
          : null;
        // this will render the brand connection user brand-desk-data directive
        this.user.brands.push(brandModel);
        // this will reset brand-desk all connections forms
        this.newBrands = [];
      });
  }

  /**
   *
   * @param {int} brandConnectionId
   */
  removeBrandDesk(brandConnectionId: number) {
    this.usersServiceInst
      .removeBrandConnection(this.user.id, brandConnectionId)
      .then(() => {
        this.user.brands = _.reject(
          (brand: any) => brand.id === brandConnectionId,
          this.user.brands,
        );
      });
  }
}

const UserUpdateComponent = {
  template,
  controller: Controller,
  controllerAs: 'vm',
};

export default UserUpdateComponent;
