import ng from 'angular';
import {
  observeShareCompChange,
  observeComponentLifecycles,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './chosen-single-dropdown.component.html';
const styles = require('./chosen-single-dropdown.component.scss');
import { useStreams } from '@proftit/rxjs.adjunct';

export class ChosenSingleDropdownController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  options$ = observeShareCompChange(this.lifecycles.onChanges$, 'options');

  /* @ngInject */
  constructor() {
    useStreams([this.options$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const ChosenSingleDropdownComponent = {
  template,
  controller: ChosenSingleDropdownController,
  bindings: {
    options: '<',
    disabled: '<',
    model: '=',
    placeholder: '<',
    onSelectChange: '&',
    name: '<',
  },
};
