import BaseController from '~/source/common/controllers/base';
import type { IScope, local } from 'angular';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import CustomersService from '~/source/contact/common/services/customers';
import { Communication } from '~/source/common/models/communication';
import {
  Customer,
  CustomerCommunication,
  CustomerCommunicationStatus,
  CustomerCommunicationType,
} from '@proftit/crm.api.models.entities';
import { DateTzAdjustService } from '~/source/common/services/date-tz-adjust';
import template from './add-communication-popup.html';
import * as _ from '@proftit/lodash';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { COMMUNICATION_ADDED } from '~/source/common/constants/general-pubsub-keys';
import { CommunicationType } from '~/source/common/models/communication-type';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { getResolveChange } from '~/source/common/utilities/rx-ng-one/operators/get-resolve-change';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { FormGroup, FormControl } from '@proftit/ng1.reactive-forms';
import { CommunicationSubject } from '~/source/common/models/communication-subject';
import * as rx from '@proftit/rxjs';
import { CommunicationsSubjectsService } from '~/source/contact/common/services/communications-subjects.service';
import CommunicationsStatusesService from '~/source/contact/common/services/communications-statuses.service';
import { requestWithComponent } from '~/source/common/utilities/request-with-component';
import { IPromise } from 'restangular';
import {
  CommunicationMethodCode,
  CommunicationTypeCode,
} from '@proftit/crm.api.models.enums';

class AddCommunicationPopupController {
  lifecycles = observeComponentLifecycles(this);
  opSave$ = new rx.Subject<void>();
  inputCustomer$ = this.lifecycles.onChanges$.pipe(
    getResolveChange<Customer>('customer'),
    shareReplayRefOne(),
  );
  inputCommunicationType$ = this.lifecycles.onChanges$.pipe(
    getResolveChange<CommunicationType>('communicationType'),
    shareReplayRefOne(),
  );
  form$ = this.streamForm();
  formCommType$ = this.streamFormCommType();

  call: Partial<Communication>;
  customersServiceInst: CustomersService;
  minDate: Date = new Date();
  isDatePickerOpen: boolean = false;
  noFollowUpCheck: boolean = true;
  close: Function;
  customer: Customer;
  initialCommType: CommunicationType;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope & { customer: Customer },
    readonly modelNormalizer: ModelNormalizerService,
    readonly dateTzAdjustService: DateTzAdjustService,
    readonly customersService: () => CustomersService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
    readonly localStorageService: local.storage.ILocalStorageService,
    readonly communicationsSubjectsService: CommunicationsSubjectsService,
    readonly communicationsStatusesService: CommunicationsStatusesService,
  ) {
    (this.customersServiceInst = this.customersService()),
      useStreams(
        [
          this.inputCustomer$,
          this.inputCommunicationType$,
          this.form$,
          this.form$.pipe(rx.switchMap((form) => form.value$)),
          this.streamSaveAction(),
        ],
        this.lifecycles.onDestroy$,
      );

    // Initial call model
    this.call = {
      type: this.initialCommType,
    };
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}

  streamForm() {
    return rx.pipe(
      () => rx.obs.combineLatest(this.inputCommunicationType$),
      rx.map(([commType]) => {
        if (_.isNil(commType)) {
          return null;
        }

        return commType;
      }),
      rx.withLatestFrom(this.inputCustomer$),
      rx.switchMap(([initialCommType, customer]) => {
        return this.customersService()
          .getLastCommunication(customer.id)
          .then((lastComm) => {
            return {
              initialCommType,
              lastComm,
            };
          });
      }),
      rx.map(({ initialCommType, lastComm }) => {
        const selectedCommType = calcSelectedComType(lastComm, initialCommType);

        return new FormGroup({
          type: new FormControl<CustomerCommunicationType>(selectedCommType),
          communicationSubject: new FormControl<CommunicationSubject>(null),
          communicationStatus: new FormControl<CustomerCommunicationStatus>(
            null,
          ),
          details: new FormControl<string>(''),
          followUpDate: new FormControl<Date>(
            // get current time adjusted to the browser offset
            this.dateTzAdjustService.getUTCAdjustedTime(),
          ),
        } as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamFormCommType() {
    return rx.pipe(
      () => this.form$,
      rx.switchMap((form) => form.value$),
      rx.map((formValue) => (formValue as any).type),
      shareReplayRefOne(),
    )(null);
  }

  streamSaveAction() {
    return rx.pipe(
      () => this.opSave$,
      rx.withLatestFrom(
        this.form$.pipe(rx.switchMap((form) => form.value$)),
        this.inputCustomer$,
      ),
      rx.tap(([op, formValue, customer]) => {
        const callNormalized = this.modelNormalizer.normalize(formValue);

        if (!this.noFollowUpCheck) {
          // Convert the "adjusted" date object back to a normal date
          callNormalized.followUpDate = this.dateTzAdjustService.format(
            (formValue as any).followUpDate,
          );
        } else {
          delete callNormalized.followUpDate;
        }

        this.customersServiceInst
          .addCommunication(customer.id, callNormalized)
          .then((newCall) => {
            // refresh calls table
            this.prfClientGeneralPubsub.publish(COMMUNICATION_ADDED, newCall);
            this.close({ $value: newCall });
          });
      }),
    )(null);
  }

  /**
   * Sets the "is date picker open" flag to true.
   */
  openDatePicker() {
    this.isDatePickerOpen = true;
  }
}

export const AddCallPopupComponent = {
  template,
  controller: AddCommunicationPopupController,
  controllerAs: 'vm',
  bindings: {
    close: '&', // ({$value}) => void
    dismiss: '&', // ({$value}) => void
    modalInstance: '<',
    resolve: '<',
  },
};
function calcSelectedComType(
  lastComm: CustomerCommunication,
  initialCommType: CustomerCommunicationType,
): CustomerCommunicationType {
  if (_.isNil(lastComm)) {
    return initialCommType;
  }

  if (
    lastComm.type === CommunicationTypeCode.Call &&
    lastComm.method === CommunicationMethodCode.Voip
  ) {
    return initialCommType;
  }

  return lastComm.type;
}
