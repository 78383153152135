// This file was generated automatically. Tread lightly.

export enum CustomerStatusCode {
  Failed = 'failed',
  Lead = 'lead',
  New = 'new',
  Noftd = 'noftd',
  Notrade = 'notrade',
  Trader = 'trader',
}
