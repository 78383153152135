import ng from 'angular';
import * as _ from '@proftit/lodash';

const directiveName = 'prfClassesForNgOptions';

/**
 * Allow providing classes for options of select.
 *
 * Based on https://stackoverflow.com/questions/15264051/how-to-use-ng-class-in-select-with-ng-options
 */
export const classesForNgOptionsDirective = ($parse) => {
  return {
    require: 'select',
    restrict: 'A',
    link: (scope, element, attrs, ngSelect) => {
      const parts = attrs.ngOptions.split(' ');
      const optionsSourceStr = parts[parts.indexOf('in') + 1];

      const getClassesFn = $parse(attrs.prfClassesForNgOptions);

      scope.$watchCollection(optionsSourceStr, (items) => {
        if (_.isNil(items)) {
          return;
        }

        scope.$$postDigest(() => {
          items.forEach((item, index) => {
            const childScope = scope.$new(false);
            childScope.item = item;
            const classes = _.defaultTo([], getClassesFn(childScope));
            const option = element.find(`option[value="${item.id}"]`);
            classes.forEach((className) => {
              ng.element(option).addClass(className);
            });
          });
        });
      });
    },
  };
};

classesForNgOptionsDirective.$inject = ['$parse'];
