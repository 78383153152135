import * as rx from '@proftit/rxjs';
import { addIsValidListenerToBean, removeIsValidListenerFromBean, getChildDataOfBean, addValueListenerToBean, removeValueListenerFromBean, getProxyBean } from '@proftit/proxy-bean';

function observeBeanIsValid(bean) {
  return rx.obs.fromEventPattern(handler => addIsValidListenerToBean(bean, handler), handler => removeIsValidListenerFromBean(bean, handler));
}

function observeObjectProxyFieldValue(proxy, childName) {
  /* eslint-disable-next-line no-underscore-dangle */
  const bean = proxy._bean;
  const childData = getChildDataOfBean(bean, childName);
  return rx.obs.fromEventPattern(listener => addValueListenerToBean(childData.bean, listener), listener => removeValueListenerFromBean(childData.bean, listener));
}

function observeObjectProxyValue(proxy) {
  /* eslint-disable-next-line no-underscore-dangle */
  const bean = proxy._bean;
  return rx.obs.fromEventPattern(listener => addValueListenerToBean(bean, listener), listener => removeValueListenerFromBean(bean, listener));
}

function observeProxyIsValid(proxy) {
  const bean = getProxyBean(proxy);
  return observeBeanIsValid(bean);
}

export { observeBeanIsValid, observeObjectProxyFieldValue, observeObjectProxyValue, observeProxyIsValid };
