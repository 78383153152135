import Big from 'big.js';
import {
  MtAssetFeed,
  Mt4Asset,
} from '@proftit/tradingcore.api.models.entities';
import { calcRawSpreadFromAsset } from './calc-raw-spread-from-asset';

export function calcTotalSpreadFromAsset(
  assetFeed: MtAssetFeed,
  asset: Mt4Asset,
) {
  const rawSpread = calcRawSpreadFromAsset(assetFeed);
  const percissionNegative = Big(asset.precision).mul(-1);
  const percisionOver = Big(10).pow(Number(percissionNegative.toString()));
  const securitySpread = Big(asset.mtGroupSecurities.spread).mul(
    Number(percisionOver.toString()),
  );
  const totalSpread = Big(rawSpread).plus(securitySpread);

  return totalSpread;
}
