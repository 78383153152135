import * as _ from '@proftit/lodash';

interface CodeDefinition {
  code: string;
  translationPath: string;
  defaultTranslationPath: string;
}

const codesDefinitions: CodeDefinition[] = [
  {
    code: 'attachmentAction',
    translationPath: 'attachment.fields.action',
    defaultTranslationPath: 'common.N_A',
  },
  {
    code: 'contactComplianceType',
    translationPath: 'contact.compliance.types',
    defaultTranslationPath: 'common.N_A',
  },
  {
    code: 'pendingPositionForexStatus',
    translationPath: 'pendingPositionForex.fields.status',
    defaultTranslationPath: 'common.N_A',
  },
  {
    code: 'contactAuditedField',
    translationPath: 'contact',
    defaultTranslationPath: 'common.N_A',
  },
  {
    code: 'customerIdType',
    translationPath: 'contact.idType',
    defaultTranslationPath: 'common.N_A',
  },
];

export const settings = {
  codes: _.keyBy((def: CodeDefinition) => def.code)(codesDefinitions),
};

export default settings;
