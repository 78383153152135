import * as _ from '@proftit/lodash';
import angular from 'angular';

import BaseController from '~/source/common/controllers/base';

import template from './header-field.html';

class Controller extends BaseController {
  /* bindings */
  placeholder: string;

  defaultPlaceholderCode = 'questionnaire.components.header.TITLE';

  /*@ngInject */
  constructor(readonly $translate: angular.translate.ITranslateService) {
    super();

    this.placeholder = _.defaultTo(
      this.getDefaultPlaceholder(),
      this.placeholder,
    );
  }

  getDefaultPlaceholder(): string {
    return this.$translate.instant(this.defaultPlaceholderCode);
  }
}

export default {
  template,
  controller: Controller,
  bindings: {
    model: '<',
    placeholder: '@',
    onChange: '&',
  },
  controllerAs: 'vm',
};
