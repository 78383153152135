import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { TokensService } from '~/source/auth/services/tokens';
import { observeTransitionSuccess } from '../utilities/rxjs/observables/observe-transition-success';
import { UsersService } from '~/source/management/user/services/users';
import { CommunicationsSocketService } from '../components/call-manager/communications-socket.service';
import { observeChannel } from '../utilities/observe-channel';
import { User } from '@proftit/crm.api.models.entities';
import { getUserOwnCustomerCommunicationChannelName } from '../utilities/get-user-own-customer-communication-channel-name';
import { CommunicationsService } from '../components/call-manager/communications.service';
import { RouterStoreService } from './router-store.service';
import { GeneralSharedWorkerService } from '../services/general-shared-worker.service';
import { UserSocketService } from '~/source/common/services/user-socket';
import { userUpdateChannelName } from '@proftit/crm.api.channels';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import angular from 'angular';
import UserTokenModel from '~/source/common/models/user-token-model';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import {
  USER_LOGIN,
  USER_LOGOUT,
} from '~/source/common/constants/general-pubsub-keys';

export class CurrentUserStoreService {
  $inject = [];

  onUserLogin$ = this.streamOnUserLogin();
  onUserLogout$ = this.streamOnUserLogout();
  loginEvent$ = this.streamLoginEvent();

  // dummy subject
  onDestroy$ = new rx.Subject<void>();

  _isLoggedIn$ = rx.pipe(
    () =>
      rx.obs.merge(
        rx.obs.from([this.tokensService.isLoggedIn()]),
        this.onUserLogin$,
        this.onUserLogout$,
      ),
    rx.map(() => this.tokensService.isLoggedIn()),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((isCurrentUserLoggedIn) => {
      this.prfGeneralSharedWorkerService.setIsCurrentUserLoggedIn(
        isCurrentUserLoggedIn,
      );
    }),
  )(null);

  _isVoipEnabled$ = rx.pipe(
    () => this._isLoggedIn$,
    rx.withLatestFrom(this._isLoggedIn$),
    rx.switchMap(([a, isLoggedIn]) => {
      if (!isLoggedIn) {
        return rx.obs.from([false]);
      }

      return this.usersService()
        .getVoipBrandsSet(<number>this.tokensService.getCachedUser().id)
        .then((brandsSet) => brandsSet.size > 0);
    }),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
    rx.tap((isVoipEnalbed) => {
      this.prfGeneralSharedWorkerService.setIsVoipEnalbed(isVoipEnalbed);
    }),
  )(null);

  _currentLoggedUser$ = rx.pipe(
    () => this._isLoggedIn$,
    rx.map(() => this.tokensService.getCachedUser()),
    rx.shareReplay({ bufferSize: 1, refCount: true }),
  )(null);

  getCurrentUserSocketUpdateStream(user): rx.Observable<User> {
    if (!user) {
      return rx.obs.EMPTY;
    }
    return observeChannel<User>(
      this.userSocketService,
      userUpdateChannelName(user.id),
    );
  }

  private _currentUserSocketUpdates$: rx.Observable<User> = rx.pipe(
    () => this.currentLoggedUser$,
    rx.switchMap((user) => {
      return this.getCurrentUserSocketUpdateStream(user);
    }),
    shareReplayRefOne(),
  )(null);

  userWithVoipConfigurations$ = this.streamUserWithVoipConfigurations();

  /* @ngInject */
  constructor(
    readonly tokensService: TokensService,
    readonly usersService: () => UsersService,
    readonly prfRouterStore: RouterStoreService,
    readonly prfGeneralSharedWorkerService: GeneralSharedWorkerService,
    readonly userSocketService: UserSocketService,
    readonly Idle: angular.idle.IIdleService,
    readonly $state: angular.ui.IStateService,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    useStreams(
      [this.streamLogoutInactiveUser(), this.onUserLogin$, this.onUserLogout$],
      this.onDestroy$,
    );
  }

  streamOnUserLogin() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === USER_LOGIN),
      shareReplayRefOne(),
    )(null);
  }

  streamOnUserLogout() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter((x) => x.key === USER_LOGOUT),
      shareReplayRefOne(),
    )(null);
  }

  streamUserWithVoipConfigurations() {
    return rx.pipe(
      () => this._currentLoggedUser$,
      rx.filter((x) => !_.isNil(x)),
      rx.switchMap((basicUserData) => {
        return rx.obs
          .from(
            this.usersService()
              .embed(['brands', 'brands.voipConfigurations'])
              .getOneWithQuery(basicUserData.id)
              .then((res) => res.plain()),
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      shareReplayRefOne(),
    )(null);
  }

  get isLoggedIn$() {
    return this._isLoggedIn$;
  }

  get currentLoggedUser$() {
    return this._currentLoggedUser$;
  }

  get isVoipEnabled$() {
    return this._isVoipEnabled$;
  }

  get currentUserSocketUpdates$() {
    return this._currentUserSocketUpdates$;
  }

  streamLogoutInactiveUser() {
    return rx.pipe(
      () => this._currentUserSocketUpdates$,
      rx.filter((user) => !user.isActive),
      rx.tap(() => {
        this.tokensService.logout().finally(() => {
          this.Idle.unwatch();
          this.$state.go('auth.login');
        });
      }),
    )(null);
  }

  streamLoginEvent(): rx.Observable<boolean> {
    return rx.pipe(
      () => this.onUserLogin$,
      rx.map(() => true),
      shareReplayRefOne(),
    )(null);
  }
}
