import log from 'loglevel';
import type { IParseService, IScope } from 'angular';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { BrandPlatform } from '@proftit/crm.api.models.entities';
import { generateInProcessStream } from '../utilities/generate-in-process-stream';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { PlatformSessionInfo } from './platform-session-info';
import { directivesPriorities } from '../constants/directives-priorities';
import { ApiTrcBrandsService } from '../api-cfd-platform/api-trc-brands.service';
import { BrandsService } from '~/source/management/brand/services/brands';
import { ApiCfdAssetsService } from '../api-cfd-platform/api-cfd-assets.service';
import { CurrentPlatformSessionStoreServiceDirectiveController } from './current-platform-session-store-service.directive';
import tapLog from '../utilities/tap-log';

interface NgSelfAttrs {
  normalize(name: string): string;
  $addClass(classVal: string): void;
  $removeClass(classVal: string): void;
  $updateClass(newClasses: string, oldClasses: string): void;
  $observe(key: string, fn: Function): void;
  $set(name: string, value: string): void;
  $attr: Record<string, string>;
}

interface GroupAssetsNgAttrs extends NgSelfAttrs {
  prfGroupAssets: string;
}
interface GroupAssets {
  groupId: number;
  filters: [];
}

export class GroupAssetsStoreServiceDirectiveController {
  lifecycles = observeComponentLifecycles(this);

  load$ = new rx.Subject<void>();
  filterChanged$ = new rx.Subject<GroupAssets>();
  updateBulk$ = new rx.Subject<GroupAssets>();
  groupAssets$;
  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;
  groupAssetsFromBulkUpdate$;

  updateInProgress$ = new rx.BehaviorSubject<{
    inProgress: boolean;
    error: Error;
  }>({ inProgress: false, error: null });
  getInProgress$ = new rx.BehaviorSubject<boolean>(false);

  /* @ngInject */
  constructor(
    readonly $scope: IScope,
    readonly $parse: IParseService,
    readonly $attrs: GroupAssetsNgAttrs,
    readonly prfApiCfdAssetsService: ApiCfdAssetsService,
  ) {}

  $onInit() {
    this.groupAssets$ = this.streamGroupAssets();
    this.groupAssetsFromBulkUpdate$ = this.streamGroupAssetsFromBulkUpdate();

    useStreams(
      [
        this.groupAssets$,
        this.prfCurrentPlatformSession.sessionS.stream$,
        this.groupAssetsFromBulkUpdate$,
      ],
      this.lifecycles.onDestroy$,
    );

    const mainAttrExpFn = this.$parse(this.$attrs.prfGroupAssets);
    const newScope: any = this.$scope.$new(false, this.$scope);
    newScope.service = this;
    mainAttrExpFn(newScope);
    newScope.$destroy();
  }

  $onChanges() {}

  $onDestroy() {}

  streamGroupAssetsFromBulkUpdate() {
    const streamFn = rx.pipe(
      () => this.updateBulk$,
      rx.tap(() =>
        this.updateInProgress$.next({ inProgress: true, error: null }),
      ),
      rx.delay(0),
      rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),
      rx.switchMap(([payload, sessionInfo]) => {
        if (_.isNil(payload)) {
          return rx.obs.of([]);
        }
        return this.prfApiCfdAssetsService.updateBulkGroupAssets(
          payload,
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      rx.tap(() =>
        this.updateInProgress$.next({ inProgress: false, error: null }),
      ),
      rx.catchError((error) => {
        this.updateInProgress$.next({ error, inProgress: false });
        return streamFn(null);
      }),
      shareReplayRefOne(),
    );

    return streamFn(null);
  }

  streamGroupAssets() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          this.filterChanged$.pipe(
            rx.startWith({ groupId: null, filters: [] }),
          ),
        ]),
      rx.tap(() => this.getInProgress$.next(true)),
      rx.delay(0),
      rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),

      rx.switchMap(([[{ groupId, filters }], sessionInfo]) => {
        if (_.isNil(groupId)) {
          return rx.obs.of([]);
        }

        return this.prfApiCfdAssetsService.getGroupAssets(
          groupId,
          filters,
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
      }),
      rx.tap(() => this.getInProgress$.next(false)),
      rx.catchError((error) => {
        this.getInProgress$.next(false);
        throw error;
      }),
      rx.startWith([]),
      shareReplayRefOne(),
    )(null);
  }

  load() {
    this.load$.next();
  }

  filter(groupId, filters) {
    this.filterChanged$.next({ groupId, filters });
  }

  updateBulk(payload) {
    this.updateBulk$.next(payload);
  }
}

export const groupAssetsStoreServiceDirective = () => {
  return {
    restrict: 'A',
    priority: directivesPriorities.serviceDirective,
    require: {
      prfCurrentPlatformSession: '^',
    },
    bindToController: true,
    controller: GroupAssetsStoreServiceDirectiveController,
  };
};

groupAssetsStoreServiceDirective.$inject = [] as string[];
