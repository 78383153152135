import type { IHttpService } from 'angular';
import * as _ from '@proftit/lodash';
import Url from 'url-parse';
import {
  init,
  setProtocol,
  setHost,
  setResource,
  getAsOne,
  appendResourceId,
  setPayload,
  update as genReqUpdate,
  GenericRestRequest,
  setTokenHeader,
} from '@proftit/request-client';
import { removeColonAtEnd } from '~/source/common/utilities/remove-colon-at-end';

const AUDIT_LOGS_RESOURCE = 'AuditLogs';

export class TradingCoreAuditLogsApiService {
  /* @ngInject */
  constructor(readonly $http: IHttpService, readonly appConfig: any) {}

  initReq(reqParam: GenericRestRequest, urlStr: string) {
    const url = new Url(urlStr);
    return _.flow([
      (req) => init(),
      (req) => setProtocol(removeColonAtEnd(url.protocol), req),
      (req) => setHost(url.host, req),
      (req) => setResource(AUDIT_LOGS_RESOURCE, req),
    ])(reqParam);
  }

  initPrivateReq(apiUrl: string, token: string) {
    return _.flow([
      () => init(),
      (req) => this.initReq(req, apiUrl),
      (req) => setTokenHeader(token, req),
    ])();
  }
}
