import { useStreams } from '@proftit/rxjs.adjunct';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { useCountriesStore } from './use-countries-store';
import { CountriesStore } from './countries-store';
import { CountriesService } from '../services/countries';

export class CountriesProviderInner {
  countriesStore: CountriesStore;

  /* @ngInject */
  constructor(readonly countriesService: () => CountriesService) {}

  $onInit() {
    const lifecycles = observeComponentLifecycles(this);
    const [countriesStore] = useCountriesStore(
      this.countriesService,
      lifecycles,
    );

    this.countriesStore = countriesStore;
  }

  $onChanges() {}

  $onDestroy() {}
}

export const CountriesProviderComponent = {
  template: `
    <prf-countries-provider-inner>
      <ng-transclude></ng-transclude>
    </prf-countries-provider-inner>
  `,
  transclude: true,
};

export const CountriesProviderInnerComponent = {
  template: `
    <ng-transclude></ng-transclude>
  `,
  controller: CountriesProviderInner,
  transclude: true,
};
