import { tableColumns } from '~/source/contact/contact-page/sidebar-tabs/audit-logs-tab/customer-trading-accounts-audit-log-popup/table-columns';
import headerField from '~/source/management/questionnaire/form-components/header-field';

export const dataExportTableColumns = [
  {
    field: 'createdAt',
    fieldFormat: "{{createdAt | amDateFormat:'L LTS'}}",
    filterable: true,
    removable: false,
    show: true,
    sortable: 'createdAt',
    title: 'user.dataExportLog.EXPORT_DATE',
  },
  {
    field: 'exportLogIndex', // i.e. this is the filter name inside filters.json.
    fieldFormat: '{{ index.name }}', // what field will be shown inside the table column.
    filterable: true,
    removable: false,
    show: true,
    sortable: 'index',
    title: 'user.dataExportLog.INDEX',
  },
  {
    field: 'rows',
    fieldFormat: '{{rows }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: 'rows',
    title: 'user.dataExportLog.ROWS',
  },
  {
    field: 'user',
    fieldFormat: '{{ user.firstName + " "  + user.lastName }}',
    filterable: true,
    removable: false,
    show: true,
    sortable: 'user',
    title: 'user.dataExportLog.EXPORTED_BY',
  },
  {
    field: 'ip',
    fieldFormat: '{{ ip  }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: 'ip',
    title: 'auditLogs.fields.IP',
  },
  {
    fieldName: 'documents',
    _comment: 'This is empty col to download the file',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
    title: 'user.dataExportLog.EXPORTED_FILES',
    className: 'downloadTableCell',
  },
];
