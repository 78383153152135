import BaseController from '~/source/common/controllers/base';
import PopupService from '~/source/common/components/modal/popup.service';
import { Customer } from '@proftit/crm.api.models.entities';
import convertStringToCustomerPropertyValue from '~/source/common/utilities/convert-string-to-customer-property-value';
import convertCustomerPropValueToPresentationText from '~/source/common/utilities/convert-customer-prop-value-to-presentation-text';

import tablePopupTemplate from '~/source/common/components/table/table-popup.html';
import template from './contact-info-display.html';
import { Permissions } from '~/source/common/models/permission-structure';

export class ContactInfoDisplayController extends BaseController {
  Permissions = Permissions;
  static $inject = ['featuresFlags', 'popupService'];

  featuersFlags: { [key: string]: boolean };
  popupService: PopupService;
  customer: Customer;
  onEditClick: () => void;
}

export default {
  template,
  controller: ContactInfoDisplayController,
  controllerAs: 'vm',
  bindings: {
    customer: '<',
    onEditClick: '&',
  },
};
