import template from './validation-message-translate-general.component.html';
const styles = require('./validation-message-translate-general.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import log from 'loglevel';

export class ValidationMessageTranslateGeneralController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  results$ = observeShareCompChange<any>(this.lifecycles.onChanges$, 'results');
  message$ = this.streamMessage();

  /*@ngInject */
  constructor(readonly $translate: angular.translate.ITranslateService) {
    useStreams([this.results$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamMessage() {
    return rx.pipe(
      () => this.results$,
      rx.switchMap((results) => {
        const resultsPairs = _.toPairs(results);
        if (resultsPairs.length === 0) {
          return rx.obs.from(['']);
        }

        const [validationKey, validationVal] = resultsPairs[0];
        const translationKey = `validation.${validationKey}`;
        return rx.obs.from(
          (this.$translate(translationKey, validationVal) as any) as Promise<
            string
          >,
        );
      }),
      rx.catchError((err, caught) => {
        log.error('error translating validaiton message', err);
        return rx.obs.from(['']);
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const ValidationMessageTranslateGeneralComponent = {
  template,
  controller: ValidationMessageTranslateGeneralController,
  bindings: {
    results: '<',
    mainTheme: '<',
  },
};
