export const emailTemplatesDesignsTableCols = [
  {
    title: 'designTemplates.fields.NAME',
    field: '',
    fieldFormat: '{{ name }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'designTemplates.fields.IS_ACTIVE',
    field: '',
    fieldFormat:
      '{{ isActive | booleanToWord:"booleanToWord.active" | translate }}',
    sortable: false,
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'designTemplates.fields.CREATED_BY',
    field: '',
    fieldFormat: '{{ createdBy.firstName }} {{ createdBy.lastName }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: 'designTemplates.fields.CREATED_AT',
    field: '',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
  {
    title: '',
    field: '',
    fieldName: 'actions',
    fieldFormat: '',
    filterable: false,
    removable: false,
    show: true,
    sortable: false,
  },
];
