import ng from 'angular';
import { ExposureDashboardComponent } from '~/source/exposure/components/exposure-dashboard/exposure-dashboard.component';
import { ExposureNavbarComponent } from '~/source/exposure/components/exposure-navbar/exposure-navbar.component';
import { SymbolsExposureComponent } from '~/source/exposure/components/symbols-exposure/symbols-exposure.component';
import { CurrencyExposureComponent } from '~/source/exposure/components/currency-exposure/currency-exposure.component';
import { ExposureMetricBoxComponent } from '~/source/exposure/components/exposure-metric-box/exposure-metric-box.component';
import { CurrencyExposureTableComponent } from '~/source/exposure/components/currency-exposure-table/currency-exposure-table.component';
import { SymbolsExposureTableComponent } from '~/source/exposure/components/symbols-exposure-table/symbols-exposure-table.component';
import { PlatformExposureSymbolsService } from '~/source/exposure/services/platform-exposure-symbols.service';
import { ExposureSymbolPositionsTableComponent } from '~/source/exposure/components/exposure-symbol-positions-table/exposure-symbol-positions-table.component';
import { Permissions } from '~/source/common/models/permission-structure';

export default ng
  .module('crm.exposure', [])
  .component('prfExposureDashboard', ExposureDashboardComponent)
  .component('prfExposureNavbar', ExposureNavbarComponent)
  .component('prfSymbolsExposure', SymbolsExposureComponent)
  .component('prfCurrencyExposure', CurrencyExposureComponent)
  .component('prfExposureMetricBox', ExposureMetricBoxComponent)
  .component('prfCurrencyExposureTable', CurrencyExposureTableComponent)
  .component('prfSymbolsExposureTable', SymbolsExposureTableComponent)
  .component(
    'prfExposureSymbolPositionsTable',
    ExposureSymbolPositionsTableComponent,
  )
  .service('prfPlatformExposureSymbolsService', PlatformExposureSymbolsService)
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.exposure', {
        url: '/exposure',
        component: 'prfExposureDashboard',
        data: {
          permissions: {
            only: [Permissions.Exposure.Read],
            redirectTo: 'error.unauthorized',
          },
        },
      });
    },
  ]).name;
