export const customerAssignmentLogsTableColumns = [
  {
    title: 'common.DATE',
    field: 'createdAt',
    id: 'createdAt',
    fieldFormat: '{{ createdAt | amDateFormat:"L LTS" }}',
    sortable: 'createdAt',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.REGISTRATION_DATE',
    field: 'registrationDate',
    id: 'registrationDate',
    fieldFormat: '{{ registrationDate | amDateFormat:"L LTS" }}',
    sortable: 'registrationDate',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.BRAND',
    field: 'brand',
    id: 'brand',
    fieldFormat: '{{ brand.name }}',
    sortable: 'brandId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.DESK',
    field: 'deskAll',
    id: 'desk',
    fieldFormat: '{{ desk.name }}',
    sortable: 'deskId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.DEPARTMENT',
    field: 'department',
    id: 'department',
    fieldFormat: '{{ department.name }}',
    sortable: 'departmentId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.CUSTOMER_NAME',
    field: '',
    fieldFormat: '{{ customerFirstName + " " + customerLastName }}',
    sortable: 'customerId',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.CUSTOMER_ID',
    field: '',
    fieldFormat: '{{ customerId }}',
    sortable: 'customerId',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.COUNTRY',
    field: 'country',
    fieldFormat: '{{ country.name }}',
    sortable: 'countryId',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.ASSIGNED_FROM',
    field: 'assignedFromRaw',
    fieldFormat: '{{ assignedFromName }}',
    sortable: 'assignedFromName',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.ASSIGNED_TO',
    field: 'assignedToRaw',
    fieldFormat: '{{ assignedToName }}',
    sortable: 'assignedToName',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.CHANGED_BY',
    field: 'changedByRaw',
    fieldFormat: '{{ changedByName }}',
    sortable: 'changedByName',
    show: true,
    filterable: true,
    removable: false,
  },
  {
    title: 'common.IP',
    field: '',
    fieldFormat: '{{ ip }}',
    sortable: 'ip',
    show: true,
    filterable: false,
    removable: false,
  },
  {
    title: 'common.IS_NEW',
    field: 'isAssignmentNew',
    fieldFormat: '{{ isNew | booleanToWord | translate }}',
    sortable: 'isNew',
    show: true,
    filterable: true,
    removable: false,
  },
];
