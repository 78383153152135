import ng from 'angular';

import CampaignsTab from './campaigns-tab/campaigns-tab.component';
import FunnelTab from './funnel-tab.component';
import GeographicTab from './geographic-tab/geographic-tab.component';
import geographicTablePopup from './geographic-table-popup/geographic-table-popup';
import PlatformsTab from './platforms-tab.component';

export default ng
  .module('marketing.dashboardTabs', [])
  .component('prfCampaignCampaignsTab', CampaignsTab)
  .component('prfCampaignFunnelTab', FunnelTab)
  .component('prfCampaignGeographicTab', GeographicTab)
  .component('prfGeographicTablePopup', geographicTablePopup)
  .component('prfCampaignPlatformsTab', PlatformsTab).name;
