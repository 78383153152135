import { Brand, Country } from '@proftit/crm.api.models.entities';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { pipeLog, shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import type { ComponentLifecyclesObservables } from '@proftit/rxjs.adjunct.ng1';
import { BrandsService } from '~/source/management/brand/services/brands';
import { IElementRestNg } from '../models/ielement-rest-ng';
import { CountriesStore } from './countries-store';
import { useCountriesStore } from './use-countries-store';

export class BrandStore {
  setBrandFromLoadOp$ = new rx.Subject<Brand>();

  setBrandFromUpdateBlacklistedCountries$ = new rx.Subject<Brand>();

  brand$ = this.streamBrand();

  constructor(
    readonly brandsService: () => BrandsService,
    readonly countriesStore: CountriesStore,
    readonly lifecycles: ComponentLifecyclesObservables,
  ) {
    useStreams([countriesStore.countries$, this.brand$], lifecycles.onDestroy$);

    countriesStore.load();
  }

  streamBrand(): rx.Observable<Brand> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.setBrandFromLoadOp$,
          this.setBrandFromUpdateBlacklistedCountries$,
        ),
      rx.withLatestFrom(this.countriesStore.systemBlacklisted$),
      rx.map(([brand, fullBlacklistedCountries]) => {
        const blacklistedCountries = unionWithSystemBlacklistedCountries(
          brand.blacklistedCountries,
          fullBlacklistedCountries,
        );

        return {
          ...brand,
          blacklistedCountries,
        };
      }),
      shareReplayRefOne(),
    )(null);
  }

  async load(id: number) {
    const dbBrand = await this.brandsService()
      .embed([
        'desks',
        'desks.countries',
        'desks.languages',
        'currencyConnections',
        'blacklistedCountries',
        'attachmentsVerifiedMethod',
        'voipCredentials',
        'smsCredentials',
      ])
      .expand([
        'file:logo',
        'platformType',
        'currencyConnections.currency',
        'attachmentsVerifiedMethod',
      ])
      .getOneWithQuery<IElementRestNg<Brand>>(id)
      .then((data) => data.plain());

    const brand = {
      desk: [],
      blacklistedCountries: [],
      currencyConnections: [],
      attachmentsVerifiedMethod: null,
      ...dbBrand,
    };

    this.setBrandFromLoadOp$.next(brand);

    return brand;
  }

  async updateBlacklistedCountries(countries: Country[]) {
    const stream = rx.pipe(
      () => rx.obs.of(true),
      rx.withLatestFrom(this.brand$),
      rx.switchMap(([a, brand]) => {
        return this.brandsService().updateBacklistedCountries(
          brand.id,
          countries,
        );
      }),
      rx.withLatestFrom(
        this.brand$,
        this.countriesStore.countries$,
        this.countriesStore.systemBlacklisted$,
      ),
      rx.map(([result, brand, fullCountries, fullBlacklistedCountries]) => {
        const resultCodes = result.map((c) => c.alpha3Code);
        const blacklistedCountries = fullCountries.filter((c) =>
          resultCodes.includes(c.alpha3Code),
        );

        return {
          ...brand,
          blacklistedCountries,
        };
      }),
      rx.tap((brand) => {
        this.setBrandFromUpdateBlacklistedCountries$.next(brand);
      }),
      shareReplayRefOne(),
    )(null);

    return stream.pipe(rx.take(1)).toPromise();
  }
}

function unionWithSystemBlacklistedCountries(
  countries: Country[],
  fullBlacklistedCountries: Country[],
) {
  const blacklistedCountries = _.unionBy<Country>(
    (country: Country) => country.alpha3Code,
    countries,
    fullBlacklistedCountries,
  );

  return blacklistedCountries;
}
