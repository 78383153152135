import ng from 'angular';
import _ from 'underscore';
import AmChart from 'amcharts/AmChart';

import {
  Controller as BaseChartController,
  config as baseChartConfig,
} from './amcharts';
import sliceLabelsTemplate from './templates/amcharts-sliced-slice-labels.html';
import sliceTooltipsTemplate from './templates/amcharts-sliced-slice-tooltips.html';
import ColorLuminanceService from '~/source/accounting/color-luminance.service';

export class Controller extends BaseChartController {
  onPullOutSlice: ({ dataContext: any }) => {};
  onPullInSlice: ({ dataContext: any }) => {};

  colorLuminanceService: ColorLuminanceService;
  $element: JQuery;
  $compile: ng.ICompileService;

  chart: AmChart;
  tooltip: {
    name: string;
    value: string;
    realValue?: string;
    display: string;
    top?: string;
    left?: string;
    borderRightColor?: string;
    borderRightWidth?: string;
  };
  sliceLabel: { id?: any; top?: any; left?: any; sliceData?: any };
  sliceLabels: object; // todoOld: remove? not in used.

  constructor(...args) {
    super(...args);
    this.updateChartPosition = this.updateChartPosition.bind(this);
  }

  $onInit() {
    /*
     * set tooltip default values
     * you can turn on|of custom tooltip by passing option setting prfUseCustomTooltip=true|false
     */
    this.tooltip = {
      name: '',
      value: '',
      realValue: '',
      display: 'none',
    };

    /*
     * set slice label object
     * you can turn on|of custom slice labels by passing option setting prfUseCustomLabels=true|false
     */
    this.sliceLabel = {};

    // remove slice label from outside
    this.$scope.$on('slicedChart:label:remove', (scope, label) => {
      if (this.options.filterParamName === label.filterParamName) {
        this.sliceLabel = {};
      }
    });

    // remove all slice label from outside
    this.$scope.$on('slicedChart:label:removeAll', () => {
      if ((window as any).AmCharts.charts.length > 0) {
        this.sliceLabel = {};
      }
    });
  }

  /**
   * set chart events. we pass these event list to amcharts chart options.
   * @return {{listeners: *[]}}
   */
  get eventOptions() {
    return {
      listeners: [
        // mouse over chart slice, show tooltip
        { event: 'rollOverSlice', method: this.rollOverSlice.bind(this) },
        // mouse out slice, remove tooltip
        { event: 'rollOutSlice', method: this.rollOutSlice.bind(this) },
        // user pulled in a chart slice - remove filter
        { event: 'pullInSlice', method: this.pullInSlice.bind(this) },
        // user pulled out a chart slice - add filter
        { event: 'pullOutSlice', method: this.pullOutSlice.bind(this) },
        // user pulled out a chart slice - add filter
        { event: 'rendered', method: this.rendered.bind(this) },
        // user pulled out a chart slice - add filter
        { event: 'dataUpdated', method: this.dataUpdated.bind(this) },
      ],
    };
  }

  /**
   * Build the options object for a pie chart, that will be passed to amcharts
   * Gets options object which will also be merged to the returned options object
   * and can be used to override settings.
   *
   * @param {object} options
   * @returns {object}
   */
  buildOptions(options) {
    return super.buildOptions(
      this.extend(
        {
          type: 'sliced',
        },
        options,
      ),
    );
  }

  /**
   * add color range to data provider
   *
   * it will add property named color = [colors] to chart data provider
   * the color luminance is calculated based on the number of slices in the chart
   *
   * this function has required options settings.
   * you need to set "colorField": "color" in chart settings
   *
   * @param {array} data
   * @param {string} hexColor - base color
   */
  addColorRange(data: { color: string }[], hexColor) {
    _.each(data, (item, index) => {
      // set pie colors based on number of items
      const lum = 1 - index / data.length;

      // generate a lighter color hex
      item.color = this.colorLuminanceService.convert(
        hexColor,
        lum === 0 ? 0.1 : lum,
      );
    });
  }

  /**
   * add html tooltip to deposit and withdrawal charts
   *
   * @param {object} sliceData slice data provided from amcharts event
   * @param {number} top
   * @param {number} left
   */
  addSliceLabel(sliceData, top, left) {
    if (!this.options.prfUseCustomLabels) {
      return;
    }

    const sliceLabel = {
      top,
      left,
      sliceData,
      id: sliceData.id,
    };

    this.sliceLabel = sliceLabel;

    // slice-label-added
    this.$scope.$emit('slicedChart:label:added', sliceLabel);
  }

  /**
   * remove slice data & emit event
   *
   * @param {object} sliceData - slice data provided by amcharts event
   */
  removeSliceLabel(sliceData) {
    if (!this.options.prfUseCustomLabels) {
      return;
    }
    this.sliceLabel = {};
    this.$scope.$emit('slicedChart:label:removed', sliceData);
  }

  /**
   * todoOld: not in used? sliceLabels does not exists.
   */
  clearSliceLabels() {
    this.sliceLabels = {};
  }

  /**
   * dispatched when user rolls-over the slice
   *
   * @param {object} event {type:"rollOverSlice", dataItem:Slice, event:MouseEvent}
   */
  rollOverSlice(event) {
    if (!this.options || !this.options.prfUseCustomTooltip) {
      return;
    }
    // update tooltip data on mouse roll over chart slice
    this.tooltip = {
      name:
        event.dataItem.dataContext[
          this.options.titleField ? this.options.titleField : 'name'
        ],

      /*
       * if minimumThresholdSize is applied we'll have 2 values:
       * realValue - the real value of the slice like we get it from the api.
       * value - can be altered in the process in order to show really small slices on the chart.
       */
      value: event.dataItem.dataContext.value,
      realValue: event.dataItem.dataContext.realValue,
      display: 'inline',
      borderRightColor: event.dataItem.dataContext.color,
      borderRightWidth: '2px',
    };
  }

  /**
   * dispatched when user rolls-out of the slice
   *
   * @param {object} event {type:"rollOutSlice", dataItem:Slice, event:MouseEvent}
   */
  rollOutSlice(event) {
    if (!this.options || !this.options.prfUseCustomTooltip) {
      return;
    }
    // hide tooltip
    this.tooltip = {
      name: '',
      value: '',
      display: 'none',
    };
  }

  /**
   * dispatched when user clicks on a slice and the slice is pulled-in
   *
   * @param {object} event {type:"pullInSlice", dataItem:Slice, event:MouseEvent}
   */
  pullInSlice(event) {
    this.removeSliceLabel(event.dataItem.dataContext);
    this.onPullInSlice({ dataContext: event.dataItem.dataContext });
  }

  /**
   * dispatched when chart slice is pulled out
   *
   * @param {object} event {type:"pullOutSlice", dataItem:Slice, event:MouseEvent}
   */
  pullOutSlice(event) {
    const left = Math.floor(event.dataItem.balloonX - event.dataItem.pullX);
    const top = Math.floor(event.dataItem.balloonY - event.dataItem.pullY);

    this.addSliceLabel(event.dataItem.dataContext, top, left);
    this.onPullOutSlice({ dataContext: event.dataItem.dataContext });
  }

  /**
   * dispatched when the chart is build for the first time and each time after chart.validateNow() method
   * is called and the chart is build.
   *
   * @param {object} event {type:"rendered", chart:AmChart}
   */
  rendered(event) {
    this.$scope.$emit('slicedChart:chart:rendered', event.chart);
  }

  /**
   * dispatched when chart is build for the first time or after validateData() method was called.
   * @param {object} event {type:"dataUpdated", chart:AmChart}
   */
  dataUpdated(event) {
    this.$scope.$emit('slicedChart:chart:dataUpdated', event.chart);
  }

  /**
   * apply minimum threshold size for really small values that are not shown in the chart.
   *
   * store slice real value in data.realValue and store calculated fake value in data.value
   * in order to force amchart to show the slice in a proper size.
   *
   * @param {array} data chart data
   * @param {number} minimumThresholdSize minimum thresholdSize (in percent, 0.05 equal 5%)
   */
  applyMinimumThresholdSize(data, minimumThresholdSize) {
    /*
     * a gap we should keep between threshold slice sizes to the rest of the slice sizes. in percents.
     * 0.2 will make threshold visible 20% percent smaller then the threshold size.
     */
    const thresholdGap = 0.2;

    // calculate sum of values
    let sum = 0;

    _.each(data, (sliceData: any) => {
      // keep real values (data.realValues)
      sliceData.realValue = sliceData.value;

      // calculate sum of values
      sum += parseInt(sliceData.value, 10);
    });

    // set minimum size on data.value
    _.each(data, (sliceData: any) => {
      // slice ratio is smaller then the minimum threshold
      if (sliceData.value > 0 && sliceData.value / sum < minimumThresholdSize) {
        // calculate a new value for the slice
        sliceData.value = minimumThresholdSize * sum * (1 - thresholdGap);
      }
    });
  }

  updateChartPosition(e) {
    this.tooltip.left = `${Math.floor(e.pageX)}px`;
    this.tooltip.top = `${Math.floor(e.pageY)}px`;
  }

  $postLink() {
    /*
     * append tooltip element to body (outside this chart directive element)
     * in order to escape parent elements overflow & relative positioning
     */
    ng.element('body').append(
      this.$compile(sliceTooltipsTemplate)(this.$scope),
    );

    // append labels to chart wrapper. this way we can use chart balloonX & Y calculated positions
    this.$element.append(this.$compile(sliceLabelsTemplate)(this.$scope));
  }

  makeChart() {
    let options: {
      colorBase?: any;
      prfUseMinimumThresholdSize?: any;
      prfMinimumThresholdSize?: any;
      dataProvider?: any;
      data?: any;
    } = {};
    let customOptions = {};

    if (_.isUndefined(this.data)) {
      return;
    }
    // build graph options
    options = this.buildOptions(this.options || {});

    // extend with custom options
    customOptions = this.processCustomOptions(this.options || {});

    options = this.extend(options, customOptions, this.eventOptions);

    this.options = options;

    // Data might be deferred: wait for resolution.
    Promise.resolve(this.data).then((data) => {
      // apply minimum threshold size on chart slices
      if (options.prfUseMinimumThresholdSize) {
        this.applyMinimumThresholdSize(data, options.prfMinimumThresholdSize);
      }

      // add color range for chart slices
      if (data.length && options.colorBase) {
        this.addColorRange(data, options.colorBase);
      }

      if (this.chart) {
        // update existing chart data
        this.chart.dataProvider = data;

        // update chart view
        this.chart.validateData();
      } else {
        // set new chart data
        options.dataProvider = data;
        delete options.data;

        // create new chart and save it for later use
        this.chart = (window as any).AmCharts.makeChart(this.chartId, options);
      }
    });
  }
}

Controller.$inject = [
  '$scope',
  '$compile',
  '$element',
  'colorLuminanceService',
];

const componentConfig = {
  controller: Controller,
  template: `<div class="amchart-wrapper" ng-mousemove="$ctrl.updateChartPosition($event)">
                            <div class="amchart"
                               id="{{$ctrl.chartId}}"
                               ng-style="{height: $ctrl.height, width: $ctrl.width}">
                           </div>
                       </div>`,
};

const slicedConfig = {
  bindings: {
    onPullInSlice: '&',
    onPullOutSlice: '&',
  },
};

export const config = (newConfig) =>
  baseChartConfig({
    ...componentConfig,
    ...slicedConfig,
    ...newConfig,
  });

export default baseChartConfig(componentConfig);
