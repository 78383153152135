import { QuestionnaireScoreSegment } from '@proftit/crm.api.models.entities';
import { generateUuid } from '~/source/common/utilities/generate-uuid';

export function generateSegment(
  attrs: Partial<QuestionnaireScoreSegment> = {},
): Partial<QuestionnaireScoreSegment> {
  return {
    id: `clientid-${generateUuid()}` as any,
    name: '',
    start: null,
    end: null,
    ...attrs,
  };
}
