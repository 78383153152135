/*
 * Create an angular ng1 service definition that is a function that
 * generate the real service each time it ivokes.
 *
 * This is usefull for some cases like:
 *
 * 1. When desired to use service in serveral places/component on the same page
 *    and the service as state.
 *    In Angular 2 there is tree of injectors that allow creating services instances down
 *    the tree so this is not needed.
 */
function createFactoryForService(serviceClass) {
  const maker = $injector => $injector.instantiate(serviceClass);

  const factory = $injector => () => maker($injector);

  factory.$inject = ['$injector'];
  return factory;
}

export { createFactoryForService };
