import * as _ from '@proftit/lodash';

import BaseController from '~/source/common/controllers/base';
import CustomerPropertyType from '~/source/common/models/customer-property-type';
import useStream from '~/source/common/utilities/use-stream';
import CustomerProperty from '~/source/common/models/customer-property';
import switchOn from '~/source/common/utilities/switch-on';

import template from './customer-dynamic-properties-editor.component.html';
import * as rx from '@proftit/rxjs';

interface DragNDropDropInfo {
  index: number;
  item: CustomerPropertyType | ComponentEditInfo;
  external: any; // todoOld
  type: string;
}

class ComponentEditInfo {
  public modifiedData: any;
  public modifiedIsEnabled: boolean;

  constructor(
    public id: number,
    public componentName: string,
    public data: any,
    public typeCode: string,
    public order: number,
    public isDisabled: boolean = false,
    public isInEdit: boolean = false,
    public isDisabledForEdit: boolean = false,
  ) {}

  static getPropInfoAfterEdit(instance: ComponentEditInfo) {
    const data = switchOn(
      {
        select: () => ComponentEditInfo.getSelectData(instance),
        multi_select: () => ComponentEditInfo.getSelectData(instance),
      },
      instance.typeCode,
      () => ({}),
    );

    return {
      data,
      name: instance.modifiedData.text,
      typeCode: instance.typeCode,
      order: instance.order,
      isDisabled: !instance.modifiedIsEnabled,
    };
  }

  static getSelectData(instance) {
    return {
      options: instance.modifiedData.answers,
    };
  }
}

class Controller extends BaseController {
  onCustomerPropertySaveClick: ({ id: number, info: any }) => void;
  onCustomerPropertyDeleteClick: ({ id: number }) => void;

  unsub$ = new rx.Subject<void>();
  componentsEditInfos$ = new rx.BehaviorSubject<ComponentEditInfo[]>([]);
  opOnPanelDrop$ = new rx.Subject<DragNDropDropInfo>();
  opInsertTypeToList$ = new rx.Subject<{
    index: number;
    type: CustomerPropertyType;
  }>();
  opRemoveComponent$ = new rx.Subject<{
    uiComp: ComponentEditInfo;
    index: number;
  }>();
  opSaveComponent$ = new rx.Subject<ComponentEditInfo>();
  opEditComponent$ = new rx.Subject<{
    uiComp: ComponentEditInfo;
    index: number;
  }>();
  opCancelEditComponent$ = new rx.Subject<{
    uiComp: ComponentEditInfo;
    index: number;
  }>();
  opMovePropertyInList$ = new rx.Subject<{
    item: ComponentEditInfo;
    index: number;
  }>();
  customerProperties$ = new rx.BehaviorSubject<CustomerProperty[]>([]);
  isAnyItemInEdit$ = new rx.BehaviorSubject<boolean>(false);

  set customerProperties(items: CustomerProperty[]) {
    if (_.isNil(this.customerProperties$)) {
      return;
    }
    this.customerProperties$.next(items);
  }

  /*@ngInject */
  constructor() {
    super();
  }

  $onInit() {
    useStream(
      this.streamEditInfosFromInputCustomerProperties(
        this.customerProperties$,
        this.componentsEditInfos$,
        this.isAnyItemInEdit$,
      ),
      this.unsub$,
    );

    useStream(
      this.streamOnPanelDrop(
        this.opOnPanelDrop$,
        this.opInsertTypeToList$,
        this.opMovePropertyInList$,
      ),
      this.unsub$,
    );

    useStream(
      this.streamInsertTypeToList(
        this.opInsertTypeToList$,
        this.componentsEditInfos$,
        this.isAnyItemInEdit$,
      ),
      this.unsub$,
    );

    useStream(
      this.streamMovePropertyInList(
        this.opMovePropertyInList$,
        this.componentsEditInfos$,
      ),
      this.unsub$,
    );

    useStream(this.streamSaveComponent(this.opSaveComponent$), this.unsub$);

    useStream(
      this.streamEditComponent(
        this.opEditComponent$,
        this.componentsEditInfos$,
        this.isAnyItemInEdit$,
      ),
      this.unsub$,
    );

    useStream(
      this.streamCancelComponent(
        this.opCancelEditComponent$,
        this.componentsEditInfos$,
        this.isAnyItemInEdit$,
      ),
      this.unsub$,
    );
  }

  $onDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  streamEditInfosFromInputCustomerProperties(
    customerProperties$: rx.Observable<CustomerProperty[]>,
    componentsEditInfos$: rx.Subject<ComponentEditInfo[]>,
    isAnyItemInEdit$: rx.BehaviorSubject<boolean>,
  ) {
    return rx.pipe(
      () => customerProperties$,
      rx.map((props) =>
        props.map((prop) => {
          return this.generateDesignComponent(
            prop.typeCode,
            prop.order,
            prop.isDisabled,
            prop.id,
            prop.name,
            prop.data,
          );
        }),
      ),
      rx.map((editInfos) => [...editInfos].sort((a, b) => a.order - b.order)),
      rx.tap((editInfos) => componentsEditInfos$.next(editInfos)),
      rx.tap(() => isAnyItemInEdit$.next(false)),
    )(null);
  }

  streamOnPanelDrop(
    opOnPanelDrop$: rx.Subject<DragNDropDropInfo>,
    opInsertTypeToList$: rx.Subject<{
      index: number;
      type: CustomerPropertyType;
    }>,
    opMovePropertyInList$: rx.Subject<{
      index: number;
      item: ComponentEditInfo;
    }>,
  ) {
    return rx.pipe(
      () => opOnPanelDrop$,
      rx.tap(({ index, item, type }) => {
        switchOn(
          {
            customer_property_type: () =>
              opInsertTypeToList$.next({
                index,
                type: item as CustomerPropertyType,
              }),
            customer_property: () =>
              opMovePropertyInList$.next({
                index,
                item: <ComponentEditInfo>item,
              }),
          },
          type,
        );
      }),
    )(null);
  }

  streamInsertTypeToList(
    opInsertTypeToList$: rx.Subject<{
      index: number;
      type: CustomerPropertyType;
    }>,
    componentsEditInfos$: rx.BehaviorSubject<ComponentEditInfo[]>,
    isAnyItemInEdit$: rx.BehaviorSubject<boolean>,
  ) {
    return rx.pipe(
      () => opInsertTypeToList$,
      rx.withLatestFrom(
        rx.pipe(
          () => componentsEditInfos$,
          rx.map((comps) =>
            comps.map((comp) => ({
              ...comp,
              isInEdit: false,
              isDisabledForEdit: true,
            })),
          ),
        )(null),
      ),
      rx.map(([{ index, type }, comps]) => {
        const newOrder = this.calcNewOrder(comps, -1, index);
        const newComp = {
          ...this.generateDesignComponent(type.code, 0, false),
          isInEdit: true,
          isDisabledForEdit: false,
          order: newOrder,
        };

        const newList = [...comps];
        newList.splice(index, 0, newComp);

        return newList;
      }),
      rx.tap((comps) => componentsEditInfos$.next(comps)),
      rx.tap(() => isAnyItemInEdit$.next(true)),
    )(null);
  }

  streamMovePropertyInList(
    opMovePropertyInList$: rx.Subject<{
      index: number;
      item: ComponentEditInfo;
    }>,
    componentsEditInfos$: rx.BehaviorSubject<ComponentEditInfo[]>,
  ) {
    return rx.pipe(
      () => opMovePropertyInList$,
      rx.withLatestFrom(componentsEditInfos$),
      rx.map(([{ item, index }, comps]) => {
        const prevIndex = comps.findIndex((comp) => comp.id === item.id);
        const newOrder = this.calcNewOrder(comps, prevIndex, index);
        return { ...comps[prevIndex], order: newOrder };
      }),
      rx.tap((editInfo) =>
        this.onCustomerPropertySaveClick({
          id: editInfo.id,
          info: ComponentEditInfo.getPropInfoAfterEdit(editInfo),
        }),
      ),
    )(null);
  }

  streamRemoveComponent(
    opRemoveComponent$: rx.Observable<{ uiComp: any; index: number }>,
    componentsEditInfos$: rx.BehaviorSubject<ComponentEditInfo[]>,
    isAnyItemInEdit$: rx.BehaviorSubject<boolean>,
  ) {
    return rx.pipe(
      () => opRemoveComponent$,
      rx.withLatestFrom(componentsEditInfos$),
      rx.tap(([{ uiComp, index }, componentsEditInfos]) => {
        if (!_.isNil(uiComp.id)) {
          this.onCustomerPropertyDeleteClick({ id: uiComp.id });
          return;
        }

        _.flow([
          () => componentsEditInfos.filter((item, inx) => inx !== index),
          (comps) =>
            comps.map((comp) => ({
              ...comp,
              isInEdit: false,
              isDisabledForEdit: false,
            })),
          (comps) => componentsEditInfos$.next(comps),
          () => isAnyItemInEdit$.next(false),
        ])(null);
      }),
    )(null);
  }

  streamSaveComponent(opSaveComponent$: rx.Subject<ComponentEditInfo>) {
    return rx.pipe(
      () => opSaveComponent$,
      rx.map((editInfo) => ({
        id: editInfo.id,
        info: ComponentEditInfo.getPropInfoAfterEdit(editInfo),
      })),
      rx.tap((saveParams) => this.onCustomerPropertySaveClick(saveParams)),
    )(null);
  }

  streamEditComponent(
    opEditComponent$: rx.Observable<{
      uiComp: ComponentEditInfo;
      index: number;
    }>,
    componentsEditInfos$: rx.BehaviorSubject<ComponentEditInfo[]>,
    isAnyItemInEdit$: rx.BehaviorSubject<boolean>,
  ) {
    return rx.pipe(
      () => opEditComponent$,
      rx.withLatestFrom(
        rx.pipe(
          () => componentsEditInfos$,
          rx.map((comps) =>
            comps.map((comp) => ({
              ...comp,
              isInEdit: false,
              isDisabledForEdit: true,
            })),
          ),
        )(null),
      ),
      rx.map(([{ uiComp, index }, comps]) =>
        _.set(
          [index],
          { ...uiComp, isDisabledForEdit: false, isInEdit: true },
          comps,
        ),
      ),
      rx.tap((comps) => componentsEditInfos$.next(comps)),
      rx.tap(() => isAnyItemInEdit$.next(true)),
    )(null);
  }

  streamCancelComponent(
    opCancelEditComponent$: rx.Observable<{
      uiComp: ComponentEditInfo;
      index: number;
    }>,
    componentsEditInfos$: rx.BehaviorSubject<ComponentEditInfo[]>,
    isAnyItemInEdit$: rx.BehaviorSubject<boolean>,
  ) {
    return rx.pipe(
      () => opCancelEditComponent$,
      rx.withLatestFrom(componentsEditInfos$),
      rx.map(([{ uiComp, index }, comps]) => {
        if (_.isNil(uiComp.id)) {
          return comps.filter((comp) => !_.isNil(comp.id));
        }

        return _.set(
          [index],
          {
            ...uiComp,
            modifiedData: _.cloneDeep(uiComp.data),
            modifiedIsEnabled: !uiComp.isDisabled,
          },
          comps,
        );
      }),
      rx.map((comps) =>
        comps.map((comp) => ({
          ...comp,
          isInEdit: false,
          isDisabledForEdit: false,
        })),
      ),
      rx.map((comps) => [...comps].sort((a, b) => a.order - b.order)),
      rx.tap((comps) => componentsEditInfos$.next(comps)),
      rx.tap(() => isAnyItemInEdit$.next(false)),
    )(null);
  }

  calcNewOrder(comps, prevIndex, index) {
    if (prevIndex === index) {
      throw new Error('trying to calculate new order for non moved element');
    }

    const prevItemIndex = index - 1;
    const nextItemIndex = index;

    const prevOrder = prevItemIndex < 0 ? 0 : comps[prevItemIndex].order;
    const nextOrder =
      nextItemIndex >= comps.length
        ? comps.length === 0
          ? 1
          : comps[comps.length - 1].order * 2
        : comps[nextItemIndex].order;

    const newOrder = (nextOrder - prevOrder) / 2 + prevOrder;
    return newOrder;
  }

  generateDesignComponent(
    type: string,
    order: number,
    isDisabled: boolean,
    id: number = null,
    name: string = '',
    data: any = {},
  ): ComponentEditInfo {
    return _.flow([
      () =>
        switchOn(
          {
            text: () => this.generateTextDesignComponent(name),
            bool: () => this.generateBoolDesignComponent(name),
            select: () =>
              this.generateSelectDesignComopnent(name, data.options),
            multi_select: () =>
              this.generateMultiSelectDesignComponent(name, data.options),
            date: () => this.generateDateDesignComponent(name),
          },
          type,
          () => {
            throw new Error('unimplemented');
          },
        ),
      (comp) => ({
        ...comp,
        order,
        id,
        isDisabled,
        modifiedIsEnabled: !isDisabled,
        isInEdit: false,
        isDisabledForEdit: false,
        modifiedData: _.cloneDeep(comp.data),
      }),
    ])(null);
  }

  generateTextDesignComponent(text): Partial<ComponentEditInfo> {
    return new ComponentEditInfo(
      null,
      'prf-questionnaire-header-field',
      {
        text,
      },
      'text',
      null,
    );
  }

  generateDateDesignComponent(value): Partial<ComponentEditInfo> {
    return new ComponentEditInfo(
      null,
      'prf-questionnaire-date-field',
      {
        text: value,
      },
      'date',
      null,
    );
  }

  generateBoolDesignComponent(text: string): Partial<ComponentEditInfo> {
    return new ComponentEditInfo(
      null,
      'prf-questionnaire-boolean-question-field',
      {
        text,
      },
      'bool',
      null,
    );
  }

  generateSelectDesignComopnent(name, options): Partial<ComponentEditInfo> {
    return new ComponentEditInfo(
      null,
      'prf-questionnaire-single-select-field',
      {
        text: name,
        answers: options,
      },
      'select',
      null,
    );
  }

  generateMultiSelectDesignComponent(
    name,
    options,
  ): Partial<ComponentEditInfo> {
    return new ComponentEditInfo(
      null,
      'prf-questionnaire-multi-select-field',
      {
        text: name,
        answers: options,
      },
      'multi_select',
      null,
    );
  }
}

const CustomerDynamicPropertiesEditorComponent = {
  template,
  controller: Controller,
  bindings: {
    customerPropertiesTypes: '<',
    customerProperties: '<',
    onCustomerPropertySaveClick: '&',
    onCustomerPropertyDeleteClick: '&',
  },
};

export default CustomerDynamicPropertiesEditorComponent;
