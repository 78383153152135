import * as rx from '@proftit/rxjs';
import BaseService from '~/source/common/services/baseService';
import { PubsubKey } from '~/source/common/models/pubsub-key';

export interface Message {
  key: string;
  payload: any;
}

export class ClientGeneralPubsub extends BaseService {
  subject$ = new rx.Subject<Message>();

  publish(key: string, payload) {
    this.subject$.next({ key, payload });
  }

  getObservable() {
    return this.subject$.asObservable();
  }

  publishSystemLogin2faUpdated() {
    this.publish(PubsubKey.SystemLogin2faUpdated, null);
  }
}
