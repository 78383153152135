import * as rx from '@proftit/rxjs';
import useStream from '../utilities/use-stream';
import { StateService } from '@uirouter/core';
import FilterCacheService from '../components/table-filters/services/filter-cache';
import * as _ from '@proftit/lodash';
import TokensService from '~/source/auth/services/tokens';

/**
 */
export class CustomerNavigationService {
  shouldShowNavigation$ = new rx.BehaviorSubject<boolean>(false);
  goToNextCustomerOp$ = new rx.Subject<void>();
  goToPreviousCustomerOp$ = new rx.Subject<void>();

  disabledNext$ = new rx.BehaviorSubject<boolean>(false);
  disabledPrevious$ = new rx.BehaviorSubject<boolean>(false);

  constructor() {}
}
