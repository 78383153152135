import * as _ from '@proftit/lodash';
const styles = require('./credit-card.scss');
import BaseController from '~/source/common/controllers/base';
import template from './credit-card.html';
import CustomersService from '~/source/contact/common/services/customers';
import { IScope } from 'angular';
import ModelNormalizerService from '~/source/common/services/model-normalizer';
import TransferMethodTypes from '~/source/contact/common/services/transfer-method-types.service';
import {
  TradingAccount,
  TradingAccountDeposit,
} from '@proftit/crm.api.models.entities';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import WithdrawalRequest from '~/source/common/models/withdrawal-request';
import { WITHDRAWAL_STATUS_UPDATE } from '~/source/common/constants/general-pubsub-keys';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { WithdrawalDepositLinkServiceDirective } from '~/source/contact/contact-page/trading-account/common/withdrawal/withdrawal-deposit-link-service-directive';
import { WithdrawalDepositLink } from '~/source/contact/contact-page/trading-account/common/withdrawal/add-withdrawal-request-popup/add-withdrawal-request-popup.component';

export class CreditCardWithdrawalController extends BaseController {
  styles = styles;
  // bindings
  $close: () => void;
  contactId: number;
  accountId: number;
  withdrawalRequest: WithdrawalRequest;
  withdrawalDepositLink: WithdrawalDepositLinkServiceDirective;

  account: TradingAccount;
  deposits: TradingAccountDeposit[];
  cardStatusMap: { [key: string]: string };
  cardStatus;
  transferMethodTypes;
  cards: string[];
  isAddButtonEnabled = true;
  shouldDisplayNegativeFreeMarginWarning: boolean;

  customerServiceInst: CustomersService;

  /*@ngInject*/
  constructor(
    readonly $scope: IScope,
    readonly customersService: () => CustomersService,
    readonly modelNormalizer: ModelNormalizerService,
    readonly $translate: angular.translate.ITranslateService,
    readonly transferMethodTypesService: TransferMethodTypes,
    readonly withdrawalSettings,
    readonly prfClientGeneralPubsub: ClientGeneralPubsub,
  ) {
    super();
    this.customerServiceInst = this.customersService();
  }

  $onInit() {
    this.initTradingAccount();
  }

  initTradingAccount() {
    return this.customerServiceInst
      .getAccountResource(this.contactId, this.accountId)
      .expand(['currency'])
      .getOneWithQuery<IElementRestNg<TradingAccount>>()
      .then((account) => {
        this.account = account;
      });
  }

  /**
   * submit withdrawals to api
   * the function is passed as callback to child component (regular, chargeback & manual withdrawal)
   */
  submitFn({ normalized }: { normalized: WithdrawalDepositLink[] }) {
    return this.customerServiceInst
      .setConfig({
        growlRef: 'withdrawalForm',
        blockUiRef: 'withdrawalForm',
        errorsTranslationPath: 'withdrawal.errors',
      })
      .getLinkedWithdrawalsResource(
        this.contactId,
        this.accountId,
        this.withdrawalRequest.id,
      )
      .postWithQuery(normalized)
      .then(() => {
        // reload withdrawal requests table
        this.prfClientGeneralPubsub.publish(WITHDRAWAL_STATUS_UPDATE, '');
        // close the modal
        this.$close();
      });
  }

  /**
   * trigger submit action in child component
   */
  submit() {
    const normalized = this.withdrawalDepositLink.requestCalc();
    this.submitFn({ normalized });
  }

  onNoValidDeposit() {
    this.isAddButtonEnabled = false;
  }

  onDepositAmountChange({ newAmount }) {
    const { freeMargin } = this.account;
    this.shouldDisplayNegativeFreeMarginWarning = newAmount > freeMargin;
  }
}

export default {
  template,
  controller: CreditCardWithdrawalController,
  controllerAs: 'vm',
  bindings: {
    $close: '<',
    contactId: '<',
    accountId: '<',
    withdrawalRequest: '<',
    shouldDisplayFreeMargin: '<',
  },
};
