import * as _ from '@proftit/lodash';

import { decimalPlaces } from '@proftit/math.adjunct';

/**
 *
 */
export function maxDecimalPoints(value, scope, element, attrs, param) {
  const realVal = _.isNil(value) ? parseFloat(element.val()) : value;

  if (!_.isFinite(realVal)) {
    return true;
  }

  const decimalLength = decimalPlaces(realVal);

  return decimalLength <= param;
}

export default maxDecimalPoints;
