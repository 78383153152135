import angular from 'angular';
import CommonModule from './common/module';
import BinaryModule from './binary/module';
import { TradingAccountForexModule } from './forex/module';
import AddAccountPopupController from './add-account-popup.controller';
import TradingAccountsContainerComponent from './trading-accounts-container/trading-accounts-container.component';
import { TransactionTableNoteComponent } from '~/source/contact/contact-page/trading-account/transaction-table-note/transaction-table-note.component';

export default angular
  .module('crm.contact.tradingAccount', [
    CommonModule,
    BinaryModule,
    TradingAccountForexModule.name,
  ])
  .component('prfTradingAccountsContainer', TradingAccountsContainerComponent)
  .component('prfTransactionTableNote', TransactionTableNoteComponent)
  .controller('AddAccountPopupController', AddAccountPopupController).name;
