import ng, { IHttpService } from 'angular';
import * as rx from '@proftit/rxjs';

import { TableLiveController } from '~/source/common/components/table/table-live.controller';
import { SocketService } from '~/source/common/services/socket';

import template from './cfd-platform-brand-assets-table.component.html';
import { cols } from './cfd-platform-brand-assets-table-cols';
import { CfdMongoRestAdapter } from '~/source/common/utilities/cfd-mongo-rest-adapter';
import { settings } from './cfd-platform-brand-assets-table-settings';
import ModalService from '~/source/common/components/modal/modal.service';
import { PlatfromAssetsService } from '../../services/platform-assets.service';
import { CfdPlatformAsset } from '~/source/common/models/cfd-platform/cfd-platform-asset';
import { CfdPlatformSocketService } from '../../services/cfd-platform-socket.service';
import { calcAssetFixedSpread } from '~/source/common/utilities/calc-asset-fixed-spread';
import {
  tapStartAsyncWorkInUi,
  tapStopAsyncWorkInUi,
} from '~/source/common/utilities/pipe-async-work-in-ui';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { PLATFORM_ASSET_UPDATED } from '~/source/common/constants/general-pubsub-keys';
import { PlatformConnection } from '~/source/common/models/platform-connection';
import * as _ from '@proftit/lodash';
import { appendResource, appendResourceId } from '@proftit/request-client';
import { CfdPlatformGroup } from '~/source/common/models/cfd-platform/cfd-platform-group';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import { useStreams } from '@proftit/rxjs.adjunct';
import { Platform } from '@proftit/crm.api.models.entities';
import { CurrentPlatformSessionStoreServiceDirectiveController } from '~/source/common/service-directives/current-platform-session-store-service.directive';

const styles = require('./cfd-platform-brand-assets-table.component.scss');

interface ICfdPlatformBrandAssetsTable {
  platformConnection: PlatformConnection;
}

const GLOBAL_GROWL_ID = 'restService';
const GROUP_RESOURCE_PATH = 'Group';

export class CfdPlatformBrandAssetsTableController extends TableLiveController
  implements ICfdPlatformBrandAssetsTable {
  static $inject = [
    '$http',
    'blockUI',
    'growl',
    'growlMessages',
    'modalService',
    'prfPlatformAssetsService',
    'prfCfdPlatformSocketService',
    'prfClientGeneralPubsub',
    ...TableLiveController.$inject,
  ];

  /* require */

  prfCurrentPlatformSession: CurrentPlatformSessionStoreServiceDirectiveController;

  lifecycles = observeComponentLifecycles(this);

  /* bindings */
  platformConnection: PlatformConnection;
  platformGroup: CfdPlatformGroup;

  /* injections */
  $http: IHttpService;
  blockUI: ng.blockUI.BlockUIService;
  growl: ng.growl.IGrowlService;
  growlMessages: ng.growl.IGrowlMessagesService;
  modalService: ModalService;
  prfPlatformAssetsService: PlatfromAssetsService;
  prfCfdPlatformSocketService: () => CfdPlatformSocketService;
  prfClientGeneralPubsub: ClientGeneralPubsub;

  socketServiceInstance: CfdPlatformSocketService;

  platformConnection$ = new rx.BehaviorSubject<PlatformConnection>(null);

  platformGroup$ = observeShareCompChange<CfdPlatformGroup>(
    this.lifecycles.onChanges$,
    'platformGroup',
  );

  searchTerm$ = new rx.BehaviorSubject<string>('');
  settings = _.cloneDeep(settings);
  cols = cols;
  opOpenEditPopup$ = new rx.Subject<CfdPlatformAsset>();
  assets: Restangular.ICollection;
  platformConnectionForFilters: PlatformConnection;

  styles = styles;

  constructor(...args) {
    super(...args);

    useStreams(
      [this.platformConnection$, this.platformGroup$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {
    super.$onInit();
    useStreams(
      [
        this.streamInitDataServiceInstanceAndTable(),
        this.streamSearchByTerm(),
        this.streamOpenEditPopup(),
        this.streamRefreshTableOnOutsideNotifications(),
        this.streamCalcPlatformConnForFilter(),
        this.streamTableCols(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onDestroy() {
    super.$onDestroy();
  }

  onPlatformConnectionChange(newPlatformConn: PlatformConnection) {
    this.platformConnection$.next(newPlatformConn);
  }

  streamTableCols() {
    return rx.pipe(
      () => this.prfCurrentPlatformSession.sessionS.stream$,
      rx.filter((session) => session.isLoggedIn),
      rx.tap((session) => {
        this.cols = this.calcCfdPlatformAssetTableCols(session.platform);
      }),
    )(null);
  }

  calcCfdPlatformAssetTableCols(platform: Platform) {
    return _.flow([
      () => cols,
      (cols) =>
        cols.filter((col) => {
          if (!_.isArray(col.relevantPlatforms)) {
            return true;
          }

          if (col.relevantPlatforms.includes(platform.code)) {
            return true;
          }

          return false;
        }),
    ])();
  }

  streamInitDataServiceInstanceAndTable() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([this.platformConnection$, this.platformGroup$]),
      rx.filter(
        ([platformConn, platformGroup]) =>
          !_.isNil(platformConn) && !_.isNil(platformGroup),
      ),
      tapStartAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.withLatestFrom(this.prfCurrentPlatformSession.sessionS.stream$),
      rx.tap(([[platformConn, _a], sessionInfo]) => {
        this.socketServiceInstance = this.prfCfdPlatformSocketService();
        this.socketServiceInstance.setToken(sessionInfo.session.token);
        this.socketServiceInstance.setStreamerUrl(
          sessionInfo.session.riskManagerStreamerUrl,
        );
      }),
      rx.map(([[platformConn, platformGroup], sessionInfo]) => {
        let req = this.prfPlatformAssetsService.initPrivateReq(
          sessionInfo.session.apiUrl,
          sessionInfo.session.token,
        );
        req = appendResource(GROUP_RESOURCE_PATH, req);
        req = appendResourceId(platformGroup.id, req);

        return new CfdMongoRestAdapter(
          this.$http,
          this.blockUI,
          this.growl,
          this.growlMessages,
          req,
          'name',
        );
      }),
      tapStopAsyncWorkInUi(
        this.blockUI,
        this.growl,
        this.growlMessages,
        this.blockUiKey,
        GLOBAL_GROWL_ID,
      ),
      rx.tap((instance: CfdMongoRestAdapter) => {
        this.dataServiceInstance = instance;
      }),
      rx.tap(() => this.initTable()),
    )(null);
  }

  streamSearchByTerm() {
    return rx.pipe(
      () => this.searchTerm$,
      rx.debounceTime(300),
      rx.distinctUntilChanged(),
      rx.pairwise(),
      rx.tap(([a, searchTerm]) => {
        this.tableParams.page(1);
        this.tableParams.filter().q = searchTerm;
      }),
    )(null);
  }

  streamOpenEditPopup() {
    return rx.pipe(
      () => this.opOpenEditPopup$,
      rx.withLatestFrom(this.platformConnection$),
      rx.tap(([asset, platformConnection]) =>
        this.openEditPopup(platformConnection, asset),
      ),
    )(null);
  }

  streamRefreshTableOnOutsideNotifications() {
    return rx.pipe(
      () => this.prfClientGeneralPubsub.getObservable(),
      rx.filter(({ key, payload }) => key === PLATFORM_ASSET_UPDATED),
      rx.tap(() => this.reloadTable()),
    )(null);
  }

  streamCalcPlatformConnForFilter() {
    return rx.pipe(
      () => this.platformConnection$,
      rx.tap(
        (platformConnection) =>
          (this.platformConnectionForFilters = platformConnection),
      ),
    )(null);
  }

  set searchTerm(val: string) {
    this.searchTerm$.next(val);
  }
  get searchTerm() {
    return this.searchTerm$.getValue();
  }

  get tableKey() {
    return 'cfdPlatformBrandAssets';
  }

  /**
   * Getter for ngTableParams
   *
   * @returns {NgTableParams}
   */
  get ngTableDataParams() {
    return this.tableParams;
  }

  get ngTableSettings() {
    return this.settings.table.ngTable;
  }

  /**
   * Returns socket service, in use by parent class
   *
   * @returns {Service}
   */
  get socketService(): SocketService {
    return this.socketServiceInstance;
  }

  /**
   * Returns channel to subscribe for updates of specific element
   *
   * @param {int} elementId
   * @returns {string}
   */
  buildChannel(elementId: number): string {
    return `${this.socketService.channelRoot}.${elementId}`;
  }

  /**
   * Name of the variable that holds entities that should be updated live.
   *
   * @returns {string}
   */
  get liveEntitiesVarName(): string {
    return 'vm.assets';
  }

  /**
   * Return container of entities that is live updated
   *
   * @returns {Collection}
   */
  get entitiesContainer() {
    return this.assets;
  }

  /*
   * Returns a configured dataService instance.
   *
   * Called by the parent's getData method.
   * @returns {object}
   */
  fetchFn() {
    return this.dataServiceInstance.setConfig({ blockUiRef: this.blockUiKey });
  }

  parseLoadedData(data) {
    data.forEach((asset) => (asset._uiFixedSpread = null));
    this.assets = data;
    return data;
  }

  findEntityToUpdateFromStreamer(entities, newData) {
    return entities.find(({ id }) => id === newData.assetId);
  }

  updateEntityFromStreamerNotification(entity, newData) {
    const fixedSpread = calcAssetFixedSpread(entity, newData);
    Object.assign(entity, {
      _uiFixedSpread: fixedSpread,
    });
  }

  openEditPopup(
    platformConnection: PlatformConnection,
    asset: CfdPlatformAsset,
  ) {
    const updatePopup = this.modalService.open({
      component: 'prfCfdPlatformBrandAssetEditDialog',
      resolve: {
        operation: () => 'update',
        platformConnection: () => platformConnection,
        assetId: () => asset.id,
        platformGroup: () => this.platformGroup,
      },
    });
  }
}

export const CfdPlatformBrandAssetsTableComponent = {
  template,
  controller: CfdPlatformBrandAssetsTableController,
  controllerAs: 'vm',
  bindings: {
    platformConnection: '<',
    platformGroup: '<',
  },
  require: {
    prfCurrentPlatformSession: '^',
  },
};

export default CfdPlatformBrandAssetsTableComponent;
