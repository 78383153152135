import template from './trading-account-actions-bar-binary.html';
import ActionsBarBase from '../common/trading-account-actions-bar.controller';

class Controller extends ActionsBarBase {}

export default {
  template,
  controller: Controller,
  bindings: {
    customer: '<',
    account: '<',
  },
};
