import { DisplayContext } from '~/source/contact/contact-page/trading-account/forex/position/display-contenxt';

export const dashboardSettings = {
  dashboardTable: {
    quickFilters_comment:
      'if quick filter is active by default. you must set its value in bgTable.parameters.filter above',
    quickFilters: {
      realContacts: {
        state: {
          isActive: true,
        },
      },
    },
    ngTable: {
      parameters: {
        count: 50,
        page: 1,
        filter: {
          isDemo: false,
        },
      },
      settings: {
        counts: [],
      },
    },
    cols: [
      {
        title: '',
        field: 'selector',
        nonEditable: true,
        removable: false,
        type: 'common',
        show: false,
        class: 'table__checkbox',
      },
      {
        title: 'portfolio.table.REGISTRATION_DATE',
        field: 'customerRegistrationDate',
        fieldFormat: '{{ customer.registrationDate | amDateFormat:"L LTS" }}',
        sortable: 'registrationDate',
        show: true,
        filterable: true,
        removable: true,
      },
      {
        title: 'portfolio.table.ACCOUNT_CREATION_DATE',
        field: 'openingDate',
        fieldFormat: '{{ openingDate | amDateFormat:"L LTS" }}',
        sortable: 'openingDate',
        show: true,
        filterable: true,
        removable: true,
      },
      {
        title: 'FIRSTNAME',
        field: 'firstName',
        fieldName: 'firstName',
        fieldFormat: '{{ customer.firstName }}',
        sortable: 'customer.firstName',
        show: true,
        filterable: false,
        removable: true,
      },
      {
        title: 'LASTNAME',
        field: 'lastName',
        fieldName: 'lastName',
        fieldFormat: '{{ customer.lastName }}',
        sortable: 'customer.lastName',
        show: true,
        filterable: false,
        removable: true,
      },
      {
        title: 'common.COUNTRY',
        field: 'customerCountry',
        fieldFormat: "{{'country.' + customer.country.alpha2Code | translate}}",
        filterable: true,
        removable: true,
        show: true,
        sortable: 'country',
        type: 'common',
      },
      {
        title: 'CUSTOMER_ID',
        field: 'customer',
        fieldFormat: '{{ customer.id }}',
        filterable: false,
        sortable: 'customerId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.PLATFORM_ID',
        field: 'platform',
        fieldFormat: '{{ syncRemoteId }}',
        filterable: false,
        sortable: 'syncRemoteId',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.CURRENCY',
        field: 'currency',
        fieldFormat: '{{ currency.code }}',
        filterable: false,
        sortable: 'currency',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.NET_DEPOSIT',
        field: 'netDeposit',
        fieldFormat: '{{ netDeposit }}',
        filterable: true,
        sortable: 'netDeposit',
        removable: true,
        show: true,
      },
      {
        title: 'portfolio.table.MARGIN',
        displayContexts: ['portfolioMt4Table'],
        field: 'margin',
        fieldFormat: '{{ margin | number:2 }}',
        filterable: false,
        sortable: 'margin',
        removable: true,
        show: true,
      },
      {
        title: 'portfolio.table.FREE_MARGIN',
        displayContexts: ['portfolioMt4Table'],
        field: 'freeMargin',
        fieldFormat: '{{ freeMargin | number}}',
        sortable: 'freeMargin',
        show: true,
        filterable: false,
        removable: true,
      },
      {
        title: 'portfolio.table.MARGIN_LEVEL',
        displayContexts: ['portfolioMt4Table'],
        field: 'marginLevel',
        fieldFormat: '{{ marginLevel }}',
        filterable: false,
        sortable: 'marginLevel',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TOTAL_OPEN_PNL',
        field: '_openPnl',
        fieldName: '_openPnl',
        fieldFormat: '{{ _openPnl  | number:(currency.decimals) }}',
        filterable: false,
        sortable: false,
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TOTAL_CLOSED_TRADING_PNL',
        field: 'pnl',
        fieldName: 'pnl',
        fieldFormat: '{{ pnl | number:(currency.decimals) }}',
        filterable: true,
        sortable: 'pnl',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.TOTAL_CLOSED_REAL_PNL',
        field: 'cashPnl',
        fieldName: 'cashPnl',
        fieldFormat: '{{ cashPnl | number:(currency.decimals) }}',
        filterable: true,
        sortable: 'cashPnl',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.CREDIT',
        displayContexts: ['portfolioMt4Table'],
        field: 'accountCredit',
        fieldFormat: '{{ credit }}',
        filterable: true,
        sortable: 'credit',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'Bonus',
        displayContexts: ['portfolioCfdTable'],
        fieldFormat: '{{bonus }}',
        field: 'bonus',
        filterable: true,
        sortable: 'bonus',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.OPEN_POSITIONS_COUNT',
        field: 'openPositionsCount',
        filterable: true,
        sortable: 'openPositionsCount',
        removable: true,
        show: true,
        type: 'common',
      },
      {
        title: 'portfolio.table.EMPLOYEE',
        field: 'customerEmployee',
        fieldFormat:
          '{{ (customer.user.firstName) + " " + (customer.user.lastName) | trimText}}',
        filterable: true,
        sortable: false,
        removable: true,
        show: true,
        type: 'common',
      },
      {
        _comment: 'This is empty col for control settings',
        title: 'portfolio.table.COMMUNICATION',
        field: 'communication',
        className: 'communication',
        fieldName: 'communication',
        filterable: false,
        removable: false,
        show: true,
        sortable: false,
        type: 'common',
      },
      {
        title: '',
        className: 'contactDashboardLink',
        fieldName: 'contactDashboardLink',
        filterable: false,
        removable: false,
        show: true,
        sortable: false,
        type: 'common',
        nonEditable: true,
      },
    ],
  },
};
