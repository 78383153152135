import template from './notice-item-contacts-import-complete.component.html';

const styles = require('./notice-item-contacts-import-complete.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { ContactsImport } from '@proftit/crm.api.models.entities';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { PrivateGoogleStorageFileService } from '~/source/common/services/private-google-storage-file.service';
import { downloadLocalFileUrl } from '@proftit/dom-utilities';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';

export class NoticeItemContactsImportCompleteController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  blockUiId = generateBlockuiId();

  contactsImport$ = observeShareCompChange<ContactsImport>(
    this.lifecycles.onChanges$,
    'contactsImport',
  );

  asyncDownloadErrorFileAction = new rx.Subject<{
    contactsImport: ContactsImport;
  }>();

  /*@ngInject */
  constructor(
    readonly privateGoogleStorageFileService: PrivateGoogleStorageFileService,
  ) {
    useStreams(
      [this.contactsImport$, this.streamDownloadContactsImportErrorFile()],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamDownloadContactsImportErrorFile() {
    return rx.pipe(
      () => this.asyncDownloadErrorFileAction,
      rx.switchMap(({ contactsImport }) => {
        const { brandId, id } = contactsImport;
        return rx.obs
          .from(
            this.privateGoogleStorageFileService.getContactsImportErrorFileUrl(
              brandId,
              id,
              this.blockUiId,
            ),
          )
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.tap(({ localFileUrl, fileName }) => {
        downloadLocalFileUrl(localFileUrl, fileName);
        URL.revokeObjectURL(localFileUrl);
      }),
      shareReplayRefOne(),
    )(null);
  }
}

export const NoticeItemContactsImportCompleteComponent = {
  template,
  controller: NoticeItemContactsImportCompleteController,
  bindings: {
    contactsImport: '<',
  },
};
