import Component from '~/source/common/components/dropdowns/base/component';

class Controller extends Component.controller {
  static $inject = ['depositStatusesService', ...Component.controller.$inject];

  get defaultPlaceholder() {
    return 'common.PLEASE_SELECT';
  }

  /**
   * Name of the property that should be translated
   *
   * @returns {string}
   */
  get translateSource() {
    return '';
  }
}

export default Component.config({ controller: Controller });
