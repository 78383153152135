import * as _ from '@proftit/lodash';

import {
  QuestionnaireScore,
  QuestionnaireForm,
} from '@proftit/crm.api.models.entities';
import RestService from '~/source/common/services/rest';
import { IElementRestNg } from '~/source/common/models/ielement-rest-ng';
import { ClientGeneralPubsub } from '~/source/common/services/client-general-pubsub';
import { QUESTIONNAIRE_SCORE_UPDATED } from '~/source/common/constants/general-pubsub-keys';

const QUESTIONNAIRE_SCORES_RESOURCE = 'questionnaireScores';
const LANGUAGES_RESOURCE = 'languages';

class QuestionnaireService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns {string}
   */
  get resource() {
    return 'questionnaireForms';
  }

  /**
   * Update old record as newly created record in the database.
   *
   * Server saves changes to questionnaire immutably so each change is a new
   * post record creation.
   *
   * @param {object} data - Questionnaire data.
   * @return {Promise} rest post promise of the request.
   */
  updateImutable(data) {
    const parentId = this.calcImutableParentId(data);
    return this.postWithQuery({
      ...data,
      parentId,
    });
  }

  /**
   * Calculate the original record to link to the record change.
   *
   * Records that have 'parentId' are considered derivation of the original record
   * that has no 'parentId'.
   *
   * @param {object} rec - the change record
   * @return {number} id of the original record
   */
  calcImutableParentId(rec) {
    return _.defaultTo(rec.id, rec.parentId);
  }

  getQuestionnaire(id: number) {
    return this.getOneWithQuery<IElementRestNg<QuestionnaireForm>>(id);
  }

  getQestionnaireScoresResource(questionnaireId: number) {
    return this.resourceBuildStart()
      .getElement(questionnaireId)
      .getNestedCollection(QUESTIONNAIRE_SCORES_RESOURCE)
      .resourceBuildEnd();
  }

  getQestionnaireScoreResource(questionnaireId: number, scoreId: number) {
    return this.resourceBuildStart()
      .getElement(questionnaireId)
      .getNestedElement(QUESTIONNAIRE_SCORES_RESOURCE, scoreId)
      .resourceBuildEnd();
  }

  getQestionnaireScores(questionnaireId: number) {
    return this.getQestionnaireScoresResource(questionnaireId)
      .getListWithQuery<IElementRestNg<QuestionnaireScore[]>>()
      .then((data) => data.plain());
  }

  getQestionnaireScore(questionnaireId: number, scoreId: number) {
    return this.getQestionnaireScoreResource(questionnaireId, scoreId)
      .getOneWithQuery<IElementRestNg<QuestionnaireScore>>()
      .then((data) => data.plain());
  }

  activateQuestionnaireScore(questionnaireId: number, scoreId: number) {
    return this.getQestionnaireScoreResource(questionnaireId, scoreId)
      .patchWithQuery({
        id: scoreId,
        isActive: true,
      })
      .then((newItem) => {
        this.prfClientGeneralPubsub.publish(
          QUESTIONNAIRE_SCORE_UPDATED,
          newItem,
        );
        return newItem;
      })
      .then((data) => data.plain());
  }

  deactivateQuestionnaireScore(questionnaireId: number, scoreId: number) {
    return this.getQestionnaireScoreResource(questionnaireId, scoreId)
      .patchWithQuery({
        id: scoreId,
        isActive: false,
      })
      .then((newItem) => {
        this.prfClientGeneralPubsub.publish(
          QUESTIONNAIRE_SCORE_UPDATED,
          newItem,
        );
        return newItem;
      })
      .then((data) => data.plain());
  }

  createQuestionnaireScore(questionnaireId: number, score: QuestionnaireScore) {
    return this.getQestionnaireScoresResource(questionnaireId)
      .postWithQuery(score)
      .then((data) => data.plain());
  }

  updateQuestionnaireScore(questionnaireId: number, scoreId: number, score) {
    return this.getQestionnaireScoreResource(questionnaireId, scoreId)
      .patchWithQuery<IElementRestNg<QuestionnaireScore>>(score)
      .then((data) => data.plain());
  }

  getLanguageResource(questionnaireId: number, translationLangId: number) {
    return this.resourceBuildStart()
      .getElement(questionnaireId)
      .getNestedElement(LANGUAGES_RESOURCE, translationLangId)
      .resourceBuildEnd();
  }
}

export default QuestionnaireService;
