import ng, { IWindowService } from 'angular';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './click-to-chat.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { observeShareCompChange } from '~/source/common/utilities/observe-share-comp-change';
import { Customer } from '@proftit/crm.api.models.entities';
import { checkCrudPermission } from '~/source/common/utilities/rxjs/observables/check-crud-permission';
import { PermissionNormalized } from '~/source/common/models/permission-structure';

const styles = require('./click-to-chat.component.scss');

const WHATSAPP_URL = 'https://api.whatsapp.com/send?phone=';

export class ClickToChatController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  customer$ = observeShareCompChange<Customer>(
    this.lifecycles.onChanges$,
    'customer',
  );

  openWhatsappOp$ = new rx.Subject<void>();
  hasPermission$ = this.streamHasPermission();

  /* @ngInject */
  constructor(
    readonly $window: IWindowService,
    readonly PermPermissionStore: ng.permission.PermissionStore,
  ) {
    useStreams(
      [this.streamOpenWhatsappWeb(), this.hasPermission$],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamHasPermission() {
    return rx.pipe(
      () =>
        rx.obs.combineLatest([
          checkCrudPermission(
            PermissionNormalized.ContactPersonalInfoPhone,
            this.PermPermissionStore,
          ),
          checkCrudPermission(
            PermissionNormalized.ContactsWhatsappweb,
            this.PermPermissionStore,
          ),
        ]),
      rx.map(
        ([phonePermission, whatsappPermission]) =>
          phonePermission.isView && whatsappPermission.isCreate,
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamOpenWhatsappWeb() {
    return rx.pipe(
      () => this.openWhatsappOp$,
      rx.withLatestFrom(this.customer$),
      rx.tap(([a, customer]) => {
        this.$window.open(
          `${WHATSAPP_URL}${customer.countryPrefix ?? ''}${customer.phone}`,
          '_blank',
        ).opener = null;
      }),
    )(null);
  }
}

export const ClickToChatComponent = {
  template,
  controller: ClickToChatController,
  bindings: {
    customer: '<',
  },
};
