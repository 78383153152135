import _ from 'underscore';
import BaseController from '~/source/common/controllers/base';

class ElasticFieldsCollectionController extends BaseController {
  initialQty: number;
  min: number;
  max: number;
  models;
  defaultValue;
  totalCreated: number;

  $onInit() {
    // used in order to enumerate elements. useful for form field names
    this.totalCreated = 0;

    // Set default values for the directive's inputs
    _.defaults(this, {
      min: 1,
      initialQty: 1,
      max: Number.MAX_VALUE,
      defaultValue: {},
    });

    this.$scope.$watch(
      '$collection.models',
      this.onCollectionChange.bind(this),
    );
  }

  /**
   * Called on "models" collection change.
   * not using 'onModelsChange' (which is called automatically by parent) because $onChanges runs
   * before $onInit - so this.defaultValue will not be set yet.
   *
   * This method waits until the collection is ready (meaning its an array), then init.
   * @param {array} models - the collection models array
   * @returns {void}
   */
  onCollectionChange(models) {
    if (!Array.isArray(models)) {
      // not ready
      return;
    }

    // if none initial quantity specified, use "0". useful for update mode.
    const qty = this.initialQty || 0;
    const remainingQty = qty - this.models.length;

    // create initial number of elements
    _.times(remainingQty, () => {
      // add first field collection to the directive template
      this.expand();
    });
  }

  /**
   * Return true if collection can be expanded.
   *
   * @returns {boolean}
   */
  isExpandable() {
    if (!this.models || this.models.length === 0) {
      // when no models exist yet, always allow expand
      return true;
    }

    /*
     * if there is no restriction on maximum elements quantity
     * then the collection is expandable
     */
    if (_.isUndefined(this.max)) {
      return true;
    }
    // if current number of models is less then max - collection expandable
    return this.models.length < this.max;
  }

  /**
   * Returns true if elements can be removed from the collection
   *
   * @returns {boolean}
   */
  isContractible() {
    /*
     * if when we remove 1 element form the collection
     * the remaining quantity will be greater or equal to min
     * then the collection is contractible
     */
    return this.models.length - 1 >= this.min;
  }

  /**
   * Expand collection by adding new element, return true
   * if succeeded
   *
   * @returns {boolean}
   */
  expand() {
    // validate that collection is expandable
    if (!this.isExpandable()) {
      return false;
    }

    // Init new empty model and add it to the collection
    this.models.push(_.clone(this.defaultValue));

    return true;
  }

  /**
   * Remove the given model from the collection
   * @param {object} model - model to remove
   * @returns {void}
   */
  contract(model) {
    if (!this.isContractible()) {
      return;
    }
    // find model index
    const modelIndex = this.models.indexOf(model);
    // remove this model from the models list
    this.models.splice(modelIndex, 1);
  }
}

ElasticFieldsCollectionController.$inject = ['$scope'];

export const ElasticFieldsCollectionDirective = () => ({
  restrict: 'E',
  scope: {},
  transclude: true,
  controller: ElasticFieldsCollectionController,
  controllerAs: '$collection',
  bindToController: {
    initialQty: '<',
    min: '<',
    max: '<',
    models: '<',
    defaultValue: '@?',
  },
  link($scope, element, attrs, ctrl, $transclude) {
    $transclude((clone, transclusionScope) => {
      // attach the controller to the transcluded scope too
      transclusionScope.$collection = $scope.$collection;
      element.append(clone);
    });
  },
});
